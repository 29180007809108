define('dashboardPrepThumbnailCardView',[
    'module',
    'app',
    'marionette',
    'underscore',
    'template!dashboardPrepThumbnailCardTpl',
    'moment'
], function (
    module,
    App,
    Marionette,
    _,
    PrepThumbnailCardTpl,
    moment
) {
    'use strict';

    var CardView = Marionette.LayoutView.extend({
        template: PrepThumbnailCardTpl,

        events: {
            'click': 'onItemClick'
        },
        regions: {},

        initialize: function (options) {
            this.model = options.model.clone();
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.runNAme = this.model.get('name');

            var creatDate = moment(this.model.get('creatDate')).format('DD/MM/YY');
            var now = moment(new Date(Date.now())).format('DD/MM/YY');

            var elapsedTime = this.getElapsedTimeFromDDMMYYYY(creatDate, now);
            templateData.elapsedTimeToString = elapsedTime.toSting;

            return templateData;
        },

        getElapsedTimeFromDDMMYYYY: function (date1, date2) {
            var dif = parseInt(moment(date2, 'DD/MM/YY').format('x'), 10) - parseInt(moment(date1, 'DD/MM/YY').format('x'), 10);

            var days = Math.floor(dif / (1000 * 60 * 60 * 24));
            var hours = Math.floor(dif / (1000 * 60 * 60)) % 24;
            var minutes = Math.floor(dif / (1000 * 60)) % 60;
            var seconds = Math.floor(dif / 1000) % 60;

            var string = 'day:' + days + ' - hour:' + hours + ' - min:' + minutes + ' - sec:' + seconds;

            var elapsedTime = {
                toSting: string,
                days: days,
                hour: hours,
                minutes: minutes,
                seconds: seconds
            };
            return elapsedTime;
        },

        onItemClick: function () {
            var secId = this.model.get('secId');
            if ($('.run-prep-' + secId).hasClass('clickable')) {
                App.navigate('runs/prep/' + secId + '/A01/1/' + this.model.get('plateSize'), {trigger: true});
            }
        }
    });

    module.exports = CardView;
});
