define('dashboardShowController',[
    'module',
    'logger',
    'underscore',
    'app',
    'dashboardShowView',
    'entities/caccounts/pcrruns',
    'entities/caccounts/setting'
], function (
    module,
    Logger,
    _,
    App,
    View
) {
    'use strict';

    module.exports = {
        showRuns: function () {
            var view = new View();
            this.view = view;
            this.selectedList = [];
            view.listenTo(view, 'run:selectedList', _.bind(this.onSelectedRunsList, this));
            App.regions.getRegion('main').currentView.getRegion('content').show(view);
        },

        onSelectedRunsList: function (selectedList) {
            this.selectedList = selectedList;
        }
    };
});
