
define('template!runChooseUploadTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <label class="popupTitleLabel">' +
((__t = ( _.i18n('choose.import.source') )) == null ? '' : __t) +
'</label>\n        <div class="form-inputs-container" style="text-align: center;">\n\n            <div class="form-group">\n                <button class="btn popupBtn-cancel cancel-b js-cancel-popup" style="padding: 3px 15px 0 15px;">\n                    <span class="mdi mdi-close" style="font-size: 30px;"></span>\n                </button>\n\n                <input type="file"\n                       id="run-xml-input"\n                       class="hidden js-upload-runs" data-icon="true"\n                       accept="' +
((__t = ( accept )) == null ? '' : __t) +
'"/>\n\n                <label for="run-xml-input" class="btn btn popupBtn-confirm btn-sm run-import"\n                       style="padding: 0 15px 0 15px;"\n                       title="' +
((__t = ( _.i18n('run.import.folder.local') )) == null ? '' : __t) +
'">\n                    <span class="mdi mdi-monitor" style="font-size: 30px; color: inherit;"></span>\n                </label>\n\n                ';
 if(remoteAvailable) { ;
__p += '\n                <button class="btn btn popupBtn-confirm btn-sm run-import-remote-folder"\n                        style="padding: 0 15px 0 15px;"\n                        title="' +
((__t = ( _.i18n('run.import.folder.remote') )) == null ? '' : __t) +
'">\n                    <span class="mdi mdi-folder-network" style="font-size: 30px; color: inherit;"></span>\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

