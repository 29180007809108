define('dynamicCreateEditTabCollection',[
	'module',
	'backbone',
	'underscore',
	'marionette',
	'dynamicCreateEditTabItem',
	'template!dynamicCreateEditTabCollectionTpl'
], function (
	module,
	Backbone,
	_,
	Marionette,
	ItemView,
	Tpl
) {
	'use strict';

	module.exports = Marionette.CompositeView.extend({

		template: Tpl,
		childViewContainer: '.collection',
		childView: ItemView,
		childEvents: {
			'item:changeTab': function (view) {
				this.currentTabId = view.model.cid;
				this.render();
				this.trigger('item:changeTab', this.currentTabId);
			}
		},
		childViewOptions: function () {
			return {
				currentTabId: this.currentTabId
			};
		},

		initialize: function () {
			this.currentTabId = this.options.currentTabId;
		}
	});
});
