
define('template!superAdminDBDocumentationJsonObjectTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div style="display: flex;" >\n    <div style="width: 15%;">\n        <div style="display: flex;width:95%;margin:0;" >\n            <input type="text" class="form-control floating-label js-info-input-child js-column inputBackground"\n                   data-field-name="internalName" placeholder="" value="' +
((__t = ( internalName )) == null ? '' : __t) +
'"\n                   data-placement="top" autocomplete="off" ' +
((__t = ( analysis? '' : 'disabled' )) == null ? '' : __t) +
'>\n        </div>\n\n    </div>\n    <div style="width: 3%;">\n        <div class="form-group" data-field-name="sortSeq" style="width:95%;margin:0;">\n            <input type="text" class="form-control floating-label js-info-input-child js-column inputBackground"\n                   data-field-name="sortSeq" placeholder="" value="' +
((__t = ( sortSeq )) == null ? '' : __t) +
'"\n                   data-placement="top" autocomplete="off" maxLength="3">\n        </div>\n    </div>\n    <div style="width: 18%;">\n        <div class="form-group" data-field-name="name" style="width:95%;margin:0;">\n            <textarea type="text" class="form-control floating-label js-info-input-child js-column inputBackground"\n                   data-field-name="name" placeholder=""\n                      data-placement="top" autocomplete="off" style="resize: vertical; height:27px;overflow-y: auto;">' +
((__t = ( name )) == null ? '' : __t) +
'</textarea>\n        </div>\n    </div>\n\n    <div style="width: 5%;margin-right: 5px;">\n        <div class="form-control js-select-datatype-region "></div>\n    </div>\n    <div style="width: 4%;">\n        <div class="form-group" data-field-name="length" style="width:95%;margin:0;">\n            <input type="text" class="form-control floating-label js-info-input-child js-column inputBackground"\n                   data-field-name="length" placeholder="" value="' +
((__t = ( length )) == null ? '' : __t) +
'" maxlength=6\n                   data-placement="top" autocomplete="off" style="width:95%;">\n        </div>\n    </div>\n\n    <div style="width: 3%;margin-right: 5px;">\n        <div class="form-control js-select-relationtype-region "></div>\n    </div>\n    <div style="width: 10%;margin-right: 5px;">\n        <div class="form-control js-select-relation-region "></div>\n    </div>\n    <div style="width: 40%;">\n        <textarea class="form-control floating-label js-info-input-child js-column inputBackground "\n                  cols="60" data-field-name="description" placeholder="" rows="1"\n                  style="resize: vertical;"\n                  wrap="on" style="resize: vertical;">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n    </div>\n    <div style="width: 40%;">\n        <textarea class="form-control floating-label js-info-input-child js-column inputBackground "\n                  cols="60" data-field-name="exampleContent" placeholder="" rows="1"\n                  style="resize: vertical;"\n                  wrap="on" style="resize: vertical;">' +
((__t = ( exampleContent  )) == null ? '' : __t) +
'</textarea>\n    </div>\n    <div style="width: 1%;">\n        <span class="mdi mdi-delete icon js-remove-icon"></span>\n    </div>\n</div>';

}
return __p
};});

