define('menuItems',[
    'underscore',
    'settings',
    'itemPagesNameMixin',
    'rolesMixin'
], function (_,
             Settings,
             ItemPagesNameMixin,
             RolesMixin) {
    'use strict';

    return [

        // Process on sample
        {
            title: _.i18n('menu.routing'),
            itemPageName: ItemPagesNameMixin.ROUTING,
            link: function () {
                return 'routing';
            },
            role: RolesMixin.DASHBOARD_ADMIN_ROUTINGGROUP,
            subMenu: [],
            display: function (settings) {
                return settings['PREP-DISABLED'] === undefined || (!('PREP-DISABLED' in settings) || !Boolean(settings['PREP-DISABLED']));
            }
        },

        //PREPRUNS
        {
            title: _.i18n('menu.prepruns'),
            itemPageName: ItemPagesNameMixin.PREPRUNS,
            link: function () {
                return 'runs/prep';
            },
            role: RolesMixin.DASHBOARD_RUNS,
            subMenu: [],
            display: function (settings) {
                return settings['PREP-DISABLED'] === undefined || (!('PREP-DISABLED' in settings) || !Boolean(settings['PREP-DISABLED']));
            }
        },

        //PCRRUNS
        {
            title: _.i18n('menu.runs'),
            itemPageName: ItemPagesNameMixin.RUNS,
            link: function () {
                return 'runs/pcr';
            },
            role: RolesMixin.DASHBOARD_RUNS,
            subMenu: []
        },

        //PCR_SETUP
        {
            title: _.i18n('menu.pcrSetup'),
            itemPageName: ItemPagesNameMixin.PCR_SETUP,
            link: function () {
                return 'pcrSetup';
            },
            role: RolesMixin.DASHBOARD_RUNS,
            subMenu: [],
            display: function (settings) {
                return settings['PREP-DISABLED'] === undefined || (!('PREP-DISABLED' in settings) || !Boolean(settings['PREP-DISABLED']));
            }
        },

        //SAMPLES
        {
            title: _.i18n('menu.sampleManagement'),
            itemPageName: ItemPagesNameMixin.SAMPLES,
            link: function () {
                return 'samples';
            },
            role: RolesMixin.DASHBOARD_SAMPLES,
            subMenu: []
        },

        //ORDERS
        {
            title: _.i18n('menu.orders'),
            itemPageName: ItemPagesNameMixin.ORDERS,
            link: function () {
                return 'orders';
            },
            role: RolesMixin.DASHBOARD_ORDERS,
            subMenu: []
        },

        //ASSAY DEFINITION
        {
            title: _.i18n('menu.assay.definition'),
            itemPageName: ItemPagesNameMixin.ADMIN_ASSAYDEFINITION,
            link: function () {
                return '';
            },
            role: RolesMixin.DASHBOARD_ADMIN_KITPROT,
            subMenu: [{
                title: _.i18n('menu.admin.assays'),
                itemPageName: ItemPagesNameMixin.ADMIN_ASSAYS,
                link: function () {
                    return 'admin/assays';
                },
                role: RolesMixin.DASHBOARD_ADMIN_KITPROT,
                subMenu: []
            }, {
                title: _.i18n('menu.assay.assayGroup'),
                itemPageName: ItemPagesNameMixin.ADMIN_ASSAYGROUP,
                link: function () {
                    return 'admin/assaygroup';
                },
                role: RolesMixin.DASHBOARD_ADMIN_ASSAYGROUP,
                subMenu: []
            }, {
                title: _.i18n('menu.admin.kitprots'),
                itemPageName: ItemPagesNameMixin.ADMIN_KITPROTS,
                link: function () {
                    return 'admin/kitprots';
                },
                role: RolesMixin.DASHBOARD_ADMIN_KITPROT,
                subMenu: []
            }, {
                title: _.i18n('menu.admin.mbanas'),
                itemPageName: ItemPagesNameMixin.ADMIN_MBANAS,
                link: function () {
                    return 'admin/mbanas';
                },
                role: RolesMixin.DASHBOARD_ADMIN_MBANA,
                subMenu: []
            }, {
                title: _.i18n('menu.admin.mbanaresgrs'),
                itemPageName: ItemPagesNameMixin.ADMIN_MBANARESGRS,
                link: function () {
                    return 'admin/mbanaresgrs';
                },
                role: RolesMixin.DASHBOARD_ADMIN_MBANA,
                subMenu: []
            }, {
                title: _.i18n('menu.admin.kits'),
                itemPageName: ItemPagesNameMixin.ADMIN_KITS_PCR,
                link: function () {
                    return 'admin/kits/pcr';
                },
                role: RolesMixin.DASHBOARD_ADMIN_KIT,
                subMenu: []
            }, {
                title: _.i18n('menu.admin.colorCompensation'),
                itemPageName: ItemPagesNameMixin.ADMIN_COLORCOMPENSATION,
                link: function () {
                    return 'admin/colorCompensation';
                },
                role: RolesMixin.DASHBOARD_ADMIN_KIT,
                subMenu: []
            }, {
                title: _.i18n('menu.standardCurve'),
                itemPageName: ItemPagesNameMixin.ADMIN_STANDARDCURVES,
                link: function () {
                    return 'admin/standardCurves';
                },
                role: RolesMixin.DASHBOARD_ADMIN_STANDARDCURVE,
                subMenu: []
            }, {
                title: _.i18n('menu.sampleResultDefinition'),
                itemPageName: ItemPagesNameMixin.ADMIN_SAMPLERESULTDEFINITION,
                link: function () {
                    return 'admin/sampleResultDefinitions';
                },
                role: RolesMixin.DASHBOARD_SAMPLES,
                subMenu: []
            }
            ]
        },

        //PREANALYTIC
        {
            title: _.i18n('menu.preanalytic'),
            itemPageName: ItemPagesNameMixin.PREANALYTIC,
            link: function () {
                return '';
            },
            role: RolesMixin.DASHBOARD_ADMIN_PREPROCESS,
            display: function (settings) {
                return settings['PREP-DISABLED'] === undefined || (!('PREP-DISABLED' in settings) || !Boolean(settings['PREP-DISABLED']));
            },
            subMenu: [
                {
                    title: _.i18n('menu.assay.routingGroup'),
                    itemPageName: ItemPagesNameMixin.ADMIN_ROUTINGGROUP,
                    link: function () {
                        return 'admin/routinggroup';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_ROUTINGGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.assay.routingAction'),
                    itemPageName: ItemPagesNameMixin.ADMIN_ROUTINGACTION,
                    link: function () {
                        return 'admin/routingaction';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_ROUTINGGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.preProcess'),
                    itemPageName: ItemPagesNameMixin.ADMIN_PREPROCESS,
                    link: function () {
                        return 'admin/preProcess';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_PREPROCESS,
                    subMenu: []
                }, {
                    title: _.i18n('menu.extraction.exMethod'),
                    itemPageName: ItemPagesNameMixin.ADMIN_EXTRACTIONMETHOD,
                    link: function () {
                        return 'admin/extractionMethods';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_EXTRACTIONMETHOD,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.kits.extr'),
                    itemPageName: ItemPagesNameMixin.ADMIN_KITS_EXTRACTION,
                    link: function () {
                        return 'admin/kits/extraction';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_KIT,
                    subMenu: []
                }
            ]
        },

        //LIS
        {
            title: _.i18n('menu.lis'),
            itemPageName: ItemPagesNameMixin.ADMIN_LIS_MENU,
            link: function () {
                return '';
            },
            role: RolesMixin.DASHBOARD_ADMIN_LISANA,
            subMenu: [
                {
                    title: _.i18n('menu.admin.lis'),
                    itemPageName: ItemPagesNameMixin.ADMIN_LIS,
                    link: function () {
                        return 'admin/lis';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_LIS,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.sampleClass'),
                    itemPageName: ItemPagesNameMixin.ADMIN_SAMPLECLASS,
                    link: function () {
                        return 'admin/sampleClass';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_SAMPLECLASS,
                    subMenu: []
                }, {
                    title: _.i18n('menu.lis.lisana'),
                    itemPageName: ItemPagesNameMixin.ADMIN_LISANA,
                    link: function () {
                        return 'admin/lisanas';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_LISANA,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.biogroups'),
                    itemPageName: ItemPagesNameMixin.ADMIN_BIOGROUPS,
                    link: function () {
                        return 'admin/biogroups';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_BIOGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.lis.lisBioGroups'),
                    itemPageName: ItemPagesNameMixin.ADMIN_LISBIOGROUP,
                    link: function () {
                        return 'admin/lisBioGroups';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_LISBIOGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.specie'),
                    itemPageName: ItemPagesNameMixin.ADMIN_SPECIES,
                    link: function () {
                        return 'admin/species';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_SPECIE,
                    subMenu: []
                }, {
                    title: _.i18n('menu.lis.lisspecie'),
                    itemPageName: ItemPagesNameMixin.ADMIN_LISSPECIE,
                    link: function () {
                        return 'admin/lisSpecies';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_LISSPECIE,
                    subMenu: []
                }
            ]
        },

        //PROCESS CTRL
        {
            title: _.i18n('menu.process.control'),
            // title: _.i18n('menu.account'),
            itemPageName: ItemPagesNameMixin.PROCESS_CTRL,
            link: function () {
                return '';
            },
            role: RolesMixin.DASHBOARD_RUNS,
            subMenu: [{
                title: _.i18n('menu.admin.exportStatus'),
                itemPageName: ItemPagesNameMixin.ADMIN_EXPORT,
                link: function () {
                    return 'admin/export/ASTM/status';
                },
                role: RolesMixin.DASHBOARD_ADMIN_LISANA,
                subMenu: []
            }, {
                title: _.i18n('menu.attachment'),
                itemPageName: ItemPagesNameMixin.ATTACHMENTS,
                link: function () {
                    return 'admin/attachments';
                },
                role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT,
                subMenu: []
            }, {
                // TRASH
                title: _.i18n('menu.trash'),
                itemPageName: ItemPagesNameMixin.DASHBOARD,
                link: function () {
                    return 'admin/trash';
                },
                role: RolesMixin.DASHBOARD_RUNS,
                subMenu: []
            }, {
                // TRASH
                title: _.i18n('menu.connector.hl7.tracings'),
                itemPageName: ItemPagesNameMixin.CONNECTOR_HL7_TRACING,
                link: function () {
                    return 'admin/connector/hl7';
                },
                role: RolesMixin.DASHBOARD_RUNS,
                subMenu: []
            }
            ]
        },

        //ACCOUNT
        {
            title: _.i18n('menu.account'),
            // title: _.i18n('menu.account'),
            itemPageName: ItemPagesNameMixin.ACCOUNT,
            link: function () {
                return '';
            },
            role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR,
            subMenu: [
                {
                    title: _.i18n('menu.myAccount'),
                    itemPageName: ItemPagesNameMixin.MY_ACCOUNT,
                    link: function () {
                        return 'caccount/' + (Settings.get('currentCAccount') ? Settings.get('currentCAccount').secId : '');
                    },
                    role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.users'),
                    itemPageName: ItemPagesNameMixin.ADMIN_USERS,
                    link: function () {
                        return 'admin/users';
                    },
                    role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.authorityGroups'),
                    itemPageName: ItemPagesNameMixin.ADMIN_ADMINISTRATOR,
                    link: function () {
                        return 'admin/authorityGroups';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.account.suppliers'),
                    itemPageName: ItemPagesNameMixin.ADMIN_SUPPLIER,
                    link: function () {
                        return 'admin/suppliers';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_SUPPLIER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.usercodelists'),
                    itemPageName: ItemPagesNameMixin.ADMIN_USERCODELISTS,
                    link: function () {
                        return 'admin/userCodelists';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_USERCODELIST,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.settings'),
                    itemPageName: ItemPagesNameMixin.ADMIN_SETTING,
                    link: function () {
                        return 'admin/settings';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_SETTING,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.fileFormat.plateLayouts'),
                    itemPageName: ItemPagesNameMixin.ADMIN_FILEFORMAT_PLATELAYOUT,
                    link: function () {
                        return 'admin/fileFormat/plateLayouts';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_FILEFORMAT,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.fileFormat.sampleLists'),
                    itemPageName: ItemPagesNameMixin.ADMIN_FILEFORMAT_SAMPLELIST,
                    link: function () {
                        return 'admin/fileFormat/sampleLists';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_FILEFORMAT,
                    subMenu: []
                }
            ]
        },
        //INSTRUMENT
        {
            title: _.i18n('menu.instrument'),
            // title: _.i18n('menu.account'),
            itemPageName: ItemPagesNameMixin.ADMIN_INSTRUMENTS,
            link: function () {
                return '';
            },
            role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR,
            subMenu: [
                {
                    title: _.i18n('menu.admin.cyclers'),
                    itemPageName: ItemPagesNameMixin.ADMIN_CYCLERS,
                    link: function () {
                        return 'admin/cyclers';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_CYCLER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.extractors'),
                    itemPageName: ItemPagesNameMixin.ADMIN_EXTRACTORS,
                    link: function () {
                        return 'admin/extractors';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_EXTRACTOR,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.sampleHandlers'),
                    itemPageName: ItemPagesNameMixin.ADMIN_SAMPLEHANDLERS,
                    link: function () {
                        return 'admin/sampleHandlers';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_SAMPLEHANDLER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.printer'),
                    itemPageName: ItemPagesNameMixin.ADMIN_PRINTERS,
                    link: function () {
                        return 'admin/printers';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_PRINTER,
                    subMenu: []
                }
            ]
        },

        //ACCOUNT
        {
            title: _.i18n('menu.templates'),
            // title: _.i18n('menu.account'),
            itemPageName: ItemPagesNameMixin.RUNTEMPLATES,
            link: function () {
                return '';
            },
            role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR,
            subMenu: [
                {
                    title: _.i18n('menu.template.prepRun'),
                    itemPageName: ItemPagesNameMixin.PREPRUNTEMPLATES,
                    link: function () {
                        return 'admin/runtemplates/prep';
                    },
                    role: RolesMixin.DASHBOARD_RUNTEMPLATES,
                    subMenu: [],
                    display: function (settings) {
                        return settings['PREP-DISABLED'] === undefined || (!('PREP-DISABLED' in settings) || !Boolean(settings['PREP-DISABLED']));
                    }
                }, {
                    title: _.i18n('menu.template.pcrRun'),
                    itemPageName: ItemPagesNameMixin.PCRRUNTEMPLATES,
                    link: function () {
                        return 'admin/runtemplates/pcr';
                    },
                    role: RolesMixin.DASHBOARD_RUNTEMPLATES,
                    subMenu: []
                }, {
                    title: _.i18n('menu.report'),
                    itemPageName: ItemPagesNameMixin.ADMIN_REPORTS,
                    link: function () {
                        return 'admin/reports';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN_REPORT,
                    subMenu: []
                }
            ]
        },

        //SUPER ADMIN
        {
            title: _.i18n('menu.superAdmin'),
            itemPageName: ItemPagesNameMixin.SUPERADMIN,
            link: function () {
                return 'superAdmin';
            },
            role: RolesMixin.SUPERUSER,
            subMenu: [
                {
                    title: _.i18n('menu.translations'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_TRANSLATIONS,
                    link: function () {
                        return 'translations';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.caccounts'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_CACCOUNTS,
                    link: function () {
                        return 'superAdmin/caccounts';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.users'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_USERS,
                    link: function () {
                        return 'superAdmin/users';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.detectionFormats'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_DETECTIONFORMATS,
                    link: function () {
                        return 'superAdmin/detectionFormats';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.cyclers'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_CYCLERS,
                    link: function () {
                        return 'superAdmin/cyclers';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.codelists'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_CODELISTS,
                    link: function () {
                        return 'superAdmin/codelists';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.arrays'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_ARRAYS,
                    link: function () {
                        return 'superAdmin/arrays';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.glossary'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_DOCGLOSSARIES,
                    link: function () {
                        return 'superAdmin/docglossaries';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.applicationObjectsDoc'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_APPLICATIONOBJECTDOCS,
                    link: function () {
                        return 'superAdmin/dbDocumentation';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.documents'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_DOCUMENTS,
                    link: function () {
                        return 'superAdmin/documents';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.menus'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_MENUS,
                    link: function () {
                        return 'superAdmin/menus';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.godMode'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_MENUS,
                    link: function () {
                        return 'superAdmin/godMode';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.logs'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_LOGS,
                    link: function () {
                        return 'superAdmin/logs';
                    },
                    role: RolesMixin.SUPERUSER,
                    subMenu: []
                }
            ]
        }
    ];
});
