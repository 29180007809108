
define('template!dashboardPrepThumbnailCardTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n    <div class="card" style="background-color: white; border: 1px solid #333;\n            padding: 5px; padding-top: 10px;">\n\n        <div class="card-header"></div>\n\n        <div class="col-xs-12 noWrapHidden m-b-5" title="' +
((__t = ( name )) == null ? '' : __t) +
'"\n             style="border: solid 1px #fce7b8;\n                background-color: #fce7b8;">\n            <div class="col-xs-11 p-l-0 p-r-0"\n                 style="font-size: 19px; font-weight: 600; text-align: center;">\n                ' +
((__t = ( name )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-xs-1 p-l-0 p-r-0">\n                <span class="mdi ';
 if(type === 'P') { ;
__p += ' mdi-alpha-p-box ';
 } ;
__p += '"\n                     style="color: #333; font-size: 29px; position: relative;\n                     bottom: 10px; height: 26px; left: 3px;"></span>\n            </div>\n        </div>\n\n        <div style="width: 100%; text-align: center;" class="hidden">\n            <!---------------------------------------------------- hidden image -->\n            <img style="width: 90%;"\n                 src="/app/styles/images/mobiolink.svg"\n                 alt="logo for test">\n        </div>\n\n        <div>\n            <div class="col-xs-12" style="font-size: 15px;">\n\n                <div class="col-xs-6 noWrapHidden p-r-0" style="height: 29px;">\n                    <div class="col-xs-2 p-l-0 p-r-2" style="height: 17px; margin-top: 8px;">\n                        <div>\n                            <span class="jqgrid-statusNb" style="bottom:16px; font-size: 12px;">' +
((__t = ( status )) == null ? '' : __t) +
'</span>\n                            <div class="cell-default js-status-tooltip" style="display: flex; position: relative; top: 3px;">\n                                ';
 for (var i = 1; i <= 5; i++) { ;
__p += '\n                                <div class="jqgrid-Header-statusIcon' +
((__t = ( i )) == null ? '' : __t) +
' ' +
((__t = ( i === status || i < status || status === 5 ? 'statusIcon-upTo' : '')) == null ? '' : __t) +
'"></div>\n                                ';
 } ;
__p += '\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class="col-xs-6 noWrapHidden p-r-0" style="height: 29px;">\n                </div>\n\n            </div>\n\n            <div class="col-xs-12 hidden" style="font-size: 18px;">\n                <!---------------------------------------------------- hidden -->\n                <div class="col-xs-2 noWrapHidden p-r-0">\n                    <label>elapsedTimeToString</label>\n                </div>\n                <div class="col-xs-10 noWrapHidden p-r-0">\n                    ' +
((__t = ( elapsedTimeToString? elapsedTimeToString : '' )) == null ? '' : __t) +
'\n                </div>\n            </div>\n\n        </div>\n    </div>\n</div>';

}
return __p
};});

