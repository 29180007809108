/**
 * Created by OLD on 30/10/2015.
 */
define('adminMainRouter',[
    'underscore',
    'jquery',
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'itemPagesNameMixin',
    'wellUtils',
    'cssUtils',
    'dynamicController'
], function (
    _,
    $,
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    ItemPagesNameMixin,
    WellUtils,
    CssUtils,
    DynamicController
) {
    'use strict';

    var AdminRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'samples': 'showSamples',
            'orders': 'showOrders',
            'admin/assays': 'showAssaysAdminList',
            'admin/mbanas': 'showMbAnasAdminList',
            'admin/kitprots': 'showKitProtsAdminList',
            'admin/lisanas': 'showLisanasAdminList',
            'admin/settings': 'showSettingAdminList',
            'admin/settings/:secId/files': 'showFilesFromSettingAdminList',
            'admin/export/ASTM/status': 'showStatusASTMExportAdminList',
            'admin/mbanaresgrs': 'showMbAnaResGrsAdminList',
            'admin/assaygroup': 'showAssayGroupAdminList',
            'admin/kits/pcr': 'showKitsAdmin',
            'admin/kits/extraction': 'showKitsExtractionAdmin',
            'admin/routinggroup': 'showRoutingGroupAdminList',
            'admin/routingaction': 'showRoutingActionAdminList',
            'admin/preProcess': 'showPreProcessAdminList',
            'admin/suppliers': 'showSuppliersAdminList',
            'admin/biogroups': 'showBioGroupsAdminList',
            'admin/sampleResultDefinitions': 'showSampleResultDefinitionsAdminList',
            'admin/lisBioGroups': 'showLisBioGroupsAdminList',
            'admin/species': 'showSpeciesAdminList',
            'admin/printers': 'showPrintersAdminList',
            'admin/reports': 'showReportsAdminList',
            'admin/lisSpecies': 'showLisSpeciesAdminList',
            'admin/sampleClass': 'showSampleClassAdminList',
            'admin/cyclers': 'showCyclersAdminList',
            'admin/extractors': 'showExtractorsAdminList',
            'admin/sampleHandlers': 'showSampleHandlersAdminList',
            'admin/fileFormat/plateLayouts': 'showFileFormatPlateLayoutsAdminList',
            'admin/fileFormat/sampleLists': 'showFileFormatSampleListsAdminList',
            'admin/lis': 'showLisAdminList',
            'admin/trash': 'showTrashAdminList',
            'admin/extractionMethods': 'showExtractionMethodsAdminList',
            'admin/users': 'showUsersAdminList',
            'admin/colorCompensation': 'showColorCompensationAdminList',
            'admin/userCodelists': 'showUserCodeListAdminList',
            'admin/attachments': 'showAttachmentsAdminList',
            'admin/runtemplates/pcr': 'showPcrRunTemplatesList',
            'admin/runtemplates/prep': 'showPrepRunTemplatesList',
            'admin/authorityGroups': 'showAuthorityGroupsList',
            'admin/standardCurves': 'showStandardCurveAdminList',
            'admin/connector/hl7': 'showConnectorHl7List'
        }
    });

    var API = {
        checkContent: function () {
            var defer = $.Deferred();

            require([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        },

        showKitsAdmin: function () {
            this.dynamicShowList('caccounts/kitspcr', ItemPagesNameMixin.ADMIN_KITS_PCR);
        },

        showKitsExtractionAdmin: function () {
            this.dynamicShowList('caccounts/kitsextraction', ItemPagesNameMixin.ADMIN_KITS_EXTRACTION);
        },

        showAssaysAdminList: function () {
            require(['adminAssayController'], _.bind(function (AdminAssayController) {
                this.dynamicShowList('caccounts/assay', ItemPagesNameMixin.ADMIN_ASSAYS, AdminAssayController.showDetails);
            }, this));
        },

        showKitProtsAdminList: function () {
            this.dynamicShowList('caccounts/kitprots', ItemPagesNameMixin.ADMIN_KITPROTS);
        },

        showSettingAdminList: function () {
            this.dynamicShowList('caccounts/setting', ItemPagesNameMixin.ADMIN_SETTING);
        },
        showFilesFromSettingAdminList: function (secId) {
            this.dynamicShowList('caccounts/files', ItemPagesNameMixin.ADMIN_SETTING, undefined, {settingSecId: secId});
        },

        showStatusASTMExportAdminList: function () {
            require(['adminExportStatusController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/lisRecords', ItemPagesNameMixin.ADMIN_EXPORT, Controller.showDetails);
            }, this));
        },

        showMbAnasAdminList: function () {
            this.dynamicShowList('caccounts/mbanas', ItemPagesNameMixin.ADMIN_MBANAS);
        },

        showMbAnaResGrsAdminList: function () {
            this.dynamicShowList('caccounts/mbanaresgrs', ItemPagesNameMixin.ADMIN_MBANARESGRS);
        },

        showBioGroupsAdminList: function () {
            this.dynamicShowList('caccounts/biogroups', ItemPagesNameMixin.ADMIN_BIOGROUPS);
        },
        showSampleResultDefinitionsAdminList: function () {
            this.dynamicShowList('caccounts/sampleResultDefinitions', ItemPagesNameMixin.ADMIN_SAMPLERESULTDEFINITION);
        },
        showSpeciesAdminList: function () {
            this.dynamicShowList('caccounts/species', ItemPagesNameMixin.ADMIN_SPECIES);
        },
        showStandardCurveAdminList: function () {
            require(['standardCurveController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/standardCurve/standardCurves', ItemPagesNameMixin.ADMIN_STANDARDCURVES, Controller.showDetails);
            }, this));
        },
        showPrintersAdminList: function () {
            this.dynamicShowList('caccounts/printers', ItemPagesNameMixin.ADMIN_PRINTERS);
        },
        showReportsAdminList: function () {
            this.dynamicShowList('caccounts/reports', ItemPagesNameMixin.ADMIN_REPORTS);
        },
        showSampleClassAdminList: function () {
            this.dynamicShowList('caccounts/sampleClass', ItemPagesNameMixin.ADMIN_SAMPLECLASS);
        },
        showCyclersAdminList: function () {
            this.dynamicShowList('caccounts/cyclers', ItemPagesNameMixin.ADMIN_CYCLERS);
        },
        showExtractorsAdminList: function () {
            this.dynamicShowList('caccounts/extractors', ItemPagesNameMixin.ADMIN_EXTRACTORS);
        },
        showAuthorityGroupsList: function () {
            this.dynamicShowList('caccounts/authorityGroup', ItemPagesNameMixin.ADMIN_ADMINISTRATOR);
        },
        showConnectorHl7List: function () {
            require(['connectorHl7Controller'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/connector/connectorHl7Tracings', ItemPagesNameMixin.CONNECTOR_HL7_TRACING, Controller.showDetails);
            }, this));
        },
        showPcrRunTemplatesList: function () {
            require(['pcrRunTemplateController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/pcrruntemplates', ItemPagesNameMixin.PCRRUNTEMPLATES, Controller.showDetails);
            }, this));
        },
        showPrepRunTemplatesList: function () {
            require(['prepRunTemplateController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/prepruntemplates', ItemPagesNameMixin.PREPRUNTEMPLATES, Controller.showDetails);
            }, this));
        },
        showSampleHandlersAdminList: function () {
            this.dynamicShowList('caccounts/samplehandlers', ItemPagesNameMixin.ADMIN_SAMPLEHANDLERS);
        },
        showFileFormatPlateLayoutsAdminList: function () {
            this.dynamicShowList('caccounts/fileFormatPlateLayouts', ItemPagesNameMixin.ADMIN_FILEFORMAT_PLATELAYOUT);
        },
        showFileFormatSampleListsAdminList: function () {
            this.dynamicShowList('caccounts/fileFormatSampleLists', ItemPagesNameMixin.ADMIN_FILEFORMAT_SAMPLELIST);
        },
        showLisAdminList: function () {
            this.dynamicShowList('caccounts/lis', ItemPagesNameMixin.ADMIN_LIS);
        },

        showTrashAdminList: function () {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                require([
                    'adminTrashController'
                ], function (TrashController) {
                    TrashController.showTrashList({
                        type: 'trash'
                    });
                    App.execute('set:active:nav:item', ItemPagesNameMixin.DASHBOARD);
                });
            });
        },

        showLisanasAdminList: function () {
            this.dynamicShowList('caccounts/lisana', ItemPagesNameMixin.ADMIN_LISANA);
        },

        showLisBioGroupsAdminList: function () {
            this.dynamicShowList('caccounts/lisBioGroups', ItemPagesNameMixin.ADMIN_LISBIOGROUP);
        },
        showLisSpeciesAdminList: function () {
            this.dynamicShowList('caccounts/lisSpecies', ItemPagesNameMixin.ADMIN_LISSPECIE);
        },

        showSamples: function () {
            require(['samplesController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/samples', ItemPagesNameMixin.SAMPLES, Controller.showDetails);
            }, this));
        },

        showOrders: function () {
            require(['ordersController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/orders', ItemPagesNameMixin.ORDERS, Controller.showDetails);
            }, this));
        },

        showAssayGroupAdminList: function () {
            this.dynamicShowList('caccounts/assaygroup', ItemPagesNameMixin.ADMIN_ASSAYGROUP);
        },

        showRoutingGroupAdminList: function () {
            this.dynamicShowList('caccounts/routinggroup', ItemPagesNameMixin.ADMIN_ROUTINGGROUP);
        },

        showRoutingActionAdminList: function () {
            this.dynamicShowList('caccounts/routingaction', ItemPagesNameMixin.ADMIN_ROUTINGACTION);
        },

        showPreProcessAdminList: function () {
            this.dynamicShowList('caccounts/preprocess', ItemPagesNameMixin.ADMIN_PREPROCESS);
        },

        showSuppliersAdminList: function () {
            require(['suppliersController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/suppliers', ItemPagesNameMixin.ADMIN_SUPPLIER, Controller.showDetails);
            }, this));
        },

        showUsersAdminList: function () {
            require(['adminUsersController'], _.bind(function (AdminUsersController) {
                this.dynamicShowList('caccounts/userpids', ItemPagesNameMixin.ADMIN_USERS, AdminUsersController.showUsersAdd);
            }, this));
        },

        showExtractionMethodsAdminList: function () {
            this.dynamicShowList('caccounts/extractionMethod', ItemPagesNameMixin.ADMIN_EXTRACTIONMETHOD);
        },

        showColorCompensationAdminList: function () {
            this.dynamicShowList('caccounts/colorCompensations', ItemPagesNameMixin.ADMIN_COLORCOMPENSATION);
        },
        showUserCodeListAdminList: function () {
            this.dynamicShowList('caccounts/userCodelist', ItemPagesNameMixin.ADMIN_USERCODELISTS);
        },

        showAttachmentsAdminList: function () {
            require(['adminAttachmentController'], _.bind(function (AdminAttachmentController) {
                this.dynamicShowList('caccounts/attachment', ItemPagesNameMixin.ATTACHMENTS, AdminAttachmentController.showDetails);
            }, this));
        },

        dynamicShowList: function (entityNamePath, itemPagesNameMixin, callBackCreateEditView, params) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                DynamicController.showList(
                    App.regions.getRegion('main').currentView.getRegion('content'), {
                        entityNamePath: entityNamePath,
                        callBackCreateEditView: callBackCreateEditView,
                        params: params
                    });
                App.execute('set:active:nav:item', itemPagesNameMixin);
            });
        }
    };

    App.on('admin:show', function () {
        App.navigate('admin');
    });

    App.on('before:start', function () {
        new AdminRouter({
            controller: API
        });
    });

    module.exports = AdminRouter;
});
