define('entities/caccounts/printers',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Printer = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/printers/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'nameService': '',
            'description': ''
        },
        initialize: function () {
            this.set('tags', []);
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/printers');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'PrinterJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Printer',
        getEntity: function () {
            return module.exports;
        },
        importable: true,
        print: function (label) {
            var url = Settings.url('compuzz', 'v2/printers/' + this.get('secId') + '/print'),
                defer = $.Deferred();
            $.ajax({
                method: 'POST',
                url: url,
                contentType: 'application/json',
                data: label,
                success: function () {
                    defer.resolve();
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'printer';
        },
        getUrl: function () {
            return 'v2/printers';
        },
        getDynamicJson: function () {
            return 'PrinterJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('printer.nameService'),
                name: 'nameService',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'nameService',
                width: 40
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Printer.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function () {
            return [
                {
                    'field': 'nameService',
                    'param': {
                        'type': 'AUTOCOMPLETE_SYNC',
                        'config': {'getAutocompleteParam': _.bind(this.getAutocompleteParamService, this)}
                    }
                }
            ];
        },
        getAutocompleteParamService: function (config, context) {
            var defer = $.Deferred();
            config = {
                modelProperty: 'nameService',
                valueProperty: 'code',
                queryProperty: 'code',
                valueKey: 'code',
                url: this.getUrl() + '/service',
                canAddOrEdit: false,
                fieldsToDisplay: ['code', 'name']
            };
            if (!context) {
                context = this;
            }
            config = Dynamic.getAutocompleteParam(config, context);
            var url = Settings.url('compuzz', this.getUrl() + '/service');

            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (data) {
                    config.data = data;
                    defer.resolve(config);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    }, Dynamic);
});
