define('docUrlCollectionView',['module',
		'backbone',
		'marionette',
		'docUrlItemCollectionView'
	],
	function (module,
			  Backbone,
			  Marionette,
			  DocUrlItemCollectionView
	) {
		'use strict';

		var DocUrlCollection = Marionette.CollectionView.extend({

			childView: DocUrlItemCollectionView,
			collection: new Backbone.Collection(),
			childEvents: {
				'item:delete': function (view, model) {
					this.collection.remove(model);
				}
			}
		});
		module.exports = DocUrlCollection;
	});
