/**
 * Created by OBL on 14/01/2016.
 */
define('superAdminDBDocumentationController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'superAdminDBDocumentationView',
  'superAdminDBDocumentationDetailView',
  'entities/admin/jsonobjectdocumentation'
], function (
  module,
  _,
  App,
  bootbox,
  View,
  CreateEditLayoutView
) {
  'use strict';

  var SuperAdminApplicationController = {
    show: function () {
      var view = new View();
      this.view = view;
      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'jsondocumentation:update', _.bind(this.onUpdateJsonDocumentation, this));
      view.listenTo(view, 'jsondocumentation:delete', _.bind(this.onDelete, this));

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

    },
    onGridLoaded: function () {
      var data = {
        url: App.request('jsonobjectdocumentation:get-collection-url'),
        rowCountUrl: App.request('jsonobjectdocumentation:get-count-url')
      };

      this.view.displayGrid(data);
    },
    onUpdateJsonDocumentation: function () {
      var that = this;
      $.when(App.request('jsonobjectdocumentation:update')).done(_.bind(function () {
        that.view.ui.loader.hide();
        that.view.updatePagination('reloadGrid');
      }, this));
    },
    showAdd: function () {
      this.createEditView = new CreateEditLayoutView({
        model: App.request('jsonobjectdocumentation:model'),
        newItem: true
      });
      this.createEditView.show({
        title: _.i18n('menu.superadmin.jsonobjectdocumentation'),
        className: 'baseTableEditAddPopup'
      });
    },
    onDelete: function (secId) {
      bootbox.confirm(_.i18n('jsonobjectdocumentation.confirmDelete'), _.bind(function (result) {
        if (result) {
          App.request('jsonobjectdocumentation:delete', secId)
            .done(_.bind(function () {
              this.view.updatePagination('reloadGrid');
            }, this));
        }
      }, this));
    }

  };

  module.exports = SuperAdminApplicationController;
});
