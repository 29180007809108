define('entities/caccounts/kitlotextraction',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/kitsextraction'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.KitLotExtraction = Backbone.RelationalModel.extend({

        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/kitlots/extraction/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'refExtractionKit': null,
            'code': '',
            'delivery': null,
            'quantity': '',
            'expiration': null,
            'beginUse': null,
            'endUse': '',
            'description': ''
        },
        relations: [{
            type: Backbone.HasOne,
            key: 'refExtractionKit',
            relatedModel: 'KitExtraction',
            includeInJSON: ['secId', 'code', 'name']
        }],
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/kitlots/extraction');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ExtractionKitLotJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ExtractionKitLot',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.KitLotExtractionCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/kitlots/extraction');
        },
        model: app.ns.KitLotExtraction,
        comparator: function (model) {
            return -model.get('creatDate');
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'kitlotsextraction';
        },
        getUrl: function () {
            return 'v2/kitlots/extraction';
        },
        getDynamicJson: function () {
            return 'ExtractionKitLotJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                    label: _.i18n('kitLot.refKit'),
                    name: 'refExtractionKit.code',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'refExtractionKit.code',
                    searchoptions: {clearSearch: false},
                    width: 60
                },
                {
                    label: _.i18n('kitLot.refKitName'),
                    name: 'refExtractionKit.name',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'refExtractionKit.name',
                    searchoptions: {clearSearch: false},
                    width: 60
                },
                {
                    label: _.i18n('kitLot.lotId'),
                    name: 'code',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'code',
                    width: 120
                }, {
                    label: _.i18n('kitLot.quantity'),
                    name: 'quantity',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'quantity',
                    width: 40
                }, {
                    label: _.i18n('kitLot.beginUse'),
                    name: 'beginUse',
                    formatter: _.bind(viewDynamic.dateFormatterFromSettings, this), // this formatter need DATEFMT from settings
                    search: true,
                    index: 'beginUse',
                    width: 40,
                    sorttype: 'date',
                    searchrules: {date: true},
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('kitLot.endUse'),
                    name: 'endUse',
                    formatter: _.bind(viewDynamic.dateFormatterFromSettings, this), // this formatter need DATEFMT from settings
                    search: true,
                    index: 'endUse',
                    width: 40,
                    sorttype: 'date',
                    searchrules: {date: true},
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('kitLot.delivery.date'),
                    name: 'delivery',
                    formatter: _.bind(viewDynamic.dateFormatterFromSettings, this), // this formatter need DATEFMT from settings
                    search: true,
                    index: 'delivery',
                    width: 40,
                    sorttype: 'date',
                    searchrules: {date: true},
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('kitLot.expiration.date'),
                    name: 'expiration',
                    formatter: _.bind(viewDynamic.dateFormatterFromSettings, this), // this formatter need DATEFMT from settings
                    search: true,
                    index: 'expiration',
                    width: 40,
                    sorttype: 'date',
                    searchrules: {date: true},
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('tags'),
                    name: 'tags',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'tags',
                    width: 40
                }, {
                    label: _.i18n('common.description'),
                    name: 'description',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'description'
                });
            return columns;
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'code';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'code';
            configuration.urlPath = (config.url ? config.url : this.getUrl()) + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            }));
            configuration.valueKey = config.valueKey ? config.valueKey : 'code';
            return configuration;
        },
        getJqGridSidx: function () {
            return 'refExtractionKit.code';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.KitLotExtraction.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.KitLotExtractionCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 200, 'fields': ['refKit.code']}; //'delivery', 'expiration'
        },

        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldCustom();
            ignored.push('comment');
            return ignored;
        },
        getCreateEditLine: function (model) {
            return [
                {
                    'field': 'current',
                    'param': {
                        'type': 'RADIO', 'display': {
                            'class': 'col-w-3 p-l-5 p-r-5',
                            'readonly': true
                        },
                        onChange: function (fieldName, value, model) {
                            if (value) {
                                var data = {};
                                data[fieldName] = value;
                                var previousModel = model.collection.findWhere(data);
                                if (previousModel) {
                                    previousModel.set(fieldName, !value);
                                    if (!previousModel.get('endUse')) {
                                        previousModel.set('endUse', new Date());
                                    }
                                }
                            }
                            model.set(fieldName, value);
                            if (model.get('current') && value && !model.get('beginUse')) {
                                model.set('beginUse', new Date());
                            }
                        }
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'validated',
                        enable: function (model) {
                            return model.get('validated');
                        },
                        onChange: function (model) {
                            if (!model.get('validated')) {
                                model.set('current', false);
                            }
                        }
                    }
                },
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12 p-r-2 p-l-2'}}},
                {'field': 'delivery', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12 p-r-2 p-l-2'}}},
                {'field': 'expiration', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12 p-r-2 p-l-2'}}},
                {'field': 'quantity', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-8 p-r-2 p-l-2'}}},
                {'field': 'beginUse', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12 p-r-2 p-l-2'}}},
                {'field': 'endUse', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12 p-r-2 p-l-2'}}},
                {
                    'field': 'validated',
                    'param': {
                        'type': 'BOOLEAN',
                        'readOnly': true,
                        'needExist': true,
                        'display': {
                            'class': 'col-w-3 p-r-5 p-l-5 disabled'
                        }
                    }
                },
                {
                    'field': 'edit',
                    'param': {
                        'type': 'POPUP',
                        'needExist': true,
                        'config': this.editDetails,
                        'display': {'class': 'col-w-7 p-r-2 p-l-2', 'mdi': 'mdi-pencil'}
                    }
                }
            ];
        },
        editDetails: function (param) {
            require(['kitLotsExtractionController'], function (Controller) {
                delete param.controllerName;
                Controller.editDetails(param);
            });
        },
        getConfigCreateEditLine: function () {
            var config = Dynamic.getConfigCreateEditLine();
            config.editButton = Settings.get('currentUserModel').hasType('KIT0') && Settings.get('currentUserModel').hasRole('DASHBOARD_ADMIN_KIT');
            return config;
        }
    }, Dynamic);
})
;
