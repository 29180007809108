
define('template!prepRunWellEditClearWellsPlateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content info-page">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container t-a-c">\n            <div class="row">\n                <label class="popupTitleLabel dsp-cont">' +
((__t = ( _.i18n('run.assignTest.from') )) == null ? '' : __t) +
'</label>\n                <div class="h-22 dsp-cont">\n                    <input type="text" class="js-input-from-well testInputFrom"\n                           style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                           id="testFrom"\n                           value="A01"\n                           pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n                           data-placement="top"\n                           onkeyup="this.value = this.value.toUpperCase();">\n                </div>\n                <label class="popupTitleLabel dsp-cont">' +
((__t = ( _.i18n('run.assignTest.to') )) == null ? '' : __t) +
'</label>\n                <div class="dsp-cont">\n                    <input type="text" class="js-input-to-well testInputTo"\n                           style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                           id="testTo"\n                           value="' +
((__t = ( plateSize )) == null ? '' : __t) +
'"\n                           pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n                           data-placement="top"\n                           onkeyup="this.value = this.value.toUpperCase();">\n                </div>\n            </div>\n\n            <div class="row enterEditPlateLayout-buttons" style="display: flex; justify-content: center;">\n                <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n                <button class="mdi-navigation-check btn popupBtn-confirm js-clear-wells js-clear-wells-btn"></button>\n            </div>\n        </div>\n    </div>\n    <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

