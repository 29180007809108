define('entities/images',[
  'module',
  'app',
  'backbone',
  'jquery',
  'settings'
], function(
  module,
  App,
  Backbone,
  $,
  Settings
) {
  'use strict';

  var Image = Backbone.Model.extend({
    getImageInfo: function(formdata) {
      var that = this,
        url = Settings.url('compuzz', 'v2/sessions.json/file');

      that.trigger('request');

      $.ajax({
        url: url,
        type: 'POST',
        data: formdata,
        processData: false,
        contentType: false,
        success: function(resp) {
          that.set(resp);
          that.trigger('sync');
        },
        error: function(resp) {
          that.trigger('error', resp);
        }
      });
    }
  });

  App.reqres.setHandler('images:model', function(params) {
    return new Image(params);
  });
});
