define('assayReagentICView',[
    'module',
    'template!assayReagentICTpl',
    'underscore',
    'jquery',
    'autocompleteView',
    'dialogFormView',
    'entities/caccounts/kitspcr'
], function (
    module,
    AssayReagentTpl,
    _,
    $,
    AutocompleteView,
    DialogFormView,
    PcrKits
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: AssayReagentTpl,

        ui: {
            show: '.show-row',
            inputIC: '.js-info-input-IC'
        },

        events: {
            'click .show-row': 'onShowConfiguration',
            'change @ui.inputIC': 'onChangeIC'
        },

        regions: {
            pcrKit: '.js-kit'
        },

        modelEvents: {
            'change:pcrKit': 'render'
        },

        onRender: function () {
            this.getRegion('pcrKit').show(new AutocompleteView(
                this._getAutocompleteOptionObject(PcrKits.getAutocompleteParamForAssay({
                    modelProperty: 'reagent.pcrKit',
                    assaySecId: this.model.get('refAssay').get('secId'),
                    reagentTypes: ['IC'],
                    callBackOnChange: this.onChangePcrKit,
                    callBackAdd: this.onAddPcrKit,
                    callBackEdit: this.onEditPcrKit
                }))
            ));
            this.showEye();
        },
        onEditPcrKit: function (secId) {
            var model = PcrKits.getModel({secId: secId});
            model.fetch().done(_.bind(function () {
                this.showPcrKit(model);
            }, this));
        },

        onAddPcrKit: function () {
            var model = PcrKits.getModel();
            this.showPcrKit(model);
        },

        showPcrKit: function (model) {
            model.getEntity().showDetails({model: model, callBackRefresh: _.bind(this.onRender, this)});
        },

        onChangePcrKit: function (fieldName, model) {
            model = model ? PcrKits.getModel(model.toJSON()) : null;
            if (model) {
                this.onChange('reagent', model.get('reagents').findWhere({type: 'IC'}));
                if (this.model.get('reagent').get('pcrKit').get('refCurrentLot')) {
                    this.showEye();
                }
            } else {
                this.onChange('reagent', null);
            }
        },

        showEye: function () {
            if (this.model.get('reagent') && this.model.get('reagent').get('secId') && this.model.get('reagent').get('pcrKit') && this.model.get('reagent').get('pcrKit').get('refCurrentLot')) {
                this.ui.show.removeClass('invisible');
            } else {
                this.ui.show.addClass('invisible');
            }
        },
        onShowConfiguration: function () {
            if (this.model.get('reagent')) {
                $('.js-global-loader').show();
                require(['kitLotsPcrController'], _.bind(function (Controller) {
                    var model = this.model.get('reagent').get('pcrKit').get('refCurrentLot');
                    model.fetch().done(_.bind(function () {
                        Controller.showDetails(model, null, {
                            current: {
                                assay: this.model.get('refAssay'),
                                reagent: this.model.get('reagent')
                            }
                        });
                        $('.js-global-loader').hide();
                    }, this));
                }, this));
            }
        },
        serializeData: function () {
            return {volumeIC: this.model.get('refAssay').get('volumeIC')};
        },

        onChangeIC: function (e) {
            this.model.get('refAssay').set('volumeIC', e.currentTarget.value);
        }
    });
});
