define('entities/admin/menu',[
	'app',
	'backbone',
	'settings',
	'jquery'
], function (
	App,
	Backbone,
	Settings,
	$
) {
	'use strict';

	var Menu = Backbone.Model.extend({
		fetch: function () {
			this.url = Settings.url('compuzz', 'public/menus/' + this.get('secId'));

			return Backbone.Model.prototype.fetch.call(this);
		},
		defaults: {
			code: '',
			name: '',
			description: '',
			menuElements: '',
			menuIcon:'',
			iconEncodedValue:'',
			iconExtension:'',
			sortSeq: '',
			imageLink: '',
			imageLinkEncodedValue: '',
			imageLinkExtension: ''
		},
		idAttribute: 'secId',

		postUrl: function () {
			return Settings.url('compuzz', 'public/menus');
		},

		save: function () {
			this.url = this.postUrl();
			return Backbone.Model.prototype.save.call(this);
		}
	});

	var API = {

		getFieldsToDisplay: function () {
			return {'fieldsWidth': 300,
				'fields' :['name']
			};
		},
		getMenuCollectionUrl: function () {
			var params = {};
			return Settings.url('compuzz', 'public/menus', params);
		},

		getAllMenus: function (params) {
			var url = Settings.url('compuzz', 'public/menus', params),
				defer = $.Deferred();

			$.ajax({
				method: 'get',
				url: url,
				success: function (items) {
					defer.resolve(items);
				}
			});

			return defer.promise();
		},

		getMenuCountUrl: function () {
			var params = '';
			return 'public/menus/rowCount' + params;
		},

		deleteMenu: function (secId) {
			var url = Settings.url('compuzz', 'public/menus/' + secId),
				defer = $.Deferred();

			$.ajax({
				type: 'DELETE',
				url: url,
				success: function (data) {
					defer.resolve(data);
				}
			});

			return defer.promise();
		},
		getMenu: function (code) {
			var url = Settings.url('compuzz', 'public/menus/code/' + code),
				defer = $.Deferred();

			$.ajax({
				type: 'GET',
				url: url,
				success: function (data) {
					defer.resolve(data);
				}
			});

			return defer.promise();
		},
		generatePdf:function(secId) {
			var formdata = new FormData();
			formdata.append('secId', secId);
			var url = Settings.url('compuzz', 'public/menus/generatepdf'),
				defer = $.Deferred();

			$.ajax({
				type: 'POST',
				url: url,
				formdata: formdata,
				processData: false,
				contentType: false,
				success: function (data) {
					defer.resolve(data);
				}
			});

			return defer.promise();
		}
	};

	App.reqres.setHandler('menu:get-collection-url', function () {
		return API.getMenuCollectionUrl();
	});

	App.reqres.setHandler('menu:get-collection', function (params) {
		return API.getAllMenus(params);
	});

	App.reqres.setHandler('menu:get-count-url', function () {
		return API.getMenuCountUrl();
	});

	App.reqres.setHandler('menu:model', function (itemJSON) {
		return new Menu(itemJSON);
	});

	App.reqres.setHandler('menu:delete', function (secId) {
		return API.deleteMenu(secId);
	});

	App.reqres.setHandler('menu:get', function (code) {
		return API.getMenu(code);
	});

	App.reqres.setHandler('menu:get-fields-to-display', function() {
		return API.getFieldsToDisplay();
	});

	App.reqres.setHandler('menu:generate-pdf', function(secId) {
		return API.generatePdf(secId);
	});
});
