
define('template!pcrSetupOneToOneTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container"\n     style="min-height: 150px; border: solid 1px black; border-radius: 5px; width: 800px; margin: auto;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n\n    <div class="row">\n    </div>\n\n    <div>\n        <div>onSetupOneToOne</div>\n    </div>\n\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="createOneToOne">\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="refPrepRun">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('pcrSetup.prepRun') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-8">\n                        <div class="js-select-prep-run-region"></div>\n                    </div>\n                </div>\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="refRunTemplate">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('pcrSetup.pcrRunTemplate') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-8">\n                        <div class="js-select-run-template-region"></div>\n                    </div>\n                </div>\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="refAssay">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('pcrSetup.assay') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-8" >\n                        <div class="js-select-assay-region"></div>\n                    </div>\n                </div>\n                <div class="form-group js-select-date-region hidden m-t-15 m-r-0 m-b-0 m-l-0"\n                     data-field-name="selectedDate">\n                    <label class="col-xs-4 control-label" for="date-select">' +
((__t = ( _.i18n('run.selectedDate') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-8">\n                        <input type="datepicker" class="form-control floating-label date-select js-info-input"\n                               id="date-select"\n                               placeholder="' +
((__t = ( _.i18n('run.selectedDate') )) == null ? '' : __t) +
'" value="' +
((__t = ( currentDate )) == null ? '' : __t) +
'"\n                               data-field-name="selectedDate">\n                        <span class="input-group-btn">\n                            <span class="caret pull-right"></span>\n                      </span>\n                    </div>\n                </div>\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="plateIdPc">\n                    <label class="col-xs-4 control-label"\n                           for="plateIdPc">' +
((__t = ( _.i18n('pcrSetup.plateIdPc') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-8">\n                        <input class="form-control floating-label js-info-input run-plateIdPc dataInput" id="plateIdPc"\n                               data-field-name="plateIdPc" title="' +
((__t = ( _.i18n('pcr.run.plateIdPc') )) == null ? '' : __t) +
'" type="text"\n                               placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'" value="' +
((__t = ( plateIdPc )) == null ? '' : __t) +
'">\n                    </div>\n                </div>\n<!--                <div class=" form-group col-xs-12 checkbox checkbox-primary control-label m-t-15 m-r-0 m-b-0 m-l-0"-->\n<!--                     style=" padding-left: 57px;-->\n<!--                             text-align: left;">-->\n<!--                    <label>-->\n<!--                        <input type="checkbox" class="generate-starter-sheet" name="generate-starter-sheet"-->\n<!--                               data-field-name="generateStarterSheet"-->\n<!--                               value="false">-->\n<!--                    </label>-->\n<!--                    <label style="padding-left: 13px;">' +
((__t = ( _.i18n('run.generateStarterSheet') )) == null ? '' : __t) +
'</label>-->\n<!--                </div>-->\n            </div>\n        </div>\n        <br/>\n        <div class="row cancelConfirmRow">\n            <div class="pull-right p-r-15 col-xs-12 cancelConfirmCreateRunDiv">\n                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n            </div>\n        </div>\n        <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n    </div>\n</div>';

}
return __p
};});

