
define('template!runRemoteFolderTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="form-group">\n                <label class="col-xs-3 control-label" for="file-import-remote-folder">' +
((__t = ( _.i18n('run.import.remoteFile') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <select class="form-control file-import js-info-input" data-field-name="file" id="file-import-remote-folder">\n                        ';
 _.each(files, function(file){ ;
__p += '\n                            <option value="' +
((__t = ( file )) == null ? '' : __t) +
'">' +
((__t = ( file )) == null ? '' : __t) +
'</option>\n                        ';
 }); ;
__p += '\n                    </select>\n                </div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="pull-right p-r-15">\n                <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n                <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

