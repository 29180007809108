
define('template!prepRunsShowView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="prepRun-listView">\n    <div class="header">\n        <div class="header-left">\n            <div class="js-addPrepRun-empty clickable">\n                        <span id="menuAddListButton" class="mdi mdi-plus-circle action header"\n                              title="' +
((__t = ( _.i18n('add.new.record') )) == null ? '' : __t) +
'">\n                        </span>\n            </div>\n            <div id="menuImportListButton" title="' +
((__t = ( _.i18n('import.record') )) == null ? '' : __t) +
'"\n                 class="dropdown dropdown-jqgrid">\n                        <span class="dropdown-toggle mdi mdi-arrow-up-bold-circle action header clickable"\n                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></span>\n                <ul class="dropdown-menu" aria-labelledby="menuImportListButton">\n                    <li class="js-createRun-fromFile-plateLayout clickable">\n                        ' +
((__t = ( _.i18n('run.menu.import.plateLatout') )) == null ? '' : __t) +
'\n                    </li>\n                    <li class="js-createRun-fromFile-sampleList clickable">\n                        ' +
((__t = ( _.i18n('run.menu.import.sampleList') )) == null ? '' : __t) +
'\n                    </li>\n                </ul>\n            </div>\n        </div>\n        <div class="header-center">\n            <h1>' +
((__t = ( _.i18n('menu.prepruns') )) == null ? '' : __t) +
'</h1>\n        </div>\n        <div class="header-right">\n            <div class="selectArchived form-group">\n                <label for="selectArchive">' +
((__t = ( _.i18n('list.archive.title') )) == null ? '' : __t) +
'</label>\n                <select class="form-control display-archived" id="selectArchive">\n                    <option value="WITHOUT">' +
((__t = ( _.i18n('list.archive.without') )) == null ? '' : __t) +
'</option>\n                    <option value="ONLY">' +
((__t = ( _.i18n('list.archive.only') )) == null ? '' : __t) +
'</option>\n                    <option value="ALL">' +
((__t = ( _.i18n('list.archive.all') )) == null ? '' : __t) +
'</option>\n                </select>\n            </div>\n        </div>\n    </div>\n    <div class="prepRun-grid">\n        <div class="js-grid-upper-menu css-upper-menu-runFix noWrapHidden hidden"\n             style="width: 90px;">\n            <span class="mdi mdi-checkbox-blank-outline js-all-rows-selector js-grid-upper-menu-icon"></span>\n            <span class="mdi mdi-archive-arrow-down-outline js-archive-by-list js-grid-upper-menu-icon"></span>\n            <span class="mdi mdi-delete-restore js-unArchive-by-list js-grid-upper-menu-icon"></span>\n        </div>\n\n        <table id="jq-grid-list" class="prepRun-table" role="grid"></table>\n        <div id="jq-grid-pager-list"></div>\n    </div>\n</div>';

}
return __p
};});

