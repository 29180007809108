define('routingGroupDispatchSampleView',[
        'app',
        'module',
        'marionette',
        'backbone',
        'underscore',
        'jquery',
        'dialogFormView',
        'template!routingGroupDispatchSampleView',
        'routingGroupCollection',
        'error',
        'entities/caccounts/routingaction',
        'entities/caccounts/routinggroup',
        'bootbox',
        'entities/caccounts/samples',
        'routingCommonHeaderView',
        'routingCommonSampleDetailView'
    ], function (
        App,
        module,
        Marionette,
        Backbone,
        _,
        $,
        DialogFormView,
        Tpl,
        RoutingGroupCollection,
        Error,
        RoutingActions,
        RoutingGroups,
        Bootbox,
        Samples,
        RoutingCommonHeaderView,
        RoutingCommonSampleDetailView
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: Tpl,

            ui: {
                inputSample: '.js-scan-sample-input',
                showSample: '.js-show-sample',
                backToList: '.js-back-to-list'
            },

            events: {
                'keydown @ui.inputSample': 'onScanSampleKeyDown',
                'click @ui.showSample': 'onShowSampleClick',
                'click @ui.backToList': 'onBackToListClick',
                'click @ui.sampleNotFoundButton': 'onValidProcessClick',
                'click @ui.sampleNotRegisteredButton': 'onValidProcessClick',
                'click @ui.sampleAlreadyRegisteredButton': 'onValidProcessClick',
                'click @ui.sampleWithoutLisAnaButton': 'onValidProcessClick',
                'click @ui.noRouteButton': 'onValidProcessClick',
                'click @ui.noDataSourceButton': 'onValidProcessClick',
                'click span.sample.clickable': 'onSampleClick'
            },

            regions: {
                errorRegion: '.js-error-region',
                plateViewRegion: '.js-plate-region',
                tracingRegion: '.js-tracing-region',
                headerRegion: '.js-header-region',
                sampleDetailRegion: '.js-sample-detail-region'
            },

            modelEvents: {
                'change': 'render'
            },

            className: 'selectRouting',

            initialize: function () {
                this.model = new Backbone.Model({
                    routingAction: this.options.routingAction,
                    i18nCode: this.options.type,
                    sampleIdToScan: '',
                    sample: Samples.getModel(),
                    logs: new Backbone.Collection()
                });

            },

            onBackToListClick: function () {
                App.navigate('routing', {trigger: true});
            },

            onShowSampleClick: function () {
                this.showSample($('.js-current-sample-input').val());
            },

            onSampleClick: function (e) {
                this.showSample(e.currentTarget.attributes['data-sample'].value);
            },

            showSample: function (sampleId) {
                $('.js-global-loader').show();
                Samples.findBySample(sampleId).done(_.bind(function (sampleModel) {
                    if (!sampleModel.get('secId')) {
                        Bootbox.alert(_.i18n('quickSearch.sample.notFound'));
                    } else {
                        require(['samplesController'], _.bind(function (Controller) {
                            Controller.showDetails(sampleModel);
                        }, this));
                    }
                }, this))
                    .always(function () {
                        $('.js-global-loader').hide();
                    });
            },

            refreshLogDisplay: function () {
                this.getRegion('tracingRegion').show(new Marionette.CollectionView({
                    collection: this.model.get('logs'),
                    attributes: {
                        'style': 'border: 1px solid #333; overflow-y: scroll; resize: vertical; height: 510px;background-color: #f5f5f5;'
                    },
                    childView: Marionette.ItemView.extend({
                        template: _.template('<%= message %>')
                    })
                }));
            },

            onScanSampleKeyDown: function (e) {
                if ((e.keyCode === 13 || e.keyCode === 9) && this.ui.inputSample.val()) {
                    e.preventDefault();
                    e.stopPropagation();
                    $('.js-global-loader').show();
                    this.getRegion('errorRegion').empty();
                    var sampleId = this.ui.inputSample.val();
                    $('.js-current-sample-input').val(sampleId);
                    this.ui.inputSample.val('');
                    this.refreshRoutingGroupsView();
                    RoutingGroups.findByRoutingActionAndSampleId(this.options.routingAction.get('secId'), sampleId, this.model.get('routingMode'))
                        .done(_.bind(function (result) {
                            this.focusSampleInput();
                            this.model.set('sample', Samples.getModel(result.sample));
                            if (result.state) {
                                var view = new Error({
                                    model: this.options.routingAction.get('states').findWhere({secId: result.state.secId})
                                });
                                this.getRegion('errorRegion').show(view);

                                var viewPopup = new Error({
                                    model: this.options.routingAction.get('states').findWhere({secId: result.state.secId}),
                                    closePopup: true
                                });
                                viewPopup.show({
                                    title: _.i18n('routingAction.error')
                                });
                                this.listenTo(view, 'onProcessValidClick', _.bind(this.onValidProcessClick, this));
                                this.listenTo(viewPopup, 'onProcessValidClick', _.bind(this.onValidProcessClickFromPopup, this));
                                setTimeout(_.bind(function () {
                                    viewPopup.hide();
                                }, this), 2000);
                                if (result.state.showPopup) {
                                    this.ui.inputSample.attr('disabled', 'disabled');
                                }
                                switch (result.state.state) {
                                    case 'ERROR':
                                        this.addMessageLog(result.state.state, 'error', sampleId, null, true, result.sample);
                                        break;
                                    case 'NO_DATASOURCE':
                                        this.addMessageLog(result.state.state, 'noDatasource', sampleId);
                                        break;
                                    case 'SAMPLE_NOT_FOUND':
                                        this.addMessageLog(result.state.state, 'sampleNotFound', sampleId);
                                        break;
                                    case 'SAMPLECLASS_NOT_FOUND':
                                        this.addMessageLog(result.state.state, 'sampleClassNotFound', sampleId);
                                        break;
                                    case 'SAMPLE_NOT_REGISTERED':
                                        this.addMessageLog(result.state.state, 'sampleNotRegistered', sampleId);
                                        break;
                                    case 'SAMPLE_ALREADY_REGISTERED':
                                        this.addMessageLog(result.state.state, 'sampleAlreadyRegistered', sampleId, null, true);
                                        break;
                                    case 'SAMPLE_WITHOUT_LISANA':
                                        this.addMessageLog(result.state.state, 'sampleWithoutLisAna', sampleId, null, true);
                                        break;
                                    case 'NO_ROUTE':
                                        this.addMessageLog(result.state.state, 'noRoute', sampleId, null, true);
                                        break;
                                }
                            } else {
                                this.routingGroupCollection.select(result.routingGroupFromRoutingAction.secId);
                                this.addMessageLog('sampleRouted', 'sampleRouted', sampleId, result.routingGroupFromRoutingAction, true, Samples.getModel(result.sample));
                                this.ui.showSample.css('display', 'block');
                                if (result.routingGroupFromRoutingAction.showPopup) {
                                    this.ui.inputSample.attr('disabled', 'disabled');
                                }
                            }
                        }, this))
                        .always(_.bind(function () {
                                $('.js-global-loader').hide();
                                this.focusSampleInput();
                            }, this)
                        );
                }
            },

            onValidProcessClick: function () {
                this.getRegion('errorRegion').empty();
                this.ui.inputSample.removeAttr('disabled');
                this.focusSampleInput();
            },

            onValidProcessClickFromPopup: function () {
                this.onValidProcessClick();
            },

            refreshRoutingGroupsView: function () {
                this.routingGroupCollection.clearSelection();
                this.ui.showSample.css('display', 'none');
            },

            resetSampleInput: function () {
                this.ui.inputSample.val('');
                this.ui.inputSample.focus();
            },

            focusSampleInput: function () {
                this.ui.inputSample.focus();
            },

            onRender: function () {
                this.getRegion('headerRegion').show(new RoutingCommonHeaderView({
                    model: new Backbone.Model({
                        code: this.options.routingAction.get('code'),
                        name: this.options.routingAction.get('name'),
                        i18nCode: this.model.get('i18nCode')
                    })
                }));
                this.getRegion('sampleDetailRegion').show(new RoutingCommonSampleDetailView({
                    model: this.model.get('sample'),
                    i18nCode: this.model.get('i18nCode')
                }));
                this.resetSampleInput();
                var maxLine = this.options.routingAction.get('plateSize') ? String.fromCharCode(this.options.routingAction.get('plateSize').charCodeAt(0)).toUpperCase().charCodeAt(0) - 64 : 0;
                var maxByLine = this.options.routingAction.get('plateSize') ? parseInt(this.options.routingAction.get('plateSize').substring(1), 10) : 0;
                var collection = new Backbone.Collection();
                collection.comparator = 'position';
                for (var i = 0; i < maxLine; i++) {
                    for (var j = 0; j < maxByLine; j++) {
                        var position = String.fromCharCode(65 + i) + (j + 1).toString().padStart(2, '0');
                        var model = this.options.routingAction.get('routingGroups').findWhere({position: position});
                        if (model) {
                            collection.push(new Backbone.Model({
                                secId: model.get('secId'),
                                code: model.get('code'),
                                routingGroup: model.get('refRoutingGroup'),
                                position: position,
                                rackId: model.get('rackId'),
                                showPopup: model.get('showPopup'),
                                color: model.get('color'),
                                preProcessing: model.get('refRoutingGroup') ? model.get('refRoutingGroup').get('preProcessing') : false,
                                routingActionRoutingGroup: model
                            }));
                            continue;
                        }
                        collection.push(new Backbone.Model({
                            secId: null,
                            code: null,
                            routingGroup: new Backbone.Model({code: null, name: null}),
                            position: position,
                            rackId: null,
                            showPopup: null,
                            preProcessing: false
                        }));
                    }
                }
                this.routingGroupCollection = new RoutingGroupCollection({
                    collection: collection,
                    settingMode: this.options.setting,
                    countByLine: this.options.routingAction.get('plateSize') ? parseInt(this.options.routingAction.get('plateSize').substring(1), 10) : 0
                });
                this.getRegion('plateViewRegion').show(this.routingGroupCollection);
                this.listenTo(this.routingGroupCollection, 'onProcessValidClick', _.bind(this.onValidProcessClick, this));
                this.refreshRoutingGroupsView();
                this.refreshLogDisplay();
            },

            addMessageLog: function (state, type, sampleId, action, sampleExisted, sample) {
                var message = '<div class="col-xs-12 sample-log-message" title="' + state + '"><span class="sample ' + (sampleExisted ? 'clickable' : '') +
                    '" data-sample="' + sampleId + '">' + (sampleExisted ? '<u>' : '') + sampleId + (sampleExisted ? '</u>' : '') +
                    '</span>: <span class="content ' + type + '">';
                switch (type) {
                    case 'noDatasource':
                        message += _.i18n('routingAction.noDatasource');
                        break;
                    case 'sampleNotFound':
                        message += _.i18n('sample.not.found');
                        break;
                    case 'sampleRouted':
                        var color = action.color;
                        if (!color) {
                            color = '#e0fbff';
                        }
                        color = color.slice(0, 7) + '40';
                        message += '<span style="background-color: ' + color.slice(0, 7) + '40' + '; color: black;">' + action.refRoutingGroup.name + ' (' + action.refRoutingGroup.code + ') <br/>' + _.i18n('assayGroup.condition') + ' : (' + action.refRoutingGroup.condition + ')<br/>' +
                            _.i18n('assayGroup.name') + ' : ' +
                            sample.get('assayGroups').map(function (model) {
                                return model.get('code');
                            }).join(', ') + '</span>';
                        break;
                    case 'noRoute':
                        message += _.i18n('sampleRouting.noRoute');
                        break;
                    case 'error':
                        message += _.i18n('sampleRouting.error') + '<br\>';
                        message += sample.get('responseQuery').replace('\n', '<br\>');
                        break;
                    case 'noActionSelected':
                        message += _.i18n('routing.action.not.selected');
                        break;
                    case 'sampleNotRegistered':
                        message += _.i18n('sampleRouting.sampleNotYetRegistered');
                        break;
                    case 'sampleAlreadyRegistered':
                        message += _.i18n('sampleRouting.sampleAlreadyRegistered');
                        break;
                    case 'sampleClassNotFound':
                        message += _.i18n('sampleRouting.sampleClassNotFound');
                        break;
                    case 'sampleWithoutLisAna':
                        message += _.i18n('sampleRouting.sampleWithoutLisAna');
                        break;
                }
                message += '</span></div>';

                var routing = null;
                if (action && action && action.secId) {
                    routing = this.options.routingAction.get('routingGroups').findWhere({secId: action.secId});
                }
                this.model.get('logs').push(new Backbone.Model({
                    message: message,
                    created: new Date(),
                    sample: sample,
                    routingGroup: routing
                }), {at: 0});
            }
        });
    }
);
