define('pcrWellResultCurveItemView',[
    'module',
    'marionette',
    'backbone',
    'template!pcrWellResultCurveItemView',
    'underscore',
    'jquery',
    'editWellView',
    'wellPopupDisplayGraphAmpView',
    'bootbox',
    'wellPopupDisplayGraphView',
    'entities/caccounts/mbanaresgrs'
], function (
    module,
    Marionette,
    Backbone,
    Tpl,
    _,
    $,
    EditWellView,
    DisplayPopupGraphAmpView,
    Bootbox,
    DisplayPopupGraphLayoutView
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'click .btn-validate-result': 'onConfirmResult',
            'click .btn-negative-result': 'onNegativeResult',
            'click .btn-edit-well-target-result': 'onEditWellTargetResult',
            'click .js-target-propC-popup': 'onTargetPropC',
            'click .btn-graph-well': 'onBtnGraphClick'
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        attributes: {
            style: 'display: flex;'
        },

        className: 'result-curve',

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        onTargetPropC: function () {
            var graphToDisplay = this.model.get('refPcrWell').getGraphToDisplay();

            if (graphToDisplay.includes('A') || graphToDisplay.includes('M')) {
                this.propCPopupView = new DisplayPopupGraphAmpView({
                    model: this.model,
                    graphToDisplay: graphToDisplay
                });
                this.propCPopupView.show({
                    title: _.i18n('wellTarget.result.interpretation.' + graphToDisplay),
                    className: 'js-draggable-modal wellTargetPropC-popup'
                });
                $('.js-draggable-modal  .modal-dialog').draggable({
                    handle: '.modal-header'
                });
            }
        },

        serializeData: function () {
            var templateData = this.model.getResultIcon();
            templateData = _.extend(templateData, {
                dyeToDisplay: this.model.get('dyeToDisplay'),
                dyeColorToDisplay: this.model.get('dyeColorToDisplay'),
                result: this.model.get('result') ? this.model.get('result').get('code') : undefined,
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : undefined,
                refPcrWellExported: this.model.get('refPcrWell') ? this.model.get('refPcrWell').get('exported') : false
            });
            if (templateData.valSt === 5) {
                templateData.repeatAllowed = false;
                // Avoid repeat (when well's exported status are inconsistant)
            }

            templateData.allowToNeg = templateData.resCode !== 'N' && templateData.propC && this.model.get('codeErr') ? Object.keys(templateData.propC).some(function (k) {
                return ~k.indexOf('Amp');
            }) : false;
            templateData.propC = this.model.getPropCFormated();

            templateData.userVal1Code = '';
            templateData.userVal1Title = '';
            if (this.model.get('refValUser')) {
                templateData.userVal1Code = this.model.get('refValUser').get('nickName');
                if (this.model.get('refValUser').get('email')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('email');
                }
                if (templateData.userVal1Code === '' && templateData.userVal1Title) {
                    templateData.userVal1Code = templateData.userVal1Title;
                }
                if (this.model.get('refValUser').get('firstName') && this.model.get('refValUser').get('lastName')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('firstName') + ' ' + this.model.get('refValUser').get('lastName');
                }
            }
            templateData.userVal2Code = '';
            templateData.userVal2Title = '';
            if (this.model.get('refValUser2')) {
                templateData.userVal2Code = this.model.get('refValUser2').get('nickName');
                if (this.model.get('refValUser2').get('email')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('email');
                }
                if (templateData.userVal2Code === '' && templateData.userVal2Title) {
                    templateData.userVal2Code = templateData.userVal2Title;
                }
                if (this.model.get('refValUser2').get('firstName') && this.model.get('refValUser2').get('lastName')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('firstName') + ' ' + this.model.get('refValUser2').get('lastName');
                }
            }
            templateData.readOnly = this.model.isReadOnly();
            templateData.needValidation = this.model.get('needValidation');
            templateData.showWellCompareGraph = false;

            if (this.model.get('result') && this.model.get('curves').any(function (curve) {
                return curve.get('type') === 'AMP' || curve.get('type') === 'MUTV' || curve.get('type') === 'MELT';
            })) {
                templateData.showWellCompareGraph = true;
            }

            templateData.errorMessage = '';
            if(templateData.codeErr) {
                templateData.errorMessage = templateData.codeErr + ' ';
            }
            if(templateData.justificatory) {
                templateData.errorMessage = templateData.justificatory;
            }

            return templateData;
        },

        onConfirmResult: function () {
            var defer = $.Deferred();
            var isControl = ['RC', 'PC', 'OC', 'NC'].includes(this.model.get('refPcrWell').get('smpType'));
            if (isControl) {
                Bootbox.prompt(_.i18n('pcrWellResult.confirm.justificatory'), _.bind(function (justificatory) {
                    defer.resolve(justificatory);
                }, this));
            } else {
                defer.resolve();
            }
            defer.done(_.bind(function (justificatory) {
                if ((isControl && justificatory) || !isControl) {
                    $('.js-global-loader').show();
                    this.model.confirmResult(justificatory)
                        .done(_.bind(function () {
                            // needed to trigger the graphic refresh on render in 'wellDetailView -> renderSafe -> refreshView'
                            this.model.get('refPcrWell').trigger('change', this.model.get('refPcrWell'));
                        }, this))
                        .always(_.bind(function () {
                            $('.js-global-loader').hide();
                        }, this));
                }
            }, this));
        },

        onNegativeResult: function () {
            var defer = $.Deferred();
            var isControl = ['RC', 'PC', 'OC', 'NC'].includes(this.model.get('refPcrWell').get('smpType'));
            if (isControl) {
                Bootbox.prompt(_.i18n('pcrWellResult.negative.justificatory'), _.bind(function (justificatory) {
                    defer.resolve(justificatory);
                }, this));
            } else {
                defer.resolve();
            }
            defer.done(_.bind(function (justificatory) {
                if ((isControl && justificatory) || !isControl) {
                    $('.js-global-loader').show();
                    this.model.negativeResult(justificatory)
                        .always(_.bind(function () {
                            $('.js-global-loader').hide();
                        }, this));
                }
            }, this));
        },

        onEditWellTargetResult: function () {
            var createEditView = new EditWellView({
                model: this.model.get('refPcrWell'),
                modelResult: this.model,
                wellResColor: this.wellResColor,
                newItem: false
            });
            createEditView.show({
                title: _.i18n('well.info.edit'),
                className: 'popupWellView'
            });
        },

        onBtnGraphClick: function () {
            var collection = new Backbone.Collection();
            this.model.get('refPcrWell').get('refPcrRun').get('wells').each(_.bind(function (well) {
                well.get('results').filter(_.bind(function (result) {
                    if (result.get('refAssayResult').get('id') === this.model.get('refAssayResult').get('id')) {
                        collection.push(result);
                    }
                }, this));
            }, this));


            this.displayGraphView = new DisplayPopupGraphLayoutView({
                model: this.model,
                collection: collection,
                currentTarget: null,
                models: collection,
                newItem: false
            });

            this.displayGraphView.graphToDisplay = this.model.get('refPcrWell').getGraphToDisplay();
            this.displayGraphView.hideOkButton = true;
            this.displayGraphView.hideNextPreviousButton = true;
            this.displayGraphView.selectionMode = false;
            this.displayGraphView.show({
                title: _.i18n('well.compare.curv'),
                className: 'popupWellDetailsGraph'
            });
        }
    });
});
