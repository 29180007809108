
define('template!prepRunWellMultiDisplayTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem noWrapHidden ' +
((__t = ( error ? 'has-error' : '')) == null ? '' : __t) +
' ">\n\t<!-- plateView-wellBackground -->\n\t<div class="prepRunWellGrid col-xs-12 p-r-0 p-l-0 ' +
((__t = ( validatedProgess ? 'watermark-progress': '' )) == null ? '' : __t) +
'"\n\t\t style="height: 100%; background-color: ' +
((__t = ( backgroundColor )) == null ? '' : __t) +
';">\n\t\t';
 if(otherWell) { ;
__p += '\n\t\t<div style="height: 20px; width: 20px; top: 0; right: 0; z-index: 10;\n\t\tposition: absolute;">\n\t\t\t';
 if(otherWell === 'repeat') { ;
__p += '\n\t\t\t<div class="repeatStatus repeat-' +
((__t = ( repeatStatus )) == null ? '' : __t) +
'"></div>\n\t\t\t';
 } else if(otherWell === 'other') { ;
__p += '\n\t\t\t<div class="repeatStatus repeat-other"></div>\n\t\t\t';
 } ;
__p += '\n\t\t</div>\n\t\t';
 } ;
__p += '\n\t\t<div class="rightSideAbsolute">\n\t\t\t<div class="btn-group-vertical">\n\t\t\t\t<button class="btn prepRunWellKitLotBtn js-btn-kitLot">\n\t\t\t\t\t<span class="mdi mdi-flask-outline"></span>\n\t\t\t\t</button>\n\t\t\t\t';
 if(well.smpType === 'U') { ;
__p += '\n\t\t\t\t<button class="btn prepRunsBtn js-btn-sample">S</button>\n\t\t\t\t<button class="btn prepRunsBtn js-btn-comment">C</button>\n\t\t\t\t';
 } ;
__p += '\n\t\t\t</div>\n\t\t</div>\n\t\t<span>\n            <div class="bioGroupColorBar" style="height: 5px; width: 100%; position: absolute;"></div>\n\t\t\t<!-- background-color = bioGroup color from sample-------------------->\n        </span>\n\t\t<div class="plateView-icon-container" style="width: 100%; height: 100%;">\n\t\t\t';
 if (well.repeatStatus) { ;
__p += '\n\t\t\t<span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
' iconTop"></span>\n\t\t\t<span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
' iconBottom"></span>\n\t\t\t';
 } ;
__p += '\n\t\t\t<div class="col-xs-12 p-r-0 p-l-0\n                    ' +
((__t = ((well.repeat)?'repeatOpacity':'')) == null ? '' : __t) +
'\n                    " style="height: 100%;">\n\t\t\t\t<!-- repeatOpacity : classe nécessaire si on affiche une icone sur la cellule -->\n\t\t\t\t<row class=" col-xs-12 p-l-0 p-r-0" style="position:relative;">\n\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0" style="line-height: 17px;">\n\t\t\t\t\t\t';
 if (well.smpType === 'NC') { ;
__p += '\n\t\t\t\t\t\t<span class="mdi-content-remove-circle smpType-plateViewIcon"></span>\n\t\t\t\t\t\t';
 } else if(well.smpType === 'PC') { ;
__p += '\n\t\t\t\t\t\t<span class="mdi-content-add-circle smpType-plateViewIcon"></span>\n\t\t\t\t\t\t';
 } else if(well.smpType === 'RC') { ;
__p += '\n\t\t\t\t\t\t<span class="mdi rc-smpType mdi mdi-alpha-r-circle smpType-plateViewIcon"></span>\n\t\t\t\t\t\t';
 } else if(well.smpType === 'C') { ;
__p += '\n\t\t\t\t\t\t<span class="mdi mdi-alpha-c-circle smpType-plateViewIcon"></span>\n\t\t\t\t\t\t';
 } else if(well.smpType === 'OC') { ;
__p += '\n\t\t\t\t\t\t<span class="mdi mdi-compass smpType-plateViewIcon"></span>\n\t\t\t\t\t\t';
 } else if(well.smpType === 'D') { ;
__p += '\n\t\t\t\t\t\t<span class="mdi mdi-alpha-d-circle smpType-plateViewIcon"></span>\n\t\t\t\t\t\t';
 } else if(well.smpType === 'C') { ;
__p += '\n\t\t\t\t\t\t<span class="mdi mdi-alpha-c-circle smpType-plateViewIcon"></span>\n\t\t\t\t\t\t';
 } else if(well.smpType === 'P') { ;
__p += ' <!-- Pool -->\n\t\t\t\t\t\t<span class="pool-label mdi mdi-google-circles-communities smpType-plateViewIcon"></span>\n\t\t\t\t\t\t';
 } else {;
__p += '\n\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t\t';
 if(well.pooled) { ;
__p += ' <!-- pooled -->\n\t\t\t\t\t\t<span class="pooled-label mdi mdi-adjust smpType-plateViewIcon"></span>\n\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-10 p-l-0 p-r-0">\n\t\t\t\t\t\t<div class="js-well-sample"></div>\n\t\t\t\t\t</div>\n\t\t\t\t</row>\n\t\t\t\t<div style="position: relative;" class="m-t-20">\n\t\t\t\t\t<row class="col-xs-12 p-l-0 p-r-0 h-16 m-t-1" style="display: flex;">\n\t\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0">\n\t\t\t\t\t\t\t' +
((__t = ( assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-10 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="noWrapHidden cs-sample-font" style="font-size: 15px; bottom: 2px;">\n\t\t\t\t\t\t\t\t' +
((__t = ( assaysToDisplay.waiting )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</row>\n\t\t\t\t\t<row class="col-xs-12 p-l-0 p-r-0 h-16 m-t-1" style="display: flex;">\n\t\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0">\n\t\t\t\t\t\t\t' +
((__t = ( assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-10 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="noWrapHidden cs-sample-font" style="font-size: 15px; bottom: 2px;">\n\t\t\t\t\t\t\t\t' +
((__t = ( assaysToDisplay.running )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</row>\n\t\t\t\t\t<row class="col-xs-12 p-l-0 p-r-0 h-16 m-t-1" style="display: flex;">\n\t\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0">\n\t\t\t\t\t\t\t' +
((__t = ( assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-10 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="noWrapHidden cs-sample-font" style="font-size: 15px; bottom: 2px;">\n\t\t\t\t\t\t\t\t' +
((__t = ( assaysToDisplay.done )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</row>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

