
define('template!prepRunTemplatesCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="row hidden">\n\t\t\t\t<!----------------- pcr prep run templates next/previous buttons not needed for now -->\n\t\t\t\t<div class="m-b-10 selectRunContainer ' +
((__t = ( newItem? 'hidden' : '' )) == null ? '' : __t) +
'"\n\t\t\t\t\t style="background-color: transparent; text-align: center; padding-left: 24px;">\n\t\t\t\t\t<div style="display: inline-flex;">\n\t\t\t\t\t\t<div class="js-show-previous-run clickable" style="width: 30px;">\n\t\t\t\t\t\t\t<span class="mdi mdi-chevron-left" style=" position: relative;bottom: 7px;"></span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t';
 _.each(selectedRunsList, function(selectedRun){ ;
__p += '\n\t\t\t\t\t\t';
 var str = $(selectedRun.code).text() ;
__p += ' <!-- ou var str = $(selectedRun.code).text()-->\n\t\t\t\t\t\t<div class="clickable js-show-selected-run headerNavRunItem\n                                ';
 if(selectedRun.secId == secId) {;
__p += '\n                                current-Run\n                                ';
 } ;
__p += '"\n\t\t\t\t\t\t\t secId="' +
((__t = ( selectedRun.secId )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t' +
((__t = ( (str.length > 8)? '..' + str.substr(-8) : str )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t\t\t<div class="js-show-next-run clickable" style="width: 30px;">\n\t\t\t\t\t\t\t<span class="mdi mdi-chevron-right" style=" position: relative;bottom: 7px;"></span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="code">\n\t\t\t\t\t\t<label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('prepRun.runTemplate') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-1">\n\t\t\t\t\t<div class="form-group" data-field-name="code">\n\t\t\t\t\t\t<input type="text"\n\t\t\t\t\t\t\t   class="form-control floating-label js-info-input js-code"\n\t\t\t\t\t\t\t   data-field-name="code" placeholder=""\n\t\t\t\t\t\t\t   value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content=""\n\t\t\t\t\t\t\t   data-placement="top" autocomplete="off">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t<div class="" data-field-name="name">\n\t\t\t\t\t\t<input type="text"\n\t\t\t\t\t\t\t   class="form-control floating-label js-info-input"\n\t\t\t\t\t\t\t   data-field-name="name" placeholder=""\n\t\t\t\t\t\t\t   value="' +
((__t = ( name )) == null ? '' : __t) +
'" autocomplete="off">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class="col-xs-1 dataLabelContainer p-l-0 p-r-0 noWrapHidden">\n\t\t\t\t\t<div class="form-group" data-field-name="seq">\n\t\t\t\t\t\t<label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('seq') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class="col-xs-1">\n\t\t\t\t\t<div class="" data-field-name="seq">\n\t\t\t\t\t\t<input type="text"\n\t\t\t\t\t\t\t   class="form-control floating-label js-info-input"\n\t\t\t\t\t\t\t   data-field-name="seq" placeholder=""\n\t\t\t\t\t\t\t   value="' +
((__t = ( seq ? seq : '' )) == null ? '' : __t) +
'" autocomplete="off">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\n\t\t\t<div class="row pcrGroup">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="pattern">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('prepRun.runName.pattern')
							)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t<div id="prepRunTemplatePatternInput" class="js-selectPattern"></div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t';
 if(!newItem){ ;
__p += '\n\t\t\t\t\t<div style="text-align: right;">\n\t\t\t\t\t\t<button class="btn btn-primary js-define-plateLayout"\n\t\t\t\t\t\t\t\tstyle="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px;">' +
((__t = (
							_.i18n('prepRun.define.PlateLayout') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="department">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('prepRun.runName.department')
							)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4 p-l-0">\n\t\t\t\t\t<div class="js-selectDepartment"></div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t';
 if(!newItem){ ;
__p += '\n\t\t\t\t\t<div style="text-align: right;">\n\t\t\t\t\t\t<button class="btn btn-primary js-assign-assay"\n\t\t\t\t\t\t\t\tstyle="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
							_.i18n('pcr.platelayout.assign.assay') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="group">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('prepRun.runName.rungroup')
							)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4 p-l-0">\n\t\t\t\t\t<div class="js-selectRunGroup"></div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t';
 if(!newItem){ ;
__p += '\n\t\t\t\t\t<div style="text-align: right;">\n\t\t\t\t\t\t<button class="btn btn-primary js-remove-assay"\n\t\t\t\t\t\t\t\tstyle="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
							_.i18n('pcr.platelayout.remove.assay') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="sequenceFormat">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('prepRun.runName.sequenceFormat')
							)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4 p-l-0">\n\t\t\t\t\t<div class="js-selectSequenceFormat"></div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t';
 if(!newItem){ ;
__p += '\n\t\t\t\t\t<div style="text-align: right;">\n\t\t\t\t\t\t<button class="btn btn-primary js-generate-smpids"\n\t\t\t\t\t\t\t\tstyle="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
							_.i18n('pcr.platelayout.generate.smpId') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group js-info-input" data-field-name="plateSize">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('plateSize') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4 p-l-0" data-field-name="plateSize">\n\t\t\t\t\t';
 if(newItem) { ;
__p += '\n\t\t\t\t\t<div class="js-selectPlateSize" style="width: 75px; margin: 0;"></div>\n\t\t\t\t\t';
 } else { ;
__p += '\n\t\t\t\t\t<span>' +
((__t = ( plateSize )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t';
 if(!newItem){ ;
__p += '\n\t\t\t\t\t<div style="text-align: right;">\n\t\t\t\t\t\t<button class="btn btn-primary js-remove-smpids"\n\t\t\t\t\t\t\t\tstyle="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
							_.i18n('pcr.platelayout.remove.smpId') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="pool">\n\t\t\t\t\t\t<label for="pool" class="control-label dataLabel">' +
((__t = ( _.i18n('runTemplate.pool') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t<div class="form-group">\n\t\t\t\t\t\t<input style="width: 20px; height: 20px; margin: 0;" type="checkbox"\n\t\t\t\t\t\t\t   class="js-pool js-info-input" data-field-name="pool"\n\t\t\t\t\t\t\t   id="pool"\n\t\t\t\t\t\t\t   name="pool" ' +
((__t = ( pool? 'checked' : '' )) == null ? '' : __t) +
'>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t';
 if(!newItem){ ;
__p += '\n\t\t\t\t\t<div style="text-align: right;">\n\t\t\t\t\t\t<button class="btn btn-primary js-add-test"\n\t\t\t\t\t\t\t\tstyle="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = ( _.i18n('pcr.platelayout.add.test')
							)) == null ? '' : __t) +
'\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="printRunSheet">\n\t\t\t\t\t\t<label for="printRunSheet" class="control-label dataLabel">' +
((__t = ( _.i18n('pcrRun.printDef')
							)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t<div class="form-group" data-field-name="printRunSheet">\n\t\t\t\t\t\t<input style="width: 20px; height: 20px; margin: 0;" type="checkbox"\n\t\t\t\t\t\t\t   class="js-printRunSheet"\n\t\t\t\t\t\t\t   id="printRunSheet"\n\t\t\t\t\t\t\t   name="printRunSheet" ' +
((__t = ( printRunSheet? 'checked' : '' )) == null ? '' : __t) +
'>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t';
 if(!newItem){ ;
__p += '\n\t\t\t\t\t<div style="text-align: right;">\n\t\t\t\t\t\t<button class="btn btn-primary js-remove-test"\n\t\t\t\t\t\t\t\tstyle="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
							_.i18n('pcr.platelayout.remove.test') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="js-description m-t-5 m-b-5"></div>\n\t\t\t<div class="row cancelConfirmRow">\n\t\t\t\t<div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n\t\t\t\t\t<button disabled\n\t\t\t\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>\n</div>';

}
return __p
};});

