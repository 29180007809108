define('entities/caccounts/pcrSetup/pcrWellLite',[
	'app',
	'backbone',
	'module',
	'backboneRelational',
	'entities/caccounts/assay'
], function (
	App,
	Backbone,
	module
) {
	'use strict';
	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.PcrWellLite = Backbone.RelationalModel.extend({
		defaults: {
			'valid': false,
			'sample': {},
			'sampleType': undefined,
			'assay': undefined,
			'type': undefined,
			'refPrepWell': undefined
		},
		relations: [{
			type: Backbone.HasOne,
			key: 'assay',
			relatedModel: 'Assay'
		}, {
			type: Backbone.HasOne,
			key: 'refPrepWell',
			relatedModel: 'PrepWellLite'
		}],
		idAttribute: 'secId',
		getLine: function () {
			if (this.get('type')) {
				return undefined;
			}
			if(!this.get('pos')) {
				return '';
			}
			return this.get('pos').substring(0, 1);
		},
		getColumn: function () {
			if (this.get('type')) {
				return undefined;
			}
			if(!this.get('pos')) {
				return '';
			}
			return parseInt(this.get('pos').substring(1, 3), 10);
		}
	});


	app.ns.PcrWellLiteCollection = Backbone.Collection.extend({
		model: app.ns.PcrWellLite,
		comparator: function (a, b) {
			var aCol = Number.parseInt(a.get('pos').substring(1));
			var bCol = Number.parseInt(b.get('pos').substring(1));
			var aRow = a.get('pos').charCodeAt(0);
			var bRow = b.get('pos').charCodeAt(0);
			if (aCol === bCol) {
				if (aRow === bRow) {
					return 0;
				}
				if (aRow < bRow) {
					return -1;
				}
				return 1;
			}
			if (aCol < bCol) {
				return -1;
			}
			return 1;
		}
	});

	module.exports = {
		create: function (params) {
			if(!params) {
				params = {};
			}
			return new app.ns.PcrWellLite(params);
		}
	};
});
