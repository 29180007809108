/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemDate',[
    'module',
    'underscore',
    'dialogFormView',
    'moment'
], function (
    module,
    _,
    DialogFormView,
    moment
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template(''),
        tagName: 'input',
        className: function () {
            var ret = 'form-control js-datePicker inputBackground';
            if (!this.options.display || !this.options.display.readOnly) {
                ret += ' ';
            }
            return ret;
        },
        attributes: function () {
            this.readOnly = this.options.field.param && this.options.field.param.display && this.options.field.param.display.readonly;
            if (this.readOnly) {
                return {
                    type: 'text',
                    'data-field-name': this.options.field.field + '_date',
                    value: this.model.get(this.options.field.field) ? moment(this.model.get(this.options.field.field)).format('DD-MM-YYYY') : '',
                    disabled: true,
                    readonly: true,
                    'class': 'form-control js-datePicker inputBackground disabled'
                };
            }
            return {
                type: 'datepicker',
                'data-field-name': this.options.field.field + '_date',
                value: this.model.get(this.options.field.field) ? moment(this.model.get(this.options.field.field)).format('DD-MM-YYYY') : ''
            };
        },
        events: {
            'change': 'onChangeDate'
        },
        initialize: function () {
            this.listenTo(this, 'date:picker:change', _.bind(this.datePickerChange, this));
        },
        onChangeDate: function (e) {
            var fieldName = e.currentTarget.attributes['data-field-name'].value.replace('_date', '');
            this.onChange(fieldName, e.currentTarget.value ? moment(e.currentTarget.value, 'DD-MM-YYYY').format() : null);
        },
        onRender: function () {
            this.initDatePicker(this.$el, this.options.field.field, true);
            this.$el.val(this.model.get(this.options.field.field) ? moment(this.model.get(this.options.field.field)).format('DD-MM-YYYY') : '');
        },
        datePickerChange: function (fieldName) {
            if (this.options.field.field === fieldName) {
                this.render();
            }
        }
    });
});
