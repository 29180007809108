
define('template!prepRunWellEditSmpIdPlateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content info-page">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="m-b-10" style="text-align: center;">\n                <div class=""\n                     style="border: solid 1px black; border-radius: 5px; width: 500px; margin: 0 auto; display: flex; padding: 5px;">\n                    <div class="col-xs-9" style="font-size: 28px; text-align: center;">\n                        ' +
((__t = ( displayName )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="col-xs-3 p-l-30" style="display: flex; align-items: center;">\n                        ' +
((__t = ( status )) == null ? '' : __t) +
'\n                        ' +
((__t = ( statusIcon )) == null ? '' : __t) +
'\n                    </div>\n                </div>\n            </div>\n            <div class="row">\n                <div class="col-xs-1">\n                </div>\n                <!-- Enter sample-id\'s -->\n                <div class="col-md-2 col-sm-12 plateLayoutSetting-column p-l-5 p-r-5">\n                    <div class="groupContainer col-xs-12" style="    height: 158px;">\n                        <div class="labelContainer">\n                            <label class="enterEditPlateLayout-label popupTitleLabel">' +
((__t = ( _.i18n('run.startWellSmpid')
                                )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <!-- scan sample - start screen -->\n                        <div class="js-scan-samples-div">\n                            <div class="col-xs-12 columnContainer">\n                                <div class="col-xs-5 leftColumn"></div>\n                                <div class="col-xs-7 rightColumn"></div>\n                            </div>\n                            <div class="col-xs-12 enterEditPlateLayout-buttons p-l-0 p-r-0 js-scan-samples-btn">\n                                <button disabled\n                                        class="btn btn-primary disabled-grey"\n                                        style="">Scan Samples\n                                </button>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <!-- Control Sample Id -->\n                <div class="col-md-2 col-sm-12 plateLayoutSetting-column p-l-5 p-r-5">\n                    <div class="groupContainer col-xs-12">\n                        <div class="labelContainer">\n                            <label class="enterEditPlateLayout-label popupTitleLabel">' +
((__t = ( _.i18n('run.control.sampleId')
                                )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="col-xs-12 columnContainer">\n                            <div class="col-xs-5 leftColumn"></div>\n                            <div class="col-xs-7 rightColumn"></div>\n                        </div>\n                        <div class="col-sm-12 enterEditPlateLayout-buttons p-l-0 p-r-0">\n                            <button class="btn btn-primary disabled-grey js-ctrl-sampleId"\n                                    style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( _.i18n('run.ctrl') )) == null ? '' : __t) +
'\n                            </button>\n                        </div>\n                    </div>\n                </div>\n                <!-- import/export -->\n                <div class="col-md-2 col-sm-12 plateLayoutSetting-column p-l-5 p-r-5">\n                    <div class="groupContainer col-xs-12">\n\n                        <div class="labelContainer">\n                            <label class="enterEditPlateLayout-label popupTitleLabel">' +
((__t = (
                                _.i18n('run.import-export.plateLayout')
                                )) == null ? '' : __t) +
' </label>\n                        </div>\n                        <div class="col-xs-12 columnContainer">\n                            <div class="col-xs-6 leftColumn">\n                                <label class="fileFormatLabel noWrapHidden">' +
((__t = ( _.i18n('kitp.refFileFormat')
                                    )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="col-xs-6 rightColumn">\n                                <div class="js-select-fileFormat-region selectFileFormatDropDown"></div>\n                            </div>\n                        </div>\n                        <div class="col-xs-12 p-l-0 p-r-0 enterEditPlateLayout-buttons" style="display: flex;">\n                            <button class="col-xs-6 btn btn-primary disabled-grey import-plate-layout-button noWrapHidden">\n                                ' +
((__t = (
                                _.i18n('common.import') )) == null ? '' : __t) +
'\n                            </button>\n                            <button class="col-xs-6 btn btn-primary disabled-grey export-plate-layout-button noWrapHidden">\n                                ' +
((__t = (
                                _.i18n('common.export') )) == null ? '' : __t) +
'\n                            </button>\n                        </div>\n                    </div>\n                </div>\n                <!-- generate sample id -->\n                <div class="col-md-2 col-sm-12 plateLayoutSetting-column p-l-5 p-r-5">\n                    <div class="groupContainer col-xs-12">\n\n                        <div class="labelContainer">\n                            <label class="enterEditPlateLayout-label popupTitleLabel" for="startWithNB">' +
((__t = (
                                _.i18n('run.sampleId.generate.label') )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="col-xs-12 columnContainer">\n                            <div class="col-xs-5 leftColumn">\n                                <label class="h-22 startWithNbLabel noWrapHidden" for="startWithNB">' +
((__t = (
                                    _.i18n('run.sampleId.generate.labelStart') )) == null ? '' : __t) +
'</label>\n                                <label class="h-22 sampleFromLabel noWrapHidden" for="sampleFrom">' +
((__t = (
                                    _.i18n('run.assignAssay.from')
                                    )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="col-xs-7 rightColumn">\n                                <div class="h-22">\n                                    <input type="text"\n                                           class="js-info-input js-generate-StartWith-sampleId startWithNbInput"\n                                           id="startWithNB" name="nb">\n                                </div>\n                                <div class="h-22 dsp-cont">\n                                    <input type="text"\n                                           class="js-info-input js-generate-sample-id-fromWell sampleIdInputFrom"\n                                           id="sampleFrom"\n                                           value="A01"\n                                           data-placement="top"\n                                           pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n                                           onkeyup="this.value = this.value.toUpperCase();">\n                                </div>\n                                <label for="sampleIdTo">' +
((__t = ( _.i18n('run.assignAssay.to') )) == null ? '' : __t) +
'</label>\n                                <div class="h-22 dsp-cont">\n                                    <input type="text"\n                                           class="js-info-input js-generate-sample-id-toWell sampleIdInputTo"\n                                           id="sampleIdTo"\n                                           value="' +
((__t = ( plateSize )) == null ? '' : __t) +
'"\n                                           data-placement="top"\n                                           pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n                                           onkeyup="this.value = this.value.toUpperCase();">\n                                </div>\n                            </div>\n                        </div>\n                        <div class="col-xs-12 p-l-0 p-r-0 enterEditPlateLayout-buttons" style="display: flex;">\n                            <button class="col-xs-6 btn btn-primary disabled-grey js-generate-sample-id noWrapHidden"\n                                    style="padding-left: 15px; padding-right: 15px;">' +
((__t = (
                                _.i18n('run.generateSampleId.action') )) == null ? '' : __t) +
'\n                            </button>\n                            <button class="col-xs-6 btn btn-primary disabled-grey js-clear-sample-id noWrapHidden"\n                                    style="padding-left: 15px; padding-right: 15px;">' +
((__t = (
                                _.i18n('plateLayout.clear') )) == null ? '' : __t) +
'\n                            </button>\n                        </div>\n                    </div>\n                </div>\n                <!-- add Assay -->\n                <div class="col-md-2 col-sm-12 plateLayoutSetting-column p-l-5 p-r-5">\n                    <div class="groupContainer col-xs-12">\n                        <div class="labelContainer">\n                            <label class="enterEditPlateLayout-label popupTitleLabel">' +
((__t = ( _.i18n('run.addAssay') )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="col-xs-12 columnContainer">\n                            <div class="col-xs-5 leftColumn">\n                                <label class="h-22 assaySelectLabel noWrapHidden" for="assay">' +
((__t = ( _.i18n('run.assay')
                                    )) == null ? '' : __t) +
'</label>\n                                <label class="h-22 assayFromLabel noWrapHidden" for="assayFrom">' +
((__t = (
                                    _.i18n('run.add.assay.from') )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="col-xs-7 rightColumn">\n                                <div class="js-select-assay-region selectAssayDropDown" id="assay"></div>\n                                <div class="h-22 dsp-cont">\n                                    <input type="text" class=" js-info-input js-input-from-well assayInputFrom"\n                                           id="assayFrom"\n                                           value="' +
((__t = ( from )) == null ? '' : __t) +
'"\n                                           pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n                                           data-placement="top"\n                                           onkeyup="this.value = this.value.toUpperCase();">\n                                </div>\n                                <label id="assayLabelTo" for="assayTo">' +
((__t = ( _.i18n('run.add.assay.to') )) == null ? '' : __t) +
'</label>\n                                <div class="dsp-cont">\n                                    <input type="text" class="js-info-input js-input-to-well assayInputTo"\n                                           id="assayTo"\n                                           value="' +
((__t = ( plateSize )) == null ? '' : __t) +
'"\n                                           pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n                                           data-placement="top"\n                                           onkeyup="this.value = this.value.toUpperCase();">\n                                </div>\n                            </div>\n                        </div>\n                        <div class="col-sm-12 p-l-0 p-r-0 enterEditPlateLayout-buttons" style="display: flex;">\n                            <button class="col-xs-6 btn btn-primary disabled-grey js-add-assay noWrapHidden">' +
((__t = (
                                _.i18n('run.assign') )) == null ? '' : __t) +
'\n                            </button>\n                            <button class="col-xs-6 btn btn-primary disabled-grey js-clear-assay noWrapHidden">' +
((__t = (
                                _.i18n('plateLayout.clear') )) == null ? '' : __t) +
'\n                            </button>\n                        </div>\n                    </div>\n                </div>\n                <div class="col-xs-1">\n                </div>\n            </div>\n            <!-- table -->\n            <div class="row" style="margin-top: 15px;">\n                <div class="col-xs-1" style="width: 4%;">\n                    <div class="row m-b-0 clickable">\n                        <div class="wellTableColumnTitleItem col-xs-12"></div>\n                    </div>\n                    ';
 for(var i = 1; i <= row; i++) { ;
__p += '\n                    <div class="row m-b-0 clickable">\n                        <div class="wellTableLineTitleItem col-xs-12 text-center p-t-15">' +
((__t = ( String.fromCharCode(i +
                            64)
                            )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                    ';
 };
__p += '\n                </div>\n                <div class="col-xs-11 clickable" style="width: 96%;">\n                    <div class="row m-b-0">\n                        ';
 for(var i = 1; i <= column; i++) {
                        var width = 100 / column; ;
__p += '\n                        <div class="wellTableColumnTitleItem text-center p-t-10"\n                             style="width: ' +
((__t = ( width)) == null ? '' : __t) +
'%; float: left;">' +
((__t = ( ("0" + i).slice(-2) )) == null ? '' : __t) +
'\n                        </div>\n                        ';
 };
__p += '\n                    </div>\n                    ';
 for(var i = 1; i <= row; i++) { ;
__p += '\n                    <div class="row m-b-0">\n                        ';
 for(var j = 1; j <= column; j++) {
                        var pos = String.fromCharCode(i + 64) + ("0" + j).slice(-2);
                        var width = 100 / column; ;
__p += '\n                        <div class="wellTableItem tbl-' +
((__t = ( pos )) == null ? '' : __t) +
'" style="width: ' +
((__t = ( width)) == null ? '' : __t) +
'%; float: left;">\n                        </div>\n                        ';
 };
__p += '\n                    </div>\n                    ';
 };
__p += '\n                </div>\n            </div>\n            <div class="row">\n                <div>\n                    <label style="font-weight: bold;">' +
((__t = ( _.i18n('run.dblWellClickHelp') )) == null ? '' : __t) +
'</label>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

