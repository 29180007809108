/**
 * Created by GSP on 24/07/2015.
 */
define('protocol',[
  'module'
], function(module) {
  'use strict';

  module.exports = {
      apply: function (url) {
          if (document.location.protocol === 'https:') {
              return url.replace('http:', 'https:');
          }
          return url;
      }
  };
});
