define('entities/caccounts/assayResultVersion',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/mbanas',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.AssayResultVersion = Backbone.RelationalModel.extend({
        defaults: {
            'refMbAna': null,
            'algoResult': {type: '', param: ''},
            'algoValidation': {type: 'NO', param: ''},
            'algoExport': {type: 'NO', param: ''},
            'ic': false,
            'configuration': null,
            'sequence': undefined,
            'sequenceDisplay': undefined,
            'sequenceDisplayGroup': undefined,
            'sequenceValidation': undefined,
            'needValidation': false
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refMbAna',
            relatedModel: 'MbAna'
        }],

        getImageUid: function () {
        },

        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayResultVersionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },
        jsonObject: 'AssayResultVersion'
    });

    app.ns.AssayResultVersionCollection = Backbone.Collection.extend({
        model: app.ns.AssayResultVersion,
        comparator: 'sequence'
    });

    module.exports = _.defaults({
        getName: function () {
            return 'assayresultversion';
        },
        getDynamicJson: function () {
            return 'AssayResultVersionJson';
        },
        getJqGridColumn: function () {
            return new Error('can\'t get grid column');
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.AssayResultVersion.findOrCreate(modelJson);
        }
    }, Dynamic);
});
