
define('template!kitLotsPcrTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n\t<div class="form-group">\n\t\t<label class="col-xs-2 control-label">' +
((__t = ( _.i18n('lot.kit') )) == null ? '' : __t) +
'</label>\n\t\t<label class="col-xs-2 control-label">' +
((__t = ( _.i18n('lot.assay') )) == null ? '' : __t) +
'</label>\n\t\t<label class="col-xs-2 control-label">' +
((__t = ( _.i18n('lot.reagent') )) == null ? '' : __t) +
'</label>\n\t</div>\n</div>\n<div class="row m-b-10">\n\t<div class="form-group">\n\t\t<div class="col-xs-2">\n\t\t\t<input type="text" class="form-control" value="' +
((__t = ( kit )) == null ? '' : __t) +
'" data-placement="top" autocomplete="off" disabled="disabled"/>\n\t\t</div>\n\t\t<div class="col-xs-2 js-assay"></div>\n\t\t<div class="col-xs-2 js-reagent"></div>\n\t</div>\n</div>\n<div class="row m-b-10 d-f col-w-all" style="column-gap: 10px;">\n\t<div class="col-w-45 js-current-lot rounded" style="padding: 10px 10px 10px;"></div>\n\t<div class="col-w-all p-0 js-left invisible" style="display:flex; align-items: center; justify-content: center; flex-direction: column;">\n\t\t<span class="mdi mdi-transfer-left clickable" style="font-size: 80px; line-height: 80px;"></span>\n\t</div>\n\t<div class="col-w-45 js-other-lot rounded invisible" style="padding: 10px 10px 10px 10px;"></div>\n</div>\n\n<div class="row cancelConfirmRow">\n\t<div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n\t\t<button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n\t\t<button disabled\n\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t</div>\n</div>';

}
return __p
};});

