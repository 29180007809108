define('assayVersionLotItem',[
	'module',
	'marionette',
	'template!assayVersionLotItem',
	'dialogFormView'
], function (
	module,
	Marionette,
	Tpl,
	DialogFormView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		ui: {},

		events: {},

		regions: {},

		className: 'col-xs-12 p-0',

		serializeData: function () {
			var templateData = {};

			templateData.reagentType = this.model.get('pcrReagent').type;
			templateData.reagentCode = this.model.get('pcrReagent').code;
			templateData.kit = this.model.get('pcrReagent').pcrKit.code;
			templateData.lot = this.model.get('code');
			templateData.volumeByTest = this.model.get('volumeByTest');
			templateData.volumeDeath = this.model.get('volumeDeath');

			return templateData;
		}

	});
});
