define('translationsTableLayoutView',['module', 'marionette', 'template!translationsTableLayoutTpl'],
    function (module, Marionette, translationsTableLayoutTpl) {
        'use strict';

        module.exports = Marionette.LayoutView.extend({
            template: translationsTableLayoutTpl,

            className: 'content',
            regions: {
                filters: '#translations-filters-region',
                table: '#translations-table-region'
            }
        });
    });
