
define('template!generateSampleId', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="form-inputs-container t-a-c">\n        <div class="form-group" data-field-name="sampleId">\n            <label class="col-xs-3 overflow-hidden popupTitleLabel p-r-0">' +
((__t = ( _.i18n('run.sampleId.generate.labelStart')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text"\n                       class="form-control js-info-input"\n                       name="sampleId"\n                       data-field-name="sampleId"\n                       value="' +
((__t = ( sampleId )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group">\n            <label class="col-xs-2 popupTitleLabel noWrapHidden t-a-r p-r-0">' +
((__t = (
                _.i18n('run.assignAssay.from') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2 p-r-2 p-l-2">\n                <input type="text" class="js-info-input" data-field-name="sourceStart"\n                       style="width: 45px;"\n                       data-placement="top"\n                       onkeyup="this.value = this.value.toUpperCase();" value="' +
((__t = ( sourceStart )) == null ? '' : __t) +
'">\n            </div>\n            <label class="col-xs-2 popupTitleLabel noWrapHidden t-a-r p-r-0">' +
((__t = (
                _.i18n('run.assignAssay.to') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2 p-r-2 p-l-2">\n                <input type="text" class="js-info-input" data-field-name="sourceEnd"\n                       style="width: 45px;"\n                       data-placement="top"\n                       onkeyup="this.value = this.value.toUpperCase();" value="' +
((__t = ( sourceEnd )) == null ? '' : __t) +
'">\n            </div>\n            <label class="col-xs-2 popupTitleLabel noWrapHidden t-a-r p-r-2 p-l-2">' +
((__t = (
                _.i18n('run.pcr.importPlateLayoutFrom.sourceDirection') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <div class="js-plate-direction-region"></div>\n            </div>\n        </div>\n        <div class="form-group">\n\n            <label class="col-xs-2 popupTitleLabel noWrapHidden t-a-r p-r-2 p-l-2">' +
((__t = (
                _.i18n('run.sampleId.generate.step') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <input type="number" class="js-info-input" data-field-name="step"\n                       style="width: 45px;"\n                       data-placement="top" value="' +
((__t = ( step )) == null ? '' : __t) +
'">\n            </div>\n\n            <label class="col-xs-2 popupTitleLabel noWrapHidden t-a-r p-r-2 p-l-2">' +
((__t = (
                _.i18n('run.sampleId.generate.firstStep') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <input type="number" class="js-info-input" data-field-name="firstStep"\n                       style="width: 45px;"\n                       data-placement="top" value="' +
((__t = ( firstStep )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <hr/>\n        <div class="form-group">\n            <label class="col-xs-3 overflow-hidden popupTitleLabel p-r-0">' +
((__t = (
                _.i18n('run.sampleId.generate.sampleClass')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-8">\n                <input type="text"\n                       class="form-control disabled readonly"\n                       value="' +
((__t = ( sampleClass.code )) == null ? '' : __t) +
'" disabled readonly>\n            </div>\n\n            <div class="col-xs-1 p-l-0 js-select-smpType" style="line-height: 25px;"></div>\n        </div>\n        <div class="form-group">\n            <label class="col-xs-3 overflow-hidden popupTitleLabel p-r-0">' +
((__t = (
                _.i18n('run.sampleId.generate.sampleClass.order') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-7 p-r-1">\n                <input type="text"\n                       class="form-control disabled readonly t-a-r"\n                       value="' +
((__t = ( sampleClass.orderPrefix )) == null ? '' : __t) +
'" disabled readonly>\n            </div>\n            <div class="col-xs-2 p-l-1">\n                <input type="text"\n                       class="form-control  disabled readonly"\n                       value="' +
((__t = ( sampleClass.orderIncrement )) == null ? '' : __t) +
'" disabled readonly>\n            </div>\n        </div>\n\n        <div class="form-group">\n            <label class="col-xs-3 overflow-hidden popupTitleLabel p-r-0">' +
((__t = (
                _.i18n('run.sampleId.generate.sampleClass.order') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-7 js-collection">\n            </div>\n        </div>\n        <hr/>\n        <div class="row enterEditPlateLayout-buttons" style="display: flex; justify-content: center;">\n            <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n            <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n        </div>\n    </div>\n</div>';

}
return __p
};});

