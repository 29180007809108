
define('template!pcrWellResultCollectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="noWrapHidden m-t-10 border-bottom p-b-0 result-header" style="display: flex;">\n    <div class="noWrapHidden result-wellres"></div>\n    <div class="noWrapHidden result-channel">\n        <label>' +
((__t = ( _.i18n('kitp.channel') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="noWrapHidden result-target">\n        <label>' +
((__t = ( _.i18n('kitp.refMbAna') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="noWrapHidden result-edit"></div>\n    <div class="noWrapHidden result-result">\n        <label>' +
((__t = ( _.i18n('result') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="noWrapHidden result-num">\n        <label>' +
((__t = ( _.i18n('well.valAndQualification') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="noWrapHidden result-action">\n        <label>' +
((__t = ( _.i18n('well.showWellCompareGraph') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="noWrapHidden result-custom">\n        <label>' +
((__t = ( _.i18n('well.custom') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="noWrapHidden result-comment">\n        <label>' +
((__t = ( _.i18n('well.comment') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="noWrapHidden result-error">\n        <label>' +
((__t = ( _.i18n('well.codeErrAndJustificatory') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="noWrapHidden result-val1">\n        <label>' +
((__t = ( _.i18n('well.validation1') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="noWrapHidden result-val2">\n        <label>' +
((__t = ( _.i18n('well.validation2') )) == null ? '' : __t) +
'</label>\n    </div>\n</div>';

}
return __p
};});

