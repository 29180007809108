/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemRadio',[
    'module',
    'underscore',
    'dialogFormView'
], function (
    module,
    _,
    DialogFormView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template(''),
        tagName: 'input',
        className: 'form-control inputBackground',
        attributes: function () {
            return {
                'data-field-name': this.options.field.field,
                'name': this.options.field.field,
                'type': 'radio',
                'checked': this.model.get(this.options.field.field),
                'disabled': this.options.field.param && this.options.field.param.readOnly
            };
        },
        events: {
            'change': 'onRadioChange',
            'click': 'onClick'
        },
        onRadioChange: function (e) {
            this.onChange(this.options.field.field, e.currentTarget.checked);
        },
        onClick: function (e) {
            if (e.currentTarget.checked && this.model.get(this.options.field.field) === e.currentTarget.checked) {
                this.$el.prop('checked', false);
                this.onChange(this.options.field.field, false);
            }
        }
    });
});
