
define('template!wellEditSmpidTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem ' +
((__t = ( well.refPrepWell || !canChangeSmpid ? 'not-allowed' : 'clickable' )) == null ? '' : __t) +
'"\n\t style="' +
((__t = ( backgroundColor ? 'background-color:' + backgroundColor + '; ' : '' )) == null ? '' : __t) +
' width:100%; height:53px; position: relative;">\n\t';
 if (well.smpType === 'NC') { ;
__p += '\n\t<span class="mdi mdi-minus-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n\t';
 } else if(well.smpType === 'PC') { ;
__p += '\n\t<span class="mdi mdi-plus-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n\t';
 } else if(well.smpType === 'C') { ;
__p += '\n\t<span class="mdi mdi-alpha-c-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n\t';
 } else if(well.smpType === 'RC') { ;
__p += '\n\t<span class="mdi mdi-alpha-r-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n\t';
 } else if(well.smpType === 'P') { ;
__p += '\n\t<span class="pooled-label" style="position: absolute; font-size:18px; left: 0;">P</span>\n\t';
 } else if(well.smpType === 'OC') { ;
__p += '\n\t<span class="mdi mdi-compass" style="position: absolute; font-size:18px; left: 0;"></span>\n\t';
 } else if(well.smpType === 'D') { ;
__p += '\n\t<span class="mdi mdi-alpha-d-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n\t';
 } else if(well.smpType === 'C') { ;
__p += '\n\t<span class="mdi mdi-alpha-c-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n\t';
 } ;
__p += '\n\t<div class=" p-l-0 p-r-0 col-xs-2 h-16"></div>\n\t';
 if (editMode && !well.refPrepWell && canChangeSmpid) { ;
__p += '\n\t<div class="col-xs-10 small-regular-compuzz p-l-2 p-r-0 h-16 smp-id-link">\n\t\t<input type="text" ' +
((__t = ( !control ? 'tabindex="' + tabIndex + '"' : '' )) == null ? '' : __t) +
'\n\t\tclass="form-control js-info-input js-input-smpId ' +
((__t = ( control ?
		'js-info-input-control' : '' )) == null ? '' : __t) +
' ' +
((__t = ( well.smpType === 'U' || well.smpType === 'D' || well.smpType === 'C' ? 'js-well-unknow' : '' )) == null ? '' : __t) +
'"\n\t\tstyle="height:17px; font-size: 13px; ' +
((__t = ( test ? 'color:white; background-color: black;' : 'color:black;' )) == null ? '' : __t) +
'"\n\t\tvalue="' +
((__t = ( wellSmpIdToDisplay )) == null ? '' : __t) +
'"\n\t\tposition="' +
((__t = ( well.pos )) == null ? '' : __t) +
'"data-placement="top">\n\t</div>\n\t';
 } else { ;
__p += '\n\t<span class="col-xs-10 small-regular-compuzz p-l-5 p-r-0 h-16 smp-id-link"\n\t\t  style="white-space: nowrap; overflow: hidden; font-family: monospace;"><div\n\t\t\tclass="js-well-sample"></div></span>\n\t';
 } ;
__p += '\n\t<div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: green;"></div>\n\t<span class="btn-assay col-xs-10 small-regular-compuzz p-l-2 p-r-0 h-16"\n\t\t  style="white-space: nowrap; overflow: hidden;"><span>' +
((__t = ( well.assay )) == null ? '' : __t) +
'</span></span>\n\t<div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16"></div>\n</div>';

}
return __p
};});

