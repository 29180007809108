define('entities/caccounts/routingaction_state',[
    'app',
    'backbone',
    'underscore',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.RoutingActionState = Backbone.RelationalModel.extend({
        defaults: {
            state: null,
            color: 16777215,
            rackId: null,
            showPopup: false,
            ignored: false,
            canBeIgnored: false
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refRoutingAction',
            relatedModel: 'RoutingAction'
        }],
        getEntity: function () {
            return module.exports;
        }
    });


    app.ns.RoutingActionStateCollection = Backbone.Collection.extend({
        model: app.ns.RoutingActionState,
        comparator: 'sequence'
    });

    module.exports = _.defaults({
        getName: function () {
            return 'routingActionState';
        },
        getDynamicJson: function () {
            return 'RoutingAction_StateJson';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.RoutingActionState.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore();
            ignored.push('canBeIgnored');
            return ignored;
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'state',
                    'param': {
                        'type': 'LABEL',
                        'display': {'class': 'col-w-40 m-l-2 m-r-2'}
                    }
                },
                {'field': 'rackId', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-21 m-l-2 m-r-2'}}},
                {
                    'field': 'ignored', 'param': {
                        'type': 'BOOLEAN', 'display': {
                            'class': 'col-w-8 p-l-5 p-r-5',
                            'condition': {
                                key: 'canBeIgnored',
                                value: true
                            }
                        }
                    }
                },
                {'field': 'showPopup', 'param': {'type': 'BOOLEAN', 'display': {'class': 'col-w-8 p-l-5 p-r-5'}}}
            ];
        },
        getConfigCreateEditLine: function () {
            var config = Dynamic.getConfigCreateEditLine();
            config.editButton = false;
            return config;
        }
    }, Dynamic);
});
