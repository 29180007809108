
define('template!prepRunWellEditControlSidPlateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="saving-label"></div>\n<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n    <div class="error-msg"></div>\n</div>\n<div class="form-horizontal">\n    <div class="form-inputs-container controlSidPopup t-a-c">\n\n        <label class="popupTitleLabel" for="controlSidLengthCheckbox">' +
((__t = ( _.i18n('platelayout.ctrl.sid.length')
            )) == null ? '' : __t) +
'</label>\n        <div class="row m-b-15 m-t-5">\n            <input class="controlSidCheckBox" id="controlSidLengthCheckbox" name="controlSidLengthCheckbox"\n                   style="transform: scale(2);"\n                   type="checkbox" checked/>\n        </div>\n\n        <label class="popupTitleLabel" for="controlDuplicateSidCheckbox">' +
((__t = ( _.i18n('platelayout.ctrl.sid.duplicate')
            )) == null ? '' : __t) +
'</label>\n        <div class="row m-b-15 m-t-5">\n            <input class="controlSidCheckBox" id="controlDuplicateSidCheckbox" name="controlDuplicateSidCheckbox"\n                   style="transform: scale(2);"\n                   type="checkbox" checked/>\n        </div>\n\n        <div class="row enterEditPlateLayout-buttons" style="display: flex; justify-content: center;">\n            <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n            <button class="mdi-navigation-check btn popupBtn-confirm disabled-grey control-plate-layout-popup-button"></button>\n            <!-- js-confirm -->\n        </div>\n    </div>\n</div>\n<div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>';

}
return __p
};});

