
define('template!samplePrepWellCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<!-- header table -->\n<div class="col-w-all col-w-100 d-f" style="border-bottom: solid 1px #999898;">\n    <div class="col-w-3 noWrapHidden css-samplePrepWellInfo t-a-l">\n    </div>\n    <div class="col-w-18 noWrapHidden css-samplePrepWellInfo t-a-l">\n        ' +
((__t = ( _.i18n('preprun') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-2 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n        ' +
((__t = ( _.i18n('pos') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-2 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n    </div>\n    <div class="col-w-1"></div>\n    <div class="col-w-2 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n    </div>\n    <div class="col-w-1"></div>\n    <div class="col-w-2 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n    </div>\n    <div class="col-w-1"></div>\n    <div class="col-w-69">\n        <div class="col-w-all col-w-100 d-f">\n            <div class="col-w-20 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n                ' +
((__t = ( _.i18n('pcrRun') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-1"></div>\n            <div class="col-w-4 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n                ' +
((__t = ( _.i18n('pos') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-1"></div>\n            <div class="col-w-10 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n                ' +
((__t = ( _.i18n('pcrRun.assay') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-1"></div>\n            <div class="noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n                ' +
((__t = ( _.i18n('results') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

