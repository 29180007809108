
define('template!prepRunWellEditAddAssayPlateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content info-page">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container t-a-c">\n\t\t\t';
 if(mode === 'add-assay' ) { ;
__p += '\n\t\t\t<label class="popupTitleLabel">' +
((__t = ( _.i18n('selectAssay.placeholder') )) == null ? '' : __t) +
'</label>\n\t\t\t<div class="row m-b-0">\n\t\t\t\t<div class="js-select-assay-region editPlateLayout-drpdn-default"></div>\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\t\t\t';
 if(mode === 'clear-assay') { ;
__p += '\n\t\t\t<label class="popupTitleLabel">' +
((__t = ( _.i18n('selectAssay.placeholder') )) == null ? '' : __t) +
'</label>\n\t\t\t<div class="row m-b-0">\n\t\t\t\t<div class="js-select-clear-assay-region editPlateLayout-drpdn-default"></div>\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\t\t\t';
 if(mode === 'replace-assay' ) { ;
__p += '\n\t\t\t<label class="popupTitleLabel">' +
((__t = ( _.i18n('selectAssay.placeholder') )) == null ? '' : __t) +
'</label>\n\t\t\t<div class="row m-b-0">\n\t\t\t\t<div class="js-select-clear-assay-region editPlateLayout-drpdn-default"></div>\n\t\t\t</div>\n\t\t\t<div class="row m-b-0">\n\t\t\t\t<div class="js-select-assay-region editPlateLayout-drpdn-default"></div>\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\n\t\t\t<div class="row">\n\t\t\t\t<label class="popupTitleLabel dsp-cont" for="assayFrom">' +
((__t = ( _.i18n('from') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="h-22 dsp-cont">\n\t\t\t\t\t<input type="text" class="js-input-from-well assayInputFrom"\n\t\t\t\t\t\t   style="width: 45px; width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n\t\t\t\t\t\t   id="assayFrom"\n\t\t\t\t\t\t   value="' +
((__t = ( from )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t   pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t   data-placement="top"\n\t\t\t\t\t\t   onkeyup="this.value = this.value.toUpperCase();">\n\t\t\t\t</div>\n\t\t\t\t<label class="popupTitleLabel dsp-cont" for="assayTo">' +
((__t = ( _.i18n('to') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="dsp-cont">\n\t\t\t\t\t<input type="text" class="js-input-to-well assayInputTo"\n\t\t\t\t\t\t   style="width: 45px; width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n\t\t\t\t\t\t   id="assayTo"\n\t\t\t\t\t\t   value="' +
((__t = ( to )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t   pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t   data-placement="top"\n\t\t\t\t\t\t   onkeyup="this.value = this.value.toUpperCase();">\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="row enterEditPlateLayout-buttons" style="display: flex; justify-content: center;">\n\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n\t\t\t\t';
 if(mode === 'add-assay') { ;
__p += '\n\t\t\t\t<button disabled\n\t\t\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-add-assay js-add-assay-btn"></button>\n\t\t\t\t<!-- js-confirm -->\n\t\t\t\t';
 } ;
__p += '\n\t\t\t\t';
 if(mode === 'clear-assay') { ;
__p += '\n\t\t\t\t<button class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-clear-assay js-clear-assay-btn"></button>\n\t\t\t\t<!-- js-confirm -->\n\t\t\t\t';
 } ;
__p += '\n\t\t\t\t';
 if(mode === 'replace-assay') { ;
__p += '\n\t\t\t\t<button class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-replace-assay js-replace-assay-btn"></button>\n\t\t\t\t<!-- js-confirm -->\n\t\t\t\t';
 } ;
__p += '\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

