define('pcrSetupAssayCollection',[
    'module',
    'backbone',
    'marionette',
    'pcrSetupAssayItem',
    'underscore',
    'template!pcrSetupAssayCollection'
], function (
    module,
    Backbone,
    Marionette,
    Item,
    _,
    Tpl
) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
        template: Tpl,
        childView: Item,
        className: 'row',
        childViewContainer: '.js-assays',
        childEvents: {
            'item:change': function (view, selected, fromProtocol) {
                _.each(this.children._views, function (viewFromCollection) {
                    if (viewFromCollection.displaySelected) {
                        viewFromCollection.displaySelected(selected && view === viewFromCollection);
                    }
                });
                this.trigger('assay:change', selected ? view.model : null, fromProtocol);
            }
        },
        clearSelection: function () {
            _.each(this.children._views, function (view) {
                view.clearSelection();
            });
        },
        applyFilter: function (protocol) {
            this.protocol = protocol;
            var views = [];
            _.each(this.children._views, function (view) {
                var enable = _.isEmpty(protocol) || view.model.get('assay').get('refKitProt').get('secId') === protocol;
                view.enableInput(enable);
                if(enable) {
                    views.push(view);
                }
            });
            if(views.length === 1) {
                views[0].select();
            }
        },
        updateProtocol: function () {
            var newWells = _.chain(this.model.get('prepRuns').filter(function (model) {
                return model.canDisplay(this.protocol);
            }, this))
                .map(function (prepRun) {
                    return _.map(prepRun.get('wells').filter(function (well) {
                        return well.get('available');
                    }), function (well) {
                        return well.get('waiting').map(function (waiting) {
                            return {
                                waiting: waiting,
                                well: well
                            };
                        });
                    });
                })
                .flatten()
                .groupBy(function (pair) {
                    return pair.waiting.get('secId');
                })
                .omit(undefined)
                .omit(null)
                .omit('')
                .value();
            this.collection = new Backbone.Collection();
            this.model.get('assays').each(_.bind(function (assay) {
                if (_.size(newWells[assay.get('secId')]) > 0) {
                    var model = new Backbone.Model({
                        assay: assay,
                        wells: new Backbone.Collection(newWells[assay.get('secId')])
                    });
                    this.collection.push(model, {silent: true});
                }
            }, this));
            this.render();
        },
        isOnlyAvaible: function () {
            var views = _.filter(this.children._views, function (view) {
                if (view.isSelectable) {
                    if(view.isSelectable()) {
                        return true;
                    }
                }
            });
            return views.length === 1;
        }
    });
});
