
define('template!sampleResultView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-w-all col-w-100 d-f">\n    <div class="col-w-1 noWrapHidden t-a-l p-r-15" title="' +
((__t = ( targetName )) == null ? '' : __t) +
'">\n        ';
 if(!exported) { ;
__p += '\n        <span class="mdi clickable mdi-pencil js-edit" style="font-size:15px; line-height: 15px;"></span>\n        ';
 } ;
__p += '\n    </div>\n    <div class="col-w-1"></div>\n    <div class="col-w-10 noWrapHidden t-a-l p-r-15" title="' +
((__t = ( targetName )) == null ? '' : __t) +
'">\n        ' +
((__t = ( targetCode )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-1"></div>\n    <div class="col-w-4 noWrapHidden t-a-l p-r-15">\n        ' +
((__t = ( iconHtml )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-1"></div>\n    <div class="col-w-4 noWrapHidden t-a-l p-r-15">\n        ' +
((__t = ( resCode )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-1"></div>\n    <div class="col-w-10 noWrapHidden t-a-l p-r-15">\n        ' +
((__t = ( sampleResultDefinition )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-1"></div>\n    <div class="col-w-10 noWrapHidden t-a-l p-r-15">\n        ' +
((__t = ( comment )) == null ? '' : __t) +
'\n    </div>\n</div>';

}
return __p
};});

