
define('template!arrayInputView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<span class="mdi mdi-border-all clickable"\n   style="font-size: 19px; line-height: 23px; position: absolute; right: 1px;"></span>\n<input class="js-info-input form-control inputBackground js-params js-info-input clickable col-w-100"\n       data-field-name="' +
((__t = ( fieldName )) == null ? '' : __t) +
'" readonly\n       type="text" value="' +
((__t = ( value )) == null ? '' : __t) +
'"/>';

}
return __p
};});

