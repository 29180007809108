define('prepRunDetailTabController',[
    'module',
    'underscore',
    'backbone',
    'app',
    'prepRunDetailTabView',
    'settings'
], function (
    module,
    _,
    Backbone,
    App,
    View
) {
    'use strict';

    var RunTabController = {
        showDetailTab: function (region, model) {
            this.region = region;
            this.model = model;
            this.createView();
        },

        createView: function () {
            var view;
            view = new View({
                model: this.model
            });
            view.tempModel = new Backbone.Model(this.model.toJSON());
            this.view = view;
            this.region.show(view);
        }
    };

    module.exports = RunTabController;
});
