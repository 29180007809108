define('prepRunCreateFromUploadView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!prepRunCreateFromUploadTpl',
    'savingBehavior',
    'bootbox',
    'underscore',
    'app',
    'settings',
    'autocompleteView',
    'entities/caccounts/prepruns'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    bootbox,
    _,
    App,
    Settings,
    AutocompleteView
) {
    'use strict';


    var CreateFromUploadView = DialogFormView.extend({
        template: createEditLayoutTpl,

        events: {
            'click .js-cancel-popup': 'hide',
            'click .js-confirm': 'onConfirm'
        },

        regions: {
            selectKitProt: '.js-select-kitprot-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'name', type: 'required'}
        ],

        fromTemplate: true,

        onShow: function () {
            var kitProtView = new AutocompleteView(
                this._getAutocompleteOptions('refKitProt', 'code', 'code', 'v2/kitprots',
                    'selectkitprot.placeholder', 'code', this.onKitProtChange)
            );
            this.getRegion('selectKitProt').show(kitProtView);

            this.triggerMethod('enable:cancel:confirm');
        },

        onKitProtChange: function (fieldName, model) {
            this.kitProt = model;
        },

        onConfirm: function () {
            this.trigger('popup-create:prep:selectLmbAnalyser', null, this.kitProt);
            this.hide();
        },

        hide: function () {
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        }
    });

    module.exports = CreateFromUploadView;
});
