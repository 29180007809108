
define('template!menusViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content" style="height:98%;\n\n  padding: 20px;overflow-y: hidden;">\n  <div class="left-pane" style="width:33%;height:100%;float:left;overflow-y: auto;">\n  <div class= "jq-grid-table-page" style="display:flex;flex-wrap: wrap;border-radius: 25px;\nwidth:90%;height:95%;overflow-y: auto;">\n\n    ';
 _.each(menus, function(menu) { ;
__p += '\n    <div class="clickable js-show-submenu menu-zoom" data-submenu="' +
((__t = ( menu.secId)) == null ? '' : __t) +
'"\n         style="border: 1px solid grey;border-radius: 25px;\n         width:300px;height:150px;\n         background-color: whitesmoke;\n         margin-left:auto;\n         margin-right: auto;\n         margin-top: 30px;\n         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);\n    ">\n      <div class="js-show-submenu" data-submenu="' +
((__t = ( menu.secId)) == null ? '' : __t) +
'" style="padding: 25px;height: 50%;">\n        <img class="js-show-submenu" data-submenu="' +
((__t = ( menu.secId)) == null ? '' : __t) +
'" src="' +
((__t = ( menu.iconEncodedValue )) == null ? '' : __t) +
'"\n               style="width: 40%;height: auto;float:left;">\n        <div data-submenu="' +
((__t = ( menu.secId)) == null ? '' : __t) +
'" style="padding: 20px 0 0 20px;float:left;">' +
((__t = (menu.name)) == null ? '' : __t) +
'</div>\n      </div>\n      <div data-submenu="' +
((__t = ( menu.secId)) == null ? '' : __t) +
'" style="padding: 20px 0 0 20px;">' +
((__t = (menu.description)) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 }) ;
__p += '\n\n  </div>\n  </div>\n  <div class="right-pane" style="width:66%;float:left;overflow-y: auto;">\n\n  </div>\n  </div>\n';

}
return __p
};});

