define('prepRunCreatePcrFromPrepView',[
	'module',
	'backbone',
	'app',
	'dialogFormView',
	'template!prepRunCreatePcrFromPrepTpl',
	'savingBehavior',
	'underscore',
	'jquery',
	'autocompleteView',
	'moment',
	'settings',
	'entities/caccounts/userpids',
	'entities/caccounts/pcrruntemplates',
	'entities/caccounts/assay',
	'entities/caccounts/cyclerpublics',
	'entities/caccounts/prepruns'
], function (
	module,
	Backbone,
	App,
	DialogFormView,
	createPoolingTpl,
	SavingBehavior,
	_,
	$,
	AutocompleteView,
	moment,
	Settings,
	Userpids,
	PcrRunTemplates,
	Assays,
	CyclerPublics
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createPoolingTpl,

		ui: {
			input: '.js-info-input',
			selectedDate: '.pcrRun-date-select',
			blockLoading: '.empty-page-loader'
		},

		regions: {
			selectRespUser: '.js-select-respUser-region',
			pcrRunTemplateView: '.js-select-runtemplate-region',
			assayView: '.js-select-assay-region',
			cyclerRegion: '.js-select-cycler-region'
		},

		events: {
			'click .js-cancel-popup': 'hide',
			'click .js-confirm': 'onConfirm',
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'change @ui.pcrRun-date-select': 'onChangeDatePicker'
		},

		fieldsToValidate: [
			{name: 'refPcrRunTemplate', type: 'required'},
			{name: 'refCycler', type: 'required'},
			{name: 'refAssay', type: 'required'}
		],

		modelEvents: {
			'change': 'render'
		},

		initialize: function () {
			this.model = new Backbone.Model();
			this.model.set('prepRunSecId', this.options.runModel.get('secId'));
			this.model.set('plateIdPc', '');
			this.model.set('respUser', Settings.get('currentUserModel').toJSON());
			this.model.set('selectedDate', Number(moment().format('x')));
			this.model.set('currentDate', moment().format('DD-MM-YYYY'));
		},

		serializeData: function () {
			return this.model.toJSON();
		},

		onChangeDatePicker: function (event) {
			if (event.target.value === '') {
				this.model.set('selectedDate', -1);
			} else {
				this.model.set('selectedDate', Number(moment(event.target.value, 'DD-MM-YYYY').format('x')));
			}
		},

		onRender: function () {
			this.initDatePicker(this.ui.selectedDate, 'selectedDate');

			this.pcrRunTemplateView = new AutocompleteView(
				this._getAutocompleteOptionObject(PcrRunTemplates.getAutocompleteParam({
					modelProperty: 'refPcrRunTemplate',
					callBackOnChange: this.onChangePcrRunTemplate
				}))
			);
			this.getRegion('pcrRunTemplateView').show(this.pcrRunTemplateView);

			this.respUserView = new AutocompleteView(
				this._getAutocompleteOptionObject(Userpids.getAutocompleteParam({
					modelProperty: 'respUser',
					valueProperty: 'nickName',
					queryProperty: 'nickName',
					valueKey: 'nickName',
					callBackOnChange: this.onRespUserChange
				}))
			);
			this.getRegion('selectRespUser').show(this.respUserView);

			this.getRegion('assayView').show(new AutocompleteView(
				this._getAutocompleteOptionObject(Assays.getAutocompleteParam({
					modelProperty: 'refAssay',
					paramUrl: {
						'sord': 'asc',
						'sidx': 'code',
						'cyclerPlateSize': this.options.runModel.get('plateSize')
					},
					callBackOnChange: this.onChangeAssay
				}))
			));

			this.getRegion('cyclerRegion').show(new AutocompleteView(
				this._getAutocompleteOptionObject(CyclerPublics.getAutocompleteParam({
					modelProperty: 'refCycler',
					paramUrl: {
						'sord': 'asc',
						'sidx': 'code',
						'plateSize': this.options.runModel.get('plateSize'),
						'assaySecId': this.model.get('refAssay') ? this.model.get('refAssay').secId : null
					},
					callBackOnChange: this.onChangeCycler
				}))
			));
		},

		onShow: function () {
			$.material.init();
			this.triggerMethod('enable:cancel:confirm');
		},

		onRespUserChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.onChange(fieldName, model ? model.toJSON() : null);
		},

		onChangePcrRunTemplate: function (fieldName, model) {
			this.onChange('refKitProt', null);

			if (model && model.get('refKitProt')) {
				this.onChange('refKitProt', model.get('refKitProt'));
			}

			if (model.get('pattern').includes('%PERIOD%')) {
				$('.pcrRunSelectDate').show();
				this.fieldsToValidate = [
					{name: 'refPcrRunTemplate', type: 'required'},
					{name: 'refCycler', type: 'required'},
					{name: 'refAssay', type: 'required'},
					{name: 'selectedDate', type: 'required'}
				];
			} else {
				$('.pcrRunSelectDate').hide();
				this.fieldsToValidate = [
					{name: 'refPcrRunTemplate', type: 'required'},
					{name: 'refCycler', type: 'required'},
					{name: 'refAssay', type: 'required'}
				];
			}
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.onChange(fieldName, model ? model.toJSON() : null);
			if (model && model.get('cyclerPublic')) {
				this.onChange('refCycler', model.get('cyclerPublic'));
			}
			if (model && this.model.get('refCycler') && model.get('plateSize') !== this.model.get('refCycler').plateSize) {
				this.onChange('refCycler', null);
			}
			this.model.trigger('change');
		},

		onChangeAssay: function (fieldName, model) {
			this.onChange(fieldName, model ? model.toJSON() : null);
			if (this.model.get('refAssay') && this.model.get('refCycler')) {
				CyclerPublics.find({
					assaySecId: this.model.get('refAssay').secId,
					plateSize: this.options.runModel.get('plateSize')
				}).done(_.bind(function (datas) {
					if (datas.length === 1) {
						this.onChange('refCycler', datas[0]);
					} else {
						if (!_.any(datas, _.bind(function (data) {
							return data.secId === this.model.get('refCycler').secId;
						}, this))) {
							this.onChange('refCycler', null);
						}
					}
					this.model.trigger('change');
				}, this));
			} else {
				this.model.trigger('change');
			}
		},

		onChangeCycler: function (fieldName, model) {
			this.onChange(fieldName, model ? model.toJSON() : null);
			this.model.trigger('change');
		},

		onConfirm: function () {
			if (this.validate(this.fieldsToValidate)) {
				this.ui.blockLoading.show();
				App.request('run:pcr:create-from-prep', this.model.toJSON())
					.done(_.bind(function (result) {
						if (result) {
							this.triggerMethod('run.created.from.prep', result);
						}
						this.hide();
					}, this), this);
			}
		},

		hide: function () {
			this.triggerMethod('hide');
			this.box.modal('hide');
			this.destroy();
		}
	});
});
