define('samplePrepWellView',[
    'module',
    'marionette',
    'template!samplePrepWellView',
    'wellUtils',
    'backbone',
    'samplePrepWellPcrWellCollection',
    'linkNewTabView'
], function (
    module,
    Marionette,
    WellTargetTpl,
    WellUtils,
    Backbone,
    SamplePrepWellPcrWellCollection,
    LinkNewTabView
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: WellTargetTpl,

        modelEvents: {
            'sync': 'renderSafe'
        },

        regions: {
            pcrWells: '.js-prepwell-pcrwell-region',
            prepRun: '.js-preprun'
        },

        className: 'col-w-all col-w-100 d-f',
        attributes: {
            style: 'border-bottom: solid 1px #999898;'
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        onShow: function () {
            this.getRegion('pcrWells').show(new SamplePrepWellPcrWellCollection({
                collection: this.model.get('pcrWells')
            }));
        },
        onRender: function () {
            var params = {
                context: this,
                placement: 'bottom'
            };
            params.target = this.$el;

            WellUtils.showPrepWellPopover(params);
            this.getRegion('prepRun').show(new LinkNewTabView(this.model.get('refPrepRun').getEntity().parameterForLink(this.model.get('refPrepRun'))));
        },

        serializeData: function () {
            var templateData = {};
            templateData.secId = this.model.get('secId');
            templateData.runName = this.model.get('runName');
            templateData.runSecId = this.model.get('runSecId');
            templateData.pos = this.model.get('pos');
            templateData.assayWaiting = this.model.getWellAssaysWaitingList();
            templateData.assayRunning = this.model.getWellAssaysRunningList();
            templateData.assayDone = this.model.getWellAssaysDoneList();
            templateData.assayIcons = WellUtils.getPrepWellTooltipAssaysIcons();
            return templateData;
        }
    });
});
