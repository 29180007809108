
define('template!superAdminDocumentsListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="well jq-grid-table-page">\n        <div class="document-table">\n            <div class="row baseTableTitleContainer">\n                <div class="col-xs-1">\n                    <div class="form-group">\n                        <a href="#superAdmin/documents/new" class="btn new-kit-button add-btn-text">\n                            <span class="mdi mdi-plus-circle"></span>\n                            <span>' +
((__t = ( _.i18n('menu.add.button') )) == null ? '' : __t) +
'</span>\n                        </a>\n                    </div>\n                </div>\n                <div class="col-xs-10">\n                    <div class="baseTAbleTitleText">' +
((__t = ( _.i18n('menu.superadmin.documents') )) == null ? '' : __t) +
'</div>\n                </div>\n                <div class="col-xs-1">\n                    <button class="mdi-navigation-refresh btn btn-info js-update-button" style="margin-top:5px;margin-left:10px;"></button>\n                </div>\n            </div>\n            <table id="jq-grid-list" class="documentTable" role="grid"></table>\n            <div id="jq-grid-pager-list"></div>\n            <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

