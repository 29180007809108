/**
 * Created by RKL on 21/08/2015.
 */
define('wellController',[
	'module',
	'underscore',
	'wellEditSmpIdView',
	'entities/caccounts/samples',
	'entities/caccounts/sampleClass'
], function (
	module,
	_,
	View,
	Samples,
	SampleClasses
) {
	'use strict';

	module.exports = {
		showDetails: function (model) {
			if (!model) {
				alert('The model well is undefined or null');
				return;
			}
			SampleClasses.findBySampleId(model.get('smpId').get('code')).done(_.bind(function (sampleClass) {
				var run = model.get('refPcrRun') ? model.get('refPcrRun').get('name') : null;
				run = !run && model.get('refPrepRun') ? model.get('refPrepRun').get('name') : null;
				run = !run && model.get('refPcrRunTemplate') ? model.get('refPcrRunTemplate').get('name') : null;
				run = !run && model.get('refPrepRunTemplate') ? model.get('refPrepRunTemplate').get('name') : '';
				var view = new View({
					model: model,
					sampleClass: sampleClass
				});
				view.show({
					title: _.i18n('well.edit') + ' (' + run + ')',
					className: 'wellEditTable'
				});
			}, this));
		}
	};
});
