define('pcrWellResultCurveView',[
    'module',
    'marionette',
    'wellResultIconView'
], function (
    module,
    Marionette,
    WellResultIconView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        className: 'col-xs-10 p-l-0 p-r-0',
        attributes: {
            'style': 'display: flex;'
        },
        childView: WellResultIconView,
        childViewOptions: function () {
            return {
                width: '20px'
            };
        }
    });
});
