define('prepRunTableTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'prepRunTableTabView',
    'jquery',
    'emptyView',
    'bootbox',
    'entities/caccounts/prepwells',
    'entities/caccounts/pcrruntracs'
], function (
    module,
    App,
    _,
    Backbone,
    TableTabView,
    $,
    EmptyView,
    bootbox
) {
    'use strict';

    module.exports = {

        showTableTab: function (region, runModel, from, step, to, filters) {
            this.region = region;
            this.model = runModel;
            this.from = from;
            this.step = step;
            this.to = to;
            this.filters = filters;
            var isMultiDisplay = this.model.get('wells').length > 96;

            require([
                'prepRunTabsLayoutController'
            ], _.bind(function (prepRunTabsLayoutController) {
                this.view = new TableTabView({
                    model: runModel,
                    isMultiDisplay: isMultiDisplay,
                    from: from,
                    to: to,
                    step: step,
                    filters: filters,
                    filteredWells: prepRunTabsLayoutController.getFilteredWells()
                });
                this.view.listenTo(this.view, 'well:filteredDetailRow', _.bind(this.filteredDetailRow, this));
                this.view.listenTo(this.view, 'well:filteredDetailColumn', _.bind(this.filteredDetailColumn, this));
                this.view.listenTo(this.view, 'well:filteredDetailItem', _.bind(this.filteredDetailItem, this));
                this.view.listenTo(this.view, 'run:prep:importXml', _.bind(this.onImportXml, this));
                this.view.listenTo(this.view, 'run:prep:import-layout-from-template', _.bind(this.onImportLayoutFromTemplate, this));
                this.view.listenTo(this.view, 'run:prep:create-pooling-bead', _.bind(this.onCreateBeadPooling, this));
                this.view.listenTo(this.view, 'run:prep:create-pooling-primary', _.bind(this.onCreatePrimaryPooling, this));
                this.view.listenTo(this.view, 'run:prep:controller-pcr-setup-same-layout-selected', _.bind(this.onPcrSetupSameLayoutSelected, this));
                this.view.listenTo(this.view, 'run:prep:controller-pcr-setup-same-layout-copy', _.bind(this.onPcrSetupSameLayoutCopy, this));
                region.show(this.view);
            }, this));
        },

        onCreateBeadPooling: function (runs) {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);
            App.request('run:prep:create-pooling-bead', this.model.get('secId'), runs).done(_.bind(function (result) {
                this.emptyView.destroy();
                this.showTableTab(this.region, result, this.from, this.step, this.to);
            }, this));
        },

        onCreatePrimaryPooling: function (runs) {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);
            App.request('run:prep:create-pooling-primary', this.model.get('secId'), runs).done(_.bind(function (result) {
                this.emptyView.destroy();
                this.showTableTab(this.region, result, this.from, this.step, this.to);
            }, this));
        },

        onImportLayoutFromTemplate: function (template) {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);
            App.request('run:prep:import-layout-from-template', this.model.get('secId'), template.get('secId')).done(_.bind(function (result) {
                this.emptyView.destroy();
                this.showTableTab(this.region, result, this.from, this.step, this.to);
            }, this));
        },

        onImportXml: function (fileInfo) {
            var fileName = fileInfo.name;
            var runName = this.model.get('name');

            if (fileName.includes(runName, 0)) {
                this.confirmImport(fileInfo, this.model);
            } else {
                bootbox.confirm(_.i18n('preprun.controlRunName'), _.bind(function (result) {
                        if (result) {
                            this.confirmImport(fileInfo, this.model);
                        }
                    }, this)
                );
            }
        },

        confirmImport: function (fileInfo, model) {
            this.model = model;
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);

            $.when(this.view.model.updateWithXmlFile(fileInfo)).done(_.bind(function (model) {
                this.emptyView.destroy();
                App.trigger('run:refresh', this.model.get('secId'), _.bind(function () {
                    this.showTableTab(this.region, model, this.from, this.step, this.to);
                }, this));
            }, this));
        },

        _getUrl: function (tab) {
            var currentRoute = App.getCurrentRoute(),
                routeSplitted = currentRoute.split('/'), newRoute = [];

            newRoute.push(routeSplitted[0]);
            newRoute.push(routeSplitted[1]);
            newRoute.push(routeSplitted[2]);
            newRoute.push(routeSplitted[3]);
            newRoute.push(routeSplitted[4]);
            if (tab) {
                newRoute.push(tab);
            }
            return newRoute.join('/');
        },

        filteredDetailAssay: function (assaySecId) {
            App.navigate(this._getUrl('details') + '/filters/{"assaySecId":"' + assaySecId + '"}');
        },

        filteredDetailRow: function (row) {
            App.navigate(this._getUrl('details') + '/row/' + row, {trigger: true});
        },

        filteredDetailColumn: function (column) {
            if (column) {
                App.navigate(this._getUrl('details') + '/column/' + column, {trigger: true});
            } else {
                App.navigate(this._getUrl(), {trigger: true});
            }
        },

        filteredDetailItem: function (well) {
            if (well.wellsPool.length > 0) {

            } else {
                App.navigate(this._getUrl('details') + '/row/' + well.pos[0] + '/column/' +
                    well.pos.substring(1), {trigger: true});
            }
        },

        onPcrSetupSameLayoutSelected: function (kitProtSecId, assaySecId) {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);
            App.request('run:prep:pcr-setup-same-layout-selected', this.model.get('secId'), kitProtSecId, assaySecId).done(_.bind(function () {
                var currentRoute = this._getUrl();
                App.navigate('runs/prep/empty', {trigger: false, replace: true});
                App.navigate(currentRoute, {trigger: true});
            }, this));
        },

        onPcrSetupSameLayoutCopy: function (kitProtSecId, assaySecId) {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);
            App.request('run:prep:pcr-setup-same-layout-copy', this.model.get('secId'), kitProtSecId, assaySecId).done(_.bind(function () {
                var currentRoute = this._getUrl();
                App.navigate('runs/prep/empty', {trigger: false, replace: true});
                App.navigate(currentRoute, {trigger: true});
            }, this));
        }
    };
});
