/**
 * Created by OBL on 14/01/2016.
 */
define('superAdminMenuDetailsController',[
  'module',
  'underscore',
  'app',
  'superAdminMenusView',
  'bootbox',
  'menuCreateEditLayoutView',
  'settings',
  'entities/admin/codelist'
], function (
  module,
  _,
  App,
  View,
  bootbox,
  CreateEditLayoutView
) {
  'use strict';

  var SuperAdminMenuController = {
    showList: function () {
      var view = new View();
      this.view = view;
      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'menu:delete', _.bind(this.onDelete, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    showAdd: function () {
      this.createEditView = new CreateEditLayoutView({
        model: App.request('menu:model'),
        newItem: true
      });
      this.createEditView.show({
        title: _.i18n('menu.superadmin.menus'),
        className: 'popupMenu'
      });
    },

    onGridLoaded: function () {
      var data = {
        url: App.request('menu:get-collection-url'),
        rowCountUrl: App.request('menu:get-count-url')
      };

      this.view.displayGrid(data);
    },

    onDelete: function (secId) {
      bootbox.confirm(_.i18n('menu.confirmDelete'), _.bind(function (result) {
        if (result) {
          App.request('menu:delete', secId)
            .done(_.bind(function () {
              this.view.updatePagination('reloadGrid');
            }, this));
        }
      }, this));
    }
  };

  module.exports = SuperAdminMenuController;
});
