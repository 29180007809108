
define('template!importResultsInPcrRunView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n\n        <div class="form-inputs-container">\n            <div class="createRunFromTemplate">\n\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="refCycler">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('cycler') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-8">\n                        <div class="js-select-cycler-region"></div>\n                    </div>\n                </div>\n\n\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="refRespUser">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('refRespUser') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-3">\n                        <div class="js-select-respUser-region"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class="form-group" style="text-align: center;">\n            <button class="btn popupBtn-cancel cancel-b js-cancel-popup" style="padding: 3px 15px 0 15px;">\n                <span class="mdi mdi-close" style="font-size: 30px;"></span>\n            </button>\n\n            <input type="file"\n                   id="run-xml-input"\n                   class="hidden js-upload-runs" data-icon="true"\n                   accept="' +
((__t = ( accept )) == null ? '' : __t) +
'" multiple/>\n\n            <label for="run-xml-input" class="btn btn popupBtn-confirm btn-sm run-import"\n                   style="padding: 0 15px 0 15px;"\n                   title="' +
((__t = ( _.i18n('run.import.folder.local') )) == null ? '' : __t) +
'">\n                <span class="mdi mdi-monitor" style="font-size: 30px; color: inherit;"></span>\n            </label>\n\n            ';
 if(remoteAvailable) { ;
__p += '\n            <button class="btn btn popupBtn-confirm btn-sm run-import-remote-folder"\n                    style="padding: 0 15px 0 15px;"\n                    title="' +
((__t = ( _.i18n('run.import.folder.remote') )) == null ? '' : __t) +
'">\n                <span class="mdi mdi-folder-network" style="font-size: 30px; color: inherit;"></span>\n            </button>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>';

}
return __p
};});

