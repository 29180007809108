
define('template!wellTargetPropCPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="saving-label"></div>\n<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t<div class="error-msg"></div>\n</div>\n<div class="form-horizontal">\n\t<div class="form-inputs-container t-a-c">\n\t\t<div class="row m-b-10">\n\t\t\t<div class="col-xs-12 m-b-0 p-l-0 p-r-0">\n\t\t\t\t<div class="col-xs-3 p-l-0 p-r-5">\n\t\t\t\t\t<label>' +
((__t = ( _.i18n('run.run') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-1 p-l-5 p-r-5">\n\t\t\t\t\t<label>' +
((__t = ( _.i18n('assay.assay') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-1 p-l-5 p-r-5">\n\t\t\t\t\t<label>' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-2 p-l-5 p-r-5">\n\t\t\t\t\t<label>' +
((__t = ( _.i18n('common.target') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-1 p-l-5 p-r-5">\n\t\t\t\t\t<label>' +
((__t = ( _.i18n('wellresult.result') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-2 p-l-5 p-r-5">\n\t\t\t\t\t<label>' +
((__t = ( _.i18n('wellresult.num') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-2 p-l-5 p-r-0">\n\t\t\t\t\t<label>' +
((__t = ( _.i18n('wellresult.error') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="col-xs-12 p-l-0 p-r-0">\n\t\t\t\t<div class="col-xs-3 p-l-0 p-r-5">\n\t\t\t\t\t' +
((__t = ( refPcrWell.runName )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-1 p-l-5 p-r-5">\n\t\t\t\t\t' +
((__t = ( refPcrWell.assay )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-1 p-l-5 p-r-5">\n\t\t\t\t\t' +
((__t = ( refPcrWell.pos )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-2 p-l-5 p-r-5">\n\t\t\t\t\t' +
((__t = ( mbAna )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-1 p-l-5 p-r-5">\n\t\t\t\t\t' +
((__t = ( result )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-2 p-l-5 p-r-5">\n\t\t\t\t\t' +
((__t = ( cts )) == null ? '' : __t) +
' ' +
((__t = ( _.isEmpty(quantifications) ? '' : '(' + quantifications + ')' )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-2 p-l-5 p-r-5">\n\t\t\t\t\t' +
((__t = ( codeErr )) == null ? '' : __t) +
' ' +
((__t = ( commentErrAuto )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="row">\n\t\t\t<div class="col-xs-2">\n\t\t\t\t<span class="mdi mdi-download clickable rawData" title="' +
((__t = ( _.i18n('curve.rawData.download') )) == null ? '' : __t) +
'"></span>\n\t\t\t\t<span class="mdi mdi-download clickable fluorData" title="' +
((__t = ( _.i18n('curve.fluorData.download') )) == null ? '' : __t) +
'"></span>\n\t\t\t</div>\n\t\t\t<div class="col-xs-10">\n\t\t\t\t' +
((__t = ( propCformated )) == null ? '' : __t) +
'\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="row">\n\t\t\t<div class="col-xs-12">\n\t\t\t\t<div class="col-w-all col-w-100 d-b p-l-0 p-r-5 m-b-3">\n\t\t\t\t\t<div class="col-w-all col-w-100 d-f">\n\t\t\t\t\t\t<div class="col-w-all col-w-11 p-l-5 t-a-l"></div>\n\t\t\t\t\t\t<div class="col-w-all col-w-19 p-l-5 t-a-l">\n\t\t\t\t\t\t\t<label style="font-size: 19px;">' +
((__t = ( algoRes )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-w-all col-w-90 p-l-5">\n\t\t\t\t\t\t\t<div class="js-algoParam"></div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t</div>\n\t\t<div class="row">\n\t\t\t<div>\n\t\t\t\t<div id="targetAmplGraphView"></div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>\n<div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n\n\n\n\n';

}
return __p
};});

