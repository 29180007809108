
define('template!pcrRunValStatusTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="z-index: 9000;">\n\t<ul>\n\t\t';
 _.each(elements, function(codeListElement) {
		var bold = false;
		if(valStatus) {
		bold = valStatus.toString().includes(codeListElement.code);
		}
		;
__p += '\n\n\n\t\t<li>' +
((__t = ( bold ? '<b>' : '' )) == null ? '' : __t) +
'\n\t\t\t' +
((__t = ( codeListElement.code )) == null ? '' : __t) +
' - ' +
((__t = ( _.i18n('pcrrun.valStatus.' + codeListElement.code) )) == null ? '' : __t) +
'\n\t\t\t' +
((__t = ( bold ? '</b>' : '' )) == null ? '' : __t) +
'\n\t\t</li>\n\t\t';
 }) ;
__p += '\n\t</ul>\n</div>';

}
return __p
};});

