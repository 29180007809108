/**
 * Created by OLD on 25/11/2015.
 */
define('wellDisplayHL7TableView',[
    'module',
    'underscore',
    'app',
    'jquery',
    'colorUtils',
    'settings',
    'jqgridView',
    'template!wellDisplayHL7TableView'
], function (
    module,
    _,
    App,
    $,
    ColorUtils,
    Settings,
    JqGridView,
    viewTpl
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'valViewHL7Filters',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqgRow: 'tr.jqgrow',
                blockLoading: '.empty-page-loader'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({}, this.gridEvents);
        },

        serializeData: function () {
            var templateData = {};

            templateData.editEnable = !!this.channelToDisplay;
            templateData.sortOn = this.sortOn;
            templateData.multiRes = this.multiRes;
            templateData.wellRes = this.wellRes;
            return templateData;
        },

        pagination: function () {
            return {
                first: 0,
                rowCount: 0,
                pageSize: 384,
                currentPage: 1
            };
        },

        gridOptions: function (data) {

            var that = this,
                defaultsOptions = this.gridInitOptions(data),
                options = {
                    colModel: [
                        {
                            label: '',
                            name: 'secId',
                            key: true,
                            hidden: true
                        }, {
                            label: ' ',
                            name: 'select',
                            sortable: false,
                            search: false,
                            classes: 'select-link-action ignore-row-click',
                            formatter: this.rowSelectorFormatter,
                            fixed: true,
                            width: 25,
                            checked: true
                        }, {
                            label: this.getSmpTypeLabelIcon(),
                            name: 'smpType',
                            formatter: _.bind(this.smptypeFormater, this),
                            search: true,
                            sortable: true,
                            index: 'smpType',
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {sopt: ['eq'], value: this.smpTypeList, clearSearch: false},
                            fixed: true,
                            width: 30
                        }, {
                            label: _.i18n('well.pos'),
                            name: 'pos',
                            formatter: this.wellPosFormatter,
                            classes: 'css-grid-cell-accentuatuion-03',
                            sortable: true,
                            title: false,
                            index: 'pos',
                            fixed: true,
                            width: 50,
                            search: true,
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            }
                        }, {
                            label: _.i18n('well.smpId'),
                            name: 'smpId.name',
                            formatter: _.bind(this.wellSmpidFormatter, this),
                            classes: 'css-grid-cell-accentuatuion-02',
                            search: true,
                            sortable: true,
                            title: false,
                            index: 'smpId.name',
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            },
                            fixed: true,
                            width: 130
                        }, {
                            label: _.i18n('well.validation'),
                            name: 'valWst',
                            formatter: this.validationFormatter,
                            search: false,
                            width: 40
                        }, {
                            label: _.i18n('well.icon'),
                            name: 'secId',
                            formatter: this.iconWellFormatter,
                            search: false,
                            width: 40
                        }, {
                            label: _.i18n('well.result'),
                            name: 'wellResSearchValue',
                            classes: 'well-list-label-wellRes',
                            formatter: _.bind(this.wellResIconCodeNumFormatter, this),
                            search: true,
                            sortable: true,
                            title: false,
                            searchoptions: {sopt: ['cn'], clearSearch: false},
                            index: 'wellResSearchValue',
                            fixed: true,
                            width: 110
                        }, {
                            label: _.i18n('well.hl7'),
                            name: 'hl7graph',
                            classes: 'jqgrid-cell-bottom',
                            formatter: this.hl7Formatter,
                            search: false,
                            width: 510
                        }
                    ],
                    sortname: this.sortOn ? this.sortOn : 'row',

                    gridComplete: function () {
                        defaultsOptions.gridComplete.apply(this, arguments);

                        var $item = that.$('.js-wellRes-color-cell');
                        _.each($item, _.bind(function (item) {
                            var $item = $(item);
                            var color = $item.attr('data-color');
                            var rgbObj = ColorUtils.hexToRgba(color);
                            var rgbaCol = 'rgba(' + rgbObj.r + ',' + rgbObj.g + ',' + rgbObj.b + ', 0.2) !important';
                            $item.parent().parent().parent().attr('style', 'background-color: ' + rgbaCol + ';');
                        }, this));
                    },

                    onSelectRow: _.bind(function (rowId, bool, e) {
                        var well = this.model.get('wells').find(function (obj) {
                            return obj.get('secId') === rowId;
                        });
                        if ($(e.target).parent().hasClass('select-link-action')) {
                            this.checkOneRow(e);
                            if ($(e.target).hasClass('mdi-checkbox-marked-outline')) {
                                this.trigger('well:check', well);
                            } else {
                                this.trigger('well:uncheck', well);
                            }
                            return;
                        }
                        if (!$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click' && !$(e.target).is('input[type=checkbox]')) {
                            require(['pcrWellController'], function (PcrWellController) {
                                PcrWellController.showDetails(well, null, null);
                            });
                        }
                    }, this)
                };

            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },

        wellPosFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellPosPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="position: relative;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="position: relative;">' + cellValue + '</span>';
        },

        wellSmpidFormatter: function (cellValue, options, object) {
            if (!cellValue) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellSmpidPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
        },

        wellResIconCodeNumFormatter: function (cellValue, call, object) {
            var start, end;
            var wellResColor = false;

            if (object && object.wellRes) {
                if (object.pos) {
                    start = '<div class="row m-b-0 ovrerallResultPopover-' + object.pos + ' ">';
                } else {
                    start = '<div class="row m-b-0">';
                }
                start += '<div class="col-xs-12 p-r-0" style="display: flex;">';

                if (object.wellRes.get('result') && object.wellRes.get('result').get('color')) {
                    wellResColor = object.wellRes.get('result').get('color');
                    start += '<div class="wellRes-tableView js-wellRes-color-cell" data-color="' + wellResColor +
                        '" style="width: 5px; height: 20px; background-color: ' + wellResColor + ';"></div>';
                }

                start += '<span class="p-l-5" style="min-width: 5px; min-height: 20px; font-size:12px;">' +
                    (object.wellRes.get('result') && object.wellRes.get('result').get('code') ? object.wellRes.get('result').get('code') : '') + '</span>';

                start += '<span class="p-l-5" ' +
                    'style="min-width: 5px; min-height: 20px; font-size:12px;">' +
                    (object.wellRes.get('cts') ? object.wellRes.get('cts').join(';') : '') + '</span>';
                start += '</div>';
                end = '</div>';
                return start + end;
            } else {
                return '';
            }
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi-content-remove-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi-content-add-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="iconify" data-icon="ant-design:copyright-circle-filled" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="iconify" data-icon="bxs:registered" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="iconify" data-icon="mdi:google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        }
    });
});
