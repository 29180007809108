define('prepRunDetailTabView',[
    'module',
    'prepRunCreateLayoutView',
    'underscore',
    'marionette',
    'template!prepRunDetailTabTpl'
], function (
    module,
    CreateEditLayoutView,
    _,
    Marionette,
    viewTpl
) {
    'use strict';

    var RunDetailView = Marionette.ItemView.extend({
        template: viewTpl,

        modelEvents: {
            change: 'render'
        },

        events: {
            'click .js-edit': '_onEdit'
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.viewLess = this.model.get('secId');

            templateData.plateRowNb = templateData.plateSize.charCodeAt(0) - 64;
            return templateData;
        },

        _onEdit: function () {
            var createEditView = new CreateEditLayoutView({
                runModel: this.model
            });
            createEditView.show({
                title: _.i18n('preprun.edit')
            });
        }

    });

    module.exports = RunDetailView;
});
