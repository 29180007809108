define('entities/caccounts/kitprotconfiguration',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/cyclerpublics',
    'entities/admin/cyclers',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    Cyclers
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.KitProtConfiguration = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/kitProtConfiguration/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'protocolNN': null,
            'protocolRN': null,
            'protocolNM': null,
            'protocolRM': null,
            'refCycler': {}
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refCycler',
            relatedModel: 'CyclerPublic',
            includeInJSON: ['secId', 'code', 'name']
        }],

        getImageUid: function () {
        },

        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'KitProtConfigurationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'KitProtConfiguration',
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.KitProtConfigurationCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/kitProtConfiguration/');
        },
        model: app.ns.KitProtConfiguration
    });

    module.exports = _.defaults({
        getName: function () {
            return 'kitProtConfiguration';
        },
        getUrl: function () {
            return 'v2/kitProtConfiguration';
        },
        getDynamicJson: function () {
            return 'KitProtConfigurationJson';
        },
        getJqGridColumn: function () {
            return new Error('can\'t get grid column');
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'name';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'name';
            configuration.urlPath = (config.url ? config.url : this.getUrl()) + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'kitprot.code'
            }));
            configuration.valueKey = config.valueKey ? config.valueKey : 'name';
            return configuration;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.KitProtConfiguration.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refKitProt.code', 'refCycler.code']};
        },

        getCreateEditLine: function () {
            return [
                {
                    'field': 'refCycler',
                    'param': {
                        'type': 'REFERENCE', 'config': {'getAutocompleteParam': Cyclers.getAutocompleteParam},
                        'context': Cyclers,
                        'display': {'class': 'col-w-20 p-l-5'}
                    }
                },
                {'field': 'protocolNN', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45 p-l-5'}}},
                {'field': 'protocolRN', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45 p-l-5'}}},
                {'field': 'protocolNM', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45 p-l-5'}}},
                {'field': 'protocolRM', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45 p-l-5'}}}
            ];
        }
    }, Dynamic);
});
