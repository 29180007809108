define('prepRunDetailsTabController',[
    'module',
    'app',
    'underscore',
    'jquery',
    'backbone',
    'prepRunDetailsTabView',
    'emptyView',
    'settings',
    'wellUtils',
    'PrepRunWellTableView',
    'prepRunWellDetailView',
    'entities/caccounts/prepruns',
    'entities/caccounts/prepwells'
], function (
    module,
    App,
    _,
    $,
    Backbone,
    DetailsTabView,
    EmptyView,
    Settings,
    WellUtils,
    WellTableView,
    PrepWellDetailView,
    PrepRuns
) {
    'use strict';

    module.exports = {
        showDetailsTab: function (region, runModel, from, step, to, row, column, filters) {
            this.model = runModel;

            // refresh the well_table_view (details) after a plate layout action because de wellView use a local gridData
            App.on('well:table:prep:refresh:jqGrid', _.bind(function () {
                this.onShowWellTable();
            }, this));

            var otherAssayInd = this.model.get('settings')['OTHER-ASSAY-IND'] ? this.model.get('settings')['OTHER-ASSAY-IND'].split(';') : [];

            if (otherAssayInd.length === 1) {
                this.model.sameAssayColor = otherAssayInd[0];
            }
            if (otherAssayInd.length === 2) {
                this.model.otherAssayColor = otherAssayInd[1];
            }

            this.wells = App.request('well:prep:filter', this.model.get('wells'), from, to, parseInt(step, 10));

            this.view = new DetailsTabView({
                model: runModel,
                wells: this.wells
            });

            this.row = row;
            this.column = column;
            this.multiRes = null;
            this.wellRes = null;
            this.filters = filters;

            if (this.model.get('settings')['LAB-SETTINGS'] && this.model.get('settings')['LAB-SETTINGS'] === 'C') {
                this.sortOn = 'column';
            } else {
                this.sortOn = 'row';
            }
            if (this.filters && this.filters.targetSecId) {
                this.target = this.model.getTarget(this.filters.targetSecId);
                this.channelToDisplay = this.target.channelNb;
                this.runAt = this.model.getRunAtFromTarget(this.filters.targetSecId);
            } else {
                this.target = null;
                this.channelToDisplay = null;
                this.runAt = null;
            }
            this.from = from;
            this.to = to;
            this.step = step;

            region.show(this.view);
            this.view.getRegion('wellTable').show(new EmptyView());
            this.onShowWellTable();
        },

        onShowWellTable: function () {
            this.wellView = new WellTableView({
                tabName: this.tabName,
                model: this.model
            });

            this.wellView.model = this.model;
            this.wellView.channelToDisplay = this.channelToDisplay;
            this.wellView.sortOn = this.sortOn;
            this.wellView.multiRes = this.multiRes;
            this.wellView.wellRes = this.wellRes;
            this.wellView.listenTo(this.wellView, 'onGridLoaded', _.bind(this.onGridLoaded, this));

            // refresh the well_table_view (details) after a wellEditPopup action because de wellView use a local gridData
            this.wellView.listenTo(App, 'prepWell:refresh:jqGrid', _.bind(this.onGridLoaded, this));
            this.view.getRegion('wellTable').show(this.wellView);
        },

        onGridLoaded: function (model, rowId) {
            this.wellView.ui.blockLoading.show();

            this.getDetailsFilteredWells()
                .done(_.bind(function (filteredWells) {
                    if (filteredWells.length < 1) {
                        $('.jq-grid-table-page').append('<div class="alert-info-grid">' + _.i18n('jqgrid.emptyrecords') + '</div>');
                        return;
                    }
                    // this.setGridData(this.getListViewData(filteredWells), rowId);
                    var listViewData = this.getListViewData(filteredWells);
                    this.setGridData(listViewData, rowId);
                }, this));
        },

        getDetailsFilteredWells: function () {
            var defer = $.Deferred();
            require([
                'prepRunTabsLayoutController'
            ], _.bind(function (PrepRunTabsLayoutController) {
                this.wellFiltersList = [];
                this.wellFiltersList = PrepRunTabsLayoutController.getWellFiltersList();
                var wells = this.model.get('wells');
                var filteredWells = false;
                if (this.wellFiltersList && this.wellFiltersList.length && this.wellFiltersList.length > 0 && App.getCurrentRoute().includes('select')) {
                    wells = wells.filter(function (well) {
                        return _.contains(PrepRunTabsLayoutController.getFilteredWells(), well.get('pos'));
                    });
                    filteredWells = App.request('well:prep:models');
                    filteredWells.set(wells);
                }
                wells = App.request('well:prep:filter', (!filteredWells ? wells : filteredWells), this.from, this.to, this.step, this.row, this.column, this.filters, this.multiRes, (this.wellRes && this.wellRes !== 'all') ? this.wellRes : null);
                defer.resolve(wells);
            }, this));
            return defer.promise();
        },

        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            this.wellView.assaysIcons = wellListViewData.assaysIcons;
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },

        setGridData: function (data, rowId) {
            setTimeout(_.bind(function () {
                /** */ this.wellView.ui.jqGrid.clearGridData(); /** needed in case of refresh */
                /** */ this.wellView.ui.jqGrid.setGridParam(data, true); //overwrite param is needed true to avoid maximum call stack error on grid refresh
                /** needed in case of refresh */
                this.wellView.gridOptionsObj = this.wellView.gridOptions(data);
                this.wellView.onRowCountDone(data, 9999);
                this.wellView.triggerMethod('checkRowCount', 9999);
                if (rowId) {
                    /** */ this.wellView.ui.jqGrid.trigger('reloadGrid', [{current: true}]);
                    /** needed in case of refresh */
                    this.wellView.ui.jqGrid.setSelection(rowId, false); // highlight selected row
                }
                this.setWellListPopover();

                this.wellView.ui.blockLoading.hide();
            }, this), 500);
        },

        setWellListPopover: function () {
            this.model.get('wells').each(_.bind(function (well) {
                if (well.get('pos') && $('.wellPosPopover-' + well.get('pos')).length > 0 &&
                    $('.wellSmpidPopover-' + well.get('pos')).length > 0) {

                    var params = {
                        context: this,
                        model: well
                    };

                    params.targets = [
                        $('.wellPosPopover-' + well.get('pos')),
                        $('.wellSmpidPopover-' + well.get('pos'))
                    ];

                    WellUtils.showPrepWellPopover(params);
                }
            }, this));
        },

        // todo missinc: biogroup && sample.assaygroup.opened
        getWellListViewData: function (wells) {
            var wellsForData = [];
            _.each(wells, _.bind(function (well) {
                var assayStatusFormatted = WellUtils.getFormattedAssayStatus(well);
                var comment = well.get('smpId').get('lisComment') ? well.get('smpId').get('lisComment') : '';
                comment += well.get('smpId').get('comment') ? (comment ? ' - ' : '') + well.get('smpId').get('comment') : '';
                var model = {
                    secId: well.get('secId'),
                    smpType: well.get('smpType'),
                    pos: well.get('pos'),
                    smpId: well.get('smpId') ? {
                        name: well.get('smpId').get('name'),
                        code: well.get('smpId').get('code'),
                        lisComment: well.get('smpId').get('lisComment'),
                        comment: well.get('smpId').get('comment')
                    } : {name: '', code: ''},
                    repeatStatus: well.get('repeatStatus'),
                    tags: well.get('tags')
                };
                model.assaysWaiting = assayStatusFormatted.waiting;
                model.assaysRunning = assayStatusFormatted.running;
                model.assaysDone = assayStatusFormatted.done;
                wellsForData.push(model);
            }, this));

            return {
                wellsForData: wellsForData,
                assaysIcons: WellUtils.getPrepWellAssaysListViewIcons()
            };
        },

        getGridSearchField: function (list) {
            var retVal = ':' + _.i18n('common.any') + ';'; //': ;';
            _.each(list, function (l) {
                retVal += l + ':' + l + ';';
            });
            retVal = retVal.substring(0, retVal.length - 1);
            return retVal;
        },

        showWell: function (model) {
            $('.js-global-loader').show();
            var prepRunModel = PrepRuns.getModel({secId: model.get('runSecId')});
            prepRunModel.fetch().done(_.bind(function () {
                var prepWellModel = prepRunModel.get('wells').findWhere({secId: model.get('secId')});
                var wellDetailView = new PrepWellDetailView({
                    model: prepWellModel,
                    readOnly: true
                });
                wellDetailView.show({
                    title: prepWellModel.get('runName'),
                    className: 'popupWellView'
                });
            }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },
        serializeData: function () {
            return {};
        }
    };
});
