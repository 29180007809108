define('pcrSetupEmptyRunView',[
	'module',
	'marionette',
	'backbone',
	'dialogFormView',
	'template!pcrSetupEmptyRunTpl',
	'savingBehavior',
	'bootbox',
	'underscore',
	'app',
	'settings',
	'autocompleteView',
	'moment',
	'entities/caccounts/fileFormatPlateLayouts',
	'entities/caccounts/pcrruns',
	'entities/createRunFromTemplate'
], function (
	module,
	Marionette,
	Backbone,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	bootbox,
	_,
	App,
	Settings,
	AutocompleteView,
	moment,
	FileFormatPlateLayouts,
	PcrRuns
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			inputName: '.run-input-name',
			createRunRadio: '.create-run-radio',
			createRunFromTemplate: '.createRunFromTemplate',
			createRunFromCyclerExport: '.createRunFromCyclerExport',
			uploadRunsCyclerExport: '.js-upload-runs-cycler-export',
			selectedDateRegion: '.js-select-date-region',
			selectedDate: '.date-select',
			numberOfRunRegion: '.js-number-of-run-region',
			numberOfRun: '.number-of-run',
			generateStarterSheet: '.generate-starter-sheet',
			exportPlateLayout: '.export-plate-layout',
			template384: '.template384',
			selectFileFormat: '.js-select-fileFormat-region',
			blockLoading: '.empty-page-loader'
		},

		events: {
			'click .js-cancel-popup': 'hide',
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-confirm': 'onConfirm',
			'change @ui.createRunRadio': 'onCreateRunRadioChange',
			'change @ui.uploadRunsCyclerExport': 'onLoadFilePlateLayout',
			'change @ui.selectedDate': 'onChangeDate',
			'change @ui.generateStarterSheet': 'onChangeGenerateStarterSheet',
			'change @ui.exportPlateLayout': 'onChangeExportPlateLayout'
		},

		regions: {
			selectRunTemplate: '.js-select-run-template-region',
			selectKitProtFromCyclerExport: '.js-select-kitprot-region-from-cycler-export',
			selectKitProt: '.js-select-kitprot-region',
			selectPlateTopLeft: '#select-plate-region-top-left',
			selectPlateTopRight: '#select-plate-region-top-right',
			selectPlateBottomLeft: '#select-plate-region-bottom-left',
			selectPlateBottomRight: '#select-plate-region-bottom-right',
			selectAssay: '#select-assay-region',
			selectFileFormat: '.js-select-fileFormat-region'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		fieldsToValidate: [
			{name: 'name', type: 'required'}
		],


		initialize: function (options) {
			this.model = options.model;
			this.currentMode = 'fromTemplate';
			this.generateStarterSheet = false;
		},

		serializeData: function () {
			this.model.set('numberOfRun', 1);
			this.model.set('selectedDate', Number(moment().format('x')));
			this.model.set('currentDate', moment().format('DD-MM-YYYY'));
			this.model.set('generateStarterSheet', false);
			return this.model.toJSON();
		},

		onDatePickerChange: function (fieldName) {
			this.$el.find(this.ui.selectedDate).val(moment(this.model.get(fieldName)).format('DD-MM-YYYY'));
			this.ui.selectedDate.removeClass('empty');
			App.request('run:pcr:available-for-run-template', this.model.get('refRunTemplate').get('secId'),
				{
					selectedDate: this.model.get(fieldName)
				}
			).done(_.bind(function (model) {
				this.$el.find(this.ui.numberOfRun).attr('max', parseInt(model, 10));
			}, this));
		},

		onChangeDate: function (event) {
			if (event.target.value === '') {
				this.model.set('selectedDate', -1);
			} else {
				this.model.set('selectedDate', Number(moment(event.target.value, 'DD-MM-YYYY').format('x')));
				this.onDatePickerChange('selectedDate');
			}
		},

		onChangeGenerateStarterSheet: function (event) {
			this.generateStarterSheet = event.target.checked;
		},

		onChangeExportPlateLayout: function (event) {
			this.exportPlateLayout = event.target.checked;
		},

		onCreateRunRadioChange: function () {
			if (this.ui.createRunRadio) {
				this.ui.createRunFromTemplate.toggleClass('hidden', true);
				this.ui.createRunFromCyclerExport.toggleClass('hidden', true);
				_.each(this.ui.createRunRadio, _.bind(function (radio) {
					switch (radio.value) {
						case 'fromTemplate':
							if (radio.checked) {
								this.ui.createRunFromTemplate.toggleClass('hidden', false);
							}
							break;
						case 'fromCyclerExport':
							if (radio.checked) {
								this.ui.createRunFromCyclerExport.toggleClass('hidden', false);
							}
							break;
					}
					if (radio.checked) {
						this.currentMode = radio.value;
					}
				}, this));
			}
		},

		onLoadFilePlateLayout: function (e) {
			if (e && e.target && e.target.files && e.target.files[0]) {
				this.currentFile = e.target.files[0];
			}
		},

		onShow: function () {
			$.material.init();
			this.getRegion('selectRunTemplate').options.allowMissingEl = true;
			this.getRegion('selectRunTemplate').show(new AutocompleteView(
				this._getAutocompleteOptions('refRunTemplate', 'name', 'name', 'v2/runtemplates/pcr?sord=asc&sidx=seq',
					'selectruntemplate.placeholder', 'name', this.onTemplateChange)
			));

			this.getRegion('selectPlateTopLeft').show(new AutocompleteView(
				this._getAutocompleteOptions('refPlateTopLeft', 'name', 'name', 'v2/runs/e-plate',
					'selectplate.placeholder', 'name')
			));
			this.getRegion('selectPlateTopRight').show(new AutocompleteView(
				this._getAutocompleteOptions('refPlateTopRight', 'name', 'name', 'v2/runs/e-plate',
					'selectplate.placeholder', 'name')
			));
			this.getRegion('selectPlateBottomLeft').show(new AutocompleteView(
				this._getAutocompleteOptions('refPlateBottomLeft', 'name', 'name', 'v2/runs/e-plate',
					'selectplate.placeholder', 'name')
			));
			this.getRegion('selectPlateBottomRight').show(new AutocompleteView(
				this._getAutocompleteOptions('refPlateBottomRight', 'name', 'name', 'v2/runs/e-plate',
					'selectplate.placeholder', 'name')
			));
			this.getRegion('selectAssay').show(new AutocompleteView(
				this._getAutocompleteOptions('refAssay', 'code', 'code', 'v2/assays',
					'selectassay.placeholder', 'code')
			));

			this.getRegion('selectFileFormat').show(new AutocompleteView(
				this._getAutocompleteOptionObject(FileFormatPlateLayouts.getAutocompleteParamForImport({
					modelProperty: 'refFileFormat',
					callBackOnChange: this.onChangeFileFormat
				}))
			));
			this.triggerMethod('enable:cancel:confirm');
		},

		onChangeFileFormat: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.fileFormatSecId = model.get('secId');
		},

		onRender: function () {
			this.initDatePicker(this.ui.selectedDate, 'selectedDate');
		},

		onKitProtChange: function (fieldName, model) {
			this.currentKitProt = model;
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.onChange(fieldName, model);
			if (model) {
				if (!this.ui.inputName.val()) {
					this.ui.inputName.val(model.get('runNamePattern'));
					this.ui.inputName.change();
				}
			}
		},

		onTemplateChange: function (fieldName, model) {
			this.model.set('refRunTemplate', model);
			var displayTemplateDate = false;
			var displayTemplateNumberOfRuns = false;
			var displayTemplate384 = false;

			this.ui.selectedDate.removeClass('invalid');
			this.ui.selectedDateRegion.closest('div[data-field-name=selectedDate]').removeClass('has-error');

			this.ui.numberOfRun.removeClass('invalid');
			this.ui.numberOfRunRegion.closest('div[data-field-name=numberOfRun]').removeClass('has-error');

			this.model.set('numberOfRun', 1);
			this.model.set('selectedDate', Number(moment().format('x')));
			this.model.set('currentDate', moment().format('DD-MM-YYYY'));

			if (model !== null) {
				var pattern = model.get('pattern');
				displayTemplateDate = true; //pattern.includes('P'); //period
				displayTemplateNumberOfRuns = pattern.includes('S'); //sequence
				displayTemplate384 = model.get('wellCount') > 96;
			}

			this.ui.selectedDateRegion.toggleClass('hidden', !displayTemplateDate);
			this.ui.numberOfRunRegion.toggleClass('hidden', !displayTemplateNumberOfRuns);
			this.ui.template384.toggleClass('hidden', !displayTemplate384);
			this.exportPlateLayout = false;
			this.ui.exportPlateLayout[0].checked = false;
			this.fileFormatSecId = null;
		},

		onConfirm: function () {

			var createRunFromTemplate = App.request('createRunFromTemplate:model');
			createRunFromTemplate.set('refRunTemplate', this.model.get('refRunTemplate').get('secId'));
			var pattern = this.model.get('refRunTemplate').get('pattern');
			var needToCheckDate = true; //pattern.includes('P'); //period
			var needToCheckNumberOfRuns = pattern.includes('S'); //sequence
			var template384 = this.model.get('refRunTemplate').get('wellCount') > 96;
			if (!this.validate([{name: 'refRunTemplate', type: 'required'}])) {
				return;
			}
			if (needToCheckDate) {
				if (!this.validate([{name: 'selectedDate', type: 'required'}]) ||
					!this.validate([{name: 'selectedDate', type: 'datePicker'}])) {
					return;
				}
				createRunFromTemplate.set('selectedDate', this.model.get('selectedDate'));
			}
			if (needToCheckNumberOfRuns) {
				if (!this.validate([{name: 'numberOfRun', type: 'required'}])) {
					return;
				}
				var numberOfRun = parseInt(this.model.get('numberOfRun'), 10);
				var maxNumberOfRun = parseInt(this.$el.find(this.ui.numberOfRun).attr('max'), 10);
				if (numberOfRun > maxNumberOfRun) {
					return;
				}
				createRunFromTemplate.set('numberOfRun', numberOfRun);
			} else {
				createRunFromTemplate.set('numberOfRun', 1);
			}
			if (template384) {
				var check = !this.validate([{name: 'refPlateTopLeft', type: 'required'}]);
				check = check && !this.validate([{name: 'refPlateTopRight', type: 'required'}]);
				check = check && !this.validate([{name: 'refPlateBottomLeft', type: 'required'}]);
				check = check && !this.validate([{name: 'refPlateBottomRight', type: 'required'}]);

				if (check) {
					return;
				}
				createRunFromTemplate.set('refPlateTopLeft', this.model.get('refPlateTopLeft').get('secId'));
				createRunFromTemplate.set('refPlateTopRight', this.model.get('refPlateTopRight').get('secId'));
				createRunFromTemplate.set('refPlateBottomLeft', this.model.get('refPlateBottomLeft').get('secId'));
				createRunFromTemplate.set('refPlateBottomRight', this.model.get('refPlateBottomRight').get('secId'));
				createRunFromTemplate.set('refAssay', this.model.get('refAssay') ? this.model.get('refAssay').get('secId') : '');
			}
			this.ui.blockLoading.show();

			createRunFromTemplate.save().done(
				_.bind(function (result) {
					if (result.length === 1) {
						var step = result[0].wellCount <= 96 ? 1 : 2;
						App.navigate('runs/pcr/' + result[0].secId + '/A01/'+step+'/' + result[0].plateSize, {trigger: true});
					} else {
						App.navigate('runs/pcr', {trigger: true});
						this.triggerMethod('run:listView:refresh');
					}
					var url;
					if (this.generateStarterSheet) {
						var runSecIds = result.map(function (run) {
							return run.secId;
						});
						PcrRuns.generateRunSheet(runSecIds);
					}
					if (this.exportPlateLayout && this.fileFormatSecId && this.model.get('refPlateTopLeft').get('secId') && this.model.get('refPlateTopRight').get('secId') && this.model.get('refPlateBottomLeft').get('secId') && this.model.get('refPlateBottomRight').get('secId')) {
						url = Settings.url('compuzz', 'v2/runs/pcr/' + this.model.get('refPlateTopLeft').get('secId') + '/plateLayout/export', {
							'fileFormatSecId': this.fileFormatSecId,
							'plateLinkedType': 'TOP_LEFT'
						});
						window.open(url, '_blank');
						url = Settings.url('compuzz', 'v2/runs/pcr/' + this.model.get('refPlateTopRight').get('secId') + '/plateLayout/export', {
							'fileFormatSecId': this.fileFormatSecId,
							'plateLinkedType': 'TOP_RIGHT'
						});
						window.open(url, '_blank');
						url = Settings.url('compuzz', 'v2/runs/pcr/' + this.model.get('refPlateBottomLeft').get('secId') + '/plateLayout/export', {
							'fileFormatSecId': this.fileFormatSecId,
							'plateLinkedType': 'BOTTOM_LEFT'
						});
						window.open(url, '_blank');
						url = Settings.url('compuzz', 'v2/runs/pcr/' + this.model.get('refPlateBottomRight').get('secId') + '/plateLayout/export', {
							'fileFormatSecId': this.fileFormatSecId,
							'plateLinkedType': 'BOTTOM_RIGHT'
						});
						window.open(url, '_blank');
					}
					this.hide();
				}, this)
			).fail(
				_.bind(function (response) {
					if (response.status === 409) {
						this.ui.selectedDate.addClass('invalid');
						this.ui.selectedDateRegion.closest('div[data-field-name=selectedDate]').addClass('has-error');
					}
					this.ui.blockLoading.hide();
				}, this)
			);
		},

		onSave: function (model) {
			var modelJSON = model.toJSON();
			this.model.set('wells', this.wells);
			this.options.runModel.set(modelJSON);
			this.triggerMethod('saved');
			App.trigger('run:header:rerender', modelJSON);
			App.navigate('runs/pcr/' + model.get('secId') + '/edit', {trigger: true});
			if (this.generateStarterSheet) {
				PcrRuns.generateRunSheet(model.get('secId'));
			}
			this.hide();
		},

		hide: function () {
			this.trigger('hide:pcrSetup:popup');
			this.triggerMethod('hide');
			//this.box.modal('hide');
			this.destroy();
		},

		onSaveError: function (model, response) {
			if (response.status === 409) {
				this.onDuplicateCode();
				this.triggerMethod('hide:label');
			}
		},

		onDuplicateCode: function () {
			this.ui.endUserIdInput.popover('show');
			this.ui.endUserIdInput.addClass('invalid');
			this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
		},

		onFocusOut: function () {
			var $target,
				datepicker;

			// close popover when click outside
			setTimeout(_.bind(function () {
				$target = $(document.activeElement);
				datepicker = $target.parents('.datepicker-element').length > 0 ||
					$target.parents('.ui-datepicker').length > 0 ||
					$target.hasClass('ui-datepicker') ||
					$target.get(0) === this.$el.get(0) ||
					$target.hasClass('popover-shown');

				if (!datepicker) this.trigger('close:popover');
			}, this), 0);
		}
	});
});
