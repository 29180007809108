
define('template!sampleResultCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<!-- header table -->\n<div class="col-w-all col-w-100 d-f" style="border-bottom: solid 1px #999898;">\n    <div class="col-w-all col-w-100 d-f">\n        <div class="col-w-1 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n        </div>\n        <div class="col-w-1"></div>\n        <div class="col-w-10 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n            ' +
((__t = ( _.i18n('sample.result.target') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-1"></div>\n        <div class="col-w-4 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n            ' +
((__t = ( _.i18n('sample.result.result') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-1"></div>\n        <div class="col-w-4 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n            ' +
((__t = ( _.i18n('sample.result.result') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-1"></div>\n        <div class="col-w-10 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n            ' +
((__t = ( _.i18n('sample.result.sampleResultDefinition') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-1"></div>\n        <div class="col-w-10 noWrapHidden css-samplePrepWellInfo t-a-l p-r-15">\n            ' +
((__t = ( _.i18n('sample.result.comment') )) == null ? '' : __t) +
'\n        </div>\n    </div>\n</div>';

}
return __p
};});

