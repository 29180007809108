define('entities/caccounts/assayconfiguration',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational',
    'entities/caccounts/assayConfigurationResult',
    'entities/caccounts/colorCompensations'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.AssayConfiguration = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/assayConfigurations/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'results': [],
            'cyclers': [],
            'refColorCompensation': null
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'AssayConfigurationResult',
            collectionType: 'AssayConfigurationResultCollection',
            reverseRelation: {
                key: 'assayConfiguration',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'cyclers',
            relatedModel: 'Cycler',
            collectionType: 'CyclerCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refColorCompensation',
            relatedModel: 'ColorCompensation'
        }],

        getImageUid: function () {
        },

        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayConfigurationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayConfiguration',
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.AssayConfigurationCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/assayConfigurations/');
        },
        model: app.ns.AssayConfiguration
    });

    module.exports = _.defaults({
        getName: function () {
            return 'assayConfiguration';
        },
        getUrl: function () {
            return 'v2/assayConfigurations';
        },
        getDynamicJson: function () {
            return 'AssayConfigurationJson';
        },
        getJqGridColumn: function () {
            return new Error('can\'t get grid column');
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'code';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'code';
            configuration.urlPath = (config.url ? config.url : this.getUrl()) + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'assay.code'
            }));

            configuration.valueKey = config.valueKey ? config.valueKey : 'code';
            return configuration;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.AssayConfiguration.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': []};
        },
        getListConfiguration: function (model) {
            var defer = $.Deferred();
            var resp = [];
            resp.push({
                fieldName: 'cyclers',
                values: model.get('cyclers').model.prototype.getEntity().findAll()
            });
            defer.resolve(resp);
            return defer.promise();
        }
    }, Dynamic);
});
