define('kitLotsExtractionValidationView',[
	'module',
	'dialogFormView',
	'template!kitLotsExtractionValidationTpl',
	'savingBehavior',
	'underscore',
	'jquery',
	'settings',
	'dynamicCreateEditDescriptionComment',
	'moment',
	'backbone',
	'entities/caccounts/userpids',
	'autocompleteView'
], function (
	module,
	DialogFormView,
	Tpl,
	SavingBehavior,
	_,
	$,
	Settings,
	DynamicCreateEditDescriptionComment,
	moment,
	Backbone,
	Userpids,
	AutocompleteView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		ui: {
			input: '.js-info-input',
			cancel: '.js-cancel-popup',
			confirm: '.js-confirm',
			validated: '.js-datePicker'
		},

		events: {
			'click .js-cancel-popup': 'onCancel',
			'click .js-confirm': 'onConfirm',
			'change @ui.validated': 'onChangeDate'
		},

		regions: {
			descriptionRegion: '.js-description',
			validationByRegion: '.js-validationBy'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		serializeData: function () {
			return {
				validated: this.model.get('validated') ? moment(this.model.get('validated')).format('DD-MM-YYYY') : null,
				validatedBy: this.model.get('validatedBy')
			};
		},

		onConfirm: function () {
			this.model.save();
		},

		onSave: function () {
			this.triggerMethod('saved');
			this.box.modal('hide');
		},

		onCancel: function () {
			this.model.fetch().done(_.bind(function () {
				this.box.modal('hide');
			}, this));
		},

		onSaveError: function (model, response) {
			if (response.status === 409) {
				this.onDuplicateCode();
				this.triggerMethod('hide:label');
			}
		},

		onShow: function () {
			$.material.init();
			this.setPermissions(Settings.get('currentUserModel').hasType('KIT0') && Settings.get('currentUserModel').hasRole('DASHBOARD_ADMIN_KIT'));
		},

		onRender: function () {
			this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionComment({
				'model': this.model
			}));
			var autocomplete = new AutocompleteView(this._getAutocompleteOptionObject(Userpids.getAutocompleteParam({
				modelProperty: 'validatedBy',
				valueProperty: 'nickName',
				queryProperty: 'nickName',
				valueKey: 'nickName',
				callBackOnChange: this.onValidatedByChange
			})));
			this.getRegion('validationByRegion').show(autocomplete);
			this.initDatePicker(this.ui.validated, 'validated', true);
		},
		onValidatedByChange: function (fieldName, model) {
			model = model === null ? null : model.toJSON();
			this.onChange(fieldName, model);
		},
		onChangeDate: function (e) {
			this.onChange('validated', e.currentTarget.value);
		}
	});
});
