define('entities/global/logs',[
    'app',
    'jquery',
    'underscore',
    'backbone',
    'settings',
    'module',
    'dynamic',
    'backboneRelational'
], function (
    App,
    $,
    _,
    Backbone,
    Settings,
    module,
    Dynamic
) {
    'use strict';

    /**
     * Model
     */
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.SystemLog = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'global/system/logs/' + this.get('filename'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {},

        url: function () {
            return Settings.url('compuzz', 'global/system/logs/' + this.get('filename'));
        },

        idAttribute: 'filename',
        getEntity: function () {
            return module.exports;
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'systemLog';
        },
        getUrl: function () {
            return 'global/system/logs';
        },
        getDynamicJson: function () {
            return 'LogJson';
        },
        getTypeDynamicJson: function () {
            return 'GLOBAL';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.selectable = false;
            config.deletable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = [];
            columns.push({
                label: _.i18n('common.download'),
                name: 'databaseExist',
                classes: 'dynamic-link',
                formatter: _.bind(this.downloadFileFormatter, this),
                search: false,
                width: 20,
                onClick: _.bind(this.downloadFileClick, this)
            }, {
                label: _.i18n('common.filename'),
                name: 'filename',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'filename',
                key: true,
                searchoptions: {size: 10}
            });
            return columns;
        },


        downloadFileFormatter: function (cellValue, options) {
            cellValue = '<span class="mdi mdi-download-circle clickable"></span>';

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },


        downloadFileClick: function (rowId) {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('compuzz', 'global/system/logs/' + rowId);
            var xhr = new XMLHttpRequest();

            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';

            xhr.onreadystatechange = function () {
                loader.hide();
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentDispositionHeader = xhr.getResponseHeader('Content-Disposition');
                        // Expression régulière pour extraire le nom du fichier
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(contentDispositionHeader);

                        // Récupérer le nom du fichier depuis les correspondances
                        var fileName = matches[1].replace(/['"]/g, '');

                        var blob = new Blob([xhr.response]);
                        var url = window.URL.createObjectURL(blob);

                        var a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = fileName;

                        document.body.appendChild(a);
                        a.click();

                        window.URL.revokeObjectURL(url);

                        alert('Your file has downloaded!');
                    } else {
                        alert('Error downloading file. Status: ' + xhr.status);
                    }
                }
            };

            xhr.send();

        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.SystemLog.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': []};
        }
    }, Dynamic);
});
