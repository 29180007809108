
define('template!prepRunEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container edit-run-popup">\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="col-xs-12 p-l-0 p-r-0">\n\t\t\t\t<div class="row edit-run-popup-header">\n\t\t\t\t\t<div class="col-w-all col-w-100 d-b p-l-15 p-r-15">\n\t\t\t\t\t\t<div class="col-w-all col-w-100 d-f">\n\t\t\t\t\t\t\t<div class="col-w-all col-w-29 "> <!--style="text-align: left;"-->\n\t\t\t\t\t\t\t\t<!--mainHeaderBoxShadow-->\n\t\t\t\t\t\t\t\t<!--border: solid 1px black; border-radius: 5px; background-color: inherit;-->\n\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0"\n\t\t\t\t\t\t\t\t\t style="height: 74px;">\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12"\n\t\t\t\t\t\t\t\t\t\t style="justify-content: end; display: flex; margin-top: 4px;">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( wellsSizePlateIcon )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12" data-field-name="refRespUser"\n\t\t\t\t\t\t\t\t\t\t style="justify-content: end; display: flex; margin-top: 8px;">\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-7 p-l-0 p-r-0 t-a-r dataLabelContainer">\n\t\t\t\t\t\t\t\t\t\t\t<div class="form-group">\n\t\t\t\t\t\t\t\t\t\t\t\t<label class="control-label dataLabel noWrapHidden">' +
((__t = (
													_.i18n('user') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-5 p-r-0 p-l-0">\n\t\t\t\t\t\t\t\t\t\t\t<!--border: solid 1px #333;-->\n\t\t\t\t\t\t\t\t\t\t\t<div class="js-select-respUser-region"\n\t\t\t\t\t\t\t\t\t\t\t\t style="background-color: white; position: relative;"></div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t<div class="col-w-all col-w-1"></div>\n\n\t\t\t\t\t\t\t<div class="col-w-all col-w-40 noWrapHidden" style="text-align: left;">\n\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0 mainHeaderBoxShadow"\n\t\t\t\t\t\t\t\t\t style="border: solid 1px black; border-radius: 5px; background-color: inherit;">\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0">\n\t\t\t\t\t\t\t\t\t\t<!-- runName Line --------------------------------------------------->\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-10 p-l-5 p-r-5 noWrapHidden pop-mainHeaderRunBox">\n\t\t\t\t\t\t\t\t\t\t\t<input class="noWrapHidden form-control floating-label js-info-input edit-run-popup-header-runName-input"\n\t\t\t\t\t\t\t\t\t\t\t\t   style="border: solid 1px #333 !important;"\n\t\t\t\t\t\t\t\t\t\t\t\t   type="text"\n\t\t\t\t\t\t\t\t\t\t\t\t   id="runName-popupField-to-clipboard"\n\t\t\t\t\t\t\t\t\t\t\t\t   data-field-name="name"\n\t\t\t\t\t\t\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t\t   title="' +
((__t = ( name )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t\t   data-content=""\n\t\t\t\t\t\t\t\t\t\t\t\t   data-placement="top" autocomplete="off">\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0" style="display: flex; margin-top: 10px;">\n\t\t\t\t\t\t\t\t\t\t\t<div class="headerRunActionButton edit-run-popup-header-action-button"\n\t\t\t\t\t\t\t\t\t\t\t\t style="margin-right: 2px;">\n                                                    <span class="hidden-print js-copyParameters mdi-content-content-copy\n                                                    headerRunActionButtonIcon edit-run-popup-header-action-copy-to-clipboard"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t  data-copy="' +
((__t = ( name )) == null ? '' : __t) +
'">\n                                                    </span>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t<div class="headerRunActionButton edit-run-popup-header-action-button">\n                                                     <span class="clickable hidden-print js-edit-runName mdi-content-create headerRunActionButtonIcon\n                                                            edit-run-popup-header-action-edit-runName"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t   title="' +
((__t = ( _.i18n('edit.run.name') )) == null ? '' : __t) +
'">\n                                                    </span>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t<!-- run list line --------------------------------------------------->\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0" style="height: 38px;">\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-10 p-l-5 p-r-5 noWrapHidden">\n\t\t\t\t\t\t\t\t\t\t\t<div class="edit-run-popup-header-runName-list">\n\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.pluck(pcrRuns,'code') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-5" style="height: 17px; margin-top: 8px;">\n\t\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t\t<span class="jqgrid-statusNb" style="bottom:10px; font-size: 12px;">' +
((__t = ( status )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="cell-default js-status-tooltip"\n\t\t\t\t\t\t\t\t\t\t\t\t\t style="display: flex; position: relative; top: 3px;">\n\t\t\t\t\t\t\t\t\t\t\t\t\t';
 for (var i = 1; i <= 5; i++) { ;
__p += '\n\t\t\t\t\t\t\t\t\t\t\t\t\t<div class="jqgrid-Header-statusIcon' +
((__t = ( i )) == null ? '' : __t) +
' ' +
((__t = ( i === status || i < status || status === 5 ? 'statusIcon-upTo' : '')) == null ? '' : __t) +
'"></div>\n\t\t\t\t\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t<div class="col-w-all col-w-1"></div>\n\n\t\t\t\t\t\t\t<div class="col-w-all col-w-29 "> <!--style="text-align: left;"-->\n\t\t\t\t\t\t\t\t<!--mainHeaderBoxShadow-->\n\t\t\t\t\t\t\t\t<!--border: solid 1px black; border-radius: 5px; background-color: inherit;-->\n\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0" style="height: 74px;">\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12 t-a-l" style="margin-top: 20px;">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( dpgs )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12 t-a-l" style="margin-top: 2px;">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( creatDateToDisplay )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<hr class="m-b-10 m-t-10">\n\t\t\t\t<div class="row">\n\t\t\t\t\t<div class="col-w-all col-w-100 d-b p-l-15 p-r-15">\n\t\t\t\t\t\t<div class="col-w-all col-w-100 d-f" style="height: 18px;">\n\t\t\t\t\t\t\t<div class="col-w-all col-w-5 p-l-15 p-r-15 noWrapHidden"\n\t\t\t\t\t\t\t\t data-field-name="toBePooled"\n\t\t\t\t\t\t\t\t style="text-align: left;">\n\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.toBePooled') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-5"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-15 p-l-15 p-r-15 noWrapHidden"\n\t\t\t\t\t\t\t\t style="text-align: left;">\n\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label extraction">' +
((__t = (
										_.i18n('refLmbExtractor.code')
										)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-5"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-20 p-l-15 p-r-15 noWrapHidden" style="text-align: left;">\n\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label extraction">' +
((__t = (
										_.i18n('refExtractionMethod.code') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-5"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-20 p-l-15 p-r-15 noWrapHidden"\n\t\t\t\t\t\t\t\t data-field-name="plateIdDW"\n\t\t\t\t\t\t\t\t style="text-align: left;">\n\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.dw') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-5"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-30 p-l-15 p-r-15 noWrapHidden"\n\t\t\t\t\t\t\t\t data-field-name="plateIdEL"\n\t\t\t\t\t\t\t\t style="text-align: left;">\n\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.el') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class="row">\n\t\t\t\t\t<div class="col-w-all col-w-100 d-b p-l-15 p-r-15">\n\t\t\t\t\t\t<div class="col-w-all col-w-100 d-f">\n\t\t\t\t\t\t\t<div class="col-w-all col-w-1"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-3">\n\t\t\t\t\t\t\t\t<input type="checkbox" class="form-control js-info-input"\n\t\t\t\t\t\t\t\t\t   data-field-name="toBePooled"\n\t\t\t\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t   title="' +
((__t = ( toBePooled )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t   data-content=""\n\t\t\t\t\t\t\t\t\t   data-placement="top" autocomplete="off"\n\t\t\t\t\t\t\t\t' +
((__t = ( toBePooled? 'checked="checked"' :'' )) == null ? '' : __t) +
'>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-1"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-5"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-15">\n\t\t\t\t\t\t\t\t<span class="extraction">\n\t\t\t\t\t\t\t\t' +
((__t = ( extractionDetails.refLmbExtractor && extractionDetails.refLmbExtractor.code ?
								extractionDetails.refLmbExtractor.code : '' )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-5"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-20">\n\t\t\t\t\t\t\t\t<span class="extraction">\n\t\t\t\t\t\t\t\t' +
((__t = ( extractionDetails.refExtractionMethod &&
								extractionDetails.refExtractionMethod.code ?
								extractionDetails.refExtractionMethod.code : ''
								)) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-5"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-20">\n\t\t\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input js-code"\n\t\t\t\t\t\t\t\t\t   data-field-name="plateIdDW"\n\t\t\t\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t   title="' +
((__t = ( plateIdDW? plateIdDW : '' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t   value="' +
((__t = ( plateIdDW? plateIdDW :'' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t   data-content=""\n\t\t\t\t\t\t\t\t\t   data-placement="top" autocomplete="off">\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-5"></div>\n\t\t\t\t\t\t\t<div class="col-w-all col-w-30">\n\t\t\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input js-code"\n\t\t\t\t\t\t\t\t\t   data-field-name="plateIdEL"\n\t\t\t\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t   title="' +
((__t = ( plateIdEL? plateIdEL : '' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t   value="' +
((__t = ( plateIdEL? plateIdEL: '' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t   data-content=""\n\t\t\t\t\t\t\t\t\t   data-placement="top" autocomplete="off">\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<hr class="m-b-10 m-t-10">\n\n\t\t\t\t<div class="row ">\n\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-6 p-t-2">\n\t\t\t\t\t\t\t<div>' +
((__t = ( assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-94 css-prepRun-listView-assayWaiting">\n\t\t\t\t\t\t\t<div class="noWrapHidden">' +
((__t = ( assaysToDisplay.waitingWithCount )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<!--% } %-->\n\t\t\t\t\t<!--% if(assaysToDisplay.running !== \'\') { %-->\n\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-6 p-t-2">\n\t\t\t\t\t\t\t<div>' +
((__t = ( assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-94 css-prepRun-listView-assayRunning">\n\t\t\t\t\t\t\t<div class="noWrapHidden">' +
((__t = ( assaysToDisplay.runningWithCount )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-6 p-t-2">\n\t\t\t\t\t\t\t<div>' +
((__t = ( assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-94 css-prepRun-listView-assayDone">\n\t\t\t\t\t\t\t<div class="noWrapHidden">' +
((__t = ( assaysToDisplay.doneWithCount )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<hr class="m-b-10 m-t-10">\n\t\t\t\t<div class="row">\n\t\t\t\t\t<fieldset class="roundedFieldset col-xs-6">\n\t\t\t\t\t\t<legend class="leftPositionedLegend">' +
((__t = ( _.i18n('runs.pcrruns') )) == null ? '' : __t) +
'</legend>\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-100">\n\t\t\t\t\t\t\t<div style="overflow-x: hidden; margin-left: 10px; overflow-y: auto; width: inherit;">\n\t\t\t\t\t\t\t\t<div style="display: contents;">\n\t\t\t\t\t\t\t\t\t';
 _.each(pcrRuns, function (run) { ;
__p += '\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-70 js-textToCopy">' +
((__t = ( run.name
											)) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-15">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-content-copy js-copyParameters clickable"\n\t\t\t\t\t\t\t\t\t\t\t\t data-copy="' +
((__t = ( run.name )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t\t style="font-size: 16px;"></span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</fieldset>\n\t\t\t\t\t<fieldset class="roundedFieldset col-xs-6">\n\t\t\t\t\t\t<legend class="leftPositionedLegend">' +
((__t = ( _.i18n('runs.pooled') )) == null ? '' : __t) +
'</legend>\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-100">\n\t\t\t\t\t\t\t<div style="overflow-x: hidden; margin-left: 10px; overflow-y: auto; width: inherit;">\n\t\t\t\t\t\t\t\t<div style="display: contents;">\n\t\t\t\t\t\t\t\t\t';
 _.each(prepRunPooled, function (run) { ;
__p += '\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-70 js-textToCopy">' +
((__t = ( run.name
											)) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-15">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-content-copy js-copyParameters clickable"\n\t\t\t\t\t\t\t\t\t\t\t\t data-copy="' +
((__t = ( run.name )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t\t style="font-size: 16px;"></span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</fieldset>\n\t\t\t\t</div>\n\t\t\t\t<!--% } %-->\n\t\t\t\t<hr class="m-b-10 m-t-10">\n\n\t\t\t\t<div class="row" style="margin-bottom: 20px;">\n\t\t\t\t\t<div class="col-xs-12 commentOrDescriptionContainer" data-field-name="comment"\n\t\t\t\t\t\t style="margin-top:0;">\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-6">\n\t\t\t\t\t\t\t<span class="mdi mdi-message-reply-text commentOrDescriptionIcon">\n\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-94 commentOrDescriptionInputDiv">\n                                    <textarea class="form-control floating-label js-info-input inputBackground"\n\t\t\t\t\t\t\t\t\t\t\t  data-field-name="comment"\n\t\t\t\t\t\t\t\t\t\t\t  rows="2"\n\t\t\t\t\t\t\t\t\t\t\t  style="resize: vertical;"\n\t\t\t\t\t\t\t\t\t\t\t  placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t  wrap="on">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class="row cancelConfirmRow">\n\t\t\t\t\t<div class="pull-right p-r-15 col-xs-12 p-l-0 p-r-0 cancelConfirmDiv"\n\t\t\t\t\t\t style="padding-left: 606px !important;">\n\t\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel\n                    cancel-b js-cancel-popup"></button>\n\t\t\t\t\t\t<button class="mdi-navigation-check btn popupBtn-confirm\n                    js-confirm"></button>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

