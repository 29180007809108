
define('template!assayVersionCreateEditLayout', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="jq-grid-table-page">\n    <div class="assay-table">\n        <div class="detailed-info-form-container">\n            <div class="saving-label"></div>\n            <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n                <div class="error-msg"></div>\n            </div>\n            <div class="form-horizontal">\n                <div class="form-inputs-container">\n                    <div class="form-group">\n\n                        <label class="col-xs-2 control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.assay') )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-2" style="display: flex;">\n                            <input type="text"\n                                   class="form-control floating-label js-code inputBackground"\n                                   placeholder=""\n                                   value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                                   data-placement="top" autocomplete="off">\n                            <span class="clickable btn-edit-assay mdi mdi-eye headerRunActionButtonIcon"\n                                  style="line-height: 22px; font-size: 23px;"></span>\n                        </div>\n                        <div class="col-xs-3">\n                            <input type="text"\n                                   class="form-control floating-label inputBackground"\n                                   placeholder="" data-placement="top"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                                   value="' +
((__t = ( name?name:'' )) == null ? '' : __t) +
'" autocomplete="off">\n                        </div>\n\n                        <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('assay.groupName') )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-2">\n                            <input type="text"\n                                   class="form-control floating-label inputBackground"\n                                   placeholder=""\n                                   value="' +
((__t = ( assayGroup ? assayGroup.code : '' )) == null ? '' : __t) +
'"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                                   data-placement="top" autocomplete="off">\n                        </div>\n                    </div>\n\n                    <div class="form-group">\n\n                        <label class="col-xs-2 control-label dataNameIdLabel">' +
((__t = ( _.i18n('menu.admin.kitprots')
                            )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-2">\n                            <input type="text"\n                                   class="form-control floating-label inputBackground"\n                                   placeholder=""\n                                   value="' +
((__t = ( kitProt ? kitProt.code : '' )) == null ? '' : __t) +
'"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                                   data-placement="top" autocomplete="off">\n                        </div>\n\n                        <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('assay.protocolVariant')
                            )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-1">\n                            <input type="text"\n                                   class="form-control floating-label inputBackground"\n                                   data-field-name="protocolVariant" placeholder=""\n                                   value="' +
((__t = ( protocolVariant )) == null ? '' : __t) +
'"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                                   data-placement="top" autocomplete="off">\n                        </div>\n\n                        <div class="col-xs-1"></div>\n\n                        <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('assay.volumeBySample')
                            )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-1">\n                            <input type="number" step="0.01" min="0"\n                                   class="form-control inputBackground"\n                                   data-field-name="volumeBySample"\n                                   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                                   value="' +
((__t = ( volumeBySample?volumeBySample:'' )) == null ? '' : __t) +
'"/>\n                        </div>\n                    </div>\n\n                    <div class="js-assayConfiguration-region"></div>\n                </div>\n                <!-- liaison lots -->\n                <div class="col-xs-12 m-t-10 m-b-10 p-5 js-kitlots rounded">\n                </div>\n                <!-- comments row -->\n\n                <div class="js-description"></div>\n                <!-- cancel/confirm row -->\n                <div class="row">\n                    <div class="pull-right p-r-15 col-xs-12" style="text-align: end;">\n                        <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

