
define('template!error', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12">\n\t<span class="rack">' +
((__t = ( rackId )) == null ? '' : __t) +
'</span>\n\t<br/>\n\t<span class="name">' +
((__t = ( _.i18n('routingActionState.' + state + '.name') )) == null ? '' : __t) +
'</span>\n</div>\n<div class="col-xs-12 text-center m-b-0">\n\t<button class="btn btn-danger btn-block validProcess height-100"\n\t' +
((__t = ( showPopup ? '' : 'style="display: none;"' )) == null ? '' : __t) +
'>' +
((__t = (
	_.i18n('routingAction.validProcess') )) == null ? '' : __t) +
'</button>\n</div>';

}
return __p
};});

