/**
 * Created by RKL on 20/08/2015.
 */
define('autocompleteView',[
    'module',
    'marionette',
    'marionetteAutocomplete',
    'underscore',
    'jquery',
    'settings',
    'template!autocompleteView',
    'cookies',
    'bootbox',
    'backbone'
], function (
    module,
    Marionette,
    MarionetteAutocomplete,
    _,
    $,
    Settings,
    viewTpl,
    Cookies,
    Bootbox,
    Backbone
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: viewTpl,
        className: 'autocomplete-dropdown-container-css',
        attributes: {
            style: 'display: flex'
        },
        ui: {
            autocomplete: 'input',
            editRecord: '.js-edit-record'
        },
        events: {
            'change @ui.autocomplete': 'onAutocompleteChange',
            'click @ui.editRecord': 'onEditRecord',
            'click span.mdi.mdi-filter-variant': 'onToggleDropdown',
            'focusout @ui.autocomplete': 'onFocusOut'
        },

        initialize: function () {
            this.listenTo(this, 'autocomplete:selected', _.bind(this.onAutocompleteSelect, this));
            this.listenTo(this, 'autocomplete:shown', _.bind(this.onAutocompleteShown, this));
            this.listenTo(this, 'autocomplete:hidden', _.bind(this.onAutocompleteHidden, this));
            this.listenTo(this, 'autocomplete:active', _.bind(this.onAutocompleteActive, this));
        },

        onToggleDropdown: function (e) {
            e.stopPropagation();
            this.ui.autocomplete.dropdown('toggle');
        },

        onAutocompleteHidden: function () {
            this.onAutocompleteChange();
        },

        onFocusOut: function () {
            setTimeout(_.bind(function () {
                if (!this.isDestroyed && this.ui.autocomplete.attr('aria-expanded') === 'true') {
                    this.ui.autocomplete.dropdown('toggle');
                }
            }, this), 300);
        },

        onAutocompleteActive: function () {
            var element = this._behaviors[0].collectionView.$el;
            var elementActive = element.find('li.active');
            if (elementActive.length !== 0) {
                element.animate({
                    scrollTop: element.find('li.active').position().top - element.find('li:first').position().top
                }, 100);
            }
        },

        onAutocompleteShown: function () {
            var otherAutocompleteAdd = this._behaviors[0].suggestions.any(_.bind(function (model) {
                return !model.get('autocompleteAdd');
            }, this));
            if (!otherAutocompleteAdd) {
                this._behaviors[0].updateQuery(this.ui.autocomplete.val());
            }
            this.ui.editRecord.hide();
        },
        behaviors: function () {
            var that = this,
                type = this.options.type || 'dataset',
                remote = this.options.remote || null,
                keys = this.options.keys || null,
                limit = this.options.limit || 20,
                rateLimit = this.options.lazyLoad && type !== 'dataset' ? 20 : 0;

            if (this.options.readOnly) {
                return {};
            }

            var behavior = {
                AutoComplete: {
                    behaviorClass: MarionetteAutocomplete.Behavior,
                    rateLimit: rateLimit,
                    collection: {
                        'class': MarionetteAutocomplete.Collection.extend({
                            reset: function () {
                                if (that.options.canAddOrEdit) {
                                    if (arguments[0]) {
                                        arguments[0].unshift({
                                            'autocompleteValue': _.i18n('autocomplete.add'),
                                            'autocompleteAdd': true
                                        });
                                    }
                                }
                                MarionetteAutocomplete.Collection.prototype.reset.apply(this, arguments);
                            }
                        }),
                        options: {
                            minLength: -1,
                            type: type,
                            remote: remote,
                            keys: keys,
                            inputType: this.options.inputType,
                            data: this.options.data,
                            valueKey: this.options.valueKey,
                            lazyLoad: true,
                            values: {
                                apiKey: this.options.apiKey,
                                limit: limit
                            }
                        }
                    }
                }
            };

            if (this.options.entity) {
                behavior.AutoComplete.collection.model = this.options.entity.getModel();
            }

            behavior.AutoComplete.collectionView = {
                'class': MarionetteAutocomplete.CollectionView.extend({
                    emptyView: Marionette.ItemView.extend({
                        tagName: 'li',
                        template: _.template('<a>' + _.i18n('autocomplete.noSuggestionsAvailable') + '</a>')
                    })
                })
            };

            if (this.options.childTemplate) {
                behavior.AutoComplete.childView = {
                    'class': MarionetteAutocomplete.ChildView.extend({
                        getTemplate: function () {
                            if (!this.model.get('autocompleteValue')) {
                                this.model.set({autocompleteValue: 'Loading'});
                            }
                            return that.options.childTemplate;
                        },

                        serializeData: function () {
                            if (that.options.childViewSerializeData) {
                                return _.bind(that.options.childViewSerializeData, this)();
                            }
                            var jsonData = this.model.toJSON();

                            // Traitement pour échapper les caractères spéciaux
                            _.each(jsonData, function (value, key) {
                                if (_.isString(value)) {
                                    jsonData[key] = _.escape(value);
                                }
                            });

                            return jsonData;
                        }
                    })
                };
            }
            if (this.options.fieldsToDisplay) {
                behavior.AutoComplete.childView = {
                    'class': MarionetteAutocomplete.ChildView.extend({
                        getTemplate: function () {
                            if (this.model.get('autocompleteAdd')) {
                                return _.template(
                                    '<span class="mdi mdi-plus" style="font-size: 20px; line-height: 20px;"></span>'
                                );
                            }

                            if (this.model.get('autocompleteValue') === 'Loading') {
                                return _.template(
                                    '<a href="#"><%= autocompleteValue %></a>'
                                );
                            }

                            $('.ac-suggestions').width(that.options.fieldsToDisplay.fieldsWidth ? that.options.fieldsToDisplay.fieldsWidth : '400');
                            $('ul.ac-suggestions').css('border', '1px solid #11ABC3');

                            // prevent html display issue like for pattern userCodeList (ex: <D><P><G><S>)
                            var str = this.model.get('autocompleteValue');
                            if (str && (str.includes('<') || str.includes('>'))) {
                                str = str.replace(new RegExp(/</g, 'g'), '&lt;');
                                str = str.replace(new RegExp(/>/g, 'g'), '&gt;');
                            }
                            this.model.set('autocompleteValueToDisplay', str);

                            var label = '<span style="font-weight: bolder;color:black"><%= ' + that.options.valueProperty + ' %></span>';
                            var separator = '|';
                            var count = 0;
                            var colorClass;
                            var template = '';

                            this.$el.attr('title', this.model.get('description'));
                            this.$el.css('display', 'flex');

                            _.each(that.options.fieldsToDisplay.fields, _.bind(function (field, index) {
                                colorClass = (count % 2 === 0) ? 'autocompleteHighlightValueOdd' : 'autocompleteHighlightValue';
                                if (index !== 0) {
                                    label += '<span style="font-weight: bolder;color:black">' + separator + '</span>';
                                }

                                label += '<span class="' + colorClass + '">  ';

                                if (!field.type) {
                                    var subFields = field.split('.'), fullField = '', parent = '';
                                    if (subFields.length === 1) {
                                        fullField = field;
                                    } else {
                                        _.each(subFields, function (subField, index) {
                                            if (index > 0) {
                                                fullField += '.' + subField;
                                            } else {
                                                fullField = subField;
                                                parent = subField;
                                            }
                                        });
                                    }

                                    if (parent !== '') {
                                        label += '<%= (typeof ' + parent + ' !== undefined && ' + parent + ' !== null) ? ' + fullField + ': \'\'%>  ';
                                    } else {
                                        label += '<%= ' + fullField + ' %>';
                                    }
                                }

                                if (field.type) {
                                    switch (field.type) {
                                        case 'array[object]':
                                            var arrayObjFields, arrayObjFullField = '', arrayObjParentField = '';
                                            if (field.fields) {
                                                arrayObjFields = field.fields.split('.');
                                                _.each(arrayObjFields, function (field, index) {
                                                    if (index > 0) {
                                                        arrayObjFullField += '.' + field;
                                                    } else {
                                                        arrayObjFullField = field;
                                                        arrayObjParentField = field;
                                                    }
                                                });
                                                label +=
                                                    '<% _.each(' + field.property + ', function(obj, index) { %> ' +
                                                    '<% if(index !== 0){ %>' + ';' + '<% } %>' +
                                                    '<% if(obj.' + arrayObjParentField + '){ %>' +
                                                    '<%= obj.' + arrayObjFullField + '%>' +
                                                    '<% } %>' +
                                                    '<% }); %>';
                                            }
                                            break;

                                        case 'array[customAssayObject]':
                                            label +=
                                                '<% _.each(' + field.property + ', function(obj, index) { %> ' +
                                                '<% if(index !== 0){ %>' + ';&nbsp;' + '<% } %>' +
                                                '<% if(obj.assay){ %>' +
                                                '<%= obj.assay.code %>' +
                                                '<% if(obj.count){ %>' +
                                                '<%= \'&nbsp;(\' + obj.count + \')\'  %>' +
                                                '<% } %>' +
                                                '<% } %>' +
                                                '<% }); %>';
                                            break;

                                        case 'string':
                                            if (field.property &&
                                                field.property !== '') {
                                                label += '<%=' + field.property + '?' + field.property + ': \'\'%>  ';
                                            }
                                            break;

                                        case 'object.string':
                                            if (field.property && field.property !== '' &&
                                                field.fields) {
                                                var objStrPropertyFields = field.fields.split('.'),
                                                    objStrFields = '', request;
                                                _.each(objStrPropertyFields, function (field, index) {
                                                    if (index > 0) {
                                                        objStrFields += '.' + field;
                                                    } else {
                                                        objStrFields = field;
                                                    }
                                                });
                                                request = field.property + (objStrFields ? '.' + objStrFields : '');
                                                label += '<%= ' + field.property + ' ? ' + request + ': \'\'%>  ';
                                            }
                                            break;

                                        default:
                                    }
                                }

                                label += '</span>';
                                count++;
                            }, this));

                            template += '<span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;"> ' +
                                label + ' </span>';

                            return _.template(template);
                        },

                        serializeData: function () {
                            if (that.options.childViewSerializeData) {
                                return _.bind(that.options.childViewSerializeData, this)();
                            }
                            var jsonData = this.model.toJSON();

                            // Traitement pour échapper les caractères spéciaux
                            _.each(jsonData, function (value, key) {
                                if (_.isString(value)) {
                                    jsonData[key] = _.escape(value);
                                }
                            });

                            return jsonData;
                        }
                    })
                };
            }
            return behavior;
        },

        serializeData: function () {
            return {
                placeholder: this.options.placeholder || null,
                value: this.options.value || null,
                fieldName: this.options.name || null
            };
        },
        onAutocompleteChange: function () {
            clearTimeout(this.timer);
            this.timer = setTimeout(_.bind(function () {
                if (!this.isDestroyed) {
                    this._onChange();
                }
            }, this), 300);
        },
        _onChange: function () {
            if (this.addNewEntity) {
                this.addNewEntity = false;
                if (!this.options.entity) {
                    console.log('autocomplete : no entity on ' + this.ui.autocomplete.data('field-name'));
                    return;
                }
                this.options.entity.onAdd(_.bind(function () {
                    if (this.options.callbackAdd) {
                        this.options.callbackAdd(arguments);
                    }
                    this._behaviors[0].collectionView.collection.reset();
                }, this));
                return;
            }
            this.options.value = this.ui.autocomplete.val();
            var callback = this.options.callback;
            if (!callback) {
                console.log('autocomplete : no callback on ' + this.ui.autocomplete.data('field-name'));
                return;
            }
            if (this.ui.autocomplete.val() && !this._behaviors[0].collectionView.collection.isEmpty()) {
                var model = _.first(_.filter(this._behaviors[0].collectionView.collection.models, _.bind(function (model) {
                    if (model instanceof Backbone.Model) {
                        return model.get(this.options.valueKey) && model.get(this.options.valueKey).toLowerCase() === this.ui.autocomplete.val().toLowerCase();
                    }
                    return model[this.options.valueKey] && model[this.options.valueKey].toLowerCase() === this.ui.autocomplete.val().toLowerCase();
                }, this), this));
                if (!model && this._behaviors[0].collectionView.collection.index !== -1) {
                    model = this._behaviors[0].collectionView.collection.currentModel;
                }
                if (model) {
                    this.ui.autocomplete.val(model.get(this.options.valueKey));
                }
                callback(this.ui.autocomplete.data('field-name'), model, this, false);
            } else {
                callback(this.ui.autocomplete.data('field-name'), null, this, false);
            }
            if (!this.isDestroyed) {
                if (this.model && this.model instanceof Backbone.Model && this.options.canAddOrEdit && (!this.options.entity || this.options.entity.showEditAdd)) {
                    this.ui.editRecord.show();
                } else {
                    this.ui.editRecord.hide();
                }
            }
        },
        onAutocompleteSelect: function (suggestion) {
            this.model = suggestion;
            if (this.model && this.model.get) {
                this.ui.autocomplete.val(this.model.get(this.options.valueKey));
            } else {
                this.ui.autocomplete.val('');
            }
            this.addNewEntity = !!this.model.get('autocompleteAdd');
            this.ui.autocomplete.change();
            this.ui.autocomplete.dropdown('toggle');
        },
        onRender: function () {
            if (this.options.remote && !!Cookies.get('gm-autocomplete')) {
                Bootbox.alert({
                    title: _.i18n('menu.superadmin.godMode'),
                    message: this.options.remote + '&' + this.options.valueKey + '=' + this.ui.autocomplete.val()
                });
            }
            if (this.options.canAddOrEdit === undefined && this.model && this.model instanceof Backbone.Model && this.model.getEntity && this.model.getEntity().canAddOrEdit) {
                this.options.canAddOrEdit = this.model.getEntity().canAddOrEdit();
            }
            if (this.model && this.model instanceof Backbone.Model && this.options.canAddOrEdit && (!this.options.entity || this.options.entity.showEditAdd)) {
                this.ui.editRecord.show();
            }
            this.ui.autocomplete.attr('readOnly', this.options.readOnly);
            if (this.options.readOnly) {
                this.ui.autocomplete.attr('disabled', 'disabled');
            }
        },
        onEditRecord: function () {
            this.model.getEntity().onEdit(this.options.callbackEdit, this.model.get('secId'));
        }
    });
});
