
define('template!prepRunCreatePrimaryPooling', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container" style="width: 500px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n\n            <div class="form-group">\n                <label class="col-xs-4 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                    _.i18n('pooling.primary.startPos') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">\n                    <input type="text" class="js-info-input" data-field-name="posStart"\n                           style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                           data-placement="top"\n                           onkeyup="this.value = this.value.toUpperCase();">\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-4 popupTitleLabel" style="text-align: right;">' +
((__t = (
                    _.i18n('pooling.primary.sourcePrepRun') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-8">\n                    <div class="js-select-prepRunSource-region"></div>\n                </div>\n            </div>\n\n            <div class="form-group">\n                <div class="col-xs-1"></div>\n                <label class="col-xs-3 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                    _.i18n('pooling.primary.sourcePosFrom') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">\n                    <input type="text" class="js-info-input" data-field-name="posFrom"\n                           style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                           data-placement="top"\n                           onkeyup="this.value = this.value.toUpperCase();">\n                </div>\n                <label class="col-xs-3 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                    _.i18n('pooling.primary.sourcePosTo') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">\n                    <input type="text" class="js-info-input" data-field-name="posTo"\n                           style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                           data-placement="top"\n                           onkeyup="this.value = this.value.toUpperCase();">\n                </div>\n            </div>\n\n            <div class="form-group">\n                <label class="col-xs-4 popupTitleLabel" style="text-align: right;">' +
((__t = (
                    _.i18n('pooling.primary.schemaPooling') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">\n                    <div class="js-plate-direction-region"></div>\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-4 popupTitleLabel" style="text-align: right;">' +
((__t = (
                    _.i18n('pooling.primary.depth') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">\n                    <input type="text" class="form-control floating-label js-info-input"\n                           data-field-name="depth" placeholder="">\n                </div>\n            </div>\n        </div>\n\n        <div class="row cancelConfirmRow">\n            <div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                <button disabled\n                        class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

