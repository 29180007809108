
define('template!prepRunWellTableTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class=""><!--content-->\n    <div class="well jq-grid-table-page">\n        <div class="row">\n        </div>\n        <div class="kitl-table">\n            <table id="jq-grid-list" class="wellTable" role="grid"></table>\n        </div>\n    </div>\n\n    <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

