
define('template!prepRunDisplayKitsLotsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row col-xs-12 m-b-10">\n    <div class="col-xs-1 noWrapHidden">\n        <div style="border: solid 1px black; min-height: 22px; text-align: center; width: 45px;">' +
((__t = ( pos ? pos : ' ' )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <div class="col-xs-4 noWrapHidden">\n        <div class="p-l-5" style="border: solid 1px black; min-height: 22px;">' +
((__t = ( smpId.name )) == null ? '' : __t) +
'</div>\n    </div>\n</div>\n\n<div class="row col-xs-12 m-b-10">\n    <div class="col-xs-12 primaryBckGrdColor">\n        <div class="col-xs-2 noWrapHidden">' +
((__t = ( _.i18n('extraction.reagent.reagentType') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-2 noWrapHidden">' +
((__t = ( _.i18n('extraction.reagent.kit') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-5 noWrapHidden">' +
((__t = ( _.i18n('extraction.reagent.litlot') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-1 noWrapHidden">' +
((__t = ( _.i18n('extraction.reagent.vol.test') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-1 noWrapHidden">' +
((__t = ( _.i18n('extraction.reagent.vol.death') )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 if(extractionKitLots && extractionKitLots.length > 0) { ;
__p += '\n    ';
 _.each(extractionKitLots, function(extractionKitLot) { ;
__p += '\n    <div class="col-xs-12">\n        <div class="col-xs-2 noWrapHidden">' +
((__t = ( extractionKitLot.reagent ? extractionKitLot.reagent.code : '' )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-2 noWrapHidden">' +
((__t = ( extractionKitLot.refKit.code )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-5 noWrapHidden">' +
((__t = ( extractionKitLot.refKitLot.code )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-1 noWrapHidden">' +
((__t = ( extractionKitLot.volumeByTest )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-1 noWrapHidden">' +
((__t = ( extractionKitLot.volumeDeath )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 }); ;
__p += '\n    ';
 } else { ;
__p += '\n    <div class="col-xs-12 p-l-5 noWrapHidden">' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n</div>\n\n<div class="row col-xs-12 m-b-10">\n    <div class="col-xs-12 primaryBckGrdColor">\n        <div class="col-xs-6 noWrapHidden">' +
((__t = ( _.i18n('kitLotByKits.pcrKits') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-6 noWrapHidden">' +
((__t = ( _.i18n('kitLotByKits.pcrKitLots') )) == null ? '' : __t) +
'</div>\n    </div>\n\n    ';
 if(kitLotByKits && kitLotByKits.length > 0) { ;
__p += '\n    ';
 _.each(kitLotByKits, function(kitLot) { ;
__p += '\n    <div class="col-xs-12">\n        <div class="col-xs-6 noWrapHidden">' +
((__t = ( kitLot.pcrKit )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-6 noWrapHidden">\n            ';
 _.each(kitLot.pcrKitLots, function(pcrKitLot) { ;
__p += '\n            ' +
((__t = ( pcrKitLot )) == null ? '' : __t) +
' </br>\n            ';
 }) ;
__p += '\n        </div>\n    </div>\n    ';
 }) ;
__p += '\n    ';
 } else { ;
__p += '\n    <div class="col-xs-12 p-l-5 noWrapHidden">' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n</div>\n\n<!-- cancel/confirm row -->\n<div class="row cancelConfirmRow" style="text-align: right;">\n    <div class="pull-right p-r-15 col-xs-12 cancelConfirmAssayAddEditDiv">\n        <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n    </div>\n</div>\n';

}
return __p
};});

