define('wellPopupDisplayGraphAmpView',[
    'module',
    'dialogFormView',
    'arrayCustomCurveNoEditView',
    'arrayCriCurveNoEditView',
    'underscore',
    'jquery',
    'template!wellPopupDisplayGraphAmpView',
    'runDetailsGraphView',
    'bootbox'
], function (
    module,
    DialogFormView,
    ArrayCustomCurveNoEditView,
    ArrayCriCurveNoEditView,
    _,
    $,
    WellTargetPropCPopupTpl,
    RunGraphView,
    Bootbox
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: WellTargetPropCPopupTpl,

        regions: {
            targetAmplGraphView: '#targetAmplGraphView',
            targetAmplGraphView2: '#targetAmplGraphView2',
            selectAlgoRes: '.js-select-algores-region',
            algoParam: '.js-algoParam'
        },

        events: {
            'click .rawData.clickable': 'onRawDataClick',
            'click .fluorData.clickable': 'onFluorDataClick',
            'click .fluorDataShow.clickable': 'onFluorDataShowClick'
        },

        initialize: function () {
            var assayVersion = this.model.get('refPcrWell').get('refPcrRun').get('assayVersions').findWhere({secId: this.model.get('refPcrWell').get('assayVersion').get('secId')});
            this.resultModel = assayVersion.get('results').findWhere({id: this.model.get('refAssayResult').get('id')});
        },

        onRawDataClick: function () {
            this.model.downloadRawData();
        },

        onFluorDataClick: function () {
            this.model.downloadFluorData();
        },
        onFluorDataShowClick: function () {
            var url = this.model.showFluorData();
            fetch(url)
                .then(function (response) {
                    if (response.ok) {
                        return response.text();
                    }
                    throw new Error('Network response was not ok.');
                })
                .then(function (data) {
                    Bootbox.alert({
                        message: '<pre>' + data + '</pre>',
                        title: 'Fluorescence data',
                        className: 'noIcon'
                    });
                });
        },

        onShow: function () {
            $.material.init();
            document.addEventListener('keydown', _.bind(function (event) {
                var key = event.key;
                if (key === 'Escape' || key === 'Enter') {
                    this.box.modal('hide');
                }
            }, this));

            var targetAmplGraphView = new RunGraphView({
                type: 'line',
                procView: true,
                showPropC: true,
                algoType: this.resultModel.get('algoResult').type,
                smooth: true,
                hideExtraPoint: true
            });
            targetAmplGraphView.showPropC = true;
            targetAmplGraphView.model = this.model;
            targetAmplGraphView.slopeEnable = false;
            targetAmplGraphView.brutEnable = true;
            targetAmplGraphView.amplGraph = this.resultModel.get('algoResult').type === 'AMP';
            targetAmplGraphView.withoutCC = false;
            targetAmplGraphView.displayLegend = this.withLegend;
            targetAmplGraphView.raw = false;
            targetAmplGraphView.resultModel = this.resultModel;

            this.onShowAlgoRes();

            this.getRegion('targetAmplGraphView').show(targetAmplGraphView);
            $('.wellTargetPropC-popup #targetAmplGraphView .col-xs-12.chart-height').css('height', '300px');

            var targetAmplGraphView2 = new RunGraphView({
                type: 'line',
                procView: true,
                showPropC: true,
                algoType: this.resultModel.get('algoResult').type,
                smooth: true,
                hideExtraPoint: true
            });

            targetAmplGraphView2.model = this.model;
            targetAmplGraphView2.slopeEnable = true;
            targetAmplGraphView2.brutEnable = false;
            targetAmplGraphView2.amplGraph = this.resultModel.get('algoResult').type === 'AMP';
            if (this.options.graphToDisplay.includes('A')) {
                targetAmplGraphView2.deriv2Enable = true;
            } else if (this.options.graphToDisplay.includes('M')) {
                targetAmplGraphView2.deriv1Enable = true;
            }
            targetAmplGraphView2.withoutCC = !this.withCC;
            targetAmplGraphView2.pointStyle = this.pointStyle;
            targetAmplGraphView2.displayLegend = this.withLegend;
            targetAmplGraphView2.raw = false;
            targetAmplGraphView2.resultModel = this.resultModel;

            this.onShowAlgoRes();

            this.getRegion('targetAmplGraphView2').show(targetAmplGraphView2);
            $('.wellTargetPropC-popup #targetAmplGraphView2 .col-xs-12.chart-height').css('height', '300px');
        },

        onShowAlgoRes: function () {
            switch (this.resultModel.get('algoResult').type) {
                case 'MUTV':
                case 'AMP':
                    this.algoParamView = new ArrayCustomCurveNoEditView({
                        model: this.resultModel
                    });
                    this.getRegion('algoParam').show(this.algoParamView);
                    break;
                case 'CRI':
                    this.algoParamView = new ArrayCriCurveNoEditView({
                        model: this.resultModel
                    });
                    this.getRegion('algoParam').show(this.algoParamView);
                    break;
                case 'COMB':
                case 'COMBCUTOFF':
                case 'STD':
                    break;
                default:
                    // ArrayInputView
                    break;
            }
            this.triggerMethod('enable:cancel:confirm');
        },

        serializeData: function () {
            var templateData = {
                run: this.model.get('refPcrWell').get('runName'),
                assay: this.model.get('refPcrWell').get('refAssay') ? this.model.get('refPcrWell').get('refAssay').get('code') : '',
                pos: this.model.get('refPcrWell').get('pos'),
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : '',
                errWarn: this.model.get('codeErr') ? this.model.get('codeErr') + ' - ' + this.model.get('commentErrAuto') : '',
                iconHtml: this.model.getResultIcon(true).iconHtml
            };
            templateData.amp1 = this.model.get('propC').Amp1 ? this.model.get('propC').Amp1 : '';
            templateData.amp2 = this.model.get('propC').Amp2 ? this.model.get('propC').Amp2 : '';
            templateData.c1 = this.model.get('propC').C1 ? this.model.get('propC').C1 : '';
            templateData.c2 = this.model.get('propC').C2 ? this.model.get('propC').C2 : '';
            templateData.slopeIndex = this.model.get('propC').SI ? this.model.get('propC').SI : '';
            return templateData;
        }
    });
});
