
define('template!pcrSetupHitPickingTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container"\n     style="min-height: 150px; border: solid 1px black; border-radius: 5px; width: 800px; margin: auto;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n\n    <div class="row">\n    </div>\n\n    <div>\n        <div>onHitPicking</div>\n    </div>\n</div>';

}
return __p
};});

