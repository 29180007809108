
define('template!assayCustomJqGridView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '\t<div class="js-importFromResult clickable">\n\t\t<span class="mdi mdi-file-chart action header" title="' +
((__t = ( _.i18n('assay.importFromResult') )) == null ? '' : __t) +
'">\n\t\t</span>\n\t</div>';

}
return __p
};});

