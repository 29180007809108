define('entities/caccounts/platesize',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getUrl: function () {
            return 'v2/cyclers/public/plateSizes';
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.urlPath = (config.url ? config.url : this.getUrl()) + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'plateSize'
            }));
            configuration.entity = null;
            configuration.canAddOrEdit = false;
            return configuration;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['code']};
        }
    }, Dynamic);
});
