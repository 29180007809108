define('prepRunCreatePcrRunFromFilePlateLayoutView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!prepRunCreatePcrRunFromFilePlateLayoutView',
    'savingBehavior',
    'bootbox',
    'underscore',
    'app',
    'settings',
    'autocompleteView',
    'runRemoteFolderView',
    'entities/caccounts/fileFormatPlateLayouts',
    'entities/caccounts/setting',
    'fileExplorerController',
    'entities/caccounts/prepruns'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    bootbox,
    _,
    App,
    Settings,
    AutocompleteView,
    RunRemoteFolderView,
    FileFormatPlateLayouts,
    SettingEntity,
    FileExplorerController
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            importFileFromCentralLib: '.importFileFromCentralLib',
            importFileFromComputer: '.importFileFromComputer',
            selectFileFromComputer: '.js-select-file-from-computer',
            blockLoading: '.empty-page-loader'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click @ui.importFileFromCentralLib': 'onSelectFileFromCentralLib',
            'change @ui.selectFileFromComputer': 'onSelectFileFromComputer',
            'click .js-blank': 'onBlankClick'
        },

        regions: {
            selectFileFormat: '.js-select-fileFormat-region'
        },

        fieldsToValidate: [
            {name: 'refFileFormat', type: 'required'}
        ],

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('refFileFormat', null);
            this.model.set('accept', '.xlsx,.xls,.xml,.rdml,.csv');
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.remoteAvailable = this.options.settings['IMPORT-PLATELAYOUT-PREP'] && this.options.settings['IMPORT-PLATELAYOUT-PREP'].split(';')[0];
            return templateData;
        },

        onShow: function () {
            $.material.init();
            this.showSelectFileFormatView();
        },

        showSelectFileFormatView: function () {
            if (this.selectFileFormatView) {
                this.selectFileFormatView.destroy();
            }

            this.selectFileFormatView = new AutocompleteView(
                this._getAutocompleteOptionObject(FileFormatPlateLayouts.getAutocompleteParamForImport({
                    modelProperty: 'refFileFormat',
                    callBackOnChange: this.onRequiredFieldChange
                }))
            );
            this.getRegion('selectFileFormat').show(this.selectFileFormatView);
        },

        onRequiredFieldChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
            this.checkForLocalImportButton();
            this.validate(this.fieldsToValidate);
        },

        checkForLocalImportButton: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.$el.find('label#importResultFromLocal').attr('for', 'run-xml-input');
            } else {
                this.$el.find('label#importResultFromLocal').attr('for', 'blank');
            }
        },

        onBlankClick: function () {
            this.validate(this.fieldsToValidate);
        },

        onSelectFileFromCentralLib: function () {
            if (this.validate(this.fieldsToValidate)) {
                SettingEntity.findByCode('IMPORT-PLATELAYOUT-PREP').done(_.bind(function (settings) {
                    FileExplorerController.show(settings.secId, true, _.bind(this.fileUpload, this));
                }, this));
            }
        },

        onSelectFileFromComputer: function (e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.fileUpload(e.target.files[0]);
            }
        },

        fileUpload: function (fileInfo) {
            this.$el.find(this.ui.blockLoading).show();
            if (fileInfo && this.validate(this.fieldsToValidate)) {
                this.ui.blockLoading.show();
                var fileFormatSecId = this.model.get('refFileFormat').secId;
                App.request('run:prep:from:plateLayout', fileInfo, fileFormatSecId)
                    .done(_.bind(function (result) {
                        this.ui.blockLoading.hide();
                        if (result && result.secId) {
                            App.navigate('runs/prep/' + result.secId + '/A01/1/' + result.plateSize, {trigger: true});
                        } else {
                            App.navigate('runs/prep', {trigger: true});
                            this.triggerMethod('run:listView:refresh');
                        }
                        this.hide();
                    }, this));
            }
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        hide: function () {
            this.triggerMethod('hide');
            this.destroy();
            this.box.modal('hide');
        }
    });
});
