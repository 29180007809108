define('entities/caccounts/pcrSetup/prepWellLite',[
    'app',
    'backbone',
    'module',
    'backboneRelational',
    'entities/caccounts/assay'
], function (
    App,
    Backbone,
    module
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.PrepWellLite = Backbone.RelationalModel.extend({
        defaults: {
            'valid': false,
            'sample': {},
            'sampleType': undefined,
            'waiting': [],
            'running': [],
            'done': [],
            'type': undefined
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'waiting',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasMany,
            key: 'running',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasMany,
            key: 'done',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrWells',
            relatedModel: 'PcrWellLite',
            collectionType: 'PcrWellLiteCollection'
        }],
        initialize: function () {
            this.set('assaysAdded', []);
        },
        getLine: function () {
            return this.get('pos').substring(0, 1);
        },
        getColumn: function () {
            return parseInt(this.get('pos').substring(1, 3), 10);
        }
    });


    app.ns.PrepWellLiteCollection = Backbone.Collection.extend({
        model: app.ns.PrepWellLite,
        comparator: function (a, b) {
            var aCol = Number.parseInt(a.get('pos').substring(1));
            var bCol = Number.parseInt(b.get('pos').substring(1));
            var aRow = a.get('pos').charCodeAt(0);
            var bRow = b.get('pos').charCodeAt(0);
            if (aCol === bCol) {
                if (aRow === bRow) {
                    return 0;
                }
                if (aRow < bRow) {
                    return -1;
                }
                return 1;
            }
            if (aCol < bCol) {
                return -1;
            }
            return 1;
        }
    });

    module.exports = {
        create: function (params) {
            return new app.ns.PrepWellLite(params);
        },
        createCollection: function () {
            return new app.ns.PrepWellLiteCollection();
        }
    };
});
