define('entities/caccounts/lisSpecies',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/userCodelist',
    'backboneRelational',
    'entities/caccounts/lis',
    'entities/caccounts/species'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    UserCodeLists
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.LisSpecie = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/lisSpecies/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'lis': null,
            'specie': null,
            'code': '',
            'name': '',
            'description': '',
            'tags': [],
            'criterias': [],
            'jsonobjectdocumentation': 'TB_LisSpecieJson.pdf'
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasOne,
            key: 'lis',
            relatedModel: 'Lis'
        }, {
            type: Backbone.HasOne,
            key: 'specie',
            relatedModel: 'Specie'
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/lisSpecies/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('LisSpecieJson.pdf');
        },
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.LisSpecieCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/lisSpecies/');
        },
        model: app.ns.LisSpecie
    });

    module.exports = _.defaults({
        getName: function () {
            return 'lisSpecie';
        },
        getUrl: function () {
            return 'v2/lisSpecies';
        },
        getDynamicJson: function () {
            return 'LisSpecieJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('lisSpecie.lis'),
                name: 'lis',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'lis',
                width: 40
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('lisSpecie.specie'),
                name: 'specie.code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'specieCode',
                width: 80
            }, {
                label: _.i18n('common.criterias'),
                name: 'criterias',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'criterias',
                width: 40
            }, {
                label: _.i18n('common.tags'),
                name: 'tags',
                formatter: viewDynamic.defaultListFormatter,
                search: true,
                sortable: false,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.LisSpecie.findOrCreate(modelJson);
        },
        getCreateEditFieldValidate: function () {
            var array = Dynamic.getCreateEditFieldValidate();
            array.push({name: 'lis', type: 'required'});
            array.push({name: 'specie', type: 'required'});
            return array;
        },
        defineDefaultValue: function (model) {
            var defer = $.Deferred();
            require(['entities/caccounts/lis'], function (Lis) {
                Lis.findDefault().done(function (value) {
                    if (value && value.get('secId')) {
                        model.get('lisSet').push(value);
                    }
                    defer.resolve();
                });
            });
            return defer.promise();
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push({field: 'lis', param: {display: {'class': 'col-xs-3 p-r-0'}}});
            custom.push({field: 'specie', param: {display: {'class': 'col-xs-3 p-r-0'}}});
            return custom;
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration();
            configuration.done(function (resp) {
                resp.push({fieldName: 'criterias', values: UserCodeLists.findItemsByCode('FCRIT_LISSPECIE'), codeListCode: 'FCRIT_LISSPECIE'});
                defer.resolve(resp);
            });
            return defer.promise();
        }
    }, Dynamic);
});
