
define('template!wellPopupDisplayGraphAmpView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row m-b-10">\n    <div class="col-xs-6">\n        <div class="col-xs-12 rounded" style="background-color: var(--css-background-color-light);">\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">' +
((__t = ( pos )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-6">' +
((__t = ( run )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('assay.assay') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-8">' +
((__t = ( assay )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('common.target') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-8">' +
((__t = ( mbAna )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('common.result') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-8" style="display: flex">' +
((__t = ( iconHtml )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('well.download.data') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-8" style="display: flex">\n                    <div class="m-r-20">\n                        <span class="label rawData">CSV</span>\n                        <span class="mdi mdi-download clickable rawData" title="' +
((__t = ( _.i18n('curve.rawData.download') )) == null ? '' : __t) +
'"\n                              style="line-height: 15px;font-size: 25px; height: 20px;"></span></div>\n                    <div>\n                        <span class="label fluorData">TXT</span>\n                        <span class="mdi mdi-download fluorData clickable"\n                              title="' +
((__t = ( _.i18n('curve.fluorData.download') )) == null ? '' : __t) +
'"\n                              style="line-height: 15px;font-size: 25px; height: 20px;"></span>\n                        <span class="mdi mdi-eye fluorDataShow clickable"\n                              title="' +
((__t = ( _.i18n('curve.fluorData.show') )) == null ? '' : __t) +
'"\n                              style="line-height: 15px;font-size: 25px; height: 20px;"></span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="col-xs-6">\n        <div class="col-xs-12 rounded" style="background-color: var(--css-background-color-light);">\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('well.algorithm') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-8 js-algoParam"></div>\n            </div>\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('amp.exp.ph') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-4">' +
((__t = ( c1 )) == null ? '' : __t) +
' - ' +
((__t = ( c2 )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('amp.amplitude') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-4">' +
((__t = ( amp1 )) == null ? '' : __t) +
' - ' +
((__t = ( amp2 )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('amp.sloveIndex') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-8">' +
((__t = ( slopeIndex )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="row">\n                <label class="col-xs-4 t-a-r">' +
((__t = ( _.i18n('graph.errWarn') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-8">' +
((__t = ( errWarn )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="row">\n    <div class="col-xs-12">\n        <div class="col-xs-12 rounded m-b-10" style="background-color: var(--css-border-light); display: flex">\n            <h1 class="col-xs-1 t-a-c" style="writing-mode: tb-rl; transform: rotate(-180deg);">' +
((__t = ( _.i18n('algo.amp')
                )) == null ? '' : __t) +
'</h1>\n            <div class="col-xs-11" id="targetAmplGraphView"></div>\n        </div>\n        <div class="col-xs-12 rounded" style="background-color: var(--css-border-light); display: flex">\n            <h1 class="col-xs-1 t-a-c" style="writing-mode: tb-rl; transform: rotate(-180deg);">' +
((__t = ( _.i18n('algo.D2')
                )) == null ? '' : __t) +
'</h1>\n            <div class="col-xs-11" id="targetAmplGraphView2"></div>\n        </div>\n    </div>\n</div>\n\n\n\n\n';

}
return __p
};});

