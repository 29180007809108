define('orderSampleView',[
	'module',
	'marionette',
	'dialogFormView',
	'template!orderSampleTpl',
	'underscore',
	'runDetailsTabController',
	'prepRunDetailsTabController',
	'bootbox'
], function (
	module,
	Marionette,
	DialogFormView,
	tpl,
	_,
	runController,
	prepRunController,
	BootBox
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: tpl,

		events: {
			'click .js-sample': 'showSample',
			'click .mdi-action-delete': 'deleteSample',
			'change .js-lisAnas': 'onChangeLisAna',
			'click .js-well': 'showWell'
		},

		modelEvents: {
			'change': 'render'
		},

		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.sample = this.model.get('code');
			templateData.bioGroupCode = this.model.get('bioGroup') ? this.model.get('bioGroup').get('code') : '-';
			templateData.lisAnas = this.model.get('lisAnas').pluck('code').join(', ');
			templateData.assayGroups = this.model.get('assayGroups').pluck('code').join(', ');
			templateData.wells = this.model.get('prepWells').map(function (well) {
				return {secId: well.get('secId'), pos: well.get('pos'), runName: well.get('runName')};
			}).concat(this.model.get('pcrWells').map(function (well) {
				return {secId: well.get('secId'), pos: well.get('pos'), runName: well.get('runName')};
			}));
			return templateData;
		},

		showSample: function () {
			require(['samplesController'], _.bind(function (Controller) {
				Controller.showDetails(this.model);
			}, this));
		},

		deleteSample: function () {
			this.trigger('item:delete');
		},

		onChangeLisAna: function (e) {
			$('.js-global-loader').show();
			var codes = _.uniq(e.currentTarget.value.split(/[,;\s]/).filter(function (element) {
				return element;
			}));
			this.model.findAnalyzes(codes).done(_.bind(function (response) {
				if (response.lisAnasCodeErrors.length > 0) {
					BootBox.dialog({
						title: _.i18n('sample.lisAnaError'),
						message: function () {
							var ul = '<ul>';
							_.each(response.lisAnasCodeErrors, function (lisAna) {
								ul += '<li>' + lisAna + '</li>';
							});
							return ul;
						}
					});
				}
				this.onChange('lisAnas', response.lisAnas);
				this.onChange('assayGroups', response.assayGroups);
				this.render();
			}, this))
				.always(function () {
					$('.js-global-loader').hide();
				});
			this.trigger('item:change');
		},
		showWell: function (e) {
			var dataSecId = e.currentTarget.attributes['data-wellsecid'].value;
			var prepWell = this.model.get('prepWells').findWhere({secId: dataSecId});
			if (prepWell) {
				prepRunController.showWell(prepWell);
			} else {
				runController.showWell(this.model.get('pcrWells').findWhere({secId: dataSecId}));
			}
		}
	});
});
