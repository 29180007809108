define('entities/caccounts/prepruns',[
    'app',
    'backbone',
    'settings',
    'module',
    'dynamic',
    'jquery',
    'underscore',
    'moment',
    'colorUtils',
    'fieldUtils',
    'wellResultIconView',
    'imageUtils',
    'wellUtils',
    'customBootboxMessage',
    'entities/caccounts/attachment',
    'entities/caccounts/prepwells',
    'entities/caccounts/pcrruns',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    module,
    Dynamic,
    $,
    _,
    moment,
    ColorUtils,
    FieldUtils,
    WellResultIconView,
    imageUtils,
    WellUtils,
    CustomBootboxMessage,
    Attachments
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.PrepRun = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'name': '',
            'creatDate': null,
            'modDate': null,
            'plateSize': 'H12',
            'comment': '',
            'refModUser': {},
            'refCreatUser': {},
            'refRespUser': {},
            'settings': [],
            'toBePooled': true,
            'period': '',
            'periodFormatted': '',
            'group': '',
            'sequence': 0,
            'sequenceFormat': '',
            'department': '',
            'pattern': '',
            'suffix': '',
            'status': 0,
            'archived': false,
            'archivedDate': '',
            'plateIdPR': '',
            'plateIdDW': '',
            'plateIdEL': '',
            'prState': true,
            'runTracExtraction': [],
            'type': '',
            'wells': [],
            'runAts': [],
            'pcrRuns': [],
            'refExtractionMethod': null,
            'reagentExtractionKitLots': []
        },

        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'wells',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection',
            reverseRelation: {
                key: 'refPrepRun'
            }
        }, {
            type: Backbone.HasMany,
            key: 'pcrRuns',
            relatedModel: 'PcrRun',
            collectionType: 'PcrRunCollection'
        }],
        getType: function () {
            return 'PrepRun';
        },

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/runs/prep');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        saveHeader: function () {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/header'),
                defer = $.Deferred();

            var refRespUserSecId = this.get('refRespUser') && this.get('refRespUser').secId ? this.get('refRespUser').secId : null;

            var body = {
                name: this.get('name'),
                status: this.get('status'),
                period: this.get('period'),
                department: this.get('department'),
                group: this.get('group'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix'),
                pattern: this.get('pattern'),
                plateIdDW: this.get('plateIdDW'),
                plateIdEL: this.get('plateIdEL'),
                plateIdPR: this.get('plateIdPR'),
                toBePooled: this.get('toBePooled'),
                comment: this.get('comment'),
                respUserSecId: refRespUserSecId,
                extractable: this.get('extractable')
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        copyPlateLayoutFrom: function (request) {

            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/copyPlateLayoutFrom'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(request),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        getFormattedRunAssayStatus: function () {
            var assaysWaitingList = [];
            var assaysRunningList = [];
            var assaysDoneList = [];

            this.get('wells').each(_.bind(function (well) {
                _.each(well.get('assayStatus'), _.bind(function (item) {
                    if (item.status === 'WAITING') {
                        assaysWaitingList.push(item.assay.code);
                    } else if (item.status === 'RUNNING') {
                        assaysRunningList.push(item.assay.code);
                    } else if (item.status === 'DONE') {
                        assaysDoneList.push(item.assay.code);
                    }
                }, this));
            }, this));

            return {
                waiting: this.getAssaysList(assaysWaitingList),
                running: this.getAssaysList(assaysRunningList),
                done: this.getAssaysList(assaysDoneList),
                waitingWithCount: this.getAssaysListWithCount(assaysWaitingList) || '-',
                runningWithCount: this.getAssaysListWithCount(assaysRunningList) || '-',
                doneWithCount: this.getAssaysListWithCount(assaysDoneList) || '-',
                assaysIcons: WellUtils.getPrepWellAssaysIcons()
            };
        },

        getAssaysList: function (assayList) {
            var retVal = _.uniq(assayList);
            return retVal.length === 0 ? '-' : retVal.join('; ');
        },

        getAssaysListWithCount: function (assayList) {
            var retVal = '';
            _.each(_.countBy(assayList), _.bind(function (count, key) {
                retVal = retVal + key + ' (' + count + '); ';
            }, this));
            retVal = FieldUtils.substringLastCharWithCharCheck(retVal, ';');
            return retVal;
        },

        updateWithXmlFile: function (fileInfo) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId'));

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        importFilePlateLayout: function (fileInfo, fileFormatSecId) {
            var param;

            param = {
                fileFormatSecId: fileFormatSecId
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/plateLayout/import', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        importFileSampleList: function (fileInfo, request) {
            var param = {
                fileFormatSecId: request.get('refFileFormat').secId,
                startPos: request.get('startPos'),
                plateFillDirection: request.get('plateFillDirection')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/sampleList/import', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        extractOrderIdFromSampleId: function (sampleId) {
            var sidOrderId = this.get('settings')['SID-ORDERID'];
            if (sidOrderId && sampleId) {
                var splitSidOrderId = sidOrderId.split(';');
                sampleId = sampleId.substr(Number(splitSidOrderId[0]) > 0 ? Number(splitSidOrderId[0]) - 1 : Number(splitSidOrderId[0]),
                    Number(splitSidOrderId[1]));
            }
            return sampleId;
        },

        addAssayWells: function (assaySecId, startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/wells/assay/add', {
                assaySecId: assaySecId,
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        addTestWells: function (startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/wells/test/add', {
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        generateSampleId: function (sampleIds) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/wells/generateSampleId'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(sampleIds),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearAssay: function (assaySecId, startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/wells/assay/clear', {
                assaySecId: assaySecId,
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        replaceAssay: function (assayClearSecId, assaySecId, startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/wells/assay/replace', {
                assayClearSecId: assayClearSecId,
                assaySecId: assaySecId,
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        removeTest: function (startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/wells/test/remove', {
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        clearWells: function (startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/wells/clear', {
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        clearSampleId: function (from, to) {
            var params = {
                from: from,
                to: to
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/wells/clearSampleId', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        sheetUpload: function (target) {
            var defer = $.Deferred();

            var fileInfo = imageUtils.getFileInfo(target, 0);

            if (!fileInfo) {
                throw 'Unable to get file info';
            }

            $.ajax({
                type: 'POST',
                url: Settings.url('compuzz', 'v2/runs/' + this.get('secId') + '/sheet'),
                data: fileInfo.formData,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        sheetRemove: function () {
            var defer = $.Deferred();

            $.ajax({
                type: 'DELETE',
                url: Settings.url('compuzz', 'v2/runs/' + this.get('secId') + '/sheet'),
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        getPreviousRun: function () {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/previous'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        getNextRun: function () {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/next'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        controlSample: function () {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/samples/control'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (controlSampleResponse) {
                    defer.resolve(controlSampleResponse);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        startScanning: function () {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/scanning/start'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    defer.resolve();
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        stopScanning: function () {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/scanning/stop'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        getPDF: function () {
            return Settings.getDocUrl('PrepRunJson.pdf');
        },

        startExtraction: function (extractionModel) {
            var params = {
                extractionMethodSecId: extractionModel.get('refExtractionMethod').secId,
                comment: extractionModel.get('comment'),
                startUserpidSecId: extractionModel.get('startUserpid') && extractionModel.get('startUserpid').secId ?
                    extractionModel.get('startUserpid').secId : ''
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/extraction/start', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        undoExtraction: function () {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/extraction/undo'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        stopExtraction: function (extractionModel) {
            var params = {
                lmbExtractorSecId: extractionModel.get('refLmbExtractor').get('secId'),
                comment: extractionModel.get('comment'),
                endUserpidSecId: extractionModel.get('endUserpid') && extractionModel.get('endUserpid').secId ?
                    extractionModel.get('endUserpid').secId : ''
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/extraction/stop', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        /**
         * Find pcrKitLot for a prep run
         * @returns {*|jQuery}
         */
        getKitLots: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/kitLots');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        createBeadPooling: function (request) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/pooling/bead'),
                defer = $.Deferred();
            var data = {
                posFrom: request.get('posFrom'), posTo: request.get('posTo'),
                plateFillDirection: request.get('plateFillDirection'),
                runSecIds: request.get('runs').chain().filter(function (run) {
                    return run.get('prepRun');
                }).map(function (run) {
                    return run.get('prepRun').get('secId');
                }).value()
            };

            $.ajax({
                method: 'put',
                url: url,
                data: JSON.stringify(data),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });

            return defer.promise();
        },

        createPrimaryPooling: function (request) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + this.get('secId') + '/pooling/primary'),
                defer = $.Deferred();

            $.ajax({
                method: 'put',
                url: url,
                data: JSON.stringify(request),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });

            return defer.promise();
        },

        getGeneratePeriodFormatted: function (period) {
            var params = {
                period: period // $int64
            };
            var url = Settings.url('compuzz', 'v2/runs/prep/generatePeriodFormatted', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        generateRunName: function () {
            var params = {
                pattern: this.get('pattern'),
                department: this.get('department'),
                group: this.get('group'),
                period: this.get('period') ? Number(moment(this.get('period')).format('x')) : null,
                sequenceFormat: this.get('sequenceFormat'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix')
            };
            var url = Settings.url('compuzz', 'v2/runs/prep/generateRunName', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        availableRunName: function () {
            var params = {
                runName: this.get('name')
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/availableRunName', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (available) {
                    defer.resolve(available);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        findPriorityAssay: function () {
            var assaysCount = {};
            this.get('wells').each(function (well) {
                _.each(well.get('assayStatus'), function (assayStatus) {
                    if (assayStatus.status === 'WAITING') {
                        if (!assaysCount[assayStatus.assay.code]) {
                            assaysCount[assayStatus.assay.code] = {'assay': assayStatus.assay, 'count': 1};
                        } else {
                            assaysCount[assayStatus.assay.code].count++;
                        }
                    }
                });
            });
            var assay = _.first(_.sortBy(assaysCount, function (assayCount) {
                return assayCount.count;
            }).reverse());
            if (assay) {
                return assay.assay;
            }
            return undefined;
        },

        canAssignSID: function () {
            return this.get('status') < 4;
        },
        canAssignAssay: function () {
            return this.get('wells').findWhere({'refPrepWell': null, 'refAssay': null}) !== undefined;
        },
        canClearAssay: function () {
            return this.get('wells').filter(function (well) {
                return _.find(well.get('assayStatus'), function (assayStatus) {
                    return assayStatus.status === 'WAITING';
                }) !== undefined;
            }).length > 0;
        },
        getEntity: function () {
            return module.exports;
        },
        getAttachments: function () {
            return Attachments.findAll(this.getEntity().getName(), this.get('secId'));
        },
        generateRunSheet: function () {
            this.getEntity().generateRunSheet(this.get('secId'));
        },
        generateRunExtractSetup: function () {
            this.getEntity().generateRunExtractSetup(this.get('secId'));
        }

    });

    app.ns.PrepRunCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/runs/prep');
        },
        model: app.ns.PrepRun
    });

    module.exports = _.defaults({
        // A finir

        getUrl: function () {
            return 'v2/runs/prep';
        },

        getName: function () {
            return 'preprun';
        },
        getDynamicJson: function () {
            return 'PrepRunJson';
        },
        getHitPickingPrepRuns: function (assaySecId) {
            var params = {
                assaySecId: assaySecId
            };
            var url = Settings.url('compuzz', 'v2/runs/prep/hitpicking', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,

                success: _.bind(function (runs) {
                    var runsList = [];
                    _.each(runs, _.bind(function (run) {
                        var model = app.ns.PrepRun.findOrCreate(run);
                        runsList.push(model);
                    }, this));

                    defer.resolve(runsList);
                }, this),


                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        getModel: function (runJSON) {
            if (!runJSON) {
                runJSON = {};
            }
            return app.ns.PrepRun.findOrCreate(runJSON);
        },
        getRoute: function () {
            return '#runs/prep';
        },
        parameterForLink: function (model) {
            return {
                secId: model.get('secId'),
                code: model.get('code'),
                name: model.get('name'),
                entity: model.getEntity()
            };
        },
        generateRunSheet: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('compuzz', 'v2/runs/prep/report/PREP_RUN_SHEET', {secIds: secIds});
            window.open(url, '_blank');
        },
        generateRunExtractSetup: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('compuzz', 'v2/runs/prep/report/PREP_RUN_EXTRACT_SETUP', {secIds: secIds});
            window.open(url, '_blank');
        }
    }, Dynamic);


    var API = {

        getFieldsToDisplay: function () {
            return {
                'fieldsWidth': 650, 'fields': [
                    {property: 'assayWaiting', type: 'array[customAssayObject]'}
                ]
            };
            // 'name' must be showed by _getAutocompleteOptions(valueProperty)
        },

        getStatusFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['name']};
            // 'code' must be showed by _getAutocompleteOptions(valueProperty)
        },

        getTypeFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['name']};
            // 'code' must be showed by _getAutocompleteOptions(valueProperty)
        },

        importXmlFile: function (fileInfo, lmbCycler, kitProt) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {};

            if (lmbCycler) {
                param.lmbCyclerSecId = lmbCycler.get('secId');
            }
            if (kitProt) {
                param.kitProtSecId = kitProt.get('secId');
            }

            var url = Settings.url('compuzz', 'v2/runs/prep', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function () {
                    defer.reject();
                }
            });

            return defer.promise();
        },

        getCollectionUrl: function () {
            new app.ns.PrepRunCollection();
            return Settings.url('compuzz', 'v2/runs/prep');
        },

        getAll: function (params) {
            var url = Settings.url('compuzz', 'v2/runs/prep', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (runs) {
                    var runsList = [];
                    _.each(runs, function (run) {
                        runsList.push(run.secId);
                    });
                    defer.resolve(runsList);
                }
            });

            return defer.promise();
        },

        getCountUrl: function () {
            return 'v2/runs/prep/rowCount';
        },

        deleteRun: function (secId) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + secId),
                defer = $.Deferred();

            $.ajax({
                type: 'DELETE',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },

        archiveRun: function (secId) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + secId + '/archive'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        unArchiveRun: function (secId) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + secId + '/unarchive'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        archiveRunByList: function (secIds) {
            var params = {
                secIds: secIds
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/archive', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        unArchiveRunByList: function (secIds) {
            var params = {
                secIds: secIds
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/unarchive', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        fromTemplate: function (templateSecId, params) {
            var url = Settings.url('compuzz', 'v2/runs/prep/fromTemplate/' + templateSecId, params),
                defer = $.Deferred();

            $.ajax({
                method: 'post',
                url: url,
                success: function (run) {
                    defer.resolve(run);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        fromTemplate384: function (templateSecId, params) {
            var url = Settings.url('compuzz', 'v2/runs/prep/fromTemplate384/' + templateSecId, params),
                defer = $.Deferred();

            $.ajax({
                method: 'post',
                url: url,
                success: function (run) {
                    defer.resolve(run);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        getHitpickingEligibleWells: function (runSecId, assaySecId) {

            var params = {
                assaySecId: assaySecId
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/hitpicking/' + runSecId + '/eligible', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,

                success: function (wells) {
                    defer.resolve(wells);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        extractionReady: function (runSecId) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + runSecId + '/extraction/ready'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (resp) {
                    var model = new app.ns.PrepRun(resp);
                    defer.resolve(model);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();

        },

        updateFromTemplate: function (runSecId, templateSecId) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + runSecId + '/fromTemplate/' + templateSecId),
                defer = $.Deferred();

            $.ajax({
                method: 'put',
                url: url,
                success: function (resp) {
                    var model = new app.ns.PrepRun(resp);
                    defer.resolve(model);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        getSampleIds: function (runSecId, sampleId) {
            var url = Settings.url('compuzz', 'v2/runs/prep/' + runSecId + '/wells/' + sampleId),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (runs) {
                    var wells = [], run, well, j, i;
                    for (i = 0; i < runs.length; i++) {
                        run = runs[i];
                        for (j = 0; j < run.wells.length; j++) {
                            well = run.wells[j];
                            well.refRun = run;
                            wells.push(well);
                        }
                    }
                    defer.resolve(wells);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        getWellSampleIdsCollectionUrl: function (runSecId, sampleId, params) {
            if (!params) {
                params = {};
            }
            return Settings.url('compuzz', 'v2/runs/prep/' + runSecId + '/wells/' + sampleId, params);
        },

        countWellSampleIdsCollectionUrl: function (runSecId, sampleId, params) {
            var stringParams = '',
                ampersand = '?';
            _.each(params, function (value, key) {
                if ((value || value === 0) && key) {
                    stringParams += (ampersand + key + '=' + value.toString());
                    ampersand = '&';
                }
            });
            return 'v2/runs/prep/' + runSecId + '/wells/' + sampleId + '/rowCount' + stringParams;
        },

        getWellQueryCollectionUrl: function (params) {
            if (!params) {
                params = {};
            }
            return Settings.url('compuzz', 'v2/runs/prep/wells/find', params);
        },

        countWellQueryCollectionUrl: function (params) {
            var stringParams = '',
                ampersand = '?';
            _.each(params, function (value, key) {
                if ((value || value === 0) && key) {
                    stringParams += (ampersand + key + '=' + value.toString());
                    ampersand = '&';
                }
            });
            return 'v2/runs/prep/wells/find/rowCount' + stringParams;
        },

        availableForRunTemplate: function (runSecId, params) {
            var url = Settings.url('compuzz', 'v2/runs/prep/availableFromRunTemplate/' + runSecId, params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (count) {
                    defer.resolve(count);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        prepSetupSameLayoutSelected: function (runSecId, kitProtSecId, assaySecId) {
            var params = {
                kitProtSecId: kitProtSecId,
                assaySecId: assaySecId
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/' + runSecId + '/pcrSetup/selected', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },
        prepSetupSameLayoutCopy: function (runSecId, kitProtSecId, assaySecId) {
            var params = {
                kitProtSecId: kitProtSecId,
                assaySecId: assaySecId
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/' + runSecId + '/pcrSetup/copy', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (newRunSecId) {
                    defer.resolve(newRunSecId);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        filesToImport: function () {
            var url = Settings.url('compuzz', 'import/result/files'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        plateLayoutToImport: function () {
            var url = Settings.url('compuzz', 'import/plateLayoutPrep/files'),
                defer = $.Deferred();
            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        deleteFileRemote: function (fileName) {
            var url = Settings.url('compuzz', 'import/result/file', {fileName: fileName}),
                defer = $.Deferred();

            $.ajax({
                method: 'delete',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        getWellCollectionUrl: function (runSecId) {
            var params = {};
            return Settings.url('compuzz', 'v2/runs/prep/' + runSecId + '/runtracs', params);
        },

        getWellsCountUrl: function (runSecId) {
            var params = '?';
            return 'v2/runs/prep/' + runSecId + '/runtracs/rowCount' + params;
        },

        createPrepRunFromPlateLayout: function (fileInfo, fileFormatSecId) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {
                    fileFormatSecId: fileFormatSecId
                };

            var url = Settings.url('compuzz', 'v2/runs/prep/fromPlateLayout', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        createPrepRunFromSampleList: function (fileInfo, request) {
            var defer = $.Deferred(),
                formdata = new FormData();
            var param = {
                fileFormatSecId: request.get('refFileFormat').secId,
                startPos: request.get('startPos'),
                plateFillDirection: request.get('plateFillDirection')
            };

            var url = Settings.url('compuzz', 'v2/runs/prep/fromSampleList', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },
        controlSID: function (model, checkLength, checkDuplicate) {
            var defer = $.Deferred();
            model.controlSample().done(_.bind(function (controlSampleResponse) {
                if ((controlSampleResponse.duplicateSampleId && Object.getOwnPropertyNames(controlSampleResponse.duplicateSampleId).length > 0) ||
                    (controlSampleResponse.sampleClassWithoutLIS && Object.getOwnPropertyNames(controlSampleResponse.sampleClassWithoutLIS).length > 0) ||
                    controlSampleResponse.sampleDiversCount > 0) {
                    var text = '';
                    var textSet = false;

                    var wrongSmpIdCount = 0;
                    _.each(controlSampleResponse.sampleClassWithoutLIS, function () {
                        wrongSmpIdCount++;
                    });

                    if (checkLength && wrongSmpIdCount > 0) {
                        textSet = true;
                        text = _.i18n('plateLayout.sampleId.sampleClassWithoutLis') + '<br/><div class="row">';

                        text = text + '<div class="col-xs-4">' + _.i18n('sampleClass') + '</div>';
                        text = text + '<div class="col-xs-4">' + _.i18n('well') + '</div>';
                        text = text + '<div class="col-xs-4">' + _.i18n('sampleId') + '</div>';
                        _.each(controlSampleResponse.sampleClassWithoutLIS, function (wells, p) {
                            _.each(wells, function (pos) {
                                text = text + '<div class="col-xs-4" style="font-weight: normal;">' + p + '</div>';
                                text = text + '<div class="col-xs-4" style="font-weight: normal;">' + pos + '</div>';
                                var wellModel = model.get('wells').findWhere({pos: pos});
                                text = text + '<div class="col-xs-4" style="font-weight: normal;">' + (wellModel ? wellModel.get('smpId').get('code') : '') + '</div>';
                            });
                        });
                        text = text + '</div>';
                    }

                    var duplicateCount = 0;
                    _.each(controlSampleResponse.duplicateSampleId, function () {
                        duplicateCount++;
                    });

                    if (checkDuplicate && duplicateCount > 0) {
                        textSet = true;
                        text = text + _.i18n('plateLayout.sampleId.duplicate') + '<br/><div class="row">';

                        for (var i in controlSampleResponse.duplicateSampleId) {
                            text = text + '<div>' + i + '</div>' +
                                '<div><ul>';
                            for (var j in controlSampleResponse.duplicateSampleId[i]) {
                                text = text + '<li>' + controlSampleResponse.duplicateSampleId[i][j] + '</li>';
                            }
                            text = text + '</ul></div>';
                        }
                        text = text + '</div><br/>';
                    }

                    if (controlSampleResponse.sampleDiversCount > 0) {
                        textSet = true;
                        text = text + _.i18n('plateLayout.sampleId.divers') + ' : ' + controlSampleResponse.sampleDiversCount;
                    }

                    if (textSet) {
                        var params = {
                            message: text,
                            type: 'info'
                        };
                        CustomBootboxMessage.customAlert(params);
                    }

                } else {
                    CustomBootboxMessage.customThumbUpNotification();
                }
                defer.resolve();
            }, this));
            return defer.promise();
        }
    };

    App.reqres.setHandler('run:prep:get-collection-url', function () {
        return API.getCollectionUrl();
    });

    App.reqres.setHandler('run:prep:get-collection-to-page', function (params) {
        return API.getAll(params);
    });

    App.reqres.setHandler('run:prep:get-count-url', function () {
        return API.getCountUrl();
    });

    App.reqres.setHandler('run:prep:model', function (runJSON) {
        if (runJSON) {
            return app.ns.PrepRun.findOrCreate(runJSON);
        }
        return new app.ns.PrepRun();
    });

    App.reqres.setHandler('run:prep:delete', function (secId) {
        return API.deleteRun(secId);
    });

    App.reqres.setHandler('run:prep:archive', function (secId) {
        return API.archiveRun(secId);
    });

    App.reqres.setHandler('run:prep:unarchive', function (secId) {
        return API.unArchiveRun(secId);
    });

    App.reqres.setHandler('run:prep:archive:bylist', function (secIds) {
        return API.archiveRunByList(secIds);
    });

    App.reqres.setHandler('run:prep:unarchive:bylist', function (secIds) {
        return API.unArchiveRunByList(secIds);
    });

    App.reqres.setHandler('run:prep:upload-xml', function (fileInfo, lmbCycler, kitProt, plateLayout) {
        return API.importXmlFile(fileInfo, lmbCycler, kitProt, plateLayout);
    });

    App.reqres.setHandler('run:prep:from-template', function (templateSecId, params) {
        return API.fromTemplate(templateSecId, params);
    });

    App.reqres.setHandler('run:prep:from-template384', function (templateSecId, params) {
        return API.fromTemplate384(templateSecId, params);
    });

    App.reqres.setHandler('run:prep:get-sampleids', function (runSecId, sampleId) {
        return API.getSampleIds(runSecId, sampleId);
    });

    App.reqres.setHandler('run:prep:get-collection-sampleids-url', function (runSecId, sampleId, params) {
        return API.getWellSampleIdsCollectionUrl(runSecId, sampleId, params);
    });

    App.reqres.setHandler('run:prep:count-collection-sampleids-url', function (runSecId, sampleId, params) {
        return API.countWellSampleIdsCollectionUrl(runSecId, sampleId, params);
    });

    App.reqres.setHandler('run:prep:get-collection-query-url', function (params) {
        return API.getWellQueryCollectionUrl(params);
    });

    App.reqres.setHandler('run:prep:count-collection-query-url', function (params) {
        return API.countWellQueryCollectionUrl(params);
    });

    App.reqres.setHandler('run:prep:import-layout-from-template', function (runSecId, templateSecId) {
        return API.updateFromTemplate(runSecId, templateSecId);
    });

    App.reqres.setHandler('run:prep:available-for-run-template', function (runSecId, params) {
        return API.availableForRunTemplate(runSecId, params);
    });

    App.reqres.setHandler('run:prep:setup-same-layout-selected', function (runSecId, kitProtSecId, assaySecId) {
        return API.prepSetupSameLayoutSelected(runSecId, kitProtSecId, assaySecId);
    });

    App.reqres.setHandler('run:prep:setup-same-layout-copy', function (runSecId, kitProtSecId, assaySecId) {
        return API.prepSetupSameLayoutCopy(runSecId, kitProtSecId, assaySecId);
    });

    App.reqres.setHandler('run:prep:files-to-import', function () {
        return API.filesToImport();
    });
    App.reqres.setHandler('run:prep:plateLayout-to-import', function () {
        return API.plateLayoutToImport();
    });

    App.reqres.setHandler('run:prep:delete-remote-file', function (fileName) {
        return API.deleteFileRemote(fileName);
    });

    App.reqres.setHandler('run:prep:trac:get-collection-url', function (runSecId) {
        return API.getWellCollectionUrl(runSecId);
    });

    App.reqres.setHandler('run:prep:trac:get-collection', function (runSecId) {
        var colUrl = API.getWellCollectionUrl(runSecId);
        var Col = App.request('trac:prep:collection');
        Col.url = colUrl;
        return Col;
    });

    App.reqres.setHandler('run:prep:trac:get-count-url', function (runSecId) {
        return API.getWellsCountUrl(runSecId);
    });

    App.reqres.setHandler('run:prep:trac:get-collection-result-url', function (runSecId) {
        return API.getWellResultCollectionUrl(runSecId);
    });

    App.reqres.setHandler('run:prep:get:hitpicking:wells', function (runSecId, assaySecId) {
        return API.getHitpickingEligibleWells(runSecId, assaySecId);
    });

    App.reqres.setHandler('run:prep:extraction:ready', function (runSecId) {
        return API.extractionReady(runSecId);
    });

    App.reqres.setHandler('run:prep:get-fields-to-display', function () {
        return API.getFieldsToDisplay();
    });

    App.reqres.setHandler('run:prep:status:get-fields-to-display', function () {
        return API.getStatusFieldsToDisplay();
    });

    App.reqres.setHandler('run:prep:type:get-fields-to-display', function () {
        return API.getTypeFieldsToDisplay();
    });

    App.reqres.setHandler('run:prep:from:plateLayout', function (fileInfo, fileFormatSecId) {
        return API.createPrepRunFromPlateLayout(fileInfo, fileFormatSecId);
    });

    App.reqres.setHandler('run:prep:from:sampleList', function (fileInfo, request) {
        return API.createPrepRunFromSampleList(fileInfo, request);
    });


    App.reqres.setHandler('run:prep:controlSID', function (model, checkLength, checkDuplicate) {
        return API.controlSID(model, checkLength, checkDuplicate);
    });
});
