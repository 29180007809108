/**
 * Created by Matteo on 11/07/2015.
 */
define('loginShowController',[
	'module',
	'underscore',
	'app',
	'loginShowView',
	'settings'
], function (
	module,
	_,
	App,
	View,
	Settings
) {
	'use strict';

	module.exports = {

		showLogin: function (textAdditionnal) {
			var view;
			var browser = navigator.userAgent.toLowerCase();
			var isInternetExplorer = /trident/.test(browser) || /msie/.test(browser);
			if (isInternetExplorer) {
				view = new View();
				this.view = view;
				App.regions.getRegion('main').show(view);
			} else {
				if (!Settings.get('currentUserModel') || !Settings.get('tokenId') ||
					!Settings.get('currentCAccount')) {
					view = new View({textAdditionnal: textAdditionnal});

					this.view = view;
					view.listenTo(view, 'login:sign:in', _.bind(this.onSignIn, this));
					view.listenTo(view, 'login:keyup', _.bind(this.onSignIn, this));

					App.regions.getRegion('main').show(view);
				} else {
					App.trigger('index:show');
				}
			}
		},

		onSignIn: function () {
			var that = this,
				userName = this.view.ui.userName.val(),
				password = this.view.ui.password.val();

			if (!_.isEmpty(userName) && !_.isEmpty(password)) {
				require(['entities/session'], function () {
					var newSessionToken = App.request('session:token:user:entity:new');

					newSessionToken.save({username: userName, password: password}, {
						success: function (model, response) {
							Settings.set('tokenId', model.get('tokenId'));
							Settings.set('currentUserGlobal', response);
							that.view.ui.form.submit();
							App.trigger('caccounts:list');
						},
						error: function () {
							that.view.onError(true);
						}
					});
				});
			} else {
				this.view.onError();
			}
		}

	};
});
