
define('template!wellContaminationsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem noWrapHidden">\n    <div class="pcrRunWellGrid  col-xs-12 p-r-0 p-l-0"\n         style="height: 100%; background-color: ' +
((__t = ( contaminationCellColor )) == null ? '' : __t) +
';">\n\n        <div class="topSideAbsolute">\n            <div class="btn-group-flat">\n                ';
 if(displayKitLot) { ;
__p += '\n                <button class="wellButton js-btn-preprun-kitLot">\n                    <span class="mdi mdi-flask-outline clickable"></span>\n                </button>\n                ';
 } ;
__p += '\n                ';
 if (well.smpType === 'NC' || well.smpType === 'PC' || well.smpType === 'RC' ||
                well.smpType === 'C' || well.smpType === 'OC' )
                { ;
__p += '\n                ';
 if (well.showQC){ ;
__p += '\n                <button class="wellButton smp-id-link mdi-qc">\n                    <span class="mdi mdi-alpha-q"></span>\n                    <span class="mdi mdi-alpha-c"></span>\n                </button>\n                ';
 } ;
__p += '\n                ';
 } else { ;
__p += '\n                ';
 if(well.smpType === 'U') { ;
__p += '\n                <button class="wellButton smp-id-link">\n                    <span class="mdi mdi-alpha-s clickable"></span>\n                </button>\n                <button class="wellButton js-btn-comment">\n                    <span class="mdi mdi-alpha-c clickable"></span>\n                </button>\n                ';
 } ;
__p += '\n                ';
 } ;
__p += '\n                ';
 if(error) { ;
__p += '\n                <button class="wellButton js-btn-kitLot">\n                    <span class="mdi mdi-alert-octagram clickable" title="' +
((__t = ( _.i18n('common.showErrors') )) == null ? '' : __t) +
'"></span>\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n\n        <div style="width: 100%; height: 100%; display: flex; position: absolute; justify-content: center;">\n            ';
 if(repeatStatus) { ;
__p += '\n            <span class="mdi mdi-play mdi-flip-h contamination-repeatStatus-icon"></span>\n            ';
 } ;
__p += '\n        </div>\n\n        <div style="width: 100%; height: 100%; display: flex; position: relative; justify-content: center; z-index: 40; align-items: center;">\n            ';
 if(well.wellResNum && well.existOtherWellOtherAssay) { ;
__p += '\n            <span class="mdi mdi-triangle mdi-flip-v"\n                  style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
'; position: absolute; left: 13px; top: 30px; line-height: 15px; font-size: 15px;"></span>\n            ';
 } ;
__p += '\n            ';
 if(well.wellResNum && well.existOtherWellSameAssay) { ;
__p += '\n            <span class="mdi mdi-triangle"\n                  style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
'; position: absolute; left: 23px; top: 30px; line-height: 15px; font-size: 15px;"></span>\n            ';
 } ;
__p += '\n            <span style="text-align: center; vertical-align: middle;\n                font-size: ' +
((__t = ( fontSize ? fontSize : '15px' )) == null ? '' : __t) +
';\n                top: ' +
((__t = ( ctTop ? ctTop : '32px' )) == null ? '' : __t) +
';\n                left: 2px;">\n                    ' +
((__t = ( well.wellResNum? well.min: well.wellResNum )) == null ? '' : __t) +
'\n                </span>\n        </div>\n\n        <div class="plateView-icon-container"\n             style="width: 100%; height: 100%; display: flex;position: absolute; top: 0;">\n            <div class="p-r-0 p-l-0" style="width: 3%;">\n                <div class="wellResColorBar" style="height: 80px;\n                ' +
((__t = ( wellResColor ? 'background-color:'+ wellResColor : '' )) == null ? '' : __t) +
';"></div>\n            </div>\n\n            <div class="p-r-0 p-l-0 contaminationWell" style="height: 100%; width: 97%;">\n\n                <div class="col-xs-12 p-l-0 p-r-0" style="height: 17%;">\n                    <div class="col-xs-2 p-l-0 p-r-0">\n                        ';
 if(topLeftCT && topLeftCT === 'outgoing') { ;
__p += '\n                        ';
 } else if(topLeftCT && topLeftCT === 'incoming') { ;
__p += '\n                        <span class="mdi mdi-arrow-bottom-right-thick top-left-contamination-icon"\n                             style="color: ' +
((__t = ( topLeftCtColor )) == null ? '' : __t) +
';"></span>\n                        ';
 } ;
__p += '\n                    </div>\n                    <div class="col-xs-8 p-l-0 p-r-0" style="text-align: center;">\n                        ';
 if(topCenterCT && topCenterCT === 'outgoing') { ;
__p += '\n                        ';
 } else if(topCenterCT && topCenterCT === 'incoming') { ;
__p += '\n                        <span class="mdi mdi-arrow-down-bold top-center-contamination-icon"\n                             style="color: ' +
((__t = ( topCenterCtColor )) == null ? '' : __t) +
';"></span>\n                        ';
 } ;
__p += '\n                    </div>\n                    <div class="col-xs-2 p-l-0 p-r-0" style="text-align: right;">\n                        ';
 if(topRightCT && topRightCT === 'outgoing') { ;
__p += '\n                        ';
 } else if(topRightCT && topRightCT === 'incoming') { ;
__p += '\n                        <span class="mdi mdi-arrow-bottom-left-thick top-right-contamination-icon"\n                             style="color: ' +
((__t = ( topRightCtColor )) == null ? '' : __t) +
';"></span>\n                        ';
 } ;
__p += '\n                    </div>\n                </div>\n                <div class="col-xs-12 p-l-0 p-r-0" style="height: 55%;">\n                    <div class="col-xs-3 p-l-0 p-r-0 well-contamination-vertical-center">\n                        ';
 if(middleLefCT && middleLefCT === 'outgoing') { ;
__p += '\n                        ';
 } else if(middleLefCT && middleLefCT === 'incoming') { ;
__p += '\n                        <span class="mdi mdi-arrow-right-bold middle-left-contamination-icon"\n                             style="color: ' +
((__t = ( middleLefCtColor )) == null ? '' : __t) +
';"></span>\n                        ';
 } ;
__p += '\n                    </div>\n                    <div class="col-xs-6 p-l-0 p-r-0">\n                        <div> <!-- style="height: 33%;" -->\n                            ';
 if(well.min || well.wellResNum) { ;
__p += '\n                            <div class="divMeter">\n                                <div class="divMeterContainer">\n                                    <div class="divMeterBar" id="resPositif-' +
((__t = ( well.pos )) == null ? '' : __t) +
'">\n                                    </div>\n                                </div>\n                            </div>\n                            ';
 } ;
__p += '\n                        </div>\n                    </div>\n                    <div class="col-xs-3 p-l-0 p-r-0 well-contamination-vertical-center" style="text-align: right;">\n                        ';
 if(middleRightCT && middleRightCT === 'outgoing') { ;
__p += '\n                        ';
 } else if(middleRightCT && middleRightCT === 'incoming') { ;
__p += '\n                        <span class="mdi mdi-arrow-left-bold middle-right-contamination-icon"\n                             style="color: ' +
((__t = ( middleRightCtColor )) == null ? '' : __t) +
';"></span>\n                        ';
 } ;
__p += '\n                    </div>\n                </div>\n                <div class="col-xs-12 p-l-0 p-r-0" style="height: 28%;">\n                    <div class="col-xs-2 p-l-0 p-r-0">\n                        ';
 if(bottomLeftCT && bottomLeftCT === 'outgoing') { ;
__p += '\n                        ';
 } else if(bottomLeftCT && bottomLeftCT === 'incoming') { ;
__p += '\n                        <span class="mdi mdi-arrow-top-right-thick bottom-left-contamination-icon"\n                             style="color: ' +
((__t = ( bottomLeftCtColor )) == null ? '' : __t) +
';"></span>\n                        ';
 } ;
__p += '\n\n                        ';
 if(comment) { ;
__p += '\n                        <div class="tags-test noWrapHidden"\n                             title="' +
((__t = ( well.tags ? well.tags : 'well.tags' )) == null ? '' : __t) +
'"\n                             style="position: absolute; display: initial; left: 17px; width: 37px; top: 3px;">\n                            ' +
((__t = ( comment )) == null ? '' : __t) +
'\n                        </div>\n                        ';
 } ;
__p += '\n                    </div>\n                    <div class="col-xs-8 p-l-0 p-r-0" style="text-align: center;">\n                        ';
 if(bottomCenterCT && bottomCenterCT === 'outgoing') { ;
__p += '\n                        ';
 } else if(bottomCenterCT && bottomCenterCT === 'incoming') { ;
__p += '\n                        <span class="mdi mdi-arrow-up-bold bottom-center-contamination-icon"\n                             style="color: ' +
((__t = ( bottomCenterCtColor )) == null ? '' : __t) +
';"></span>\n                        ';
 } ;
__p += '\n                    </div>\n                    <div class="col-xs-2 p-l-0 p-r-0" style="text-align: right;">\n                        ';
 if(bottomRightCT && bottomRightCT === 'outgoing') { ;
__p += '\n                        ';
 } else if(bottomRightCT && bottomRightCT === 'incoming') { ;
__p += '\n                        <span class="mdi mdi-arrow-top-left-thick bottom-right-contamination-icon"\n                             style="color: ' +
((__t = ( bottomRightCtColor )) == null ? '' : __t) +
';"></span>\n                        ';
 } ;
__p += '\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        ';
 if (well.smptype === 'NC') { ;
__p += ' <!-- well.smptype === \'NC\' -->\n        <span class="mdi mdi-minus-circle smp-id-link" style="left: 4px; top: 15px;font-size: 15px;"></span>\n\n        ';
 } else if(well.smptype === 'PC') { ;
__p += ' <!-- well.smptype === \'PC\' -->\n        <span class="mdi mdi-plus-circle smp-id-link" style="left: 4px; top: 15px;font-size: 15px;"></span>\n\n        ';
 } else if(well.smptype === 'RC') { ;
__p += ' <!-- well.smptype === \'RC\' -->\n        <span class="mdi mdi-alpha-r-circle-outline smp-id-link" style="left: 4px; top: 15px;font-size: 15px;"></span>\n        ';
 } else if(well.smptype === 'OC') { ;
__p += ' <!-- well.smptype === \'RC\' -->\n        <span class="mdi mdi-compass-outline smp-id-link" style="left: 4px; top: 15px;font-size: 15px;"></span>\n        ';
 } ;
__p += '\n    </div>\n</div>';

}
return __p
};});

