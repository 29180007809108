define('env',['module'
], function (module) {
	'use strict';

    module.exports = {
        config: {
            defaults: {
                serverUrl: 'http://localhost:8085/',
                defaultLogo: 'app/styles/css/images/logo.svg',
                compuzzLogo: 'app/styles/css/images/logo.svg',
                compuzzTitle: 'app/styles/css/images/mobiolink.svg',
                notFoundImage: 'app/styles/css/images/404.png',
                catalogueDefaultImage: 'app/styles/css/images/book.png',
                logoToolSubDomain: '',
                editOrderUrl: 'http://localhost:8082/'
            },
            minified: {
                serverUrl: 'http://localhost:8085/',
                defaultLogo: 'images/logo.svg',
                compuzzLogo: 'images/logo.svg',
                compuzzTitle: 'images/mobiolink.svg',
                notFoundImage: 'images/404.png',
                catalogueDefaultImage: 'images/book.png',
                logoToolSubDomain: '',
                editOrderUrl: 'http://localhost:8082/'
            }
        }
    };
});

