define('colorCompensationMatriceValueColumnCollection',[
    'module',
    'backbone',
    'marionette',
    'colorCompensationMatriceValueColumnItem',
    'colorCompensationMatriceValueColumnItemHeader',
    'colorCompensationMatriceValueColumnItemManual',
    'colorCompensationMatriceValueColumnItemManualInput',
    'colorCompensationMatriceValueColumnItemResult'
], function (
    module,
    Backbone,
    Marionette,
    View,
    Header,
    Manual,
    ManualInput,
    Result
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        getChildView: function (item) {
            if (item.get('header')) {
                return Header;
            }
            if (this.options.manual) {
                if (item.get('x') === item.get('y')) {
                    return Manual;
                }
                return ManualInput;
            }
            if (this.options.result) {
                return Result;
            }
            return View;
        },
        childViewOptions: function () {
            return {
                manual: this.options.manual,
                result: this.options.result
            };
        },
        attributes: function () {
            return {style: 'display: flex; flex-flow: row wrap; align-items: center; row-gap: 10px; column-gap: 10px;'};
        }
    });
});
