define('tabsContentItem',[
    'module',
    'marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: _.template('<div class="js-content"></div>'),
        regions: {
            content: '.js-content'
        },
        className: 'tab-pane fade',
        attributes: function () {
            return {id: this.model.cid};
        },
        onRender: function () {
            this.getRegion('content').show(this.model.get('content'));
        },
        serializeData: function () {
            return {};
        },
        setActive: function (active) {
            this.$el.toggleClass('active', active);
            this.$el.toggleClass('in', active);
        }
    });
});
