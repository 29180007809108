define('pcrSetupPrepWellItemHeader',[
	'module',
	'marionette',
	'template!pcrSetupPrepWellItemHeader'
], function (
	module,
	Marionette,
	Tpl
) {
	'use strict';

	module.exports = Marionette.ItemView.extend({
		template: Tpl,

		attributes: function () {
			return {style: 'width: ' + this.options.width + '%;height: ' + this.options.width + '%;'};
		},

		className: 'prep',
		ui: {
			input: '.js-info-input',
			header: '.header'
		},

		events: {
			'mouseover @ui.header': 'onMouseOver',
			'mouseleave @ui.header': 'onMouseLeave',
			'click @ui.header': 'onClick'
		},

		type: 'header',

		serializeData: function () {
			var templateData = {};
			templateData.width = this.options.width;
			templateData.pos = this.model.get('pos');
			templateData.name = this.model.get('name');
			return templateData;
		},

		onMouseOver: function () {
			this.trigger('header:mouseOver', this.model.get('type'), this.model.get('pos'));
		},
		onMouseLeave: function () {
			this.trigger('header:mouseLeave', this.model.get('type'), this.model.get('pos'));
		},
		onClick: function () {
			this.trigger('header:click', this.model.get('type'), this.model.get('pos'));
		}
	});
});
