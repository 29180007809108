define('entities/caccounts/lisConfiguration',[
	'app',
	'backbone',
	'settings',
	'jquery',
	'underscore',
	'module',
	'dynamic',
	'entities/caccounts/sampleClass',
	'backboneRelational'
], function (
	App,
	Backbone,
	Settings,
	$,
	_,
	module,
	Dynamic
) {
	'use strict';

	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.LisConfiguration = Backbone.RelationalModel.extend({
		defaults: {
			'action': '',
			'default': false,
			'source': undefined
		},
		idAttribute: 'secId',

		getImageUid: function () {
		},

		jsonObjectName: 'LISConfigurationJson',
		getPDF: function () {
			return Settings.getDocUrl(this.jsonObjectName + '.pdf');
		},

		jsonObject: 'LisConfiguration',
		getEntity: function () {
			return module.exports;
		},
		findTypeByAction: function (action) {
			var url = Settings.url('compuzz', 'v2/lisConfiguration/typeByAction', {action: action}),
				defer = $.Deferred();

			$.ajax({
				url: url,
				type: 'GET',
				contentType: 'application/json',
				success: function (response) {
					defer.resolve(response);
				},
				error: function (err) {
					defer.reject(err);
				}
			});
			return defer.promise();
		}
	});

	app.ns.LisConfigurationCollection = Backbone.Collection.extend({
		model: app.ns.LisConfiguration
	});

	module.exports = _.defaults({
		getName: function () {
			return 'lis';
		},
		getUrl: function () {
			return 'v2/lis';
		},
		getDynamicJson: function () {
			return 'LISJson';
		},
		getModel: function (modelJson) {
			if (!modelJson) {
				modelJson = {};
			}
			return app.ns.LisConfiguration.findOrCreate(modelJson);
		},
		getFieldsToDisplay: function () {
			return {'fieldsWidth': 400, 'fields': ['action', 'source']};
		},
		getCreateEditLine: function () {
			return [
				{
					'field': 'source', 'param': {
						'type': 'LISCONFIGURATION',
						'display': {
							'class': 'col-w-90', 'headers': [{
								'field': 'ACTION',
								'param': {'display': {'class': 'col-w-27'}}
							}, {
								'field': 'TYPE',
								'param': {'display': {'class': 'col-w-14'}}
							}, {
								'field': 'PARAM',
								'param': {'display': {'class': 'col-w-48'}}
							}]
						}
					}
				},
				{'field': 'default', 'param': {'type': 'BOOLEAN', 'display': {'class': 'col-w-3 p-l-5 p-r-5'}}}
			];
		}
	}, Dynamic);
});
