define('prepRunCollectionView',[
	'module',
	'backbone',
	'marionette',
	'linkNewTabView'
], function (
	module,
	Backbone,
	Marionette,
	View
) {
	'use strict';

	module.exports = Marionette.CollectionView.extend({
		tagName: 'div',
		attributes: function () {
			return {
				id: 'prepRunFromPcrRun',
				style: 'border: solid 1px #333; border-radius: 5px; height: 34px; overflow: auto; white-space: normal; font-size: 12px; padding-left: 2px;'
			};
		},
		className: 'elements-container',
		childViewContainer: '.elements-container',

		childView: View,

		childViewOptions: function (model) {
			return model.getEntity().parameterForLink(model);
		},
		collection: new Backbone.Collection()
	});
});
