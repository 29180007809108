
define('template!prepRunWellEditScanSmpIdPlateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content info-page">\n    <!--<div class="saving-label"></div>-->\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n\n            ';
 if(mode === 'old') { ;
__p += ' <!--used for scan-->\n            <div class="row">\n                <div class="col-xs-5"></div>\n                <!-- Enter sample-id\'s -->\n                <div class="col-xs-2 plateLayoutSetting-column p-l-5 p-r-5">\n                    <div style="height: 158px;">\n                        <div class="labelContainer">\n                            <label class="enterEditPlateLayout-label popupTitleLabel">' +
((__t = ( _.i18n('run.startWellSmpid')
                                )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <!-- scan sample - start screen -->\n                        <div class="js-scan-samples-div">\n                            <div class="col-xs-12 columnContainer">\n                                <div class="col-xs-5 leftColumn"></div>\n                                <div class="col-xs-7 rightColumn"></div>\n                            </div>\n                            <div class="col-xs-12 enterEditPlateLayout-buttons p-l-0 p-r-0 js-scan-samples-btn">\n                                <button disabled\n                                        class="btn btn-primary disabled-grey"\n                                        style="">Scan Samples\n                                </button>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class="col-xs-5"></div>\n            </div>\n            ';
 } ;
__p += '\n\n            <!-- table -->\n            <div class="row" style="margin-top: 15px;">\n                <div class="col-xs-1" style="width: 4%;">\n                    <div class="row m-b-0 clickable">\n                        <div class="wellTableColumnTitleItem col-xs-12"></div>\n                    </div>\n                    ';
 for(var i = 1; i <= row; i++) { ;
__p += '\n                    <div class="row m-b-0 clickable">\n                        <div class="wellTableLineTitleItem col-xs-12 text-center p-t-15">\n                            ' +
((__t = ( String.fromCharCode(i + 64) )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                    ';
 };
__p += '\n                </div>\n                <div class="col-xs-11" style="width: 96%;">\n                    <div class="row m-b-0">\n                        ';
 for(var i = 1; i <= column; i++) {
                        var width = 100 / column; ;
__p += '\n                        <div class="wellTableColumnTitleItem text-center p-t-10"\n                             style="width: ' +
((__t = ( width)) == null ? '' : __t) +
'%; float: left;">' +
((__t = ( ("0" + i).slice(-2) )) == null ? '' : __t) +
'\n                        </div>\n                        ';
 };
__p += '\n                    </div>\n                    ';
 for(var i = 1; i <= row; i++) { ;
__p += '\n                    <div class="row m-b-0">\n                        ';
 for(var j = 1; j <= column; j++) {
                        var pos = String.fromCharCode(i + 64) + ("0" + j).slice(-2);
                        var width = 100 / column; ;
__p += '\n                        <div class="wellTableItem tbl-' +
((__t = ( pos )) == null ? '' : __t) +
'" style="width: ' +
((__t = ( width)) == null ? '' : __t) +
'%; float: left;">\n                        </div>\n                        ';
 };
__p += '\n                    </div>\n                    ';
 };
__p += '\n                </div>\n            </div>\n            ';
 if(mode === 'edit') { ;
__p += '\n            <div class="row">\n                <div>\n                    <label style="font-weight: bold;">' +
((__t = ( _.i18n('run.dblWellClickHelp') )) == null ? '' : __t) +
'</label>\n                </div>\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(mode === 'scan') { ;
__p += '\n            <div class="row m-b-0">\n                <div class="col-xs-12 t-a-c">\n                    <button class="btn btn-primary js-stop-scan-btn" style="">Stop</button>\n                </div>\n            </div>\n            ';
 } ;
__p += '\n\n\n            ';
 if(editTemplate) { ;
__p += '\n            <div class="row m-b-0">\n                <div class="col-xs-12 t-a-r">\n                    <button class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-hide"></button>\n                </div>\n            </div>\n            ';
 } ;
__p += '\n\n        </div>\n    </div>\n    <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

