/**
 * Created by OLD on 04/09/2015.
 */
define('colorUtils',[
    'module',
    'underscore'
], function (
    module,
    _
) {
    'use strict';

    module.exports = {

        rgbToDecimal: function (r, g, b) {
            return ((r * 65536) + (g * 256) + b);
        },

        hexToRgba: function (hexWithAlpha) {
            // Supprimez le caractère '#' initial
            hexWithAlpha = hexWithAlpha.replace('#', '');

            // Extrait les composantes RVB
            var hexColor = hexWithAlpha.slice(0, 6);
            var alphaHex = hexWithAlpha.slice(6);

            // Convertir les composantes RVB en décimal
            var r = parseInt(hexColor.slice(0, 2), 16);
            var g = parseInt(hexColor.slice(2, 4), 16);
            var b = parseInt(hexColor.slice(4, 6), 16);

            // Convertir la composante alpha en décimal
            var a = parseInt(alphaHex, 16) / 255;

            // Retourner les valeurs RVBA sous forme d'objet
            return {
                r: r,
                g: g,
                b: b,
                a: a
            };
        },

        colorTarget: function (index, run, well, procView, curve) {
            var color;
            if (run && run.get('dyes')) {
                var dye = _.first(run.get('dyes').filter(function (dye) {
                    return curve.get('name') === dye.channel + '_' + dye.name;
                }));
                if (dye) {
                    color = this.hexToRgba(dye.color);
                }
            }
            if (!color) {
                if (run && run.get('settings') && run.get('settings')['TARGETS-COLORS']) {
                    var col = run.get('settings')['TARGETS-COLORS'].split(';')[index - 1];
                    color = this.hexToRgba(col);
                } else {
                    color = [{r: 226, g: 147, b: 239}, {r: 0, g: 0, b: 239}, {r: 0, g: 239, b: 0}, {r: 239, g: 0, b: 0},
                        {r: 239, g: 0, b: 239}, {r: 0, g: 239, b: 239}][index - 1];
                }
            }
            var wellTarget;

            if (procView) {
                wellTarget = well;
            } else if (index === -1) {
                wellTarget = well.getWellRes();
            } else if (curve) {
                wellTarget = _.find(well.get('results').models, function (result) {
                    return result.get('curves').indexOf(curve) !== -1 && result.get('curves').length === 1;
                });
            } else {
                wellTarget = well.get('results').findWhere({'sequenceDisplay': index});
            }

            if (wellTarget && wellTarget.get('internalControl') && run.get('settings')['IC-PARAM']) {
                color = this.hexToRgba(run.get('settings')['IC-PARAM'].split(';')[0]);
            }

            if (wellTarget && wellTarget.get('codeErr')) {
                if (wellTarget.get('codeErr') === 'E') {
                    color.dashed = {
                        borderDashOffset: 3,
                        borderDash: [2, 4]
                    };
                } else if (wellTarget.get('codeErr') === 'W') {
                    color.dashed = {
                        borderDashOffset: 10,
                        borderDash: [12, 8]
                    };
                }
            }
            return color;
        },
        defineAlpha: function (hex, alpha) {
            if (!hex) {
                hex = '#F5F5F5';
            }
            // Check if the hex already has alpha
            if (hex.length === 9) {
                // Remove the last 2 characters (alpha value in hex)
                hex = hex.slice(0, -2);
            }
            if (!alpha) {
                alpha = 100;
            }
            // Convert string alpha to number
            if (_.isString(alpha)) {
                alpha = parseInt(alpha, 10);
            }

            var alphaHex = Math.round(alpha / 100 * 255).toString(16);
            if (alphaHex.length === 1)
                alphaHex = '0' + alphaHex;

            return hex + alphaHex;
        }
    };
});

