
define('template!runOperationsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="form-group row">\n\t\t\t\t<label class="col-xs-3 control-label" for="runName">' +
((__t = ( _.i18n('run.name') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<span id="runName" class="col-xs-9">' +
((__t = ( runName )) == null ? '' : __t) +
'</span>\n\t\t\t</div>\n\t\t\t<div class="form-group row">\n\t\t\t\t<fieldset class="col-xs-12 roundedFieldset p-t-10" style="padding-bottom: 20px !important;">\n\t\t\t\t\t<div class="form-group row">\n\t\t\t\t\t\t<label class="col-xs-3 control-label" for="wellRange">' +
((__t = ( _.i18n('runOperation.rangeWell')
							)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t<div class="col-xs-9 rangeWell" id="wellRange">\n\t\t\t\t\t\t\t<input type="text" class="js-info-input form-control input-well m-r-2" maxlength="3"\n\t\t\t\t\t\t\t\t   size="3"\n\t\t\t\t\t\t\t\t   data-field-name="from"/>\n\t\t\t\t\t\t\t<span class="m-r-5 m-l-5">' +
((__t = ( _.i18n('runOperation.rangeWell.to') )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t\t<input type="text" class="js-info-input form-control input-well m-l-2" maxlength="3"\n\t\t\t\t\t\t\t\t   size="3"\n\t\t\t\t\t\t\t\t   data-field-name="to"/>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="form-group row rowRunOperation">\n\t\t\t\t\t\t<label class="col-xs-4">' +
((__t = ( _.i18n('runOperation.wellTest') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-top border-right runOperationAssign">\n\t\t\t\t\t\t\t\t<label class="switch js-checkbox">\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-info-input" data-field-name="wellTest">\n\t\t\t\t\t\t\t\t\t<span class="slider classicSliderColors round"></span>\n\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-top border-right runOperationClear">\n\t\t\t\t\t\t\t\t<input type="checkbox" class="form-control js-info-input"\n\t\t\t\t\t\t\t\t\t   data-field-name="removeWellTest"/>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="form-group row rowRunOperation">\n\t\t\t\t\t\t<label class="col-xs-4">' +
((__t = ( _.i18n('runOperation.sample') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-right runOperationAssign">\n\t\t\t\t\t\t\t\t<input type="text" class="form-control js-info-input" data-field-name="sid"/>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-right runOperationClear">\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="form-group row rowRunOperation">\n\t\t\t\t\t\t<label class="col-xs-4">' +
((__t = ( _.i18n('runOperation.increment') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-right runOperationAssign" style="display: flex;">\n\t\t\t\t\t\t\t\t<label class="col-xs-8">' +
((__t = ( _.i18n('runOperation.increment.by') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t<input type="text" class="js-info-input form-control input-well"\n\t\t\t\t\t\t\t\t\t   data-field-name="incrementValue"/>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-right runOperationClear">\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="form-group row rowRunOperation">\n\t\t\t\t\t\t<label class="col-xs-4">' +
((__t = ( _.i18n('runOperation.assay') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-right js-assay runOperationAssign">\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-right js-assay runOperationClear">\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="form-group row rowRunOperation">\n\t\t\t\t\t\t<label class="col-xs-4"></label>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-right border-bottom runOperationAssign">\n\t\t\t\t\t\t\t\t<button class="btn btn-primary">' +
((__t = ( _.i18n('runOperation.assign') )) == null ? '' : __t) +
'</button>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t\t\t<div class="border-left border-right border-bottom runOperationClear">\n\t\t\t\t\t\t\t\t<button class="btn btn-primary">' +
((__t = ( _.i18n('runOperation.clear') )) == null ? '' : __t) +
'</button>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</fieldset>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

