define('extractionMethodIcKitsView',[
    'module',
    'dialogFormView',
    'template!extractionMethodIcKitsTpl',
    'underscore'
], function (
    module,
    DialogFormView,
    ExtractionMethodIcKitsTpl,
    _
) {
    'use strict';

    var ExtractionIcKitsView = DialogFormView.extend({
        template: ExtractionMethodIcKitsTpl,

        initialize: function (options) {
            this.model = options.model;
        },

        serializeData: function () {
            var templateData = this.options.model.toJSON();

            this.displayIcList = [];
            _.each(this.options.prepRunKitLot.pcrKitLots, _.bind(function (pcrKitLot) {
                this.displayIcList.push({
                    code: pcrKitLot.refKitLot.code,
                    value: pcrKitLot.count
                });
            }, this));

            templateData.displayIcList = this.displayIcList;
            return templateData;
        },

        onShow: function () {
            $.material.init();
        }
    });

    module.exports = ExtractionIcKitsView;
});
