/**
 * Created by RKL on 21/08/2015.
 */
define('samplesController',[
    'module',
    'underscore',
    'sampleCreateEditLayoutView',
    'entities/caccounts/samples',
    'bootbox',
    'dynamicController',
    'jquery',
    'entities/caccounts/sampleResultDefinitions'
], function (
    module,
    _,
    CreateEditLayoutView,
    Samples,
    Bootbox,
    DynamicController,
    $,
    SampleResultDefinitions
) {
    'use strict';

    module.exports = _.defaults({
        showListForQuickSearch: function (region, data, displayArchived) {
            data.entityNamePath = 'caccounts/sample';
            this._showList(region, data, displayArchived, Samples);
        },
        showDetailsBySampleSecId: function (sampleSecId, callBackRefresh) {
            var model = Samples.getModel({secId: sampleSecId});
            model.fetch().done(_.bind(function () {
                if (!model.get('secId')) {
                    Bootbox.alert(_.i18n('quickSearch.sample.notFound'));
                } else {
                    this.showDetails(model, callBackRefresh);
                }
            }, this));
        },

        /**
         *
         * @param model {object}
         * @param callBackRefresh
         */
        showDetails: function (model, callBackRefresh) {
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            $('.js-global-loader').show();
            SampleResultDefinitions.findAll().done(function (sampleResultDefinitions) {
                var createEditView = new CreateEditLayoutView({
                    model: model,
                    sampleResultDefinitions: sampleResultDefinitions
                });
                createEditView.show({
                    title: _.i18n(Samples.getName() + '.new'),
                    className: 'baseTableEditAddPopup sampleBaseTable'
                }, _.bind(function () {
                    if (callBackRefresh) {
                        callBackRefresh();
                    }
                }, callBackRefresh ? callBackRefresh.context : undefined));
            }).always(function () {
                $('.js-global-loader').hide();
            });
        }
    }, DynamicController);
});
