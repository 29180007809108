
define('template!miniWellTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="miniWellSelectableItem ' +
((__t = ( classSelected )) == null ? '' : __t) +
'" style="height: 17px;position: relative;">\n\t<div style="' +
((__t = ( wellResColor?'background-color: '+wellResColor+';':'' )) == null ? '' : __t) +
' height: 19px; width:4px; position: absolute;"></div>\n\t';
 if(existOtherWellSameAssay) { ;
__p += '\n\t<div class="triangle-same-assay-mini" style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
';"></div>\n\t';
 } ;
__p += '\n\t';
 if(existOtherWellOtherAssay) { ;
__p += '\n\t<div class="triangle-other-assay-mini" style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
';"></div>\n\t';
 } ;
__p += '\n\t<div class="h-16" style="padding-left: 10px;">\n\t\t<div class="m-b-0">\n\t\t\t<div class="result-icon" style="margin-left:-4px;">\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>\n';

}
return __p
};});

