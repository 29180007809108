define('assayVersionCreateEditLayout',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!assayVersionCreateEditLayout',
    'underscore',
    'jquery',
    'entities/caccounts/kitprots',
    'entities/caccounts/assayreagent',
    'entities/caccounts/assayconfiguration',
    'entities/caccounts/assaygroup',
    'assayVersionLotCollection',
    'dynamicCreateEditTab',
    'assayConfigurationVersion',
    'dynamicCreateEditCustomListToList',
    'dynamicCreateEditDescriptionCommentTags',
    'arrayInputView',
    'entities/admin/codelist',
    'dynamicCreateEditTypeParam',
    'assayCreateEditLayoutView',
    'entities/admin/array'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    Tpl,
    _,
    $,
    KitProts,
    AssayReagents,
    AssayConfigurations,
    AssayGroups,
    AssayVersionLotCollection,
    DynamicCreateEditTab,
    AssayConfigurationVersion,
    DynamicCreateEditCustomListToList,
    DynamicCreateEditDescriptionCommentTags,
    ArrayInputView,
    CodeLists,
    TypeParamView,
    CreateEditLayoutView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .btn-edit-assay': 'onShowAssay'
        },

        regions: {
            assayConfigurationRegion: '.js-assayConfiguration-region',
            descriptionRegion: '.js-description',
            selectAlgoValidation: '.js-select-algo-deathVolume-region',
            kitLots: '.js-kitlots'
        },

        initialize: function (options) {
            if (options.assayModel) {
                this.model = options.assayModel.clone();
            }
            if (this.model.get('secId') === undefined || this.model.get('secId') === null || this.model.get('secId') === '') {
                this.model.get('configurations').push(AssayConfigurations.getModel());
            }
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            return templateData;
        },

        onShow: function () {
            $.material.init();

            var assayConfigurationView = new AssayConfigurationVersion({
                model: this.model,
                cyclerPublicSecId: this.options.cyclerPublicSecId,
                targetValueFiltered: this.options.targetValueFiltered
            });

            this.getRegion('assayConfigurationRegion').show(assayConfigurationView);

            var collection = new Backbone.Collection(this.options.lots);
            var lotView = new AssayVersionLotCollection({
                model: this.model,
                collection: collection
            });
            this.getRegion('kitLots').show(lotView);
            this.setPermissions(false);
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onAddReagent: function () {
            this.model.get('reagents').add(AssayReagents.getModel());
        },
        onRender: function () {
            this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionCommentTags({
                model: this.model
            }));

            this.triggerMethod('enable:cancel:confirm');
        },
        onShowAssay: function () {
            this.model.get('assay').fetch().done(_.bind(function () {
                var createEditView = new CreateEditLayoutView({
                    model: this.model.get('assay')
                });
                createEditView.show({
                    title: _.i18n('assay.definition'),
                    className: 'popupAssayEdit baseTableEditAddPopup assaysEditAddPopup '
                });
            }, this));
        }
    });
});
