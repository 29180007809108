
define('template!runAssaysTargetsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="tab-content content">\n    <!-- margin-top: 132px; -->\n    <div class="well col-xs-2 p-r-0 p-l-0" style="width: 21% !important;" id="miniWell-runDetail">\n        <div class="run-detail col-xs-12 p-0">\n            <div class="col-xs-8 p-l-10 p-r-0">\n                <div class="col-xs-12 p-l-0 p-r-0">\n                    <div class="col-xs-12 p-r-0" style="padding-left: 33px;">\n                        <div class="groupCellTitle">\n                            ' +
((__t = ( _.i18n('well.assay') )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                </div>\n                <div class="col-xs-12 p-l-0 p-r-0"\n                     style="height: 28px; display: flex;">\n                    <div style="width: 28px;"> ';
 if(assays.length > 1){ ;
__p += '\n                        <button class="previous btn-previous-assay" style="height: 28px; width: 28px;"\n                                type="button">\n                            <i class="previous mdi mdi-play mdi-flip-h"\n                               style="position: relative; bottom: 6px; right: 5px;"></i>\n                        </button>\n                        ';
 } ;
__p += '\n                    </div>\n                    <div class="p-t-0 p-r-5 p-b-0 p-l-5" style="flex-grow: 1;">\n                        <select class="form-control js-info-input-assay" data-field-name="selectedAssay"\n                                id="selectedAssay">\n                            ';
 _.each(assays, function(assay) { ;
__p += '\n                            <option value="' +
((__t = ( assay.secId )) == null ? '' : __t) +
'"\n                            ' +
((__t = ( currentAssayTarget.assay.secId == assay.secId ? 'selected'
                            : '' )) == null ? '' : __t) +
'>' +
((__t = ( assay.code
                            )) == null ? '' : __t) +
'</option>\n                            ';
 }); ;
__p += '\n                        </select>\n                    </div>\n                    <div style="width: 28px;">\n                        ';
 if(assays.length > 1){ ;
__p += '\n                        <button class="next btn-next-assay" style="height: 28px; width: 28px;" type="button">\n                            <i class="next mdi mdi-play" style="position: relative; bottom: 6px; right: 5px;"></i>\n                        </button>\n                        ';
 } ;
__p += '\n                    </div>\n                </div>\n            </div>\n            <div class="col-xs-4">\n                <div class="groupCellTitle">\n                    ' +
((__t = ( _.i18n('well.target') )) == null ? '' : __t) +
'\n                </div>\n                <div class="" style="height: 28px; display: flex;">\n                    <label class="lbl-assay" id="lbl-assay" style="top: 5px; position: relative;">' +
((__t = (
                        currentAssayTarget.target.code )) == null ? '' : __t) +
'</label>\n                </div>\n            </div>\n        </div>\n        <div class="col-xs-12">\n            <span class="clickable hidden-print mdi mdi-filter-remove js-target-reset-filters"\n                  title="Clear all filters"></span>\n        </div>\n        <div class="col-xs-12" id="mini-plate-view">\n        </div>\n    </div>\n    <div class="col-xs-8" style="width: 58% !important;">\n        <div class="well run-detail col-xs-12">\n            <div class="col-xs-12 p-r-0 p-l-0" id="right-view">\n            </div>\n        </div>\n    </div>\n    <!--margin-top: 132px; height: 285px; overflow-y: scroll-->\n    <div class="well col-xs-2" style="width: 21% !important; overflow-y: auto; height: 722px;"\n         id="assayTargetList">\n        ';
 if(assays.length > 0){ ;
__p += '\n        ';
 _.each(assays, function(assay) { ;
__p += '\n\n        <div class="col-xs-12 p-l-0 p-r-0 m-b-10 valViewList-targetAssayContainer whiteBorder"\n             data-secId="' +
((__t = ( assay.secId )) == null ? '' : __t) +
'"\n             style="padding: 2px; background-color: white;\n             ' +
((__t = ( (assay.color && assay.color.backgroundColor ?('background-color:' + assay.color.backgroundColor + ' !important;'):'') )) == null ? '' : __t) +
'\n             ' +
((__t = ( (assay.color && assay.color.assayColor ?('border: solid 2px; border-radius: 5px; border-color:' + assay.color.assayColor + ';'):'') )) == null ? '' : __t) +
'\n                ">\n\n            <!--todo: get assaycolor from plateView -> passed to the controller on keuvalue -> assaaySecId: color-->\n            <span class="col-xs-1 p-0 p-l-10 mdi mdi-flask-outline clickable js-assayVersion"\n                  style="line-height: 15px; font-size: 15px;" data-field-secId="' +
((__t = ( assay.secId )) == null ? '' : __t) +
'"></span>\n            <div class="col-xs-3 noWrapHidden js-valViewList-assayClick clickable" data-secId="' +
((__t = ( assay.secId )) == null ? '' : __t) +
'">\n                ' +
((__t = ( assay.code )) == null ? '' : __t) +
'\n            </div>\n\n            <div class="col-xs-8 p-r-0" data-secId="' +
((__t = ( assay.secId )) == null ? '' : __t) +
'">\n                ';
 _.each(assay.results, function(result) { ;
__p += '\n                <div class="col-xs-12 p-l-0 p-r-0 valViewList-targetIconContainer js-valViewList-targetClick clickable"\n                     data-id="' +
((__t = ( result.id )) == null ? '' : __t) +
'">\n                    <div class="col-xs-1 p-l-0 p-r-0" style="height: 25px;">\n                        <span class="mdi mdi-menu-right valViewList-targetIcon"\n                              style="position: relative; bottom: 8px; display: none;"></span>\n                    </div>\n                    <div class="col-xs-11 p-r-0 noWrapHidden" style="height: 25px;">' +
((__t = ( result.target.code )) == null ? '' : __t) +
'</div>\n                </div>\n                ';
 }); ;
__p += '\n            </div>\n        </div>\n        ';
 }); ;
__p += '\n        ';
 } ;
__p += '\n    </div>\n</div>\n<div id="region-loader">\n</div>';

}
return __p
};});

