define('preProcessView',[
    'module',
    'app',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!preProcessView',
    'autocompleteView',
    'splitLisAnaView',
    'backbone',
    'bootbox',
    'entities/caccounts/preprocess',
    'entities/caccounts/samples',
    'entities/caccounts/printers',
    'routingCommonHeaderView',
    'routingCommonSampleDetailView'
], function (
    module,
    App,
    _,
    $,
    DialogFormView,
    Tpl,
    AutocompleteView,
    SplitLisAnaView,
    Backbone,
    Bootbox,
    PreProcesses,
    Samples,
    Printers,
    RoutingCommonHeaderView,
    RoutingCommonSampleDetailView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        className: 'content pcrSetup height-100 selectRouting',

        ui: {
            sampleScan: '.js-scan-sample-input',
            sampleOutput: '.js-sample-output',
            bioGroup: '.js-bioGroup',
            specie: '.js-specie',
            lis: '.js-LIS',
            lisAnas: '.js-lisAnas',
            targets: '.js-targets',
            assayGroups: '.js-assayGroups',
            log: '.js-log',
            preProcessName: '.js-preProcess-name',
            backToList: '.js-back-to-list',
            currentSampleId: '.js-scan-current-sample',
            orderShow: '.js-order-show',
            nextSample: '.js-next-sample',
            undo: '.js-undo',
            undoSpan: '.js-undo span',
            printBC: '.js-print-barcode'
        },

        regions: {
            printer: '.js-printer-region',
            headerRegion: '.js-header-region',
            sampleDetailRegion: '.js-sample-detail-region'
        },

        events: {
            'change @ui.sampleScan': 'onChangeSampleScan',
            'click @ui.backToList': 'onBackToListClick',
            'click @ui.orderShow': 'onOrderShow',
            'click @ui.nextSample': 'onNextSample',
            'click @ui.printBC': 'onPrintBarcode',
            'click @ui.undo': 'onUndo'
        },

        modelEvents: {
            'change:printer': 'render'
        },

        _emptySample: function () {
            return Samples.getModel({secId: null});
        },

        initialize: function () {
            this.model = new Backbone.Model({
                preProcess: this.options.model,
                i18nCode: 'preProcess',
                sampleIdToScan: '',
                sample: this._emptySample(),
                logs: new Backbone.Collection()
            });
        },

        onRender: function () {
            this.getRegion('headerRegion').show(new RoutingCommonHeaderView({
                model: new Backbone.Model({
                    code: this.options.model.get('code'),
                    name: this.options.model.get('name'),
                    i18nCode: this.model.get('i18nCode')
                })
            }));
            this.getRegion('sampleDetailRegion').show(new RoutingCommonSampleDetailView({
                model: this.model.get('sample'),
                i18nCode: this.model.get('i18nCode')
            }));
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(Printers.getAutocompleteParam({
                    modelProperty: 'printer',
                    callBackOnChange: this.onChangePrinter
                }))
            );
            this.getRegion('printer').show(view);

            this.ui.orderShow.attr('disabled', !this.model.get('sample'));
            this.ui.nextSample.attr('disabled', !this.model.get('sample'));
            this.ui.printBC.attr('disabled', !this.model.get('sample'));
            this.ui.undo.attr('disabled', !this.model.get('sample'));
            this.ui.sampleScan.attr('disabled', !!this.model.get('sample'));

            this.model.get('logs').each(_.bind(function (log) {
                var row = $('<div>', {
                    'class': 'col-xs-12'
                });
                row.prependTo(this.ui.log);
                var opt = $('<span>', {
                    'class': 'col-xs-4'
                });
                opt.append(log.get('sample'));
                opt.appendTo(row);
                opt = $('<span>', {
                    'class': 'col-xs-4'
                });
                opt.append(log.get('newSample'));
                opt.appendTo(row);
                opt = $('<span>', {
                    'class': 'col-xs-4'
                });
                opt.append(log.get('preProcess'));
                opt.appendTo(row);
            }, this));
            if (this.model.get('printer')) {
                this.ui.sampleScan.removeClass('disabled');
                this.ui.sampleScan.attr('disabled', false);
                if (this.ui.sampleScan.val()) {
                    this.ui.undo.removeClass('disabled');
                    this.ui.undoSpan.addClass('clickable');
                } else {
                    this.ui.undo.addClass('disabled');
                    this.ui.undoSpan.removeClass('clickable');
                }
            } else {
                this.ui.sampleScan.addClass('disabled');
                this.ui.sampleScan.attr('disabled', true);
                this.ui.undo.addClass('disabled');
                this.ui.undoSpan.removeClass('clickable');
            }
        },

        onChangePrinter: function (fieldName, model) {
            model = model && model.get('secId') ? Printers.getModel(model.toJSON()) : null;
            this.onChange(fieldName, model);
        },
        onBackToListClick: function () {
            App.navigate('routing', {trigger: true});
        },

        serializeData: function () {
            var templateData = {};
            templateData.sampleOutput = this.model.get('sampleOutput') ? this.model.get('sampleOutput').get('code') : null;
            templateData.description = this.options.model.get('description');
            return templateData;
        },
        onChangeSampleScan: function () {
            var sample = this.ui.sampleScan.val();
            if (!sample) {
                this.displaySampleInformation();
                this.render();
                return;
            }

            if (!this.model.get('printer')) {
                Bootbox.alert(_.i18n('preprocess.printer.notSelected'));
                this.displaySampleInformation();
                this.render();
                return;
            }

            $('.js-global-loader').show();
            Samples.findBySampleFromPreProcessing(sample).done(_.bind(function (sampleModel) {
                if (!sampleModel.get('secId')) {
                    $('.js-global-loader').hide();
                    Bootbox.alert(_.i18n('preProcessing.sample.notFound'));
                    this.displaySampleInformation();
                    this.render();
                    return;
                }
                this.displaySampleInformation(sampleModel);
                this.render();
                if (this.model.get('preProcess').get('configuration').startsWith('SPLIT')) {
                    if (_.isEmpty(this.model.get('sample').get('lisAnas'))) {
                        $('.js-global-loader').hide();
                        Bootbox.alert(_.i18n('preProcess.split.sample.noLisAna'));
                    } else {
                        var splitView = new SplitLisAnaView({model: this.model.get('sample')});
                        splitView.show({title: _.i18n('preProcess.splitLisAna')});
                        this.listenTo(splitView, 'split', _.bind(this.endSplit, this));
                        $('.js-global-loader').hide();
                    }
                } else {
                    this._process();
                }
            }, this))
                .fail(_.bind(function () {
                    $('.js-global-loader').hide();
                    Bootbox.alert(_.i18n('quickSearch.sample.notFound'));
                    this.displaySampleInformation();
                    this.render();
                }, this));
        },

        endSplit: function (lisAnas) {
            $('.js-global-loader').show();
            this._process(lisAnas);
        },

        _process: function (lisAnas) {
            this.model.get('preProcess').processSample(this.model.get('sample'), lisAnas).done(_.bind(function (processSampleResponse) {
                if (processSampleResponse.badConfiguration) {
                    Bootbox.alert(_.i18n('preProcess.badConfiguration'));
                    return;
                }
                if (this.model.get('preProcess').get('configuration').startsWith('DUP')) {
                    this.successProcess(processSampleResponse.sample);
                    return;
                } else if (this.model.get('preProcess').get('configuration').startsWith('TRSF')) {
                    switch (processSampleResponse.type) {
                        case 'NEW_SAMPLE':
                            this.successProcess(processSampleResponse.sample);
                            return;
                        case 'ALIQUOT_ALREADY_EXIST':
                            Bootbox.alert(_.i18n('preProcess.trsf.aliquoteAlreadyExist'));
                            this.successProcess(processSampleResponse.sample);
                            return;
                        case 'BAD_CONFIGURATION':
                            Bootbox.alert(_.i18n('preProcess.badConfiguration'));
                            return;
                        case 'ALIQUOT_SAME_SAMPLE':
                            Bootbox.alert(_.i18n('preProcess.trsf.aliquotSameSample'));
                            return;
                    }
                } else if (this.model.get('preProcess').get('configuration').startsWith('SPLIT')) {
                    switch (processSampleResponse.type) {
                        case 'NEW_SAMPLE':
                            this.model.get('sample').get('lisAnas').remove(this.model.get('sample').get('lisAnas').filter(function (lisAna) {
                                return processSampleResponse.lisAnaSecIds.includes(lisAna.get('secId'));
                            }));
                            this.successProcess(processSampleResponse.sample);
                            return;
                        case 'SAME_SAMPLE':
                            Bootbox.alert(_.i18n('preProcess.split.sameSample'));
                            return;
                        case 'BAD_CONFIGURATION':
                            Bootbox.alert(_.i18n('preProcess.badConfiguration'));
                            return;
                        case 'SAMPLE_ALREADY_EXIST':
                            Bootbox.alert(_.i18n('preProcess.split.sampleAlreadyExist'));
                            return;
                    }
                } else if (this.model.get('preProcess').get('configuration').startsWith('POOL')) {
                }
                Bootbox.alert('Not implemented for "' + this.model.get('preProcess').get('configuration') + '" for preProcess "' + this.model.get('preProcess').get('code') + '"');
                console.error('Not implemented for "' + this.model.get('preProcess').get('configuration') + '" for preProcess "' + this.model.get('preProcess').get('code') + '"');
            }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        successProcess: function (newSample) {
            this.onChange('sampleOutput', Samples.getModel(newSample));
            this.ui.sampleScan.val('');
            this.ui.currentSampleId.val(this.model.get('sample').get('code'));
            this.ui.sampleOutput.val(this.model.get('sampleOutput').get('code'));
            this.addLog(this.model.get('sampleOutput'));
            this.render();
        },

        displaySampleInformation: function (sample) {
            if (!sample) {
                sample = this._emptySample();
            }
            this.onChange('sample', sample);
            this.onChange('sampleScan', null);
            this.onChange('currentSampleId', sample ? sample.get('code') : null);
            this.onChange('sampleOutput', null);
        },

        addLog: function (newSample) {
            this.model.get('logs').push({
                sample: this.model.get('sample').get('code'),
                newSample: newSample.get('code'),
                preProcess: this.model.get('preProcess').get('code')
            });
            var row = $('<div>', {
                'class': 'col-xs-12'
            });
            row.prependTo(this.ui.log);
            var opt = $('<span>', {
                'class': 'col-xs-4'
            });
            opt.append(this.model.get('sample').get('code'));
            opt.appendTo(row);
            opt = $('<span>', {
                'class': 'col-xs-4'
            });
            opt.append(newSample.get('code'));
            opt.appendTo(row);
            opt = $('<span>', {
                'class': 'col-xs-4'
            });
            opt.append(this.model.get('preProcess').get('code'));
            opt.appendTo(row);
        },

        onNextSample: function () {
            this.displaySampleInformation();
            this.render();
        },

        onOrderShow: function () {
            if (!this.model.get('sample') && !this.model.get('sample').get('secId')) {
                Bootbox.alert(_.i18n('quickSearch.sample.notFound'));
            } else {
                if (this.model.get('sample').get('order') && this.model.get('sample').get('order').get('secId')) {
                    $('.js-global-loader').show();
                    require(['ordersController'], _.bind(function (Controller) {
                        Controller.showDetails(this.model.get('sample').get('order'));
                        $('.js-global-loader').hide();
                    }, this));
                } else {
                    Bootbox.alert(_.i18n('quickSearch.sample.notFound'));
                }
            }
        },

        onPrintBarcode: function () {
            this.model.get('printer').print(this.model.get('sampleOutput').get('code'));
        },

        onUndo: function () {
            Samples.deleteEntity(this.model.get('sampleOutput').get('secId'));
            this.displaySampleInformation();
            this.render();
        }
    });
});
