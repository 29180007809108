define('entities/createRunFromTemplate',[
	'app',
	'backbone',
	'settings'
], function (
	App,
	Backbone,
	Settings
) {
	'use strict';
	var CreateRunFromTemplate = Backbone.Model.extend({
		defaults: {
			'refRunTemplate': '',
			'selectedDate': '',
			'numberOfRun': '',
			'refPlateTopLeft': '',
			'refPlateTopRight': '',
			'refPlateBottomLeft': '',
			'refPlateBottomRight': '',
			'refAssay': ''
		},
		idAttribute: 'secId',

		postUrl: function () {
			return Settings.url('compuzz', 'v2/runs/pcr/fromTemplate');
		},

		save: function () {
			this.url = this.postUrl();
			return Backbone.Model.prototype.save.call(this);
		}
	});

	App.reqres.setHandler('createRunFromTemplate:model', function () {
		return new CreateRunFromTemplate();
	});
});
