define('assayResultCollectionView',[
	'module',
	'backbone',
	'marionette',
	'assayResultView',
	'underscore',
	'jquery',
    'template!assayResultCollectionTpl'
], function (
	module,
	Backbone,
	Marionette,
	AssayResultView,
	_,
	$,
    Tpl
) {
	'use strict';

	module.exports = Marionette.CompositeView.extend({
        template: Tpl,
        className: 'assayResultCollection',
		childView: AssayResultView,
        childViewContainer: '.js-assayResult-collection',

		childEvents: {
			'item:delete': function (view, model) {
				this.collection.remove(model);
			}
		},

		collectionFieldsToValidate: [
			{name: 'refMbAna', type: 'required'}
		],

		validateFields: function () {
			return this.validate();
		},

		validate: function () {
			var noError = true;
			_.each(this.collectionFieldsToValidate, _.bind(function (field) {
				var fieldElement = this.$el.find('*[data-field-name="' + field.name + '"]');
				_.each(fieldElement, function (fe) {
					if ($(fe).val() === '' || $(fe).val() === null || $(fe).val() === undefined) {
						$(fe).attr('placeholder', _.i18n('warning.mandatoryFields'));
						$(fe).addClass('invalid-field-value');
						noError = false;
					}
				});
			}, this));
			return noError;
		}
	});
});
