
define('template!arrayCustomFromTypeView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-w-all col-w-100 d-f">\n    <div class="js-autocomplete col-w-20 inputBackground"></div>\n    <div class="js-input lightGrey-input-border col-w-80 p-l-5"></div>\n</div>';

}
return __p
};});

