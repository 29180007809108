define('qcListListView',[
    'module',
    'marionette',
    'underscore',
    'jquery',
    'jqgridView',
    'template!qcListListView',
    'entities/caccounts/pcrwells',
    'entities/caccounts/kitlotpcr'
], function (
    module,
    Marionette,
    _,
    $,
    JqGridView,
    viewTpl,
    PcrWells,
    PcrKitLots
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'wellQCListFilters',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-listQC',
                jqGridPager: '#jq-grid-pager-listQC',
                jqgRow: 'tr.jqgrow',
                blockLoading: '.empty-page-loader'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({}, this.gridEvents);
        },

        onGridLoaded: function () {
            this.trigger('onGridLoaded', this);
        },

        serializeData: function () {
            return {};
        },

        pagination: function () {
            return {
                first: 0,
                rowCount: 0,
                pageSize: 18,
                currentPage: 1
            };
        },

        gridOptions: function (data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('qc.run.date'),
                        name: 'refPcrRun.period',
                        classes: '',
                        formatter: this.dateFormatterFromSettings,
                        search: false,
                        width: 80
                    }, {
                        label: _.i18n('qc.run'),
                        name: 'runName',
                        classes: '',
                        formatter: this.defaultFormatter,
                        search: false,
                        width: 140
                    }, {
                        label: _.i18n('well.pos'),
                        name: 'pos',
                        classes: '',
                        formatter: this.defaultFormatter,
                        search: false,
                        width: 40
                    }, {
                        label: _.i18n('well.sampleType'),
                        name: 'smpType',
                        classes: '',
                        formatter: this.defaultFormatter,
                        search: this.model.getEntity().getName() === PcrKitLots.getName(),
                        width: 30,
                        index: 'qualityControl'
                    }, {
                        label: _.i18n('well.assay'),
                        name: 'refAssay',
                        classes: '',
                        formatter: this.codeNameFormatter,
                        search: this.model.getEntity().getName() === PcrKitLots.getName(),
                        width: 50,
                        index: 'assayCode'
                    }, {
                        label: _.i18n('well.qc.targetResultIcon1'),
                        name: 'targetResultIcon1',
                        classes: '',
                        formatter: _.bind(this.resultIconCodeNumFormatter, this),
                        search: false,
                        width: 60
                    }, {
                        label: _.i18n('well.qc.targetResultIcon2'),
                        name: 'targetResultIcon2',
                        classes: '',
                        formatter: _.bind(this.resultIconCodeNumFormatter, this),
                        search: false,
                        width: 60
                    }, {
                        label: _.i18n('well.qc.targetResultIcon3'),
                        name: 'targetResultIcon3',
                        classes: '',
                        formatter: _.bind(this.resultIconCodeNumFormatter, this),
                        search: false,
                        width: 60
                    }, {
                        label: _.i18n('well.qc.targetResultIcon4'),
                        name: 'targetResultIcon4',
                        classes: '',
                        formatter: _.bind(this.resultIconCodeNumFormatter, this),
                        search: false,
                        width: 60
                    }, {
                        label: _.i18n('well.qc.targetResultIcon5'),
                        name: 'targetResultIcon5',
                        classes: '',
                        formatter: _.bind(this.resultIconCodeNumFormatter, this),
                        search: false,
                        width: 60
                    }],

                sidx: 'runNameDate',
                sord: 'desc',
                onSelectRow: _.bind(function (rowId) {
                    this.trigger('wellSelected', rowId);
                }, this)
            };

            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },

        resultIconCodeNumFormatter: function (cellValue, call, object) {
            var well = PcrWells.getModel(object);
            var targetResultIcons = well.getTargetsResultIcons(true);
            var index = parseInt(call.colModel.name.replaceAll('targetResultIcon', ''), 10) - 1;
            if (targetResultIcons[index]) {
                return targetResultIcons[index].iconHtml;
            }
            return '';
        }
    });
});
