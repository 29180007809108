define('docUrlItemCollectionView',['module',
		'marionette',
		'template!docUrlItemCollectionViewTpl',
		'dialogFormView',
		'underscore',
		'autocompleteView',
		'app',
		'entities/admin/document'
	],
	function (module,
			  Marionette,
			  DocUrlItemCollectionViewTpl,
			  DialogFormView,
			  _,
			  AutocompleteView,
			  App
	) {
		'use strict';

		var DocUrlItemCollectionView = DialogFormView.extend({
			template: DocUrlItemCollectionViewTpl,

			ui: {
				description: '.js-description'
			},

			regions: {
				selectDocument: '.js-select-document-region'
			},

			events: {
				'change @ui.input': 'onInputChange',
				'focus .inputBackground': 'onInputChange',
				'click .delete-row': 'onDelete',
				'change .js-code': 'onChangeCode'
			},

			initialize: function () {
				this.model = this.options.model;
				DialogFormView.prototype.initialize.call(this);
			},
			onShow: function () {
				$.material.init();
				this.getRegion('selectDocument').show(new AutocompleteView(
					this._getAutocompleteOptions(
						'name',
						'secId',
						'name',
						'public/document',
						' ',
						'name',
						this.onChangeDocument,
						'',
						'',
						App.request('document:get-fields-to-display')
					)
				));
				$('.js-select-document-region').find('input').css('width', '300px');
			},

			validFields: function () {
				var result = this.validate(this.collectionFieldsToValidate, false);
				return result;
			},

			onError: function (errors, dontShowError) {
				_.each(errors, _.bind(function (error) {
					this.$('input[data-field-name="' + error.name + '"]').addClass('invalidInput');
					if (this.$('input[data-field-name="' + error.name + '"]').attr('class').includes('autocomplete')) {
						this.$('input[data-field-name="' + error.name + '"]').parent().addClass('invalidInput');
					}
				}, this));
				if (dontShowError) {
					return;
				}
				this.triggerMethod('errorMessage', _.i18n('warning.mandatoryFields'));
			},
			onChangeDocument: function (fieldName, model) {
				if (model) {
					if (model) {
						model.unset('autocompleteValue', {silent: true});
					}
					this.onChange('secId', model.get('secId'));
				}
				this.ui.description.text(model.get('docAbstract') !== null ? model.get('docAbstract') : '');
			},

			serializeData: function () {
				return this.options.model.toJSON();
			},
			onDelete: function () {
				this.trigger('item:delete', this.model);
			}
		});

		module.exports = DocUrlItemCollectionView;
	});
