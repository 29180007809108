define('entities/caccounts/lisRecords',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/lisRecordQueries',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.LisRecord = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/export/result/ASTM/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            namePCRPlate: '',
            createdDate: '',
            server: {},
            wellSecId: '',
            sampleId: '',
            type: null,
            queries: [],
            targetCode: ''
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'queries',
            relatedModel: app.ns.LisRecordQuery,
            collectionType: app.ns.LisRecordQueryCollection,
            reverseRelation: {
                key: 'lisRecord',
                includeInJSON: false
            }
        }],

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/export');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'LisRecordJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LisRecord',
        getEntity: function () {
            return module.exports;
        },
        importable: false
    });

    module.exports = _.defaults({
        getName: function () {
            return 'lisrecord';
        },
        getUrl: function () {
            return 'v2/export/result/ASTM';
        },
        getDynamicJson: function () {
            return 'LisRecordJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.creatable = false;
            config.archivable = false;
            config.selectArchivable = true;
            config.exportable = false;
            config.importable = false;
            config.attachmentable = false;
            config.selectable = false;
            config.deletable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('lisRecord.namePCRPlate'),
                name: 'namePCRPlate',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'namePCRPlate',
                width: 50
            }, {
                label: _.i18n('lisRecord.position'),
                name: 'position',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'position',
                width: 10
            }, {
                label: _.i18n('lisRecord.sampleId'),
                name: 'sampleId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'sampleId',
                width: 40
            }, {
                label: _.i18n('lisRecord.targetCode'),
                name: 'targetCode',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'targetCode',
                width: 40
            }, {
                label: _.i18n('lisRecord.created'),
                name: 'createdDate',
                formatter: viewDynamic.dateTimeFormatter,
                search: true,
                index: 'createdDate',
                width: 40
            }, {
                label: _.i18n('lisRecord.lastQuery.responseDate'),
                name: 'lastQuery.responseDate',
                formatter: viewDynamic.dateTimeFormatter,
                search: false,
                index: 'lastQuery.responseDate',
                width: 40
            }, {
                label: _.i18n('lisRecord.lastQuery.responseType'),
                name: 'lastQuery.responseType',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'lastQuery.responseType',
                width: 40
            }, {
                label: _.i18n('lisRecord.server'),
                name: 'server',
                classes: 'dynamic-link',
                formatter: _.bind(this.serverFormatter, this),
                search: false,
                index: 'server',
                width: 150
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'createdDate';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        serverFormatter: function (cellvalue) {
            if (!cellvalue) {
                return '';
            }
            return '<span>' + cellvalue.uriString + '</span>';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.LisRecord.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        }
    }, Dynamic);
});
