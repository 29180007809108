/**
 * Created by Matteo on 4/08/2015.
 */
define('pcrSetupMain',[
	'module',
	'logger',
	'pcrSetupRouter'
], function (
	module,
	Logger
) {
	'use strict';

	module.exports = {
		start: function () {
			Logger.log(module, 'starting PcrSetupModule');
		},

		stop: function () {
			Logger.log(module, 'stopping PcrSetupModule');
		}
	};
});
