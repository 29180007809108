define('pcrWellResultMagPixItemView',[
    'module',
    'marionette',
    'template!pcrWellResultMagPixItemView',
    'underscore',
    'jquery',
    'editWellView',
    'chartHorizontalBarView',
    'entities/caccounts/mbanaresgrs'
], function (
    module,
    Marionette,
    Tpl,
    _,
    $,
    EditWellView,
    ChartHorizontalBarView
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: Tpl,

        regions: {
            chart: '.js-chart'
        },

        events: {
            'click .btn-edit-well-target-result': 'onEditWellTargetResult',
            'click .result-custom': 'onPropertiesClick'
        },

        modelEvents: {
            'sync': 'render'
        },

        attributes: {
            style: 'display: flex;'
        },

        className: 'result-magpix',

        serializeData: function () {
            this.result = this.model.getResultIcon();
            var templateData = this.result;
            templateData = _.extend(templateData, {
                result: this.model.get('result'),
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : undefined,
                refPcrWellExported: this.model.get('refPcrWell') ? this.model.get('refPcrWell').get('exported') : false
            });

            templateData.propC = this.model.getPropCFormated();

            templateData.userVal1Code = '';
            templateData.userVal1Title = '';
            if (this.model.get('refValUser')) {
                templateData.userVal1Code = this.model.get('refValUser').get('nickName');
                if (this.model.get('refValUser').get('email')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('email');
                }
                if (templateData.userVal1Code === '' && templateData.userVal1Title) {
                    templateData.userVal1Code = templateData.userVal1Title;
                }
                if (this.model.get('refValUser').get('firstName') && this.model.get('refValUser').get('lastName')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('firstName') + ' ' + this.model.get('refValUser').get('lastName');
                }
            }
            templateData.userVal2Code = '';
            templateData.userVal2Title = '';
            if (this.model.get('refValUser2')) {
                templateData.userVal2Code = this.model.get('refValUser2').get('nickName');
                if (this.model.get('refValUser2').get('email')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('email');
                }
                if (templateData.userVal2Code === '' && templateData.userVal2Title) {
                    templateData.userVal2Code = templateData.userVal2Title;
                }
                if (this.model.get('refValUser2').get('firstName') && this.model.get('refValUser2').get('lastName')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('firstName') + ' ' + this.model.get('refValUser2').get('lastName');
                }
            }
            templateData.readOnly = this.model.isReadOnly();

            templateData.properties = '';
            _.each(this.model.get('properties'), function (property) {
                var value = property.value;
                if (!_.isNaN(value)) {
                    value = parseFloat(value).toFixed(1);
                }
                templateData.properties += property.name.split('.').pop() + ': ' + value + ', ';
            });

            templateData.properties = {mdd: null, mfi: null, beadCount: null};

            var property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('MFI Value');
            });

            if (property) {
                templateData.properties.mfi = parseFloat(property.value).toFixed(0);
            }
            property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('MFI Threshold');
            });
            if (property) {
                templateData.properties.mfi += ' (' + parseFloat(property.value).toFixed(0) + ')';
            }
            property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('MDD Value');
            });
            if (property) {
                templateData.properties.mdd = parseFloat(property.value).toFixed(0);
            }
            property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('MDD Threshold');
            });
            if (property) {
                templateData.properties.mdd += ' (' + parseFloat(property.value).toFixed(0) + ')';
            }
            property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('Bead Count');
            });
            if (property) {
                templateData.properties.beadCount = property.value;
            }

            templateData.errorMessage = '';
            if (templateData.codeErr) {
                templateData.errorMessage = templateData.codeErr + ' ';
            }
            if (templateData.justificatory) {
                templateData.errorMessage = templateData.justificatory;
            }
            return templateData;
        },

        onRender: function () {
            this.renderChart();
        },
        renderChart: function () {
            if (this.model.get('valSt') === 0) {
                this.getRegion('chart').empty();
            } else {
                var chart = new ChartHorizontalBarView({
                    max: 6,
                    color: this.result.color,
                    data: parseFloat(this.result.cts).toFixed(2)
                });
                this.getRegion('chart').show(chart);
            }
        },

        onEditWellTargetResult: function () {
            var createEditView = new EditWellView({
                model: this.model.get('refPcrWell'),
                modelResult: this.model,
                wellResColor: this.wellResColor,
                newItem: false
            });
            createEditView.show({
                title: _.i18n('well.info.edit'),
                className: 'popupWellView'
            });
        },
        onPropertiesClick: function () {
            require(['pcrWellController'], _.bind(function (Controller) {
                Controller.showProperties(this.model);
            }, this));
        }
    });
});
