define('colorCompensationMatriceValueColumnItemResult',[
    'module',
    'marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        className: 'rounded-5 t-a-r',
        modelEvents: {
            'change:manualCorrection': 'render',
            'change': 'render'
        },
        attributes: function () {
            var style = this.options.result ? 'width: 60px; font-size: 14px;' : 'width: 40px; font-size: 10px;';
            var ret = {style: style};
            if (this.model.get('x') === this.model.get('y')) {
                ret.style += 'background-color: var(--css-theme-image-filter-color-02);';
            }
            return ret;
        },
        template: _.template('<%= result %>'),
        serializeData: function () {
            var val = parseFloat(this.model.get('value'), 10) + parseFloat(this.model.get('manualCorrection'), 10);
            if (val > parseInt(val, 10) && val < parseInt(val, 10) + 1) {
                val = parseFloat(val, 10).toFixed(1);
            }
            return {
                result: val
            };
        },
        onRender: function () {
            if (this.model.get('value') !== (parseFloat(this.model.get('value'), 10) + parseFloat(this.model.get('manualCorrection'), 10))) {
                this.$el.css('background-color', '#a9e595');
            } else if (this.model.get('x') !== this.model.get('y')) {
                this.$el.css('background-color', '');
            }
        }

    });
});
