
define('template!prepRunExtrKitsLotsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="col-xs-12 p-l-0 p-r-0">\n                <div class="col-xs-12 p-l-0 p-r-0" style="display: flex; text-align: left; height: 21px;">\n                    <div class="noWrapHidden p-l-5 p-r-0" style="width: 15%;">\n                        <label>' +
((__t = ( _.i18n('extraction.reagent.reagentType') )) == null ? '' : __t) +
'</label>\n                    </div>\n                    <div class="noWrapHidden p-l-5 p-r-0" style="width: 15%;">\n                        <label>' +
((__t = ( _.i18n('extraction.reagent.kit') )) == null ? '' : __t) +
'</label>\n                    </div>\n                    <div class="noWrapHidden p-l-5 p-r-0" style="width: 30%;">\n                        <label>' +
((__t = ( _.i18n('extraction.reagent.litlot') )) == null ? '' : __t) +
'</label>\n                    </div>\n                    <div class="noWrapHidden p-l-5 p-r-0" style="width: 10%;">\n                        <label>' +
((__t = ( _.i18n('extraction.reagent.qty') )) == null ? '' : __t) +
'</label>\n                    </div>\n                    <div class="noWrapHidden p-l-5 p-r-0" style="width: 8%; ">\n                        <label>' +
((__t = ( _.i18n('extraction.reagent.vol.test') )) == null ? '' : __t) +
'</label>\n                    </div>\n                    <div class="noWrapHidden p-l-5 p-r-0" style="width: 8%; ">\n                        <label>' +
((__t = ( _.i18n('extraction.reagent.vol.death') )) == null ? '' : __t) +
'</label>\n                    </div>\n                    <div class="noWrapHidden p-l-5 p-r-0" style="width: 14%;">\n                        <label>' +
((__t = ( _.i18n('extraction.reagent.vol.tot') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n            </div>\n            ';
 if(kitLots && kitLots.extractionKitLots && kitLots.extractionKitLots.length > 0) { ;
__p += '\n            ';
 _.each(kitLots.extractionKitLots, function(extractionKitLot) { ;
__p += '\n            <div class="col-xs-12 p-l-0 p-r-0 m-b-10" style="display: flex; text-align: left;">\n                <div class="p-l-5"\n                     style="border-bottom: 1px solid #333333; background-color: white; width: 15%; padding: 2px 0 0 5px;">\n                    ' +
((__t = ( extractionKitLot.reagent ? extractionKitLot.reagent.code : '' )) == null ? '' : __t) +
'\n                </div>\n                <div class="p-l-5"\n                     style="border-bottom: 1px solid #333333; background-color: white; width: 15%; padding: 2px 0 0 5px;">\n                    ' +
((__t = ( extractionKitLot.refKit.code )) == null ? '' : __t) +
'\n                </div>\n                <div class="p-l-5"\n                     style="border-bottom: 1px solid #333333; background-color: white; width: 30%; padding: 2px 0 0 5px;">\n                    ' +
((__t = ( extractionKitLot.refKitLot.name )) == null ? '' : __t) +
'\n                </div>\n                <div class="p-l-5"\n                     style="border-bottom: 1px solid #333333; background-color: white; width: 10%; padding: 2px 0 0 5px;">\n                    ' +
((__t = ( extractionKitLot.count )) == null ? '' : __t) +
'\n                </div>\n                <div class="p-l-5"\n                     style="border-bottom: 1px solid #333333; background-color: white; width: 8%; padding: 2px 0 0 5px;">\n                    ' +
((__t = ( extractionKitLot.volumeByTest )) == null ? '' : __t) +
'\n                </div>\n                <div class="p-l-5"\n                     style="border-bottom: 1px solid #333333; background-color: white; width: 8%; padding: 2px 0 0 5px;">\n                    ' +
((__t = ( extractionKitLot.volumeDeath )) == null ? '' : __t) +
'\n                </div>\n                <div class="p-l-5"\n                     style="border-bottom: 1px solid #333333; background-color: white; width: 14%; padding: 2px 0 0 5px;">\n                    ' +
((__t = ( ((extractionKitLot.count*extractionKitLot.volumeByTest) +
                    extractionKitLot.volumeDeath).toFixed(2) )) == null ? '' : __t) +
'\n                </div>\n            </div>\n            ';
 }); ;
__p += '\n            ';
 } else { ;
__p += '\n            <div class="col-xs-12 p-l-0 p-r-0 m-b-10" style="display: flex; text-align: left;">\n                <div class="p-l-5"\n                     style="border-bottom: 1px solid #333333; background-color: white; width: 100%; padding: 2px 0 0 5px;">\n                    ' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n            ';
 } ;
__p += '\n            <div class="col-xs-12 p-l-0 p-r-0 m-t-20">\n                <div class="col-xs-3">\n                </div>\n                <div class="col-xs-6">\n                    <div class="row p-l-0 p-r-0" style="display: flex; text-align: left; height: 21px;">\n                        <div class="col-xs-8 p-l-5 p-r-0 noWrapHidden">\n                            <label>\n                                ' +
((__t = ( _.i18n('extraction.icKit.code') )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                        <div class="col-xs-4 p-l-5 p-r-0 noWrapHidden">\n                            <label>\n                                ' +
((__t = ( _.i18n('extraction.icKit.quantity') )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                    </div>\n                    ';
 if(kitLots && kitLots.pcrKitLots && kitLots.pcrKitLots.length > 0) {;
__p += '\n                    ';
 _.each(kitLots.pcrKitLots, function (pcrKitLot) { ;
__p += '\n                    <div class="row p-l-0 p-r-0" style="display: flex; text-align: left;">\n                        <div class="col-xs-8"\n                             style="background-color: white; border-bottom: 1px solid #ccc; padding: 2px 0 0 5px;">\n                            ' +
((__t = ( pcrKitLot.refKitLot.code )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-4"\n                             style="background-color: white; border-bottom: 1px solid #ccc; padding: 2px 0 0 5px;">\n                            ' +
((__t = ( pcrKitLot.count )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                    ';
 }); ;
__p += '\n                    ';
 } else { ;
__p += '\n                    <div class="col-xs-12 p-l-0 p-r-0"\n                         style="display: flex; text-align: left;">\n                        <div class="p-l-5 col-xs-12"\n                             style="border-bottom: 1px solid #333333; background-color: white; padding: 2px 0 0 5px;">\n                            ' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                    ';
 } ;
__p += '\n                </div>\n                <div class="col-xs-3">\n                </div>\n            </div>\n            <div class="row cancelConfirmRow" style="text-align: center;">\n                <div class="pull-right p-r-15 col-xs-12 p-l-0 p-r-0 m-t-20">\n                    <label style="padding-right: 20px; font-size: 17px;">' +
((__t = ( _.i18n('print.pcr.setup.sheet') )) == null ? '' : __t) +
'</label>\n                    <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n                    <button class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"\n                            disabled></button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

