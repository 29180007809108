define('prepRunTableTabView',[
    'module',
    'marionette',
    'template!prepRunTableTabTpl',
    'underscore',
    'settings',
    'app',
    'colorUtils',
    'envHandler',
    'backbone',
    'dateUtils',
    'prepWellView',
    'entities/caccounts/prepwells',
    'entities/caccounts/setting'
], function (
    module,
    Marionette,
    runTableTabTpl,
    _,
    Settings,
    App,
    ColorUtils,
    EnvironmentHandler,
    Backbone,
    DateUtil,
    WellView
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: runTableTabTpl,

        selectedWells: [],

        modelEvents: {
            'change': 'renderSafe'
        },

        ui: {
            blockLoading: '.empty-page-loader',
            wellContainer: '.well-container'
        },

        events: {
            'click .wellTableCornerTitle .js-top-left': 'onTopLeftClick',
            'click .wellTableCornerTitle .js-top-right': 'onTopRightClick',
            'click .wellTableCornerTitle .js-bottom-left': 'onBottomLeftClick',
            'click .wellTableCornerTitle .js-bottom-right': 'onBottomRightClick'
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        initialize: function (options) {
            this.model = options.model;
            this.step = parseInt(options.step, 10);
            this.wellSelected = this.model.get('wells').filterByPos(options.from, options.step, options.to);
        },

        serializeData: function () {
            var templateData = {
                name: this.model.get('name') ? this.model.get('name') : '-',
                creatDate: this.model.get('creatDate') ? this.model.get('creatDate') : '-',
                creatUser: this.model.get('refCreatUser') && this.model.get('refCreatUser').email ? this.model.get('refCreatUser').email : '-',
                comment: this.model.get('comment')
            };
            templateData.rows = [];
            templateData.columns = [];

            this.wellSelected.forEach(
                _.bind(function (well) {
                    var row = well.get('pos').substring(0, 1);
                    if (!_.contains(templateData.rows, row)) {
                        templateData.rows.push(row);
                    }
                    var column = parseInt(well.get('pos').substring(1, 3), 10);
                    if (!_.contains(templateData.columns, column)) {
                        templateData.columns.push(column);
                    }
                }, this));

            templateData.width = 100 / parseInt(templateData.columns.length, 10);
            templateData.isMultiDisplay = this.options.isMultiDisplay;
            templateData.isGrouping = this.step === 2;
            templateData.firstWellPos = this.wellSelected && this.wellSelected.size() > 0 ? this.wellSelected.at(0).get('pos') : '';
            return templateData;
        },

        onRender: function () {
            this.filters = this.options.filters ? this.options.filters : null;
            this.filteredWells = this.options.filteredWells ? this.options.filteredWells : null;
            this.showWell();
            this.selectedWells = [];
        },

        onShow: function () {
        },

        showWell: function () {
            this.colorsAssay = [];
            if (this.model.get('settings') && this.model.get('settings')['COLORS-ASSAY']) {
                this.colorsAssay = this.model.get('settings')['COLORS-ASSAY'].split(';');
            }
            this.index = 2;
            this.assaysWaitingList = '';

            this.wellSelected.forEach(
                _.bind(function (well) {
                    var backgroundColor = '#efefef';
                    var wellAssaysWaitingList = well.getWellAssaysWaitingList().join(';');

                    if (this.colorsAssay.length > 0 && wellAssaysWaitingList) {
                        if (this.assaysWaitingList !== wellAssaysWaitingList) {
                            this.assaysWaitingList = wellAssaysWaitingList;
                            this.index++;
                        }
                        if (this.index + 1 > this.colorsAssay.length) {
                            this.index = 2;
                        }
                        backgroundColor = ColorUtils.defineAlpha(this.colorsAssay[this.index], this.colorsAssay[0]);
                    } else {
                        this.assaysWaitingList = '';
                    }

                    this.addRegion('tbl' + well.get('pos'), '.tbl-' + well.get('pos'));
                    var region = this.getRegion('tbl' + well.get('pos'));
                    var wellView = new WellView({
                        model: well,
                        // assayIndex: assayIndex,
                        backgroundColor: backgroundColor,

                        isMultiDisplay: this.step === 2,
                        region: region.$el
                    });

                    region.show(wellView);

                    this.fadeWell = false;
                    if (this.filteredWells && this.filteredWells.length > 0) {
                        var count = 0;
                        _.each(this.filteredWells, _.bind(function (filter) {
                            if (well.get('pos') === filter) {
                                count++;
                            }
                        }, this));
                        if ((count < 1)) {
                            this.fadeWell = true;
                        }
                    }
                    if (this.fadeWell) {
                        if (!region.$el.hasClass('fadeWell')) {
                            region.$el.addClass('fadeWell');
                        }
                    } else {
                        if (region.$el.hasClass('fadeWell')) {
                            region.$el.removeClass('fadeWell');
                        }
                    }
                }, this));
        },

        changeWellSelected: function (from, disableGroup) {
            $('.js-global-loader').show();
            this.step = (disableGroup ? 1 : 2);
            if (disableGroup) {
                from = 'A01';
            }
            this.wellSelected = this.model.get('wells').filterByPos(from, this.step, this.model.get('plateSize'));
            App.navigate('runs/prep/' + this.model.get('secId') + '/' + from + '/' + this.step + '/' + this.model.get('plateSize') + '/table');
            this.render();
            $('.js-global-loader').hide();
        },

        onTopLeftClick: function () {
            this.changeWellSelected('A01', this.step === 2 && this.wellSelected.first().get('pos') === 'A01');
        },

        onTopRightClick: function () {
            this.changeWellSelected('A02', this.step === 2 && this.wellSelected.first().get('pos') === 'A02');
        },

        onBottomLeftClick: function () {
            this.changeWellSelected('B01', this.step === 2 && this.wellSelected.first().get('pos') === 'B01');
        },

        onBottomRightClick: function () {
            this.changeWellSelected('B02', this.step === 2 && this.wellSelected.first().get('pos') === 'B02');
        }
    });
});
