/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminMainRouter',[
    'underscore',
    'jquery',
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'wellUtils',
    'cssUtils',
    'itemPagesNameMixin'
], function (
    _,
    $,
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    WellUtils,
    CssUtils,
    ItemPagesNameMixin
) {
    'use strict';

    var SuperAdminRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'superAdmin/caccounts': 'showCaccountsSuperadmin',
            'superAdmin/users': 'showUsersSuperadmin',
            'superAdmin/cyclers': 'showCyclersSuperadminList',
            'superAdmin/detectionFormats': 'showDetectionFormatsSuperadminList',
            'superAdmin/codelists': 'showCodeListsSuperadminList',
            'superAdmin/arrays': 'showArraysSuperadminList',
            'superAdmin/docglossaries': 'showDocGlossariesSuperadminList',
            'superAdmin/docglossaries/new': 'showDocGlossariesSuperadminNew',
            'superAdmin/dbDocumentation': 'showDBDocumentationSuperadmin',
            'superAdmin/logs': 'showLogs',
            'superAdmin/dbDocumentation/new': 'showDBDocumentationSuperadminNew',
            'superAdmin/documents': 'showDocumentsSuperadminList',
            'superAdmin/documents/new': 'showDocumentsSuperadminNew',
            'superAdmin/menus': 'showMenusSuperadmin',
            'superAdmin/menus/new': 'showMenusSuperadminNew',
            'superAdmin/godMode': 'showGodModeSuperAdmin'
        }
    });

    var API = {
        checkContent: function () {
            var defer = $.Deferred();

            require([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        },

        showCaccountsSuperadmin: function () {
            require(['caccountsController'], _.bind(function (Controller) {
                this.dynamicShowList('global/caccounts', ItemPagesNameMixin.SUPERADMIN_CACCOUNTS, Controller.showDetails);
            }, this));
        },
        showUsersSuperadmin: function () {
            this.dynamicShowList('global/userpids', ItemPagesNameMixin.SUPERADMIN_USERS);
        },
        showLogs: function () {
            this.dynamicShowList('global/logs', ItemPagesNameMixin.SUPERADMIN_LOGS);
        },

        showCyclersSuperadminList: function () {
            this.dynamicShowList('admin/cyclers', ItemPagesNameMixin.SUPERADMIN_CYCLERS);
        },
        showDetectionFormatsSuperadminList: function () {
            this.dynamicShowList('admin/detectionFormat', ItemPagesNameMixin.SUPERADMIN_DETECTIONFORMATS);
        },

        showCodeListsSuperadminList: function () {
            this.dynamicShowList('admin/codelist', ItemPagesNameMixin.SUPERADMIN_CODELISTS);
        },

        showArraysSuperadminList: function () {
            this.dynamicShowList('admin/array', ItemPagesNameMixin.SUPERADMIN_ARRAYS);
        },

        showDocGlossariesSuperadminList: function () {
            this.showDocGlossariesSuperadmin(false);
        },

        showDocGlossariesSuperadminNew: function () {
            this.showDocGlossariesSuperadmin(true);
        },

        showDocGlossariesSuperadmin: function (openNewPopup) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(_.bind(function () {
                require([
                    'superAdminDocGlossaryDetailsController'
                ], function (SuperAdminDocGlossaryController) {
                    SuperAdminDocGlossaryController.showList();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_DOCGLOSSARIES);

                    if (openNewPopup) {
                        SuperAdminDocGlossaryController.showAdd();
                    } else {
                        if (SuperAdminDocGlossaryController.createEditView) {
                            SuperAdminDocGlossaryController.createEditView.hide(true);
                            SuperAdminDocGlossaryController.createEditView = null;
                        }
                    }
                });
            }, this));
        },

        showDBDocumentationSuperadminNew: function () {
            this.showDBDocumentationSuperadmin(true);
        },

        showDBDocumentationSuperadmin: function (openNewPopup) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(_.bind(function () {
                require([
                    'superAdminDBDocumentationController'
                ], function (SuperAdminDBDocumentationController) {
                    SuperAdminDBDocumentationController.show();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_APPLICATIONOBJECTDOCS);
                    if (openNewPopup) {
                        SuperAdminDBDocumentationController.showAdd();
                    }
                });
            }, this));
        },

        showDocumentsSuperadminNew: function () {
            this.showDocumentsSuperadmin(true);
        },

        showDocumentsSuperadminList: function () {
            this.showDocumentsSuperadmin(false);
        },

        showDocumentsSuperadmin: function (openNewPopup) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(_.bind(function () {
                require([
                    'superAdminDocumentController'
                ], function (SuperAdminDocumentController) {
                    SuperAdminDocumentController.show(true);
                    App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_DOCUMENTS);

                    if (openNewPopup) {
                        SuperAdminDocumentController.showAdd(true);
                    } else {
                        if (SuperAdminDocumentController.createEditView) {
                            SuperAdminDocumentController.createEditView.hide(true);
                            SuperAdminDocumentController.createEditView = null;
                        }
                    }
                });
            }, this));
        },

        showMenusSuperadminNew: function () {
            this.showMenusSuperadmin(true);
        },

        showMenusSuperadmin: function (openNewPopup) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(_.bind(function () {
                require([
                    'superAdminMenuDetailsController'
                ], function (SuperAdminMenuDetailsController) {
                    SuperAdminMenuDetailsController.showList();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MENUS);
                    if (openNewPopup) {
                        SuperAdminMenuDetailsController.showAdd();
                    }
                });
            }, this));
        },

        showGodModeSuperAdmin: function () {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(_.bind(function () {
                require([
                    'superAdminGodModeController'
                ], function (Controller) {
                    Controller.show(App.regions.getRegion('main').currentView.getRegion('content'));
                });
            }, this));
        },

        dynamicShowList: function (entityNamePath, itemPagesNameMixin, callBackCreateEditView) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                require([
                    'dynamicController'
                ], function (DynamicController) {
                    DynamicController.showList(
                        App.regions.getRegion('main').currentView.getRegion('content'), {
                            entityNamePath: entityNamePath,
                            callBackCreateEditView: callBackCreateEditView
                        });
                    App.execute('set:active:nav:item', itemPagesNameMixin);
                });
            });
        }
    };

    App.on('before:start', function () {
        new SuperAdminRouter({
            controller: API
        });
    });

    module.exports = SuperAdminRouter;
});
