define('prepRunsShowController',[
	'backbone',
	'module',
	'logger',
	'underscore',
	'app',
	'cssUtils',
	'prepRunsShowView',
	'jquery',
	'bootbox',
	'customBootboxMessage',
	'emptyView',
	'prepRunCreateLayoutView',
	'prepRunCreateFromUploadView',
	'runChooseUploadView',
	'settings',
	'prepRunCreatePcrRunFromFilePlateLayoutView',
	'prepRunCreatePcrRunFromFileSampleListView',
	'runRemoteFolderView',
	'entities/caccounts/prepruns',
	'entities/admin/codelist',
	'entities/caccounts/setting'
], function (
	Backbone,
	module,
	Logger,
	_,
	App,
	CssUtils,
	View,
	$,
	bootbox,
	CustomBootboxMessage,
	EmptyView,
	CreateEditLayoutView,
	CreateFromUploadView,
	ChooseUploadView,
	Settings,
	PrepRunCreatePcrRunFromFilePlateLayoutView,
	PrepRunCreatePcrRunFromFileSampleListView,
	RunRemoteFolderView,
	PrepRuns,
	CodeLists
) {
	'use strict';

	module.exports = {
		showRuns: function (displayArchived) {
			CssUtils.applyPrepRunTheme();
			App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
				CodeLists.findItemsByCode('PREPRUN_ST').done(_.bind(function (data) {
					if (!displayArchived) {
						displayArchived = false;
					}
					this.view = new View({
						displayArchived: displayArchived,
						settings: settings,
						codeList: data,
						entity: PrepRuns
					});
					this.selectedList = [];
					this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
					this.view.listenTo(this.view, 'onLoadSelectedRuns', _.bind(this.onLoadSelectedRuns, this));
					this.view.listenTo(this.view, 'run:prep:import-xml', _.bind(this.onImportXml, this));
					this.view.listenTo(this.view, 'run:prep:delete', _.bind(this.onDelete, this));
					this.view.listenTo(this.view, 'run:prep:archive', _.bind(this.onArchive, this));
					this.view.listenTo(this.view, 'run:prep:unarchive', _.bind(this.onUnArchive, this));
					this.view.listenTo(this.view, 'run:prep:import', _.bind(this.showImportRun, this));
					this.view.listenTo(this.view, 'run:prep:selectedList', _.bind(this.onSelectedPrepRunsList, this));
					App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
				}, this));
			}, this));
		},

		onArchive: function (secId) {
			App.request('run:prep:archive', secId)
				.done(_.bind(function () {
					this.view.updatePagination('reloadGrid');
				}, this));
		},

		onUnArchive: function (secId) {
			App.request('run:prep:unarchive', secId)
				.done(_.bind(function () {
					this.view.updatePagination('reloadGrid');
				}, this));
		},

		onSelectedPrepRunsList: function (selectedList) {
			this.selectedList = selectedList;
		},

		getSelectedPrepRunsList: function () {
			return this.selectedList;
		},

		onCreateEmptyPrepRun: function (defDept) { //used to create run from listView
			this.createEditView = new CreateEditLayoutView({
				runModel: App.request('run:prep:model'),
				newItem: true,
				defDept: defDept
			});
			this.createEditView.show({
				title: _.i18n('run.prep.new'),
				className: 'actionPopupCss900 actionPopupCss popupFitContent'
			});
			this.createEditView.listenTo(this.createEditView, 'run:prep:create-from-popup', _.bind(this.onCreateFromPopup, this));
			this.createEditView.listenTo(this.createEditView, 'run:prep:reloadData', _.bind(function () {
				this.view.updatePagination('reloadGrid');
			}, this));
			//this.createEditView.listenTo(this.createEditView, 'run:listView:refresh', _.bind(this.onRefreshListViewGrid, this));
		},

		showNewRun: function (defDept) {  //used to create run from listView
			this.createEditView = new CreateEditLayoutView({
				runModel: App.request('run:prep:model'),
				newItem: true,
				defDept: defDept
			});
			this.createEditView.show({
				title: _.i18n('run.prep.new'),
				className: 'actionPopupCss900 actionPopupCss popupFitContent'
				//actionLargePopupCss //actionMediumPopupCss // actionPopupCss900
			});
			this.createEditView.listenTo(this.createEditView, 'run:prep:create-from-popup', _.bind(this.onCreateFromPopup, this));
			this.createEditView.listenTo(this.createEditView, 'run:prep:reloadData', _.bind(function () {
				this.view.updatePagination('reloadGrid');
			}, this));
			//this.createEditView.listenTo(this.createEditView, 'run:listView:refresh', _.bind(this.onRefreshListViewGrid, this));
		},

		showViewNewRunFromFilePlateLayout: function () {
			App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
				this.createEditView = new PrepRunCreatePcrRunFromFilePlateLayoutView({
					model: new Backbone.Model(),
					settings: settings
				});
				this.createEditView.show({
					title: _.i18n('run.prep.new.fromFile.plateLayout'),
					className: 'actionMediumPopupCss actionPopupCss popupFitContent'
				});
				this.createEditView.listenTo(this.createEditView, 'run:listView:refresh', _.bind(this.onRefreshListViewGrid, this));
			}, this));
		},

		showViewNewRunFromFileSampleList: function () {
			App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
				this.createEditView = new PrepRunCreatePcrRunFromFileSampleListView({
					model: new Backbone.Model(),
					settings: settings
				});
				this.createEditView.show({
					title: _.i18n('run.prep.new.fromFile.sampleList'),
					className: 'actionMediumPopupCss actionPopupCss popupFitContent'
				});
				this.createEditView.listenTo(this.createEditView, 'run:listView:refresh', _.bind(this.onRefreshListViewGrid, this));
			}, this));
		},

		showImportRun: function () {
			$.when(App.request('setting:get-all-setting')).done(_.bind(function (settings) {
				this.chooseUploadView = new ChooseUploadView({
					settings: settings
				});
				this.chooseUploadView.show({
					title: _.i18n('run.import')
				});
				this.chooseUploadView.listenTo(this.chooseUploadView, 'run:prep:reloadData', _.bind(function () {
					this.view.updatePagination('reloadGrid');
				}, this));
				this.chooseUploadView.listenTo(this.chooseUploadView, 'run:prep:import-xml', _.bind(this.onImportXml, this));
			}, this));
		},

		onRefreshListViewGrid: function () {
			this.view.trigger('run:listView:refresh');
		},

		onCreateFromPopup: function (file, anaylyzer, protocol, plateLayout, generateStarterSheet) {
			this.view.onDestroy();
			this.emptyView = new EmptyView();
			this.generateStarterSheet = generateStarterSheet;
			App.regions.getRegion('main').currentView.getRegion('content').show(this.emptyView);
			App.request('run:prep:upload-xml', file, anaylyzer, protocol, plateLayout).done(_.bind(function (result) {
				if (this.generateStarterSheet) {
					PrepRuns.generateRunSheet(result.secId);
				}
				this.emptyView.destroy();
				this.showRuns();
			}, this)).fail(_.bind(function () {
				bootbox.alert(_.i18n('common.errorOccuredWhileImporting'), _.bind(function () {
					this.emptyView.destroy();
					this.showRuns();
					return;
				}, this));
			}, this));
		},

		onGridLoaded: function () {
			var that = this, data;
			data = {
				url: App.request('run:prep:get-collection-url', false),
				rowCountUrl: App.request('run:prep:get-count-url', false)
			};
			if (this.view.options.displayArchived) {
				data = {
					url: App.request('run:prep:get-collection-url', true),
					rowCountUrl: App.request('run:prep:get-count-url', true)
				};
			}
			that.view.displayGrid(data);
		},

		onLoadSelectedRuns: function (first, last, params) {
			App.request('run:prep:get-collection-to-page', params)
				.done(_.bind(function (runs) {
					this.view.triggerMethod('selectRuns', first, last, runs);
				}, this));
		},

		onImportXml: function (fileInfo, generateStarterSheet, deleteFileFromRemoteFolder) {
			this.generateStarterSheet = generateStarterSheet;
			this.deleteFileFromRemoteFolder = deleteFileFromRemoteFolder;
			var createFromUploadView = new CreateFromUploadView();
			createFromUploadView.listenTo(createFromUploadView, 'popup-create:prep:selectLmbAnalyser', _.bind(function (lmbAnalyser, kitProt) {
				this.view.onDestroy();
				this.emptyView = new EmptyView();
				App.regions.getRegion('main').currentView.getRegion('content').show(this.emptyView);

				App.request('run:prep:upload-xml', fileInfo, lmbAnalyser, kitProt).done(_.bind(function (result) {
					if (this.generateStarterSheet) {
						PrepRuns.generateRunSheet(result.secId);
					}
					if (this.deleteFileFromRemoteFolder) {
						App.request('run:prep:delete-remote-file', fileInfo.name);
					}
					this.emptyView.destroy();
					this.showRuns();
				}, this)).fail(_.bind(function () {
					this.emptyView.destroy();
					this.showRuns();
					bootbox.alert(_.i18n('common.errorOccuredWhileImporting'), function () {
					});
				}, this));
			}, this));
			createFromUploadView.show({
				title: _.i18n('preprun.create')
			});
		},

		onDelete: function (secId, codeName) {
			var callback = _.bind(function (result) {
				if (result) {
					App.request('run:prep:delete', secId)
						.done(_.bind(function () {
							this.view.updatePagination('reloadGrid');
						}, this));
				}
			}, this);
			var confirmParams = {
				message: _.i18n('confirmDelete') + ' run ' + ' \"' + codeName + '\" ?',
				notaBene: _.i18n('confirmDelete.notaBene'),
				type: 'warning'
			};
			CustomBootboxMessage.customConfirm(confirmParams, callback);
		},

		onSelectFileToImport: function () {
			$.when(App.request('run:prep:files-to-import')).done(_.bind(function (files) {
				this.runRemoteFolderView = new RunRemoteFolderView({files: files, type: 'result'});
				this.runRemoteFolderView.listenTo(this.runRemoteFolderView, 'run:prep:import-xml', _.bind(this.onImportXml, this));
				this.runRemoteFolderView.show({
					title: _.i18n('preprun.import.remote.folder')
				});
			}, this));
		}
	};
});
