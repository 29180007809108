define('entities/caccounts/assay',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'assayCustomJqGridView',
    'entities/caccounts/assayreagent',
    'backboneRelational',
    'entities/caccounts/assayconfiguration',
    'entities/caccounts/kitprots',
    'entities/caccounts/assaygroup'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    AssayCustomJqGridView
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Assay = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/assays/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'refKitProt': null,
            'code': '',
            'name': '',
            'description': undefined,
            'comment': '',
            'refAssayGroup': undefined,
            'reagents': undefined,
            'volumeBySample': null,
            'volumeIC': null,
            'algoDeathVolume': {type: 'NO', param: ''},
            'configurations': []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'configurations',
            relatedModel: 'AssayConfiguration',
            collectionType: 'AssayConfigurationCollection',
            reverseRelation: {
                key: 'refAssay',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'reagents',
            relatedModel: 'AssayReagent',
            collectionType: 'AssayReagentCollection',
            reverseRelation: {
                includeInJSON: ['secId', 'code', 'name']
            }
        }, {
            type: Backbone.HasOne,
            key: 'refKitProt',
            relatedModel: 'KitProt',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refAssayGroup',
            relatedModel: 'AssayGroup',
            includeInJSON: ['secId', 'code', 'name']
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/assays');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'AssayJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Assay',
        getEntity: function () {
            return module.exports;
        },
        importable: true,
        getReagentsQC: function () {
            return new app.ns.AssayReagentCollection(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && assayReagent.get('reagent').get('type') === 'QC';
            }));
        },
        getReagentIC: function () {
            var model = _.first(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && ['IC'].includes(assayReagent.get('reagent').get('type'));
            }));
            if (!model) {
                model = new app.ns.AssayReagent({refAssay: this});
            }
            return model;
        },
        getReagentsMMX: function () {
            return new app.ns.AssayReagentCollection(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && !['QC', 'IC'].includes(assayReagent.get('reagent').get('type')) && assayReagent.get('reagent').get('type');
            }));
        }
    });

    app.ns.AssayCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/assays/');
        },
        model: app.ns.Assay
    });

    var API = {
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['description']};
        }
    };

    module.exports = _.defaults({
        getName: function () {
            return 'assay';
        },
        getUrl: function () {
            return 'v2/assays';
        },
        getDynamicJson: function () {
            return 'AssayJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {clearSearch: false},
                width: '50px'
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {clearSearch: false},
                width: '70px'
            }, {
                label: _.i18n('run.prot'),
                name: 'refKitProt.code',
                classes: 'kitc-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refKitProt.code',
                searchoptions: {clearSearch: false},
                width: 40
            }, {
                label: _.i18n('assay.groupName'),
                name: 'refAssayGroup.code',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refAssayGroup.code',
                searchoptions: {clearSearch: false},
                width: 40
            }, {
                label: _.i18n('assay.assayConfiguration'),
                name: 'configurations',
                classes: 'assay-link',
                formatter: this.assayConfigurationFormatter,
                search: true,
                index: 'configurationCode',
                searchoptions: {clearSearch: false},
                width: 80
            }, {
                label: _.i18n('assay.assayConfiguration.cyclers'),
                name: 'configurationsCyclers',
                classes: 'assay-link',
                formatter: this.cyclerFormatter,
                search: true,
                index: 'cyclerCode',
                searchoptions: {clearSearch: false},
                width: 80
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description',
                searchoptions: {clearSearch: false}
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment',
                searchoptions: {clearSearch: false}
            });
            return columns;
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.custom = new AssayCustomJqGridView({assay: this});
            return config;
        },

        assayConfigurationFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            var codes = _.map(cellValue, function (configuration) {
                return configuration.code;
            });
            cellValue = codes.join(', ');

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        cyclerFormatter: function (cellValue, options, model) {
            var codes = _.map(model.configurations, function (configuration) {
                return _.map(configuration.cyclers, function (cycler) {
                    return cycler.code;
                }).join(', ');
            });
            cellValue = codes.join(', ');

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        getAutocompleteParamFromRun: function (pcrRun, config) {
            var url = pcrRun.getEntity().getUrl() + '/' + pcrRun.get('secId') + '/assays';
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.urlPath = url + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            }));
            return configuration;
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Assay.findOrCreate(modelJson);
        },
        getCollection: function (models) {
            return new app.ns.AssayCollection(models);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refKitProt.code']};
        },
        find: function (params) {
            var url = Settings.url('compuzz', this.getUrl(), params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },
        showEditAdd: function (model, callBack) {
            if (!model) {
                model = this.getModel({});
            }
            this.showDetails({model: model, callBackRefresh: callBack});
        },
        showDetails: function (param) {
            require(['adminAssayController'], function (controller) {
                controller.showDetails(param.model, param.callBackRefresh);
            });
        },
        createRunFromResult: function (files, cyclerPublicSecId) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {
                    cyclerPublicSecId: cyclerPublicSecId
                };

            var url = Settings.url('compuzz', this.getUrl() + '/createFromRunResult', param);
            _.each(files, function (file) {
                formdata.append('file', file);
            });

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function (resp) {
                    defer.resolve(this.getModel(resp));
                }, this),
                error: function () {
                    defer.reject();
                }
            });

            return defer.promise();
        }
    }, Dynamic);

    App.reqres.setHandler('assay:get-fields-to-display', function () {
        return API.getFieldsToDisplay();
    });
});
