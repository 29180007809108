define('assayReagentMMXItemView',[
	'module',
	'marionette',
	'template!assayReagentMMXItemTpl',
	'underscore',
	'app',
	'autocompleteView',
	'dialogFormView',
	'entities/caccounts/kitspcr',
	'entities/caccounts/pcrreagents'
], function (
	module,
	Marionette,
	Tpl,
	_,
	App,
	AutocompleteView,
	DialogFormView,
	PcrKits,
	PcrReagents
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		ui: {
			input: '.js-info-input-mmx'
		},

		events: {
			'change @ui.input': 'onInputChange',
			'click .delete-row': 'onDelete'
		},

		regions: {
			pcrKit: '.js-kit',
			reagent: '.js-reagent'
		},

		fieldsToValidate: [
			// {name: 'reagent', type: 'required'}
		],

		modelEvents:{
			'change:volumeByTest': 'onChangeVolumeByTest'
		},

		className: 'col-xs-12 p-0',

		initialize: function () {
			if (this.model.get('reagent')) {
				this.pcrKit = this.model.get('reagent').get('pcrKit');
			}
		},
		serializeData: function () {
			return {volumeByTest: this.model.get('volumeByTest'), remark: this.model.get('remark')};
		},
		onRender: function () {
			this.displayPcrKitAutocomplete();
			if (this.model.get('reagent')) {
				this.displayReagent();
			}
		},

		onChangePcrKit: function (fieldName, model) {
			if (model) {
				model = PcrKits.getModel(model.toJSON());
			}
			this.pcrKit = model;
			if (this.model.get('reagent')) {
				this.displayReagent();
			}
		},
		displayPcrKitAutocomplete: function () {
			this.getRegion('pcrKit').show(new AutocompleteView(
				this._getAutocompleteOptionObject(PcrKits.getAutocompleteParamForAssay({
					modelProperty: 'reagent.pcrKit',
					callBackOnChange: this.onChangePcrKit,
					assaySecId: this.model.get('refAssay').get('secId'),
					reagentTypes: ['MMX'],
					callBackAdd: this.onAddPcrKit,
					callBackEdit: this.onEditPcrKit
				}))
			));
		},
		onEditPcrKit: function (secId) {
			var model = PcrKits.getModel({secId: secId});
			model.fetch().done(_.bind(function () {
				this.showPcrKit(model);
			}, this));
		},

		onAddPcrKit: function () {
			var model = PcrKits.getModel();
			this.showPcrKit(model);
		},

		showPcrKit: function (model) {
			model.getEntity().showDetails({model: model, callBackRefresh: _.bind(this.onRender, this)});
		},
		displayReagent: function () {
			if (!this.pcrKit) {
				return;
			}
			this.getRegion('reagent').show(new AutocompleteView(
				this._getAutocompleteOptionObject(PcrReagents.getAutocompleteParam({
					data: _.map(this.pcrKit.get('reagents').filter(_.bind(function (model) {
						return ['MMX'].includes(model.get('type'));
					}, this)), function (model) {
						return model.toJSON();
					}),
					modelProperty: 'reagent',
					callBackOnChange: this.onChangeReagent,
					canAddOrEdit: false
				}))));
		},
		onChangeReagent: function (fieldName, model) {
			this.onChange(fieldName, model);
			this.trigger('item:updateReagent', model);
		},

		onDelete: function () {
			this.trigger('item:delete', this.model);
		},
		refresh: function () {
			this.displayPcrKitAutocomplete();
		},
		onChangeVolumeByTest: function() {
			this.trigger('item:updateVolume');
		}
	});
});
