define('runCreateEmptyPcrRunView',[
	'module',
	'marionette',
	'backbone',
	'dialogFormView',
	'template!runCreateEmptyPcrRunTpl',
	'savingBehavior',
	'bootbox',
	'underscore',
	'jquery',
	'app',
	'settings',
	'autocompleteView',
	'moment',
	'entities/caccounts/fileFormatPlateLayouts',
	'entities/caccounts/userpids',
	'entities/caccounts/pcrruntemplates',
	'entities/caccounts/pcrruns',
	'entities/caccounts/userCodelist',
	'entities/createRunFromTemplate'
], function (
	module,
	Marionette,
	Backbone,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	bootbox,
	_,
	$,
	App,
	Settings,
	AutocompleteView,
	moment,
	FileFormatPlateLayouts,
	Userpids,
	PcrRunTemplates,
	PcrRuns,
	UserCodeLists
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			inputName: '.run-input-name',
			createRunRadio: '.create-run-radio',
			createRunFromTemplate: '.createRunFromTemplate',
			createRunFromCyclerExport: '.createRunFromCyclerExport',
			uploadRunsCyclerExport: '.js-upload-runs-cycler-export',
			numberOfRunRegion: '.js-number-of-run-region',
			numberOfRun: '.number-of-run',
			generateStarterSheet: '.generate-starter-sheet',
			exportPlateLayout: '.export-plate-layout',
			template384: '.template384',
			selectFileFormat: '.js-select-fileFormat-region',
			blockLoading: '.empty-page-loader',
			selectedDate: '.js-selectedDate',
			forceRunName: '.js-runNameForced'
		},

		events: {
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-confirm': 'onConfirm',
			'click .js-cancel-popup': 'onCancel',
			'change @ui.createRunRadio': 'onCreateRunRadioChange',
			'change @ui.uploadRunsCyclerExport': 'onLoadFilePlateLayout',
			'change @ui.generateStarterSheet': 'onChangeGenerateStarterSheet',
			'change @ui.exportPlateLayout': 'onChangeExportPlateLayout',
			'change @ui.forceRunName': 'onChangeForceRunName'
		},

		regions: {
			selectRespUser: '.js-select-respUser-region',
			selectRunPattern: '.js-runName-pattern',
			selectRunNameDepartment: '.js-select-runName-department',
			selectRunNameGroup: '.js-select-runName-runGroup',
			selectRunNameSequenceFormat: '.js-select-runName-sequenceFormat',
			selectRunTemplate: '.js-select-run-template-region',
			selectKitProtFromCyclerExport: '.js-select-kitprot-region-from-cycler-export',
			selectKitProt: '.js-select-kitprot-region',
			selectPlateTopLeft: '#select-plate-region-top-left',
			selectPlateTopRight: '#select-plate-region-top-right',
			selectPlateBottomLeft: '#select-plate-region-bottom-left',
			selectPlateBottomRight: '#select-plate-region-bottom-right',
			selectAssay: '#select-assay-region',
			selectFileFormat: '.js-select-fileFormat-region'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		fieldsToValidate: [
			{name: 'name', type: 'required'}
		],

		onCancel: function () {
			this.box.modal('hide');
		},

		initialize: function (options) {
			this.model = options.model;
			this.model.set('runName', '');
			this.model.set('refRespUser', '');
			// this.model.set('defDept', options.defDept ? options.defDept.params : null);
			// this.model.set('department', options.defDept ? options.defDept.params : null);
			this.model.set('suffix', '');
			this.currentMode = 'fromTemplate';
			this.model.set('generateStarterSheet', false);
			this.nameForced = false;
			if (!this.model.get('pattern') || this.model.get('pattern') === '') {
				this.model.set('pattern', '');
			}
		},

		serializeData: function () {
			this.model.set('numberOfRun', 1);
			this.model.set('generateStarterSheet', false);
			this.model.set('nameForced', false);
			this.model.set('selectedDate', Number(moment().format('x')));
			this.model.set('currentDate', moment().format('DD-MM-YY'));
			return this.model.toJSON();
		},

		onChangeGenerateStarterSheet: function (event) {
			this.model.set('generateStarterSheet', event.target.checked);
		},

		onChangeForceRunName: function (event) {
			this.nameForced = event.target.checked;
			this.model.set('nameForced', this.nameForced);
			if (this.nameForced) {
				this.model.set('numberOfRun', 1);
				$('.js-number-of-run-region').css('display', 'none');
			} else {
				$('.js-number-of-run-region').css('display', 'block');
			}
		},

		onChangeExportPlateLayout: function (event) {
			this.exportPlateLayout = event.target.checked;
		},

		onCreateRunRadioChange: function () {
			if (this.ui.createRunRadio) {
				this.ui.createRunFromTemplate.toggleClass('hidden', true);
				this.ui.createRunFromCyclerExport.toggleClass('hidden', true);
				_.each(this.ui.createRunRadio, _.bind(function (radio) {
					switch (radio.value) {
						case 'fromTemplate':
							if (radio.checked) {
								this.ui.createRunFromTemplate.toggleClass('hidden', false);
							}
							break;
						case 'fromCyclerExport':
							if (radio.checked) {
								this.ui.createRunFromCyclerExport.toggleClass('hidden', false);
							}
							break;
					}
					if (radio.checked) {
						this.currentMode = radio.value;
					}
				}, this));
			}
		},

		onLoadFilePlateLayout: function (e) {
			if (e && e.target && e.target.files && e.target.files[0]) {
				this.currentFile = e.target.files[0];
			}
		},

		onDepartmentChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.model.set(fieldName, model.get('code'));
			} else {
				this.model.set(fieldName, null);
			}
		},

		onRunGroupChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.model.set(fieldName, model.get('code'));
			} else {
				this.model.set(fieldName, null);
			}
		},

		onRunSequenceFormatChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.model.set(fieldName, model.get('code'));
			} else {
				this.model.set(fieldName, null);
			}
		},

		onPatternChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.model.set(fieldName, model.get('code'));
			} else {
				this.model.set(fieldName, null);
			}
		},

		showSelectRunTemplateView: function () {
			if (this.selectRunTemplateView) {
				this.selectRunTemplateView.destroy();
			}
			this.selectRunTemplateView = new AutocompleteView(
				this._getAutocompleteOptionObject(PcrRunTemplates.getAutocompleteParam({
					modelProperty: 'refRunTemplate',
					callBackOnChange: this.onTemplateChange
				}))
			);
			this.getRegion('selectRunTemplate').show(this.selectRunTemplateView);
			this.triggerMethod('enable:cancel:confirm');
		},

		showPatternDropDown: function () {
			if (this.selectPatternView) {
				this.selectPatternView.destroy();
			}
			this.selectPatternView = new AutocompleteView(
				this._getAutocompleteOptionObject(
					UserCodeLists.getAutocompleteParam({
						modelProperty: 'pattern',
						codeListCode: 'PATTERN_PCR',
						callBackOnChange: this.onPatternChange
					})));
			this.getRegion('selectRunPattern').show(this.selectPatternView);
		},

		showDepartmentDropDown: function () {
			if (this.selectDepartmentView) {
				this.selectDepartmentView.destroy();
			}
			this.selectDepartmentView = new AutocompleteView(
				this._getAutocompleteOptionObject(
					UserCodeLists.getAutocompleteParam({
						modelProperty: 'department',
						codeListCode: 'DEPT',
						callBackOnChange: this.onDepartmentChange
					})));
			this.getRegion('selectRunNameDepartment').show(this.selectDepartmentView);
		},

		showRunGroupDropDown: function () {
			if (this.selectRunGroupView) {
				this.selectRunGroupView.destroy();
			}
			this.selectRunGroupView = new AutocompleteView(
				this._getAutocompleteOptionObject(
					UserCodeLists.getAutocompleteParam({
						modelProperty: 'group',
						codeListCode: 'RUNGROUP_PCR',
						callBackOnChange: this.onRunGroupChange
					})));
			this.getRegion('selectRunNameGroup').show(this.selectRunGroupView);
		},

		showSequenceFormatDropDown: function () {
			if (this.selectRunSequenceFormatView) {
				this.selectRunSequenceFormatView.destroy();
			}
			this.selectRunSequenceFormatView = new AutocompleteView(
				this._getAutocompleteOptionObject(
					UserCodeLists.getAutocompleteParam({
						modelProperty: 'sequenceFormat',
						codeListCode: 'SEQUENCE',
						callBackOnChange: this.onRunSequenceFormatChange
					})));
			this.getRegion('selectRunNameSequenceFormat').show(this.selectRunSequenceFormatView);
		},

		onShow: function () {
			$.material.init();

			$('input.js-datePicker[data-field-name="selectedDate"]').val(this.model.get('currentDate'));

			this.showPatternDropDown();
			this.showDepartmentDropDown();
			this.showRunGroupDropDown();
			this.showSequenceFormatDropDown();

			this.getRegion('selectRunTemplate').options.allowMissingEl = true;
			this.showSelectRunTemplateView();

			this.getRegion('selectAssay').show(new AutocompleteView(
				this._getAutocompleteOptions('refAssay', 'code', 'code', 'v2/assays',
					'selectassay.placeholder', 'code', this.onChangeAssay)
			));
			this.getRegion('selectFileFormat').show(new AutocompleteView(
				this._getAutocompleteOptionObject(FileFormatPlateLayouts.getAutocompleteParamForImport({
					modelProperty: 'refFileFormat',
					callBackOnChange: this.onChangeFileFormat
				}))
			));
			this.showEPlate();
			this.triggerMethod('enable:cancel:confirm');
		},

		onChangeAssay: function (fieldName, model) {
			this.currentAssay = model;
			this.showEPlate();
		},
		onChangePlate: function (fieldName, model) {
			this.onChange(fieldName, model);
			this.showEPlate();
		},

		generateIgnoredName: function (excepted) {
			var ignoredNames = [];
			if (excepted !== 'refPlateTopLeft' && this.model.get('refPlateTopLeft')) {
				ignoredNames.push(this.model.get('refPlateTopLeft').get('name'));
			}
			if (excepted !== 'refPlateTopRight' && this.model.get('refPlateTopRight')) {
				ignoredNames.push(this.model.get('refPlateTopRight').get('name'));
			}
			if (excepted !== 'refPlateBottomLeft' && this.model.get('refPlateBottomLeft')) {
				ignoredNames.push(this.model.get('refPlateBottomLeft').get('name'));
			}
			if (excepted !== 'refPlateBottomRight' && this.model.get('refPlateBottomRight')) {
				ignoredNames.push(this.model.get('refPlateBottomRight').get('name'));
			}
			return ignoredNames;
		},

		showEPlate: function () {
			var params = Settings.generateParamUrl({
				assaySecId: this.currentAssay ? this.currentAssay.get('secId') : null,
				ignoredNames: this.generateIgnoredName('refPlateTopLeft')
			});
			this.getRegion('selectPlateTopLeft').show(new AutocompleteView(
				this._getAutocompleteOptions('refPlateTopLeft', 'name', 'name', 'v2/runs/e-plate' + (params ? '?' + params : ''),
					'selectplate.placeholder', 'name', this.onChangePlate)
			));
			params = Settings.generateParamUrl({
				assaySecId: this.currentAssay ? this.currentAssay.get('secId') : null,
				ignoredNames: this.generateIgnoredName('refPlateTopRight')
			});
			this.getRegion('selectPlateTopRight').show(new AutocompleteView(
				this._getAutocompleteOptions('refPlateTopRight', 'name', 'name', 'v2/runs/e-plate' + (params ? '?' + params : ''),
					'selectplate.placeholder', 'name', this.onChangePlate)
			));
			params = Settings.generateParamUrl({
				assaySecId: this.currentAssay ? this.currentAssay.get('secId') : null,
				ignoredNames: this.generateIgnoredName('refPlateBottomLeft')
			});
			this.getRegion('selectPlateBottomLeft').show(new AutocompleteView(
				this._getAutocompleteOptions('refPlateBottomLeft', 'name', 'name', 'v2/runs/e-plate' + (params ? '?' + params : ''),
					'selectplate.placeholder', 'name', this.onChangePlate)
			));
			params = Settings.generateParamUrl({
				assaySecId: this.currentAssay ? this.currentAssay.get('secId') : null,
				ignoredNames: this.generateIgnoredName('refPlateBottomRight')
			});
			this.getRegion('selectPlateBottomRight').show(new AutocompleteView(
				this._getAutocompleteOptions('refPlateBottomRight', 'name', 'name', 'v2/runs/e-plate' + (params ? '?' + params : ''),
					'selectplate.placeholder', 'name', this.onChangePlate)
			));
		},

		onChangeFileFormat: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.fileFormatSecId = model.get('secId');
		},

		onRender: function () {
			this.initDatePicker(this.ui.selectedDate, 'selectedDate');
			this.showRefRespUserDropDown();
			this.listenTo(this, 'date:picker:change', _.bind(this.onChangeDt, this));
		},

		showRefRespUserDropDown: function () {
			if (this.respUserView) {
				this.respUserView.destroy();
			}
			this.respUserView = new AutocompleteView(
				this._getAutocompleteOptionObject(Userpids.getAutocompleteParam({
					modelProperty: 'refRespUser',
					valueProperty: 'nickName',
					queryProperty: 'nickName',
					valueKey: 'nickName',
					callBackOnChange: this.onRespUserChange
				}))
			);
			this.getRegion('selectRespUser').show(this.respUserView);
		},

		onRespUserChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.onChange(fieldName, model ? model : null);
		},

		onChangeDt: function (fieldName) {
			var tempDt = $('input.js-datePicker[data-field-name=' + fieldName + ']').val();
			var displayDt = moment(tempDt, 'DD-MM-YYYY').format('DD-MM-YY');
			$('input.js-datePicker[data-field-name=' + fieldName + ']').val(displayDt);
		},

		onKitProtChange: function (fieldName, model) {
			this.currentKitProt = model;
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.onChange(fieldName, model);
			if (model) {
				if (!this.ui.inputName.val()) {
					this.ui.inputName.val(model.get('runNamePattern'));
					this.ui.inputName.change();
				}
			}
		},

		onTemplateChange: function (fieldName, model) {
			this.model.set('refRunTemplate', model);
			// var displayTemplateDate = false;
			// var displayTemplateNumberOfRuns = false;
			var displayTemplate384 = false;

			this.model.set('generateStarterSheet', false);
			if (model) {
				if (model.get('pattern') && model.get('pattern') !== '') {
					this.model.set('pattern', model.get('pattern'));
					this.showPatternDropDown();
				}
				if (model.get('department') && model.get('department') !== '') {
					this.model.set('department', model.get('department'));
					this.showDepartmentDropDown();
				}
				if (model.get('group') && model.get('group') !== '') {
					this.model.set('group', model.get('group'));
					this.showRunGroupDropDown();
				}
				if (model.get('sequenceFormat') && model.get('sequenceFormat') !== '') {
					this.model.set('sequenceFormat', model.get('sequenceFormat'));
					this.showSequenceFormatDropDown();
				}
				if (model.get('suffix') && model.get('suffix') !== '') {
					this.model.set('suffix', model.get('suffix'));
					$('.js-runName-suffix').find('input').val(model.get('suffix'));
				}
				if (model.get('pattern') && model.get('pattern') !== '') {
					this.model.set('pattern', model.get('pattern'));
					$('.js-runName-pattern').find('input').val(model.get('pattern'));
				}
				this.model.set('generateStarterSheet', model.get('printRunSheet'));
			}
			this.ui.generateStarterSheet.attr('checked', this.model.get('generateStarterSheet'));

			this.ui.selectedDate.removeClass('invalid');
			this.ui.numberOfRun.removeClass('invalid');
			this.ui.numberOfRunRegion.closest('div[data-field-name=numberOfRun]').removeClass('has-error');

			this.model.set('numberOfRun', 1);
			// this.model.set('selectedDate', Number(moment().format('x')));
			// this.model.set('currentDate', moment().format('DD-MM-YYYY'));

			if (model !== null) {
				displayTemplate384 = model.get('wellCount') > 96;
			}

			this.ui.template384.toggleClass('hidden', !displayTemplate384);
			this.exportPlateLayout = false;
			this.ui.exportPlateLayout[0].checked = false;
			this.fileFormatSecId = null;
		},

		onConfirm: function () {
			var createRunFromTemplate = App.request('createRunFromTemplate:model');
			createRunFromTemplate.set('refRunTemplate', (this.model.get('refRunTemplate') && this.model.get('refRunTemplate').get('secId') ? this.model.get('refRunTemplate').get('secId') : ''));
			createRunFromTemplate.set('suffix', (this.model.get('suffix') ? this.model.get('suffix') : ''));
			createRunFromTemplate.set('nameForced', (this.model.get('nameForced') ? this.model.get('nameForced') : false));
			createRunFromTemplate.set('name', (this.model.get('name') ? this.model.get('name') : ''));
			createRunFromTemplate.set('pattern', (this.model.get('pattern') ? this.model.get('pattern') : ''));
			createRunFromTemplate.set('department', (this.model.get('department') ? this.model.get('department') : ''));
			createRunFromTemplate.set('group', (this.model.get('group') ? this.model.get('group') : ''));
			createRunFromTemplate.set('sequenceFormat', (this.model.get('sequenceFormat') ? this.model.get('sequenceFormat') : ''));
			createRunFromTemplate.set('respUserSecId', (this.model.get('refRespUser') && this.model.get('refRespUser').get('secId') ?
				this.model.get('refRespUser').get('secId') : ''));

			var needToCheckNumberOfRuns = true;
			if (this.model.get('nameForced')) {
				needToCheckNumberOfRuns = false;
				createRunFromTemplate.set('numberOfRun', this.model.get('numberOfRun'));
			}

			var template384 = this.model.get('refRunTemplate').get('wellCount') > 96;
			if (!this.validate([{name: 'refRunTemplate', type: 'required'}])) {
				return;
			}

			if (!this.validate([{name: 'selectedDate', type: 'required'}]) ||
				!this.validate([{name: 'selectedDate', type: 'datePicker'}])) {
				return;
			}
			createRunFromTemplate.set('selectedDate', this.model.get('selectedDate'));

			if (needToCheckNumberOfRuns) {
				if (!this.validate([{name: 'numberOfRun', type: 'required'}])) {
					return;
				}
				var numberOfRun = parseInt(this.model.get('numberOfRun'), 10);
				var maxNumberOfRun = parseInt(this.$el.find(this.ui.numberOfRun).attr('max'), 10);
				if (numberOfRun > maxNumberOfRun) {
					return;
				}
				createRunFromTemplate.set('numberOfRun', numberOfRun);
			} else {
				createRunFromTemplate.set('numberOfRun', 1);
			}
			if (template384) {
				var check = !this.validate([{name: 'refPlateTopLeft', type: 'required'}]);
				check = check && !this.validate([{name: 'refPlateTopRight', type: 'required'}]);
				check = check && !this.validate([{name: 'refPlateBottomLeft', type: 'required'}]);
				check = check && !this.validate([{name: 'refPlateBottomRight', type: 'required'}]);

				if (check) {
					return;
				}
				createRunFromTemplate.set('refPlateTopLeft', this.model.get('refPlateTopLeft').get('secId'));
				createRunFromTemplate.set('refPlateTopRight', this.model.get('refPlateTopRight').get('secId'));
				createRunFromTemplate.set('refPlateBottomLeft', this.model.get('refPlateBottomLeft').get('secId'));
				createRunFromTemplate.set('refPlateBottomRight', this.model.get('refPlateBottomRight').get('secId'));
				createRunFromTemplate.set('refAssay', this.currentAssay ? this.currentAssay.get('secId') : '');
			}
			this.ui.blockLoading.show();

			createRunFromTemplate.save().done(
				_.bind(function (result) {
					this.options.callBackOnCreated(result);
					this.triggerMethod('run:create', result);
					var url;
					if (this.model.get('generateStarterSheet')) {
						var runSecIds = result.map(function (run) {
							return run.secId;
						});
						PcrRuns.generateRunSheet(runSecIds);
					}
					if (this.exportPlateLayout && this.fileFormatSecId && this.model.get('refPlateTopLeft').get('secId') && this.model.get('refPlateTopRight').get('secId') && this.model.get('refPlateBottomLeft').get('secId') && this.model.get('refPlateBottomRight').get('secId')) {
						url = Settings.url('compuzz', 'v2/runs/pcr/' + this.model.get('refPlateTopLeft').get('secId') + '/plateLayout/export', {
							'fileFormatSecId': this.fileFormatSecId,
							'plateLinkedType': 'TOP_LEFT'
						});
						window.open(url, '_blank');
						url = Settings.url('compuzz', 'v2/runs/pcr/' + this.model.get('refPlateTopRight').get('secId') + '/plateLayout/export', {
							'fileFormatSecId': this.fileFormatSecId,
							'plateLinkedType': 'TOP_RIGHT'
						});
						window.open(url, '_blank');
						url = Settings.url('compuzz', 'v2/runs/pcr/' + this.model.get('refPlateBottomLeft').get('secId') + '/plateLayout/export', {
							'fileFormatSecId': this.fileFormatSecId,
							'plateLinkedType': 'BOTTOM_LEFT'
						});
						window.open(url, '_blank');
						url = Settings.url('compuzz', 'v2/runs/pcr/' + this.model.get('refPlateBottomRight').get('secId') + '/plateLayout/export', {
							'fileFormatSecId': this.fileFormatSecId,
							'plateLinkedType': 'BOTTOM_RIGHT'
						});
						window.open(url, '_blank');
					}
					this.hide();
				}, this)
			).fail(
				_.bind(function (response) {
					if (response.status === 409) {
						this.ui.selectedDate.addClass('invalid');
						// this.ui.selectedDateRegion.closest('div[data-field-name=selectedDate]').addClass('has-error');
					}
					this.ui.blockLoading.hide();
				}, this)
			).always(
				// _.bind(function () {
				//     this.hide();
				// }, this)
			);
		},

		onSave: function (model) {
			var modelJSON = model.toJSON();
			this.model.set('wells', this.wells);
			this.options.runModel.set(modelJSON);
			this.triggerMethod('saved');
			App.trigger('run:header:rerender', modelJSON);
			App.navigate('runs/pcr/' + model.get('secId') + '/edit', {trigger: true});
			if (this.model.get('generateStarterSheet')) {
				PcrRuns.generateRunSheet(model.get('secId'));
			}
			this.hide();
		},

		hide: function () {
			this.triggerMethod('hide');
			this.destroy();
			this.box.modal('hide');
		},

		onSaveError: function (model, response) {
			if (response.status === 409) {
				this.onDuplicateCode();
				this.triggerMethod('hide:label');
			}
		},

		onDuplicateCode: function () {
			this.ui.endUserIdInput.popover('show');
			this.ui.endUserIdInput.addClass('invalid');
			this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
		},

		onFocusOut: function () {
			var $target,
				datepicker;

			// close popover when click outside
			setTimeout(_.bind(function () {
				$target = $(document.activeElement);
				datepicker = $target.parents('.datepicker-element').length > 0 ||
					$target.parents('.ui-datepicker').length > 0 ||
					$target.hasClass('ui-datepicker') ||
					$target.get(0) === this.$el.get(0) ||
					$target.hasClass('popover-shown');

				if (!datepicker) this.trigger('close:popover');
			}, this), 0);
		}
	});
});
