define('wellDisplayHL7View',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!wellDisplayHL7View',
    'savingBehavior',
    'bootbox',
    'underscore',
    'app',
    'autocompleteView',
    'settings',
    'jquery',
    'createEditRunAtLayoutView',
    'targetsView',
    'wellDisplayHL7TableView',
    'wellUtils',
    'entities/caccounts/runats'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    bootbox,
    _,
    App,
    AutocompleteView,
    Settings,
    $,
    CreateEditRunAtLayoutView,
    TargetsView,
    WellDisplayHL7TableView,
    WellUtils
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,
        displayHeader: true,

        models: [],

        ui: {
            cbxValidation: '.cbx-validation',
            cvRadio: '.cv-radio',
            loader: '.empty-page-loader',
            targetResCheckBoxRegion: '.js-targetRes-checkBox-region',
            errWarnCheckBoxRegion: '.js-errWarn-checkBox-region',
            resultBtn: '.js-result-btn',
            warningBtn: '.js-warning-btn'
        },

        events: {
            'click .js-confirm': 'onConfirm',
            'click .btn-edit-runat': 'onClickBtnEditRunAt',
            'change @ui.cvRadio': 'onCVRadioChange',
            'click .js-result-btn': 'onFilterClick',
            'click .js-warning-btn': 'onFilterClick',
            'click .js-target-reset-filters': 'onResetFilter',
            'click .js-run-valView-action-nav-drop-btn': 'onRunActionNavDropBtnClick',
            'click .js-run-valView-action-nav-drop-cell-btn': 'onRunActionNavDropCellBtnClick',
            'mouseleave .run-valView-action-nav-container': 'onHideRunActionContainer',
            'click .valView-dilution-dropdown': 'onDilution'
        },

        regions: {
            graph: '.js-graph',
            targetsView: '#targets-view'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            error: 'onSaveError',
            change: 'render'
        },

        fieldsToValidate: [],

        filtersList: [],

        onBeforePrintTargetGraph: function () {
            $('*').removeClass('hidden-print');
            $('#main-region').removeClass('hidden-print');
            $('#sidebar-region').addClass('hidden-print');
            $('.slider-range,.slider-range-vert,.mdi-action-cached').addClass('hidden-print');
        },

        initialize: function (options) {
            this.currentAssayTarget = options.currentAssayTarget;
            this.assays = options.assays;
            this.assayTargets = options.assayTargets;
            this.legend = null;
            this.withCC = true;
            this.withLegend = options.displayLegend;
            this.raw = false;
            this.fixedColor = true;
            this.graphToDisplay = options.graphToDisplay;
            this.hideOkButton = options.hideOkButton;
            this.wellSelected = options.wellSelected;
            this.rangeSmooth = 1;
        },

        serializeData: function () {
            var templateData = {};

            templateData.statuss = this.statuss;
            templateData.ctrls = this.ctrls;
            templateData.hideOkButton = this.hideOkButton;
            templateData.hideNextPreviousButton = this.hideNextPreviousButton;
            templateData.displayHeader = this.displayHeader;
            templateData.displayCC = false;
            var assay = _.findWhere(this.assays, {secId: this.currentAssayTarget.assayVersion});
            if (assay) {
                templateData.displayCC = !!assay.colorCompensation;
            }
            templateData.legendChecked = this.withLegend ? 'checked' : '';
            templateData.rangeSmooth = this.rangeSmooth;
            return templateData;
        },

        onRender: function () {
            this.targetsView = new TargetsView({
                currentAssayTarget: this.currentAssayTarget,
                assays: this.assays,
                assayTargets: this.assayTargets
            });

            this.targetsView.listenTo(this.targetsView, 'well:target:click', _.bind(this.onTargetClick, this));
            this.getRegion('targetsView').show(this.targetsView);
            this.fillTargetResRegionBox();
            this.fillErrWarnRegionBox();
            this.displayGraph();
        },

        onShow: function () {
            $.material.init();
            $(this.ui.cvRadio[0]).prop('checked', true);
            this.ui.cbxValidation.prop('checked', true);
            $('#main-region').addClass('hidden-print');
            this.triggerMethod('enable:cancel:confirm');
        },

        onDilution: function (e) {
            e.preventDefault();
            e.stopPropagation();
        },

        onRunActionNavDropBtnClick: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).parent().find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).parent().find('.run-valView-action-nav-list-empty'));

            _.each(targets, function (target) {
                if (target.hasClass('hidden')) {
                    target.removeClass('hidden');
                    $(e.currentTarget).addClass('active');
                } else {
                    target.addClass('hidden');
                    $(e.currentTarget).removeClass('active');
                }
            });
        },

        onRunActionNavDropCellBtnClick: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).parent().parent().parent().find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).parent().parent().parent().find('.run-valView-action-nav-list-empty'));
            _.each(targets, function (target) {
                if (target.hasClass('hidden')) {
                    target.removeClass('hidden');
                    $(e.currentTarget).addClass('active');
                } else {
                    target.addClass('hidden');
                    $(e.currentTarget).removeClass('active');
                }
            });
        },

        onHideRunActionContainer: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).find('.run-valView-action-nav-list-empty'));
            _.each(targets, function (target) {
                target.addClass('hidden');
                $(e.currentTarget).find('.run-valView-action-nav-drop-btn').removeClass('active');
            });
        },

        onTargetClick: function (k) {
            var currentAssayTarget = k;
            // var currentAssayTarget = this.assayTargets[k];
            if (currentAssayTarget.id !== this.currentAssayTarget.id) {
                this.wellSelected = [];
            }
            this.currentAssayTarget = currentAssayTarget;
            this.onResetFilter();
        },

        onCVRadioChange: function () {
            if (this.ui.cvRadio) {
                _.each(this.ui.cvRadio, _.bind(function (radio) {
                    if (radio.checked) {
                        this.fixedColor = radio.value === 'yes';
                    }
                }, this));
                this.displayGraph();
            }
        },

        onClickBtnEditRunAt: function () {
            this.createEditRunAtView = new CreateEditRunAtLayoutView({
                model: App.request('runat:model', this.runAt),
                modelRun: this.model,
                newItem: false
            });
            this.createEditRunAtView.show({
                title: _.i18n('runat.edit')
            });
        },

        onFilterClick: function (event) {
            var resultCode = 'none';
            var filterType = event.target.attributes['data-filter-type'].value;
            if (filterType === 'result') {
                resultCode = event.target.attributes['data-result-code'].value;
            }
            var filter = {
                type: filterType,
                resultCode: resultCode
            };
            var filtersList = this.onFiltersListChange(filter);
            this.displayWellsResult(filtersList);
        },

        displayWellsResult: function (filtersList) {
            //------------ utilisation des filtres avec 'ET' logique &&
            var first = true;
            var filteredResult = [];
            _.each(filtersList, _.bind(function (obj) {
                var result = this.getWellsFromFilter(obj);

                if (result === null) {
                    filteredResult = [];
                } else if (filteredResult.length < 1 && first) {
                    filteredResult = result;
                    first = false;
                } else if (filteredResult.length > 0 && result && result.length > 0) {
                    filteredResult = filteredResult.filter(function (n) {
                        return result.indexOf(n) !== -1;
                    });
                }
            }, this));
            if (filteredResult.length < 1 && filtersList.length > 0) {
                filteredResult = ['noResult'];
            }
            this.wellSelected = filteredResult;
            this.trigger('well:filter', this.currentAssayTarget, this.wellSelected);
        },

        getWellsFromFilter: function (filter) {
            var filteredWells = this.wellSelected ? this.wellSelected : [];
            switch (filter.type) {
                case 'result':
                    filteredWells = this.getResultFilteredWell(filteredWells, filter);
                    break;
                case 'errWarn':
                    filteredWells = this.getErrWarnFilteredWell(filteredWells, filter);
                    break;
            }

            if (filteredWells.length > 0) {
                return filteredWells;
            } else {
                return null;
            }
        },

        getResultFilteredWell: function (filteredWells, filter) {
            var wellSelected = [];
            this.collection.each(_.bind(function (model) {
                if (model.get('refAssay') && model.get('refAssay').get('secId') !== this.currentAssayTarget.assay.assay.secId) {
                    return;
                }
                var target = this.getCurrentResult(model);
                if (target && target.get('result').get('code') === filter.resultCode) {
                    wellSelected.push(model.get('pos'));
                }
            }, this));
            return wellSelected;
        },

        getCurrentResult: function (model) {
            return _.first(model.get('results').filter(_.bind(function (result) {
                return result.get('refAssayResult').get('id') === this.currentAssayTarget.id;
            }, this)));
        },

        getErrWarnFilteredWell: function () {
            var wellSelected = [];
            this.collection.filter(_.bind(function (model) {
                if (model.get('assayVersion') && model.get('assayVersion').get('secId') !== this.currentAssayTarget.assay.secId) {
                    return;
                }
                var target = this.getCurrentResult(model);
                if (target && target.get('codeErr')) {
                    wellSelected.push(model.get('pos'));
                }
            }, this));
            return wellSelected;
        },

        onFiltersListChange: function (filter) {
            var filtersList = this.options.controller.getFiltersList();
            var add = true;
            if (filtersList.length > 0) {
                filtersList = _.filter(filtersList, _.bind(function (obj) {
                    if (obj.type === filter.type && obj.resultCode === filter.resultCode) {
                        add = false;
                        return false;
                    } else {
                        return true;
                    }
                }, this));
            }
            if (add) {
                filtersList.push(filter);
            }
            this.options.controller.setFiltersList(filtersList);
            return filtersList;
        },

        onResetFilter: function () {
            this.options.controller.setFiltersList([]);
            this.trigger('well:filter', this.currentAssayTarget, []);
        },

        /**
         * fill the error-warning filter-box
         */
        fillErrWarnRegionBox: function () {
            var filtersList = this.options.controller.getFiltersList();
            var content = '';
            var count = 0;
            this.collection.each(_.bind(function (model) {
                var result = this.getCurrentResult(model);
                if (result && result.get('codeErr')) {
                    count++;
                }
            }, this));

            if (count > 0) {
                var found = false;
                if (filtersList && filtersList.length > 0) {
                    found = _.find(filtersList, _.bind(function (obj) {
                        return obj.type === 'errWarn';
                    }, this));
                }
                var checkbox = found ? 'checked' : '';
                content =
                    '<div class="headerGroupCell">' +
                    '<div class="groupCellTitle">' + _.i18n('graph.errWarn') + '</div>' +
                    '<div style="display: flex">' +
                    '<div class="valViewHeaderFilterButton clickable hidden-print " code="" ' +
                    'style="margin: 2px;" title="' + _.i18n('graph.res.warnErr') + '">' +
                    '<span class="mdi mdi-alert warningColorIcon js-warning-btn" data-filter-type="errWarn"' +
                    'style="font-size: 18px; left: 2px; position: relative; top: 1px;"></span>' +
                    '<input type="checkbox" class="js-warning-btn" data-filter-type="errWarn" id="checkBox-errWarn" style="top: 3px; position: relative; left: 6px;" ' + checkbox + '>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
            }
            this.ui.errWarnCheckBoxRegion.append(content);
        },

        /**
         * fill the target results filter-box with selected mbAnaResGroup values
         */
        fillTargetResRegionBox: function () {
            var filtersList = this.options.controller.getFiltersList();
            var content = '';
            var results = [];
            var count = [];
            this.collection.each(_.bind(function (model) {
                var result = this.getCurrentResult(model);
                if (!result) {
                    return;
                }
                if (result.get('result')) {
                    results.push(result.get('result'));
                    if (!count[result.get('result').get('code')]) {
                        count[result.get('result').get('code')] = 1;
                    } else {
                        count[result.get('result').get('code')]++;
                    }
                }
            }, this));
            results = _.uniq(results, function (result) {
                return result.get('secId');
            });

            results = results.filter(_.bind(function (result) {
                return result.get('code') !== null;
            }, this));

            if (results.length) {
                content =
                    '<div class="headerGroupCell">' +
                    '<div class="groupCellTitle">' + _.i18n('graph.res') + '</div>' +
                    '<div style="display: flex">';
                _.each(results, _.bind(function (result) {
                    var resultColor = result.get('color');
                    var found = false;
                    if (filtersList && filtersList.length > 0) {
                        found = _.find(filtersList, _.bind(function (obj) {
                            return (obj.type === 'result' && obj.resultCode === result.get('code'));
                        }, this));
                    }
                    var checkbox = found ? 'checked' : '';
                    content = content +
                        '<div class="valViewHeaderFilterButton clickable hidden-print " code="' + result.get('code') + '" ' +
                        'style="margin: 2px;" title="' + result.get('name') + '">' +
                        '<span class="mdi mdi-checkbox-blank-circle js-result-btn" style="font-size: 21px; position: relative; bottom: 7px;' +
                        'color:' + (resultColor ? resultColor : '') + ';" data-filter-type="result" data-result-code="' + result.get('code') + '"></span>' +
                        '<input type="checkbox" class="js-result-btn" id="checkbox-result-' + result.get('code') + '" ' +
                        'data-result-code="' + result.get('code') + '" data-filter-type="result" style="top: -5px; position: relative; left: 5px;" ' + checkbox + ' value="' + result.get('code') + '">' +
                        '</div>';
                }, this));
                content = content +
                    '</div>' +
                    '</div>';
            }
            this.ui.targetResCheckBoxRegion.append(content);
        },

        onDestroy: function () {
            this.stopListening();
        },

        displayGraph: function () {
            var models = this.collection.filter(_.bind(function (model) {
                return this.wellSelected.includes(model.get('pos'));
            }, this));

            _.each(models, _.bind(function (model) {
                this.listenTo(model, 'change', this.renderSafe);
            }, this));

            var wells = this.collection.filter(function (well) {
                return !well.get('results').isEmpty();
            });
            wells = this.getListViewData(wells);
            var view = new WellDisplayHL7TableView({
                model: this.model
            });
            view.listenTo(view, 'well:check', _.bind(this.addWellSelected, this));
            view.listenTo(view, 'well:uncheck', _.bind(this.removeWellSelected, this));
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.getRegion('graph').show(view);
            view.gridOptionsObj = view.gridOptions(wells);
            view.onRowCountDone(wells, 9999);
        },
        onGridLoaded: function () {
            setTimeout(_.bind(function () {
                var wells = this.collection.filter(function (well) {
                    return !well.get('results').isEmpty();
                });
                _.each(wells, _.bind(function (well, index) {
                    if (well.get('pos') && $('.wellAssayPopover-' + well.get('pos')).length > 0) {

                        var params = {
                            context: this,
                            model: well
                        };

                        params.targets = [
                            $('.wellAssayPopover-' + well.get('pos'))
                        ];

                        if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                            (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                            params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                        }
                        var placement = 'bottom';
                        if (index > wells.length - 2 - (well.get('results').length * 0.75)) {
                            placement = 'top';
                        }

                        WellUtils.showPcrWellPopover(params, placement);
                    }
                }, this));
            }, this), 200);
        },
        addWellSelected: function (well) {
            this.trigger('well:check', well);
        },
        removeWellSelected: function (well) {
            this.trigger('well:uncheck', well);
        },

        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },
        getWellListViewData: function (wells) {
            var wellsForData = [];

            _.each(wells, _.bind(function (well) {
                var model = {
                    secId: well.get('secId'),
                    wellRes: well.get('wellRes'),
                    pos: well.get('pos'),
                    smpId: well.get('smpId') ? {
                        secId: well.get('smpId').get('secId'),
                        name: well.get('smpId').get('name'),
                        code: well.get('smpId').get('code'),
                        lisComment: well.get('smpId').get('lisComment'),
                        comment: well.get('smpId').get('comment')
                    } : {name: '', code: ''},
                    codeErr: well.get('codeErr'),
                    repeatStatus: well.get('repeatStatus'),
                    existOtherWellSameAssay: well.get('existOtherWellSameAssay'),
                    smpType: well.get('smpType'),
                    refAssay: well.get('refAssay') ? {
                        code: well.get('refAssay').get('code'),
                        secId: well.get('refAssay').get('secId'),
                        name: well.get('refAssay').get('name')
                    } : {code: '', name: ''},
                    wellResSearchValue: well.getWellRes(),
                    valWst: well.get('valWst'),
                    dilution: ''
                };

                model.wellRes = _.first(well.getDisplayGroup());

                var wellResCode = well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code') ?
                    well.get('wellRes').get('result').get('code') : '';

                var wellResQuantification = well.get('wellRes') && well.get('wellRes').get('quantificationFormatted') ?
                    well.get('wellRes').get('quantificationFormatted') : '';

                model.wellResSearchValue = wellResCode + wellResQuantification;

                _.each(well.getTargetsResultIcons(), _.bind(function (result, key) {
                    model['target' + (key + 1) + 'Results'] = result;
                    model['target' + (key + 1) + 'ResultsSearchValue'] = result.resCode + result.cts;
                }, this));

                wellsForData.push(model);
            }, this));

            return {
                wellsForData: wellsForData
            };
        },


        onConfirm: function () {
            this.triggerMethod('saved');
            this.hide();
        },

        hide: function () {
            $('#main-region').removeClass('hidden-print');
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
        }
    });
})
;
