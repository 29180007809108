/**
 * Created by OBL on 14/01/2016.
 */
define('superAdminDocGlossaryDetailsController',[
  'module',
  'underscore',
  'app',
  'superAdminDocGlossariesView',
  'bootbox',
  'docGlossaryCreateEditLayoutView',
  'settings',
  'entities/admin/docglossary'
], function (
  module,
  _,
  App,
  View,
  bootbox,
  CreateEditLayoutView
) {
  'use strict';

  var SuperAdminDocGlossaryController = {
    showList: function () {
      var view = new View();
      this.view = view;
      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'docglossary:delete', _.bind(this.onDelete, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    showAdd: function () {
      this.createEditView = new CreateEditLayoutView({
        model: App.request('docglossary:model'),
        newItem: true
      });
      this.createEditView.show({
        title: _.i18n('menu.superadmin.docGlossary'),
        className: 'baseTableEditAddPopup'
      });
    },

    onGridLoaded: function () {
      var data = {
        url: App.request('docglossary:get-collection-url'),
        rowCountUrl: App.request('docglossary:get-count-url')
      };

      this.view.displayGrid(data);
    },

    onDelete: function (secId) {
      bootbox.confirm(_.i18n('docglossary.confirmDelete'), _.bind(function (result) {
        if (result) {
          App.request('docglossary:delete', secId)
            .done(_.bind(function () {
              this.view.updatePagination('reloadGrid');
            }, this));
        }
      }, this));
    }
  };

  module.exports = SuperAdminDocGlossaryController;
});
