
define('template!wellTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem noWrapHidden ' +
((__t = ( error ? 'has-error' : '')) == null ? '' : __t) +
'">\n    <div class="pcrRunWellGrid plateView-wellBackground col-xs-12 p-r-0 p-l-0 ' +
((__t = ( validatedProgess ? 'watermark-progress': '' )) == null ? '' : __t) +
'"\n         style="height: 100%; ' +
((__t = ( (backgroundColor? ('background-color:'+ backgroundColor + ' !important;' ):'') )) == null ? '' : __t) +
'">\n        <div class="topSideAbsolute">\n            <div class="btn-group-flat">\n                ';
 if(displayKitLot) { ;
__p += '\n                <button class="wellButton js-btn-preprun-kitLot">\n                    <span class="mdi mdi-flask-outline clickable"></span>\n                </button>\n                ';
 } ;
__p += '\n                ';
 if (well.smpType === 'NC' || well.smpType === 'PC' || well.smpType === 'RC' ||
                well.smpType === 'C' || well.smpType === 'OC' )
                { ;
__p += '\n                ';
 if (well.showQC){ ;
__p += '\n                <button class="wellButton smp-id-link mdi-qc">\n                    <span class="mdi mdi-alpha-q"></span>\n                    <span class="mdi mdi-alpha-c"></span>\n                </button>\n                ';
 } ;
__p += '\n                ';
 } else { ;
__p += '\n                ';
 if(well.smpType === 'U') { ;
__p += '\n                <button class="wellButton smp-id-link">\n                    <span class="mdi mdi-alpha-s clickable"></span>\n                </button>\n                <button class="wellButton js-btn-comment">\n                    <span class="mdi mdi-alpha-c clickable"></span>\n                </button>\n                ';
 } ;
__p += '\n                ';
 } ;
__p += '\n                ';
 if(error) { ;
__p += '\n                <button class="wellButton js-btn-kitLot">\n                    <span class="mdi mdi-alert-octagram clickable" title="' +
((__t = ( _.i18n('common.showErrors') )) == null ? '' : __t) +
'"></span>\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n\n        <div class="plateView-icon-container" style="width: 100%; height: 100%;">\n            ';
 if(errorWarning && expertMode) { ;
__p += '\n            <span class="mdi mdi-alert warningErrorIcon iconTop warningColorIcon"></span>\n            <span class="mdi mdi-alert warningErrorIcon iconBottom"></span>\n            <span class="mdi mdi-school inWarning-expertIcon iconTop"\n                  style="color: #1c3bc1;"></span>\n            <span class="mdi mdi-school inWarning-expertIcon iconBottom"></span>\n\n            ';
 } else if(expertMode) { ;
__p += '\n            <span class="mdi mdi-school expertIcon iconTop"\n                  style="color: #1c3bc1;"></span>\n            <span class="mdi mdi-school expertIcon iconBottom"></span>\n            ';
 } else if(errorWarning) { ;
__p += '\n            <span class="mdi mdi-alert warningErrorIcon iconTop warningColorIcon"></span>\n            <span class="mdi mdi-alert warningErrorIcon iconBottom"></span>\n\n            <!-- vvv START: move this selection to the target line bellow to show highlighted repeat icon if the status is exported  vvv -->\n            ';
 } else if (well.exported && well.repeatStatus) { ;
__p += '\n            <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
'" style="opacity: 0.2;"></span>\n            ';
 } else if (well.repeatStatus) { ;
__p += '\n            <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
' iconTop"></span>\n            <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
' iconBottom"></span>\n            ';
 } else if(well.val2 && well.smpType !== 'E') { ;
__p += '\n            <span class="mdi mdi-check-all watermark"></span>\n            ';
 } else if(well.exported && well.smpType !== 'E') { ;
__p += '\n            <span class="mdi mdi-send watermark"></span>\n            ';
 } ;
__p += '\n\n            ';
 if (well.readyToExport) { ;
__p += '\n            <span class="mdi mdi-decagram mdi-spin smpType-plateViewIcon"\n                  style="position: absolute; top: 58px; left: 11px;"></span>\n            ';
 } ;
__p += '\n            <div class="col-xs-1 p-r-0 p-l-0">\n                <div class="wellResColorBar" style="height: 80px; width: 5px; margin-left: 5px;\n                ' +
((__t = ( (wellResColor? ('background-color:'+ wellResColor + ';'):'') )) == null ? '' : __t) +
';"></div>\n            </div>\n            <div class="col-xs-11 p-r-0 p-l-0 ' +
((__t = ( !well.exported && (well.repeatStatus || errorWarning || expertMode)?'repeatOpacity':'')) == null ? '' : __t) +
'"\n                 style="height: 100%; ">\n                <div style="position: relative;display: flex;flex-direction: column;" class="m-t-20">\n                    <!-- Sample -->\n                    <div class="col-xs-12 p-l-0 p-r-0 " style="display: flex;flex:1;">\n                        <div class="col-xs-2 p-l-0 p-r-0" style="line-height: 17px;">\n                            ';
 if (well.smpType === 'NC') { ;
__p += '\n                            <span class="mdi-content-remove-circle smpType-plateViewIcon"></span>\n                            ';
 } else if(well.smpType === 'PC') { ;
__p += '\n                            <span class="mdi-content-add-circle smpType-plateViewIcon"></span>\n                            ';
 } else if(well.smpType === 'RC') { ;
__p += '\n                            <span class="mdi rc-smpType mdi mdi-alpha-r-circle smpType-plateViewIcon"></span>\n                            ';
 } else if(well.smpType === 'C') { ;
__p += '\n                            <span class="mdi mdi-alpha-c-circle smpType-plateViewIcon"></span>\n                            ';
 } else if(well.smpType === 'OC') { ;
__p += '\n                            <span class="mdi mdi-compass smpType-plateViewIcon"></span>\n                            ';
 } else if(well.smpType === 'D') { ;
__p += '\n                            <span class="mdi mdi-alpha-d-circle smpType-plateViewIcon"></span>\n                            ';
 } else if(well.smpType === 'C') { ;
__p += '\n                            <span class="mdi mdi-alpha-c-circle smpType-plateViewIcon"></span>\n                            ';
 } else if(well.smpType === 'P') { ;
__p += ' <!-- Pool -->\n                            <span class="pool-label mdi mdi-google-circles-communities smpType-plateViewIcon"></span>\n                            ';
 } else {;
__p += '\n                            ';
 if(well.existOtherWellSameAssay) { ;
__p += '\n                            <span class="triangle-same-assay-plateView"\n                                  style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
'; top: 18px; z-index: 10;"></span>\n                            ';
 } ;
__p += '\n                            ';
 if(well.existOtherWellOtherAssay) { ;
__p += '\n                            <span class="triangle-other-assay-plateView"\n                                  style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
'; top: 18px; z-index: 10;"></span>\n                            ';
 } ;
__p += '\n                            ';
 } ;
__p += '\n\n                            ';
 if(well.pooled) { ;
__p += ' <!-- pooled -->\n                            <span class="pooled-label mdi mdi-adjust smpType-plateViewIcon"></span>\n                            ';
 } ;
__p += '\n                        </div>\n                        <div class="col-xs-10 p-l-0 p-r-0 js-well-sample">\n                        </div>\n                    </div>\n                    <div class="col-xs-12 p-l-0 p-r-0 " style="display: flex;">\n                        <div class="col-xs-2 p-l-0 p-r-0">\n                        </div>\n                        <div class="col-xs-10 p-l-0 p-r-0">\n                            <div class="noWrapHidden cs-assay-font" style="font-size: 12px;">\n                            <span style="' +
((__t = ( (assayColor?('background-color:' + assayColor + ' !important;'):'') )) == null ? '' : __t) +
'">\n                                ' +
((__t = ( well.assay )) == null ? '' : __t) +
'</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class="col-xs-12 p-l-0 p-r-0 js-display-result" style="display: flex;flex:1;">\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

