/**
 * Created by RKL on 21/08/2015.
 */
define('runsShowController',[
	'backbone',
	'module',
	'logger',
	'underscore',
	'app',
	'cssUtils',
	'runsShowView',
	'jquery',
	'bootbox',
	'customBootboxMessage',
	'emptyView',
	'runCreateLayoutView',
	'runCreateFromUploadView',
	'runChooseUploadView',
	'settings',
	'runCreatePcrRunFromFileImportResultView',
	'runCreatePcrRunFromFilePlateLayoutView',
	'runCreatePcrRunFromFileSampleListView',
	'runCreateEmptyPcrRunView',
	'runCreatePcrRunFromCyclerView',
	'runCreatePcrRunFromPlateView',
	'runCreateNewPcrRunSetupView',
	'runRemoteFolderView',
	'entities/caccounts/pcrruns',
	'entities/admin/codelist',
	'entities/caccounts/setting'
], function (
	Backbone,
	module,
	Logger,
	_,
	App,
	CssUtils,
	View,
	$,
	bootbox,
	CustomBootboxMessage,
	EmptyView,
	CreateEditLayoutView,
	CreateFromUploadView,
	ChooseUploadView,
	Settings,
	RunCreatePcrRunFromFileImportResultView,
	RunCreatePcrRunFromFilePlateLayoutView,
	RunCreatePcrRunFromFileSampleListView,
	RunCreateEmptyPcrRunView,
	RunCreatePcrRunFromCyclerView,
	RunCreatePcrRunFromPlateView,
	RunCreateNewPcrRunSetupView,
	RunRemoteFolderView,
	PcrRuns,
	CodeLists
) {
	'use strict';

	module.exports = {
		showRuns: function (displayArchived) {
			CssUtils.applyPcrRunTheme();
			App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
				CodeLists.findItemsByCode('PCRRUN-ST').done(_.bind(function (codeListStatus) {
					CodeLists.findItemsByCode('PCRRUN-VAL-ST').done(_.bind(function (codeListValStatus) {
						if (displayArchived === undefined) {
							displayArchived = 'WITHOUT';
						}
						this.view = new View({
							displayArchived: displayArchived,
							settings: settings,
							codeListStatus: codeListStatus,
							codeListValStatus: codeListValStatus,
							entity: PcrRuns
						});
						this.selectedList = [];
						this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
						this.view.listenTo(this.view, 'onLoadSelectedRuns', _.bind(this.onLoadSelectedRuns, this));
						this.view.listenTo(this.view, 'run:import-xml', _.bind(this.onImportXml, this));
						this.view.listenTo(this.view, 'run:pcr:delete', _.bind(this.onDelete, this));
						this.view.listenTo(this.view, 'run:pcr:archive', _.bind(this.onArchive, this));
						this.view.listenTo(this.view, 'run:pcr:unarchive', _.bind(this.onUnArchive, this));
						this.view.listenTo(this.view, 'run:import', _.bind(this.showImportRun, this));
						this.view.listenTo(this.view, 'run:selectedList', _.bind(this.onSelectedRunsList, this));
						App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
					}, this));
				}, this));
			}, this));
		},

		onArchive: function (secId) {
			App.request('run:pcr:archive', secId)
				.done(_.bind(function () {
					this.view.updatePagination('reloadGrid');
				}, this));
		},

		onUnArchive: function (secId) {
			App.request('run:pcr:unarchive', secId)
				.done(_.bind(function () {
					this.view.updatePagination('reloadGrid');
				}, this));
		},

		onSelectedRunsList: function (selectedList) {
			this.selectedList = selectedList;
		},

		getSelectedRunsList: function () {
			return this.selectedList;
		},

		onCreateEmptyPcrRun: function (defDept, callBackOnCreated) { //used to create run from listView
			this.createEditView = new RunCreateEmptyPcrRunView({
				model: PcrRuns.getModel(),
				defDept: defDept,
				callBackOnCreated: callBackOnCreated ? callBackOnCreated : _.bind(this.onCreateRun, this)
			});
			this.createEditView.show({
				title: _.i18n('run.pcr.new'),
				className: 'actionPopupCss900 actionPopupCss popupFitContent'
			});
		},

		showViewNewRunFromFileImportResult: function () {
			App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
				this.createEditView = new RunCreatePcrRunFromFileImportResultView({
					model: new Backbone.Model(),
					settings: settings
				});
				this.createEditView.show({
					title: _.i18n('run.pcr.new.fromFile.importResult'),
					className: 'actionMediumPopupCss actionPopupCss popupFitContent'
				});
				this.createEditView.listenTo(this.createEditView, 'run:listView:refresh', _.bind(this.onRefreshListViewGrid, this));
			}, this));
		},

		showViewNewRunFromFilePlateLayout: function () {
			App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
				this.createEditView = new RunCreatePcrRunFromFilePlateLayoutView({
					model: new Backbone.Model(),
					settings: settings
				});
				this.createEditView.show({
					title: _.i18n('run.pcr.new.fromFile.plateLayout'),
					className: 'actionMediumPopupCss actionPopupCss popupFitContent'
				});
				this.createEditView.listenTo(this.createEditView, 'run:listView:refresh', _.bind(this.onRefreshListViewGrid, this));
			}, this));
		},

		showViewNewRunFromFileSampleList: function () {
			App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
				this.createEditView = new RunCreatePcrRunFromFileSampleListView({
					model: new Backbone.Model(),
					settings: settings
				});
				this.createEditView.show({
					title: _.i18n('run.pcr.new.fromFile.sampleList'),
					className: 'actionMediumPopupCss actionPopupCss popupFitContent'
				});
				this.createEditView.listenTo(this.createEditView, 'run:listView:refresh', _.bind(this.onRefreshListViewGrid, this));
			}, this));
		},

		onCreateRun: function (result) {
			if (result.length === 1) {
				App.navigate('runs/pcr/' + result[0].secId + '/A01/1/' + result[0].plateSize, {trigger: true});
			} else {
				this.onRefreshListViewGrid();
			}
		},
		onRefreshListViewGrid: function () {
			this.view.updatePagination('reloadGrid');
		},

		showImportRun: function () {
			App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
				this.chooseUploadView = new ChooseUploadView({
					settings: settings
				});
				this.chooseUploadView.show({
					title: _.i18n('run.import')
				});
				this.chooseUploadView.listenTo(this.chooseUploadView, 'run:reloadData', _.bind(function () {
					this.view.updatePagination('reloadGrid');
				}, this));
				this.chooseUploadView.listenTo(this.chooseUploadView, 'run:import-xml', _.bind(this.onImportXml, this));
				this.chooseUploadView.listenTo(this.chooseUploadView, 'run:select-file-to-import', _.bind(this.onSelectFileToImport, this));
			}, this));
		},

		onGridLoaded: function () {
			var data = {
				url: App.request('run:pcr:get-collection-url', this.view.options.displayArchived),
				rowCountUrl: App.request('run:pcr:get-count-url', this.view.options.displayArchived)
			};
			this.view.displayGrid(data);
		},

		onLoadSelectedRuns: function (first, last, params) {
			App.request('run:pcr:get-collection-to-page', params)
				.done(_.bind(function (runs) {
					this.view.triggerMethod('selectRuns', first, last, runs);
				}, this));
		},

		onImportXml: function (fileInfo, generateStarterSheet, deleteFileFromRemoteFolder) {
			this.generateStarterSheet = generateStarterSheet;
			this.deleteFileFromRemoteFolder = deleteFileFromRemoteFolder;
			var createFromUploadView = new CreateFromUploadView();
			createFromUploadView.listenTo(createFromUploadView, 'popup-create:selectLmbAnalyser', _.bind(function (lmbAnalyser, kitProt) {
				this.view.onDestroy();
				this.emptyView = new EmptyView();
				App.regions.getRegion('main').currentView.getRegion('content').show(this.emptyView);

                PcrRuns.importXmlFile(fileInfo, lmbAnalyser, kitProt).done(_.bind(function (result) {
					if (this.generateStarterSheet) {
						PcrRuns.generateRunSheet(result.secId);
					}
					if (this.deleteFileFromRemoteFolder) {
						App.request('run:pcr:delete-remote-file', fileInfo.name);
					}
					this.emptyView.destroy();
					this.showRuns();
				}, this)).fail(_.bind(function () {
					this.emptyView.destroy();
					this.showRuns();
					bootbox.alert(_.i18n('common.errorOccuredWhileImporting'), function () {
					});
				}, this));
			}, this));
			createFromUploadView.show({
				title: _.i18n('run.create')
			});
		},

		onDelete: function (secId, codeName) {
			var callback = _.bind(function (result) {
				if (result) {
                    PcrRuns.deleteRun(secId)
						.done(_.bind(function () {
							this.view.updatePagination('reloadGrid');
						}, this));
				}
			}, this);
			var confirmParams = {
				message: _.i18n('confirmDelete') + ' run ' + ' \"' + codeName + '\" ?',
				notaBene: _.i18n('confirmDelete.notaBene'),
				type: 'warning'
			};
			CustomBootboxMessage.customConfirm(confirmParams, callback);
		},

		onSelectFileToImport: function () {
			PcrRuns.filesToImport().done(_.bind(function (files) {
				this.runRemoteFolderView = new RunRemoteFolderView({files: files, type: 'result'});
				this.runRemoteFolderView.listenTo(this.runRemoteFolderView, 'run:import-xml', _.bind(this.onImportXml, this));
				this.runRemoteFolderView.show({
					title: _.i18n('run.import.remote.folder')
				});
			}, this));
		}
	};
});
