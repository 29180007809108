define('prepRunExtractionShow',[
    'module',
    'dialogFormView',
    'template!prepRunExtractionShow',
    'underscore',
    'jquery',
    'dateUtils'
], function (
    module,
    DialogFormView,
    Tpl,
    _,
    $,
    dateConverter
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'hide',
            'click .js-confirm': 'hide'
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            if (templateData.refExtractionMethod) {
                templateData.refExtractionMethod = templateData.refExtractionMethod.code + ' : ' + templateData.refExtractionMethod.name;
                templateData.startUserpid = templateData.startUserpid.lastName + ' ' + templateData.startUserpid.firstName + ' ' + dateConverter.toDateTimeShortYearFormatString(templateData.startDate);
            }
            if (templateData.refLmbExtractor) {
                templateData.refLmbExtractor = templateData.refLmbExtractor.code + (templateData.name ? ' : ' + templateData.name : '');
                templateData.endUserpid = templateData.endUserpid.lastName + ' ' + templateData.endUserpid.firstName + ' ' + dateConverter.toDateTimeShortYearFormatString(templateData.endDate);
            }
            return templateData;
        },

        onShow: function () {
            $.material.init();
            this.triggerMethod('enable:cancel:confirm');
        },

        hide: function () {
            this.trigger('run:prep:extraction:update:buttons');
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        }
    });
});
