
define('template!prepRunWellEditExportSmpIdPlateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="saving-label"></div>\n<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n    <div class="error-msg"></div>\n</div>\n<div class="form-horizontal">\n    <div class="form-inputs-container t-a-c">\n\n        <label class="popupTitleLabel">' +
((__t = ( _.i18n('selectfileFormat.placeholder') )) == null ? '' : __t) +
'</label>\n        <div class="row">\n            <div class="js-select-fileFormat-region selectFileFormatDropDown\n            editPlateLayout-drpdn-short"></div>\n        </div>\n\n        <label class="popupTitleLabel">' +
((__t = ( _.i18n('export.destination') )) == null ? '' : __t) +
'</label>\n        <div class="row">\n            <div class="js-select-exportSource-region editPlateLayout-drpdn-short"\n                 id="exportDestinationePlateLayout"></div>\n        </div>\n        <div class="row enterEditPlateLayout-buttons" style="display: flex; justify-content: center;">\n            <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n\n            <button disabled\n                    class="mdi-navigation-check btn popupBtn-confirm disabled-grey export-plate-layout-popup-button"></button>\n            <!-- js-confirm -->\n        </div>\n    </div>\n</div>\n<div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n';

}
return __p
};});

