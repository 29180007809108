/**
 * Created by GSP on 15/09/2015.
 */
/**
 * Created by RKL on 10/09/2015.
 */
define('chartHorizontalBarView',[
    'module',
    'logger',
    'marionette',
    'underscore',
    'jquery',
    'chart',
    'template!chartHorizontalBarView',
    'extendChart'
], function (
    module,
    Logger,
    Marionette,
    _,
    $,
    Chart,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({

        template: viewTpl,

        ui: {
            chart: '.chart-canvas'
        },

        attributes: function () {
            return {style: 'position: relative;height:20px; width:100%;'};
        },

        onShow: function () {
            this.data = {
                labels: [''],
                datasets: [{
                    axis: 'y',
                    label: '',
                    data: [this.options.data],
                    fill: false,
                    backgroundColor: [
                        this.options.color
                    ],
                    barThickness: 'flex'
                }]
            };


            this.instance = new Chart(this.ui.chart[0].getContext('2d'), {
                type: 'horizontalBar',
                data: this.data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    lineAtIndex: 1,
                    scales: {
                        xAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: this.options.max,
                                suggestedMin: 0,
                                max: this.options.max,
                                min: 0,
                                stepSize: 0.1
                            }
                        }],
                        yAxes: [{
                            display: false
                        }]
                    },
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    }
                }
            });
        },

        onDestroy: function () {
            if (this.instance) {
                this.instance.destroy();
            }
        }
    });
});
