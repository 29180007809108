
define('template!orderCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\n\t\t\t<!-- header -->\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t<div>\n\t\t\t\t\t\t<div class="col-xs-10" style="border: solid 1px black; border-radius: 5px; background-color: #f5f5dc;\n                     padding: 2px; height: 40px; text-align: center;">\n\t\t\t\t\t\t\t<span style="font-size: 24px;">' +
((__t = ( code )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('order.created') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( created )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = (
							_.i18n('order.untilValidated') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( untilValidated )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('order.specie') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( specieCode )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row p-l-15 p-r-15 m-t-20">\n\t\t\t\t<label class="col-xs-2 text-right">' +
((__t = ( _.i18n('sample.patientInformation') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-10">' +
((__t = ( patient )) == null ? '' : __t) +
'</div>\n\t\t\t</div>\n\t\t\t<!-- results -->\n\t\t\t<div class="row p-l-15 p-r-15 m-t-20" style="border: solid 1px #c6c6c6; border-radius: 8px;">\n\t\t\t\t<div class="col-w-all col-w-100 d-f border-bottom">\n\t\t\t\t\t<div class="col-w-5">\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-w-25">\n\t\t\t\t\t\t<b>' +
((__t = ( _.i18n('sampleId') )) == null ? '' : __t) +
'</b>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-w-10">\n\t\t\t\t\t\t<b>' +
((__t = ( _.i18n('samples.biogroup') )) == null ? '' : __t) +
'</b>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-w-40">\n\t\t\t\t\t\t<b>' +
((__t = ( _.i18n('sample.lisAna.open') )) == null ? '' : __t) +
'</b>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-w-20">\n\t\t\t\t\t\t<b>' +
((__t = ( _.i18n('sample.gAssay') )) == null ? '' : __t) +
'</b>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-w-20">\n\t\t\t\t\t\t<b>' +
((__t = ( _.i18n('sample.wells') )) == null ? '' : __t) +
'</b>\n\t\t\t\t\t</div>\n\n\t\t\t\t</div>\n\t\t\t\t<div class="js-samples m-t-10 "></div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="row cancelConfirmRow">\n\t\t\t<div class="pull-right p-r-15 col-xs-12" style="text-align: end;">\n\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n\t\t\t\t<button disabled\n\t\t\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class="loader loading-block empty-page-loader" style="top: 147px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
};});

