define('superAdminDBDocumentationJsonObjectView',[
	'module',
	'backbone',
	'marionette',
	'underscore',
	'jquery',
	'app',
	'autocompleteView',
	'settings',
	'dialogFormView',
	'template!superAdminDBDocumentationJsonObjectTpl',
	'entities/admin/codelist',
    'entities/admin/array'
], function (
	module,
	Backbone,
	Marionette,
	_,
	$,
	App,
	AutocompleteView,
	Settings,
	DialogFormView,
	ViewTpl,
	CodeLists,
    Arrays
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: ViewTpl,

		ui: {
			input: '.js-info-input-child'
		},
		regions: {
			selectRelationType: '.js-select-relationtype-region',
			selectRelation: '.js-select-relation-region',
			selectDataType: '.js-select-datatype-region'
		},
		events: {
			'change @ui.input': 'onInputChange',
			'focus .js-select-relationtype-region': 'onInputFocus',
			'focus .js-select-relation-region': 'onInputFocus',
			'focus .js-select-datatype-region': 'onInputFocus',
			'click .js-remove-icon': 'onRemoveProperty'
		},

		onShow: function () {
			this.getRegion('selectRelationType').show(new AutocompleteView(
				this._getAutocompleteOptionObject(
				CodeLists.getAutocompleteParamForCode({
					modelProperty: 'relationType',
					codeListCode: 'RELTYPE',
					callBackOnChange: this.onChangeRelationType
				}))));
			if (this.model.get('relationType')) {
				this.showRelationTypeAutocomplete();
			}
			this.getRegion('selectDataType').show(new AutocompleteView(
				this._getAutocompleteOptionObject(
				CodeLists.getAutocompleteParamForCode({
					modelProperty: 'dataType',
					codeListCode: 'JSONFLDTYP',
					callBackOnChange: this.onChangeDataType
				}))));
		},
		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.analysis = this.options.analysis;
			return templateData;
		},
		onChangeRelationType: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.onChangeItem(fieldName, model ? model : null);
				this.model.set('relation', null);
				this.showRelationTypeAutocomplete();
			}
		},
		onChangeRelation: function (fieldName, model) {
			if (model) {
				this.onChangeItem(fieldName, model);
			}
		},
		onChangeItem: function (fieldName, model) {
			model.unset('autocompleteValue', {silent: true});
			if (this.model.get('relationType') === 'J') {
				this.model.set(fieldName, model.get('name'));
			} else {
				this.model.set(fieldName, model.get('code'));
			}
		},
		onChangeDataType: function(fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.model.set(fieldName, model.get('code'));
			} else {
				this.model.set(fieldName, null);
			}
		},

		getFieldAndUrl: function () {
			var url, field, fieldsToDisplay, searchField;
			switch (this.model.get('relationType')) {
				case 'C':
					url = 'public/codeLists/ac';
					field = 'code';
					searchField = 'name';
					fieldsToDisplay = CodeLists.getFieldsToDisplay();
					break;
				case 'J':
					url = 'public/jsondocumentation/object?sidx=name&sord=ASC';
					field = 'name';
					searchField = 'name';
					fieldsToDisplay = App.request('jsonobjectdocumentation:get-fields-to-display');
					break;
				case 'A':
					url = 'public/arrays/ac';
					field = 'code';
					searchField = 'name';
					fieldsToDisplay = Arrays.getFieldsToDisplay();
					break;
			}
			return {'url': url, 'field': field, 'fieldsToDisplay': fieldsToDisplay, 'searchField': searchField};
		},
		showRelationTypeAutocomplete: function () {
			var params = this.getFieldAndUrl();
			this.getRegion('selectRelation').show(new AutocompleteView(
				this._getAutocompleteOptions(
					'relation',
					params.field,
					params.searchField,
					params.url,
					' ',
					params.field,
					this.onChangeRelation,
					'',
					'',
					params.fieldsToDisplay
				)
			));
		},
		onRemoveProperty: function () {
			this.model.trigger('destroy', this.model);
		},
		onInputFocus: function (event) {
			if ($(event.currentTarget).offset().top - $('.cancelConfirmDiv').offset().top > -100) {
				var scrollLength = 250;
				if (document.getElementById('columns').scrollHeight - $('#columns').scrollTop() > 250) {
					scrollLength = 50;
				}
				setTimeout(function () {
					document.getElementById('columns').scrollTop += scrollLength;
				}, 500);
			}
		}
	});
});
