define('prepRunTabsLayoutView',[
	'module',
	'jquery',
	'app',
	'marionette',
	'template!prepRunTabsTpl',
	'prepRunTabsLayoutHeaderView',
	'entities/caccounts/pcrruns'
], function (
	module,
	$,
	App,
	Marionette,
	prepRunTabsTpl,
	PrepRunTabsLayoutHeaderView
) {
	'use strict';

	module.exports = Marionette.LayoutView.extend({
		template: prepRunTabsTpl,

		regions: {
			tabs: '.js-tabs-region',
			mainHeader: '.tab-layout-main-header-region'
		},

		onShow: function () {
			var opacity = '1'; //block
			if (this.currentTab === 'tracs' || App.getCurrentRoute().search('tracs') >= 0) {
				opacity = '0.2'; //none
			}

			this.prepRunTabsLayoutHeaderView = new PrepRunTabsLayoutHeaderView({
				model: this.options.model,
				tabs: this.options.tabs,
				edit: this.options.edit,
				from: this.options.from,
				step: this.options.step,
				to: this.options.to,
				opacity: opacity,
				tabName: this.options.tabName
			});
			this.getRegion('mainHeader').show(this.prepRunTabsLayoutHeaderView);
			this.prepRunTabsLayoutHeaderView.triggerMethod('show:active', this.options.tabName, '');
		},

		serializeData: function () {
			return {};
		}
	});
});
