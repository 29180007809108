define('entities/global/token',[
    'app',
    'jquery',
    'underscore',
    'backbone',
    'settings',
    'module'
], function (
    App,
    $,
    _,
    Backbone,
    Settings,
    module
) {
    'use strict';

    module.exports = {
        findBy: function () {
            var url = Settings.url('compuzz', 'v2/tokens/'),
                defer = $.Deferred();
            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        }
    };
});
