
define('template!dashboardPcrThumbnailTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="well jq-grid-table-page col-xs-12" style="height: 700px;">\n\n        <div class="row baseTableTitleContainer" style="width: inherit;">\n            <div class="col-xs-12">\n                <div>' +
((__t = ( _.i18n('menu.pcr.thumbnail.view') )) == null ? '' : __t) +
'</div>\n            </div>\n\n            <div class="col-xs-12 p-l-0 p-r-0 js-pcr-thumbnail-filter-container"\n                 style="display: flex; justify-content: center; height: 120px;">\n\n                <!-- Filter dropDowns -------------------------------------------->\n                <div class="headerGroupCellContainer" style="width: 115px;">\n                    <div class="groupCellTitle">' +
((__t = ( _.i18n('department') )) == null ? '' : __t) +
'</div>\n                    <div style="display: flex;">\n                        <div class="headerFilterDropDown js-department-region dropdown-custom-width-css js-select-department"\n                             name="select-department"\n                             style="width: 100px;"></div>\n                    </div>\n                </div>\n                <div class="headerGroupCellContainer" style="width: 115px;">\n                    <div class="groupCellTitle">' +
((__t = ( _.i18n('run.group') )) == null ? '' : __t) +
'</div>\n                    <div style="display: flex;">\n                        <div class="headerFilterDropDown js-runGroup-region dropdown-custom-width-css js-select-runGroup"\n                             name="select-runGroup"\n                             style="width: 100px;"></div>\n                    </div>\n                </div>\n\n                <!-- Filter buttons -------------------------------------------->\n                <!-- status filter -->\n                <div class="headerGroupCellContainer">\n                    <div class="groupCellTitle">' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</div>\n                    <div style="display: flex;">\n                        <!-- waiting for import result-->\n                        <div class="headerFilterButton js-select-import-result js-tab-filters clickable hidden-print js-select-filter"\n                             name="select-import-result"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('waitingFor.result') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-playlist-check mdiHeaderIconButton"\n                                 style="position: relative;  bottom: 2px; left: 4px; font-size: 24px;"></span>\n                            <input type="checkbox" class="js-filter-checkBox">\n                        </div>\n                        <!-- waiting for pcr setup-->\n                        <div class="headerFilterButton js-select-waitingFor-pcrSetup js-tab-filters clickable hidden-print js-select-filter"\n                             name="select-waitingFor-pcrSetup"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('waitingFor.pcrSetup') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-playlist-check mdiHeaderIconButton"\n                                 style="position: relative;  bottom: 2px; left: 4px; font-size: 24px;"></span>\n                            <input type="checkbox" class="js-filter-checkBox">\n                        </div>\n\n\n                        <div class="headerFilterButton js-select-waiting-val1  js-tab-filters clickable hidden-print js-select-filter"\n                             name="select-waiting-val1"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('waitingFor.val1') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-check mdiHeaderIconButton"\n                                 style="position: relative; bottom: 2px; left: 4px; font-size: 24px; color: #00b700;"></span>\n                            <input type="checkbox" class="js-filter-checkBox">\n                        </div>\n                        <div class="headerFilterButton js-select-waiting-val2  js-tab-filters clickable hidden-print js-select-filter"\n                             name="select-waiting-val2"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('waitingFor.val2') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-check mdiHeaderIconButton"\n                                 style="position: relative;  bottom: 2px; left: 2px; font-size: 24px; ">\n                            </span>\n                            <input type="checkbox" class="js-filter-checkBox">\n                            <span class="mdi mdi-check mdiHeaderIconButton"\n                                 style="position: relative;  bottom: 58px; left: 7px; font-size: 24px;  color: #00b700;">\n                            </span>\n                        </div>\n                        <div class="headerFilterButton js-select-waiting-expert js-tab-filters clickable hidden-print js-select-filter"\n                             name="select-waiting-expert"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('waitingFor.expert') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-school mdiHeaderIconButton"\n                                 style="position: relative;  bottom: 2px; left: 4px; font-size: 24px; color: #1c3bc1;"></span>\n                            <input type="checkbox" class="js-filter-checkBox">\n                        </div>\n                    </div>\n                </div>\n                <!-- reset filters -->\n                <div class="clickable hidden-print mdi mdi-filter-remove js-reset-filters"\n                     style=""\n                     title="' +
((__t = ( _.i18n('reset.filters') )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n        <div class="pcr-runs-cards"\n             style="width: inherit; height: 520px; overflow-y: scroll; padding: 0; margin: 0;"></div>\n    </div>\n</div>';

}
return __p
};});

