/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminMain',[
  'module',
  'logger',
  'superAdminMainRouter'
], function(
  module,
  Logger
) {
	'use strict';

  module.exports = {
      start: function () {
          Logger.log(module, 'starting superAdminModule');
      },

      stop: function () {
          Logger.log(module, 'stopping superAdminModule');
      }
  };
});
