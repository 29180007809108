
define('template!menuCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n\n    <div class="row">\n        <div>\n            <div class="col-xs-2 dataLabelContainer">\n                <div class="form-group" data-field-name="code">\n                    <label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</label>\n                </div>\n            </div>\n            <div class="col-xs-3">\n                <div class="form-group" data-field-name="code">\n                    <input type="text" id="code"\n                           class="form-control floating-label js-info-input js-custom-input js-code dataInput200 inputBackground"\n                           data-field-name="code" placeholder=""\n                           value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                           data-placement="top" autocomplete="off">\n\n                </div>\n            </div>\n            <div class="col-xs-3">\n                <div class="form-group" data-field-name="name">\n                    <input type="text" id="name"\n                           class="form-control floating-label js-info-input js-custom-input dataInput300 inputBackground"\n                           data-field-name="name" placeholder=""\n                           value="' +
((__t = ( name )) == null ? '' : __t) +
'" autocomplete="off">\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-2 dataLabelContainer">\n            <div class="form-group" data-field-name="sortSeq">\n                <label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.seq') )) == null ? '' : __t) +
'</label>\n            </div>\n        </div>\n        <div class="col-xs-1">\n            <div class="form-group" data-field-name="sortSeq">\n                <input class="js-info-input js-custom-input dataNameIdInput inputBackground" data-field-name="sortSeq"\n                       type="text" value="' +
((__t = (sortSeq)) == null ? '' : __t) +
'" maxlength="3" size="3"/>\n            </div>\n        </div>\n    </div>\n\n    <div class="row">\n        <div class="col-xs-2 dataLabelContainer">\n            <div class="form-group" data-field-name="description">\n                <label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</label>\n            </div>\n        </div>\n        <div class="col-xs-10">\n            <div class="form-group" data-field-name="description">\n\t\t\t\t\t\t<textarea class="form-control floating-label js-info-input js-custom-input inputBackground"\n                                  rows="3" cols="89" wrap="on" data-field-name="description"\n                                  placeholder="" style="resize: vertical;">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n            </div>\n        </div>\n    </div>\n    <div class="row m-t-10">\n        <div class="col-xs-2 dataLabelContainer">\n            <div class="form-group" data-field-name="">\n                <label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.menuIcon') )) == null ? '' : __t) +
'</label>\n            </div>\n        </div>\n        <div class="col-xs-3">\n            <input type="file"\n                   style="' +
((__t = ( imageLinkEncodedValue?'color:transparent':'')) == null ? '' : __t) +
'"\n                   class="js-upload-icon-link" data-icon="true"\n                   accept=".png,.jpg,.jpeg"/>\n        </div>\n        <div class="col-xs-5 js-icon-container"></div>\n    </div>\n    <div class="row m-t-10">\n        <div class="col-xs-2 dataLabelContainer">\n            <div class="form-group" data-field-name="">\n                <label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.imageLink') )) == null ? '' : __t) +
'</label>\n            </div>\n        </div>\n        <div class="col-xs-3">\n            <input type="file"\n                   style="' +
((__t = ( iconEncodedValue?'color:transparent':'')) == null ? '' : __t) +
'"\n                   class="js-upload-image-link" data-icon="true"\n                   accept=".png,.jpg,.jpeg"/>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-2 dataLabelContainer"></div>\n        <div class="col-xs-8 js-image-container"></div>\n    </div>\n    <button class="js-add-menu-element btn btn-info">' +
((__t = ( _.i18n('menu.addmenuelement') )) == null ? '' : __t) +
'</button>\n\n    <div style="width:100%;display: flex;">\n        <div style="width:15%;">\n            ' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'\n        </div>\n        <div style="width:17%;">\n            ' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'\n        </div>\n        <div style="width:3%;">\n            ' +
((__t = ( _.i18n('common.seq') )) == null ? '' : __t) +
'\n        </div>\n        <div style="width:15%;">\n            ' +
((__t = ( _.i18n('common.link') )) == null ? '' : __t) +
'\n        </div>\n        <div style="width:25%;">\n            ' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'\n        </div>\n\n    </div>\n    <div class="menuElementsTable"></div>\n    <div class="row cancelConfirmRow">\n        <div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n            <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"\n                    style="float: right;"></button>\n            <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"\n                    style="float: right;"></button>\n        </div>\n    </div>\n</div>';

}
return __p
};});

