
define('template!dynamicCreateEditListItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-w-all col-w-100 d-b p-l-5 p-r-5 m-b-3">\n    <div class="col-w-all col-w-100 d-f js-itemViews">\n        ';
 if(config.editButton) { ;
__p += '\n        <div class="col-w-all " style="width: 24px; line-height: 24px;">\n                \t<span class="mdi mdi-delete delete-row clickable"\n                          data-row-id="' +
((__t = ( model.secId )) == null ? '' : __t) +
'"\n                    ></span>\n        </div>\n        ';
 } ;
__p += '\n        ';
 _.each(fields, function(field) { ;
__p += '\n        <div class="col-w-all ' +
((__t = ( field.param.display.class )) == null ? '' : __t) +
' js-itemView-' +
((__t = ( field.fieldName )) == null ? '' : __t) +
'"\n             style="' +
((__t = ( field.param.display.css )) == null ? '' : __t) +
'"></div>\n        ';
 }) ;
__p += '\n    </div>\n</div>';

}
return __p
};});

