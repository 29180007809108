
define('template!dynamicList', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n    <div class="header-left">\n        ';
 if(config.creatable) { ;
__p += '\n        <div class="js-showViewNew clickable">\n                <span id="' +
((__t = ( uniqueId['menuAddListButton'] )) == null ? '' : __t) +
'" class="mdi mdi-plus-circle action header"\n                      title="' +
((__t = ( _.i18n('add.new.record') )) == null ? '' : __t) +
'">\n                </span>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(config.importable) { ;
__p += '\n        <label for="import-file">\n                    <span class="mdi mdi-arrow-up-bold-circle action header clickable"\n                          id="' +
((__t = ( uniqueId['menuImportListButton'] )) == null ? '' : __t) +
'"\n                          title="' +
((__t = ( _.i18n('import.record') )) == null ? '' : __t) +
'">\n                            <input type="file"\n                                   id="import-file"\n                                   class="hidden js-import" data-icon="true"\n                                   accept="' +
((__t = ( accept )) == null ? '' : __t) +
'"/>\n                        </span>\n        </label>\n        ';
 } ;
__p += '\n        <div class="js-custom"></div>\n    </div>\n    <div class="header-center">\n        <h1>' +
((__t = ( _.i18n('menu.admin.'+entityName) )) == null ? '' : __t) +
'</h1>\n    </div>\n\n    <div class="header-right">\n        ';
 if(config.selectArchivable) { ;
__p += '\n        <div class="selectArchived form-group">\n            <label for="selectArchive">' +
((__t = ( _.i18n('list.archive.title') )) == null ? '' : __t) +
'</label>\n            <select class="form-control display-archived" id="selectArchive">\n                <option value="WITHOUT">' +
((__t = ( _.i18n('list.archive.without') )) == null ? '' : __t) +
'\n                </option>\n                <option value="ONLY">' +
((__t = ( _.i18n('list.archive.only') )) == null ? '' : __t) +
'\n                </option>\n                <option value="ALL">' +
((__t = ( _.i18n('list.archive.all') )) == null ? '' : __t) +
'\n                </option>\n            </select>\n        </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n<div class="' +
((__t = ( entityName )) == null ? '' : __t) +
'-grid">\n    <div class="js-grid-upper-menu noWrapHidden hidden">\n        ';
 if(config.selectable) { ;
__p += '\n        <span class="mdi mdi-checkbox-blank-outline js-all-rows-selector js-grid-upper-menu-icon"></span>\n        ';
 if(config.exportable) { ;
__p += '\n        <span class="mdi mdi-export-variant js-all-rows-export js-grid-upper-menu-icon"></span>\n        ';
 } ;
__p += '\n        ';
 if(config.deletable) { ;
__p += '\n        <span class="mdi mdi-delete-outline js-delete-by-list js-grid-upper-menu-icon"></span>\n        ';
 } ;
__p += '\n        ';
 if(config.archivable) { ;
__p += '\n        <span class="mdi mdi-archive-arrow-down-outline js-archive-by-list js-grid-upper-menu-icon"></span>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n    </div>\n    <table id="' +
((__t = ( uniqueId['jq-grid-list'] )) == null ? '' : __t) +
'" class="jq-grid-list ' +
((__t = ( entityName )) == null ? '' : __t) +
'-table"\n           role="grid"></table>\n    <div id="' +
((__t = ( uniqueId['jq-grid-pager-list'] )) == null ? '' : __t) +
'" class="jq-grid-pager-list"></div>\n</div>';

}
return __p
};});

