define('dynamicCreateEditTabItem',['module',
		'marionette',
		'dialogFormView',
		'underscore'
	],
	function (module,
			  Marionette,
			  DialogFormView,
			  _
	) {
		'use strict';

		module.exports = DialogFormView.extend({
			getTemplate: function () {
				var name = this.model.get('code') ? this.model.get('code') : _.i18n('common.notDefined');
				return _.template('<a>' + name + '</a>');
			},

			events: {
				'click': 'onSelectedTab'
			},

			modelEvents: {
				'change': 'render'
			},

			tagName: 'li',
			className: function () {
				return 'nav-tab js-collection-nav-tab' + (this.model.cid === this.options.currentTabId ? ' active' : ' clickable');
			},
			id: function () {
				return this.model.cid;
			},
			onSelectedTab: function () {
				if (this.el.className.includes('clickable')) {
					this.trigger('item:changeTab');
				}
			},
			onShowDetails: function () {
				this.model.getEntity().showDetails({model: this.model});
			}
		});
	});
