define('entities/caccounts/routingaction',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational',
    'entities/caccounts/routingaction_routinggroup',
    'entities/caccounts/routingaction_state'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.RoutingAction = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/routingaction/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'type': null,
            'refreshAnalyzes': false,
            'plateSize': null,
            'sequence': null,
            'routingGroups': [],
            'states': [{
                'state': 'SAMPLECLASS_NOT_FOUND',
                'sequence': 0
            }, {
                'state': 'NO_DATASOURCE',
                'sequence': 1
            }, {
                'state': 'SAMPLE_NOT_FOUND',
                'sequence': 2
            }, {
                'state': 'SAMPLE_ALREADY_REGISTERED',
                'sequence': 3
            }, {
                'state': 'SAMPLE_NOT_REGISTERED',
                'sequence': 4
            }, {
                'state': 'ERROR',
                'sequence': 5
            }, {
                'state': 'SAMPLE_WITHOUT_LISANA',
                'sequence': 6
            }, {
                'state': 'MULITPLE_SAMPLES_SAME_ORDER',
                'sequence': 7
            }, {
                'state': 'NO_ROUTE',
                'sequence': 8
            }
            ]
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'routingGroups',
            relatedModel: 'RoutingActionRoutingGroup',
            collectionType: 'RoutingActionRoutingGroupCollection',
            reverseRelation: {
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'states',
            relatedModel: 'RoutingActionState',
            collectionType: 'RoutingActionStateCollection',
            reverseRelation: {
                includeInJSON: false
            }
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/routingaction');
        },

        save: function () {
            this.url = this.postUrl();
            Backbone.Model.prototype.save.call(this).done(_.bind(function () {
                this.get('routingGroups').sort();
            }, this));
        },

        jsonObjectName: 'RoutingActionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'RoutingAction',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.RoutingActionCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/routingaction/');
        },
        model: app.ns.RoutingAction
    });

    module.exports = _.defaults({
        getName: function () {
            return 'routingAction';
        },
        getUrl: function () {
            return 'v2/routingaction';
        },
        getDynamicJson: function () {
            return 'RoutingActionJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('routingAction.type'),
                name: 'type',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 30
            }, {
                label: _.i18n('routingAction.refreshAnalyzes'),
                name: 'refreshAnalyzes',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                width: 10
            }, {
                label: _.i18n('routingAction.sequence'),
                name: 'sequence',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 15
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.RoutingAction.findOrCreate(modelJson);
        },
        getJqGridSidx: function () {
            return 'sequence';
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return new app.ns.RoutingActionCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        },
        findRoutingAction: function (params) {
            if (!params) {
                params = {};
            }
            params.sidx = 'sequence';
            var url = Settings.url('compuzz', 'v2/routingaction', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (response) {
                    defer.resolve(new app.ns.RoutingActionCollection(response));
                }
            });

            return defer.promise();
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push({
                'field': 'states',
                'param': {
                    'filter': [{
                        attributeChange: 'sampleRegistration',
                        values: [
                            {
                                value: false,
                                filter: {attribute: 'state', value: ['SAMPLE_NOT_REGISTERED', 'ERROR', 'NO_ROUTE']}
                            },
                            {
                                value: true,
                                filter: {
                                    attribute: 'state',
                                    value: ['SAMPLECLASS_NOT_FOUND', 'NO_DATASOURCE', 'SAMPLE_NOT_FOUND', 'SAMPLE_ALREADY_REGISTERED', 'SAMPLE_WITHOUT_LISANA', 'MULITPLE_SAMPLES_SAME_ORDER', 'ERROR', 'NO_ROUTE']
                                }
                            }]
                    }]
                }
            });
            custom.push({
                'field': 'plateSize',
                'param': {
                    'type:': 'STRING',
                    'display': {'class': 'col-xs-1'}
                },
                'extra': {
                    'type': 'LABEL',
                    'display': {'class': 'col-xs-7'}
                }
            });
            custom.push(
                {
                    'field': 'type', 'param': {
                        'type': 'CODELIST', 'code': 'ROUTACT_TYP', 'display': {'class': 'col-xs-3'}
                    }
                }
            );
            return custom;
        }
    }, Dynamic);
});
