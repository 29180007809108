define('dashboardMainRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'wellUtils',
    'cssUtils',
    'underscore',
    'contentShowController',
    'itemPagesNameMixin',
    'entities/caccounts/setting',
    'entities/caccounts/pcrruns',
    'entities/caccounts/prepruns'
], function (
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    WellUtils,
    CssUtils,
    _,
    ContentShowController,
    ItemPagesNameMixin
) {
    'use strict';

    var DashboardRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'dashboard/test': 'showView'
        }
    });

    var API = {

        checkContent: function () {
            var defer = $.Deferred();

            require([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        },

        showView: function () {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            require([
                'dashboardShowController'
            ], function (ShowController) {
                ContentShowController.showContent().done(function () {
                    App.startSubModule('dashboardMain');
                    ShowController.showRuns();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.DASHBOARD);
                });
            });
        }
    };

    App.on('runs:show', function () {
        App.navigate('runs');
        API.showView();
    });

    App.on('before:start', function () {
        new DashboardRouter({
            controller: API
        });
    });

    // App.on('run:refresh', _.bind(function (runSecId, callback) {
    //     $('.js-global-loader').show();
    //     $.when(API.findModel(runSecId, true, callback)).done(_.bind(function () {
    //         $('.js-global-loader').hide();
    //     }));
    // }));
    //
    // App.on('run:refresh:model', _.bind(function (model, callback) {
    //     API.setModel(model, callback);
    // }));

    module.exports = DashboardRouter;
});
