define('arrayColorView',[
    'module',
    'dialogFormView',
    'underscore'
], function (
    module,
    DialogFormView,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template('<div class="js-colorPicker input-group colorpicker-component <%= readOnly %>"><span class="input-group-addon" style="border: 1px solid #80808066; padding: 5px 5px 5px 5px;"><i></i></span>' +
            '<input type="text" value="<%= value %>" class="form-control js-info-input" data-field-name="value" /></div>'),

        ui: {
            colorPicker: '.js-colorPicker',
            input: '.js-info-input'
        },

        events: {
            'change @ui.input': 'onInputChange'
        },

        modelEvents: {
            'change:value': 'triggerChange'
        },

        initialize: function () {
            if (this.model.get('value') && this.model.get('value').length === 7) {
                this.model.set('value', this.model.get('value') + 'ff');
            }
        },

        serializeData: function () {
            return {
                value: this.model.get('value'),
                readOnly: this.options.readOnly ? 'disabled' : 'clickable'
            };
        },
        onShow: function () {
            this.ui.colorPicker.colorpicker({
                color: this.model.get('value'),
                format: 'hex'
            });
        },
        triggerChange: function () {
            this.trigger('change');
        }
    });
});
