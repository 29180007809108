
define('template!dynamicCreateEditCustomComment', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group" style="display: flex;">\n    <div class="p-r-2 p-l-2" style="display:flex; flex: 2;">\n        <span class="mdi mdi-note"></span>\n        <textarea class="form-control js-info-input inputBackground"\n                  rows="5" cols="88" wrap="on" data-field-name="comment" id="comment"\n                  placeholder="' +
((__t = ( _.i18n('comment') )) == null ? '' : __t) +
'">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n    </div>\n    <div class="p-r-2 p-l-2" style="width: 200px; display: flex; flex-direction: column; justify-content: center;">\n        <div style="display: flex; flex-direction: row; align-items: center;">\n            <span class="mdi mdi-clock-start" title="' +
((__t = ( _.i18n('created') )) == null ? '' : __t) +
'"></span>\n            <span id="created" style="display: flex; align-items: center;height: 100%;">' +
((__t = ( created )) == null ? '' : __t) +
'<br/>(' +
((__t = ( createdBy )) == null ? '' : __t) +
')</span>\n        </div>\n        <div class="m-t-5" style="display: flex; flex-direction: row;">\n            <span class="mdi mdi-clock-end" title="' +
((__t = ( _.i18n('updated') )) == null ? '' : __t) +
'"></span>\n            <span id="updated" style="display: flex; align-items: center;height: 100%;">' +
((__t = ( updated )) == null ? '' : __t) +
'<br/>(' +
((__t = ( updatedBy )) == null ? '' : __t) +
')</span>\n        </div>\n    </div>\n</div>';

}
return __p
};});

