define('colorCompensationMatriceValueColumnItem',[
    'module',
    'marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        className: 'rounded-5 t-a-r',
        attributes: function () {
            var style = this.options.result ? 'width: 60px; font-size: 14px;' : 'width: 40px; font-size: 10px;';
            var ret = {style: style};
            if (this.model.get('x') === this.model.get('y')) {
                ret.style += 'background-color: var(--css-theme-image-filter-color-02);';
            }
            return ret;
        },
        template: _.template('<%= value %>'),
        serializeData: function () {
            return {value: this.model.get('value')};
        }

    });
});
