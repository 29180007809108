/**
 * Created by OLD on 20/08/2015.
 */
define('dateUtils',[
	'module',
	'moment'
], function (module, moment) {
	'use strict';

	module.exports = {
		isValidDateFormatString: function (inputValue, settings) {
			var format = 'DD/MM/YYYY';
			if (settings && settings.DATEFMT &&
				settings.DATEFMT.split(';') &&
				settings.DATEFMT.split(';').length > 0 &&
				settings.DATEFMT.split(';')[0] !== '' &&
				settings.DATEFMT.split(';')[0] !== null &&
				settings.DATEFMT.split(';')[0] !== undefined) {
				format = settings.DATEFMT.split(';')[0];
			}

			if (moment(inputValue, format, true).isValid()) {
				return true;
			}
			return false;
		},

		toDateFormatStringFromSettings: function (inputValue, settings) {
			if (inputValue === '' || inputValue === null || inputValue === undefined) {
				console.error('invalid date or missing');
				return '';
			}
			var format = 'DD/MM/YYYY';
			if (settings && settings.DATEFMT &&
				settings.DATEFMT.split(';') &&
				settings.DATEFMT.split(';').length > 0 &&
				settings.DATEFMT.split(';')[0] !== '' &&
				settings.DATEFMT.split(';')[0] !== null &&
				settings.DATEFMT.split(';')[0] !== undefined) {
				format = settings.DATEFMT.split(';')[0];
			}
			return moment(inputValue).format(format);
		},

		toDateTimeFormatStringFromSettings: function (inputValue, settings) {
			var format = 'DD/MM/YYYY HH:mm:ss';
			if (settings && settings.DATEFMT &&
				settings.DATEFMT.split(';') &&
				settings.DATEFMT.split(';').length > 3 &&
				settings.DATEFMT.split(';')[3] !== '' &&
				settings.DATEFMT.split(';')[3] !== null &&
				settings.DATEFMT.split(';')[3] !== undefined) {
				format = settings.DATEFMT.split(';')[3];
			}
			return moment(inputValue).format(format);
		},

		toMDYFormat: function (inputValue) {
			var format = new RegExp('^(0?[1-9]|[12][0-9]|3[01])/' +
					'(0?[1-9]|1[012])/((19|20)\\d\\d)$'),
				dateArr,
				formattedDate;

			if (format.test(inputValue)) {
				dateArr = inputValue.split('/');
				formattedDate = dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
			}

			return formattedDate ? formattedDate : undefined;
		},

		toLocaleFormat: function (inputValue) {
			var date = new Date(inputValue),
				localeDate = date.toLocaleString();

			return localeDate && localeDate !== 'Invalid Date' ?
				localeDate : undefined;
		},

		toDateFormatString: function (inputValue) {
			return moment(inputValue).format('DD/MM/YYYY');
		},

		toMDYHHMMFormat: function (inputValue) {
			var date = this.toDateFormatString(inputValue),
				time = this.toTimeFormatString(inputValue);

			return date + ' ' + time;
		},

		toMonthDateFormat: function (inputValue) {
			var date = new Date(inputValue),
				getMonth = date.getMonth() + 1,
				day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
				month = getMonth < 10 ? '0' + getMonth : getMonth;

			return day + '/' + month + '/' + date.getFullYear();
		},

		toDateTimeFormatString: function (inputValue) {
			return moment(inputValue).format('DD/MM/YYYY HH:mm');
		},

		toDateTimeShortYearFormatString: function (inputValue) {
			return moment(inputValue).format('DD/MM/YY HH:mm');
		},

		toDateTimeSecondeFormatString: function (inputValue) {
			return moment(inputValue).format('DD/MM/YYYY HH:mm:ss');
		},

		// hh:mm
		toTimeFormatString: function (inputValue) {
			return moment(inputValue).format('HH:mm');
		},

		getDateFormat: function () {
			return 'dd/mm/yy';
		},

		toMillisecondsFormat: function (inputValue) {
			var date = new Date(this.toMDYFormat(inputValue));
			return date.getTime() + '';
		},

		toHHMM: function (time, format) {
			var timeJson = format === 'seconds' ? moment.duration(time, 'seconds').toJSON() : moment.duration(time).toJSON(),
				pt = timeJson.split('PT'),
				ptH, ptM, h, m;

			if (timeJson.indexOf('H') !== -1) {
				ptH = pt[1].split('H');
				h = ptH[0];
				if (timeJson.indexOf('M') !== -1) {
					ptM = ptH[1].split('M');
					m = ptM[0];
					m = (m < 10) ? '0' + m : m;
				} else {
					m = '00';
				}
			} else {
				h = '0';
				if (timeJson.indexOf('M') !== -1) {
					ptM = pt[1].split('M');
					m = ptM[0];
					m = (m < 10) ? '0' + m : m;
				} else {
					m = '00';
				}
			}
			return h + ':' + m;
		},

		getDayName: function (value) {
			return new Date(value).getDay();
		}
	};
});

