/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemTextArea',[
	'module',
	'underscore',
	'dialogFormView'
], function (
	module,
	_,
	DialogFormView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: _.template('<%= value %>'),
		tagName: 'textarea',
		className: 'form-control js-info-input-child inputBackground',
		attributes: function () {
			return {
				'rows': 1,
				'data-field-name': this.options.field.field,
				'style': 'resize: vertical;',
				'disabled': this.options.field.param && this.options.field.param.readOnly,
				'title': this.model.get(this.options.field.field)
			};
		},
		serializeData: function () {
			var templateData = {};
			var model = this.model;
			if (this.options.field.dependsOn) {
				model = this.options.field.dependsOn.model.get(this.options.field.dependsOn.field);
			}
			templateData.value = model.get(this.options.field.field);
			return templateData;
		}
	});
});
