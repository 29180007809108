define('runCreatePcrRunFromPlateView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!runCreatePcrRunFromPlateTpl',
    'savingBehavior',
    'bootbox',
    'underscore',
    'jquery',
    'app',
    'settings',
    'autocompleteView',
    'moment',
    'entities/caccounts/fileFormatPlateLayouts',
    'entities/caccounts/pcrruns',
    'entities/createRunFromTemplate'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    Tpl,
    SavingBehavior,
    bootbox,
    _,
    $,
    App,
    Settings,
    AutocompleteView,
    moment,
    FileFormatPlateLayouts,
    PcrRuns
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            inputName: '.run-input-name',
            blockLoading: '.empty-page-loader'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm'
        },

        regions: {
            selectFileFormat: '.js-select-fileFormat-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'name', type: 'required'}
        ],

        onCancel: function () {
            this.box.modal('hide');
        },

        initialize: function (options) {
            this.model = options.model;
            this.currentMode = 'fromCyclerExport';
            this.generateStarterSheet = false;
        },

        serializeData: function () {
            this.model.set('numberOfRun', 1);
            this.model.set('selectedDate', Number(moment().format('x')));
            this.model.set('currentDate', moment().format('DD-MM-YYYY'));
            this.model.set('generateStarterSheet', false);
            return this.model.toJSON();
        },

        onShow: function () {
            $.material.init();
            this.getRegion('selectFileFormat').show(new AutocompleteView(
                this._getAutocompleteOptionObject(FileFormatPlateLayouts.getAutocompleteParamForImport({
                    modelProperty: 'refFileFormat',
                    callBackOnChange: this.onChangeFileFormat
                }))
            ));

            this.triggerMethod('enable:cancel:confirm');
        },

        onChangeFileFormat: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.fileFormatSecId = model.get('secId');
        },

        onConfirm: function () {
            this.hide();
        },

        onSave: function (model) {
            var modelJSON = model.toJSON();
            this.model.set('wells', this.wells);
            this.options.runModel.set(modelJSON);
            this.triggerMethod('saved');
            App.trigger('run:header:rerender', modelJSON);
            App.navigate('runs/pcr/' + model.get('secId') + '/edit', {trigger: true});
            if (this.generateStarterSheet) {
                PcrRuns.generateRunSheet(model.get('secId'));
            }
            this.hide();
        },

        hide: function () {
            this.triggerMethod('hide');
            this.destroy();
            this.box.modal('hide');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        }
    });
});
