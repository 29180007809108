
define('template!extractionMethodsReagentsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-12 p-l-0 p-r-0">\n    <div class="col-xs-12 p-l-0 p-r-0" style="display: flex; text-align: left;">\n        <div class="noWrapHidden" style="width: 15%;">' +
((__t = ( _.i18n('extraction.reagent.reagentType') )) == null ? '' : __t) +
'</div>\n        <div class="noWrapHidden" style="width: 15%;">' +
((__t = ( _.i18n('extraction.reagent.kit') )) == null ? '' : __t) +
'</div>\n        <div class="noWrapHidden" style="width: 30%;">' +
((__t = ( _.i18n('extraction.reagent.litlot') )) == null ? '' : __t) +
'</div>\n        <div class="noWrapHidden" style="width: 10%;">' +
((__t = ( _.i18n('extraction.reagent.qty') )) == null ? '' : __t) +
'</div>\n        <div class="noWrapHidden" style="width: 8%;">' +
((__t = ( _.i18n('extraction.reagent.vol.test') )) == null ? '' : __t) +
'</div>\n        <div class="noWrapHidden" style="width: 8%;">' +
((__t = ( _.i18n('extraction.reagent.vol.death') )) == null ? '' : __t) +
'</div>\n        <div class="noWrapHidden" style="width: 14%;">' +
((__t = ( _.i18n('extraction.reagent.vol.tot') )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 _.each(reagents, function(reagent) { ;
__p += '\n    <div class="col-xs-12 p-l-0 p-r-0" style="display: flex; text-align: left;">\n        <div class="p-l-5 noWrapHidden" style="border: 1px solid #333333; background-color: white; width: 15%;">' +
((__t = (
            reagent.reagent ? reagent.reagent.code : '' )) == null ? '' : __t) +
'\n        </div>\n        <div class="p-l-5 noWrapHidden" style="border: 1px solid #333333; background-color: white; width: 15%;">' +
((__t = (
            reagent.refKit ? reagent.refKit.code : '' )) == null ? '' : __t) +
'\n        </div>\n        <div class="p-l-5 noWrapHidden" style="border: 1px solid #333333; background-color: white; width: 30%;">' +
((__t = (
            reagent.refKit.refCurrentLot && reagent.refKit.refCurrentLot.code ?
            reagent.refKit.refCurrentLot.code : '' )) == null ? '' : __t) +
'\n        </div>\n        <div class="p-l-5 noWrapHidden" style="border: 1px solid #333333; background-color: white; width: 10%;">' +
((__t = (
            wellsNumber )) == null ? '' : __t) +
'\n        </div>\n        <div class="p-l-5 noWrapHidden" style="border: 1px solid #333333; background-color: white; width: 8%;">' +
((__t = (
            reagent.volumeByTest )) == null ? '' : __t) +
'\n        </div>\n        <div class="p-l-5 noWrapHidden" style="border: 1px solid #333333; background-color: white; width: 8%;">' +
((__t = (
            reagent.volumeDeath )) == null ? '' : __t) +
'\n        </div>\n        <div class="p-l-5 noWrapHidden" style="border: 1px solid #333333; background-color: white; width: 14%;">' +
((__t = (
            (wellsNumber*reagent.volumeByTest) + reagent.volumeDeath )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    ';
 }); ;
__p += '\n</div>';

}
return __p
};});

