define('entities/caccounts/lmbcyclers',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/admin/cyclers',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.LmbCycler = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/lmbcyclers/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'description': '',
            'serialNb': '',
            'location': '',
            'refCycler': {}
        },
        idAttribute: 'secId',
        elations: [{
            type: Backbone.HasOne,
            key: 'refCycler',
            relatedModel: 'Cycler'
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/lmbcyclers');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'LmbCyclerJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LmbCycler',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.LmbCyclerCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/lmbcyclers');
        },
        model: app.ns.LmbCycler
    });

    module.exports = _.defaults({
        getName: function () {
            return 'lmbcycler';
        },
        getUrl: function () {
            return 'v2/lmbcyclers';
        },
        getDynamicJson: function () {
            return 'LmbCyclerJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lmbCycler.refCycler'),
                name: 'refCycler.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refCycler.code',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lmbCycler.serialNb'),
                name: 'serialNb',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'serialNb',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('lmbCycler.location'),
                name: 'location',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'location',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.LmbCycler.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['location', 'serialNb']};
        },

        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push({field: 'code', param: {type: 'STRING', display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({'field': 'serialNb', 'param': {'type': 'STRING', 'display': {'class': 'col-xs-2'}}});
            custom.push({'field': 'location', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-xs-8', rows: 2}}});
            custom.push({'field': 'refCycler', 'param': {'type': 'REFERENCE', 'display': {'class': 'col-xs-2', 'readOnly': true}}});
            return custom;
        },

        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'serialNb', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'location', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-xs-4'}}},
                {'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-xs-4'}}}
            ];
        },

        getAutocompleteParamWithColorCompensation: function (config, context) {
            var configuration = Dynamic.getAutocompleteParam(config, context);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            configuration.urlPath = context.getUrl() + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code',
                withColorCompensation: true
            }));
            return configuration;
        }
    }, Dynamic);
});
