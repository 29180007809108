
define('template!assayResultCollectionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12 m-t-5 m-b-5 rounded">\n    <div class="header">\n        <span class="col-w-2 mdi mdi-plus-circle action clickable js-add-result"></span>\n\n        <div class="col-w-3" style="margin: 0 0 0 0; padding: 0 2px 0 2px;">' +
((__t = ( _.i18n('assayresult.sequence.title') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-9 "><!--p-l-1-->\n            ' +
((__t = ( _.i18n('assay.kitp.title.target') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-1" style="margin: 0 0 0 0;"><!--ic-->\n            ' +
((__t = ( _.i18n('assay.kitp.title.ic') )) == null ? '' : __t) +
'\n        </div>\n\n        <div class="col-w-45">\n            <div class="col-w-100 col-w-all d-f">\n                <div class="col-w-22 p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.algo.res') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-78 d-f">\n                    <div class="col-w-27 p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.algo.dye') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="col-w-73 p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.algo.param') )) == null ? '' : __t) +
'\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="col-w-1"></div>\n\n        <div class="col-w-5">' +
((__t = ( _.i18n('assay.kitp.title.validation.algo') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-10">' +
((__t = ( _.i18n('assay.kitp.title.validation.param') )) == null ? '' : __t) +
'\n        </div>\n\n        <div class="col-w-1" style="margin: 0 0 0 0; padding: 0 2px 0 2px;">' +
((__t = (
            _.i18n('assayresult.sequenceDisplayGroup.title') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-3" style="margin: 0 0 0 0; padding: 0 2px 0 2px;">' +
((__t = (
            _.i18n('assayresult.sequenceValidation.title') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-3" style="margin: 0 0 0 0; padding: 0 2px 0 2px;">' +
((__t = (
            _.i18n('assayresult.sequenceDisplay.title') )) == null ? '' : __t) +
'\n        </div>\n\n        <div class="col-w-1"></div>\n\n        <div class="col-w-6">' +
((__t = ( _.i18n('assay.kitp.title.algotexport') )) == null ? '' : __t) +
'</div>\n        <div class="col-w-9">' +
((__t = ( _.i18n('assay.kitp.title.export.params') )) == null ? '' : __t) +
'</div>\n    </div>\n\n    <!-- liaison results - table -->\n    <div class="js-assayResult-collection"></div>\n</div>';

}
return __p
};});

