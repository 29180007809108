define('entities/trash',[
    'app',
    'backbone',
    'settings'
], function (
    App,
    Backbone,
    Settings
) {
    'use strict';

    // var Trash = Backbone.Model.extend({
    //     fetch: function () {
    //     },
    //     getImageUid: function () {
    //     },
    //     postUrl: function () {
    //     },
    //     save: function () {
    //     }
    // });

    var API = {
        getTrashCollectionUrl: function () {
            return Settings.url('compuzz', 'v2/trash');
        },

        getTrashCountUrl: function () {
            return 'v2/trash/rowCount';
        },

        downloadTrashByFileName: function (fileName) {
            var url = Settings.url('compuzz', 'v2/trash/download/' + fileName),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });

            return defer.promise();
        }
    };

    App.reqres.setHandler('trash:get-collection-url', function () {
        return API.getTrashCollectionUrl();
    });

    App.reqres.setHandler('trash:get-count-url', function () {
        return API.getTrashCountUrl();
    });

    App.reqres.setHandler('trash:download-by-filename', function (fileName) {
        return API.downloadTrashByFileName(fileName);
    });
});
