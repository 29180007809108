define('exportResultByLis',[
	'module',
	'backbone',
	'marionette',
	'exportResultByConfigurationType',
	'template!exportResultByLisTpl'
], function (
	module,
	Backbone,
	Marionette,
	ItemView,
	Tpl
) {
	'use strict';

	module.exports = Marionette.CompositeView.extend({

		template: Tpl,
		childViewContainer: '.collection',
		childView: ItemView,
		className: 'row rounded',
		serializeData: function () {
			return {lis: this.model.get('lis').code + ' : ' + this.model.get('lis').name};
		},

		childViewOptions: function () {
			return {
				configurations: this.model.get('lis').configurations
			};
		}
	});
});
