define('prepRunHeaderWellView',[
    'module',
    'marionette',
    'template!prepRunHeaderWellTpl',
    'underscore',
    'jquery',
    'dialogFormView',
    'savingBehavior',
    'app',
    'prepRunWellDetailView',
    'sampleCommentView',
    'prepRunTracsView',
    'entities/caccounts/samples',
    'wellUtils',
    'wellDisplaySampleView',
    'prepRunWellEditSmpIdPlateView',
    'dynamicCreateEditCustomListToList',
    'entities/caccounts/prepwells',
    'entities/caccounts/pcrruns',
    'envHandler',
    'backbone'
], function (
    module,
    Marionette,
    headerWellTpl,
    _,
    $,
    DialogFormView,
    SavingBehavior,
    App,
    PrepWellDetailView,
    EditSampleCommentView,
    PrepRunTracsView,
    Samples,
    WellUtils,
    WellDisplaySampleView,
    CreateEditSmpIdPlateLayoutView,
    DynamicCreateEditCustomListToList,
    PrepWells,
    PcrRuns
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: headerWellTpl,

        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        ui: {
            btnEditWellResult: '.btn-edit-well-result',
            input: '.js-info-input',
            btnNextWell: '.btn-next-well',
            btnPreviousWell: '.btn-previous-well',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            showKitsLots: '.js-show-kitsLots',
            blockLoading: '.empty-page-loader',
            assayPlus: '.assayWaiting .mdi-plus-box-outline',
            assayMinus: '.assayWaiting .mdi-minus-box-outline',
            copyParameters: '.js-copyParameters'
        },

        events: {
            'click .js-show-kitsLots': 'onShowKitsLots',
            'click @ui.btnEditWellResult': 'onEditWellResult',
            'click @ui.btnNextWell': 'onNextWell',
            'click @ui.btnPreviousWell': 'onPreviousWell',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click .js-edit-sampleComment': 'onEditSampleComment',
            'click .js-show-pcrWell-from-prepWell': 'onShowPcrWellFromPrepWell',
            'click .js-show-prepWell': 'onShowPrepWell',
            'click .js-prepWell-tracs-view': 'onShowTracsView',
            'click @ui.assayPlus': 'onAddAssay',
            'click @ui.assayMinus': 'onRemoveAssay'
        },

        modelEvents: {
            'change': 'renderSafe',
            'change:tags': 'saveTags'
        },

        regions: {
            wellDisplaySample: '.js-well-sample',
            tags: '.js-tags'
        },

        onShowTracsView: function () {
            this.pcrRunTracsView = new PrepRunTracsView({
                model: this.model,
                type: 'wellTracks'
            });
            this.pcrRunTracsView.show({
                title: _.i18n('tracs'),
                className: 'runShowTracsPopup'
            });
        },

        onShowPcrWellFromPrepWell: function (e) {
            var $target = $(e.currentTarget);
            var datSecId = $target.attr('data-secId');

            if (this.pcrWellsFromPrep) {
                var pcrWellModel = _.find(this.pcrWellsFromPrep, _.bind(function (obj) {
                    return obj.get('secId') === datSecId;
                }, this));

                if (pcrWellModel) {
                    this.ui.blockLoading.show();

                    var pcrRunModel = PcrRuns.getModel({secId: pcrWellModel.get('runSecId')});
                    pcrRunModel.fetch().done(_.bind(function () {
                        pcrWellModel.set('refPcrRun', pcrRunModel);
                        require(['pcrWellController'], _.bind(function (PcrWellController) {
                            PcrWellController.showDetails(pcrWellModel, null, null);
                        }, this));
                        this.ui.blockLoading.hide();
                    }, this));
                }
            }
        },

        onShowPrepWell: function (e) {
            var $target = $(e.currentTarget);
            var datSecId = $target.attr('data-secId');
            this.ui.blockLoading.show();
            var prepWell = PrepWells.getModel({secId: datSecId});
            prepWell.fetch().done(_.bind(function () {
                this.ui.blockLoading.hide();
                var wellDetailView = new PrepWellDetailView({
                    model: prepWell,
                    readOnly: true
                });
                wellDetailView.show({
                    title: prepWell.get('refPrepRun').get('runName'),
                    className: 'popupWellView'
                });
            }, this));
        },

        onEditSampleComment: function () {
            this.model.get('smpId').fetch().done(_.bind(function () {
                this.editSampleCommentView = new EditSampleCommentView({
                    wellModel: this.model,
                    model: this.model.get('smpId')
                });
                this.editSampleCommentView.show({
                    title: _.i18n('edit.sampleComment'),
                    className: ''
                });
            }, this));
        },

        onEditWellResult: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showDetails(this.model);
            }, this));
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        initialize: function (options) {
            this.channelToDisplay = options.channelToDisplay;
            this.readOnly = options.readOnly;
            this.cSelection = options.cSelection;
            this.pcrWellsFromPrep = this.options.pcrWellsFromPrep;
            this.listenTo(this.model.get('smpId'), 'change', this.renderSafe);
        },

        findPosition: function (array, value, next) {
            var positions = array;
            var nextPosition = false;
            for (var i = 0; i < positions.length; i += 1) {
                if (positions[i] === value) {
                    if (next) {
                        if (i === positions.length - 1) {
                            nextPosition = positions[0];
                        } else {
                            nextPosition = positions[i + 1];
                        }
                    } else {
                        if (i === 0) {
                            nextPosition = positions[positions.length - 1];
                        } else {
                            nextPosition = positions[i - 1];
                        }
                    }
                }
            }
            return nextPosition;
        },

        findPositionAttr: function (array, attr, value, next) {
            this.params = {
                attr: attr,
                value: value,
                next: next,
                retVal: false
            };

            array.each(_.bind(function (object, index, parent) {
                if (object.get(this.params.attr) === this.params.value) {
                    if (this.params.next) {
                        if (index === parent.length - 1) {
                            this.params.retVal = parent[0].get(this.params.attr);
                        } else {
                            this.params.retVal = parent[index + 1].get(this.params.attr);
                        }
                    } else {
                        if (index === 0) {
                            this.params.retVal = parent[parent.length - 1].get(this.params.attr);
                        } else {
                            this.params.retVal = parent[index - 1].get(this.params.attr);
                        }
                    }
                }
            }, this));

            return this.params.retVal;
        },

        onPreviousWell: function () {
            var wellPosition = this.model.get('pos');
            var previousWellPosition, previousWell;
            if (this.cSelection && this.cSelection.length) {
                previousWellPosition = this.findPosition(this.cSelection, wellPosition, false);
            } else {
                previousWellPosition = this.findPositionAttr(this.model.get('refPrepRun').get('wells'), 'pos', wellPosition, false);
            }
            previousWell = this.model.get('refPrepRun').get('wells').findWhere({'pos': previousWellPosition});

            // App.trigger('well:prep:refresh:wellPopover', this.model);
            this.triggerMethod('wellDetail:onModelChange', previousWell);
        },

        onNextWell: function () {
            var wellPosition = this.model.get('pos');
            var nextWellPosition, nextWell;
            if (this.cSelection && this.cSelection.length) {
                nextWellPosition = this.findPosition(this.cSelection, wellPosition, true);
            } else {
                nextWellPosition = this.findPositionAttr(this.model.get('refPrepRun').get('wells'), 'pos', wellPosition, true);
            }
            nextWell = this.model.get('refPrepRun').get('wells').findWhere({'pos': nextWellPosition});

            this.triggerMethod('wellDetail:onModelChange', nextWell);
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                smpType: this.model.get('smpType'),
                pooled: this.model.get('pooled'),
                smpId: this.model.get('smpId') ? this.model.get('smpId').get('code') : '',
                repeatStatus: this.model.get('repeatStatus'),
                dilution: this.model.get('dilution'),
                wellsPooled: this.model.get('wellsPooled').map(function (well) {
                    return {
                        runName: well.get('refPrepRun').get('name'),
                        pos: well.get('pos'),
                        smpId: well.get('smpId') ? well.get('smpId').get('code') : ''
                    };
                }),
                wellsPool: this.model.get('wellsPool').map(function (well) {
                    return {
                        runName: well.get('refPrepRun').get('name'),
                        pos: well.get('pos'),
                        smpId: well.get('smpId') ? well.get('smpId').get('code') : ''
                    };
                })
            };

            templateData.otherWell = null;
            if (_.any(this.model.get('otherPrepWells'), function (well) {
                return well.repeatStatus;
            })) {
                templateData.otherWell = 'repeat';
            }
            if (templateData.otherWell === null && _.any(this.model.get('otherPrepWells'), function (well) {
                return !well.repeatStatus;
            })) {
                templateData.otherWell = 'other';
            }

            templateData.refLmbExtractor = this.model.get('refPrepRun').get('extractionDetails').refLmbExtractor && this.model.get('refPrepRun').get('extractionDetails').refLmbExtractor.code ?
                this.model.get('refPrepRun').get('extractionDetails').refLmbExtractor.code : '-';

            templateData.refExtractionMethod = this.model.get('refPrepRun').get('extractionDetails').refExtractionMethod && this.model.get('refPrepRun').get('extractionDetails').refExtractionMethod.code ?
                this.model.get('refPrepRun').get('extractionDetails').refExtractionMethod.code : '-';

            var pcrWellsFromPrepJson = [];
            _.each(this.pcrWellsFromPrep, _.bind(function (well) {
                var wellRes = well.getWellRes();
                var wellResults = well.getTargetsResultIcons(false);
                var wellJson = {
                    runName: well.get('runName'),
                    pos: well.get('pos'),
                    secId: well.get('secId'),
                    assay: well.get('refAssay') ? well.get('refAssay').get('code') : '-'
                };
                wellJson.wellResNumFormated = wellRes && wellRes.get('quantificationFormatted') ? wellRes.get('quantificationFormatted') : '';
                wellJson.wellResColor = this.getWellResColor(wellRes);
                wellJson.results = wellResults;
                if (wellRes) {
                    wellJson.wellRes = {
                        targetCode: wellRes.get('targetCode'),
                        cts: wellRes.get('cts'),
                        resCode: wellRes.get('resCode'),
                        iconHtml: wellRes.getResultIcon(true).iconHtml
                    };
                }
                pcrWellsFromPrepJson.push(wellJson);
            }, this));

            templateData.pcrWellsFromPrep = pcrWellsFromPrepJson;

            var extractionKitsLots = [];
            _.each(this.model.get('refPrepRun').get('reagentExtractionKitLots'), _.bind(function (extractionKitLot, reagentType) {
                extractionKitsLots.push({
                    lotId: extractionKitLot && extractionKitLot.refExtractionKitLot ? extractionKitLot.refExtractionKitLot.code : '-',
                    code: extractionKitLot && extractionKitLot.refExtractionKit ? extractionKitLot.refExtractionKit.code : '-',
                    reagentType: reagentType
                });
            }, this));
            templateData.extractionKitsLots = extractionKitsLots;

            templateData.concatComment = (this.model.get('smpId').get('lisComment') ? this.model.get('smpId').get('lisComment') : '') + ' - ' + (this.model.get('smpId').get('comment') ? this.model.get('smpId').get('comment') : '');
            if (templateData.concatComment === ' - ') {
                templateData.concatComment = '';
            }

            templateData.pcrKitsLots = [];

            _.each(this.model.get('kitLotByKits'), _.bind(function (item) {

                var kitLots = [];
                var pcrKit = {};

                if (item.refPcrKit && item.refPcrKit.code) {
                    pcrKit.code = item.refPcrKit.code;
                }
                _.each(item.pcrKitLots, _.bind(function (pcrKitLot) {
                    kitLots.push(pcrKitLot.code);
                }, this));

                pcrKit.kitLots = kitLots.join(';');
                templateData.pcrKitsLots.push(pcrKit);
            }, this));

            templateData.assaysToDisplay = WellUtils.getFormattedAssayStatus(this.model, 'wellEditPopup');
            templateData.displayPool = !this.model.get('wellsPool').isEmpty();
            templateData.displayPooled = !this.model.get('wellsPooled').isEmpty();
            return templateData;
        },

        getWellResColor: function (wellRes) {
            var wellResColor;
            if (wellRes && wellRes.get('result')) {
                wellResColor = wellRes.get('result').get('color');
            } else {
                wellResColor = 'transparent';
            }
            return wellResColor;
        },

        onRender: function () {
            $.material.init();
            this.$el.find('[data-toggle="tooltip"]').tooltip();

            var allowPreviousBtn = true;
            var allowNextBtn = true;
            if (this.cSelection && this.cSelection.length > 0) {
                _.each(this.cSelection, _.bind(function (wellPos, i) {
                    if (wellPos === this.model.get('pos')) {
                        if (i === 0) {
                            allowPreviousBtn = false;
                        }
                        if (i === this.cSelection.length - 1) {
                            allowNextBtn = false;
                        }
                    }
                }, this));
            } else {
                this.model.get('refPrepRun').get('wells').each(_.bind(function (well, i) {
                    if (well.get('pos') === this.model.get('pos')) {
                        if (i === 0) {
                            allowPreviousBtn = false;
                        }
                        if (i === this.model.get('refPrepRun').get('wells').length - 1) {
                            allowNextBtn = false;
                        }
                    }
                }, this));
            }
            if (!allowPreviousBtn) {
                var previousBtn = this.$el.find('.css-previous-well');
                previousBtn.removeClass('btn-previous-well');
                previousBtn.removeClass('clickable');
                previousBtn.css('opacity', 0.2);
            }
            if (!allowNextBtn) {
                var nextBtn = this.$el.find('.css-next-well');
                nextBtn.removeClass('btn-next-well');
                nextBtn.removeClass('clickable');
                nextBtn.css('opacity', 0.2);
            }

            var view = new WellDisplaySampleView({
                model: this.model
            });
            this.getRegion('wellDisplaySample').show(view);
            App.trigger('forClipboard', this.ui.copyParameters);
            this.getRegion('tags').show(new DynamicCreateEditCustomListToList({
                'field': 'tags',
                'model': this.model
            }));
        },

        onCancel: function () {
            this.hide();
        },

        hide: function () {
            this.triggerMethod('wellDetail:destroy');
            this.triggerMethod('hide');
        },

        onShowKitsLots: function () {
            this.triggerMethod('wellDetail:showKitsLotsPopup');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
            console.error(response);
        },
        onAddAssay: function () {
            var assays = [];
            _.each(this.model.get('assayStatus'), function (assayFromWell) {
                if (!_.find(assays, function (assay) {
                    return assay.secId === assayFromWell.secIds;
                })) {
                    assays.push(assayFromWell);
                }
            });
            this.editAssay('add-assay', _.i18n('prep.platelayout.assign.assay'), assays);
        },
        onRemoveAssay: function () {
            this.editAssay('clear-assay', _.i18n('prep.platelayout.clear.assay'));
        },
        editAssay: function (mode, title, assays) {
            this.createEditSmpIdPlateView = new CreateEditSmpIdPlateLayoutView({
                model: this.model.get('refPrepRun'),
                type: 'prepRuns',
                mode: mode,
                routingGroup: null,
                assays: assays ? assays : [],
                pos: {from: this.model.get('pos'), to: this.model.get('pos')}
            });
            var className = 'actionPopupCss popupFitContent';

            if (mode === 'all' || mode === 'scan' || mode === 'edit') {
                className = 'popupEnterPlateLayout actionPopupCss popupFitContent';
            }
            if (mode === 'import' || mode === 'export' || mode === 'control' || mode === 'replace-assay' ||
                mode === 'add-assay' || mode === 'clear-assay' || mode === 'generate-smpId' ||
                mode === 'remove-smpId' || mode === 'importFromOtherRun'
            ) {
                className = 'actionPopupCss popupFitContent actionMiniPopupCss';
            }

            this.createEditSmpIdPlateView.show({
                title: title,
                className: className
            });
        },
        saveTags: function () {
            $('.js-global-loader').show();
            this.model.saveDetail().always(function () {
                $('.js-global-loader').hide();
            });
        }
    });
});
