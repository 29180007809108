
define('template!pcrSetup', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="container-fluid height-100 m-l-0 m-r-0 p-l-0 p-r-0">\n    <div class="col-xs-2 p-l-5 p-r-5 js-filter">\n        <div class="rounded filter m-b-20">\n            <h1 style="margin: 10px 0 10px 0;">' +
((__t = ( _.i18n('pcrSetup.filter') )) == null ? '' : __t) +
'</h1>\n        </div>\n        <div class="rounded filter">\n            <div class="form-group">\n                <label for="protocol">' +
((__t = ( _.i18n('pcrSetup.filter.protocol') )) == null ? '' : __t) +
'</label>\n                <select id="protocol" class="form-control js-info-input" data-field-name="protocol">\n                    <option value=""></option>\n                    ';
 _.each(protocols, function(protocol) { ;
__p += '\n                    <option value="' +
((__t = ( protocol.secId )) == null ? '' : __t) +
'">' +
((__t = ( protocol.code )) == null ? '' : __t) +
'</option>\n                    ';
 }) ;
__p += '\n                </select>\n            </div>\n\n            <div class="form-group js-assay m-b-0"></div>\n        </div>\n    </div>\n    <div class="col-xs-4 p-l-10 p-r-5 js-prepRuns height-100"></div>\n    <div class="col-xs-2 buttonAction" style="display: flex; flex-direction: column; justify-content: center;align-items:center; height: 100%;">\n        <button class="btn btn-white js-undo disabled" title="' +
((__t = ( _.i18n('undo') )) == null ? '' : __t) +
'">\n            <span class="mdi mdi-undo"></span>\n        </button>\n        <button class="btn btn-white js-redo disabled" title="' +
((__t = ( _.i18n('redo') )) == null ? '' : __t) +
'">\n            <span class="mdi mdi-redo"></span>\n        </button>\n        <div style="min-height: 10%;"></div>\n        <button class="btn btn-white js-bulk disabled" title="' +
((__t = ( _.i18n('pcrSetup.bulk') )) == null ? '' : __t) +
'">\n            <span class="mdi mdi-shuffle"></span>\n        </button>\n        <button class="btn btn-white js-transfer disabled selected" title="' +
((__t = ( _.i18n('pcrSetup.range') )) == null ? '' : __t) +
'">\n            <span class="mdi mdi-shuffle-disabled"></span>\n        </button>\n    </div>\n    <div class="col-xs-4 p-l-5 p-r-10 js-pcrRuns height-100"></div>\n</div>\n';

}
return __p
};});

