define('connectorHl7Controller',[
    'module',
    'underscore',
    'connectorHl7ViewTracing',
    'entities/caccounts/connector/connectorHl7Tracings'
], function (
    module,
    _,
    CreateEditLayoutView,
    ConnectorHL7Tracing
) {
    'use strict';

    module.exports = {
        showDetails: function (model, callBackRefresh) {
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var createEditView = new CreateEditLayoutView({
                model: model
            });
            createEditView.show({
                title: _.i18n(ConnectorHL7Tracing.getName() + '.new'),
                className: 'baseTableEditAddPopup ' + ConnectorHL7Tracing.getName() + 'EditAddPopup'
            }, _.bind(function () {
                if (callBackRefresh) {
                    callBackRefresh();
                }
            }, callBackRefresh ? callBackRefresh.context : undefined));
        }
    };
});
