define('entities/caccounts/reportProperties',[
	'app',
	'backbone',
	'settings',
	'jquery',
	'underscore',
	'module',
	'dynamic'
], function (
	App,
	Backbone,
	Settings,
	$,
	_,
	module,
	Dynamic
) {
	'use strict';

	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.ReportProperty = Backbone.RelationalModel.extend({
		fetch: function () {
			this.url = Settings.url('compuzz', 'v2/reports/' + this.get('secId'));

			return Backbone.Model.prototype.fetch.call(this);
		},
		defaults: {
			'report': {},
			'key': undefined,
			'value': undefined
		},
		idAttribute: 'secId',
		getImageUid: function () {
		},
		jsonObjectName: 'ReportPropertyJson',
		getPDF: function () {
			return Settings.getDocUrl(this.jsonObjectName + '.pdf');
		},

		jsonObject: 'ReportProperty',
		getEntity: function () {
			return module.exports;
		}
	});

	app.ns.ReportPropertyCollection = Backbone.Collection.extend({
		model: app.ns.ReportProperty
	});

	module.exports = _.defaults({
		getName: function () {
			return 'report';
		},
		getUrl: function () {
			return 'v2/reports';
		},
		getDynamicJson: function () {
			return 'ReportPropertyJson';
		},
		getModel: function (modelJson) {
			if (!modelJson) {
				modelJson = {};
			}
			return app.ns.ReportProperty.findOrCreate(modelJson);
		},
		getCreateEditLine: function () {
			return [
				{'field': 'key', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12 p-r-2 p-l-2'}}},
				{'field': 'value', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12 p-r-2 p-l-2'}}}
			];
		}
	}, Dynamic);
});
