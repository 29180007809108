/**
 * Created by OBL on 14/01/2016.
 */
define('dynamicController',[
    'module',
    'underscore',
    'backbone',
    'jquery',
    'app',
    'dynamicList',
    'customBootboxMessage',
    'dynamicCreateEdit',
    'dynamicLoadEntities'
], function (
    module,
    _,
    Backbone,
    $,
    App,
    View,
    CustomBootboxMessage,
    CreateEditLayoutView
) {
    'use strict';

    module.exports = {
        showList: function (region, data, displayArchived) {
            require([
                'entities/' + data.entityNamePath
            ], _.bind(function (entity) {
                this._showList(region, data, displayArchived, entity);
            }, this));
        },
        _showList: function (region, data, displayArchived, entity) {
            var view = new View({
                data: data,
                displayArchived: displayArchived,
                entity: entity,
                region: region
            });
            view.listenTo(view, 'dynamic:show', _.bind(this.showDetails, this));
            if (region) {
                region.show(view);
            } else {
                view.show({
                    title: _.i18n('menu.' + entity.getName()),
                    className: 'baseTableEditAddPopup ' + entity.getName() + 'ListPopup'
                });
            }
        },
        showDetails: function (model, useCreateEditView, callBackOnClose) {
            if (!(model instanceof Backbone.Model) && typeof model === 'object') {
                var param = model;
                model = param.model;
                useCreateEditView = param.useCreateEditView;
                callBackOnClose = param.callBackOnClose;
            }
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            if (useCreateEditView) {
                useCreateEditView(model, callBackOnClose);
            } else {
                if (model.isNew() && model.getEntity().defineDefaultValue) {
                    model.getEntity().defineDefaultValue(model).done(_.bind(function () {
                        this._showDetails(model, callBackOnClose);
                    }, this));
                } else {
                    this._showDetails(model, callBackOnClose);
                }
            }
        },
        _showDetails: function (model, callBackOnClose) {
            model.getEntity().getDynamicConfiguration().done(_.bind(function (dynamicConfiguration) {
                var options = {
                    model: model,
                    dynamicConfiguration: dynamicConfiguration,
                    newItem: !model.get('secId')
                };
                var createEditView = new CreateEditLayoutView(options);
                createEditView.show({
                    title: _.i18n(model.getEntity().getName() + '.new'),
                    className: 'popupDynamicEdit baseTableEditAddPopup ' + model.getEntity().getName() + 'EditPopup'
                }, function () {
                    if (callBackOnClose) {
                        callBackOnClose();
                    }
                });
            }, this));
        }
    };
});
