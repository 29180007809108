/**
 * Created by RKL on 21/08/2015.
 */
define('runsShowView',[
    'module',
    'underscore',
    'jquery',
    'jqgridView',
    'urlUtil',
    'app',
    'settings',
    'template!runsShowView',
    'datePickerPopoverMixin',
    'emptyView',
    'contentShowController',
    'itemPagesNameMixin',
    'template!pcrRunStatusTpl',
    'template!pcrRunValStatusTpl',
    'marionette'
], function (
    module,
    _,
    $,
    JqGridView,
    UrlUtil,
    App,
    Settings,
    viewTpl,
    datePickerPopoverMixin,
    EmptyView,
    ContentShowController,
    ItemPagesNameMixin,
    StatusToolTip,
    ValStatusToolTip,
    Marionette
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'runsFilters',
        keepCurrentPage: false,

        className: 'dynamic jq-grid-table-page',
        regions: {
            runsActions: '.js-action-buttons-region'
        },

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                uploadRuns: '.js-upload-runs',
                deleteRun: '.delete-link-action',
                selectRow: '.select-link-action',
                gridUpperMenu: '.js-grid-upper-menu',
                selectAllRows: '.js-all-rows-selector',
                tableHeader: 'table.ui-jqgrid-htable',
                addEmptyRun: '.js-addPcrRun-empty',
                showViewNewRun: '.js-showViewNewRun',
                attachment: '.attachment-link-action',
                archive: '.cell-archive',
                unArchive: '.cell-unArchive',
                archiveByList: '.js-archive-by-list',
                unArchiveByList: '.js-unArchive-by-list',
                menuButton: '#menuAddListButton',
                menuButtonImport: '#menuImportListButton',
                menuAddList: '#menuAddList',
                menuAddListImport: '#menuAddListImport',
                menuEntry: '.menuEntry',
                menuEntryImport: '.menuEntryImport',
                displayArchived: '.display-archived'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({
                'mouseenter @ui.tableHeader': 'showGridUpperMenu',
                'mouseleave @ui.tableHeader': 'HideGridUpperMenu',
                'mouseenter @ui.gridUpperMenu': 'showGridUpperMenu',
                'mouseleave @ui.gridUpperMenu': 'HideGridUpperMenu',
                'click @ui.selectAllRows': 'onCheckAllRows',
                'click @ui.selectRow': 'onCheckOneRow',
                'click @ui.deleteRun': 'onDeleteRun',
                'click @ui.addEmptyRun': 'onAddEmptyRun',
                'click @ui.showViewNewRun': 'showViewNewRun',
                'click @ui.attachment': 'onAttachment',
                'click @ui.archive': 'onArchive',
                'click @ui.unArchive': 'onUnArchive',
                'click @ui.archiveByList': 'onArchiveByList',
                'click @ui.unArchiveByList': 'onUnArchiveByList',
                'click .js-back-button': 'onHistoryBack',

                'click @ui.menuButton': 'showMenu',
                'click @ui.menuButtonImport': 'showMenuImport',
                'mouseleave @ui.menuAddList': 'hideMenu',
                'mouseleave @ui.menuAddListImport': 'hideMenuImport',
                'click @ui.menuEntry': 'hideMenu',
                'click @ui.menuEntryImport': 'hideMenuImport',

                'click .js-createRun-fromFile-importResult': 'showViewNewRunFromFileImportResult',
                'click .js-createRun-fromFile-plateLayout': 'showViewNewRunFromFilePlateLayout',
                'click .js-createRun-fromFile-sampleList': 'showViewNewRunFromFileSampleList',
                'change @ui.displayArchived': 'onChangeDisplayArchived'
            }, this.gridEvents);
        },

        refreshGrid: function () {
            this.ui.jqGrid.trigger('reloadGrid');
        },

        onChangeDisplayArchived: function (event) {
            $('input[name="displayArchived"]').val(event.currentTarget.value);
            $('input[name="displayArchived"]').change();
        },
        onAddEmptyRun: function () {
            App.request('setting:get-setting-by-code', 'DEF_DEPT')
                .done(_.bind(function (defDept) {
                    require([
                        'runsShowController'
                    ], function (ShowController) {
                        ContentShowController.showContent().done(function () {
                            App.startSubModule('runsMain');
                            App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                            ShowController.onCreateEmptyPcrRun(defDept);
                        });
                    });
                }, this));
        },

        showViewNewRunFromFileImportResult: function () {
            App.request('setting:get-setting-by-code', 'DEF_DEPT')
                .done(_.bind(function (/*defDept*/) {
                    require([
                        'runsShowController'
                    ], function (ShowController) {
                        ContentShowController.showContent().done(function () {
                            App.startSubModule('runsMain');
                            App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                            ShowController.showViewNewRunFromFileImportResult(/*defDept*/);
                        });
                    });
                }, this));
        },

        showViewNewRunFromFilePlateLayout: function () {
            require([
                'runsShowController'
            ], function (ShowController) {
                ContentShowController.showContent().done(function () {
                    App.startSubModule('runsMain');
                    App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    ShowController.showViewNewRunFromFilePlateLayout();
                });
            });
        },

        showViewNewRunFromFileSampleList: function () {
            require([
                'runsShowController'
            ], function (ShowController) {
                ContentShowController.showContent().done(function () {
                    App.startSubModule('runsMain');
                    App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    ShowController.showViewNewRunFromFileSampleList();
                });
            });
        },

        showViewNewRun: function () {
            location.href = '#runs/pcr/new';
        },

        showMenu: function () {
            this.showBaseTableAddMenu($(this.ui.menuAddList));
        },

        hideMenu: function () {
            this.hideBaseTableAddMenu($(this.ui.menuAddList));
        },

        showMenuImport: function () {
            this.showBaseTableAddMenu($(this.ui.menuAddListImport));
        },

        hideMenuImport: function () {
            this.hideBaseTableAddMenu($(this.ui.menuAddListImport));
        },

        onShow: function () {
            $.material.init();
            this.hideMenu();
            this.hideMenuImport();
        },

        initialize: function () {
            this.listenTo(this, 'run:listView:refresh', _.bind(this.refreshGrid, this));
        },

        serializeData: function () {
            this.settings = this.options.settings;
            this.checkedRowsList = [];
            this.selectedRuns = Settings.get('selectedRuns') || [];
            this.archiveByListCall = 'run:pcr:archive:bylist';
            this.unArchiveByListCall = 'run:pcr:unarchive:bylist';
            var templateData = {
                'type': 'runs',
                displayArchived: this.options.displayArchived
            };
            return templateData;
        },
        onHistoryBack: function () {
            this.jqGridHistoryBack();
        },

        onDeleteRun: function (event) {
            var codeName = $(event.currentTarget).find('span').attr('data-row-codeName');
            var secId = $(event.currentTarget).find('span').attr('data-row-id');
            this.trigger('run:pcr:delete', secId, codeName);
            this.resetCheckedRowList();
        },

        onAttachment: function (event) {
            var target = $(event.currentTarget);
            var model = this.options.entity.getModel({secId: target.find('span').attr('data-row-id')});
            $('.js-global-loader').show();
            model.fetch().done(_.bind(function () {
                model.getEntity().openAttachment(model);
            }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onArchive: function (event) {
            var rowId = $(event.currentTarget).attr('data-row-id');
            this.trigger('run:pcr:archive', rowId);
            this.resetCheckedRowList();
        },

        onUnArchive: function (event) {
            var rowId = $(event.currentTarget).attr('data-row-id');
            this.trigger('run:pcr:unarchive', rowId);
            this.resetCheckedRowList();
        },

        onArchiveByList: function () {
            if (this.checkedRowsList && this.checkedRowsList.length > 0) {
                var checkedRowsList = this.checkedRowsList;
                this.resetCheckedRowList();
                this.jqGridArchiveRestoreByList(checkedRowsList, this.archiveByListCall, true)
                    .done(_.bind(function () {
                        this.updatePagination('reloadGrid');

                    }, this));
            }
        },

        onUnArchiveByList: function () {
            if (this.checkedRowsList && this.checkedRowsList.length > 0) {
                var checkedRowsList = this.checkedRowsList;
                this.resetCheckedRowList();
                this.jqGridArchiveRestoreByList(checkedRowsList, this.unArchiveByListCall, false)
                    .done(_.bind(function () {
                        this.updatePagination('reloadGrid');
                    }, this));
            }
        },

        resetCheckedRowList: function () {
            this.checkedRowsList = [];
            this.resetAllRowSelector();
        },

        showGridUpperMenu: function () {
            this.ui.gridUpperMenu.removeClass('hidden');
        },

        HideGridUpperMenu: function () {
            this.ui.gridUpperMenu.addClass('hidden');
        },

        onCheckAllRows: function (e) {
            this.checkedRowsList = this.checkAllRows(e);
        },

        onCheckOneRow: function (e) {
            this.checkedRowsList = this.checkOneRow(e, this.checkedRowsList);
        },

        gridOptions: function (data) {
            var that = this,
                defaultsOptions = this.gridInitOptions(data),
                options = {
                    colModel: [{
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: '',
                        name: 'wellCount',
                        hidden: true
                    }, {
                        label: '',
                        name: 'displayArchived',
                        index: 'displayArchived',
                        search: true,
                        hidden: true
                    }, {
                        label: ' ',
                        name: 'select',
                        sortable: false,
                        search: false,
                        classes: 'select-link-action ignore-row-click css-no-sideBorders',
                        formatter: this.rowSelectorFormatter,
                        fixed: true,
                        width: 25
                    }, {
                        label: ' ',
                        name: 'delete',
                        sortable: false,
                        search: false,
                        classes: 'delete-link-action ignore-row-click css-no-sideBorders',
                        formatter: this.deleteActionIconFormatter,
                        fixed: true,
                        width: 25
                    }, {
                        label: ' ',
                        name: 'archiveRestore',
                        sortable: false,
                        search: false,
                        classes: 'archiveRestore-link-action ignore-row-click css-no-sideBorders',
                        formatter: this.archiveRestoreActionIconFormatter,
                        fixed: true,
                        width: 25
                    }, {
                        label: ' ',
                        name: 'attachment',
                        sortable: false,
                        search: false,
                        classes: 'attachment-link-action ignore-row-click css-no-sideBorders',
                        formatter: this.attachmentActionIconFormatter,
                        fixed: true,
                        width: 25
                    }, {
                        label: _.i18n('runList.status'),
                        name: 'status',
                        classes: 'order-link',
                        formatter: _.bind(this.statusFormatter, this),
                        search: true,
                        searchoptions: {clearSearch: false, size: 2},
                        index: 'status',
                        fixed: true,
                        width: 60
                    }, {
                        label: _.i18n('runList.period'),
                        name: 'periodFormatted',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'periodFormatted',
                        fixed: true,
                        width: 70
                    }, {
                        label: _.i18n('runList.group'),
                        name: 'group',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'group',
                        fixed: true,
                        width: 50
                    }, {
                        label: _.i18n('user'),
                        name: 'refRespUser',
                        formatter: this.userFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'refRespUser.nickName',
                        fixed: true,
                        width: 75,
                        sortable: false
                    }, {
                        label: _.i18n('plateSize'),
                        name: 'plateSize',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'plateSize',
                        fixed: true,
                        sortable: false,
                        width: 50
                    }, {
                        label: _.i18n('runList.name'),
                        name: 'code',
                        classes: 'order-link css-run-listView-accentuation',
                        formatter: this.runNameFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'code',
                        fixed: true,
                        width: 160
                    }, {
                        label: _.i18n('runList.valStatus'),
                        name: 'valStatus',
                        classes: 'order-link',
                        formatter: _.bind(this.valStatusFormatter, this),
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'valStatus',
                        fixed: true,
                        width: 60
                    }, {
                        label: _.i18n('runList.assays'),
                        name: 'assays',
                        classes: 'order-link',
                        formatter: this.codeNameListFormatter,
                        search: true,
                        index: 'assays',
                        searchoptions: {size: 40, clearSearch: false},
                        fixed: true,
                        sortable: false,
                        width: 200
                    }, {
                        label: _.i18n('runList.refKitProt'),
                        name: 'refKitProt.code',
                        classes: 'order-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'kitProtCode',
                        fixed: true,
                        width: 120
                    }, {
                        label: _.i18n('runList.refCycler'),
                        name: 'refCycler.code',
                        classes: 'order-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'cyclerCode',
                        fixed: true,
                        width: 90
                    }, {
                        label: _.i18n('runList.refLmbCycler'),
                        name: 'refLmbCycler.code',
                        classes: 'order-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'refLmbCycler.code',
                        fixed: true,
                        width: 130
                    }, {
                        label: _.i18n('run.prepruns'),
                        name: 'prepRuns',
                        formatter: this.codeNameListFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'prepRunName',
                        fixed: true,
                        sortable: false,
                        width: 200
                    }, {
                        label: _.i18n('runList.plateIdPC'),
                        name: 'plateIdPC',
                        formatter: this.plateIdFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'plateIdPC',
                        fixed: true,
                        width: 110
                    }, {
                        label: _.i18n('common.comment'),
                        name: 'comment',
                        classes: 'order-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'comment'
                    }
                    ],

                    serializeGridData: function (postData) {
                        if (postData.status === that.selectDefault) {
                            delete postData.status;
                        }

                        if (postData.modDate) {
                            postData.dateFrom = datePickerPopoverMixin.parseDateFrom(postData.modDate);
                            postData.dateTo = datePickerPopoverMixin.parseDateTo(postData.modDate);
                        } else if (!that.sorting) {
                            delete postData.dateFrom;
                            delete postData.dateTo;
                        }
                        that.sorting = false;
                        defaultsOptions.serializeGridData.apply(this, arguments);

                        return postData;
                    },

                    gridComplete: function () {
                        var purchaseOrderId = UrlUtil.parseQueryString().purchaseOrderId;
                        if (purchaseOrderId) {
                            UrlUtil.clearQueryString();
                            that.preselectFirstRow();
                        }
                        defaultsOptions.gridComplete.apply(this, arguments);
                        $('#jq-grid-list').jqGrid('setLabel', 'hyphen', '-', 'prepRunList-hyphen');
                        $('#jq-grid-list').jqGrid('setLabel', 'hyphen2', '-', 'prepRunList-hyphen');
                        that.checkRunsSelection();

                        var popOver = $('.popover-status');
                        if (popOver.length > 0) {
                            popOver.popover({
                                trigger: 'manual',
                                placement: 'bottom',
                                html: 'true',
                                delay: {
                                    show: '300',
                                    hide: '10'
                                },
                                container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
                            }).on('mouseenter', function () {
                                var _this = this;
                                $(this).popover('show');
                                $('.popover').on('mouseleave', function () {
                                    $(_this).popover('hide');
                                });
                            }).on('mouseleave', function () {
                                var _this = this;
                                setTimeout(function () {
                                    if (!$('.popover:hover').length) {
                                        $(_this).popover('hide');
                                    }
                                }, 10);
                            }).data('bs.popover').tip().addClass('run-pcr-popover');
                        }
                    },

                    onSelectRow: function (rowId, bool, e) {
                        var currentRowData = that.ui.jqGrid.getRowData(rowId);
                        var pcrRunList = that.ui.jqGrid.getRowData();

                        if (that.checkedRowsList && that.checkedRowsList.length > 0) {
                            pcrRunList = that.checkedRowsList;
                        }
                        that.triggerMethod('run:selectedList', pcrRunList);

                        if (!$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click') {
                            App.regions.getRegion('main').currentView.getRegion('content').show(new EmptyView());
                            var plateSize = $(currentRowData.plateSize).text();
                            App.navigate('runs/pcr/' + rowId + '/A01/1/' + plateSize, {trigger: true});
                        }
                    }
                };

            options.sortorder = 'DESC';

            options = _.defaults(options, defaultsOptions);

            return options;
        },

        runNameFormatter: function (cellValue, options) {
            return '<span class="cell-default" style="font-weight: bold; " data-row-id="' +
                options.rowId + '">' + _.escape(cellValue) + '</span>';
        },

        statusFormatter: function (cellValue, options) {
            var status = cellValue ? parseInt(cellValue, 10) : ' ';
            var iconColor = [];
            var statusIcon = '';

            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb">' + status + '</span>';
            var content = Marionette.Renderer.render(StatusToolTip, {
                elements: this.options.codeListStatus,
                status: status
            });

            statusIcon += '<div class="cell-default popover-status" style="display: flex; top: 2px;\n' +
                '            position: relative;" data-row-id="' + options.rowId + '" data-content="' + _.escape(content) + '">';

            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-statusIcon' + i + ' ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        },

        valStatusFormatter: function (cellValue, options, object) {
            var status = parseInt(object.status, 10);
            var valStatus = object.valStatus;
            var iconColor = [];
            var statusIcon = '';

            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb">' + valStatus + '</span>';
            var content = Marionette.Renderer.render(ValStatusToolTip, {
                elements: this.options.codeListValStatus,
                valStatus: valStatus
            });

            statusIcon += '<div class="cell-default popover-status" style="display: flex; top: 2px;\n' +
                '            position: relative;" data-row-id="' + options.rowId + '" data-content="' + _.escape(content) + '">';

            var valStatusArray = valStatus.split('');
            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                if (valStatusArray.includes(i.toString())) {
                    iconColor[i] = 'statusIcon-toDo';
                }
                if (i === 5 && valStatusArray.includes(i.toString()) && status === 5) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-valStatusIcon ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        },

        plateIdFormatter: function (cellValue, options) {
            var idMaxLength = 12;
            var value = '', sub = 0;
            if (cellValue) {
                if (cellValue.length > idMaxLength) {
                    sub = cellValue.length - idMaxLength;
                    value = '...';
                }
                value += cellValue.substr(sub);
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + value + '</span>';
        },

        pagination: function () {
            return {
                first: 0,
                rowCount: 0,
                pageSize: 18,
                currentPage: 1
            };
        },

        checkRunsSelection: function () {
            var $list = this.ui.jqGrid.find('tr');
            $list.each(_.bind(function (index, item) {
                var $item = $(item);
                if (_.some(this.selectedRuns, {rowid: $item.attr('id')})) {
                    $item.addClass('selected');
                } else {
                    $item.removeClass('selected');
                }
            }, this));
            this.onSelectedRunsChanged();
        },

        onSelectedRunsChanged: function () {
            if (this.selectedRuns.length > 0) {
            }
            Settings.set('selectedRuns', this.selectedRuns);
        },

        onGridFilterChange: function () {

            var that = this,
                filters = {},
                value;

            _.each(this.colModel, function (column) {
                if (column.search) {
                    if (column.stype === 'select') {
                        value = that.$('select[name="' + column.index + '"]').val();
                        filters[column.index] = value === that.selectDefault ? '' : value;
                    } else if (column.sorttype === 'date') {
                        value = that.$('input[name="' + column.index + '"]').val();
                        if (column.index === 'modDate' && value) {
                            filters.dateFrom = datePickerPopoverMixin.parseDateFrom(value);
                            filters.dateTo = datePickerPopoverMixin.parseDateTo(value);
                        }
                    } else {
                        value = that.$('input[name="' + column.index + '"]').val();
                        filters[column.index] = value;
                    }
                }
            });

            this.filters = filters;
            this.filterChange = true;
            this.updatePagination();
        }
    });
})
;
