/**
 * Created by GSP on 15/09/2015.
 */
/**
 * Created by RKL on 10/09/2015.
 */
define('pcrWellResultHL7View',[
    'module',
    'logger',
    'marionette',
    'underscore',
    'jquery',
    'chart',
    'template!pcrWellResultHL7View',
    'app',
    'extendChart'
], function (
    module,
    Logger,
    Marionette,
    _,
    $,
    Chart,
    viewTpl,
    App
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({

        template: viewTpl,

        ui: {
            chart: '.chart-canvas'
        },

        initialize: function () {
            this.options = this.options || {};

            if (this.options.generateImage) {
                $('body').append('<div id="chart-' + this.cid + '"></div>');
                this.region = App.regions.addRegion('chart', '#chart-' + this.cid);
                this.region.show(this);
            }
        },

        attributes: function () {
            var height = this.options.height;
            var width = this.options.width;
            if (this.options.generateImage) {
                height = height ? height : 80;
                width = width ? width : 400;
                return {style: 'position: relative;height:' + height + 'px; width:' + width + 'px;'};
            }
            if (this.options.max) {
                height = height ? height : 16;
                return {style: 'position: relative;height:' + height + 'px; width:100%;padding-right:5px'};
            }
            width = width ? width : '100%';
            return {style: 'position: relative;width:100%;padding-right:5px'};
        },

        onRender: function () {
            this.data = {
                labels: _.map(this.options.data, function () {
                    return '';
                }),
                datasets: [{
                    barPercentage: 0.9,
                    categoryPercentage: 1,
                    axis: 'y',
                    label: '',
                    data: _.map(this.options.data, _.bind(function (data) {
                        return this.options.max - parseFloat(data.cts ? data.cts : 0);
                    }, this)),
                    fill: false,
                    backgroundColor: _.map(this.options.data, function (data) {
                        return data.color;
                    })
                }]
            };

            var config = {
                type: 'bar',
                data: this.data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    lineAtIndex: 1,
                    scales: {
                        xAxes: [{
                            display: false
                        }],
                        yAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                suggestedMax: this.options.max ? this.options.max : 6,
                                min: 0,
                                max: this.options.max ? this.options.max : 6,
                                stepSize: 1
                            }
                        }]
                    },
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    }
                }
            };

            if (this.options.generateImage) {
                config.options.animation = false;
            }

            this.instance = new Chart(this.ui.chart[0].getContext('2d'), config);
        },

        toBase64Image: function () {
            var img = this.instance.toBase64Image();
            this.destroy();
            return img;
        },

        onDestroy: function () {
            if (this.instance) {
                this.instance.destroy();
            }
            if (this.region) {
                this.region.destroy();
                $('body').find('#chart-' + this.cid).remove();
            }
        }
    });
});
