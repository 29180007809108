
define('template!prepRunCreateLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="createRunFromTemplate">\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="refPrepRunTemplate"\n                     style="display: flex;">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.runTemplate') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-4">\n                        <div class="js-select-run-template-region"></div>\n                    </div>\n                    <label class="col-xs-2 control-label">' +
((__t = ( _.i18n('run.refRespUser') )) == null ? '' : __t) +
'</label>\n                    <div class="col-w-all col-w-13 p-r-0 p-l-15">\n                        <div class="js-select-respUser-region"></div>\n                    </div>\n                </div>\n\n                <div class="form-group dataLabelContainer m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="selectedDate">\n                    <label class="col-xs-4 control-label" for="selectedDate">' +
((__t = ( _.i18n('crun.selectedDate') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-2">\n                        <input type="datepicker" id="selectedDate"\n                               class="form-control js-datePicker js-selectedDate inputBackground"\n                               data-field-name="selectedDate"\n                               placeholder="" value="' +
((__t = ( selectedDate )) == null ? '' : __t) +
'">\n                    </div>\n                </div>\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="department">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.department') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-8">\n\n                        <div class="col-xs-2 p-l-0 js-select-runName-department"></div>\n                        <label class="col-xs-1 p-l-0 control-label noWrapHidden">' +
((__t = ( _.i18n('run.runGroup.short')
                            )) == null ? '' : __t) +
'</label>\n                        <div class="col-xs-2 p-l-0 js-select-runName-runGroup"></div>\n\n                        <label class="col-xs-1 p-l-0 control-label noWrapHidden">' +
((__t = ( _.i18n('run.sequence.short')
                            )) == null ? '' : __t) +
'</label>\n                        <div class="col-xs-2 p-l-0 js-select-runName-sequenceFormat"></div>\n\n                        <label class="col-xs-1 p-l-0 control-label noWrapHidden">' +
((__t = ( _.i18n('run.suffix.short')
                            )) == null ? '' : __t) +
'</label>\n                        <div class="col-xs-3 p-l-0 js-runName-suffix">\n                            <input type="text"\n                                   class="form-control js-info-input inputBackground"\n                                   data-field-name="suffix"\n                                   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'" value="' +
((__t = ( suffix )) == null ? '' : __t) +
'">\n                        </div>\n                    </div>\n                </div>\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0"\n                     data-field-name="pattern">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.pattern') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-4 js-runName-pattern">\n                    </div>\n                </div>\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0"\n                     data-field-name="name">\n                    <label class="col-xs-4 control-label" for="name">' +
((__t = ( _.i18n('run.name') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-5">\n                        <input type="text" id="name"\n                               class="form-control floating-label js-info-input"\n                               data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                               value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n                    </div>\n                    <div class="col-xs-3 p-l-0 p-r-0"\n                         style=" padding-left: 299px;\n                             text-align: left;">\n                        <label>\n                            <input type="checkbox" class="js-runNameForced"\n                                   data-field-name="nameForced"\n                                   style="position: relative;\n                                        display: inline-block;\n                                        width: 20px;\n                                        height: 20px;\n                                        border-radius: 2px;\n                                        overflow: hidden;\n                                        z-index: 1;\n                                        color: #11ABC3;"\n                                   value="false">\n                        </label>\n                        <label style="padding-left: 13px;">' +
((__t = ( _.i18n('run.name.forced') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="form-group js-number-of-run-region m-t-15 m-r-0 m-b-0 m-l-0"\n                     data-field-name="numberOfRun"><!--hidden-->\n                    <label class="col-xs-4 control-label" for="number-of-run">' +
((__t = ( _.i18n('run.numberOfRun') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-2">\n                        <input type="number" max="999" min="1" style="width: 70px;"\n                               id="number-of-run"\n                               class="form-control floating-label js-info-input number-of-run"\n                               data-field-name="numberOfRun" placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                               value="' +
((__t = ( numberOfRun )) == null ? '' : __t) +
'">\n                    </div>\n                </div>\n\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0">\n                    <label class="col-xs-4 control-label" for="number-of-run">' +
((__t = ( _.i18n('print.runsheet') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-2">\n                        <input type="checkbox" class="generate-starter-sheet"\n                               style="position: relative;\n                                        display: inline-block;\n                                        width: 20px;\n                                        height: 20px;\n                                        border-radius: 2px;\n                                        overflow: hidden;\n                                        z-index: 1;\n                                        color: #11ABC3;"\n                               id="printRunSheet"\n                               value="false">\n                    </div>\n                </div>\n            </div>\n        </div>\n        <br/>\n        <div class="row cancelConfirmRow" style="text-align: end; padding-right: 15px;">\n            <div class="pull-right p-r-15 col-xs-12 cancelConfirmCreateRunDiv" style="display: contents;">\n                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n            </div>\n        </div>\n        <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n    </div>\n</div>';

}
return __p
};});

