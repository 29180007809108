define('pcrSetup',[
    'module',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!pcrSetup',
    'pcrSetupAssayCollection',
    'pcrSetupPrepRunCollection',
    'pcrSetupPcrRunCollectionView',
    'backbone',
    'bootbox'
], function (
    module,
    _,
    $,
    DialogFormView,
    Tpl,
    AssayCollectionView,
    PrepRunCollectionView,
    PcrRunCollectionView,
    Backbone,
    BootBox
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        className: 'content pcrSetup height-100',

        ui: {
            input: '.js-info-input',
            protocol: '#protocol',
            filter: '.js-filter',
            historyUndo: '.js-undo',
            historyRestore: '.js-redo',
            bulk: '.js-bulk',
            transfer: '.js-transfer'
        },

        regions: {
            prepRuns: '.js-prepRuns',
            pcrRuns: '.js-pcrRuns',
            assays: '.js-assay'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'change @ui.protocol': 'onChangeProtocol',
            'click .js-undo.clickable': 'onHistoryUndoClick',
            'click .js-redo.clickable': 'onHistoryRestoreClick',
            'click @ui.bulk': 'onBulkClick',
            'click @ui.transfer': 'onTransferClick'
        },

        modelEvents: {
            'change:history': 'onHistoryChange'
        },

        initialize: function () {
            this.filter = {
                protocol: null,
                assay: null
            };
        },

        _filter: function () {
            var loader = $('.js-global-loader');
            loader.show();
            this.assayCollectionView.applyFilter(this.filter.protocol);
            this.model.generateColor(this.filter.assay);
            this.prepRunCollectionView.applyFilter(this.filter.assay, this.filter.protocol);
            this.pcrRunCollectionView.applyFilter(this.filter.assay, this.filter.protocol);
            loader.hide();
        },
        onClickAssay: function (model, fromProtocol) {
            if (this.filter.assay && !model) {
                var models = _.map(this.pcrRunCollectionView.children._views, function (view) {
                    return view.model;
                });

                var pcrRunsWithoutQC = _.filter(models, _.bind(function (pcrRun) {
                    if (pcrRun.get('wells').any(function (well) {
                        return well.added;
                    })) {
                        var ret = pcrRun.containQualityControl(this.filter.assay);
                        var r = _.findWhere(ret, {assay: this.filter.assay});
                        return !r.PC || !r.NC;
                    }
                }, this));
                if (!_.isEmpty(pcrRunsWithoutQC)) {
                    BootBox.alert({
                        title: _.i18n('notification'),
                        message: '<span style="font-size: 20px;">' + _.i18n('pcrSetup.pcrRun.missingQC') + '</span>'
                    });
                }
            }
            var loader = $('.js-global-loader');
            loader.show();
            var assay = model ? model.get('assay') : null;
            this.filter.assay = assay;
            var onlyAssay = this.assayCollectionView.isOnlyAvaible();
            if (assay || onlyAssay) {
                this.ui.protocol.val(assay && assay.get('refKitProt') ? assay.get('refKitProt').get('secId') : '');
                this.filter.protocol = this.ui.protocol.val();
            }
            if (!fromProtocol) {
                this._filter();
            }
        },

        onChangeProtocol: function (e) {
            var value = e.currentTarget.value;
            this.assayCollectionView.clearSelection();
            this.onClickAssay(null, true);
            this.ui.protocol.val(value);
            this.filter.protocol = this.ui.protocol.val();
            this._filter();
        },

        serializeData: function () {
            var templateData = {};
            templateData.protocols = _.map(this.model.findProtocols(), function (kitProt) {
                return kitProt.toJSON();
            });
            return templateData;
        },

        onShow: function () {
            this.assayCollectionView = new AssayCollectionView({model: this.model});
            this.listenTo(this.assayCollectionView, 'assay:change', _.bind(this.onClickAssay, this));
            this.getRegion('assays').show(this.assayCollectionView);
            this.assayCollectionView.updateProtocol();
            this.prepRunCollectionView = new PrepRunCollectionView({
                collection: this.model.get('prepRuns'),
                model: this.model
            });
            this.getRegion('prepRuns').show(this.prepRunCollectionView);
            this.listenTo(this.prepRunCollectionView, 'enableButtons', _.bind(function (enable) {
                if (enable) {
                    this.ui.bulk.removeClass('disabled');
                    this.ui.transfer.removeClass('disabled');
                } else {
                    this.ui.bulk.addClass('disabled');
                    this.ui.transfer.addClass('disabled');
                }
            }, this));
            this.pcrRunCollectionView = new PcrRunCollectionView({
                collection: this.model.get('pcrRuns'),
                model: this.model
            });
            this.getRegion('pcrRuns').show(this.pcrRunCollectionView);
            this.pcrRunCollectionView.listenTo(this.pcrRunCollectionView, 'validateSelection', _.bind(function () {
                this.model.get('prepWellSelected').each(function (well) {
                    well.unset('selected');
                    well.unset('available');
                });
                this.model.get('prepWellSelected').reset();
                this.model.set({'qualityControl': null}, {silent: true});
                this.model.set({'mode': null}, {silent: true});
            }, this));
        },

        onBulkClick: function () {
            this.ui.bulk.addClass('selected');
            this.ui.bulk.removeClass('clickable');
            this.ui.transfer.removeClass('selected');
            this.ui.bulk.addClass('clickable');
            this.model.set({'transfer': 'bulk'}, {silent: true});
        },
        onTransferClick: function () {
            this.ui.bulk.removeClass('selected');
            this.ui.bulk.addClass('clickable');
            this.ui.transfer.addClass('selected');
            this.ui.transfer.removeClass('clickable');
            this.model.set({'transfer': 'transfer'}, {silent: true});
        },

        hide: function () {
            this.box.modal('hide');
        },

        onHistoryChange: function () {
            if (_.isEmpty(this.model.get('history'))) {
                this.ui.historyUndo.removeClass('clickable');
                this.ui.historyRestore.removeClass('clickable');
                this.ui.historyUndo.addClass('disabled');
                this.ui.historyRestore.addClass('disabled');
            } else {
                this.ui.historyUndo.addClass('clickable');
                this.ui.historyRestore.addClass('clickable');
                this.ui.historyUndo.removeClass('disabled');
                this.ui.historyRestore.removeClass('disabled');
                if (this.model.get('historyIndex') === -1) {
                    this.ui.historyUndo.addClass('disabled');
                    this.ui.historyUndo.removeClass('clickable');
                } else if (this.model.get('historyIndex') === _.size(this.model.get('history')) - 1) {
                    this.ui.historyRestore.addClass('disabled');
                    this.ui.historyRestore.removeClass('clickable');
                }
            }
        },
        onHistoryUndoClick: function () {
            this.model.undo(this.filter.assay);
        },
        onHistoryRestoreClick: function () {
            this.model.restore(this.filter.assay);
        }
    });
});
