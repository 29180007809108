define('assayReagentMMXView',[
	'module',
	'backbone',
	'marionette',
	'underscore',
	'template!assayReagentMMXTpl',
	'assayReagentMMXItemView',
	'entities/caccounts/assayreagent'
], function (
	module,
	Backbone,
	Marionette,
	_,
	Tpl,
	View,
	AssayReagents
) {
	'use strict';


	module.exports = Marionette.CompositeView.extend({
		template: Tpl,
		childView: View,
		childViewContainer: '.collection',
		childEvents: {
			'item:delete': function (view, model) {
				this.collection.remove(model);
			},
			'item:updateReagent': function () {
				var reagentTypes = _.pluck(this.collection.pluck('reagent'), 'type');
				_.each(this.children._views, function (view) {
					view.options.reagentTypes = reagentTypes;
				});
			},
			'item:updateVolume': function () {
				this.render();
			}
		},
		events: {
			'click .js-add': 'onAdd'
		},
		onAdd: function () {
			this.collection.push(AssayReagents.getModel({refAssay: this.model}));
		},
		serializeData: function () {
			var volumeMMX = _.reduce(this.collection.map(function (model) {
				return parseFloat(model.get('volumeByTest'), 10);
			}), function (memo, num) {
				return memo + num;
			});

			return {volumeMMX: volumeMMX};
		}
	});
});
