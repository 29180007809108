/**
 * Created by OLD on 30/10/2015.
 */
define('menuRouter',[
  'underscore',
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'wellUtils'
], function (
    _,
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    WellUtils
) {
  'use strict';

  var MenuRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'menuRouter': 'showMenus'
    }
  });

  var API = {
    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function (ContentShowController) {
        ContentShowController.showContent().done(function () {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    showMenus: function () {
      WellUtils.dismissRemainingPopovers();
      this.checkContent().done(function () {
        require([
          'menusController'
        ], function (MenusController) {
          MenusController.showMainMenus();
        });
      });
    }
  };

  App.on('before:start', function() {
    new MenuRouter({
      controller: API
    });
  });

  module.exports = MenuRouter;
});
