
define('template!headerCaccountAutocompleteTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-5" style="max-height: 40px; height: 40px; max-width: 120px; display: flex; align-content: center; align-items: center;">\n    <img alt="' +
((__t = ( code )) == null ? '' : __t) +
'" src="' +
((__t = ( logo )) == null ? '' : __t) +
'" style="max-width: 100%; max-height: 100%; object-fit: contain;"></div>\n<div class="col-xs-7 caccount-name">' +
((__t = ( code )) == null ? '' : __t) +
'</div>';

}
return __p
};});

