define('dynamicCreateEditCodeNameSequence',[
    'module',
    'formView',
    'template!dynamicCreateEditCodeNameSequence',
    'dynamicCreateEditCodeList'
], function (
    module,
    FormView,
    tpl,
    DynamicCreateEditCodeList
) {
    'use strict';

    module.exports = FormView.extend({
        template: tpl,

        ui: {
            input: '.js-info-input'
        },
        regions: {
            codeList: '.js-codeList'
        },
        className: 'codeName-component codeNameSequence-component',
        events: {
            'change @ui.input': 'onInputChange'
        },
        onRender: function () {
            if (this.options.codeListCode) {
                this.getRegion('codeList').show(new DynamicCreateEditCodeList({
                    'fieldName': 'code',
                    'model': this.model,
                    config: {
                        'modelProperty': 'code',
                        'code': this.options.codeListCode
                    }
                }));
            }
        },
        serializeData: function () {
            return {
                'code': this.model.get('code'),
                'name': this.model.get('name'),
                'sequence': this.model.get('sequence'),
                'codeListCode': !!this.options.codeListCode
            };
        }
    });
});
