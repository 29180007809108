define('entities/caccounts/prepruntracs',[
    'app',
    'backbone',
    'settings'
], function (
    App,
    Backbone,
    Settings
) {
    'use strict';

    var PrepRunTrac = Backbone.Model.extend({
        fetch: function() {
            this.url = Settings.url('compuzz', 'v2/runtracs/prep/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'creatDate': null,
            'refUserpid': {'nickname': ''},
            'action': '',
            'level': '',
            'comment': '',
            'wells': [''],
            'refRun': {'name': ''}
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('compuzz', 'v2/runtracs/prep');
        },

        save: function() {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF:function() {
            return Settings.getDocUrl('PrepRunTracJson.pdf');
        }

    });

    var PrepRunTracCollection = Backbone.Collection.extend({
        model: PrepRunTrac
    });

    App.reqres.setHandler('trac:prep:get-collection-url', function (runSecId, position) {
        var params = {};
        if (runSecId) {
            params.runSecId = runSecId;
        }
        if (position) {
            params.position = position;
        }
        return Settings.url('compuzz', 'v2/runtracs/prep', params);
    });

    App.reqres.setHandler('trac:prep:get-count-url', function (runSecId, position) {
        var params = '';
        if (runSecId) {
            params += 'runSecId=' + runSecId + '&';
        }
        if (position) {
            params += 'position=' + position + '&';
        }
        return 'v2/runtracs/prep/rowCount?' + params;
    });

    App.reqres.setHandler('trac:prep:collection', function (runJSON) {
        return new PrepRunTracCollection(runJSON);
    });

    App.reqres.setHandler('trac:prep:model', function (runJSON) {
        return new PrepRunTrac(runJSON);
    });
});
