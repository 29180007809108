
define('template!prepRunCreatePcrRunFromFileSampleListView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n\n    <div class="form-horizontal">\n\n        <div class="form-inputs-container">\n            <div class="createRunFromTemplate">\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="refFileFormat">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('import.refFileFormat') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-8">\n                        <div class="js-select-fileFormat-region"></div>\n                    </div>\n                </div>\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="startPos">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('import.startPos') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-4">\n                        <input type="text" class="form-control js-info-input" data-field-name="startPos"\n                               style="width: 45px; border: solid 1px #cccccc;"\n                               data-placement="top" value="' +
((__t = ( startPos )) == null ? '' : __t) +
'"\n                               onkeyup="this.value = this.value.toUpperCase();">\n                    </div>\n                </div>\n                <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="plateFillDirection">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('import.plateFillDirection') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-2">\n                        <div class="js-plate-direction-region"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class="form-group" style="text-align: center;">\n            <button class="btn popupBtn-cancel cancel-b js-cancel-popup" style="padding: 3px 15px 0 15px;">\n                <span class="mdi mdi-close" style="font-size: 30px;"></span>\n            </button>\n\n            <input type="file"\n                   id="run-xml-input"\n                   class="hidden js-select-file-from-computer" data-icon="true"\n                   accept="' +
((__t = ( accept )) == null ? '' : __t) +
'"/>\n\n            <label for="blank" class="btn popupBtn-confirm btn-sm run-import"\n                   style="padding: 0 15px 0 15px;"\n                   id="importResultFromLocal" title="' +
((__t = ( _.i18n('run.import.folder.local') )) == null ? '' : __t) +
'">\n                <span class="mdi mdi-monitor" style="font-size: 30px; color: inherit;"></span>\n            </label>\n            <input type="text" id="blank" class="hidden js-blank"/>\n\n            ';
 if(remoteAvailable) { ;
__p += '\n            <button class="btn popupBtn-confirm btn-sm importFileFromCentralLib"\n                    style="padding: 0 15px 0 15px;"\n                    id="importResultFromRemote" title="' +
((__t = ( _.i18n('run.import.folder.remote') )) == null ? '' : __t) +
'">\n                <span class="mdi mdi-folder-network" style="font-size: 30px; color: inherit;"></span>\n            </button>\n            ';
 } ;
__p += '\n        </div>\n\n        <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n    </div>\n</div>';

}
return __p
};});

