define('dynamicCreateEditCustomListToEntityItem',[
    'module',
    'formView',
    'backbone',
    'underscore'
], function (
    module,
    FormView,
    Backbone,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template('<%= value %>'),
        className: 'label clickable',
        tagName: 'span',
        events: {
            'click': 'onClick'
        },

        serializeData: function () {
            return {value: this.model.get('code')};
        },
        onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.model.getEntity().showEditAdd(this.model, _.bind(this.render, this));
        }
    });
});
