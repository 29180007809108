
define('template!usersCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n\t<div class="my-vector-popup-container">\n\t\t<form class="form-horizontal">\n\t\t\t<fieldset>\n\t\t\t\t<!-- Email  -->\n\t\t\t\t<div class="form-group" data-field-name="email">\n\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t<input type="email" class="form-control floating-label js-info-input inputBackground" data-field-name="email"\n\t\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'" value="' +
((__t = ( email )) == null ? '' : __t) +
'">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<!-- First name  -->\n\t\t\t\t<div class="form-group" data-field-name="firstName">\n\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input inputBackground"\n\t\t\t\t\t\t\t   data-field-name="firstName" placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   value="' +
((__t = ( firstName )) == null ? '' : __t) +
'">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<!-- Last name  -->\n\t\t\t\t<div class="form-group" data-field-name="lastName">\n\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input inputBackground" data-field-name="lastName"\n\t\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( lastName )) == null ? '' : __t) +
'">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<!-- NickName  -->\n\t\t\t\t<div class="form-group" data-field-name="nickName">\n\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input inputBackground" data-field-name="nickName"\n\t\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('common.nickName') )) == null ? '' : __t) +
'" value="' +
((__t = ( nickName )) == null ? '' : __t) +
'">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="form-group" data-field-name="type">\n\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t<label>' +
((__t = ( _.i18n('user.access.rights') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-12 js-types" >\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="form-group" data-field-name="authorityGroups">\n\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t<label>' +
((__t = ( _.i18n('user.authorityGroups') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-12 js-authorityGroups">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</fieldset>\n\t\t\t<!-- cancel/confirm row -->\n\t\t\t<div class="row cancelConfirmRow">\n\t\t\t\t<div class="pull-right col-xs-12" style="text-align: end;">\n\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n\t\t\t\t\t<button disabled\n\t\t\t\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</form>\n\t</div>\n</div>';

}
return __p
};});

