define('entities/admin/translations',['module', 'backbone', 'app', 'settings', 'jquery'],
    function (module, Backbone, App, Settings, $) {
        'use strict';

        var TranslationItem = Backbone.Model.extend({
            defaults: {
                code: '',
                text: {
                    localized: {}
                },
                app: 'dashboard'
            },

            url: Settings.url('compuzz', 'public/texts'),

            save: function () {
                var that = this;
                Backbone.Model.prototype.save.apply(this, [{}, {
                    success: function () {
                        that.trigger('saved');
                    }
                }]);
            },

            destroyTranslation: function () {
                this.url = Settings.url('compuzz', 'public/texts/' + this.get('secId'));
                this.destroy();
            },

            idAttribute: 'secId'
        });

        var TranslationCollection = Backbone.Collection.extend({
            model: TranslationItem,
            filters: {
                pageIndex: 0,
                pageSize: 15
            },

            url: function () {
                return Settings.url('compuzz', 'public/texts', this.filters);
            }
        });

        var API = {
            getTranslationsCount: function (params) {
                var defer = $.Deferred();

                $.ajax({
                    url: Settings.url('compuzz', 'public/texts/rowCount', params),
                    success: function (response) {
                        defer.resolve(response);
                    }
                });

                return defer.promise();
            },

            uploadTranslations: function (formData) {
                var defer = $.Deferred();

                defer.resolve($.ajax({
                    type: 'POST',
                    url: Settings.url('compuzz', 'v2/i18n.json/excel'),
                    data: formData,
                    dataType: 'json',
                    processData: false,
                    contentType: false
                }));

                return defer.promise();
            }
        };

        App.reqres.setHandler('translations:collection', function () {
            return new TranslationCollection();
        });

        App.reqres.setHandler('translations:model', function (translationJSON) {
            return new TranslationItem(translationJSON);
        });

        App.reqres.setHandler('translations:count', function (params) {
            return API.getTranslationsCount(params);
        });

        App.reqres.setHandler('translations:excel:download', function () {
            return Settings.url('compuzz', 'v2/i18n.json/excel');
        });

        App.reqres.setHandler('translations:upload', function (formData) {
            return API.uploadTranslations(formData);
        });

    });
