
define('template!standardCurve', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="form-inputs-container">\n        <div class="form-group">\n            <label class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.code.name') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-8">\n                <div class="col-xs-4 p-l-0" data-field-name="code">\n                    <input type="text" class="form-control js-info-input inputBackground" data-field-name="code"\n                           placeholder="" value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="common.duplicatedCodeError"\n                           data-placement="top"\n                           autocomplete="off">\n                </div>\n                <div class="col-xs-8 p-r-0" data-field-name="name">\n                    <input type="text" class="form-control js-info-input inputBackground" data-field-name="name"\n                           placeholder=""\n                           value="' +
((__t = ( name )) == null ? '' : __t) +
'" autocomplete="off">\n                </div>\n            </div>\n        </div>\n        <div class="form-group">\n            <label class="col-xs-4 text-right" data-field-name="dilution">' +
((__t = ( _.i18n('standardCurve.dilution.name')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-1" data-field-name="dilution">\n                <input type="text"\n                       class="form-control js-info-input inputBackground t-a-r ' +
((__t = ( alreadyValues ? 'disabled' : '' )) == null ? '' : __t) +
'"\n                       data-field-name="dilution"\n                       placeholder=""\n                       value="' +
((__t = ( dilution )) == null ? '' : __t) +
'" autocomplete="off" ' +
((__t = ( alreadyValues ? 'readOnly disabled' : '' )) == null ? '' : __t) +
'>\n            </div>\n        </div>\n        <div class="form-group">\n            <label class="col-xs-4 text-right">' +
((__t = ( _.i18n('standardCurve.ct.name') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-1" data-field-name="fromCt">\n                <input type="text" class="form-control js-info-input inputBackground t-a-r" data-field-name="fromCt"\n                       placeholder=""\n                       value="' +
((__t = ( fromCt )) == null ? '' : __t) +
'" autocomplete="off">\n            </div>\n            <div class="col-xs-1" data-field-name="toCt">\n                <input type="text" class="form-control js-info-input inputBackground t-a-r" data-field-name="toCt"\n                       placeholder=""\n                       value="' +
((__t = ( toCt )) == null ? '' : __t) +
'" autocomplete="off">\n            </div>\n        </div>\n        <div class="form-group">\n            <div class="col-xs-6 js-samples" style="padding-right: 2px;">\n            </div>\n            <div class="col-xs-6" style="padding-left: 2px;">\n                <label class="col-xs-12">' +
((__t = ( _.i18n('standardCurve.descriptionKitLot.name') )) == null ? '' : __t) +
'</label>\n                <textarea class="form-control js-info-input inputBackground" data-field-name="descriptionKitLot">' +
((__t = ( descriptionKitLot )) == null ? '' : __t) +
'</textarea>\n            </div>\n        </div>\n\n        <div class="form-group js-results"></div>\n        <div class="col-xs-12 js-description"></div>\n        <div class="row cancelConfirmRow">\n            <div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                <button class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

