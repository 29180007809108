define('exportResultStatView',[
	'module',
	'app',
	'underscore',
	'jquery',
	'dialogFormView',
	'template!exportResultStatTpl',
	'backbone',
	'exportResult',
	'entities/caccounts/pcrruns'
], function (
	module,
	App,
	_,
	$,
	DialogFormView,
	exportResultStatTpl,
	Backbone,
	ExportResultByLisView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: exportResultStatTpl,

		ui: {
			qualityControlType: '#qualityControlType',
			resultType: '#resultType',
			resultTestType: '#resultTestType',
			repeatType: '#repeatType',
			qualityControlCheckbox: '#qualityControlCheckbox',
			resultCheckbox: '#resultCheckbox',
			resultTestCheckbox: '#resultTestCheckbox',
			repeatCheckbox: '#repeatCheckbox',
			exportBtn: '.js-export',
			reexportBtn: '.js-reexport',
			result: '.js-export-result',
			resultTest: '.js-export-result-test',
			qualityControl: '.js-export-qc',
			repeat: '.js-export-repeat'
		},

		events: {
			'click .js-cancel-popup': 'hide',
			'click .js-export': 'onExport',
			'click .js-reexport': 'onReexport',
			'change .assayRow': 'onSelectedAssay'
		},
		regions: {
			lisRegion: '.js-lis'
		},

		initialize: function () {
			this.model = new Backbone.Model();
			this.model.set('byLis', new Backbone.Collection());
			_.each(this.options.stats.byLis, _.bind(function (byLis) {
				var model = new Backbone.Model(byLis);
				model.set('configurationByTypes', new Backbone.Collection(model.get('configurationByTypes')));
				model.get('configurationByTypes').parent = model;
				this.model.get('byLis').push(model);
			}, this));

		},

		onShow: function () {
			$.material.init();
			this.onSelectedAssay();
			this.getRegion('lisRegion').show(new ExportResultByLisView({
				model: this.model,
				collection: this.model.get('byLis')
			}));
		},

		serializeData: function () {
			var templateData = this.options.stats;

			templateData.idCounter = 0;
			templateData.enabledExport = false;
			templateData.enabledReExport = false;

			var assays = _.countBy(templateData.exportResultStats, function (exportResultStat) {
				return exportResultStat.line.assay.code;
			});
			_.each(templateData.exportResultStats, function (exportResultStat) {
				exportResultStat.total = (exportResultStat.toExport.result.length + exportResultStat.toExport.resultTest.length + exportResultStat.toExport.qualityControl.length + exportResultStat.toExport.repeat.length) +
					(exportResultStat.reExport.result.length + exportResultStat.reExport.resultTest.length + exportResultStat.reExport.qualityControl.length + exportResultStat.reExport.repeat.length) +
					(exportResultStat.notReady.result.length + exportResultStat.notReady.resultTest.length + exportResultStat.notReady.qualityControl.length + exportResultStat.notReady.repeat.length);

				if (exportResultStat.validated && (exportResultStat.toExport.result.length > 0 || exportResultStat.toExport.resultTest.length > 0 || exportResultStat.toExport.repeat.length > 0 || exportResultStat.toExport.qualityControl.length > 0)) {
					templateData.enabledExport = true;
					exportResultStat.readyToExport = true;
				}
				if (exportResultStat.validated && (exportResultStat.reExport.result.length > 0 || exportResultStat.reExport.resultTest.length > 0 || exportResultStat.reExport.repeat.length > 0 || exportResultStat.reExport.qualityControl.length > 0)) {
					templateData.enabledReExport = true;
					exportResultStat.readyToReExport = true;
				}
				if (assays[exportResultStat.line.assay.code] > 1) {
					exportResultStat.line.assay.code = exportResultStat.line.assay.code + ' (' + _.pluck(exportResultStat.line.pcrKitLots, 'code') + ')';
				}
			});
			return templateData;
		},

		onExport: function () {
			var params = {reexport: false, formatByType: [], lineSelected: []};
			this.onExportFunction(params);
		},
		onReexport: function () {
			var params = {reexport: true, formatByType: [], lineSelected: []};
			this.onExportFunction(params);
		},

		onExportFunction: function (params) {
			$('.exportResultPopup input.assayRow:checked').each(function () {
				params.lineSelected.push($(this).attr('data-lineSecId'));
			});

			params.byLis = [];
			this.model.get('byLis').each(_.bind(function (byLis) {
				var configurationByTypes = [];
				byLis.get('configurationByTypes').each(_.bind(function (configurationByType) {
					if (configurationByType.get('configuration')) {
						var action = 'toExport';
						if (params.reexport) {
							action = 'reExport';
						}
						var type;
						switch (configurationByType.get('type')) {
							case 'EXPORT_RESULT':
								type = 'result';
								break;
							case 'EXPORT_RESULT_TEST':
								type = 'resultTest';
								break;
							case 'EXPORT_QUALITY_CONTROL':
								type = 'qualityControl';
								break;
							case 'EXPORT_REPEAT':
								type = 'repeat';
								break;
						}

						var result = _.map(this.options.stats.exportResultStats, function (stat) {
							if(stat.validated) {
								return stat[action][type].length;
							}
							return 0;
						});

						result = _.reduce(result, function (m, n) {
							return m + n;
						}, 0);

						if (result > 0) {
							configurationByTypes.push({
								type: configurationByType.get('type'),
								lisConfigurationSecId: configurationByType.get('configuration').secId
							});
						}
					}
				}, this));
				params.byLis.push({lisSecId: byLis.get('lis').secId, configurationByTypes: configurationByTypes});
			}, this));
			this.trigger('export', params);
			this.hide();
		},

		hide: function () {
			this.triggerMethod('hide');
			this.box.modal('hide');
			this.destroy();
		},

		onSelectedAssay: function () {
			var selectedAssays = [];
			$('.exportResultPopup input.assayRow:checked').each(function () {
				selectedAssays.push($(this).attr('data-lineSecId'));
			});
			var enabledExport = false;
			var enabledReExport = false;

			_.each(this.options.stats.exportResultStats, function (exportResultStat) {
				exportResultStat.total = (exportResultStat.toExport.result.length + exportResultStat.toExport.resultTest.length + exportResultStat.toExport.qualityControl.length + exportResultStat.toExport.repeat.length) +
					(exportResultStat.reExport.result.length + exportResultStat.reExport.resultTest.length + exportResultStat.reExport.qualityControl.length + exportResultStat.reExport.repeat.length) +
					(exportResultStat.notReady.result.length + exportResultStat.notReady.resultTest.length + exportResultStat.notReady.qualityControl.length + exportResultStat.notReady.repeat.length);
				if (selectedAssays.includes(exportResultStat.line.secId)) {
					if (exportResultStat.validated && (exportResultStat.toExport.result.length > 0 || exportResultStat.toExport.resultTest.length > 0 || exportResultStat.toExport.repeat.length > 0 || exportResultStat.toExport.qualityControl.length > 0)) {
						enabledExport = true;
					}
					if (exportResultStat.validated && (exportResultStat.reExport.result.length > 0 || exportResultStat.reExport.resultTest.length > 0 || exportResultStat.reExport.repeat.length > 0 || exportResultStat.reExport.qualityControl.length > 0)) {
						enabledReExport = true;
					}
				}
			});

			if (enabledExport) {
				this.ui.exportBtn.removeClass('invisible');
			} else {
				this.ui.exportBtn.addClass('invisible');
			}
			if (enabledReExport) {
				this.ui.reexportBtn.removeClass('invisible');
			} else {
				this.ui.reexportBtn.addClass('invisible');
			}

			var displayExportButton = _.any(this.options.stats.exportResultStats, function (exportResultStat) {
				return exportResultStat.validated && selectedAssays.includes(exportResultStat.line.secId) && exportResultStat.toExport.result.length > 0;
			});
			var displayReExportButton = _.any(this.options.stats.exportResultStats, function (exportResultStat) {
				return exportResultStat.validated && selectedAssays.includes(exportResultStat.line.secId) && exportResultStat.reExport.result.length > 0;
			});
			this.ui.result.attr('disabled', !displayExportButton && !displayReExportButton);
			if (!displayExportButton && !displayReExportButton) {
				this.ui.resultCheckbox.attr('checked', false);
			}

			displayExportButton = _.any(this.options.stats.exportResultStats, function (exportResultStat) {
				return exportResultStat.validated && selectedAssays.includes(exportResultStat.line.secId) && exportResultStat.toExport.resultTest.length > 0;
			});
			displayReExportButton = _.any(this.options.stats.exportResultStats, function (exportResultStat) {
				return exportResultStat.validated && selectedAssays.includes(exportResultStat.line.secId) && exportResultStat.reExport.resultTest.length > 0;
			});
			this.ui.resultTest.attr('disabled', !displayExportButton && !displayReExportButton);
			if (!displayExportButton && !displayReExportButton) {
				this.ui.resultTestCheckbox.attr('checked', false);
			}

			displayExportButton = _.any(this.options.stats.exportResultStats, function (exportResultStat) {
				return exportResultStat.validated && selectedAssays.includes(exportResultStat.line.secId) && exportResultStat.toExport.qualityControl.length > 0;
			});
			displayReExportButton = _.any(this.options.stats.exportResultStats, function (exportResultStat) {
				return exportResultStat.validated && selectedAssays.includes(exportResultStat.line.secId) && exportResultStat.reExport.qualityControl.length > 0;
			});
			this.ui.qualityControl.attr('disabled', !displayExportButton && !displayReExportButton);
			if (!displayExportButton && !displayReExportButton) {
				this.ui.qualityControlCheckbox.attr('checked', false);
			}

			displayExportButton = _.any(this.options.stats.exportResultStats, function (exportResultStat) {
				return exportResultStat.validated && selectedAssays.includes(exportResultStat.line.secId) && exportResultStat.toExport.repeat.length > 0;
			});
			displayReExportButton = _.any(this.options.stats.exportResultStats, function (exportResultStat) {
				return exportResultStat.validated && selectedAssays.includes(exportResultStat.line.secId) && exportResultStat.reExport.repeat.length > 0;
			});
			this.ui.repeat.attr('disabled', !displayExportButton && !displayReExportButton);
			if (!displayExportButton && !displayReExportButton) {
				this.ui.repeat.attr('checked', false);
			}
		}
	});
});
