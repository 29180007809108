define('entities/caccounts/userpids',[
    'app',
    'jquery',
    'backbone',
    'settings',
    'module',
    'dynamic',
    'underscore',
    'entities/global/userpids',
    'entities/admin/codelist',
    'entities/caccounts/authorityGroup',
    'backboneRelational'
], function (
    App,
    $,
    Backbone,
    Settings,
    module,
    Dynamic,
    _,
    UserpidGlobal,
    CodeLists
) {
    'use strict';

    /**
     * Model
     */
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.UserPid = app.ns.UserPidGlobal.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/userpid/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            email: undefined,
            firstName: undefined,
            lastName: undefined,
            nickName: undefined,
            types: undefined,
            authorityGroups: undefined,
            roles: undefined
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'authorityGroups',
            relatedModel: 'AuthorityGroup',
            collectionType: 'AuthorityGroupCollection'
        }],
        save: function () {
            this.url = Settings.url('compuzz', 'v2/userpid' + (this.get('secId') ? '/' + this.get('secId') : ''));
            return Backbone.Model.prototype.save.call(this);
        },

        getEntity: function () {
            return module.exports;
        },
        hasType: function (typeSearch) {
            return _.any(this.get('types'), function (type) {
                return typeSearch === type;
            });
        },
        hasRole: function (roleSearch) {
            return _.any(this.get('roles'), function (role) {
                return roleSearch === role;
            });
        }
    });

    module.exports = _.defaults({

        getCreateEditFieldValidate: function () {
            return [
                {name: 'email', type: 'required'},
                {name: 'firstName', type: 'required'},
                {name: 'lastName', type: 'required'},
                {name: 'nickName', type: 'required'},
                {name: 'logonId', type: 'required'}
            ];
        },

        isPublic: function () {
            return false;
        },
        getUrl: function () {
            return 'v2/userpid';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('userpid.firstName'),
                name: 'firstName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'firstName',
                width: 30
            }, {
                label: _.i18n('userpid.lastName'),
                name: 'lastName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'lastName',
                width: 50
            }, {
                label: _.i18n('userpid.logonId'),
                name: 'logonId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'logonId',
                width: 50
            }, {
                label: _.i18n('userpid.nickName'),
                name: 'nickName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'nickName',
                width: 20
            }, {
                label: _.i18n('userpid.email'),
                name: 'email',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'email',
                width: 50
            }, {
                label: _.i18n('userpid.type'),
                name: 'types',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'type',
                width: 50
            }, {
                label: _.i18n('userpid.authorityGroups'),
                name: 'authorityGroups',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                search: false,
                index: 'type',
                width: 20
            });
            return columns;
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.urlPath = (config.url ? config.url : this.getUrl()) + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'nickName'
            }));
            return configuration;
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.UserPid.findOrCreate(modelJson);
        },
        getByGlobal: function (secIdGlobalUser) {
            var url = Settings.url('compuzz', 'v2/userpid/byGlobal/' + secIdGlobalUser),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (json) {
                    defer.resolve(this.getModel(json));
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        findUserAccessRights: function () {
            var url = Settings.url('compuzz', 'v2/userpid/userAccessRights'),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (json) {
                    defer.resolve(json);
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        getRoles: function () {
            var defer = $.Deferred();

            var url = Settings.url('compuzz', 'v2/userpid/roles');
            $.ajax({
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration();
            configuration.done(function (resp) {
                resp.push({fieldName: 'types', values: CodeLists.findItemsByCode('ARIGHTS')});
                resp.push({fieldName: 'authorityGroups', values: this.getRoles()});
                defer.resolve(resp);
            });
            return defer.promise();
        },
        getCurrentUser: function () {
            var defer = $.Deferred();

            var url = Settings.url('compuzz', 'v2/userpid/currentUser');
            $.ajax({
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        }
    }, UserpidGlobal);

});
