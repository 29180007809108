
define('template!targetsViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="" style="display: flex; padding: 0; margin: 0;"> <!--valViewHeaderBoxShadow-->\n    <div class="p-l-2 p-r-2">\n        ';
 if(currentAssayTarget !== min){ ;
__p += '\n        <div class="p-l-0 p-r-0 previous btn-previous-css btn-previous clickable">\n            <div class="previousTargetTriangleIcon"></div>\n            <div class="previousTargetTriangleIcon2"></div>\n        </div>\n        ';
 } else { ;
__p += '\n        <div class="p-l-0 p-r-0 previous btn-previous-css">\n            <div class="previousTargetTriangleIcon" style="opacity: 0.2;"></div>\n            <div class="previousTargetTriangleIcon2"></div>\n        </div>\n        ';
 } ;
__p += '\n    </div>\n    <div class="p-l-2 p-r-2 headerTargetDropdown">\n        <div class="p-l-0 p-r-0 groupCellTitle" style="position: relative; bottom: 6px;">\n            <select class="form-control js-info-input-assay" data-field-name="selectedAssay" id="selectedAssay"\n                    style="background-color: white;">\n                ';
 _.each(assays, function(assay) { ;
__p += '\n                <option value="' +
((__t = ( assay.secId )) == null ? '' : __t) +
'"\n                ' +
((__t = ( currentAssay == assay.secId ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( assay.code )) == null ? '' : __t) +
'\n                </option>\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        <div class="p-l-0 p-r-0">\n            <select class="form-control js-info-input-target" data-field-name="selectedTarget" id="selectedTarget"\n                    style="background-color: white;">\n                ';
 _.each(results, function(result) { ;
__p += '\n                <option value="' +
((__t = ( result.id )) == null ? '' : __t) +
'"\n                ' +
((__t = ( currentTarget == result ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( result.target.code )) == null ? '' : __t) +
'\n                </option>\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n    </div>\n    <div class="p-l-2 p-r-2">\n        ';
 if(currentAssayTarget < max){ ;
__p += '\n        <div class="p-l-0 p-r-0 next btn-next-css btn-next clickable">\n            <div class="nextTargetTriangleIcon"></div>\n            <div class="nextTargetTriangleIcon2"></div>\n        </div>\n        ';
 } else { ;
__p += '\n        <div class="p-l-0 p-r-0 next btn-next-css">\n            <div class="nextTargetTriangleIcon" style="opacity: 0.2;"></div>\n            <div class="nextTargetTriangleIcon2"></div>\n        </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';

}
return __p
};});

