/**
 * Created by BFR on 17/01/2018.
 */
define('targetsView',[
	'module',
	'app',
	'underscore',
	'marionette',
	'template!targetsViewTpl',
	'entities/caccounts/pcrwells',
	'entities/caccounts/runats'
], function (
	module,
	App,
	_,
	Marionette,
	viewTpl
) {
	'use strict';

	module.exports = Marionette.LayoutView.extend({
		template: viewTpl,

		events: {
			'change .js-info-input-assay': 'onBtnAssayClick',
			'change .js-info-input-target': 'onBtnTargetClick',
			'click .btn-previous': 'onBtnPreviousClick',
			'click .btn-next': 'onBtnNextClick'
		},

		modelEvents: {
			'change': 'render'
		},

		initialize: function (options) {
			this.currentAssayTarget = options.currentAssayTarget;
			this.assays = options.assays;
			this.assayTargets = options.assayTargets;
		},

		serializeData: function () {
			var templateData = {};
			templateData.assayTargets = this.assayTargets;
			templateData.assays = _.uniq(_.pluck(this.assayTargets, 'assay'), false, function (assay) {
				return assay.code;
			});
			templateData.assayCode = this.options.currentAssayTarget.assay.code;
			templateData.currentAssayTarget = _.indexOf(this.assayTargets, this.currentAssayTarget);
			templateData.currentTarget = this.currentAssayTarget;
			templateData.currentAssay = this.currentAssayTarget.assay.secId;
			templateData.results = _.where(this.assayTargets, _.bind(function (assayResult) {
				return assayResult.needValidation && assayResult.assay.secId === this.currentAssayTarget.assay.secId;
			}, this));
			templateData.min = 0;
			templateData.max = this.assayTargets.length - 1;
			return templateData;
		},

		onBtnAssayClick: function (event) {
			var selectedAssaySecId = event.target.value;
			this.selectedAssayTargets = [];
			_.each(this.assays, _.bind(function (assay) {
				if (assay.secId === selectedAssaySecId) {
					if (assay.results.length > 0) {
						var concatResults = [].concat(this.selectedAssayTargets, assay.results);
						this.selectedAssayTargets = concatResults;
					}
				}
			}, this));

			this.currentAssayTarget = _.first(this.selectedAssayTargets);

			this.trigger('well:target:click', this.currentAssayTarget);
		},

		onBtnTargetClick: function (event) {
			var selectedTargetSecId = parseInt(event.target.value, 10);

			_.each(this.assayTargets, _.bind(function (assay) {
				if (assay.id === selectedTargetSecId) {
					this.currentAssayTarget = assay;
				}
			}, this));

			this.trigger('well:target:click', this.currentAssayTarget);
		},

		onBtnPreviousClick: function () {
			var i = _.indexOf(this.assayTargets, this.currentAssayTarget) - 1;
			if (i >= 0) {
				this.trigger('well:target:click', this.assayTargets[i]);
			}
		},

		onBtnNextClick: function () {
			var i = _.indexOf(this.assayTargets, this.currentAssayTarget) + 1;
			if (i <= this.assayTargets.length) {
				this.trigger('well:target:click', this.assayTargets[i]);
			}
		}
	});
});
