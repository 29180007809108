define('pcrRunTemplateController',[
    'module',
    'underscore',
    'createEditLayoutPcrTemplatesView',
    'entities/caccounts/pcrruntemplates',
    'entities/caccounts/setting/defaultCheckbox'
], function (
    module,
    _,
    CreateEditLayoutView,
    PcrRunTemplates,
    DefaultCheckBox
) {
    'use strict';

    module.exports = {
        showDetails: function (model, callBackRefresh) {
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
			var setting = DefaultCheckBox.getModel();
			setting.fetch().done(_.bind(function () {
            var createEditView = new CreateEditLayoutView({
                model: model,
				printRunSheet: setting.get('pcrPrintRunSheet')
            });

                createEditView.show({
                    title: _.i18n(PcrRunTemplates.getName() + '.new'),
                    className: 'baseTableEditAddPopup'
                }, _.bind(function () {
                    if (callBackRefresh) {
                        callBackRefresh();
                    }
                }, callBackRefresh ? callBackRefresh.context : undefined));
            }, this));
        }
    };
});
