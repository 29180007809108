define('runCreatePcrRunFromCyclerView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!runCreatePcrRunFromCyclerTpl',
    'savingBehavior',
    'bootbox',
    'underscore',
    'app',
    'settings',
    'autocompleteView',
    'moment',
    'entities/caccounts/pcrruns',
    'entities/createRunFromTemplate'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    bootbox,
    _,
    App,
    Settings,
    AutocompleteView,
    moment,
    PcrRuns
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            inputName: '.run-input-name',
            createRunRadio: '.create-run-radio',
            createRunFromTemplate: '.createRunFromTemplate',
            createRunFromCyclerExport: '.createRunFromCyclerExport',
            uploadRunsCyclerExport: '.js-upload-runs-cycler-export',
            selectedDateRegion: '.js-select-date-region',
            selectedDate: '.date-select',
            numberOfRunRegion: '.js-number-of-run-region',
            numberOfRun: '.number-of-run',
            generateStarterSheet: '.generate-starter-sheet',
            exportPlateLayout: '.export-plate-layout',
            template384: '.template384',
            selectFileFormat: '.js-select-fileFormat-region',
            blockLoading: '.empty-page-loader'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'change @ui.createRunRadio': 'onCreateRunRadioChange',
            'change @ui.uploadRunsCyclerExport': 'onLoadFilePlateLayout',
            'change @ui.selectedDate': 'onChangeDate',
            'change @ui.generateStarterSheet': 'onChangeGenerateStarterSheet',
            'change @ui.exportPlateLayout': 'onChangeExportPlateLayout'
        },

        regions: {
            selectRunTemplate: '.js-select-run-template-region',
            selectKitProtFromCyclerExport: '.js-select-kitprot-region-from-cycler-export',
            selectKitProt: '.js-select-kitprot-region',
            selectPlateTopLeft: '#select-plate-region-top-left',
            selectPlateTopRight: '#select-plate-region-top-right',
            selectPlateBottomLeft: '#select-plate-region-bottom-left',
            selectPlateBottomRight: '#select-plate-region-bottom-right',
            selectAssay: '#select-assay-region',
            selectFileFormat: '.js-select-fileFormat-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'name', type: 'required'},
            {name: 'selectedDate', type: 'required'}
        ],

        onCancel: function () {
            this.box.modal('hide');
        },

        initialize: function (options) {
            this.model = options.model;
            this.currentMode = 'fromCyclerExport';
            this.generateStarterSheet = false;
        },

        serializeData: function () {
            this.model.set('numberOfRun', 1);
            this.model.set('selectedDate', Number(moment().format('x')));
            this.model.set('currentDate', moment().format('DD-MM-YYYY'));
            this.model.set('generateStarterSheet', false);
            return this.model.toJSON();
        },

        onDatePickerChange: function (fieldName) {
            this.$el.find(this.ui.selectedDate).val(moment(this.model.get(fieldName)).format('DD-MM-YYYY'));
            this.ui.selectedDate.removeClass('empty');
            App.request('run:pcr:available-for-run-template', this.model.get('refRunTemplate').get('secId'),
                {
                    selectedDate: this.model.get(fieldName)
                }
            ).done(_.bind(function (model) {
                this.$el.find(this.ui.numberOfRun).attr('max', parseInt(model, 10));
            }, this));
        },

        onChangeDate: function (event) {
            if (event.target.value === '') {
                this.model.set('selectedDate', -1);
            } else {
                this.model.set('selectedDate', Number(moment(event.target.value, 'DD-MM-YYYY').format('x')));
                this.onDatePickerChange('selectedDate');
            }
        },

        onChangeGenerateStarterSheet: function (event) {
            this.generateStarterSheet = event.target.checked;
        },

        onChangeExportPlateLayout: function (event) {
            this.exportPlateLayout = event.target.checked;
        },

        onCreateRunRadioChange: function () {
            if (this.ui.createRunRadio) {
                this.ui.createRunFromTemplate.toggleClass('hidden', true);
                this.ui.createRunFromCyclerExport.toggleClass('hidden', true);
                _.each(this.ui.createRunRadio, _.bind(function (radio) {
                    switch (radio.value) {
                        case 'fromTemplate':
                            if (radio.checked) {
                                this.ui.createRunFromTemplate.toggleClass('hidden', false);
                            }
                            break;
                        case 'fromCyclerExport':
                            if (radio.checked) {
                                this.ui.createRunFromCyclerExport.toggleClass('hidden', false);
                            }
                            break;
                    }
                    if (radio.checked) {
                        this.currentMode = radio.value;
                    }
                }, this));
            }
        },

        onLoadFilePlateLayout: function (e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.currentFile = e.target.files[0];
            }
        },

        onShow: function () {
            $.material.init();
            this.getRegion('selectRunTemplate').options.allowMissingEl = true;
            this.getRegion('selectRunTemplate').show(new AutocompleteView(
                this._getAutocompleteOptions('refRunTemplate', 'name', 'name', 'v2/runtemplates/pcr?sord=asc&sidx=seq',
                    'selectruntemplate.placeholder', 'name', this.onTemplateChange)
            ));
            this.getRegion('selectKitProtFromCyclerExport').show(new AutocompleteView(
                this._getAutocompleteOptions('refKitProt', 'code', 'code', 'v2/kitprots',
                    'selectkitprot.placeholder', 'code', this.onKitProtChange)
            ));
            this.triggerMethod('enable:cancel:confirm');
        },

        onChangeFileFormat: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.fileFormatSecId = model.get('secId');
        },

        onRender: function () {
            this.initDatePicker(this.ui.selectedDate, 'selectedDate');
        },

        onKitProtChange: function (fieldName, model) {
            this.currentKitProt = model;
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model);
            if (model) {
                if (!this.ui.inputName.val()) {
                    this.ui.inputName.val(model.get('runNamePattern'));
                    this.ui.inputName.change();
                }
            }
        },

        onTemplateChange: function (fieldName, model) {
            this.model.set('refRunTemplate', model);
            var displayTemplateDate = false;
            var displayTemplateNumberOfRuns = false;
            var displayTemplate384 = false;

            this.ui.selectedDate.removeClass('invalid');
            this.ui.selectedDateRegion.closest('div[data-field-name=selectedDate]').removeClass('has-error');

            this.ui.numberOfRun.removeClass('invalid');
            this.ui.numberOfRunRegion.closest('div[data-field-name=numberOfRun]').removeClass('has-error');

            this.model.set('numberOfRun', 1);
            this.model.set('selectedDate', Number(moment().format('x')));
            this.model.set('currentDate', moment().format('DD-MM-YYYY'));

            if (model !== null) {
                var pattern = model.get('pattern');
                displayTemplateDate = true; //pattern.includes('P'); //period
                displayTemplateNumberOfRuns = pattern.includes('S'); //sequence
                displayTemplate384 = model.get('wellCount') > 96;
            }

            this.ui.selectedDateRegion.toggleClass('hidden', !displayTemplateDate);
            this.ui.numberOfRunRegion.toggleClass('hidden', !displayTemplateNumberOfRuns);
            this.ui.template384.toggleClass('hidden', !displayTemplate384);
            this.exportPlateLayout = false;
            this.ui.exportPlateLayout[0].checked = false;
            this.fileFormatSecId = null;
        },

        onConfirm: function () {
            this.trigger('run:create-from-popup', this.currentFile, this.currentLmbCycler, this.currentKitProt, false, this.generateStarterSheet);
            this.hide();
        },

        onSave: function (model) {
            var modelJSON = model.toJSON();
            this.model.set('wells', this.wells);
            this.options.runModel.set(modelJSON);
            this.triggerMethod('saved');
            App.trigger('run:header:rerender', modelJSON);
            App.navigate('runs/pcr/' + model.get('secId') + '/edit', {trigger: true});
            if (this.generateStarterSheet) {
                PcrRuns.generateRunSheet(model.get('secId'));
            }
            this.hide();
        },

        hide: function () {
            this.triggerMethod('hide');
            this.destroy();
            this.box.modal('hide');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },

        onFocusOut: function () {
            var $target,
                datepicker;

            // close popover when click outside
            setTimeout(_.bind(function () {
                $target = $(document.activeElement);
                datepicker = $target.parents('.datepicker-element').length > 0 ||
                    $target.parents('.ui-datepicker').length > 0 ||
                    $target.hasClass('ui-datepicker') ||
                    $target.get(0) === this.$el.get(0) ||
                    $target.hasClass('popover-shown');

                if (!datepicker) this.trigger('close:popover');
            }, this), 0);
        }
    });
});
