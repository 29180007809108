define('entities/caccounts/userCodelist',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'bootbox',
    'cookies',
    'backboneRelational',
    'entities/caccounts/userCodelistelement'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    Bootbox,
    Cookies
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.UserCodeList = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/userCodeLists/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            code: '',
            name: '',
            description: '',
            comment: null,
            tags: [],
            documents: '',
            codeListElements: []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'codeListElements',
            relatedModel: 'UserCodeListElement',
            collectionType: 'UserCodeListElementCollection'
        }],
        postUrl: function () {
            return Settings.url('compuzz', 'v2/userCodeLists');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getEntity: function () {
            return module.exports;
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'usercodelist';
        },
        getUrl: function () {
            return 'v2/userCodeLists';
        },
        getDynamicJson: function () {
            return 'UserCodeListJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.archivable = false;
            config.selectArchivable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'code',
                    'param': {'type': 'CODELIST', 'code': 'USERCODELISTS', 'display': {'class': 'col-xs-12'}}
                });
            return custom;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.UserCodeList.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 550, 'fields': ['name', 'description']};
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            configuration.urlPath = 'v2/userCodeLists/code/' + config.codeListCode + '/elements/?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            }));
            return configuration;
        },
        findItemsByCode: function (code) {
            var url = Settings.url('compuzz', 'v2/userCodeLists/code/' + code + '/elements'),
                defer = $.Deferred();

            if (Cookies.get('gm-autocomplete') === 'true') {
                Bootbox.alert({
                    title: _.i18n('menu.superadmin.godMode'),
                    message: url
                });
            }

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    _.each(data, function (item) {
                        delete item.secId;
                    });
                    defer.resolve(data);
                }
            });

            return defer.promise();
        }
    }, Dynamic);
});

