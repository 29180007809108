define('createEditLayoutPcrTemplatesView',[
	'module',
	'marionette',
	'dialogFormView',
	'template!pcrRunTemplatesCreateEditLayoutTpl',
	'savingBehavior',
	'underscore',
	'jquery',
	'settings',
	'autocompleteView',
	'entities/caccounts/kitprots',
	'entities/caccounts/pcrruntemplates',
	'wellEditSmpIdPlateView',
	'dynamicCreateEditCodeList',
	'dynamicCreateEditUserCodeList',
	'entities/caccounts/platesize',
	'entities/caccounts/cyclerpublics',
	'entities/caccounts/cyclers',
	'runController',
	'dynamicCreateEditDescriptionCommentTags'
], function (
	module,
	Marionette,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	_,
	$,
	Settings,
	AutocompleteView,
	KitProts,
	PcrRunTemplates,
	CreateEditSmpIdPlateLayoutView,
	CodeListView,
	UserCodeListView,
	PlateSizes,
	CyclerPublics,
	Cyclers,
	RunController,
	DynamicCreateEditDescriptionCommentTags
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			cancel: '.js-cancel-popup',
			confirm: '.js-confirm',
			plateLayout: '.js-define-plateLayout',
			periodFormatInfo: '.js-periodFormat-info',
			printRunSheet: '.js-printRunSheet',
			plateSize: '.js-selectPlateSize',


			generateSmpIds: '.js-generate-smpids',
			removeSmpIds: '.js-remove-smpids',
			assignAssay: '.js-assign-assay',
			removeAssay: '.js-remove-assay',
			addTest: '.js-add-test',
			removeTest: '.js-remove-test'
		},

		events: {
			'click .js-cancel-popup': 'onCancel',
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-confirm': 'onConfirm',
			'click @ui.plateLayout': 'onPlateLayout',
			'click @ui.periodFormatInfo': 'onPeriodFormatInfo',
			'change @ui.printRunSheet': 'onChangePrintRunSheet',

			'click @ui.generateSmpIds': 'onGenerateSmpIds',
			'click @ui.removeSmpIds': 'onRemoveSmpIds',
			'click @ui.assignAssay': 'onAssignAssay',
			'click @ui.removeAssay': 'onRemoveAssay',
			'click @ui.addTest': 'onAddTest',
			'click @ui.removeTest': 'onRemoveTest'
		},

		regions: {
			selectRefKitProt: '.js-select-refKitProt',
			selectCyclerPublic: '.js-select-cyclerPublic-region',
			selectCycler: '.js-select-cycler-region',
			selectDepartment: '.js-selectDepartment',
			selectRunGroup: '.js-selectRunGroup',
			selectSequenceFormat: '.js-selectSequenceFormat',
			selectPattern: '.js-selectPattern',
			selectPlateSize: '.js-selectPlateSize',
			descriptionRegion: '.js-description'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		fieldsToValidate: [
			{name: 'code', type: 'required'},
			{name: 'plateSize', type: 'required'}
		],

		onGenerateSmpIds: function () {
			RunController.showGenerateSampleId(this.model, _.bind(function () {
				this.render();
			}, this));
		},
		onRemoveSmpIds: function () {
			this.editPlateLayout('remove-smpId', _.i18n('pcr.platelayout.remove.smpId'));
		},
		onAssignAssay: function () {
			this.editPlateLayout('assign-assay', _.i18n('pcr.platelayout.assign.assay'));
		},
		onRemoveAssay: function () {
			this.editPlateLayout('remove-assay', _.i18n('pcr.platelayout.remove.assay'));
		},
		onAddTest: function () {
			this.editPlateLayout('add-test', _.i18n('pcr.platelayout.add.test'));
		},
		onRemoveTest: function () {
			this.editPlateLayout('remove-test', _.i18n('pcr.platelayout.remove.test'));
		},

		editPlateLayout: function (mode, title) {
			this.createEditSmpIdPlateView = new CreateEditSmpIdPlateLayoutView({
				model: this.model,
				type: 'PcrRunTemplate',
				mode: mode
			});
			var className = 'actionPopupCss popupFitContent';

			if (mode === 'all' || mode === 'scan' || mode === 'edit') {
				className = 'popupEnterPlateLayout actionPopupCss popupFitContent';
			}
			if (mode === 'import' || mode === 'export' || mode === 'control' ||
				mode === 'assign-assay' || mode === 'remove-assay' || mode === 'add-test' || mode === 'remove-test' ||
				mode === 'generate-smpId' || mode === 'remove-smpId' || mode === 'importFromOtherRun'
			) {
				className = 'actionPopupCss popupFitContent actionMiniPopupCss';
			}

			this.createEditSmpIdPlateView.show({
				title: title,
				className: className
			});
		},

		initialize: function (options) {
			this.selectedRunsList = options.selectedRunsList;
		},

		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.newItem = this.model.isNew();
			templateData.selectedRunsList = this.selectedRunsList;

			templateData.sortSequence = this.model.get('sortSequence') ? this.model.get('sortSequence') : '';
			if (this.model.isNew()) {
				templateData.printRunSheet = this.options.printRunSheet;
			}

			return templateData;
		},

		onRender: function () {
			this.getRegion('selectPattern').show(new UserCodeListView({
				model: this.model,
				config: {
					modelProperty: 'pattern',
					code: 'PATTERN_PCR'
				}
			}));
			this.getRegion('selectDepartment').show(new UserCodeListView({
				model: this.model,
				config: {
					modelProperty: 'department',
					code: 'DEPT'
				}
			}));
			this.getRegion('selectRunGroup').show(new UserCodeListView({
				model: this.model,
				config: {
					modelProperty: 'group',
					code: 'RUNGROUP_PCR'
				}
			}));
			this.getRegion('selectSequenceFormat').show(new UserCodeListView({
				model: this.model,
				config: {
					modelProperty: 'sequenceFormat',
					code: 'SEQUENCE'
				}
			}));

			this.showKitProtRegion(true);
			this.showCyclerRegion();
			this.showPlateSizeRegion();
			this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionCommentTags({
				model: this.model
			}));

			this.setPermissions(Settings.get('currentUserModel').hasType('A02'));
		},

		onChangeSelection: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.onChange(fieldName, model ? model.toJSON() : null);
			this.model.trigger('change');
		},

		onEditKitProt: function (secId) {
			var model = KitProts.getModel({secId: secId});
			model.fetch().done(_.bind(function () {
				this.showEditAddKitProtView(model);
			}, this));
		},

		onAddKitProt: function () {
			this.showEditAddKitProtView(KitProts.getModel());
		},

		showEditAddKitProtView: function (model) {
			require(['dynamicController'], _.bind(function (DynamicController) {
				DynamicController.showDetails(model, null, _.bind(function (view) {
						view.on('saved', _.bind(function (model) {
							this.model.set('refKitProt', model.attributes);
							this.autocompleteKitProtView.options.value = model.get('code');
							this.autocompleteKitProtView.render();
						}, this));
					}, this));
			}, this));
		},

		showPlateSizeRegion: function () {
			if (this.model.get('cyclerPublic') && this.model.get('cyclerPublic').plateSize) {
				this.getRegion('selectPlateSize').show(new Marionette.ItemView({
					template: _.template(this.model.get('cyclerPublic').plateSize),
					tagName: 'span'
				}));
				this.model.set('plateSize', this.model.get('cyclerPublic').plateSize);
			} else {
				this.getRegion('selectPlateSize').show(new AutocompleteView(
					this._getAutocompleteOptionObject(PlateSizes.getAutocompleteParam({
						modelProperty: 'plateSize',
						callBackOnChange: this.onChangePlateSize,
						paramUrl: {
							'sord': 'asc',
							'sidx': 'plateSize',
							'cyclerCode': this.model.get('cyclerPublic') ? this.model.get('cyclerPublic').code : null
						}
					}))));
			}
		},

		showCyclerPublicRegion: function (fromKitProtRegion) {
			if (this.model.get('cycler')) {
				this.getRegion('selectCyclerPublic').show(new Marionette.ItemView({
					template: _.template(this.model.get('cycler').refCycler.code),
					tagName: 'span'
				}));
			} else {
				this.getRegion('selectCyclerPublic').show(new AutocompleteView(
					this._getAutocompleteOptionObject(CyclerPublics.getAutocompleteParam({
						modelProperty: 'cyclerPublic',
						callBackOnChange: this.onChangeCyclerPublic,
						paramUrl: {
							'sord': 'asc',
							'sidx': 'code',
							'plateSize': this.model.get('plateSize')
						}
					}))));
				if (!fromKitProtRegion) {
					this.showKitProtRegion(false);
				}
				this.showCyclerRegion();
			}
		},

		showKitProtRegion: function (showCyclerPublicRegion) {
			this.getRegion('selectRefKitProt').show(new AutocompleteView(
				this._getAutocompleteOptionObject(KitProts.getAutocompleteParam({
					modelProperty: 'refKitProt',
					callBackOnChange: this.onChangeSelection,
					callBackAdd: this.onAddKitProt,
					callBackEdit: this.onEditKitProt,
					paramUrl: {
						'sord': 'asc',
						'sidx': 'code',
						'cyclerCode': this.model.get('cyclerPublic') ? this.model.get('cyclerPublic').code : null
					}
				}))));
			if(showCyclerPublicRegion) {
				this.showCyclerPublicRegion(true);
			}
		},

		showCyclerRegion: function () {
			this.getRegion('selectCycler').show(new AutocompleteView(
				this._getAutocompleteOptionObject(Cyclers.getAutocompleteParam({
					modelProperty: 'cycler',
					callBackOnChange: this.onChangeCycler,
					paramUrl: {
						'sord': 'asc',
						'sidx': 'code',
						'plateSize': this.model.get('plateSize'),
						cyclerSecId: this.model.get('cyclerPublic') ? this.model.get('cyclerPublic').secId : null
					}
				}))));
		},

		onChangeCyclerPublic: function (fieldName, model) {
			this.onChange(fieldName, model ? model.toJSON() : null);
			this.showCyclerRegion();
			this.showPlateSizeRegion();
			this.showKitProtRegion(false);
		},

		onChangeCycler: function (fieldName, model) {
			this.onChange(fieldName, model ? model.toJSON() : null);
			if (this.model.get(fieldName)) {
				this.onChange('cyclerPublic', this.model.get(fieldName).refCycler);
			}
			this.showCyclerPublicRegion();
			this.showPlateSizeRegion();
			this.showKitProtRegion(false);
		},

		onChangePlateSize: function (fieldName, model) {
			this.onChange(fieldName, model ? model.get('code') : null);
			this.showCyclerPublicRegion();
		},

		onConfirm: function () {
			if (this.validate(this.fieldsToValidate)) {
				this.model.save();
			}
		},

		onSave: function (model) {
			var modelJSON = model.toJSON();
			this.model.set(modelJSON);
			this.triggerMethod('saved');
			this.box.modal('hide');
		},

		onCancel: function () {
			this.box.modal('hide');
		},

		onSaveError: function (model, response) {
			if (response.status === 409) {
				this.onDuplicateCode();
				this.triggerMethod('hide:label');
			}
		},

		onDuplicateCode: function () {
			this.ui.endUserIdInput.popover('show');
			this.ui.endUserIdInput.addClass('invalid');
			this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
		},

		onChangePrintRunSheet: function (event) {
			this.model.set('printRunSheet', event.target.checked);
		},

		onPlateLayout: function () {
			this.createEditSmpIdPlateView = new CreateEditSmpIdPlateLayoutView({
				model: this.model,
				mode: 'edit'
			});
			this.createEditSmpIdPlateView.show({
				title: _.i18n('run.editSmdIdPlateView.title') + ' - ' + this.model.get('name'),
				className: 'popupEnterPlateLayout'
			});
		}
	});
});
