
define('template!pcrSetupPcrRunItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="headerRun col-xs-12 m-0 p-0">\n    <h3 title="' +
((__t = ( name )) == null ? '' : __t) +
'">' +
((__t = ( name )) == null ? '' : __t) +
'</h3>\n    <div class="header controls">\n        <span class="mdi mdi-plus-circle" data-field-sampleType="PC"></span>\n        <span class="mdi mdi-minus-circle" data-field-sampleType="NC"></span>\n        <span class="mdi mdi-alpha-r-circle" data-field-sampleType="RC"></span>\n        <button class="mdi-navigation-check btn popupBtn-confirm clickable js-confirm"></button>\n    </div>\n</div>\n<div class="header col-xs-12 m-0 p-0">\n    <hr class="js-hr">\n</div>\n\n<div class="header js-wells col-xs-12 m-0 p-0">\n</div>';

}
return __p
};});

