
define('template!routingCommonHeaderView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12 m-b-20">\n    <div class="col-xs-1 back-to-list-sample js-back-to-list clickable m-t-10"\n         title="' +
((__t = ( _.i18n(i18nCode + '.returnToList') )) == null ? '' : __t) +
'">\n        <span class="mdi mdi-reply"></span>\n    </div>\n    <div class="col-xs-2 dispatchSampleHeader">\n        <div class="row header-box ' +
((__t = ( i18nCode )) == null ? '' : __t) +
'">\n            <label class="col-xs-12 text-center">' +
((__t = ( _.i18n(i18nCode + '.action') )) == null ? '' : __t) +
'</label>\n        </div>\n    </div>\n    <div class="col-xs-6 dispatchSampleHeader">\n        <div class="row header-box ' +
((__t = ( i18nCode )) == null ? '' : __t) +
'">\n            <span class="col-xs-12 text-center">' +
((__t = ( code.trim() + ' - ' + name )) == null ? '' : __t) +
'</span>\n        </div>\n    </div>\n    <div class="col-xs-3 m-t-30">\n    </div>\n</div>';

}
return __p
};});

