define('importResultsInPcrRunView',[
	'module',
	'marionette',
	'backbone',
	'app',
	'underscore',
	'jquery',
	'dialogFormView',
	'autocompleteView',
	'settings',
	'template!importResultsInPcrRunView',
	'entities/caccounts/cyclerpublics',
	'entities/caccounts/userpids',
	'entities/caccounts/pcrruns'
], function (
	module,
	Marionette,
	Backbone,
	App,
	_,
	$,
	DialogFormView,
	AutocompleteView,
	Settings,
	importResultsInPcrRunTpl,
	CyclerPublics,
	Userpids
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: importResultsInPcrRunTpl,

		ui: {
			input: '.js-info-input',
			uploadRuns: '.js-upload-runs'
		},

		events: {
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-cancel-popup': 'onCancel',
			'change @ui.uploadRuns': 'onUploadRunsPopup',
			'click .run-import-remote-folder': 'onImportFromRemoteFolder'
		},

		regions: {
			selectRespUser: '.js-select-respUser-region',
			selectCycler: '.js-select-cycler-region'
		},

		fromTemplate: true,

		initialize: function (options) {
			this.model = options.model;
			this.runModel = options.runModel;
			this.model.set('refCycler', options.runModel.get('refCyclerPublic') ? options.runModel.get('refCyclerPublic') : null);
			this.model.set('refRespUser', null);
			this.model.set('accept', '.xlsx,.xls,.xml,.rdml,.csv');
			this.model.set('refRespUser', Settings.get('currentUserModel').toJSON());
			this.respUserSecId = Settings.get('currentUserModel').get('secId');
		},

		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.remoteAvailable = this.options.settings['IMPORT-RESULT'] && this.options.settings['IMPORT-RESULT'].split(';')[0];
			templateData.cyclerPublicToDisplay = this.runModel.get('refCycler') && this.runModel.get('refCycler').code ? this.runModel.get('refCycler').code : '';
			templateData.enableCyclerEdit = this.enableCyclerEdit = this.runModel.get('assays').length < 1;
			return templateData;
		},

		onShow: function () {
			$.material.init();
			this.showSelectCyclerView();
			this.showRefRespUserDropDown();
		},

		showRefRespUserDropDown: function () {
			if (this.respUserView) {
				this.respUserView.destroy();
			}
			this.respUserView = new AutocompleteView(
				this._getAutocompleteOptionObject(Userpids.getAutocompleteParam({
					modelProperty: 'refRespUser',
					valueProperty: 'nickName',
					queryProperty: 'nickName',
					valueKey: 'nickName',
					callBackOnChange: this.onRespUserChange
				}))
			);
			this.getRegion('selectRespUser').show(this.respUserView);
		},

		showSelectCyclerView: function () {
			if (!this.model.get('refCycler')) {
				CyclerPublics.findDefault().done(_.bind(function (data) {
					this.model.set('refCycler', data);
					this.displaySelectCycler();
				}, this));
			} else {
				this.displaySelectCycler();
			}
		},
		displaySelectCycler: function () {
			this.getRegion('selectCycler').show(new AutocompleteView(
				this._getAutocompleteOptionObject(CyclerPublics.getAutocompleteParam({
					modelProperty: 'refCycler',
					callBackOnChange: this.onCyclerChange
				}))
			));
		},

		onRespUserChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.onChange(fieldName, model ? model.toJSON() : null);
		},

		onCyclerChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.onChange(fieldName, model ? model.toJSON() : null);
		},

		onUploadRunsPopup: function (e) {
			if (e && e.target && e.target.files) {
				this.trigger('run:import-xml', e.target.files, this.model);
			}
			this.hide();
		},

		onImportFromRemoteFolder: function () {
			this.triggerMethod('run:select-file-to-import', this.model);
			this.hide();
		},

		onCancel: function () {
			this.hide();
		},

		hide: function () {
			this.triggerMethod('hide');
			this.box.modal('hide');
			this.destroy();
		}
	});
});
