define('prepRunTabsLayoutHeaderView',[
    'module',
    'backbone',
    'jquery',
    'app',
    'bootbox',
    'customBootboxMessage',
    'settings',
    'template!runPrepTooltipTpl',
    'backToListBehavior',
    'marionette',
    'template!prepRunTabsLayoutHeaderTpl',
    'dateUtils',
    'imageUtils',
    'underscore',
    'prepRunEditLayoutView',
    'prepRunWellEditSmpIdPlateView',
    'emptyView',
    'cssUtils',
    'fieldUtils',
    'wellUtils',
    'prepRunTabsLayoutController',
    'prepRunsShowController',
    'prepRunCreateBeadPooling',
    'prepRunCreatePrimaryPooling',
    'prepRunCreatePcrFromPrepView',
    'prepRunCreatedPcrFromPrepView',
    'prepRunExtractionStart',
    'prepRunExtractionStop',
    'prepRunExtractionShow',
    'prepRunExtrKitsLotsView',
    'prepRunTableTabController',
    'prepRunTracsView',
    'prepRunImportPlateLayoutView',
    'prepRunImportSampleListView',
    'prepRunExportPlateLayoutView',
    'prepRunExportSampleListView',
    'template!prepRunStatusTpl',
    'pcrRunCollectionView',
    'entities/caccounts/prepruns',
    'entities/admin/codelist',
    'entities/caccounts/setting',
    'entities/caccounts/setting/defaultCheckbox',
    'runController'
], function (
    module,
    Backbone,
    $,
    App,
    bootbox,
    CustomBootboxMessage,
    Settings,
    runPrepTooltipTpl,
    BackToListBehavior,
    Marionette,
    PrepRunTabsLayoutHeaderTpl,
    dateFormatter,
    imageUtils,
    _,
    CreateEditLayoutView,
    CreateEditSmpIdPlateLayoutView,
    EmptyView,
    CssUtils,
    FieldUtils,
    WellUtils,
    PrepRunTabsLayoutController,
    PrepRunsShowController,
    CreateBeadPoolingView,
    CreatePrimaryPoolingView,
    CreatePcrFromPrepView,
    CreatedPcrFromPrepView,
    ExtractionStart,
    ExtractionStop,
    ExtractionShow,
    PrepRunExtrKitsLotsView,
    PrepRunTableTabController,
    PrepRunTracsView,
    PrepRunImportPlateLayoutView,
    PrepRunImportSampleListView,
    PrepRunExportPlateLayoutView,
    PrepRunExportSampleListView,
    StatusToolTip,
    PcrRunCollectionView,
    PrepRuns,
    CodeLists,
    SettingEntity,
    DefaultCheckBox,
    RunController
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: PrepRunTabsLayoutHeaderTpl,

        tagName: 'div',

        ui: {
            logo: '.js-logo-holder',
            tabs: '.js-tab-layout-tab',
            filters: '.js-tab-filters',
            tabsRegion: '.js-tabs-region',
            header: '.js-tab-layout-header',
            refreshBtn: '.refresh-button',
            previousRun: '.js-show-previous-run',
            nextRun: '.js-show-next-run',
            toSelectedRun: '.js-show-selected-run',
            generateStarterSheet: '.js-generate-starter-sheet',

            generateSmpIds: '.js-generate-smpids',
            removeSmpIds: '.js-remove-smpids',
            addAssay: '.js-add-assay',
            clearAssay: '.js-clear-assay',
            replaceAssay: '.js-replace-assay',
            addTest: '.js-add-test',
            removeTest: '.js-remove-test',
            clearWells: '.js-clear-wells',

            resetFilters: '.js-reset-filters',
            showKits: '.btn-show-kits',
            showAttachments: '.btn-show-attachments',
            mainHeaderRunBox: '.pop-mainHeaderRunBox',
            tracsView: '.js-prepRun-tracs-view',
            statusToolTip: '.js-status-tooltip',
            copyParameters: '.js-copyParameters'
        },

        events: {
            'click @ui.showKits': 'onShowKits',
            'click @ui.showAttachments': 'onShowAttachments',
            'click @ui.resetFilters': 'onResetFilters',
            'click @ui.previousRun': 'onPreviousRun',
            'click @ui.nextRun': 'onNextRun',
            'change @ui.toSelectedRun': 'onSelectedRun',
            'click .js-tab-layout-tab': '_navigate',
            'click .btn-edit-preprun': 'onEditPrepRun',
            'click .js-run-refresh': 'onRunRefresh',
            'click .fill-smpids': 'onEditWellsSmpId',
            'click .import-plateLayout-button.clickable': 'onPlateLayoutImport',
            'click .import-sampleList-button.clickable': 'onSampleListImport',
            'click .import-plateLayout-fromOtherRun-button.clickable': 'onPlateLayoutImportFromOtherRun',
            'click .scan-plateLayout-button.clickable': 'onPlateLayoutScan',
            'click .edit-plateLayout-button.clickable': 'onPlateLayoutEdit',
            'click .export-plateLayout-button': 'onPlateLayoutExport',
            'click .export-sampleList-button': 'onSampleListExport',
            'click .ctrl-plateLayout-button': 'onPlateLayoutControl',
            'click .js-create-bead-pooling': 'onCreateBeadPoolingClick',
            'click .js-create-primary-pooling': 'onCreatePrimaryPoolingClick',
            'click .js-create-pcrFromPrep': 'onCheckBeforeCreatePcrFromPrep',
            'click .js-select-filter': 'onSelectFilters',
            'click .start-extraction-button.clickable': 'onStartExtractionClick',
            'click .undo-extraction-button.clickable': 'onUndoExtractionClick',
            'click .stop-extraction-button.clickable': 'onStopExtractionClick',
            'click .show-extraction-button.clickable': 'onShowExtractionClick',
            'click @ui.generateStarterSheet': 'onGenerateStarterSheet',
            'click .js-generate-extract-setup': 'onGenerateExtractSetup',
            'click @ui.generateSmpIds.clickable': 'onGenerateSmpIds',
            'click @ui.removeSmpIds.clickable': 'onRemoveSmpIds',
            'click @ui.addAssay.clickable': 'onAddAssay',
            'click @ui.clearAssay.clickable': 'onClearAssay',
            'click @ui.replaceAssay.clickable': 'onReplaceAssay',
            'click @ui.addTest.clickable': 'onAddTest',
            'click @ui.removeTest.clickable': 'onRemoveTest',
            'click @ui.clearWells.clickable': 'onClearWells',
            'click .js-run-action-nav-drop-btn': 'onRunActionNavDropBtnClick',
            'click .js-run-action-nav-drop-cell-btn': 'onRunActionNavDropCellBtnClick',
            'mouseleave .run-action-nav-container': 'onHideRunActionContainer',
            'click @ui.tracsView': 'onShowTracsView'
        },

        regions: {
            header: '.js-header-region',
            pcrRuns: '.js-pcrruns'
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        onShowTracsView: function () {
            this.prepRunTracsView = new PrepRunTracsView({
                model: this.model
            });
            this.prepRunTracsView.show({
                title: _.i18n('run.tracs'),
                className: 'runShowTracsPopup'
            });
        },

        onHideRunActionContainer: function (e) {
            var $target = $(e.currentTarget).find('.run-action-nav-list');
            $target.addClass('hidden');
            $(e.currentTarget).find('.run-action-nav-drop-btn').removeClass('active');
        },

        onRunActionNavDropBtnClick: function (e) {
            var $target = $(e.currentTarget).parent().find('.run-action-nav-list');
            if ($target.hasClass('hidden')) {
                $target.removeClass('hidden');
                $(e.currentTarget).addClass('active');
            } else {
                $target.addClass('hidden');
                $(e.currentTarget).removeClass('active');
            }
        },

        onRunActionNavDropCellBtnClick: function (e) {
            var $target = $(e.currentTarget).parent().parent().parent().find('.run-action-nav-list');
            if ($target.hasClass('hidden')) {
                $target.removeClass('hidden');
                $(e.currentTarget).addClass('active');
            } else {
                $target.addClass('hidden');
                $(e.currentTarget).removeClass('active');
            }
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        initialize: function () {
            this.selectedRunsList = PrepRunsShowController.getSelectedPrepRunsList();

            this.filtersToAllow = {};
            this.filtersToAllow.selectSmpId = {selector: 'js-select-smpId', show: false};
            this.filtersToAllow.selectQcSample = {selector: 'js-select-qcSample', show: false};
            this.filtersToAllow.selectUnKnowSample = {selector: 'js-select-unKnowSample', show: false};
            this.filtersToAllow.selectEmptySid = {selector: 'js-select-emptySid', show: false};
            this.filtersToAllow.selectWrongSid = {selector: 'js-select-wrongSid', show: false};
            this.filtersToAllow.selectDuplicates = {selector: 'js-select-duplicates', show: false};
            this.filtersToAllow.selectWithoutIC = {selector: 'js-select-withoutIC', show: false};
            this.filtersToAllow.selectWithOneIc = {selector: 'js-select-withOneIc', show: false};
            this.filtersToAllow.selectWithMoreIcs = {selector: 'js-select-withMoreIcs', show: false};
            this.filtersToAllow.selectComment = {selector: 'js-select-comment', show: false};
            this.filtersToAllow.selectSmpId.show = this.model.get('status') !== 0;
            this.filtersToAllow.selectWaiting = {selector: 'js-select-waiting', show: false};
            this.filtersToAllow.selectRunning = {selector: 'js-select-running', show: false};
            this.filtersToAllow.selectDone = {selector: 'js-select-done', show: false};
            this.filtersToAllow.selectRepeatPrep = {selector: 'js-select-repeat-prep', show: false};

        },

        onRender: function () {
            require([
                'prepRunTabsLayoutController'
            ], _.bind(function (PrepRunTabsLayoutController) {

                var smpids = [];
                this.model.get('wells').forEach(
                    _.bind(function (well) {
                        if (well.get('smpId').get('name')) {
                            smpids.push(well.get('smpId').get('name'));

                            if (this.model.get('settings') && this.model.get('settings').SID) {
                                var controlSid = this.model.get('settings').SID.split(';')[4] ? this.model.get('settings').SID.split(';')[4] : null;
                                var lengthMin = this.model.get('settings').SID.split(';')[2] ? this.model.get('settings').SID.split(';')[2] : null;
                                var lengthMax = this.model.get('settings').SID.split(';')[3] ? this.model.get('settings').SID.split(';')[3] : null;
                                if ((controlSid && controlSid === 'Y') && (well.get('smpId').get('name').length < lengthMin || well.get('smpId').get('name').length > lengthMax)) {
                                    this.filtersToAllow.selectWrongSid.show = true;
                                }
                            }
                        } else {
                            this.filtersToAllow.selectEmptySid.show = true;
                        }

                        if (well.get('repeat')) {
                            this.filtersToAllow.selectRepeatPrep.show = true;
                        }
                        if (well.get('smpType').includes('C')) {
                            this.filtersToAllow.selectQcSample.show = true;
                        }
                        if (well.get('smpType').includes('U')) {
                            this.filtersToAllow.selectUnKnowSample.show = true;
                        }
                        if (well.get('sampleComment') || well.get('sampleLisComment')) {
                            this.filtersToAllow.selectComment.show = true;
                        }
                        if (well.get('kitLotByKits')) {
                            if (well.get('kitLotByKits').length > 1) {
                                this.filtersToAllow.selectWithMoreIcs.show = true;
                            } else if (well.get('kitLotByKits').length > 0) {
                                this.filtersToAllow.selectWithOneIc.show = true;
                            } else {
                                this.filtersToAllow.selectWithoutIC.show = true;
                            }
                        }

                        var wellAssayStatusCount = well.getAssayStatusCount();

                        if (wellAssayStatusCount.waiting > 0) {
                            this.filtersToAllow.selectWaiting.show = true;
                        }
                        if (wellAssayStatusCount.running > 0) {
                            this.filtersToAllow.selectRunning.show = true;
                        }
                        if (wellAssayStatusCount.done > 0) {
                            this.filtersToAllow.selectDone.show = true;
                        }


                    }, this));

                var smpidsByCount = _.countBy(smpids, function (item) {
                    return item;
                });
                for (var smpId in smpidsByCount) {
                    if (smpidsByCount[smpId] > 1 && smpId !== '') {
                        this.filtersToAllow.selectDuplicates.show = true;
                    }
                }
                this.allowFilters(this.filtersToAllow);

                this.wellFiltersList = [];
                this.wellFiltersList = PrepRunTabsLayoutController.getWellFiltersList();
                this.currentTab = PrepRunTabsLayoutController.getCurrentTab();
                this.onShowActiveFilters(this.wellFiltersList);
                this.allowActionButtons();

                var logo;
                if (!this.model.getImageUid) {
                    throw 'Add method getImageUid to your model. It\'s required for displaying on header.';
                }
                logo = imageUtils.getLogo(this.model.getImageUid(), this.model.get('code'));
                if (logo) {
                    this.ui.logo.css('background-image', 'url(' + logo + ')');
                } else {
                    this.ui.logo.addClass('default-upload-image');
                }
                this._setHeight();

                this.filteredWells = PrepRunTabsLayoutController.getFilteredWellsCollection();

                this.showMainHeaderPrepRunPopover(this.model);

                CodeLists.findItemsByCode('PREPRUN_ST').done(_.bind(function (data) {
                    this.codeListElements = data;
                    this.showStatusPrepRunPopover();
                }, this));

                this.hideRemainingPopover();

                // hide runHeader buttons & filters in valView tab
                var $items = this.$el.find('.css-grayed-out-header');
                _.each($items, _.bind(function (item) {
                    var $item = $(item);
                    $item.css('opacity', this.options.opacity);

                    if (this.options.opacity === '1') {
                        $item.css('pointer-events', 'inherit');
                    } else {
                        $item.css('pointer-events', 'none');
                    }
                }, this));

                var allowPreviousBtn = this.selectedRunsList ? true : false;
                var allowNextBtn = this.selectedRunsList ? true : false;
                _.each(this.selectedRunsList, _.bind(function (run, i) {
                    if (run.secId === this.model.get('secId')) {
                        if (i === 0) {
                            allowPreviousBtn = false;
                        }
                        if (i === this.selectedRunsList.length - 1) {
                            allowNextBtn = false;
                        }
                    }
                }, this));
                if (!allowPreviousBtn) {
                    var previousBtn = this.$el.find('.css-show-previous-run');
                    previousBtn.removeClass('js-show-previous-run');
                    previousBtn.removeClass('clickable');
                    previousBtn.css('opacity', 0.2);
                }
                if (!allowNextBtn) {
                    var nextBtn = this.$el.find('.css-show-next-run');
                    nextBtn.removeClass('js-show-next-run');
                    nextBtn.removeClass('clickable');
                    nextBtn.css('opacity', 0.2);
                }
                var pcrruns = new PcrRunCollectionView({collection: this.model.get('pcrRuns')});
                this.getRegion('pcrRuns').show(pcrruns);
                App.trigger('forClipboard', this.ui.copyParameters);
            }, this));
        },

        allowFilters: function (filtersToAllow) {
            _.each(filtersToAllow, _.bind(function (f) {
                var filterComponent = $('.headerFilterButton.' + f.selector + '.js-tab-filters.js-select-filter');
                if (f.show) {
                    this.displayFilterComponent(filterComponent, true);
                } else {
                    this.displayFilterComponent(filterComponent, false);
                }
            }, this));
        },

        displayFilterComponent: function (filterComponent, display) {
            filterComponent.css('opacity', display ? 1 : 0.3);
            if (display) {
                filterComponent.addClass('js-select-filter');
                filterComponent.addClass('clickable');
            } else {
                filterComponent.removeClass('js-select-filter');
                filterComponent.removeClass('clickable');
            }
            filterComponent.find('input[type="checkbox"]').css('opacity', display ? 1 : 0);
        },

        onShowKits: function () {
            this.model.getKitLots()
                .done(_.bind(function (result) {
                    this.prepRunExtrKitsLotsView = new PrepRunExtrKitsLotsView({
                        runModel: this.model,
                        kitLots: result
                    });
                    this.prepRunExtrKitsLotsView.show({
                        title: _.i18n('prepRun.extr.kits'),
                        className: 'runShowKitsPopup'
                    });
                }, this));
        },
        onShowAttachments: function () {
            this.model.getEntity().openAttachment(this.model);
        },

        onGenerateStarterSheet: function () {
            PrepRuns.generateRunSheet(this.model.get('secId'));
        },

        onGenerateExtractSetup: function () {
            PrepRuns.generateRunExtractSetup(this.model.get('secId'));
        },

        onCheckBeforeCreatePcrFromPrep: function () {
            if (this.model.get('status') < 3) {
                var confirmParams = {
                    title: _.i18n('exraction.notFinished.warning.title'),
                    message: _.i18n('exraction.notFinished.warning.message'),
                    type: 'warning'
                };
                var callback = _.bind(function (result) {
                    if (result) {
                        this.onCreatePcrFromPrepClick();
                    }
                }, this);
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            } else {
                this.onCreatePcrFromPrepClick();
            }
        },

        onCreatePcrFromPrepClick: function () {
            this.createPcrFromPrep = new CreatePcrFromPrepView({
                runModel: this.model
            });
            this.createPcrFromPrep.show({
                title: _.i18n('run.createPcrFromPrep'),
                className: 'actionPopupCss popupFitContent'
            });

            this.createPcrFromPrep.listenTo(this.createPcrFromPrep, 'run.created.from.prep', _.bind(function (result) {
                var setting = DefaultCheckBox.getModel();
                setting.fetch().done(_.bind(function () {
                    SettingEntity.findByCode('EXPORT-PLATELAYOUT').done(_.bind(function (data) {
                        var model = new Backbone.Model();
                        model.set(result);
                        var exportPlateLayoutSetted = data && data.params && data.params.split(';')[0];
                        var view = new CreatedPcrFromPrepView({
                            model: model,
                            exportPlateLayoutSetted: !!exportPlateLayoutSetted,
                            pcrExportPlateLayout: setting.get('pcrExportPlateLayout'),
                            printPcrSetup: setting.get('printPcrSetup'),
                            printPcrSetupMMX: setting.get('printPcrSetupMMX')
                        });
                        view.show({
                            title: _.i18n('run.created.from.prep'),
                            className: 'actionPopupCss popupFitContent'
                        }, _.bind(function () {
                            $('.js-global-loader').show();
                            this.model.fetch().always(function () {
                                $('.js-global-loader').hide();
                            });
                        }, this));
                    }, this));
                }, this));
            }, this));
        },

        findPrepRunAttr: function (array, attr, value, next) {
            var nextPrepRun = false;
            for (var i = 0; i < array.length; i += 1) {
                if (array[i][attr] === value) {
                    if (next) {
                        if (i === array.length - 1) {
                            nextPrepRun = array[0];
                        } else {
                            nextPrepRun = array[i + 1];
                        }
                    } else {
                        if (i === 0) {
                            nextPrepRun = array[array.length - 1];
                        } else {
                            nextPrepRun = array[i - 1];
                        }
                    }
                }
            }
            return nextPrepRun;
        },

        findSelectedPcrRunAttr: function (array, attr, value) {
            var selectedPrepRun = false;
            for (var i = 0; i < array.length; i += 1) {
                if (array[i][attr] === value) {
                    selectedPrepRun = array[i];
                }
            }
            return selectedPrepRun;
        },

        onPreviousRun: function () {
            this.nextPreviousPrepRun(false);
        },

        onNextRun: function () {
            this.nextPreviousPrepRun(true);
        },

        onSelectedRun: function () {
            var runSecId = $(this.ui.toSelectedRun).children('option:selected').val();
            require([
                'prepRunsShowController'
            ], _.bind(function (prepRunsShowController) {
                var selectedRunsList = prepRunsShowController.getSelectedPrepRunsList(),
                    nextPrepRun;
                nextPrepRun = this.findSelectedPcrRunAttr(selectedRunsList, 'secId', runSecId);
                this.navigateToSelectedRun(nextPrepRun);
            }, this));
        },

        nextPreviousPrepRun: function (next) {
            require([
                'prepRunsShowController'
            ], _.bind(function (prepRunsShowController) {
                var selectedRunsList = prepRunsShowController.getSelectedPrepRunsList(),
                    nextPrepRun;

                if (selectedRunsList && selectedRunsList.length > 0) {
                    nextPrepRun = this.findPrepRunAttr(selectedRunsList, 'secId', this.model.get('secId'), next);
                    this.navigateToSelectedRun(nextPrepRun);
                } else {
                    if (next) {
                        nextPrepRun = this.model.getNextRun()
                            .done(_.bind(function (nextPrepRun) {
                                this.navigateToSelectedRun(nextPrepRun);
                            }, this));
                    } else {
                        nextPrepRun = this.model.getPreviousRun()
                            .done(_.bind(function (nextPrepRun) {
                                this.navigateToSelectedRun(nextPrepRun);
                            }, this));
                    }
                }
            }, this));
        },

        navigateToSelectedRun: function (nextPrepRun) {
            require([
                'prepRunTabsLayoutController'
            ], _.bind(function (prepRunTabsLayoutController) {
                prepRunTabsLayoutController.removeSmpIdSearchFromWellFiltersList();
                var currentRoute = App.getCurrentRoute();
                var nextPrepRunSecID = nextPrepRun !== '' && nextPrepRun.secId ? nextPrepRun.secId : false;

                if (nextPrepRunSecID) {
                    if (currentRoute.includes(nextPrepRunSecID)) {
                        return;
                    } else {
                        App.regions.getRegion('main').currentView.getRegion('content').show(new EmptyView());

                        var plateSize = $(nextPrepRun.plateSize).text();

                        var route = 'runs/prep/' + nextPrepRunSecID +
                            '/A01/1/' + plateSize;
                        App.navigate(route, {trigger: true});
                    }
                } else {
                    bootbox.alert(_.i18n('warning.noMoreRun'));
                }
            }, this));
        },

        serializeData: function () {
            var templateData = {
                secId: this.model.get('secId'),
                status: this.model.get('status'),
                name: this.model.get('name')
            };
            templateData.tabs = [];
            _.each(this.options.tabs, _.bind(function (tab) {
                if ((!tab.role || Settings.get('currentUserModel').hasRole(tab.role)) &&
                    (tab.displayOnPooled === undefined || tab.displayOnPooled === true ||
                        (tab.displayOnPooled === false && this.model.get('toBePooled')))) {
                    templateData.tabs.push(tab);
                }
            }, this));
            templateData.formatedDate = dateFormatter.toDateTimeFormatString(templateData.creatDate);
            templateData.analyser = '';
            if (templateData.refLmbCycler) {
                templateData.analyser = templateData.refLmbCycler.code;
            }

            templateData.wellExported = 0;
            templateData.wellCanBeExported = 0;
            templateData.wellRepeat = 0;
            _.each(templateData.wells, _.bind(function (well) {
                if (well.smpType !== 'PC' && well.smpType !== 'NC' && well.smpType !== 'RC') {
                    templateData.wellCanBeExported++;
                }
                if (well.exported) {
                    templateData.wellExported++;
                }
                if (well.repeatStatus) {
                    templateData.wellRepeat++;
                }
            }, this));

            var runslist = [];
            _.each(this.selectedRunsList, function (selectedRun) {
                var name = $(selectedRun.code).text();
                if (name === '') {
                    name = selectedRun.code;
                }
                var secId = selectedRun.secId;
                runslist.push({
                    name: name,
                    secId: secId
                });
            });
            templateData.selectedRunsList = runslist;

            this.showStart = false;
            this.showStop = false;
            if (templateData.status === 1) {
                this.showStart = true;
            } else if (templateData.status === 2) {
                this.showStop = true;
            }
            templateData.showStart = this.showStart;
            templateData.showStop = this.showStop;
            templateData.extractionMethod = templateData.refExtractionMethod ? templateData.refExtractionMethod.code : '';
            templateData.extractor = templateData.refLmbExtractor ? templateData.refLmbExtractor.code : '';
            var well = App.request('well:prep:model');
            templateData.assayStatusIcons = well.getAssayStatusFilterIcons();

            return templateData;
        },

        allowActionButtons: function () {
            var status = this.model.get('status');
            if (status >= 4) {
                //désactiver  Import plate Layout
                this.disableButton('.import-plateLayout-button-container', '.mdiHeaderActionButtonIcon', 'import-plateLayout-button');
            }
            if (this.model.get('toBePooled') || status === 0) {
                this.disableButton('.btn.btn-sm.start-extraction-button');
                this.disableButton('.start-extraction-button-container', '.mdiHeaderActionButtonIcon', 'start-extraction-button');
                this.disableButton('.btn.btn-sm.stop-extraction-button');
                this.disableButton('.stop-extraction-button-container', '.mdiHeaderActionButtonIcon', 'stop-extraction-button');
                this.disableButton('.btn.btn-sm.show-extraction-button');
                this.disableButton('.show-extraction-button-container', '.mdiHeaderActionButtonIcon', 'show-extraction-button');
                this.disableButton('.btn.btn-sm.undo-extraction-button');
                this.disableButton('.undo-extraction-button-container', '.mdiHeaderActionButtonIcon', 'undo-extraction-button');
                this.disableButton('.js-generate-extract-setup');
            } else {
                if (_.isEmpty(this.model.get('extractionDetails').refExtractionMethod)) {
                    this.disableButton('.btn.btn-sm.stop-extraction-button');
                    this.disableButton('.stop-extraction-button-container', '.mdiHeaderActionButtonIcon', 'stop-extraction-button');
                    this.disableButton('.btn.btn-sm.show-extraction-button');
                    this.disableButton('.show-extraction-button-container', '.mdiHeaderActionButtonIcon', 'show-extraction-button');
                    this.disableButton('.btn.btn-sm.undo-extraction-button');
                    this.disableButton('.undo-extraction-button-container', '.mdiHeaderActionButtonIcon', 'undo-extraction-button');
                    this.disableButton('.js-generate-extract-setup');
                } else {
                    if (_.isEmpty(this.model.get('extractionDetails').refLmbExtractor)) {
                        this.disableButton('.btn.btn-sm.start-extraction-button');
                        this.disableButton('.start-extraction-button-container', '.mdiHeaderActionButtonIcon', 'start-extraction-button');
                    } else {
                        this.disableButton('.btn.btn-sm.start-extraction-button');
                        this.disableButton('.start-extraction-button-container', '.mdiHeaderActionButtonIcon', 'start-extraction-button');
                        this.disableButton('.btn.btn-sm.undo-extraction-button');
                        this.disableButton('.undo-extraction-button-container', '.mdiHeaderActionButtonIcon', 'undo-extraction-button');
                        this.disableButton('.btn.btn-sm.stop-extraction-button');
                        this.disableButton('.stop-extraction-button-container', '.mdiHeaderActionButtonIcon', 'stop-extraction-button');
                    }
                }
            }

            if (status < 1 || status >= 5) {
                this.disableButton('.pcrFromPrep-btn-container', '.mdiHeaderActionButtonIcon', 'js-create-pcrFromPrep');
            }
            if (status >= 4) {
                this.disableButton('.btn.btn-sm.import-plateLayout-button');
                this.disableButton('.btn.btn-sm.import-plateLayout-fromOtherRun-button');
                this.disableButton('.btn.btn-sm.scan-plateLayout-button');
                this.disableButton('.edit-plateLayout-button-container fill-smpids-container', '.mdiHeaderActionButtonIcon', 'scan-plateLayout-button');
                this.disableButton('.import-plateLayout-button-container', '.mdiHeaderActionButtonIcon', 'import-plateLayout-button');
                this.disableButton('.btn.btn-sm.undo-extraction-button');
                this.disableButton('.undo-extraction-button-container', '.mdiHeaderActionButtonIcon', 'undo-extraction-button');
            }

            if (status > 1) {
                //désactiver  Scan plate layout
                this.disableButton('.scan-plateLayout-button-container', '.headerIconButton', 'scan-plateLayout-button');
            }

            if (!this.model.canAssignSID()) {
                this.disableButton('.btn.btn-sm.edit-plateLayout-button');
                this.disableButton('.edit-plateLayout-button-container', '.mdiHeaderActionButtonIcon', 'edit-plateLayout-button');
                this.disableButton('.btn.btn-sm.js-generate-smpids');
                this.disableButton('.btn.btn-sm.js-remove-smpids');
            }
            if (!this.model.canClearAssay()) {
                this.disableButton('.btn.btn-sm.js-clear-assay');
                this.disableButton('.assay-clear-button-container', '.mdiHeaderActionButtonIcon', 'js-clear-assay');
                this.disableButton('.assay-replace-button-container', '.mdiHeaderActionButtonIcon', 'js-replace-assay');
            }
        },

        disableButton: function (container, finder, removing) {
            var selector = $(container);
            selector.removeClass('clickable');
            if (finder && removing) {
                selector.find(finder).removeClass(removing);
            }
        },

        getFormatedPcrRunsFromPrepRun: function () {
            var formatedPcrRuns = '';
            this.model.get('pcrRuns').each(_.bind(function (runName) {
                formatedPcrRuns = formatedPcrRuns + runName.get('name') + '; ';
            }, this));
            formatedPcrRuns = formatedPcrRuns.substring(0, formatedPcrRuns.length - 2);
            return formatedPcrRuns;
        },

        showMainHeaderPrepRunPopover: function (model) {
            if (this.ui.mainHeaderRunBox && this.ui.mainHeaderRunBox.length > 0) {
                var templateData = {
                    secId: model.get('secId'),
                    name: model.get('name'),
                    status: model.get('status'),
                    department: model.get('department'),
                    periodFormatted: model.get('periodFormatted'),
                    group: model.get('group'),
                    sequenceFormatted: model.get('sequenceFormatted'),
                    extractionMethod: model.get('extractionDetails').refExtractionMethod ? model.get('extractionDetails').refExtractionMethod.code : '',
                    extractor: model.get('extractionDetails').refExtractor ? model.get('extractionDetails').refExtractor.code : '',
                    respUser: model.get('refRespUser') ? model.get('refRespUser').nickName ? model.get('refRespUser').nickName : model.get('refRespUser').email : ''
                };
                templateData.formatedPcrRuns = this.getFormatedPcrRunsFromPrepRun();
                templateData.sequenceFormatted = FieldUtils.getSequenceFormatted(model.get('sequence'), model.get('sequenceFormat'));
                templateData.wellsSizePlateIcon = WellUtils.getWellsSizePlateIcon(this.model.get('wells').size(), true);
                templateData.assaysToDisplay = model.getFormattedRunAssayStatus();

                var content = Marionette.Renderer.render(runPrepTooltipTpl, templateData);
                this.ui.mainHeaderRunBox.attr('data-content', content);
                this.ui.mainHeaderRunBox.popover({
                    trigger: 'manual',
                    placement: 'bottom',
                    html: 'true',
                    delay: {
                        show: '300',
                        hide: '10'
                    },
                    container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
                }).on('mouseenter', function () {
                    var _this = this;
                    $(this).popover('show');
                    $('.popover').on('mouseleave', function () {
                        $(_this).popover('hide');
                    });
                }).on('mouseleave', function () {
                    var _this = this;
                    setTimeout(function () {
                        if (!$('.popover:hover').length) {
                            $(_this).popover('hide');
                        }
                    }, 10);
                }).data('bs.popover').tip().addClass('run-prep-popover');
            }
        },

        showStatusPrepRunPopover: function () {
            if (this.ui.statusToolTip && this.ui.statusToolTip.length > 0) {
                var content = Marionette.Renderer.render(StatusToolTip, {
                    elements: this.codeListElements,
                    status: this.model.get('status')
                });
                this.ui.statusToolTip.attr('data-content', content);
                this.ui.statusToolTip.popover({
                    trigger: 'manual',
                    placement: 'bottom',
                    html: 'true',
                    delay: {
                        show: '300',
                        hide: '10'
                    },
                    container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
                }).on('mouseenter', function () {
                    var _this = this;
                    $(this).popover('show');
                    $('.popover').on('mouseleave', function () {
                        $(_this).popover('hide');
                    });
                }).on('mouseleave', function () {
                    var _this = this;
                    setTimeout(function () {
                        if (!$('.popover:hover').length) {
                            $(_this).popover('hide');
                        }
                    }, 10);
                }).data('bs.popover').tip().addClass('run-prep-popover');
            }
        },

        onShowActiveFilters: function (filtersName) {
            this.ui.filters.each(function (index, tab) {
                var $tab = $(tab);
                $tab.removeClass('activeFilter');
                $tab.find('input').prop('checked', false);
            });

            _.each(filtersName, _.bind(function (f) {
                this.ui.filters.each(function (index, tab) {
                    var $tab = $(tab);
                    if ($tab.hasClass('js-' + f)) {
                        $tab.addClass('activeFilter');
                        $tab.find('input').prop('checked', true);
                    }
                });
            }, this));
        },

        onShowActive: function (tabName) {
            this.ui.tabs.each(function (index, tab) {
                var $tab = $(tab);
                if ($tab.hasClass('js-' + tabName)) {
                    $tab.addClass('active');
                } else {
                    $tab.removeClass('active');
                }
            });
        },

        onSelectSmpidFilters: function (e) {
            var $target = $(e.currentTarget);
            var selectedWellFilter = $target.attr('name');
            if (!$target.hasClass('activeFilter')) {
                bootbox.prompt({
                    title: _.i18n('popup.select.smpId'),
                    className: 'actionPopupCss popupFitContent headerAction-bootboxPopup',
                    onEscape: true,
                    buttons: {
                        confirm: {
                            label: '<i></i>',
                            className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm btn-primary'
                        },
                        cancel: {
                            label: '<i></i>',
                            className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                        }
                    },
                    callback: _.bind(function (result) {
                        if (result) {
                            this.onSmpidSearch(result, e);
                        }
                        bootbox.hideAll();
                    }, this)
                });
            } else {
                require([
                    'prepRunTabsLayoutController'
                ], _.bind(function (PrepRunTabsLayoutController) {
                    PrepRunTabsLayoutController.onWellFiltersListChange(selectedWellFilter);
                    PrepRunTabsLayoutController.removeSmpIdSearchFromWellFiltersList();
                    this.wellFiltersList = [];
                    this.wellFiltersList = PrepRunTabsLayoutController.getWellFiltersList();
                    this.onShowActiveFilters(this.wellFiltersList);
                    if (this.wellFiltersList.length > 0) {
                        this.filteredWells = PrepRunTabsLayoutController.getFilteredWellsCollection();
                    } else {
                        this.filteredWells = null;
                    }
                    this.triggerMethod('navigate', null);
                }, this));
            }
        },

        onSmpidSearch: function (result, e) {
            var $target = $(e.currentTarget);
            var selectedWellFilter = $target.attr('name');
            require([
                'prepRunTabsLayoutController'
            ], _.bind(function (PrepRunTabsLayoutController) {
                PrepRunTabsLayoutController.onWellFiltersListChange(selectedWellFilter);
                PrepRunTabsLayoutController.onWellSmpidSearch(result);

                this.wellFiltersList = [];
                this.wellFiltersList = PrepRunTabsLayoutController.getWellFiltersList();
                this.onShowActiveFilters(this.wellFiltersList);

                if (this.wellFiltersList.length > 0) {
                    this.filteredWells = PrepRunTabsLayoutController.getFilteredWellsCollection();
                    //console.log(this.filteredWells);
                } else {
                    this.filteredWells = null;
                }
                this.triggerMethod('navigate', null);
            }, this));
        },

        onResetFilters: function () {
            require([
                'prepRunTabsLayoutController'
            ], _.bind(function (PrepRunTabsLayoutController) {
                PrepRunTabsLayoutController.removeAllFiltersFromList();
                this.wellFiltersList = [];
                this.filteredWells = null;
                this.triggerMethod('navigate', null);
            }, this));
        },

        onSelectFilters: function (e/*, result*/) {
            var $target = $(e.currentTarget);
            var selectedWellFilter = $target.attr('name');
            if (selectedWellFilter === 'select-smpId') {
                this.onSelectSmpidFilters(e);
            } else {
                require([
                    'prepRunTabsLayoutController'
                ], _.bind(function (PrepRunTabsLayoutController) {
                    PrepRunTabsLayoutController.onWellFiltersListChange(selectedWellFilter);

                    this.wellFiltersList = [];
                    this.wellFiltersList = PrepRunTabsLayoutController.getWellFiltersList();
                    this.onShowActiveFilters(this.wellFiltersList);

                    if (this.wellFiltersList.length > 0) {
                        this.filteredWells = PrepRunTabsLayoutController.getFilteredWellsCollection();
                    } else {
                        this.filteredWells = null;
                    }

                    this.triggerMethod('navigate', null);
                }, this));
            }
        },

        _navigate: function (e) {
            require([
                'prepRunTabsLayoutController'
            ], _.bind(function (PrepRunTabsLayoutController) {
                var $target = $(e.currentTarget);
                var tabName = $target.data('tab-name');
                PrepRunTabsLayoutController.setCurrentTab(tabName);
                this.triggerMethod('navigate', tabName, null);
                this.hideRemainingPopover();
            }, this));
        },

        hideRemainingPopover: function () {
            var $items = this._parent.$el.parent().parent().parent().find('.popover');
            _.each($items, _.bind(function (item) {
                $(item).addClass('hidden');
            }, this));
        },

        _setHeight: _.throttle(function () {
            var windowHeight = $(window).height(),
                headerHeight = $('header').height() || 75,
                detailsHeaderHeight = this.ui.header && this.ui.header.outerHeight() ? this.ui.header.outerHeight() : 0,
                height = windowHeight - headerHeight - detailsHeaderHeight;
            this.ui.tabsRegion.height(height);
        }, 500),

        onNavigate: function (tabName) {
            var selectedTab;
            if (!tabName) {
                console.error('%c selectedTab can\'t be null or undefined - onShowActive.tabName', 'color: #00f8ff');
                selectedTab = $('#header-navBar-tabs').find('.active').attr('data-tab-name');
            } else {
                selectedTab = tabName;
            }

            var currentRoute = App.getCurrentRoute();
            var routeSplitted = currentRoute.split('/'), newRoute = [];
            newRoute.push(routeSplitted[0]);
            newRoute.push(routeSplitted[1]);
            newRoute.push(routeSplitted[2]);
            newRoute.push(routeSplitted[3]);
            newRoute.push(routeSplitted[4]);
            newRoute.push(routeSplitted[5]);

            if (selectedTab) {
                newRoute.push(selectedTab);
            } else {
                newRoute.push('table');
            }

            if (this.filteredWells && this.filteredWells.length > 0 && (selectedTab === 'details' || selectedTab === 'table')) {
                newRoute.push('select');
            }
            newRoute = newRoute.join('/');

            if (newRoute !== currentRoute) {
                App.navigate(newRoute, {trigger: true});
            }
        },

        onRunRefresh: function () {
            App.trigger('run:prep:refresh', this.model.get('secId'), _.bind(function () { //to do check
                var currentRoute = App.getCurrentRoute();
                App.navigate('/', {trigger: false, replace: true});
                App.navigate(currentRoute, {trigger: true});
            }, this));
        },

        onEditPrepRun: function () {
            this.createEditView = new CreateEditLayoutView({
                model: this.model,
                formatedPcrRuns: this.getFormatedPcrRunsFromPrepRun()
            });
            this.createEditView.show({
                title: _.i18n('preprun.edit'),
                className: 'runEditAddPopup'
            });

            this.createEditView.listenTo(this.createEditView, 'sheet:upload', _.bind(this.uploadSheet, this));
            this.createEditView.listenTo(this.createEditView, 'sheet:remove', _.bind(this.removeSheet, this));
            this.createEditView.listenTo(this.createEditView, 'hide:refresh', _.bind(this.onEditPrepRunSaved, this));
        },

        onEditPrepRunSaved: function (oldData) {
            if (oldData) {
                if (this.model.get('name') !== oldData.name) {
                    this.refreshHeaderRunName(oldData.name);
                }
            }
        },

        refreshHeaderRunName: function (oldRunName) {
            if ($('.js-runName-div-container').length > 0) {
                $('.js-runName-div-container').text(this.model.get('name'));
            }
            if ($('.js-runName-selectOption-container').length > 0) {
                var selectOptions = $('.js-runName-selectOption-container').find('option');
                var item = _.find(selectOptions, _.bind(function (option) {
                    return option.selected;
                }, this));
                if (item) {
                    $(item).text(this.model.get('name'));
                }
                _.each(this.selectedRunsList, _.bind(function (run) {
                    if (run.secId === this.model.get('secId')) {
                        run.name = run.name.replace(oldRunName, this.model.get('name'));
                    }
                }, this));
                PrepRunsShowController.onSelectedPrepRunsList(this.selectedRunsList);
            }
        },

        uploadSheet: function (target) {
            $('.js-global-loader').show();
            this.model.sheetUpload(target).done(_.bind(function (response) {
                $.when(App.trigger('run:prep:refresh:model', response, _.bind(function (model) {
                    this.model = model;
                    this.createEditView.model = model;
                    this.createEditView.render();
                }, this))).always($('.js-global-loader').hide());
            }, this)).always($('.js-global-loader').hide());
        },

        removeSheet: function () {
            $('.js-global-loader').show();
            this.model.sheetRemove().done(_.bind(function (response) {
                $.when(App.trigger('run:prep:refresh:model', response, _.bind(function (model) {
                    this.model = model;
                    this.createEditView.model = model;
                    this.createEditView.render();
                }, this))).always($('.js-global-loader').hide());
            }, this)).always($('.js-global-loader').hide());
        },

        onEditWellsSmpId: function () {
            this.editPlateLayout('all', _.i18n('prep.platelayout.all'));
        },

        onPlateLayoutExport: function () {
            SettingEntity.findByCode('EXPORT-PLATELAYOUT').done(_.bind(function (setting) {
                this.createEditView = new PrepRunExportPlateLayoutView({
                    model: this.model,
                    setting: setting
                });
                this.createEditView.show({
                    title: _.i18n('run.export.plateLayout'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
            }, this));
        },

        onSampleListExport: function () {
            SettingEntity.findByCode('EXPORT-SAMPLELIST').done(_.bind(function (setting) {
                this.createEditView = new PrepRunExportSampleListView({
                    model: this.model,
                    setting: setting
                });
                this.createEditView.show({
                    title: _.i18n('run.export.sampleList'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
            }, this));
        },

        onPlateLayoutImport: function () {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.createEditView = new PrepRunImportPlateLayoutView({
                    model: this.model,
                    settings: settings
                });
                this.createEditView.show({
                    title: _.i18n('run.pcr.new.fromFile.plateLayout'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
            }, this));
        },

        onSampleListImport: function () {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.createEditView = new PrepRunImportSampleListView({
                    model: this.model,
                    settings: settings
                });
                this.createEditView.show({
                    title: _.i18n('run.pcr.new.fromFile.sampleList'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
            }, this));
        },

        onPlateLayoutImportFromOtherRun: function () {
            this.editPlateLayout('importFromOtherRun', _.i18n('editPlateLayout.import.fromOtherRun'));
        },

        onPlateLayoutScan: function () {
            this.editPlateLayout('scan', _.i18n('prep.platelayout.scan'));
        },

        onPlateLayoutEdit: function () {
            if (this.checkBeforeSampleIdEdit()) {
                this.warmOnWrongStatusBeforeEdit('edit', _.i18n('prep.platelayout.edit'));
            }
        },

        onGenerateSmpIds: function () {
            if (this.checkBeforeSampleIdEdit()) {
                this.warmOnWrongStatusBeforeEdit('generate-smpId', _.i18n('prep.platelayout.generate.smpId'));
            }
        },

        onRemoveSmpIds: function () {
            if (this.checkBeforeSampleIdEdit()) {
                this.warmOnWrongStatusBeforeEdit('remove-smpId', _.i18n('prep.platelayout.remove.smpId'));
            }
        },

        onPlateLayoutControl: function () {
            this.editPlateLayout('control', _.i18n('prep.platelayout.ctrl'));
        },

        onAddAssay: function () {
            this.editPlateLayout('add-assay', _.i18n('prep.platelayout.assign.assay'));
        },
        onClearAssay: function () {
            this.editPlateLayout('clear-assay', _.i18n('prep.platelayout.clear.assay'));
        },
        onReplaceAssay: function () {
            this.editPlateLayout('replace-assay', _.i18n('prep.platelayout.replace.assay'));
        },
        onAddTest: function () {
            this.editPlateLayout('add-test', _.i18n('prep.platelayout.add.test'));
        },
        onRemoveTest: function () {
            this.editPlateLayout('remove-test', _.i18n('prep.platelayout.remove.test'));
        },
        onClearWells: function () {
            this.editPlateLayout('clear-wells', _.i18n('prep.platelayout.clear.wells'));
        },

        editPlateLayout: function (mode, title, routingGroup) {
            this.createEditSmpIdPlateView = new CreateEditSmpIdPlateLayoutView({
                model: this.model,
                type: 'prepRuns',
                mode: mode,
                routingGroup: routingGroup ? routingGroup : null
            });
            var className = 'actionPopupCss popupFitContent';

            if (mode === 'all' || mode === 'scan' || mode === 'edit') {
                className = 'popupEnterPlateLayout actionPopupCss popupFitContent';
            }
            if (mode === 'import' || mode === 'export' || mode === 'control' || mode === 'replace-assay' ||
                mode === 'add-assay' || mode === 'clear-assay' || mode === 'add-test' || mode === 'remove-test' || mode === 'generate-smpId' ||
                mode === 'remove-smpId' || mode === 'importFromOtherRun' || mode === 'clear-wells'
            ) {
                className = 'actionPopupCss popupFitContent actionMiniPopupCss';
            }

            this.createEditSmpIdPlateView.show({
                title: title,
                className: className
            });

            this.createEditSmpIdPlateView.listenTo(this.createEditSmpIdPlateView, 'on:runTemplate:edit:Hide', _.bind(function () {
                var route = App.getCurrentRoute();
                App.navigate(route + '/refresh', {trigger: false});
                App.navigate(route, {trigger: true, replace: true});
            }, this));
        },

        checkBeforeSampleIdEdit: function () {
            var allowed = true;
            var assaysRunning = [];
            var assaysDone = [];
            var message = '';

            this.model.get('wells').each(_.bind(function (well) {
                _.each(well.get('assayStatus'), _.bind(function (item) {
                    if (item.status === 'RUNNING') {
                        assaysRunning.push(item.assay.code);
                    } else if (item.status === 'DONE') {
                        assaysDone.push(item.assay.code);
                    }
                }, this));
            }, this));

            assaysRunning = _.uniq(assaysRunning);
            assaysDone = _.uniq(assaysDone);

            if (this.model.get('pcrRuns') && this.model.get('pcrRuns').length > 0) {
                var messageToAdd = [];
                this.model.get('pcrRuns').each(_.bind(function (pcrRun) {
                    messageToAdd.push(pcrRun.get('name'));
                }, this));
                messageToAdd = messageToAdd.slice(0, -1);

                allowed = false;
                message = this.addToAlertMessage(message, _.i18n('prepRun.linked.with.pcrRun'), messageToAdd);
            }

            if (assaysRunning.length > 0) {
                allowed = false;
                message = this.addToAlertMessage(message, _.i18n('prepRun.assaysRunning.found'), assaysRunning);
            }

            if (assaysDone.length > 0) {
                allowed = false;
                message = this.addToAlertMessage(message, _.i18n('prepRun.assaysDone.found'), assaysDone);
            }

            if (!allowed) {
                var params = {
                    title: _.i18n('prepRun.with.constrains'),
                    message: message,
                    type: 'info'
                };
                CustomBootboxMessage.customAlert(params);
            }
            return allowed;
        },

        addToAlertMessage: function (message, messageToAdd, arrayToAdd) {
            message += messageToAdd;
            message += '<br> <textarea style="width: 100%; resize: vertical; background-color: white;" rows="1" wrap="on">';
            message += arrayToAdd.join(',');
            message += '</textarea>';
            message += '<br>';
            return message;
        },

        warmOnWrongStatusBeforeEdit: function (mode, message) {
            if (this.model.get('status') >= 3) {
                var callback = _.bind(function (result) {
                    if (result) {
                        if (mode === 'generate-smpId') {
                            RunController.showGenerateSampleId(this.model, _.bind(function () {
                                this.render();
                            }, this));
                        } else {
                            this.editPlateLayout(mode, message);
                        }
                    }
                }, this);
                var confirmParams = {
                    message: _.i18n('pcrWell.extraction.already.done'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            } else {
                if (mode === 'generate-smpId') {
                    RunController.showGenerateSampleId(this.model, _.bind(function () {
                        this.render();
                    }, this));
                } else {
                    this.editPlateLayout(mode, message);
                }
            }
        },

        onCreateBeadPoolingClick: function () {
            this.createPooling = new CreateBeadPoolingView({
                model: this.model
            });
            this.createPooling.listenTo(this.createPooling, 'run:prep:create-pooling-bead', _.bind(function (runs) {
                this.onCreateBeadPooling(runs);
            }, this));
            this.createPooling.show({
                title: _.i18n('run.createPooling.bead'),
                className: 'actionPopupCss popupFitContent'
            });
        },

        onCreateBeadPooling: function (runs) {
            PrepRunTableTabController.onCreatePooling(runs);
        },

        onCreatePrimaryPoolingClick: function () {
            this.createPooling = new CreatePrimaryPoolingView({
                model: this.model
            });
            this.createPooling.listenTo(this.createPooling, 'run:prep:create-pooling-primary', _.bind(function (runs) {
                this.onCreatePrimaryPooling(runs);
            }, this));
            this.createPooling.show({
                title: _.i18n('run.createPooling.primary'),
                className: 'actionPopupCss popupFitContent'
            });
        },

        onCreatePrimaryPooling: function (runs) {
            PrepRunTableTabController.onCreatePrimaryPooling(runs);
        },

        onStartExtractionClick: function () {
            if (this.model.get('status') === 0) {
                this.plateLayoutNotFinishedAlert();
            } else if (!_.isEmpty(this.model.get('refExtractionMethod')) && this.model.get('status') > 2) {
                this.extractionAlreadyDoneAlert();
            } else {
                this.onStartExtraction();
            }
        },

        onUndoExtractionClick: function () {
            if (this.model.get('status') === 2) {
                bootbox.confirm(_.i18n('preprun.extraction.undo'), _.bind(function (result) {
                        if (result) {
                            this.model.undoExtraction();
                        }
                    }, this)
                );
            }
        },

        extractionAlreadyDoneAlert: function () {
            var params = {
                title: _.i18n('prepRun.extraction.finished.warning.title'),
                message: _.i18n('prepRun.extraction.finished.warning.message'),
                type: 'warning'
            };
            CustomBootboxMessage.customAlert(params);
        },

        plateLayoutNotFinishedAlert: function () {
            var confirmParams = {
                title: _.i18n('prepRun.notFinished.warning.title'),
                message: _.i18n('prepRun.notFinished.warning.message'),
                type: 'warning'
            };

            var callback = _.bind(function (result) {
                if (result) {
                    this.onStartExtraction();
                }
            }, this);
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onStartExtraction: function () {
            var setting = DefaultCheckBox.getModel();
            setting.fetch().done(_.bind(function () {
                this.extraction = new ExtractionStart({
                    runModel: this.model,
                    currentUser: Settings.get('currentUserModel').toJSON(),
                    printExtractionSetup: setting.get('prepExtractionSetup')
                });

                this.extraction.listenTo(this.extraction, 'run:prep:extraction:update:buttons', _.bind(function () {
                    this.updateExtractionButtons();
                }, this));

                this.extraction.show({
                    title: _.i18n('run.extraction.start'),
                    className: 'actionPopupCss popupFitContent'
                });
            }, this));
        },

        onStopExtractionClick: function () {
            this.extraction = new ExtractionStop({
                runModel: this.model,
                currentUser: Settings.get('currentUserModel').toJSON()
            });

            this.extraction.listenTo(this.extraction, 'run:prep:extraction:update:buttons', _.bind(function () {
                this.updateExtractionButtons();
            }, this));

            this.extraction.show({
                title: _.i18n('run.extraction.stop'),
                className: 'actionPopupCss popupFitContent'
            });
        },

        onShowExtractionClick: function () {
            this.extraction = new ExtractionShow({
                model: new Backbone.Model(this.model.get('extractionDetails'))
            });

            this.extraction.show({
                title: _.i18n('run.extraction.show'),
                className: 'actionPopupCss popupFitContent'
            });
        },

        updateExtractionButtons: function () {
            $('.js-global-loader').show();
            this.model.fetch().always(function () {
                $('.js-global-loader').hide();
            });
        }
    });
});
