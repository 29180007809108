define('entities/admin/codelistelement',[
    'app',
    'backbone',
    'settings',
    'underscore',
    'jquery',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    Settings,
    _,
    $,
    module,
    Dynamic
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.CodeListElement = Backbone.RelationalModel.extend({
        defaults: {
            secId: null,
            code: '',
            name: '',
            description: '',
            seq: null
        },
        idAttribute: 'secId',
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.CodeListElementCollection = Backbone.Collection.extend({
        model: app.ns.CodeListElement,
        comparator: function (o1, o2) {
            var seq1 = o1.get('seq');
            var seq2 = o2.get('seq');
            var code1 = o1.get('code');
            var code2 = o2.get('code');

            if ((!seq1 && !seq2) || seq1 === seq2) {
                if (!code1 || !code2) {
                    return 0;
                }
                return code1.localeCompare(code2);
            }
            if (!seq1) {
                return 1;
            }
            if (!seq2) {
                return -1;
            }

            return seq1.localeCompare(seq2);
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'codelistelement';
        },
        getDynamicJson: function () {
            return 'CodeListElementJson';
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 p-l-2 p-r-2'}}},
                {'field': 'name', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-40 p-l-2 p-r-2'}}},
                {'field': 'seq', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-5 p-l-2 p-r-2', maxLength: 3}}},
                {'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-40 p-l-2 p-r-2'}}}
            ];
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.CodeListElement.findOrCreate(modelJson);
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        }
    }, Dynamic);
});
