define('pcrWellController',[
    'module',
    'underscore',
    'jquery',
    'pcrWellDetailsView',
    'bootbox'
], function (
    module,
    _,
    $,
    PcrWellDetailsView,
    Bootbox
) {
    'use strict';

    module.exports = {
        showDetails: function (model, cSelection, icRefKitsToDiplay) {
            var defers = [];
            if (model.get('assayVersion')) {
                defers.push(model.get('assayVersion').fetch());
            }
            if (model.get('refAssay')) {
                defers.push(model.get('refAssay').fetch());
            }
            $.when.apply($, defers).done(function () {
                var view = new PcrWellDetailsView({
                    model: model,
                    cSelection: cSelection,
                    icRefKitsToDiplay: icRefKitsToDiplay
                });
                view.show({
                    title: model.get('refPcrRun').get('name'),
                    className: 'popupWellView'
                });
            });
        },
        showProperties: function (model) {
            var properties = _.map(model.get('properties'), function (property) {
                return {
                    name: property.name,
                    value: property.value
                };
            });
            var message = '<h1>' + _.i18n('properties') + '</h1>';
            message += '<table class="table table-striped">';
            message += '<thead>';
            message += '<tr>';
            message += '<th>' + _.i18n('property.name') + '</th>';
            message += '<th>' + _.i18n('property.value') + '</th>';
            message += '</tr>';
            message += '</thead>';
            message += '<tbody>';
            _.each(properties, function (property) {
                message += '<tr>';
                message += '<td>' + property.name + '</td>';
                message += '<td>' + property.value + '</td>';
                message += '</tr>';
            });
            message += '</tbody>';
            message += '</table>';
            Bootbox.dialog({
                message: message,
                title: _.i18n('properties'),
                className: 'noIcon',
                buttons: {
                    cancel: {
                        label: '<i></i>',
                        className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                    }
                }
            });
        }
    };
});
