define('caccountsView',[
    'module',
    'caccountsEditView',
    'underscore',
    'jquery',
    'marionette',
    'imageUtils',
    'template!caccountsView'
], function (
    module,
    CaccountEditLayoutView,
    _,
    $,
    Marionette,
    imageUtils,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: viewTpl,

        modelEvents: {
            sync: 'render'
        },

        ui: {
            logo: 'img.js-logo-holder',
            logoDiv: 'div.js-logo-holder',
            imgBlock: 'div.profile-img-block'
        },
        className: 'm-t-10 m-b-10 m-l-10 m-r-10',

        events: {
            'click .js-edit': '_onEdit'
        },

        serializeData: function () {
            var templateData = this.model.toJSON(),
                country;
            country = this.options.countries.findWhere({code: this.model.get('country')});
            templateData.country = country ? country.get('name') : null;

            return templateData;
        },

        onRender: function () {
            this.displayLogo(this.model.get('logo'), this.model.get('code'));
            $.material.init();
        },

        onShow: function () {
            $.material.init();
        },

        displayLogo: function (logo, code, remove) {
            var image = imageUtils.getLogo(logo, code);

            if (image) {
                this.ui.logo.removeClass('hidden');
                this.ui.imgBlock.removeClass('hidden');
                this.ui.logo.attr('src', image);
                if (!this.ui.logoDiv.hasClass('hidden')) {
                    this.ui.logoDiv.addClass('hidden');
                }
            } else {
                if (remove) {
                    this.ui.logo.attr('src', '');
                }
                this.ui.logo.attr('src', '').addClass('hidden');
                this.ui.imgBlock.addClass('hidden');
                this.ui.logoDiv.removeClass('hidden');
            }
        },

        _onEdit: function () {
            require(['caccountsController'], _.bind(function (Controller) {
                Controller.showDetails(this.model);
            }, this));
        }
    });
});
