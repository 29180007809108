define('standardCurve',[
    'module',
    'template!standardCurve',
    'underscore',
    'jquery',
    'dialogFormView',
    'dynamicCreateEditList',
    'dynamicCreateEditDescriptionCommentTags',
    'settings',
    'savingBehavior'
], function (
    module,
    Tpl,
    _,
    $,
    DialogFormView,
    DynamicCreateEditList,
    DynamicCreateEditDescriptionCommentTags,
    Settings,
    SavingBehavior
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm'
        },

        regions: {
            regionSamples: '.js-samples',
            regionResults: '.js-results',
            regionDescription: '.js-description'
        },
        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },
        serializeData: function () {
            var templateData = {};
            templateData.code = this.model.get('code');
            templateData.name = this.model.get('name');
            templateData.descriptionKitLot = this.model.get('descriptionKitLot');
            templateData.dilution = this.model.get('dilution');
            templateData.fromCt = this.model.get('fromCt');
            templateData.toCt = this.model.get('toCt');
            templateData.alreadyValues = this.model.get('results').any(function (result) {
                return result.get('values').length > 0;
            });
            return templateData;
        },


        onRender: function () {
            this.getRegion('regionSamples').show(new DynamicCreateEditList({
                field: 'samples',
                model: this.model,
                collection: this.model.get('samples'),
                afterPush: _.bind(this.addSample, this)
            }));
            this.getRegion('regionResults').show(new DynamicCreateEditList({
                field: 'results',
                model: this.model,
                collection: this.model.get('results')
            }));
            this.getRegion('regionDescription').show(new DynamicCreateEditDescriptionCommentTags({
                model: this.model
            }));
            this.setPermissions(Settings.get('currentUserModel').hasRole('DASHBOARD_ADMIN_STANDARDCURVE'));
        },
        addSample: function (model) {
            var at = this.model.get('samples').indexOf(model);
            model.set('dilution', Math.pow(parseInt(this.model.get('dilution'), 10), parseInt(at, 10)));
        },
        onConfirm: function () {
            if (this.validate(this.fieldsToValidate) && this.model.get('samples').every(function (model) {
                return model.get('sample');
            })) {
                $('.js-global-loader').show();
                this.model.save();
            }
        },
        onSave: function () {
            this.box.modal('hide');
            $('.js-global-loader').hide();
        },
        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },
        onCancel: function () {
            $('.js-global-loader').show();
            this.model.fetch().done(_.bind(function () {
                this.triggerMethod('hide');
                $('.js-global-loader').hide();
            }, this));
        }
    });
});
