
define('template!wellEditSmpIdView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-group col-w-all d-f">\n    <label class="col-w-5 m-r-5">' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n    <label class="col-w-50 m-l-5 m-r-5">' +
((__t = ( _.i18n('well.smpid') )) == null ? '' : __t) +
'</label>\n    <label class="col-w-5 m-l-5 m-r-5">' +
((__t = ( _.i18n('well.type') )) == null ? '' : __t) +
'</label>\n    <label class="col-w-10 m-l-5 m-r-5">' +
((__t = ( _.i18n('well.test') )) == null ? '' : __t) +
'</label>\n    <label class="col-w-10 m-l-5 m-r-5">' +
((__t = ( _.i18n('well.lis') )) == null ? '' : __t) +
'</label>\n    <label class="col-w-10 m-l-5">' +
((__t = ( _.i18n('well.sampleClass') )) == null ? '' : __t) +
'</label>\n</div>\n<div class="form-group col-w-all d-f">\n    <label class="col-w-5 control-label m-r-5">' +
((__t = ( pos )) == null ? '' : __t) +
'</label>\n    <div class="col-w-50 div-input-smpId m-l-5 m-r-5">\n        <input class="form-control js-info-input js-input-smpid" data-field-name="smpId"\n               maxlength="99" placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n               type="text" value="' +
((__t = ( smpId )) == null ? '' : __t) +
'">\n    </div>\n    <div class="col-w-5 p-l-0 p-r-0 m-l-5 m-r-5 js-select-smpType" style="line-height: 25px;"></div>\n    <div class="col-w-10 d-f m-l-5 m-r-5 p-t-2">\n        <label class="switch js-checkbox">\n            <input type="checkbox" class="js-info-input" data-field-name="test" ' +
((__t = ( test ?
            'checked="checked"' : '' )) == null ? '' : __t) +
' ' +
((__t = ( !enableToChangeTest ? 'disabled="disabled"' : '' )) == null ? '' : __t) +
'>\n            <span class="slider classicSliderColors round" ' +
((__t = ( !enableToChangeTest ? 'title="' +
            _.i18n('well.test.defineInParent') + '"' : '' )) == null ? '' : __t) +
'></span>\n        </label>\n    </div>\n    <label class="col-w-10 control-label m-l-5 m-r-5">' +
((__t = ( lis )) == null ? '' : __t) +
'</label>\n    <label class="col-w-10 control-label m-l-5">' +
((__t = ( sampleClass )) == null ? '' : __t) +
'</label>\n</div>\n';
 if(canChooseAssay) { ;
__p += '\n<div class="form-group ">\n    <label class="col-xs-2 control-label text-right">' +
((__t = ( _.i18n('well.refAssay') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-10">\n        <div class="js-assay-region"></div>\n    </div>\n</div>\n';
 } ;
__p += '\n<div class="row">\n    <div class="pull-right p-r-15">\n        <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'\n        </button>\n        <button class="btn btn-primary disabled-grey js-confirm"\n                disabled>' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'\n        </button>\n    </div>\n</div>';

}
return __p
};});

