define('contaminationsWellView',[
    'module',
    'marionette',
    'backbone',
    'wellUtils',
    'template!wellContaminationsTpl',
    'template!wellContaminationsMultiDisplayTpl',
    'wellResultIconView',
    'underscore',
    'jquery',
    'sampleCommentView',
    'qcChartPopup',
    'entities/caccounts/pcrwells'
], function (
    module,
    Marionette,
    Backbone,
    WellUtils,
    wellTpl,
    wellMultiDisplayTpl,
    WellResultIconView,
    _,
    $,
    EditSampleCommentView,
    QcView
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        ui: {
            blockLoading: '.empty-page-loader'
        },

        regions: {},

        events: {
            'click': 'onItemClick',
            'click .js-btn-preprun-kitLot': 'onKitLotClick',
            'click .btn-assay': 'onAssayClick',
            'click .smp-id-link': 'onSampleIdClick',
            'click .js-btn-comment': 'onCommentClick'
        },

        onCommentClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.model.get('smpId').fetch().done(_.bind(function () {
                this.editSampleCommentView = new EditSampleCommentView({
                    wellModel: this.model,
                    model: this.model.get('smpId')
                });
                this.editSampleCommentView.show({
                    title: _.i18n('edit.sampleComment'),
                    className: ''
                });
            }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        },

        onKitLotClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['assayVersionController'], _.bind(function (AssayVersionController) {
                this.model.getPcrKitLots().done(_.bind(function (response) {
                    AssayVersionController.showDetails(this.model.get('assayVersion'), null, {lots: response});
                }, this))
                    .always(_.bind(function () {
                        this.$el.trigger('mouseleave');
                    }, this));
            }, this));
        },

        onAssayClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.model.get('refAssay')) {
                this.trigger('well:filteredDetailAssay', this.model.get('refAssay').get('secId'));
            }
            this.$el.trigger('mouseleave');
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        initialize: function (/*options*/) {
            this.popoverCount = 0;
            this.displayWellRes = false;
            this.listenTo(this.model.get('smpId'), 'change', this.renderSafe);
        },

        getTemplate: function () {
            if (this.options.isMultiDisplay) {
                return wellMultiDisplayTpl;
            } else {
                return wellTpl;
            }
        },

        getWellResDifference: function (num1, num2) {
            var difference = 0;
            if (num1 < num2) {
                difference = num2 - num1;
            } else if (num1 > num2) {
                difference = num1 - num2;
            }
            return difference;
        },

        getColorContaminationArrowColor: function (num1, num2) {

            var color = '#333333';
            var lowCt = 8;
            var highCt = 10;

            if (this.options.modelRun.get('settings') &&
                this.options.modelRun.get('settings')['CONTAM-WARN'] &&
                this.options.modelRun.get('settings')['CONTAM-WARN'].length > 1) {
                var warningSettings = this.options.modelRun.get('settings')['CONTAM-WARN'].split(';');
                if (warningSettings[2]) {
                    color = warningSettings[2];
                }
                if (warningSettings[0]) {
                    lowCt = warningSettings[0];
                }
                if (warningSettings[1]) {
                    highCt = warningSettings[1];
                }
            }

            var difference = this.getWellResDifference(num1, num2);

            if (difference < lowCt) {
                return 'transparent';
            } else if (difference > highCt) {
                this.backgroundColorFull = true;
                return color;
            } else {
                this.backgroundColorNormal = true;
                return color + '8a';
            }
        },

        getContaminationCellColor: function () {
            var color = '#FF6500';
            if (this.options.modelRun.get('settings') &&
                this.options.modelRun.get('settings')['CONTAM-WARN'] &&
                this.options.modelRun.get('settings')['CONTAM-WARN'].length > 1) {
                var warningSettings = this.options.modelRun.get('settings')['CONTAM-WARN'].split(';');
                if (warningSettings[3]) {
                    color = warningSettings[3];
                }
            }

            if (!this.options.wellToDisplay) {
                return 'transparent';
            }

            if (this.options.wellToDisplay) {
                if (this.backgroundColorFull) {
                    return color + '9B';
                } else if (this.backgroundColorNormal) {
                    return color + '40';
                } else {
                    return '#efefef';
                }
            } else if (this.model.wellRes === 'N') {
                return '#efefef';
            } else {
                return 'transparent';
            }
        },

        serializeData: function () {
            var templateData = {
                well: {
                    smpType: this.model.get('smpType'),
                    showQC: this.model.get('showQC'),
                    wellResNum: this.options.wellResNumMin ? this.options.wellResNumMin : this.model.get('wellResNum'),
                    pos: this.model.get('pos'),
                    tags: this.model.get('tags'),
                    existOtherWellSameAssay: this.model.get('existOtherWellSameAssay'),
                    existOtherWellOtherAssay: this.model.get('existOtherWellOtherAssay')
                }
            };
            templateData.displayKitLot = !!this.model.get('assayVersion');
            var ctMin = templateData.well.min = this.options.wellResNumMin;

            if (ctMin < 20) { // >=15 et <20
                templateData.fontSize = '30px;';
                templateData.ctTop = '16px;';
            } else if (ctMin >= 20 && ctMin < 25) {
                templateData.fontSize = '25px;';
                templateData.ctTop = '22px;';
            } else if (ctMin >= 25 && ctMin < 30) {
                templateData.fontSize = '20px;';
                templateData.ctTop = '28px;';
            } else if (ctMin >= 30) {
                templateData.fontSize = '15px;';
                templateData.ctTop = '32px;';
            } else {
                templateData.fontSize = null;
                templateData.ctTop = null;
            }

            var wellRes = this.model.findWellResult(this.options.currentResult);

            templateData.wellResColor = 'white';
            if (wellRes && wellRes.get('result')) {
                templateData.wellResColor = wellRes.get('result').get('color');
            }

            templateData.colorOtherWellSameAssay = '#FF0000';
            templateData.colorOtherWellOtherAssay = '#FFFFFF';
            if (this.model.get('refPcrRun').get('settings') &&
                this.model.get('refPcrRun').get('settings')['OTHER-ASSAY-IND']) {
                var otherAssayInd = this.model.get('refPcrRun').get('settings')['OTHER-ASSAY-IND'].split(';');
                if (otherAssayInd[0]) {
                    templateData.colorOtherWellSameAssay = otherAssayInd[0];
                }
                if (otherAssayInd[1]) {
                    templateData.colorOtherWellOtherAssay = otherAssayInd[1];
                }
            }

            this.displayWellRes = false;
            templateData.repeatStatus = false;
            if (this.options.wellToDisplay || this.options.wellToDisplayRepeat) {
                this.displayWellRes = true;
            }
            if (this.options.wellToDisplayRepeat) {
                if (this.model.get('repeatStatus')) {
                    templateData.repeatStatus = true;
                }
            }
            templateData.display = templateData.displayWellRes = this.displayWellRes;

            templateData.topLeftCtColor = templateData.topCenterCT = templateData.topRightCT = templateData.middleLefCT =
                templateData.middleRightCT = templateData.bottomLeftCtColor = templateData.bottomCenterCtColor = templateData.bottomRightCT = 'transparent';

            if (this.options.adjacentWells) {
                // topLeftCT
                if (this.options.adjacentWells.topLeftCT && this.options.adjacentWells.topLeftCT < this.options.wellResNumMin) {
                    templateData.topLeftCT = 'incoming';
                    templateData.topLeftCtColor = this.getColorContaminationArrowColor(this.options.adjacentWells.topLeftCT, this.options.wellResNumMin);
                } else {
                    templateData.topLeftCT = false;
                }
                // topCenterCT
                if (this.options.adjacentWells.topCenterCT && this.options.adjacentWells.topCenterCT < this.options.wellResNumMin) {
                    templateData.topCenterCT = 'incoming';
                    templateData.topCenterCtColor = this.getColorContaminationArrowColor(this.options.adjacentWells.topCenterCT, this.options.wellResNumMin);
                } else {
                    templateData.topCenterCT = false;
                }
                // topRightCT
                if (this.options.adjacentWells.topRightCT && this.options.adjacentWells.topRightCT < this.options.wellResNumMin) {
                    templateData.topRightCT = 'incoming';
                    templateData.topRightCtColor = this.getColorContaminationArrowColor(this.options.adjacentWells.topRightCT, this.options.wellResNumMin);
                } else {
                    templateData.topRightCT = false;
                }
                // middleLefCT
                if (this.options.adjacentWells.middleLefCT && this.options.adjacentWells.middleLefCT < this.options.wellResNumMin) {
                    templateData.middleLefCT = 'incoming';
                    templateData.middleLefCtColor = this.getColorContaminationArrowColor(this.options.adjacentWells.middleLefCT, this.options.wellResNumMin);
                } else {
                    templateData.middleLefCT = false;
                }
                // middleRightCT
                if (this.options.adjacentWells.middleRightCT && this.options.adjacentWells.middleRightCT < this.options.wellResNumMin) {
                    templateData.middleRightCT = 'incoming';
                    templateData.middleRightCtColor = this.getColorContaminationArrowColor(this.options.adjacentWells.middleRightCT, this.options.wellResNumMin);
                } else {
                    templateData.middleRightCT = false;
                }
                // bottomLeftCT
                if (this.options.adjacentWells.bottomLeftCT && this.options.adjacentWells.bottomLeftCT < this.options.wellResNumMin) {
                    templateData.bottomLeftCT = 'incoming';
                    templateData.bottomLeftCtColor = this.getColorContaminationArrowColor(this.options.adjacentWells.bottomLeftCT, this.options.wellResNumMin);
                } else {
                    templateData.bottomLeftCT = false;
                }
                // bottomCenterCT
                if (this.options.adjacentWells.bottomCenterCT && this.options.adjacentWells.bottomCenterCT < this.options.wellResNumMin) {
                    templateData.bottomCenterCT = 'incoming';
                    templateData.bottomCenterCtColor = this.getColorContaminationArrowColor(this.options.adjacentWells.bottomCenterCT, this.options.wellResNumMin);
                } else {
                    templateData.bottomCenterCT = false;
                }
                // bottomRightCT
                if (this.options.adjacentWells.bottomRightCT && this.options.adjacentWells.bottomRightCT < this.options.wellResNumMin) {
                    templateData.bottomRightCT = 'incoming';
                    templateData.bottomRightCtColor = this.getColorContaminationArrowColor(this.options.adjacentWells.bottomRightCT, this.options.wellResNumMin);
                } else {
                    templateData.bottomRightCT = false;
                }
                templateData.contaminationCellColor = 'transparent';
                var color = this.getContaminationCellColor();
                if (color) {
                    templateData.contaminationCellColor = color;
                }
            }
            templateData.comment = this.model.get('smpId') ? ((this.model.get('smpId').get('lisComment') ? this.model.get('smpId').get('lisComment') + ' ' : '') + (this.model.get('smpId').get('comment') ? this.model.get('smpId').get('comment') : '')).substring(0, 15) : null;
            templateData.error = this.model.get('entityError') || this.model.get('smpId').get('verified') === 'NOT_VALIDATED' || this.model.get('smpId').get('entityError');
            return templateData;
        },

        onRender: function () {
            var contaminationAlgoSetting = this.options.modelRun.get('settings')['CONTAM-ALGO'] ? this.options.modelRun.get('settings')['CONTAM-ALGO'].split(';') : [];

            var highValue = contaminationAlgoSetting && contaminationAlgoSetting[1] ? contaminationAlgoSetting[1] : 40;
            var divider = contaminationAlgoSetting && contaminationAlgoSetting[2] ? contaminationAlgoSetting[2] : 25;

            if (this.options.calculatedWellRes) {
                var fillerValue = ((highValue - this.options.calculatedWellRes) / divider) * 100;
                // var fillerValue = ((40 - this.options.calculatedWellRes) / 25) * 100;
                this.$el.find('#resPositif-' + this.model.get('pos')).width(fillerValue + '%');
                this.$el.find('#resPositif-' + this.model.get('pos')).css('opacity', (parseFloat(fillerValue / 100).toFixed(1)));
            }

            if (this.options.adjacentWells) {
                this.adjacentWells = this.options.adjacentWells;
            }

            var params = {
                context: this,
                target: this.options.region
            };
            WellUtils.showPcrWellPopover(params);
        },

        onSampleIdClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.empty-page-loader').show();

            if (this.model.get('smpType') !== 'PC' &&
                this.model.get('smpType') !== 'NC' &&
                this.model.get('smpType') !== 'RC' &&
                this.model.get('smpType') !== 'OC') {

                require(['samplesController'], _.bind(function (Controller) {
                    this.model.get('smpId').fetch().done(_.bind(function () {
                        Controller.showDetails(this.model.get('smpId'));
                    }, this)).always(_.bind(function () {
                        $('.empty-page-loader').hide();
                        this.$el.trigger('mouseleave');
                    }, this));
                }, this));

            } else {
                if (!this.model.get('smpType') || !this.model.get('refAssay') || !this.model.get('refAssay').get('secId')) {
                    return;
                }
                this.model.getControlKitLot().done(_.bind(function (controlKits) {
                    this.qcChartPopupLayoutView = new QcView({
                        wellModel: this.model,
                        qcTargetModel: null,
                        collection: new Backbone.Collection(controlKits),
                        settings: this.model.get('settings')
                    });
                    this.qcChartPopupLayoutView.show({
                        title: _.i18n('kitlot:qc:chart:popup'),
                        className: 'runQcChartViewPopup'
                    });
                }, this))
                    .always(_.bind(function () {
                        $('.empty-page-loader').hide();
                        this.$el.trigger('mouseleave');
                    }, this))
                    .always(_.bind(function () {
                        this.$el.trigger('mouseleave');
                    }, this));
            }
        },

        onItemClick: function () {
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails(this.model, null, null);
            }, this));
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        }
    });
});
