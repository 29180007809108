define('adminAttachmentController',[
    'module',
    'underscore',
    'backbone',
    'adminAttachmentsListView',
    'adminAttachmentsPopupListView',
    'adminAttachmentsCreateEditView',
    'dynamicController',
    'entities/caccounts/attachment',
    'jquery',
    'entities/caccounts/userCodelist'
], function (
    module,
    _,
    Backbone,
    View,
    PopupView,
    CreateEditLayoutView,
    DynamicController,
    Attachments,
    $,
    UserCodeList
) {
    'use strict';

    module.exports = _.defaults({
        showListForContext: function (region, data, displayArchived) {
            data.entityNamePath = 'caccounts/attachment';
            var entityConfig = _.bind(Attachments.getJqGridColumn, Attachments);
            data.getJqGridColumn = function (viewDynamic) {
                var configuration = entityConfig(viewDynamic);
                configuration = _.filter(configuration, function (column) {
                    return !['context', 'contextSecId', 'contextCode'].includes(column.name);
                });
                _.each(configuration, function (column) {
                    column.search = false;
                });
                return configuration;
            };

            this._showList(region, data, displayArchived, Attachments);
        },
        showDetails: function (model, createViewObsolete, callBackRefresh) {
            $('.js-global-loader').show();
            if (!(model instanceof Backbone.Model) && typeof model === 'object') {
                var param = model;
                model = param.model;
                callBackRefresh = param.callBackRefresh;
            }
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }

            var codeListCode = 'ATTACH-TYPE-' + model.get('context');
            UserCodeList.findItemsByCode(codeListCode).done(function (codeList) {
                if (_.isEmpty(codeList)) {
                    codeListCode = 'ATTACH-TYPE-BASETABLE';
                }
                var createEditView = new CreateEditLayoutView({
                    model: model,
                    codeListCode: codeListCode
                });
                createEditView.show({
                    title: _.i18n(model.getEntity().getName() + '.new'),
                    className: 'baseTableEditAddPopup kitPcrsEditAddPopup'
                }, _.bind(function () {
                    if (callBackRefresh) {
                        callBackRefresh();
                    }
                }, callBackRefresh ? callBackRefresh.context : undefined));
                $('.js-global-loader').hide();
            });
        }
    }, DynamicController);
});
