/**
 * Created by Matteo on 11/07/2015.
 */
define('sidebarMain',[
    'module',
    'logger',
    'app',
    'underscore',
    'sidebarShowController'
], function (
    module,
    Logger,
    App,
    _,
    ShowController
) {
    'use strict';

    var Sidebar = {
        start: function (settings) {
            Logger.log(module, 'starting SidebarModule');
            ShowController.showSidebar(settings);
        },

        stop: function () {
            Logger.log(module, 'stopping SidebarModule');
        }
    };

    App.on('sidebar:show', function (settings) {
        Sidebar.start(settings);
    });

    App.commands.setHandler('set:active:nav:item', function (name) {
        ShowController.setActiveNavItem(name);
    });

    module.exports = Sidebar;
});
