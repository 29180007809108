define('entities/caccounts/fileFormatPlateLayouts',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.FileFormatPlateLayout = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/fileFormat/plateLayouts/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            comment: null,
            tags: [],
            'inputPlateLayoutService': '',
            'outputPlateLayoutService': '',
            'customInputPlateLayout': '',
            'customOutputPlateLayout': ''
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/fileFormat/plateLayouts');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'FileFormatPlateLayoutJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'FileFormatPlateLayout',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.FileFormatPlateLayoutCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/fileFormat/plateLayouts');
        },
        model: app.ns.FileFormatPlateLayout
    });

    module.exports = _.defaults({
        getName: function () {
            return 'fileFormatPlateLayout';
        },
        getUrl: function () {
            return 'v2/fileFormat/plateLayouts';
        },
        getDynamicJson: function () {
            return 'FileFormatPlateLayoutJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('fileFormat.inputPlateLayout'),
                name: 'inputPlateLayoutService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'inputPlateLayoutService',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('fileFormat.customInputPlateLayout'),
                name: 'customInputPlateLayout',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'customInputPlateLayout',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('fileFormat.outputPlateLayout'),
                name: 'outputPlateLayoutService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'outputPlateLayoutService',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('fileFormat.customOutputPlateLayout'),
                name: 'customOutputPlateLayout',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'customOutputPlateLayout',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.FileFormatPlateLayout.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore();
            ignored.push('customInputPlateLayout', 'customOutputPlateLayout');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'inputPlateLayoutService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customInputPlateLayout',
                        'url': 'v2/inputPlateLayoutService'
                    }
                },
                {
                    'field': 'outputPlateLayoutService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customOutputPlateLayout',
                        'url': 'v2/outputPlateLayoutService'
                    }
                },
                {
                    'field': 'customOutputPlateLayout',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customOutputResult',
                        'url': 'v2/outputResultService'
                    }
                }
            );
            return custom;
        },

        getAutocompleteParamForImport: function (config) {
            config.url = this.getUrl() + '/forImport';
            return Dynamic.getAutocompleteParam(config, this);
        },
        getAutocompleteParamForExport: function (config) {
            config.url = this.getUrl() + '/forExport';
            return Dynamic.getAutocompleteParam(config, this);
        }
    }, Dynamic);
});
