define('jsonPropertiesCollectionView',['module',
		'backbone',
		'marionette',
		'superAdminDBDocumentationJsonObjectView'
	],
	function (module,
			  Backbone,
			  Marionette,
			      SuperAdminDBDocumentationJsonObjectView
	) {
		'use strict';

		var JsonPropertiesCollection = Marionette.CollectionView.extend({

			childView: SuperAdminDBDocumentationJsonObjectView,
			collection: new Backbone.Collection(),
			childViewOptions: function() {
				return {
					analysis: this.options.analysis,
					modelDisplayProperty : this.options.modelDisplayProperty
				};
			}

		});
		module.exports = JsonPropertiesCollection;
	});
