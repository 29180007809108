
define('template!dynamicCreateEditSelectTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(values, function(value) { ;
__p += '\n<option value="' +
((__t = ( value.key )) == null ? '' : __t) +
'" ' +
((__t = ( value.display && value.display.style ? 'style="' +value.display.style + '"' : '' )) == null ? '' : __t) +
'>' +
((__t = ( value.value )) == null ? '' : __t) +
'</option>\n';
 }) ;
__p += '\n';

}
return __p
};});

