
define('template!prepRunTemplateTooltips', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<span style="z-index: 9000;">\n    <ul>\n        <li>%PERIOD% : ' +
((__t = (_.i18n('runtemplate.prep.period.description'))) == null ? '' : __t) +
'</li>\n        <li>%RUNGROUP% : ' +
((__t = (_.i18n('runtemplate.prep.rungroup.description'))) == null ? '' : __t) +
'</li>\n        <li>%NNN% : ' +
((__t = (_.i18n('runtemplate.prep.nnn.description'))) == null ? '' : __t) +
'</li>\n        <li>%A% : ' +
((__t = (_.i18n('runtemplate.prep.a.description'))) == null ? '' : __t) +
'</li>\n    </ul>\n</span>';

}
return __p
};});

