define('dashboardShowView',[
    'runsMainRouter',
    'module',
    'underscore',
    'backbone',
    'jqgridView',
    'dateUtils',
    'urlUtil',
    'app',
    'settings',
    'template!dashboardViewTpl',
    'datePickerPopoverMixin',
    'jqgridActionsButtonFormatterActions',
    'jqgridActionsButtonFormatterView',
    'bootbox',
    'marionette',
    'emptyView',
    'dashboardPcrThumbnailView',
    'dashboardPrepThumbnailView'
], function (
    RunsMainRouter,
    module,
    _,
    Backbone,
    JqGridView,
    dateConverter,
    UrlUtil,
    App,
    Settings,
    viewTpl,
    datePickerPopoverMixin,
    availableActions,
    ActionButtonsView,
    bootbox,
    Marionette,
    EmptyView,
    DashboardPcrThumbnailView,
    DashboardPrepThumbnailView
) {
    'use strict';

    var RunsView = Marionette.LayoutView.extend({
        template: viewTpl,

        regions: {
            thumbnailPcrRunRegion: '.js-dashboard-runs-pcr-region',
            thumbnailPrepRunRegion: '.js-dashboard-runs-prep-region'
        },

        ui: function () {
            return _.extend({
                showAllPanel: '.js-show-all-panel',
                showPrepPanel: '.js-show-prep-panel-only',
                showPcrPanel: '.js-show-pcr-panel-only'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({
                'click @ui.deleteRun': 'onDeleteRun',
                'click @ui.showAllPanel': 'showAllPanel',
                'click @ui.showPrepPanel': 'showPrepPanel',
                'click @ui.showPcrPanel': 'showPcrPanel'
            }, this.gridEvents);
        },

        showAllPanel: function () {
            $('.js-dashboard-prep-tab').css('width', '50%');
            $('.js-dashboard-pcr-tab').css('width', '50%');

            $('.js-pcr-thumbnail-filter-container').css('justify-content', 'center');
            $('.js-prep-thumbnail-filter-container').css('justify-content', 'center');
        },

        showPrepPanel: function () {
            this.showAllPanel();
            $('.js-dashboard-prep-tab').css('width', '100%');
            $('.js-dashboard-pcr-tab').css('width', '0%');
            // $('.js-dashboard-pcr-tab').css('margin-left', 'auto');
            $('.js-pcr-thumbnail-filter-container').css('justify-content', 'none');
            $('.js-prep-thumbnail-filter-container').css('justify-content', 'center');
        },

        showPcrPanel: function () {
            this.showAllPanel();
            $('.js-dashboard-prep-tab').css('width', '0%');
            $('.js-dashboard-pcr-tab').css('width', '100%');

            $('.js-pcr-thumbnail-filter-container').css('justify-content', 'center');
            $('.js-prep-thumbnail-filter-container').css('justify-content', 'none');
        },

        onShow: function () {
            $.material.init();
            this.showAllPanel();
            this.showPcrRunsThumbnails();
            this.showPrepRunsThumbnails();
        },

        showPcrRunsThumbnails: function () {
            var params = {
                sidx: 'creatDate',
                sord: 'asc',
                status: ['1', '2', '3', '4']
            };

            $.when(App.request('run:pcr:get-collection-to-page', params))
                .done(_.bind(function (runsList) {
                        var view = new DashboardPcrThumbnailView({
                            runsList: runsList
                            // type: 'pcr'
                        });
                    this.getRegion('thumbnailPcrRunRegion').show(view);
                    }, this)
                );
        },

        showPrepRunsThumbnails: function () {
            var params = {
                sidx: 'creatDate',
                sord: 'asc',
                status: ['1', '2']
            };

            $.when(App.request('run:prep:get-collection-to-page', params))
                .done(_.bind(function (runsList) {
                        var view = new DashboardPrepThumbnailView({
                            runsList: runsList
                            // type: 'prep'
                        });
                    this.getRegion('thumbnailPrepRunRegion').show(view);
                    }, this)
                );
        },

        serializeData: function () {
            this.selectedRuns = Settings.get('selectedRuns') || [];
            var templateData = {
            };
            return templateData;
        }
    });

    module.exports = RunsView;
});
