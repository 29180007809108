define('arrayCollectionView',[
    'module',
    'marionette',
    'arrayCollectionItemView',
    'template!arrayCollectionView'
], function (
    module,
    Marionette,
    ItemView,
    Tpl
) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
        template: Tpl,
        childView: ItemView,
        childEvents: {
            'changeChild': 'onChildChange'
        },
        onChildChange: function () {
            this.trigger('change');
        }
    });
});
