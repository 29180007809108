
define('template!editSampleResultView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="js-header-region"></div>\n\n<div class="row p-l-15 p-r-15 m-t-20">\n    <div class="col-xs-12 rounded">\n        <div class="col-w-all col-w-100 d-f">\n            <div class="col-w-all col-w-100">\n                <div class="col-w-all col-w-100 d-f m-t-6">\n                    <label>' +
((__t = ( _.i18n('result') )) == null ? '' : __t) +
'</label>\n                </div>\n\n                <div class="js-result-region"></div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class="row m-t-10"></div>\n\n<div class="row cancelConfirmRow">\n    <div class="pull-right p-r-15 col-xs-12 p-l-0 p-r-0 cancelConfirmDiv t-a-r">\n        <button class="mdi-navigation-close btn popupBtn-cancel\n                    cancel-b js-cancel-popup"></button>\n        <button class="mdi-navigation-check btn popupBtn-confirm\n                        js-confirm"></button>\n    </div>\n</div>';

}
return __p
};});

