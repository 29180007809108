
define('template!docGlossaryCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="name">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t<div class="form-group" data-field-name="name">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input dataInput300 inputBackground"\n\t\t\t\t\t\t\t   data-field-name="name" placeholder="" value="' +
((__t = ( name )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   data-content="' +
((__t = ( _.i18n('docglossary.duplicatedNameError') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   data-placement="top" autocomplete="off">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="tag">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('docglossary.tag') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-3">\n\t\t\t\t\t<div class="form-group" data-field-name="tag">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input dataInput150 inputBackground"\n\t\t\t\t\t\t\t   data-field-name="tag" placeholder="" value="' +
((__t = ( tag )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   data-content="' +
((__t = ( _.i18n('docglossary.duplicatedTagError') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   data-placement="top" autocomplete="off">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="abbreviation">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('docglossary.abbreviation') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-3">\n\t\t\t\t\t<div class="form-group" data-field-name="abbreviation">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input dataInput150 inputBackground"\n\t\t\t\t\t\t\t   data-field-name="abbreviation" placeholder="" value="' +
((__t = ( abbreviation )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   data-placement="top" autocomplete="off">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<button class="js-add-docurl btn btn-info">' +
((__t = ( _.i18n('codelist.addDocUrl') )) == null ? '' : __t) +
'</button>\n\t\t\t<div class="row col-xs-12">\n\t\t\t\t<div class="col-xs-4">\n\t\t\t\t\t' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-7">\n\t\t\t\t\t' +
((__t = ( _.i18n('common.abstract') )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\n\t\t\t\t<div class="col-xs-1">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="docUrlsTable"></div>\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-12 commentOrDescriptionContainer">\n\t\t\t\t\t<div>\n\t\t\t\t\t\t<span class=mdi mdi-message-reply-text commentOrDescriptionIcon">\n\t\t\t\t\t\t</span>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="commentOrDescriptionInputDiv">\n              <textarea class="form-control floating-label js-info-input inputBackground"\n\t\t\t\t\t\trows="3" cols="89" wrap="on" data-field-name="description"\n\t\t\t\t\t\tplaceholder="" style="resize: vertical;">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row cancelConfirmRow">\n\t\t\t\t<div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n\t\t\t\t\t<button disabled\n\t\t\t\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

