/**
 * Created by RKL on 21/08/2015.
 */
define('ordersController',[
	'module',
	'underscore',
	'orderCreateEditLayoutView',
	'entities/caccounts/orders'
], function (
	module,
	_,
	CreateEditLayoutView,
	Orders
) {
	'use strict';

	module.exports = {
		/**
		 *
		 * @param model {object}
		 * @param callBackRefresh
		 */
		showDetails: function (model, callBackRefresh) {
			if (!model) {
				alert('Can\'t create a new model without entity, need to define empty model or existing model.');
				throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
			}
			var createEditView = new CreateEditLayoutView({
				model: model
			});
			createEditView.show({
				title: _.i18n(Orders.getName() + '.new'),
				className: 'baseTableEditAddPopup orderBaseTable'
			}, _.bind(function () {
				if (callBackRefresh) {
					callBackRefresh();
				}
			}, callBackRefresh ? callBackRefresh.context : undefined));
		}
	};
});
