define('createEditLayoutPrepTemplatesView',[
    'module',
    'dialogFormView',
    'template!prepRunTemplatesCreateEditLayoutTpl',
    'savingBehavior',
    'underscore',
    'jquery',
    'settings',
    'template!prepRunTemplateTooltips',
    'autocompleteView',
    'entities/caccounts/prepruntemplates',
    'prepRunWellEditSmpIdPlateView',
    'dynamicCreateEditCodeList',
    'dynamicCreateEditUserCodeList',
    'runOperationsView',
    'runController',
    'dynamicCreateEditDescriptionCommentTags'
], function (
    module,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    _,
    $,
    Settings,
    prepRunTemplateTooltips,
    AutocompleteView,
    PrepRunTemplates,
    CreateEditSmpIdPlateLayoutView,
    CodeListView,
    UserCodeListView,
    RunOperationsView,
    RunController,
    DynamicCreateEditDescriptionCommentTags
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            plateLayout: '.js-define-plateLayout',
            periodFormatInfo: '.js-periodFormat-info',
            printRunSheet: '.js-printRunSheet',
            onlyForPcrSetupFromPrep: '.js-onlyForPcrSetupFromPrep',
            generateSmpIds: '.js-generate-smpids',
            removeSmpIds: '.js-remove-smpids',
            assignAssay: '.js-assign-assay',
            removeAssay: '.js-remove-assay',
            addTest: '.js-add-test',
            removeTest: '.js-remove-test',
            operations: '.js-operations'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click @ui.plateLayout': 'onPlateLayout',
            'click @ui.periodFormatInfo': 'onPeriodFormatInfo',
            'change @ui.printRunSheet': 'onChangePrintRunSheet',
            'change @ui.onlyForPcrSetupFromPrep': 'onChangeOnlyForPcrSetupFromPrep',
            'click @ui.generateSmpIds': 'onGenerateSmpIds',
            'click @ui.removeSmpIds': 'onRemoveSmpIds',
            'click @ui.assignAssay': 'onAssignAssay',
            'click @ui.removeAssay': 'onRemoveAssay',
            'click @ui.addTest': 'onAddTest',
            'click @ui.removeTest': 'onRemoveTest',
            'click @ui.operations': 'onOperations'
        },

        regions: {
            // selectType: '.js-select-type-region',
            selectDepartment: '.js-selectDepartment',
            selectRunGroup: '.js-selectRunGroup',
            selectSequenceFormat: '.js-selectSequenceFormat',
            selectPattern: '.js-selectPattern',
            selectPlateSize: '.js-selectPlateSize',
            descriptionRegion: '.js-description'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'},
            {name: 'plateSize', type: 'required'}
        ],

        onGenerateSmpIds: function () {
            RunController.showGenerateSampleId(this.model, _.bind(function () {
                this.render();
            }, this));
        },
        onRemoveSmpIds: function () {
            this.editPlateLayout('remove-smpId', _.i18n('prep.platelayout.remove.smpId'));
        },
        onAssignAssay: function () {
            this.editPlateLayout('add-assay', _.i18n('prep.platelayout.assign.assay'));
        },
        onRemoveAssay: function () {
            this.editPlateLayout('clear-assay', _.i18n('prep.platelayout.clear.assay'));
        },
        onAddTest: function () {
            this.editPlateLayout('add-test', _.i18n('prep.platelayout.add.test'));
        },
        onRemoveTest: function () {
            this.editPlateLayout('remove-test', _.i18n('prep.platelayout.remove.test'));
        },
        onOperations: function () {
            var view = new RunOperationsView({
                model: this.model
            });

            view.show({
                title: _.i18n('runOperations.title'),
                className: 'baseTableEditAddPopup'
            });
        },

        editPlateLayout: function (mode, title, routingGroup) {
            this.createEditSmpIdPlateView = new CreateEditSmpIdPlateLayoutView({
                model: this.model,
                type: 'PrepRunTemplate',
                statusIcon: this.statusIcon,
                mode: mode,
                routingGroup: routingGroup ? routingGroup : null
            });
            var className = 'actionPopupCss popupFitContent';

            if (mode === 'all' || mode === 'scan' || mode === 'edit') {
                className = 'popupEnterPlateLayout actionPopupCss popupFitContent';
            }
            if (mode === 'import' || mode === 'export' || mode === 'control' ||
                mode === 'add-assay' || mode === 'clear-assay' || mode === 'add-test' || mode === 'remove-test' || mode === 'generate-smpId' ||
                mode === 'remove-smpId' || mode === 'importFromOtherRun'
            ) {
                className = 'actionPopupCss popupFitContent actionMiniPopupCss';
            }

            this.createEditSmpIdPlateView.show({
                title: title,
                className: className
            });
        },

        initialize: function (options) {
            this.newItem = this.model.isNew();
            this.selectedRunsList = options.selectedRunsList;
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.newItem = this.newItem;
            templateData.selectedRunsList = this.selectedRunsList;

            templateData.sortSequence = this.model.get('sortSequence') ? this.model.get('sortSequence') : '';
            if (this.newItem) {
                templateData.printRunSheet = this.options.printRunSheet;
            }

            return templateData;
        },

        onRender: function () {
            $.material.init();

            this.getRegion('selectPattern').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'pattern',
                    code: 'PATTERN_PREP'
                }
            }));

            this.getRegion('selectDepartment').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'department',
                    code: 'DEPT'
                }
            }));

            this.getRegion('selectRunGroup').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'group',
                    code: 'RUNGROUP_PREP'
                }
            }));
            this.getRegion('selectSequenceFormat').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'sequenceFormat',
                    code: 'SEQUENCE'
                }
            }));

            if (this.newItem) {
                this.getRegion('selectPlateSize').show(new UserCodeListView({
                    model: this.model,
                    config: {
                        modelProperty: 'plateSize',
                        code: 'PLATESIZES_PREP'
                    }
                }));
            }

            this.setPermissions(Settings.get('currentUserModel').hasType('A02'));
            this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionCommentTags({
                model: this.model
            }));
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate) && this.isValidRunGroup()) {
                this.model.save();
            }
        },

        onSave: function (model) {
            var modelJSON = model.toJSON();
            this.model.set(modelJSON); //this.options.model.set(modelJSON);
            this.triggerMethod('saved');
            this.box.modal('hide');
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },

        isValidRunGroup: function () {
            return true;
        },

        onChangePrintRunSheet: function (event) {
            this.model.set('printRunSheet', event.target.checked);
        },

        onPlateLayout: function () {
            this.createEditSmpIdPlateView = new CreateEditSmpIdPlateLayoutView({
                model: this.model,
                mode: 'edit',
                editTemplate: true
            });
            this.createEditSmpIdPlateView.show({
                title: _.i18n('run.editSmdIdPlateView.title') + ' - ' + this.model.get('name'),
                className: 'popupEnterPlateLayout'
            });
        }
    });
});
