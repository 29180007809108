/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemPopup',[
	'module',
	'underscore',
	'dialogFormView'
], function (
	module,
	_,
	DialogFormView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: _.template(''),
		events: {
			'click': 'onPopupClick'
		},
		tagName: 'span',
		className: function () {
			return 'mdi ' + (this.options.field && this.options.field.param && this.options.field.param.display ? this.options.field.param.display.mdi : '') + ' clickable';
		},
		attributes: function () {
			return {
				'style': 'line-height: 25px;',
				'data-field-name': this.options.field.field,
				'disabled': this.options.field.param && this.options.field.param.readOnly
			};
		},
		serializeData: function () {
			var templateData = {};
			templateData.field = this.options.field;
			templateData.mdi = this.options.field && this.options.field.param && this.options.field.param.display ? this.options.field.param.display.mdi : '';
			return templateData;
		},
		onPopupClick: function () {
			var config = _.findWhere(this.model.getEntity().getCreateEditLine(this.model), {field: this.options.field.field});
			config.param.config({model: this.model});
		}
	});
});
