define('entities/caccounts/lisRecordQueries',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.LisRecordQuery = Backbone.RelationalModel.extend({
        defaults: {
            createdDate: null,
            result: {},
            query: '',
            responseDate: null,
            responseType: null
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        jsonObjectName: 'LisRecordQueryJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LisRecordQuery',
        getEntity: function () {
            return module.exports;
        },
        importable: false,
        retry: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/export/result/ASTM/' + this.get('lisRecord').get('secId') + '/' + this.get('secId') + '/retry');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('lisRecord').fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.ns.LisRecordQueryCollection = Backbone.Collection.extend({
        model: app.ns.LisRecordQuery
    });


    module.exports = _.defaults({
        getName: function () {
            return 'lisrecord';
        },
        getUrl: function () {
            return 'v2/export/result/ASTM';
        },
        getDynamicJson: function () {
            return 'LisRecordJson';
        },
        getCreateEditLine: function () {
            return [
                {'field': 'createdDate', 'param': {'type': 'DATETIME', 'display': {'class': 'col-xs-2 p-l-2 p-r-2'}}},
                {'field': 'responseDate', 'param': {'type': 'DATETIME', 'display': {'class': 'col-xs-2 p-l-2 p-r-2'}}},
                {'field': 'responseType', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2 p-l-2 p-r-2'}}},
                {'field': 'assay', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1 p-l-2 p-r-2'}}},
                {'field': 'targetCode', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1 p-l-2 p-r-2'}}},
                {'field': 'resultCode', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1 p-l-2 p-r-2'}}},
                {'field': 'cts', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1 p-l-2 p-r-2'}}},
                {
                    'field': 'result',
                    'param': {
                        'type': 'POPUP',
                        'config': this.showResults,
                        'display': {'class': 'col-w-5 p-r-2 p-l-2', 'mdi': 'mdi-eye'}
                    }
                },
                {
                    'field': 'query',
                    'param': {
                        'type': 'POPUP',
                        'config': this.showQuery,
                        'display': {'class': 'col-w-5 p-r-2 p-l-2', 'mdi': 'mdi-eye'}
                    }
                },
                {
                    'field': 'retry',
                    'param': {
                        'type': 'POPUP',
                        'config': this.retry,
                        'display': {
                            'class': 'col-w-5 p-r-2 p-l-2', 'mdi': 'mdi-refresh',
                            'condition': {
                                key: 'responseType',
                                value: 'FAILED'
                            }
                        }
                    }
                }
            ];
        },
        showResults: function (param) {
            require(['adminExportStatusController'], function (Controller) {
                Controller.showResults(param);
            });
        },
        showQuery: function (param) {
            require(['adminExportStatusController'], function (Controller) {
                Controller.showQuery(param);
            });
        },
        retry: function (param) {
            $('.js-global-loader').show();
            param.model.retry().always(function () {
                $('.js-global-loader').hide();
            });
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.LisRecordQuery.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getConfigCreateEditLine: function () {
            return {
                editButton: false
            };
        }
    }, Dynamic);
});
