define('entities/caccounts/mbanaresgrs',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/mbanares'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.MbAnaResGr = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/mbanaresgrs/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'comment': '',
            'mbAnaRes': [],
            'mbAnas': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'mbAnaRes',
            relatedModel: 'MbAnaRes',
            collectionType: 'MbAnaResCollection'
        }, {
            type: Backbone.HasMany,
            key: 'mbAnas',
            relatedModel: 'MbAna',
            collectionType: 'MbAnaCollection'
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/mbanaresgrs');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'MbAnaResGrJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'MbAnaResGr',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.MbAnaResGrCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/mbanaresgrs');
        },
        model: app.ns.MbAnaResGr
    });

    var API = {

        getMbAnaResGrs: function (secId) {
            var url = Settings.url('compuzz', 'v2/mbanaresgrs/' + secId + '/mbanares'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (items) {
                    defer.resolve(items);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });

            return defer.promise();
        }
    };

    App.reqres.setHandler('mbanaresgr:get:list', function (secId) {
        return API.getMbAnaResGrs(secId);
    });

    module.exports = _.defaults({
        getName: function () {
            return 'mbAnaResGr';
        },
        getUrl: function () {
            return 'v2/mbanaresgrs';
        },
        getDynamicJson: function () {
            return 'MbAnaResGrJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'mbanaresgr-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'mbanaresgr-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('mbAnaResGr.mbAnaResCode'),
                name: 'mbAnaRes',
                classes: 'mbanaresgr-link',
                formatter: this.mbAnaResCodeFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('mbAna'),
                name: 'mbAnas',
                classes: 'mbanaresgr-link',
                formatter: viewDynamic.codeNameListFormatter,
                search: true,
                index: 'mbAnaCode',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'mbanaresgr-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        mbAnaResCodeFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }

            var values = [];
            _.each(cellValue, function (mbAnaRes) {
                values.push(mbAnaRes.code);
            });
            cellValue = values.join(', ');
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.MbAnaResGr.findOrCreate(modelJson);
        },
        getCreateEditFieldCustom: function () {
            return [
                {field: 'mbAnas', param: {type: 'LIST2ENTITY'}}
            ];
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.MbAnaResGrCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        }
    }, Dynamic);
});
