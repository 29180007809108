
define('template!pcrWellResultOtherItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="noWrapHidden result-wellres"></div>\n<div class="noWrapHidden result-channel">\n    <label data-html="true" data-toggle="tooltip"></label>\n</div>\n\n<div class="noWrapHidden result-target">\n    <label data-html="true" data-toggle="tooltip" title="' +
((__t = ( propC )) == null ? '' : __t) +
'">' +
((__t = ( mbAna )) == null ? '' : __t) +
'</label>\n</div>\n\n<div class="noWrapHidden result-edit">\n    ';
 if (!refPcrWellExported && !readOnly) { ;
__p += '\n    <div class="btn-edit-well-target-result mdi-content-create clickable"\n         name="well-editTarget"\n         style="font-size: 16px; top: 4px;" target="' +
((__t = ( result )) == null ? '' : __t) +
'"\n         title="' +
((__t = ( _.i18n('icon.edit.wellTarget') )) == null ? '' : __t) +
'"></div>\n    ';
 } ;
__p += '\n</div>\n\n<div class="noWrapHidden result-result"\n     style="position: relative; display: flex;">\n    ' +
((__t = ( iconHtml )) == null ? '' : __t) +
'\n    ' +
((__t = ( resCode )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-num">\n    ' +
((__t = ( cts )) == null ? '' : __t) +
' ' +
((__t = ( _.isEmpty(quantifications) ? '' : '(' + quantifications + ')' )) == null ? '' : __t) +
'\n</div>\n<div class="noWrapHidden result-action"></div>\n<div class="noWrapHidden result-custom"></div>\n\n<div class="noWrapHidden result-comment" title="' +
((__t = ( comment )) == null ? '' : __t) +
'">\n    ' +
((__t = ( comment )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-error" title="' +
((__t = ( errorMessage )) == null ? '' : __t) +
'">\n    ' +
((__t = ( errorMessage )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-val1">\n    <label title="' +
((__t = ( userVal1Title )) == null ? '' : __t) +
'">\n        ' +
((__t = ( userVal1Code )) == null ? '' : __t) +
'\n    </label>\n</div>\n\n<div class="noWrapHidden result-val2">\n    <label title="' +
((__t = ( userVal2Title )) == null ? '' : __t) +
'">\n        ' +
((__t = ( userVal2Code )) == null ? '' : __t) +
'\n    </label>\n</div>';

}
return __p
};});

