
define('template!wellDisplayMagPixView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row hidden-print m-b-0" style="' +
((__t = ( !displayHeader?'display: none;':'' )) == null ? '' : __t) +
'">\n    <div class="row m-b-0" style="display: flex; position: relative; height: 77px; bottom: 11px;">\n        <!-- Action buttons -------------------------------------------->\n        <div class="col-w-all col-w-32 f-l p-l-0 p-r-0" style="display: flex; justify-content: flex-end;"><!--noWrapHidden-->\n            <!-- validation -->\n            <div class="css-grayed-out-header">\n                <div style="padding: 2px;">\n                    <div class="run-valView-action-nav-container">\n                        <button class="run-valView-action-nav-drop-btn js-run-valView-action-nav-drop-btn noWrapHidden">\n                            ' +
((__t = (_.i18n('valView.val'))) == null ? '' : __t) +
'\n                        </button>\n                        <div class="run-valView-action-nav-list hidden">\n                            <div>\n                                <button class="btn btn-sm btn-validate run-valView-action-nav-list-btn clickable">\n                                    <div class="noWrapHidden"\n                                         title="' +
((__t = ( _.i18n('well.edit.validation.1.popover') )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( _.i18n('well.edit.validation.1') )) == null ? '' : __t) +
'\n                                    </div>\n                                </button>\n                            </div>\n                            <div>\n                                <button class="btn btn-sm btn-validate2 run-valView-action-nav-list-btn clickable">\n                                    <div class="noWrapHidden"\n                                         title="' +
((__t = ( _.i18n('well.edit.validation.2.popover') )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( _.i18n('well.edit.validation.2') )) == null ? '' : __t) +
'\n                                    </div>\n                                </button>\n                            </div>\n                            <div>\n                                <button class="btn btn-sm btn-validate12 run-valView-action-nav-list-btn clickable">\n                                    <div class="noWrapHidden"\n                                         title="' +
((__t = ( _.i18n('well.edit.validation.1.2.popover') )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( _.i18n('well.edit.validation.1.2') )) == null ? '' : __t) +
'\n                                    </div>\n                                </button>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class="valViewGraphHeaderGroupCellContainer" style="padding-left: 4px;">\n                    <div style="display: flex;">\n                        <div class=" clickable hidden-print btn-validate valView-graph-button"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('well.edit.validation.1') )) == null ? '' : __t) +
'">\n                                            <span class="mdi mdi-check check-val1-graphAction-icon val-green"\n                                                  style="left: 1px;">\n                                            </span>\n                        </div>\n                        <div class=" clickable hidden-print btn-validate2 valView-graph-button"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('well.edit.validation.2') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-check check-val1-graphAction-icon val-light"></span>\n                            <span class="mdi mdi-check check-val2-graphAction-icon val-green"></span>\n                        </div>\n                        <div class=" clickable hidden-print btn-validate12 valView-graph-button"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('well.edit.validation.1.2') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-check check-val1-graphAction-icon val-green"></span>\n                            <span class="mdi mdi-check check-val2-graphAction-icon val-green"></span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <!-- miscaleanous -->\n            <div class="css-grayed-out-header">\n                <div style="padding: 2px;">\n                    <div class="run-valView-action-nav-container">\n                        <button class="run-valView-action-nav-drop-btn js-run-valView-action-nav-drop-btn noWrapHidden">\n                            ' +
((__t = ( _.i18n('common.miscaelenous') )) == null ? '' : __t) +
'\n                        </button>\n                        <div class="run-valView-action-nav-list hidden">\n                            <div>\n                                <button class="btn btn-sm valView-repeat-btn js-valview-repeat-pcr run-valView-action-nav-list-btn clickable">\n                                    <div class="noWrapHidden"\n                                         title="' +
((__t = ( _.i18n('repeat.pcr.popover') )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( _.i18n('repeat.pcr') )) == null ? '' : __t) +
'\n                                    </div>\n                                </button>\n                            </div>\n                            <div>\n                                <button class="btn btn-sm valView-repeat-btn js-valview-repeat-pcr-control run-valView-action-nav-list-btn clickable">\n                                    <div class="noWrapHidden"\n                                         title="' +
((__t = ( _.i18n('repeat.pcr.control') )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( _.i18n('repeat.pcr.control') )) == null ? '' : __t) +
'\n                                    </div>\n                                </button>\n                            </div>\n                            <div>\n                                <button class="btn btn-sm valView-repeat-btn js-valview-repeat-extract run-valView-action-nav-list-btn clickable"\n                                        style="display: flex; width: 300px;">\n                                    <div class="noWrapHidden"\n                                         style="width: 240px; padding-top: 3px;"\n                                         title="' +
((__t = ( _.i18n('repeat.extract.popover') )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( _.i18n('repeat.extract') )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div class="valView-dilution-dropdown">\n                                        <select id="valViewDillution" name="dillution"\n                                                style="color: black; height: 21px; position: relative; width: 60px;">\n                                            <option value="No dil." selected>No dil.</option>\n                                            <option value="1:2">1:2</option>\n                                            <option value="1:5">1:5</option>\n                                        </select>\n                                    </div>\n                                </button>\n                            </div>\n                            <div>\n                                <button class="btn btn-sm valView-repeat-btn js-valview-repeat-extract-control run-valView-action-nav-list-btn clickable"\n                                        style="display: flex; width: 300px;">\n                                    <div class="noWrapHidden"\n                                         style="width: 240px; padding-top: 3px;"\n                                         title="' +
((__t = ( _.i18n('repeat.extract.control.popover') )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( _.i18n('repeat.extract.control') )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div class="valView-dilution-dropdown">\n                                        <select id="valViewDillutionControl" name="dillution"\n                                                style="color: black; height: 21px; position: relative; width: 60px;">\n                                            <option value="No dil." selected>No dil.</option>\n                                            <option value="1:2">1:2</option>\n                                            <option value="1:5">1:5</option>\n                                        </select>\n                                    </div>\n                                </button>\n                            </div>\n                            <div>\n                                <button class="btn btn-sm valView-repeat-btn btn-confirm-result run-valView-action-nav-list-btn clickable">\n                                    <div class="noWrapHidden"\n                                         title="' +
((__t = ( _.i18n('update.confirmResult.popover') )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( _.i18n('update.confirmResult') )) == null ? '' : __t) +
'\n                                    </div>\n                                </button>\n                            </div>\n                            <div>\n                                <button class="btn btn-sm valView-repeat-btn btn-change-to-neg run-valView-action-nav-list-btn clickable">\n                                    <div class="noWrapHidden"\n                                         title="' +
((__t = ( _.i18n('update.changeToNeg.popover') )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( _.i18n('update.changeToNeg') )) == null ? '' : __t) +
'\n                                    </div>\n                                </button>\n                            </div>\n                        </div>\n                        <div class="run-valView-action-nav-list-empty hidden">\n                            <!-- transparent div needed to avoid menu to close when mousse leave menu to access dilution dropdown menu -->\n                        </div>\n\n                    </div>\n                </div>\n                <div class="valViewGraphHeaderGroupCellContainer" style="padding-left: 3px;">\n                    <!--hidden-->\n                    <div style="display: flex;">\n                        <div class="headerActionButton clickable hidden-print js-run-valView-action-nav-drop-cell-btn"\n                             style="margin: 2px 2px 2px 4px; height: 35px; width: 37px;"\n                             title="' +
((__t = ( _.i18n('more') )) == null ? '' : __t) +
'">\n                                            <span class="mdi mdi-menu-down-outline mdiHeaderActionButtonIcon"\n                                                  style="bottom: 11px; left: 0; font-size: 36px;"></span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="col-w-all f-l p-l-0 p-r-0" style="display: flex; justify-content: center;">\n            <div id="targets-view" style="padding: 0; margin: 0; width: fit-content;"></div>\n        </div>\n    </div>\n    <div class="row m-b-0" style="display: none;">\n        <div class="col-xs-12">\n            <div class="col-xs-2 p-t-3" style="font-weight: bold;">\n                ' +
((__t = ( _.i18n('graph.validation') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-xs-2">\n                <div class="row m-b-0">\n                    <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0"\n                           style="font-weight: normal;">' +
((__t = ( _.i18n('graph.res.all') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                        <label>\n                            <input class="cbx-validation js-keep-logged-in" data-value="all"\n                                   id="validation-all"\n                                   type="checkbox">\n                        </label>\n                    </div>\n                </div>\n            </div>\n            <div class="col-xs-2">\n                <div class="row m-b-0">\n                    <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0"\n                           style="font-weight: normal;">' +
((__t = ( _.i18n('graph.validation.tovalidate')
                        )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                        <label>\n                            <input class="cbx-validation js-keep-logged-in" data-value="1"\n                                   id="validation-tovalidate"\n                                   type="checkbox">\n                        </label>\n                    </div>\n                </div>\n            </div>\n            <div class="col-xs-2">\n                <div class="row m-b-0">\n                    <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0"\n                           style="font-weight: normal;">' +
((__t = ( _.i18n('graph.validation.expert')
                        )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                        <label>\n                            <input class="cbx-validation js-keep-logged-in" data-value="2"\n                                   id="validation-expert"\n                                   type="checkbox">\n                        </label>\n                    </div>\n                </div>\n            </div>\n            <div class="col-xs-2">\n                <div class="row m-b-0">\n                    <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0"\n                           style="font-weight: normal;">' +
((__t = ( _.i18n('graph.res.validated') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                        <label>\n                            <input class="cbx-validation js-keep-logged-in" data-value="4"\n                                   id="validation-validated"\n                                   type="checkbox">\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class="row m-b-0 js-graph"></div>\n';
 if (!hideOkButton) { ;
__p += '\n<div class="row hidden-print">\n    <div class="pull-right p-r-15">\n        <button class="btn btn-primary disabled-grey js-confirm" disabled>' +
((__t = ( _.i18n('common.ok') )) == null ? '' : __t) +
'\n        </button>\n    </div>\n</div>\n';
 } ;


}
return __p
};});

