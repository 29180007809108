define('wellEditSmpIdPlateView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!wellEditSmpIdPlateTpl',
    'template!wellEditScanSmpIdPlateTpl',
    'template!wellEditExportSmpIdPlateTpl',
    'template!createEditControlSidLayoutTpl',
    'template!createEditAssignAssayLayoutTpl',
    'template!createEditAddRemoveTestLayoutTpl',
    'template!createEditClearWellsLayoutTpl',
    'template!createEditGenerateSmpIdLayoutTpl',
    'template!createEditimportFromOtherRunLayoutTpl',
    'savingBehavior',
    'underscore',
    'app',
    'jquery',
    'bootbox',
    'customBootboxMessage',
    'dynamicCreateEditCodeList',
    'settings',
    'emptyView',
    'runRemoteFolderView',
    'runChooseUploadView',
    'runChooseDownloadView',
    'wellEditSmpidView',
    'autocompleteView',
    'entities/caccounts/fileFormatPlateLayouts',
    'entities/caccounts/assay',
    'entities/admin/codelist',
    'entities/caccounts/pcrruns',
    'entities/caccounts/pcrwells',
    'entities/caccounts/pcrwelltemplates',
    'entities/caccounts/pcrruntemplates'
], function (
    module,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    createEditScanLayoutTpl,
    createEditExportLayoutTpl,
    createEditControlSidLayoutTpl,
    createEditAssignAssayLayoutTpl,
    createEditAddRemoveTestLayoutTpl,
    createEditClearWellsLayoutTpl,
    createEditGenerateSmpIdLayoutTpl,
    createEditimportFromOtherRunLayoutTpl,
    SavingBehavior,
    _,
    App,
    $,
    Bootbox,
    CustomBootboxMessage,
    CodeListView,
    Settings,
    EmptyView,
    RunRemoteFolderView,
    ChooseUploadView,
    ChooseDownloadView,
    WellEditSmpidView,
    AutocompleteView,
    FileFormatPlateLayouts,
    Assays,
    CodeLists
) {
    'use strict';

    module.exports = DialogFormView.extend({

        regions: {
            selectAssay: '.js-select-assay-region',
            selectAssayRemove: '.js-select-assayRemove-region',
            selectFileFormat: '.js-select-fileFormat-region',
            selectExportSource: '.js-select-exportSource-region',
            selectPcrRunList: '.js-select-pcrRunList-region',
            sourcePlateDirection: '.js-source-plate-direction-region',
            targetPlateDirection: '.js-target-plate-direction-region'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-hide': 'onCancel',
            'click .js-start-edit': 'onCheckStartEdit',
            'click .js-exit-edit': 'onExitEdit',
            'click .js-ctrl-sampleId': 'onControlSampleId',
            'click .js-replace-assay': 'onCheckReplaceAssay',
            'click .js-apply-assay': 'onCheckApplyAssay',
            'click .js-clear-assay': 'onClearAssay',
            'click .js-add-test': 'onAddTest',
            'click .js-remove-test': 'onRemoveTest',
            'click .js-clear-wells': 'onClearWells',
            'click .js-generate-sample-id': 'onCheckGenerateSampleId',
            'click .js-clear-sample-id': 'onClearSampleId',
            'click .js-generate-sample-id-popup': 'onGenerateSampleIdPopupClick',
            'click .js-clear-sample-id-popup': 'onClearSampleIdClick',
            'click @ui.importPlateLayout': 'onCheckPlateLayoutImport',
            'click @ui.exportPlateLayout': 'onExportPlateLayout',
            'click @ui.exportPlateLayoutPopup': 'onExportPlateLayoutPopup',
            'click @ui.controlPlateLayoutPopup': 'onControlPlateLayoutPopup',
            'change @ui.inputSampleIdStart': 'onChangeSampleIdStart',
            'click @ui.stopScanBtn': 'onStopScanBtn',
            'click .js-import-fromOtherRun-button': 'onConfirmImportFromOtherRun',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        ui: {
            stopScanBtn: '.js-stop-scan-btn',
            inputStart: '.js-input-start-well',
            inputFromWell: '.js-input-from-well',
            inputToWell: '.js-input-to-well',
            inputSampleIdFrom: '.js-generate-sample-id-fromWell',
            inputSampleIdTo: '.js-generate-sample-id-toWell',
            inputSampleIdStart: '.js-generate-StartWith-sampleId',
            generateSmpIdBtn: '.js-generate-sample-id-btn',
            clearSmpIdBtn: '.js-clear-sample-id-btn',
            blockLoading: '.empty-page-loader',
            inputFromSampleId: '.js-generate-sample-id-from',
            inputSampleId: '.js-generate-sample-id',
            assignAssay: '.js-apply-assay',
            clearAssay: '.js-clear-assay',
            applyAssayBtn: '.js-apply-assay-btn',
            removeAssayBtn: '.js-clear-assay-btn',
            replaceAssayBtn: '.js-replace-assay-btn',
            clearSampleId: '.js-clear-sample-id',
            importPlateLayout: '.import-plate-layout-button',
            exportPlateLayout: '.export-plate-layout-button',
            exportPlateLayoutPopup: '.export-plate-layout-popup-button',
            inputToComputerPopup: '.inputToComputerPopup',
            controlPlateLayoutPopup: '.control-plate-layout-popup-button',
            input: '.js-info-input'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            change: 'renderSafe',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'refFileFormat', type: 'required'}
        ],

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        getTemplate: function () {
            switch (this.mode) {
                case 'importFromOtherRun':
                    return createEditimportFromOtherRunLayoutTpl;
                case 'scan':
                    return createEditScanLayoutTpl;
                case 'edit':
                    return createEditScanLayoutTpl;
                case 'export':
                    return createEditExportLayoutTpl;
                case 'all':
                    return createEditLayoutTpl;
                case 'assign-assay':
                case 'replace-assay':
                    return createEditAssignAssayLayoutTpl;
                case 'remove-assay':
                    return createEditAssignAssayLayoutTpl;
                case 'generate-smpId':
                    return createEditGenerateSmpIdLayoutTpl;
                case 'remove-smpId':
                    return createEditGenerateSmpIdLayoutTpl;
                case 'add-test':
                case 'remove-test':
                    return createEditAddRemoveTestLayoutTpl;
                case 'clear-wells':
                    return createEditClearWellsLayoutTpl;
                case 'control':
                    return createEditControlSidLayoutTpl;
                default:
                    return createEditLayoutTpl;
            }
        },

        initialize: function (options) {
            this.mode = options.mode;
            this.type = this.options.model.getType();
            this.model = new Backbone.Model();
        },

        onControlSampleId: function () {
            if (this.mode === 'all' || this.mode === 'scan' || this.mode === 'edit') {
                this.showWell(true);
            }
            this.onExitEdit();
        },

        onStartEdit: function () {
            this.$el.find(this.ui.inputFromWell).prop('disabled', true);
            this.$el.find(this.ui.inputToWell).prop('disabled', true);

            this.$el.find(this.ui.inputSampleIdFrom).prop('disabled', true);
            this.$el.find(this.ui.inputSampleIdTo).prop('disabled', true);
            this.$el.find(this.ui.inputSampleIdStart).prop('disabled', true);
            this.$el.find(this.ui.clearSampleId).prop('disabled', true);
            this.$el.find(this.ui.inputFromSampleId).prop('disabled', true);

            this.$el.find(this.ui.assignAssay).prop('disabled', true);
            this.$el.find(this.ui.clearAssay).prop('disabled', true);
            this.$el.find(this.regions.selectAssay).find('input').prop('disabled', true);
            this.$el.find(this.regions.selectAssayRemove).find('input').prop('disabled', true);
            this.$el.find(this.ui.inputSampleId).prop('disabled', true);

            this.$el.find(this.ui.importPlateLayout).prop('disabled', true);
            this.$el.find(this.ui.exportPlateLayout).prop('disabled', true);
            this.$el.find(this.regions.selectFileFormat).find('input').prop('disabled', true);

            this.$el.find(this.regions.selectExportSource).find('input').prop('disabled', true);

            if (this.mode === 'all' || this.mode === 'scan' || this.mode === 'edit') {
                this.showWell(true);
            }
            this.box.closeButton = false;

            if (this.type !== 'pcrruntemplate') {
                this.options.model.startScanning();
            }
        },

        onExitEdit: function () {
            if (this.type === 'pcrruntemplate') {
                this.box.modal('hide');
            } else {
                this.ui.blockLoading.show();
                this.exitEdit()
                    .done(_.bind(function () {
                        this.stopScanning();
                    }, this));
            }
        },

        exitEdit: function () {
            var defer = $.Deferred();
            this.ui.blockLoading.show();
            App.request('run:pcr:controlSID', this.options.model, true, true)
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                    defer.resolve();
                }, this));
            return defer.promise();
        },

        stopScanning: function () {
            this.options.model.stopScanning()
                .done(_.bind(function () {
                    if (this.mode === 'all') {
                        this.render();
                    }

                    if (this.mode === 'export' || this.mode === 'scan' || this.mode === 'edit') {
                        this.box.modal('hide');
                    }
                }, this))
                .fail(_.bind(function (error) {
                    this.onStopScanningError(error);
                }, this))
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                }, this));
        },

        onStopScanningError: function (error) {
            var params = {
                title: 'End of scan error',
                message: error.status + ' ' + error.statusText,
                type: 'error'
            };
            CustomBootboxMessage.customAlert(params);
            this.render();
        },

        onControlPlateLayoutPopup: function () {
            var controlCheckBoxes = [];
            $('.controlSidPopup input.controlSidCheckBox:checked').each(function () {
                controlCheckBoxes.push($(this).attr('name'));
            });

            this.ui.blockLoading.show();
            App.request('run:pcr:controlSID', this.options.model, controlCheckBoxes.includes('controlSidLengthCheckbox'), controlCheckBoxes.includes('controlDuplicateSidCheckbox'))
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                }, this));
            this.box.modal('hide');
        },

        onReplaceAssay: function () {
            if (!this.currentNewAssay || !this.currentNewAssay.secId || !this.currentAssay || !this.currentAssay.secId) {
                Bootbox.alert(_.i18n('plateLayout.undefinedReplaceAssay'));
                return;
            }
            this.ui.blockLoading.show();

            this.options.model.replaceAssayWells(this.currentAssay.secId, this.currentNewAssay.secId, this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    if (this.mode === 'replace-assay') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this))
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                }, this));
        },
        onAddTest: function () {
            this.ui.blockLoading.show();

            this.options.model.applyTestWells(this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    if (this.mode === 'add-test') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this))
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                }, this));
        },

        onApplyAssay: function () {
            if (!this.currentAssay || !this.currentAssay.secId) {
                Bootbox.alert(_.i18n('plateLayout.undefinedAssay'));
                return;
            }
            this.ui.blockLoading.show();

            this.options.model.applyAssayWells(this.currentAssay.secId, this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    if (this.mode === 'assign-assay') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this))
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                }, this));
        },

        onClearAssay: function () {
            Bootbox.confirm(_.i18n('plateLayout.clearAssay.confirm'), _.bind(function (result) {
                if (!result) {
                    return;
                }
                this.ui.blockLoading.show();

                this.options.model.clearAssay(this.ui.inputFromWell.val(), this.ui.inputToWell.val(), this.currentAssay)
                    .done(_.bind(function () {
                        if (this.mode === 'remove-assay') {
                            this.box.modal('hide');
                        } else {
                            this.render();
                        }
                    }, this))
                    .always(_.bind(function () {
                        this.ui.blockLoading.hide();
                    }, this));
            }, this));
        },

        onRemoveTest: function () {
            Bootbox.confirm(_.i18n('plateLayout.remove.test.confirm'), _.bind(function (result) {
                if (!result) {
                    return;
                }
                this.ui.blockLoading.show();

                this.options.model.removeTest(this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                    .done(_.bind(function () {
                        if (this.mode === 'remove-test') {
                            this.box.modal('hide');
                        } else {
                            this.render();
                        }
                    }, this))
                    .always(_.bind(function () {
                        this.ui.blockLoading.hide();
                    }, this));
            }, this));
        },

        onClearWells: function () {
            Bootbox.confirm(_.i18n('plateLayout.clear.wells.confirm'), _.bind(function (result) {
                if (!result) {
                    return;
                }
                this.ui.blockLoading.show();

                this.options.model.clearWells(this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                    .done(_.bind(function () {
                        if (this.mode === 'clear-wells') {
                            this.box.modal('hide');
                        } else {
                            this.render();
                        }
                    }, this))
                    .always(_.bind(function () {
                        this.ui.blockLoading.hide();
                    }, this));
            }, this));
        },

        onChangeSampleIdStart: function (e) {
            var $target = $(e.currentTarget),
                display = $target && $target.val() && $target.val() !== undefined && $target.val() !== null && $target.val() !== '';
            this.$el.find(this.ui.generateSmpIdBtn).prop('disabled', !display);
            this.$el.find(this.ui.clearSmpIdBtn).prop('disabled', !display);
        },

        onGenerateSampleIdPopupClick: function () {
            var fromVal = this.ui.inputSampleIdFrom.val(),
                fromValideValue = !!(fromVal && fromVal !== '');

            var toVal = this.ui.inputSampleIdTo.val(),
                toValideValue = !!(toVal && toVal !== '');

            if (!this.ui.inputSampleIdStart.val() || this.ui.inputSampleIdStart.val() === '' || !fromValideValue || !toValideValue) {
                var notification = Bootbox.alert(_.i18n('plateLayout.wrong.value'));
                notification.show();
                setTimeout(function () {
                    notification.modal('hide');
                }, 8000);
            } else {
                this.onGenerateSampleIdClick();
            }
        },

        onGenerateSampleIdClick: function () {
            if (this.options.model.get('status') >= 2) {
                var callback = _.bind(function (result) {
                    if (result) {
                        this.onGenerateSampleId();
                    }
                }, this);
                this.statusChangeSidAlert(callback);
            } else {
                this.onGenerateSampleId();
            }
        },

        statusChangeSidAlert: function (callback) {
            var confirmParams = {
                message: _.i18n('pcrRun.st2+.sid.warning.message'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onGenerateSampleId: function () {
            if (!this.ui.inputSampleIdStart.val() || this.ui.inputSampleIdStart.val() === '') {
                Bootbox.alert(_.i18n('plateLayout.undefinedStartWithNB'));
                return;
            }
            if (isNaN(this.ui.inputSampleIdStart.val().slice(-1))) {
                this.ui.inputSampleIdStart.val(this.ui.inputSampleIdStart.val() + '01');
            }
            this.ui.blockLoading.show();
            this.options.model.generateSampleId(this.ui.inputSampleIdStart.val(), this.ui.inputSampleIdFrom.val(), this.ui.inputSampleIdTo.val())
                .done(_.bind(function () {
                    if (this.mode === 'generate-smpId') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this))
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                }, this));
        },

        onClearSampleIdClick: function () {
            if (this.options.model.get('status') >= 2) {
                var callback = _.bind(function (result) {
                    if (result) {
                        this.onClearSampleId();
                    }
                }, this);

                this.statusChangeSidAlert(callback);
            } else {
                this.onClearSampleId();
            }
        },

        onClearSampleId: function () {
            this.ui.blockLoading.show();
            this.options.model.clearSampleId(this.ui.inputSampleIdFrom.val(), this.ui.inputSampleIdTo.val())
                .done(_.bind(function () {
                    if (this.mode === 'remove-smpId') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this))
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                }, this));
        },

        serializeData: function () {
            var templateData = {};
            templateData.status = this.options.model.get('status');
            templateData.plateSize = this.options.model.get('plateSize');
            templateData.accept = '.xlsx,.xls,.xml,.rdml,.csv';
            templateData.mode = this.mode;
            templateData.newItem = this.options.newItem;
            templateData.statusIcon = this.options.statusIcon;
            templateData.valStatusIcon = this.options.valStatusIcon;
            var plateSize = this.options.model.get('plateSize');
            var rowNb = plateSize.substring(0, 1);
            var columnNb = parseInt(plateSize.substring(1, plateSize.length), 10);
            var max = parseInt(columnNb.toString().substring(0, 1), 10);

            templateData.column = columnNb;
            templateData.row = rowNb.charCodeAt(0) - 64;

            var pattern = '(';
            var patternRow = '[A-' + rowNb + ']';
            for (var i = 0; i <= max; i++) {
                if (i === max) {
                    var plateColumnNb = columnNb;
                    if (plateColumnNb > 10) {
                        pattern = pattern + patternRow + i + '[0-' + (plateColumnNb - 10) + ']';
                    } else {
                        pattern = pattern + patternRow + i + '[0-' + plateColumnNb + ']';
                    }
                } else {
                    pattern = pattern + patternRow + i + '[0-9]';
                }
                pattern = pattern + '|';
            }
            templateData.pattern = pattern.substr(0, pattern.length - 1) + ')';

            if (this.type === 'pcrRunTemplate') {
                templateData.displayName = templateData.code;
            } else {
                templateData.displayName = templateData.name;
            }

            return templateData;
        },

        onRender: function () {
            if (this.mode === 'scan' || this.mode === 'edit') {
                this.showWell(true);
            }
            if (this.mode === 'all') {
                this.showWell(false);
            }
            this.triggerMethod('enable:cancel:confirm');
            if (this._isShown) {
                this.triggerMethod('show', this);
            }
        },

        onShow: function () {
            if (this.mode === 'all' || this.mode === 'replace-assay') {
                this.assayReplaceView = new AutocompleteView(
                    this._getAutocompleteOptionObject(Assays.getAutocompleteParam({
                        modelProperty: 'refAssay',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'code',
                            'cyclerSecId': this.options.model.get('refCycler') && this.options.model.get('refCycler').get('secId') ? this.options.model.get('refCycler').get('secId') : null,
                            'cyclerPublicSecId': this.options.model.get('refCyclerPublic') ? this.options.model.get('refCyclerPublic').secId : null,
                            'kitProtSecId': this.options.model.get('refKitProt') ? this.options.model.get('refKitProt').secId : null
                        },
                        callBackOnChange: this.onChangeAssayReplace
                    }))
                );
                this.getRegion('selectAssay').show(this.assayReplaceView);

                this.$el.find(this.ui.removeAssayBtn).prop('disabled', false);
                this.assayRemoveView = new AutocompleteView(
                    this._getAutocompleteOptionObject(Assays.getAutocompleteParamFromRun(this.options.model, {
                        modelProperty: 'refAssay',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'code'
                        },
                        callBackOnChange: this.onChangeRemoveAssay
                    }))
                );
                this.getRegion('selectAssayRemove').show(this.assayRemoveView);
            }

            if (this.mode === 'all' || this.mode === 'assign-assay') {
                this.assayView = new AutocompleteView(
                    this._getAutocompleteOptionObject(Assays.getAutocompleteParam({
                        modelProperty: 'refAssay',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'code',
                            'cyclerSecId': this.options.model.get('refCycler') && this.options.model.get('refCycler').get('secId') ? this.options.model.get('refCycler').get('secId') : null,
                            'cyclerPublicSecId': this.options.model.get('refCyclerPublic') ? this.options.model.get('refCyclerPublic').secId : null,
                            'kitProtSecId': this.options.model.get('refKitProt') ? this.options.model.get('refKitProt').secId : null
                        },
                        callBackOnChange: this.onChangeAssay
                    }))
                );
                this.getRegion('selectAssay').show(this.assayView);
            }

            if (this.mode === 'remove-assay') {
                this.$el.find(this.ui.removeAssayBtn).prop('disabled', false);
                this.assayRemoveView = new AutocompleteView(
                    this._getAutocompleteOptionObject(Assays.getAutocompleteParamFromRun(this.options.model, {
                        modelProperty: 'refAssay',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'code'
                        },
                        callBackOnChange: this.onChangeRemoveAssay
                    }))
                );
                this.getRegion('selectAssayRemove').show(this.assayRemoveView);
            }

            if (this.mode === 'importFromOtherRun') {
                this.model.set({
                    copySampleId: true,
                    copyAssay: true,
                    copySampleType: true,
                    sourcePlateFillDirection: this.options.model.get('settings')['LAB-SETTINGS'],
                    targetPlateFillDirection: this.options.model.get('settings')['LAB-SETTINGS']
                }, {silent: true});
                this.getRegion('sourcePlateDirection').show(new CodeListView({
                    model: this.model,
                    config: {
                        modelProperty: 'sourcePlateFillDirection',
                        code: 'PLATE_FILL_DIRECTION'
                    }
                }));
                this.getRegion('targetPlateDirection').show(new CodeListView({
                    model: this.model,
                    config: {
                        modelProperty: 'targetPlateFillDirection',
                        code: 'PLATE_FILL_DIRECTION'
                    }
                }));

                this.getRegion('selectPcrRunList').show(new AutocompleteView(
                    this._getAutocompleteOptions('pcrRunName', 'name', 'name',
                        'v2/runs/pcr?sord=desc&sidx=creatDate',
                        'common.empty.placeholder', 'name',
                        this.onPcrRunListChange, '', '',
                        App.request('run:pcr:get-fields-to-display'))
                ));
            }

            if (this.mode === 'export') {
                var exportSourceView = new AutocompleteView(
                    this._getAutocompleteOptionObject(
                        CodeLists.getAutocompleteParamForCode({
                            callBackOnChange: this.onChangeExportDestination,
                            codeListCode: 'DOWNL_DEST'
                        })));
                this.getRegion('selectExportSource').show(exportSourceView);
            }

            if (this.mode === 'all' || this.mode === 'export') {
                this.fileFormatView = new AutocompleteView(
                    this._getAutocompleteOptionObject(FileFormatPlateLayouts.getAutocompleteParamForExport({
                        modelProperty: 'refFileFormat',
                        callBackOnChange: this.onChangeFileFormat
                    }))
                );
                this.getRegion('selectFileFormat').show(this.fileFormatView);
            }

            if (this.mode === 'scan') {
                this.onStartEdit();
            }
        },

        onPcrRunListChange: function (fieldName, model) {
            this.onChange('sourceSecId', model ? model.get('secId') : null);
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirmImportFromOtherRun: function () {
            var fieldsToValidate = [
                {name: 'sourceSecId', type: 'required'},
                {name: 'sourceStart', type: 'required'},
                {name: 'sourceEnd', type: 'required'},
                {name: 'sourcePlateFillDirection', type: 'required'},
                {name: 'targetStart', type: 'required'},
                {name: 'targetPlateFillDirection', type: 'required'}
            ];
            if (this.validate(fieldsToValidate)) {
                this.ui.blockLoading.show();
                this.options.model.copyPlateLayoutFrom(this.model.toJSON())
                    .done(_.bind(function () {
                        this.box.modal('hide');
                    }, this))
                    .fail(_.bind(function (error) {
                        this.onImportError(error);
                        this.box.modal('hide');
                    }, this))
                    .always(_.bind(function () {
                        this.ui.blockLoading.hide();
                    }, this));
            }
        },

        onImportError: function (error) {
            var params = {
                title: _.i18n('import.error'),
                message: error.status + ' ' + error.statusText,
                type: 'error'
            };
            CustomBootboxMessage.customAlert(params);
            this.render();
        },

        onChangeExportDestination: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            App.request('setting:get-all-setting', {'pageSize': 100})
                .done(_.bind(function (settings) {
                    this.currentExportDestination = model ? model.toJSON() : null;
                    var remoteAvailable = settings['EXPORT-PLATELAYOUT'].split(';')[0];

                    if (this.currentExportDestination && this.currentExportDestination.code) {
                        if (this.currentExportDestination.code === 'CENTRALLIB') {
                            this.$el.find(this.ui.exportPlateLayoutPopup).prop('disabled', !remoteAvailable);
                        } else {
                            this.$el.find(this.ui.exportPlateLayoutPopup).prop('disabled', false);
                        }
                    } else {
                        this.$el.find(this.ui.exportPlateLayoutPopup).prop('disabled', true);
                    }
                }, this));
        },

        onChangeFileFormat: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.fileFormatSecId = model.get('secId');
                this.$el.find(this.ui.importPlateLayout).prop('disabled', false);
                this.$el.find(this.ui.exportPlateLayout).prop('disabled', false);
            } else {
                this.fileFormatSecId = false;
                this.$el.find(this.ui.importPlateLayout).prop('disabled', true);
                this.$el.find(this.ui.exportPlateLayout).prop('disabled', true);
            }
            this.onChange(fieldName, model);
        },

        onStopScanBtn: function () {
            this.onExitEdit();
        },

        onChangeAssayReplace: function (fieldName, model) {
            this.currentNewAssay = model ? model.toJSON() : null;
            this.$el.find(this.ui.applyAssayBtn).prop('disabled', !model);
            this.replaceButton();
        },
        onChangeAssay: function (fieldName, model) {
            this.currentAssay = model ? model.toJSON() : null;
            this.$el.find(this.ui.applyAssayBtn).prop('disabled', !model);
        },
        onChangeRemoveAssay: function (fieldName, model) {
            this.currentAssay = model ? model.toJSON() : null;
            this.$el.find(this.ui.removeAssayBtn).prop('disabled', !model);
            this.replaceButton();
        },

        replaceButton: function () {
            var enab = this.currentNewAssay && this.currentAssay;
            this.$el.find(this.ui.replaceAssayBtn).prop('disabled', !enab);
        },

        onCheckPlateLayoutImport: function () {
            var bootboxMessage = 'warning.import.change.sampleId';
            this.onCheck('onImportPlateLayout', bootboxMessage);
        },

        onCheckGenerateSampleId: function () {
            var bootboxMessage = 'warning.import.change.sampleId';
            this.onCheck('onGenerateSampleId', bootboxMessage);
        },

        onCheckApplyAssay: function () {
            var bootboxMessage = 'warning.change.assay';
            this.onCheck('onApplyAssay', bootboxMessage);
        },

        onCheckReplaceAssay: function () {
            var bootboxMessage = 'warning.replace.assay';
            this.onCheck('onReplaceAssay', bootboxMessage);
        },

        onCheck: function (type, bootboxMessage) {
            var valStatus = this.options.model.get('valStatus');
            if (valStatus && valStatus.includes('5')) {
                Bootbox.confirm(_.i18n(bootboxMessage), _.bind(function (result) {
                    if (result) {
                        this.validateAction(type);
                    }
                }, this));
            } else {
                this.validateAction(type);
            }
        },

        validateAction: function (type) {
            switch (type) {
                case 'onStartEdit':
                    this.onStartEdit();
                    break;
                case 'onImportPlateLayout':
                    this.onImportPlateLayout();
                    break;
                case 'onGenerateSampleId':
                    this.onGenerateSampleId();
                    break;
                case 'onReplaceAssay':
                    this.onReplaceAssay();
                    break;
                case 'onAddTest':
                    this.onAddTest();
                    break;
                case 'onApplyAssay':
                    this.onApplyAssay();
                    break;
            }
        },

        onImportPlateLayout: function () {
            if (this.validate(this.fieldsToValidate)) {
                App.request('setting:get-all-setting', {'pageSize': 100})
                    .done(_.bind(function (settings) {
                        this.chooseUploadView = new ChooseUploadView({
                            settings: settings,
                            remoteAvailable: settings['IMPORT-PLATELAYOUT-PCR'].split(';')[0],
                            accept: '.xlsx,.xls,.xml,.rdml,.csv'
                        });
                        this.chooseUploadView.show({
                            title: _.i18n('run.importFileFormat')
                        });
                        this.chooseUploadView.listenTo(this.chooseUploadView, 'run:import-xml', _.bind(this.fileUpload, this));
                        this.chooseUploadView.listenTo(this.chooseUploadView, 'run:select-file-to-import', _.bind(this.onSelectFileToImport, this));
                    }, this));
            }
        },

        onError: function (errors, dontShowError) {
            var that = this,
                nameField;
            _.each(errors, function (error) {
                nameField = that.$('input[data-field-name="' + error.name + '"]');
                nameField.addClass('invalidInput');
            });
            if (dontShowError) {
                return;
            }
            this.triggerMethod('errorMessage', _.i18n('warning.mandatoryFields'));
        },

        fileUpload: function (fileInfo) {
            if (this.fileFormatSecId) {
                var fileName = fileInfo.name;
                var runName = this.options.model.get('name');

                if (fileName.includes(runName, 0)) {
                    this.confirmUpload(fileInfo);
                } else {
                    var params = {
                        message: _.i18n('run.controlRunName'),
                        type: 'warning'
                    };

                    var callbacks = [];

                    callbacks[0] = {
                        label: 'yes',
                        callback:
                            _.bind(function () {
                                this.confirmUpload(fileInfo);
                            }, this)

                    };

                    callbacks[1] = {
                        label: 'no',
                        callback: ''
                    };
                    CustomBootboxMessage.customDialog(params, callbacks);
                }
            }
        },

        confirmUpload: function (fileInfo) {
            this.$el.find(this.ui.blockLoading).show();
            this.options.model.importFilePlateLayout(fileInfo, this.fileFormatSecId)
                .done(_.bind(function (result) {
                    this.options.model = result;
                    if (this.mode === 'import' || this.mode === 'export') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this))
                .fail(_.bind(function (response) {
                    alert(response.statusText + ' -- ' + response.status);
                    this.box.modal('hide');
                }, this));
        },

        onSelectFileToImport: function () {
            App.request('run:pcr:plateLayout-to-import')
                .done(_.bind(function (files) {
                    this.runRemoteFolderView = new RunRemoteFolderView({
                        files: files,
                        runModel: this.options.model,
                        type: 'plateLayoutPcr'
                    });

                    this.runRemoteFolderView.listenTo(this.runRemoteFolderView, 'run:import-xml', _.bind(this.fileUpload, this));
                    this.runRemoteFolderView.show({
                        title: _.i18n('run.import.remote.folder')
                    });
                }, this));
        },

        onExportPlateLayoutPopup: function () {
            if (this.validate(this.fieldsToValidate)) {
                if (this.currentExportDestination && this.currentExportDestination.code === 'CENTRALLIB') {
                    this.onRemoteFileDownload();
                } else if (this.currentExportDestination && this.currentExportDestination.code === 'COMPUTER') {
                    this.onFileDownload();
                }
            }
        },

        onExportPlateLayout: function () {
            App.request('setting:get-all-setting', {'pageSize': 100})
                .done(_.bind(function (settings) {
                    this.chooseDownloadView = new ChooseDownloadView({
                        settings: settings,
                        exportSetting: settings['EXPORT-PLATELAYOUT'].split(';')[0]
                    });
                    this.chooseDownloadView.show({
                        title: _.i18n('run.exportFileFormat')
                    });
                    this.chooseDownloadView.listenTo(this.chooseDownloadView, 'run:local-export-xml', _.bind(this.onFileDownload, this));
                    this.chooseDownloadView.listenTo(this.chooseDownloadView, 'run:remote-export-xml', _.bind(this.onRemoteFileDownload, this));
                }, this));
        },

        onFileDownload: function () {
            $.when(window.open(Settings.url('compuzz', 'v2/runs/pcr/' + this.options.model.get('secId') + '/plateLayout/export', {
                fileFormatSecId: this.fileFormatSecId,
                downloadFile: true
            }), '_blank'))
                .done(_.bind(function (model) {
                    this.options.model = model;
                    if (this.mode === 'import' || this.mode === 'export') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this));
        },

        onRemoteFileDownload: function () {
            this.remoteFileDownload(this.options.model.get('secId'))
                .done(function () {
                    Bootbox.alert({
                        title: _.i18n('run.exportFileFormat.exportResult'),
                        message: _.i18n('run.exportFileFormat.exportResult.success')
                    });
                    if (this.mode === 'import' || this.mode === 'export') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                });
        },

        remoteFileDownload: function (secId) {
            var defer = $.Deferred(),
                url;
            url = Settings.url('compuzz', 'v2/runs/pcr/' + secId + '/plateLayout/export', {
                fileFormatSecId: this.fileFormatSecId,
                downloadFile: false
            });
            $.ajax({
                type: 'GET',
                url: url,
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        showWell: function (start) {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                var indexTabIndex = 1;

                var wells;

                if (this.options.model.get('wells')) {
                    wells = this.options.model.get('wells');
                } else if (this.options.model.get('pcrWellTemplates')) {
                    wells = this.options.model.get('pcrWellTemplates');
                }

                wells.forEach(
                    _.bind(function (well) {

                        var control = well.get('smpType') === 'NC' ||
                            well.get('smpType') === 'PC' ||
                            well.get('smpType') === 'RC' ||
                            well.get('smpType') === 'OC';

                        var wellView = new WellEditSmpidView({
                            model: well,
                            modelRun: this.options.model,
                            tabIndex: indexTabIndex,
                            editMode: start,
                            control: control,
                            parent: this,
                            settings: settings,
                            mode: this.mode
                        });

                        if (!control) {
                            indexTabIndex = indexTabIndex + 1;
                        }
                        this.addRegion('tbl' + well.get('pos'), '.tbl-' + well.get('pos'));
                        this.getRegion('tbl' + well.get('pos')).show(wellView);
                    }, this));
                var inputs = $('.js-input-smpId[tabindex=1]');
                if (inputs.length > 0) {
                    inputs[0].focus();
                }
            }, this));
        },

        onEscape: function (event) {
            var display = this.$el.find(this.ui.start).parent().css('display');
            if (display === 'none') {
                Bootbox.alert(_.i18n('plateLayout.editing'));
                event.stopImmediatePropagation();
                return false;
            }
            return true;
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        hide: function () {
            if (this.options.mode === 'edit') {
                this.trigger('on:runTemplate:edit:Hide');
                this.refreshWellTableView();
                this.destroy();
            } else {
                this.refreshWellTableView();
                this.trigger('on:hide');
                this.destroy();
            }
        },

        refreshWellTableView: function () {
            var currentRoute = App.getCurrentRoute();
            if (currentRoute.includes('details')) {
                App.trigger('well:table:pcr:refresh:jqGrid');
            }
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
        }
    });
});
