define('dynamicCreateEditReference',[
    'module',
    'dialogFormView',
    'backbone',
    'template!dynamicCreateEditReferenceTpl',
    'autocompleteView',
    'underscore'
], function (
    module,
    DialogFormView,
    Backone,
    tpl,
    AutocompleteView,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        regions: {
            autocompleteRegion: '.autocomplete-region'
        },

        serializeData: function () {
            return this.options;
        },

        onRender: function () {
            var config;
            if (!this.options.config) {
                this.options.config = {};
            }
            config = _.extend(this.options.config, this.options.entityReferenceName.getAutocompleteParam({
                modelProperty: this.options.field,
                callBackOnChange: this.onChangeValue
            }, this.options.entityReferenceName));
            if (this.options.display) {
                config.readOnly = this.options.display.readOnly;
            }
            var autocomplete = new AutocompleteView(this._getAutocompleteOptionObject(config));
            this.getRegion('autocompleteRegion').show(autocomplete);
        },

        onChangeValue: function (fieldName, model) {
            this.onChange(fieldName, model ? model.toJSON() : null);
        }
    });
});
