define('entities/caccounts/prepwells',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'wellUtils',
    'module',
    'entities/caccounts/userCodelist',
    'dynamic',
    'entities/caccounts/prepruns',
    'entities/caccounts/samples',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    WellUtils,
    module,
    UserCodeLists,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }
    app.ns.PrepWell = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/wells/prep/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'runName': '',
            'runSecId': '',
            'pos': '',
            'smpType': 'E',
            'sampleComment': '',
            'tags': undefined,
            'wellsPooled': undefined,
            'wellsPool': undefined,
            'repeat': false,
            'refKitLots': undefined,
            'pcrWells': undefined
        },
        // initialize: function () {
        // 	this.set('tags', []);
        // 	this.set('wellsPooled', []);
        // 	this.set('wellsPool', []);
        // 	this.set('refKitLots', []);
        // 	this.set('pcrWells', []);
        // },
        parse: function (response) {
            return Backbone.RelationalModel.prototype.parse.call(this, response);
        },
        set: function (data, options) {
            return Backbone.RelationalModel.prototype.set.call(this, data, options);
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'pcrWells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'wellsPooled',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'wellsPool',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasOne,
            key: 'smpId',
            relatedModel: 'Sample'
        }],

        getImageUid: function () {
        },

        getAssayStatusFilterIcons: function () {
            return WellUtils.getPrepWellAssaysFilterIcons();
        },

        getWellAssaysWaitingList: function () {
            var assays = [];
            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'WAITING') {
                    assays.push(item.assay.code);
                }
            }, this));
            return assays;
        },
        getWellAssaysRunningList: function () {
            var assays = [];
            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'RUNNING') {
                    assays.push(item.assay.code);
                }
            }, this));
            return assays;
        },
        getWellAssaysDoneList: function () {
            var assays = [];
            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'DONE') {
                    assays.push(item.assay.code);
                }
            }, this));
            return assays;
        },

        getAssayStatusCount: function () {
            var assaysWaiting = 0;
            var assaysRunning = 0;
            var assaysDone = 0;

            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'WAITING') {
                    assaysWaiting++;
                } else if (item.status === 'RUNNING') {
                    assaysRunning++;
                } else if (item.status === 'DONE') {
                    assaysDone++;
                }
            }, this));

            return {
                waiting: assaysWaiting,
                running: assaysRunning,
                done: assaysDone
            };
        },

        postUrl: function (runSecId, routingGroup) {
            var params = {
                'routingGroupSecId': routingGroup ? routingGroup.get('secId') : null
            };

            return Settings.url('compuzz', 'v2/runs/prep/' + runSecId + '/wells', params);
        },

        saveDetail: function () {
            var url = Settings.url('compuzz', 'v2/wells/prep/' + this.get('secId') + '/detail'),
                defer = $.Deferred();

            var tags = this.get('tags');
            if (tags && (!Array.isArray(tags)) && tags !== '' && tags !== undefined) {
                tags = this.get('tags').split(/[,;\s]/);
            }

            var body = {
                tags: tags
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveHeader: function (request) {
            var url = Settings.url('compuzz', 'v2/wells/prep/' + this.get('secId') + '/header'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(request),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('refPrepRun').fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveSmpid: function (routingGroupSecId) {
            var url = Settings.url('compuzz', 'v2/wells/prep/' + this.get('secId') + '/sampleId'),
                defer = $.Deferred();

            var body = JSON.stringify({sampleId: this.get('smpId').get('name'), routingGroupSecId: routingGroupSecId});
            $.ajax({
                url: url,
                type: 'PUT',
                data: body,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        save: function (routingGroup) {
            this.url = this.postUrl(this.get('runSecId'), routingGroup);
            return Backbone.Model.prototype.save.call(this);
        },
        getEntity: function () {
            return module.exports;
        },
        canChangeSmpId: function () {
            return this.getWellAssaysRunningList().length === 0 && this.getWellAssaysDoneList().length === 0;
        },
        findDissolveds: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/wells/prep/' + this.get('secId') + '/dissolved');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.ns.PrepWellCollection = Backbone.Collection.extend({
        url: function (runSecId) {
            if (runSecId) {
                return Settings.url('compuzz', 'v2/wells/prep/from/' + runSecId);
            } else {
                return Settings.url('compuzz', 'v2/runs/prep');
            }
        },
        model: app.ns.PrepWell,
        /**
         * récupère une liste de model sur base d'une recherche
         * @param from
         * @param step
         * @param to
         * @returns {*}
         */
        filterByPos: function (from, step, to) {
            var fromRow = from.substring(0, 1).charCodeAt(0) - 65;
            var fromColumn = Number(from.substring(1, 3));
            var toRow = to.substring(0, 1).charCodeAt(0) - 65;
            var toColumn = Number(to.substring(1, 3));
            var models = this.filter(function (model) {
                var pos = model.get('pos');
                var posRow = pos.substring(0, 1).charCodeAt(0) - 65;
                var posColumn = Number(pos.substring(1, 3));
                return (fromRow % step === posRow % step && fromColumn % step === posColumn % step) && (posRow <= toRow && posColumn <= toColumn);
            });
            return new app.ns.PrepWellCollection(models);
        },

        comparator: function (item1, item2) {
            var str1 = item1.get('runName');
            var str2 = item2.get('runName');

            return str1.localeCompare(str2);
        }

    });

    module.exports = _.defaults({
        getList: function (fieldName) {
            var defer = $.Deferred();
            var configurations = [];
            configurations.push({fieldName: 'tags', values: UserCodeLists.findItemsByCode('TAG_RESULT'), codeListCode: 'TAG_RESULT'});
            var configuration = _.findWhere(configurations, {fieldName: fieldName});
            if (configuration) {
                configuration.values.done(function (items) {
                    defer.resolve(items);
                });
            } else {
                defer.reject('No found for ' + fieldName);
            }
            return defer.promise();
        },
        getName: function () {
            return 'PrepWell';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.PrepWell.findOrCreate(modelJson);
        },
        findBySampleId: function (sampleId) {
            var defer = $.Deferred();
            var url = Settings.url('compuzz', 'v2/wells/prep/bySampleId/' + sampleId);
            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(new app.ns.PrepWellCollection(resp));
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();

        }
    }, Dynamic);

    var API = {
        getWellResultCollectionUrl: function (runSecId) {
            return Settings.url('compuzz', 'v2/runs/prep/' + runSecId + '/results', {});
        }
    };

    var STATIC = {
        filterWells: function (wells, from, to, step, row, column) {
            console.info('%c check "filterWells" static method in prepwells', 'color: #00f8ff');
            if (from) {
                var rowFrom = from.substr(0, 1);
                var columnFrom = parseInt(from.substr(1), 10);
            }
            if (row) {
                var rowTo = to.substr(0, 1);
                var columnTo = parseInt(to.substr(1), 10);
            }

            wells = wells.filter(_.bind(function (well) {
                var pos = well.get('pos');
                var rowCurrent = pos.substr(0, 1);
                var columnCurrent = parseInt(pos.substr(1), 10);
                if (from) {
                    if ((rowCurrent < rowFrom) ||
                        (columnCurrent < columnFrom) ||
                        ((columnCurrent - columnFrom) % step !== 0) ||
                        ((rowCurrent.charCodeAt(0) - rowFrom.charCodeAt(0)) % step !== 0)) {
                        return false;
                    }
                }
                if (to) {
                    if ((columnCurrent > columnTo) ||
                        (rowCurrent > rowTo)) {
                        return false;
                    }
                }
                if ((row && rowCurrent !== row) || (column && columnCurrent !== column)) {
                    return false;
                }

                return true;
            }, this));
            return _.sortBy(wells, function (model) {
                return model.get('pos');
            });
        }
    };

    App.reqres.setHandler('well:prep:get-collection-result-url', function (runSecId) {
        return API.getWellResultCollectionUrl(runSecId);
    });

    App.reqres.setHandler('well:prep:model', function (wellJSON) {
        if (wellJSON) {
            return app.ns.PrepWell.findOrCreate(wellJSON);
        }
        return new app.ns.PrepWell();
    });

    App.reqres.setHandler('well:prep:models', function (wellsJSON) {
        return new app.ns.PrepWellCollection(wellsJSON);
    });

    App.reqres.setHandler('well:prep:filter', function (wells, from, to, step, row, column, filters, multiRes, wellRes) {
        return STATIC.filterWells(wells, from, to, step, row, parseInt(column, 10), filters, multiRes, wellRes);
    });
});
