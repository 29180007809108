define('runContaminationsTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'runContaminationsTabView',
    'samplesController',
    'entities/caccounts/pcrwells'
], function (
    module,
    App,
    _,
    Backbone,
    ContaminationsTabView,
    SampleController
) {
    'use strict';

    module.exports = {
        showContaminationsTab: function (region, runModel, from, step, to, filters) {
            this.region = region;
            this.model = runModel;
            this.from = from;
            this.step = step;
            this.to = to;
            this.filters = filters;
            var isMultiDisplay = this.model.get('wells').length > 96;

            var defer= [];
            this.model.get('assayVersions').each(function(assayVersion){
                defer.push(assayVersion.fetch());
            });
            $.when.apply($, defer).done(_.bind(function() {
                var wells = App.request('well:pcr:filter', runModel.get('wells'), from, to, parseInt(step, 10));
                this.view = new ContaminationsTabView({
                    model: runModel,
                    wells: wells,
                    isMultiDisplay: isMultiDisplay,
                    from: from,
                    to: to,
                    step: step,
                    filters: filters
                });
                this.view.listenTo(this.view, 'well:filteredDetailRow', _.bind(this.filteredDetailRow, this));
                this.view.listenTo(this.view, 'well:filteredDetailAssay', _.bind(this.filteredDetailAssay, this));
                this.view.listenTo(this.view, 'well:filteredDetailColumn', _.bind(this.filteredDetailColumn, this));
                this.view.listenTo(this.view, 'well:filteredDetailItem', _.bind(this.filteredDetailItem, this));
                this.view.listenTo(this.view, 'well:showSample', _.bind(this.showSample, this));
                region.show(this.view);
            }, this));
        },

        _getUrl: function (tab) {
            var currentRoute = App.getCurrentRoute(),
                routeSplitted = currentRoute.split('/'), newRoute = [];

            newRoute.push(routeSplitted[0]);
            newRoute.push(routeSplitted[1]);
            newRoute.push(routeSplitted[2]);
            newRoute.push(routeSplitted[3]);
            newRoute.push(routeSplitted[4]);
            if (tab) {
                newRoute.push(tab);
            }
            return newRoute.join('/');
        },

        filteredDetailAssay: function (assaySecId) {
            App.navigate(this._getUrl('contaminations') + '/filters/{"assaySecId":"' + assaySecId + '"}');
        },

        filteredDetailRow: function (row) {
            App.navigate(this._getUrl('contaminations') + '/row/' + row, {trigger: true});
        },

        filteredDetailColumn: function (column) {
            if (column) {
                App.navigate(this._getUrl('contaminations') + '/column/' + column, {trigger: true});
            } else {
                App.navigate(this._getUrl(), {trigger: true});
            }
        },

        filteredDetailItem: function (well) {
            if (well.wellsPool.length > 0) {
            } else {
                App.navigate(this._getUrl('contaminations') + '/row/' + well.pos[0] + '/column/' +
                    well.pos.substring(1), {trigger: true});
            }
        },

        showSample: function (sampleSecId) {
            SampleController.showDetailsBySampleSecId(sampleSecId);
        }
    };
});
