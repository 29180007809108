/**
 * Created by GSP on 4/08/2015.
 */
define('caccountsMainRouter',[
    'require',
    'underscore',
    'jquery',
    'exports',
    'module',
    'marionette',
    'app',
    'itemPagesNameMixin',
    'cssUtils'
], function (
    require,
    _,
    $,
    exports,
    module,
    Marionette,
    App,
    ItemPagesNameMixin,
    CssUtils
) {
    'use strict';

    var CaccountsRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'caccount/:secId': 'showCurrentAccount'
        }
    });

    var API = {
        checkContent: function () {
            var defer = $.Deferred();

            require([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        },
        showCurrentAccount: function (secId) {
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                require([
                    'caccountsController'
                ], function (CaccountsController) {
                    CaccountsController.show(App.regions.getRegion('main').currentView.getRegion('content'), secId);
                    App.execute('set:active:nav:item', ItemPagesNameMixin.MY_ACCOUNT);
                });
            });
        }
    };

    App.on('before:start', function () {
        new CaccountsRouter({
            controller: API
        });
    });

    module.exports = CaccountsRouter;
});

