define('pcrSetupPcrRunItem',[
    'module',
    'dialogFormView',
    'template!pcrSetupPcrRunItem',
    'pcrSetupPcrWellCollectionView',
    'backbone',
    'entities/caccounts/pcrSetup/pcrWellLite',
    'underscore',
    'jquery',
    'pcrSetupPcrRunSave',
    'entities/caccounts/pcrruns',
    'entities/caccounts/setting/defaultCheckbox',
    'entities/caccounts/setting',
    'bootbox'
], function (
    module,
    DialogFormView,
    Tpl,
    WellCollectionView,
    Backbone,
    PcrWellLite,
    _,
    $,
    PcrSetupPcrRunSaveView,
    PcrRuns,
    DefaultCheckBox,
    SettingEntity,
    BootBox
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        className: 'rounded runCard col-xs-12',
        ui: {
            input: '.js-info-input',
            wells: '.js-wells',
            hr: '.js-hr',
            header: '.header',
            control: '.controls span',
            confirm: '.js-confirm'
        },

        regions: {
            wells: '.js-wells',
            controls: '.controls'
        },

        modelEvents: {
            'change': 'render'
        },

        events: {
            'click .controls span.clickable': 'onClickControl',
            'click .js-confirm': 'onConfirm'
        },

        attributes: function () {
            return {
                'style': 'background-color: white;'
            };
        },

        serializeData: function () {
            var templateData = {};
            templateData.name = this.model.get('name');
            return templateData;
        },

        onRender: function () {
            this.model.generateColor();
            var collection = new Backbone.Collection();
            this.model.get('wells').each(function (well) {
                collection.push(well);
            });
            collection.comparator = 'pos';
            this._generateWellHeader(collection);

            this.wellCollectionView = new WellCollectionView({
                collection: collection,
                model: this.model,
                wellWidth: 100 / (this.model.getColumnNumber() + 1),
                assay: this.options.assay,
                protocol: this.options.protocol
            });
            this.getRegion('wells').show(this.wellCollectionView);
            this.wellCollectionView.listenTo(this.wellCollectionView, 'validateSelection', _.bind(function () {
                this.trigger('validateSelection');
                this.ui.control.removeClass('active');
            }, this));

            this.ui.control.removeClass('clickable');
            if (this.options.assay) {
                this.ui.control.addClass('clickable');
            }
        },
        _generateWellHeader: function (collection) {
            var plateSize = this.model.get('plateSize');
            var column = Number.parseInt(plateSize.substring(1));
            var row = plateSize.substring(0, 1).charCodeAt(0);
            collection.push(PcrWellLite.create({pos: '000', type: 'header', name: ''}));
            for (var i = 1; i <= column; i++) {
                collection.push(PcrWellLite.create({
                    pos: '0' + String(i).padStart(2, '0'),
                    type: 'header',
                    name: String(i).padStart(2, '0')
                }));
            }
            for (var j = 65; j <= row; j++) {
                collection.push(PcrWellLite.create({
                    pos: String.fromCharCode(j) + '00',
                    type: 'header',
                    name: String.fromCharCode(j)
                }));
            }
            collection.sort();
        },

        onClickControl: function (e) {
            this.ui.control.removeClass('active');
            this.model.get('refPcrSetup').get('prepWellSelected').reset();
            var model = PcrWellLite.create({
                sampleType: e.currentTarget.attributes['data-field-sampleType'].value,
                assay: this.model.get('refPcrSetup').get('assays').findWhere({secId: this.options.assay.get('secId')}),
                control: true
            });
            if (this.model.get('refPcrSetup').get('qualityControl') && this.model.get('refPcrSetup').get('qualityControl').get('sampleType') === model.get('sampleType')) {
                this.model.get('refPcrSetup').set('qualityControl', null);
                this.model.get('refPcrSetup').set('mode', null);
            } else {
                this.model.get('refPcrSetup').set('qualityControl', model);
                this.model.get('refPcrSetup').set('mode', 'qualityControl');
                e.currentTarget.classList.add('active');
            }
        },

        onConfirm: function () {
            var missingQC = false;
            if (this.model.get('wells').any(function (well) {
                return well.added;
            })) {
                var ret = this.model.containQualityControl();
                missingQC = _.any(ret, function (r) {
                    return !r.PC || !r.NC;
                });
            }
            if (missingQC) {
                BootBox.alert({
                    title: _.i18n('notification'),
                    message: '<span style="font-size: 20px;">' + _.i18n('pcrSetup.pcrRun.missingQC') + '</span>'
                });
            } else {
                $('.js-global-loader').show();
                this.model.save().done(_.bind(function () {
                    var setting = DefaultCheckBox.getModel();
                    setting.fetch().done(_.bind(function () {
                        SettingEntity.findByCode('EXPORT-PLATELAYOUT').done(_.bind(function (data) {
                            var exportPlateLayoutSetted = data && data.params && data.params.split(';')[0];
                            var pcrRun = PcrRuns.getModel({secId: this.model.get('secId')});
                            pcrRun.fetch().done(_.bind(function () {
                                var view = new PcrSetupPcrRunSaveView({
                                    model: pcrRun,
                                    pcrExportPlateLayout: setting.get('pcrExportPlateLayout'),
                                    printPcrSetup: setting.get('printPcrSetup'),
                                    printPcrSetupMMX: setting.get('printPcrSetupMMX'),
                                    exportPlateLayoutSetted: !!exportPlateLayoutSetted
                                });
                                view.show({
                                        title: _.i18n('run.created.from.pcrSetup'),
                                        className: 'actionPopupCss popupFitContent'
                                    },
                                    _.bind(function () {
                                        if (pcrRun.get('plateIdPC')) {
                                            this.model.get('refPcrSetup').removePcrRun(this.model);
                                        }
                                    }, this));
                            }, this));
                        }, this))
                            .always(function () {
                                $('.js-global-loader').hide();
                            });
                    }, this)).fail(function () {
                        $('.js-global-loader').hide();
                    });
                }, this)).fail(function () {
                    $('.js-global-loader').hide();
                });
            }
        }
    });
});
