/**
 * Created by RKL on 21/08/2015.
 */
define('pcrSetupController',[
	'module',
	'underscore',
	'jquery',
	'pcrSetup',
	'entities/caccounts/pcrSetup/pcrSetup'
], function (
	module,
	_,
	$,
	View,
	PcrSetup
) {
	'use strict';

	module.exports = {
		show: function (region) {
			$('.js-global-loader').show();
			PcrSetup.find().done(_.bind(function (pcrSetup) {
				var view = new View({
					model: pcrSetup
				});
				region.show(view);
			}, this))
				.always(function () {
					$('.js-global-loader').hide();
				});
		}
	};
});
