define('entities/caccounts/setting',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/userCodelist',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    UserCodeLists
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Setting = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/settings/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'params': '',
            'description': '',
            'pathSetting': false,
            comment: null,
            tags: []
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/settings');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('SettingJson.pdf');
        },
        jsonObjectName: 'SettingJson',
        jsonObject: 'Setting',
        getEntity: function () {
            return module.exports;
        },
        importable: false
    });

    app.ns.SettingCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/settings');
        },
        model: app.ns.Setting
    });

    var API = {

        getSettingCollectionUrl: function () {
            new app.ns.SettingCollection();
            return Settings.url('compuzz', 'v2/settings');
        },

        getAllSetting: function (params) {
            var url = Settings.url('compuzz', 'v2/settings', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (items) {
                    var list = [];
                    _.each(items, function (item) {
                        list.push(item.secId);
                    });
                    defer.resolve(list);
                }
            });

            return defer.promise();
        },

        getAllSettingFull: function (params) {
            var url = Settings.url('compuzz', 'v2/settings', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (items) {
                    var list = [];
                    _.each(items, function (item) {
                        list[item.code] = item.params;
                        // list.push({item.code: item.params});
                    });
                    defer.resolve(list);
                }
            });

            return defer.promise();
        },

        getSettingCountUrl: function () {
            return 'v2/settings/rowCount';
        },

        deleteSetting: function (secId) {
            var url = Settings.url('compuzz', 'v2/settings/' + secId),
                defer = $.Deferred();

            $.ajax({
                type: 'DELETE',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },

        exportSetting: function (secId) {
            var url = Settings.url('compuzz', 'v2/settings/' + secId + '/export'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });

            return defer.promise();
        },

        importSetting: function (fileInfo) {
            var url = Settings.url('compuzz', 'v2/settings/import'),
                defer = $.Deferred();

            var formdata = new FormData();
            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (result) {
                    defer.resolve(module.exports.getModel(result));
                }
            });
            return defer.promise();
        },

        getSettingByCode: function (code) {
            var url = Settings.url('compuzz', 'v2/settings/byCode/' + code),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (item) {
                    defer.resolve(item);
                }
            });

            return defer.promise();
        }
    };

    App.reqres.setHandler('setting:get-collection-url', function () {
        return API.getSettingCollectionUrl();
    });

    App.reqres.setHandler('setting:get-collection-to-page', function (params) {
        return API.getAllSetting(params);
    });

    App.reqres.setHandler('setting:get-all-setting', function (params) {
        return API.getAllSettingFull(params);
    });

    App.reqres.setHandler('setting:get-count-url', function () {
        return API.getSettingCountUrl();
    });

    App.reqres.setHandler('setting:model', function (itemJSON) {
        return module.exports.getModel(itemJSON);
    });

    App.reqres.setHandler('setting:delete', function (secId) {
        return API.deleteSetting(secId);
    });

    App.reqres.setHandler('setting:get-setting-by-code', function (code) {
        return API.getSettingByCode(code);
    });

    App.reqres.setHandler('setting:export', function (secId) {
        return API.exportSetting(secId);
    });

    App.reqres.setHandler('setting:import', function (fileInfo) {
        return API.importSetting(fileInfo);
    });

    module.exports = _.defaults({
        findByCode: function (code) {
            return API.getSettingByCode(code);
        },
        getAllSettingFull: function () {
            return API.getAllSettingFull({'pageSize': 100});
        },
        getName: function () {
            return 'setting';
        },
        getUrl: function () {
            return 'v2/settings';
        },
        getDynamicJson: function () {
            return 'SettingJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.archivable = false;
            config.selectArchivable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('setting.params'),
                name: 'params',
                classes: 'setting-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false, size: 50},
                index: 'params',
                width: 150
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'setting-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false, size: 50},
                index: 'description'
            }, {
                label: _.i18n('common.action'),
                name: 'pathSetting',
                classes: 'setting-link',
                formatter: _.bind(this.fileExplorerFormatter, this),
                search: false,
                width: 30
            });
            return columns;
        },

        fileExplorerFormatter: function (cellValue, options) {
            if (!cellValue) {
                return '';
            }
            return '<div><span data-navigate="admin/settings/' + options.rowId + '/files" class="mdi mdi-file-find clickable"></span></div>';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Setting.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['description']};
        },
        getCreateEditFieldValidate: function () {
            return [
                {name: 'code', type: 'required', alternative: 'codeName'}
            ];
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration();
            configuration.done(function (resp) {
                resp.push({fieldName: 'code', values: UserCodeLists.findItemsByCode('SETTINGS'), codeListCode: 'SETTINGS'});
                defer.resolve(resp);
            });
            return defer.promise();
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore();
            ignored.push('pathSetting');
            return ignored;
        },
        getCreateEditFieldCustom: function (model) {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'code',
                    'param': {'type': 'CODELIST', 'code': 'SETTINGS', 'display': {'class': 'col-xs-3'}}
                },
                {
                    'field': 'params',
                    'param': {
                        'type': 'ARRAYLIST', 'arrayCode': function (model) {
                            return model.get('code');
                        }
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'code'
                    }
                },
                {
                    'field': 'defaultParams', 'param': {
                        'type': 'ARRAYLIST', 'arrayCode': model.get('code'),
                        'readOnly': true
                    }
                }
            );
            return custom;
        }
    }, Dynamic);
});
