
define('template!editWellView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="img-responsive headerWell-css">\n                <!--Left Pannel------------------------------------------------------------------------------------>\n                <div class="row p-b-5 p-t-10 editPopupTextBanner" style="background-color: #5fcfe1;">\n                    <div class="col-xs-12 p-l-0 p-r-0" style="display: flex;">\n                        <div class="p-l-0 p-r-0" style="width: 37%; display: flex; justify-content: end;">\n                        </div>\n                        <!--Center Pannel------------------------------------------------------------------------------------>\n                        <div class="p-l-0 p-r-0" style="width: 26%; display: flex;">\n                            <div class="col-xs-1 p-l-0 p-r-0">\n                            </div>\n                            <div class="col-xs-10 p-l-0 p-r-0 noWrapHidden wellHeaderBoxShadow"\n                                 style="border: solid 2px black; border-radius: 5px; background-color: #f5f5dc; padding-top: 2px; padding-bottom: 2px;">\n                                <!--line 1 ---------------------------------------------->\n                                <div class="col-xs-12 p-l-0 p-r-0" style="display: flex;">\n                                    <div class="p-l-2 p-r-0" style="width: 10%;">\n                            <span class="smpType-icon-css" style="position: relative;">\n                            ';
 if (smpType === 'NC') { ;
__p += ' <!-- smpType === \'NC\' -->\n                            <span class="mdi mdi-minus-circle"></span>\n                            ';
 } else if(smpType === 'PC') { ;
__p += ' <!-- smpType === \'PC\' -->\n                            <span class="mdi mdi-plus-circle"></span>\n                            ';
 } else if(smpType === 'RC') { ;
__p += ' <!-- smpType === \'RC\' -->\n                            <span class="mdi mdi-registered-trademark" style="font-size: 25px;"></span>\n                            ';
 } else if(smpType === 'C') { ;
__p += ' <!-- smpType === \'C\' -->\n                            <span class="mdi mdi-alpha-c-circle" style="font-size: 25px;"></span>\n                            ';
 } else if(smpType === 'P') { ;
__p += ' <!-- smpType === \'P\' Pool -->\n                            <span class="mdi mdi-alpha-p-circle js-show-pool" style="font-size: 25px;"></span>\n                            ';
 } else if(smpType === 'OC') { ;
__p += '\n                            <span class="mdi mdi-compass" style="font-size: 25px;"></span>\n                            ';
 } else if(smpType === 'T') { ;
__p += '\n                            <span class="mdi mdi-alpha-t-circle" style="font-size: 25px;"></span>\n                            ';
 } else if(smpType === 'D') { ;
__p += '\n                            <span class="mdi mdi-alpha-d-circle" style="font-size: 25px;"></span>\n                            ';
 } else if(smpType === 'U') { ;
__p += '\n                            ';
 } ;
__p += '\n\n                            ';
 if(pooled) { ;
__p += '\n                            <span class="mdi mdi-adjust" style="font-size: 25px;"></span>\n\t\t\t\t\t\t\t\t';
 } ;
__p += '\n                            </span>\n                                    </div>\n                                    <div class="p-l-0 p-r-0" style="width: 80%;">\n                                        <div class="col-xs-4 p-l-5 p-r-0">\n                                            <label style="font-size: 18px; margin-bottom: 0;">' +
((__t = ( pos )) == null ? '' : __t) +
'</label>\n                                        </div>\n                                        <div class="col-xs-8 p-l-0 p-r-5" style="text-align: right;">\n                                            <label class="cs-assay-font" style="font-size: 18px; margin-bottom: 0;">' +
((__t = (
                                                assayCode )) == null ? '' : __t) +
'</label>\n                                        </div>\n                                    </div>\n                                    <div class="p-l-0 p-r-0 p-t-3" style="width: 10%;">\n                                    </div>\n                                </div>\n                                <!--line 2 ---------------------------------------------->\n                                <div class="col-xs-12 p-l-0 p-r-0" style="display: flex;">\n                                    <div class="p-l-5 p-r-0" style="display: grid; padding-top: 4px; width: 10%;">\n                                    </div>\n                                    <div class="p-l-0 p-r-0" style="width: 80%;">\n                                        <!-- cs-sample-font -->\n                                        <div class="col-xs-12 p-l-5 p-r-0 noWrapHidden">\n                                            <div class="js-well-sample"></div>\n                                        </div>\n                                    </div>\n                                    <div class="p-l-0 p-r-0 p-t-3" style="width: 10%;">\n                                        ';
 if(smpId.code) { ;
__p += '\n                                        <div class="headerRunActionButton" style="\n                                        border-radius: 25px;\n                                        border: solid 1px #333333;\n                                        height: 20px;\n                                        width: 20px;\n                                        top: 5px;\n                                        margin-right: 2px;\n                                        background-color: white;">\n                                    <span class="hidden-print mdi-content-content-copy headerRunActionButtonIcon js-copyParameters"\n                                          data-copy="' +
((__t = ( smpId.code )) == null ? '' : __t) +
'"\n                                          style="\n                                            top: 0;\n                                            position: relative;\n                                            font-size: 14px;\n                                            right: -2px;">\n                                    </span>\n                                        </div>\n                                        ';
 } ;
__p += '\n                                    </div>\n                                </div>\n                                <!--line 3 ---------------------------------------------->\n                                <div class="col-xs-12 p-l-0 p-r-0" style="display: flex;">\n                                    <div class="p-l-5 p-r-0" style="display: grid; padding-top: 4px; width: 10%;">\n                                    </div>\n                                    <div class="p-l-0 p-r-0" style="width: 80%;">\n                                        <div class="col-xs-12 p-l-5 p-r-0 noWrapHidden">\n                                            <label class="" style="text-align: left; font-size: 18px; margin: 0;"\n                                                   title="' +
((__t = ( runName )) == null ? '' : __t) +
'">\n                                                ' +
((__t = ( runName )) == null ? '' : __t) +
'\n                                            </label>\n                                        </div>\n                                    </div>\n                                    <div class="p-l-0 p-r-0 p-t-3" style="width: 10%;">\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="col-xs-1 p-l-0 p-r-0">\n                            </div>\n                        </div>\n                        <!--Right Pannel------------------------------------------------------------------------------------>\n                        <!-- icon - repeatStatus - exported - warning - error - smpType -- col-xs-1 -->\n                        <!-- priorité d\'affichage: 1-expert; 2-error; 3-repeat; 4-exported -->\n                        <div class="p-l-0 p-r-0" style="width: 37%; display: flex;">\n                            <div class="col-xs-3 p-r-0" style="top: 15px;">\n                                ';
 if(valWst === 3.1) { ;
__p += '\n                                <span class="mdi mdi-school expertColorIcon"\n                                     style="font-size: 55px; position: absolute;"></span>\n                                ';
 } else if (errorWarning) { ;
__p += '\n                                <span class="mdi mdi-alert warningColorIcon"\n                                     style="font-size: 55px; position: absolute;"></span>\n                                ';
 } else if (repeatStatus === 'PT') { ;
__p += '\n                                <div class="triangleIcon triangle-repeat"></div>\n                                ';
 } else if (repeatStatus === 'PC') { ;
__p += '\n                                <div class="triangleIcon triangle-repeat"></div>\n                                <span class="spanIcon">C</span>\n                                ';
 } else if (repeatStatus === 'XT') { ;
__p += '\n                                <div class="triangleIcon"></div>\n                                <div class="triangleIcon triangle-extract"></div>\n                                ';
 } else if (repeatStatus === 'XC') { ;
__p += '\n                                <div class="triangleIcon"></div>\n                                <div class="triangleIcon triangle-extract"></div>\n                                <span class="spanIcon">C</span>\n                                ';
 } else if (repeatStatus === 'DP') { ;
__p += '\n                                <div class="triangleIcon triangle-repeat"></div>\n                                <span class="spanIcon">D</span>\n                                ';
 } ;
__p += '\n                            </div>\n\n                            <div class="col-xs-9 p-l-0">\n                                ';
 if(popupMode === 'result'){ ;
__p += '\n                                <div class="col-xs-12 p-l-0" style="display: flex; margin-bottom: 4px;">\n                                    <div class="col-xs-2 p-l-0 p-r-0 noWrapHidden">\n                                        ' +
((__t = ( _.i18n('well.popup.tags') )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div class="col-xs-10 p-l-0 p-r-0 noWrapHidden">\n                                <textarea class="js-tags" data-field-name="tags"\n                                          style="resize: vertical; text-align: left; height: 24px; width: 176px; border: transparent;"\n                                          title="' +
((__t = ( tags )) == null ? '' : __t) +
'">' +
((__t = ( tags )) == null ? '' : __t) +
'</textarea>\n                                    </div>\n                                </div>\n                                ';
 } ;
__p += '\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class="col-xs-12">\n                <div class="row m-t-10">\n                    <div class="col-xs-12 p-l-0 p-r-0">\n                    </div>\n                </div>\n                <div class="js-wellTarget-region"></div>\n            </div>\n\n            <div class="row m-t-10"></div>\n\n            <div class="row cancelConfirmRow">\n                <div class="pull-right p-r-15 col-xs-12 p-l-0 p-r-0 cancelConfirmDiv t-a-r">\n                    <button class="mdi-navigation-close btn popupBtn-cancel\n                    cancel-b js-cancel-popup"></button>\n                    <button class="mdi-navigation-check btn popupBtn-confirm\n                        js-confirm"></button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

