define('entities/admin/dye',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Dye = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'public/dyes/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            channel: undefined,
            name: undefined,
            shortName: undefined,
            excitation: undefined,
            emission: undefined,
            comment: undefined,
            color: undefined
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'public/dyes');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'DyeJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Dye',
        getEntity: function () {
            return module.exports;
        }
    });
    app.ns.DyeCollection = Backbone.Collection.extend({
        model: app.ns.Dye,
        comparator: 'channel'
    });

    module.exports = _.defaults({
        getName: function () {
            return 'dye';
        },
        getUrl: function () {
            return 'public/dyes';
        },
        getDynamicJson: function () {
            return 'DyeJson';
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.shortName'),
                name: 'shortName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'shortName',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('detectionFormat.label'),
                name: 'refDetectionFormat.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refDetectionFormat.code',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('dye.color'),
                name: 'color',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'color',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'name';
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel').hasRole('SUPERUSER');
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'shortName';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'shortName';
            configuration.callbackEdit = undefined;
            configuration.callbackAdd = undefined;
            configuration.urlPath = this.getUrl() + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'shortName'
            }));
            configuration.valueKey = config.valueKey ? config.valueKey : 'shortName';
            return configuration;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Dye.findOrCreate(modelJson);
        },
        getCreateEditFieldValidate: function () {
            return [
                {name: 'channel', type: 'required'},
                {name: 'name', type: 'required'},
                {name: 'shortName', type: 'required'},
                {name: 'refDetectionFormat', type: 'required'}
            ];
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['channel', 'name', 'refDetectionFormat.code', 'comment']};
        },
        getCreateEditLine: function () {
            return [
                {'field': 'channel', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1'}}},
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'shortName', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'color', 'param': {'type': 'COLOR', 'display': {'class': 'col-xs-2 p-r-2 p-l-2'}}},
                {'field': 'excitation', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'emission', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'comment', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}}
            ];
        }
    }, Dynamic);
});
