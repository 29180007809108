/**
 * Created by OLD on 25/11/2015.
 */
define('wellTableView',[
    'module',
    'underscore',
    'app',
    'jquery',
    'colorUtils',
    'settings',
    'jqgridView',
    'template!wellTableTpl',
    'entities/caccounts/pcrwells'
], function (
    module,
    _,
    App,
    $,
    ColorUtils,
    Settings,
    JqGridView,
    viewTpl
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'wellViewTableListFilters',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqgRow: 'tr.jqgrow',
                blockLoading: '.empty-page-loader'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({}, this.gridEvents);
        },

        serializeData: function () {
            var templateData = {};

            templateData.editEnable = !!this.channelToDisplay;
            templateData.sortOn = this.sortOn;
            templateData.multiRes = this.multiRes;
            templateData.wellRes = this.wellRes;
            return templateData;
        },

        pagination: function () {
            return {
                first: 0,
                rowCount: 0,
                pageSize: 384,
                currentPage: 1
            };
        },

        gridOptions: function (data) {

            var that = this,
                defaultsOptions = this.gridInitOptions(data),
                options = {
                    colModel: [
                        {
                            label: '',
                            name: 'secId',
                            key: true,
                            hidden: true
                        }, {
                            label: _.i18n('well.sample.comment'),
                            name: 'wellComment',
                            formatter: _.bind(this.wellCommentFormatter, this),
                            search: true,
                            sortable: true,
                            index: 'wellComment',
                            searchoptions: {
                                clearSearch: false
                            },
                            fixed: true,
                            width: 150
                        }, {
                            label: this.getWarningLabelIcon(),
                            name: 'codeErr',
                            formatter: _.bind(this.warningFormater, this),
                            search: true,
                            sortable: true,
                            index: 'codeErr',
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {sopt: ['eq'], value: this.warningSearch, clearSearch: false},
                            fixed: true,
                            width: 30
                        }, {
                            label: this.getRepeatLabelIcon(),
                            name: 'repeatStatus',
                            formatter: _.bind(this.repeatStatusFormater, this),
                            search: true,
                            sortable: true,
                            index: 'repeatStatus',
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {sopt: ['eq'], value: this.availableRepeatStatus, clearSearch: false},
                            fixed: true,
                            width: 30
                        }, {
                            label: this.getSameOtherAssayLabelIcon(),
                            name: 'existOtherWellSameAssay',
                            formatter: _.bind(this.sameOtherAssayFormater, this),
                            search: true,
                            sortable: true,
                            index: 'existOtherWellSameAssay',
                            fixed: true,
                            width: 40
                        }, {
                            label: this.getSmpTypeLabelIcon(),
                            name: 'smpType',
                            formatter: _.bind(this.smptypeFormater, this),
                            search: true,
                            sortable: true,
                            index: 'smpType',
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {sopt: ['eq'], value: this.smpTypeList, clearSearch: false},
                            fixed: true,
                            width: 30
                        }, {
                            label: _.i18n('well.pos'),
                            name: 'pos',
                            formatter: this.wellPosFormatter,
                            classes: 'css-grid-cell-accentuatuion-03',
                            sortable: true,
                            title: false,
                            index: 'pos',
                            fixed: true,
                            width: 50,
                            search: true,
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            }
                        }, {
                            label: _.i18n('well.smpId'),
                            name: 'smpId',
                            formatter: _.bind(this.wellSmpidFormatter, this),
                            classes: 'css-grid-cell-accentuatuion-02',
                            search: true,
                            sortable: true,
                            title: false,
                            index: 'smpId',
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            },
                            fixed: true,
                            width: 130
                        }, {
                            label: _.i18n('common.empty.placeholder'),
                            name: 'smpId.code',
                            sortable: false,
                            search: false,
                            classes: 'ignore-row-click css-no-leftBorder',
                            formatter: this.copyToClipboardFormatter,
                            fixed: true,
                            width: 30
                        }, {
                            label: _.i18n('well.refAssay'),
                            name: 'refAssay.code',
                            formatter: this.wellAssayFormatter,
                            classes: 'css-run-listView-accentuation',
                            // classes: 'css-grid-cell-accentuatuion-01',
                            search: true,
                            sortable: true,
                            title: false,
                            index: 'refAssay.code',
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            },
                            fixed: true,
                            width: 150
                        }, {
                            label: _.i18n('well.result'),
                            name: 'wellResSearchValue',
                            classes: 'well-list-label-wellRes',
                            formatter: _.bind(this.wellResIconCodeNumFormatter, this),
                            search: true,
                            sortable: true,
                            title: false,
                            searchoptions: {sopt: ['cn'], clearSearch: false},
                            index: 'wellResSearchValue',
                            fixed: true,
                            width: 110
                        }
                    ],
                    sortname: this.sortOn ? this.sortOn : 'row',

                    gridComplete: function () {
                        defaultsOptions.gridComplete.apply(this, arguments);

                        var $item = that.$('.js-wellRes-color-cell');
                        _.each($item, _.bind(function (item) {
                            var $item = $(item);
                            var color = $item.attr('data-color');
                            var rgbObj = ColorUtils.hexToRgba(color);
                            var rgbaCol = 'rgba(' + rgbObj.r + ',' + rgbObj.g + ',' + rgbObj.b + ', 0.2) !important';
                            $item.parent().parent().parent().attr('style', 'background-color: ' + rgbaCol + ';');
                        }, this));
                    },

                    onSelectRow: _.bind(function (rowId, bool, e) {
                        if (!$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click') {
                            var filteredRowList = this.ui.jqGrid.getRowData(null, true);
                            var cSelection = [];
                            _.each(filteredRowList, _.bind(function (wellRow) {
                                cSelection.push(wellRow.pos);
                            }, this));
                            var well = this.model.get('wells').find(function (obj) {
                                return obj.get('secId') === rowId;
                            });
                            require(['pcrWellController'], function (PcrWellController) {
                                PcrWellController.showDetails(well, null, null);
                            });
                        }
                    }, this)
                };

            if (this.model.getTypeWells() === 'MAGPIX') {
                options.colModel.push({
                    label: _.i18n('well.magpix'),
                    dataChannel: 1,
                    name: 'target1ResultsSearchValue',
                    classes: 'well-ch1',
                    formatter: this.magpixFormatter,
                    search: false
                });
            } else {
                options.colModel.push({
                    label: _.i18n('well.ch1'),
                    dataChannel: 1,
                    name: 'target1ResultsSearchValue',
                    classes: 'well-ch1',
                    formatter: _.bind(this.resultIconCodeNumFormatter, this),
                    search: true,
                    sortable: true,
                    index: 'target1ResultsSearchValue',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    title: false,
                    fixed: true,
                    width: 100
                });
                options.colModel.push({
                    label: _.i18n('well.ch2'),
                    dataChannel: 2,
                    name: 'target2ResultsSearchValue',
                    classes: 'well-ch2',
                    formatter: _.bind(this.resultIconCodeNumFormatter, this),
                    search: true,
                    sortable: true,
                    index: 'target2ResultsSearchValue',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    title: false,
                    fixed: true,
                    width: 100
                });
                options.colModel.push({
                    label: _.i18n('well.ch3'),
                    dataChannel: 3,
                    name: 'target3ResultsSearchValue',
                    classes: 'well-ch3',
                    formatter: _.bind(this.resultIconCodeNumFormatter, this),
                    search: true,
                    sortable: true,
                    index: 'target3ResultsSearchValue',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    title: false,
                    fixed: true,
                    width: 100
                });
                options.colModel.push({
                    label: _.i18n('well.ch4'),
                    dataChannel: 4,
                    name: 'target4ResultsSearchValue',
                    classes: 'well-ch4',
                    formatter: _.bind(this.resultIconCodeNumFormatter, this),
                    search: true,
                    sortable: true,
                    index: 'target4ResultsSearchValue',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    title: false,
                    fixed: true,
                    width: 100
                });
                options.colModel.push({
                    label: _.i18n('well.ch5'),
                    dataChannel: 5,
                    name: 'target5ResultsSearchValue',
                    classes: 'well-ch5',
                    formatter: _.bind(this.resultIconCodeNumFormatter, this),
                    search: true,
                    sortable: true,
                    index: 'target5ResultsSearchValue',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    title: false,
                    fixed: true,
                    width: 100
                });
                options.colModel.push({
                    label: _.i18n('well.ch6'),
                    dataChannel: 6,
                    name: 'target6ResultsSearchValue',
                    classes: 'well-ch6',
                    formatter: _.bind(this.resultIconCodeNumFormatter, this),
                    search: true,
                    sortable: true,
                    index: 'target6ResultsSearchValue',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    title: false,
                    fixed: true,
                    width: 100
                });
            }
            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },

        wellPosFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellPosPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="position: relative;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="position: relative;">' + cellValue + '</span>';
        },

        wellSmpidFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = {name: ''};
            }
            if (object.pos) {
                return '<span class="cell-default wellSmpidPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="font-weight: 600;">' + cellValue.name + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue.name + '</span>';
        },

        wellAssayFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellAssayPopover-' + object.pos + '" ' +
                    'style="font-weight: bold;" data-row-id="' +
                    options.rowId + '">' + cellValue + '</span>';
            }
            return '<span class="cell-default" style="font-weight: bold;" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        sameOtherAssayFormater: function (cellValue, call, object) {
            if (object.smpType !== 'PC' && object.smpType !== 'NC' && object.smpType !== 'RC' && object.smpType !== 'OC') {
                var start, end;
                start = '<div class="row m-b-0 m-t-0">';
                start += '<div class="col-xs-12 p-l-15" style="display: flex;">';
                if (object.existOtherWellOtherAssay) {
                    start += '<span class="triangle-other-Assay-wellView" ' +
                        'style="' +
                        'border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 15px solid ' +
                        this.model.otherAssayColor + '; ' +
                        '"></span>';
                }
                if (object.existOtherWellSameAssay) {
                    start += '<span class="triangle-same-Assay-wellView" ' +
                        'style="' +
                        'border-left: 6px solid transparent; border-right: 6px solid transparent; border-bottom: 15px solid ' +
                        this.model.sameAssayColor + '; ' +
                        '"></span>';
                }
                start += '</div>';
                end = '</div>';
                return start + end;
            }
            return '';
        },

        wellResIconCodeNumFormatter: function (cellValue, call, object) {
            var start, end;
            var wellResColor = false;

            if (object && object.wellRes) {
                if (object.pos) {
                    start = '<div class="row m-b-0 ovrerallResultPopover-' + object.pos + ' ">';
                } else {
                    start = '<div class="row m-b-0">';
                }
                start += '<div class="col-xs-12 p-r-0" style="display: flex;">';

                if (object.wellRes.get('result') && object.wellRes.get('result').get('color')) {
                    wellResColor = object.wellRes.get('result').get('color');
                    start += '<div class="wellRes-tableView js-wellRes-color-cell" data-color="' + wellResColor +
                        '" style="width: 5px; height: 20px; background-color: ' + wellResColor + ';"></div>';
                }

                start += '<span class="p-l-5" style="min-width: 5px; min-height: 20px; font-size:12px;">' +
                    (object.wellRes.get('result') && object.wellRes.get('result').get('code') ? object.wellRes.get('result').get('code') : '') + '</span>';

                start += '<span class="p-l-5" ' +
                    'style="min-width: 5px; min-height: 20px; font-size:12px;">' +
                    (object.wellRes.get('cts') ? object.wellRes.get('cts').join(';') : '') + '</span>';
                start += '</div>';
                end = '</div>';
                return start + end;
            } else {
                return '';
            }
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi-content-remove-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi-content-add-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="iconify" data-icon="ant-design:copyright-circle-filled" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="iconify" data-icon="bxs:registered" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="iconify" data-icon="mdi:google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        wellCommentFormatter: function (cellValue, options, object) {
            var comment = object.smpId ? (object.smpId.lisComment ? object.smpId.lisComment : '') +
                ' ' + (object.smpId.comment ? object.smpId.comment : '') : '';
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + comment + '</span>';
        },

        repeatStatusFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12" style="right: 5px;">';
            if (cellValue === 'PT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 25px; top: -7px; color: white; font-size: 11px;">c</span>';
            } else if (cellValue === 'XT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            } else if (cellValue === 'XC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 28px; top: -7px; color: white; font-size: 11px;">c</span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        warningFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12" style="padding-left: 17px;">';
            if (cellValue === 'warning') {
                start += '<span class="iconify" data-icon="fluent:warning-24-filled" ' +
                    'style="font-size: 17px; position: absolute; top: -7px; color: #ff5200;"></span>';
            } else if (cellValue === 'expert') { //object.valWst === 3.1
                start += '<span class="mdi-social-school" ' +
                    'style="font-size: 17px; position: absolute; top: -7px; color: #0014FF;"></span>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        resultIconCodeNumFormatter: function (cellValue, call, object) {
            var start, end;

            var i = call.colModel.dataChannel,
                results = object && object['target' + i + 'Results'] ? object['target' + i + 'Results'] : null;

            if (cellValue && results) {

                start = '<div class="row m-b-0">';
                start += '<div class="col-xs-5 p-r-0" style="display: flex; width: 100%; height: 100%; align-content: center; flex-wrap: wrap;">';

                start += results.iconHtml.replaceAll('width: 100%;', '').replaceAll('height: 100%;', '').replaceAll('align-content: center;', '').replaceAll('flex-wrap: wrap;', '').replaceAll('display: flex;', '');
                start += '<span class="p-l-5" style="min-width: 5px; min-height: 20px; font-size:12px;">' +
                    (results.resCode ? results.resCode : ' ') + '</span>';
                start += '<span class="p-l-5" style="min-width: 5px; min-height: 20px; font-size:12px;">' +
                    (results.cts ? results.cts : ' ') + '</span>';
                start += '</div>';
                end = '</div>';
                return start + end;
            } else {
                start = '<div class="row m-b-0">';
                end = '</div>';
                return start + end;
            }
        },

        onCheckRowCount: function (rowCount) {
            if (rowCount === 0) {
                this.trigger('well:check-rowcount');
            }
        }
    });
});
