
define('template!fileExplorer', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12">\n\t<label class="col-xs-4" for="fileExplorerFilter">' +
((__t = ( _.i18n('fileExplorer.filter') )) == null ? '' : __t) +
'</label>\n\t<input id="fileExplorerFilter" type="text" value="" class="input">\n</div>\n<div class="col-xs-12" id="file-tree"></div>\n<div class="row cancelConfirmRow">\n\t<div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n\t\t<button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n\t\t<button disabled\n\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t</div>\n</div>';

}
return __p
};});

