define('fileExplorer',[
	'module',
	'dialogFormView',
	'underscore',
	'jquery',
	'template!fileExplorer',
	'settings',
	'savingBehavior',
	'jstree'
], function (
	module,
	DialogFormView,
	_,
	$,
	Tpl,
	Settings,
	SavingBehavior
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		ui: {
			search: '.input',
			cancel: '.js-cancel-popup',
			confirm: '.js-confirm'
		},

		events: {
			'keyup @ui.search': 'onSearch',
			'click .js-cancel-popup': 'onCancel',
			'click .js-confirm': 'onConfirm'
		},
		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			}
		},

		onSearch: function () {
			if (this.searchTimeOut) {
				clearTimeout(this.searchTimeOut);
			}
			this.searchTimeOut = setTimeout(_.bind(function () {
				var v = this.ui.search.val();
				$('#file-tree').jstree(true).search(v);
			}, this), 250);
		},

		onRender: function () {
			var plugins = ['wholerow', 'search', 'types'];
			if (this.options.selectable) {
				plugins = ['wholerow', 'checkbox', 'search', 'types'];
			}
			this.$('#file-tree').jstree({
				core: {
					data: _.bind(this.loadTreeData, this)
				},
				plugins: plugins,
				checkbox: {
					'three_state': false,
					'tie_selection': false
				},
				'types': {
					'folder': {
						'icon': 'mdi mdi-folder',
						'check_node': false
					},
					'file': {
						'icon': 'mdi mdi-file',
						'check_node': false
					}
				}
			}).on('activate_node.jstree', _.bind(function (e, data) {
				var node = data.node;

				if (node.type === 'file') {
					this.triggerMethod('enable:cancel:confirm');
					// Désélectionner les autres fichiers sélectionnés
					this.$('#file-tree').jstree('deselect_all');
					// Sélectionner le fichier actuel
					this.$('#file-tree').jstree('select_node', node);
				} else {
					this.ui.confirm.addClass('disabled-grey');
					this.ui.confirm.attr('disabled', 'disabled');
				}
			}, this));
		},
		loadTreeData: function (node, callback) {
			var nodeId = node.id; // ID du nœud de dossier sélectionné

			var param = {
				'settingSecId': this.options.settingSecId,
				'subFolder': nodeId === '#' ? '' : nodeId
			};
			var url = Settings.url('compuzz', 'v2/settings/filesExplorer', param);

			// Effectuez une requête AJAX ou tout autre moyen de récupérer les données du dossier sélectionné
			$.ajax({
				url: url,
				success: _.bind(function (response) {
					return callback(response);
				}, this),
				error: function (error) {
					// Gérer les erreurs de la requête AJAX
					console.error('Erreur lors du chargement des dossiers', error);
				}
			});
		},

		onDestroy: function () {
			// Destruction de jsTree lors de la suppression de la vue
			this.$('#file-tree').jstree('destroy');
		},

		onConfirm: function () {
			var firstNode;
			// Récupérer le premier fichier sélectionné
			var selectedNodes = $('#file-tree').jstree('get_selected');

			// Filtrer les nœuds pour obtenir ceux de type "file"
			var selectedFiles = selectedNodes.filter(function (node) {
				return $('#file-tree').jstree('get_type', node) === 'file';
			});

			// Récupérer le premier nœud de type "file"
			if (selectedFiles.length > 0) {
				firstNode = selectedFiles[0];
			}

			if (firstNode) {
				var url = ''; // ligne pour éviter un problème jshint, à supprimer quand le jshint ignore sera enlevé
				url = Settings.url('compuzz', 'v2/settings/filesExplorer/file', {
					settingSecId: this.options.settingSecId,
					pathFile: firstNode
				});

				/* jshint ignore:start */
				var filename = null;
				fetch(url)
					.then(_.bind(function (response) {
						var contentDisposition = response.headers.get('content-disposition');
						if (contentDisposition && contentDisposition.indexOf('filename') !== -1) {
							var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
							var matches = filenameRegex.exec(contentDisposition);
							if (matches != null && matches[1]) {
								filename = matches[1].replace(/['"]/g, '');
							}
						}
						return response.blob();
					}, this))
					.then(_.bind(function (blob) {
						return blob.arrayBuffer();
					}, this))
					.then(_.bind(function (blob) {
						var fd = new File([blob], filename);
						this.options.callBackConfirm(fd);
						this.box.modal('hide');
					}, this));
				/* jshint ignore:end */
			}
		},
		onCancel: function () {
			this.box.modal('hide');
		}
	});
});
