define('qcChartPopup',[
	'module',
	'marionette',
	'dialogMixin',
	'jquery',
	'underscore',
	'assayReagentMbAnaPcrKitLotByPcrWell',
	'wellUtils'
], function (
	module,
	Marionette,
	DialogMixin,
	$,
	_,
	ItemView,
	WellUtils
) {
	'use strict';

	module.exports = Marionette.CollectionView.extend(_.extend({
		childViewContainer: '.js-show-controlKits-region',
		childView: ItemView,
		className: 'js-show-controlKits-region',
		serializeData: function () {
			return {};
		},

		attributes: function () {
			return {
				style: 'overflow-y: auto; overflow-x: hidden; max-height:' + ($(window).height() - 150) + 'px; padding-right: 10px;'
			};
		},
		childViewOptions: function () {
			return {
				wellModel: this.options.wellModel,
				smpTypeIcon: WellUtils.getPcrWellSmpTypeIcon(this.options.wellModel.get('smpType'))
			};
		}
	}, DialogMixin));
});
