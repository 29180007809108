define('assayConfigurationVersion',[
	'module',
	'template!assayConfigurationVersion',
	'jquery',
	'dialogFormView',
	'assayResultCollectionVersion'
], function (
	module,
	Tpl,
	$,
	DialogFormView,
	AssayResultCollectionVersion
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		ui: {
			input: '.js-info-input-assayConfiguration',
			dropDownInput: '.js-dropDownRegion-input',
			displayCollectionItemView: '.ui-display-collection-item-view',
			colorCompensationRegion: '.js-colorCompensation'
		},

		events: {
			'focus @ui.input': 'onFocus',
			'focus @ui.dropDownInput': 'onDropdownFocus',
			'change @ui.input': 'onInputChange'
		},

		className: 'col-xs-12 p-l-0 p-r-0 ui-display-collection-item-view',

		regions: {
			colorCompensationSelect: '.js-select-colorCompensation-region',
			assayResultRegion: '.js-assayResult-region'
		},

		fieldsToValidate: [
			{name: 'code', type: 'required'},
			{name: 'name', type: 'required'}
		],

		validateFields: function () {
			return this.assayResultCollectionView.validateFields();
		},

		serializeData: function () {
			return this.model.toJSON();
		},

		onRender: function () {
			$.material.init();

			var assayResultCollectionView = new AssayResultCollectionVersion({
				collection: this.model.get('results'),
				cyclerPublicSecId: this.options.cyclerPublicSecId,
				targetValueFiltered: this.options.targetValueFiltered
			});

			this.getRegion('assayResultRegion').show(assayResultCollectionView);
		}
	});
});
