define('entities/caccounts/standardCurve/standardCurves',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/standardCurve/standardCurveSamples',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResults'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.StandardCurve = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/standardCurves/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'descriptionKitLot': '',
            dilution: 10,
            fromCt: null,
            toCt: null,
            samples: [],
            results: []
        },
        idAttribute: 'secId',
        initialize: function () {
            this.listenTo(this, 'change:dilution', _.bind(this.changeDilution, this));
        },
        relations: [{
            type: Backbone.HasMany,
            key: 'samples',
            relatedModel: 'StandardCurveSample',
            collectionType: 'StandardCurveSampleCollection'
        }, {
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'StandardCurveAssayConfigurationResult',
            collectionType: 'StandardCurveAssayConfigurationResultCollection'
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/standardCurves');
        },

        save: function () {
            this.url = this.postUrl();
            this.get('results').each(function (result) {
                result.set('values', new app.ns.StandardCurveAssayConfigurationResultValueCollection([]));
            });
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'StandardCurveJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'StandardCurve',
        getEntity: function () {
            return module.exports;
        },
        importable: false,
        changeDilution: function () {
            this.get('samples').each(_.bind(function (sample, index) {
                sample.set('dilution', Math.pow(parseInt(this.get('dilution'), 10), parseInt(index, 10)));
            }, this));
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'standardcurve';
        },
        getUrl: function () {
            return 'v2/standardCurves';
        },
        getDynamicJson: function () {
            return 'StandardCurveJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.StandardCurve.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditLine: function (model) {
            return [
                {
                    'field': 'dilution', 'param': {
                        'type': 'TEXT', 'display': {
                            'class': 'col-xs-1 t-a-r', readonly: model.get('results').any(function (result) {
                                return result.get('values').length > 0;
                            })
                        }
                    }
                }
            ];
        },
        showDetails: function (param) {
            require(['standardCurveController'], function (controller) {
                controller.showDetails(param.model, param.callBackRefresh);
            });
        }
    }, Dynamic);
});
