
define('template!wellSmpTypeDTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<span class="mdi mdi-alpha-d-circle ' +
((__t = ( pcrSetup ? 'pcrSetupWellIcon' : '')) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'"></span>';

}
return __p
};});

