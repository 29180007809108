define('pcrWellView',[
    'module',
    'app',
    'marionette',
    'backbone',
    'wellUtils',
    'template!wellTpl',
    'template!wellMultiDisplayTpl',
    'wellResultIconView',
    'underscore',
    'jquery',
    'colorUtils',
    'runDissolvedView',
    'sampleCommentView',
    'entities/caccounts/samples',
    'wellDisplaySampleView',
    'pcrWellController',
    'pcrWellResultCurveView',
    'pcrWellResultMagPixView',
    'entities/caccounts/prepwells',
    'entities/caccounts/pcrwells'
], function (
    module,
    App,
    Marionette,
    Backbone,
    WellUtils,
    wellTpl,
    wellMultiDisplayTpl,
    WellResultIconView,
    _,
    $,
    ColorUtils,
    RunDissolvedPopup,
    EditSampleCommentView,
    Samples,
    WellDisplaySampleView,
    PcrWellController,
    PcrWellResultCurveView,
    PcrWellResultMagPixView
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        ui: {
            blockLoading: '.empty-page-loader',
            elementRight: '.elementRight',
            selectableItem: '.wellSelectableItem',
            poolLabel: '.pool-label',
            pooledLabel: '.pooled-label'

        },

        regions: {
            wellDisplaySample: '.js-well-sample',
            result: '.js-display-result'
        },

        events: {
            'click': 'onItemClick',
            'click .js-btn-preprun-kitLot': 'onKitLotClick',
            'click .js-btn-comment': 'onCommentClick',
            'click .smp-id-link': 'onSampleIdClick',
            'click .triangle-same-assay-plateView': 'onSampleIdClick',
            'click .triangle-other-assay-plateView': 'onSampleIdClick',
            'click .pool-label': 'onClickPool',
            'click .mdi-alert-octagram': 'onClickError'
        },

        modelEvents: {
            'change': 'renderSafe',
            'change:tooltip': 'renderToolTip'
        },

        initialize: function () {
            this.listenTo(this.model.get('smpId'), 'change', this.renderSafe);
        },

        onRender: function () {
            if (this.model.get('smpType') === 'E') {
                return;
            }
            this.renderToolTip();

            var shortted = !this.options.editMode && this.model.get('smpId').get('name') && this.model.get('smpType') !== 'P';

            var view = new WellDisplaySampleView({
                model: this.model,
                shortted: shortted,
                plateView: true
            });
            this.getRegion('wellDisplaySample').show(view);

            var type = this.model.getType();
            if (!this.model.get('results').isEmpty()) {
                if (type === 'MAGPIX') {
                    var data = this.model.get('results').chain().filter(function (model) {
                        return model.get('type') === 'MAGPIX' && model.get('valSt') !== 0;
                    }).map(function (model) {
                        return model.getResultIcon();
                    }).value();
                    if (_.isEmpty(data)) {
                        this.getRegion('result').empty();
                    } else {
                        var chart = new PcrWellResultMagPixView({
                            data: data,
                            max: 3
                        });
                        this.getRegion('result').show(chart);
                    }
                } else {
                    var counterMax = (this.options.isMultiDisplay) ? 4 : 6;
                    var data2 = this.model.get('results').chain().filter(function (model) {
                        return model.get('sequenceDisplay');
                    }).first(counterMax).value();
                    this.getRegion('result').show(new PcrWellResultCurveView({collection: new Backbone.Collection(data2, {comparator: 'sequenceDisplay'})}));
                }
            }
        },

        renderToolTip: function () {
            clearTimeout(this.timerRenderToolTip);
            this.timerRenderToolTip = setTimeout(_.bind(function () {
                var params = {
                    context: this
                };
                params.target = this.options.region;
                WellUtils.showPcrWellPopover(params);
            }, this), 100);
        },

        getTemplate: function () {
            if (this.options.isMultiDisplay) {
                return wellMultiDisplayTpl;
            } else {
                return wellTpl;
            }
        },

        onClickPool: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.model.findDissolveds()
                .done(_.bind(function (wells) {
                    this.dissolvedPopup = new RunDissolvedPopup({
                        model: this.model,
                        wells: wells
                    });
                    this.dissolvedPopup.show({
                        title: _.i18n('preprun.dissolvedPopup'),
                        className: 'dissolvedPopupCss centeredPopup'
                    });
                }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        },

        onClickError: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['errorController'], _.bind(function (ErrorController) {
                ErrorController.showE(this.model, this.model.get('entityError'));
            }, this));
        },

        onCommentClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.model.get('smpId').fetch().done(_.bind(function () {

                this.editSampleCommentView = new EditSampleCommentView({
                    wellModel: this.model,
                    model: this.model.get('smpId')
                });
                this.editSampleCommentView.show({
                    title: _.i18n('edit.sampleComment'),
                    className: ''
                });
            }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        },

        onKitLotClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['assayVersionController'], _.bind(function (AssayVersionController) {
                this.model.getPcrKitLots().done(_.bind(function (response) {
                    AssayVersionController.showDetails(this.model.get('assayVersion'), null, {lots: response});
                }, this))
                    .always(_.bind(function () {
                        this.$el.trigger('mouseleave');
                    }, this));
            }, this));
        },

        onSampleIdClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.empty-page-loader').show();

            if (this.model.get('smpType') !== 'PC' &&
                this.model.get('smpType') !== 'NC' &&
                this.model.get('smpType') !== 'RC' &&
                this.model.get('smpType') !== 'OC') {

                require(['samplesController'], _.bind(function (Controller) {
                    this.model.get('smpId').fetch().done(_.bind(function () {
                        Controller.showDetails(this.model.get('smpId'));
                    }, this)).always(_.bind(function () {
                        $('.empty-page-loader').hide();
                        this.$el.trigger('mouseleave');
                    }, this));
                }, this));
            } else {
                if (!this.model.get('smpType') || !this.model.get('refAssay') || !this.model.get('refAssay').get('secId')) {
                    return;
                }
                require(['kitLotsPcrController'], _.bind(function (KitLotsPcrController) {
                    KitLotsPcrController.showQCFromWell(this.model);
                    $('.empty-page-loader').hide();
                    this.$el.trigger('mouseleave');
                }, this));
            }
        },

        onItemClick: function () {
            this.showWellDetailView();
        },

        showWellDetailView: function () {
            var defer = $.Deferred();
            require([
                'runTabsLayoutController'
            ], _.bind(function (RunTabsLayoutController) {
                defer.resolve(RunTabsLayoutController.getFilteredWellsCollection());
            }, this));

            defer.promise();
            defer.done(_.bind(function (cSelection) {
                PcrWellController.showDetails(this.model, cSelection, this.icRefKitsToDiplay);
                $('.js-global-loader').hide();
            }, this));

        },

        serializeData: function () {
            var templateData = {
                well: {
                    smpType: this.model.get('smpType'),
                    repeatStatus: this.model.get('repeatStatus'),
                    pooled: this.model.get('pooled'),
                    val1: parseInt(this.model.get('valWst'), 10) === 3,
                    val2: parseInt(this.model.get('valWst'), 10) === 4,
                    exported: this.model.get('exported'),
                    showQC: this.model.get('showQC'),
                    existOtherWellSameAssay: this.model.get('existOtherWellSameAssay'),
                    existOtherWellOtherAssay: this.model.get('existOtherWellOtherAssay'),
                    assay: this.model.get('refAssay') ? this.model.get('refAssay').get('code') : ''
                }
            };

            this.displayResult = !!(this.model.get('refAssay') && this.model.get('refAssay').get('code'));

            templateData.displayResult = this.displayResult;
            templateData.errorWarning = false;
            templateData.displayKitLot = !!this.model.get('assayVersion');

            templateData.errorWarning = this.model.get('results').some(function (result) {
                return result.get('codeErr');
            });

            templateData.colorOtherWellSameAssay = '#FF0000';
            templateData.colorOtherWellOtherAssay = '#006fff';
            if (this.model.get('refPcrRun').get('settings') && this.model.get('refPcrRun').get('settings')['OTHER-ASSAY-IND']) {
                var otherAssayInd = this.model.get('refPcrRun').get('settings')['OTHER-ASSAY-IND'].split(';');
                if (otherAssayInd[0]) {
                    templateData.colorOtherWellSameAssay = otherAssayInd[0];
                }
                if (otherAssayInd[1]) {
                    templateData.colorOtherWellOtherAssay = otherAssayInd[1];
                }
            }

            var colorsAssay = ['20', '60', '#fff302', '#d06767', '#00FF00', '#285085', '#0012ff'];

            if (this.options.settings && this.options.settings['COLORS-ASSAY']) {
                colorsAssay = this.options.settings['COLORS-ASSAY'].split(';');
            }

            var assayVersions = _.chain(this.model.get('refPcrRun').get('wells').getAssays()).uniq().value();
            _.each(assayVersions, _.bind(function (value, key) {
                if (value && this.model.get('refAssay') && value.get('assay') === this.model.get('refAssay')) {
                    this.assayIndex = key + 1;
                }
            }, this));

            templateData.colorAssay = undefined;
            if (this.displayResult) {
                var i = (this.assayIndex % (colorsAssay.length - 2));

                if (i === 0) {
                    i = colorsAssay.length - 2;
                }
                templateData.colorAssay = colorsAssay[i + 1];
            }
            var cellOpacity = colorsAssay[0];
            var assayOpacity = colorsAssay[1];
            templateData.backgroundColor = ColorUtils.defineAlpha(templateData.colorAssay, cellOpacity);
            templateData.assayColor = ColorUtils.defineAlpha(templateData.colorAssay, assayOpacity);

            templateData.runStatus = this.model.get('refPcrRun').get('status');

            var wellRes = this.model.getDisplayGroup();

            if (wellRes && wellRes.length > 0 && _.first(wellRes).get('result')) {
                templateData.wellResColor = _.first(wellRes).get('result').get('color');
            } else {
                templateData.wellResColor = null;
            }

            templateData.pooled = this.model.get('wellsPool').length > 0;
            templateData.pool = this.model.get('wellsPooled').length > 0;
            templateData.expertMode = false;

            templateData.expertMode = this.model.get('results').some(function (result) {
                return result.get('valSt') === 3.1;
            });

            templateData.well.readyToExport = (wellRes && wellRes.length > 0 && _.some(wellRes, function (res) {
                return res.valSt >= 4 && res.valSt < 5;
            }));
            templateData.validatedProgess = this.model.get('smpId').get('verified') === 'IN_PROGRESS';
            templateData.error = this.model.get('entityError') || this.model.get('smpId').get('verified') === 'NOT_VALIDATED' || this.model.get('smpId').get('entityError');
            return templateData;
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        }
    });
});
