
define('template!dynamicCreateEditEnumTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<select class="form-control file-import js-info-input" data-field-name="' +
((__t = ( field )) == null ? '' : __t) +
'"\n\t\tstyle="width: ' +
((__t = ( width )) == null ? '' : __t) +
';">\n\t';
 _.each(values, function(val){ ;
__p += '\n\t<option value="' +
((__t = ( val )) == null ? '' : __t) +
'"\n\t' +
((__t = ( val === value ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( val )) == null ? '' : __t) +
'</option>\n\t';
 }); ;
__p += '\n</select>';

}
return __p
};});

