define('entities/caccounts/cyclerpublics',[
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/admin/cyclers',
    'app'
], function (
    Settings,
    $,
    _,
    module,
    Dynamic,
    Cyclers,
    App
) {
    'use strict';

    module.exports = _.defaults({
        getName: function () {
            return Cyclers.getName();
        },
        getUrl: function () {
            return 'v2/cyclers/public';
        },
        getDynamicJson: function () {
            return Cyclers.getDynamicJson();
        },
        getJqGridColumn: function (viewDynamic) {
            return Cyclers.getJqGridColumn(viewDynamic);
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return Cyclers.getModel(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return Cyclers.getCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return Cyclers.getFieldsToDisplay();
        },

        findDefault: function () {
            var defer = $.Deferred();
            var cyclerDefault;
            App.request('setting:get-setting-by-code', 'DEFAULT-VALUES')
                .done(function (value) {
                    if (value && value.params) {
                        cyclerDefault = value.params.split(';')[0];
                    }
                })
                .always(_.bind(function () {
                    if (cyclerDefault) {
                        var url = Settings.url('compuzz', this.getUrl() + '?' + Settings.generateParamUrl({
                            'sord': 'asc',
                            'sidx': 'code',
                            'code': cyclerDefault
                        }));

                        $.ajax({
                            type: 'GET',
                            url: url,
                            success: function (data) {
                                defer.resolve(data[0]);
                            }
                        });
                    } else {
                        defer.resolve(undefined);
                    }
                }, this));
            return defer.promise();
        },
        find: function (params) {
            var url = Settings.url('compuzz', this.getUrl(), params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        }
    }, Cyclers);
});
