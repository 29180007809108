define('entities/caccounts/prepruntemplates',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'dynamic',
    'module',
    'backboneRelational',
    'entities/caccounts/prepwelltemplates'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    Dynamic,
    module
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.PrepRunTemplate = Backbone.RelationalModel.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/runtemplates/prep/' + this.get('secId'));
        },
        defaults: {
            'code': '',
            'name': '',
            'plateSize': '',
            'comment': '',
            'description': '',
            'group': '',
            'periodFormat': '',
            'pattern': '',
            'settings': [],
            'printRunSheet': true,
            'type': '',
            'seq': '',
            pool: false,
            'prepWellTemplates': undefined,
            tags: []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'prepWellTemplates',
            relatedModel: 'PrepWellTemplate',
            collectionType: 'PrepWellTemplateCollection',
            reverseRelation: {
                key: 'refPrepRunTemplate'
            }
        }],

        getType: function () {
            return 'PrepRunTemplate';
        },

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/runtemplates/prep');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('PrepRunTemplateJson.pdf');
        },
        getEntity: function () {
            return module.exports;
        },

        addAssayWells: function (assaySecId, startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runtemplates/prep/' + this.get('secId') + '/wells/assay/add', {
                assaySecId: assaySecId,
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        addTestWells: function (startIndex, stopIndex) {
            var url = Settings.url('compuzz', 'v2/runtemplates/prep/' + this.get('secId') + '/wells/test/add', {
                from: startIndex,
                to: stopIndex
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearAssay: function (assaySecId, from, to) {
            var params = {
                assaySecId: assaySecId,
                from: from,
                to: to
            };

            var url = Settings.url('compuzz', 'v2/runtemplates/prep/' + this.get('secId') + '/wells/assay/clear', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        replaceAssay: function (assayClearSecId, assaySecId, from, to) {
            var params = {
                assayClearSecId: assayClearSecId,
                assaySecId: assaySecId,
                from: from,
                to: to
            };

            var url = Settings.url('compuzz', 'v2/runtemplates/prep/' + this.get('secId') + '/wells/assay/replace', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        removeTest: function (from, to) {
            var params = {
                from: from,
                to: to
            };

            var url = Settings.url('compuzz', 'v2/runtemplates/prep/' + this.get('secId') + '/wells/test/remove', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        generateSampleId: function (sampleIds) {
            var url = Settings.url('compuzz', 'v2/runtemplates/prep/' + this.get('secId') + '/wells/generateSampleId'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(sampleIds),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearSampleId: function (from, to) {
            var params = {
                from: from,
                to: to
            };

            var url = Settings.url('compuzz', 'v2/runtemplates/prep/' + this.get('secId') + '/wells/clearSampleId', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });

    app.ns.PrepRunTemplateCollection = Backbone.Collection.extend({
        model: app.ns.PrepRunTemplate
    });

    module.exports = _.defaults({
        getName: function () {
            return 'prepruntemplates';
        },
        getUrl: function () {
            return 'v2/runtemplates/prep';
        },
        getDynamicJson: function () {
            return 'PrepRunTemplateJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('sortSeq'),
                name: 'seq',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'seq',
                width: 20
            }, {
                label: _.i18n('runtemplate.plateSize'),
                name: 'plateSize',
                formatter: viewDynamic.defaultFormatter,
                index: 'plateSize',
                width: 20
            }, {
                label: _.i18n('runList.department'),
                name: 'department',
                formatter: viewDynamic.defaultFormatter,
                index: 'department',
                width: 25
            }, {
                label: '0',
                name: 'departmentHyphen',
                search: false,
                width: 2
            }, {
                label: _.i18n('runList.group'),
                name: 'group',
                formatter: viewDynamic.defaultFormatter,
                index: 'group',
                width: 20
            }, {
                label: '0',
                name: 'groupHyphen',
                search: false,
                width: 2
            }, {
                label: _.i18n('runList.sequence'),
                name: 'sequenceFormat',
                formatter: viewDynamic.defaultFormatter,
                index: 'sequenceFormat',
                width: 22
            }, {
                label: _.i18n('runTemplate.pattern'),
                name: 'pattern',
                formatter: viewDynamic.rawFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'pattern',
                width: 55
            }, {
                label: _.i18n('runTemplate.onlyForPrepSetupFromPrep'),
                name: 'pool', // onlyForPrepSetupFromPrep
                formatter: viewDynamic.booleanFormatter,
                search: false,
                index: 'pool', // onlyForPrepSetupFromPrep
                width: 25
            }, {
                label: _.i18n('runTemplate.printRunSheet'),
                name: 'printRunSheet',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                index: 'printRunSheet',
                width: 25
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'comment'
            });
            return columns;
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.urlPath = (config.url ? config.url : this.getUrl()) + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'seq'
            }));
            configuration.fieldsToDisplay = config.fieldsToDisplay ? config.fieldsToDisplay : this.getFieldsToDisplay();
            return configuration;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.PrepRunTemplate.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.PrepRunTemplateCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, fields: ['name']};
        }
    }, Dynamic);
});
