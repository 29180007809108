define('entities/caccounts/extractionMethod',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.ExtractionMethod = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/extractionMethods/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            code: '',
            description: '',
            comment: '',
            tags: [],
            reagents: [],
            secId: null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'reagents',
            relatedModel: 'ExtractionMethodReagent',
            collectionType: 'ExtractionMethodReagentCollection'
        }, {
            type: Backbone.HasMany,
            key: 'refExtractors',
            relatedModel: 'Extractor',
            collectionType: 'ExtractorCollection'
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/extractionMethods');
        },

        updateUrl: function (extractionMethodSecId) {
            return Settings.url('compuzz', 'v2/extractionMethods/' + extractionMethodSecId);
        },

        save: function () {
            if (this.get('secId') !== null) {
                this.url = this.updateUrl(this.get('secId'));
            } else {
                this.url = this.postUrl();
            }
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ExtractionMethodJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ExtractionMethod',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.ExtractionMethodCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/extractionMethod/');
        },
        model: app.ns.ExtractionMethod
    });

    module.exports = _.defaults({
        getName: function () {
            return 'extractionMethod';
        },
        getUrl: function () {
            return 'v2/extractionMethods';
        },
        getDynamicJson: function () {
            return 'ExtractionMethodJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'extractionMethod-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'extractionMethod-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'name',
                width: 100
            }, {
                label: _.i18n('extractor.model'),
                name: 'refExtractors',
                classes: 'extractionMethod-link',
                formatter: viewDynamic.codeNameListFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'refExtractor.code'
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.ExtractionMethod.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.ExtractionMethodCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['name']};
        },

        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {'field': 'refExtractors', 'param': {'type': 'EXTRACTIONMETHOD_EXTRACTOR'}}
            );
            return custom;
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'code',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {'getAutocompleteParam': this.getAutocompleteParam},
                        'self': true,
                        'context': this,
                        'display': {'class': 'col-xs-6'}
                    }
                }
            ];
        }
    }, Dynamic);
});
