define('assayVersionController',[
    'module',
    'underscore',
    'jquery',
    'assayVersionCreateEditLayout',
    'entities/caccounts/assayVersion'
], function (
    module,
    _,
    $,
    CreateEditLayoutView,
    AssayVersions
) {
    'use strict';

    module.exports = {
        showDetails: function (model, callBackRefresh, options) {
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var createEditView = new CreateEditLayoutView({
                model: model,
                lots: options.lots,
                cyclerPublicSecId: options.cyclerPublicSecId
            });
            $('.js-global-loader').show();
            model.fetch().done(function () {
                createEditView.show({
                    title: _.i18n(AssayVersions.getName() + '.new'),
                    className: 'assayVersionsEditAddPopup'
                }, _.bind(function () {
                    if (callBackRefresh) {
                        callBackRefresh();
                    }
                }, callBackRefresh ? callBackRefresh.context : undefined));
            })
                .always(function () {
                    $('.js-global-loader').hide();
                });
        }
    };
});
