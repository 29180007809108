define('kitLotsExtractionController',[
	'module',
	'underscore',
	'kitLotsExtractionValidationView',
	'entities/caccounts/kitlotextraction',
	'backbone'
], function (
	module,
	_,
	EditView,
	KitLotExtractions,
	Backbone
) {
	'use strict';

	module.exports = {
		editDetails: function (model, callBackRefresh, options) {
			if (!(model instanceof Backbone.Model) && typeof model === 'object') {
				var param = model;
				model = param.model;
				callBackRefresh = param.callBackRefresh;
				options = param.options;
			}
			if (!model) {
				alert('Can\'t create a new model without entity, need to define empty model or existing model.');
				throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
			}
			if (!options) {
				options = {model: model};
			} else {
				options = _.defaults({model: model}, options);
			}
			var createEditView = new EditView(options);
			createEditView.show({
				title: _.i18n(KitLotExtractions.getName() + '.new'),
				className: 'baseTableEditAddPopup kitLotExtractionsEditAddPopup'
			}, _.bind(function () {
				if (callBackRefresh) {
					callBackRefresh();
				}
			}, callBackRefresh ? callBackRefresh.context : undefined));
		}
	};
});
