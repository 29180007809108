/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemBoolean',[
    'module',
    'underscore',
    'dialogFormView'
], function (
    module,
    _,
    DialogFormView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template(''),
        tagName: 'input',
        className: 'form-control js-info-input-child inputBackground',
        attributes: function () {
            return {
                'data-field-name': this.options.field.field,
                'type': 'checkbox',
                'checked': !!this.model.get(this.options.field.field),
                'disabled': this.options.field.param && this.options.field.param.readOnly
            };
        }
    });
});
