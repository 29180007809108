
define('template!createEditAssignAssayLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content info-page">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container t-a-c">\n            ';
 if(mode === 'assign-assay') { ;
__p += '\n            <label class="popupTitleLabel">' +
((__t = ( _.i18n('run.assignAssay') )) == null ? '' : __t) +
'</label>\n            <div class="row m-b-0">\n                <div class="js-select-assay-region editPlateLayout-drpdn-default"></div>\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(mode === 'remove-assay') { ;
__p += '\n            <label class="popupTitleLabel" style="margin-bottom: 10px;">' +
((__t = ( _.i18n('run.removeAssay') )) == null ? '' : __t) +
'</label>\n            <div class="row m-b-0">\n                <div class="js-select-assayRemove-region editPlateLayout-drpdn-default"></div>\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(mode == 'replace-assay') { ;
__p += '\n            <label class="popupTitleLabel">' +
((__t = ( _.i18n('run.replaceAssay') )) == null ? '' : __t) +
'</label>\n            <div class="row m-b-0">\n                <div class="js-select-assayRemove-region editPlateLayout-drpdn-default"></div>\n            </div>\n            <div class="row m-b-0">\n                <div class="js-select-assay-region editPlateLayout-drpdn-default"></div>\n            </div>\n            ';
 } ;
__p += '\n\n            <div class="row">\n                <label class="popupTitleLabel dsp-cont">' +
((__t = ( _.i18n('run.assignAssay.from') )) == null ? '' : __t) +
'</label>\n                <div class="h-22 dsp-cont">\n                    <input type="text" class="js-input-from-well assayInputFrom"\n                           style="width: 45px; width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                           id="assayFrom"\n                           value="A01"\n                           pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n                           data-placement="top"\n                           onkeyup="this.value = this.value.toUpperCase();">\n                </div>\n                <label class="popupTitleLabel dsp-cont">' +
((__t = ( _.i18n('run.assignAssay.to') )) == null ? '' : __t) +
'</label>\n                <div class="dsp-cont">\n                    <input type="text" class="js-input-to-well assayInputTo"\n                           style="width: 45px; width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                           id="assayTo"\n                           value="' +
((__t = ( plateSize )) == null ? '' : __t) +
'"\n                           pattern="' +
((__t = ( pattern )) == null ? '' : __t) +
'"\n                           data-placement="top"\n                           onkeyup="this.value = this.value.toUpperCase();">\n                </div>\n            </div>\n\n            <div class="row enterEditPlateLayout-buttons" style="display: flex; justify-content: center;">\n                <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n                ';
 if(mode === 'assign-assay') { ;
__p += '\n                <button disabled\n                        class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-apply-assay js-apply-assay-btn"></button>\n                ';
 } ;
__p += '\n\n                ';
 if(mode === 'remove-assay') { ;
__p += '\n                <button disabled\n                        class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-clear-assay js-clear-assay-btn"></button>\n                ';
 } ;
__p += '\n\n                ';
 if(mode === 'replace-assay') { ;
__p += '\n                <button disabled\n                        class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-replace-assay js-replace-assay-btn"></button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    </div>\n    <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

