
define('template!runTabsLayoutHeaderTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n    <div class="tab-layout-header js-tab-layout-header hidden-print">\n        <div class="tab-layout-info">\n            <!-- Row : actions - run details - filters ------------------------------------------------------------------------------------------------------->\n            <div class="row">\n                <div class="col-xs-12">\n                    <!-- Action buttons -------------------------------------------->\n                    <div class="col-w-all col-w-40 f-l p-l-0 p-r-0 "\n                         style="display: flex; justify-content: flex-end;"><!--noWrapHidden-->\n                        <!-- Edit plate layout -->\n                        <div class="css-grayed-out-header">\n                            <div style="padding: 2px;">\n\n                                <div class="run-action-nav-container">\n                                    <button class="run-action-nav-drop-btn js-run-action-nav-drop-btn">\n                                        ' +
((__t = ( _.i18n('common.plateLayout') )) == null ? '' : __t) +
'\n                                    </button>\n\n                                    <div class="run-action-nav-list hidden">\n                                        <div>\n                                            <button class="btn btn-sm import-plateLayout-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.import.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.import') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm import-plateLayout-fromOtherRun-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.import.from.other.run.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.import.from.other.run') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm import-sampleList-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.sampleList.import.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.sampleList.import') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm export-plateLayout-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.export.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.export') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm export-plateLayout-cycler-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.cycler.export.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.cycler.export') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm export-sampleList-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.sampleList.export.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.sampleList.export') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm ctrl-plateLayout-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.ctrl.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.ctrl') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm scan-plateLayout-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.scan.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.scan') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm edit-plateLayout-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('prep.platelayout.edit.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.edit') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-generate-smpids run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.generate.smpId.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.generate.smpId') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-remove-smpids run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.remove.smpId.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.remove.smpId') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-assign-assay run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.assign.assay.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.assign.assay') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-remove-assay run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.remove.assay.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.remove.assay') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-replace-assay run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.replace.assay.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.replace.assay') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-add-test run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.add.test.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.add.test') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-remove-test run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.remove.test.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.remove.test') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-clear-wells run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.platelayout.clear.wells.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.platelayout.clear.wells') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="headerGroupCellContainer">\n                                <div style="display: flex;">\n                                    <div class="headerActionButton clickable import-plateLayout-button-container hidden-print"\n                                         title="' +
((__t = ( _.i18n('pcr.platelayout.import') )) == null ? '' : __t) +
'">\n                                        <span class="mdi mdi-import mdiHeaderActionButtonIcon import-plateLayout-button clickable"></span>\n                                    </div>\n                                    <div class="headerActionButton clickable hidden-print fill-smpids-container"\n                                         title="' +
((__t = ( _.i18n('pcr.platelayout.all') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-table-edit headerActionButtonIcon edit-plateLayout-button clickable"\n                                              style="top: -5px; left: 4px; font-size: 36px;">\n\t\t\t\t\t\t\t\t\t\t</span>\n                                    </div>\n                                    <div class="group-button-container hidden-print"\n                                         style="margin: 2px; width: 47px; height: 47px; display: flex; flex-flow: wrap;">\n                                        <div class="headerActionButton clickable assay-add-button-container"\n                                             style="width: 20px; height: 20px; margin: 1px;"\n                                             title="' +
((__t = ( _.i18n('pcr.platelayout.assign.assay.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-plus-circle mdiHeaderActionButtonIcon js-assign-assay clickable"\n                                                  style="position: relative; line-height: 20px; font-size: 15px; bottom: 2px; left: 2px;"></span>\n                                        </div>\n                                        <div class="headerActionButton clickable assay-remove-button-container"\n                                             style="width: 20px; height: 20px; margin: 1px;"\n                                             title="' +
((__t = ( _.i18n('pcr.platelayout.remove.assay.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-minus-circle mdiHeaderActionButtonIcon js-remove-assay clickable"\n                                                  style="position: relative; line-height: 20px; font-size: 15px; bottom: 2px; left: 2px;"></span>\n                                        </div>\n                                        <div class="headerActionButton clickable assay-replace-button-container"\n                                             style="width: 20px; height: 20px; margin: 1px;"\n                                             title="' +
((__t = ( _.i18n('pcr.platelayout.replace.assay.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-rotate-3d-variant mdiHeaderActionButtonIcon js-replace-assay clickable"\n                                                  style="position: relative; line-height: 20px; font-size: 15px; bottom: 2px; left: 2px;"></span>\n                                        </div>\n                                        <div class="headerActionButton clickable edit-plateLayout-button-container"\n                                             style="width: 20px; height: 20px; margin: 1px;"\n                                             title="' +
((__t = ( _.i18n('prep.platelayout.edit.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-pencil mdiHeaderActionButtonIcon edit-plateLayout-button clickable"\n                                                  style="position: relative; line-height: 20px; font-size: 15px; bottom: 2px; left: 2px;"></span>\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                        <!-- res -->\n                        <div class="css-grayed-out-header">\n                            <div style="padding: 2px;">\n                                <div class="run-action-nav-container">\n                                    <button class="run-action-nav-drop-btn js-run-action-nav-drop-btn">\n                                        ' +
((__t = ( _.i18n('common.result') )) == null ? '' : __t) +
'\n                                    </button>\n                                    <div class="run-action-nav-list hidden">\n                                        <div>\n                                            <button class="btn btn-sm import-xml-button-headerButton run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.result.import.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.result.import') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm refresh-button run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.result.recalc.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.result.recalc') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm download-export-run-button-reexport run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.result.reexport.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.result.reexport') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm download-export-run-button-manual run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.result.exportManual.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.result.exportManual') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-print-exportList run-action-nav-list-btn clickable"\n                                                    data-print-type="REPEAT">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.result.print.repeat.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.result.print.repeat') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-print-exportList run-action-nav-list-btn clickable"\n                                                    data-print-type="POSITIVE">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.result.print.positive.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.result.print.positive') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-print-exportList run-action-nav-list-btn clickable"\n                                                    data-print-type="REPEAT_POSITIVE">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.result.print.repeat.positive.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.result.print.repeat.positive') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-print-exportList run-action-nav-list-btn clickable"\n                                                    data-print-type="DISSOLVE">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.result.print.dissolve.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.result.print.dissolve') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-print-exportList run-action-nav-list-btn clickable"\n                                                    data-print-type="RESULT">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.result.print.result.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.result.print.result') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-val2run run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.val2run.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.val2run') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="headerGroupCellContainer">\n                                <div style="display: flex;">\n                                    <div class="headerActionButton import-xml-button-headerButton-container clickable hidden-print"\n                                         title="' +
((__t = ( _.i18n('pcr.result.import') )) == null ? '' : __t) +
'">\n                                        <span class="mdi mdi-import mdiHeaderActionButtonIcon import-xml-button-headerButton"></span>\n                                    </div>\n                                    <div class="headerActionButton refresh-button-container clickable hidden-print"\n                                         title="' +
((__t = ( _.i18n('pcr.result.recalc') )) == null ? '' : __t) +
'">\n                                        <span class="mdi mdi-recycle headerActionButtonIcon refresh-button"></span>\n                                    </div>\n                                    <div class="headerActionButton download-export-run-button-container clickable hidden-print ' +
((__t = ( runReadyToexport? 'toPulse' : '' )) == null ? '' : __t) +
'"\n                                         title="' +
((__t = ( _.i18n('pcr.result.export') )) == null ? '' : __t) +
'">\n                                        <span class="mdi mdi-export headerActionButtonIcon download-export-run-button clickable"></span>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                        <!-- miscaleanous -->\n                        <div class="css-grayed-out-header">\n                            <div style="padding: 2px;">\n                                <div class="run-action-nav-container">\n                                    <button class="run-action-nav-drop-btn js-run-action-nav-drop-btn">\n                                        ' +
((__t = ( _.i18n('common.miscaelenous') )) == null ? '' : __t) +
'\n                                    </button>\n                                    <div class="run-action-nav-list hidden">\n                                        <div>\n                                            <button class="btn btn-sm run-action-nav-list-btn clickable js-print-runSheet">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.setup.view.sheet.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.setup.view.sheet') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm run-action-nav-list-btn clickable js-print-setup">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.setup.print.sheet.popove') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.setup.print.sheet') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm run-action-nav-list-btn clickable js-print-setupMMX">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.setup.print.sheet.popove') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.setupMMX.print.sheet') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-reload-Curves run-action-nav-list-btn clickable">\n                                                <!--js not yet done-->\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('pcr.curve.re.import.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('pcr.curve.re.import') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-pcrRun-tracs-view run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('run.tracs.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('run.tracs') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                        <div>\n                                            <button class="btn btn-sm js-pcrRun-summary run-action-nav-list-btn clickable">\n                                                <div class="noWrapHidden"\n                                                     title="' +
((__t = ( _.i18n('run.report.summary.popover') )) == null ? '' : __t) +
'">\n                                                    ' +
((__t = ( _.i18n('run.report.summary') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </button>\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="headerGroupCellContainer css-grayed-out-header">\n                                <div style="display: flex;">\n                                    <div class="headerActionButton clickable hidden-print js-pcrRun-tracs-view"\n                                         title="' +
((__t = ( _.i18n('run.tracs.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-gnome headerActionButtonIcon"\n                                              style="font-size: 35px; bottom: 4px; left: 4px;">\n\t\t\t\t\t\t\t\t\t\t</span>\n                                    </div>\n                                </div>\n                            </div>\n                            <!-- more button that open menu dropdown -- temporary hidden ------------------------------>\n                            <div class="headerGroupCellContainer hidden">\n                                <div style="display: flex;">\n                                    <div class="headerActionButton clickable hidden-print js-run-action-nav-drop-cell-btn"\n                                         title="' +
((__t = ( _.i18n('more') )) == null ? '' : __t) +
'">\n                                        <span class="mdi mdi-menu-down-outline mdiHeaderActionButtonIcon"></span>\n                                    </div>\n                                </div>\n                            </div>\n                            <!-- more button that open menu dropdown -- temporary hidden ------------------------------>\n                        </div>\n                        <!-- previous button -->\n                        <div class="js-show-previous-run-main" style="padding: 2px;">\n                            <div class="mainHeaderBoxShadow"\n                                 style="border: solid 1px #333; border-radius: 5px;">\n                                <div class="js-show-previous-run clickable noWrapHidden"\n                                     style="width: 30px; height: 82px;">\n                                    <div class="previousRunTriangleIcon" style="top: 3px;left: 5px;"></div>\n                                    <div class="previousRunTriangleIcon2" style="bottom: 72px; left: 11px;"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n                    <!-- Run details -------------------------------------------->\n                    <div class="col-xs-2 p-l-0 p-r-0 noWrapHidden mainHeaderBoxShadow"\n                         style="top: 2px; height: 83px; border: solid 1px black; border-radius: 5px; background-color: inherit;">\n                        <div class="d-f" style="flex-direction: row; display: flex;">\n                            <div class="col-xs-1 p-r-2 p-l-2"\n                                 style="display:flex; flex-direction: column; justify-content: space-around;">\n                                <div class="buttonCircle">\n                                    <span class="clickable hidden-print  btn-edit-run mdi-content-create"\n                                          title="' +
((__t = ( _.i18n('well.tooltip.edit') )) == null ? '' : __t) +
'"\n                                          style="\n                                            top: 0;\n                                            position: relative;\n                                            font-size: 14px;\n                                            right: -2px;">\n                                    </span>\n                                </div>\n                                <div class="buttonCircle">\n                                    <span class="clickable hidden-print btn-show-kits mdi mdi-flask-outline"\n                                          title="' +
((__t = ( _.i18n('well.tooltip.edit') )) == null ? '' : __t) +
'"\n                                          style="\n                                            top: -2px;\n                                            position: relative;\n                                            font-size: 14px;\n                                            right: -2px;">\n                                    </span>\n                                </div>\n                                <div class="buttonCircle">\n                                    <span class="clickable hidden-print btn-show-attachments mdi mdi-flip-v mdi-rotate-270 mdi-attachment"\n                                          title="' +
((__t = ( _.i18n('well.tooltip.edit') )) == null ? '' : __t) +
'"\n                                          style="top: -1px;\n                                            position: relative;\n                                            font-size: 16px;\n                                            line-height: 19px;">\n                                    </span>\n                                </div>\n                            </div>\n                            <div class="col-xs-9 p-r-2 p-l-2"\n                                 style="display: flex; flex-direction: column; justify-content: space-around;">\n                                <div class="col-xs-12 p-l-5 p-r-5 noWrapHidden pop-mainHeaderRunBox" id="to-clipboard"\n                                     data-name="' +
((__t = ( name )) == null ? '' : __t) +
'">\n                                    ';
 if(selectedRunsList && selectedRunsList.length > 0){ ;
__p += '\n                                    <select class="js-show-selected-run headerRunNameDropdown js-runName-selectOption-container">\n                                        ';
 _.each(selectedRunsList, function(selectedRun){ ;
__p += '\n                                        <option style="font-size:20px;"\n                                                value="' +
((__t = ( selectedRun.secId )) == null ? '' : __t) +
'"\n                                        ' +
((__t = ( selectedRun.secId === secId ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( selectedRun.name
                                        )) == null ? '' : __t) +
'</option>\n                                        ';
 }); ;
__p += '\n                                    </select>\n                                    <div id="to-clipboard-run-name" class="js-runName-div-container noWrapHidden"\n                                         style="\n                                                display: none;">\n                                        ' +
((__t = ( name )) == null ? '' : __t) +
'\n                                    </div>\n                                    ';
 }else{ ;
__p += '\n                                    <div class="js-runName-div-container noWrapHidden"\n                                         style="\n                                                font-size: 20px;\n                                                height: 38px;\n                                                border: solid 1px #333;\n                                                border-radius: 5px;\n                                                margin-top: 2px;\n                                                padding-top: 6px;\n                                                padding-left: 2px;\n                                                margin-bottom: 4px;">\n                                        ' +
((__t = ( name )) == null ? '' : __t) +
'\n                                    </div>\n                                    ';
 } ;
__p += '\n                                </div>\n                                <div class="col-xs-12 p-l-5 p-r-5 noWrapHidden">\n                                    <div class="js-prepruns"></div>\n                                </div>\n                            </div>\n                            <div class="col-xs-2 p-r-2 p-l-2"\n                                 style="display: flex; flex-direction: column; justify-content: space-around;">\n                                <div style="display: flex; justify-content: space-around;">\n                                    <div class="buttonCircle">\n                                    <span class="clickable hidden-print js-copyParameters mdi-content-content-copy"\n                                          data-copy="' +
((__t = ( name )) == null ? '' : __t) +
'"\n                                          style="\n                                            top: 0;\n                                            position: relative;\n                                            font-size: 14px;\n                                            right: -2px;">\n                                    </span>\n                                    </div>\n\n                                    <div class="buttonCircle">\n                                     <span class="clickable hidden-print js-run-refresh mdi-navigation-refresh"\n                                           title="' +
((__t = ( _.i18n('refresh.run') )) == null ? '' : __t) +
'"\n                                           style="\n                                             top: 0;\n                                             position: relative;\n                                             font-size: 18px;\n                                             right: 0;">\n                                    </span>\n                                    </div>\n                                </div>\n                                <div class="" style="height: 17px; margin-top: 8px;">\n                                    <div>\n\t\t\t\t\t\t\t\t\t<span class="jqgrid-statusNb"\n                                          style="bottom:20px; font-size: 12px;">' +
((__t = ( valStatus )) == null ? '' : __t) +
'</span>\n                                        <div class="cell-default js-status-tooltip"\n                                             style="display: flex; position: relative; top: 3px;">\n                                            ';
 for (var i = 1; i <= 5; i++) {

                                            var iconColor = '';
                                            if (status >= i) {
                                            iconColor = 'statusIcon-upTo';
                                            }
                                            if (valStatus.includes(i)) {
                                            iconColor = 'statusIcon-toDo';
                                            }
                                            if (i === 5 && valStatus.includes(i) && status === 5) {
                                            iconColor = 'statusIcon-upTo';
                                            }
                                            ;
__p += '\n                                            <div class="header jqgrid-valStatusIcon ' +
((__t = ( iconColor )) == null ? '' : __t) +
'"></div>\n                                            ';
 } ;
__p += '\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n\n                    <div class="col-xs-5 p-l-0 p-r-0" style="display: flex; justify-content: flex-start;">\n                        <!-- next button -->\n                        <div class="js-show-next-run-main" style="padding: 2px;">\n                            <div class="mainHeaderBoxShadow"\n                                 style="border: solid 1px #333; border-radius: 5px;">\n                                <div class="js-show-next-run clickable noWrapHidden"\n                                     style="width: 30px; height: 82px;">\n                                    <div class="nextRunTriangleIcon" style="left: 7px; top: 3px;"></div>\n                                    <div class="nextRunTriangleIcon2" style="bottom: 72px; left: 2px;"></div>\n                                </div>\n                            </div>\n                        </div>\n                        <!-- Filter buttons -------------------------------------------->\n                        <!-- smpId search filter -->\n                        <div class="headerGroupCellContainer css-grayed-out-header">\n                            <div class="groupCellTitle">&nbsp</div>\n                            <div style="display: flex;">\n                                <div class="headerFilterButton js-select-smpId js-tab-filters hidden-print js-select-filter"\n                                     name="select-smpId"\n                                     title="' +
((__t = ( _.i18n('common.search.on.smpId') )) == null ? '' : __t) +
'">\n                                    <span class="mdi mdi-magnify mdiHeaderIconButton"></span>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                </div>\n                            </div>\n                        </div>\n                        <!-- status filter -->\n                        <div class="headerGroupCellContainer css-grayed-out-header">\n                            <div class="groupCellTitle">' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</div>\n                            <div style="display: flex;">\n                                <div class="headerFilterButton js-select-val1 js-tab-filters hidden-print js-select-filter"\n                                     name="select-val1"\n                                     title="' +
((__t = ( _.i18n('common.select.val1') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-check mdiHeaderIconButton"\n                                          style="left: 4px; color: #00b700;"></span>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                </div>\n                                <div class="headerFilterButton js-select-val2 js-tab-filters hidden-print js-select-filter"\n                                     name="select-val2"\n                                     title="' +
((__t = ( _.i18n('common.select.val2') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-check mdiHeaderIconButton"\n                                          style="left: 1px; z-index: 2;">\n\t\t\t\t\t\t\t\t\t</span>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                    <span class="mdi mdi-check mdiHeaderIconButton"\n                                          style="left: 7px; z-index: 1; color: #00b700;">\n\t\t\t\t\t\t\t\t\t</span>\n                                </div>\n                                <div class="headerFilterButton js-select-val-expert js-tab-filters hidden-print js-select-filter"\n                                     name="select-val-expert"\n                                     title="' +
((__t = ( _.i18n('common.select.expert') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-school mdiHeaderIconButton"\n                                          style="color: #1c3bc1;"></span>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                </div>\n                                <div class="headerFilterButton js-select-warning  js-tab-filters hidden-print js-select-filter"\n                                     name="select-warning"\n                                     title="' +
((__t = ( _.i18n('common.select.warning') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-alert mdiHeaderIconButton"\n                                          style="color: #ff5e00;"></span>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                </div>\n                            </div>\n                        </div>\n                        <!-- repeat filter -->\n                        <div class="headerGroupCellContainer css-grayed-out-header">\n                            <div class="groupCellTitle">' +
((__t = ( _.i18n('repeat') )) == null ? '' : __t) +
'</div>\n                            <div style="display: flex;">\n                                <div class="headerFilterButton js-select-repeat-pcr js-tab-filters hidden-print js-select-filter"\n                                     name="select-repeat-pcr"\n                                     title="' +
((__t = ( _.i18n('common.repeat.pcr') )) == null ? '' : __t) +
'">\n                                    <span class="mdi mdi-play mdi-flip-h mdiHeaderIconButton"></span>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                </div>\n                                <div class="headerFilterButton js-select-repeat-extract js-tab-filters hidden-print js-select-filter"\n                                     name="select-repeat-extract"\n                                     title="' +
((__t = ( _.i18n('common.repeat.extract') )) == null ? '' : __t) +
'">\n                                    <span class="mdi mdi-play mdi-flip-h mdiHeaderIconButton" style="left: 1px;"></span>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                    <span class="mdi mdi-play mdi-flip-h mdiHeaderIconButton"\n                                          style="left: 8px;"></span>\n                                </div>\n                            </div>\n                        </div>\n                        <!-- assay filter -->\n                        <div class="headerGroupCellContainer css-grayed-out-header">\n                            <div class="groupCellTitle">' +
((__t = ( _.i18n('prevRes') )) == null ? '' : __t) +
'</div>\n                            <div style="display: flex;">\n                                <div class="headerFilterButton js-select-sameAssay js-tab-filters hidden-print js-select-filter"\n                                     name="select-sameAssay"\n                                     title="' +
((__t = ( _.i18n('common.sameAssay') )) == null ? '' : __t) +
'">\n                                    <div class="headerSameAssayButton"\n                                         style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
';"></div>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                </div>\n                                <div class="headerFilterButton js-select-otherAssay js-tab-filters hidden-print js-select-filter"\n                                     name="select-otherAssay"\n                                     title="' +
((__t = ( _.i18n('common.otherAssay') )) == null ? '' : __t) +
'">\n                                    <div class="headerOtherAssayButton"\n                                         style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
';"></div>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                </div>\n                            </div>\n                        </div>\n                        <!-- wellRes filter -->\n                        <div class="headerGroupCellContainer css-grayed-out-header ' +
((__t = ( (results.length? '':'hidden') )) == null ? '' : __t) +
'">\n                            <div class="groupCellTitle">' +
((__t = ( _.i18n('ovaRes') )) == null ? '' : __t) +
'</div>\n                            <div style="display: flex;">\n                                ';
 _.each(results , function(result) { ;
__p += '\n                                <div class="headerFilterButton js-select-res-filter js-select-wellres-' +
((__t = (result.code)) == null ? '' : __t) +
' js-tab-filters clickable hidden-print js-select-filter"\n                                     name="select-wellres-' +
((__t = (result.code)) == null ? '' : __t) +
'" code="' +
((__t = (result.code)) == null ? '' : __t) +
'"\n                                     title="' +
((__t = (result.resText)) == null ? '' : __t) +
'">\n                                    <span class="mdi mdi-circle mdiHeaderIconButton"\n                                         style="color: ' +
((__t = ( (result.color? result.color:'') )) == null ? '' : __t) +
';"></span>\n                                    <input type="checkbox" class="js-filter-checkBox">\n                                </div>\n                                ';
 }); ;
__p += '\n                            </div>\n                        </div>\n                        <!-- reset filters -->\n                        <div class="css-grayed-out-header clickable hidden-print mdi mdi-filter-remove js-reset-filters"\n                             style=""\n                             title="' +
((__t = ( _.i18n('reset.filters') )) == null ? '' : __t) +
'">\n                        </div>\n\n                    </div>\n                </div>\n            </div>\n            <!-- Row : actions - run details - filters End ------------------------------------------------------------------------------------------------------->\n            <!-- Row Navigation bar ------------------------------------------------------------------------------------------------------->\n            <div class="row tabsIconsMenu">\n                <div class="col-xs-12 p-l-0 p-r-0 run-tabsLayout-navBar-container" id="header-navBar-tabs">\n                    <!-- run-tabsLayout-navBar-container added -->\n                    <div class="run-tabsLayout-navTab"> <!-- col-xs-2 -> run-tabsLayout-navTab -->\n                        <div class="col-xs-12 p-l-0 p-r-0 js-tab-layout-tab js-table clickable header-nav-tab"\n                             style="border: solid 1px black; border-radius: 7px;"\n                             title="' +
((__t = ( _.i18n('runs.table.tabTitle') )) == null ? '' : __t) +
'"\n                             data-tab-name="table">\n                            <div class="col-xs-3 nav-tab-icon mdi mdi-grid"></div>\n                            <div class="col-xs-9 nav-tab-text noWrapHidden" style="height: 35px;\n                                    padding: 6px 5px 5px 5px; font-size: 18px;">\n                                ' +
((__t = ( _.i18n('runs.table.tabTitle') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </div>\n                    <div class="run-tabsLayout-navTab"> <!-- col-xs-2 -> run-tabsLayout-navTab -->\n                        <div class="col-xs-12 p-l-0 p-r-0 js-tab-layout-tab js-assays-targets header-nav-tab"\n                             style="border: solid 1px black; border-radius: 7px;"\n                             title="' +
((__t = ( _.i18n('runs.assays.valview.tabTitle') )) == null ? '' : __t) +
'"\n                             data-tab-name="assays-targets">\n                            <div class="col-xs-3 nav-tab-icon mdi mdi-chart-areaspline"></div>\n                            <div class="col-xs-9 nav-tab-text noWrapHidden" style="height: 35px;\n                                    padding: 6px 5px 5px 5px; font-size: 18px;">\n                                ' +
((__t = ( _.i18n('runs.assays.valview.tabTitle') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </div>\n                    <div class="run-tabsLayout-navTab"> <!-- col-xs-2 -> run-tabsLayout-navTab -->\n                        <div class="col-xs-12 p-l-0 p-r-0 js-tab-layout-tab js-details clickable header-nav-tab"\n                             style="border: solid 1px black; border-radius: 7px;"\n                             title="' +
((__t = ( _.i18n('runs.details.tabTitle') )) == null ? '' : __t) +
'"\n                             data-tab-name="details">\n                            <div class="col-xs-3 nav-tab-icon mdi mdi-view-list"></div>\n                            <div class="col-xs-9 nav-tab-text noWrapHidden" style="height: 35px;\n                                    padding: 6px 5px 5px 5px; font-size: 18px;">\n                                ' +
((__t = ( _.i18n('runs.details.tabTitle') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </div>\n                    <div class="run-tabsLayout-navTab"> <!-- col-xs-2 -> run-tabsLayout-navTab -->\n                        <div class="col-xs-12 p-l-0 p-r-0 js-tab-layout-tab js-results header-nav-tab"\n                             style="border: solid 1px black; border-radius: 7px;"\n                             title="' +
((__t = ( _.i18n('runs.targetview.tabTitle') )) == null ? '' : __t) +
'"\n                             data-tab-name="results">\n                            <div class="col-xs-3 nav-tab-icon mdi mdi-reorder-horizontal"></div>\n                            <div class="col-xs-9 nav-tab-text noWrapHidden" style="height: 35px;\n                                    padding: 6px 5px 5px 5px; font-size: 18px;">\n                                ' +
((__t = ( _.i18n('runs.targetview.tabTitle') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </div>\n                    <div class="run-tabsLayout-navTab"> <!-- col-xs-2 -> run-tabsLayout-navTab -->\n                        <div class="col-xs-12 p-l-0 p-r-0 js-tab-layout-tab js-contaminations header-nav-tab"\n                             style="border: solid 1px black; border-radius: 7px;"\n                             title="' +
((__t = ( _.i18n('runs.contaminations.tabTitle') )) == null ? '' : __t) +
'"\n                             data-tab-name="contaminations">\n                            <div class="col-xs-3 nav-tab-icon mdi mdi-biohazard"></div>\n                            <div class="col-xs-9 nav-tab-text noWrapHidden" style="height: 35px;\n                                    padding: 6px 5px 5px 5px; font-size: 18px;">\n                                ' +
((__t = ( _.i18n('runs.contaminations.tabTitle') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <!-- Row Navigation bar End ------------------------------------------------------------------------------------------------------->\n            <div class="js-header-region"></div>\n        </div>\n        <div class="navbar navbar-default navbar-md hidden row m-b-0">\n            <div class="navbar-collapse collapse">\n                <ul class="nav navbar-nav p-l-10">\n                    ';
 _.each(tabs, function(tab) { ;
__p += '\n                    <li class="js-tab-title tab-layout-tab js-tab-layout-tab js-' +
((__t = ( tab.name )) == null ? '' : __t) +
' clickable"\n                        data-tab-name="' +
((__t = ( tab.name )) == null ? '' : __t) +
'"><a>' +
((__t = ( tab.title )) == null ? '' : __t) +
'</a></li>\n                    ';
 }) ;
__p += '\n                </ul>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

