define('entities/createPrepRunFromTemplate',[
    'app',
    'backbone',
    'settings'
], function (
    App,
    Backbone,
    Settings
) {
    'use strict';
    var CreatePrepRunFromTemplate = Backbone.Model.extend({
        defaults: {
            'refPrepRunTemplate': '',
            'selectedDate': '',
            'numberOfRun': ''
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('compuzz', 'v2/runs/prep/fromTemplate');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        }
    });

    App.reqres.setHandler('createPrepRunFromTemplate:model', function () {
        return new CreatePrepRunFromTemplate();
    });
});
