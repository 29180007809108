
define('template!qcListListView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<table id="jq-grid-listQC" class="qc" role="grid"></table>\n<div id="jq-grid-pager-listQC" class="jq-grid-pager-list"></div>';

}
return __p
};});

