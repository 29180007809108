/**
 * Created by RKL on 10/09/2015.
 */
define('formView',[
    'module',
    'marionette',
    'underscore',
    'jquery',
    'validate',
    'dateUtils',
    'backbone'
], function (
    module,
    Marionette,
    _,
    $,
    validateUtils,
    dateUtils,
    Backbone
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({

        attributes: function () {
            return {'dataView': this.cid};
        },
        onInputChange: function (e) {
            var $target = $(e.currentTarget),
                value = $target.val(),
                fieldName = $target.data('field-name'),
                isDate = $target.attr('type') === 'datepicker',
                isCheckBox = $target.attr('type') === 'checkbox';

            if (isDate) {
                value = dateUtils.toMillisecondsFormat(value);
            } else if (isCheckBox) {
                value = $target.is(':checked');
            }
            if ($target.hasClass('uppercase-input')) {
                value = value.toUpperCase();
            }
            this.onChange(fieldName, value);
        },

        onRender: function () {
            setTimeout(_.bind(function () {
                this.$el.find('.js-array').each(function () {
                    var marginLeft = $(this).css('margin-left').replace('px', ''), offset = 15;
                    if (marginLeft > 0) {
                        offset = 0;
                    }
                    $(this).after('<i class="mdi-editor-border-all" style="font-size:15px;' +
                        'top:0;' +
                        'position:absolute;' +
                        'left:' + ($(this).width() - offset) + 'px;' +
                        'margin-left:' + $(this).css('margin-left') + '"></i>');
                });
            }, this), 500);

        },

        onChange: function (fieldName, value) {
            var toSave = {};
            toSave[fieldName] = value;
            if (value && this.model.get(fieldName) instanceof Backbone.Model && this.model.get(fieldName).getEntity && this.model.get(fieldName).getEntity().getModel) {
                if (value instanceof Backbone.Model) {
                    toSave[fieldName] = this.model.get(fieldName).getEntity().getModel(value.toJSON());
                } else {
                    toSave[fieldName] = this.model.get(fieldName).getEntity().getModel(value);
                }
            }
            if (this.model.getRelations) {
                var relation = _.first(this.model.getRelations().filter(function (relation) {
                    return relation.key === fieldName;
                }));
                if (relation) {
                    if (value instanceof Backbone.Model) {
                        toSave[fieldName] = relation.relatedModel.findOrCreate(value.toJSON());
                    } else {
                        toSave[fieldName] = relation.relatedModel.findOrCreate(value);
                    }
                }
            }
            this.model.set(toSave, {silent: true});
            this.model.trigger('change:' + fieldName);
        },

        validate: function (fields, dontShowError) {
            var model = this.model,
                errors = [],
                error;

            _.each(fields, _.bind(function (field) {
                var nameField = this.$('input[data-field-name="' + field.name + '"]');
                var formGroup = this.$('.form-group[data-field-name*="' + field.name + '"]');
                formGroup.removeClass('has-error');
                nameField.removeClass('invalid');
                if (field.alternative) {
                    nameField = this.$('input[data-field-name="' + field.alternative + '"]');
                    formGroup = this.$('.form-group[data-field-name*="' + field.alternative + '"]');
                    formGroup.removeClass('has-error');
                    nameField.removeClass('invalid');
                }

                this.$el.find('input[min][data-field-name="' + field.name + '"]').each(function (index, input) {
                    var $input = $(input);
                    error = validateUtils.validateMin(model,
                        parseInt($input.attr('min'), 10),
                        $input.data('field-name'));
                    errors = _.union(errors, error);
                });

                this.$el.find('input[max][data-field-name="' + field.name + '"]').each(function (index, input) {
                    var $input = $(input);
                    error = validateUtils.validateMax(model,
                        parseInt($input.attr('max'), 10),
                        $input.data('field-name'));
                    errors = _.union(errors, error);
                });

                if (field.type === 'required') {
                    error = validateUtils.validateFields(model, field);
                    errors = _.union(errors, error);
                } else if (field.type === 'email') {
                    error = validateUtils.validateEmail(model, field);
                    errors = _.union(errors, error);
                } else if (field.type === 'number') {
                    error = validateUtils.validateNumber(model, field);
                    errors = _.union(errors, error);
                } else if (field.type === 'multipleEmail') {
                    error = validateUtils.validateMultipleEmail(model, field);
                    errors = _.union(errors, error);
                } else if (field.type === 'datePicker') {
                    error = validateUtils.validateDatePicker(model, field);
                    errors = _.union(errors, error);
                }
            }, this));

            if (!_.isEmpty(errors)) {
                this.onError(errors, dontShowError);
                return false;
            } else {
                return true;
            }
        },

        onError: function (errors, dontShowError) {
            var nameField, formGroup;

            _.each(errors, _.bind(function (error) {
                nameField = this.$('input[data-field-name="' + error.name + '"]');
                formGroup = this.$('.form-group[data-field-name*="' + error.name + '"]');
                formGroup.addClass('has-error');
                nameField.addClass('invalid');
                if (error.alternative) {
                    nameField = this.$('input[data-field-name="' + error.alternative + '"]');
                    formGroup = this.$('.form-group[data-field-name*="' + error.alternative + '"]');
                    formGroup.addClass('has-error');
                    nameField.addClass('invalid');
                }
            }, this));
            if (dontShowError) {
                return;
            }
            this.triggerMethod('errorMessage', _.i18n('warning.mandatoryFields'));
        },

        onFocus: function (e) {
            var target = $(e.currentTarget),
                fieldName = target.data('field-name'),
                formGroup = this.$('.form-group[data-field-name="' + fieldName + '"]');

            formGroup.removeClass('has-error');
            target.removeClass('invalid');
            this.triggerMethod('hideMessage');
        },

        onDropdownFocus: function (e) {
            var $target = $(e.currentTarget).find('input.invalid-field-value');
            $target.attr('placeholder', _.i18n('common.empty.placeholder'));
            $target.removeClass('invalid-field-value');
        },
        onBeforeDestroy: function () {
            this.undelegateEvents();
        },
        setPermissions: function (enable) {
            /*jshint -W069 */
            /*Disable Warning Justification on using [] instead of dot notation*/
            if (enable) {
                this.triggerMethod('enable:cancel:confirm');
            } else {
                if (this.ui.confirm) {
                    this.ui.confirm.hide();
                }
                this.$el.find('input,select,textarea').attr('disabled', true);
                this.$el.find('input,select,textarea').addClass('disabled');
                this.$el.find('input,select,textarea').removeClass('clickable');
                this.$el.find('.mdi-content-add,.mdi-action-delete').addClass('invisible');
            }
            /*jshint +W069 */
        }
    });
});
