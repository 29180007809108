
define('template!pcrSetupAssayCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12 p-r-0 p-l-0">\n    <div class="col-xs-9"><label class="col-xs-12">' +
((__t = ( _.i18n('prepRun.assay') )) == null ? '' : __t) +
'</label></div>\n    <div class="col-xs-3">\n        <span class="mdi mdi mdi-human-male" style="color: #ff0000;"></span>\n    </div>\n</div>\n<div class="js-assays col-xs-12"></div>\n';

}
return __p
};});

