
define('template!pcrSetupPrepWellItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well ' +
((__t = ( selected )) == null ? '' : __t) +
' ' +
((__t = ( available )) == null ? '' : __t) +
' ' +
((__t = ( clickable )) == null ? '' : __t) +
'" data-pos="' +
((__t = ( pos )) == null ? '' : __t) +
'"\n     style="' +
((__t = ( color )) == null ? '' : __t) +
'; "\n     title="' +
((__t = ( assay )) == null ? '' : __t) +
'">\n    <span class="mdi ' +
((__t = ( mdi )) == null ? '' : __t) +
'" data-field-sampletype="' +
((__t = ( sampleType )) == null ? '' : __t) +
'"\n          style="line-height: 18px; font-size: 19px;"></span>\n</div>';

}
return __p
};});

