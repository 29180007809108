
define('template!assayVersionLotCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-w-all col-w-100 d-b p-l-5 p-r-5 tableUnderlineTitle">\n\t<div class="col-xs-1 p-r-2 p-l-0">' +
((__t = ( _.i18n('kitLot.reagent.type') )) == null ? '' : __t) +
'</div>\n\t<div class="col-xs-1 p-r-2 p-l-2">' +
((__t = ( _.i18n('kitLot.reagent.code') )) == null ? '' : __t) +
'</div>\n\t<div class="col-xs-3 p-r-2 p-l-2">' +
((__t = ( _.i18n('kitLot.kit.code') )) == null ? '' : __t) +
'</div>\n\t<div class="col-xs-3 p-r-2 p-l-2">' +
((__t = ( _.i18n('kitLot.code') )) == null ? '' : __t) +
'</div>\n\t<div class="col-xs-1 p-r-2 p-l-2">' +
((__t = ( _.i18n('kitLot.volumeByTest') )) == null ? '' : __t) +
'</div>\n\t<div class="col-xs-1 p-r-2 p-l-2">' +
((__t = ( _.i18n('kitLot.volumeDeath') )) == null ? '' : __t) +
'</div>\n\t<div class="col-xs-1 p-r-0 p-l-2"></div>\n</div>\n<div class="collection col-xs-12 p-0"></div>';

}
return __p
};});

