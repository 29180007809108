define('exportResult',[
	'module',
	'backbone',
	'marionette',
	'exportResultByLis'
], function (
	module,
	Backbone,
	Marionette,
	ItemView
) {
	'use strict';

	module.exports = Marionette.CollectionView.extend({

		childView: ItemView,
		className: 'm-t-10 col-xs-12',
		childViewOptions: function (model) {
			return {
				collection: model.get('configurationByTypes')
			};
		}
	});
});
