define('pcrWellResultHL7ItemView',[
    'module',
    'marionette',
    'template!pcrWellResultHL7ItemView',
    'underscore',
    'jquery',
    'editWellView',
    'entities/caccounts/mbanaresgrs'
], function (
    module,
    Marionette,
    WellResultTpl,
    _,
    $,
    EditWellView
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: WellResultTpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'click .btn-validate-result': 'onConfirmResult',
            'click .btn-negative-result': 'onNegativeResult',
            'click .btn-edit-well-target-result': 'onEditWellTargetResult'
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        attributes: {
            style: 'display: flex;'
        },

        className: 'result-hl7',

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        serializeData: function () {
            var targetsResultIcons = this.model.get('refPcrWell').getTargetsResultIcons();
            var templateData = _.findWhere(targetsResultIcons, {'targetCode': this.model.get('mbAna').get('code')});
            templateData = _.extend(templateData, {
                refPcrWellExported: this.model.get('refPcrWell') ? this.model.get('refPcrWell').get('exported') : false,
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : undefined,
                result: this.model.get('result') ? this.model.get('result').get('code') : ''
            });

            if (templateData.valSt === 5) {
                templateData.repeatAllowed = false;
                // Avoid repeat (when well's exported status are inconsistant)
            }

            templateData.allowToNeg = templateData.resCode !== 'N' && templateData.propC && this.model.get('codeErr') ? Object.keys(templateData.propC).some(function (k) {
                return ~k.indexOf('Amp');
            }) : false;
            templateData.propC = this.model.getPropCFormated();

            templateData.userVal1Code = '';
            templateData.userVal1Title = '';
            if (this.model.get('refValUser')) {
                templateData.userVal1Code = this.model.get('refValUser').get('nickName');
                if (this.model.get('refValUser').get('email')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('email');
                }
                if (templateData.userVal1Code === '' && templateData.userVal1Title) {
                    templateData.userVal1Code = templateData.userVal1Title;
                }
                if (this.model.get('refValUser').get('firstName') && this.model.get('refValUser').get('lastName')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('firstName') + ' ' + this.model.get('refValUser').get('lastName');
                }
            }
            templateData.userVal2Code = '';
            templateData.userVal2Title = '';
            if (this.model.get('refValUser2')) {
                templateData.userVal2Code = this.model.get('refValUser2').get('nickName');
                if (this.model.get('refValUser2').get('email')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('email');
                }
                if (templateData.userVal2Code === '' && templateData.userVal2Title) {
                    templateData.userVal2Code = templateData.userVal2Title;
                }
                if (this.model.get('refValUser2').firstName && this.model.get('refValUser2').get('lastName')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('firstName') + ' ' + this.model.get('refValUser2').get('lastName');
                }
            }
            templateData.readOnly = this.model.isReadOnly();
            templateData.needValidation = this.model.get('needValidation');
            templateData.showPropC = false;
            templateData.showWellCompareGraph = false;

            if (this.model.get('curves').any(function (curve) {
                return curve.get('type') === 'AMP';
            })) {
                templateData.showPropC = true;
                templateData.showWellCompareGraph = true;
            }

            if (this.model.get('curves').any(function (curve) {
                return curve.get('type') === 'MUTV' || curve.get('type') === 'MELT';
            })) {
                templateData.showWellCompareGraph = true;
            }

            templateData.errorMessage = '';
            if(templateData.codeErr) {
                templateData.errorMessage = templateData.codeErr + ' ';
            }
            if(templateData.justificatory) {
                templateData.errorMessage = templateData.justificatory;
            }
            return templateData;
        },

        onConfirmResult: function () {
            $('.js-global-loader').show();
            this.model.confirmResult()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onNegativeResult: function () {
            $('.js-global-loader').show();
            this.model.negativeResult()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onEditWellTargetResult: function () {
            this.showEditWellView();
        },

        showEditWellView: function () {
            this.createEditView = new EditWellView({
                model: this.model.get('refPcrWell'),
                modelResult: this.model,
                wellResColor: this.wellResColor,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.info.edit'),
                className: 'popupWellView'
            });
        }
    });
});
