define('dynamicLoadEntities',[
    'entities/caccounts/assay',
    'entities/caccounts/assaygroup',
    'entities/caccounts/biogroups',
    'entities/caccounts/sampleResultDefinitions',
    'entities/caccounts/species',
    'entities/caccounts/cyclers',
    'entities/caccounts/lmbcyclers',
    'entities/caccounts/lisana',
    'entities/caccounts/lisBioGroups',
    'entities/caccounts/lisSpecies',
    'entities/caccounts/colorCompensations',
    'entities/caccounts/mbanas',
    'entities/caccounts/mbanares',
    'entities/caccounts/mbanaresgrs',
    'entities/caccounts/lis',
    'entities/caccounts/extractors',
    'entities/caccounts/lmbextractors',
    'entities/caccounts/samplehandlers',
    'entities/caccounts/lmbsamplehandlers',
    'entities/caccounts/fileFormatPlateLayouts',
    'entities/caccounts/fileFormatSampleLists',
    'entities/caccounts/kitspcr',
    'entities/caccounts/kitsextraction',
    'entities/caccounts/kitlotextraction',
    'entities/caccounts/kitlotpcr',
    'entities/caccounts/kitprots',
    'entities/caccounts/kitprotconfiguration',
    'entities/caccounts/routingaction',
    'entities/caccounts/routinggroup',
    'entities/caccounts/preprocess',
    'entities/caccounts/routingaction_routinggroup',
    'entities/caccounts/routingaction_state',
    'entities/caccounts/extractionMethod',
    'entities/caccounts/extractionMethodReagent',
    'entities/caccounts/suppliers',
    'entities/caccounts/samples',
    'entities/caccounts/orders',
    'entities/caccounts/pcrreagents',
    'entities/caccounts/authorityGroup',
    'entities/caccounts/reports',
    'entities/caccounts/reportProperties',
    'entities/caccounts/userCodelist',
    'entities/caccounts/userCodelistelement',
    'entities/caccounts/attachment',
    'entities/caccounts/colorCompensations',
    'entities/caccounts/colorCompensationMatrices',
    'entities/caccounts/connector/connectorHl7Tracings',
    'entities/caccounts/standardCurve/standardCurves',
    'entities/admin/cyclers',
    'entities/admin/detectionFormat',
    'entities/admin/dye',
    'entities/admin/codelist',
    'entities/admin/codelistelement',
    'entities/admin/array',
    'entities/admin/arrayelement',
    'entities/global/caccounts',
    'entities/global/userpids',
    'entities/global/logs',
    'entities/caccounts/lisRecords'

], function () {

});
