define('entities/caccounts/prepwelltemplates',[
	'app',
	'backbone',
	'settings',
	'underscore',
	'jquery',
	'backboneRelational',
	'entities/caccounts/samples'
], function (
	App,
	Backbone,
	Settings,
	_,
	$
) {
	'use strict';

	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}
	app.ns.PrepWellTemplate = Backbone.RelationalModel.extend({

		url: function () {
			return Settings.url('compuzz', 'v2/runtemplates/prep/wells/' + this.get('secId'));
		},

		defaults: {
			'pos': '',
			'smpType': 'E',
			'wellsPooled': [],
			'wellsPool': [],
			'pcrKits': [],
			'smpId': undefined
		},
		idAttribute: 'secId',
		relations: [{
			type: Backbone.HasOne,
			key: 'smpId',
			relatedModel: 'Sample'
		}],
		getImageUid: function () {
		},

		saveHeader: function (request) {
			var url = Settings.url('compuzz', 'v2/runtemplates/prep/wells/' + this.get('secId') + '/header'),
				defer = $.Deferred();

			$.ajax({
				url: url,
				type: 'PUT',
				data: JSON.stringify(request),
				contentType: 'application/json',
				success: _.bind(function () {
					this.fetch()
						.done(function () {
							defer.resolve();
						});
				}, this),
				error: function (err) {
					defer.reject(err);
				}
			});
			return defer.promise();
		},

		getPDF: function () {
			return Settings.getDocUrl('PrepWellTemplateJson.pdf');
		},
		saveSmpid: function () {
			var url = Settings.url('compuzz', 'v2/runtemplates/prep/wells/' + this.get('secId') + '/sampleId'),
				defer = $.Deferred();

			var body = this.get('smpId').get('name');
			$.ajax({
				url: url,
				type: 'PUT',
				data: body,
				contentType: 'application/json',
				success: _.bind(function () {
					this.fetch().done(function () {
						defer.resolve();
					});
				}, this),
				error: _.bind(function (err) {
					this.fetch().done(function () {
						defer.reject(err);
					});
				}, this)
			});
			return defer.promise();
		}
	});

	app.ns.PrepWellTemplateCollection = Backbone.Collection.extend({
		model: app.ns.PrepWellTemplate
	});
});
