
define('template!superAdminCustomTooltipTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div style="z-index: 9000;">\n    csv_s_n_x_x_x_x_...<br/>\n    or<br/>\n    txt_s_n_x_x_x_x_...<br/><br/>\n    s = char separtor (C = \',\', SC = \';\', T = tab))<br/>\n    n = number of header lines<br/>\n    x = represent one column of file csv or txt<br/>\n    <hr>\n    xls_n_x_x_x_x_...<br/>\n    or<br/>\n    xlsx_n_x_x_x_x_...<br/>\n    n = number of header lines<br/>\n    x = represent one column of file<br/>\n    <hr>\n    Possible value for \'x\' :\n    <ul>\n        <li>E = empty</li>\n        <li>R = line (A,B, C…)</li>\n        <li>C = column (1,2, …) (number format)</li>\n        <li>RC = Notation Ax(x) de Line column</li>\n        <li>STYPE = Sample type</li>\n        <li>ASS = Assay (Prep = list of assays, Pcr = assay)</li>\n        <li>SID = Sample</li>\n        <li>RUN = Runname</li>\n        <li>PIDE = PlateID-Eluat plate</li>\n        <li>PIDP = PlateID-PCRPlate</li>\n        <li>ORDERID = Order id from sample (only for well typed "U", others will empty)</li>\n        <li>BIOGR = BioGroup id from sample (only for well typed "U", others will empty)</li>\n        <li>SPECIE = Specie id from sample (only for well typed "U", others will empty)</li>\n        <li>EXTR = Extraction method code (only for prep well, will be ignored in PCR)</li>\n    </ul>\n</div>';

}
return __p
};});

