define('entities/caccounts/curves',[
	'app',
	'backbone',
	'settings',
	'jquery',
	'underscore',
	'module',
	'dynamic',
	'backboneRelational'
], function (
	App,
	Backbone,
	Settings,
	$,
	_,
	module,
	Dynamic
) {
	'use strict';
	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.Curve = Backbone.RelationalModel.extend({
		fetch: function (pcrWell) {
			this.url = Settings.url('compuzz', 'v2/well/pcr/' + pcrWell.get('secId') + '/curves/' + this.get('secId'));

			return Backbone.Model.prototype.fetch.call(this);
		},
		defaults: {
			'name': undefined,
			'lines': [],
			'type': undefined,
			'channel': undefined
		},
		idAttribute: 'secId',

		getImageUid: function () {
		},

		save: function () {
			throw new Error('Not available');
		},

		jsonObjectName: 'CurveJson',
		getPDF: function () {
			return Settings.getDocUrl(this.jsonObjectName + '.pdf');
		},

		jsonObject: 'Curve',
		getEntity: function () {
			return module.exports;
		}
	});


	app.ns.CurveCollection = Backbone.Collection.extend({
		model: app.ns.Curve,
		comparator: 'channel'
	});

	module.exports = _.defaults({
		getName: function () {
			return 'curve';
		},
		getDynamicJson: function () {
			return 'CurveJson';
		},

		getModel: function (modelJson) {
			if (!modelJson) {
				modelJson = {};
			}
			return app.ns.Curve.findOrCreate(modelJson);
		}
	}, Dynamic);
});
