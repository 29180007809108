define('entities/caccounts/samples',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/sampleClass',
    'sampleCustomJqGridView',
    'entities/caccounts/orders',
    'entities/caccounts/species',
    'entities/caccounts/biogroups',
    'entities/caccounts/lis',
    'entities/caccounts/lisana',
    'entities/caccounts/mbanas',
    'entities/caccounts/prepwells',
    'entities/caccounts/pcrwells',
    'entities/caccounts/assaygroup',
    'entities/caccounts/sampleTracing',
    'entities/caccounts/sample_routingactionroutinggroup',
    'entities/caccounts/sampleResults'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    SampleClasses,
    SampleCustomJqGridView
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Sample = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/samples/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'creatDate': null,
            'modDate': null,
            'lisAnas': [],
            'assayGroups': [],
            'targets': [],
            'refLIS': null,
            'comment': '',
            'lisComment': '',
            'bioGroup': null,
            'specie': null,
            'prepWells': [],
            'pcrWells': [],
            'refSampleClass': null,
            'order': null,
            'lastRoutingGroup': null,
            'routingGroupHistories': [],
            'results': [],
            'lisCode': ''
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'bioGroup',
            relatedModel: 'BioGroup'
        }, {
            type: Backbone.HasOne,
            key: 'specie',
            relatedModel: 'Specie'
        }, {
            type: Backbone.HasOne,
            key: 'refLIS',
            relatedModel: 'Lis'
        }, {
            type: Backbone.HasOne,
            key: 'refSampleClass',
            relatedModel: 'SampleClass'
        }, {
            type: Backbone.HasOne,
            key: 'lastRoutingGroup',
            relatedModel: 'SampleRoutingActionRoutingGroup'
        }, {
            type: Backbone.HasMany,
            key: 'routingGroupHistories',
            relatedModel: 'SampleRoutingActionRoutingGroup',
            collectionType: 'SampleRoutingActionRoutingGroupCollection'
        }, {
            type: Backbone.HasMany,
            key: 'assayGroups',
            relatedModel: 'AssayGroup',
            collectionType: 'AssayGroupCollection'
        }, {
            type: Backbone.HasMany,
            key: 'targets',
            relatedModel: 'MbAna',
            collectionType: 'MbAnaCollection'
        }, {
            type: Backbone.HasMany,
            key: 'lisAnas',
            relatedModel: 'LisAna',
            collectionType: 'LisAnaCollection'
        }, {
            type: Backbone.HasMany,
            key: 'prepWells',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrWells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'tracing',
            relatedModel: 'SampleTracing',
            collectionType: 'SampleTracingCollection'
        }, {
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'SampleResult',
            collectionType: 'SampleResultCollection',
            reverseRelation: {
                key: 'sampleParent',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasOne,
            key: 'order',
            relatedModel: 'Order',
            includeInJSON: false,
            reverseRelation: {
                key: 'order',
                includeInJSON: false
            }
        }],

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/samples');
        },

        save: function () {
            this.url = this.postUrl();

            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('SampleJson.pdf');
        },
        getEntity: function () {
            return module.exports;
        },
        recalcSampleClass: function () {
            var defer = $.Deferred();
            SampleClasses.findBySampleId(this.get('code')).done(_.bind(function (sampleClass) {
                if (sampleClass !== null) {
                    this.set('refSampleClass', sampleClass.toJSON());
                } else {
                    this.set('refSampleClass', null);
                }
                this.set('validSampleClass', true);
                defer.resolve();
            }, this))
                .fail(function (response) {
                    defer.reject(response);
                });
            return defer.promise();
        },
        refreshValidation: function () {
            var url = Settings.url('compuzz', 'v2/samples/' + this.get('secId') + '/refreshValidation'),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        findAnalyzes: function (codes) {
            var url = Settings.url('compuzz', 'v2/samples/' + this.get('secId') + '/findAnalyzes', {codes: codes}),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        updateComment: function (comment) {
            var url = Settings.url('compuzz', 'v2/samples/' + this.get('secId') + '/comment'),
                defer = $.Deferred();
            $.ajax({
                method: 'PUT',
                url: url,
                contentType: 'application/json',
                data: comment,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });

    app.ns.SampleCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/samples');
        },
        model: app.ns.Sample
    });

    module.exports = _.defaults({
        getName: function () {
            return 'sample';
        },
        getUrl: function () {
            return 'v2/samples';
        },
        getDynamicJson: function () {
            return 'SampleJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.exportable = false;
            config.archivable = false;
            config.importable = false;
            config.creatable = false;
            config.custom = new SampleCustomJqGridView();
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('sample.sampleId'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 100
            }, {
                label: _.i18n('sample.lis'),
                name: 'refLIS',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'lisCode',
                width: 40
            }, {
                label: _.i18n('sample.sampleClass'),
                name: 'refSampleClass',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'sampleClassCode',
                width: 40
            }, {
                label: _.i18n('sample.verified'),
                name: 'verified',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'verified',
                width: 60,
                stype: 'select',
                searchoptions: {
                    value: ':;PROGRESSING:PROGRESSING;VALIDATED:VALIDATED;INVALIDATE:INVALIDATE'
                }
            }, {
                label: _.i18n('sample.aliquot'),
                name: 'parent',
                formatter: viewDynamic.booleanFormatter,
                search: true,
                index: 'aliquot',
                width: 20,
                stype: 'select',
                searchoptions: {
                    sopt: ['eq'],
                    value: ':;true:' + _.i18n('common.checked') + ';false:' + _.i18n('common.unchecked')
                }
            }, {
                label: _.i18n('sample.bioGroup'),
                name: 'bioGroup',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'bioGroupCode',
                width: 40
            }, {
                label: _.i18n('sample.specie'),
                name: 'specie',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'specieCode',
                width: 40
            }, {
                label: _.i18n('common.creatDate'),
                name: 'creatDate',
                formatter: viewDynamic.toDateTimeFormatString, // this formatter need DATEFMT from settings
                search: true,
                index: 'creatDate',
                sorttype: 'date',
                searchrules: {date: true},
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                },
                width: 80
            }, {
                label: _.i18n('common.modDate'),
                name: 'modDate',
                formatter: viewDynamic.toDateTimeFormatString,
                search: true,
                index: 'modDate',
                sorttype: 'date',
                searchrules: {date: true},
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                },
                width: 80
            }, {
                label: _.i18n('sample.lisAnaOpened'),
                name: 'lisAnas',
                formatter: viewDynamic.codeNameListFormatter,
                search: true,
                index: 'lisTargetCode',
                sort: false
            }, {
                label: _.i18n('sample.gAssays'),
                name: 'assayGroups',
                formatter: viewDynamic.codeNameListFormatter,
                search: true,
                index: 'assayGroupCode',
                sort: false
            }, {
                label: _.i18n('sample.comment'),
                name: ['lisComment', 'comment'],
                formatter: viewDynamic.concatFormatter,
                search: true,
                index: 'comment',
                sort: false
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'creatDate';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Sample.findOrCreate(modelJson);
        },
        getCollection: function (datas) {
            if (!datas) {
                datas = [];
            }
            return new app.ns.SampleCollection(datas);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refLIS.code']};
        },
        findBySample: function (sample) {
            var url = Settings.url('compuzz', this.getUrl() + '/find/' + sample),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function (response) {
                    defer.resolve(this.getModel(response));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        findBySampleFromPreProcessing: function (sample) {
            var url = Settings.url('compuzz', this.getUrl() + '/findFromPreProcessing/' + sample),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function (response) {
                    defer.resolve(this.getModel(response));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        findByStartSample: function (sample) {
            var url = Settings.url('compuzz', this.getUrl() + '/findStart/' + sample),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function (response) {
                    defer.resolve(new app.ns.SampleCollection(response));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        recalcSampleClassByList: function (secIds) {
            var url = Settings.url('compuzz', this.getUrl() + '/recalcSampleClass'),
                defer = $.Deferred();
            $.ajax({
                method: 'PUT',
                url: url,
                contentType: 'application/json',
                data: JSON.stringify(secIds),
                success: _.bind(function () {
                    defer.resolve();
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        refreshValidationByList: function (secIds) {
            var url = Settings.url('compuzz', this.getUrl() + '/refreshValidation'),
                defer = $.Deferred();
            $.ajax({
                method: 'PUT',
                url: url,
                contentType: 'application/json',
                data: JSON.stringify(secIds),
                success: _.bind(function () {
                    defer.resolve();
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        valid: function (sample, secId) {
            var url = Settings.url('compuzz', this.getUrl() + '/valid', {sample: sample, sampleSecId: secId}),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    }, Dynamic);
});
