define('entities/admin/docglossary',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var DocGlossary = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'public/docglossary/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'name': '',
      'tag': '',
      'abbreviation': '',
      'description': ''
    },
    idAttribute: 'secId',

    postUrl: function () {
      return Settings.url('compuzz', 'public/docglossary');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var DocGlossaryCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'public/docglossary');
    },
    model: DocGlossary
  });

  var API = {

    getDocGlossaryCollectionUrl: function() {
      new DocGlossaryCollection();
      var params = {};
      return Settings.url('compuzz', 'public/docglossary', params);
    },

       getAllDocGlossary: function(params) {
      var url = Settings.url('compuzz', 'public/docglossary', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },



    getDocGlossaryCountUrl: function() {
      var params = '';
      return 'public/docglossary/rowCount' + params;
    },

    deleteDocGlossary: function(secId){
      var url = Settings.url('compuzz', 'public/docglossary/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getDocGlossaryByTag: function (tag) {
      var url = Settings.url('compuzz', 'public/docglossary/tag/' + tag),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function (item) {
          defer.resolve(item);
        }
      });

      return defer.promise();
    },
    generatePdf:function(secId) {
      var formdata = new FormData();
      formdata.append('secId', secId);
      var url = Settings.url('compuzz', 'public/docglossary/generatepdf'),
        defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: url,
        formdata: formdata,
        processData: false,
        contentType: false,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('docglossary:get-collection-url', function() {
      return API.getDocGlossaryCollectionUrl();
  });

  App.reqres.setHandler('docglossary:get-collection-to-page', function(params) {
      return API.getAllDocGlossary(params);
  });

  App.reqres.setHandler('docglossary:get-count-url', function() {
    return API.getDocGlossaryCountUrl();
  });

  App.reqres.setHandler('docglossary:model', function(itemJSON) {
    return new DocGlossary(itemJSON);
  });

  App.reqres.setHandler('docglossary:delete', function (secId) {
    return API.deleteDocGlossary(secId);
  });

  App.reqres.setHandler('setting:get-setting-by-tag', function (tag) {
    return API.getDocGlossaryByTag(tag);
  });

  App.reqres.setHandler('docglossary:generate-pdf', function(secId) {
    return API.generatePdf(secId);
  });
});
