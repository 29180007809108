define('tabsCollection',[
    'module',
    'marionette',
    'tabsItem',
    'tabsContentCollection',
    'underscore'
], function (
    module,
    Marionette,
    TabChildView,
    TabContentCollection,
    _
) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
        template: _.template('<ul class="nav nav-pills"></ul><div class="js-tab-content container-fluid" style="padding-left: 0; padding-right: 0; padding-top: 5px;"></div>'),
        childViewContainer: 'ul',
        childView: TabChildView,
        childEvents: {
            'click': function (childView) {
                _.each(this.children._views, _.bind(function (child) {
                    child.setActive(child === childView);
                }, this));
                this.tabContentCollection.changeActive(childView.model);
            }
        },
        ui: {
            tabContent: '.js-tab-content'
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.tabContentCollection = new TabContentCollection({collection: this.collection});
            this.ui.tabContent.html(this.tabContentCollection.render().el);
        },
        onShow: function () {
            _.each(this.children._views, _.bind(function (child) {
                child.setActive(child.model === this.collection.at(0));
            }, this));
            this.tabContentCollection.changeActive(this.collection.at(0));
        },
        addView: function (view) {
            this.collection.add(view.model);
        }
    });
});
