define('pcrWellDetailsView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'settings',
    'template!pcrWellDetailsView',
    'underscore',
    'jquery',
    'colorUtils',
    'app',
    'template!wellPoolTooltipTpl',
    'template!wellPooledTooltipTpl',
    'bootbox',
    'pcrWellResultCollectionView',
    'sampleCommentView',
    'pcrRunTracsView',
    'entities/caccounts/prepruns',
    'linkPopupView',
    'linkNewTabView',
    'dynamicCreateEditCustomListToList',
    'pcrWellGraphCurveView',
    'entities/caccounts/pcrwells',
    'entities/caccounts/mbanaresgrs'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    Settings,
    tpl,
    _,
    $,
    colorUtils,
    App,
    wellPoolTooltipTpl,
    wellPooledTooltipTpl,
    bootbox,
    PcrWellResultCollectionView,
    EditSampleCommentView,
    PcrRunTracsView,
    PrepRuns,
    LinkPopupView,
    LinkNewTabView,
    DynamicCreateEditCustomListToList,
    PcrWellGraphCurveView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            btnEditWellResult: '.btn-edit-well-result',
            btnEditAssay: '.btn-edit-assay',
            btnEditAssayVersion: '.btn-edit-assay-version',
            poolLabel: '.pool-label',
            pooledLabel: '.pooled-label',
            btnNextWell: '.btn-next-well',
            btnPreviousWell: '.btn-previous-well',
            copyParameters: '.js-copyParameters'
        },

        events: {
            'click .btn-show-prepWell': 'onShowPrepWell',
            'click @ui.btnEditWellResult': 'onEditWellResult',
            'click @ui.btnEditAssay': 'onAssayEdit',
            'click @ui.btnEditAssayVersion': 'onAssayEditVersion',
            'click @ui.btnNextWell': 'onNextWell',
            'click @ui.btnPreviousWell': 'onPreviousWell',
            'click .js-btn-refresh-well': 'onRefreshWell',
            'click .js-btn-repeat-pcr-result': 'onBtnRepeatPcr',
            'click .js-btn-repeat-pcr-result-control': 'onBtnRepeatPcrControl',
            'click .js-btn-repeat-dna-result': 'onBtnRepeatExtraction',
            'click .js-btn-repeat-dna-result-control': 'onBtnRepeatExtractionControl',
            'click .js-btn-repeat-dna-result-default': 'onBtnRepeatExtractionDefault',
            'click .js-btn-repeat-dna-result-control-default': 'onBtnRepeatExtractionControlDefault',
            'click .js-btn-expert-result': 'onWellBtnExpertClick',
            'click .js-btn-validation-1': 'onBtnValidation1',
            'click .js-btn-validation-2': 'onBtnValidation2',
            'click .js-btn-validation-1-2': 'onBtnValidation12',
            'click .js-btn-cancel-validation': 'onBtnCancelValidation',
            'click .js-btn-validation-expert': 'onBtnValidationExpert',
            'click .sampleid': 'onSampleIdClick',
            'click .js-edit-sampleComment': 'onEditSampleComment',
            'click .js-pcrWell-tracs-view': 'onShowTracsView',
            'click .js-run-valView-action-nav-drop-btn': 'onRunActionNavDropBtnClick',
            'click .js-run-valView-action-nav-drop-cell-btn': 'onRunActionNavDropCellBtnClick',
            'mouseleave .run-valView-action-nav-container': 'onHideRunActionContainer',
            'click .valView-dilution-dropdown': 'onDilution'
        },

        regions: {
            wellResultRegion: '.js-wellResult-region',
            wellTargetRegion: '.js-wellTarget-region',
            wellDisplaySample: '.js-well-sample',
            runDisplay: '.js-preprun',
            tags: '.js-tags',
            graphRegion: '.js-graph-region',
            resultRegion: '.js-result-region'
        },

        modelEvents: {
            'change': 'render',
            'change:tags': 'saveTags'
        },
        className: 'container-fluid',

        onDilution: function (e) {
            e.preventDefault();
            e.stopPropagation();
        },

        onRunActionNavDropBtnClick: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).parent().find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).parent().find('.run-valView-action-nav-list-empty'));

            _.each(targets, function (target) {
                if (target.hasClass('hidden')) {
                    target.removeClass('hidden');
                    $(e.currentTarget).addClass('active');
                } else {
                    target.addClass('hidden');
                    $(e.currentTarget).removeClass('active');
                }
            });
        },

        onRunActionNavDropCellBtnClick: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).parent().parent().parent().find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).parent().parent().parent().find('.run-valView-action-nav-list-empty'));
            _.each(targets, function (target) {
                if (target.hasClass('hidden')) {
                    target.removeClass('hidden');
                    $(e.currentTarget).addClass('active');
                } else {
                    target.addClass('hidden');
                    $(e.currentTarget).removeClass('active');
                }
            });
        },

        onHideRunActionContainer: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).find('.run-valView-action-nav-list-empty'));
            _.each(targets, function (target) {
                target.addClass('hidden');
                $(e.currentTarget).find('.run-valView-action-nav-drop-btn').removeClass('active');
            });
        },

        onShowTracsView: function () {
            this.pcrRunTracsView = new PcrRunTracsView({
                model: this.model,
                type: 'wellTracks'
            });
            this.pcrRunTracsView.show({
                title: _.i18n('tracs'),
                className: 'runShowTracsPopup'
            });
        },

        onEditSampleComment: function () {
            this.model.get('smpId').fetch().done(_.bind(function () {
                this.editSampleCommentView = new EditSampleCommentView({
                    wellModel: this.model,
                    model: this.model.get('smpId')
                });
                this.editSampleCommentView.show({
                    title: _.i18n('edit.sampleComment'),
                    className: ''
                });
            }, this));
        },

        initialize: function (options) {
            this.channelToDisplay = options.channelToDisplay;
            this.readOnly = options.readOnly; // true if popup is called from a prepWell edit popup
            this.cSelection = options.cSelection;
        },

        onAssayEdit: function () {
            require(['adminAssayController'], _.bind(function (Controller) {
                Controller.showDetails(this.model.get('refAssay'));
            }, this));
        },

        onAssayEditVersion: function () {
            require(['assayVersionController'], _.bind(function (AssayVersionController) {
                this.model.getPcrKitLots().done(_.bind(function (response) {
                    AssayVersionController.showDetails(this.model.get('assayVersion'), null, {
                        lots: response,
                        cyclerPublicSecId: this.model.get('refPcrRun').get('refCyclerPublic').secId
                    });
                }, this))
                    .always(_.bind(function () {
                        this.$el.trigger('mouseleave');
                    }, this));
            }, this));
        },

        findPosition: function (array, value, next) {
            var positions = array;
            var nextPosition = false;
            for (var i = 0; i < positions.length; i += 1) {
                if (positions[i] === value) {
                    if (next) {
                        if (i === positions.length - 1) {
                            nextPosition = positions[0];
                        } else {
                            nextPosition = positions[i + 1];
                        }
                    } else {
                        if (i === 0) {
                            nextPosition = positions[positions.length - 1];
                        } else {
                            nextPosition = positions[i - 1];
                        }
                    }
                }
            }
            return nextPosition;
        },

        findPositionAttr: function (array, attr, value, next) {
            this.params = {
                attr: attr,
                value: value,
                next: next,
                retVal: false
            };

            array.each(_.bind(function (object, index, parent) {
                if (object.get(this.params.attr) === this.params.value) {
                    if (this.params.next) {
                        if (index === parent.length - 1) {
                            this.params.retVal = parent[0].get(this.params.attr);
                        } else {
                            this.params.retVal = parent[index + 1].get(this.params.attr);
                        }
                    } else {
                        if (index === 0) {
                            this.params.retVal = parent[parent.length - 1].get(this.params.attr);
                        } else {
                            this.params.retVal = parent[index - 1].get(this.params.attr);
                        }
                    }
                }
            }, this));

            return this.params.retVal;
        },

        onPreviousWell: function () {
            var wellPosition = this.model.get('pos');
            var previousWellPosition;
            if (this.cSelection && this.cSelection.length) {
                previousWellPosition = this.findPosition(this.cSelection, wellPosition, false);
            } else {
                previousWellPosition = this.findPositionAttr(this.model.get('refPcrRun').get('wells'), 'pos', wellPosition, false);
            }
            this._changeModel(previousWellPosition);
        },

        _changeModel: function (pos) {
            this.model = this.model.get('refPcrRun').get('wells').findWhere({'pos': pos});
            this.render();
        },

        onNextWell: function () {
            var wellPosition = this.model.get('pos');
            var nextWellPosition;
            if (this.cSelection && this.cSelection.length) {
                nextWellPosition = this.findPosition(this.cSelection, wellPosition, true);
            } else {
                nextWellPosition = this.findPositionAttr(this.model.get('refPcrRun').get('wells'), 'pos', wellPosition, true);
            }
            this._changeModel(nextWellPosition);
        },

        onSampleIdClick: function () {
            var assaySecId = this.model.get('refAssay') ? this.model.get('refAssay').get('secId') : null;
            var smpSecId = this.model.get('smpId').get('secId');

            if (assaySecId && smpSecId) {
                if (this.model.get('smpType') === 'U') {
                    require(['samplesController'], _.bind(function (Controller) {
                        Controller.showDetailsBySampleSecId(this.model.get('smpId').get('secId'));
                    }, this));
                }
            }
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                smpType: this.model.get('smpType'),
                pooled: this.model.get('pooled'),
                assay: this.model.get('refAssay') ? this.model.get('refAssay').get('code') : '',
                assayVersion: this.model.get('assayVersion') ? this.model.get('assayVersion').get('code') : '',
                existOtherWellSameAssay: this.model.get('existOtherWellSameAssay'),
                existOtherWellOtherAssay: this.model.get('existOtherWellOtherAssay'),
                smpId: this.model.get('smpId').get('code'),
                valWst: this.model.get('valWst'),
                repeatStatus: this.model.get('repeatStatus'),
                dilution: this.model.get('dilution')
            };
            // templateData.smpId = this.model.get('smpId').toJSON();
            templateData.readOnly = this.model.isReadOnly();
            templateData.canChangeSmpId = this.model.canChangeSmpId();
            /*jshint -W069 */
            /*Disable Warning Justification on using [] instead of dot notation*/
            templateData.val1User = this.val1User = !!(Settings.get('currentUserModel').hasType('VAL1'));
            templateData.val2User = this.val2User = !!(Settings.get('currentUserModel').hasType('VAL2'));
            templateData.val12User = this.val12User = !!(Settings.get('currentUserModel').hasType('VAL12'));
            templateData.valEUser = this.valEUser = !!(Settings.get('currentUserModel').hasType('EXPVAL'));
            templateData.mres1 = this.valMres1 = !!(Settings.get('currentUserModel').hasType('MRES1'));
            templateData.mres2 = this.valMres2 = !!(Settings.get('currentUserModel').hasType('MRES2'));
            /*jshint +W069 */

            templateData.repeatAllowed = this.repeatAllowed = true;
            templateData.concatComment = (this.model.get('smpId').get('lisComment') ? this.model.get('smpId').get('lisComment') : '') + ' - ' + (this.model.get('smpId').get('comment') ? this.model.get('smpId').get('comment') : '');
            if (templateData.concatComment === ' - ') {
                templateData.concatComment = '';
            }

            var resultsCounter = 0,
                toVal1Counter = 0,
                toVal2Counter = 0,
                toValExpertCounter = 0,
                validatedCounter = 0,
                targetsCodeErr = false;

            this.model.get('results').each(_.bind(function (r) {
                if (r.get('codeErr') && r.get('codeErr') !== null && r.get('codeErr') !== '') {
                    targetsCodeErr = true;
                }
                if (r.get('sequenceDisplayGroup')) {
                    return;
                }

                if (!r.get('needValidation')) {
                    return;
                }
                resultsCounter++;
                if (r.get('valSt') < 3) {
                    toVal1Counter++;
                } else if (r.get('valSt') === 3.1) {
                    toValExpertCounter++;
                } else if (r.get('valSt') === 3.2) {
                    toVal2Counter++;
                } else if (r.get('valSt') >= 4) {
                    validatedCounter++;
                }
                if (r.get('valSt') === 5) {
                    templateData.repeatAllowed = this.repeatAllowed = false;
                    // Avoid repeat (when well's exported status are inconsistant)
                }
            }, this));

            this.wellToValExpert = toValExpertCounter > 0;
            this.wellToVal1 = toVal1Counter > 0;
            this.wellToVal2 = toVal2Counter > 0;
            this.wellValidated = !(this.wellToVal1 || this.wellToVal2 || this.wellToValExpert);

            templateData.colorOtherWellSameAssay = this.options.colorOtherWellSameAssay;
            templateData.colorOtherWellOtherAssay = this.options.colorOtherWellOtherAssay;

            templateData = _.extend(templateData, this.model.getErrorObject());

            templateData.pooled = !this.model.get('wellsPool').isEmpty();
            templateData.pool = !this.model.get('wellsPooled').isEmpty();

            var $tags = this.model.get('tags');
            if ($tags.length === 1 && $tags[0] !== '' || $tags.length > 1) {
                templateData.tags = $tags.join(';');
            }

            templateData.errorWarning = targetsCodeErr;
            templateData.refPrepWellPosition = this.model.get('refPrepWell') ? this.model.get('refPrepWell').get('pos') : '';
            templateData.displayResult = this.model.get('results').any(function (result) {
                return !result.get('sequenceDisplayGroup');
            });
            templateData.displayResultGroup = this.model.get('results').any(function (result) {
                return result.get('sequenceDisplayGroup');
            });
            return templateData;
        },

        isResolved: function () {
            var retVal = true;
            this.model.get('results').each(function (target) {
                if (target.get('needValidation') && target.get('codeErr')) {
                    retVal = false;
                }
            });
            if (!retVal) {
                bootbox.alert(_.i18n('well.remaining.unResolved'));
            }
            return retVal;
        },

        onBtnValidation1: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.validation1()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnValidationExpert: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.validationExpert()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnValidation2: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.validation2()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnValidation12: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.validation12()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnCancelValidation: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.cancelValidation()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnRepeatPcr: function () {
            $('.js-global-loader').show();
            this.model.repeatPCR()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onBtnRepeatPcrControl: function () {
            $('.js-global-loader').show();
            this.model.repeatPCRControl()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onBtnRepeatExtraction: function () {
            this.repeatExtraction($('#dnaDilution').val());
        },

        onBtnRepeatExtractionDefault: function () {
            this.repeatExtraction('No dil.');
        },

        repeatExtraction: function (dilution) {
            $('.js-global-loader').show();
            this.model.repeatExtraction(dilution)
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onBtnRepeatExtractionControl: function () {
            this.repeatExtractionControl($('#dnaDilutionControl').val());
        },

        onBtnRepeatExtractionControlDefault: function () {
            this.repeatExtractionControl('No dil.');
        },

        repeatExtractionControl: function (dilution) {
            $('.js-global-loader').show();
            this.model.repeatExtractionControl(dilution)
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onWellBtnExpertClick: function () {
            $('.js-global-loader').show();
            this.model.requireExpertValidation()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onRefreshWell: function () {
            var valStatus = parseInt(this.model.get('valWst'), 10);
            if ([3, 4, 5].includes(valStatus)) {
                var txt = '';
                if (valStatus === 5) {
                    txt = '<h2><b>' + _.i18n('warning.recalc.result') + '</b></h2><br/><br/>';
                }
                txt += _.i18n('recalc.result');
                bootbox.confirm(txt, _.bind(function (result) {
                    if (result) {
                        this._refreshWell();
                    }
                }, this));
            } else {
                this._refreshWell();
            }
        },
        _refreshWell: function () {
            $('.js-global-loader').show();
            this.model.refreshWell()
                .fail(_.bind(function (response) {
                    if (response.status === 409) {
                        bootbox.alert(_.i18n(response.responseText));
                    }
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onEditWellResult: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showDetails(this.model);
            }, this));
        },

        onRender: function () {
            $('#right-graph').css('border-left-color', this.wellResColor);
            this.$el.find('[data-toggle="tooltip"]').tooltip();
            this.showPoolPopover(this.model);
            this.showPooledPopover(this.model);

            this.model.get('results').each(_.bind(function (result) {
                var dyeToDisplay = '';
                var colors = [];
                var curveSorted = result.get('curves').sortBy(_.bind(function (curve) {
                    var refDye = this.model.get('refPcrRun').get('dyes').find(function (dye) {
                        return (dye.channel && dye.name ? dye.channel + '_' + dye.name : (!dye.channel ? dye.name : (!dye.name ? dye.channel : ''))) === curve.get('name');
                    });

                    return refDye ? refDye.channel : curve.get('name');
                }, this));
                _.each(curveSorted, _.bind(function (curve) {
                    dyeToDisplay = curve.get('name');
                    var refDye = this.model.get('refPcrRun').get('dyes').find(function (dye) {
                        return (dye.channel && dye.name ? dye.channel + '_' + dye.name : (!dye.channel ? dye.name : (!dye.name ? dye.channel : ''))) === curve.get('name');
                    });
                    var color;
                    if (refDye) {
                        dyeToDisplay = refDye.shortName ? refDye.shortName : '';
                        color = refDye.color ? refDye.color : colorUtils.colorTarget(refDye.channel, this.model.get('refPcrRun'), this.model, null, curve);
                    }
                    if (result.get('internalControl') && this.model.get('refPcrRun').get('settings')['IC-PARAM']) {
                        color = this.model.get('refPcrRun').get('settings')['IC-PARAM'].split(';')[0];
                    }
                    if (!color) {
                        color = 'transparent';
                    }
                    colors.push(color);
                }, this));
                var borderImage;
                if (colors.length === 0) {
                    borderImage = 'border-color: transparent';
                } else if (colors.length === 1) {
                    borderImage = 'border-color: ' + colors[0];
                } else {
                    borderImage = 'border-color: transparent';
                }

                var data = {'dyeToDisplay': dyeToDisplay, 'dyeColorToDisplay': borderImage};
                result.set(data, {silent: true});
            }, this));


            var region = this.getRegion('resultRegion');
            if (this.model.get('results').isEmpty()) {
                var missing = [];
                if (!this.model.get('refPcrRun').get('refCyclerPublic')) {
                    missing.push('<li>' + _.i18n('pcrRun.refCyclerPublic.missing') + '</li>');
                }
                if (!this.model.get('refPcrRun').get('refCycler')) {
                    missing.push('<li>' + _.i18n('pcrRun.refCycler.missing') + '</li>');
                }
                if (!this.model.get('refAssay')) {
                    missing.push('<li>' + _.i18n('pcrRun.refAssay.missing') + '</li>');
                }
                region.show(new Marionette.ItemView({
                    template: _.template(missing.join('')),
                    tagName: 'ul'
                }));
            } else {
                region.show(new PcrWellResultCollectionView({
                    collection: this.model.get('results')
                }));
            }


            if (!this.model.get('curves').isEmpty()) {
                this.getRegion('graphRegion').show(new PcrWellGraphCurveView({
                    model: this.model
                }));
            }

            this.checkForAllowedPreviousNextButtons();
            this.checkForAllowedActions();
            var options, view;
            if (this.model.get('smpType') === 'U') {
                options = this.model.get('smpId').getEntity().parameterForLink(this.model.get('smpId'));
                view = new LinkPopupView(options);
                this.getRegion('wellDisplaySample').show(view);
            } else {
                this.getRegion('wellDisplaySample').show(new Marionette.ItemView({template: _.template(this.model.get('smpId').get('code'))}));
            }
            if (this.model.get('refPrepWell')) {
                options = this.model.get('refPrepWell').get('refPrepRun').getEntity().parameterForLink(this.model.get('refPrepWell').get('refPrepRun'));
                view = new LinkNewTabView(options);
                this.getRegion('runDisplay').show(view);
            }
            App.trigger('forClipboard', this.ui.copyParameters);
            this.getRegion('tags').show(new DynamicCreateEditCustomListToList({
                'field': 'tags',
                'model': this.model
            }));
        },

        checkForAllowedActions: function () {
            var wellRepeated = !!this.model.get('repeatStatus');
            this.readOnly = this.model.isReadOnly();
            var isQC = this.model.get('smpType') === 'PC' || this.model.get('smpType') === 'NC' || this.model.get('smpType') === 'RC' || this.model.get('smpType') === 'OC';

            // check on validation 1 action
            if (!(this.wellToVal1 && this.val1User) || this.wellValidated) {
                this.$el.find('.valView-graph-button.js-btn-validation-1').addClass('fadedActionButton');
                this.$el.find('.valView-graph-button.js-btn-validation-1').removeClass('js-btn-validation-1');
                this.$el.find('button.js-btn-validation-1').parent().remove();
            }

            // check on validation 2 action
            if ((this.wellToVal1 && this.val1User) || this.wellValidated) {
                this.$el.find('.valView-graph-button.js-btn-validation-2').addClass('fadedActionButton');
                this.$el.find('.valView-graph-button.js-btn-validation-2').removeClass('js-btn-validation-2');
                this.$el.find('button.js-btn-validation-2').parent().remove();
            }

            if (!(parseInt(this.model.get('valWst'), 10) === 3 && this.val1User) && !(parseInt(this.model.get('valWst'), 10) === 4 && (this.val2User || this.val12User)) && !(this.wellValidated && (this.val2User || this.val12User))) {
                this.$el.find('.valView-graph-button.js-btn-cancel-validation').addClass('fadedActionButton');
                this.$el.find('.valView-graph-button.js-btn-cancel-validation').removeClass('js-btn-cancel-validation');
                this.$el.find('button.js-btn-cancel-validation').parent().remove();
            }

            // check on validation expert action
            if (!(this.wellToValExpert && this.valEUser) || this.wellValidated) {
                this.$el.find('.valView-graph-button.js-btn-validation-expert').addClass('fadedActionButton');
                this.$el.find('.valView-graph-button.js-btn-validation-expert').removeClass('js-btn-validation-expert');
                this.$el.find('button.js-btn-validation-expert').parent().remove();
            }

            // check on validation 1 & 2 action
            if (!((this.wellToVal1 || this.wellToVal2) && this.val12User) || this.wellValidated) {
                this.$el.find('.valView-graph-button.js-btn-validation-1-2').addClass('fadedActionButton');
                this.$el.find('.valView-graph-button.js-btn-validation-1-2').removeClass('js-btn-validation-1-2');
                this.$el.find('button.js-btn-validation-1-2').parent().remove();
            }

            if (wellRepeated || isQC || this.wellValidated) {
                this.$el.find('.repeat-graph-button.js-btn-repeat-dna-result-default').addClass('fadedActionButton');
                this.$el.find('.repeat-graph-button.js-btn-repeat-dna-result-default').removeClass('js-btn-repeat-dna-result-default');
                this.$el.find('button.js-btn-repeat-pcr-result').parent().remove();
                this.$el.find('button.js-btn-repeat-pcr-result-control').parent().remove();
                this.$el.find('.repeat-graph-button.js-btn-repeat-dna-result-control-default').addClass('fadedActionButton');
                this.$el.find('.repeat-graph-button.js-btn-repeat-dna-result-control-default').removeClass('js-btn-repeat-dna-result-control-default');
                this.$el.find('button.js-btn-repeat-dna-result').parent().remove();
                this.$el.find('button.js-btn-repeat-dna-result-control').parent().remove();
            }

            // check on refresh recalc action
            if (!this.valMres1) {
                this.$el.find('.valView-graph-button.js-btn-refresh-well').addClass('fadedActionButton');
                this.$el.find('.valView-graph-button.js-btn-refresh-well').removeClass('js-btn-refresh-well');
            }

            // check on expert action
            if (!(this.valMres2 && !wellRepeated) || this.wellValidated) {
                this.$el.find('.btn-edit-well-target-result').addClass('invisible');
            }

            if (!this.valMres1 || this.wellValidated || this.wellToValExpert) {
                this.$el.find('.valView-graph-button.js-btn-expert-result').addClass('fadedActionButton');
                this.$el.find('.valView-graph-button.js-btn-expert-result').removeClass('js-btn-expert-result');
            }
        },

        checkForAllowedPreviousNextButtons: function () {
            var allowPreviousBtn = true;
            var allowNextBtn = true;
            if (this.cSelection && this.cSelection.length && this.cSelection.length > 0) {
                _.each(this.cSelection, _.bind(function (wellPos, i) {
                    if (wellPos === this.model.get('pos')) {
                        if (i === 0) {
                            allowPreviousBtn = false;
                        }
                        if (i === this.cSelection.length - 1) {
                            allowNextBtn = false;
                        }
                    }
                }, this));
            } else {
                this.model.get('refPcrRun').get('wells').each(_.bind(function (well, i) {
                    if (well.get('pos') === this.model.get('pos')) {
                        if (i === 0) {
                            allowPreviousBtn = false;
                        }
                        if (i === this.model.get('refPcrRun').get('wells').length - 1) {
                            allowNextBtn = false;
                        }
                    }
                }, this));
            }
            if (!allowPreviousBtn) {
                var previousBtn = this.$el.find('.css-previous-well');
                previousBtn.removeClass('btn-previous-well');
                previousBtn.removeClass('clickable');
                previousBtn.css('opacity', 0.2);
            }
            if (!allowNextBtn) {
                var nextBtn = this.$el.find('.css-next-well');
                nextBtn.removeClass('btn-next-well');
                nextBtn.removeClass('clickable');
                nextBtn.css('opacity', 0.2);
            }
        },

        showPooledPopover: function (well) {
            if (this.ui.pooledLabel && this.ui.pooledLabel.length > 0) {
                var templateData = well.toJSON();
                var content = Marionette.Renderer.render(wellPooledTooltipTpl, templateData);
                this.ui.pooledLabel.attr('data-content', content);
                this.ui.pooledLabel.popover({
                    trigger: 'manual',
                    placement: 'bottom',
                    html: 'true',
                    delay: {
                        show: '300',
                        hide: '500'
                    },
                    container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
                }).on('mouseenter', function () {
                    var _this = this;
                    $(this).popover('show');
                    $('.popover').on('mouseleave', function () {
                        $(_this).popover('hide');
                    });
                }).on('mouseleave', function () {
                    var _this = this;
                    setTimeout(function () {
                        if (!$('.popover:hover').length) {
                            $(_this).popover('hide');
                        }
                    }, 300);
                }).data('bs.popover').tip().addClass('pool-popover');
            }
        },

        showPoolPopover: function (well) {
            if (this.ui.poolLabel && this.ui.poolLabel.length > 0 &&
                (well.get('smpId').get('name') || well.get('smpType')) && well.get('refAssay')) {
                var templateData = well.toJSON();
                var content = Marionette.Renderer.render(wellPoolTooltipTpl, templateData);
                var t = $(this.ui.poolLabel);
                t.attr('data-content', content);
                t.popover({
                    trigger: 'manual',
                    placement: 'bottom',
                    html: 'true',
                    delay: {
                        show: '300',
                        hide: '100'
                    },
                    container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
                }).on('mouseenter', function () {
                    var _this = this;
                    $(this).popover('show');
                    $('.popover').on('mouseleave', function () {
                        $(_this).popover('hide');
                    });
                }).on('mouseleave', function () {
                    var _this = this;
                    setTimeout(function () {
                        if (!$('.popover:hover').length) {
                            $(_this).popover('hide');
                        }
                    }, 300);
                }).data('bs.popover').tip().addClass('pool-popover');
            }
        },
        saveTags: function () {
            $('.js-global-loader').show();
            this.model.saveDetail().always(function () {
                $('.js-global-loader').hide();
            });
        }
    });
});
