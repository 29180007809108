
define('template!pcrSetupPrepRunItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="headerRun">\n    <h3>' +
((__t = ( name )) == null ? '' : __t) +
'</h3>\n</div>\n<hr class="js-hr">\n<div class="js-wells">\n\n</div>';

}
return __p
};});

