define('adminUsersController',[
	'module',
	'underscore',
	'app',
	'adminUsersView',
	'bootbox',
	'usersCreateEditLayoutView',
	'settings'
], function (
	module,
	_,
	App,
	View,
	bootbox,
	CreateEditLayoutView
) {
	'use strict';

	module.exports = {

		showUsersAdd: function (model, callBackRefresh) {
			if (!model) {
				alert('Can\'t create a new model without entity, need to define empty model or existing model.');
				throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
			}
			var createEditLayoutView = new CreateEditLayoutView({
				model: model
			});
			createEditLayoutView.show({
				title: _.i18n('menu.admin.users')
			}, _.bind(function () {
				if (callBackRefresh) {
					callBackRefresh();
				}
			}));
		},

		onGridLoaded: function () {
			var data = {
				url: App.request('caccount-admins:get-url'),
				rowCountUrl: App.request('caccount-admins:row-count-url')
			};
			this.view.displayGrid(data);
		},

		onDelete: function (secId) {
			bootbox.confirm(_.i18n('user.confirmDelete'), _.bind(function (result) {
				if (result) {
					App.request('caccount-admins:delete-item', secId)
						.done(_.bind(function () {
							this.view.updatePagination('reloadGrid');
						}, this));
				}
			}, this));
		}
	};
});
