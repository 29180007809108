define('dynamicCreateEditDescriptionCommentTags',[
    'module',
    'formView',
    'template!dynamicCreateEditDescriptionCommentTags',
    'moment',
    'underscore',
    'backbone',
    'dynamicCreateEditCustomListToList'
], function (
    module,
    FormView,
    tpl,
    moment,
    _,
    Backbone,
    DynamicCreateEditCustomListToList
) {
    'use strict';

    module.exports = FormView.extend({
        template: tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'change @ui.input': 'onInputChange'
        },

        regions: {
            tags: '.js-tags'
        },

        modelEvents: {
            'change:tags': 'render'
        },

        className: 'description-component',

        serializeData: function () {
            var templateData = {
                'description': this.model.get('description'),
                'comment': this.model.get('comment'),
                'created': '-',
                'createdBy': '-',
                'createdByTitle': '-',
                'updated': '-',
                'updatedBy': '-',
                'updatedByTitle': '-'
            };
            if (this.model.get('creatDate')) {
                templateData.created = moment(this.model.get('creatDate')).format('DD-MM-YYYY HH:mm');
            }
            if (this.model.get('creatBy')) {
                if (this.model.get('creatBy') instanceof Backbone.Model) {
                    templateData.createdBy = this.model.get('creatBy').get('nickName');
                    templateData.createdByTitle = this.model.get('creatBy').get('logonId');
                }
                if (_.isObject(this.model.get('creatBy'))) {
                    templateData.createdBy = this.model.get('creatBy').nickName;
                    templateData.createdByTitle = this.model.get('creatBy').logonId;
                }
            }
            if (this.model.get('modDate')) {
                templateData.updated = moment(this.model.get('modDate')).format('DD-MM-YYYY HH:mm');
            }
            if (this.model.get('modBy')) {
                if (this.model.get('modBy') instanceof Backbone.Model) {
                    templateData.updatedBy = this.model.get('modBy').get('nickName');
                    templateData.updatedByTitle = this.model.get('modBy').get('logonId');
                }
                if (_.isObject(this.model.get('modBy'))) {
                    templateData.updatedBy = this.model.get('modBy').nickName;
                    templateData.updatedByTitle = this.model.get('modBy').logonId;
                }
            }

            return templateData;
        },

        onRender: function () {
            this.getRegion('tags').show(new DynamicCreateEditCustomListToList({
                'field': 'tags',
                'model': this.model,
                display: {
                    readonly: true,
                    placeHolder: _.i18n('common.tags')
                }
            }));
            this.setPermissions(this.options.editable !== undefined ? this.options.editable : true);
        }
    });
});
