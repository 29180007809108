
define('template!pcrWellMagPixToolTipView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="color: white;">\n    <div class="row p-l-5 p-r-5" style="color: white;">\n        <div class="col-w-all col-w-100 d-b" style="color: white;">\n            <div class="col-w-all col-w-100 d-f">\n                <div class="col-w-all col-w-10 p-r-5 col-labelContainer noWrapHidden"\n                     style="text-align: left;">' +
((__t = ( pos )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-20 p-r-5 col-labelContainer noWrapHidden"\n                     style="text-align: left;">' +
((__t = ( assay )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-10 p-r-5 noWrapHidden d-f"\n                     style="text-align: right; height: 25px;">\n                    ';
 if ( valWst === 3.1) { ;
__p += '\n                    <span class="mdi mdi-school pcrTooltip-expert"></span>\n                    ';
 } else if(errorWarning) { ;
__p += '\n                    <span class="mdi mdi-alert  pcrTooltip-warning-error" style="color: orange;"></span>\n                    ';
 } else if (repeatStatus === 'PT') { ;
__p += '\n                    <div style="width: 15px;">\n                        <span class="mdi mdi-play mdi-flip-h pcrTooltip-repeatIcon-simple"></span>\n                    </div>\n                    ';
 } else if (repeatStatus === 'PC') { ;
__p += '\n                    <div style="width: 15px;">\n                        <span class="mdi mdi-play mdi-flip-h pcrTooltip-repeatIcon-simple"></span>\n                        <span class="pcrTooltip-repeatIcon-text-simple">C</span>\n                    </div>\n                    ';
 } else if(repeatStatus === 'XT') { ;
__p += '\n                    <div style="width: 23px; padding-left: 4px;">\n                        <span class="mdi mdi-play mdi-flip-h pcrTooltip-repeatIcon-left"></span>\n                        <span class="mdi mdi-play mdi-flip-h pcrTooltip-repeatIcon-right"></span>\n                    </div>\n                    ';
 } else if (repeatStatus === 'XC') { ;
__p += '\n                    <div style="width: 23px; padding-left: 4px;">\n                        <span class="mdi mdi-play mdi-flip-h pcrTooltip-repeatIcon-left"></span>\n                        <span class="mdi mdi-play mdi-flip-h pcrTooltip-repeatIcon-right"></span>\n                        <span class="pcrTooltip-repeatIcon-text">C</span>\n                    </div>\n                    ';
 } else if (repeatStatus === 'DP') { ;
__p += '\n                    <div style="width: 15px;">\n                        <span class="mdi mdi-play mdi-flip-h pcrTooltip-repeatIcon-simple"></span>\n                        <span class="pcrTooltip-repeatIcon-text-simple">D</span>\n                    </div>\n                    ';
 } ;
__p += '\n                    ';
 if (smpType === 'NC') { ;
__p += ' <!-- smpType === \'NC\' -->\n                    <span class="mdi-content-remove-circle-outline pcrTooltip-smpType-icon"></span>\n                    ';
 } else if(smpType === 'PC') { ;
__p += ' <!-- smpType === \'PC\' -->\n                    <span class="mdi-content-add-circle-outline pcrTooltip-smpType-icon"></span>\n                    ';
 } else if(smpType === 'RC') { ;
__p += ' <!-- smpType === \'RC\' -->\n                    <span class="iconify" style="font-size: 22px; position: relative;"\n                          data-icon="mdi-registered-trademark"></span>\n                    ';
 } else if(smpType === 'C') { ;
__p += ' <!-- smpType === \'C\' -->\n                    <span class="iconify" style="font-size: 22px; position: relative;"\n                          data-icon="ant-design:copyright-circle-outlined"></span>\n                    ';
 } else if(smpType === 'P') { ;
__p += ' <!-- smpType === \'P\' Pool -->\n                    <span class="iconify" style="font-size: 22px; position: relative;"\n                          data-icon="mdi:google-circles-communities"></span>\n                    ';
 } else if(smpType === 'OC') { ;
__p += '\n                    <span class="iconify" style="font-size: 22px; position: relative;"\n                          data-icon="ant-design:compass-outlined"></span>\n                    ';
 } else if(smpType === 'D') { ;
__p += '\n                    <span class="iconify" style="font-size: 22px; position: relative;"\n                          data-icon="fa6-solid:d"></span>\n                    ';
 } else if(smpType === 'C') { ;
__p += '\n                    <span class="iconify" style="font-size: 22px; position: relative;"\n                          data-icon="fa6-solid:c"></span>\n                    ';
 } else if(smpType === 'U') { ;
__p += '\n                    ';
 } ;
__p += '\n                    ';
 if(pooled) { ;
__p += ' <!-- smpType === \'p\' pooled -->\n                    <span class="iconify" style="font-size: 22px; position: relative;"\n                          data-icon="ic:outline-adjust"></span>\n                    ';
 } ;
__p += '\n                </div>\n                <div class="col-w-all col-w-35 p-r-5 col-labelContainer noWrapHidden"\n                     style="text-align: left;">' +
((__t = ( smpId )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-25 p-l-5 p-r-5 col-labelContainer noWrapHidden ' +
((__t = ( concatComment ? 'commentWell' : '')) == null ? '' : __t) +
'"\n                     style="text-align: left;">' +
((__t = ( concatComment )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class="row p-l-5 p-r-5 m-b-5">\n        <div class="separator col-w-all col-w-100 d-b" style="border-bottom: solid 1px;"></div>\n    </div>\n\n    <img class="js-graph"/>\n\n    ';
 if (wellsPooled && wellsPooled.length > 0) { ;
__p += '\n    <div class="col-xs-12 m-b-10 p-t-5" style="border: solid 1px; border-radius: 8px; color: white;">\n        ';
 wellsPooled.sort((a,b) => (a.runName > b.runName) ? 1 : ((b.runName > a.runName) ? -1 : 0));
__p += '\n        ';
 _.each(wellsPooled, function(well) { ;
__p += '\n        <div class="m-b-0 col-xs-12">\n            <label class="col-xs-6 noWrapHidden">' +
((__t = ( well.runName )) == null ? '' : __t) +
'</label>\n            <label class="col-xs-2 noWrapHidden">' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n            <label class="col-xs-4 noWrapHidden">' +
((__t = ( well.smpId.name )) == null ? '' : __t) +
'</label>\n        </div>\n        ';
 }); ;
__p += '\n    </div>\n    ';
 } ;
__p += '\n</div>';

}
return __p
};});

