define('prepRunCreateLayoutView',[
	'module',
	'marionette',
	'backbone',
	'dialogFormView',
	'template!prepRunCreateLayoutTpl',
	'savingBehavior',
	'bootbox',
	'underscore',
	'jquery',
	'app',
	'settings',
	'autocompleteView',
	'moment',
	'entities/caccounts/userpids',
	'entities/caccounts/prepruntemplates',
	'entities/caccounts/prepruns',
	'entities/caccounts/userCodelist',
	'entities/createPrepRunFromTemplate'
], function (
	module,
	Marionette,
	Backbone,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	bootbox,
	_,
	$,
	App,
	Settings,
	AutocompleteView,
	moment,
	Userpids,
	PrepRunTemplates,
	PrepRuns,
	UserCodeLists
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			numberOfRunRegion: '.js-number-of-run-region',
			numberOfRun: '.number-of-run',
			generateStarterSheet: '.generate-starter-sheet',
			blockLoading: '.empty-page-loader',
			selectedDate: '.js-selectedDate',
			forceRunName: '.js-runNameForced'
		},

		events: {
			'click .js-cancel-popup': 'hide',
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-confirm': 'onConfirm',
			'change @ui.generateStarterSheet': 'onChangeGenerateStarterSheet',
			'change @ui.forceRunName': 'onChangeForceRunName'
		},

		regions: {
			selectRespUser: '.js-select-respUser-region',

			selectRunPattern: '.js-runName-pattern',
			selectRunNameDepartment: '.js-select-runName-department',
			selectRunNameGroup: '.js-select-runName-runGroup',
			selectRunNameSequenceFormat: '.js-select-runName-sequenceFormat',
			selectRunTemplate: '.js-select-run-template-region'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		fieldsToValidate: [
			{name: 'name', type: 'required'},
			{name: 'selectedDate', type: 'required'}
		],

		currentMode: 'fromTemplate',
		generateStarterSheet: false,


		initialize: function (options) {
			this.model = options.runModel;
			this.model.set('name', '');
			this.model.set('refRespUser', '');
			this.model.set('suffix', '');
			this.currentMode = 'fromTemplate';
			this.model.set('generateStarterSheet', false);
			this.nameForced = false;
			if (!this.model.get('pattern') || this.model.get('pattern') === '') {
				this.model.set('pattern', '');
			}
		},

		serializeData: function () {
			this.model.set('numberOfRun', 1);
			this.model.set('nameForced', false);
			this.model.set('selectedDate', Number(moment().format('x')));
			this.model.set('currentDate', moment().format('DD-MM-YY'));
			this.model.set('generateStarterSheet', false);
			return this.model.toJSON();
		},

		onChangeForceRunName: function (event) {
			this.nameForced = event.target.checked;
			this.model.set('nameForced', this.nameForced);
			if (this.nameForced) {
				this.model.set('numberOfRun', 1);
				$('.js-number-of-run-region').css('display', 'none');
			} else {
				$('.js-number-of-run-region').css('display', 'block');
			}
		},

		onDepartmentChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.model.set(fieldName, model.get('code'));
			} else {
				this.model.set(fieldName, null);
			}
		},

		onRunGroupChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.model.set(fieldName, model.get('code'));
			} else {
				this.model.set(fieldName, null);
			}
		},

		onRunSequenceFormatChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.model.set(fieldName, model.get('code'));
			} else {
				this.model.set(fieldName, null);
			}
		},

		onPatternChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.model.set(fieldName, model.get('code'));
			} else {
				this.model.set(fieldName, null);
			}
		},

		showSelectRunTemplateView: function () {
			if (this.selectRunTemplateView) {
				this.selectRunTemplateView.destroy();
			}
			this.selectRunTemplateView = new AutocompleteView(
				this._getAutocompleteOptionObject(PrepRunTemplates.getAutocompleteParam({
					modelProperty: 'refPrepRunTemplate',
					callBackOnChange: this.onTemplateChange
				}))
			);
			this.getRegion('selectRunTemplate').show(this.selectRunTemplateView);
			this.triggerMethod('enable:cancel:confirm');
		},

		showPatternDropDown: function () {
			if (this.selectPatternView) {
				this.selectPatternView.destroy();
			}
			this.selectPatternView = new AutocompleteView(
				this._getAutocompleteOptionObject(
					UserCodeLists.getAutocompleteParam({
						modelProperty: 'pattern',
						codeListCode: 'PATTERN_PREP',
						callBackOnChange: this.onPatternChange
					})));
			this.getRegion('selectRunPattern').show(this.selectPatternView);
		},

		showDepartmentDropDown: function () {
			if (this.selectDepartmentView) {
				this.selectDepartmentView.destroy();
			}
			this.selectDepartmentView = new AutocompleteView(
				this._getAutocompleteOptionObject(
					UserCodeLists.getAutocompleteParam({
						modelProperty: 'department',
						codeListCode: 'DEPT',
						callBackOnChange: this.onDepartmentChange
					})));
			this.getRegion('selectRunNameDepartment').show(this.selectDepartmentView);
		},

		showRunGroupDropDown: function () {
			if (this.selectRunGroupView) {
				this.selectRunGroupView.destroy();
			}
			this.selectRunGroupView = new AutocompleteView(
				this._getAutocompleteOptionObject(
					UserCodeLists.getAutocompleteParam({
						modelProperty: 'group',
						codeListCode: 'RUNGROUP_PREP',
						callBackOnChange: this.onRunGroupChange
					})));
			this.getRegion('selectRunNameGroup').show(this.selectRunGroupView);
		},

		showSequenceFormatDropDown: function () {
			if (this.selectRunSequenceFormatView) {
				this.selectRunSequenceFormatView.destroy();
			}
			this.selectRunSequenceFormatView = new AutocompleteView(
				this._getAutocompleteOptionObject(
					UserCodeLists.getAutocompleteParam({
						modelProperty: 'sequenceFormat',
						codeListCode: 'SEQUENCE',
						callBackOnChange: this.onRunSequenceFormatChange
					})));
			this.getRegion('selectRunNameSequenceFormat').show(this.selectRunSequenceFormatView);
		},

		onChangeGenerateStarterSheet: function (event) {
			this.model.set('generateStarterSheet', event.target.checked);
		},

		onShow: function () {
			$.material.init();

			$('input.js-datePicker[data-field-name="selectedDate"]').val(this.model.get('currentDate'));

			this.showPatternDropDown();
			this.showDepartmentDropDown();
			this.showRunGroupDropDown();
			this.showSequenceFormatDropDown();
			this.getRegion('selectRunTemplate').options.allowMissingEl = true;
			this.showSelectRunTemplateView();
			this.triggerMethod('enable:cancel:confirm');
		},

		onRender: function () {
			this.initDatePicker(this.ui.selectedDate, 'selectedDate');
			this.showRefRespUserDropDown();
			this.listenTo(this, 'date:picker:change', _.bind(this.onChangeDt, this));
		},

		showRefRespUserDropDown: function () {
			if (this.respUserView) {
				this.respUserView.destroy();
			}
			this.respUserView = new AutocompleteView(
				this._getAutocompleteOptionObject(Userpids.getAutocompleteParam({
					modelProperty: 'refRespUser',
					valueProperty: 'nickName',
					queryProperty: 'nickName',
					valueKey: 'nickName',
					callBackOnChange: this.onRespUserChange
				}))
			);
			this.getRegion('selectRespUser').show(this.respUserView);
		},

		onRespUserChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.onChange(fieldName, model ? model : null);
		},

		onChangeDt: function (fieldName) {
			var tempDt = $('input.js-datePicker[data-field-name=' + fieldName + ']').val();
			var displayDt = moment(tempDt, 'DD-MM-YYYY').format('DD-MM-YY');
			$('input.js-datePicker[data-field-name=' + fieldName + ']').val(displayDt);
		},

		onTemplateChange: function (fieldName, model) {
			this.model.set('refPrepRunTemplate', model);

			this.model.set('generateStarterSheet', false);
			if (model) {

				if (model.get('pattern') && model.get('pattern') !== '') {
					this.model.set('pattern', model.get('pattern'));
					this.showPatternDropDown();
				}
				if (model.get('department') && model.get('department') !== '') {
					this.model.set('department', model.get('department'));
					this.showDepartmentDropDown();
				}
				if (model.get('group') && model.get('group') !== '') {
					this.model.set('group', model.get('group'));
					this.showRunGroupDropDown();
				}
				if (model.get('sequenceFormat') && model.get('sequenceFormat') !== '') {
					this.model.set('sequenceFormat', model.get('sequenceFormat'));
					this.showSequenceFormatDropDown();
				}
				if (model.get('suffix') && model.get('suffix') !== '') {
					this.model.set('suffix', model.get('suffix'));
					$('.js-runName-suffix').find('input').val(model.get('suffix'));
				}
				if (model.get('pattern') && model.get('pattern') !== '') {
					this.model.set('pattern', model.get('pattern'));
					$('.js-runName-pattern').find('input').val(model.get('pattern'));
				}
				this.model.set('generateStarterSheet', model.get('printRunSheet'));
			}
			this.ui.generateStarterSheet.attr('checked', this.model.get('generateStarterSheet'));

			this.ui.selectedDate.removeClass('invalid');
			// this.ui.selectedDateRegion.closest('div[data-field-name=selectedDate]').removeClass('has-error');
			this.ui.numberOfRun.removeClass('invalid');
			this.ui.numberOfRunRegion.closest('div[data-field-name=numberOfRun]').removeClass('has-error');

			this.model.set('numberOfRun', 1);
		},

		onConfirm: function () {
			var createRunFromTemplate = App.request('createPrepRunFromTemplate:model');
			createRunFromTemplate.set('refPrepRunTemplate', (this.model.get('refPrepRunTemplate') && this.model.get('refPrepRunTemplate').get('secId') ? this.model.get('refPrepRunTemplate').get('secId') : ''));
			createRunFromTemplate.set('suffix', (this.model.get('suffix') ? this.model.get('suffix') : ''));
			createRunFromTemplate.set('nameForced', (this.model.get('nameForced') ? this.model.get('nameForced') : false));
			createRunFromTemplate.set('name', (this.model.get('name') ? this.model.get('name') : ''));
			createRunFromTemplate.set('pattern', (this.model.get('pattern') ? this.model.get('pattern') : ''));
			createRunFromTemplate.set('department', (this.model.get('department') ? this.model.get('department') : ''));
			createRunFromTemplate.set('group', (this.model.get('group') ? this.model.get('group') : ''));
			createRunFromTemplate.set('sequenceFormat', (this.model.get('sequenceFormat') ? this.model.get('sequenceFormat') : ''));
			createRunFromTemplate.set('respUserSecId', (this.model.get('refRespUser') && this.model.get('refRespUser').get('secId') ?
				this.model.get('refRespUser').get('secId') : ''));

			var needToCheckNumberOfRuns = true;
			if (this.model.get('nameForced')) {
				needToCheckNumberOfRuns = false;
				createRunFromTemplate.set('numberOfRun', this.model.get('numberOfRun'));
			}

			if (!this.validate([{name: 'refPrepRunTemplate', type: 'required'}])) {
				// break;
				return;
			}

			if (!this.validate([{name: 'selectedDate', type: 'required'}]) ||
				!this.validate([{name: 'selectedDate', type: 'datePicker'}])) {
				return;
			}
			createRunFromTemplate.set('selectedDate', this.model.get('selectedDate'));


			if (needToCheckNumberOfRuns) {
				if (!this.validate([{name: 'numberOfRun', type: 'required'}])) {
					// break;
					return;
				}
				var numberOfRun = parseInt(this.model.get('numberOfRun'), 10);
				var maxNumberOfRun = parseInt(this.$el.find(this.ui.numberOfRun).attr('max'), 10);
				if (numberOfRun > maxNumberOfRun) {
					// break;
					return;
				}
				createRunFromTemplate.set('numberOfRun', numberOfRun);
			} else {
				createRunFromTemplate.set('numberOfRun', 1);
			}

			this.ui.blockLoading.show();

			createRunFromTemplate.save().done(
				_.bind(function (result) {
					if (result.length === 1) {
						var step = result[0].wellCount <= 96 ? 1 : 2;
						App.navigate('runs/prep/' + result[0].secId + '/A01/'+ step + '/' + result[0].plateSize, {trigger: true});
					} else {
						App.navigate('runs/prep', {trigger: true});
					}
					if (this.model.get('generateStarterSheet')) {
						var runSecIds = result.map(function (run) {
							return run.secId;
						});
						PrepRuns.generateRunSheet(runSecIds);
					}
					this.hide();
				}, this)
			).fail(
				_.bind(function (response) {
					if (response.status === 409) {
						this.ui.selectedDate.addClass('invalid');
					}
					this.ui.blockLoading.hide();
				}, this)
			);
		},

		onSave: function (model) {
			var modelJSON = model.toJSON();
			this.model.set('wells', this.wells);
			this.options.runModel.set(modelJSON);
			this.triggerMethod('saved');
			App.trigger('run:prep:header:rerender', modelJSON);
			App.navigate('runs/prep/' + model.get('secId') + '/edit', {trigger: true});
			if (this.model.get('generateStarterSheet')) {
				PrepRuns.generateRunSheet(model.get('secId'));
			}
			this.hide();
		},

		hide: function () {
			this.triggerMethod('hide');
			this.box.modal('hide');
			this.destroy();
			// if (App.getCurrentRoute() === 'runs/prep/new') {
			// 	App.navigate('runs/prep', {trigger: (this.currentMode !== 'fromCyclerExport')});
			// }
		},

		onSaveError: function (model, response) {
			if (response.status === 409) {
				this.onDuplicateCode();
				this.triggerMethod('hide:label');
			}
		},

		onDuplicateCode: function () {
			this.ui.endUserIdInput.popover('show');
			this.ui.endUserIdInput.addClass('invalid');
			this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
		},

		onFocusOut: function () {
			var $target,
				datepicker;

			setTimeout(_.bind(function () {
				$target = $(document.activeElement);
				datepicker = $target.parents('.datepicker-element').length > 0 ||
					$target.parents('.ui-datepicker').length > 0 ||
					$target.hasClass('ui-datepicker') ||
					$target.get(0) === this.$el.get(0) ||
					$target.hasClass('popover-shown');

				if (!datepicker) this.trigger('close:popover');
			}, this), 0);
		}
	});
});
