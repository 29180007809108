define('linkPopupView',[
	'module',
	'marionette',
	'underscore',
	'jquery'
], function (
	module,
	Marionette,
	_,
	$
) {
	'use strict';

	module.exports = Marionette.ItemView.extend({
		template: _.template('<%= code %>'),

		className: 'js-click clickable openNewTab',

		events: {
			'click': 'onClick'
		},

		tagName: 'span',

		attributes: function () {
			return {
				'data-secId': this.options.secId,
				title: this.options.name
			};
		},

		serializeData: function () {
			return {code: this.options.code};
		},

		onClick: function () {
			$('.js-global-loader').show();
			require([
				'dynamicController'
			], _.bind(function (DynamicController) {
				var model = this.options.entity.getModel({secId: this.options.secId});
				var useDynamic = false;
				model.fetch().done(_.bind(function () {
					if(model.getEntity().getName() === 'sample') {
						require(['samplesController'], function (Controller) {
							DynamicController.showDetails(model, Controller.showDetails);
						});
					} else {
						useDynamic = true;
						DynamicController.showDetails(model, this.options.useCreateEditView);
					}
				}, this)).always(function () {
					if(useDynamic) {
						$('.js-global-loader').hide();
					}
				});
			}, this));
		}
	});
});
