define('kitLotsPcrController',[
    'module',
    'underscore',
    'backbone',
    'kitLotsPcrView',
    'kitLotsPcrValidationView',
    'entities/caccounts/kitlotpcr',
    'qcChartPopup',
    'tabsCollection',
    'tabsCollectionPopup',
    'jquery',
    'qcListView'
], function (
    module,
    _,
    Backbone,
    View,
    EditView,
    KitLotPcrs,
    QCChartPopup,
    TabsCollection,
    TabsCollectionPopup,
    $,
    QcListView
) {
    'use strict';

    module.exports = {
        showDetails: function (model, callBackRefresh, options) {
            if (!(model instanceof Backbone.Model) && typeof model === 'object') {
                var param = model;
                model = param.model;
                callBackRefresh = param.callBackRefresh;
                options = param.options;
            }
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            model.get('pcrKit').fetch().done(function () {
                if (!options) {
                    options = {model: model};
                } else {
                    options = _.defaults({model: model}, options);
                }
                var createEditView = new View(options);
                createEditView.show({
                    title: _.i18n(KitLotPcrs.getName() + '.new'),
                    className: 'baseTableEditAddPopup kitLotPcrsEditAddPopup'
                }, _.bind(function () {
                    if (callBackRefresh) {
                        callBackRefresh();
                    }
                }, callBackRefresh ? callBackRefresh.context : undefined));
            });
        },
        editDetails: function (model, callBackRefresh, options) {
            if (!(model instanceof Backbone.Model) && typeof model === 'object') {
                var param = model;
                model = param.model;
                callBackRefresh = param.callBackRefresh;
                options = param.options;
            }
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            if (!options) {
                options = {model: model};
            } else {
                options = _.defaults({model: model}, options);
            }
            var createEditView = new EditView(options);
            createEditView.show({
                title: _.i18n(KitLotPcrs.getName() + '.new'),
                className: 'baseTableEditAddPopup kitLotPcrsEditAddPopup'
            }, _.bind(function () {
                if (callBackRefresh) {
                    callBackRefresh();
                }
            }, callBackRefresh ? callBackRefresh.context : undefined));
        },
        showGraph: function (model, callBackRefresh, options) {
            if (!(model instanceof Backbone.Model) && typeof model === 'object') {
                var param = model;
                model = param.model;
                callBackRefresh = param.callBackRefresh;
                options = param.options;
            }
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            model.get('pcrKit').fetch().done(function () {
                if (!options) {
                    options = {model: model};
                } else {
                    options = _.defaults({model: model}, options);
                }
                var createEditView = new View(options);
                createEditView.show({
                    title: _.i18n(KitLotPcrs.getName() + '.new'),
                    className: 'baseTableEditAddPopup kitLotPcrsEditAddPopup'
                }, _.bind(function () {
                    if (callBackRefresh) {
                        callBackRefresh();
                    }
                }, callBackRefresh ? callBackRefresh.context : undefined));
            });
        },
        showQCFromWell: function (model) {
            $('.js-global-loader').show();
            var defers = [];

            var promiseQCGraph = $.Deferred();
            defers.push(promiseQCGraph);
            model.getControlKitLot()
                .done(_.bind(function (controlKits) {
                    controlKits = _.filter(controlKits, function (controlKit) {
                        return controlKit.assayReagentTargetPcrKitLot.refMbAnaRes && controlKit.assayReagentTargetPcrKitLot.refMbAnaRes.code !== 'N';
                    });
                    var collection = new Backbone.Collection(controlKits);
                    var qcChartPopupLayoutView = new QCChartPopup({
                        wellModel: model,
                        qcTargetModel: null,
                        collection: collection,
                        settings: model.get('settings')
                    });
                    promiseQCGraph.resolve(qcChartPopupLayoutView);
                }, this));
            var promiseQCList = $.Deferred();
            defers.push(promiseQCList);
            var qcListView = new QcListView({
                model: model
            });
            promiseQCList.resolve(qcListView);

            $.when.apply($, defers).done(_.bind(function (qcChartPopupLayoutView, qcListView) {
                var collection = new Backbone.Collection();
                collection.push(new Backbone.Model({
                    content: qcChartPopupLayoutView,
                    name: _.i18n('qcChartPopup.title')
                }));
                collection.push(new Backbone.Model({
                    content: qcListView,
                    name: _.i18n('qcListChartPopup.title')
                }));
                var tabsCollection = new TabsCollection({collection: collection, className: 'qcChartPopup'});
                var tabsCollectionPopup = new TabsCollectionPopup({tabsCollection: tabsCollection});
                tabsCollectionPopup.show({
                    title: _.i18n('qcChartPopup.title'),
                    className: 'baseTableEditAddPopup qcChartPopup'
                });
            }, this)).always(function () {
                $('.js-global-loader').hide();
            });
        },
        showQCFromLot: function (model) {
            $('.js-global-loader').show();
            var defers = [];

            var promiseQCGraph = $.Deferred();
            defers.push(promiseQCGraph);
            model.getControlKitLot()
                .done(_.bind(function (controlKits) {
                    controlKits = _.filter(controlKits, function (controlKit) {
                        return controlKit.assayReagentTargetPcrKitLot.refMbAnaRes && controlKit.assayReagentTargetPcrKitLot.refMbAnaRes.code !== 'N';
                    });
                    var collection = new Backbone.Collection(controlKits);
                    var qcChartPopupLayoutView = new QCChartPopup({
                        wellModel: model,
                        qcTargetModel: null,
                        collection: collection,
                        settings: model.get('settings')
                    });
                    promiseQCGraph.resolve(qcChartPopupLayoutView);
                }, this));
            var promiseQCList = $.Deferred();
            defers.push(promiseQCList);
            var qcListView = new QcListView({
                model: model
            });
            promiseQCList.resolve(qcListView);

            $.when.apply($, defers).done(_.bind(function (qcChartPopupLayoutView, qcListView) {
                var collection = new Backbone.Collection();
                collection.push(new Backbone.Model({
                    content: qcChartPopupLayoutView,
                    name: _.i18n('qcChartPopup.title')
                }));
                collection.push(new Backbone.Model({
                    content: qcListView,
                    name: _.i18n('qcListChartPopup.title')
                }));
                var tabsCollection = new TabsCollection({collection: collection, className: 'qcChartPopup'});
                var tabsCollectionPopup = new TabsCollectionPopup({tabsCollection: tabsCollection});
                tabsCollectionPopup.show({
                    title: _.i18n('qcChartPopup.title'),
                    className: 'baseTableEditAddPopup qcChartPopup'
                });
            }, this)).always(function () {
                $('.js-global-loader').hide();
            });
        }
    };
});
