define('entities/caccounts/sampleTracing',[
	'app',
	'backbone',
	'settings',
	'entities/caccounts/userpids'
], function (
	App,
	Backbone,
	Settings
) {
	'use strict';

	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.SampleTracing = Backbone.RelationalModel.extend({
		fetch: function () {
			this.url = Settings.url('compuzz', 'v2/samples/' + this.get('secId'));
			return Backbone.Model.prototype.fetch.call(this);
		},

		defaults: {
			'creatDate': null,
			'userpid': {},
			type: undefined,
			comment: undefined
		},
		idAttribute: 'secId',
		relations: [{
			type: Backbone.HasOne,
			key: 'userpid',
			relatedModel: 'UserPid'
		}],

		getImageUid: function () {
		},

		getPDF: function () {
			return Settings.getDocUrl('SampleTracingJson.pdf');
		}
	});

	app.ns.SampleTracingCollection = Backbone.Collection.extend({
		url: function () {
			return Settings.url('compuzz', 'v2/samples');
		},
		comparator: function (model) {
			return -model.get('code');
		},
		model: app.ns.SampleTracing
	});
});
