/**
 * Created by OBL on 14/01/2016.
 */
define('adminExportStatusController',[
    'module',
    'underscore',
    'exportCreateEditLayoutView',
    'entities/caccounts/lisRecords',
    'bootbox'
], function (
    module,
    _,
    View,
    LisRecords,
    BootBox
) {
    'use strict';

    module.exports = {
        showDetails: function (model, callBackRefresh) {
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var createEditView = new View({
                model: model
            });
            createEditView.show({
                title: _.i18n(LisRecords.getName() + '.new'),
                className: 'baseTableEditAddPopup ' + LisRecords.getName() + 'EditAddPopup'
            }, _.bind(function () {
                if (callBackRefresh) {
                    callBackRefresh();
                }
            }, callBackRefresh ? callBackRefresh.context : undefined));
        },
        showResults: function (param) {
            BootBox.dialog({
                title: _.i18n('exportStatus.title'),
                message: '<pre>' + JSON.stringify(param.model.get('result'), null, 2) + '</pre>'
            });
        },
        showQuery: function (param) {
            BootBox.dialog({
                title: _.i18n('exportStatus.title'),
                message: '<pre>' + param.model.get('query') + '</pre>'
            });
        }
    };
});
