define('superAdminDocGlossariesView',[
  'module',
  'marionette',
  'underscore',
  'jquery',
  'app',
  'settings',
  'jqgridView',
  'template!superAdminDocGlossariesListTpl',
  'docGlossaryCreateEditLayoutView'
], function (
  module,
  Marionette,
  _,
  $,
  App,
  Settings,
  JqGridView,
  viewTpl,
  CreateEditLayoutView
) {
  'use strict';

  module.exports = JqGridView.extend({
    template: viewTpl,
    filtersName: 'docGlossariesListFilters',

    triggers: {},

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        deleteDocGlossary: '.delete-docGlossary-link'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({
        'click @ui.deleteDocGlossary': 'onDeleteDocGlossary'
      }, this.gridEvents);
    },

    onDeleteDocGlossary: function (event) {
      this.trigger('docglossary:delete', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    gridOptions: function (data) {
      var options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('common.name'),
            name: 'name',
            classes: 'docGlossary-link',
            formatter: this.defaultFormatter,
            search: true,
            index: 'name',
            searchoptions: {size: 10},
            width: 50
          }, {
            label: _.i18n('docglossary.tag'),
            name: 'tag',
            classes: 'docGlossary-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'tag',
            width: 50
          }, {
            label: _.i18n('common.abbreviation'),
            name: 'abbreviation',
            classes: 'docGlossary-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'abbreviation',
            width: 80
          }, {
            label: _.i18n('common.description'),
            name: 'description',
            classes: 'docGlossary-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'description',
            width: 150
          }, {
            label: ' ',
            name: 'copy',
            search: false,
            classes: 'copy-docGlossary-link copy-link',
            formatter: this.copyIconFormatter,
            width: 10
          }, {
            label: ' ',
            name: 'delete',
            search: false,
            classes: 'delete-docGlossary-link delete-link',
            formatter: this.deleteIconFormatter,
            width: 20
          }
        ],

        onSelectRow: function (rowId, bool, e) {
          if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
            if (e.type === 'click') {
              var model = App.request('docglossary:model', {secId: rowId});
              model.fetch().done(function () {
                if ($(e.target).hasClass('cell-copy')) {
                  model.set('secId', null);
                  model.set('name', 'Copy-' + model.get('name'));
                  model.set('tag', 'Copy-' + model.get('tag'));
                }
                var createEditView = new CreateEditLayoutView({
                  model: model
                });
                createEditView.show({
                  title: _.i18n('menu.superadmin.docglossary'),
                  generatePdfButton: true,
                  className: 'baseTableEditAddPopup'
                });
              });
            }
          }
        },
        sortname: 'name'
      };

      options = _.defaults(options, this.gridInitOptions(data));

      return options;
    }
  });
});
