/**
 * Created by OLD on 30/10/2015.
 */
define('pcrSetupRouter',[
	'underscore',
	'jquery',
	'require',
	'exports',
	'module',
	'marionette',
	'app',
	'wellUtils',
	'cssUtils',
	'itemPagesNameMixin'
], function (
	_,
	$,
	require,
	exports,
	module,
	Marionette,
	App,
	WellUtils,
	CssUtils,
	ItemPagesNameMixin
) {
	'use strict';

	var PcrSetupRouter = Marionette.AppRouter.extend({
		appRoutes: {
			'pcrSetup': 'showPcrSetup'
		}
	});

	var API = {
		checkContent: function () {
			var defer = $.Deferred();
			require([
				'contentShowController'
			], function (ContentShowController) {
				ContentShowController.showContent().done(function () {
					defer.resolve();
				});
			});
			return defer.promise();
		},

		showPcrSetup: function () {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyDefaultTheme();
			this.checkContent().done(function () {
				require([
					'pcrSetupController'
				], function (Controller) {
					Controller.show(
						App.regions.getRegion('main').currentView.getRegion('content'));
					App.execute('set:active:nav:item', ItemPagesNameMixin.PCR_SETUP);
				});
			});
		}

	};

	App.on('before:start', function () {
		new PcrSetupRouter({
			controller: API
		});
	});

	module.exports = PcrSetupRouter;
});
