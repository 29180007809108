define('dynamicCreateEditText',[
    'module',
    'formView',
    'template!dynamicCreateEditText',
    'underscore'
], function (
    module,
    FormView,
    tpl,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: tpl,

        initialize: function () {
            this.listenTo(this.model, 'change:' + this.options.field, _.bind(this.render, this));
        },
        serializeData: function () {
            var templateData = {};
            templateData.field = this.options.field;
            templateData.value = this.model.get(this.options.field);
            templateData.readOnly = this.options.field.param && this.options.field.param.readOnly;
            return templateData;
        }
    });
});
