/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemText',[
	'module',
	'underscore',
	'dialogFormView'
], function (
	module,
	_,
	DialogFormView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: _.template(''),
		tagName: 'input',
		className: 'form-control js-info-input-child inputBackground',
		attributes: function () {
			var config = {
				'autocomplete': 'off',
				'data-field-name': this.options.field.field,
				'placeholder': '',
				'type': 'text',
				lenght: this.options.field.field.param && this.options.field.field.param.display && this.options.field.field.param.display.maxLength ? this.options.field.field.param.display.maxLength : '',
				'value': this.model.get(this.options.field.field),
				'disabled': this.options.field.param && this.options.field.param.readOnly
			};
			if (this.options.field.param && this.options.field.param.display && this.options.field.param.display.minLength) {
				config.minLength = this.options.field.param.display.minLength;
			}
			if (this.options.field.param && this.options.field.param.display && this.options.field.param.display.maxLength) {
				config.maxLength = this.options.field.param.display.maxLength;
			}
			return config;
		}
	});
});
