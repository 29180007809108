define('entities/caccounts/extractionMethodReagent',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'module',
    'dynamic',
    'underscore',
    'entities/caccounts/kitsextraction',
    'entities/caccounts/extractionreagents'
], function (
    App,
    Backbone,
    Settings,
    $,
    module,
    Dynamic,
    _,
    ExtractionKits,
    ExtractionReagents
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.ExtractionMethodReagent = Backbone.RelationalModel.extend({
        defaults: {
            secId: null,
            remark: '',
            reagent: null,
            refKit: null,
            volumeByTest: '',
            volumeDeath: '',
            sequence: ''
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refKit',
            relatedModel: 'KitExtraction'
        }, {
            type: Backbone.HasOne,
            key: 'reagent',
            relatedModel: 'ExtractionReagent'
        }],

        postUrl: function (extractionMethodSecId) {
            return Settings.url('compuzz', 'v2/extractionMethods/' + extractionMethodSecId + '/extractionMethodReagents');
        },

        save: function () {
            this.url = this.postUrl(this.get('refExtractionMethod').secId);
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('ExtractionMethodReagentJson.pdf');
        },
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.ExtractionMethodReagentCollection = Backbone.Collection.extend({
        url: function (extractionMethodSecId) {
            return Settings.url('compuzz', 'v2/extractionMethods/' + extractionMethodSecId + '/extractionMethodReagents');
        },
        model: app.ns.ExtractionMethodReagent,
        comparator: 'sequence'
    });

    module.exports = _.defaults({
        getName: function () {
            return 'extractionMethodReagent';
        },
        getDynamicJson: function () {
            return 'ExtractionMethodReagentJson';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.ExtractionMethodReagent.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.ExtractionMethodReagentCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['name', 'refExtractor.code']};
        },

        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore();
            ignored.push('comment');
            return ignored;
        },

        getCreateEditLine: function (model) {
            return [
                {
                    'field': 'sequence',
                    'param': {'type': 'TEXT', 'display': {'class': 'col-w-6 p-l-5 p-r-5', 'maxLength': '3'}}
                },
                {
                    'field': 'refKit',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {'getAutocompleteParam': ExtractionKits.getAutocompleteParam},
                        'context': ExtractionKits,
                        'display': {'class': 'col-w-20 p-l-5'}
                    }
                },
                {
                    'field': 'reagent',
                    'dependsOn': {
                        'model': model,
                        'field': 'refKit'
                    },
                    'param': {
                        'type': 'REFERENCE',
                        'config': {
                            'getAutocompleteParam': model && model.get('refKit') ? _.bind(model.get('refKit').getAutocompleteParamForReagent, model.get('refKit')) : {}
                        },
                        'context': ExtractionReagents,
                        'display': {'class': 'col-w-15 p-l-5'}
                    }
                },
                {'field': 'remark', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-44 p-l-5'}}},
                {'field': 'volumeByTest', 'param': {'type': 'NUMBER', 'display': {'class': 'col-w-8 p-l-5'}}},
                {'field': 'volumeDeath', 'param': {'type': 'NUMBER', 'display': {'class': 'col-w-8 p-l-5 p-r-5'}}}
            ];
        }
    }, Dynamic);

});
