
define('template!runCreateNewPcrRunSetupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n\n    <div class="row" style="text-align: center;">\n        <i class="btn add-btn-text js-emppty-pcrrun"><span>empty pcr run</span></i>\n        <i class="btn add-btn-text js-setup-1-1"><span>setup 1:1</span></i>\n        <i class="btn add-btn-text js-import-pcr-plate-layout disabled"\n           style="background-color: #80808066 !important;"><span>import pcr plate layout</span></i>\n        <i class="btn add-btn-text js-import-reuslt"><span>import a result file</span></i>\n        <i class="btn add-btn-text js-flexible-pcr-setup disabled"\n           style="background-color: #80808066 !important;"><span>flexible pcr setup</span></i>\n        <i class="btn add-btn-text js-pcr-run-hit-picking disabled"\n           style="background-color: #80808066 !important;"><span>hit picking</span></i>\n    </div>\n\n    <div>\n        <div class="js-display-create-pcrRun"></div>\n    </div>\n</div>';

}
return __p
};});

