
define('template!prepRunCreateBeadPooling', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container" style="width: 500px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="form-group">\n                <label class="col-xs-2 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                    _.i18n('pooling.bead.sourcePosFrom') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">\n                    <input type="text" class="js-info-input" data-field-name="posFrom"\n                           style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                           data-placement="top"\n                           onkeyup="this.value = this.value.toUpperCase();" value="' +
((__t = ( posFrom )) == null ? '' : __t) +
'">\n                </div>\n                <label class="col-xs-2 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                    _.i18n('pooling.bead.sourcePosTo') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">\n                    <input type="text" class="js-info-input" data-field-name="posTo"\n                           style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                           data-placement="top"\n                           onkeyup="this.value = this.value.toUpperCase();" value="' +
((__t = ( posTo )) == null ? '' : __t) +
'">\n                </div>\n                <label class="col-xs-2 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                    _.i18n('pooling.bead.sourceDirection') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">\n                    <div class="js-source-plate-direction-region"></div>\n                </div>\n            </div>\n\n            <div class="js-select-run-container-region">\n            </div>\n            <!-- cancel/confirm row -->\n            <div class="row cancelConfirmRow" style="text-align: center;">\n                <div class="pull-right p-r-15 col-xs-12 cancelConfirmAssayAddEditDiv">\n                    <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n                    <button disabled\n                            class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

