
define('template!docUrlItemCollectionViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row col-xs-12">\n\n\t<div class="col-xs-4">\n\t\t<div class="form-control js-select-document-region " style="width:300px;"></div>\n\t</div>\n\t<div class="col-xs-7">\n        <textarea class="form-control floating-label js-description inputBackground"\n\t\t\t\t  cols="60" data-field-name="docAbstract" placeholder="" rows="1"\n\t\t\t\t  style="resize: vertical;"\n\t\t\t\t  wrap="on" disabled>' +
((__t = ( docAbstract )) == null ? '' : __t) +
'</textarea>\n\t</div>\n\t<div class="col-xs-1">\n        <span class="mdi-action-delete delete-row js-icon m-t-5"\n\t\t\t  style="cursor: pointer;vertical-align:sub;right: 25px;position: absolute;"></span>\n\t</div>\n</div>';

}
return __p
};});

