define('prepRunsShowView',[
    'module',
    'underscore',
    'jquery',
    'jqgridView',
    'wellUtils',
    'urlUtil',
    'app',
    'settings',
    'template!prepRunsShowView',
    'datePickerPopoverMixin',
    'emptyView',
    'contentShowController',
    'itemPagesNameMixin',
    'template!prepRunStatusTpl',
    'marionette'
], function (
    module,
    _,
    $,
    JqGridView,
    WellUtils,
    UrlUtil,
    App,
    Settings,
    viewTpl,
    datePickerPopoverMixin,
    EmptyView,
    ContentShowController,
    ItemPagesNameMixin,
    StatusToolTip,
    Marionette
) {
    'use strict';


    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'prepRunsFilters',
        keepCurrentPage: false,
        className: 'dynamic jq-grid-table-page',
        regions: {
            runsActions: '.js-action-buttons-region'
        },

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                uploadRuns: '.js-upload-runs',
                deleteRun: '.delete-link-action',
                selectRow: '.select-link-action',
                gridUpperMenu: '.js-grid-upper-menu',
                selectAllRows: '.js-all-rows-selector',
                tableHeader: 'table.ui-jqgrid-htable',
                attachment: '.attachment-link-action',
                archive: '.cell-archive',
                unArchive: '.cell-unArchive',
                archiveByList: '.js-archive-by-list',
                unArchiveByList: '.js-unArchive-by-list',
                menuButton: '#menuAddListButton',
                menuButtonImport: '#menuImportListButton',
                menuAddList: '#menuAddList',
                menuAddListImport: '#menuAddListImport',
                addEmptyRun: '.js-addPrepRun-empty',
                menuEntry: '.menuEntry',
                menuEntryImport: '.menuEntryImport',
                displayArchived: '.display-archived'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({
                'mouseenter @ui.tableHeader': 'showGridUpperMenu',
                'mouseleave @ui.tableHeader': 'HideGridUpperMenu',
                'mouseenter @ui.gridUpperMenu': 'showGridUpperMenu',
                'mouseleave @ui.gridUpperMenu': 'HideGridUpperMenu',
                'click @ui.selectAllRows': 'onCheckAllRows',
                'click @ui.selectRow': 'onCheckOneRow',
                'click @ui.deleteRun': 'onDeleteRun',
                'click @ui.attachment': 'onAttachment',
                'click @ui.archive': 'onArchive',
                'click @ui.unArchive': 'onUnArchive',
                'click @ui.archiveByList': 'onArchiveByList',
                'click @ui.unArchiveByList': 'onUnArchiveByList',
                'click .js-back-button': 'onHistoryBack',
                'click @ui.menuButton': 'showMenu',
                'click @ui.menuButtonImport': 'showMenuImport',
                'mouseleave @ui.menuAddList': 'hideMenu',
                'mouseleave @ui.menuAddListImport': 'hideMenuImport',
                'click @ui.menuEntry': 'hideMenu',
                'click @ui.menuEntryImport': 'hideMenuImport',

                'click .js-createRun-fromFile-plateLayout': 'showViewNewRunFromFilePlateLayout',
                'click .js-createRun-fromFile-sampleList': 'showViewNewRunFromFileSampleList',
                'click @ui.addEmptyRun': 'onAddEmptyRun',
                'change @ui.displayArchived': 'onChangeDisplayArchived'
            }, this.gridEvents);
        },

        refreshGrid: function () {
            this.ui.jqGrid.trigger('reloadGrid');
        },

        showMenu: function () {
            this.showBaseTableAddMenu($(this.ui.menuAddList));
        },

        hideMenu: function () {
            this.hideBaseTableAddMenu($(this.ui.menuAddList));
        },

        showMenuImport: function () {
            this.showBaseTableAddMenu($(this.ui.menuAddListImport));
        },

        hideMenuImport: function () {
            this.hideBaseTableAddMenu($(this.ui.menuAddListImport));
        },

        onAddEmptyRun: function () {
            App.request('setting:get-setting-by-code', 'DEF_DEPT')
                .done(_.bind(function (defDept) {
                    require([
                        'prepRunsShowController'
                    ], function (ShowController) {
                        ContentShowController.showContent().done(function () {
                            App.startSubModule('prepRunsMain');
                            App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
                            ShowController.onCreateEmptyPrepRun(defDept);
                        });
                    });
                }, this));
        },

        showViewNewRunFromFilePlateLayout: function () {
            require([
                'prepRunsShowController'
            ], function (ShowController) {
                ContentShowController.showContent().done(function () {
                    App.startSubModule('runsMain');
                    App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
                    ShowController.showViewNewRunFromFilePlateLayout();
                });
            });
        },

        showViewNewRunFromFileSampleList: function () {
            require([
                'prepRunsShowController'
            ], function (ShowController) {
                ContentShowController.showContent().done(function () {
                    App.startSubModule('runsMain');
                    App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
                    ShowController.showViewNewRunFromFileSampleList();
                });
            });
        },

        initialize: function () {
            this.listenTo(this, 'run:listView:refresh', _.bind(this.refreshGrid, this));
        },

        onShow: function () {
            $.material.init();
            this.hideMenu();
            this.hideMenuImport();
        },

        onChangeDisplayArchived: function (event) {
            $('input[name="displayArchived"]').val(event.currentTarget.value);
            $('input[name="displayArchived"]').change();
        },

        serializeData: function () {
            this.settings = this.options.settings;
            this.checkedRowsList = [];
            this.selectedRuns = Settings.get('selectedRuns') || [];
            this.archiveByListCall = 'run:prep:archive:bylist';
            this.unArchiveByListCall = 'run:prep:unarchive:bylist';

            this.icons = WellUtils.getPrepWellAssaysListViewIcons();

            var templateData = {
                'type': 'prepruns',
                displayArchived: this.options.displayArchived
            };
            return templateData;
        },

        onHistoryBack: function () {
            this.jqGridHistoryBack();
        },

        onDeleteRun: function (event) {
            var codeName = $(event.currentTarget).find('span').attr('data-row-codeName');
            var secId = $(event.currentTarget).find('span').attr('data-row-id');
            this.trigger('run:prep:delete', secId, codeName);
            this.resetCheckedRowList();
        },

        onAttachment: function (event) {
            var target = $(event.currentTarget);
            var model = this.options.entity.getModel({secId: target.find('span').attr('data-row-id')});
            model.fetch().done(_.bind(function () {
                model.getEntity().openAttachment(model);
            }, this));
        },

        onArchive: function (event) {
            var rowId = $(event.currentTarget).attr('data-row-id');
            this.trigger('run:prep:archive', rowId);
            this.resetCheckedRowList();
        },

        onUnArchive: function (event) {
            var rowId = $(event.currentTarget).attr('data-row-id');
            this.trigger('run:prep:unarchive', rowId);
            this.resetCheckedRowList();
        },

        onArchiveByList: function () {
            if (this.checkedRowsList && this.checkedRowsList.length > 0) {
                var checkedRowsList = this.checkedRowsList;
                this.resetCheckedRowList();
                this.jqGridArchiveRestoreByList(checkedRowsList, this.archiveByListCall, true)
                    .done(_.bind(function () {
                        this.updatePagination('reloadGrid');

                    }, this));
            }
        },

        onUnArchiveByList: function () {
            if (this.checkedRowsList && this.checkedRowsList.length > 0) {
                var checkedRowsList = this.checkedRowsList;
                this.resetCheckedRowList();
                this.jqGridArchiveRestoreByList(checkedRowsList, this.unArchiveByListCall, false)
                    .done(_.bind(function () {
                        this.updatePagination('reloadGrid');
                    }, this));
            }
        },

        resetCheckedRowList: function () {
            this.checkedRowsList = [];
            this.resetAllRowSelector();
        },

        showGridUpperMenu: function () {
            this.ui.gridUpperMenu.removeClass('hidden');
        },

        HideGridUpperMenu: function () {
            this.ui.gridUpperMenu.addClass('hidden');
        },

        onCheckAllRows: function (e) {
            this.checkedRowsList = this.checkAllRows(e);
        },

        onCheckOneRow: function (e) {
            this.checkedRowsList = this.checkOneRow(e, this.checkedRowsList);
        },

        gridOptions: function (data) {
            var that = this,
                defaultsOptions = this.gridInitOptions(data),
                options = {
                    colModel: [{
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: '',
                        name: 'wellCount',
                        hidden: true
                    }, {
                        label: '',
                        name: 'displayArchived',
                        index: 'displayArchived',
                        search: true,
                        hidden: true
                    }, {
                        label: ' ',
                        name: 'select',
                        sortable: false,
                        search: false,
                        searchoptions: {clearSearch: false},
                        classes: 'select-link-action ignore-row-click css-no-sideBorders',
                        formatter: this.rowSelectorFormatter,
                        fixed: true,
                        width: 25
                    }, {
                        label: ' ',
                        name: 'delete',
                        sortable: false,
                        search: false,
                        searchoptions: {clearSearch: false},
                        classes: 'delete-link-action ignore-row-click css-no-sideBorders',
                        formatter: this.deleteActionIconFormatter,
                        fixed: true,
                        width: 25
                    }, {
                        label: ' ',
                        name: 'archiveRestore',
                        sortable: false,
                        search: false,
                        searchoptions: {clearSearch: false},
                        classes: 'archiveRestore-link-action ignore-row-click css-no-sideBorders',
                        formatter: this.archiveRestoreActionIconFormatter,
                        fixed: true,
                        width: 25
                    }, {
                        label: ' ',
                        name: 'attachment',
                        sortable: false,
                        search: false,
                        searchoptions: {clearSearch: false},
                        classes: 'attachment-link-action ignore-row-click css-no-sideBorders',
                        formatter: this.attachmentActionIconFormatter,
                        fixed: true,
                        width: 25
                    }, {
                        label: _.i18n('runList.period'),
                        name: 'periodFormatted',
                        formatter: this.defaultFormatter,
                        index: 'periodFormatted',
                        search: true,
                        searchoptions: {clearSearch: false},
                        fixed: true,
                        width: 70
                    }, {
                        label: _.i18n('runList.group'),
                        name: 'group',
                        formatter: this.defaultFormatter,
                        index: 'group',
                        search: true,
                        searchoptions: {clearSearch: false},
                        fixed: true,
                        width: 35
                    }, {
                        label: _.i18n('user'),
                        name: 'refRespUser',
                        formatter: this.userFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'refRespUser.nickName',
                        sortable: false,
                        fixed: true,
                        width: 40
                    }, {
                        label: _.i18n('plateSize'),
                        name: 'plateSize',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'plateSize',
                        fixed: true,
                        sortable: false,
                        width: 35
                    }, {
                        label: _.i18n('runList.name'),
                        name: 'code',
                        classes: 'order-link css-run-listView-accentuation',
                        formatter: this.runNameFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'code',
                        fixed: true,
                        width: 160
                    }, {
                        label: _.i18n('runList.status'),
                        name: 'status',
                        classes: 'order-link',
                        formatter: _.bind(this.statusFormatter, this),
                        index: 'status',
                        search: true,
                        searchoptions: {clearSearch: false},
                        fixed: true,
                        width: 60
                    }, {
                        label: this.icons.waiting,
                        name: 'assayWaiting',
                        classes: 'order-link',
                        sortable: false,
                        search: true,
                        searchoptions: {clearSearch: false},
                        formatter: _.bind(this.assayWaitingFormatter, this),
                        index: 'assayWaiting',
                        fixed: true,
                        width: 150
                    }, {
                        label: this.icons.running,
                        name: 'assayRunning',
                        classes: 'order-link',
                        sortable: false,
                        search: true,
                        searchoptions: {clearSearch: false},
                        formatter: _.bind(this.assayRunningFormatter, this),
                        index: 'assayRunning',
                        fixed: true,
                        width: 150
                    }, {
                        label: this.icons.done,
                        name: 'assayDone',
                        classes: 'order-link',
                        sortable: false,
                        search: true,
                        searchoptions: {clearSearch: false},
                        formatter: _.bind(this.assayDoneFormatter, this),
                        index: 'assayDone',
                        fixed: true,
                        width: 150
                    }, {
                        label: _.i18n('run.assays'),
                        name: 'assays',
                        classes: 'order-link',
                        sortable: false,
                        search: true,
                        searchoptions: {clearSearch: false},
                        formatter: _.bind(this.codeNameListFormatter, this),
                        index: 'assay',
                        fixed: true,
                        width: 100
                    }, {
                        label: _.i18n('run.pcrruns'),
                        name: 'pcrRuns',
                        formatter: this.codeNameListFormatter,
                        index: 'pcrRunName',
                        fixed: true,
                        sortable: false,
                        width: 150
                    }, {
                        label: _.i18n('runList.dwPoolPlate'),
                        // label: '<span class="mdi mdi-google-circles-group" style="font-size: 19px;"></span>',
                        name: 'toBePooled',
                        formatter: this.booleanFormatterNoCheckBox,
                        search: true,
                        fixed: true,
                        width: 35
                    }, {
                        label: _.i18n('runList.extrmethod'),
                        name: 'extractionDetails.refExtractionMethod.code',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'extractionMethodCode',
                        fixed: true,
                        sortable: false,
                        width: 60
                    }, {
                        label: _.i18n('runList.extractor'),
                        name: 'extractionDetails.refLmbExtractor.code',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'lmbExtractorCode',
                        fixed: true,
                        sortable: false,
                        width: 60
                    }, {
                        label: _.i18n('runList.EPlateId'),
                        name: 'plateIdEL',
                        formatter: this.plateIdFormatter,
                        index: 'plateIdEL',
                        search: true,
                        searchoptions: {clearSearch: false},
                        fixed: true,
                        width: 90
                    }, {
                        label: _.i18n('common.comment'),
                        name: 'comment',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'comment'
                    }
                    ],

                    serializeGridData: function (postData) {
                        if (postData.status === that.selectDefault) {
                            delete postData.status;
                        }

                        if (postData.modDate) {
                            postData.dateFrom = datePickerPopoverMixin.parseDateFrom(postData.modDate);
                            postData.dateTo = datePickerPopoverMixin.parseDateTo(postData.modDate);
                        } else if (!that.sorting) {
                            delete postData.dateFrom;
                            delete postData.dateTo;
                        }
                        that.sorting = false;
                        defaultsOptions.serializeGridData.apply(this, arguments);

                        return postData;
                    },

                    gridComplete: function () {
                        var purchaseOrderId = UrlUtil.parseQueryString().purchaseOrderId;
                        if (purchaseOrderId) {
                            UrlUtil.clearQueryString();
                            that.preselectFirstRow();
                        }
                        defaultsOptions.gridComplete.apply(this, arguments);

                        $('#jq-grid-list').jqGrid('setLabel', 'hyphen', '-', 'prepRunList-hyphen');

                        var $waiting = that.$('.assayWaitingCell');
                        _.each($waiting, _.bind(function (waiting) {
                            var $waiting = $(waiting);
                            $waiting.parent().addClass('css-prepRun-listView-assayWaiting');
                        }, this));

                        var $running = that.$('.assayRunningCell');
                        _.each($running, _.bind(function (running) {
                            var $running = $(running);
                            $running.parent().addClass('css-prepRun-listView-assayRunning');
                        }, this));

                        var $done = that.$('.assayDoneCell');
                        _.each($done, _.bind(function (done) {
                            var $done = $(done);
                            $done.parent().addClass('css-prepRun-listView-assayDone');
                        }, this));

                        that.checkRunsSelection();
                        var popOver = $('.popover-status');
                        if (popOver.length > 0) {
                            popOver.popover({
                                trigger: 'manual',
                                placement: 'bottom',
                                html: 'true',
                                delay: {
                                    show: '300',
                                    hide: '10'
                                },
                                container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
                            }).on('mouseenter', function () {
                                var _this = this;
                                $(this).popover('show');
                                $('.popover').on('mouseleave', function () {
                                    $(_this).popover('hide');
                                });
                            }).on('mouseleave', function () {
                                var _this = this;
                                setTimeout(function () {
                                    if (!$('.popover:hover').length) {
                                        $(_this).popover('hide');
                                    }
                                }, 10);
                            }).data('bs.popover').tip().addClass('run-prep-popover');
                        }
                    },

                    onSelectRow: function (rowId, bool, e) {
                        var currentRowData = that.ui.jqGrid.getRowData(rowId);
                        var prepRunList = that.ui.jqGrid.getRowData();

                        if (that.checkedRowsList && that.checkedRowsList.length > 0) {
                            prepRunList = that.checkedRowsList;
                        }
                        that.triggerMethod('run:prep:selectedList', prepRunList);

                        if (!$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click') {
                            App.regions.getRegion('main').currentView.getRegion('content').show(new EmptyView());
                            var plateSize = $(currentRowData.plateSize).text();
                            App.navigate('runs/prep/' + rowId + '/A01/1/' + plateSize, {trigger: true});
                        }
                    }
                };

            options.sortorder = 'DESC';

            options = _.defaults(options, defaultsOptions);

            return options;
        },

        booleanFormatterNoCheckBox: function (cellvalue, options) {
            if (cellvalue) {
                return '<span class="mdi mdi-check-bold js-icon" data-row-id="' + options.rowId + '"></span>';
            } else {
                return '';
            }
        },

        runNameFormatter: function (cellValue, options) {
            return '<span class="cell-default" style="font-weight: bold; " data-row-id="' +
                options.rowId + '">' + _.escape(cellValue) + '</span>';
        },

        statusFormatter: function (cellValue, options) {
            var status = cellValue ? parseInt(cellValue, 10) : ' ';
            var iconColor = [];
            var statusIcon = '';
            var content = Marionette.Renderer.render(StatusToolTip, {elements: this.options.codeList, status: status});
            statusIcon += '<div class="cell-default popover-status" style="display: flex;" data-row-id="' + options.rowId + '" data-content="' + _.escape(content) + '">';
            statusIcon += '<div class="jqgrid-statusNb">' + status + '</div>';

            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (i === status || i < status || status === 5) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-statusIcon' + i + ' ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            return statusIcon;
        },

        plateIdFormatter: function (cellValue, options) {
            var idMaxLength = 5;
            var value = '', sub = 0;

            if (cellValue) {
                if (cellValue.length > idMaxLength) {
                    sub = cellValue.length - idMaxLength;
                    value = '...';
                }
                value += cellValue.substr(sub);
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + value + '</span>';
        },

        pagination: function () {
            return {
                first: 0,
                rowCount: 0,
                pageSize: 18,
                currentPage: 1
            };
        },

        checkRunsSelection: function () {
            var $list = this.ui.jqGrid.find('tr');
            $list.each(_.bind(function (index, item) {
                var $item = $(item);
                if (_.some(this.selectedRuns, {rowid: $item.attr('id')})) {
                    $item.addClass('selected');
                } else {
                    $item.removeClass('selected');
                }
            }, this));
            this.onSelectedRunsChanged();
        },

        onSelectedRunsChanged: function () {
            if (this.selectedRuns.length > 0) {
            }
            Settings.set('selectedRuns', this.selectedRuns);
        },

        onGridFilterChange: function () {

            var that = this,
                filters = {},
                value;

            _.each(this.colModel, function (column) {
                if (column.search) {
                    if (column.stype === 'select') {
                        value = that.$('select[name="' + column.index + '"]').val();
                        filters[column.index] = value === that.selectDefault ? '' : value;
                    } else if (column.sorttype === 'date') {
                        value = that.$('input[name="' + column.index + '"]').val();
                        if (column.index === 'modDate' && value) {
                            filters.dateFrom = datePickerPopoverMixin.parseDateFrom(value);
                            filters.dateTo = datePickerPopoverMixin.parseDateTo(value);
                        }
                    } else {
                        value = that.$('input[name="' + column.index + '"]').val();
                        filters[column.index] = value;
                    }
                }
            });

            this.filters = filters;
            this.filterChange = true;
            this.updatePagination();
        }
    });
});
