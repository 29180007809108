define('dashboardPcrThumbnailView',[
    'module',
    'marionette',
    'dialogFormView',
    'template!dashboardPcrThumbnailTpl',
    'underscore',
    'jquery',
    'app',
    'dashboardPcrThumbnailCardView',
    'bootbox',
    'autocompleteView',
    'entities/caccounts/userCodelist',
    'entities/caccounts/pcrruns'
], function (
    module,
    Marionette,
    DialogFormView,
    PcrThumbnailTpl,
    _,
    $,
    App,
    CardView,
    bootbox,
    AutocompleteView,
    UserCodeLists,
    PcrRuns
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: PcrThumbnailTpl,

        ui: {
            blockLoading: '.empty-page-loader',
            filters: '.js-tab-filters',
            selectFilter: '.js-select-filter',
            resetFilters: '.js-reset-filters'
        },

        events: {
            'click @ui.selectFilter': 'onSelectFilters',
            'click @ui.resetFilters': 'onResetFilters'
        },

        regions: {
            selectRunDepartment: '.js-department-region',
            selectRunGroup: '.js-runGroup-region'
        },

        serializeData: function () {
            var templateData = {};
            templateData.runsList = this.runsList;
            return templateData;
        },

        onShow: function () {
            $.material.init();
            this.showSelectDepartmentView();
            this.showSelectRunGroupView();
            this.showCards();
            this.filteredRuns = [];
            this.runFiltersList = [];
            this.filteredRunsCollection = [];
            this.processedRunsResult = [];
        },

        initialize: function (options) {
            this.runsList = options.runsList;
            Marionette.LayoutView.prototype.initialize.call(this, options);
        },

        onDepartmentFilterChange: function (fieldName, model) {
            this[fieldName] = null;
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this[fieldName] = model.get('code');
            }
            var filterName = 'select-department';
            this.onSelectFilters(null, filterName);
        },

        onRunGroupFilterChange: function (fieldName, model) {
            this[fieldName] = null;
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this[fieldName] = model.get('code');
            }
            var filterName = 'select-runGroup';
            this.onSelectFilters(null, filterName);
        },

        onSelectFilters: function (e, filterName) {
            var selectedRunFilter = null;
            if (e) {
                var $target = $(e.currentTarget);
                selectedRunFilter = $target.attr('name');
            } else if (filterName) {
                selectedRunFilter = filterName;
            }

            if (selectedRunFilter && selectedRunFilter === 'select-sample') { //selectedRunFilter === 'select-assay' || selectedRunFilter === 'select-ic'
                this.onSelectSearchFilters(e, selectedRunFilter);
            } else {
                this.onRunsFiltersListChange(selectedRunFilter); //updates this.runFiltersList
                this.onShowActiveFilters(this.runFiltersList);
                if (this.runFiltersList.length > 0) {
                    this.filteredRuns = this.getFilteredRunsCollection(); // updates and returns this.filteredRunsCollection
                } else {
                    this.filteredRuns = null;
                }
                this.refreshCardsDisplay();
            }
        },

        onResetFilters: function () {
            this.runFiltersList = [];
            this.filteredRuns = null;
            this.resetDropDownFilters();
            this.onShowActiveFilters(this.runFiltersList);
            this.refreshCardsDisplay();
        },

        resetDropDownFilters: function () {
            if (this.selectDepartmentView) {
                this.selectDepartmentView.destroy();
            }
            if (this.selectRunGroupView) {
                this.selectRunGroupView.destroy();
            }
            this.showSelectDepartmentView();
            this.showSelectRunGroupView();
        },

        showSelectDepartmentView: function () {
            this.selectDepartmentView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                UserCodeLists.getAutocompleteParam({
                    modelProperty: 'lisVariantAlgo',
                    codeListCode: 'DEPT',
                    callBackOnChange: this.onDepartmentFilterChange
                })));
            this.getRegion('selectRunDepartment').show(this.selectDepartmentView);
        },

        showSelectRunGroupView: function () {
            this.selectRunGroupView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                UserCodeLists.getAutocompleteParam({
                    modelProperty: 'runGroup',
                    codeListCode: 'RUNGROUP_PCR',
                    callBackOnChange: this.onRunGroupFilterChange
                })));
            this.getRegion('selectRunGroup').show(this.selectRunGroupView);
        },

        getFilteredRunsCollection: function () {
            var first = true;
            var filteredArray = [];

            _.each(this.runFiltersList, _.bind(function (runFilter) {
                var runsFromFilter = [];
                runsFromFilter = this.getRunsFromFilter(runFilter);
                if (filteredArray && filteredArray.length < 1 && first) {
                    filteredArray = runsFromFilter;
                    first = false;
                } else if (filteredArray && filteredArray.length > 0 && runsFromFilter && runsFromFilter.length > 0) {
                    filteredArray = filteredArray.filter(function (n) {
                        return runsFromFilter.indexOf(n) !== -1;
                    });
                } else if (runsFromFilter === null) {
                    filteredArray = [];
                }
            }, this));
            this.filteredRunsCollection = filteredArray;
            return this.filteredRunsCollection;
        },

        onShowActiveFilters: function (filtersName) {
            // unset all filter checkbox
            this.ui.filters.each(function (index, tab) {
                var $tab = $(tab);
                $tab.removeClass('activeFilter');
                $tab.find('input').prop('checked', false);
            });

            // set selected from list (this.runFiltersList) checkbox to active
            _.each(filtersName, _.bind(function (f) {
                this.ui.filters.each(function (index, tab) {
                    var $tab = $(tab);
                    if ($tab.hasClass('js-' + f)) {
                        $tab.addClass('activeFilter');
                        $tab.find('input').prop('checked', true);
                    }
                });
            }, this));
        },

        getRunsFromFilter: function (runFilter) {
            var filteredRuns = [];
            var errorMessage = '';
            switch (runFilter) {
                case 'select-department':
                    _.each(this.processedRunsResult, _.bind(function (run) {
                        if (run.get('department') && run.get('department') === this.department) {
                            filteredRuns.push(run);
                        }
                    }, this));
                    errorMessage = _.i18n('error.noDepartmentMatch');
                    break;
                case 'select-runGroup':
                    _.each(this.processedRunsResult, _.bind(function (run) {
                        if (run.get('group') && run.get('group') === this.runGroup) {
                            filteredRuns.push(run);
                        }
                    }, this));
                    errorMessage = _.i18n('error.noRunGroupMatch');
                    break;
                case 'select-import-result':
                    _.each(this.processedRunsResult, function (run) {
                        if (run.get('status') && run.get('status') === 0) {
                            filteredRuns.push(run);
                        }
                    });
                    errorMessage = _.i18n('error.noResultMissing');
                    break;
                case 'select-waitingFor-pcrSetup':
                    _.each(this.processedRunsResult, function (run) {
                        if (run.get('assays') && run.get('assays').length > 0) {
                            filteredRuns.push(run);
                        }
                    });
                    errorMessage = _.i18n('error.noWaitingPcrSetup');
                    break;
                case 'select-waiting-val1':
                    _.each(this.processedRunsResult, function (run) {
                        var isWaitingForVal1 = false;
                        isWaitingForVal1 = _.some(run.get('wells'), function (well) {
                            if (well.valWst >= 2 && well.valWst < 3) return true;
                        });
                        if (isWaitingForVal1) {
                            filteredRuns.push(run);
                        }
                    });
                    errorMessage = _.i18n('error.noWaitingVal1');
                    break;
                case 'select-waiting-val2':
                    _.each(this.processedRunsResult, function (run) {
                        var isWaitingForVal2 = false;
                        isWaitingForVal2 = _.some(run.get('wells'), function (well) {
                            if (well.valWst === 3.2) return true;
                        });
                        if (isWaitingForVal2) {
                            filteredRuns.push(run);
                        }
                    });
                    errorMessage = _.i18n('error.noWaitingVal1');
                    break;
                case 'select-waiting-expert':
                    _.each(this.processedRunsResult, function (run) {
                        var isWaitingForValE = false;
                        isWaitingForValE = _.some(run.get('wells'), function (well) {
                            if (well.valWst === 3.1) return true;
                        });
                        if (isWaitingForValE) {
                            filteredRuns.push(run);
                        }
                    });
                    errorMessage = _.i18n('error.noWaitingVal1');
                    break;
            }
            if (filteredRuns.length > 0) {
                return filteredRuns;
            } else {
                return null;
            }
        },

        onSelectSearch: function (result, e, selectedRunFilter) {
            this.onRunsFiltersListChange(selectedRunFilter);
            this.onSearchChange(result, selectedRunFilter);
            this.onShowActiveFilters(this.runFiltersList);
            if (this.runFiltersList.length > 0) {
                this.filteredRuns = this.getFilteredRunsCollection(); // updates and returns this.filteredRunsCollection
            } else {
                this.filteredRuns = null;
            }
            this.refreshCardsDisplay();
        },

        onSearchChange: function (result, selectedRunFilter) {
            switch (selectedRunFilter) {
                case 'select-sample': // 'select-assay' 'select-ic'
                    this.sampleSearch = result && result !== '' ? result : null;
                    break;
                default:
                    break;
            }
        },

        onSelectSearchFilters: function (e, selectedRunFilter) {
            var $target = $(e.currentTarget);
            if (!$target.hasClass('activeFilter')) {
                bootbox.prompt({
                    title: _.i18n('popup.select.search'),
                    className: 'actionPopupCss popupFitContent headerAction-bootboxPopup',
                    onEscape: true,
                    buttons: {
                        confirm: {
                            label: '<i></i>',
                            className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm btn-primary'
                        },
                        cancel: {
                            label: '<i></i>',
                            className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                        }
                    },
                    callback: _.bind(function (result) {
                        if (result) {
                            this.onSelectSearch(result, e, selectedRunFilter);
                        }
                        bootbox.hideAll();
                    }, this)
                });
            } else {
                this.onRunsFiltersListChange(selectedRunFilter); //updates this.runFiltersList
                this.onShowActiveFilters(this.runFiltersList);
                if (this.runFiltersList.length > 0) {
                    this.filteredRuns = this.getFilteredRunsCollection(); // updates and returns this.filteredRunsCollection
                } else {
                    this.filteredRuns = null;
                }
                this.refreshCardsDisplay();
            }
        },

        onRunsFiltersListChange: function (selectedRunFilter) {
            if (selectedRunFilter === 'select-department') {
                if (this.department && !this.runFiltersList.includes(selectedRunFilter)) {
                    this.runFiltersList.push(selectedRunFilter);
                } else {
                    if (this.runFiltersList.includes(selectedRunFilter)) {
                        var d = this.runFiltersList.indexOf(selectedRunFilter);
                        this.runFiltersList.splice(d, 1);
                    }
                }
            } else if (selectedRunFilter === 'select-runGroup') {
                if (this.runGroup && !this.runFiltersList.includes(selectedRunFilter)) {
                    this.runFiltersList.push(selectedRunFilter);
                } else {
                    if (this.runFiltersList.includes(selectedRunFilter)) {
                        var r = this.runFiltersList.indexOf(selectedRunFilter);
                        this.runFiltersList.splice(r, 1);
                    }
                }
            } else {
                if (this.runFiltersList.includes(selectedRunFilter)) {
                    var i = this.runFiltersList.indexOf(selectedRunFilter);
                    this.runFiltersList.splice(i, 1);
                } else {
                    this.runFiltersList.push(selectedRunFilter);
                }
            }
        },

        setOpacity: function (secId, opacity) {
            $('.run-pcr-' + secId).css('opacity', opacity);
        },

        setClickable: function (secId, isClickable) {
            if (isClickable) {
                if (!$('.run-pcr-' + secId).hasClass('clickable')) {
                    $('.run-pcr-' + secId).addClass('clickable');
                }
            } else {
                if ($('.run-pcr-' + secId).hasClass('clickable')) {
                    $('.run-pcr-' + secId).removeClass('clickable');
                }
            }
        },

        refreshCardsDisplay: function () {
            if ((this.runFiltersList && this.runFiltersList.length > 0)) {
                if (this.filteredRuns && this.filteredRuns.length > 0) {
                    _.each(this.processedRunsResult, _.bind(function (run) {
                        if (this.filteredRuns.indexOf(run) !== -1) {
                            this.setOpacity(run.get('secId'), 1);
                            this.setClickable(run.get('secId'), true);
                        } else {
                            this.setOpacity(run.get('secId'), 0.3);
                            this.setClickable(run.get('secId'), false);
                        }
                    }, this));
                } else {
                    _.each(this.processedRunsResult, _.bind(function (run) {
                        this.setOpacity(run.get('secId'), 0.3);
                        this.setClickable(run.get('secId'), false);
                    }, this));
                }
            } else {
                _.each(this.processedRunsResult, _.bind(function (run) {
                    this.setOpacity(run.get('secId'), 1);
                    this.setClickable(run.get('secId'), true);
                }, this));
            }
            if (this.filteredRuns) {
                var selection = this.getArrayToJsSon(this.filteredRuns);
                this.updatesHeaderRunsList(selection);
            }
        },

        getArrayToJsSon: function (array) {
            var result = [];
            if (array && array.length > 0) {
                for (var i = 0; i < array.length; i++) {
                    result.push(array[i].toJSON());
                }
            }
            return result;
        },

        updatesHeaderRunsList: function (runsList) {
            require([
                'runsShowController'
            ], _.bind(function (runsShowController) {
                runsShowController.onSelectedRunsList(runsList);
            }, this));
        },

        showCards: function (/*type*/) {
            this.getRunsToDisplay(this.runsList).done(_.bind(function () { // updates this.processedRunsResult

                var selection = this.getArrayToJsSon(this.processedRunsResult);
                this.updatesHeaderRunsList(selection);

                var filtersToAllow = {};
                filtersToAllow.selectImportResult = {selector: 'js-select-import-result', show: false};
                filtersToAllow.selectWaitingForPcrSetup = {selector: 'js-select-waitingFor-pcrSetup', show: false};
                filtersToAllow.selectWaitingVal1 = {selector: 'js-select-waiting-val1', show: false};
                filtersToAllow.selectWaitingVal2 = {selector: 'js-select-waiting-val2', show: false};
                filtersToAllow.selectWaitingExpert = {selector: 'js-select-waiting-expert', show: false};

                _.each(this.processedRunsResult, _.bind(function (run) {
                    var cardView = new CardView({model: run});
                    var runName = run.get('name');
                    var secId = run.get('secId');
                    $('.pcr-runs-cards').append('<div class="js-runCard-click css-runCards clickable run-pcr-' + secId + '" ' +
                        'style="" ' + 'data-secid="' + secId + '" ' +
                        'data-name="' + runName + '"></div>');
                    this.addRegion('run-pcr-' + secId, '.run-pcr-' + secId);
                    this.getRegion('run-pcr-' + secId).show(cardView);

                    if (run.get('assays') && run.get('assays').length > 0) {
                        filtersToAllow.selectWaitingForPcrSetup.show = true;
                    }
                    if (run.get('status') && run.get('status') === 0) {
                        filtersToAllow.selectImportResult.show = true;
                    }

                    var wells = run.get('wells');
                    for (var w = 0; w < wells.length; w++) {
                        if (wells[w].valWst >= 2 && wells[w].valWst < 3) {
                            filtersToAllow.selectWaitingVal1.show = true;
                        }
                        if (wells[w].valWst === 3.2) {
                            filtersToAllow.selectWaitingVal2.show = true;
                        }
                        if (wells[w].valWst === 3.1) {
                            filtersToAllow.selectWaitingExpert.show = true;
                        }
                        if (filtersToAllow.selectWaitingVal1.show &&
                            filtersToAllow.selectWaitingVal2.show &&
                            filtersToAllow.selectWaitingExpert.show) {
                            break;
                        }
                    }

                }, this));
                this.allowFilters(filtersToAllow);
            }, this));
        },

        allowFilters: function (filtersToAllow) {
            _.each(filtersToAllow, _.bind(function (f) {
                var filterComponent = $('.headerFilterButton.' + f.selector + '.js-tab-filters.js-select-filter');
                if (f.show) {
                    this.displayFilterComponent(filterComponent, true);
                } else {
                    this.displayFilterComponent(filterComponent, false);
                }
            }, this));
        },

        displayFilterComponent: function (filterComponent, display) {
            filterComponent.css('opacity', display ? 1 : 0.3);
            if (display) {
                filterComponent.addClass('js-select-filter');
                filterComponent.addClass('clickable');
            } else {
                filterComponent.removeClass('js-select-filter');
                filterComponent.removeClass('clickable');
            }
            filterComponent.find('input[type="checkbox"]').css('opacity', display ? 1 : 0);
        },

        getRunsToDisplay: function (runsList) {
            var defer = $.Deferred();
            var lst = [];
            _.each(runsList, _.bind(function (secId) {
                var model = PcrRuns.getModel({secId: secId});
                lst.push({run: model});
            }, this));
            this.process(lst, defer);
            return defer.promise();
        },

        process: function (lst, defer) {
            if (lst.length !== 0) {
                var obj = lst.pop();
                obj.run.fetch().done(_.bind(function () {
                    this.processedRunsResult.push(obj.run);
                    this.process(lst, defer);
                }, this));
            } else {
                defer.resolve();
            }
        }
    });
});
