
define('template!autocompleteView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div style="width: 100%; position: relative;">\n    <input class="btn btn-dropdown autocomplete form-control js-form-input inputBackground"\n           data-field-name="' +
((__t = ( fieldName )) == null ? '' : __t) +
'"\n           placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'"\n           type="search"\n           value="' +
((__t = ( value )) == null ? '' : __t) +
'">\n    <span class="mdi mdi-filter-variant autocomplete-dropdown js-dropdown-icon clickable"></span>\n</div>\n<div class="js-edit-record clickable"\n     style="display: none;" title="' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'">\n    <span class="mdi mdi-pencil clickable" style="font-size:21px; line-height: 21px;"></span>\n</div>\n<div class="js-display-doc-options" style="display:none;">\n    <div class="js-show-doc" style="background-color: rgba(255,255,255,0); display:none;"\n         title="' +
((__t = ( _.i18n('common.show') )) == null ? '' : __t) +
'">\n        <span class="mdi mdi-information-outline" style="font-size:21px;"></span>\n    </div>\n</div>';

}
return __p
};});

