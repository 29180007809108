/**
 * Created by GSP on 2/10/2015.
 */
define('menuItemsUtils',[
	'module',
	'settings',
	'underscore',
	'menuItems'
], function (
	module,
	Settings,
	_,
	MenuItems
) {
	'use strict';

	module.exports = {
		getMenuItems: function (settings) {
			var currentUserModel = Settings.get('currentUserModel'),
				menuConfig = {}, subMenus, cMenuItem;

			if (currentUserModel) {
				menuConfig.menuItems = [];
				_.each(MenuItems, function (menuItem) {
					subMenus = [];
					cMenuItem = _.clone(menuItem);
					if (currentUserModel.hasRole(cMenuItem.role) === true && (!cMenuItem.display || cMenuItem.display(settings))) {
						//main menu
						if (_.isEmpty(cMenuItem.subMenu)) {
							menuConfig.menuItems.push(cMenuItem);
						} else {
							//subMenus
							_.each(cMenuItem.subMenu, function (subMenu) {
								if (currentUserModel.hasRole(subMenu.role) === true) {
									subMenus.push(subMenu);
								}
							});
							cMenuItem.subMenu = subMenus;
							menuConfig.menuItems.push(cMenuItem);
						}
					}
				});
			} else {
				menuConfig.menuItems = MenuItems;
			}

			return menuConfig.menuItems;
		}
	};
});
