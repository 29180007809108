define('prepRunCreateBeadPoolingItem',[
	'module',
	'marionette',
	'template!prepRunCreateBeadPoolingItem',
	'underscore',
	'app',
	'autocompleteView',
	'dialogFormView'
], function (
	module,
	Marionette,
	Tpl,
	_,
	App,
	AutocompleteView,
	DialogFormView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		events: {
			'click .mdi-delete': 'onDelete'
		},

		regions: {
			runRegion: '.js-select-run-region'
		},

		className: 'col-xs-12 p-0',

		onRender: function () {
			var runView = new AutocompleteView(
				this._getAutocompleteOptions('prepRun', 'name', 'name', 'v2/runs/prep?status=1&toBePooled=true',
					'selectrun.placeholder', 'name')
			);
			this.getRegion('runRegion').options.allowMissingEl = true;
			this.getRegion('runRegion').show(runView);
		},
		onDelete: function () {
			this.trigger('item:delete', this.model);
		}
	});
});
