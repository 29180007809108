define('adminUsersView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!usersListTpl',
    'usersCreateEditLayoutView',
    'entities/global/userpids'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl,
    CreateEditLayoutView,
    Userpids
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'usersListFilters',
        triggers: {},

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteUser: '.delete-link'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({
                'click @ui.deleteUser': 'onDelete'
            }, this.gridEvents);
        },

        serializeData: function () {
            var templateData = {
                type: 'users',
                data: this.options.data
            };
            return templateData;
        },

        onDelete: function (event) {
            var rowId = $(event.currentTarget).find('span').attr('data-row-id');
            this.trigger('user:delete', rowId);
        },

        gridOptions: function (data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('common.first.name'),
                        name: 'firstName',
                        classes: 'cell-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'firstName',
                        width: 50
                    }, {
                        label: _.i18n('common.last.name'),
                        name: 'lastName',
                        classes: 'cell-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'lastName',
                        width: 50
                    }, {
                        label: _.i18n('common.nickName'),
                        name: 'nickName',
                        classes: 'cell-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'nickName',
                        width: 40
                    }, {
                        label: _.i18n('userpid.type'),
                        name: 'type',
                        classes: 'cell-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'type'
                    }, {
                        label: _.i18n('common.email'),
                        name: 'email',
                        classes: 'cell-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        searchoptions: {clearSearch: false},
                        index: 'email',
                        width: 80
                    }, {
                        label: '',
                        name: '',
                        classes: 'delete-link',
                        formatter: this.deleteIconFormatter,
                        search: false,
                        width: 50
                    }
                ],
                sortname: 'firstName',

                onSelectRow: function (rowId, bool, e) {

                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            var model, userAccessRights;
                            model = Userpids.getModel({secId: rowId});
                            model.fetch().done(_.bind(
                                function () {
                                    userAccessRights = App.request('caccount-admins:user-access-rights');
                                    $.when(userAccessRights).done(_.bind(function (userAccessRights) {

                                        var createEditLayoutView = new CreateEditLayoutView({
                                            model: model,
                                            userAccessRights: userAccessRights
                                        });
                                        createEditLayoutView.show({
                                            title: _.i18n('menu.admin.users')
                                        });
                                    }, this));
                                }, this));
                        }
                    }
                }
            };
            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        }
    });
});
