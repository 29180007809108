define('kitLotPcrTargetReagentAssayView',[
    'module',
    'underscore',
    'template!kitLotPcrTargetReagentAssayView',
    'dialogFormView',
    'autocompleteView',
    'entities/caccounts/mbanares'
], function (
    module,
    _,
    Tpl,
    DialogFormView,
    AutocompleteView,
    MbAnaRes
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input-child',
            chart: '.js-show-qc-graph'
        },
        regions: {
            result: '.js-result'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'click @ui.chart': 'showQCDetails'
        },
        className: 'col-w-all d-f',

        onRender: function () {
            this.getRegion('result').show(new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaRes.getAutocompleteParam({
                    modelProperty: 'refMbAnaRes',
                    mbAnaResGrSecId: this.model.get('refMbAna') && this.model.get('refMbAna').get('mbAnaResGr') ? this.model.get('refMbAna').get('mbAnaResGr').secId : null
                }))
            ));
        },

        serializeData: function () {
            var templateData = {};
            templateData.assay = this.model.get('refAssayReagent').get('refAssay').get('code');
            templateData.target = this.model.get('refMbAna').get('code');
            templateData.reagent = this.model.get('refAssayReagent').get('reagent') ? this.model.get('refAssayReagent').get('reagent').get('code') : '';
            templateData.val = this.model.get('val');
            templateData.deltaWarning = this.model.get('deltaWarning');
            templateData.deltaError = this.model.get('deltaError');
            templateData.disabled = this.options.editable ? '' : 'disabled="disabled"';
            templateData.enableQc = this.model.get('refAssayReagent').get('reagent') && this.model.get('refAssayReagent').get('reagent').get('code').toUpperCase() !== 'IC';
            return templateData;
        },

        showQCDetails: function () {
            require(['kitLotsPcrController'], _.bind(function (Controller) {
                Controller.showQCFromLot(this.model);
            }, this));
        }
    });
});
