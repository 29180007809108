
define('template!superAdminDocGlossariesListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="well jq-grid-table-page">\n        <div class="docglozzary-table">\n            <div class="row baseTableTitleContainer">\n                <div class="col-xs-1">\n                    <div class="form-group">\n                        <a href="#superAdmin/docglossaries/new" class="btn new-kit-button add-btn-text">\n                            <span class="mdi mdi-plus-circle"></span>\n                            <span>' +
((__t = ( _.i18n('menu.add.button') )) == null ? '' : __t) +
'</span>\n                        </a>\n                    </div>\n                </div>\n                <div class="col-xs-11">\n                    <div class="baseTAbleTitleText">' +
((__t = ( _.i18n('menu.superadmin.docglossaries') )) == null ? '' : __t) +
'</div>\n                </div>\n            </div>\n            <table id="jq-grid-list" class="docGlossaryTable" role="grid"></table>\n            <div id="jq-grid-pager-list"></div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

