/**
 * Created by OLD on 30/10/2015.
 */
define('menuMain',[
  'module',
  'logger',
  'menuRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var MenuMain = {
    start: function() {
      Logger.log(module, 'starting SampleModule');
    },

    stop: function() {
      Logger.log(module, 'stopping SampleModule');
    }
  };

  module.exports = MenuMain;
});
