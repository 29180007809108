define('entities/caccounts/standardCurve/standardCurveSamples',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.StandardCurveSample = Backbone.RelationalModel.extend({
        defaults: {
            'sample': '',
            'dillution': '',
            mandatory: true
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.StandardCurveSampleCollection = Backbone.Collection.extend({
        model: app.ns.StandardCurveSample
    });

    module.exports = _.defaults({
        getName: function () {
            return 'standardcurvesample';
        },
        getDynamicJson: function () {
            return 'StandardCurveSampleJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('standardCurveSample.sample'),
                name: 'sample',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'sample',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('standardCurveSample.dilution'),
                name: 'dilution',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'dilution'
            });
            return columns;
        },

        getCreateEditLine: function () {
            return [
                {'field': 'sample', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45 p-l-5'}}},
                {
                    'field': 'dilution', 'param': {
                        'type': 'NUMBER', 'display': {'class': 'col-w-20 p-l-5'},
                        'readOnly': true
                    }
                },
                {'field': 'mandatory', 'param': {'type': 'BOOLEAN', 'display': {'class': 'col-w-3 p-l-5 p-r-5'}}}
            ];
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.StandardCurveSample.findOrCreate(modelJson);
        }
    }, Dynamic);
});
