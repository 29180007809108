
define('template!pcrSetupImportResultTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container"\n     style="min-height: 150px; border: solid 1px black; border-radius: 5px; width: 800px; margin: auto;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n\n    <div class="row">\n    </div>\n\n    <div>\n        <div>onImportResult</div>\n    </div>\n\n    <div class="form-horizontal">\n        <div class="createRunFromCyclerExport">\n            <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0">\n                <div class="col-xs-4 mdi-file-folder-open"\n                     style="    text-align: right;\n                            font-size: 30px;\n                            bottom: 5px;"></div>\n                <div class="col-xs-8">\n                    <input type="file"\n                           class="js-upload-runs-cycler-export" data-icon="true"\n                           accept=".xlsx,.xls,.xml,.rdml,.csv"/>\n                </div>\n            </div>\n            <div class="form-group" style="margin: 0;">\n                <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.refKitProt') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-8">\n                    <div class="js-select-kitprot-region-from-cycler-export"></div>\n                </div>\n            </div>\n        </div>\n        <br/>\n        <div class="row cancelConfirmRow">\n            <div class="pull-right p-r-15 col-xs-12 cancelConfirmCreateRunDiv">\n                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n            </div>\n        </div>\n        <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n    </div>\n</div>';

}
return __p
};});

