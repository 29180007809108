define('linkNewTabView',[
	'module',
	'marionette',
	'underscore'
], function (
	module,
	Marionette,
	_
) {
	'use strict';

	module.exports = Marionette.LayoutView.extend({
		template: _.template('<%= code %>'),

		className: 'js-click clickable openNewTab',
		tagName: 'span',

		events: {
			'click': 'onClick'
		},
		attributes: function () {
			return {
				'data-secId': this.options.secId,
				title: this.options.name
			};
		},

		serializeData: function () {
			return {code: this.options.code};
		},

		onClick: function() {
			window.open(window.location.origin + window.location.pathname + this.options.entity.getRoute() + '/' + this.options.secId, '_blank');
		}
	});
});
