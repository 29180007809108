
define('template!routingGroupDispatchSampleView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="js-header-region">\n</div>\n<div class="col-xs-12 m-b-10">\n    <div class="col-xs-9" style="padding: 0 10px 0 10px;">\n        <label class="col-xs-4 text-right p-l-0">\n            <span class="mdi mdi-barcode-scan" style="font-size: 51px; line-height: 33px;"></span>\n        </label>\n        <div class="col-xs-3 p-l-0 p-r-0">\n            <input class="js-scan-sample-input form-control inputBackground" type="text"\n                   style="height: 40px; font-size: 30px;"/>\n        </div>\n    </div>\n</div>\n<div class="col-xs-12 m-b-20">\n    <div class="col-xs-9 js-sample-detail-region">\n    </div>\n    <div class="col-xs-3 js-error-region">\n    </div>\n</div>\n<div class="col-xs-12">\n    <div class="col-xs-9 js-plate-region"></div>\n    <div class="col-xs-3 js-tracing-region" style="position: relative;"></div>\n</div>';

}
return __p
};});

