/**
 * Created by Matteo on 11/07/2015.
 */
define('loginMainRouter',[
  'require',
  'exports',
  'module',
  'marionette',
  'app'
], function(
  require,
  exports,
  module,
  Marionette,
  App
) {
  'use strict';

  var LoginRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'login': 'showLogin'
    }
  });

  var API = {
    showLogin: function(textAdditionnal){
      require(['loginShowController'], function(ShowController) {
        App.startSubModule('loginMain');
        ShowController.showLogin(textAdditionnal);
      });
    }
  };

  App.on('login:show', function(textAdditionnal) {
    App.navigate('login');
    API.showLogin(textAdditionnal);
  });

  App.on('before:start', function() {
    new LoginRouter({
      controller: API
    });
  });

  module.exports = LoginRouter;
});

