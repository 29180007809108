define('entities/caccounts/connector/connectorHl7Tracings',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.ConnectorHL7Tracing = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/connector/hl7/tracing/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {},
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/connector/hl7/tracing');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'Request',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Request',
        getEntity: function () {
            return module.exports;
        },
        importable: false,
        resend: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/connector/hl7/tracing/' + this.get('secId') + '/resend');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function () {
                    defer.resolve();
                },
                error: _.bind(function (xhr) {
                    defer.reject(xhr);
                }, this)
            });

            return defer.promise();
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'connectorHl7Tracing';
        },
        getUrl: function () {
            return 'v2/connector/hl7/tracing';
        },
        getDynamicJson: function () {
            return 'Request';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('connector.hl7.analysisDevice'),
                name: 'analysisDevice',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'analysisDevice',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('connector.hl7.created'),
                name: 'created',
                classes: 'dynamic-link',
                formatter: viewDynamic.dateTimeFormatter,
                search: true,
                index: 'created',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('connector.hl7.updated'),
                name: 'updated',
                formatter: viewDynamic.dateTimeFormatter,
                search: true,
                index: 'updated',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('connector.hl7.sampleId'),
                name: 'sampleId',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'sampleId',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('connector.hl7.status'),
                name: 'status',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {size: 10},
                index: 'status',
                width: 40
            }, {
                label: _.i18n('connector.hl7.error'),
                name: 'error',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'error'
            }, {
                label: _.i18n('connector.hl7.lastAction'),
                name: 'lastAction',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'lastAction'
            });
            return columns;
        },

        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.selectable = false;
            config.creatable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridSidx: function () {
            return 'created';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.ConnectorHL7Tracing.findOrCreate(modelJson);
        }
    }, Dynamic);
});
