define('entities/caccounts/pcrreagents',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.PcrReagent = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/pcr/reagents/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': undefined,
            'name': undefined,
            'description': undefined,
            'type': undefined,
            'shortName': undefined
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'pcrKit',
            relatedModel: 'KitPcr',
            includeInJSON: ['secId', 'code', 'name']
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/pcr/reagents');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('PcrReagentJson.pdf');
        },
        getEntity: function () {
            return module.exports;
        },
        getValues: function (fieldName) {
            return this.getEntity().getValues(fieldName);
        }
    });

    app.ns.PcrReagentCollection = Backbone.Collection.extend({
        model: app.ns.PcrReagent,
        comparator: 'type'
    });

    module.exports = _.defaults({
        getName: function () {
            return 'pcrreagent';
        },
        getUrl: function () {
            return 'v2/pcr/reagents';
        },
        getDynamicJson: function () {
            return 'PcrReagentJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.shortName'),
                name: 'shortName',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'shortName',
                width: 80
            }, {
                label: _.i18n('common.city'),
                name: 'type',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'type',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.PcrReagent.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 550, 'fields': ['name', 'pcrKit.code', 'pcrKit.name']};
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'type',
                    'param': {'type': 'CODELIST', 'code': 'PCRREAGENT_GROUP', 'display': {'class': 'col-xs-2'}}
                },
                {
                    'field': 'code',
                    'param': {'type': 'USERCODELIST', 'code': 'PCR_REAGENT', 'display': {'class': 'col-xs-2'}}
                },
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-4'}}},
                {'field': 'shortName', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-4'}}}
            ];
        },
        getConfigCreateEditLine: function () {
            var config = Dynamic.getConfigCreateEditLine();
            config.editButton = Settings.get('currentUserModel').hasType('KIT0') && Settings.get('currentUserModel').hasRole('DASHBOARD_ADMIN_KIT');
            return config;
        },
        canAddOrEdit: function () {
            return false;
        }
    }, Dynamic);
});
