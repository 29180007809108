define('entities/menu',[
    'backbone',
    'module',
    'backboneRelational'
], function (
    Backbone,
    module
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Menu = Backbone.RelationalModel.extend({
        defaults: {},
        idAttribute: 'itemPageName',
        relations: [{
            type: Backbone.HasMany,
            key: 'subMenu',
            relatedModel: 'Menu',
            collectionType: 'MenuCollection',
            reverseRelation: {
                key: 'parent'
            }
        }]
    });

    app.ns.MenuCollection = Backbone.Collection.extend({
        model: app.ns.Menu
    });
    module.exports = {
        getModel: function (items) {
            if (!items) {
                items = {};
            }
            return app.ns.Menu.findOrCreate({title: 'menu', subMenu: items});
        }
    };
});
