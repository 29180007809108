define('entities/createPcrRunSetupOneToOne',[
	'app',
	'backbone',
	'settings'
], function (
	App,
	Backbone,
	Settings
) {
	'use strict';
	var CreatePcrRunSetupOneToOne = Backbone.Model.extend({
		defaults: {
			'pcrRunTemplateSecId': '',
			'prepRunSecId': '',
			'assaySecId': '',
			'selectedDate': '',
			'plateIdPc': ''
		},
		idAttribute: 'secId',

		postUrl: function () {
			return Settings.url('compuzz', 'v2/runs/pcr/createFromPrep');
		},

		save: function () {
			this.url = this.postUrl();
			return Backbone.Model.prototype.save.call(this);
		}
	});

	App.reqres.setHandler('createPcrRunSetupOneToOne:model', function () {
		return new CreatePcrRunSetupOneToOne();
	});
});
