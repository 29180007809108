define('assayVersionLotCollection',[
	'module',
	'marionette',
	'template!assayVersionLotCollection',
	'assayVersionLotItem'
], function (
	module,
	Marionette,
	Tpl,
	View
) {
	'use strict';


	module.exports = Marionette.CompositeView.extend({
		template: Tpl,
		childView: View,
		childViewContainer: '.collection'
	});
});
