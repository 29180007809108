/**
 * Created by RKL on 21/08/2015.
 */
define('menusShowView',[
    'module',
    'backbone',
    'marionette',
    'app',
    'menuItemsUtils',
    'underscore',
    'subMenuView',
    'emptyView',
    'settings',
    'template!menusViewTpl'
], function (
    module,
    Backbone,
    Marionette,
    App,
    MenuItemUtils,
    _,
    SubMenuView,
    EmptyView,
    Settings,
    viewTpl

) {
    'use strict';

    var MainMenuView = Marionette.LayoutView.extend({
        template: viewTpl,
        ui: {
            content:'.content',
            subMenu:'.js-show-submenu'
        },
        events: {
            'click @ui.subMenu': 'showSubMenu'
        },
        serializeData: function() {
            var templateData = {};
            templateData.menus = this.model;
            return templateData;
        },
        onShow: function() {
            this.ui.content.parent().height('100%');
        },
        showSubMenu: function(event) {
            /*var subMenuModel = _.findWhere(this.model,{secId:$(event.target).attr('data-submenu')}).menuElements;
            if (subMenuModel !== undefined) {
                var subMenuView = new SubMenuView(
                  {model: subMenuModel}
                );
                this.getRegion('rightPane').show(subMenuView);
            } else {
                var emptyView = new EmptyView();
                this.getRegion('rightPane').show(emptyView);

            }*/
            var menuModel = _.findWhere(this.model,{secId:$(event.target).attr('data-submenu')});
            var mapHtml = '';
            mapHtml += '<img src="'+menuModel.imageLinkEncodedValue+'" usemap="#imagemap">';
            mapHtml += '<map name="imagemap">';
            _.each(menuModel.menuElements, function(menuElement) {
                var test = menuElement.imagePosition.split(',');
                var shape = 'default';
                switch (test.length) {
                    case 4: shape = 'rect';break;
                    case 3: shape = 'circle';break;
                    default: shape = 'poly';break;
                }
                if (menuElement.link === 'caccount/') {
                    menuElement.link = menuElement.link + (Settings.get('currentCAccount') ? Settings.get('currentCAccount').secId : '');
                }
                mapHtml += '<area shape="'+shape+'" coords="'+menuElement.imagePosition+'" href="#'+menuElement.link+'" title="'+menuElement.name+'">';
            });
            mapHtml += '</map>';
            $('.right-pane').html(mapHtml);
        }
    });

    module.exports = MainMenuView;
});
