/**
 * Created by OBL on 7/01/2016.
 */
define('rolesMixin',['module', 'settings'], function (module, Settings) {
    'use strict';
    var prefixApplication = Settings.get('application').toUpperCase();
    module.exports = {
        SUPERUSER: 'SUPERUSER',
        ADMIN: 'ADMIN',
        EXPORT: 'EXPORT',
        VECTORIZE: 'VECTORIZE',
        VECTORIZATOR: 'VECTORIZATOR',
        CREDITS: 'CREDITS',
        //----DASHBOARD------------------------------
        // ACCOUNT
        // -- ACCOUNT
        DASHBOARD_ACCOUNT: prefixApplication + '_ACCOUNT',
        DASHBOARD_ACCOUNT_ACCOUNT: prefixApplication + '_ACCOUNT_ACCOUNT',
        //--TABS
        DASHBOARD_ACCOUNT_ACCOUNT_PROFILE: prefixApplication + '_ACCOUNT_ACCOUNT_PROFILE',
        DASHBOARD_ACCOUNT_ACCOUNT_CREDITS: prefixApplication + '_ACCOUNT_ACCOUNT_CREDITS',
        DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR: prefixApplication + '_ACCOUNT_ACCOUNT_ADMINISTRATOR',
        DASHBOARD_ACCOUNT_ACCOUNT_SHARE: prefixApplication + '_ACCOUNT_ACCOUNT_SHARE',
        DASHBOARD_ACCOUNT_ACCOUNT_ACTIVITY: prefixApplication + '_ACCOUNT_ACCOUNT_ACTIVITY',
        DASHBOARD_ACCOUNT_ACCOUNT_ATTACHMENTS: prefixApplication + '_ACCOUNT_ACCOUNT_ATTACHMENTS',
        // -- GRAPHICAL ID
        DASHBOARD_ACCOUNT_GRAPHICALID: prefixApplication + '_ACCOUNT_GRAPHICALID',
        //--TABS
        DASHBOARD_ACCOUNT_GRAPHICALID_FONTS: prefixApplication + '_ACCOUNT_GRAPHICALID_FONTS',
        DASHBOARD_ACCOUNT_GRAPHICALID_OBJECTNAMES: prefixApplication + '_ACCOUNT_GRAPHICALID_OBJECTNAMES',
        //CLIENTS
        DASHBOARD_CUSTOMERS: prefixApplication + '_CUSTOMERS',
        DASHBOARD_CUSTOMERS_CUSTOMERS: prefixApplication + '_CUSTOMERS_CUSTOMERS',
        //--TABS
        DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE: prefixApplication + '_CUSTOMERS_CUSTOMERS_PROFILE',
        DASHBOARD_CUSTOMERS_CUSTOMERS_DESIGNELEMENTS: prefixApplication + '_CUSTOMERS_CUSTOMERS_DESIGNELEMENTS',
        DASHBOARD_CUSTOMERS_CUSTOMERS_LOGOSIMAGES: prefixApplication + '_CUSTOMERS_CUSTOMERS_LOGOSIMAGES',
        DASHBOARD_CUSTOMERS_CUSTOMERS_DATASETS: prefixApplication + '_CUSTOMERS_CUSTOMERS_DATASETS',
        DASHBOARD_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES: prefixApplication + '_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES',
        DASHBOARD_CUSTOMERS_CUSTOMERS_TEMPLATES: prefixApplication + '_CUSTOMERS_CUSTOMERS_TEMPLATES',
        DASHBOARD_CUSTOMERS_CUSTOMERS_ATTACHMENTS: prefixApplication + '_CUSTOMERS_CUSTOMERS_ATTACHMENTS',
        DASHBOARD_CUSTOMERS_CUSTOMERS_FONTS: prefixApplication + '_CUSTOMERS_CUSTOMERS_FONTS',
        DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS: prefixApplication + '_CUSTOMERS_CUSTOMERS_CONTACTS',
        //RUNS
        DASHBOARD_RUNS: prefixApplication + '_RUNS',
        DASHBOARD_RUNTEMPLATES: prefixApplication + '_RUNTEMPLATES',
        DASHBOARD_SAMPLES: prefixApplication + '_SAMPLES',
        DASHBOARD_ORDERS: prefixApplication + '_ORDERS',
        //SUPERADMIN
        DASHBOARD_SUPERADMIN_ACCOUNT_PROFILE: prefixApplication + '_SUPERADMIN_ACCOUNT_PROFILE',
        DASHBOARD_SUPERADMIN_ACCOUNT_CREDITS: prefixApplication + '_SUPERADMIN_ACCOUNT_CREDITS',
        DASHBOARD_SUPERADMIN_ACCOUNT_GRAPHICALREQUESTS: prefixApplication + '_SUPERADMIN_ACCOUNT_GRAPHICALREQUESTS',
        // OTHERS
        DASHBOARD_ADMIN: prefixApplication + '_ADMIN',
        DASHBOARD_ADMIN_KIT: prefixApplication + '_ADMIN_KIT',
        DASHBOARD_ADMIN_CYCLER: prefixApplication + '_ADMIN_CYCLER',
        DASHBOARD_ADMIN_LMBCYCLER: prefixApplication + '_ADMIN_LMBCYCLER',
        DASHBOARD_ADMIN_EXTRACTOR: prefixApplication + '_ADMIN_EXTRACTOR',
        DASHBOARD_ADMIN_LMBEXTRACTOR: prefixApplication + '_ADMIN_LMBEXTRACTOR',
        DASHBOARD_ADMIN_MBANA: prefixApplication + '_ADMIN_MBANA',
        DASHBOARD_ADMIN_KITPROT: prefixApplication + '_ADMIN_KITPROT',
        DASHBOARD_ADMIN_LISANA: prefixApplication + '_ADMIN_LISANA',
        DASHBOARD_ADMIN_SETTING: prefixApplication + '_ADMIN_SETTING',
        DASHBOARD_ADMIN_ASSAYGROUP: prefixApplication + '_ADMIN_ASSAYGROUP',
        DASHBOARD_ADMIN_ROUTINGGROUP: prefixApplication + '_ADMIN_ROUTINGGROUP',
        DASHBOARD_ADMIN_ROUTINGACTION: prefixApplication + '_ADMIN_ROUTINGACTION',
        DASHBOARD_ADMIN_PREPROCESS: prefixApplication + '_ADMIN_PREPROCESS',
        DASHBOARD_ADMIN_SUPPLIER: prefixApplication + '_ADMIN_SUPPLIER',
        DASHBOARD_ADMIN_SAMPLEHANDLER: prefixApplication + '_ADMIN_SAMPLEHANDLER',
        DASHBOARD_ADMIN_FILEFORMAT: prefixApplication + '_ADMIN_FILEFORMAT',
        DASHBOARD_ADMIN_LMBSAMPLEHANDLER: prefixApplication + '_ADMIN_LMBSAMPLEHANDLER',
        DASHBOARD_ADMIN_BIOGROUP: prefixApplication + '_ADMIN_BIOGROUP',
        DASHBOARD_ADMIN_LISBIOGROUP: prefixApplication + '_ADMIN_LISBIOGROUP',
        DASHBOARD_ADMIN_SPECIE: prefixApplication + '_ADMIN_SPECIE',
        DASHBOARD_ADMIN_STANDARDCURVE: prefixApplication + '_ADMIN_STANDARDCURVE',DASHBOARD_ADMIN_LISSPECIE: prefixApplication + '_ADMIN_LISSPECIE',
        DASHBOARD_ADMIN_EXTRACTIONMETHOD: prefixApplication + '_ADMIN_EXTRACTIONMETHOD',
        DASHBOARD_ADMIN_COLORCOMPENSATION: prefixApplication + '_ADMIN_COLORCOMPENSATION',
        DASHBOARD_ADMIN_USERCODELIST: prefixApplication + '_ADMIN_USERCODELIST',
        DASHBOARD_ADMIN_LIS: prefixApplication + '_ADMIN_LIS',
        DASHBOARD_ADMIN_SAMPLECLASS: prefixApplication + '_ADMIN_SAMPLECLASS',
        DASHBOARD_ADMIN_PRINTER: prefixApplication + '_ADMIN_PRINTER',
        DASHBOARD_ADMIN_REPORT: prefixApplication + '_ADMIN_REPORT',
        DASHBOARD_CONNECTOR_HL7: prefixApplication + '_CONNECTOR_HL7'
    };
});


