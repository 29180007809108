define('wellSmpTypeView',[
		'module',
		'marionette',
		'backbone',
		'dialogFormView',
		'underscore',
		'template!wellSmpTypeETpl',
		'template!wellSmpTypeUTpl',
		'template!wellSmpTypeDTpl',
		'template!wellSmpTypeCTpl',
		'template!wellSmpTypePTpl',
		'template!wellSmpTypePCTpl',
		'template!wellSmpTypeNCTpl',
		'template!wellSmpTypeRCTpl',
		'template!wellSmpTypeOCTpl'
	], function (
		module,
		Marionette,
		Backbone,
		DialogFormView,
		_,
		SmpTypeETpl,
		SmpTypeUTpl,
		SmpTypeDTpl,
		SmpTypeCTpl,
		SmpTypePTpl,
		SmpTypePCTpl,
		SmpTypeNCTpl,
		SmpTypeRCTpl,
		SmpTypeOCTpl
	) {
		'use strict';

		module.exports = DialogFormView.extend({
			getTemplate: function () {
				switch(this.smpType) {
					case 'U':
						return SmpTypeUTpl;
					case 'D':
						return SmpTypeDTpl;
					case 'C':
						return SmpTypeCTpl;
					case 'E':
						return SmpTypeETpl;
					case 'P':
						return SmpTypePTpl;
					case 'PC':
						return SmpTypePCTpl;
					case 'NC':
						return SmpTypeNCTpl;
					case 'RC':
						return SmpTypeRCTpl;
					case 'OC':
						return SmpTypeOCTpl;
				}
			},

			initialize: function (options) {
				this.smpType = options.smpType ? options.smpType.toUpperCase() : 'E';
			},

			serializeData: function () {
				return {
					smpType: this.smpType,
					title: _.i18n('well.smpType.' + this.smpType),
					pcrSetup: !!this.options.pcrSetup
				};
			}
		});
	}
);
