define('superAdminMenusView',[
  'module',
  'marionette',
  'underscore',
  'jquery',
  'app',
  'settings',
  'jqgridView',
  'template!superAdminMenusListTpl',
  'menuCreateEditLayoutView'
], function (
  module,
  Marionette,
  _,
  $,
  App,
  Settings,
  JqGridView,
  viewTpl,
  CreateEditLayoutView
) {
  'use strict';

  module.exports = JqGridView.extend({
    template: viewTpl,
    filtersName: 'menusListFilters',

    triggers: {},

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        deleteMenu: '.delete-menu-link'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({
        'click @ui.deleteMenu': 'onDeleteMenu'
      }, this.gridEvents);
    },

    onDeleteMenu: function (event) {
      this.trigger('menu:delete', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    gridOptions: function (data) {
      var options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('common.code'),
            name: 'code',
            classes: 'menu-link',
            formatter: this.defaultFormatter,
            search: true,
            index: 'code',
            searchoptions: {size: 10},
            width: 50
          }, {
            label: _.i18n('common.name'),
            name: 'name',
            classes: 'menu-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'name',
            width: 80
          }, {
            label: _.i18n('common.description'),
            name: 'description',
            classes: 'menu-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'description',
            width: 150
          }, {
            label: ' ',
            name: 'copy',
            search: false,
            classes: 'copy-menu-link copy-link',
            formatter: this.copyIconFormatter,
            width: 10
          }, {
            label: ' ',
            name: 'delete',
            search: false,
            classes: 'delete-menu-link delete-link',
            formatter: this.deleteIconFormatter,
            width: 20
          }
        ],

        onSelectRow: function (rowId, bool, e) {
          if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
            if (e.type === 'click') {
              var model = App.request('menu:model', {secId: rowId});
              model.fetch().done(function () {
                if ($(e.target).hasClass('cell-copy')) {
                  model.set('secId', null);
                  model.set('code', 'Copy-' + model.get('code'));
                }
                var createEditView = new CreateEditLayoutView({
                  model: model
                });
                createEditView.show({
                  title: _.i18n('menu.superadmin.menus'),
                  generatePdfButton: true,
                  className: 'popupMenu'
                });
              });
            }
          }
        },
        sortname: 'code'
      };

      options = _.defaults(options, this.gridInitOptions(data));

      return options;
    }
  });
});
