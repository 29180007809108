define('usersCreateEditLayoutView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!usersCreateEditLayoutTpl',
    'savingBehavior',
    'underscore',
    'jquery',
    'settings',
    'dynamicCreateEditCustomListToList',
    'entities/caccounts/authorityGroup'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    _,
    $,
    Settings,
    DynamicCreateEditCustomListToList,
    AuthorityGroups
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'change @ui.userAccesRight': 'onUserAccesRightChange',
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm'
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        regions: {
            'types': '.js-types',
            'authorityGroups': '.js-authorityGroups'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        fieldsToValidate: [
            {name: 'firstName', type: 'required'},
            {name: 'lastName', type: 'required'},
            {name: 'email', type: 'email'}
        ],

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.newItem = this.model.isNew();
            return templateData;
        },

        onShow: function () {
            $.material.init();

            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.model.save();
            }
        },
        onSave: function () {
            this.box.modal('hide');
        },
        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },
        onCancel: function () {
            this.model.fetch().done(_.bind(function () {
                this.box.modal('hide');
            }, this));
        },
        onRender: function () {
            this.model.getEntity().findUserAccessRights().done(_.bind(function (response) {
                this.getRegion('types').show(new DynamicCreateEditCustomListToList({
                    'field': 'types',
                    'model': this.model,
                    'items': {items: response, codeListCode: ''},
                    'display': {
                        'rows': 2
                    }
                }));
            }, this));
            AuthorityGroups.findAll().done(_.bind(function (collection) {
                this.getRegion('authorityGroups').show(new DynamicCreateEditCustomListToList({
                    'field': 'authorityGroups',
                    'model': this.model,
                    'items': {items: collection.toJSON(), codeListCode: ''}
                }));
            }, this));
        }
    });
});
