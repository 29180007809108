
define('template!documentCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="name">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8">\n\t\t\t\t\t<div class="form-group" data-field-name="name">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input inputBackground"\n\t\t\t\t\t\t\t   data-field-name="name" placeholder="" value="' +
((__t = ( name )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   data-content="' +
((__t = ( _.i18n('document.duplicatedNameError') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   data-placement="top" autocomplete="off" ' +
((__t = ( newItem ? '' : 'disabled' )) == null ? '' : __t) +
' >\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="contentType">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('document.contentType') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0">\n\t\t\t\t\t<div class="js-select-contentype-region"></div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="language">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('document.language') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0">\n\t\t\t\t\t<div class="js-select-language-region"></div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="url">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('document.url') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8">\n\t\t\t\t\t<div class="form-group" data-field-name="url">\n\t\t\t\t\t\t<textarea type="text" class="form-control floating-label js-info-input dataInputLong inputBackground"\n                                  data-field-name="url" placeholder=""\n                                  data-placement="top" autocomplete="off" style="resize: vertical;" disabled>' +
((__t = ( url )) == null ? '' : __t) +
'</textarea>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="docAbstract">\n\t\t\t\t\t\t<label class="control-label dataLabel" >' +
((__t = ( _.i18n('document.abstract') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8">\n\t\t\t\t\t<div class="form-group" data-field-name="docAbstract">\n\t\t\t\t\t\t<textarea type="text" class="form-control floating-label js-info-input dataInputLong inputBackground"\n\t\t\t\t\t\t\t   data-field-name="docAbstract" placeholder=""\n\t\t\t\t\t\t\t\t  data-placement="top" autocomplete="off" style="resize: vertical;">' +
((__t = ( docAbstract )) == null ? '' : __t) +
'</textarea>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="keywords">\n\t\t\t\t\t\t<label class="control-label dataLabel" >' +
((__t = ( _.i18n('document.keywords') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8">\n\t\t\t\t\t<div class="form-group" data-field-name="keywords">\n\t\t\t\t\t\t<textarea type="text" class="form-control floating-label js-info-input dataInputLong inputBackground"\n\t\t\t\t\t\t\t\t  data-field-name="keywords" placeholder=""\n\t\t\t\t\t\t\t\t  data-placement="top" autocomplete="off" style="resize: vertical;">' +
((__t = ( keywords )) == null ? '' : __t) +
'</textarea>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="validated">\n\t\t\t\t\t\t<label class="control-label dataLabel" >' +
((__t = ( _.i18n('document.isValidated') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8">\n\t\t\t\t\t<div class="form-group">\n\t\t\t\t\t\t<input data-field-name="validated" style="width: 20px; height: 20px; margin: 0;" type="checkbox"\n\t\t\t\t\t\t\t   class="js-info-input" ' +
((__t = ( validated? 'checked' : '' )) == null ? '' : __t) +
'>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="deleted">\n\t\t\t\t\t\t<label class="control-label dataLabel" >' +
((__t = ( _.i18n('document.isDeleted') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8">\n\t\t\t\t\t<div class="form-group" >\n\t\t\t\t\t\t<input data-field-name="deleted" style="width: 20px; height: 20px; margin: 0;" type="checkbox"\n\t\t\t\t\t\t\t   class="js-info-input" ' +
((__t = ( deleted? 'checked' : '' )) == null ? '' : __t) +
' disabled >\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t';
 if (url === '') { ;
__p += '\n\t\t\t<div class="col-xs-8">\n\t\t\t\t<input type="file"\n\t\t\t\t\t   id="doc-file"\n\t\t\t\t\t   class="js-upload-file" data-icon="true"\n\t\t\t\t\t   accept=".docx" style="' +
((__t = ( name ?'color:transparent;float:left':'float:left' )) == null ? '' : __t) +
'" title=""/>\n\t\t\t\t<div class="js-file-link">\n\n\t\t\t\t</div>\n\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\t\t\t<div class="row cancelConfirmRow">\n\t\t\t\t<div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n\t\t\t\t\t<button disabled\n\t\t\t\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

