define('fieldUtils',[
		'module'
	], function (
		module
	) {
		'use strict';

		module.exports = {

			generateSecId: function () {
				var a = new Uint32Array(3);
				window.crypto.getRandomValues(a);
				return (
					// performance.now().toString(36) +
					Array.from(a).map(
						function (A) {
							return A.toString(36);
						}).join('')).replace(/\./g, '');
			},

			getHtmlString: function (string) {
				if (string) {
					string = string.replace(/"/g, '&#34;');
					string = string.replace(/'/g, '&#39;');
					string = string.replace(/</g, '&#60;');
					string = string.replace(/>/g, '&#62;');
				}
				return string;
			},

			getSequenceFormatted: function (sequence, sequenceFormat) {
				if (!sequence) {
					return '';
				}
				var retVal = sequence;
				if (sequenceFormat && sequenceFormat === 'NNN') {
					retVal = this.threeDigit(sequence);
				} else if (sequenceFormat && sequenceFormat === 'NN') {
					retVal = this.twoDigit(sequence);
				}
				return retVal;
			},

			twoDigit: function (n) {
				return (n < 10 ? '0' : '') + n;
			},

			threeDigit: function (n) {
				if (n < 10) {
					return (n < 10 ? '00' : '') + n;
				} else {
					return (n < 100 ? '0' : '') + n;
				}
			},

			arrayMin: function (arr) {
				var len = arr.length, min = Infinity;
				while (len--) {
					if (arr[len] < min) {
						min = arr[len];
					}
				}
				return min;
			},

			arrayMax: function (arr) {
				var len = arr.length, max = -Infinity;
				while (len--) {
					if (arr[len] > max) {
						max = arr[len];
					}
				}
				return max;
			},

			isNumber: function (value) {
				var retVal = false;
				if (typeof value === 'number') {
					retVal = true;
				}
				return retVal;
			},

			isInteger: function (value) {
				var retVal = false;
				if (this.isNumber(value) && Number.isInteger(value)) {
					retVal = true;
				}
				return retVal;
			},

			substringLastChar: function (str) {
				return str.substring(0, str.length - 1);
			},

			substringLastCharWithCharCheck: function (str, charToCheck) {
				var retVal = str;
				if (str !== null && str.length > 0 && str.charAt(str.length - 1) === charToCheck) {
					retVal = str.substring(0, str.length - 1);
				}
				return retVal;
			}
		};
	}
);

