define('translationsTableFiltersView',['module', 'marionette', 'template!translationsTableFiltersTpl'],
    function (module, Marionette, translationsTableFiltersTpl) {
        'use strict';

        module.exports = Marionette.LayoutView.extend({
            template: translationsTableFiltersTpl,

            ui: {
                codeFilter: '.js-code-filter',
                textFilter: '.js-text-filter'
            },

            events: {
                'change @ui.codeFilter': 'onCodeFilter',
                'change @ui.textFilter': 'onTextFilter'
            },

            regions: {
                languages: '#translations-filters-languages-region'
            },

            attributes: {
                style: 'background-color: white; margin-bottom: 10px;padding: 10px;'
            },

            onRender: function () {
                $.material.init();
            },

            onCodeFilter: function (e) {
                e.preventDefault();
                this.trigger('translations:filter:code', this.ui.codeFilter.val());
            },

            onTextFilter: function (e) {
                e.preventDefault();
                this.trigger('translations:filter:text', this.ui.textFilter.val());
            }

        });
    });
