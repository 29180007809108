define('prepRunDisplayKitsLotsView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'underscore',
    'jquery',
    'app',
    'savingBehavior',
    'template!prepRunDisplayKitsLotsTpl'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    _,
    $,
    App,
    SavingBehavior,
    WellCommentTpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: WellCommentTpl,

        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        ui: {
            cancel: '.js-cancel-popup'
        },

        events: {
            'click .js-cancel-popup': 'onCancel'
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                smpId: this.model.get('smpId') ? {
                    name: this.model.get('smpId').get('name'),
                    code: this.model.get('smpId').get('code')
                } : {name: '', code: ''},
                kitLotByKits: _.map(this.model.get('kitLotByKits'), function (kitLot) {
                    return {
                        refPcrKit: kitLot.refPcrKit.code,
                        pcrKitLots: _.pluck(kitLot.pcrKitLots, 'code')
                    };
                })
            };
            templateData.extractionKitLots = this.options.extractionKitLots;
            return templateData;
        },

        onShow: function () {
            $.material.init();
            this.triggerMethod('enable:cancel:confirm');
        },

        onSave: function (model) {
            this.triggerMethod('saved');
            this.triggerMethod('prep:wellComment:saved', model); // to refresh well view
            this.box.modal('hide');
        },

        onCancel: function () {
            this.box.modal('hide');
        }
    });
});
