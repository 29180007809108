define('sampleResultIconView',[
    'module',
    'marionette',
    'underscore',
    'template!sampleResultIconView'
], function (
    module,
    Marionette,
    _,
    Tpl
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: Tpl,

        modelEvents: {
            'change': 'renderSafe'
        },

        serializeData: function () {
            var templateData = {
                isResult: this.model.get('result') && this.model.get('result').get('code') ? 'border: grey solid 1px;' : '',
                color: this.model.get('result') ? this.model.get('result').get('color') : '#000000',
                resComment: this.model.get('comment'),
                exported: this.model.get('exported')
            };
            return templateData;
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        }
    });
});
