define('runImportSampleListView',[
    'module',
    'marionette',
    'dialogFormView',
    'savingBehavior',
    'underscore',
    'app',
    'jquery',
    'bootbox',
    'customBootboxMessage',
    'runRemoteFolderView',
    'autocompleteView',
    'template!runImportSampleListView',
    'entities/caccounts/fileFormatSampleLists',
    'fileExplorerController',
    'entities/caccounts/setting',
    'dynamicCreateEditCodeList',
    'entities/caccounts/pcrruns'
], function (
    module,
    Marionette,
    DialogFormView,
    SavingBehavior,
    _,
    App,
    $,
    Bootbox,
    CustomBootboxMessage,
    RunRemoteFolderView,
    AutocompleteView,
    Tpl,
    FileFormatSampleLists,
    FileExplorerController,
    Settings,
    CodeListView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            importFileFromCentralLib: '.importFileFromCentralLib',
            importFileFromComputer: '.importFileFromComputer',
            selectFileFromComputer: '.js-select-file-from-computer',
            blockLoading: '.empty-page-loader'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click @ui.importFileFromCentralLib': 'onSelectFileFromCentralLib',
            'change @ui.selectFileFromComputer': 'onSelectFileFromComputer',
            'click .js-blank': 'onBlankClick'
        },

        regions: {
            selectFileFormat: '.js-select-fileFormat-region',
            plateFillDirection: '.js-plate-direction-region'
        },

        fieldsToValidate: [
            {name: 'refFileFormat', type: 'required'}
        ],

        initialize: function (options) {
            this.model = options.model;
            this.model.set('refFileFormat', null);
            this.model.set('accept', '.xlsx,.xls,.xml,.rdml,.csv');
            this.model.set('startPos', 'A01');
            this.model.set('plateFillDirection', this.options.settings['LAB-SETTINGS']);
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.remoteAvailable = this.options.settings['IMPORT-PLATELAYOUT-PCR'] && this.options.settings['IMPORT-PLATELAYOUT-PCR'].split(';')[0];
            return templateData;
        },

        onShow: function () {
            $.material.init();
            this.showSelectFileFormatView();
            this.getRegion('plateFillDirection').show(new CodeListView({
                model: this.model,
                config: {
                    modelProperty: 'plateFillDirection',
                    code: 'PLATE_FILL_DIRECTION'
                }
            }));
        },

        showSelectFileFormatView: function () {
            if (this.selectFileFormatView) {
                this.selectFileFormatView.destroy();
            }
            this.selectFileFormatView = new AutocompleteView(
                this._getAutocompleteOptionObject(FileFormatSampleLists.getAutocompleteParamForImport({
                    modelProperty: 'refFileFormat',
                    callBackOnChange: this.onRequiredFieldChange
                }))
            );
            this.getRegion('selectFileFormat').show(this.selectFileFormatView);
        },

        onRequiredFieldChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
            this.checkForLocalImportButton();
        },

        checkForLocalImportButton: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.$el.find('label#importResultFromLocal').attr('for', 'run-xml-input');
            } else {
                this.$el.find('label#importResultFromLocal').attr('for', 'blank');
            }
        },

        onBlankClick: function () {
            this.validate(this.fieldsToValidate);
        },

        onSelectFileFromCentralLib: function () {
            if (this.validate(this.fieldsToValidate)) {
                Settings.findByCode('IMPORT-SAMPLELIST-PCR').done(_.bind(function (settings) {
                    FileExplorerController.show(settings.secId, true, _.bind(this.fileUpload, this));
                }, this));
            }
        },

        onSelectFileFromComputer: function (e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.fileUpload(e.target.files[0]);
            }
        },

        fileUpload: function (fileInfo) {
            if (this.validate(this.fieldsToValidate)) {
                if (fileInfo.name.includes(this.options.model.get('name'), 0)) {
                    this.confirmUpload(fileInfo);
                } else {
                    var callback = _.bind(function (result) {
                        if (result) {
                            this.confirmUpload(fileInfo);
                        }
                    }, this);
                    var confirmParams = {
                        message: _.i18n('run.controlRunName'),
                        type: 'warning'
                    };
                    CustomBootboxMessage.customConfirm(confirmParams, callback);
                }
            }
        },

        confirmUpload: function (fileInfo) {
            this.$el.find(this.ui.blockLoading).show();
            this.options.model.importFileSampleList(fileInfo, this.model)
                .done(_.bind(function () {
                    this.box.modal('hide');
                }, this))
                .fail(_.bind(function (response) {
                    alert(response.statusText + ' -- ' + response.status);
                    this.box.modal('hide');
                }, this));
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        hide: function () {
            this.refreshWellTableView();
            this.destroy();
        },

        refreshWellTableView: function () {
            var currentRoute = App.getCurrentRoute();
            if (currentRoute.includes('details')) {
                App.trigger('well:table:pcr:refresh:jqGrid');
            }
        }
    });
});
