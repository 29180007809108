define('entities/admin/document',[
	'app',
	'backbone',
	'settings',
	'jquery',
	'underscore'
], function (
	App,
	Backbone,
	Settings,
	$,
	_
) {
	'use strict';

	var Document = Backbone.Model.extend({
		fetch: function () {
			this.url = Settings.url('compuzz', 'public/document/' + this.get('secId'));

			return Backbone.Model.prototype.fetch.call(this);
		},
		defaults: {
			'url': '',
			'name': '',
			'docAbstract': '',
			'contentType': null,
			'language': null,
			'keywords': '',
			'deleted': false,
			'validated': false
		},
		idAttribute: 'secId',

		postUrl: function () {
			return Settings.url('compuzz', 'public/document');
		},

		save: function () {
			this.url = this.postUrl();
			return Backbone.Model.prototype.save.call(this);
		}
	});

	var DocumentCollection = Backbone.Collection.extend({
		url: function () {
			return Settings.url('compuzz', 'public/document');
		},
		model: Document
	});

	var API = {

		getDocumentCollectionUrl: function () {
			new DocumentCollection();
			var params = {};
			return Settings.url('compuzz', 'public/document', params);
		},

		getAllDocument: function (params) {
			var url = Settings.url('compuzz', 'public/document', params),
				defer = $.Deferred();

			$.ajax({
				method: 'get',
				url: url,
				success: function (items) {
					var list = [];
					_.each(items, function (item) {
						list.push(item.secId);
					});
					defer.resolve(list);
				}
			});

			return defer.promise();
		},


		getDocumentCountUrl: function () {
			var params = '';
			return 'public/document/rowCount' + params;
		},

		deleteDocument: function (secId) {
			var url = Settings.url('compuzz', 'public/document/' + secId),
				defer = $.Deferred();

			$.ajax({
				type: 'DELETE',
				url: url,
				success: function (data) {
					defer.resolve(data);
				}
			});

			return defer.promise();
		},
		updateDocument: function () {

			var formdata = new FormData();
			formdata.append('secId', null);
			var jsonObjectDocumentationUrl = Settings.url('compuzz', 'public/jsondocumentation/generatepdf');
			$.ajax({
				type: 'POST',
				url: jsonObjectDocumentationUrl,
				data: formdata,
				processData: false,
				contentType: false,
				success: function () {
					formdata = new FormData();
					formdata.append('secId', null);
					var arrayUrl = Settings.url('compuzz', 'public/docglossary/generatepdf');
					$.ajax({
						type: 'POST',
						url: arrayUrl,
						data: formdata,
						processData: false,
						contentType: false,
						success: function () {
							var url = Settings.url('compuzz', 'public/document/updatefilelist');
							$.ajax({
								type: 'POST',
								url: url,
								timeout: 300000,
								success: function () {
									return '';
								}
							});
						}
					});
				}
			});
		},
		importFile: function (fileInfo) {
			var defer = $.Deferred(),
				formdata = new FormData(),
				param = {};


			var url = Settings.url('compuzz', 'public/document/importFile', param);

			formdata.append('file', fileInfo);

			$.ajax({
				type: 'POST',
				url: url,
				data: formdata,
				processData: false,
				contentType: false,
				success: function (resp) {
					defer.resolve(resp);
				},
				error: function () {
					defer.reject();
				}
			});

			return defer.promise();
		},
		getFieldsToDisplay: function () {
			return {'fieldsWidth': 350};
			// 'code' must be showed by _getAutocompleteOptions(valueProperty)
		}
	};

	App.reqres.setHandler('document:get-collection-url', function () {
		return API.getDocumentCollectionUrl();
	});

	App.reqres.setHandler('document:get-collection-to-page', function (params) {
		return API.getAllDocument(params);
	});

	App.reqres.setHandler('document:get-count-url', function () {
		return API.getDocumentCountUrl();
	});

	App.reqres.setHandler('document:model', function (itemJSON) {
		return new Document(itemJSON);
	});

	App.reqres.setHandler('document:collection', function (itemJSON) {
		return new DocumentCollection(itemJSON);
	});

	App.reqres.setHandler('document:delete', function (secId) {
		return API.deleteDocument(secId);
	});

	App.reqres.setHandler('document:update', function () {
		return API.updateDocument();
	});
	App.reqres.setHandler('document:get-fields-to-display', function () {
		return API.getFieldsToDisplay();
	});
	App.reqres.setHandler('document:import-file', function (fileInfo) {
		return API.importFile(fileInfo);
	});
});
