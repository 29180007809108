define('pcrSetupFlexibleSetupView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!pcrSetupFlexibleSetupTpl',
    'savingBehavior'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            // input: '.js-info-input',
            // blockLoading: '.empty-page-loader'
        },

        events: {
            // 'click .js-cancel-popup': 'hide',
            // 'focus @ui.input': 'onFocus',
            // 'change @ui.input': 'onInputChange',
            // 'click .js-confirm': 'onConfirm',
        },

        regions: {},

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [],

        initialize: function (options) {
            this.model = options.model;
        },

        serializeData: function () {
            return this.model.toJSON();
        },

        onShow: function () {
            $.material.init();
        },

        onRender: function () {
        },

        onConfirm: function () {
        },

        onSave: function () {
        },

        hide: function () {
        },

        onSaveError: function () {
        },

        onDuplicateCode: function () {
        }
    });
});
