define('entities/global/userpids',[
	'app',
	'jquery',
	'backbone',
	'settings',
	'module',
	'dynamic',
	'underscore',
	'backboneRelational'
], function (
	App,
	$,
	Backbone,
	Settings,
	module,
	Dynamic,
	_
) {
	'use strict';

	/**
	 * Model
	 */
	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.UserPidGlobal = Backbone.RelationalModel.extend({
		fetch: function () {
			this.url = Settings.url('compuzz', 'global/userpid/' + this.get('secId'));
			return Backbone.Model.prototype.fetch.call(this);
		},
		defaults: {
			superUser: false,
			firstName: '',
			lastName: '',
			logonId: '',
			tokenId: undefined,
			nickName: undefined,
			email: undefined,
			creatDate: null
		},
		idAttribute: 'secId',
		save: function () {
			this.url = Settings.url('compuzz', 'global/userpid');
			return Backbone.Model.prototype.save.call(this);
		},
		getEntity: function () {
			return module.exports;
		}
	});

	/**
	 * Collection
	 */
	app.ns.UserPidGlobals = Backbone.Collection.extend({
		model: app.ns.UserPidGlobal
	});

	/**
	 * REST API
	 */
	var API = {

		getAdminsUrl: function () {
			return Settings.url('compuzz', 'global/userpid');
		},

		getRowCountUrl: function () {
			return 'global/userpid/rowCount';
		},

		getAdmins: function (params) {
			var userPids = new app.ns.UserPidGlobals(),
				defer = $.Deferred();

			userPids.url = Settings.url('compuzz', 'global/userpid/', params);

			userPids.fetch().done(function (resp) {
				defer.resolve(resp);
			});

			return defer.promise();
		},

		getItem: function (secId) {
			var model = new app.ns.UserPidGlobal(),
				defer = $.Deferred();

			model.url = Settings.url('compuzz', 'global/userpid/' + secId);

			model.fetch().done(function (resp) {
				defer.resolve(resp);
			});

			return defer.promise();
		},

		postData: function (data) {
			var defer = $.Deferred();

			$.ajax({
				type: 'POST',
				url: Settings.url('compuzz', 'global/userpid'),
				data: data,
				processData: false,
				contentType: false,
				success: function (resp) {
					defer.resolve(resp);
				}
			});
			return defer.promise();
		},

		deleteItem: function (secId) {
			var defer = $.Deferred(),
				url = Settings.url('compuzz', 'global/userpid/' + secId);

			$.ajax({
				type: 'DELETE',
				url: url,
				success: function (resp) {
					defer.resolve(resp);
				}
			});

			return defer.promise();
		},
		userAccessRights: function () {
			var defer = $.Deferred();

			$.ajax({
				type: 'GET',
				url: Settings.url('compuzz', 'global/userpid/userAccessRights'),
				processData: false,
				contentType: false,
				success: function (resp) {
					defer.resolve(resp);
				}
			});
			return defer.promise();
		}
	};

	/**
	 * Request communications
	 */

	App.reqres.setHandler('caccount-admins:get-url', function (id) {
		return API.getAdminsUrl(id);
	});

	App.reqres.setHandler('caccount-admins:row-count-url', function (id) {
		return API.getRowCountUrl(id);
	});

	App.reqres.setHandler('caccount-admins:get-admins', function (params) {
		return API.getAdmins(params);
	});

	App.reqres.setHandler('caccount-admins:post-item', function (data) {
		return API.postData(data);
	});

	App.reqres.setHandler('caccount-admins:get-item', function (secId) {
		return API.getItem(secId);
	});

	App.reqres.setHandler('caccount-admins:delete-item', function (secId) {
		return API.deleteItem(secId);
	});

	App.reqres.setHandler('caccount-admins:user-access-rights', function () {
		return API.userAccessRights();
	});

	module.exports = _.defaults({
		getName: function () {
			return 'userpid';
		},
		getUrl: function () {
			return 'global/userpid';
		},
		getDynamicJson: function () {
			return 'UserpidGlobalJson';
		},
		isPublic: function () {
			return true;
		},
		getTypeDynamicJson: function () {
			return 'GLOBAL';
		},
		getJqGridColumnConfig: function () {
			var config = Dynamic.getJqGridColumnConfig();
			config.exportable = false;
			config.archivable = false;
			config.selectArchivable = false;
			config.importable = false;
			config.attachmentable = false;
			config.selectable = false;
			config.duplicable = false;
			return config;
		},
		getJqGridColumn: function (viewDynamic) {
			var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
			columns.push({
				label: _.i18n('userpid.firstName'),
				name: 'firstName',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'firstName',
				searchoptions: {clearSearch: false},
				width: 20
			}, {
				label: _.i18n('userpid.lastName'),
				name: 'lastName',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'lastName',
				searchoptions: {clearSearch: false, size: 30},
				width: 25
			}, {
				label: _.i18n('userpid.nickName'),
				name: 'nickName',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'nickName',
				searchoptions: {clearSearch: false},
				width: 15
			}, {
				label: _.i18n('userpid.logonId'),
				name: 'logonId',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'logonId',
				searchoptions: {clearSearch: false, size: 30},
				width: 40
			}, {
				label: _.i18n('userpid.email'),
				name: 'email',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'email',
				searchoptions: {clearSearch: false, size: 30},
				width: 40
			}, {
				label: _.i18n('userpid.superUser'),
				name: 'superUser',
				classes: 'dynamic-link',
				formatter: viewDynamic.booleanFormatter,
				search: false,
				index: 'superUser',
				searchoptions: {clearSearch: false},
				width: 50,
				fixed: true
			}, {
				label: _.i18n('userpid.creatDate'),
				name: 'creatDate',
				classes: 'dynamic-link',
				formatter: viewDynamic.dateTimeSecondeFormatter,
				search: true,
				index: 'creatDate',
				searchrules: {date: true},
				searchoptions: {
					dataInit: function (element) {
						$(element).datepicker({
							dateFormat: 'dd/mm/yy',
							changeYear: true,
							changeMonth: true,
							showOn: 'focus',
							prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
							nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
						});
					}
				},
				width: 25
			}, {
				label: _.i18n('userpid.logo'),
				name: 'logo',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'logo',
				searchoptions: {clearSearch: false, size: 30},
				width: 20
			}, {
				label: _.i18n('userpid.tokenId'),
				name: 'tokenId',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'tokenId',
				searchoptions: {clearSearch: false},
				width: 20
			});
			return columns;
		},
		getJqGridSidx: function () {
			return 'creatDate';
		},

		getCreateEditFieldValidate: function () {
			return [
				{name: 'firstName', type: 'required'},
				{name: 'lastName', type: 'required'},
				{name: 'logonId', type: 'required'},
				{name: 'nickName', type: 'required'},
				{name: 'email', type: 'required'}
			];
		},
		getAutocompleteParam: function (config) {
			var configuration = Dynamic.getAutocompleteParam(config, this);
			configuration.urlPath = (config.url ? config.url : this.getUrl()) + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
				'sord': 'asc',
				'sidx': 'nickName'
			}));
			return configuration;
		},
		getModel: function (modelJson) {
			if (!modelJson) {
				modelJson = {};
			}
			return app.ns.UserPidGlobal.findOrCreate(modelJson);
		},
		getFieldsToDisplay: function () {
			return {'fieldsWidth': 300, 'fields': ['email', 'firstName', 'lastName']};
		},
		getCreateEditFieldIgnore: function () {
			var ignored = Dynamic.getCreateEditFieldIgnore();
			ignored.push('online', 'password', 'logo');
			return ignored;
		},
		typeFormatter: function (cellValue, options) {
			if (cellValue === null || cellValue === undefined) {
				cellValue = '';
			}
			var caccounts = _.pluck(_.pluck(cellValue, 'caccount'), 'code');
			return '<span class="cell-default" data-row-id="' +
				options.rowId + '">' + caccounts + '</span>';
		},
		getCreateEditFieldCustom: function () {
			var custom = Dynamic.getCreateEditFieldCustom();
			custom.push(
				{
					'field': 'tokenId',
					'param': {
						readOnly: true
					}
				}
			);
			return custom;
		}
	}, Dynamic);

});
