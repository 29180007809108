
define('template!prepRunEditRunNameTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="col-xs-12 p-l-0 p-r-0">\n                <div class="col-xs-12">\n                    <div class="row">\n                        <div class="col-xs-3 dataLabelContainer">\n                            <div class="form-group" data-field-name="name">\n                                <label class="control-label dataLabel">' +
((__t = ( _.i18n('run.name')
                                    )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-xs-8 p-r-2" style="padding-left: 15px;">\n                            <input type="text" id="editRunNameField" class="form-control floating-label js-info-input"\n                                   data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n                                   value="' +
((__t = ( name )) == null ? '' : __t) +
'" data-content=""\n                                   data-placement="top" autocomplete="off">\n                        </div>\n                        <div class="col-xs-1 p-l-0 btn js-generate-runName" style="margin: 0; padding: 0; width: 28px; bottom: 0;\n                                border: solid 1px #cccccc; border-radius: 5px; height: 28px;"\n                             title="' +
((__t = ( _.i18n('run.generate.run.name') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-recycle"\n                                 style="position: relative; bottom: 8px; right: 0; font-size: 26px;"></span>\n                        </div>\n                    </div>\n                    <div class="row">\n                        <div class="col-xs-3 dataLabelContainer">\n                            <div class="form-group" data-field-name="period">\n                                <label class="control-label dataLabel">' +
((__t = ( _.i18n('run.period')
                                    )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-xs-2">\n                            <input type="datepicker" id="period"\n                                   class="form-control js-datePicker js-period"\n                                   data-field-name="period"\n                                   placeholder="" value="' +
((__t = ( period )) == null ? '' : __t) +
'">\n                        </div>\n                    </div>\n                    <div class="row">\n                        <div class="col-xs-3"></div>\n                        <div class="col-xs-9">\n                            <label class="col-xs-2 p-l-0 m-b-0 noWrapHidden">' +
((__t = ( _.i18n('run.department')
                                )) == null ? '' : __t) +
'</label>\n                            <label class="col-xs-2 p-l-0 m-b-0 noWrapHidden">' +
((__t = ( _.i18n('run.period')
                                )) == null ? '' : __t) +
'</label>\n                            <label class="col-xs-2 p-l-0 m-b-0 noWrapHidden">' +
((__t = ( _.i18n('run.runGroup.short')
                                )) == null ? '' : __t) +
'</label>\n                            <label class="col-xs-2 p-l-0 m-b-0 noWrapHidden">' +
((__t = ( _.i18n('run.sequence.short')
                                )) == null ? '' : __t) +
'</label>\n                            <label class="col-xs-4 p-l-0 m-b-0 noWrapHidden">' +
((__t = ( _.i18n('run.suffix.short')
                                )) == null ? '' : __t) +
'</label>\n                        </div>\n                    </div>\n                    <div class="row">\n                        <div class="col-xs-3 dataLabelContainer">\n                            <div class="form-group m-b-0">\n                                <label class="control-label dataLabel">' +
((__t = ( _.i18n('run.details')
                                    )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-xs-9">\n                            <div class="col-xs-2 p-l-0 js-select-runName-department"></div>\n                            <div class="col-xs-2 p-l-0 dataLabelContainer noWrapHidden"\n                                 id="periodFormatted3"\n                                 style="text-align: left; font-size: 18px;">' +
((__t = ( periodFormatted )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="col-xs-2 p-l-0 js-select-runName-runGroup"></div>\n                            <div class="col-xs-2 p-l-0">\n                                <input type="text"\n                                       class="form-control js-info-input"\n                                       data-field-name="sequence"\n                                       placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                                       value="' +
((__t = ( sequence ? sequence : '' )) == null ? '' : __t) +
'">\n                            </div>\n                            <div class="col-xs-4 p-l-0 js-runName-suffix">\n                                <input type="text"\n                                       class="form-control js-info-input"\n                                       data-field-name="suffix"\n                                       placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                                       value="' +
((__t = ( suffix )) == null ? '' : __t) +
'">\n                            </div>\n                        </div>\n                    </div>\n                    <div class="row">\n                        <div class="col-xs-3"></div>\n                        <div class="col-xs-9">\n                            <label class="col-xs-2 p-l-0 noWrapHidden"></label>\n                            <label class="col-xs-2 p-l-0 m-b-15 noWrapHidden" style="font-size: 13px;">' +
((__t = ( periodFmt
                                )) == null ? '' : __t) +
'</label>\n                            <label class="col-xs-2 p-l-0 noWrapHidden"></label>\n                            <label class="col-xs-2 p-l-0 m-b-15 noWrapHidden" style="font-size: 13px;">' +
((__t = (
                                sequenceFormat )) == null ? '' : __t) +
'</label>\n                            <label class="col-xs-4 p-l-0"></label>\n                        </div>\n                    </div>\n                    <div class="row">\n                        <div class="col-xs-3 dataLabelContainer">\n                            <div class="form-group" data-field-name="pattern">\n                                <label class="control-label dataLabel">' +
((__t = ( _.i18n('run.pattern')
                                    )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-xs-4 js-runName-pattern">\n                        </div>\n                        <div class="col-xs-4 dataLabelContainer">\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class="row cancelConfirmRow">\n                <div class="pull-right p-r-15 col-xs-12 p-l-0 p-r-0 cancelConfirmDiv"\n                     style="padding-left: 606px !important;">\n                    <button class="mdi-navigation-close btn popupBtn-cancel\n                    cancel-b js-cancel-popup"></button>\n                    <button disabled\n                            class="mdi-navigation-check btn popupBtn-confirm\n                            disabled-grey js-confirm"></button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

