define('runTabsLayoutView',[
	'module',
	'backbone',
	'autocompleteView',
	'jquery',
	'app',
	'bootbox',
	'settings',
	'underscore',
	'template!runPcrTooltipTpl',
	'envHandler',
	'marionette',
	'template!runTabsTpl',
	'runTabsLayoutHeaderView'
], function (
	module,
	Backbone,
	AutocompleteView,
	$,
	App,
	Bootbox,
	Settings,
	_, runPcrTooltipTpl,
	EnvironmentHandler,
	Marionette,
	RunTabsTpl,
	RunTabsLayoutHeaderView
) {
	'use strict';

	module.exports = Marionette.LayoutView.extend({
		template: RunTabsTpl,

		regions: {
			tabs: '.js-tabs-region',
			mainHeader: '.tab-layout-main-header-region'
		},

		serializeData: function () {
			return {};
		},

		onShow: function () {
			var opacity = '1'; //block
			if (this.currentTab === 'assays-targets' || App.getCurrentRoute().search('assays-targets') >= 0 ||
				this.currentTab === 'results' || App.getCurrentRoute().search('results') >= 0 ||
				this.currentTab === 'contaminations' || App.getCurrentRoute().search('contaminations') >= 0 ||
				this.currentTab === 'tracs' || App.getCurrentRoute().search('tracs') >= 0) {
				opacity = '0.2'; //none
			}

			this.RunTabsLayoutHeaderView = new RunTabsLayoutHeaderView({
				model: this.options.model,
				tabs: this.options.tabs,
				edit: this.options.edit,
				from: this.options.from,
				step: this.options.step,
				to: this.options.to,
				opacity: opacity,
				tabName: this.options.tabName,
				runTabsLayoutController: this.options.runTabsLayoutController
				//reports: reports
			});
			this.getRegion('mainHeader').show(this.RunTabsLayoutHeaderView);
			this.RunTabsLayoutHeaderView.triggerMethod('show:active', this.options.tabName, '');
		}
	});
});
