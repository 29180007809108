
define('template!assayReagentMbAnaPcrKitLotByPcrWell', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n\t<div class="col-xs-12" style="display: flex; align-items: baseline; justify-content: space-between;">\n\t\t<div class="col-xs-3 col-labelContainer noWrapHidden"\n\t\t\t style="font-size: 20px;">' +
((__t = ( smpTypeIcon )) == null ? '' : __t) +
' ' +
((__t = ( displayedKitCode )) == null ? '' : __t) +
' (' +
((__t = ( displayedKitLotId )) == null ? '' : __t) +
')\n\t\t</div>\n\t\t<div class="col-xs-6 col-labelContainer noWrapHidden t-a-c">\n\t\t\t<span style="font-size: 25px; font-weight: bold;">' +
((__t = ( title )) == null ? '' : __t) +
'</span>\n\t\t\t<span style="font-size: 20px;">' +
((__t = ( target )) == null ? '' : __t) +
'</span>\n\t\t</div>\n\t\t<div class="col-xs-3 col-labelContainer noWrapHidden">\n\t\t</div>\n\t</div>\n</div>\n<div class="js-show-graphics-region" style="border-top: #c6c6c6 1px solid;">\n</div>\n\n';

}
return __p
};});

