define('menuFooter',[
    'module',
    'marionette',
    'menuItemView',
    'underscore',
    'dateUtils',
    'settings'
], function (
    module,
    Marionette,
    ItemView,
    _,
    DateUtils,
    Settings
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: _.template('<div class="m-l-10 m-r-10" title="Backend : <%= BVersion %> (<%= BDate %>) | Frontend : <%= FVersion %> (<%= FDate %>)">\n' +
            '    <label class="small">Backend: <%= BVersion %></label><br/>\n' +
            '    <label class="small">Frontend: <%= FVersion %></label>\n' +
            '</div>'),
        serializeData: function () {
            var templateData = {};
            templateData.BVersion = this.options.information.version;
            templateData.BDate = this.options.information.date ? DateUtils.toDateTimeFormatString(this.options.information.date) : '';
            templateData.FVersion = Settings.get('version');
            templateData.FDate = DateUtils.toDateTimeFormatString(Settings.get('date'));
            return templateData;
        }
    });
});
