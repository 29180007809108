define('generateSampleId',[
    'module',
    'dialogFormView',
    'underscore',
    'jquery',
    'template!generateSampleId',
    'backbone',
    'entities/caccounts/sampleClass',
    'dynamicCreateEditCodeList',
    'marionette',
    'wellSmpTypeView',
    'savingBehavior'
], function (
    module,
    DialogFormView,
    _,
    $,
    Tpl,
    Backbone,
    SampleClasses,
    CodeListView,
    Marionette,
    SmpTypeView,
    SavingBehavior
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'change .js-sample': 'onChangeSample'
        },

        modelEvents: {
            'change:sampleId': 'onChangeSampleId',
            'change:response': 'render',
            'change:step': 'onChangeSampleId',
            'change:firstStep': 'onChangeSampleId',
            'change:sourceStart': 'onChangeSampleId',
            'change:sourceEnd': 'onChangeSampleId',
            'change:plateFillDirection': 'onChangeSampleId'
        },

        regions: {
            plateFillDirection: '.js-plate-direction-region',
            collection: '.js-collection',
            smpTypeRegion: '.js-select-smpType'
        },

        fieldsToValidate: [
            {name: 'response', type: 'required'}
        ],

        behaviors: {
            SavingBehavior: {
                behaviorClass: SavingBehavior
            }
        },

        initialize: function () {
            this.model = new Backbone.Model({
                sampleId: null,
                sourceStart: 'A01',
                sourceEnd: 'H12',
                plateFillDirection: this.options.plateFillDirection,
                step: 1,
                firstStep: 1,
                response: null
            });
        },
        serializeData: function () {
            var templateData = {};
            templateData.sampleId = this.model.get('sampleId');
            templateData.sourceStart = this.model.get('sourceStart');
            templateData.sourceEnd = this.model.get('sourceEnd');
            templateData.step = this.model.get('step');
            templateData.firstStep = this.model.get('firstStep');
            templateData.sampleClass = {
                code: '-',
                name: '-',
                orderPrefix: null,
                orderIncrement: null
            };
            if (this.model.get('response') && this.model.get('response').sampleClass) {
                var order = this.model.get('response').sampleClass.regexOrderId;

                if (order) {
                    var positionsSplitted = order.split('-');
                    var correspondance = null;
                    var end = 0;
                    if (positionsSplitted.length > 1) {
                        try {
                            end = parseInt(positionsSplitted[1], 10);
                            if (end >= templateData.sampleId.length) {
                                end = templateData.sampleId.length;
                            }
                            correspondance = templateData.sampleId.substring(0, end);
                        } catch (ignored) {
                            // Ignored exception
                        }
                    }
                    if (positionsSplitted.length === 1) {
                        try {
                            correspondance = templateData.sampleId.substring(parseInt(positionsSplitted[0], 10) - 1);
                            end = templateData.sampleId.length;
                        } catch (ignored) {
                            // Ignored exception
                        }
                    }

                    if (correspondance !== null) {
                        templateData.sampleClass.orderPrefix = correspondance;
                        templateData.sampleClass.orderIncrement = templateData.sampleId.replace(templateData.sampleId.substring(0, end), '');
                    }
                }
                templateData.sampleClass.code = this.model.get('response').sampleClass.code;
                templateData.sampleClass.name = this.model.get('response').sampleClass.name;
            }
            return templateData;
        },

        onRender: function () {
            this.getRegion('plateFillDirection').show(new CodeListView({
                model: this.model,
                config: {
                    modelProperty: 'plateFillDirection',
                    code: 'PLATE_FILL_DIRECTION'
                }
            }));
            this.selectView = new SmpTypeView({
                smpType: this.model.get('response') && this.model.get('response').sampleClass ? this.model.get('response').sampleClass.sampleType : 'D'
            });
            this.getRegion('smpTypeRegion').show(this.selectView);
            if (this.model.get('response')) {
                var ItemModel = Backbone.Model.extend({
                    defaults: {
                        pos: '',
                        value: ''
                    }
                });
                var ItemCollection = Backbone.Collection.extend({
                    model: ItemModel
                });
                var ItemView = Marionette.ItemView.extend({
                    tagName: 'li',
                    template: _.template('<%= pos %> : <%= value %>')
                });
                var ListView = Marionette.CollectionView.extend({
                    tagName: 'ul',
                    childView: ItemView
                });

                var items = new ItemCollection();
                for (var i = 0; i < this.model.get('response').sampleIds.length && i < 5; i++) {
                    var sampleId = this.model.get('response').sampleIds[i];
                    items.push(new ItemModel({pos: sampleId.position, value: sampleId.sampleId}));
                }
                if (this.model.get('response').sampleIds.length >= 5) {
                    items.push(new ItemModel({pos: '...', value: '...'}));
                    var lastSampleId = this.model.get('response').sampleIds[this.model.get('response').sampleIds.length - 1];
                    items.push(new ItemModel({pos: lastSampleId.position, value: lastSampleId.sampleId}));
                }
                var listView = new ListView({
                    collection: items
                });
                this.getRegion('collection').show(listView);
                this.triggerMethod('enable:cancel:confirm');
            }
        },
        onChangeSampleId: function () {
            SampleClasses.generate({
                sampleId: this.model.get('sampleId').replaceAll('#', '$'),
                firstStep: this.model.get('firstStep'),
                step: this.model.get('step'),
                plateSize: this.options.plateSize,
                plateFillDirection: this.model.get('plateFillDirection'),
                from: this.model.get('sourceStart'),
                to: this.model.get('sourceEnd')
            }).done(_.bind(function (response) {
                this.model.set('response', response);
            }, this))
                .fail(_.bind(function () {
                    this.model.set('response', null);
                }, this));
        },

        onConfirm: function () {
            if (this.model.get('response').sampleIds) {
                this.trigger('confirm', this.model.get('response').sampleIds);
            }
            this.hide();
        }
    });
});
