define('entities/caccounts/colorCompensations',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'dynamic',
    'module',
    'entities/caccounts/assay'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    Dynamic,
    module
) {
    'use strict';


    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.ColorCompensation = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/colorCompensations/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'comment': '',
            'temperature': null,
            'sampleIdWater': null,
            'sampleIds': '',
            'matrices': [],
            'assays': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'matrices',
            relatedModel: 'ColorCompensationMatrice',
            collectionType: 'ColorCompensationMatriceCollection',
            reverseRelation: {
                key: 'colorCompensation',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }],
        initialize: function () {
            this.on('change:sampleIds', this.onSampleIdsChange, this);
        },
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/colorCompensations');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ColorCompensationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ColorCompensation',
        getEntity: function () {
            return module.exports;
        },
        importable: true,
        onSampleIdsChange: function () {
            var sampleIds = this.get('sampleIds').split(';');
            var index = 0;
            var max = 0;
            _.each(sampleIds, function (sample) {
                if (sample) {
                    max = index;
                }
                index++;
            });
            this.get('matrices').each(function (matrice) {
                matrice.maxValue(max);
                matrice.trigger('change:values');
            });
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'colorCompensation';
        },
        getUrl: function () {
            return 'v2/colorCompensations';
        },
        getDynamicJson: function () {
            return 'ColorCompensationJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 30
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 50
            }, {
                label: _.i18n('colorCompensation.temperature'),
                name: 'temperature',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'temperature',
                width: 10
            }, {
                label: _.i18n('colorCompensation.sampleIdWater'),
                name: 'sampleIdWater',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'sampleIdWater',
                width: 30
            }, {
                label: _.i18n('colorCompensation.sampleIds'),
                name: 'sampleIds',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'sampleIds',
                width: 100
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {'field': 'sampleIds', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'CC_SAMPLE'}},
                {'field': 'temperature', 'param': {'type': 'INTEGER', 'display': {'class': 'col-xs-1'}}},
                {'field': 'sampleIdWater', 'param': {'type': 'STRING', 'display': {'class': 'col-xs-2'}}},
                {'field': 'lmbCyclers', 'param': {'type': 'LISTTOLIST'}},
                {
                    'field': 'matrices', 'param': {'type': 'LIST'},
                    afterPush: function (model) {
                        model.get('colorCompensation').onSampleIdsChange();
                    }
                },
                {
                    'field': 'assays', 'param': {'type': 'LIST2ENTITY'}
                }
            );
            return custom;
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.ColorCompensation.findOrCreate(modelJson);
        }
    }, Dynamic);
});
