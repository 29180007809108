
define('template!runContaminationsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content well info-page ' +
((__t = ( isMultiDisplay ? 'p24' : '')) == null ? '' : __t) +
'" id="pcrRuns">\n    <div class="contaminationSelectTarget">\n        <span class="mdi mdi-arrow-left disabled-grey" style="display: none;"></span>\n        <select class="target">\n        </select>\n        <span class="mdi mdi-arrow-right clickable" style="display: none;"></span>\n    </div>\n    <!-- run-filters hidden -->\n    <div class="run-filters row form-horizontal hidden">\n        <div class="col-xs-10">\n            <div class="form-group col-md-6 col-lg-3">\n                <label class="col-xs-3 control-label" for="filter-smpId">' +
((__t = ( _.i18n('run.filters.smpId') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text"\n                           class="form-control floating-label select-smpId"\n                           placeholder="' +
((__t = ( _.i18n('run.filters.smpId') )) == null ? '' : __t) +
'"\n                           id="filter-smpId">\n                </div>\n            </div>\n            <div class="form-group col-md-6 col-lg-3">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('run.filters.kit') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <div class="js-select-assay-region"></div>\n                </div>\n            </div>\n            <div class="form-group col-md-6 col-lg-3">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('run.filters.mbana') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <div class="js-select-mbana-region"></div>\n                </div>\n            </div>\n            <div class="form-group col-md-6 col-lg-3">\n                <label class="col-xs-3 control-label" for="filter-result">' +
((__t = ( _.i18n('run.filters.result') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label select-result"\n                           placeholder="' +
((__t = ( _.i18n('run.filters.result') )) == null ? '' : __t) +
'"\n                           id="filter-result">\n                </div>\n            </div>\n        </div>\n        <div class="col-xs-2">\n            <label class="btn add-btn-text btn-sm search-well-button">\n                <span class="mdi mdi-magnify"></span>\n                <span>' +
((__t = ( _.i18n("run.filters.search") )) == null ? '' : __t) +
'</span>\n            </label>\n        </div>\n    </div>\n    <!-- / run-filters hidden -->\n\n    <!-- js-kitl-table-details-for-printing-container -->\n    <div class="js-kitl-table-details-for-printing-container">\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( name  )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('runList.runModDate') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( creatDate ? creatDate : '-' )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('runList.refRespUser') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( creatUser )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('run.refKitProt') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( kitProt )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('run.refLmbCycler') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( lmbCycler )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('run.plateIdPC') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( plateIdPC )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( comment )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n    </div>\n    <!--/ js-kitl-table-details-for-printing-container -->\n\n\n    <div class="container-fluid well-container js-print-table p-t-1">\n        <div class="row borderNone">\n            <div class="col-xs-1" style="width: 2%;">\n                <div class="row m-b-0 clickable">\n                    <div class="wellTableCornerTitle" style="height: 30px;">\n                        ';
 if(isMultiDisplay) { ;
__p += '\n                        <div class="" style="">\n                            <div class="js-top-left ' +
((__t = ( firstWellPos === 'A01' && isGrouping ? 'mdi mdi-square' : 'mdi mdi-square-outline' )) == null ? '' : __t) +
'"\n                                 style="padding: 0; margin: 0; font-size: 20px; position: relative; bottom: 20px;"\n                            ></div>\n\n                            <div class="js-top-right ' +
((__t = ( firstWellPos === 'A02' && isGrouping ? 'mdi mdi-square' : 'mdi mdi-square-outline' )) == null ? '' : __t) +
'"\n                                 style="padding: 0; margin: 0; font-size: 20px; position: relative; bottom: 50px; right: -17px;"\n                            ></div>\n                        </div>\n                        <div class="" style="">\n                            <div class="js-bottom-left ' +
((__t = ( firstWellPos === 'B01' && isGrouping ? 'mdi mdi-square' : 'mdi mdi-square-outline' )) == null ? '' : __t) +
'"\n                                 style="padding: 0; margin: 0; font-size: 20px; position: relative; bottom: 62px;"\n                            ></div>\n\n                            <div class="js-bottom-right ' +
((__t = ( firstWellPos === 'B02' && isGrouping ? 'mdi mdi-square' : 'mdi mdi-square-outline' )) == null ? '' : __t) +
'"\n                                 style="padding: 0; margin: 0; font-size: 20px; position: relative; bottom: 91px; right: -17px;"\n                            ></div>\n                        </div>\n                        ';
 } ;
__p += '\n                    </div>\n                </div>\n\n                ';
 _.each(rows, function(row) {;
__p += '\n                <div class="row m-b-0 clickable">\n                    <div class="wellTableLineTitleItem col-xs-12 text-center">' +
((__t = ( row )) == null ? '' : __t) +
'</div>\n                </div>\n                ';
 }) ;
__p += '\n            </div>\n\n            <div class="col-xs-11 clickable" style="width: 97%;">\n                <div class="row m-b-0">\n                    ';
 _.each(columns, function(column) {;
__p += '\n                    <div class="wellTableColumnTitleItem text-center"\n                         style="width: ' +
((__t = ( width )) == null ? '' : __t) +
'%; float: left;">' +
((__t = ( ("0" + column).slice(-2) )) == null ? '' : __t) +
'\n                    </div>\n                    ';
 }) ;
__p += '\n                </div>\n                ';
 _.each(rows, function(row) {;
__p += '\n                <div class="row m-b-0">\n                    ';
 _.each(columns, function(column) {
                    var pos = row + ("0" + column).slice(-2); ;
__p += '\n                    <div class="wellTableItem tbl-' +
((__t = ( pos )) == null ? '' : __t) +
'" style="width: ' +
((__t = ( width )) == null ? '' : __t) +
'%; float: left;"></div>\n                    ';
 }) ;
__p += '\n                </div>\n                ';
 }) ;
__p += '\n            </div>\n        </div>\n    </div>\n    <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

