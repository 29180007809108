define('wellTargetPropCPopupView',[
    'module',
    'backbone',
    'dialogFormView',
    'app',
    'autocompleteView',
    'assayResultCollectionView',
    'arrayCustomCurveNoEditView',
    'arrayCriCurveNoEditView',
    'settings',
    'underscore',
    'jquery',
    'template!wellTargetPropCPopupTpl',
    'runDetailsGraphView',
    'cssUtils'
], function (
    module,
    Backbone,
    DialogFormView,
    App,
    AutocompleteView,
    AssayResultCollectionView,
    ArrayCustomCurveNoEditView,
    ArrayCriCurveNoEditView,
    Settings,
    _,
    $,
    WellTargetPropCPopupTpl,
    RunGraphView,
    CssUtils
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: WellTargetPropCPopupTpl,

        regions: {
            targetAmplGraphView: '#targetAmplGraphView',
            selectAlgoRes: '.js-select-algores-region',
            algoParam: '.js-algoParam'
        },

        events: {
            'click .rawData.clickable': 'onRawDataClick',
            'click .fluorData.clickable': 'onFluorDataClick'
        },

        onRawDataClick: function () {
            this.model.downloadRawData();
        },

        onFluorDataClick: function () {
            this.model.downloadFluorData();
        },

        onShow: function () {
            $.material.init();
            document.addEventListener('keydown', _.bind(function (event) {
                var key = event.key;
                if (key === 'Escape' || key === 'Enter') {
                    this.box.modal('hide');
                }
            }, this));

            var graphToDisplay = this.model.get('refPcrWell').getGraphToDisplay();

            if (graphToDisplay.indexOf('A') !== -1) {
                if (this.targetAmplGraphView) {
                    this.targetAmplGraphView.destroy();
                }
                this.targetAmplGraphView = new RunGraphView({
                    type: 'line',
                    procView: true,
                    showPropC: true
                });
                this.targetAmplGraphView.showPropC = true;
                this.targetAmplGraphView.model = this.model;
                this.targetAmplGraphView.slopeEnable = false;
                this.targetAmplGraphView.brutEnable = true;
                this.targetAmplGraphView.amplGraph = true;
                this.targetAmplGraphView.yLabel = _.i18n('well.graph.legend.cycle');
                this.targetAmplGraphView.xLabel = _.i18n('well.graph.legend.fluo');
                this.targetAmplGraphView.withoutCC = false;
                this.targetAmplGraphView.displayLegend = this.withLegend;
                this.targetAmplGraphView.raw = false;
                if (this.runAt) {
                    this.targetAmplGraphView.baseline = this.runAt.baseline;
                    this.targetAmplGraphView.threshold = this.runAt.threshold;
                }
            }

            this.onShowAlgoRes();

            this.getRegion('targetAmplGraphView').show(this.targetAmplGraphView);
            $('.wellTargetPropC-popup #targetAmplGraphView .col-xs-12.chart-height').css('height', '316px');
        },

        onShowAlgoRes: function () {
            switch (this.resultModel.get('algoResult').type) {
                case 'MUTV':
                case 'AMP':
                    this.algoParamView = new ArrayCustomCurveNoEditView({
                        model: this.resultModel
                    });
                    this.getRegion('algoParam').show(this.algoParamView);
                    break;
                case 'CRI':
                    this.algoParamView = new ArrayCriCurveNoEditView({
                        model: this.resultModel
                    });
                    this.getRegion('algoParam').show(this.algoParamView);
                    break;
                case 'COMB':
                case 'COMBCUTOFF':
                case 'STD':
                    break;
                default:
                    // ArrayInputView
                    break;
            }
            this.triggerMethod('enable:cancel:confirm');
        },

        serializeData: function () {
            var templateData = {
                refPcrWell: {
                    runName: this.model.get('refPcrWell').get('runName'),
                    assay: this.model.get('refPcrWell').get('refAssay') ? this.model.get('refPcrWell').get('refAssay').get('code') : '',
                    pos: this.model.get('refPcrWell').get('pos')
                },
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : '',
                result: this.model.get('result') ? this.model.get('result').get('code') : '',
                cts: this.model.get('cts').join(';'),
                quantifications: this.model.get('quantifications').join(';'),
                codeErr: this.model.get('codeErr'),
                commentErrAuto: this.model.get('commentErrAuto'),
                propCformated: this.model.get('propCformated')
            };

            var assayVersion = this.model.get('refPcrWell').get('refPcrRun').get('assayVersions').findWhere({secId: this.model.get('refPcrWell').get('assayVersion').get('secId')});
            this.resultModel = assayVersion.get('results').findWhere({id: this.model.get('refAssayResult').get('id')});
            templateData.algoRes = this.resultModel.get('algoResult').type;

            var prop1Color = CssUtils.getPropertyValue('--css-propC-1');
            var prop2Color = CssUtils.getPropertyValue('--css-propC-2');

            var htmlEl = '<div style="font-size: 18px;">';
            var first = true;
            _.each(this.model.get('propC'), function (key, value) {

                var style = '';
                if (value === 'Amp1' || value === 'C1') {
                    style = 'style="color:' + prop1Color + '"';
                } else if (value === 'Amp2' || value === 'C2') {
                    style = 'style="color:' + prop2Color + '"';
                }

                if (!first) {
                    htmlEl += '&nbsp; &nbsp;';
                }
                htmlEl +=
                    '<label ' + style + '>' + value + ': &nbsp; </label>' + key + ',';
                first = false;
            });
            htmlEl = htmlEl.substring(0, htmlEl.length - 1);
            htmlEl += '</div>';
            templateData.propCformated = htmlEl;
            return templateData;
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        hide: function () {
            this.destroy();
        }
    });
});
