
define('template!assayReagentMMXTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12 m-0 p-0">\n\t<h2 class="m-0 m-b-5 col-xs-12 p-0">' +
((__t = ( _.i18n('assay.reagent.mmx.title') )) == null ? '' : __t) +
'</h2>\n</div>\n<div class="col-xs-1 p-r-2 p-l-2" style="width: 24px;">\n\t<span class="mdi mdi-plus-circle action clickable js-add" style="line-height: 24px; font-size: 24px;"></span></div>\n<div class="col-xs-4 p-r-4 p-l-2">' +
((__t = ( _.i18n('assayReagent.kit.title') )) == null ? '' : __t) +
'</div>\n<div class="col-xs-2 p-r-2 p-l-2">' +
((__t = ( _.i18n('assayReagent.reag.title') )) == null ? '' : __t) +
'</div>\n<div class="col-xs-2 p-r-2 p-l-2">' +
((__t = ( _.i18n('assayReagent.vol.title') )) == null ? '' : __t) +
'</div>\n<div class="col-xs-2 p-r-2 p-l-2">' +
((__t = ( _.i18n('assayReagent.comm.title') )) == null ? '' : __t) +
'</div>\n<div class="collection col-xs-12 p-0 m-b-5"></div>\n\n<div class="col-xs-7 dataLabelContainer p-r-2 p-l-2">\n\t<label class="control-label p-r-5">' +
((__t = ( _.i18n('assay.volumeMMX')
		)) == null ? '' : __t) +
'</label>\n</div>\n<div class="col-xs-2 p-r-2 p-l-2">\n\t<input type="number" step="0.01"\n\t\t   class="js-info-input form-control inputBackground"\n\t\t   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n\t\t   value="' +
((__t = ( volumeMMX )) == null ? '' : __t) +
'" disabled="disabled"/>\n</div>\n<div class="col-xs-3 p-l-2"></div>';

}
return __p
};});

