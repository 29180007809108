define('entities/caccounts/assaygroup',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/userCodelist',
    'template!TooltipRoutingGroupCondition'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    UserCodeLists,
    ConditionTooltips
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.AssayGroup = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/assayGroup/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'defaultAssay': null,
            'condition': null,
            'criterias': [],
            'assays': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasOne,
            key: 'defaultAssay',
            relatedModel: 'Assay'
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/assayGroup');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'AssayGroupJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayGroup',
        getEntity: function () {
            return module.exports;
        },
        importable: true,
        validateCondition: function (condition) {
            var params = {
                condition: condition
            };

            var url = Settings.url('compuzz', 'v2/assayGroup/validateCondition', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.ns.AssayGroupCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/assayGroup/');
        },
        model: app.ns.AssayGroup,
        comparator: function (a, b) {
            if (a.get('code') === '') {
                return 1;
            }
            return a.get('code') < b.get('code');
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'assaygroup';
        },
        getUrl: function () {
            return 'v2/assayGroup';
        },
        getDynamicJson: function () {
            return 'AssayGroupJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('assayGroup.defaultAssay'),
                name: 'defaultAssay',
                formatter: viewDynamic.codeNameFormatter,
                search: false,
                index: 'defaultAssay',
                width: 40
            }, {
                label: _.i18n('assayGroup.assays'),
                name: 'assays',
                formatter: viewDynamic.assayFormatter,
                search: true,
                index: 'assays',
                width: 80
            }, {
                label: _.i18n('common.criterias'),
                name: 'criterias',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'criterias',
                width: 40
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('validCondition'),
                name: 'validCondition',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                width: 10
            }, {
                label: _.i18n('condition'),
                name: 'condition',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.AssayGroup.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 350, 'fields': ['name']};
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration();
            configuration.done(function (resp) {
                resp.push({
                    fieldName: 'criterias',
                    values: UserCodeLists.findItemsByCode('FCRIT_GASSAY'),
                    codeListCode: 'FCRIT_GASSAY'
                });
                defer.resolve(resp);
            });
            return defer.promise();
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore();
            ignored.push('validCondition');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {field: 'defaultAssay', param: {display: {'class': 'col-xs-3'}}});
            custom.push(
                {field: 'assays', param: {type: 'LIST2ENTITY'}});
            custom.push(
                {
                    'field': 'condition',
                    'param': {
                        'type': 'TEXTAREA',
                        'display': {tooltip: ConditionTooltips}
                    },
                    'validate': function (model) {
                        return model.validateCondition(btoa(model.get('condition')));
                    }
                }
            );
            return custom;
        }
    }, Dynamic);
});
