define('prepRunWellDetailView',[
	'module',
	'app',
	'template!prepRunWellDetailTpl',
	'underscore',
	'jquery',
	'dialogFormView',
	'prepRunHeaderWellView',
	'prepRunDisplayKitsLotsView',
	'entities/caccounts/prepwells'
], function (
	module,
	App,
	wellDetailTpl,
	_,
	$,
	DialogFormView,
	HeaderWellView,
	PrepRunDisplayKitsLotsView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: wellDetailTpl,

		ui: {
			tabs1: '.tabs',
			ccRadio: '.cc-radio'
		},

		events: {
			'change @ui.ccRadio': 'onCCRadioChange'
		},

		regions: {
			headerWell: '#header-region'
		},

		initialize: function (options) {
			this.channelToDisplay = options.channelToDisplay;
			this.readOnly = options.readOnly;
			this.cSelection = options.cSelection;
		},

		onShow: function () {
			$.material.init();
			this.refreshView();
		},

		refreshView: function () {
			App.request('well:pcr:getWell:fromPrepWell', this.model.get('secId'))
				.done(_.bind(function (pcrWellsFromPrep) {
					if (this.headerWellView) {
						this.headerWellView.destroy();
					}
					this.headerWellView = new HeaderWellView({
						model: this.model,
						channelToDisplay: this.channelToDisplay,
						readOnly: this.readOnly,
						cSelection: this.cSelection,
						pcrWellsFromPrep: pcrWellsFromPrep
					});
					var region = this.getRegion('headerWell');
					if (region) {
						region.show(this.headerWellView);
						this.listenTo(this.headerWellView, 'wellDetail:onModelChange', _.bind(this.onModelChange, this));
						this.listenTo(this.headerWellView, 'wellDetail:destroy', _.bind(this.destroywellDetailPopup, this));
						this.listenTo(this.headerWellView, 'wellDetail:showKitsLotsPopup', _.bind(this.showKitsLotsPopup, this));
					}
				}, this));
		},

		destroywellDetailPopup: function () {
			this.box.modal('hide');
		},

		onModelChange: function (model) {
			var well = App.request('well:prep:model', {secId: model.get('secId')});
			well.fetch().done(_.bind(function () {
				this.model = well;
				this.refreshView();
			}, this));
		},

		serializeData: function () {
			var templateData = {};
			templateData.fromDetailsPopup = this.detailsPopup;
			return templateData;
		},

		showKitsLotsPopup: function () {
			this.model.get('refPrepRun').getKitLots().done(_.bind(function (prepRunKitLot) {
				var kitLotView = new PrepRunDisplayKitsLotsView({
					model: this.model,
					extractionKitLots: prepRunKitLot.extractionKitLots
				});

				kitLotView.show({
					title: _.i18n('prep.run') + '&nbsp;&nbsp;&nbsp;' + this.model.runName + '&nbsp;&nbsp;&nbsp;' + _.i18n('prep.run.well.kitlot'),
					className: 'editPrepRunWellComment'
				});
			}, this));
		}
	});
});
