define('wellDisplaySampleView',[
        'module',
        'dialogFormView',
        'underscore',
        'jquery',
        'entities/caccounts/samples',
        'bootbox'
    ], function (
        module,
        DialogFormView,
        _,
        $,
        Samples,
        Bootbox
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: _.template('<%= sample %>'),
            tagName: 'span',
            className: function () {
                var classList = 'cs-sample-font';
                if (this.model.get('test')) {
                    classList += ' well-sample-test';
                }
                if (this.options.plateView) {
                    classList += ' plateView';
                }
                if (!this.options.plateView && (this.model.get('smpId').get ? (this.model.get('smpId').get('secId') && this.model.get('smpId').get('secId') !== '') : this.model.get('smpId').get('secId'))) {
                    classList += ' clickable';
                }
                if (this.model.get('smpId').get('verified') === 'NOT_VALIDATED' || this.model.get('smpId').get('entityError')) {
                    classList += ' error';
                }
                return classList;
            },

            events: {
                'click .clickable': 'onClickSample'
            },

            onClickSample: function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                $('.js-global-loader').show();
                this.model.get('smpId').fetch().done(_.bind(function () {
                    if (!this.model.get('smpId').get('secId')) {
                        Bootbox.alert(_.i18n('quickSearch.sample.notFound'));
                    } else {
                        require(['samplesController'], _.bind(function (Controller) {
                            Controller.showDetails(this.model.get('smpId'));
                        }, this));
                    }
                }, this))
                    .always(function () {
                        $('.js-global-loader').hide();
                    });
            },

            serializeData: function () {
                return {
                    sample: this.model.get('smpId').get ? (this.options.shortted && this.model.get('smpId').get('shortId') ?
                            this.model.get('smpId').get('shortId') : this.model.get('smpId').get('name')) :
                        this.model.get('smpId').shortId ? this.model.get('smpId').shortId : this.model.get('smpId').name
                };
            }
        });
    }
)
;
