
define('template!dynamicExtractionMethodExtractor', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (manual) { ;
__p += '\n<span class="mdi mdi-hand-left" style="line-height: 26px;font-size: 27px;"></span>' +
((__t = ( _.i18n('extractionMethod.manual') )) == null ? '' : __t) +
'\n';
 } else { ;
__p += '\n<div class="js-extractors"></div>\n';
 } ;


}
return __p
};});

