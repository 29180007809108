define('arrayCustomCurveView',[
    'module',
    'underscore',
    'autocompleteView',
    'dialogFormView',
    'template!arrayCustomCurveView',
    'app',
    'entities/admin/dye',
    'arrayEncodeEditLayoutController',
    'backbone',
    'arrayInputView'
], function (
    module,
    _,
    AutocompleteView,
    DialogFormView,
    Tpl,
    App,
    Dyes,
    ArrayEncodeEditLayoutController,
    Backbone,
    ArrayInputView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            autocomplete: '.js-autocomplete',
            input: '.js-input'
        },

        modelEvents: {
            'change:param': 'updateModel'
        },

        initialize: function () {
            this.model = new Backbone.Model(this.options.model.get('algoResult'));
            var split = this.model.get('param') ? this.model.get('param').split(';') : [];
            this.model.set('dyeName', null);
            if (split.length > 0) {
                this.model.set('dyeName', split[0]);
            }
            if (split.length > 1) {
                this.model.set('param', _.rest(split).join(';'));
            }
        },
        serializeData: function () {
            return {dyeName: this.model.get('dyeName')};
        },
        onRender: function () {
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(Dyes.getAutocompleteParam({
                        modelProperty: 'dyeName',
                        callBackOnChange: this.onChangeDye,
                        valueProperty: 'shortName',
                        queryProperty: 'shortName',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'channel',
                            'cyclerSecId': this.options.cyclerPublicSecId
                        }
                    })
                ));
            this.getRegion('autocomplete').show(view);
            var param = '';
            if (this.model.get('param')) {
                if (this.model.get('param').startsWith(this.model.get('dyeName'))) {
                    param = this.model.get('param').substring(this.model.get('dyeName').length + 1);
                } else {
                    param = this.model.get('param');
                }
            }
            this.getRegion('input').show(new ArrayInputView({
                model: this.model,
                field: {
                    field: 'param',
                    param: {arrayCode: this.model.get('type')},
                    forceValue: param
                }
            }));
        },
        onChangeDye: function (fieldName, model) {
            this.onChange(fieldName, model ? model.get('shortName') : null);
            this.updateModel();
        },
        updateModel: function () {
            var data = {};
            data.algoResult = {
                type: this.model.get('type'),
                param: this.model.get('dyeName') + (this.model.get('param') ? ';' + this.model.get('param') : '')
            };
            this.options.model.set(data, {silent: true});
            this.options.model.trigger('change:algoResult');
        }
    });
});
