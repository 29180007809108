define('runExportSampleListView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!runExportSampleListView',
    'savingBehavior',
    'underscore',
    'jquery',
    'settings',
    'customBootboxMessage',
    'runRemoteFolderView',
    'autocompleteView',
    'entities/caccounts/fileFormatSampleLists',
    'entities/caccounts/pcrwells',
    'entities/caccounts/pcrruns'
], function (
    module,
    Backbone,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    Settings,
    CustomBootboxMessage,
    RunRemoteFolderView,
    AutocompleteView,
    FileFormatSampleLists
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            exportFileToCentralLib: '.exportFileToCentralLib',
            exportFileToLocal: '.exportFileToLocal',
            blockLoading: '.empty-page-loader'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',

            'click @ui.exportFileToCentralLib': 'onExportFileToCentralLib',
            'click @ui.exportFileToLocal': 'onExportFileToLocal'
        },

        regions: {
            selectFileFormat: '.js-select-fileFormat-region'
        },

        fieldsToValidate: [
            {name: 'refFileFormat', type: 'required'}
        ],

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('refFileFormat', null);
            this.model.set('accept', '.xlsx,.xls,.xml,.rdml,.csv');
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            return templateData;
        },

        onShow: function () {
            $.material.init();
            this.showSelectFileFormatView();
        },

        showSelectFileFormatView: function () {
            if (this.selectFileFormatView) {
                this.selectFileFormatView.destroy();
            }
            this.selectFileFormatView = new AutocompleteView(
                this._getAutocompleteOptionObject(FileFormatSampleLists.getAutocompleteParamForExport({
                    modelProperty: 'refFileFormat',
                    callBackOnChange: this.onRequiredFieldChange
                }))
            );
            this.getRegion('selectFileFormat').show(this.selectFileFormatView);
        },

        onRequiredFieldChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
        },

        onExportFileToCentralLib: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.remoteFileDownload(this.options.model.get('secId'))
                    .done(function () {
                        CustomBootboxMessage.customThumbUpNotification();
                    })
                    .fail(function () {
                        CustomBootboxMessage.customThumbDownNotification();
                    })
                    .always(_.bind(function () {
                        this.box.modal('hide');
                    }, this));
            }
        },

        remoteFileDownload: function (secId) {
            var defer = $.Deferred(),
                url;
            url = Settings.url('compuzz', 'v2/runs/pcr/' + secId + '/sampleList/export', {
                fileFormatSecId: this.model.get('refFileFormat').secId,
                downloadFile: false
            });
            $.ajax({
                type: 'GET',
                url: url,
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        onExportFileToLocal: function () {
            if (this.validate(this.fieldsToValidate)) {
                window.open(Settings.url('compuzz', 'v2/runs/pcr/' + this.options.model.get('secId') + '/sampleList/export', {
                    fileFormatSecId: this.model.get('refFileFormat').secId,
                    downloadFile: true
                }), '_blank');
                this.box.modal('hide');
            }
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        hide: function () {
            this.destroy();
        }
    });
});
