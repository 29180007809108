define('entities/admin/jsonobjectdocumentation',[
  'app',
  'backbone',
  'settings'

], function(
  App,
  Backbone,
  Settings
) {
  'use strict';

  var JsonObjectDocumentation = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'public/jsondocumentation/dbapplication/object/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'name': '',
      'internalName':'',
      'jsonAppDocumentationJson': {},
      'description': '',
      'jsonPropertyDocumentationJsons': {},
      'refDBApplication': '',
      'analysis': true
    },
    idAttribute: 'secId',

    postUrl: function () {
      return Settings.url('compuzz', 'public/jsondocumentation/object');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var JsonObjectDocumentationCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'public/jsondocumentation/object');
    },
    model: JsonObjectDocumentation
  });

  var API = {
    getFieldsToDisplay: function () {
      return {'fieldsWidth': 250};
      // 'code' must be showed by _getAutocompleteOptions(valueProperty)
    },
    getJsonObjectDocumentationCollection: function() {
      new JsonObjectDocumentationCollection();
      var params = {};
      return Settings.url('compuzz', 'public/jsondocumentation/object', params);

    },

    getJsonObjectDocumentationCountUrl: function() {
      var params = '';
      return 'public/jsondocumentation/object/rowCount' + params;
    },
    deleteJsonObjectDocumentation: function(secId){
      var url = Settings.url('compuzz', 'public/jsondocumentation/object/'+secId),
        defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },
    updateDatabase: function() {
      var params = '';
      var url = Settings.url('compuzz', 'public/jsondocumentation/update', params),
        defer = $.Deferred();

      $.ajax({
        method: 'post',
        url: url,
        success: function () {
          defer.resolve();
        }
      });
      return defer.promise();
    },
    generatePdf:function(secId) {
      var formdata = new FormData();
      formdata.append('secId', secId);
      var url = Settings.url('compuzz', 'public/jsondocumentation/generatepdf'),
        defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: url,
        data:formdata,
        processData: false,
        contentType: false,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('jsonobjectdocumentation:get-collection-url', function(secId) {
    return API.getJsonObjectDocumentationCollection(secId);
  });

  App.reqres.setHandler('jsonobjectdocumentation:get-count-url', function() {
    return API.getJsonObjectDocumentationCountUrl();
  });

  App.reqres.setHandler('jsonobjectdocumentation:model', function(itemJSON) {
    return new JsonObjectDocumentation(itemJSON);
  });

  App.reqres.setHandler('jsonobjectdocumentation:update', function() {
    return API.updateDatabase();
  });

  App.reqres.setHandler('jsonobjectdocumentation:delete', function (secId) {
    return API.deleteJsonObjectDocumentation(secId);
  });

  App.reqres.setHandler('jsonobjectdocumentation:get-fields-to-display', function () {
    return API.getFieldsToDisplay();
  });

  App.reqres.setHandler('jsonobjectdocumentation:generate-pdf', function(secId) {
    return API.generatePdf(secId);
  });
});
