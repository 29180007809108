define('entities/caccounts/setting/defaultCheckbox',[
	'backbone',
	'settings',
	'underscore',
	'module'
], function (
	Backbone,
	Settings,
	_,
	module
) {
	'use strict';

	var Setting = Backbone.Model.extend({
		fetch: function () {
			this.url = Settings.url('compuzz', 'v2/settings/byCode/DEFAULT-CHECKBOX');

			return Backbone.Model.prototype.fetch.call(this);
		},
		defaults: {
			'prepPrintRunSheet': false,
			'prepExtractionSetup': false,
			'pcrPrintRunSheet': false,
			'pcrExportPlateLayout': false,
			'printPcrSetup': false,
			'printPcrSetupMMX': false
		},
		parse: function (response) {
			if (!response) {
				return;
			}
			var splitted = response.params.split(';');
			if (splitted.length > 0) {
				this.set('prepPrintRunSheet', splitted[0].toLowerCase() === 'true');
			}
			if (splitted.length > 1) {
				this.set('prepExtractionSetup', splitted[1].toLowerCase() === 'true');
			}
			if (splitted.length > 2) {
				this.set('pcrPrintRunSheet', splitted[2].toLowerCase() === 'true');
			}
			if (splitted.length > 3) {
				this.set('pcrExportPlateLayout', splitted[3].toLowerCase() === 'true');
			}
			if (splitted.length > 4) {
				this.set('printPcrSetup', splitted[4].toLowerCase() === 'true');
			}
			if (splitted.length > 5) {
				this.set('printPcrSetupMMX', splitted[5].toLowerCase() === 'true');
			}
		}
	});
	module.exports = {
		getModel: function () {
			return new Setting();
		}
	};
});
