define('entities/caccounts/lis',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/sampleClass',
    'entities/caccounts/lisConfiguration',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Lis = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/lis/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'parameter': '',
            'lisFollowed': undefined,
            'configurations': undefined,
            'sampleClasses': [],
            description: null,
            comment: null,
            tags: []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'lisFollowed',
            relatedModel: 'Lis'
        }, {
            type: Backbone.HasMany,
            key: 'configurations',
            relatedModel: 'LisConfiguration',
            collectionType: 'LisConfigurationCollection',
            reverseRelation: {
                key: 'lis',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'sampleClasses',
            relatedModel: 'SampleClass',
            collectionType: 'SampleClassCollection'
        }],

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/lis' + (this.get('secId') ? '/' + this.get('secId') : ''));
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'LISJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Lis',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.LisCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/lis/');
        },
        model: app.ns.Lis
    });

    module.exports = _.defaults({
        getName: function () {
            return 'lis';
        },
        getUrl: function () {
            return 'v2/lis';
        },
        getDynamicJson: function () {
            return 'LISJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lis.sampleClasses'),
                name: 'sampleClasses',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                search: false,
                width: 45
            }, {
                label: _.i18n('lis.followeds'),
                name: 'lisFollowed',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameFormatter,
                search: false,
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Lis.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.duplicable = false;
            return config;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'sampleClasses',
                    'param': {
                        'type': 'LIST2ENTITY'
                    }
                },
                {'field': 'parameter', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'LIS_PARAM'}},
                {'field': 'lisFollowed', 'param': {'display': {'class': 'col-xs-3'}}}
            );
            return custom;
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'sampleClasses',
                    'param': {
                        'type': 'LIST2ENTITY'
                    }
                }
            ];
        },
        findDefault: function () {
            var defer = $.Deferred();
            var defaultValue;
            App.request('setting:get-setting-by-code', 'DEFAULT-VALUES')
                .done(function (value) {
                    if (value && value.params) {
                        var paramSplitted = value.params.split(';');
                        if (paramSplitted.length > 3)
                            defaultValue = value.params.split(';')[3];
                    }
                })
                .always(_.bind(function () {
                    if (defaultValue) {
                        var url = Settings.url('compuzz', this.getUrl() + '?' + Settings.generateParamUrl({
                            'sord': 'asc',
                            'sidx': 'code',
                            'code': defaultValue
                        }));

                        $.ajax({
                            type: 'GET',
                            url: url,
                            success: _.bind(function (data) {
                                defer.resolve(this.getModel(data[0]));
                            }, this)
                        });
                    } else {
                        defer.resolve(undefined);
                    }
                }, this));
            return defer.promise();
        }
    }, Dynamic);
});
