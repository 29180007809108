/**
 * Created by BFR on 24/01/2018.
 */
define('miniPlateView',[
	'module',
	'app',
	'underscore',
	'colorUtils',
	'backbone',
	'marionette',
	'template!miniPlateViewTpl',
	'miniWellView',
	'entities/caccounts/pcrwells'
], function (
	module,
	App,
	_,
	ColorUtils,
	Backbone,
	Marionette,
	viewTpl,
	MiniWellView
) {
	'use strict';

	module.exports = Marionette.LayoutView.extend({
		template: viewTpl,

		events: {
			'click .wellTableMiniLineTitleItem': 'onLineTitleClick',
			'click .wellTableMiniColumnTitleItem': 'onColumnTitleClick',
			'click .wellTableMiniCornerTitle .js-top-left': 'onTopLeftClick',
			'click .wellTableMiniCornerTitle .js-top-right': 'onTopRightClick',
			'click .wellTableMiniCornerTitle .js-bottom-left': 'onBottomLeftClick',
			'click .wellTableMiniCornerTitle .js-bottom-right': 'onBottomRightClick'
		},

		ui: {
			miniPlate: '.miniPlate',
			blockLoading: '.empty-page-loader'
		},

		modelEvents: {
			'change': 'renderSafe'
		},

		renderSafe: function () {
			if (!this.isDestroyed) {
				this.render();
			}
		},

		initialize: function (options) {
			this.isMultiDisplay = this.model.get('wells').length > 96;
			this.assays = options.assays;
			this.wellSelected = options.wellSelected;
			this.currentAssayTarget = options.currentAssayTarget;
		},

		serializeData: function () {
			var templateData = {};
			templateData.rows = [];
			templateData.columns = [];
			this.options.collectionDisplay.each(_.bind(function (well) {
				var row = well.get('pos').substring(0, 1);
				if (!_.contains(templateData.rows, row)) {
					templateData.rows.push(row);
				}
				var column = parseInt(well.get('pos').substring(1, 3), 10);
				if (!_.contains(templateData.columns, column)) {
					templateData.columns.push(column);
				}
			}, this));

			templateData.width = 100 / parseInt(templateData.columns.length, 10);
			templateData.isMultiDisplay = this.isMultiDisplay;
			templateData.selected = this.collection.size() === 96;
			templateData.firstWellPos = this.collection.at(0).get('pos');
			return templateData;
		},

		onRender: function () {
			this.collection.each(_.bind(function (model) {
				this.addRegion('tbl' + model.get('pos'), '.tbl-' + model.get('pos'));
				var region = this.getRegion('tbl' + model.get('pos'));
				var miniWellView = new MiniWellView({
					model: _.first(model.get('results').filter(_.bind(function (result) {
						return result.get('refAssayResult').get('id') === this.currentAssayTarget.id;
					}, this))),
					selected: this.wellSelected.includes(model.get('pos')),
					region: region.$el
				});
				miniWellView.listenTo(miniWellView, 'miniWell:filter', _.bind(this.onMiniWellFilter, this));
				region.show(miniWellView);
			}, this));

		},

		uncheckAllIfEveryIsSelected: function () {
			if (this.collection.length === this.wellSelected.length) {
				this.wellSelected = [];
			}
		},

		onMiniWellFilter: function (well, selected) {
			this.uncheckAllIfEveryIsSelected();
			if (this.wellSelected.length === 0 && !selected) {
				selected = true;
			}
			if (selected) {
				this.wellSelected.push(well.get('refPcrWell').get('pos'));
			} else {
				for (var i = 0; i < this.wellSelected.length; i++) {
					if (this.wellSelected[i] === well.get('refPcrWell').get('pos')) {
						this.wellSelected.splice(i, 1);
					}
				}
			}
			this.trigger('well:filter', this.currentAssayTarget, this.wellSelected);
		},

		filteredDetailItem: function (model) {
			this.trigger('well:show-well-detail', model);
		},

		onLineTitleClick: function (event) {
			this.uncheckAllIfEveryIsSelected();
			var line = event.target.textContent.split('\n')[0];
			var lineSelected = this.collection.filter(function (model) {
				return model.get('pos').substring(0, 1) === line;
			});
			var selected = _.any(lineSelected, _.bind(function (model) {
				return !this.wellSelected.includes(model.get('pos'));
			}, this));
			this.collection.each(_.bind(function (model) {
				if (model.get('pos').substring(0, 1) === line) {
					if (selected) {
						if (!this.wellSelected.includes(model.get('pos'))) {
							this.wellSelected.push(model.get('pos'));
						}
					} else {
						if (this.wellSelected.includes(model.get('pos'))) {
							for (var i = 0; i < this.wellSelected.length; i++) {
								if (this.wellSelected[i] === model.get('pos')) {
									this.wellSelected.splice(i, 1);
								}
							}
						}
					}
				}
			}, this));
			this.trigger('well:filter', this.currentAssayTarget, this.wellSelected);
		},

		onColumnTitleClick: function (event) {
			this.uncheckAllIfEveryIsSelected();
			var column = event.target.textContent.split('\n')[0];
			var columnSelected = this.collection.filter(function (model) {
				return model.get('pos').substring(1, 3) === column;
			});
			var selected = _.any(columnSelected, _.bind(function (model) {
				return !this.wellSelected.includes(model.get('pos'));
			}, this));
			this.collection.each(_.bind(function (model) {
				if (model.get('pos').substring(1, 3) === column) {
					if (selected) {
						if (!this.wellSelected.includes(model.get('pos'))) {
							this.wellSelected.push(model.get('pos'));
						}
					} else {
						if (this.wellSelected.includes(model.get('pos'))) {
							for (var i = 0; i < this.wellSelected.length; i++) {
								if (this.wellSelected[i] === model.get('pos')) {
									this.wellSelected.splice(i, 1);
								}
							}
						}
					}
				}
			}, this));
			this.trigger('well:filter', this.currentAssayTarget, this.wellSelected);
		},

		onTopLeftClick: function () {
			if (this.collection.at(0).get('pos') === 'A01' && this.collection.length === 96) {
				this.trigger('well:collection', 'A01', 1, this.model.get('plateSize'));
			} else {
				this.trigger('well:collection', 'A01', 2, this.model.get('plateSize'));
			}
		},
		onTopRightClick: function () {
			if (this.collection.at(0).get('pos') === 'A02') {
				this.trigger('well:collection', 'A01', 1, this.model.get('plateSize'));
			} else {
				this.trigger('well:collection', 'A02', 2, this.model.get('plateSize'));
			}
		},
		onBottomLeftClick: function () {
			if (this.collection.at(0).get('pos') === 'B01') {
				this.trigger('well:collection', 'A01', 1, this.model.get('plateSize'));
			} else {
				this.trigger('well:collection', 'B01', 2, this.model.get('plateSize'));
			}
		},
		onBottomRightClick: function () {
			if (this.collection.at(0).get('pos') === 'B02') {
				this.trigger('well:collection', 'A01', 1, this.model.get('plateSize'));
			} else {
				this.trigger('well:collection', 'B02', 2, this.model.get('plateSize'));
			}
		}
	});
});
