define('pcrSetupPcrWellCollectionView',[
	'module',
	'backbone',
	'marionette',
	'pcrSetupPcrWellItem',
	'pcrSetupPcrWellItemHeader',
	'underscore'
], function (
	module,
	Backbone,
	Marionette,
	Item,
	ItemHeader,
	_
) {
	'use strict';

	module.exports = Marionette.CollectionView.extend({
		getChildView: function (item) {
			if (item.get('type') === 'header') {
				return ItemHeader;
			} else {
				return Item;
			}
		},
		childViewOptions: function () {
			return {
				width: this.options.wellWidth,
				assay: this.options.assay,
				protocol: this.options.protocol
			};
		},
		childEvents: {
			'item:delete': function (view, model) {
				this.collection.remove(model);
			},
			'item:mouseOver': function (view) {
				if (view.model.get('type') || !this.model.get('refPcrSetup').get('mode')) {
					return;
				}
				switch (this.model.get('refPcrSetup').get('mode')) {
					case 'prepWell':
						var wellSelected = this.model.get('refPcrSetup').get('prepWellSelected');
						if (wellSelected.length === 0) {
							return;
						}
						var start = view.model.get('pos');
						var wells;
						var available;
						if (this.model.get('refPcrSetup').get('transfer').startsWith('bulk')) {
							wells = this.model.findBlockStartAt(start, wellSelected.length);
							if (wells.isEmpty()) {
								this.children.each(_.bind(function (child) {
									if (child.type) {
										return;
									}
									child.defineTemporary(false);
								}, this));
							} else {
								available = wellSelected.length === wells.length;
								this.children.each(_.bind(function (child) {
									if (child.type) {
										return;
									}
									var well = wells.findWhere({pos: child.model.get('pos')});
									child.defineTemporary(wellSelected.at(wells.indexOf(well)), available);
								}, this));
							}
						} else {
							wells = this.model.findBlock(start);
							if (wells.isEmpty()) {
								this.children.each(_.bind(function (child) {
									if (child.type) {
										return;
									}
									child.defineTemporary(false);
								}, this));
							} else {
								available = wellSelected.length === wells.length;
								this.children.each(_.bind(function (child) {
									if (child.type) {
										return;
									}
									var well = wells.findWhere({pos: child.model.get('pos')});
									child.defineTemporary(well && well.get('wellSelected'), !child.model.get('assay'));
								}, this));
							}
						}
						break;
					case 'qualityControl':
						this.children.each(_.bind(function (child) {
							if (child.type) {
								return;
							}
							var well = view === child ? this.model.get('refPcrSetup').get('qualityControl') : null;
							child.defineTemporary(well, true);
						}, this));
						break;
					case 'pcrWell':
						break;
				}
			},
			'item:mouseLeave': function () {
				this.children.each(function (view) {
					if (view.type) {
						return;
					}
					view.defineTemporary(false);
				});
			},
			'item:click': function () {
				var childrens = this.children.filter(function (view) {
					return !view.type && view.model.get('temp');
				});
				var wells = [];
				_.each(childrens, function (view) {
					wells.push(view.validateSelection());
				});
				if (wells.length > 0) {
					this.model.get('refPcrSetup').addHistory(wells);
					this.trigger('validateSelection');
					if (!this.model.get('pcrProtocol')) {
						this.model.set({pcrProtocol: _.first(wells).get('assay').get('refKitProt')}, {silent: true});
					}
					this.model.generateColor();
				}
			}
		}
	});
});
