define('arrayCollectionItemView',[
    'module',
    'dialogFormView',
    'template!arrayCollectionItemView',
    'savingBehavior',
    'underscore',
    'jquery',
    'arrayBoolView',
    'arrayColorView',
    'arrayNumberView',
    'arrayTextView',
    'autocompleteView',
    'entities/admin/codelist',
    'dynamicCreateEditCustomListToList',
    'colorpicker',
    'colorUtils'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    ArrayBoolView,
    ArrayColorView,
    ArrayNumberView,
    ArrayTextView,
    AutocompleteView,
    CodeLists,
    DynamicCreateEditCustomListToList
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },
        className: 'col-xs-12 p-l-0 p-r-0 m-t-1 m-b-1',

        ui: {
            showDescription: '.js-show-description'
        },

        regions: {
            itemRegion: '.js-item-region'
        },
        modelEvents: {
            'change:value': 'changeModel'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'}
        ],

        serializeData: function () {
            return {
                seq: this.model.get('arrayElement').get('seq'),
                code: this.model.get('arrayElement').get('code'),
                contentType: this.model.get('arrayElement').get('contentType'),
                contentTypeParam: this.model.get('arrayElement').get('contentTypeParam'),
                description: this.model.get('arrayElement').get('description'),
                name: this.model.get('arrayElement').get('name')
            };
        },

        onRender: function () {
            $.material.init();
            var region = this.getRegion('itemRegion');
            var view;
            if (!this.options.readOnly && this.model.get('arrayElement').get('contentType') === 'CL') {
                if (!this.options.readOnly) {
                    view = new AutocompleteView(
                        this._getAutocompleteOptionObject(
                            CodeLists.getAutocompleteParamForCode({
                                modelProperty: 'value',
                                codeListCode: this.model.get('arrayElement').get('contentTypeParam'),
                                callBackOnChange: this.onChangeCodeListParamValue
                            })));
                }
            } else {
                switch (this.model.get('arrayElement').get('contentType')) {
                    case 'L2L-E':
                        view = new DynamicCreateEditCustomListToList({
                            'field': 'value',
                            'model': this.model,
                            'separator': ',',
                            'display': {
                                'rows': 1,
                                'style': 'height: 28px'
                            },
                            items: {items: this.model.get('items'), codeListCode: ''}
                        });
                        break;
                    case 'COL':
                        view = new ArrayColorView({
                            readOnly: this.options.readOnly,
                            field: 'value',
                            model: this.model
                        });
                        break;
                    case 'N':
                        view = new ArrayNumberView({
                            readOnly: this.options.readOnly,
                            field: 'value',
                            model: this.model
                        });
                        break;
                    case 'BOOL':
                        view = new ArrayBoolView({
                            readOnly: this.options.readOnly,
                            field: 'value',
                            model: this.model
                        });
                        break;
                    // case 'AN':
                    // case 'A':
                    default:
                        view = new ArrayTextView({
                            readOnly: this.options.readOnly,
                            field: 'value',
                            model: this.model
                        });
                        break;

                }
            }
            if (!view) {
                throw new Error('No view found for contentType: ' + this.model.get('arrayElement').get('contentType'));
            }
            this.listenTo(view, 'change', _.bind(this.changeModel, this));
            region.show(view);
            if (this.model.get('arrayElement').get('description')) {
                this.ui.showDescription.attr('data-content', this.model.get('arrayElement').get('description'));
                this.ui.showDescription.popover({
                    'trigger': 'hover',
                    'placement': 'bottom',
                    container: this.$el
                });
            }
        },
        changeModel: function () {
            this.trigger('changeChild');
        },
        onChangeCodeListParamValue: function (fieldName, model) {
            this.onChange(fieldName, model ? model.get('code') : null);
        }
    });
});
