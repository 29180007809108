define('entities/common',[
	'app',
	'jquery',
	'backbone',
	'underscore',
	'settings'
], function(
	App,
	$,
	Backbone,
	_,
	Settings
) {
	'use strict';

	/**
	 * Model
	 */
	/**
	 * REST API
	 */
	var API = {
		getRowCount: function(base, urlParams) {
			var params = {},
				defer = $.Deferred();

			_.each(urlParams.filters, function (item, key) {
				if (item) {
					params[key] = item;
				}
			});
			$.ajax({
				url: Settings.url(base, urlParams.rowCountUrl, params),
				success: function(result) {
					defer.resolve(result);
				},
				error: function(result) {
					defer.reject(result);
				}
			});

			return defer.promise();
		}
	};

	/**
	 * Request communications
	 */

	App.reqres.setHandler('common:get-row-count', function(base, urlParams) {
		if(!base) {
			base = 'compuzz';
			console.warn('The variable "base" is not defined, use "compuzz" by default value');
		}
		return API.getRowCount(base, urlParams);
	});
});
