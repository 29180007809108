define('assayConfigurationView',[
    'module',
    'marionette',
    'template!assayConfigurationTpl',
    'underscore',
    'jquery',
    'app',
    'autocompleteView',
    'dialogFormView',
    'assayResultCollectionView',
    'dynamicCreateEditCustomListToList',
    'entities/caccounts/colorCompensations',
    'entities/caccounts/assayConfigurationResult',
    'entities/caccounts/cyclers',
    'entities/caccounts/cyclerpublics'
], function (
    module,
    Marionette,
    AssayConfigurationTpl,
    _,
    $,
    App,
    AutocompleteView,
    DialogFormView,
    AssayResultCollectionView,
    DynamicCreateEditCustomListToList,
    ColorCompensations,
    AssayResults,
    Cyclers,
    CyclerPublics
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: AssayConfigurationTpl,

        ui: {
            input: '.js-info-input-assayConfiguration',
            dropDownInput: '.js-dropDownRegion-input',
            colorCompensationRegion: '.js-colorCompensation',
            cyclersRegion: '.js-cyclers'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'focus @ui.dropDownInput': 'onDropdownFocus',
            'change @ui.input': 'onInputChange',
            'click .delete-row': 'onDelete',
            'click .duplicate-row': 'onDuplicate',
            'click .js-add-result': 'onAddResult'
        },

        regions: {
            colorCompensationSelect: '.js-select-colorCompensation-region',
            assayResultRegion: '.js-assayResult-region',
            cyclerPublic: '.js-cyclerPublic-region',
            cyclers: '.js-cyclers-region'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'},
            {name: 'name', type: 'required'}
        ],

        modelEvents: {
            'change:code': 'onChangeCode',
            'change:cyclerPublic': 'onChangeCyclerPublic',
            'change:refColorCompensation': 'renderColorCompensation'
        },

        className: 'assayConfiguration',

        initialize: function () {
            var cycler = this.model.get('cyclers').first();
            if (cycler) {
                cycler = cycler.get('refCycler');
            }
            this.model.set('cyclerPublic', cycler);
        },

        validateFields: function () {
            return this.assayResultCollectionView.validateFields();
        },

        serializeData: function () {
            return this.model.toJSON();
        },

        onRender: function () {
            $.material.init();
            this.renderColorCompensation();

            this.assayResultCollectionView = new AssayResultCollectionView({
                collection: this.model.get('results')
            });

            this.getRegion('assayResultRegion').show(this.assayResultCollectionView);

            var cyclerView = new AutocompleteView(
                this._getAutocompleteOptionObject(CyclerPublics.getAutocompleteParam({
                    modelProperty: 'cyclerPublic'
                }))
            );
            this.getRegion('cyclerPublic').show(cyclerView);
            this.renderCyclers();
        },

        renderColorCompensation: function () {
            this.autocompleteColorCompensationView = new AutocompleteView(
                this._getAutocompleteOptionObject(ColorCompensations.getAutocompleteParam({
                    modelProperty: 'refColorCompensation'
                }))
            );
            this.getRegion('colorCompensationSelect').show(this.autocompleteColorCompensationView);
        },

        renderCyclers: function () {
            if (this.model.get('cyclerPublic')) {
                Cyclers.findAll(this.model.get('cyclerPublic').get('secId')).done(_.bind(function (data) {
                    var view = new DynamicCreateEditCustomListToList({
                        'field': 'cyclers',
                        'model': this.model,
                        'display': {
                            'rows': 1,
                            'style': 'height: 28px'
                        },
                        items: {items: data, codeListCode: ''},
                        changeCallBack: _.bind(this.changeCyclers, this)
                    });
                    this.getRegion('cyclers').show(view);
                    this.ui.cyclersRegion.show();
                }, this));
            } else {
                this.ui.cyclersRegion.hide();
            }

            this.displayColorCompensation(this.model.get('cyclers').any(function (cycler) {
                return cycler.get('refCycler').get('colorCompensation');
            }));


        },

        changeCyclers: function (fieldName, itemsSelected) {
            var items = _.map(itemsSelected, function (itemSelected) {
                return Cyclers.getModel({secId: itemSelected});
            });

            var defers = [];
            _.each(items, _.bind(function (item) {
                defers.push(item.fetch());
            }, this));
            this.model.get('cyclers').reset();
            this.model.get('cyclers').push(items);

            $.when.apply($, defers)
                .done(_.bind(function () {
                    this.model.trigger('change');
                }, this));
            this.renderCyclers();
        },

        displayColorCompensation: function (display) {
            if (display) {
                this.ui.colorCompensationRegion.show();
            } else {
                this.ui.colorCompensationRegion.hide();
                this.model.set({'refColorCompensation': null}, {silent: true});
            }
        },

        onDelete: function () {
            this.trigger('item:delete', this.model);
        },

        onDuplicate: function () {
            var modelJson = this.model.clone().toJSON();
            delete modelJson.secId;
            delete modelJson.refCycler;
            modelJson.code = undefined;
            modelJson.name = undefined;
            this.model.get('results').each(function (result) {
                var resultJSON = result.toJSON();
                delete resultJSON.secId;
                modelJson.results.push(resultJSON);
            });
            this.trigger('item:add', this.model.getEntity().getModel(modelJson));
        },

        onAddResult: function () {
            var newSequence = this.model.get('results').length > 0 ? this.model.get('results').max(function (result) {
                return result.get('sequence');
            }).get('sequence') + 1 : 1;
            var model = AssayResults.getModel({'sequence': newSequence, 'assayConfiguration': this.model});
            this.model.get('results').add(model);
        },
        onChangeCode: function () {
            this.model.trigger('change');
        },
        onChangeCyclerPublic: function () {
            if (!this.model.get('cyclerPublic')) {
                this.model.get('cyclers').reset();
                this.renderCyclers();
            } else {
                if (!this.model.get('cyclers').isEmpty()) {
                    this.model.get('cyclers').remove(this.model.get('cyclers').filter(_.bind(function (cycler) {
                        return cycler.get('refCycler').get('secId') !== this.model.get('cyclerPublic').get('secId');
                    }, this)));
                }
                if (this.model.get('cyclers').isEmpty()) {
                    $('.js-global-loader').show();
                    Cyclers.findAll(this.model.get('cyclerPublic').get('secId')).done(_.bind(function (data) {
                        this.model.get('cyclers').add(data);
                        this.renderCyclers();
                        $('.js-global-loader').hide();
                    }, this));
                } else {
                    this.renderCyclers();
                }
            }
        }
    });
});
