
define('template!sampleCreateEditHeaderView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- header -->\n<div class="col-xs-3 p-r-0 p-l-0">\n    <div class="row">\n        <div class="col-xs-5 text-right m-r-0">' +
((__t = ( _.i18n('sample.lis') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-7 p-l-0">' +
((__t = ( lisCode )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="row" style="position:relative;">\n        <div class="col-xs-5 text-right m-r-0">' +
((__t = ( _.i18n('sample.sampleClass') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-7 p-l-0">' +
((__t = ( sampleClassCode )) == null ? '' : __t) +
'</div>\n        <span class="mdi mdi-refresh clickable js-sampleClass-refresh ' +
((__t = ( canRefreshSampleClass )) == null ? '' : __t) +
'"\n              style="position:absolute;right: 30px;font-size: 18px;"\n              title="' +
((__t = ( _.i18n('sample.sampleClassRecalc') )) == null ? '' : __t) +
'"></span>\n        ';
 if(!validSampleClass) { ;
__p += '\n        <span class="mdi mdi-alert-circle" style="position:absolute;right: 20px;font-size: 18px;"\n              title="' +
((__t = ( _.i18n('sample.sampleClassNotCorresponding') )) == null ? '' : __t) +
'"></span>\n        ';
 } ;
__p += '\n    </div>\n    <div class="row" style="position:relative;">\n        <div class="col-xs-5 text-right m-r-0 ' +
((__t = ( sampleNotValidated ? 'error': '' )) == null ? '' : __t) +
'">' +
((__t = (
            _.i18n('samples.verified') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-7 p-l-0 ' +
((__t = ( sampleNotValidated ? 'error': '' )) == null ? '' : __t) +
'">' +
((__t = ( verified )) == null ? '' : __t) +
'\n        </div>\n        <span class="mdi mdi-refresh clickable js-validation-refresh ' +
((__t = ( canSynchronizeLis )) == null ? '' : __t) +
'"\n              style="position:absolute;right: 30px;font-size: 18px;"\n              title="' +
((__t = ( _.i18n('sample.refreshValidation') )) == null ? '' : __t) +
'"></span>\n    </div>\n    <div class="row">\n        <div class="col-xs-5 text-right m-r-0">' +
((__t = ( _.i18n('samples.aliquot') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-7 p-l-0">\n            <div class="row">\n                <div class="col-xs-12">' +
((__t = ( parent.sample )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="row">\n                <div class="col-xs-4 text-right m-r-0 m-l-0 p-r-0 p-l-0">' +
((__t = ( _.i18n('sample.lis') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-8">' +
((__t = ( parent.lisCode )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="row">\n                <div class="col-xs-4 text-right m-r-0 m-l-0 p-r-0 p-l-0 ">' +
((__t = (
                    _.i18n('sample.sampleClass') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-8">' +
((__t = ( parent.sampleClassCode )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <span class="mdi mdi-content-copy js-copy-parameters clickable" data-copy="' +
((__t = ( parent.sample )) == null ? '' : __t) +
'"\n              title="' +
((__t = ( _.i18n('sample.copyAliquot') )) == null ? '' : __t) +
'"\n              style="position: absolute; font-size: 18px; right: 13px;"></span>\n    </div>\n</div>\n<div class="col-xs-6">\n    <div>\n        <div class="col-xs-10" style="border: solid 1px black; border-radius: 5px; background-color: #f5f5dc;\n                     padding: 2px; height: 40px; text-align: center;">\n            <span style="font-size: 24px;">' +
((__t = ( sample )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="col-xs-2">\n                <span class="mdi mdi-pencil clickable js-edit-sampleId" data-copy="' +
((__t = ( sample )) == null ? '' : __t) +
'"\n                      style="float: right;"\n                      title="' +
((__t = ( _.i18n('sample.editSampleId') )) == null ? '' : __t) +
'"></span>\n            <span class="mdi mdi-content-copy clickable js-copy-parameters" data-copy="' +
((__t = ( sample )) == null ? '' : __t) +
'"\n                  style="float: right;"\n                  title="' +
((__t = ( _.i18n('sample.copySampleId') )) == null ? '' : __t) +
'"></span>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = (
            _.i18n('sample.created') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( created )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="row">\n        <div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = (
            _.i18n('sample.modified') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( modified )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="row">\n        <div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = (
            _.i18n('sample.untilValidated') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( untilValidated )) == null ? '' : __t) +
'\n        </div>\n    </div>\n</div>\n<div class="col-xs-3 p-r-0 p-l-0">\n    <div class="row">\n        <div class="col-xs-4 text-right m-r-0">' +
((__t = ( _.i18n('sample.patient') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( patientId )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="row" style="position: relative;">\n        <div class="col-xs-4 text-right m-r-0">' +
((__t = ( _.i18n('sample.order') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8"><span class="clickable js-order">' +
((__t = ( orderCode )) == null ? '' : __t) +
'</span></div>\n        <span class="mdi mdi-content-copy js-copy-parameters clickable" data-field-name="' +
((__t = ( orderCode )) == null ? '' : __t) +
'"\n              style="position: absolute;\n    font-size: 18px;\n    right: 13px;"\n              title="' +
((__t = ( _.i18n('sample.copyOrderId') )) == null ? '' : __t) +
'"></span>\n    </div>\n    <div class="row">\n        <div class="col-xs-4 text-right m-r-0">' +
((__t = ( _.i18n('samples.biogroup') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( bioGroupCode )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="row">\n        <div class="col-xs-4 text-right m-r-0">' +
((__t = ( _.i18n('order.specie') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( specieCode )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="row">\n        <div class="col-xs-4 text-right m-r-0">' +
((__t = ( _.i18n('samples.lis.comment') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( lisComment )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="row">\n        <div class="col-xs-4 text-right m-r-0">' +
((__t = ( _.i18n('samples.comment') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-7">' +
((__t = ( comment )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-1"></div>\n    </div>\n</div>';

}
return __p
};});

