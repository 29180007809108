
define('template!splitLisAnaView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row"\n\t style="display:flex; align-items: center; justify-content: center; flex-direction: row;column-gap: 10px;">\n\t<div class="col-w-45">\n\t\t<label for="lisAnaOriginSample">' +
((__t = ( _.i18n('preProcess.split.sample.originLisAna') )) == null ? '' : __t) +
'</label>\n\t\t<select multiple="multiple" id="lisAnaOriginSample" style="width: 100%;" size="10">\n\t\t\t';
 _.each(lisAnas, function(lisAna) { ;
__p += '\n\t\t\t<option value="' +
((__t = ( lisAna.secId )) == null ? '' : __t) +
'">' +
((__t = ( lisAna.code )) == null ? '' : __t) +
'</option>\n\t\t\t';
 }) ;
__p += '\n\t\t</select>\n\t</div>\n\t<div class="col-w-all p-0"\n\t\t style="display:flex; align-items: center; justify-content: center; flex-direction: column;">\n\t\t<span class="mdi mdi-transfer-right clickable invisible" style="font-size: 80px; line-height: 80px;"></span>\n\t\t<span class="mdi mdi-transfer-left clickable invisible" style="font-size: 80px; line-height: 80px;"></span>\n\t</div>\n\t<div class="col-w-45">\n\t\t<label for="lisAnaNewSample">' +
((__t = ( _.i18n('preProcess.split.sample.newLisAna') )) == null ? '' : __t) +
'</label>\n\t\t<select multiple="multiple" id="lisAnaNewSample" style="width: 100%;" size="10">\n\t\t</select>\n\t</div>\n</div>\n<div class="row cancelConfirmRow">\n\t<div class="pull-right p-r-15 col-xs-12" style="text-align: end;">\n\t\t<button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n\t\t<button disabled\n\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t</div>\n</div>\n';

}
return __p
};});

