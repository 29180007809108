
define('template!samplePrepWellPcrWellView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<span class="col-w-all col-w-20 noWrapHidden t-a-l js-pcrrun">\n\t</span>\n<div class="col-w-all col-w-1 noWrapHidden t-a-l"></div>\n<div class="col-w-all col-w-4 noWrapHidden t-a-l"\n     title="' +
((__t = ( pos )) == null ? '' : __t) +
'">\n    ' +
((__t = ( pos )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-all col-w-1 noWrapHidden t-a-l"></div>\n<div class="col-w-all col-w-10 noWrapHidden t-a-l"\n     title="' +
((__t = ( assay )) == null ? '' : __t) +
'">\n    ' +
((__t = ( assay )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-all col-w-1 noWrapHidden t-a-l"></div>\n<div class="col-w-all col-w-55 noWrapHidden t-a-l">\n    <div class="col-w-all col-w-100 d-f">\n        ';
 _.each(results, function(result) { ;
__p += '\n        ';
 if (result.iconHtml) { ;
__p += '\n        <div class="col-w-15 noWrapHidden t-a-l"\n             title="' +
((__t = ( result.targetCode )) == null ? '' : __t) +
' &nbsp; ' +
((__t = ( result.cts )) == null ? '' : __t) +
' &nbsp; ' +
((__t = ( _.isEmpty(result.quantifications) ? '' : '(' + result.quantifications + ')' + '&nbsp;' )) == null ? '' : __t) +
' ' +
((__t = ( result.resCode )) == null ? '' : __t) +
'">\n            ' +
((__t = ( result.iconHtml )) == null ? '' : __t) +
'\n        </div>\n        ';
 } else { ;
__p += '\n        <div class="col-w-all col-w-15">\n        </div>\n        ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n    </div>\n</div>\n<div class="col-w-all col-w-1 noWrapHidden t-a-l"></div>\n<div class="col-w-all col-w-4 noWrapHidden t-a-l clickable headerRunActionButton js-read-click"\n     style="border-radius: 20px; height: 27px; width: 27px; position: relative; bottom: 5px;">\n    <div class="headerRunActionButtonIcon mdi-action-visibility js-show-pcrWell-from-prepWell"\n         data-secId="' +
((__t = ( secId )) == null ? '' : __t) +
'"\n         data-run-secId="' +
((__t = ( runSecId )) == null ? '' : __t) +
'"\n         style="font-size: 22px; position: relative; left: 3px; top: 5px;">\n    </div>\n</div>';

}
return __p
};});

