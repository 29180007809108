define('runContaminationsTabView',[
    'module',
    'marionette',
    'template!runContaminationsTabTpl',
    'underscore',
    'app',
    'jquery',
    'envHandler',
    'backbone',
    'dateUtils',
    'wellUtils',
    'fieldUtils',
    'contaminationsWellView',
    'dateUtils',
    'entities/caccounts/pcrwells'
], function (
    module,
    Marionette,
    runContaminationsTabTpl,
    _,
    App,
    $,
    EnvironmentHandler,
    Backbone,
    DateUtil,
    WellUtils,
    FieldUtils,
    WellView,
    dateConverter
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: runContaminationsTabTpl,

        selectedWells: [],

        ui: {
            blockLoading: '.empty-page-loader',
            wellContainer: '.well-container',
            selectTarget: 'select.target',
            leftSelectTarget: '.contaminationSelectTarget .mdi-arrow-left',
            rightSelectTarget: '.contaminationSelectTarget .mdi-arrow-right'
        },

        events: {
            'click .wellTableCornerTitle .js-top-left': 'onTopLeftClick',
            'click .wellTableCornerTitle .js-top-right': 'onTopRightClick',
            'click .wellTableCornerTitle .js-bottom-left': 'onBottomLeftClick',
            'click .wellTableCornerTitle .js-bottom-right': 'onBottomRightClick',
            'click @ui.leftSelectTarget': 'onSelectLeft',
            'click @ui.rightSelectTarget': 'onSelectRight',
            'change @ui.selectTarget': 'onChangeTarget'
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        initialize: function (options) {
            this.isMultiDisplay = options.isMultiDisplay;
            this.targets = {};
            this.model.get('assayVersions').each(_.bind(function (assayDetailVersion) {
                this.targets[assayDetailVersion.get('code')] = [];
                assayDetailVersion.get('results').each(_.bind(function (result) {
                    if (result.get('sequenceDisplayGroup') < 1) {
                        this.targets[assayDetailVersion.get('code')].push(result.toJSON());
                    }
                }, this));
            }, this));
            var first = this.model.get('assayVersions').first();
            if (first) {
                this.currentResult = _.first(this.targets[first.get('code')]);
            }
            this.step = parseInt(options.step, 10);
        },

        serializeData: function () {
            var templateData = {
                name: this.model.get('name') ? this.model.get('name') : '-',
                kitProt: this.model.get('refKitProt') ? this.model.get('refKitProt').code : '-',
                lmbCycler: this.model.get('refLmbCycler') ? this.model.get('refLmbCycler').code : '-',
                plateIdPC: this.model.get('plateIdPC') ? this.model.get('plateIdPC') : '-',
                comment: this.model.get('comment') ? this.model.get('comment') : '-',
                creatUser: this.model.get('refCreatUser') ? (this.model.get('refCreatUser').email ? this.model.get('refCreatUser').email : '-') : '-'
            };
            templateData.creatDate = dateConverter.toDateFormatString(this.model.get('creatDate'));
            templateData.rows = [];
            templateData.columns = [];

            this.model.get('wells').forEach(
                _.bind(function (well) {
                    var row = well.get('pos').substring(0, 1);
                    if (!_.contains(templateData.rows, row)) {
                        templateData.rows.push(row);
                    }
                    var column = parseInt(well.get('pos').substring(1, 2), 10);
                    if (!_.contains(templateData.columns, column)) {
                        templateData.columns.push(column);
                    }
                }, this));

            if (templateData.rows.length < 8) {
                templateData.rows = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
            }
            if (templateData.columns.length < 12) {
                templateData.columns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            }

            templateData.width = 100 / parseInt(templateData.columns.length, 10);
            templateData.isMultiDisplay = this.options.isMultiDisplay;
            templateData.isGrouping = this.step === 2;
            templateData.firstWellPos = this.model.get('wells') && this.model.get('wells').size() > 0 ? this.model.get('wells').at(0).get('pos') : '';
            return templateData;
        },

        onRender: function () {
            if (this.options.filters) {
                this.filters = this.options.filters;
            }
            this.selectedWells = [];
            _.each(this.targets, _.bind(function (target, key) {
                var optgroup = $('<optgroup>', {
                    label: key
                });
                optgroup.appendTo(this.ui.selectTarget);
                _.each(this.targets[key], _.bind(function (result) {
                    var opt = $('<option>', {
                        text: result.target.code,
                        value: result.id,
                        selected: this.currentResult.id === result.id
                    });
                    opt.appendTo(optgroup);
                }, this));
            }, this));
            this.showWell();
        },

        onSelectLeft: function () {
            if (!this.ui.leftSelectTarget.hasClass('clickable')) {
                return;
            }
            var prev = this.ui.selectTarget.find('option:selected');
            prev.attr('selected', false);
            prev = prev.prev('option');
            if (prev.prev('option').length > 0) {
                this.ui.leftSelectTarget.removeClass('disabled-grey');
                this.ui.leftSelectTarget.addClass('clickable');
            } else {
                this.ui.leftSelectTarget.addClass('disabled-grey');
                this.ui.leftSelectTarget.removeClass('clickable');
            }
            this.ui.rightSelectTarget.removeClass('disabled-grey');
            this.ui.rightSelectTarget.addClass('clickable');
            prev.prev().attr('selected', 'selected');
            this.showWell();
        },

        onSelectRight: function () {
            if (!this.ui.rightSelectTarget.hasClass('clickable')) {
                return;
            }
            var next = this.ui.selectTarget.find('option:selected');
            next.attr('selected', false);
            next = next.next('option');
            if (next.next('option').length > 0) {
                this.ui.rightSelectTarget.removeClass('disabled-grey');
                this.ui.rightSelectTarget.addClass('clickable');
            } else {
                this.ui.rightSelectTarget.addClass('disabled-grey');
                this.ui.rightSelectTarget.removeClass('clickable');
            }
            this.ui.leftSelectTarget.removeClass('disabled-grey');
            this.ui.leftSelectTarget.addClass('clickable');
            next.attr('selected', 'selected');
            this.showWell();
        },

        onChangeTarget: function (e) {
            var resultId = parseInt(e.currentTarget.value, 10);
            var currentTarget = _.first(_.filter(this.targets, _.bind(function (target, key) {
                return _.any(this.targets[key], function (result) {
                    return resultId === result.id;
                });
            }, this)));
            this.currentResult = _.findWhere(currentTarget, {id: resultId});
            this.showWell();
        },

        getWellResNumValues: function (wellResNum) {
            if (!wellResNum) {
                return false;
            }
            var wellResNumMin = wellResNum;
            if (wellResNum && Array.isArray(wellResNum)) {
                wellResNumMin = FieldUtils.arrayMin(wellResNum);
                wellResNum = wellResNum.join(';');
            }
            if (wellResNum &&
                (typeof wellResNum === 'string' || wellResNum instanceof String)) {
                if (wellResNum.includes(';')) {
                    var wellResNumbers = [];
                    var wellResStringArray = wellResNum.split(';');
                    _.each(wellResStringArray, function (n) {
                        if (n !== '') {
                            wellResNumbers.push(Number(n));
                        }
                    });
                    wellResNumMin = FieldUtils.arrayMin(wellResNumbers);
                } else {
                    wellResNumMin = Number(wellResNum);
                }
            }
            return {
                wellResNumMin: wellResNumMin,
                wellResNum: wellResNum
            };
        },

        getCalculatedWellRes: function (wellResNumMin) {
            var contaminationAlgoSetting = this.model.get('settings')['CONTAM-ALGO'] ? this.model.get('settings')['CONTAM-ALGO'].split(';') : [];
            var lowValue = contaminationAlgoSetting ? contaminationAlgoSetting[0] : 15;
            var highValue = contaminationAlgoSetting ? contaminationAlgoSetting[1] : 40;

            if (wellResNumMin < lowValue) {
                return lowValue;
            } else if (wellResNumMin > highValue) {
                return highValue;
            } else {
                return wellResNumMin;
            }
        },

        findWellCt: function (line, column) {
            var retVal = false;
            var foundWell = this.model.get('wells').findWhere({'pos': line + column});
            var foundWellToDisplay = this.isWellToDisplay(foundWell);
            var result = foundWell.findWellResult(this.currentResult);

            if (foundWell && result && result.get('cts').length > 0 && foundWellToDisplay) {
                var wellResNums = this.getWellResNumValues(result.get('cts'));
                retVal = wellResNums && wellResNums.wellResNumMin ? wellResNums.wellResNumMin : false;
            }
            return retVal;
        },

        isWellToDisplay: function (well) {
            if (!well) {
                return false;
            }

            if (!well.get('refAssay')) {
                return false;
            }

            var assay = _.first(this.model.get('assayVersions').filter(function (assayDetailVersion) {
                return assayDetailVersion.get('assay') === well.get('refAssay');
            }));
            if (!assay) {
                return false;
            }
            if (!assay.get('results').any(_.bind(function (result) {
                return result.get('id') === this.currentResult.id;
            }, this))) {
                return false;
            }

            var wellResult = _.first(well.get('results').filter(_.bind(function (result) {
                return result && result.get('refAssayResult') && result.get('refAssayResult').get('id') === this.currentResult.id;
            }, this)));
            return !!(wellResult && wellResult.get('result') && wellResult.get('result').get('code') &&
                (well.get('smpType') !== 'PC' && well.get('smpType') !== 'NC' && well.get('smpType') !== 'RC' && well.get('smpType') !== 'OC') &&
                wellResult.get('cts').length > 0);
        },
        isWellToDisplayRepeat: function (well) {
            if (!well) {
                return false;
            }

            return !!well.get('repeatStatus');
        },

        showWell: function () {
            var val = $(this.ui.selectTarget)[0].selectedIndex;
            if (val === 0) {
                $(this.ui.leftSelectTarget).prop('disabled', true);
            } else {
                $(this.ui.leftSelectTarget).prop('disabled', false);
            }
            if (val === $(this.ui.selectTarget).find('option').length - 1) {
                $(this.ui.leftSelectTarget).prop('disabled', true);
            } else {
                $(this.ui.leftSelectTarget).prop('disabled', false);
            }
            var firstOfAssay, lastAssay, assayIndex = 0;

            this.model.get('wells').forEach(
                _.bind(function (well) {

                    var calculatedWellRes, wellResNumMin;
                    var adjacentWells = {};

                    this.wellToDisplay = this.isWellToDisplay(well);
                    this.wellToDisplayRepeat = this.isWellToDisplayRepeat(well);
                    if (this.wellToDisplay) {
                        var wellResult = well.findWellResult(this.currentResult);

                        var wellResNums = this.getWellResNumValues(wellResult.get('cts') ? wellResult.get('cts') : null);
                        wellResNumMin = wellResNums && wellResNums.wellResNumMin ? wellResNums.wellResNumMin : wellResult.get('cts');

                        calculatedWellRes = wellResNumMin ? this.getCalculatedWellRes(wellResNumMin) : null;

                        var column = well.get('pos').substring(1);
                        var line = well.get('pos').substring(0, well.get('pos').length - 2);
                        var previousLine = WellUtils.getPreviousLine(line, this.isMultiDisplay);
                        var nextLine = WellUtils.getNextLine(line, this.isMultiDisplay);
                        var previousColumn = WellUtils.getPreviousColumn(column, this.isMultiDisplay);
                        var nextColumn = WellUtils.getNextColumn(column, this.isMultiDisplay);

                        if (previousLine && previousColumn) { // top-left-well-ct
                            adjacentWells.topLeftCT = this.findWellCt(previousLine, previousColumn);
                        }

                        if (previousLine) { // top-center-well-ct
                            adjacentWells.topCenterCT = this.findWellCt(previousLine, column);
                        }

                        if (previousLine && nextColumn) { // top-right-well-ct
                            adjacentWells.topRightCT = this.findWellCt(previousLine, nextColumn);
                        }

                        if (previousColumn) { // middle-left-well-ct
                            adjacentWells.middleLefCT = this.findWellCt(line, previousColumn);
                        }

                        if (nextColumn) { // middle-right-well-ct
                            adjacentWells.middleRightCT = this.findWellCt(line, nextColumn);
                        }

                        if (nextLine && previousColumn) { // bottom-left-well-ct
                            adjacentWells.bottomLeftCT = this.findWellCt(nextLine, previousColumn);
                        }

                        if (nextLine) { // bottom-center-well-ct
                            adjacentWells.bottomCenterCT = this.findWellCt(nextLine, column);
                        }

                        if (nextLine && nextColumn) { // bottom-right-well-ct
                            adjacentWells.bottomRightCT = this.findWellCt(nextLine, nextColumn);
                        }
                    }

                    if (!lastAssay || (well.get('refAssay') && well.get('refAssay').code && well.get('refAssay').code !== lastAssay.code)) {
                        firstOfAssay = true;
                        assayIndex++;
                    } else {
                        firstOfAssay = false;
                    }
                    if (well.get('refAssay')) {
                        lastAssay = well.get('refAssay');
                    }

                    this.addRegion('tbl' + well.get('pos'), '.tbl-' + well.get('pos'));
                    var region = this.getRegion('tbl' + well.get('pos'));


                    var wellView = new WellView({
                        model: well,
                        isMultiDisplay: this.isMultiDisplay && this.model.get('wellCount') > 96,
                        region: region.$el,

                        calculatedWellRes: calculatedWellRes,
                        wellResNumMin: wellResNumMin,
                        adjacentWells: adjacentWells,
                        wellToDisplay: this.wellToDisplay,
                        wellToDisplayRepeat: this.wellToDisplayRepeat,

                        modelRun: this.model,
                        firstOfAssay: firstOfAssay,
                        assayIndex: assayIndex,
                        currentResult: this.currentResult
                    });

                    var fadeWell = false;
                    region.show(wellView);

                    if (fadeWell) {
                        if (!region.$el.hasClass('fadeWell')) {
                            region.$el.addClass('fadeWell');
                        }
                    } else {
                        if (region.$el.hasClass('fadeWell')) {
                            region.$el.removeClass('fadeWell');
                        }
                    }
                }, this));
        },
        changeWellSelected: function (from, disableGroup) {
            $('.js-global-loader').show();
            this.step = (disableGroup ? 1 : 2);
            if (disableGroup) {
                from = 'A01';
            }
            this.wellSelected = this.model.get('wells').filterByPos(from, this.step, this.model.get('plateSize'));
            App.navigate('runs/pcr/' + this.model.get('secId') + '/' + from + '/' + this.step + '/' + this.model.get('plateSize') + '/table');
            this.render();
            $('.js-global-loader').hide();
        },
        onTopLeftClick: function () {
            this.changeWellSelected('A01', this.step === 2 && this.wellSelected.first().get('pos') === 'A01');
        },

        onTopRightClick: function () {
            this.changeWellSelected('A02', this.step === 2 && this.wellSelected.first().get('pos') === 'A02');
        },

        onBottomLeftClick: function () {
            this.changeWellSelected('B01', this.step === 2 && this.wellSelected.first().get('pos') === 'B01');
        },

        onBottomRightClick: function () {
            this.changeWellSelected('B02', this.step === 2 && this.wellSelected.first().get('pos') === 'B02');
        }
    });
});
