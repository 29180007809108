/**
 * Created by RKL on 10/09/2015.
 */
define('validate',[
    'module',
    'underscore'
], function (
    module,
    _
) {
    'use strict';

    module.exports = {
        validateFields: function (model, field) {
            var errors = [],
                error = 'Empty field',
                fieldValue = model.get(field.name);

            if (_.isEmpty(fieldValue) && typeof fieldValue !== 'number') {
                field.error = error;
                errors.push(field);
            }

            return errors;
        },

        validateEmail: function (model, field) {
            var email = model.get(field.name),
                emailValid = this.checkEmail(email),
                errors = [],
                error = 'Email field is not valid';

            if (email && !emailValid) {
                field.error = error;
                errors.push(field);
            }

            return errors;
        },

        validateMultipleEmail: function (model, field) {
            var emails = model.get(field.name), errors = [],
                error = 'Email field is not valid';
            if (emails) {
                emails.split(';').forEach(_.bind(function (email) {
                    email = email.trim();
                    var emailValid = this.checkEmail(email);
                    if (email && !emailValid) {
                        field.error = error;
                        errors.push(field);
                    }
                }, this));
            }
            return errors;
        },

        validateDatePicker: function (model, field) {
            var date = model.get(field.name), errors = [],
                error = 'Date field is not valid';
            if (date && date < 0) {
                field.error = error;
                errors.push(field);
            }
            return errors;
        },

        checkEmail: function (email) {
            var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return pattern.test(email);
        },

        validateNumber: function (model, field) {
            var errors = [],
                error = 'Not a number',
                fieldValue = model.get(field.name);

            if (isNaN(fieldValue)) {
                field.error = error;
                errors.push(field);
            }

            return errors;
        },

        validateMin: function (model, min, fieldName) {
            var errors = [],
                error = 'Min value is ' + min,
                fieldValue = model.get(fieldName);

            if (fieldValue < min) {
                errors.push({name: fieldName, error: error});
            }

            return errors;
        },

        validateMax: function (model, max, fieldName) {
            var errors = [],
                error = 'Max value is ' + max,
                fieldValue = model.get(fieldName);

            if (fieldValue > max) {
                errors.push({name: fieldName, error: error});
            }

            return errors;
        }
    };
});

