/**
 * Created by RKL on 05/08/2015.
 */
define('forgotShowView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'template!forgotViewTpl'
], function(
  module,
  Marionette,
  App,
  _,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,

    triggers: {
      'change @ui.email': 'forgot:change:email',
      'click @ui.sendRequest': 'forgot:send:request'
    },

    ui: {
      email: '.js-request-email',
      sendRequest: '.js-send-request',
      form: '.email-form-group'
    },

    events: {
      'keydown @ui.email': 'onKeyPress',
      'click @ui.email': 'onSelect',
      'focus @ui.email': 'onSelect'
    },

    onKeyPress: function (e) {
      var charCode = (e.which) ? e.which : e.keyCode;

      if (charCode === 13) {
        e.preventDefault();
        this.triggerMethod('forgot:change:email', this, 'send');
      }
    },

    onSelect: function () {
      this.ui.form.find('.floating-label').text(_.i18n('common.email'));
      this.ui.form.removeClass('has-error');
    },

    emailError: function (error) {
      this.ui.form.addClass('has-error');
      this.ui.email.addClass('invalid');
      this.ui.form.find('.floating-label').text(error);
    }
  });
});
