define('runTabsLayoutHeaderView',[
    'module',
    'backbone',
    'autocompleteView',
    'jquery',
    'app',
    'bootbox',
    'settings',
    'template!runPcrTooltipTpl',
    'envHandler',
    'marionette',
    'template!runTabsLayoutHeaderTpl',
    'dateUtils',
    'imageUtils',
    'customBootboxMessage',
    'underscore',
    'importResultsInPcrRunView',
    'runRemoteFolderView',
    'runEditLayoutView',
    'wellEditSmpIdPlateView',
    'emptyView',
    'colorUtils',
    'cssUtils',
    'fieldUtils',
    'wellUtils',
    'runsShowController',
    'runPcrKitsLotsView',
    'exportResultStatView',
    'bootbox',
    'runTableTabController',
    'pcrRunTracsView',
    'runImportPlateLayoutView',
    'runImportSampleListView',
    'runExportPlateLayoutView',
    'runExportPlateLayoutCyclerView',
    'runExportSampleListView',
    'template!pcrRunStatusTpl',
    'prepRunCollectionView',
    'entities/caccounts/pcrruns',
    'entities/admin/codelist',
    'runController'
], function (
    module,
    Backbone,
    AutocompleteView,
    $,
    App,
    Bootbox,
    Settings,
    runPcrTooltipTpl,
    EnvironmentHandler,
    Marionette,
    RunTabsLayoutHeaderTpl,
    dateFormatter,
    imageUtils,
    CustomBootboxMessage,
    _,
    ImportResultsInPcrRunView,
    RunRemoteFolderView,
    CreateEditLayoutView,
    CreateEditSmpIdPlateLayoutView,
    EmptyView,
    ColorUtils,
    CssUtils,
    FieldUtils,
    WellUtils,
    RunsShowController,
    RunPcrKitsLotsView,
    ExportResultStatView,
    bootbox,
    RunTableTabController,
    PcrRunTracsView,
    RunImportPlateLayoutView,
    RunImportSampleListView,
    RunExportPlateLayoutView,
    RunExportPlateCyclerLayoutView,
    RunExportSampleListView,
    StatusToolTip,
    PrepRunCollectionView,
    PcrRuns,
    CodeLists,
    RunController
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: RunTabsLayoutHeaderTpl,

        tagName: 'div',

        ui: {
            testSpan: '.testSpan',
            logo: '.js-logo-holder',
            tabs: '.js-tab-layout-tab',
            filters: '.js-tab-filters',
            tabsRegion: '.js-tabs-region',
            header: '.js-tab-layout-header',
            refreshBtn: '.refresh-button',
            previousRunMain: '.js-show-previous-run-main',
            nextRunMain: '.js-show-next-run-main',
            previousRun: '.js-show-previous-run',
            nextRun: '.js-show-next-run',
            toSelectedRun: '.js-show-selected-run',
            resetFilters: '.js-reset-filters',
            showKits: '.btn-show-kits',
            showAttachments: '.btn-show-attachments',
            generateSmpIds: '.js-generate-smpids',
            removeSmpIds: '.js-remove-smpids',
            assignAssay: '.js-assign-assay',
            removeAssay: '.js-remove-assay',
            replaceAssay: '.js-replace-assay',
            addTest: '.js-add-test',
            removeTest: '.js-remove-test',
            clearWells: '.js-clear-wells',
            mainHeaderRunBox: '.pop-mainHeaderRunBox',
            reloadCurves: '.js-reload-Curves',
            tracsView: '.js-pcrRun-tracs-view',
            reportSummary: '.js-pcrRun-summary',
            statusToolTip: '.js-status-tooltip',
            copyParameters: '.js-copyParameters',
            assayTargets: '.js-assays-targets',
            results: '.js-results',
            contaminations: '.js-contaminations'
        },

        events: {
            'click @ui.showKits': 'onShowKits',
            'click @ui.showAttachments': 'onShowAttachments',
            'click @ui.resetFilters': 'onResetFilters',
            'click .js-show-previous-run.clickable': 'onPreviousRun',
            'click .js-show-next-run.clickable': 'onNextRun',
            'change @ui.toSelectedRun': 'onSelectedRun',
            'click .js-tab-layout-tab.clickable': '_navigate',
            'click .btn-edit-run': 'onEditRun',
            'click .js-run-refresh': 'onRunRefresh',
            'click .download-export-run-button.clickable': 'onExportResult',
            'click .download-export-run-button-reexport.clickable': 'onReExportResult',
            'click .download-export-run-button-manual.clickable': 'onExportResultManual',
            'click .fill-smpids': 'onEditWellsSmpId',
            'click .import-plateLayout-button.clickable': 'onPlateLayoutImport',
            'click .import-sampleList-button.clickable': 'onSampleListImport',
            'click .import-plateLayout-fromOtherRun-button.clickable': 'onPlateLayoutImportFromOtherRun',
            // 'click .edit-plateLayout-button': 'onPlateLayoutEdit',
            'click .scan-plateLayout-button.clickable': 'onPlateLayoutScan',
            'click .edit-plateLayout-button.clickable': 'onPlateLayoutEdit',
            'click .export-plateLayout-button': 'onPlateLayoutExport',
            'click .export-plateLayout-cycler-button': 'onPlateLayoutCyclerExport',
            'click .export-sampleList-button': 'onSampleListExport',
            'click .import-xml-button-headerButton': 'onCheckBeforeImport',
            'click @ui.refreshBtn': 'refreshBtnClick',
            'click .js-select-filter': 'onSelectFilters',
            'click .ctrl-plateLayout-button': 'onPlateLayoutControl',
            'click @ui.generateSmpIds.clickable': 'onGenerateSmpIds',
            'click @ui.removeSmpIds.clickable': 'onRemoveSmpIds',
            'click @ui.assignAssay.clickable': 'onAssignAssay',
            'click @ui.removeAssay.clickable': 'onRemoveAssay',
            'click @ui.replaceAssay.clickable': 'onReplaceAssay',
            'click @ui.addTest.clickable': 'onAddTest',
            'click @ui.removeTest.clickable': 'onRemoveTest',
            'click @ui.clearWells.clickable': 'onClearWells',
            'click .js-print-exportList': 'onPrintExportList',
            'click .js-val2run.clickable': 'onVal2Run',
            'click @ui.reloadCurves': 'onReloadCurves',
            'click .js-run-action-nav-drop-btn': 'onRunActionNavDropBtnClick',
            'click .js-run-action-nav-drop-cell-btn': 'onRunActionNavDropCellBtnClick',
            'mouseleave .run-action-nav-container': 'onHideRunActionContainer',
            'click @ui.tracsView': 'onShowTracsView',
            'click @ui.reportSummary': 'onReportSummaryClick',
            'click .js-print-runSheet.clickable': 'onPrintRunSheet',
            'click .js-print-setup.clickable': 'onPrintPcrSetup',
            'click .js-print-setupMMX.clickable': 'onPrintPcrSetupMMX'
        },

        regions: {
            header: '.js-header-region',
            prepRuns: '.js-prepruns'
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        onShowTracsView: function () {
            this.pcrRunTracsView = new PcrRunTracsView({
                model: this.model
            });
            this.pcrRunTracsView.show({
                title: _.i18n('tracs'),
                className: 'runShowTracsPopup'
            });
        },

        onHideRunActionContainer: function (e) {
            var $target = $(e.currentTarget).find('.run-action-nav-list');
            $target.addClass('hidden');
            $(e.currentTarget).find('.run-action-nav-drop-btn').removeClass('active');
        },

        onRunActionNavDropBtnClick: function (e) {
            var $target = $(e.currentTarget).parent().find('.run-action-nav-list');
            if ($target.hasClass('hidden')) {
                $target.removeClass('hidden');
                $(e.currentTarget).addClass('active');
            } else {
                $target.addClass('hidden');
                $(e.currentTarget).removeClass('active');
            }
        },

        onRunActionNavDropCellBtnClick: function (e) {
            var $target = $(e.currentTarget).parent().parent().parent().find('.run-action-nav-list');
            if ($target.hasClass('hidden')) {
                $target.removeClass('hidden');
                $(e.currentTarget).addClass('active');
            } else {
                $target.addClass('hidden');
                $(e.currentTarget).removeClass('active');
            }
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        initialize: function () {
            this.selectedRunsList = RunsShowController.getSelectedRunsList();
            this.filtersToAllow = {};
            this.filtersToAllow.selectSmpId = {selector: 'js-select-smpId', show: false};
            this.filtersToAllow.selectVal1 = {selector: 'js-select-val1', show: false};
            this.filtersToAllow.selectVal2 = {selector: 'js-select-val2', show: false};
            this.filtersToAllow.selectValExpert = {selector: 'js-select-val-expert', show: false};
            this.filtersToAllow.selectWarning = {selector: 'js-select-warning', show: false};
            this.filtersToAllow.selectRepeatPcr = {selector: 'js-select-repeat-pcr', show: false};
            this.filtersToAllow.selectRepeatExtract = {selector: 'js-select-repeat-extract', show: false};
            this.filtersToAllow.selectSameAssay = {selector: 'js-select-sameAssay', show: false};
            this.filtersToAllow.selectOtherAssay = {selector: 'js-select-otherAssay', show: false};
            this.filtersToAllow.selectSmpId.show = this.model.get('status') !== 0;
            this.cyclerSelected = this.model && this.model.get('refCycler') && this.model.get('refCycler').secId ? this.model.get('refCycler').secId : null;

        },

        onRender: function () {

            this.model.get('wells').forEach(
                _.bind(function (well) {

                    if (!this.filtersToAllow.selectVal1.show && well.get('valWst') >= 2 && well.get('valWst') < 3) {
                        this.filtersToAllow.selectVal1.show = true;
                    } else if (!this.filtersToAllow.selectVal2.show && well.get('valWst') === 3.2) {
                        this.filtersToAllow.selectVal2.show = true;
                    } else if (!this.filtersToAllow.selectValExpert.show && well.get('valWst') === 3.1) {
                        this.filtersToAllow.selectValExpert.show = true;
                    }
                    if (!this.filtersToAllow.selectWarning.show) {
                        this.filtersToAllow.selectWarning.show = well.get('results').some(function (result) {
                            return result.get('codeErr');
                        });
                    }
                    if ((!this.filtersToAllow.selectRepeatPcr.show) && well.get('repeatStatus') && (well.get('repeatStatus') === 'PC' || well.get('repeatStatus') === 'PT')) {
                        this.filtersToAllow.selectRepeatPcr.show = true;
                    }
                    if ((!this.filtersToAllow.selectRepeatExtract.show) && well.get('repeatStatus') && (well.get('repeatStatus') === 'XC' || well.get('repeatStatus') === 'XT')) {
                        this.filtersToAllow.selectRepeatExtract.show = true;
                    }
                    if (!this.filtersToAllow.selectSameAssay.show && well.get('existOtherWellSameAssay')) {
                        this.filtersToAllow.selectSameAssay.show = true;
                    }
                    if (!this.filtersToAllow.selectOtherAssay.show && well.get('existOtherWellOtherAssay')) {
                        this.filtersToAllow.selectOtherAssay.show = true;
                    }
                }, this));
            this.allowFilters(this.filtersToAllow);

            this.wellFiltersList = [];
            this.wellFiltersList = this.options.runTabsLayoutController.getWellFiltersList();
            this.currentTab = this.options.runTabsLayoutController.getCurrentTab();
            this.onShowActiveFilters(this.wellFiltersList);
            this.allowActionButtons();

            var logo;
            if (!this.model.getImageUid) {
                throw 'Add method getImageUid to your model. It\'s required for displaying on header.';
            }
            logo = imageUtils.getLogo(this.model.getImageUid(), this.model.get('code'));
            if (logo) {
                this.ui.logo.css('background-image', 'url(' + logo + ')');
            } else {
                this.ui.logo.addClass('default-upload-image');
            }
            this._setHeight();

            this.filteredWells = this.options.runTabsLayoutController.getFilteredWellsCollection();

            this.showMainHeaderRunPopover(this.model);

            CodeLists.findItemsByCode('PCRRUN-VAL-ST').done(_.bind(function (data) {
                this.codeListElements = data;
                this.showStatusRunPopover();
            }, this));

            this.hideRemainingPopover();

            // hide runHeader buttons & filters in valView tab
            var $items = this.$el.find('.css-grayed-out-header');
            _.each($items, _.bind(function (item) {
                var $item = $(item);
                // $item.css('display', this.options.opacity);
                $item.css('opacity', this.options.opacity);

                if (this.options.opacity === '1') {
                    $item.css('pointer-events', 'inherit');
                } else {
                    $item.css('pointer-events', 'none');
                }
            }, this));

            var allowPreviousBtn = this.selectedRunsList ? true : false;
            var allowNextBtn = this.selectedRunsList ? true : false;
            _.each(this.selectedRunsList, _.bind(function (run, i) {
                if (run.secId === this.model.get('secId')) {
                    if (i === 0) {
                        allowPreviousBtn = false;
                    }
                    if (i === this.selectedRunsList.length - 1) {
                        allowNextBtn = false;
                    }
                }
            }, this));
            var prepruns = new PrepRunCollectionView({collection: this.model.get('prepRuns')});
            this.getRegion('prepRuns').show(prepruns);
            App.trigger('forClipboard', this.ui.copyParameters);
            this.onShowActive(this.options.runTabsLayoutController.getCurrentTab());
            if (this.model.get('assayVersions').isEmpty() || this.model.get('status') === 0) {
                this.ui.assayTargets.removeClass('clickable');
                this.ui.results.removeClass('clickable');
                this.ui.contaminations.removeClass('clickable');
            } else {
                this.ui.assayTargets.addClass('clickable');
                this.ui.results.addClass('clickable');
                this.ui.contaminations.addClass('clickable');
            }
            this.ui.previousRun.removeClass('clickable');
            this.ui.previousRun.css('opacity', 0.2);
            this.ui.previousRunMain.css('opacity', 0.2);
            this.ui.nextRun.removeClass('clickable');
            this.ui.nextRun.css('opacity', 0.2);
            this.ui.nextRunMain.css('opacity', 0.2);
            if (this.currentTab !== 'assays-targets') {
                if (allowPreviousBtn) {
                    this.ui.previousRun.addClass('clickable');
                    this.ui.previousRun.css('opacity', 1);
                    this.ui.previousRunMain.css('opacity', 1);
                }
                if (allowNextBtn) {
                    this.ui.nextRun.addClass('clickable');
                    this.ui.nextRun.css('opacity', 1);
                    this.ui.nextRunMain.css('opacity', 1);
                }
            }
        },

        allowFilters: function (filtersToAllow) {
            _.each(filtersToAllow, _.bind(function (f) {
                var filterComponent = $('.headerFilterButton.' + f.selector + '.js-tab-filters.js-select-filter');
                if (f.show) {
                    this.displayFilterComponent(filterComponent, true);
                } else {
                    this.displayFilterComponent(filterComponent, false);
                }
            }, this));
        },

        displayFilterComponent: function (filterComponent, display) {
            filterComponent.css('opacity', display ? 1 : 0.3);
            if (display) {
                filterComponent.addClass('js-select-filter');
                filterComponent.addClass('clickable');
            } else {
                filterComponent.removeClass('js-select-filter');
                filterComponent.removeClass('clickable');
            }
            filterComponent.find('input[type="checkbox"]').css('opacity', display ? 1 : 0);
        },

        onPrintExportList: function (e) {
            var typeName = e.currentTarget.attributes['data-print-type'].value;

            if (typeName) {
                $('.js-global-loader').show();
                this.model.exportList(typeName)
                    .done(_.bind(function (response) {
                        $('.js-global-loader').hide();
                        if (response.success) {
                            _.each(response.exportResponses, function (exportResponse) {
                                var element = document.createElement('a');
                                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(exportResponse.resultArray));
                                element.setAttribute('download', exportResponse.fileName);
                                element.style.display = 'none';
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            });
                        } else {
                            var message = '';
                            _.each(response.errors, function (error) {
                                message += error + '<br>';
                            });
                            Bootbox.alert(message);
                        }
                    }, this))
                    .fail(function () {
                        $('.js-global-loader').hide();
                    });
            }
        },

        onShowKits: function () {
            this.model.getPcrKitLots().done(_.bind(function (response) { // besoin des kitLots
                this.runPcrKitsLotsView = new RunPcrKitsLotsView({
                    model: this.model,
                    kitLots: response
                });
                this.runPcrKitsLotsView.show({
                    title: _.i18n('run.pcr.kits'),
                    className: 'runShowKitsPopup'
                });
                this.listenTo(this.runPcrKitsLotsView, 'on:print:runSheet', _.bind(this.onPrintRunSheet, this));

            }, this));
        },

        onShowAttachments: function () {
            this.model.getEntity().openAttachment(this.model);
        },

        onPrintRunSheet: function () {
            PcrRuns.generateRunSheet(this.model.get('secId'));
        },

        onPrintPcrSetup: function () {
            PcrRuns.generatePcrSetup(this.model.get('secId'));
        },
        onPrintPcrSetupMMX: function () {
            PcrRuns.generatePcrSetupMMX(this.model.get('secId'));
        },
        onReportSummaryClick: function () {
            this.model.generateSummary();
        },

        findNextPcrRunAttr: function (array, attr, value, next) {
            var nextPcrRun = false;
            for (var i = 0; i < array.length; i += 1) {
                if (array[i][attr] === value) {
                    if (next) {
                        if (i === array.length - 1) {
                            nextPcrRun = array[0];
                        } else {
                            nextPcrRun = array[i + 1];
                        }
                    } else {
                        if (i === 0) {
                            nextPcrRun = array[array.length - 1];
                        } else {
                            nextPcrRun = array[i - 1];
                        }
                    }
                }
            }
            return nextPcrRun;
        },

        findSelectedPcrRunAttr: function (array, attr, value) {
            var selectedPcrRun = false;
            for (var i = 0; i < array.length; i += 1) {
                if (array[i][attr] === value) {
                    selectedPcrRun = array[i];
                }
            }
            return selectedPcrRun;
        },

        onPreviousRun: function () {
            this.nextPreviousPcrRun(false);
        },

        onNextRun: function () {
            this.nextPreviousPcrRun(true);
        },

        onSelectedRun: function () {
            var runSecId = $(this.ui.toSelectedRun).children('option:selected').val();
            var nextPcrRun;
            nextPcrRun = this.findSelectedPcrRunAttr(this.selectedRunsList, 'secId', runSecId);
            this.navigateToSelectedRun(nextPcrRun);
        },

        nextPreviousPcrRun: function (next) {
            var nextPcrRun;

            if (this.selectedRunsList && this.selectedRunsList.length > 0) {
                nextPcrRun = this.findNextPcrRunAttr(this.selectedRunsList, 'secId', this.model.get('secId'), next);
                this.navigateToSelectedRun(nextPcrRun);
            } else {
                if (next) {
                    nextPcrRun = this.model.getNextRun()
                        .done(_.bind(function (nextPcrRun) {
                            this.navigateToSelectedRun(nextPcrRun);
                        }, this));
                } else {
                    nextPcrRun = this.model.getPreviousRun()
                        .done(_.bind(function (nextPcrRun) {
                            this.navigateToSelectedRun(nextPcrRun);
                        }, this));
                }
            }
        },

        navigateToSelectedRun: function (nextPcrRun) {
            this.options.runTabsLayoutController.removeWellResFromWellFiltersList();
            this.options.runTabsLayoutController.removeSmpIdSearchFromWellFiltersList();
            var currentRoute = App.getCurrentRoute();
            var nextPcrRunSecID = nextPcrRun !== '' && nextPcrRun.secId ? nextPcrRun.secId : false;

            if (nextPcrRunSecID) {
                if (currentRoute.includes(nextPcrRunSecID)) {
                    return;
                } else {
                    App.regions.getRegion('main').currentView.getRegion('content').show(new EmptyView());

                    var plateSize = $(nextPcrRun.plateSize).text();
                    var route = 'runs/pcr/' + nextPcrRunSecID +
                        '/A01/1/' + plateSize;
                    App.navigate(route, {trigger: true});
                }
            } else {
                Bootbox.alert(_.i18n('warning.noMoreRun'));
            }
        },

        serializeData: function () {
            var templateData = {};
            templateData.name = this.model.get('name');
            templateData.valStatus = this.model.get('valStatus');
            templateData.status = this.model.get('status');
            templateData.secId = this.model.get('secId');
            templateData.alreadyValidated = this.model.get('alreadyValidated');
            templateData.tabs = [];
            _.each(this.options.tabs, _.bind(function (tab) {
                if ((!tab.role || Settings.get('currentUserModel').hasRole(tab.role)) &&
                    (tab.displayOnPooled === undefined || tab.displayOnPooled === true ||
                        (tab.displayOnPooled === false && !this.model.get('toBePooled')))) {
                    templateData.tabs.push(tab);
                }
            }, this));
            templateData.formatedDate = dateFormatter.toDateTimeFormatString(templateData.creatDate);
            templateData.analyser = '';
            if (templateData.refLmbCycler) {
                templateData.analyser = templateData.refLmbCycler.code;
            }
            templateData.wellExported = 0;
            templateData.wellCanBeExported = 0;
            templateData.wellRepeat = 0;

            this.model.get('wells').each(_.bind(function (well) {
                if (well.get('smpType') !== 'PC' && well.get('smpType') !== 'NC' && well.get('smpType') !== 'RC') {
                    templateData.wellCanBeExported++;
                }
                if (well.get('exported')) {
                    templateData.wellExported++;
                }
                if (well.get('repeatStatus')) {
                    templateData.wellRepeat++;
                }
            }, this));

            templateData.runReadyToexport = this.model.get('wells').find(function (well) {
                return well.get('valWst') >= 4 && well.get('valWst') < 5;
            });

            if (templateData.runReadyToexport) {
                templateData.runReadyToexport = true;
            }

            var results = [];
            this.model.get('wells').each(_.bind(function (well) {
                // TODO : A changer car il peut y avoir plusieurs WELL RES maintenant
                var wellRes = _.first(well.getDisplayGroup());
                if (wellRes && wellRes.get('result')) {
                    if (!_.some(results, function (model) {
                        return model.secId === wellRes.get('result').get('secId');
                    })) {
                        results.push(wellRes.get('result').toJSON());
                    }
                }
            }, this));
            templateData.results = results;

            templateData.colorOtherWellSameAssay = '#FF0000';
            templateData.colorOtherWellOtherAssay = '#000000';
            if (this.model.get('settings') && this.model.get('settings')['OTHER-ASSAY-IND']) {
                var otherAssayInd = this.model.get('settings')['OTHER-ASSAY-IND'].split(';');
                if (otherAssayInd[0]) {
                    templateData.colorOtherWellSameAssay = otherAssayInd[0];
                }
                if (otherAssayInd[1]) {
                    templateData.colorOtherWellOtherAssay = otherAssayInd[1];
                }
            }

            var runslist = [];
            _.each(this.selectedRunsList, function (selectedRun) {
                var name = $(selectedRun.code).text();
                if (name === '') {
                    name = selectedRun.code;
                }
                var secId = selectedRun.secId;
                runslist.push({
                    name: name,
                    secId: secId
                });
            });
            templateData.selectedRunsList = runslist;


            return templateData;
        },

        getValStatusIcon: function (templateStatus, templateValStatus, editPopup) {
            var status = parseInt(templateStatus, 10);
            var valStatus = templateValStatus;
            var iconColor = [];
            var bottom = editPopup ? '5px' : '16px';
            var statusIcon = '';
            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb" style="bottom:' + bottom + '; font-size: 12px;">' + valStatus + '</span>';
            statusIcon += '<div class="cell-default" style="display: flex; top: 5px;\n' +
                '            position: relative;" title="' + valStatus + '">';

            var valStatusArray = valStatus.split('');
            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                if (valStatusArray.includes(i.toString())) {
                    iconColor[i] = 'statusIcon-toDo';
                }
                if (i === 5 && valStatusArray.includes(i.toString()) && status === 5) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-Header-valStatusIcon ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        },

        allowActionButtons: function () {
            var status = this.model.get('status');
            var valStatus = this.model.get('valStatus');
            var canExport = Settings.get('currentUserModel').hasType('EXP');
            var canVal2Run = Settings.get('currentUserModel').hasType('VAL2G');
            if (!this.model.canAssignSID()) {
                this.disableButton('.btn.btn-sm.js-generate-smpids');
                this.disableButton('.btn.btn-sm.import-plateLayout-button');
                this.disableButton('.btn.btn-sm.import-plateLayout-fromOtherRun-button');
                this.disableButton('.btn.btn-sm.scan-plateLayout-button');
                this.disableButton('.fill-smpids-container', '.headerActionButtonIcon ', 'edit-plateLayout-button');
                this.disableButton('.import-plateLayout-button-container', '.mdiHeaderActionButtonIcon', 'import-plateLayout-button');
                this.disableButton('.edit-plateLayout-button-container', '.mdiHeaderActionButtonIcon', 'edit-plateLayout-button');
            }
            if (!this.model.canAssignAssay()) {
                this.disableButton('.btn.btn-sm.js-assign-assay');
                this.disableButton('.assay-add-button-container', '.mdiHeaderActionButtonIcon', 'js-assign-assay');
            }
            if (!this.model.canClearAssay()) {
                this.disableButton('.btn.btn-sm.js-remove-assay');
                this.disableButton('.assay-remove-button-container', '.mdiHeaderActionButtonIcon', 'js-remove-assay');
                this.disableButton('.assay-replace-button-container', '.mdiHeaderActionButtonIcon', 'js-replace-assay');
            }
            if (this.model.get('alreadyValidated')) {
                //désactiver  Boutons Plate Layout
                this.disableButton('.import-plateLayout-button-container', '.mdiHeaderActionButtonIcon', 'import-plateLayout-button');
                this.disableButton('.fill-smpids-container', '.headerActionButtonIcon ', 'edit-plateLayout-button');
                this.disableButton('.btn.btn-sm.import-plateLayout-button');
                this.disableButton('.btn.btn-sm.import-plateLayout-fromOtherRun-button');
                this.disableButton('.btn.btn-sm.scan-plateLayout-button');
            }
            if (status === 5) {
                //désactiver  Bouton “Result input” et “recalc”
                this.disableButton('.import-xml-button-headerButton-container', '.mdiHeaderActionButtonIcon', 'import-xml-button-headerButton');
                this.disableButton('.refresh-button-container', '.headerActionButtonIcon', 'refresh-button');
            }
            if (!canExport || (valStatus && !valStatus.includes(4))) {
                this.disableButton('.btn.btn-sm.download-export-run-button');
                this.disableButton('.download-export-run-button-container', '.headerActionButtonIcon', 'download-export-run-button');
            }
            if (valStatus && !valStatus.includes(4) && !valStatus.includes(5)) {
                this.disableButton('.btn.btn-sm.download-export-run-button-manual');
                this.disableButton('.btn.btn-sm.download-export-run-button-reexport');
                this.disableButton('.download-export-run-button-container', '.headerActionButtonIcon', 'download-export-run-button-manual');
                this.disableButton('.download-export-run-button-container', '.headerActionButtonIcon', 'download-export-run-button-reexport');
            }
            if (!canVal2Run) {
                this.disableButton('.btn.btn-sm.js-val2run');
            }
            if (_.isEmpty(this.model.get('assayVersions'))) {
                this.disableButton('.btn.btn-sm.js-print-exportList');
            }
        },

        disableButton: function (container, finder, removing) {
            var selector = this.$el.find(container);
            // selector.css('opacity', 0.3);
            selector.removeClass('clickable');
            if (finder && removing) {
                selector.find(finder).removeClass(removing);
                selector.find(finder).removeClass('clickable');
            }
        },

        showMainHeaderRunPopover: function (model) {
            if (this.ui.mainHeaderRunBox && this.ui.mainHeaderRunBox.length > 0) {
                var templateData = {
                    name: model.get('name'),
                    department: model.get('department'),
                    periodFormatted: model.get('periodFormatted'),
                    group: model.get('group'),
                    sequenceFormatted: model.get('sequenceFormatted'),
                    kitProt: model.get('refKitProt') ? model.get('refKitProt').code : '',
                    lmbCycler: model.get('refLmbCycler') ? model.get('refLmbCycler').code : '',
                    respUser: model.get('refRespUser') ? (model.get('refRespUser').nickName ? model.get('refRespUser').nickName : (model.get('refRespUser').email ? model.get('refRespUser').email : '')) : '',
                    comment: model.get('comment'),
                    valStatus: model.get('valStatus')
                };
                templateData.valStatusIcon = this.getValStatusIcon(model.get('status'), model.get('valStatus'));

                var formattedPcrRuns = [];
                this.model.get('prepRuns').each(function (obj) {
                    formattedPcrRuns.push(obj.get('code'));
                });
                templateData.formattedPcrRuns = formattedPcrRuns.length > 0 ? formattedPcrRuns.join(';') : '';

                templateData.assaysToDisplay = this.model.get('assays') && !this.model.get('assays').isEmpty() ? this.model.get('assays').pluck('code').join(';') : '';
                templateData.sequenceFormatted = FieldUtils.getSequenceFormatted(model.get('sequence'), model.get('sequenceFormat'));
                templateData.wellSize = this.model.get('wells') && this.model.get('wells').size() ? this.model.get('wells').size() : '';
                templateData.creatDateToDisplay = dateFormatter.toDateTimeFormatStringFromSettings(this.model.get('creatDate'), this.model.get('settings'));
                templateData.wellsSizePlateIcon = WellUtils.getWellsSizePlateIcon(this.model.get('wells').size(), true);

                var content = Marionette.Renderer.render(runPcrTooltipTpl, templateData);
                this.ui.mainHeaderRunBox.attr('data-content', content);
                this.ui.mainHeaderRunBox.popover({
                    trigger: 'manual',
                    placement: 'bottom',
                    html: 'true',
                    delay: {
                        show: '300',
                        hide: '10'
                    },
                    container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
                }).on('mouseenter', function () {
                    var _this = this;
                    $(this).popover('show');
                    $('.popover').on('mouseleave', function () {
                        $(_this).popover('hide');
                    });
                }).on('mouseleave', function () {
                    var _this = this;
                    setTimeout(function () {
                        if (!$('.popover:hover').length) {
                            $(_this).popover('hide');
                        }
                    }, 10);
                }).data('bs.popover').tip().addClass('run-pcr-popover');
            }
        },

        showStatusRunPopover: function () {
            if (this.ui.statusToolTip && this.ui.statusToolTip.length > 0) {
                var content = Marionette.Renderer.render(StatusToolTip, {
                    elements: this.codeListElements,
                    valStatus: this.model.get('valStatus')
                });
                this.ui.statusToolTip.attr('data-content', content);
                this.ui.statusToolTip.popover({
                    trigger: 'manual',
                    placement: 'bottom',
                    html: 'true',
                    delay: {
                        show: '300',
                        hide: '10'
                    },
                    container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
                }).on('mouseenter', function () {
                    var _this = this;
                    $(this).popover('show');
                    $('.popover').on('mouseleave', function () {
                        $(_this).popover('hide');
                    });
                }).on('mouseleave', function () {
                    var _this = this;
                    setTimeout(function () {
                        if (!$('.popover:hover').length) {
                            $(_this).popover('hide');
                        }
                    }, 10);
                }).data('bs.popover').tip().addClass('run-prep-popover');
            }
        },

        onShowActiveFilters: function (filtersName) {
            this.ui.filters.each(function (index, tab) {
                var $tab = $(tab);
                $tab.removeClass('activeFilter');
                $tab.find('input').prop('checked', false);
            });

            _.each(filtersName, _.bind(function (f) {
                this.ui.filters.each(function (index, tab) {
                    var $tab = $(tab);
                    if ($tab.hasClass('js-' + f)) {
                        $tab.addClass('activeFilter');
                        $tab.find('input').prop('checked', true);
                    }
                });
            }, this));
        },

        onShowActive: function (tabName) {
            this.ui.tabs.each(function (index, tab) {
                var $tab = $(tab);
                if ($tab.hasClass('js-' + tabName)) {
                    $tab.addClass('active');
                } else {
                    $tab.removeClass('active');
                }
            });
        },

        onSelectSmpidFilters: function (e) {
            var $target = $(e.currentTarget);
            var selectedWellFilter = $target.attr('name');
            if (!$target.hasClass('activeFilter')) {
                Bootbox.prompt({
                    title: _.i18n('popup.select.smpId'),
                    className: 'actionPopupCss popupFitContent headerAction-bootboxPopup',
                    onEscape: true,
                    buttons: {
                        confirm: {
                            label: '<i></i>',
                            className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm btn-primary'
                        },
                        cancel: {
                            label: '<i></i>',
                            className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                        }
                    },
                    callback: _.bind(function (result) {
                        if (result) {
                            this.onSmpidSearch(result, e);
                        }
                        Bootbox.hideAll();
                    }, this)
                });
            } else {
                this.options.runTabsLayoutController.onWellFiltersListChange(selectedWellFilter);
                this.options.runTabsLayoutController.removeSmpIdSearchFromWellFiltersList();
                this.wellFiltersList = [];
                this.wellFiltersList = this.options.runTabsLayoutController.getWellFiltersList();
                this.onShowActiveFilters(this.wellFiltersList);
                if (this.wellFiltersList.length > 0) {
                    this.filteredWells = this.options.runTabsLayoutController.getFilteredWellsCollection();
                } else {
                    this.filteredWells = null;
                }
                this.triggerMethod('navigate', null);
            }
        },

        onSmpidSearch: function (result, e) {
            var $target = $(e.currentTarget);
            var selectedWellFilter = $target.attr('name');
            this.options.runTabsLayoutController.onWellFiltersListChange(selectedWellFilter);
            this.options.runTabsLayoutController.onWellSmpidSearch(result);

            this.wellFiltersList = [];
            this.wellFiltersList = this.options.runTabsLayoutController.getWellFiltersList();
            this.onShowActiveFilters(this.wellFiltersList);

            if (this.wellFiltersList.length > 0) {
                this.filteredWells = this.options.runTabsLayoutController.getFilteredWellsCollection();
            } else {
                this.filteredWells = null;
            }
            this.triggerMethod('navigate', null);
        },

        onResetFilters: function () {
            this.options.runTabsLayoutController.removeAllFiltersFromList();
            this.wellFiltersList = [];
            this.filteredWells = null;
            this.triggerMethod('navigate', null);
        },

        onSelectFilters: function (e/*, result*/) {
            var $target = $(e.currentTarget);
            var selectedWellFilter = $target.attr('name');
            if (selectedWellFilter === 'select-smpId') {
                this.onSelectSmpidFilters(e);
            } else {
                this.options.runTabsLayoutController.onWellFiltersListChange(selectedWellFilter);

                this.wellFiltersList = [];
                this.wellFiltersList = this.options.runTabsLayoutController.getWellFiltersList();
                this.onShowActiveFilters(this.wellFiltersList);

                if (this.wellFiltersList.length > 0) {
                    this.filteredWells = this.options.runTabsLayoutController.getFilteredWellsCollection();
                } else {
                    this.filteredWells = null;
                }

                var selectedTab = $('#header-navBar-tabs').find('.active').attr('data-tab-name');
                if (selectedTab && selectedTab === 'table' || selectedTab === 'details') {
                    this.triggerMethod('navigate', null);
                }
            }
        },

        _navigate: function (e) {
            var $target = $(e.currentTarget);
            var tabName = $target.data('tab-name');
            this.options.runTabsLayoutController.setCurrentTab(tabName);
            this.triggerMethod('navigate', tabName, null);
            this.hideRemainingPopover();
        },

        hideRemainingPopover: function () {
            var $items = this._parent.$el.parent().parent().parent().find('.popover');
            _.each($items, _.bind(function (item) {
                $(item).addClass('hidden');
            }, this));
        },

        _setHeight: _.throttle(function () {
            var windowHeight = $(window).height(),
                headerHeight = $('header').height() || 75,
                detailsHeaderHeight = this.ui.header && this.ui.header.outerHeight() ? this.ui.header.outerHeight() : 0,
                height = windowHeight - headerHeight - detailsHeaderHeight;
            this.ui.tabsRegion.height(height);
        }, 500),

        onNavigate: function (tabName) {
            var selectedTab;
            if (!tabName) {
                selectedTab = $('#header-navBar-tabs').find('.active').attr('data-tab-name');
            } else {
                selectedTab = tabName;
            }
            var currentRoute = App.getCurrentRoute();
            var routeSplitted = currentRoute.split('/'), newRoute = [];
            newRoute.push(routeSplitted[0]);
            newRoute.push(routeSplitted[1]);
            newRoute.push(routeSplitted[2]);
            newRoute.push(routeSplitted[3]);
            newRoute.push(routeSplitted[4]);
            newRoute.push(routeSplitted[5]);

            if (selectedTab) {
                newRoute.push(selectedTab);
            } else {
                newRoute.push('table');
            }

            if (this.filteredWells && this.filteredWells.length > 0 && (selectedTab === 'details' || selectedTab === 'table')) {
                newRoute.push('select');
            }
            newRoute = newRoute.join('/');

            if (newRoute === currentRoute) {
                if (selectedTab === 'details' || selectedTab === 'table') {
                    App.navigate(newRoute + '/refresh', {trigger: false});
                    App.navigate(newRoute, {trigger: true});
                }
            } else {
                App.navigate(newRoute, {trigger: true});
            }
        },

        onReloadCurves: function () {
            $('.js-global-loader').show();
            this.model.reloadCurves()
                .done(_.bind(function () {
                    console.info('%cpcrRun reload curves done!"', 'color: #00f8ff');
                }, this))
                .fail(_.bind(function () {
                    console.error('%cpcrRun reload curves failed!"', 'color: #00f8ff');
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        refreshBtnClick: function () { //recalc
            var valStatus = this.model.get('valStatus');
            if (valStatus.includes('2') || valStatus.includes('3') || valStatus.includes('4') || valStatus.includes('5')) {
                var txt = '';
                if (valStatus.includes('5')) {
                    txt = '<h2><b>' + _.i18n('warning.recalc.result') + '</b></h2><br/><br/>';
                }
                txt += _.i18n('recalc.result');
                Bootbox.confirm(txt, _.bind(function (result) {
                    if (result) {
                        this.recalcRun();
                    }
                }, this));
            } else {
                this.recalcRun();
            }
        },

        recalcRun: function () {
            $('.js-global-loader').show();
            this.model.refreshData()
                .done(_.bind(function () {
                    this.model.trigger('change', this.model);
                }, this))
                .fail(_.bind(function (error) {
                    console.error(error);
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onExportResultManual: function () {
            var defer = $.Deferred();
            $('.js-global-loader').show();
            var json = {test: true};
            this.model.exportResult(json).done(_.bind(function (response) {
                $('.js-global-loader').hide();

                var view = new ExportResultStatView({
                    stats: response,
                    callback: this.exportCallback
                });
                view.show({
                    title: _.i18n('run.export.title') + ' : ' + this.model.get('name'),
                    className: 'actionPopupCss exportResultPopup'
                });
                view.listenTo(view, 'export', _.bind(this.exportResultCallback, this));
                defer.resolve();
            }, this)).fail(function () {
                $('.js-global-loader').hide();
            });
            return defer;
        },
        onVal2Run: function () {
            $('.js-global-loader').show();
            this.model.validation2()
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },
        onReExportResult: function () {
            this.onExportResult(null, {reexport: true, automatic: true});
        },

        onExportResult: function (e, param) {
            $('.js-global-loader').show();
            if (!param) {
                param = {automatic: true};
            }
            this.model.exportResult(param).done(_.bind(function (response) {
                $('.js-global-loader').hide();
                var errors = [];
                if (response.success) {
                    _.each(response.exportResponses, function (exportResponse) {
                        if (exportResponse.response.success) {
                            if (!exportResponse.response.externalExport && exportResponse.response.fileName) {
                                var element = document.createElement('a');
                                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(exportResponse.response.resultArray));
                                element.setAttribute('download', exportResponse.response.fileName);
                                element.style.display = 'none';
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        } else {
                            errors.push(exportResponse.configuration.action + ' : ' + exportResponse.response.error);
                        }
                    });
                    this.onRunRefresh();
                } else {
                    errors.push(response.error);
                }

                if (errors.length === 0) {
                    var tracings = _.pluck(response.exportResponses, 'tracing');
                    tracings = _.map(tracings, function (tracing) {
                        return '[' + tracing.action + '] : ' + tracing.comment;
                    });
                    if (tracings.length === 0) {
                        Bootbox.alert({
                            message: _.i18n('pcrRun.export.result.none'),
                            backdrop: true
                        });
                    } else {
                        Bootbox.alert({
                            message: tracings.join('<br/>'),
                            backdrop: true,
                            className: 'success'
                        });
                    }
                } else {
                    Bootbox.alert({
                        message: errors.join('<br/>'),
                        backdrop: true
                    });
                }
            }, this))
                .fail(function () {
                    $('.js-global-loader').hide();
                });
        },

        exportResultCallback: function (params) {
            this.onExportResult(null, params);
        },

        onCheckBeforeImport: function () {
            var alreadyValidated = this.model.get('alreadyValidated');
            if (alreadyValidated) {
                Bootbox.confirm(_.i18n('warning.import.result'), _.bind(function (result) {
                    if (result) {
                        this.onImportXmlClick();
                    }
                }, this));
            } else {
                this.onImportXmlClick();
            }
        },

        onImportXmlClick: function () {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.importResultsView = new ImportResultsInPcrRunView({
                    model: new Backbone.Model(),
                    runModel: this.model,
                    settings: settings
                });
                this.importResultsView.show({
                    title: _.i18n('run.importXml'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
                this.importResultsView.listenTo(this.importResultsView, 'run:import-xml', _.bind(this.uploadXml, this));
                this.importResultsView.listenTo(this.importResultsView, 'run:select-file-to-import', _.bind(this.onSelectFileToImport, this));
            }, this));
        },

        onSelectFileToImport: function (model) {
            this.cyclerSelected = model && model.get('refCycler') && model.get('refCycler').secId ?
                model.get('refCycler').secId : null;
            this.respUserSelected = model && model.get('refRespUser') && model.get('refRespUser').secId ?
                model.get('refRespUser').secId : null;

            PcrRuns.filesToImport().done(_.bind(function (files) {
                this.runRemoteFolderView = new RunRemoteFolderView({
                    files: files,
                    runModel: this.model,
                    type: 'result'
                });
                this.runRemoteFolderView.listenTo(this.runRemoteFolderView, 'run:import-xml', _.bind(this.uploadXml, this));
                this.runRemoteFolderView.show({
                    title: _.i18n('run.import.remote.folder')
                });
            }, this));
        },

        uploadXml: function (fileInfo, model) {
            this.cyclerSelected = model && model.get('refCycler') && model.get('refCycler').secId ?
                model.get('refCycler').secId : null;
            this.respUserSelected = model && model.get('refRespUser') && model.get('refRespUser').secId ?
                model.get('refRespUser').secId : null;

            this.onImportXml(fileInfo, this.model);
        },

        onImportXml: function (files, model) {
            var runName = model.get('name');

            var exist = _.any(files, function (file) {
                return file.name.includes(runName, 0);
            });
            if (exist) {
                this.confirmImport(files, model);
            } else {
                var callback = _.bind(function (result) {
                    if (result) {
                        this.confirmImport(files, model);
                    }
                }, this);

                var confirmParams = {
                    message: _.i18n('run.controlRunName'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            }
        },

        confirmImport: function (files, model) {
            var params = {
                userpidSecId: this.respUserSelected ? this.respUserSelected : null,
                cyclerSecId: this.cyclerSelected ? this.cyclerSelected : null
            };
            RunTableTabController.confirmImport(files, params, model);
        },

        onRunRefresh: function () {
            $('.js-global-loader').show();
            this.model.fetch()
                .fail(_.bind(function (error) {
                    console.error(error);
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onEditRun: function () {
            this.createEditView = new CreateEditLayoutView({
                model: this.model,
                valStatusIcon: this.getValStatusIcon(this.model.get('status'), this.model.get('valStatus'), true),
                settings: this.model.get('settings')
            });
            this.createEditView.show({
                title: _.i18n('run.edit'),
                className: 'runEditAddPopup js-draggable-modal'
            });
            $('.js-draggable-modal  .modal-dialog').draggable({
                handle: '.modal-header'
            });

            this.createEditView.listenTo(this.createEditView, 'sheet:upload', _.bind(this.uploadSheet, this));
            this.createEditView.listenTo(this.createEditView, 'sheet:remove', _.bind(this.removeSheet, this));
            this.createEditView.listenTo(this.createEditView, 'hide:refresh', _.bind(this.onEditRunSaved, this));
        },

        onEditRunSaved: function (oldData) {
            if (this.model.get('name') !== oldData.name) {
                this.refreshHeaderRunName(oldData.name);
            }
            if (this.model.get('valStatus') !== oldData.valStatus || this.model.get('status') !== oldData.status) {
                this.refreshHeaderRunValStatus();
            }
        },

        setPopover: function (pop, pos) {
            pop.popover({
                trigger: 'hover',
                html: 'true',
                placement: pos,
                delay: {
                    show: '500',
                    hide: '100'
                },
                container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
            }).data('bs.popover').tip().addClass('jqgrid-popover').css('z-index', '9000');
        },

        refreshHeaderRunValStatus: function () {
            this.valStatusIcon = this.getValStatusIcon(this.model.get('status'), this.model.get('valStatus'));
            $('#runHeader-valStatusIcon').html(this.valStatusIcon);
        },

        refreshHeaderRunName: function (oldRunName) {
            if ($('.js-runName-div-container').length > 0) {
                $('.js-runName-div-container').text(this.model.get('name'));
            }
            if ($('.js-runName-selectOption-container').length > 0) {
                var selectOptions = $('.js-runName-selectOption-container').find('option');
                var item = _.find(selectOptions, _.bind(function (option) {
                    return option.selected;
                }, this));
                if (item) {
                    $(item).text(this.model.get('name'));
                }
                _.each(this.selectedRunsList, _.bind(function (run) {
                    if (run.secId === this.model.get('secId')) {
                        var str = run.name.replace(oldRunName, this.model.get('name'));
                        run.name = str;
                    }
                }, this));
                RunsShowController.onSelectedRunsList(this.selectedRunsList);
            }
        },

        uploadSheet: function (target) {
            $('.js-global-loader').show();
            this.model.sheetUpload(target).done(_.bind(function (response) {
                App.trigger('run:refresh:model', response, _.bind(function (model) {
                    this.model = model;
                    this.createEditView.model = model;
                    this.createEditView.render();
                }, this)).always($('.js-global-loader').hide());
            }, this)).always($('.js-global-loader').hide());
        },

        removeSheet: function () {
            $('.js-global-loader').show();
            this.model.sheetRemove().done(_.bind(function (response) {
                App.trigger('run:refresh:model', response, _.bind(function (model) {
                    this.model = model;
                    this.createEditView.model = model;
                    this.createEditView.render();
                }, this)).always($('.js-global-loader').hide());
            }, this)).always($('.js-global-loader').hide());
        },

        onEditWellsSmpId: function () {
            this.editPlateLayout('all', _.i18n('editPlateLayout.all'));
        },

        onPlateLayoutImportFromOtherRun: function () {
            this.editPlateLayout('importFromOtherRun', _.i18n('editPlateLayout.import.fromOtherRun'));
        },

        onPlateLayoutScan: function () {
            this.editPlateLayout('scan', _.i18n('prep.platelayout.scan'));
        },
        onGenerateSmpIds: function () {
            RunController.showGenerateSampleId(this.model, _.bind(function () {
                this.render();
            }, this));
        },
        onRemoveSmpIds: function () {
            this.editPlateLayout('remove-smpId', _.i18n('pcr.platelayout.remove.smpId'));
        },
        onPlateLayoutEdit: function () {
            this.editPlateLayout('edit', _.i18n('editPlateLayout.scan'));
        },

        onPlateLayoutControl: function () {
            this.editPlateLayout('control', _.i18n('pcr.platelayout.ctrl'));
        },

        onAssignAssay: function () {
            this.editPlateLayout('assign-assay', _.i18n('pcr.platelayout.assign.assay'));
        },
        onRemoveAssay: function () {
            this.editPlateLayout('remove-assay', _.i18n('pcr.platelayout.remove.assay'));
        },
        onReplaceAssay: function () {
            this.editPlateLayout('replace-assay', _.i18n('pcr.platelayout.replace.assay'));
        },

        onAddTest: function () {
            this.editPlateLayout('add-test', _.i18n('pcr.platelayout.add.test'));
        },
        onRemoveTest: function () {
            this.editPlateLayout('remove-test', _.i18n('pcr.platelayout.remove.test'));
        },
        onClearWells: function () {
            this.editPlateLayout('clear-wells', _.i18n('pcr.platelayout.clear.wells'));
        },

        onPlateLayoutImport: function () {
            var alreadyValidated = this.model.get('alreadyValidated');

            if (alreadyValidated) {
                Bootbox.alert(_.i18n('run.import.result.forbidden'));
            } else {
                this.plateLayoutImport();
            }
        },

        onSampleListImport: function () {
            var alreadyValidated = this.model.get('alreadyValidated');

            if (alreadyValidated) {
                Bootbox.alert(_.i18n('run.import.result.forbidden'));
            } else {
                this.sampleListImport();
            }
        },

        plateLayoutImport: function () {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.createEditView = new RunImportPlateLayoutView({
                    model: this.model,
                    settings: settings
                });
                this.createEditView.show({
                    title: _.i18n('run.pcr.new.fromFile.plateLayout'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
            }, this));
        },

        sampleListImport: function () {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.createEditView = new RunImportSampleListView({
                    model: this.model,
                    settings: settings
                });
                this.createEditView.show({
                    title: _.i18n('run.pcr.new.fromFile.sampleList'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
            }, this));
        },

        onPlateLayoutExport: function () {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.createEditView = new RunExportPlateLayoutView({
                    model: this.model,
                    settings: settings
                });
                this.createEditView.show({
                    title: _.i18n('run.export.plateLayout'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
            }, this));
        },

        onPlateLayoutCyclerExport: function () {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.createEditView = new RunExportPlateCyclerLayoutView({
                    model: this.model,
                    settings: settings
                });
                this.createEditView.show({
                    title: _.i18n('run.export.plateLayout.cycler'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
            }, this));
        },

        onSampleListExport: function () {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.createEditView = new RunExportSampleListView({
                    model: this.model,
                    settings: settings
                });
                this.createEditView.show({
                    title: _.i18n('run.export.sampleList'),
                    className: 'actionMediumPopupCss actionPopupCss popupFitContent'
                });
            }, this));
        },

        editPlateLayout: function (mode, title) {
            this.createEditSmpIdPlateView = new CreateEditSmpIdPlateLayoutView({
                model: this.model,
                type: 'pcrruns',
                valStatusIcon: this.valStatusIcon,
                mode: mode
            });
            var className = 'actionPopupCss popupFitContent';

            if (mode === 'all' || mode === 'scan' || mode === 'edit') {
                className = 'popupEnterPlateLayout actionPopupCss popupFitContent';
            }
            if (mode === 'import' || mode === 'export' || mode === 'control' ||
                mode === 'assign-assay' || mode === 'remove-assay' || mode === 'replace-assay' ||
                mode === 'generate-smpId' || mode === 'remove-smpId' || mode === 'importFromOtherRun' || mode === 'clear-wells'
            ) {
                className = 'actionPopupCss popupFitContent actionMiniPopupCss';
            }

            this.createEditSmpIdPlateView.show({
                title: title,
                className: className
            }, _.bind(this.render, this), _.bind(this.render, this));

            this.createEditSmpIdPlateView.listenTo(this.createEditSmpIdPlateView, 'on:hide', _.bind(this.render, this));
            this.createEditSmpIdPlateView.listenTo(this.createEditSmpIdPlateView, 'on:runTemplate:edit:Hide', _.bind(function () {
                var route = App.getCurrentRoute();
                App.navigate(route + '/refresh', {trigger: false});
                App.navigate(route, {trigger: true, replace: true});
            }, this));
        }
    });
});
