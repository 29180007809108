
define('template!dynamicCreateEditList', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 style="margin-top: 0;">' +
((__t = ( _.i18n(name + '.' + field + '.list') )) == null ? '' : __t) +
'</h2>\n<div class="rounded"\n\t style="padding: 10px 0 5px 0;">\n\t<div class="col-w-all col-w-100 d-b p-l-5 p-r-5 tableUnderlineTitle">\n\t\t<div class="col-w-all col-w-100 d-f">\n\t\t\t';
 if(config.editButton) { ;
__p += '\n\t\t\t<div class="col-w-all" style="width: 24px; line-height: 24px;"><span class="mdi mdi-plus-circle action clickable"></span>\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\t\t\t';
 _.each(fields, function(field) { ;
__p += '\n\t\t\t<div class="col-w-all ' +
((__t = ( field.param && field.param.display ? field.param.display.class : '')) == null ? '' : __t) +
'">\n\t\t\t\t' +
((__t = ( _.i18n(name + '.' + field.field) )) == null ? '' : __t) +
'\n\t\t\t</div>\n\t\t\t';
 }) ;
__p += '\n\t\t</div>\n\t</div>\n\t<div class="collection" style="' +
((__t = ( css )) == null ? '' : __t) +
'"></div>\n</div>';

}
return __p
};});

