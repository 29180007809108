/**
 * Created by RKL on 26/08/2015.
 */
define('caccountsController',[
    'module',
    'underscore',
    'jquery',
    'caccountsView',
    'caccountsEditView',
    'entities/global/caccounts'
], function (
    module,
    _,
    $,
    View,
    EditView,
    Caccounts
) {
    'use strict';

    module.exports = {
        show: function (region, secId) {
            $('.js-global-loader').show();
            var model = Caccounts.getModel({secId: secId});
            model.fetch().done(_.bind(function () {
                var fetchCountries = Caccounts.getCountries();
                var fetchLangs = Caccounts.getLanguages();
                var defers = [];
                defers.push(fetchCountries);
                defers.push(fetchLangs);
                $.when.apply($, defers).done(_.bind(function (countries, langs) {
                    var view = new View({
                        model: model,
                        countries: countries,
                        langs: langs
                    });
                    region.show(view);
                }, this))
                    .always(function () {
                        $('.js-global-loader').hide();
                    });
            }, this));
        },
        showDetails: function (model, callBackRefresh) {
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var fetchCountries = Caccounts.getCountries();
            var fetchLangs = Caccounts.getLanguages();
            var defers = [];
            defers.push(fetchCountries);
            defers.push(fetchLangs);
            $.when.apply($, defers).done(_.bind(function (countries, langs) {
                var createEditView = new EditView({
                    model: model,
                    countries: countries,
                    langs: langs
                });
                createEditView.show({
                    title: _.i18n(Caccounts.getName() + '.new'),
                    className: Caccounts.getName() + 'EditAddPopup'
                }, _.bind(function () {
                    if (callBackRefresh) {
                        callBackRefresh();
                    }
                }, callBackRefresh ? callBackRefresh.context : undefined));
            }, this));
        }
    };
});
