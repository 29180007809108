define('orderSampleCollection',[
	'module',
	'backbone',
	'marionette',
	'orderSampleView',
	'underscore',
	'jquery'
], function (
	module,
	Backbone,
	Marionette,
	view,
	_,
	$
) {
	'use strict';
	module.exports = Marionette.CollectionView.extend({
		childView: view,

		collection: new Backbone.Collection(),
		childEvents: {
			'item:delete': function (view) {
				$('.js-global-loader').show();
				view.model.getEntity().deleteEntity(view.model.get('secId')).always(_.bind(function () {
					this.model.fetch().always(function () {
						$('.js-global-loader').hide();
					});
				}, this));
			},
			'item:change': function () {
				this.trigger('childChange');
			}
		}
	});
});
