define('colorCompensationMatriceValueLineItem',[
    'module',
    'marionette',
    'underscore',
    'colorCompensationMatriceValueColumnCollection'
], function (
    module,
    Marionette,
    _,
    ColumnCollection
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        className: 'm-b-5 m-t-5 col-xs-12',
        attributes: function () {
            return {style: 'display: flex; flex-flow: column wrap; align-items: center; row-gap: 10px; column-gap: 10px; margin: auto;'};
        },

        template: _.template('<div class="js-line-region"></div>'),
        regions: {
            'lineRegion': '.js-line-region'
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            var columnCollection = new ColumnCollection({
                collection: this.model.get('collection'),
                manual: this.options.manual,
                result: this.options.result
            });
            this.getRegion('lineRegion').show(columnCollection);
        }
    });
});
