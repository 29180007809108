/**
 * Created by OLD on 25/11/2015.
 */
define('wellResultView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'jquery',
    'settings',
    'jqgridView',
    'template!wellResultTpl',
    'colorUtils',
    'dateUtils',
    'entities/caccounts/pcrwells'
], function (
    module,
    Marionette,
    _,
    App,
    $,
    Settings,
    JqGridView,
    viewTpl,
    ColorUtils,
    dateConverter
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'resultsListFilters',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqgRow: 'tr.jqgrow',
                sortWellRadio: '.sort-well-radio'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({
                'change @ui.sortWellRadio': 'onSortWellRadioChange',
                'click .print-results': 'print'
            }, this.gridEvents);
        },

        onShow: function () {
            $.material.init();
        },

        print: function () {
            var $print = $('#jq-grid-list')
                .clone()
                .addClass('print')
                .prependTo('#print-region');

            $('#print-region').find('.js-kitl-table-details-for-printing-container').remove();
            $('.js-kitl-table-details-for-printing-container').clone().prependTo('#print-region');
            $('.ui-jqgrid-htable>thead').clone().prependTo($print);
            $('html,body').css('overflow', 'visible');
            $('#main-region').hide();
            //window.print() stops JS execution
            window.print();

            //Remove div once printed
            $print.remove();
            $('html,body').css('overflow', 'hidden');
            $('#main-region').show();
        },

        serializeData: function () {
            var templateData = {
                name: this.model.get('name') ? this.model.get('name') : '-',
                kitProt: this.model.get('refKitProt') ? this.model.get('refKitProt').code : '-',
                lmbCycler: this.model.get('refLmbCycler') ? this.model.get('refLmbCycler').code : '-',
                plateIdPC: this.model.get('plateIdPC') ? this.model.get('plateIdPC') : '-',
                comment: this.model.get('comment') ? this.model.get('comment') : '-',
                creatUser: this.model.get('refCreatUser') ? (this.model.get('refCreatUser').email ? this.model.get('refCreatUser').email : '-') : '-'
            };
            templateData.sortOn = this.sortOn;
            templateData.creatDate = dateConverter.toDateFormatString(this.model.get('creatDate'));
            return templateData;
        },

        onSortWellRadioChange: function () {
            if (this.ui.sortWellRadio) {
                _.each(this.ui.sortWellRadio, _.bind(function (radio) {
                    if (radio.checked) {
                        switch (radio.value) {
                            case 'sortRow':
                                this.trigger('well-table:sort-row');
                                break;
                            case 'sortColumn':
                                this.trigger('well-table:sort-column');
                                break;
                        }
                    }
                }, this));
            }
        },

        pagination: function () {
            return {
                first: 0,
                rowCount: 0,
                pageSize: 9999,
                currentPage: 1
            };
        },

        gridOptions: function (data) {
            var defaultsOptions, options,
                _colModel = [];

            _colModel.push({
                label: '',
                name: 'secId',
                key: true,
                hidden: true
            });

            _colModel.push({
                label: _.i18n('well.pos'),
                name: 'pos',
                classes: 'well-list-pos-label',
                formatter: this.defaultFormatter,
                search: true,
                sortable: true,
                index: 'pos',
                searchoptions: {
                    sopt: ['cn']
                },
                fixed: true,
                width: 40
            });

            _colModel.push({
                label: _.i18n('well.smpId'),
                name: 'smpId.name',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: true,
                sortable: true,
                index: 'smpId.name',
                searchoptions: {
                    sopt: ['cn']
                },
                fixed: true,
                width: 180
            });

            _colModel.push({
                label: _.i18n('well.refAssay'),
                name: 'refAssay.code',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: true,
                sortable: true,
                index: 'refAssay.code',
                searchoptions: {
                    sopt: ['cn']
                },
                fixed: true,
                width: 120
            });

            _colModel.push({
                label: _.i18n('well.target'),
                name: 'target.mbAna.code',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: true,
                sortable: true,
                index: 'target.mbAna.code',
                searchoptions: {
                    sopt: ['cn']
                },
                fixed: true,
                width: 120
            });

            _colModel.push({
                label: _.i18n('result'),
                name: 'iconHtml',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: false,
                fixed: true,
                width: 50
            });

            _colModel.push({
                label: _.i18n('result'),
                name: 'result',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: true,
                sortable: true,
                fixed: true,
                width: 50
            });

            _colModel.push({
                label: _.i18n('wellresult.num'),
                name: 'cts',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: false,
                fixed: true,
                width: 50
            });

            _colModel.push({
                label: _.i18n('target.quantification'),
                name: 'quantifications',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: false,
                sortable: false,
                fixed: true,
                width: 60
            });

            _colModel.push({
                label: _.i18n('well.valSt'),
                name: 'target.valSt',
                classes: 'well-list-label',
                formatter: _.bind(this.validationFormater, this),
                search: false,
                sortable: true,
                index: 'target.valSt',
                fixed: true,
                width: 60
            });

            _colModel.push({
                label: _.i18n('well.resCodeErr'),
                name: 'target.codeErr',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: true,
                sortable: true,
                index: 'target.codeErr',
                fixed: true,
                width: 60
            });

            _colModel.push({
                label: _.i18n('well.resComment'),
                name: 'target.comment',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: true,
                sortable: true,
                index: 'target.comment',
                fixed: true,
                width: 200
            });

            defaultsOptions = this.gridInitOptions(data);
            options = {
                colModel: _colModel,
                sortname: this.sortOn ? this.sortOn : 'row', // 'smpId'
                loadonce: true,

                onSelectRow: _.bind(function (rowId, bool, e) {
                    if (e.type === 'click') {
                        var filteredRowList = this.ui.jqGrid.getRowData(null, false);
                        var cSelection = [];
                        _.each(filteredRowList, _.bind(function (wellRow) {
                            var pos = $(wellRow.pos).text();
                            if (!cSelection.includes(pos)) {
                                cSelection.push(pos);
                            }
                        }, this));
                        var firstUnderscoreIndex = rowId.indexOf('_');
                        var secondUnderscoreIndex = rowId.indexOf('_', firstUnderscoreIndex + 1);
                        var secId = rowId.substring(0, secondUnderscoreIndex);

                        var well = this.model.get('wells').find(function (obj) {
                            return obj.get('secId') === secId;
                        });

                        require(['pcrWellController'], _.bind(function (PcrWellController) {
                            PcrWellController.showDetails(well, cSelection, null);
                        }, this));
                    }
                }, this)
            };

            options = _.defaults(options, defaultsOptions);

            return options;
        },

        validationFormater: function (cellValue, call, object) {
            var valSt = object.target.valSt;
            if (!object.target.overAllResult && valSt !== null && (valSt <= 2 || valSt === 4 || valSt === 3)) {
                var exp = '';
                if (valSt === 2) {
                    exp = 'E';
                }
                var color = '';

                if (valSt <= 2) {
                    color = '#0000FF';
                } else if (valSt === 4) {
                    color = '#00FF00';
                } else if (valSt === 3) {
                    color = '#FF0000';
                }

                return '<div class="row m-b-0">' +
                    '<div class="col-xs-1">' +
                    '<div class="well-square-icon-green">' +
                    '<div class="well-circle-icon p-t-1" style="position:absolute; background-color: ' + color + '">' +
                    '<span style="color:#FFFFFF;font-size:10px;font-weight:400;margin-left:3px;margin-top: -2px;' +
                    'position:absolute;">' + exp + '</span>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
            } else {
                return '';
            }
        },

        resultIconFormatter: function (cellValue, call, object) {
            var start, end;
            var targetResultIcon = object && object.targetResultIcon ? object.targetResultIcon : null;

            if (cellValue && targetResultIcon) {

                start = '<div class="row m-b-0">';
                start += '<div class="col-xs-12 p-r-0" style="display: flex; width: 100%; height: 100%; align-content: center; flex-wrap: wrap;">';

                start += targetResultIcon.iconHtml.replaceAll('width: 100%;', '').replaceAll('height: 100%;', '').replaceAll('align-content: center;', '').replaceAll('flex-wrap: wrap;', '').replaceAll('display: flex;', '');
                start += '</div>';
                end = '</div>';
                return start + end;
            } else {
                start = '<div class="row m-b-0">';
                end = '</div>';
                return start + end;
            }
        },
        resultCodeFormatter: function (cellValue, call, object) {
            return object.targetResultIcon && object.targetResultIcon.resCode ? object.targetResultIcon.resCode : '';
        },

        resultCTFormatter: function (cellValue, call, object) {
            return object.targetResultIcon && object.targetResultIcon.cts ? object.targetResultIcon.cts : '';
        },

        resultQuantificationFormatter: function (cellValue, call, object) {
            return object.targetResultIcon && object.targetResultIcon.quantifications ? object.targetResultIcon.quantifications : '';
        },

        onCheckRowCount: function (rowCount) {
            if (rowCount === 0) {
                this.trigger('well:check-rowcount');
            }
        }
    });
});
