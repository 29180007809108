define('runTableTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'runTableTabView',
    'jquery',
    'bootbox',
    'customBootboxMessage',
    'emptyView',
    'samplesController',
    'entities/caccounts/setting',
    'entities/caccounts/pcrwells'
], function (
    module,
    App,
    _,
    Backbone,
    TableTabView,
    $,
    Bootbox,
    CustomBootboxMessage,
    EmptyView,
    SampleController,
    SettingEntity
) {
    'use strict';

    module.exports = {
        showTableTab: function (region, runModel, from, step, to, filters) {
            this.region = region;
            this.model = runModel;
            this.from = from;
            this.step = step;
            this.to = to;
            this.filters = filters;
            var isMultiDisplay = this.model.get('wells').length > 96;
            SettingEntity.getAllSettingFull().done(_.bind(function (settings) {
                require([
                    'runTabsLayoutController'
                ], _.bind(function (RunTabsLayoutController) {
                    this.view = new TableTabView({
                        model: this.model,
                        isMultiDisplay: isMultiDisplay,
                        from: from,
                        to: to,
                        step: step,
                        filters: filters,
                        filteredWells: RunTabsLayoutController.getFilteredWells(),
                        settings: settings
                    });
                    this.view.listenTo(this.view, 'well:showSample', _.bind(this.showSample, this));
                    this.view.listenTo(this.view, 'well:filteredDetailAssay', _.bind(this.filteredDetailAssay, this));
                    region.show(this.view);
                }, this));
            }, this));
        },

        showSample: function (sampleSecId) {
            SampleController.showDetailsBySampleSecId(sampleSecId);
        },

        confirmImport: function (files, params, model) {
            this.model = model;
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);
            this.model.updateWithXmlFile(files, params)
                .done(_.bind(function () {
                    this.emptyView.destroy();
                    var currentRoute = App.getCurrentRoute();
                    App.navigate(currentRoute + '/refresh', {trigger: false, replace: true});
                    App.navigate(currentRoute, {trigger: true});
                }, this))
                .fail(_.bind(function () {

                    this.model.fetch().done(_.bind(function () {
                        this.showTableTab(this.region, model, this.from, this.step, this.to);
                        var params = {
                            title: _.i18n('pcr.result.import.fail'),
                            message: _.i18n('pcr.result.import.fail.message'),
                            type: 'info'
                        };
                        CustomBootboxMessage.customAlert(params);
                    }, this));
                }, this));
        },

        _getUrl: function (tab) {
            var currentRoute = App.getCurrentRoute(),
                routeSplitted = currentRoute.split('/'), newRoute = [];

            newRoute.push(routeSplitted[0]);
            newRoute.push(routeSplitted[1]);
            newRoute.push(routeSplitted[2]);
            newRoute.push(routeSplitted[3]);
            newRoute.push(routeSplitted[4]);
            newRoute.push(routeSplitted[5]);
            if (tab) {
                newRoute.push(tab);
            }
            return newRoute.join('/');
        },

        filteredDetailAssay: function (assaySecId) {
            App.navigate(this._getUrl('details') + '/filters/{"assaySecId":"' + assaySecId + '"}');
        }
    };
});
