
define('template!routingGroupItemToolTip', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12">\n    <label class="col-xs-3 t-a-r">' +
((__t = ( _.i18n('rack.rackId') )) == null ? '' : __t) +
' :</label>\n    <div class="col-xs-7">' +
((__t = ( rackId )) == null ? '' : __t) +
'' +
((__t = ( preProcessing ? ' (PP)' : '')) == null ? '' : __t) +
'</div>\n</div>\n<div class="col-xs-12">\n    <label class="col-xs-3 t-a-r">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
' :</label>\n    <div class="col-xs-9">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n</div>\n<div class="col-xs-12">\n    <label class="col-xs-3 t-a-r">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
' :</label>\n    <div class="col-xs-9">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n</div>\n<div class="col-xs-12">\n    <label class="col-xs-3 t-a-r">' +
((__t = ( _.i18n('condition') )) == null ? '' : __t) +
' :</label>\n    <div class="col-xs-9">' +
((__t = ( condition )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

