define('editWellTargetView',[
    'module',
    'marionette',
    'template!editWellTargetView',
    'underscore',
    'jquery',
    'app',
    'autocompleteView',
    'colorUtils',
    'dialogFormView',
    'entities/caccounts/mbanares',
    'entities/caccounts/pcrwells'
], function (
    module,
    Marionette,
    WellTargetTpl,
    _,
    $,
    App,
    AutocompleteView,
    colorUtils,
    DialogFormView,
    MbAnaRes
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: WellTargetTpl,

        ui: {
            cts: '.js-ui-cts',
            quantifications: '.js-ui-quantifications',
            input: '.js-info-input'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'change @ui.cts': 'onChangeCts',
            'change @ui.quantifications': 'onChangeQuanfitications'
        },

        regions: {
            codeErrRegion: '.js-select-codeErr-region',
            mbAnaResRegion: '.js-mbAnaRes'
        },

        modelEvents: {
            'change:result': 'render',
            'change:cts': 'onChangeValue',
            'change:quantifications': 'onChangeValue'
        },

        initialize: function () {
            this.isOVAR = this.model.get('sequenceDisplayGroup');
            this.resultIcon = this.model.getResultIcon();
            this.canEdit = this.resultIcon.valSt < 4 || !!this.isOVAR;
        },

        serializeData: function () {
            var templateData = {
                mbAnaCode: this.model.get('mbAna').get('code'),
                resCode: this.model.get('result') ? this.model.get('result').get('code') : null,
                quantification: this.model.get('quantification'),
                comment: this.model.get('comment'),
                codeErr: this.model.get('codeErr')
            };
            templateData.allowToNeg = templateData.propC ? Object.keys(templateData.propC).some(function (k) {
                return ~k.indexOf('Amp');
            }) : false;
            templateData = _.extend(templateData, this.model.getResultIcon());
            templateData.propC = this.model.getPropCFormated();

            templateData.isOVAR = this.isOVAR;
            templateData.canEdit = this.canEdit;

            return templateData;
        },

        onRender: function () {
            if (this.canEdit && !this.isOVAR) {
                var codeErrView = new AutocompleteView(
                    this._getAutocompleteOptions('codeErr', 'code', 'code',
                        'public/codeLists/code/ERRTYPE/elements/?sord=asc&sidx=code',
                        'common.empty.placeholder', 'code',
                        this.onChangeCodeErr,
                        null, '',
                        App.request('well:pcr:get-errCode-fields-to-display')
                    )
                );
                this.getRegion('codeErrRegion').show(codeErrView);
            }
            if (this.canEdit) {
                this.resGroupView = new AutocompleteView(
                    this._getAutocompleteOptionObject(MbAnaRes.getAutocompleteParam({
                        modelProperty: 'result',
                        callBackOnChange: this.onChangeResult,
                        mbAnaResGrSecId: this.model.get('mbAna') && this.model.get('mbAna').get('refMbAnaResGr') ? this.model.get('mbAna').get('refMbAnaResGr').get('secId') : null,
                        canAddOrEdit: false
                    })));
                this.getRegion('mbAnaResRegion').show(this.resGroupView);
            }
        },

        onChangeCodeErr: function (fieldName, model) {
            if (model) {
                model = model.get('code');
            }
            this.onChange(fieldName, model);
            this.triggerMethod('enable:cancel:confirm');
        },

        onChangeResult: function (fieldName, model) {
            if (model) {
                if (model.get('code') === 'N') {
                    this.model.set({cts: [], quantifications: []}, {silent: true});
                }
                var errorParameter = MbAnaRes.getErrorParameter(model);
                model.codeErr = errorParameter.code;
                model.commentErrAuto = errorParameter.message;
                model.justificatory = errorParameter.message;
            }
            this.onChange(fieldName, model);
            this.render();
        },

        onChangeValue: function () {
            if ((this.model.get('cts') || this.model.get('quantifications')) && this.model.get('result').get('code') === 'N') {
                MbAnaRes.findByCode('P', this.model.get('result').get('refMbAnaResGr').get('secId')).done(_.bind(function (result) {
                    this.onChange('result', result);
                }, this));
            }
        },

        onChangeCts: function (e) {
            var $target = $(e.currentTarget);
            var cts = $target.val().split(';');
            this.model.set('cts', cts);
        },

        onChangeQuanfitications: function (e) {
            var $target = $(e.currentTarget);
            var quantifications = $target.val().split(';');
            this.model.set('quantifications', quantifications);
        }
    });
});
