
define('template!pcrWellResultSPOOvarItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="noWrapHidden result-wellres"></div>\n<div class="noWrapHidden result-channel">\n    <label data-html="true" data-toggle="tooltip"></label>\n</div>\n\n<div class="noWrapHidden result-target">\n    <label data-html="true" data-toggle="tooltip" title="' +
((__t = ( propC )) == null ? '' : __t) +
'">' +
((__t = ( mbAna )) == null ? '' : __t) +
'</label>\n</div>\n\n<div class="noWrapHidden result-edit">\n</div>\n\n<div class="noWrapHidden result-result"\n     style="position: relative; display: flex;">\n    ' +
((__t = ( iconHtml )) == null ? '' : __t) +
'\n    ' +
((__t = ( resCode )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-num">\n    ' +
((__t = ( cts )) == null ? '' : __t) +
' ' +
((__t = ( _.isEmpty(quantifications) ? '' : '(' + quantifications + ')' )) == null ? '' : __t) +
'\n</div>\n<div class="noWrapHidden result-action"></div>\n<div class="noWrapHidden result-custom"></div>\n\n<div class="noWrapHidden result-comment" title="' +
((__t = ( comment )) == null ? '' : __t) +
'">\n    ' +
((__t = ( comment )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-error" title="' +
((__t = ( errorMessage )) == null ? '' : __t) +
'">\n    ' +
((__t = ( errorMessage )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-val1">\n    <label title="' +
((__t = ( userVal1Title )) == null ? '' : __t) +
'">\n        ' +
((__t = ( userVal1Code )) == null ? '' : __t) +
'\n    </label>\n</div>\n\n<div class="noWrapHidden result-val2">\n    <label title="' +
((__t = ( userVal2Title )) == null ? '' : __t) +
'">\n        ' +
((__t = ( userVal2Code )) == null ? '' : __t) +
'\n    </label>\n</div>';

}
return __p
};});

