define('prepWellView',[
    'module',
    'app',
    'marionette',
    'backbone',
    'wellUtils',
    'template!prepRunWellTpl',
    'template!prepRunWellMultiDisplayTpl',
    'wellResultIconView',
    'underscore',
    'jquery',
    'colorUtils',
    'fieldUtils',
    'prepRunWellDetailView',
    'prepRunDisplayKitsLotsView',
    'qcChartPopup',
    'wellDisplaySampleView',
    'entities/caccounts/prepwells'
], function (
    module,
    App,
    Marionette,
    Backbone,
    WellUtils,
    wellTpl,
    wellMultiDisplayTpl,
    WellResultIconView,
    _,
    $,
    ColorUtils,
    FieldUtils,
    WellDetailView,
    PrepRunDisplayKitsLotsView,
    QcView,
    WellDisplaySampleView
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        ui: {
            elementRight: '.elementRight',
            selectableItem: '.wellSelectableItem',
            poolLabel: '.pool-label',
            pooledLabel: '.pooled-label'
        },

        regions: {
            iconResult1: '.icon-result1',
            iconResult2: '.icon-result2',
            iconResult3: '.icon-result3',
            iconResult4: '.icon-result4',
            iconResult5: '.icon-result5',
            iconResult6: '.icon-result6',
            wellDisplaySample: '.js-well-sample'
        },

        events: {
            'click': 'onItemClick',
            'click .js-btn-kitLot': 'onKitLotClick',
            'click .smp-id-link': 'onSampleIdClick',
            'click .pool-label': 'onClickPool',
            'click .js-btn-comment': 'onClickComment',
            'click .js-btn-sample': 'onClickSample',
            'click .repeatStatus': 'onClickSample',
            'click .mdi-alert-octagram': 'onClickError'
        },

        modelEvents: {
            'change': 'render'
        },

        onRender: function () {
            var params = {
                context: this
            };
            params.target = this.options.region;

            WellUtils.showPrepWellPopover(params);

            var shortted = !this.options.editMode && this.model.get('smpId').get('name') && this.model.get('smpType') !== 'P';

            var view = new WellDisplaySampleView({
                model: this.model,
                shortted: shortted,
                plateView: true
            });
            this.getRegion('wellDisplaySample').show(view);
        },

        initialize: function (options) {
            this.model = options.model;
            this.listenTo(this.model.get('smpId'), 'change', this.renderSafe);
        },

        getTemplate: function () {
            if (this.options.isMultiDisplay) {
                return wellMultiDisplayTpl;
            } else {
                return wellTpl;
            }
        },

        serializeData: function () {
            var templateData = {
                well: {
                    smpType: this.model.get('smpType'),
                    repeat: this.model.get('repeat'),
                    pooled: this.model.get('pooled')
                }
            };
            templateData.otherWell = null;
            if (_.any(this.model.get('otherPrepWells'), function (well) {
                return well.repeatStatus;
            }) || this.model.get('pcrWells').any(function (well) {
                return well.get('repeatStatus');
            })) {
                templateData.otherWell = 'repeat';
                templateData.repeatStatus = null;
                var model = _.findWhere(this.model.get('otherPrepWells'), {repeatStatus: 'XT'});
                if (!model) {
                    model = this.model.get('pcrWells').findWhere({repeatStatus: 'XT'});
                }
                templateData.repeatStatus = model ? (model.repeatStatus ? model.repeatStatus : model.get('repeatStatus')) : null;
                if (templateData.repeatStatus === null) {
                    model = _.findWhere(this.model.get('otherPrepWells'), {repeatStatus: 'XC'});
                    if (!model) {
                        model = this.model.get('pcrWells').findWhere({repeatStatus: 'XC'});
                    }
                    templateData.repeatStatus = model ? (model.repeatStatus ? model.repeatStatus : model.get('repeatStatus')) : null;
                }
                if (templateData.repeatStatus === null) {
                    model = _.findWhere(this.model.get('otherPrepWells'), {repeatStatus: 'DP'});
                    if (!model) {
                        model = this.model.get('pcrWells').findWhere({repeatStatus: 'DP'});
                    }
                    templateData.repeatStatus = model ? (model.repeatStatus ? model.repeatStatus : model.get('repeatStatus')) : null;
                }
            }
            if (templateData.otherWell === null && _.any(this.model.get('otherPrepWells'), function (well) {
                return !well.repeatStatus;
            })) {
                templateData.otherWell = 'other';
            }
            if (!this.options.editMode && this.model.get('smpId').get('name') && this.model.get('smpType') !== 'P') {
                templateData.wellSmpIdToDisplay = this.model.get('smpId').get('shortId');
            } else {
                templateData.wellSmpIdToDisplay = this.model.get('smpId').get('name');
            }

            templateData.colorRepeats = '#ffcc00';
            if (this.model.get('refPrepRun').get('settings') && this.model.get('refPrepRun').get('settings')['OTHER-ASSAY-IND']) {
                var otherAssayInd = this.model.get('refPrepRun').get('settings')['OTHER-ASSAY-IND'].split(';');
                if(otherAssayInd[2]) {
                    templateData.colorRepeats = otherAssayInd[2];
                }
            }

            templateData.firstOfAssay = this.options.firstOfAssay;

            templateData.runStatus = this.model.get('refPrepRun').get('status');


            templateData.pooled = !this.model.get('wellsPool').isEmpty();
            templateData.pool = !this.model.get('wellsPooled').isEmpty();

            templateData.refPcrKits = [];
            templateData.pcrKitsLots = [];
            _.each(this.model.get('kitLotByKits'), _.bind(function (item) {
                if (item.refPcrKit && item.refPcrKit.code)
                    templateData.refPcrKits.push(item.refPcrKit.code);
                _.each(item.pcrKitLots, _.bind(function (pcrKitLot) {
                    templateData.pcrKitsLots.push(pcrKitLot.code);
                }, this));
            }, this));

            templateData.refPcrKits = templateData.refPcrKits.join(';');
            templateData.pcrKitsLots = templateData.pcrKitsLots.join(';');

            templateData.assaysToDisplay = WellUtils.getFormattedAssayStatus(this.model, 'well');

            templateData.backgroundColor = this.options.backgroundColor;
            templateData.validatedProgess = this.model.get('smpId').get('verified') === 'IN_PROGRESS';
            templateData.error = this.model.get('entityError') || this.model.get('smpId').get('verified') === 'NOT_VALIDATED' ||
                this.model.get('smpId').get('entityError') ||
                (_.isEmpty(this.model.get('assayStatus')) && !_.isEmpty(this.model.get('smpId').get('code')));
            return templateData;
        },

        onClickPool: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.$el.trigger('mouseleave');
            console.info('%c findDissolved does not exists in prepWell', 'color: #00f8ff');
        },

        onClickSample: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['samplesController'], _.bind(function (Controller) {
                this.model.get('smpId').fetch().done(_.bind(function () {
                    Controller.showDetails(this.model.get('smpId'));
                }, this)).always(_.bind(function () {
                    $('.empty-page-loader').hide();
                    this.$el.trigger('mouseleave');
                }, this));
            }, this));
        },

        onClickError: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['errorController'], _.bind(function (ErrorController) {
                ErrorController.showE(this.model, this.model.get('entityError'));
            }, this));
        },

        onClickComment: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['sampleCommentView'], _.bind(function (EditSampleCommentView) {
                this.model.get('smpId').fetch().done(_.bind(function () {
                    this.editSampleCommentView = new EditSampleCommentView({
                        wellModel: this.model,
                        model: this.model.get('smpId')
                    });
                    this.editSampleCommentView.show({
                        title: _.i18n('edit.sampleComment'),
                        className: ''
                    });
                }, this));
            }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        },

        onSampleIdClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.empty-page-loader').show();
            if (this.model.get('smpType') !== 'PC' &&
                this.model.get('smpType') !== 'NC' &&
                this.model.get('smpType') !== 'RC' &&
                this.model.get('smpType') !== 'OC') {

                require(['samplesController'], _.bind(function (Controller) {
                    this.model.get('smpId').fetch().done(_.bind(function () {
                        Controller.showDetails(this.model.get('smpId'));
                    }, this)).always(_.bind(function () {
                        $('.empty-page-loader').hide();
                        this.$el.trigger('mouseleave');
                    }, this));
                }, this));

            } else {
                if (!this.model.get('smpType') || !this.model.get('refAssay') || !this.model.get('refAssay').get('secId')) {
                    return;
                }
                this.model.getControlKitLot().done(_.bind(function (controlKits) {
                    this.qcChartPopupLayoutView = new QcView({
                        wellModel: this.model,
                        qcTargetModel: null,
                        collection: new Backbone.Collection(controlKits),
                        settings: this.model.get('settings')
                    });
                    this.qcChartPopupLayoutView.show({
                        title: _.i18n('kitlot:qc:chart:popup'),
                        className: 'runQcChartViewPopup'
                    });
                }, this))
                    .always(_.bind(function () {
                        $('.empty-page-loader').hide();
                        this.$el.trigger('mouseleave');
                    }, this));
            }
        },

        onKitLotClick: function (e) {
            e.preventDefault();
            e.stopPropagation();

            this.model.get('refPrepRun').getKitLots().done(_.bind(function (prepRunKitLot) {
                var kitLotView = new PrepRunDisplayKitsLotsView({
                    model: this.model,
                    extractionKitLots: prepRunKitLot.extractionKitLots
                });

                kitLotView.show({
                    title: _.i18n('prep.run') + '&nbsp;&nbsp;&nbsp;' + this.model.get('runName') + '&nbsp;&nbsp;&nbsp;' + _.i18n('prep.run.well.kitlot'),
                    className: 'editPrepRunWellComment'
                });
            }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        },

        onItemClick: function () {
            $('.js-global-loader').show();
            require([
                'prepRunTabsLayoutController'
            ], _.bind(function (PrepRunTabsLayoutController) {

                var cSelection = PrepRunTabsLayoutController.getFilteredWellsCollection();
                var wellDetailView = new WellDetailView({
                    model: this.model,
                    cSelection: cSelection,
                    extractionMethodModel: this.model.get('refPrepRun').get('extractionDetails').refExtractionMethod
                });

                wellDetailView.show({
                    title: this.model.get('runName'), //todo check value
                    className: 'popupWellView' //popupPrepWellView
                });
                $('.js-global-loader').hide();
            }, this));
        }
    });
});
