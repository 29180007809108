define('dynamicCreateEditCustomListToList',[
    'module',
    'formView',
    'underscore',
    'jquery',
    'backbone',
    'dynamicListToList'
], function (
    module,
    FormView,
    _,
    $,
    Backbone,
    DynamicListToList
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template('<%= values %>'),

        events: {
            'click': 'onShowListToList'
        },
        tagName: 'textarea',
        className: 'listToList form-control inputBackground clickable',
        attributes: function () {
            return {
                'data-field-name': this.options.field,
                'placeholder': this.options.display && this.options.display.placeHolder ? this.options.display.placeHolder : _.i18n('common.empty.placeholder'),
                'style': 'resize: vertical;' + (this.options.display && this.options.display.style ? this.options.display.style : ''),
                'rows': this.options.display && this.options.display.rows ? this.options.display.rows : 1,
                'disabled': this.options.display && this.options.display.disabled ? this.options.display.disabled : false,
                readonly: true
            };
        },

        serializeData: function () {
            var templateData = {};
            if (this.model.get(this.options.field) instanceof Backbone.Collection) {
                templateData.values = this.model.get(this.options.field).map(function (model) {
                    return model.get('code');
                }).join(', ');
            } else {
                templateData.values = this.model.get(this.options.field) ? this.model.get(this.options.field) : '';
            }
            return templateData;
        },

        onRender: function () {
            this._loadItems(this.options.field).done(_.bind(function (response) {
                var titles = _.map(this.model.get(this.options.field), function (tag) {
                    var item = _.findWhere(response.items, {code: tag});
                    if (item) {
                        if (item.name) {
                            return '[' + item.code + '] ' + item.name;
                        }
                        return item.code;
                    }
                    return tag;
                });
                this.$el.attr('title', titles.join(', '));
            }, this));
        },

        onShowListToList: function (e) {
            var fieldName = e.currentTarget.attributes['data-field-name'].value;
            this._loadItems(fieldName).done(_.bind(function (response) {
                this.itemsLoaded = response.items;
                var name = this.model.getEntity ? this.model.getEntity().getName() : 'TEST';
                var view = new DynamicListToList({
                    'field': fieldName,
                    'values': this.model.get(fieldName),
                    'items': response.items,
                    'entityName': name,
                    'codeListCode': response.codeListCode,
                    'separator': this.options.separator
                });
                this.listenTo(view, 'listToList:confirm', _.bind(this.listToListConfirm, this, fieldName));
                view.show({
                    title: _.i18n(name + '.' + fieldName),
                    className: 'baseTableEditAddPopup'
                });
            }, this));
        },
        _loadItems: function (fieldName) {
            var defer = $.Deferred();
            if (this.options.items) {
                defer.resolve(this.options.items);
            } else {
                this.model.getEntity().getList(fieldName, this.model).done(_.bind(function (items) {
                    defer.resolve(items);
                }, this));
            }
            return defer.promise();
        },
        listToListConfirm: function (fieldName, itemsSelected) {
            if (this.options.changeCallBack) {
                this.options.changeCallBack(fieldName, itemsSelected);
            } else {
                if (this.model.get(fieldName) instanceof Backbone.Collection) {
                    var models = _.filter(this.itemsLoaded, function (item) {
                        if (item instanceof Backbone.Model) {
                            return _.any(itemsSelected, function (itemSelected) {
                                return itemSelected === item.get('secId');
                            });
                        } else {
                            return _.any(itemsSelected, function (itemSelected) {
                                if (item.secId) {
                                    return itemSelected === item.secId;
                                }
                                if (item.code) {
                                    return itemSelected === item.code;
                                }
                                return itemSelected === item;
                            });
                        }
                    });
                    this.model.get(fieldName).reset();
                    this.model.get(fieldName).push(models);
                    this.model.trigger('change:' + fieldName);
                } else {
                    this.onChange(fieldName, itemsSelected);
                }
            }
            if (!this.isDestroyed) {
                this.render();
            }
        }
    });
});
