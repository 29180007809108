
define('template!wellResultTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well jq-grid-table-page" style="position: relative">\n    <span class="mdi mdi-printer clickable hidden-print print-results" style="position: absolute; right: 10px; z-index: 1"></span>\n    <div class="js-kitl-table-details-for-printing-container">\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( name)) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('runList.runModDate') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( creatDate ? creatDate : '-' )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('runList.refRespUser') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( creatUser )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('run.refKitProt') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( kitProt )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('run.refLmbCycler') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( lmbCycler )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('run.plateIdPC') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( plateIdPC )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="col-md-6">\n                <div class="col-xs-4">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</div>\n                <div class="col-xs-8">' +
((__t = ( comment )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n    </div>\n    <div class="kitl-table">\n        <table class="resultsTable" id="jq-grid-list" role="grid"></table>\n    </div>\n</div>';

}
return __p
};});

