define('entities/caccounts/assayreagent',[
    'app',
    'backbone',
    'module',
    'backboneRelational',
    'entities/caccounts/pcrreagents',
    'entities/caccounts/kitspcr'
], function (
    App,
    Backbone,
    module
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.AssayReagent = Backbone.RelationalModel.extend({
        defaults: {
            'reagent': null,
            'volumeByTest': 0.0,
            'volumeDeath': 0.0,
            'refAssay': null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'reagent',
            relatedModel: 'PcrReagent'
        }, {
            type: Backbone.HasOne,
            key: 'refAssay',
            relatedModel: 'Assay',
            includeInJSON: ['secId', 'code', 'name']
        }],

        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.AssayReagentCollection = Backbone.Collection.extend({
        model: app.ns.AssayReagent
    });

    module.exports = {
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.AssayReagent.findOrCreate(modelJson);
        },
        getName: function () {
            return 'assayreagent';
        },
        getDynamicJson: function () {
            return 'AssayReagentJson';
        }
    };
});
