define('wellsPopupGraphView',[
    'module',
    'underscore',
    'mobioChartView'
], function (
    module,
    _,
    MobioChartView
) {
    'use strict';

    module.exports = MobioChartView.extend({

        channelToDisplay: null,

        initialize: function (options) {
            this.displayLegend = options.displayLegend === undefined ? true : options.displayLegend;
        },

        serializeData: function () {
            this.data = {
                datasets: []
            };

            var c;
            var dataAlgosCurves = [];

            this.models.each(_.bind(function (result) {
                var curve = result.get('curves').first();

                if (curve && curve.get('lines') && ((result.get('refPcrWell').get('smpId').get('name')) || result.get('refPcrWell').get('smpType') === 'P')) {
                    dataAlgosCurves.push({
                        curve: curve.get('lines'),
                        color: result.get('result').get('color'),
                        resCode: result.get('result').get('code'),
                        errCode: result.get('codeErr'),
                        comment: result.get('comment'),
                        target: result,
                        highlight: this.highlight ? result.get('refPcrWell').get('secId') === this.highlight.get('secId') : false
                    });
                }
            }, this));

            dataAlgosCurves = _.sortBy(dataAlgosCurves, function (data) {
                return !data.highlight;
            });

            _.each(dataAlgosCurves, _.bind(function (algoCurve) {
                if (!_.isUndefined(algoCurve.curve) && !_.isEmpty(algoCurve.curve)) {
                    c = this.getColorFromResult(
                        algoCurve.target.get('refPcrWell'),
                        algoCurve.target.get('refPcrWell').get('refAssay'),
                        algoCurve.target.get('refPcrWell').get('smpType'),
                        algoCurve.channel,
                        algoCurve.resCode,
                        algoCurve.errCode,
                        !this.fixedColor,
                        algoCurve.highlight,
                        algoCurve.color
                    );

                    var legendContent = algoCurve.target.get('refPcrWell').get('pos');
                    if (algoCurve.target) {
                        if (algoCurve.target.get('cts')) {
                            legendContent += ' (' + algoCurve.target.get('cts') + ')';
                        }
                    }

                    var result = this.addData(
                        algoCurve.curve,
                        c.r,
                        c.g,
                        c.b,
                        legendContent,
                        c.line,
                        c.dashed,
                        algoCurve.comment,
                        algoCurve.target.get('refPcrWell'),
                        algoCurve.channel,
                        null,
                        this.options.rangeSmooth
                    );
                    var exponentialData = _.filter(result.data, function (data) {
                        return data.exponential;
                    });
                    result.data = _.reject(result.data, function (data) {
                        return data.exponential;
                    });
                    this.data.datasets.push(result);
                    if (exponentialData.length > 0 && !this.options.hideExtraPoint) {
                        var result2 = JSON.parse(JSON.stringify(result));
                        result2.data = exponentialData;
                        result2.color = 'rgba(0, 0, 0, 0.5)';
                        result2.borderColor = 'rgba(0, 0, 0, 0.5)';
                        result2.dashed = {
                            borderDashOffset: 3,
                            borderDash: [2, 4]
                        };
                        this.data.datasets.push(result2);
                    }
                }

            }, this));

            if (this.data.datasets.length) {
                if (this.baseline !== null && this.baseline !== undefined) {
                    this.data.datasets.push(this.addLine(this.baseline, 'baseline', 255, 0, 0));
                }
                if (this.threshold !== null && this.threshold !== undefined) {
                    this.data.datasets.push(this.addLine(this.threshold, 'threshold', 0, 0, 255));
                }
            }

            this.generateLegend();
            return this.data;
        }
    });
});
