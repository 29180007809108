define('prepRunTracsView',[
    'module',
    'app',
    'underscore',
    'dialogFormView',
    'template!prepRunTracsTpl',
    'prepTracsView'
], function (
    module,
    App,
    _,
    DialogFormView,
    PrepRunTracsTpl,
    TracsView
) {
    'use strict';

    var ShowPcrRunKitsLotsView = DialogFormView.extend({
        template: PrepRunTracsTpl,

        regions: {
            prepRunTracsRegion: '.js-prepRun-tracs-region'
        },

        onShow: function () {
            $.material.init();
            this.view = new TracsView({
                model: this.options.model
            });
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.getRegion('prepRunTracsRegion').show(this.view);
        },

        onGridLoaded: function () {
            var data;
            var url = App.request('trac:prep:get-collection-url', this.model.get('secId'));
            var rowCountUrl = App.request('trac:prep:get-count-url', this.model.get('secId'));
            if (this.options.type === 'wellTracks') {
                url = App.request('trac:prep:get-collection-url', this.model.get('runSecId'), this.model.get('pos'));
                rowCountUrl = App.request('trac:prep:get-count-url', this.model.get('runSecId'), this.model.get('pos'));
            }
            data = {
                url: url,
                rowCountUrl: rowCountUrl
            };
            this.view.displayGrid(data);
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            return templateData;
        }
    });

    module.exports = ShowPcrRunKitsLotsView;
});
