define('runCreatePcrRunFromFileImportResultView',[
	'module',
	'marionette',
	'backbone',
	'dialogFormView',
	'template!runCreatePcrRunFromFileImportResultTpl',
	'savingBehavior',
	'bootbox',
	'underscore',
	'jquery',
	'app',
	'settings',
	'autocompleteView',
	'runRemoteFolderView',
	'entities/caccounts/cyclerpublics',
	'entities/caccounts/userpids',
	'entities/caccounts/setting',
	'fileExplorerController',
	'entities/caccounts/pcrruns'
], function (
	module,
	Marionette,
	Backbone,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	bootbox,
	_,
	$,
	App,
	Settings,
	AutocompleteView,
	RunRemoteFolderView,
	CyclerPublics,
	Userpids,
	SettingEntity,
	FileExplorerController,
    PcrRuns
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			importFileFromCentralLib: '.importFileFromCentralLib',
			importFileFromComputer: '.importFileFromComputer',
			selectFileFromComputer: '.js-select-file-from-computer',
			blockLoading: '.empty-page-loader'
		},

		events: {
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-cancel-popup': 'onCancel',
			'click @ui.importFileFromCentralLib': 'onSelectFileFromCentralLib',
			'change @ui.selectFileFromComputer': 'onSelectFileFromComputer',
			'click .js-blank': 'onBlankClick'
		},

		regions: {
			selectRespUser: '.js-select-respUser-region',
			selectCycler: '.js-select-cycler-region'
		},

		fieldsToValidate: [
			{name: 'refCycler', type: 'required'}
		],

		initialize: function () {
			this.model.set('refCycler', null);
			this.model.set('accept', '.xlsx,.xls,.xml,.rdml,.csv');
			this.model.set('refRespUser', Settings.get('currentUserModel').toJSON());
			this.respUserSecId = Settings.get('currentUserModel').get('secId');
		},

		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.remoteAvailable = this.options.settings['IMPORT-RESULT'] && this.options.settings['IMPORT-RESULT'].split(';')[0];
			return templateData;
		},

		onShow: function () {
			$.material.init();
			this.showselectCyclerView();
			this.showRefRespUserDropDown();
		},

		showRefRespUserDropDown: function () {
			if (this.respUserView) {
				this.respUserView.destroy();
			}

			this.respUserView = new AutocompleteView(
				this._getAutocompleteOptionObject(Userpids.getAutocompleteParam({
					modelProperty: 'refRespUser',
					valueProperty: 'nickName',
					queryProperty: 'nickName',
					valueKey: 'nickName',
					callBackOnChange: this.onRespUserChange
				}))
			);

			this.getRegion('selectRespUser').show(this.respUserView);
		},

		showselectCyclerView: function () {
			if (this.selectCyclerView) {
				this.selectCyclerView.destroy();
			}

			CyclerPublics.findDefault().done(_.bind(function (data) {
				this.model.set('refCycler', data);
				if (data) {
					this.$el.find('label#importResultFromLocal').attr('for', 'run-xml-input');
				} else {
					this.$el.find('label#importResultFromLocal').attr('for', 'blank');
				}
				this.displaySelectCycler();
			}, this));
		},
		displaySelectCycler: function () {
			this.selectCyclerView = new AutocompleteView(
				this._getAutocompleteOptionObject(CyclerPublics.getAutocompleteParam({
					modelProperty: 'refCycler',
					callBackOnChange: this.onCyclerChange
				}))
			);
			this.getRegion('selectCycler').show(this.selectCyclerView);
		},

		onRespUserChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
			}
			this.respUserSecId = model ? model.get('secId') : null;
		},

		onCyclerChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.$el.find('label#importResultFromLocal').attr('for', 'run-xml-input');
			} else {
				this.$el.find('label#importResultFromLocal').attr('for', 'blank');
			}
			this.onChange(fieldName, model ? model.toJSON() : null);
		},

		onBlankClick: function () {
			this.validate(this.fieldsToValidate);
		},

		onSelectFileFromCentralLib: function () {
			if (this.validate(this.fieldsToValidate)) {
				SettingEntity.findByCode('IMPORT-RESULT').done(_.bind(function (settings) {
					FileExplorerController.show(settings.secId, true, _.bind(this.fileUpload, this));
				}, this));
			}
		},

		onSelectFileFromComputer: function (e) {
			if (e && e.target && e.target.files) {
				this.fileUpload(e.target.files);
			}
		},

		fileUpload: function (files) {
			this.$el.find(this.ui.blockLoading).show();
			if (files && this.validate(this.fieldsToValidate)) {
				this.ui.blockLoading.show();
				var cyclerSecId = this.model.get('refCycler').secId;
				var respUserSecId = this.respUserSecId;
				PcrRuns.createRunFromResult(files, cyclerSecId, respUserSecId)
					.done(_.bind(function (result) {
						this.ui.blockLoading.hide();
						if (result && result.length === 1) {
							App.navigate('runs/pcr/' + result[0].secId + '/A01/1/' + result[0].plateSize, {trigger: true});
						} else {
							App.navigate('runs/pcr', {trigger: true});
							this.triggerMethod('run:listView:refresh');
						}
						this.hide();
					}, this));
			}
		},

		onCancel: function () {
			this.box.modal('hide');
		},

		hide: function () {
			this.triggerMethod('hide');
			this.destroy();
			this.box.modal('hide');
		}
	});
});
