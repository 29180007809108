
define('template!wellDisplayHL7TableView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="jq-grid-table-page">\n    <table id="jq-grid-list" role="grid"></table>\n</div>';

}
return __p
};});

