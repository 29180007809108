
define('template!wellPooledTooltipTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="" style="">\n    ';
 wellsPooled.sort((a,b) => (a.runName > b.runName) ? 1 : ((b.runName > a.runName) ? -1 : 0));
__p += '\n    ';
 _.each(wellsPool, function(well) { ;
__p += '\n    <div class="row m-b-0 col-xs-12">\n        <label class="col-xs-5 noWrapHidden">' +
((__t = ( well.runName )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-1 noWrapHidden">' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-4 noWrapHidden">' +
((__t = ( well.smpId.name )) == null ? '' : __t) +
'</label>\n    </div>\n    ';
 }); ;
__p += '\n</div>';

}
return __p
};});

