define('standardCurveController',[
    'module',
    'underscore',
    'backbone',
    'standardCurve',
    'standardCurveAssayConfigurationResultView',
    'bootbox'
], function (
    module,
    _,
    Backbone,
    View,
    ViewDetails,
    Bootbox
) {
    'use strict';

    module.exports = {
        showDetails: function (model, callBackRefresh, options) {
            if (!(model instanceof Backbone.Model) && typeof model === 'object') {
                var param = model;
                model = param.model;
                callBackRefresh = param.callBackRefresh;
                options = param.options;
            }
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            options = _.defaults({model: model}, options);
            var createEditView = new View(options);
            createEditView.show({
                title: _.i18n(model.getEntity().getName() + '.new'),
                className: 'baseTableEditAddPopup ' + model.getEntity().getName() + 'EditAddPopup'
            }, _.bind(function () {
                if (callBackRefresh) {
                    callBackRefresh();
                }
            }, callBackRefresh ? callBackRefresh.context : undefined));
        },
        showResults: function (model, callBackRefresh, options) {
            if (!(model instanceof Backbone.Model) && typeof model === 'object') {
                var param = model;
                model = param.model;
                callBackRefresh = param.callBackRefresh;
                options = param.options;
            }
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            if (model.get('values').isEmpty()) {
                Bootbox.alert(_.i18n('standardCurve.result.noValues'));
                return;
            }
            options = _.defaults({model: model}, options);
            var createEditView = new ViewDetails(options);
            createEditView.show({
                title: _.i18n(model.getEntity().getName() + '.new'),
                className: 'baseTableEditAddPopup ' + model.getEntity().getName() + 'EditAddPopup'
            }, _.bind(function () {
                if (callBackRefresh) {
                    callBackRefresh();
                }
            }, callBackRefresh ? callBackRefresh.context : undefined));
        }
    };
});
