define('entities/admin/jsonpropertydocumentation',[
  'app',
  'backbone'
], function(
  App,
  Backbone
) {
  'use strict';

  var JsonPropertyDocumentation = Backbone.Model.extend({
    defaults: {
      name:'',
      internalName:'',
      description:'',
      dataType:'',
      internalDataType:'',
      sortSeq:'',
      relationType:'',
      relation:'',
      length:'',
      exampleContent: ''
    },
    idAttribute: 'secId'

  });

  var JsonPropertyDocumentationCollection = Backbone.Collection.extend({
    model: JsonPropertyDocumentation
  });


  App.reqres.setHandler('jsonpropertydocumentation:collection', function (itemJSON) {
    return new JsonPropertyDocumentationCollection(itemJSON);
  });

  App.reqres.setHandler('jsonpropertydocumentation:model', function (itemJSON) {
    return new JsonPropertyDocumentation(itemJSON);
  });
});
