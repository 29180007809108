define('entities/caccounts/lmbsamplehandlers',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.LmbSampleHandler = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/lmbsamplehandlers/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'description': '',
            'serialNb': '',
            'location': '',
            'refSampleHandler': {}
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/lmbsamplehandlers');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'LmbSampleHandlerJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LmbSampleHandler',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.LmbSampleHandlerCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/lmbsamplehandlers');
        },
        model: app.ns.LmbSampleHandler
    });

    module.exports = _.defaults({
        getName: function () {
            return 'lmbsamplehandler';
        },
        getUrl: function () {
            return 'v2/lmbsamplehandlers';
        },
        getDynamicJson: function () {
            return 'LmbSampleHandlerJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lmbSampleHandler.refSampleHandler'),
                name: 'refSampleHandler.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refSampleHandler.code',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lmbSampleHandler.serialNb'),
                name: 'serialNb',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'serialNb',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('lmbSampleHandler.location'),
                name: 'location',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'location',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.LmbSampleHandler.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['location', 'serialNb']};
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'serialNb', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'location', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-xs-4'}}},
                {'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-xs-4'}}}
            ];
        }
    }, Dynamic);

});
