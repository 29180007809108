define('entities/admin/cyclers',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/admin/detectionFormat',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.CyclerPublic = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'public/cyclers/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': undefined,
            'name': undefined,
            'refDetectionFormat': undefined,
            'plateSize': undefined,
            'colorCompensation': false,
            'plateFillDirection': 'C',
            'inputPlateLayoutService': '',
            'outputPlateLayoutService': '',
            'inputResultService': '',
            'customInputPlateLayout': '',
            'customOutputPlateLayout': '',
            'customInputResult': '',
            'description': undefined
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refDetectionFormat',
            relatedModel: 'DetectionFormat'
        }],

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'public/cyclers');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'CyclerPublicJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Cycler',
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.CyclerPublicCollection = Backbone.Collection.extend({
        model: app.ns.CyclerPublic
    });

    module.exports = _.defaults({
        getName: function () {
            return 'cyclerpublic';
        },
        getUrl: function () {
            return 'public/cyclers';
        },
        getDynamicJson: function () {
            return 'CyclerPublicJson';
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'name',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('detectionformat.label'),
                name: 'refDetectionFormat.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'refDetectionFormat.code',
                searchoptions: {size: 10},
                width: 30
            }, {
                label: _.i18n('cycler.inputPlateLayoutService'),
                name: 'inputPlateLayoutService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'inputPlateLayoutService',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.outputPlateLayoutService'),
                name: 'outputPlateLayoutService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'outputPlateLayoutService',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.inputResultService'),
                name: 'inputResultService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'inputResultService',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.customInputPlateLayout'),
                name: 'customInputPlateLayout',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'customInputPlateLayout',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.customOutputPlateLayout'),
                name: 'customOutputPlateLayout',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'customOutputPlateLayout',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.customInputResult'),
                name: 'customInputResult',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'customInputResult',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('plateSize.label'),
                name: 'plateSize',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'plateSize',
                searchoptions: {size: 10},
                width: 20
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'description'
            });
            return columns;
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel').hasRole('SUPERUSER');
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.CyclerPublic.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.CyclerPublicCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'plateSize']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore();
            ignored.push('customInputPlateLayout', 'customOutputPlateLayout', 'customInputResult');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'outputPlateLayoutService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customOutputPlateLayout',
                        'url': 'v2/inputPlateLayoutService'
                    }
                },
                {
                    'field': 'inputPlateLayoutService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customInputPlateLayout',
                        'url': 'v2/outputPlateLayoutService'
                    }
                },
                {
                    'field': 'inputResultService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customInputResult',
                        'url': 'v2/outputResultService'
                    }
                },
                {
                    'field': 'plateSize',
                    'param': {
                        'type': 'CODELIST',
                        'code': 'PLATESIZES_CYC'
                    }
                }
            );
            return custom;
        }
    }, Dynamic);
});
