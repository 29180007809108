define('prepRunWellEditSmpIdPlateView',[
    'module',
    'backbone',
    'marionette',
    'dialogFormView',
    'template!prepRunWellEditSmpIdPlateTpl',
    'template!prepRunWellEditImportSmpIdPlateTpl',
    'template!prepRunWellEditScanSmpIdPlateTpl',
    'template!prepRunWellEditExportSmpIdPlateTpl',
    'template!prepRunWellEditAddAssayPlateTpl',
    'template!prepRunWellEditAddRemoveTestPlateTpl',
    'template!prepRunWellEditClearWellsPlateTpl',
    'template!prepRunWellEditControlSidPlateTpl',
    'template!prepRunWellEditGenerateSmpIdPlateTpl',
    'template!prepRunEditImportFromOtherRunLayoutTpl',
    'savingBehavior',
    'underscore',
    'app',
    'jquery',
    'bootbox',
    'settings',
    'emptyView',
    'runRemoteFolderView',
    'runChooseUploadView',
    'runChooseDownloadView',
    'prepRunWellEditSmpidView',
    'autocompleteView',
    'bootbox',
    'customBootboxMessage',
    'dynamicCreateEditCodeList',
    'entities/caccounts/fileFormatPlateLayouts',
    'entities/caccounts/assay',
    'entities/admin/codelist',
    'entities/caccounts/prepwells',
    'entities/caccounts/prepwelltemplates',
    'entities/caccounts/prepruns',
    'entities/caccounts/prepruntemplates'
], function (
    module,
    Backbone,
    Marionette,
    DialogFormView,
    createEditLayoutTpl,
    createEditImportLayoutTpl,
    createEditScanLayoutTpl,
    createEditExportLayoutTpl,
    createEditAddAssayLayoutTpl,
    createEditAddRemoveTestLayoutTpl,
    createEditClearWellsLayoutTpl,
    createEditControlSidLayoutTpl,
    createEditGenerateSmpidLayoutTpl,
    createEditimportFromOtherRunLayoutTpl,
    SavingBehavior,
    _,
    App,
    $,
    Bootbox,
    Settings,
    EmptyView,
    RunRemoteFolderView,
    ChooseUploadView,
    ChooseDownloadView,
    WellEditSmpidView,
    AutocompleteView,
    bootbox,
    CustomBootboxMessage,
    CodeListView,
    FileFormatPlateLayouts,
    Assays,
    CodeLists
) {
    'use strict';

    module.exports = DialogFormView.extend({

        regions: {
            selectFileFormat: '.js-select-fileFormat-region',
            selectExportSource: '.js-select-exportSource-region',
            selectAssay: '.js-select-assay-region',
            selectClearAssay: '.js-select-clear-assay-region',
            selectPrepRunList: '.js-select-prepRunList-region',
            sourcePlateDirection: '.js-source-plate-direction-region',
            targetPlateDirection: '.js-target-plate-direction-region'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-ctrl-sampleId': 'onControlSampleId',
            'click .js-add-assay': 'onAddAssayClick',
            'click .js-clear-assay': 'onClearAssayClick',
            'click .js-replace-assay': 'onReplaceAssayClick',
            'click .js-add-test': 'onAddTestClick',
            'click .js-remove-test': 'onRemoveTestClick',
            'click .js-clear-wells': 'onClearWells',
            'click .js-generate-sample-id': 'onGenerateSampleIdClick',
            'click .js-generate-sample-id-popup': 'onGenerateSampleIdPopupClick',
            'click .js-clear-sample-id-popup': 'onClearSampleIdClick',
            'click .js-clear-sample-id': 'onClearSampleIdClick',
            'click @ui.importPlateLayout': 'onCheckPlateLayoutImport',
            'click @ui.exportPlateLayout': 'onExportPlateLayout',
            'click @ui.exportPlateLayoutPopup': 'onExportPlateLayoutPopup',
            'click @ui.controlPlateLayoutPopup': 'onControlPlateLayoutPopup',
            'click @ui.scanSamplesBtn': 'onCheckBeforeScanSample',
            'click @ui.quitScanBtn': 'onQuitScanBtn',
            'click @ui.stopScanBtn': 'onStopScanBtn',
            'change @ui.controlDuplicateSidCheckbox': 'onChangeControlSidCheckBox',
            'change @ui.controlSidLengthCheckbox': 'onChangeControlSidCheckBox',
            'change @ui.inputSampleIdStart': 'onChangeSampleIdStart',
            'click .js-import-fromOtherRun-button': 'onConfirmImportFromOtherRun',
            'click .js-hide': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        ui: {
            controlDuplicateSidCheckbox: '#controlDuplicateSidCheckbox',
            controlSidLengthCheckbox: '#controlSidLengthCheckbox',
            inputStart: '.js-input-start-well',
            inputFromWell: '.js-input-from-well',
            inputToWell: '.js-input-to-well',
            scanSamplesBtn: '.js-scan-samples-btn',
            scanSamplesDiv: '.js-scan-samples-div',
            startScanBtn: '.js-start-scan-btn',
            quitScanBtn: '.js-quit-scan-btn',
            stopScanBtn: '.js-stop-scan-btn',
            inputRoutingGroup: '.js-input-routing-group',
            inputSampleIdFrom: '.js-generate-sample-id-fromWell',
            inputSampleIdTo: '.js-generate-sample-id-toWell',
            inputSampleIdStart: '.js-generate-StartWith-sampleId',
            generateSmpIdBtn: '.js-generate-sample-id-btn',
            clearSmpIdBtn: '.js-clear-sample-id-btn',
            blockLoading: '.empty-page-loader',
            inputFromSampleId: '.js-generate-sample-id-from',
            inputSampleId: '.js-generate-sample-id',
            addAssay: '.js-add-assay',
            clearAssay: '.js-clear-assay',
            clearSampleId: '.js-clear-sample-id',
            importPlateLayout: '.import-plate-layout-button',
            exportPlateLayout: '.export-plate-layout-button',
            exportPlateLayoutPopup: '.export-plate-layout-popup-button',
            controlPlateLayoutPopup: '.control-plate-layout-popup-button',
            inputFromComputerPopup: '.inputFromComputerPopup',
            inputToComputerPopup: '.inputToComputerPopup',
            addAssayBtn: '.js-add-assay-btn',
            clearAssayBtn: '.js-clear-assay-btn',
            replaceAssayBtn: '.js-replace-assay-btn',
            input: '.js-info-input'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError',
            'change': 'renderSafe'
        },

        fieldsToValidate: [
            {name: 'refFileFormat', type: 'required'}
        ],

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        getTemplate: function () {
            switch (this.mode) {
                case 'import':
                    return createEditImportLayoutTpl;
                case 'importFromOtherRun':
                    return createEditimportFromOtherRunLayoutTpl;
                case 'scan':
                    return createEditScanLayoutTpl;
                case 'edit':
                    return createEditScanLayoutTpl;
                case 'export':
                    return createEditExportLayoutTpl;
                case 'all':
                    return createEditLayoutTpl;
                case 'add-assay':
                    return createEditAddAssayLayoutTpl;
                case 'clear-assay':
                    return createEditAddAssayLayoutTpl;
                case 'replace-assay':
                    return createEditAddAssayLayoutTpl;
                case 'generate-smpId':
                    return createEditGenerateSmpidLayoutTpl;
                case 'remove-smpId':
                    return createEditGenerateSmpidLayoutTpl;
                case 'control':
                    return createEditControlSidLayoutTpl;
                case 'add-test':
                case 'remove-test':
                    return createEditAddRemoveTestLayoutTpl;
                case 'clear-wells':
                    return createEditClearWellsLayoutTpl;
                default:
                    return createEditLayoutTpl;
            }
        },

        initialize: function (options) {
            this.mode = options.mode;
            this.type = this.options.model.getType();
            this.currentRoutingGroup = null;
            this.model = new Backbone.Model();
        },

        onControlSampleId: function () {
            if (this.mode === 'all' || this.mode === 'scan' || this.mode === 'edit') {
                this.showWell(true);
            }
            this.onExitEdit();
        },

        onStartEdit: function () {
            this.$el.find(this.ui.inputFromWell).prop('disabled', true);
            this.$el.find(this.ui.inputToWell).prop('disabled', true);

            this.$el.find(this.ui.inputSampleIdFrom).prop('disabled', true);
            this.$el.find(this.ui.inputSampleIdTo).prop('disabled', true);
            this.$el.find(this.ui.inputSampleIdStart).prop('disabled', true);
            this.$el.find(this.ui.clearSampleId).prop('disabled', true);
            this.$el.find(this.ui.inputFromSampleId).prop('disabled', true);

            this.$el.find(this.ui.addAssay).prop('disabled', true);
            this.$el.find(this.ui.clearAssay).prop('disabled', true);
            this.$el.find(this.regions.selectAssay).find('input').prop('disabled', true);
            this.$el.find(this.regions.selectClearAssay).find('input').prop('disabled', true);
            this.$el.find(this.ui.inputSampleId).prop('disabled', true);

            this.$el.find(this.ui.importPlateLayout).prop('disabled', true);
            this.$el.find(this.ui.exportPlateLayout).prop('disabled', true);

            this.$el.find(this.ui.controlPlateLayoutPopup).prop('disabled', true);
            this.$el.find(this.regions.selectFileFormat).find('input').prop('disabled', true);

            this.$el.find(this.regions.selectExportSource).find('input').prop('disabled', true);

            if (this.mode === 'all' || this.mode === 'scan' || this.mode === 'edit') {
                this.showWell(true);
            }
            this.box.closeButton = false;

            if (this.type !== 'prepruntemplate') {
                this.options.model.startScanning();
            }
        },

        onExitEdit: function () {
            if (this.type === 'prepruntemplate') {
                this.box.modal('hide');
            } else {
                this.ui.blockLoading.show();
                this.exitEdit().done(_.bind(function () {
                    this.checkIfAssigned();
                }, this));
            }
        },

        exitEdit: function () {
            var defer = $.Deferred();
            this.ui.blockLoading.show();
            App.request('run:prep:controlSID', this.options.model, true, true)
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                    defer.resolve();
                }, this));
            return defer.promise();
        },

        checkIfAssigned: function (afterUpload) {
            this.ui.blockLoading.hide();

            if (this.ui.blockLoading && afterUpload) {
                this.$el.find(this.ui.blockLoading).show();
                this.options.model = this.options.model;
            } else if (this.ui.blockLoading) {
                this.ui.blockLoading.show();
            }

            this.options.model.stopScanning()
                .done(_.bind(function () {
                    if (this.mode === 'all') {
                        this.render();
                    }
                    if (this.mode === 'import' || this.mode === 'export' || this.mode === 'scan' || this.mode === 'edit') {
                        this.box.modal('hide');
                    }
                }, this))
                .fail(_.bind(function (error) {
                    this.onStopScanningError(error);
                }, this))
                .always(_.bind(function () {
                    if (this.ui.blockLoading && this.ui.blockLoading.hide !== undefined) {
                        this.ui.blockLoading.hide();
                    }
                }, this));
        },

        onStopScanningError: function (error) {
            var params = {
                title: 'End of scan error',
                message: error.status + ' ' + error.statusText,
                type: 'error'
            };
            CustomBootboxMessage.customAlert(params);
            this.render();
        },

        onControlPlateLayoutPopup: function () {
            var controlCheckBoxes = [];
            $('.controlSidPopup input.controlSidCheckBox:checked').each(function () {
                controlCheckBoxes.push($(this).attr('name'));
            });

            this.ui.blockLoading.show();
            App.request('run:prep:controlSID', this.options.model, controlCheckBoxes.includes('controlSidLengthCheckbox'), controlCheckBoxes.includes('controlDuplicateSidCheckbox'))
                .always(_.bind(function () {
                    this.ui.blockLoading.hide();
                }, this));
            this.box.modal('hide');
        },

        onAddAssayClick: function () {
            var fromVal = this.ui.inputFromWell.val(),
                fromValidValue = !!(fromVal && fromVal !== '');

            var toVal = this.ui.inputToWell.val(),
                toValidValue = !!(toVal && toVal !== '');

            if (!this.currentAssay || !this.currentAssay.secId || !fromValidValue || !toValidValue) {
                Bootbox.alert(_.i18n('plateLayout.wrong.value'));
            } else {
                if (this.options.model.get('status') >= 2) {
                    var callback = _.bind(function (result) {
                        if (result) {
                            this.onAddAssay();
                        }
                    }, this);
                    this.statusChangeIcAlert(callback);
                } else {
                    if (this.type === 'prepruntemplate') {
                        this.options.model.addAssayWells(this.currentAssay.secId, this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                            .done(_.bind(function () {
                                this.render();
                            }, this))
                            .fail(_.bind(function (error) {
                                this.onAddAssayError(error);
                            }, this));
                    } else {
                        this.onAddAssay();
                    }
                }
            }
        },

        onAddTestClick: function () {
            var fromVal = this.ui.inputFromWell.val(),
                fromValidValue = !!(fromVal && fromVal !== '');

            var toVal = this.ui.inputToWell.val(),
                toValidValue = !!(toVal && toVal !== '');

            if (!fromValidValue || !toValidValue) {
                Bootbox.alert(_.i18n('plateLayout.wrong.value'));
            } else {
                if (this.type === 'prepruntemplate') {
                    this.options.model.addTestWells(this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                        .done(_.bind(function () {
                            this.render();
                        }, this))
                        .fail(_.bind(function (error) {
                            this.onAddTestError(error);
                        }, this));
                } else {
                    this.onAddTest();
                }
            }
        },

        statusChangeIcAlert: function (callback) {
            var confirmParams = {
                message: _.i18n('prepRun.st2+.sid.warning.message'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onAddAssay: function () {
            this.options.model.addAssayWells(this.currentAssay.secId, this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    if (this.mode === 'add-assay' || this.mode === 'clear-assay') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this))
                .fail(_.bind(function (error) {
                    this.onAddAssayError(error);
                }, this));
        },

        onAddTest: function () {
            this.options.model.addTestWells(this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    if (this.mode === 'add-test' || this.mode === 'remove-test') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this))
                .fail(_.bind(function (error) {
                    this.onAddTestError(error);
                }, this));
        },

        onAddAssayError: function (error) {
            var params = {
                title: _.i18n('run.addAssay.error'),
                message: error.status + ' ' + error.statusText,
                type: 'error'
            };
            CustomBootboxMessage.customAlert(params);
            this.render();
        },

        onAddTestError: function (error) {
            var params = {
                title: _.i18n('run.addTest.error'),
                message: error.status + ' ' + error.statusText,
                type: 'error'
            };
            CustomBootboxMessage.customAlert(params);
            this.render();
        },

        onClearAssayClick: function () {
            if (this.type === 'prepruntemplate') {
                this.onPrepRunTemplateClearAssay();
            } else {
                var fromVal = this.ui.inputFromWell.val(),
                    fromValideValue = !!(fromVal && fromVal !== undefined && fromVal !== null && fromVal !== '');

                var toVal = this.ui.inputToWell.val(),
                    toValideValue = !!(toVal && toVal !== undefined && toVal !== null && toVal !== '');

                if (!fromValideValue || !toValideValue) {
                    Bootbox.alert(_.i18n('plateLayout.wrong.value'));
                    return;
                } else {
                    if (this.options.model.get('status') > 3) {
                        var callback = _.bind(function (result) {
                            if (result) {
                                this.onClearAssay(true);
                            }
                        }, this);

                        this.statusChangeIcAlert(callback);
                    } else {
                        this.onClearAssay(false);
                    }
                }
            }
        },

        onReplaceAssayClick: function () {
            if (this.type === 'prepruntemplate') {
                this.onPrepRunTemplateClearAssay();
            } else {
                var fromVal = this.ui.inputFromWell.val(),
                    fromValideValue = !!(fromVal && fromVal !== undefined && fromVal !== null && fromVal !== '');

                var toVal = this.ui.inputToWell.val(),
                    toValideValue = !!(toVal && toVal !== undefined && toVal !== null && toVal !== '');

                if (!fromValideValue || !toValideValue) {
                    Bootbox.alert(_.i18n('plateLayout.wrong.value'));
                    return;
                } else {
                    if (this.options.model.get('status') > 3) {
                        var callback = _.bind(function (result) {
                            if (result) {
                                this.onReplaceAssay(true);
                            }
                        }, this);

                        this.statusChangeIcAlert(callback);
                    } else {
                        this.onReplaceAssay(false);
                    }
                }
            }
        },

        onRemoveTestClick: function () {
            if (this.type === 'prepruntemplate') {
                this.onPrepRunTemplateRemoveTest();
            } else {
                var fromVal = this.ui.inputFromWell.val(),
                    fromValideValue = !!(fromVal && fromVal !== '');

                var toVal = this.ui.inputToWell.val(),
                    toValideValue = !!(toVal && toVal !== '');

                if (!fromValideValue || !toValideValue) {
                    Bootbox.alert(_.i18n('plateLayout.wrong.value'));
                } else {
                    this.onRemoveTest(false);
                }
            }
        },

        onClearWells: function () {
            Bootbox.confirm(_.i18n('plateLayout.clear.wells.confirm'), _.bind(function (result) {
                if (!result) {
                    return;
                }
                this.ui.blockLoading.show();

                this.options.model.clearWells(this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                    .done(_.bind(function () {
                        if (this.mode === 'clear-wells') {
                            this.box.modal('hide');
                        } else {
                            this.render();
                        }
                    }, this))
                    .always(_.bind(function () {
                        this.ui.blockLoading.hide();
                    }, this));
            }, this));
        },
        onPrepRunTemplateClearAssay: function () {
            var confirmParams = {
                message: _.i18n('runTemplate.clear.kit.confirm'),
                type: 'warning'
            };

            var callback = _.bind(function (result) {
                if (result) {
                    this.prepRunTemplateClearAssay();
                }
            }, this);
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onPrepRunTemplateRemoveTest: function () {
            var confirmParams = {
                message: _.i18n('runTemplate.remove.test.confirm'),
                type: 'warning'
            };

            var callback = _.bind(function (result) {
                if (result) {
                    this.prepRunTemplateRemoveTest();
                }
            }, this);
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        prepRunTemplateClearAssay: function () {
            this.ui.blockLoading.show();
            this.options.model.clearAssay(this.currentAssay.secId, this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    this.render();
                }, this));
        },

        prepRunTemplateRemoveTest: function () {
            this.ui.blockLoading.show();
            this.options.model.removeTest(this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    this.render();
                }, this));
        },

        onClearAssay: function (alreadyWarningDisplay) {
            if (alreadyWarningDisplay) {
                this.clearAssay();
            } else {
                var confirmParams = {
                    message: _.i18n('plateLayout.clear.assay.confirm'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, _.bind(function (result) {
                    if (result) {
                        this.clearAssay();
                    }
                }, this));
            }
        },

        onReplaceAssay: function (alreadyWarningDisplay) {
            if (alreadyWarningDisplay) {
                this.replaceAssay();
            } else {
                var confirmParams = {
                    message: _.i18n('plateLayout.replace.assay.confirm'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, _.bind(function (result) {
                    if (result) {
                        this.replaceAssay();
                    }
                }, this));
            }
        },

        clearAssay: function () {
            this.options.model.clearAssay(this.currentClearAssay.secId, this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    this.box.modal('hide');
                }, this));
        },


        replaceAssay: function () {
            this.options.model.replaceAssay(this.currentClearAssay.secId, this.currentAssay.secId, this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    this.box.modal('hide');
                }, this));
        },

        onRemoveTest: function (alreadyWarningDisplay) {
            if (alreadyWarningDisplay) {
                this.removeTest();
            } else {
                var confirmParams = {
                    message: _.i18n('plateLayout.remove.test.confirm'),
                    type: 'warning'
                };
                var callback = _.bind(function (result) {
                    if (result) {
                        this.removeTest();
                    }
                }, this);
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            }
        },

        removeTest: function () {
            this.ui.blockLoading.show();
            this.options.model.removeTest(this.ui.inputFromWell.val(), this.ui.inputToWell.val())
                .done(_.bind(function () {
                    if (this.mode === 'add-test' || this.mode === 'remove-test') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this));

        },

        onChangeSampleIdStart: function (e) {
            var $target = $(e.currentTarget),
                display = $target && $target.val() && $target.val() !== undefined && $target.val() !== null && $target.val() !== '';
            this.$el.find(this.ui.generateSmpIdBtn).prop('disabled', !display);
            this.$el.find(this.ui.clearSmpIdBtn).prop('disabled', !display);
        },

        onGenerateSampleIdPopupClick: function () {
            var fromVal = this.ui.inputSampleIdFrom.val(),
                fromValideValue = !!(fromVal && fromVal !== '');

            var toVal = this.ui.inputSampleIdTo.val(),
                toValideValue = !!(toVal && toVal !== '');

            if (!this.ui.inputSampleIdStart.val() || this.ui.inputSampleIdStart.val() === '' || !fromValideValue || !toValideValue) {
                var notification = Bootbox.alert(_.i18n('plateLayout.wrong.value'));
                notification.show();
                setTimeout(function () {
                    notification.modal('hide');
                }, 8000);

            } else {
                this.onGenerateSampleIdClick();
            }
        },

        statusChangeSidAlert: function (callback) {
            var confirmParams = {
                message: _.i18n('prepRun.st2+.sid.warning.message'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onGenerateSampleIdClick: function () {
            if (this.options.model.get('status') >= 2) {
                var callback = _.bind(function (result) {
                    if (result) {
                        this.onGenerateSampleId();
                    }
                }, this);
                this.statusChangeSidAlert(callback);
            } else {
                this.onGenerateSampleId();
            }
        },

        onClearSampleIdClick: function () {
            if (this.options.model.get('status') >= 2) {
                var callback = _.bind(function (result) {
                    if (result) {
                        this.onClearSampleId();
                    }
                }, this);
                this.statusChangeSidAlert(callback);
            } else {
                this.onClearSampleId();
            }
        },

        onGenerateSampleId: function () {
            if (isNaN(this.ui.inputSampleIdStart.val().slice(-1))) {
                this.ui.inputSampleIdStart.val(this.ui.inputSampleIdStart.val() + '01');
            }
            this.ui.blockLoading.show();
            this.options.model.generateSampleId(this.ui.inputSampleIdStart.val(), this.ui.inputSampleIdFrom.val(), this.ui.inputSampleIdTo.val(), this.currentRoutingGroup)
                .done(_.bind(function () {
                    if (this.mode === 'generate-smpId') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this));
        },

        onClearSampleId: function () {
            this.ui.blockLoading.show();
            this.options.model.clearSampleId(this.ui.inputSampleIdFrom.val(), this.ui.inputSampleIdTo.val())
                .done(_.bind(function () {
                    if (this.mode === 'remove-smpId') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this));
        },

        serializeData: function () {
            var templateData = {};
            templateData.status = this.options.model.get('status');
            templateData.plateSize = this.options.model.get('plateSize');

            templateData.editTemplate = this.options.editTemplate;
            templateData.accept = '.xlsx,.xls,.xml,.rdml,.csv';
            templateData.mode = this.mode;
            templateData.newItem = this.options.newItem;
            templateData.statusIcon = this.options.statusIcon;

            var plateSize = this.options.model.get('plateSize');
            var rowNb = plateSize.substring(0, 1);
            var columnNb = parseInt(plateSize.substring(1, plateSize.length), 10);
            var max = parseInt(columnNb.toString().substring(0, 1), 10);

            templateData.from = this.options.pos && this.options.pos.from ? this.options.pos.from : 'A01';
            templateData.to = this.options.pos && this.options.pos.to ? this.options.pos.to : plateSize;
            templateData.column = columnNb;
            templateData.row = rowNb.charCodeAt(0) - 64;

            var pattern = '(';
            var patternRow = '[A-' + rowNb + ']';
            for (var i = 0; i <= max; i++) {
                if (i === max) {
                    var plateColumnNb = columnNb;
                    if (plateColumnNb > 10) {
                        pattern = pattern + patternRow + i + '[0-' + (plateColumnNb - 10) + ']';
                    } else {
                        pattern = pattern + patternRow + i + '[0-' + plateColumnNb + ']';
                    }
                } else {
                    pattern = pattern + patternRow + i + '[0-9]';
                }
                pattern = pattern + '|';
            }
            templateData.pattern = pattern.substring(0, pattern.length - 1) + ')';

            if (this.type === 'prepRunTemplate') {
                templateData.displayName = templateData.code;
            } else {
                templateData.displayName = templateData.name;
            }

            return templateData;
        },

        onRender: function () {
            if (this.mode === 'all' || this.mode === 'scan' || this.mode === 'edit') {
                this.showWell(this.mode === 'scan');
            }
            this.triggerMethod('enable:cancel:confirm');
            if (this._isShown) {
                this.triggerMethod('show', this);
            }
        },

        onShow: function () {
            if (this.mode === 'all' || this.mode === 'export') {

                this.fileFormatView = new AutocompleteView(
                    this._getAutocompleteOptionObject(FileFormatPlateLayouts.getAutocompleteParamForExport({
                        modelProperty: 'refFileFormat',
                        callBackOnChange: this.onChangeFileFormat
                    }))
                );
                this.getRegion('selectFileFormat').show(this.fileFormatView);
            }

            if (this.mode === 'importFromOtherRun') {
                this.model.set({
                    copySampleId: true, copyAssay: true, copySampleType: true,
                    sourcePlateFillDirection: this.options.model.get('settings')['LAB-SETTINGS'],
                    targetPlateFillDirection: this.options.model.get('settings')['LAB-SETTINGS']
                }, {silent: true});
                this.getRegion('sourcePlateDirection').show(new CodeListView({
                    model: this.model,
                    config: {
                        modelProperty: 'sourcePlateFillDirection',
                        code: 'PLATE_FILL_DIRECTION'
                    }
                }));
                this.getRegion('targetPlateDirection').show(new CodeListView({
                    model: this.model,
                    config: {
                        modelProperty: 'targetPlateFillDirection',
                        code: 'PLATE_FILL_DIRECTION'
                    }
                }));

                this.getRegion('selectPrepRunList').show(new AutocompleteView(
                    this._getAutocompleteOptions('prepRunName', 'name', 'name',
                        'v2/runs/prep?sord=desc&sidx=creatDate',
                        'common.empty.placeholder', 'name',
                        this.onPrepRunListChange, '', '',
                        App.request('run:prep:get-fields-to-display'))
                ));
            }

            if (this.mode === 'export') {
                var exportSourceView = new AutocompleteView(
                    this._getAutocompleteOptionObject(
                        CodeLists.getAutocompleteParamForCode({
                            callBackOnChange: this.onChangeExportDestination,
                            codeListCode: 'DOWNL_DEST'
                        })));
                this.getRegion('selectExportSource').show(exportSourceView);
            }
            if (this.mode === 'all' || this.mode === 'add-assay') {
                this.assayView = new AutocompleteView(
                    this._getAutocompleteOptions('', 'code', 'code',
                        'v2/assays?sord=asc&sidx=code&reagentType=IC&onlyWithCurrentLot=true',
                        'common.empty.placeholder', 'code',
                        this.onChangeAssay, null, '',
                        App.request('assay:get-fields-to-display'), '', '')
                );
                this.getRegion('selectAssay').show(this.assayView);
            }

            if (this.mode === 'all' || this.mode === 'clear-assay') {
                this.assayClearView = new AutocompleteView(
                    this._getAutocompleteOptionObject(Assays.getAutocompleteParamFromRun(this.options.model, {
                        modelProperty: 'refAssay',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'assay.code'
                        },
                        callBackOnChange: this.onChangeClearAssay
                    })));
                this.getRegion('selectClearAssay').show(this.assayClearView);
            }
            if (this.mode === 'all' || this.mode === 'replace-assay') {
                this.assayView = new AutocompleteView(
                    this._getAutocompleteOptions('', 'code', 'code',
                        'v2/assays?sord=asc&sidx=code&reagentType=IC&onlyWithCurrentLot=true',
                        'common.empty.placeholder', 'code',
                        this.onChangeAssay, null, '',
                        App.request('assay:get-fields-to-display'), '', '')
                );
                this.getRegion('selectAssay').show(this.assayView);
                this.assayClearView = new AutocompleteView(
                    this._getAutocompleteOptionObject(Assays.getAutocompleteParamFromRun(this.options.model, {
                        modelProperty: 'refAssay',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'assay.code'
                        },
                        callBackOnChange: this.onChangeClearAssay
                    })));
                this.getRegion('selectClearAssay').show(this.assayClearView);
            }

            if (this.mode === 'scan') {
                this.currentRoutingGroup = this.options.routingGroup;
                this.onStartEdit();
            }
        },

        onPrepRunListChange: function (fieldName, model) {
            this.onChange('sourceSecId', model ? model.get('secId') : null);
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirmImportFromOtherRun: function () {
            var fieldsToValidate = [
                {name: 'sourceSecId', type: 'required'},
                {name: 'sourceStart', type: 'required'},
                {name: 'sourceEnd', type: 'required'},
                {name: 'sourcePlateFillDirection', type: 'required'},
                {name: 'targetStart', type: 'required'},
                {name: 'targetPlateFillDirection', type: 'required'}
            ];
            if (this.validate(fieldsToValidate)) {
                this.ui.blockLoading.show();
                this.options.model.copyPlateLayoutFrom(this.model.toJSON())
                    .done(_.bind(function () {
                        this.box.modal('hide');
                    }, this))
                    .fail(_.bind(function (error) {
                        this.onImportError(error);
                        this.box.modal('hide');
                    }, this))
                    .always(_.bind(function () {
                        this.ui.blockLoading.hide();
                    }, this));
            }
        },

        onImportError: function (error) {
            var params = {
                title: _.i18n('import.error'),
                message: error.status + ' ' + error.statusText,
                type: 'error'
            };
            CustomBootboxMessage.customAlert(params);
            this.render();
        },

        onChangeControlSidCheckBox: function () {
            var controlCheckBoxes = [];
            $('.controlSidPopup input.controlSidCheckBox:checked').each(function () {
                controlCheckBoxes.push($(this).attr('name'));
            });
            if (controlCheckBoxes.length < 1) {
                this.$el.find(this.ui.controlPlateLayoutPopup).prop('disabled', true);
            } else {
                this.$el.find(this.ui.controlPlateLayoutPopup).prop('disabled', false);
            }
        },

        onStopScanBtn: function () {
            this.onExitEdit();
        },

        onChangeAssay: function (fieldName, model) {
            this.currentAssay = model ? model.toJSON() : null;
            this.$el.find(this.ui.addAssayBtn).prop('disabled', !model);
            this.$el.find(this.ui.clearAssayBtn).prop('disabled', !model);
            this.$el.find(this.ui.replaceAssayBtn).prop('disabled', !model);
        },

        onChangeClearAssay: function (fieldName, model) {
            this.currentClearAssay = model ? model.toJSON() : null;
            this.$el.find(this.ui.addAssayBtn).prop('disabled', !model);
            this.$el.find(this.ui.clearAssayBtn).prop('disabled', !model);
            this.$el.find(this.ui.replaceAssayBtn).prop('disabled', !model);
        },

        onChangeFileFormat: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model);
            this.fileFormatSecId = model.get('secId');
            this.$el.find(this.ui.importPlateLayout).prop('disabled', !this.fileFormatSecId);
            this.$el.find(this.ui.exportPlateLayout).prop('disabled', !this.fileFormatSecId);
        },

        onCheckPlateLayoutImport: function () {
            this.onImportPlateLayout();
        },

        onChangeExportDestination: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.currentExportDestination = model ? model.toJSON() : null;
                var remoteAvailable = settings['EXPORT-PLATELAYOUT'].split(';')[0];

                if (this.currentExportDestination && this.currentExportDestination.code) {
                    if (this.currentExportDestination.code === 'CENTRALLIB') {
                        this.$el.find(this.ui.exportPlateLayoutPopup).prop('disabled', !remoteAvailable);
                    } else {
                        this.$el.find(this.ui.exportPlateLayoutPopup).prop('disabled', false);
                    }
                } else {
                    this.$el.find(this.ui.exportPlateLayoutPopup).prop('disabled', true);
                }
            }, this));
        },

        onImportPlateLayout: function () {
            if (this.validate(this.fieldsToValidate)) {
                App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                    this.chooseUploadView = new ChooseUploadView({
                        settings: settings,
                        remoteAvailable: settings['IMPORT-PLATELAYOUT-PREP'].split(';')[0],
                        accept: '.xlsx,.xls,.xml,.rdml,.csv'
                    });
                    this.chooseUploadView.show({
                        title: _.i18n('run.importFileFormat')
                    });
                    this.chooseUploadView.listenTo(this.chooseUploadView, 'run:import-xml', _.bind(this.fileUpload, this));
                    this.chooseUploadView.listenTo(this.chooseUploadView, 'run:select-file-to-import', _.bind(this.onSelectFileToImport, this));
                }, this));
            }
        },

        onError: function (errors, dontShowError) {
            var that = this,
                nameField;
            _.each(errors, function (error) {
                nameField = that.$('input[data-field-name="' + error.name + '"]');
                nameField.addClass('invalidInput');
            });
            if (dontShowError) {
                return;
            }
            this.triggerMethod('errorMessage', _.i18n('warning.mandatoryFields'));
        },

        fileUpload: function (fileInfo) {
            if (this.fileFormatSecId) {
                var fileName = fileInfo.name;
                var runName = this.options.model.get('name');

                if (fileName.includes(runName, 0)) {
                    this.confirmUpload(fileInfo);
                } else {
                    var callback = _.bind(function (result) {
                        if (result) {
                            this.confirmUpload(fileInfo);
                        }
                    }, this);

                    var confirmParams = {
                        message: _.i18n('run.controlRunName'),
                        type: 'warning'
                    };
                    CustomBootboxMessage.customConfirm(confirmParams, callback);
                }
            }
        },

        confirmUpload: function (fileInfo) {
            this.ui.blockLoading.show();
            this.options.model.importFilePlateLayout(fileInfo, this.fileFormatSecId)
                .done(_.bind(function () {
                    this.checkIfAssigned(true);
                }, this));
        },

        onSelectFileToImport: function () {
            App.request('run:prep:plateLayout-to-import').done(_.bind(function (files) {
                this.runRemoteFolderView = new RunRemoteFolderView({
                    files: files,
                    runModel: this.options.model,
                    type: 'plateLayoutPrep'
                });
                this.runRemoteFolderView.listenTo(this.runRemoteFolderView, 'run:import-xml', _.bind(this.fileUpload, this));
                this.runRemoteFolderView.show({
                    title: _.i18n('preprun.import.remote.folder')
                });
            }, this));
        },

        onExportPlateLayoutPopup: function () {
            if (this.validate(this.fieldsToValidate)) {
                if (this.currentExportDestination && this.currentExportDestination.code === 'CENTRALLIB') {
                    this.onRemoteFileDownload();
                } else if (this.currentExportDestination && this.currentExportDestination.code === 'COMPUTER') {
                    this.onFileDownload();
                }
            }
        },

        onExportPlateLayout: function () {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                this.chooseDownloadView = new ChooseDownloadView({
                    settings: settings,
                    exportSetting: settings['EXPORT-PLATELAYOUT'].split(';')[0]
                });
                this.chooseDownloadView.show({
                    title: _.i18n('run.exportFileFormat')
                });
                this.chooseDownloadView.listenTo(this.chooseDownloadView, 'run:local-export-xml', _.bind(this.onFileDownload, this));
                this.chooseDownloadView.listenTo(this.chooseDownloadView, 'run:remote-export-xml', _.bind(this.onRemoteFileDownload, this));
            }, this));
        },

        onFileDownload: function () {
            $.when(window.open(Settings.url('compuzz', 'v2/runs/prep/' + this.options.model.get('secId') + '/plateLayout/export', {
                fileFormatSecId: this.fileFormatSecId,
                downloadFile: true
            }), '_blank'))
                .done(_.bind(function (model) {
                    this.options.model = model;
                    if (this.mode === 'import' || this.mode === 'export') {
                        this.box.modal('hide');
                    } else {
                        this.render();
                    }
                }, this));
        },

        onRemoteFileDownload: function () {
            this.remoteFileDownload(this.options.model.get('secId')).done(function () {
                Bootbox.alert({
                    title: _.i18n('run.exportFileFormat.exportResult'),
                    message: _.i18n('run.exportFileFormat.exportResult.success')
                });
                if (this.mode === 'import' || this.mode === 'export') {
                    this.box.modal('hide');
                } else {
                    this.render();
                }
            });
        },

        remoteFileDownload: function (secId) {
            var defer = $.Deferred(),
                url;
            url = Settings.url('compuzz', 'v2/runs/prep/' + secId + '/plateLayout/export', {
                fileFormatSecId: this.fileFormatSecId,
                downloadFile: false
            });
            $.ajax({
                type: 'GET',
                url: url,
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        showWell: function (start) {
            App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
                var indexTabIndex = 1;

                var wells;

                if (this.options.model.get('wells')) {
                    wells = this.options.model.get('wells');
                } else if (this.options.model.get('prepWellTemplates')) {
                    wells = this.options.model.get('prepWellTemplates');
                }

                wells.forEach(
                    _.bind(function (well) {

                        var control = well.get('smpType') === 'NC' ||
                            well.get('smpType') === 'PC' ||
                            well.get('smpType') === 'RC' ||
                            well.get('smpType') === 'OC' ||
                            well.get('smpType') === 'C';

                        var wellView = new WellEditSmpidView({
                            model: well,
                            modelRun: this.options.model,
                            tabIndex: indexTabIndex,
                            editMode: start,
                            control: control,
                            parent: this,
                            settings: settings,
                            mode: this.mode
                        });

                        if (!control) {
                            indexTabIndex = indexTabIndex + 1;
                        }

                        this.addRegion('tbl' + well.get('pos'), '.tbl-' + well.get('pos'));
                        this.getRegion('tbl' + well.get('pos')).show(wellView);
                    }, this));

                var inputs = $('.js-input-smpId[tabindex=1]');
                if (inputs.length > 0) {
                    inputs[0].focus();
                }
            }, this));
        },

        onSave: function () {
        },

        onEscape: function (event) {
            var display = this.$el.find(this.ui.start).parent().css('display');
            if (display === 'none') {
                Bootbox.alert(_.i18n('plateLayout.editing'));
                event.stopImmediatePropagation();
                return false;
            }
            return true;
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        hide: function () {
            if (this.options.mode === 'edit') {
                this.trigger('on:runTemplate:edit:Hide');
                this.refreshWellTableView();
                this.destroy();
            } else {
                this.refreshWellTableView();
                this.destroy();
            }
        },

        refreshWellTableView: function () {
            var currentRoute = App.getCurrentRoute();
            if (currentRoute.includes('details')) {
                App.trigger('well:table:prep:refresh:jqGrid');
            }
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
        }
    });
});
