/**
 * Created by Matteo on 4/08/2015.
 */
define('routingMain',[
	'module',
	'logger',
	'routingRouter'
], function (
	module,
	Logger
) {
	'use strict';

	module.exports = {
		start: function () {
			Logger.log(module, 'starting RoutingModule');
		},

		stop: function () {
			Logger.log(module, 'stopping RoutingModule');
		}
	};
});
