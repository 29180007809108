define('tabsContentCollection',[
    'module',
    'marionette',
    'tabsContentItem',
    'underscore'
], function (
    module,
    Marionette,
    TabsContentItem,
    _
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: TabsContentItem,
        className: 'tab-content',
        changeActive: function (model) {
            _.each(this.children._views, function (child) {
                child.setActive(child.model === model);
            });
        }
    });
});
