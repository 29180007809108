/**
 * Created by RKL on 26/08/2015.
 */
define('dynamicList',[
    'module',
    'underscore',
    'jquery',
    'jqgridView',
    'template!dynamicList',
    'customBootboxMessage',
    'bootbox',
    'dialogMixin',
    'settings',
    'fileExplorerController',
    'errorController'
], function (
    module,
    _,
    $,
    JqGridView,
    viewTpl,
    CustomBootboxMessage,
    Bootbox,
    DialogMixin,
    Settings,
    FileExplorerController,
    ErrorController
) {
    'use strict';

    module.exports = JqGridView.extend(
        _.extend({
            template: viewTpl,
            filtersName: function () {
                return this.options.entity.getJqGridFilterName();
            },
            triggers: {},

            ui: function () {
                this.uniqueId = _.extend({
                    'jq-grid-list': _.uniqueId('jq-grid-list_'),
                    'jq-grid-pager-list': _.uniqueId('jq-grid-pager-list_'),
                    'menuAddListButton': _.uniqueId('menuAddListButton_'),
                    'menuAddList': _.uniqueId('menuAddList_')
                }, this.uniqueId);
                return _.extend({
                    jqGrid: '#' + this.uniqueId['jq-grid-list'],
                    jqGridPager: '#' + this.uniqueId['jq-grid-pager-list'],
                    deleteDynamic: '.delete-link-action',
                    exportDynamic: '.export-link-action',
                    duplicateDynamic: '.duplicate-link-action',
                    selectRow: '.select-link-action',
                    gridUpperMenu: '.js-grid-upper-menu',
                    selectAllRows: '.js-all-rows-selector',
                    exportSelected: '.js-all-rows-export',
                    tableHeader: 'table.ui-jqgrid-htable',
                    menuButton: '#' + this.uniqueId.menuAddListButton,
                    menuAddList: '#' + this.uniqueId.menuAddList,
                    menuEntry: '.menuEntry',
                    showViewNewDynamic: '.js-showViewNew',
                    attachment: '.attachment-link-action',
                    alert: '.alert-link-action',
                    archive: '.cell-archive',
                    unArchive: '.cell-unArchive',
                    deleteByList: '.js-delete-by-list',
                    archiveByList: '.js-archive-by-list',
                    unArchiveByList: '.js-unArchive-by-list',
                    displayArchived: '.display-archived',
                    importFile: '.js-import'
                }, this.gridUi);
            },

            events: function () {
                return _.extend({
                    'mouseenter @ui.tableHeader': 'showGridUpperMenu',
                    'mouseleave @ui.tableHeader': 'HideGridUpperMenu',
                    'mouseenter @ui.gridUpperMenu': 'showGridUpperMenu',
                    'mouseleave @ui.gridUpperMenu': 'HideGridUpperMenu',
                    'click @ui.selectAllRows': 'onCheckAllRows',
                    'click @ui.selectRow': 'onCheckOneRow',
                    'click @ui.deleteDynamic': 'onDeleteDynamic',
                    'click @ui.exportSelected': 'onExportElementsList',
                    'click @ui.exportDynamic': 'onExportElement',
                    'click @ui.duplicateDynamic': 'onDuplicateElement',
                    'click @ui.showViewNewDynamic': 'showViewNewDynamic',
                    'click @ui.menuButton': 'showMenu',
                    'mouseleave @ui.menuAddList': 'hideMenu',
                    'click @ui.menuEntry': 'hideMenu',
                    'change .js-import': 'onImport',
                    'click @ui.attachment': 'onAttachment',
                    'click @ui.alert': 'onAlert',
                    'click @ui.archive': 'onArchive',
                    'click @ui.unArchive': 'onUnArchive',
                    'click @ui.deleteByList': 'onDeleteByList',
                    'click @ui.archiveByList': 'onArchiveByList',
                    'click @ui.unArchiveByList': 'onUnArchiveByList',
                    'click .js-back-button': 'onHistoryBack',
                    'change @ui.displayArchived': 'onChangeDisplayArchived'
                }, this.gridEvents);
            },

            className: function () {
                return 'dynamic jq-grid-table-page ' + this.options.entity.getName() + '-listView';
            },
            regions: function () {
                return _.extend({
                    'custom': '.js-custom'
                });
            },

            showViewNewDynamic: function () {
                this.trigger('dynamic:show', this.options.entity.getModel(this.options.data.defaultModel), this.options.data.callBackCreateEditView, _.bind(this.updatePagination, this));
            },

            showMenu: function () {
                this.showBaseTableAddMenu($(this.ui.menuAddList));
            },

            hideMenu: function () {
                this.hideBaseTableAddMenu($(this.ui.menuAddList));
            },

            onInitialize: function () {
                if (!this.options.displayArchived) {
                    this.options.displayArchived = 'WITHOUT';
                }
            },
            onChangeDisplayArchived: function (event) {
                var inputs = $('input[name="displayArchived"]');
                inputs.val(event.currentTarget.value);
                inputs.change();
            },

            serializeData: function () {
                this.exportListCall = _.bind(function (rowIds, type) {
                    return this.options.entity.exportEntities(rowIds, type);
                }, this);
                this.exportCall = _.bind(function (rowId) {
                    return this.options.entity.exportEntity(rowId);
                }, this);
                this.archiveByListCall = _.bind(function (rowIds) {
                    return this.options.entity.archiveEntities(rowIds);
                }, this);
                this.unArchiveByListCall = _.bind(function (rowIds) {
                    return this.options.entity.unarchiveEntities(rowIds);
                }, this);
                this.deleteByListCall = _.bind(function (rowIds) {
                    return this.options.entity.deleteEntities(rowIds);
                }, this);
                this.duplicateCall = _.bind(function (rowIds) {
                    return this.options.entity.duplicateEntity(rowIds);
                }, this);
                var templateData = {
                    entityName: this.options.entity.getName(),
                    data: this.options.data,
                    accept: '.json,.csv'
                };
                templateData.config = this.options.entity.getJqGridColumnConfig();
                templateData.uniqueId = this.uniqueId;
                templateData.className = this.options.region && !this.options.data.removeClassName ? 'well' : 'dynamic';
                return templateData;
            },

            onHistoryBack: function () {
                this.jqGridHistoryBack();
            },

            onDeleteDynamic: function (event) {
                var target = $(event.currentTarget);
                var codeName = target.find('span').attr('data-row-codeName');
                var secId = target.find('span').attr('data-row-id');
                var callback = _.bind(function (result) {
                    if (result) {
                        this.options.entity.deleteEntity(secId)
                            .done(_.bind(function () {
                                this.updatePagination();
                            }, this));
                    }
                    this.resetCheckedRowList();
                }, this);
                var confirmParams = {
                    message: _.i18n('confirmDelete') + ' ' + this.options.entity.getName() + ' \"' + '' + codeName + '\" ?',
                    notaBene: _.i18n('confirmDelete.notaBene'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            },

            onAttachment: function (event) {
                var target = $(event.currentTarget);
                var model = this.options.entity.getModel({secId: target.find('span').attr('data-row-id')});
                model.fetch().done(_.bind(function () {
                    model.getEntity().openAttachment(model);
                }, this));
            },
            onAlert: function (event) {
                var target = $(event.currentTarget);
                var model = this.options.entity.getModel({secId: target.find('span').attr('data-row-id')});
                model.fetch().done(_.bind(function () {
                    ErrorController.showE(model, model.get('entityError'));
                }, this));
            },

            onArchive: function (event) {
                var target = $(event.currentTarget);
                var codeName = target.attr('data-row-codeName');
                var secId = target.attr('data-row-id');
                var callback = _.bind(function (result) {
                    if (result) {
                        this.options.entity.archiveEntity(secId)
                            .done(_.bind(function () {
                                this.updatePagination();
                            }, this));
                    }
                    this.resetCheckedRowList();
                }, this);
                var confirmParams = {
                    message: _.i18n('confirmArchive') + ' ' + this.options.entity.getName() + ' \"' + '' + codeName + '\" ?',
                    notaBene: _.i18n('confirmArchive.notaBene'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            },

            onUnArchive: function (event) {
                var target = $(event.currentTarget);
                var codeName = target.attr('data-row-codeName');
                var secId = target.attr('data-row-id');
                var callback = _.bind(function (result) {
                    if (result) {
                        this.options.entity.unarchiveEntity(secId)
                            .done(_.bind(function () {
                                this.updatePagination();
                            }, this));
                    }
                    this.resetCheckedRowList();
                }, this);
                var confirmParams = {
                    message: _.i18n('confirmUnarchive') + ' ' + this.options.entity.getName() + ' \"' + '' + codeName + '\" ?',
                    notaBene: _.i18n('confirmUnarchive.notaBene'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            },

            onDeleteByList: function () {
                var checkedRowsList = this.getCheckedRowsList();
                if (checkedRowsList && checkedRowsList.length > 0) {
                    var callback = _.bind(function (result) {
                        if (result) {
                            this.resetCheckedRowList();
                            this.jqGridDeleteByList(checkedRowsList, this.deleteByListCall)
                                .done(_.bind(function () {
                                    this.updatePagination('reloadGrid');
                                }, this));
                        }
                        this.resetCheckedRowList();
                    }, this);
                    var confirmParams = {
                        message: _.i18n('confirmDeleteSelected', null, {number: checkedRowsList.length}),
                        notaBene: _.i18n('confirmDeleteSelected.notaBene'),
                        type: 'warning'
                    };
                    CustomBootboxMessage.customConfirm(confirmParams, callback);
                }
            },

            onArchiveByList: function () {
                var checkedRowsList = this.getCheckedRowsList();
                if (checkedRowsList && checkedRowsList.length > 0) {
                    var callback = _.bind(function (result) {
                        if (result) {
                            this.resetCheckedRowList();
                            this.jqGridArchiveRestoreByList(checkedRowsList, this.archiveByListCall, true)
                                .done(_.bind(function () {
                                    this.updatePagination('reloadGrid');
                                }, this));
                        }
                        this.resetCheckedRowList();
                    }, this);
                    var confirmParams = {
                        message: _.i18n('confirmArchiveSelected', null, {count: checkedRowsList.length}),
                        notaBene: _.i18n('confirmArchiveSelected.notaBene'),
                        type: 'warning'
                    };
                    CustomBootboxMessage.customConfirm(confirmParams, callback);
                }
            },

            onUnArchiveByList: function () {
                var checkedRowsList = this.getCheckedRowsList();
                if (checkedRowsList && checkedRowsList.length > 0) {
                    this.resetCheckedRowList();
                    this.jqGridArchiveRestoreByList(checkedRowsList, this.unArchiveByListCall, false)
                        .done(_.bind(function () {
                            this.updatePagination('reloadGrid');
                        }, this));
                }
            },

            resetCheckedRowList: function () {
                this.resetAllRowSelector();
            },

            showGridUpperMenu: function () {
                this.ui.gridUpperMenu.removeClass('hidden');
            },

            HideGridUpperMenu: function () {
                this.ui.gridUpperMenu.addClass('hidden');
            },

            onExportElementsList: function () {
                var checkedRowsList = this.getCheckedRowsList();
                if (checkedRowsList && checkedRowsList.length > 0) {
                    this.exportElementsList(checkedRowsList, this.exportCall, this.exportListCall);
                }
            },

            onExportElement: function (event) {
                this.exportElement(event, this.exportCall);
            },

            onDuplicateElement: function (event) {
                this.duplicateElement(event, this.duplicateCall);
            },

            onCheckAllRows: function (e) {
                this.checkAllRows(e);
            },

            onCheckOneRow: function (e) {
                this.checkOneRow(e);
            },

            gridOptions: function (data) {
                var options = {
                    colModel: this.options.data.getJqGridColumn ? this.options.data.getJqGridColumn(this) : this.options.entity.getJqGridColumn(this),
                    sortname: this.options.entity.getJqGridSidx(this),
                    sortorder: this.options.entity.getJqGridSord(this),

                    onSelectRow: _.bind(function (rowId, bool, e) {
                        var target = $(e.target);
                        var index = this._findIndex(target);
                        if (index <= this.colModel.length) {
                            var col = this.colModel[index];
                            if (col && col.onClick) {
                                col.onClick(rowId, bool, e);
                                return;
                            }
                        }
                        if (e.target.attributes['data-navigate'] && e.type === 'click') {
                            FileExplorerController.show(rowId);
                        } else if (!target.parent().hasClass('ignore-row-click') && e.type === 'click') {
                            var model = this.options.entity.getModel({secId: rowId});
                            model.fetch().done(_.bind(function () {
                                if (target.hasClass('cell-copy')) {
                                    model.set('secId', null);
                                    model.set('code', 'Copy-' + model.get('code'));
                                }
                                this.trigger('dynamic:show', model, this.options.data.callBackCreateEditView, _.bind(this.updatePagination, this));
                            }, this));
                        } else if (target.hasClass('cell-copy-qc')) {
                            var model2 = this.options.entity.getModel({secId: rowId});
                            model2.importQCFromCurrentLot().done(function () {
                                Bootbox.alert(_.i18n('pcrKitLot.importQCFromCurrentLot'));
                            });
                        }
                    }, this)
                };
                options = _.defaults(options, this.gridInitOptions(data));
                return options;
            },

            _findIndex: function (target) {
                if (target.attr('role') !== 'gridcell') {
                    return this._findIndex(target.parent());
                }
                return target.index();
            },

            onImport: function (e) {
                if (!e || !e.target || !e.target.files || !e.target.files[0]) {
                    return;
                }
                var file = e.target.files[0];
                Bootbox.dialog({
                    title: _.i18n('import.ask.title'),
                    message: _.i18n('import.ask.message'),
                    size: 'large',
                    backdrop: true,
                    buttons: {
                        cancel: {
                            label: _.i18n('cancel'),
                            className: 'btn-danger'
                        },
                        CREATE: {
                            label: _.i18n('import.ask.create'),
                            className: 'btn-info',
                            callback: _.bind(function () {
                                this._onImport(file, 'CREATE');
                            }, this)
                        },
                        UPDATE: {
                            label: _.i18n('import.ask.update'),
                            className: 'btn-info',
                            callback: _.bind(function () {
                                this._onImport(file, 'UPDATE');
                            }, this)
                        }
                    }
                });
            },

            _onImport: function (file, type) {
                this.options.entity.importEntity(file, type)
                    .done(_.bind(function () {
                        this.ui.jqGrid.trigger('reloadGrid');
                        this.ui.importFile.val(null);
                    }, this));
            },

            onGridLoaded: function () {
                var displayArchived = this.options.displayArchived;
                var storedFilters = Settings.getFromMemory(this.findFilterName());
                if (storedFilters && storedFilters.displayArchived) {
                    displayArchived = storedFilters.displayArchived;
                }
                this.ui.displayArchived.val(displayArchived);
                this.hideMenu();
                var customView = this.options.entity.getJqGridColumnConfig().custom;
                if (customView) {
                    this.getRegion('custom').show(this.options.entity.getJqGridColumnConfig().custom);
                }
                var data = {
                    url: this.options.entity.getCollectionUrl(this.options.data.params),
                    rowCountUrl: this.options.entity.getCollectionCountUrl(this.options.data.params),
                    getJqGridColumnConfig: this.options.entity.getJqGridColumnConfig()
                };
                this.displayGrid(data);
            },
            onLoadComplete: function () {
                var events = this.options.entity.getJqGridEvents();
                _.each(events, _.bind(function (event) {
                    this.$el.find(event.filter).on('click', function (e) {
                        e.stopPropagation();
                        event.func(e.target.attributes['data-secId'].value);
                    });
                }, this));
            },
            pagination: function () {
                return {
                    first: 0,
                    rowCount: 0,
                    pageSize: this.options.data.pageSize ? this.options.data.pageSize : Settings.get('perPage'),
                    currentPage: 1
                };
            }
        }, DialogMixin));
});
