
define('template!wellContaminationsMultiDisplayTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem">\n\t<div class="pcrRunWellGrid plateView-wellBackground">\n\t\t<div style="background-color: ' +
((__t = ( wellResColor? wellResColor:'white' )) == null ? '' : __t) +
';" class="pcrRunGridResult"></div>\n\t\t<div style="height: 100%;"\n\t\t\t class="pcrRunGridAlert">\n\t\t\t<div class="col-xs-1 p-l-0 p-r-0" style="height: 33%;"></div>\n\t\t\t<div class="col-xs-10 p-l-0 p-r-0" style="height: 33%;"></div>\n\t\t\t<div class="col-xs-1 p-l-0 p-r-0" style="height: 33%;"></div>\n\n\t\t\t<div class="col-xs-1 p-l-0 p-r-0" style="height: 33%;"></div>\n\t\t\t<div class="col-xs-10 p-l-0 p-r-0" style="height: 33%; text-align: center;">\n\t\t\t\t';
 if(well.wellRes === 'P' && well.wellResNum ) { ;
__p += '\n\t\t\t\t' +
((__t = ( well.min? well.min: well.wellResNum )) == null ? '' : __t) +
'\n\t\t\t\t';
 } ;
__p += '\n\t\t\t</div>\n\t\t\t<div class="col-xs-1 p-l-0 p-r-0" style="height: 33%;"></div>\n\n\t\t\t<div class="col-xs-1 p-l-0 p-r-0" style="height: 33%;"></div>\n\t\t\t<div class="col-xs-10 p-l-0 p-r-0" style="height: 33%;"></div>\n\t\t\t<div class="col-xs-1 p-l-0 p-r-0" style="height: 33%;"></div>\n\t\t</div>\n\n\n\t</div>\n</div>';

}
return __p
};});

