
define('template!kitLotsPcrValidationView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="row">\n\t\t\t\t<div class="form-group">\n\t\t\t\t\t<div class="col-xs-4 text-right" data-field-name="validated">\n\t\t\t\t\t\t<label class="control-label">' +
((__t = ( _.i18n('kitlot.validated') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-8" data-field-name="validated">\n\t\t\t\t\t\t<input type="datepicker"\n\t\t\t\t\t\t\t   class="form-control js-datePicker inputBackground"\n\t\t\t\t\t\t\t   data-field-name="validated"\n\t\t\t\t\t\t\t   style="width: auto;"\n\t\t\t\t\t\t\t   placeholder="" value="' +
((__t = ( validated )) == null ? '' : __t) +
'">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="form-group">\n\t\t\t\t\t<div class="col-xs-4 text-right" data-field-name="validated">\n\t\t\t\t\t\t<label class="control-label">' +
((__t = ( _.i18n('kitlot.validatedBy') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-2" data-field-name="validatedBy">\n\t\t\t\t\t\t<div class="js-validationBy "></div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="js-description"></div>\n\t\t\t<div class="row cancelConfirmRow">\n\t\t\t\t<div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n\t\t\t\t\t<button disabled\n\t\t\t\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

