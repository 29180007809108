/**
 * Created by Matteo on 19/07/2015.
 */
define('router',[
	'require',
	'exports',
	'jquery',
	'module',
	'logger',
	'settings',
	'marionette',
	'app',
	'bootbox',
	'underscore',
	'loginMain',
	'autoLoginMain',
	'registerMain',
	'forgotMain',
	'resetMain',
	'thankYouMain',
	'selectCaccountMain',
	'contentMain',
	'headerMain',
	'sidebarMain',
    'superAdminMain',
	'pluginMain',
	'pcrSetupMain',
    'caccountsMain',
	'routingMain',
	'runsMain',
	'prepRunsMain',
	'menuMain',
	'translationsRouter',
	'adminMain',
	'dashboardMain'
], function(
	require,
	exports,
	$,
	module,
	Logger,
	Settings,
	Marionette,
	App,
	bootbox,
	_
) {
	'use strict';

	var Router = Marionette.AppRouter.extend({
		appRoutes: {
			'': 'showIndex',
			'unauthorized': 'showUnauthorized'
		},

		initialize: function() {
			$(window).ajaxError(function (event, request) {
				switch (request.status) {
					case 204:
						bootbox.alert(_.i18n('method.noContent') + '<br/><br/>' + request.responseText);
						break;
					//User doesn't have the correct roles
					case 403:
						Settings.set('lastUrlPageCookie',  null);
						App.trigger('unauthorized:show', request.responseText);
						break;
					//The session has expired
					case 401:
						Settings.set('lastUrlPageCookie',  App.getCurrentRoute());
						App.clearAuthCookieValues();
						App.trigger('login:show', _.i18n('sessionExpired'));
						break;
					case 405:
						bootbox.alert(_.i18n('method.notAllowed') + '<br/><br/>' + request.responseText);
						break;
					case 500:
						bootbox.alert(_.i18n('internalServerError') + '<br/><br/>' + request.responseText);
						break;
					case 503:
						window.location.href = Settings.serverUrl + 'server-unavailable.html';
						break;
					default:
						Logger.log(arguments);
				}
			});
		}
	});

	var API = {
		showUnauthorized: function(message) {
			require([ 'unauthorizedShowController' ], function ( ShowController ) {
				ShowController.showUnauthorized(message);
			});
		},

		showIndex: function() {
			require(
				['contentShowController', 'indexController'],
				function(ContentShowController, IndexController) {
					ContentShowController.showContent().done(function() {
						IndexController.show();
					} ).fail(function() {
						App.trigger('login:show');
					});
				}
			);
		}
	};

	App.on('unauthorized:show', function(message) {
		App.navigate('unauthorized');
		API.showUnauthorized(message);
	});

	App.on('index:show', function() {
		App.navigate('');
		API.showIndex();
	});

	App.on('before:start', function() {
		new Router({
			controller: API
		});
	});

	module.exports = Router;
});

