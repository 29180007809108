define('entities/admin/arrayelement',[
    'app',
    'backbone',
    'underscore',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.ArrayElement = Backbone.RelationalModel.extend({
        defaults: {
            secId: null,
            name: '',
            seq: '',
            contentType: null,
            contentTypeParam: null,
            description: null
        },
        idAttribute: 'secId',
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.ArrayElementCollection = Backbone.Collection.extend({
        model: app.ns.ArrayElement,
        comparator: 'seq'
    });

    App.reqres.setHandler('arrayListElements:collection', function (itemJSON) {
        var collection = new app.ns.ArrayElementCollection(itemJSON);
        collection.comparator = function (a, b) {
            if (a.get('seq') === '') {
                return 1;
            }
            return a.get('seq') < b.get('seq');
        };
        collection.sort();
        return collection;
    });

    App.reqres.setHandler('arrayListElements:model', function (itemJSON) {
        return app.ns.ArrayElement.findOrCreate(itemJSON);
    });

    module.exports = _.defaults({
        getName: function () {
            return 'arrayelement';
        },
        getDynamicJson: function () {
            return 'ArrayElementJson';
        },
        getCreateEditLine: function (model) {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-15 p-l-2 p-r-2'}}},
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 p-l-2 p-r-2'}}},
                {'field': 'seq', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-5 p-l-2 p-r-2'}}},
                {
                    'field': 'contentType',
                    'param': {
                        'type': 'CODELIST', 'code': 'ARRAYELTYPE', 'display': {'class': 'col-w-5 p-l-2 p-r-2'}
                    }
                },
                {
                    'field': 'contentTypeParam',
                    'dependsOn': {
                        'model': model,
                        'field': 'contentType',
                        enable: function (model) {
                            var ret = model.get('contentType') === 'CL' || model.get('contentType').startsWith('L2L');
                            if (!ret) {
                                model.set({'contentTypeParam': ''}, {silent: true});
                            }
                            return ret;
                        }
                    },
                    defaultParam: {
                        'display': {'class': 'col-w-10 p-l-2 p-r-2'}
                    },
                    'param': [{
                        'fieldNameKey': 'contentType',
                        'fieldNameValue': 'CL',
                        'type': 'CODELIST', 'showAll': true,
                        'display': {'class': 'col-w-10 p-l-2 p-r-2'}
                    }, {
                        'fieldNameKey': 'contentType',
                        'fieldNameValue': 'L2L-E',
                        'type': 'CODELIST', 'code': 'ARRAY-L2L-E',
                        'display': {'class': 'col-w-10 p-l-2 p-r-2'}
                    }]
                },
                {'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-45 p-l-2 p-r-2'}}}
            ];
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.ArrayElement.findOrCreate(modelJson);
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        }
    }, Dynamic);

});
