
define('template!prepWellToolTipView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n\t<div class="row" style="height: 22px; color: white;">\n\t\t<div class="col-xs-2" style="bottom: 5px; display: flex;">\n\t\t\t';
 if (smpType === 'NC') { ;
__p += ' <!-- smpType === \'NC\' -->\n\t\t\t<span class="mdi-content-remove-circle-outline pcrTooltip-smpType-icon"\n\t\t\t\t  style="font-size: 22px; position: relative;"></span>\n\t\t\t';
 } else if(smpType === 'PC') { ;
__p += ' <!-- smpType === \'PC\' -->\n\t\t\t<span class="mdi-content-add-circle-outline pcrTooltip-smpType-icon"\n\t\t\t\t  style="font-size: 22px; position: relative;"></span>\n\t\t\t';
 } else if(smpType === 'RC' ) { ;
__p += ' <!-- smpType === \'RC\' -->\n\t\t\t<span class="iconify" style="font-size: 22px; position: relative;"\n\t\t\t\t  data-icon="mdi-registered-trademark"></span>\n\t\t\t';
 } else if(smpType === 'C') { ;
__p += ' <!-- smpType === \'C\' -->\n\t\t\t<span class="iconify" style="font-size: 22px; position: relative;"\n\t\t\t\t  data-icon="ant-design:copyright-circle-outlined"></span>\n\t\t\t';
 } else if(smpType === 'P') { ;
__p += ' <!-- smpType === \'P\' Pool -->\n\t\t\t<span class="iconify" style="font-size: 22px; position: relative;"\n\t\t\t\t  data-icon="mdi:google-circles-communities"></span>\n\t\t\t';
 } else if(smpType === 'OC') { ;
__p += '\n\t\t\t<span class="iconify" style="font-size: 22px; position: relative;"\n\t\t\t\t  data-icon="ant-design:compass-outlined"></span>\n\t\t\t';
 } else if(smpType === 'D') { ;
__p += '\n\t\t\t<span class="iconify" style="font-size: 22px; position: relative;"\n\t\t\t\t  data-icon="fa6-solid:d"></span>\n\t\t\t';
 } else if(smpType === 'C') { ;
__p += '\n\t\t\t<span class="iconify" style="font-size: 22px; position: relative;"\n\t\t\t\t  data-icon="fa6-solid:c"></span>\n\t\t\t';
 } else if(smpType === 'U') { ;
__p += '\n\t\t\t';
 } ;
__p += '\n\t\t\t';
 if (repeatStatus) { ;
__p += '\n\t\t\t<div style="height: 22px; width: 22px;">\n\t\t\t\t<div class="repeatStatus repeat-' +
((__t = ( repeatStatus )) == null ? '' : __t) +
'"></div>\n\t\t\t</div>\n\t\t\t';
 } ;
__p += '\n\t\t\t';
 if(pooled) { ;
__p += '\n\t\t\t<span class="iconify" style="font-size: 22px; position: relative;"\n\t\t\t\t  data-icon="ic:outline-adjust"></span>\n\t\t\t';
 } ;
__p += '\n\t\t</div>\n\t\t<div class="col-xs-2">\n\t\t\t' +
((__t = ( pos )) == null ? '' : __t) +
'\n\t\t</div>\n\t\t<div class="col-xs-6" style="overflow: hidden;">\n\t\t\t' +
((__t = ( smpId )) == null ? '' : __t) +
'\n\t\t</div>\n\t\t<div class="col-xs-2 ' +
((__t = ( concatComment ? 'commentWell' : '')) == null ? '' : __t) +
'">\n\t\t\t' +
((__t = ( concatComment )) == null ? '' : __t) +
'\n\t\t</div>\n\t</div>\n\n\t<div class="m-t-0 m-b-5" style="border-bottom: 1px solid;"></div>\n\n\t<div class="col-xs-12 p-l-0 p-r-0 m-b-10">\n\t\t<div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n\t\t\t<div class="col-w-all f-l d-f col-w-100 noWrapHidden">\n\t\t\t\t<div class="col-w-all f-l d-f col-w-5">' +
((__t = ( assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-w-all f-l d-f col-w-95" style="margin: 2px 0 0 5px;">' +
((__t = (
					assaysToDisplay.waiting )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\n\t<div class="col-xs-12 p-l-0 p-r-0 m-b-10">\n\t\t<div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n\t\t\t<div class="col-w-all f-l d-f col-w-100 noWrapHidden">\n\t\t\t\t<div class="col-w-all f-l d-f col-w-5">' +
((__t = ( assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-w-all f-l d-f col-w-95" style="margin: 2px 0 0 5px;"> ' +
((__t = (
					assaysToDisplay.running )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\n\t<div class="col-xs-12 p-l-0 p-r-0 m-b-10">\n\t\t<div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n\t\t\t<div class="col-w-all f-l d-f col-w-100 noWrapHidden">\n\t\t\t\t<div class="col-w-all f-l d-f col-w-5">' +
((__t = ( assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-w-all f-l d-f col-w-95" style="margin: 2px 0 0 5px;">' +
((__t = (
					assaysToDisplay.done )) == null ? '' : __t) +
'\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\n\t';
 if (wellsPool && wellsPool.length > 0) { ;
__p += '\n\t<div class="col-xs-12 m-b-10 p-t-5" style="border: solid 1px; border-radius: 8px;">\n\t\t';
 wellsPool.sort((a,b) => (a.runName > b.runName) ? 1 : ((b.runName > a.runName) ? -1 : 0));
__p += '\n\t\t';
 _.each(wellsPool, function(well) { ;
__p += '\n\t\t<div class="m-b-0 col-xs-12">\n\t\t\t<label class="col-xs-5 noWrapHidden">' +
((__t = ( well.runName )) == null ? '' : __t) +
'</label>\n\t\t\t<label class="col-xs-2 noWrapHidden">' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n\t\t\t<label class="col-xs-4 noWrapHidden">' +
((__t = ( well.smpId.name )) == null ? '' : __t) +
'</label>\n\t\t</div>\n\t\t';
 }); ;
__p += '\n\t</div>\n\t';
 } ;
__p += '\n\n\t';
 if (wellsPooled && wellsPooled.length > 0) { ;
__p += '\n\t<div class="col-xs-12 m-b-10 p-t-5" style="border: solid 1px; border-radius: 8px;">\n\t\t';
 wellsPooled.sort((a,b) => (a.runName > b.runName) ? 1 : ((b.runName > a.runName) ? -1 : 0));
__p += '\n\t\t';
 _.each(wellsPooled, function(well) { ;
__p += '\n\t\t<div class="m-b-0 col-xs-12">\n\t\t\t<label class="col-xs-6 noWrapHidden">' +
((__t = ( well.runName )) == null ? '' : __t) +
'</label>\n\t\t\t<label class="col-xs-2 noWrapHidden">' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n\t\t\t<label class="col-xs-4 noWrapHidden">' +
((__t = ( well.smpId.name )) == null ? '' : __t) +
'</label>\n\t\t</div>\n\t\t';
 }); ;
__p += '\n\t</div>\n\t';
 } ;
__p += '\n</div>';

}
return __p
};});

