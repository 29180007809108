define('superAdminDBDocumentationView',[
	'module',
	'marionette',
	'underscore',
	'jquery',
	'backbone',
	'app',
	'settings',
	'jqgridView',
	'template!superAdminDBDocumentationTpl',
	'superAdminDBDocumentationDetailView',
	'entities/admin/jsonobjectdocumentation',
	'entities/admin/jsonpropertydocumentation'
], function (
	module,
	Marionette,
	_,
	$,
	Backbone,
	App,
	Settings,
	JqGridView,
	viewTpl,
	DBDocumentationDetailView
) {
	'use strict';

	module.exports = JqGridView.extend({
		template: viewTpl,
		filtersName: 'applicationObjectListFilters',

		triggers: {},

		ui: function () {
			return _.extend({
				jqGrid: '#jq-grid-list',
				jqGridPager: '#jq-grid-pager-list',
				updateButton: '.js-update-button',
				loader: '.empty-page-loader',
				deleteJsonObjectDocumentation: '.delete-jsonobjectdocumentation-link',
				viewDocument: '.view-document-link'
			}, this.gridUi);
		},
		events: function () {
			return _.extend({
				'click @ui.viewDocument': 'onViewDocument',
				'click @ui.updateButton': 'onUpdateJsonDocumentation',
				'click @ui.deleteJsonObjectDocumentation': 'onDeleteJsonObjectDocumentation'
			}, this.gridEvents);
		},
		serializeData: function () {
			var templateData = {type: 'kitprots'};
			return templateData;
		},

		onViewDocument: function (event) {
			var url = Settings.url('compuzz', 'public/document/getfileByName/' + 'TB_' + $(event.currentTarget).parent().find('td')[1].title + '.pdf');
			window.open(url, 'documentation', 'width=' + window.outerWidth * 0.6 + ',height=' + window.outerWidth * 0.7);
		},
		gridOptions: function (data) {
			var options = {
				colModel: [
					{
						label: '',
						name: 'secId',
						key: true,
						hidden: true
					}, {
						label: _.i18n('common.internalName'),
						name: 'internalName',
						formatter: this.defaultFormatter,
						search: true,
						index: 'internalName',
						width: '50px'
					}, {
						label: _.i18n('common.name'),
						name: 'name',
						formatter: this.defaultFormatter,
						search: true,
						index: 'name',
						width: '35px'
					}, {
						label: _.i18n('common.description'),
						name: 'description',
						formatter: this.defaultFormatter,
						search: false,
						index: 'description',
						width: '75px'
					}, {
						label: ' ',
						name: 'view',
						search: false,
						classes: 'view-document-link view-link ',
						formatter: this.viewIconFormatter,
						width: '20px'
					}, {
						label: ' ',
						name: 'delete',
						search: false,
						classes: 'delete-jsonobjectdocumentation-link delete-link',
						formatter: this.deleteIconFormatter,
						width: '10px'
					}
				],

				onSelectRow: function (rowId, bool, e) {
					if (!$(e.target).hasClass('cell-delete') && !$(e.target).hasClass('cell-view') && e.type === 'click') {
						if (e.type === 'click') {
							var model = App.request('jsonobjectdocumentation:model', {secId: rowId});
							model.fetch().done(function () {
									var detailsView = new DBDocumentationDetailView({
										collection: App.request('jsonpropertydocumentation:collection', model.get('jsonPropertyDocumentationJsons')),
										model: model
									});
									detailsView.show({
										title: _.i18n('menu.superadmin.jsondocumentation'),
										generatePdfButton: true,
										className: 'baseTableEditAddPopup'
									});
								}
							);
						}
					}
				},
				sortName: 'internalName'
			};
			options = _.defaults(options, this.gridInitOptions(data));
			return options;
		},
		onUpdateJsonDocumentation: function () {
			this.ui.loader.show();
			this.triggerMethod('jsondocumentation:update');
		},
		onDeleteJsonObjectDocumentation: function (event) {
			this.trigger('jsondocumentation:delete', $(event.currentTarget).find('span').attr('data-row-id'));
		}
	});
});

