define('entities/caccounts/pcrruntracs',[
	'app',
	'backbone',
	'settings'
], function (
	App,
	Backbone,
	Settings
) {
	'use strict';

	var PcrRunTrac = Backbone.Model.extend({
		fetch: function () {
			this.url = Settings.url('compuzz', 'v2/runtracs/pcr/' + this.get('secId'));

			return Backbone.Model.prototype.fetch.call(this);
		},
		defaults: {
			'creatDate': null,
			'refUserpid': {'nickname': ''},
			'action': '',
			'level': '',
			'comment': '',
			'target': 0,
			'wells': [''],
			'refRun': {'name': ''}
		},
		idAttribute: 'secId',

		postUrl: function () {
			return Settings.url('compuzz', 'v2/runtracs/pcr');
		},

		save: function () {
			this.url = this.postUrl();
			return Backbone.Model.prototype.save.call(this);
		},
		getPDF:function() {
			return Settings.getDocUrl('PcrRunTracJson.pdf');
		}
	});

	var PcrRunTracCollection = Backbone.Collection.extend({
		model: PcrRunTrac
	});

	App.reqres.setHandler('trac:pcr:get-collection-url', function (runSecId, position) {
		var params = {};
		if (runSecId) {
			params.runSecId = runSecId;
		}
		if (position) {
			params.position = position;
		}
		return Settings.url('compuzz', 'v2/runtracs/pcr', params);
	});

	App.reqres.setHandler('trac:pcr:get-count-url', function (runSecId, position) {
		var params = '';
		if (runSecId) {
			params += 'runSecId=' + runSecId + '&';
		}
		if (position) {
			params += 'position=' + position + '&';
		}
		return 'v2/runtracs/pcr/rowCount?' + params;
	});

	App.reqres.setHandler('trac:pcr:collection', function (runJSON) {
		return new PcrRunTracCollection(runJSON);
	});

	App.reqres.setHandler('trac:pcr:model', function (runJSON) {
		return new PcrRunTrac(runJSON);
	});
});
