define('pcrSetupPcrWellItemHeader',[
	'module',
	'marionette',
	'template!pcrSetupPcrWellItemHeader'
], function (
	module,
	Marionette,
	Tpl
) {
	'use strict';

	module.exports = Marionette.ItemView.extend({
		template: Tpl,

		attributes: function () {
			return {style: 'width: ' + this.options.width + '%;height: ' + this.options.width + '%;'};
		},
		className: 'pcr',
		ui: {
			input: '.js-info-input',
			well: '.well'
		},
		type: 'header',

		events: {},
		serializeData: function () {
			var templateData = {};
			templateData.width = this.options.width;
			templateData.pos = this.model.get('pos');
			templateData.name = this.model.get('name');
			return templateData;
		}
	});
});
