
define('template!prepRunExtractionStop', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container container-fluid" style="width: 500px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="col-xs-12">\n                <div class="form-group" data-field-name="refExtractionMethod">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('common.lmbExtractor') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-4 js-select-extractor-region" data-field-name="refLmbExtractor"></div>\n                </div>\n\n                <div class="form-group" data-field-name="endUserpid">\n                    <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.extractionUser') )) == null ? '' : __t) +
'</label>\n                    <div class="col-xs-4 js-select-respUser-region" data-field-name="endUserpid"></div>\n                </div>\n\n                <div class="form-group commentOrDescriptionContainer m-t-20">\n                    <div class="col-xs-1 mdi-editor-insert-comment commentOrDescriptionIcon"\n                         style="font-size: 40px;">\n                    </div>\n                    <div class="commentOrDescriptionInputDiv col-xs-11">\n                                    <textarea class="form-group floating-label js-info-input"\n                                              style="margin: 0; width:inherit; resize: vertical; background-color: white;"\n                                              rows="3" wrap="on" data-field-name="comment"\n                                              placeholder="">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n                    </div>\n                </div>\n            </div>\n            <!-- cancel/confirm row -->\n            <div class="row cancelConfirmRow" style="text-align: center;">\n                <div class="pull-right p-r-15 col-xs-12 cancelConfirmAssayAddEditDiv">\n                    <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n                    <button disabled\n                            class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

