
define('template!miniPlateViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-1" style="width: 3%;">\n        <div class="row m-b-0 clickable">\n            <div class="wellTableMiniCornerTitle">\n                ';
 if(isMultiDisplay) { ;
__p += '\n                <div class="">\n                    <div class="col-xs-6 js-top-left p-l-0 p-r-0 dot ' +
((__t = ( firstWellPos === 'A01' && selected ? 'dot-selected' : '' )) == null ? '' : __t) +
'"></div>\n                    <div class="col-xs-6 js-top-right p-l-0 p-r-0 dot ' +
((__t = ( firstWellPos === 'A02' ? 'dot-selected' : '' )) == null ? '' : __t) +
'"></div>\n                </div>\n                <div class="">\n                    <div class="col-xs-6 js-bottom-left p-l-0 p-r-0 dot ' +
((__t = ( firstWellPos === 'B01' ? 'dot-selected' : '' )) == null ? '' : __t) +
'"></div>\n                    <div class="col-xs-6 js-bottom-right p-l-0 p-r-0 dot ' +
((__t = ( firstWellPos === 'B02' ? 'dot-selected' : '' )) == null ? '' : __t) +
'"></div>\n                </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        ';
 _.each(rows, function(row) {;
__p += '\n        <div class="row m-b-0 clickable">\n            <div class="wellTableMiniLineTitleItem col-xs-12 text-center">' +
((__t = ( row )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 }) ;
__p += '\n    </div>\n    <div class="col-xs-11 clickable miniPlate" style="width: 89%;">\n        <div class="row m-b-0">\n            ';
 _.each(columns, function(column) {;
__p += '\n            <div class="wellTableMiniColumnTitleItem text-center"\n                 style="width: ' +
((__t = ( width )) == null ? '' : __t) +
'%; float: left;">' +
((__t = ( ("0" + column).slice(-2) )) == null ? '' : __t) +
'\n            </div>\n            ';
 }) ;
__p += '\n        </div>\n        ';
 _.each(rows, function(row) {;
__p += '\n        <div class="row m-b-0">\n            ';
 _.each(columns, function(column) {
            var pos = row + ("0" + column).slice(-2); ;
__p += '\n            <div class="wellTableMiniItem tbl-' +
((__t = ( pos )) == null ? '' : __t) +
'" style="width: ' +
((__t = ( width )) == null ? '' : __t) +
'%; float: left;"></div>\n            ';
 }) ;
__p += '\n        </div>\n        ';
 }) ;
__p += '\n    </div>\n    <div class="loader loading-block empty-page-loader" style="top: calc(50% - 20px);">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

