define('pcrWellResultSPOOvarItemView',[
    'module',
    'marionette',
    'template!pcrWellResultSPOOvarItemView',
    'underscore',
    'jquery',
    'editWellView',
    'entities/caccounts/mbanaresgrs'
], function (
    module,
    Marionette,
    WellResultTpl,
    _,
    $,
    EditWellView
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: WellResultTpl,

        ui: {
            input: '.js-info-input'
        },

        events: {},

        modelEvents: {
            'change': 'renderSafe'
        },

        attributes: {
            style: 'display: flex;'
        },
        className: 'result-spo-ovar',

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        },

        serializeData: function () {
            var targetsResultIcons = this.model.get('refPcrWell').getTargetsResultIcons();
            var templateData = _.findWhere(targetsResultIcons, {'targetCode': this.model.get('mbAna').get('code')});
            templateData = _.extend(templateData, {
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : undefined
            });

            templateData.propC = this.model.getPropCFormated();

            templateData.userVal1Code = '';
            templateData.userVal1Title = '';
            if (this.model.get('refValUser')) {
                templateData.userVal1Code = this.model.get('refValUser').get('nickName');
                if (this.model.get('refValUser').get('email')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('email');
                }
                if (templateData.userVal1Code === '' && templateData.userVal1Title) {
                    templateData.userVal1Code = templateData.userVal1Title;
                }
                if (this.model.get('refValUser').get('firstName') && this.model.get('refValUser').get('lastName')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('firstName') + ' ' + this.model.get('refValUser').get('lastName');
                }
            }
            templateData.userVal2Code = '';
            templateData.userVal2Title = '';
            if (this.model.get('refValUser2')) {
                templateData.userVal2Code = this.model.get('refValUser2').get('nickName');
                if (this.model.get('refValUser2').get('email')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('email');
                }
                if (templateData.userVal2Code === '' && templateData.userVal2Title) {
                    templateData.userVal2Code = templateData.userVal2Title;
                }
                if (this.model.get('refValUser2').firstName && this.model.get('refValUser2').get('lastName')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('firstName') + ' ' + this.model.get('refValUser2').get('lastName');
                }
            }

            templateData.errorMessage = '';
            if (templateData.codeErr) {
                templateData.errorMessage = templateData.codeErr + ' ';
            }
            if (templateData.justificatory) {
                templateData.errorMessage = templateData.justificatory;
            }

            return templateData;
        },

        showEditWellView: function () {
            this.createEditView = new EditWellView({
                model: this.model.get('refPcrWell'),
                modelResult: this.model,
                wellResColor: this.wellResColor,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.info.edit'),
                className: 'popupWellView'
            });
        }
    });
});
