
define('template!prepRunEditImportFromOtherRunLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n    <div class="error-msg"></div>\n</div>\n<div class="form-horizontal">\n    <div class="form-inputs-container importFromOtherRunPopup t-a-c">\n        <div class="form-group">\n            <label class="col-xs-4 popupTitleLabel t-a-r" for="prepRunList">' +
((__t = (
                _.i18n('run.prep.importPlateLayoutFrom.sourceRun') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-8 js-select-prepRunList-region" id="prepRunList"></div>\n        </div>\n\n        <div class="form-group">\n            <label class="col-xs-2 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                _.i18n('run.prep.importPlateLayoutFrom.sourcePosFrom') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <input type="text" class="js-info-input" data-field-name="sourceStart"\n                       style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                       data-placement="top"\n                       onkeyup="this.value = this.value.toUpperCase();">\n            </div>\n            <label class="col-xs-2 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                _.i18n('run.prep.importPlateLayoutFrom.sourcePosTo') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <input type="text" class="js-info-input" data-field-name="sourceEnd"\n                       style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                       data-placement="top"\n                       onkeyup="this.value = this.value.toUpperCase();">\n            </div>\n            <label class="col-xs-2 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                _.i18n('run.prep.importPlateLayoutFrom.sourceDirection') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <div class="js-source-plate-direction-region"></div>\n            </div>\n        </div>\n\n        <div class="form-group">\n            <label class="col-xs-6 popupTitleLabel t-a-r" for="copySampleTypeCheckbox">' +
((__t = (
                _.i18n('platelayout.copy.sample.type')
                )) == null ? '' : __t) +
'</label>\n            <input class="col-xs-1 controlSidCheckBox js-info-input" data-field-name="copySampleType"\n                   id="copySampleTypeCheckbox"\n                   style="transform: scale(2);"\n                   type="checkbox" checked/>\n        </div>\n        <div class="form-group">\n            <label class="col-xs-6 popupTitleLabel t-a-r" for="copySampleIdsCheckbox">' +
((__t = (
                _.i18n('platelayout.copy.sample.ids')
                )) == null ? '' : __t) +
'</label>\n            <input class="col-xs-1 controlSidCheckBox js-info-input" data-field-name="copySampleId"\n                   id="copySampleIdsCheckbox"\n                   style="transform: scale(2);"\n                   type="checkbox" checked/>\n        </div>\n        <div class="form-group">\n            <label class="col-xs-6 popupTitleLabel t-a-r" for="copyAssaysCheckbox">' +
((__t = (
                _.i18n('platelayout.copy.assays.ids')
                )) == null ? '' : __t) +
'</label>\n            <input class="col-xs-1 controlSidCheckBox js-info-input" data-field-name="copyAssay" id="copyAssaysCheckbox"\n                   style="transform: scale(2);"\n                   type="checkbox" checked/>\n        </div>\n        <div class="form-group">\n            <label class="col-xs-2 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                _.i18n('run.prep.importPlateLayoutFrom.targetPosFrom') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <input type="text" class="js-info-input" data-field-name="targetStart"\n                       style="width: 45px; border: solid 1px #cccccc; margin: 0 5px 0 5px;"\n                       data-placement="top"\n                       onkeyup="this.value = this.value.toUpperCase();">\n            </div>\n            <div class="col-xs-4"></div>\n            <label class="col-xs-2 popupTitleLabel noWrapHidden" style="text-align: right;">' +
((__t = (
                _.i18n('run.prep.importPlateLayoutFrom.targetDirection') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <div class="js-target-plate-direction-region"></div>\n            </div>\n        </div>\n\n        <div class="row enterEditPlateLayout-buttons" style="display: flex; justify-content: center;">\n            <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n            <button class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-import-fromOtherRun-button"></button>\n            <!-- js-confirm -->\n        </div>\n    </div>\n</div>\n<div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>';

}
return __p
};});

