define('pcrRunTracsView',[
    'module',
    'app',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!pcrRunTracsTpl',
    'tracsView'
], function (
    module,
    App,
    _,
    $,
    DialogFormView,
    PcrRunTracsTpl,
    TracsView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: PcrRunTracsTpl,

        regions: {
            pcrRunTracsRegion: '.js-pcrRun-tracs-region'
        },

        onShow: function () {
            $.material.init();
            this.view = new TracsView({
                model: this.options.model
            });
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.getRegion('pcrRunTracsRegion').show(this.view);
        },

        onGridLoaded: function () {
            var data;
            var url = App.request('trac:pcr:get-collection-url', this.model.get('secId'));
            var rowCountUrl = App.request('trac:pcr:get-count-url', this.model.get('secId'));
            if (this.options.type === 'wellTracks') {
                url = App.request('trac:pcr:get-collection-url', this.model.get('runSecId'), this.model.get('pos'));
                rowCountUrl = App.request('trac:pcr:get-count-url', this.model.get('runSecId'), this.model.get('pos'));
            }
            data = {
                url: url,
                rowCountUrl: rowCountUrl
            };
            this.view.displayGrid(data);
        }
    });
});
