define('assayResultVersion',[
    'module',
    'marionette',
    'template!assayResultVersion',
    'underscore',
    'jquery',
    'backbone',
    'autocompleteView',
    'dialogFormView',
    'entities/caccounts/mbanas',
    'arrayCustomCurveView',
    'arrayCriCurveView',
    'arrayCustomAssayResultView',
    'arrayInputView',
    'dynamicCreateEditTypeParam',
    'entities/admin/codelist',
    'assayResultTargetValuesVersionCollection'
], function (
    module,
    Marionette,
    Tpl,
    _,
    $,
    Backbone,
    AutocompleteView,
    DialogFormView,
    MbAnas,
    ArrayCustomCurveView,
    ArrayCriCurveView,
    ArrayCustomAssayResultView,
    ArrayInputView,
    TypeParamView,
    CodeLists,
    AssayResultTargetValuesVersionCollection
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            selectAlgoResult: '.js-algoResult',
            selectAlgoValidation: '.js-algoValidation',
            selectAlgoExport: '.js-algoExport',
            targetValues: '.js-targetValues'
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.secId = this.model.get('secId') ? this.model.get('secId') : '';
            return templateData;
        },

        onRender: function () {
            $.material.init();

            this.renderAlgoResult();

            var selectAlgoValidationtView = new TypeParamView({
                fieldName: 'algoValidation',
                model: this.model,
                getAutocompleteParam: CodeLists.getAutocompleteParamForCode({codeListCode: 'ALGO-VALAUT'}),
                readOnly: true
            });
            this.getRegion('selectAlgoValidation').show(selectAlgoValidationtView);

            var selectAlgoExportView = new TypeParamView({
                fieldName: 'algoExport',
                model: this.model,
                getAutocompleteParam: CodeLists.getAutocompleteParamForCode({codeListCode: 'ALGOTEXPORT'}),
                readOnly: true
            });
            this.getRegion('selectAlgoExport').show(selectAlgoExportView);
            var type = 'IC';
            if(['PC', 'NC', 'OC', 'RC'].includes(this.options.targetValueFiltered)) {
                type = 'QC-' + this.options.targetValueFiltered;
            }
            this.getRegion('targetValues').show(new AssayResultTargetValuesVersionCollection({
                collection: new Backbone.Collection(this.model.get('targetValues'))
            }));
        },
        renderAlgoResult: function () {
            if (this.getRegion('selectAlgoResult')) {
                this.getRegion('selectAlgoResult').destroy();
            }
            var selectAlgoResultView = new TypeParamView({
                fieldName: 'algoResult',
                model: this.model,
                getAutocompleteParam: CodeLists.getAutocompleteParamForCode({codeListCode: 'ALGO-RES'}),
                customInput: _.bind(this.onShowAlgoRes, this),
                display: {
                    type: {
                        'class': 'col-w-20'
                    },
                    param: {
                        'class': 'col-w-80'
                    }
                },
                readOnly: true
            });
            this.getRegion('selectAlgoResult').show(selectAlgoResultView);
        },

        onShowAlgoRes: function () {
            switch (this.model.get('algoResult').type) {
                case 'MUTV':
                case 'AMP':
                    return new ArrayCustomCurveView({
                        model: this.model,
                        cyclerPublicSecId: this.options.cyclerPublicSecId,
                        readOnly: true
                    });
                case 'CRI':
                    return new ArrayCriCurveView({
                        model: this.model,
                        cyclerPublicSecId: this.options.cyclerPublicSecId,
                        readOnly: true
                    });
                case 'COMB':
                case 'COMBCUTOFF':
                case 'STD':
                    return new ArrayCustomAssayResultView({
                        model: this.model,
                        fieldName: 'algoResult',
                        fieldNameCode: this.model.get('algoResult').type,
                        readOnly: true
                    });
                default:
                    return new ArrayCustomAssayResultView({
                        model: this.model,
                        fieldName: 'algoResult',
                        fieldNameCode: this.model.get('algoResult').type,
                        readOnly: true
                    });
            }
        }
    });
});
