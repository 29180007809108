
define('template!dynamicCreateEditText', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<input type="text" class="form-control js-info-input inputBackground"\n\t   data-field-name="' +
((__t = ( field )) == null ? '' : __t) +
'" placeholder=""\n\t   value="' +
((__t = ( value )) == null ? '' : __t) +
'" autocomplete="off" ' +
((__t = ( readOnly ? 'disabled="disabled"' : '' )) == null ? '' : __t) +
'>\n';

}
return __p
};});

