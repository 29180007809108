
define('template!dynamicCreateEditCustomSampleList', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-4 js-info-input p-l-0" data-field-name="' +
((__t = ( tpl.fieldCustom )) == null ? '' : __t) +
'">\n\t<div class="js-select-service"></div>\n</div>\n<div class="col-xs-8 p-r-0">\n\t<input type="text" class="form-control js-info-input js-input-fieldCustom"\n\t\t   data-field-name="' +
((__t = ( tpl.fieldCustom )) == null ? '' : __t) +
'"\n\t\t   value="' +
((__t = ( model[tpl.fieldCustom] )) == null ? '' : __t) +
'" autocomplete="off">\n</div>';

}
return __p
};});

