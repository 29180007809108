define('samplePrepWellCollection',[
	'module',
	'backbone',
	'marionette',
	'samplePrepWellView',
    'template!samplePrepWellCollection'
], function (
	module,
	Backbone,
	Marionette,
	view,
    Tpl
) {
	'use strict';


	module.exports = Marionette.CompositeView.extend({
        template: Tpl,
		childView: view,

		childViewOptions: {
			wellTargetSecID: view.secId
		},

		collection: new Backbone.Collection()
	});
});
