/**
 * Created by OBL on 14/01/2016.
 */
define('errorController',[
    'module',
    'backbone',
    'underscore',
    'errorView',
    'entities/errors'
], function (
    module,
    Backbone,
    _,
    ViewError,
    Errors
) {
    'use strict';

    module.exports = {
        showE: function (entity, errors) {
            var model = Errors.getModel(errors);
            var errorView = new ViewError({
                errors: model.get('errors'),
                model: entity
            });
            errorView.show({
                title: _.i18n('error'),
                className: 'baseTableEditAddPopup ' + 'ListPopup'
            });
        }
    };
});
