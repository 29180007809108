define('entities/caccounts/reports',[
	'app',
	'backbone',
	'settings',
	'jquery',
	'underscore',
	'module',
	'dynamic',
	'entities/caccounts/reportProperties'
], function (
	App,
	Backbone,
	Settings,
	$,
	_,
	module,
	Dynamic
) {
	'use strict';

	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.Report = Backbone.RelationalModel.extend({
		fetch: function () {
			this.url = Settings.url('compuzz', 'v2/reports/' + this.get('secId'));

			return Backbone.Model.prototype.fetch.call(this);
		},
		defaults: {
			'code': undefined,
			'name': undefined,
			'type': undefined,
			'fileName': undefined,
			'description': undefined,
			'properties': []
		},
		initialize: function () {
			this.set('tags', []);
			this.set('properties', []);
		},
		idAttribute: 'secId',
		relations: [{
			type: Backbone.HasMany,
			key: 'properties',
			relatedModel: 'ReportProperty',
			collectionType: 'ReportPropertyCollection',
			reverseRelation: {
				key: 'report'
			}
		}],
		getImageUid: function () {
		},

		postUrl: function () {
			return Settings.url('compuzz', 'v2/reports');
		},

		save: function () {
			this.url = this.postUrl();
			return Backbone.Model.prototype.save.call(this);
		},

		jsonObjectName: 'ReportJson',
		getPDF: function () {
			return Settings.getDocUrl(this.jsonObjectName + '.pdf');
		},

		jsonObject: 'Report',
		getEntity: function () {
			return module.exports;
		},
		importable: false,
		creatable: false,
		print: function (label) {
			var url = Settings.url('compuzz', 'v2/reports/' + this.get('secId') + '/print'),
				defer = $.Deferred();
			$.ajax({
				method: 'POST',
				url: url,
				contentType: 'application/json',
				data: label,
				success: function () {
					defer.resolve();
				},
				error: function (response) {
					defer.reject(response);
				}
			});
			return defer.promise();
		}
	});

	module.exports = _.defaults({
		getName: function () {
			return 'report';
		},
		getUrl: function () {
			return 'v2/reports';
		},
		getDynamicJson: function () {
			return 'ReportJson';
		},
		getJqGridColumn: function (viewDynamic) {
			var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
			columns.push(
				{
					label: _.i18n('report.type'),
					name: 'type',
					formatter: viewDynamic.codeNameFormatter,
					search: true,
					index: 'type',
					width: 40
				}, {
					label: _.i18n('report.fileName'),
					name: 'fileName',
					formatter: viewDynamic.codeNameFormatter,
					search: true,
					index: 'fileName',
					width: 40
				});
			return columns;
		},

		getModel: function (modelJson) {
			if (!modelJson) {
				modelJson = {};
			}
			return app.ns.Report.findOrCreate(modelJson);
		},
		getFieldsToDisplay: function () {
			return {'fieldsWidth': 400, 'fields': ['name', 'type']};
		},
		getCreateEditFieldIgnore: function () {
			var ignored = Dynamic.getCreateEditFieldIgnore();
			ignored.push('code', 'name', 'tags', 'type');
			return ignored;
		},
		getJqGridColumnConfig: function () {
			var config = Dynamic.getJqGridColumnConfig();
			config.archivable = false;
			config.selectArchivable = false;
			config.deletable = false;
			config.attachmentable = false;
			config.creatable = false;
			return config;
		},
		getCreateEditFieldCustom: function (model) {
			return [
				{
					'field': 'fileName',
					'dependsOn': {
						'model': model,
						'field': 'type'
					},
					'param': {
						'type': 'AUTOCOMPLETE_SYNC',
						'config': {'getAutocompleteParam': _.bind(this.getAutocompleteParamByType, this)}
					}
				}
			];
		},
		getAutocompleteParamByType: function (config, context, model) {
			var defer = $.Deferred();
			config = {
				modelProperty: 'fileName',
				valueProperty: 'name',
				queryProperty: 'name',
				valueKey: 'name',
				url: this.getUrl() + '/type/' + (model.get('type') ? model.get('type').code : ''),
				canAddOrEdit: false
			};
			if (!context) {
				context = this;
			}
			config = Dynamic.getAutocompleteParam(config, context);
			var url = Settings.url('compuzz', 'v2/reports/type/' + (model.get('type') ? model.get('type').code : ''));

			$.ajax({
				method: 'GET',
				url: url,
				contentType: 'application/json',
				success: function (data) {
					config.data = data;
					defer.resolve(config);
				},
				error: function (response) {
					defer.reject(response);
				}
			});
			return defer.promise();
		}
	}, Dynamic);
});
