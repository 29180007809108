
define('template!browserNotCompatibleTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n    <div class="well login-form-container">\n        <form target="hidden_iframe" autocomplete="on" class="form-horizontal js-form-submit form-group">\n            <fieldset>\n                <div class="top-login-part text-center">\n                    <a class="login-view-link">\n                        <div class="avatar-view-link-img"></div>\n                    </a>\n                    <h2>' +
((__t = ( _.i18n('common.browserNotCompatible') )) == null ? '' : __t) +
'</h2>\n                </div>\n                <div class="form-group">\n                    <div class="col-lg-12">\n                        <a href="https://www.google.com/chrome">' +
((__t = ( _.i18n('common.downloadChrome') )) == null ? '' : __t) +
'</a>\n                    </div>\n                </div>\n            </fieldset>\n        </form>\n        <!-- Hidden iframe for login form autofill -->\n        <iframe class="hidden_iframe-block" name="hidden_iframe"></iframe>\n    </div>\n</div>';

}
return __p
};});

