define('dynamicCreateEditCustomLisConfiguration',[
	'module',
	'dialogFormView',
	'template!dynamicCreateEditCustomLisConfigurationTpl',
	'arrayInputView',
	'backbone',
	'underscore',
	'jquery'
], function (
	module,
	DialogFormView,
	tpl,
	ArrayListView,
	Backbone,
	_,
	$
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: tpl,

		ui: {
			array: '.js-arraylist-region',
			actions: '.js-select-action',
			sources: '.js-select-source'
		},

		events: {
			'focus @ui.fieldCustom': 'onFocusCustom',
			'change @ui.actions': 'onChangeAction',
			'change @ui.sources': 'changeService'
		},

		regions: {
			arrayListRegion: '.js-arraylist-region'
		},

		modelEvents: {
			change: 'onChangeModel'
		},

		className: 'col-w-all col-w-100 d-f',

		initialize: function () {
			this.model = new Backbone.Model();
			var sourceSplitted = this.options.model.get('source') ? this.options.model.get('source').split(';') : [];
			var source = sourceSplitted.length > 0 ? sourceSplitted[0] : null;
			this.model.set('action', this.options.model.get('action'));
			this.model.set('source', source);
			if (sourceSplitted.length > 1) {
				this.model.set('sourceParam', sourceSplitted.slice(1).join(';'));
			} else {
				this.model.set('sourceParam', null);
			}
		},

		serializeData: function () {
			var templateData = {};
			templateData.model = this.model.toJSON();
			templateData.actions = ['QUERY_SID', 'EXPORT_RESULT', 'EXPORT_RESULT_TEST', 'EXPORT_QUALITY_CONTROL', 'EXPORT_REPEAT'];
			templateData.tpl = {};
			templateData.tpl.field = this.options.field;
			templateData.tpl.fieldCustom = this.options.fieldCustom;
			return templateData;
		},

		onShow: function () {
			this.options.model.findTypeByAction(this.model.get('action')).done(_.bind(function (response) {
				if (!response) {
					response = [];
				}
				var opt = $('<option>', {
					text: '',
					value: null
				});
				opt.appendTo(this.ui.sources[0]);
				_.each(response, _.bind(function (type) {
					var opt = $('<option>', {
						text: type,
						value: type,
						selected: type === this.model.get('source')
					});
					opt.appendTo(this.ui.sources[0]);
				}, this));
				this.ui.sources.attr('disabled', false);
				this.onChangeField();
			}, this));
		},

		changeService: function (e) {
			var value = e.currentTarget.value;
			if (!value) {
				this.onChange('source', null);
			} else {
				this.model.set('source', value);
			}
			this.onChange('sourceParam', null);
			this.onChangeField();
		},

		onChangeField: function () {
			this.ui.sources.attr('disabled', !this.model.get('action'));
			if (this.model.get('source') && this.model.get('source') !== 'NO') {
				this.ui.array.removeClass('invisible');
				var arrayListView = new ArrayListView({
					model: this.model,
					field: {field: 'sourceParam', param: {arrayCode: 'LIS-SOURCE-' + this.model.get('source')}}
				});
				this.getRegion('arrayListRegion').show(arrayListView);
			} else {
				this.model.set({'sourceParam': null}, {silent: true});
				this.ui.array.addClass('invisible');
			}
		},

		onChangeAction: function (e) {
			var value = e.currentTarget.value;
			var i, L = this.ui.sources[0].options.length - 1;
			for (i = L; i >= 0; i--) {
				this.ui.sources[0].remove(i);
			}
			this.ui.sources.attr('disabled', true);
			if (!value) {
				this.onChange('action', null);
				this.onChangeField();
			} else {
				this.model.set('action', value);
				this.options.model.findTypeByAction(this.model.get('action')).done(_.bind(function (response) {
					var opt = $('<option>', {
						text: '',
						value: null
					});
					opt.appendTo(this.ui.sources[0]);
					_.each(response, _.bind(function (type) {
						var opt = $('<option>', {
							text: type,
							value: type
						});
						opt.appendTo(this.ui.sources[0]);
					}, this));
					this.onChangeField();
				}, this));
			}
		},
		onChangeModel: function () {
			this.options.model.set({'action': this.model.get('action')}, {silent: true});
			var source;
			if (this.model.get('source')) {
				source = this.model.get('source');
				if (this.model.get('sourceParam')) {
					source += ';' + this.model.get('sourceParam');
				}
			}
			this.options.model.set({'source': source}, {silent: true});
		}
	});
});
