define('runChooseUploadView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!runChooseUploadTpl',
    'entities/caccounts/pcrruns'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    chooseUploadLayoutTpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: chooseUploadLayoutTpl,

        ui: {
            uploadRuns: '.js-upload-runs'
        },

        events: {
            'change @ui.uploadRuns': 'onUploadRunsPopup',
            'click .js-cancel-popup': 'onCancel',
            'click .run-import-remote-folder': 'onImportFromRemoteFolder'
        },

        fromTemplate: true,

        initialize: function () {
            this.accept = this.options.accept;
        },

        serializeData: function () {
            var templateData = {};
            templateData.remoteAvailable = this.options.settings['IMPORT-RESULT'] && this.options.settings['IMPORT-RESULT'].split(';')[0];
            templateData.accept = this.accept;
            return templateData;
        },

        onUploadRunsPopup: function (e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.trigger('run:import-xml', e.target.files[0]);
            }
            this.hide();
        },

        onImportFromRemoteFolder: function () {
            this.triggerMethod('run:select-file-to-import');
            this.hide();
        },

        onCancel: function () {
            this.hide();
        },

        hide: function () {
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        }
    });
});
