define('dynamicListToList',[
    'module',
    'backbone',
    'dialogFormView',
    'template!dynamicListToList',
    'underscore',
    'jquery',
    'settings',
    'savingBehavior'
], function (
    module,
    Backbone,
    DialogFormView,
    tpl,
    _,
    $,
    Settings,
    SavingBehavior
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            availableList: '.js-available',
            selectedList: '.js-selected',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            rawParams: '#rawparams',
            copyParameter: '.js-copy',
            pasteParameter: '.js-paste',
            deleteParameter: '.js-delete'
        },

        events: {
            'click .mdi-transfer-left': 'onTransferToLeft',
            'click .mdi-transfer-right': 'onTransferToRight',
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'click @ui.copyParameter': 'onCopyParameter',
            'click @ui.pasteParameter': 'onPasteParameter',
            'click @ui.deleteParameter': 'onDeleteParameter',
            'change @ui.rawParams': 'onRawParamsChange'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            'change': 'render'
        },

        className: 'col-w-100',

        initialize: function () {
            if (!this.options.separator) {
                this.options.separator = ';';
            }
            this.options.items = _.map(this.options.items, function (item) {
                if (item instanceof Backbone.Model) {
                    return {secId: item.get('secId'), code: item.get('code'), name: item.get('name')};
                }
                if (_.isArray(item) || _.isObject(item)) {
                    return {secId: item.secId, code: item.code, name: item.name};
                }
                return {secId: undefined, code: item, name: undefined};
            });
            if (this.options.values instanceof Backbone.Collection) {
                this.options.values = this.options.values.models;
            }

            if (!_.isArray(this.options.values)) {
                if (this.options.values) {
                    this.options.values = this.options.values.split(this.options.separator);
                } else {
                    this.options.values = [];
                }
            }

            this.options.values = _.map(this.options.values, _.bind(function (value) {
                if (value instanceof Backbone.Model) {
                    if (value.get('secId')) {
                        return _.findWhere(this.options.items, {secId: value.get('secId')});
                    }
                    if (value.get('code')) {
                        return _.findWhere(this.options.items, {code: value.get('code')});
                    }
                    if (value.get('name')) {
                        return _.findWhere(this.options.items, {name: value.get('name')});
                    }
                    return {secId: value.get('secId'), code: value.get('code'), name: value.get('name')};
                }
                if (_.isArray(value) || _.isObject(value)) {
                    if (value.secId) {
                        return _.findWhere(this.options.items, {secId: value.secId});
                    }
                    if (value.code) {
                        return _.findWhere(this.options.items, {code: value.code});
                    }
                    if (value.name) {
                        return _.findWhere(this.options.items, {name: value.name});
                    }
                    return {secId: value.secId, code: value.code, name: value.name};
                }
                if (_.isString(value)) {
                    var item = _.findWhere(this.options.items, {secId: value});
                    if (item) {
                        return item;
                    }
                    item = _.findWhere(this.options.items, {code: value});
                    if (item) {
                        return item;
                    }
                    item = _.findWhere(this.options.items, {name: value});
                    if (item) {
                        return item;
                    }
                }
                return {secId: null, code: value, name: undefined};
            }, this));
            this.itemsAvailable = _.sortBy(_.reject(this.options.items, _.bind(function (item) {
                return _.any(this.options.values, function (value) {
                    if (item.code) {
                        return item.code === value.code;
                    }
                    return item === value;
                });
            }, this)), function (item) {
                if (item.code) {
                    return item.code;
                }
                if (item.name) {
                    return item.name;
                }
                return item;
            });
            this.itemsSelected = _.sortBy(this.options.values, function (item) {
                if (item.code) {
                    return item.code;
                }
                if (item.name) {
                    return item.name;
                }
                return item;
            });

            this.model = new Backbone.Model();
            this.model.set('available', new Backbone.Collection());
            this.model.set('selected', new Backbone.Collection());
            _.each(this.itemsAvailable, _.bind(function (item) {
                this.model.get('available').push(item);
            }, this));
            _.each(this.itemsSelected, _.bind(function (item) {
                this.model.get('selected').push(item);
            }, this));
        },

        serializeData: function () {
            var templateData = {maxSize: this.options.items.length < 30 ? this.model.get('available').length + this.model.get('selected').length : 30};
            templateData.readOnly = this.options.readOnly;
            templateData.accessToClipboard = navigator.clipboard;
            templateData.rawParams = this.model.get('selected').map(function (item) {
                return item.get('code');
            }).join(', ');
            templateData.entityName = this.options.entityName;
            templateData.fieldName = this.options.field;
            templateData.codeListCode = this.options.codeListCode ? ' - ' + this.options.codeListCode : '';

            return templateData;
        },

        onRender: function () {
            this.model.get('available').each(_.bind(function (item) {
                var opt = $('<option>', {
                    text: item.get('name') ? '[' + item.get('code') + '] : ' + item.get('name') : (item.get('code') ? item.get('code') : item),
                    value: item.get('secId') ? item.get('secId') : (item.get('code') ? item.get('code') : item)
                });
                opt.appendTo(this.ui.availableList);
            }, this));

            this.model.get('selected').each(_.bind(function (item) {
                var opt = $('<option>', {
                    text: item.get('name') ? '[' + item.get('code') + '] : ' + item.get('name') : (item.get('code') ? item.get('code') : item),
                    value: item.get('secId') ? item.get('secId') : (item.get('code') ? item.get('code') : item)
                });
                opt.appendTo(this.ui.selectedList);
            }, this));
            this.setPermissions(Settings.get('currentUserModel').hasRole('DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR'));
        },

        onConfirm: function () {
            this.trigger('listToList:confirm', this.model.get('selected').map(function (item) {
                return item.get('secId') ? item.get('secId') : item.get('code');
            }));
            this.box.modal('hide');
        },

        onTransferToRight: function () {
            this._transfer('available', 'selected');
            this.model.trigger('change');
        },

        onTransferToLeft: function () {
            this._transfer('selected', 'available');
            this.model.trigger('change');
        },

        _transfer: function (origin, target) {
            var listSelected;
            if (origin === 'available') {
                listSelected = this.ui.availableList[0];
            } else {
                listSelected = this.ui.selectedList[0];
            }
            var modelToTransfer = this.model.get(origin).filter(_.bind(function (item) {
                return _.any(listSelected.selectedOptions, function (selected) {
                    return item.get('code') === selected.value || item.get('secId') === selected.value;
                });
            }, this));

            this.model.get(origin).remove(modelToTransfer);

            _.each(modelToTransfer, _.bind(function (optionToTransfer) {
                this.model.get(target).push(optionToTransfer);
            }, this));
        },

        onCopyParameter: function () {
            this.ui.copyParameter.css('color', '#4cc8da');
            if (!navigator.clipboard) {
                //old - document execcommand copy is deprecated
                var range = document.createRange();
                range.selectNode(document.getElementById('rawparams'));
                window.getSelection().removeAllRanges(); // clear current selection
                window.getSelection().addRange(range); // to select text
                document.execCommand('copy');
                window.getSelection().removeAllRanges();// to deselect
            } else {
                var textToCopy = this.ui.rawParams.val();
                navigator.clipboard.writeText(textToCopy);
            }
            setTimeout(_.bind(function () {
                this.ui.copyParameter.css('color', 'black');
            }, this), 100);
        },

        onPasteParameter: function () {
            navigator.clipboard.readText().then(_.bind(function (clipText) {
                this._changeParameter(clipText);
            }, this));
        },

        onDeleteParameter: function () {
            $(this.ui.selectedList[0].children).prop('selected', true);
            this._transfer('selected', 'available');
            this.model.trigger('change');
        },
        onRawParamsChange: function (e) {
            this._changeParameter(e.target.value);
        },
        _changeParameter: function (clipText) {
            var textSplitted = clipText.split(', ');
            $(this.ui.selectedList[0].children).prop('selected', true);
            this._transfer('selected', 'available');
            _.each(this.ui.availableList[0].children, function (item) {
                if (_.any(textSplitted, function (text) {
                    return item.value === text;
                })) {
                    $(item).prop('selected', true);
                }
            });
            this._transfer('available', 'selected');
            this.model.trigger('change');

        }
    });
});
