define('entities/caccounts/mbanares',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.MbAnaRes = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/mbanares/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'resText': '',
            'color': null,
            'lisCode': '',
            'lisText': '',
            'lisVariant': '',
            'errorParameter': '',
            'refMbAnaResGr': null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refMbAnaResGr',
            relatedModel: 'MbAnaResGr',
            includeInJSON: ['secId', 'code', 'name']
        }],

        getEntity: function () {
            return module.exports;
        }
    });

    var API = {

        getFieldsToDisplay: function () {
            return {'fieldsWidth': 300, 'fields': ['name']};
        }
    };

    app.ns.MbAnaResCollection = Backbone.Collection.extend({
        model: app.ns.MbAnaRes
    });

    App.reqres.setHandler('mbanares:collection', function (itemJSON) {
        var collection = new app.ns.MbanaresCollection(itemJSON);
        collection.comparator = function (item) {
            return item.get('code');
        };
        collection.sort();
        return collection;
    });


    App.reqres.setHandler('mbanares:model', function (itemJSON) {
        return new app.ns.MbAnaRes(itemJSON);
    });
    App.reqres.setHandler('mbanares:get-fields-to-display', function () {
        return API.getFieldsToDisplay();
    });

    module.exports = _.defaults({
        getName: function () {
            return 'mbanares';
        },
        getUrl: function (mbAnaResGrSecId) {
            return 'v2/mbanaresgrs/' + mbAnaResGrSecId + '/mbanares';
        },
        getDynamicJson: function () {
            return 'MbAnaResJson';
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.urlPath = (config.url ? config.url : this.getUrl(config.mbAnaResGrSecId)) + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            }));
            return configuration;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.MbAnaRes.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.MbAnaResCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['resText', 'refMbAnaResGr.code']};
        },

        getCreateEditFieldValidate: function () {
            var ignored = Dynamic.getCreateEditFieldValidate();
            ignored.push({name: 'refMbAnaResGr', type: 'required'});
            return ignored;
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1'}}},
                {'field': 'resText', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'color', 'param': {'type': 'COLOR', 'display': {'class': 'col-xs-2 p-r-2 p-l-2'}}},
                {'field': 'lisCode', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'lisText', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'lisVariant', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {
                    'field': 'errorParameter',
                    'param': {'type': 'ARRAYLIST', 'arrayCode': 'RESCODE-ERROR', 'display': {'class': 'col-xs-2'}}
                }
            ];
        },
        getErrorParameter: function (model) {
            var ret = {code: null, message: null};
            if (!model.get('errorParameter')) {
                return ret;
            }
            var splitted = model.get('errorParameter').split(';');
            if (splitted.length > 0) {
                ret.code = splitted[0];
            }
            if (splitted.length > 1) {
                ret.message = splitted[1];
            }
            return ret;
        },
        findByCode: function (code, mbAnaResGrSecId) {
            var deferred = $.Deferred();
            $.ajax({
                url: Settings.url('compuzz', 'v2/mbanaresgrs/' + mbAnaResGrSecId + '/mbanares/code/' + code),
                method: 'GET',
                dataType: 'json',
                success: _.bind(function (data) {
                    deferred.resolve(this.getModel(data));
                }, this),
                error: function () {
                    deferred.reject();
                }
            });
            return deferred.promise();
        }
    }, Dynamic);
});
