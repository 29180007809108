
define('template!prepRunTableTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content well info-page" id="pcrRuns">\n\t<div class="run-filters row form-horizontal hidden">\n\t\t<div class="col-xs-10">\n\t\t\t<div class="form-group col-md-6 col-lg-3">\n\t\t\t\t<label class="col-xs-3 control-label" for="filter-smpId">' +
((__t = ( _.i18n('run.filters.smpId') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input type="text"\n\t\t\t\t\t\t   class="form-control floating-label select-smpId"\n\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('run.filters.smpId') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t   id="filter-smpId">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group col-md-6 col-lg-3">\n\t\t\t\t<label class="col-xs-3 control-label">' +
((__t = ( _.i18n('run.filters.kit') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<div class="js-select-assay-region"></div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group col-md-6 col-lg-3">\n\t\t\t\t<label class="col-xs-3 control-label">' +
((__t = ( _.i18n('run.filters.mbana') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<div class="js-select-mbana-region"></div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group col-md-6 col-lg-3">\n\t\t\t\t<label class="col-xs-3 control-label" for="filter-result">' +
((__t = ( _.i18n('run.filters.result') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input type="text" class="form-control floating-label select-result"\n\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('run.filters.result') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t   id="filter-result">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="col-xs-2">\n\t\t\t<label class="btn add-btn-text btn-sm search-well-button">\n\t\t\t\t<span class="mdi mdi-magnify"></span>\n\t\t\t\t<span>' +
((__t = ( _.i18n("run.filters.search") )) == null ? '' : __t) +
'</span>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\t<div class="js-kitl-table-details-for-printing-container">\n\t\t<div class="row">\n\t\t\t<div class="col-md-6">\n\t\t\t\t<div class="col-xs-4">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-8">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="row">\n\t\t\t<div class="col-md-6">\n\t\t\t\t<div class="col-xs-4">' +
((__t = ( _.i18n('runList.runModDate') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-8">' +
((__t = ( creatDate )) == null ? '' : __t) +
'</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="row">\n\t\t\t<div class="col-md-6">\n\t\t\t\t<div class="col-xs-4">' +
((__t = ( _.i18n('runList.refRespUser') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-8">' +
((__t = ( creatUser )) == null ? '' : __t) +
'</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="row">\n\t\t\t<div class="col-md-6">\n\t\t\t\t<div class="col-xs-4">' +
((__t = ( _.i18n('run.refKitProt') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-8">(refKitProt.code)</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="row">\n\t\t\t<div class="col-md-6">\n\t\t\t\t<div class="col-xs-4">' +
((__t = ( _.i18n('run.refLmbCycler') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-8">(refLmbCycler.code)</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="row">\n\t\t\t<div class="col-md-6">\n\t\t\t\t<div class="col-xs-4">' +
((__t = ( _.i18n('run.plateIdPC') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-8">(plateIdPC)</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="row">\n\t\t\t<div class="col-md-6">\n\t\t\t\t<div class="col-xs-4">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-8">' +
((__t = ( comment ? comment : '-' )) == null ? '' : __t) +
'</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class="container-fluid well-container js-print-table p-t-1">\n\t\t<div class="row borderNone">\n\t\t\t<div class="col-xs-1 p-0" style="width: 2%;">\n\t\t\t\t<div class="m-b-0 clickable">\n\t\t\t\t\t<div class="wellTableCornerTitle" style="height: 30px;">\n\t\t\t\t\t\t';
 if(isMultiDisplay) { ;
__p += '\n\t\t\t\t\t\t<div style="display: flex; flex-wrap: wrap;">\n\t\t\t\t\t\t\t<span class="js-top-left clickable ' +
((__t = ( firstWellPos === 'A01' && isGrouping ? 'mdi mdi-square' : 'mdi mdi-square-outline' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t  style=" width: 50%;height:15px; font-size: 15px; line-height: 15px;"></span>\n\t\t\t\t\t\t\t<span class="js-top-right clickable ' +
((__t = ( firstWellPos === 'A02' && isGrouping ? 'mdi mdi-square' : 'mdi mdi-square-outline' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t  style=" width: 50%;height:15px;font-size: 15px; line-height: 15px;"></span>\n\t\t\t\t\t\t\t<span class="js-bottom-left clickable ' +
((__t = ( firstWellPos === 'B01' && isGrouping ? 'mdi mdi-square' : 'mdi mdi-square-outline' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t  style=" width: 50%;height:15px;font-size: 15px; line-height: 15px;"></span>\n\t\t\t\t\t\t\t<span class="js-bottom-right clickable ' +
((__t = ( firstWellPos === 'B02' && isGrouping ? 'mdi mdi-square' : 'mdi mdi-square-outline' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t  style=" width: 50%;height:15px;font-size: 15px; line-height: 15px;"></span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t';
 _.each(rows, function(row) {;
__p += '\n\t\t\t\t<div class="row m-b-0 clickable">\n\t\t\t\t\t<div class="wellTableLineTitleItem col-xs-12 text-center">' +
((__t = ( row )) == null ? '' : __t) +
'</div>\n\t\t\t\t</div>\n\t\t\t\t';
 }) ;
__p += '\n\t\t\t</div>\n\t\t\t<div class="col-xs-11 clickable" style="width: 97%;">\n\t\t\t\t<div class="row m-b-0">\n\t\t\t\t\t';
 _.each(columns, function(column) {;
__p += '\n\t\t\t\t\t<div class="wellTableColumnTitleItem text-center"\n\t\t\t\t\t\t style="width: ' +
((__t = ( width )) == null ? '' : __t) +
'%; float: left;">' +
((__t = ( ("0" + column).slice(-2) )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t</div>\n\t\t\t\t';
 _.each(rows, function(row) {;
__p += '\n\t\t\t\t<div class="row m-b-0">\n\t\t\t\t\t';
 _.each(columns, function(column) {
					var pos = row + ("0" + column).slice(-2); ;
__p += '\n\t\t\t\t\t<div class="wellTableItem tbl-' +
((__t = ( pos )) == null ? '' : __t) +
'" style="width: ' +
((__t = ( width )) == null ? '' : __t) +
'%; float: left;"></div>\n\t\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t</div>\n\t\t\t\t';
 }) ;
__p += '\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

