
define('template!assayResultVersion', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-w-all col-w-100 d-f p-l-0 p-r-5">\n\t<div class="col-w-3">\n\t\t<input type="number" data-field-name="sequence" min="1" value="' +
((__t = ( sequence )) == null ? '' : __t) +
'"\n\t\t\t   class="col-w-100  form-control inputBackground"/>\n\t</div>\n\n\t<div class="col-w-9 js-dropDownRegion-input p-l-5">\n\t\t<input type="text"\n\t\t\t   class="form-control inputBackground"\n\t\t\t   placeholder=""\n\t\t\t   value="' +
((__t = ( target ? target.code : '' )) == null ? '' : __t) +
'"\n\t\t\t   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n\t\t\t   data-placement="top" autocomplete="off">\n\t</div>\n\t<div class="col-w-2 p-l-5">\n\t\t<input class="js-info-input targetGridIcRowText inputBackground js-radio"\n\t\t\t   value="' +
((__t = ( secId )) == null ? '' : __t) +
'"\n\t\t\t   id="ic-' +
((__t = ( secId )) == null ? '' : __t) +
'"\n\t\t\t   name="ic" readonly="readonly"\n\t\t\t   data-field-name="ic" type="radio" ' +
((__t = ( ic ? 'checked="checked"' : '' )) == null ? '' : __t) +
' />\n\t</div>\n\n\t<div class="col-w-45 d-b p-l-0 p-r-5 css-algoRes-column-color" style="padding: 2px 0 2px 0;">\n\t\t<div class="js-algoResult"></div>\n\t</div>\n\t<div class="col-w-1"></div>\n\n\t<div class="col-w-15 d-b p-l-0 p-r-5">\n\t\t<div class="js-algoValidation"></div>\n\t</div>\n\n\t<div class="col-w-1">\n\t</div>\n\n\t<div class="col-w-10 d-b p-l-0 p-r-5 css-res-column-color" style="padding: 2px 0 2px 0;">\n\t\t<div class="col-w-all d-f">\n\t\t\t<div class="col-w-20 p-l-5">\n\t\t\t\t<input class="js-info-input js-sequenceDisplayGroup js-radio"\n\t\t\t\t\t   name="sequenceDisplayGroup-"\n\t\t\t\t\t   data-field-name="sequenceDisplayGroup" type="radio" readonly="readonly" ' +
((__t = ( !!sequenceDisplayGroup ?
				'checked="checked"' : '' )) == null ? '' : __t) +
' />\n\t\t\t</div>\n\t\t\t<div class="col-w-40 p-l-5 p-r-5">\n\t\t\t\t<input type="number" data-field-name="sequenceValidation" min="1" value="' +
((__t = ( sequenceValidation )) == null ? '' : __t) +
'"\n\t\t\t\t\t   class="col-w-100  form-control inputBackground js-sequenceValidation ' +
((__t = ( needValidation ? '' : 'disabled' )) == null ? '' : __t) +
'"\n\t\t\t\t' +
((__t = ( needValidation ? '' : 'disabled="disabled"' )) == null ? '' : __t) +
'/>\n\t\t\t</div>\n\t\t\t<div class="col-w-40 p-l-5 p-r-5">\n\t\t\t\t<input type="number" data-field-name="sequenceDisplay" min="1" value="' +
((__t = ( sequenceDisplay )) == null ? '' : __t) +
'"\n\t\t\t\t\t   class="col-w-100  form-control inputBackground"/>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\n\t<div class="col-w-1">\n\t</div>\n\n\t<div class="col-w-15 d-b p-l-0 p-r-5">\n\t\t<div class="js-algoExport"></div>\n\t</div>\n</div>\n<div class="row js-targetValues">\n</div>';

}
return __p
};});

