/**
 * Created by Matteo on 11/07/2015.
 */
define('sidebarShowController',[
    'module',
    'underscore',
    'app',
    'sidebarShowView',
    'menuItems',
    'entities/menu'
], function (
    module,
    _,
    App,
    View,
    MenuItems,
    Menu
) {
    'use strict';
    module.exports = {
        showSidebar: function (settings) {
            this.menu = Menu.getModel(MenuItems);
            this.view = new View({controller: this, menu: this.menu, settings: settings});
            App.regions.getRegion('main').currentView.getRegion('sidebar').show(this.view);
        },

        setActiveNavItem: function (name) {
            this.view.setActive(name);
        },
        collapseAll: function (el) {
            this.view.collapse(el);
        }
    };
});
