define('entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValuePcrWellValues',[
    'app',
    'backbone',
    'entities/caccounts/pcrwells',
    'entities/caccounts/pcrruns'
], function (
    App,
    Backbone
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.StandardCurveAssayConfigurationResultValuePcrWellValue = Backbone.RelationalModel.extend({
        defaults: {
            'pcrWell': null,
            'ct': 0,
            'log': 0
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'pcrWell',
            relatedModel: 'PcrWell'
        }],
        getImageUid: function () {
        }
    });

    app.ns.StandardCurveAssayConfigurationResultValuePcrWellValueCollection = Backbone.Collection.extend({
        model: app.ns.StandardCurveAssayConfigurationResultValuePcrWellValue
    });
});
