define('entities/caccounts/assayVersion',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/assayReagentVersion',
    'entities/caccounts/assayResultVersion',
    'backboneRelational',
    'entities/caccounts/kitprots',
    'entities/caccounts/assaygroup',
    'entities/caccounts/assay',
    'entities/caccounts/colorCompensations'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.AssayVersion = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/assayVersions/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'refKitProt': null,
            'code': '',
            'name': '',
            'description': '',
            'comment': '',
            'refAssayGroup': undefined,
            'reagents': undefined,
            'volumeBySample': null,
            'volumeIC': null,
            'algoDeathVolume': {type: 'NO', param: ''},
            'colorCompensation': null,
            'results': [],
            'assay': {}
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'AssayResultVersion',
            collectionType: 'AssayResultVersionCollection',
            reverseRelation: {
                key: 'assayVersion',
                includeInJSON: ['secId', 'code', 'name']
            }
        }, {
            type: Backbone.HasMany,
            key: 'reagents',
            relatedModel: 'AssayReagentVersion',
            collectionType: 'AssayReagentVersionCollection',
            reverseRelation: {
                key: 'assayVersion',
                includeInJSON: ['secId', 'code', 'name']
            }
        }, {
            type: Backbone.HasOne,
            key: 'refKitProt',
            relatedModel: 'KitProt'
        }, {
            type: Backbone.HasOne,
            key: 'assay',
            relatedModel: 'Assay'
        }, {
            type: Backbone.HasOne,
            key: 'refAssayGroup',
            relatedModel: 'AssayGroup'
        }, {
            type: Backbone.HasOne,
            key: 'colorCompensation',
            relatedModel: 'ColorCompensation',
            includeInJSON: ['secId', 'code', 'name']
        }],
        getImageUid: function () {
        },

        jsonObjectName: 'AssayVersionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayVersion',
        getEntity: function () {
            return module.exports;
        },
        importable: true,
        getReagentsQC: function () {
            return new app.ns.AssayReagentVersionCollection(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && assayReagent.get('reagent').get('type') === 'QC';
            }));
        },
        getReagentIC: function () {
            var model = _.first(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && ['IC'].includes(assayReagent.get('reagent').get('type'));
            }));
            if (!model) {
                model = new app.ns.AssayReagentVersion({assayVersion: this});
            }
            return model;
        },
        getReagentsMMX: function () {
            return new app.ns.AssayReagentVersionCollection(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && !['QC', 'IC'].includes(assayReagent.get('reagent').get('type')) && assayReagent.get('reagent').get('type');
            }));
        }
    });
    app.ns.AssayVersionCollection = Backbone.Collection.extend({
        model: app.ns.AssayVersion
    });

    module.exports = _.defaults({
        getName: function () {
            return 'assayversion';
        },
        getDynamicJson: function () {
            return 'AssayVersionJson';
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.AssayVersion.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refKitProt.code']};
        }
    }, Dynamic);
});
