define('dashboardPcrThumbnailCardView',[
    'module',
    'app',
    'marionette',
    'underscore',
    'template!dashboardPcrThumbnailCardTpl',
    'moment'
], function (
    module,
    App,
    Marionette,
    _,
    PcrThumbnailCardTpl,
    moment
) {
    'use strict';

    var CardView = Marionette.LayoutView.extend({
        template: PcrThumbnailCardTpl,

        events: {
            'click': 'onItemClick'
        },
        regions: {},

        initialize: function (options) {
            this.model = options.model.clone();
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.runNAme = this.model.get('name');
            templateData.statusIcon = this.getStatusIcon(this.model.get('status'));
            templateData.valStatusIcon = this.getValStatusIcon(this.model.get('status'), this.model.get('valStatus'));

            var creatDate = moment(this.model.get('creatDate')).format('DD/MM/YY');
            var now = moment(new Date(Date.now())).format('DD/MM/YY');

            var elapsedTime = this.getElapsedTimeFromDDMMYYYY(creatDate, now);
            templateData.elapsedTimeToString = elapsedTime.toSting;

            return templateData;
        },

        getElapsedTimeFromDDMMYYYY: function (date1, date2) {
            var dif = parseInt(moment(date2, 'DD/MM/YY').format('x'), 10) - parseInt(moment(date1, 'DD/MM/YY').format('x'), 10);

            var days = Math.floor(dif / (1000 * 60 * 60 * 24));
            var hours = Math.floor(dif / (1000 * 60 * 60)) % 24;
            var minutes = Math.floor(dif / (1000 * 60)) % 60;
            var seconds = Math.floor(dif / 1000) % 60;

            var string = 'day:' + days + ' - hour:' + hours + ' - min:' + minutes + ' - sec:' + seconds;

            var elapsedTime = {
                toSting: string,
                days: days,
                hour: hours,
                minutes: minutes,
                seconds: seconds
            };
            return elapsedTime;
        },

        onItemClick: function () {
            var secId = this.model.get('secId');
            if ($('.run-pcr-' + secId).hasClass('clickable')) {
                App.navigate('runs/pcr/' + secId + '/A01/1/' + this.model.get('plateSize'), {trigger: true});
            }
        },

        getStatusIcon: function (templateStatus) {
            var status = templateStatus ? parseInt(templateStatus, 10) : ' ';
            var iconColor = [];
            var statusIcon = '';
            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb">' + status + '</span>';
            statusIcon += '<div class="cell-default" style="display: flex; top: 2px;\n' + 'position: relative;">';

            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-statusIcon' + i + ' ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        },

        getValStatusIcon: function (templateStatus, templateValStatus) {
            var status = parseInt(templateStatus, 10);
            var valStatus = templateValStatus;
            var iconColor = [];
            var statusIcon = '';
            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb">' + valStatus + '</span>';
            statusIcon += '<div class="cell-default" style="display: flex; top: 2px;\n' + 'position: relative;">';

            var valStatusArray = valStatus.split('');
            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                if (valStatusArray.includes(i.toString())) {
                    iconColor[i] = 'statusIcon-toDo';
                }
                if (i === 5 && valStatusArray.includes(i.toString()) && status === 5) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-valStatusIcon ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        }
    });

    module.exports = CardView;
});
