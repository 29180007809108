define('translationsTableView',[
    'module',
    'template!translationsTableTpl',
    'marionette',
    'translationsTranslationView',
    'translationsPopupFormView',
    'underscore',
    'entities/admin/translations'
  ],
function(
  module,
  translationsTableTpl,
  Marionette,
  TranslationsTranslationView,
  PopupFormView,
  _
) {
  'use strict';

  var TranslationsTableView = Marionette.CompositeView.extend({
    template: translationsTableTpl,
    childViewContainer: 'tbody',
    childView: TranslationsTranslationView,
    childViewOptions: function() {
      return {
        languages: this.options.languages,
        viewsData: this.options.viewsData
      };
    },
    ui: {
      paginationFirst: '.js-pagination-first',
      paginationPrev: '.js-pagination-prev',
      paginationNext: '.js-pagination-next',
      paginationLast: '.js-pagination-last',
      currentPage: '.js-current-page',
      pageIndex: '.js-page-index',
      pageLastIndex: '.js-page-last-index',
      translationsUpload: '#translations-upload',
      tableLoading: '.js-table-loading'
    },
    events: {
      'click @ui.paginationFirst': 'onPaginationFirst',
      'click @ui.paginationPrev': 'onPaginationPrev',
      'click @ui.paginationNext': 'onPaginationNext',
      'click @ui.paginationLast': 'onPaginationLast',
      'change @ui.translationsUpload': 'onUploadTranslations',
      'click .js-add-translation': 'onAddTranslation'
    },

    collectionEvents: {
      sync: 'onCollectionLoaded'
    },

    pagination: {
      currentPage: 1,
      pageSize: 15,
      totalPages: undefined,
      totalCount: undefined,
      first: 0
    },

    initialize: function() {
      this.listenTo(this.options.languages, 'change', this.render);
      this.pagination.totalCount = this.options.totalCount;
      this.pagination.totalPages = Math.floor(this.pagination.totalCount / this.pagination.pageSize);
      if(this.pagination.totalCount % this.pagination.pageSize) {
        this.pagination.totalPages++;
      }
    },

    serializeData: function() {
      var that = this;
      return {
        pagination: that.pagination,
        excelDownloadUrl: this.options.excelDownloadUrl,
        languages: this.options.languages
      };
    },

    onRender: function() {
      this._activateDeactivatePagination();
    },

    onAddTranslation: function() {
      var popupFormView = new PopupFormView();
      this.listenTo(popupFormView, 'translation:saved', _.bind(this.collection.add, this.collection));
      popupFormView.show({title: _.i18n('translationsList.textAreaDynamicTextEditor')});
    },

    onUploadTranslations: function(e) {
      if (e && e.target && e.target.files && e.target.files[0]) {
        this.trigger('translations:upload', e.target);
      }
    },

    onPaginationFirst: function() {
      if(this.pagination.currentPage === 1) {
        return;
      }
      this.pagination.currentPage = 1;
      this.filterTranslations();
    },

    onPaginationPrev: function() {
      if(this.pagination.currentPage === 1) {
        return;
      }
      this.pagination.currentPage--;
      if(this.pagination.currentPage === 1) {
        this._paginationDeactivatePrev();
      }
      this.filterTranslations();
    },

    onPaginationNext: function() {
      if(this.pagination.currentPage === this.pagination.totalPages) {
        return;
      }
      this.pagination.currentPage++;
      this.filterTranslations();
    },

    onPaginationLast: function() {
      if(this.pagination.currentPage === this.pagination.totalPages) {
        return;
      }
      this.pagination.currentPage = this.pagination.totalPages;
      this.filterTranslations();
    },

    onCollectionLoaded: function() {
      this.ui.tableLoading.fadeOut();
    },

    filterTranslations: function() {
      var lastIndex;

      this.ui.tableLoading.fadeIn();
      this.ui.currentPage.text(this.pagination.currentPage);
      this.pagination.first = (this.pagination.currentPage - 1) * this.pagination.pageSize;
      this.ui.pageIndex.text(this.pagination.first);
      lastIndex = this.pagination.first + this.pagination.pageSize;
      this.ui.pageLastIndex.text(lastIndex > this.pagination.totalCount ? this.pagination.totalCount : lastIndex);
      this.collection.filters.pageIndex = this.pagination.currentPage - 1;
      this.collection.fetch();

      this._activateDeactivatePagination();
    },

    _activateDeactivatePagination: function() {
      if(this.pagination.currentPage === 1) {
        this._paginationDeactivatePrev();
      } else {
        this._paginationActivatePrev();
      }
      if(this.pagination.currentPage === this.pagination.totalPages) {
        this._paginationDeactivateNext();
      } else {
        this._paginationActivateNext();
      }
    },

    _paginationDeactivatePrev: function() {
      this.ui.paginationFirst.removeClass('pagination-active clickable');
      this.ui.paginationPrev.removeClass('pagination-active clickable');
    },

    _paginationDeactivateNext: function() {
      this.ui.paginationLast.removeClass('pagination-active clickable');
      this.ui.paginationNext.removeClass('pagination-active clickable');
    },

    _paginationActivatePrev: function() {
      this.ui.paginationFirst.addClass('pagination-active clickable');
      this.ui.paginationPrev.addClass('pagination-active clickable');
    },

    _paginationActivateNext: function() {
      this.ui.paginationLast.addClass('pagination-active clickable');
      this.ui.paginationNext.addClass('pagination-active clickable');
    }
  });

  module.exports = TranslationsTableView;
});
