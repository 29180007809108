
define('template!selectCaccountListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <div class="well login-form-container">\n    <form class="form-horizontal js-form form-group">\n      <fieldset>\n        <div class="top-login-part text-center">\n          <a class="login-view-link">\n            <div class="avatar-view-link-img"></div>\n          </a>\n          <h2>' +
((__t = ( _.i18n("selectaccount.title") )) == null ? '' : __t) +
'</h2>\n        </div>\n        <div class="form-group">\n          <div class="col-lg-12 form-control-wrapper">\n            <!-- <input class="autocomplete form-control input-lg" placeholder="' +
((__t = ( _.i18n("selectaccount.placeholder") )) == null ? '' : __t) +
'"> -->\n            <div class="select-accounts" style="width: 50%;margin: 0 auto;"></div>\n          </div>\n        </div>\n        <div class="form-group">\n          <div class="col-lg-12 login-view-btns">\n            <a class="btn btn-primary btn-lg btn-block login-view-caccount-sign-in js-sign-in">' +
((__t = ( _.i18n("selectaccount.sigin") )) == null ? '' : __t) +
'</a>\n          </div>\n        </div>\n        <div class="form-group login-footer">\n          <div class="text-center">\n            <p>Mobiolink</p>\n            <p>&#169; ' +
((__t = ( year )) == null ? '' : __t) +
'</p>\n          </div>\n        </div>\n      </fieldset>\n    </form>\n  </div>\n</div>';

}
return __p
};});

