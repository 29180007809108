
define('template!prepRunStatusTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="z-index: 9000;">\n\t<ul>\n\t\t';
 _.each(elements, function(codeListElement) { ;
__p += '\n\t\t<li>' +
((__t = ( status.toString() === codeListElement.code ? '<b>' : '' )) == null ? '' : __t) +
'' +
((__t = ( codeListElement.code )) == null ? '' : __t) +
' - ' +
((__t = (
			_.i18n('preprun.status.' + codeListElement.code) )) == null ? '' : __t) +
'' +
((__t = ( status.toString() === codeListElement.code ?
			'</b>' : '' )) == null ? '' : __t) +
'\n\t\t</li>\n\t\t';
 }) ;
__p += '\n\t</ul>\n</div>';

}
return __p
};});

