/**
 * Created by RKL on 21/08/2015.
 */
define('menusController',[
  'module',
  'logger',
  'underscore',
  'app',
  'menusShowView',
  'entities/admin/menu'
], function (
    module,
    Logger,
    _,
    App,
    View

) {
  'use strict';

  var MenuController = {
    showMainMenus: function() {
      var that = this;
      this.model = App.request('menu:model');
      App.request('menu:get-collection').done(function(data) {
        var view = new View({
          model: data
        });
        that.view = view;
        App.regions.getRegion('main').currentView.getRegion('content').show(view);
      });


    }
  };

  module.exports = MenuController;
});
