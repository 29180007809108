define('adminAssayController',[
    'module',
    'underscore',
    'assayCreateEditLayoutView',
    'entities/caccounts/assay',
    'jquery'
], function (
    module,
    _,
    CreateEditLayoutView,
    Assays,
    $
) {
    'use strict';

    module.exports = {
        showDetails: function (model, callBackRefresh) {
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var deferred = $.Deferred();
            if (model.isNew()) {
                deferred.resolve();
            } else {
                model.fetch().done(function () {
                    deferred.resolve();
                });
            }
            deferred.done(_.bind(function () {
                    deferred.resolve();
                }, this));
            deferred.done(_.bind(function () {
                var createEditView = new CreateEditLayoutView({
                    model: model
                });
                createEditView.show({
                    title: _.i18n(Assays.getName() + '.edit'),
                    className: 'assaysEditAddPopup'
                }, _.bind(function () {
                    if (callBackRefresh) {
                        callBackRefresh();
                    }
                }, callBackRefresh ? callBackRefresh.context : undefined));
            }, this));
        }
    };
});
