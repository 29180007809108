define('entities/caccounts/sampleClass',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/lis',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.SampleClass = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/sampleClass/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'sequence': null,
            'sampleType': null,
            'validityDays': undefined,
            'regexSampleId': undefined,
            'regexShortSampleId': undefined,
            'regexOrderId': undefined,
            'regexSequenceSample': undefined,
            'refLIS': null,
            'description': null,
            'comment': null
        },
        idAttribute: 'secId',

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/sampleClass' + (this.get('secId') ? '/' + this.get('secId') : ''));
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'SampleClassJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'SampleClass',
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.SampleClassCollection = Backbone.Collection.extend({
        model: app.ns.SampleClass
    });

    module.exports = _.defaults({
        getName: function () {
            return 'sampleClass';
        },
        getUrl: function () {
            return 'v2/sampleClass';
        },
        getDynamicJson: function () {
            return 'SampleClassJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('sampleClass.sequence'),
                name: 'sequence',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 15
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('sampleClass.sampleType'),
                name: 'sampleType',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 15
            }, {
                label: _.i18n('sample.validityDays'),
                name: 'validityDays',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 15
            }, {
                label: _.i18n('common.regex'),
                name: 'regexSampleId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 45
            }, {
                label: _.i18n('common.regexShortId'),
                name: 'regexShortSampleId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 45
            }, {
                label: _.i18n('common.regexOrderId'),
                name: 'regexOrderId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 45
            }, {
                label: _.i18n('sampleClass.lis'),
                name: 'refLIS',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'lisCode',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'sequence';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.SampleClass.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return new app.ns.SampleClassCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refLIS.code']};
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 p-l-5'}}},
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 p-l-5'}}},
                {'field': 'validityDays', 'param': {'type': 'NUMBER', 'display': {'class': 'col-w-6 p-l-5'}}},
                {'field': 'regexSampleId', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-49 p-l-5 p-r-5'}}},
                {
                    'field': 'regexShortSampleId',
                    'param': {'type': 'TEXT', 'display': {'class': 'col-w-49 p-l-5 p-r-5'}}
                },
                {'field': 'regexOrderId', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-49 p-l-5 p-r-5'}}}

            ];
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'validityDays',
                    'param': {'type': 'INTEGER', 'display': {'class': 'col-xs-1 p-r-0 text-right'}}
                },
                {'field': 'sequence', 'param': {'type': 'STRING', 'display': {'class': 'col-xs-1 p-r-0 text-right'}}},
                {
                    'field': 'sampleType',
                    'param': {'type': 'CODELIST', 'code': 'SMPTYPE', 'display': {'class': 'col-xs-1 p-r-0'}}
                },
                {'field': 'regexShortSampleId', 'param': {'display': {'class': 'col-xs-3'}}},
                {'field': 'regexSequenceSample', 'param': {'display': {'class': 'col-xs-3'}}},
                {'field': 'regexOrderId', 'param': {'display': {'class': 'col-xs-3'}}},
                {'field': 'refLIS', 'param': {'display': {'class': 'col-xs-3'}}}
            );
            return custom;
        },
        findBySampleId: function (sampleId) {
            var url = Settings.url('compuzz', this.getUrl() + '/findBySampleId/', {sampleId: sampleId}),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (modelJSON) {
                    defer.resolve(modelJSON === '' ? null : app.ns.SampleClass.findOrCreate(modelJSON));
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        generate: function (request) {
            var url = Settings.url('compuzz', this.getUrl() + '/generate/', request),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    }, Dynamic);
});
