
define('template!tracsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="kitl-table">\n\t<table id="jq-grid-list" class="resultsTable" role="grid"></table>\n\t<div id="jq-grid-pager-list"></div>\n</div>\n<div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>';

}
return __p
};});

