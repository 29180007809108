define('menuItemView',['module',
        'marionette',
        'app',
        'underscore'
    ],
    function (module,
              Marionette,
              App,
              _
    ) {
        'use strict';

        module.exports = Marionette.LayoutView.extend({
            template: _.template('<a data-link="<%= link %>" href="<%= link %>" title="<%= title %>"><%= title %></a>'),

            events: {
                'click': 'onLinkClick'
            },

            tagName: 'li',
            attributes: function () {
                return {
                    id: this.model.get(this.model.idAttribute)
                };
            },

            initialize: function () {
                this.link = '#' + this.model.get('link')();
            },

            serializeData: function () {
                var templateData = {};
                templateData.title = this.model.get('title');
                templateData.link = this.link;
                return templateData;
            },

            onLinkClick: function (e) {
                e.stopPropagation();
            },
            findByName: function (name) {
                if (this.model.get(this.model.idAttribute) === name) {
                    return this.model;
                }
                return null;
            },
            setActive: function (model) {
                if (model === this.model) {
                    this.$el.addClass('active');
                } else {
                    this.$el.removeClass('active');
                }
            }
        });
    });
