define('runTableTabView',[
    'module',
    'marionette',
    'template!runTableTabTpl',
    'underscore',
    'settings',
    'colorUtils',
    'app',
    'envHandler',
    'backbone',
    'dateUtils',
    'pcrWellView',
    'entities/caccounts/pcrwells',
    'entities/caccounts/setting'
], function (
    module,
    Marionette,
    runTableTabTpl,
    _,
    Settings,
    ColorUtils,
    App,
    EnvironmentHandler,
    Backbone,
    DateUtil,
    WellView
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: runTableTabTpl,

        selectedWells: [],

        ui: {
            blockLoading: '.empty-page-loader',
            wellContainer: '.well-container'
        },

        events: {
            'click .wellTableCornerTitle .js-top-left': 'onTopLeftClick',
            'click .wellTableCornerTitle .js-top-right': 'onTopRightClick',
            'click .wellTableCornerTitle .js-bottom-left': 'onBottomLeftClick',
            'click .wellTableCornerTitle .js-bottom-right': 'onBottomRightClick'
        },

        initialize: function (options) {
            this.model = options.model;
            this.isMultiDisplay = options.isMultiDisplay;
            this.step = parseInt(options.step, 10);

            this.wellSelected = this.model.get('wells').filterByPos(options.from, options.step, options.to);
            // todo check if needed in case of 384 well plate
            // commented because it use setWellFiltersList with pos instead of filtersname
            // if needed, wee will create setWellPosFiltersList
            // require([
            // 	'runTabsLayoutController'
            // ], _.bind(function (RunTabsLayoutController) {
            // 	RunTabsLayoutController.setWellFiltersList(this.wellSelected.pluck('pos'));
            // }, this));
        },

        serializeData: function () {
            var templateData = {};
            templateData.name = this.model.get('name');
            templateData.creatDate = this.model.get('creatDate');
            templateData.refCreatUserEmail = this.model.get('refCreatUser') ? this.model.get('refCreatUser').email : '-';
            templateData.refKitProtCode = this.model.get('refKitProt') ? this.model.get('refKitProt').code : '-';
            templateData.refLmbCyclerCode = this.model.get('refLmbCycler') ? this.model.get('refLmbCycler').code : '-';
            templateData.plateIdPC = this.model.get('plateIdPC') ? this.model.get('plateIdPC') : '-';
            templateData.comment = this.model.get('comment') ? this.model.get('comment') : '-';
            templateData.rows = [];
            templateData.columns = [];

            this.wellSelected.forEach(
                _.bind(function (well) {
                    var row = well.get('pos').substring(0, 1);
                    if (!_.contains(templateData.rows, row)) {
                        templateData.rows.push(row);
                    }
                    var column = parseInt(well.get('pos').substring(1, 3), 10);
                    if (!_.contains(templateData.columns, column)) {
                        templateData.columns.push(column);
                    }
                }, this));

            if (templateData.rows.length < 8) {
                templateData.rows = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
            }
            if (templateData.columns.length < 12) {
                templateData.columns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            }

            templateData.width = 100 / parseInt(templateData.columns.length, 10);
            templateData.isMultiDisplay = this.isMultiDisplay;
            templateData.isGrouping = this.step === 2;
            templateData.firstWellPos = this.wellSelected && this.wellSelected.size() > 0 ? this.wellSelected.at(0).get('pos') : '';
            return templateData;
        },

        onShow: function () {
            this.filters = this.options.filters ? this.options.filters : null;
            this.filteredWells = this.options.filteredWells ? this.options.filteredWells : null;
            this.showWell();
            this.selectedWells = [];
        },

        showWell: function () {
            this.wellSelected.forEach(
                _.bind(function (well) {
                    this.addRegion('tbl' + well.get('pos'), '.tbl-' + well.get('pos'));
                    var region = this.getRegion('tbl' + well.get('pos'));
                    var wellView = new WellView({
                        model: well,
                        isMultiDisplay: this.isMultiDisplay,
                        region: region.$el,
                    settings: this.options.settings});
                    wellView.listenTo(wellView, 'well:filteredDetailAssay', _.bind(this.filteredDetailAssay, this));

                    region.show(wellView);

                    this.fadeWell = false;
                    if (this.filteredWells && this.filteredWells.length > 0) {
                        var count = 0;

                        _.each(this.filteredWells, _.bind(function (filter) {
                            if (well.get('pos') === filter) {
                                count++;
                            }
                        }, this));

                        if ((count < 1)) {
                            this.fadeWell = true;
                        }
                    }
                    if (this.fadeWell) {
                        if (!region.$el.hasClass('fadeWell')) {
                            region.$el.addClass('fadeWell');
                        }
                    } else {
                        if (region.$el.hasClass('fadeWell')) {
                            region.$el.removeClass('fadeWell');
                        }
                    }
                }, this));
        },

        filteredDetailAssay: function (assaySecId) {
            this.trigger('well:filteredDetailAssay', assaySecId);
        },

        changeWellSelected: function (from, disableGroup) {
            $('.js-global-loader').show();
            this.step = (disableGroup ? 1 : 2);
            if (disableGroup) {
                from = 'A01';
            }
            this.wellSelected = this.model.get('wells').filterByPos(from, this.step, this.model.get('plateSize'));
            App.navigate('runs/pcr/' + this.model.get('secId') + '/' + from + '/' + this.step + '/' + this.model.get('plateSize') + '/table');
            this.render();
            $('.js-global-loader').hide();
        },
        onTopLeftClick: function () {
            this.changeWellSelected('A01', this.step === 2 && this.wellSelected.first().get('pos') === 'A01');
        },

        onTopRightClick: function () {
            this.changeWellSelected('A02', this.step === 2 && this.wellSelected.first().get('pos') === 'A02');
        },

        onBottomLeftClick: function () {
            this.changeWellSelected('B01', this.step === 2 && this.wellSelected.first().get('pos') === 'B01');
        },

        onBottomRightClick: function () {
            this.changeWellSelected('B02', this.step === 2 && this.wellSelected.first().get('pos') === 'B02');
        }
    });
});
