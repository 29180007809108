
define('template!adminAttachmentsListView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="jq-grid-table-page">\n    <div class="attachments-listView">\n        <div class="row">\n            <div class="col-xs-12" style="display: flex; height: 48px;">\n                ';
 if(canAdd) { ;
__p += '\n                <div class="js-showViewNewAttachment clickable">\n                <span id="menuAddListButton" class="mdi mdi-plus-circle action header"\n                      title="' +
((__t = ( _.i18n('add.new.record') )) == null ? '' : __t) +
'">\n                </span>\n                </div>\n\n                <label for="import-file-attachment">\n                    <span class="mdi mdi-arrow-up-bold-circle action header clickable"\n                          id="menuImportListButton"\n                          title="' +
((__t = ( _.i18n('import.record') )) == null ? '' : __t) +
'">\n                            <input type="file"\n                                   id="import-file-attachment"\n                                   class="hidden js-import" data-icon="true"\n                                   accept="' +
((__t = ( accept )) == null ? '' : __t) +
'"/>\n                        </span>\n                </label>\n                ';
 } ;
__p += '\n\n                <div class="" style="width: max-content;\n                    font-size: 27px;\n                    font-weight: 500;\n                    margin-left: 5px;">\n                    ' +
((__t = ( _.i18n('menu.admin.attachments') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n        <div class="attachments-grid">\n            <div class="js-grid-upper-menu noWrapHidden hidden" style="width: 115px;">\n                <span class="mdi mdi-checkbox-blank-outline js-all-rows-selector js-grid-upper-menu-icon"></span>\n            </div>\n            <table id="jq-grid-list-attachment" class="attachments-table" role="grid"></table>\n            <div id="jq-grid-pager-list"></div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

