define('entities/caccounts/species',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/userCodelist',
    'entities/caccounts/lisSpecies'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    UserCodeLists
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Specie = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/species/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'lisSpecies': [],
            'tags': [],
            'criterias': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'lisSpecies',
            relatedModel: 'LisSpecie',
            collectionType: 'LisSpecieCollection',
            includeInJSON: ['secId', 'code', 'name']
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/species');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'SpecieJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Specie',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    module.exports = _.defaults({
        getName: function () {
            return 'specie';
        },
        getUrl: function () {
            return 'v2/species';
        },
        getDynamicJson: function () {
            return 'SpecieJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Specie.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refCycler.code']};
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push({'field': 'lisSpecies', 'param': {'type': 'LIST2ENTITY'}});
            return custom;
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration();
            configuration.done(function (resp) {
                resp.push({fieldName: 'criterias', values: UserCodeLists.findItemsByCode('FCRIT_SPECIE'), codeListCode: 'FCRIT_SPECIE'});
                defer.resolve(resp);
            });
            return defer.promise();
        }
    }, Dynamic);
});
