/**
 * Created by RKL on 13/08/2015.
 */
define('runAssaysTargetsTabController',[
    'module',
    'underscore',
    'jquery',
    'backbone',
    'app',
    'runAssaysTargetsTabView',
    'wellDisplayGraphView',
    'targetsView',
    'emptyView',
    'entities/caccounts/pcrwells',
    'settings'
], function (
    module,
    _,
    $,
    Backbone,
    App,
    View,
    DisplayGraphLayoutView,
    TargetsView,
    EmptyView
) {
    'use strict';

    module.exports = {

        filtersList: [],

        setFiltersList: function (wellFiltersList) {
            this.filtersList = wellFiltersList;
        },

        getFiltersList: function () {
            return this.filtersList;
        },

        showAssaysTargetsTab: function (region, model, from, step, to) {
            this.region = region;
            var loader = $('.js-global-loader');
            loader.show();
            this.model = model;
            this.createView(from, step, to);
            this.cSelection = [];
        },

        createView: function (from, step, to) {
            this.fetchAssayVersions().done(_.bind(function () {
                this.view = new View({
                    model: this.model,
                    from: from,
                    step: step,
                    to: to,
                    controller: this
                });
                this.region.show(this.view);
            }, this));
        },

        fetchAssayVersions: function () {
            var assayVersions = this.model.get('wells').chain().filter(function (well) {
                return well.get('assayVersion');
            }).map(function (well) {
                return well.get('assayVersion');
            }).uniq().value();
            var defer = $.Deferred();
            var deferreds = [];
            _.each(assayVersions, function (assayVersion) {
                deferreds.push(assayVersion.fetch());
            });
            $.when.apply($, deferreds).done(function () {
                defer.resolve();
            });
            return defer.promise();
        },

        onValViewRepeatPcr: function () {
            console.log('onValViewRepeatPcr');
            this.onRepeatSelectedWells('PT', null);
        },

        onValViewRepeatPcrControl: function () {
            console.log('onValViewRepeatPcrControl');
            this.onRepeatSelectedWells('PC', null);
        },

        onValViewRepeatExtraction: function (dilution) {
            console.log('onValViewRepeatExtraction');
            console.log('dilution = ' + dilution);
            this.onRepeatSelectedWells('XT', dilution);
        },

        onValViewRepeatExtractionControl: function (dilution) {
            console.log('onValViewRepeatExtractionControl');
            console.log('dilution = ' + dilution);
            this.onRepeatSelectedWells('XC', dilution);
        },

        onRepeatSelectedWells: function (status, dilution) {
            if (this.displayGraphView && this.displayGraphView.currentModels &&
                this.displayGraphView.currentModels.length > 0) {
                this.currentModelsSecIds = [];
                _.each(this.displayGraphView.currentModels, _.bind(function (wellModel) {
                    this.currentModelsSecIds.push(wellModel.get('secId'));
                }, this));

                this.emptyView = new EmptyView();
                this.view.getRegion('loader').show(this.emptyView);
                var tempWell = this.displayGraphView.currentModels[0];

                tempWell.repeat(status, dilution, this.currentModelsSecIds)
                    .done(_.bind(function (result) {
                        if (result.run) {
                            App.trigger('well:refresh:well', null, result.run.wells);
                        } else {
                            App.trigger('well:refresh:well', null, result.wells);
                        }
                    }, this))
                    .always(_.bind(function () {
                        App.trigger('run:refresh', this.model.get('secId'), _.bind(function () {
                            this.emptyView.destroy();
                            $('.empty-page-loader').each(function () {
                                $(this).hide();
                            });
                        }, this));
                    }, this));
            }
        }
    };
});
