define('superAdminDocumentsView',[
	'module',
	'marionette',
	'underscore',
	'jquery',
	'app',
	'settings',
	'jqgridView',
	'template!superAdminDocumentsListTpl',
	'documentCreateEditLayoutView',
	'customBootboxMessage',
	'entities/admin/document'
], function (
	module,
	Marionette,
	_,
	$,
	App,
	Settings,
	JqGridView,
	viewTpl,
	CreateEditLayoutView,
	CustomBootboxMessage
) {
	'use strict';

	module.exports = JqGridView.extend({
		template: viewTpl,
		filtersName: 'documentsListFilters',

		triggers: {},

		ui: function () {
			return _.extend({
				jqGrid: '#jq-grid-list',
				jqGridPager: '#jq-grid-pager-list',
				viewDocument: '.view-document-link',
				updateDocuments: '.js-update-button',
				loader: '.empty-page-loader'
			}, this.gridUi);
		},

		events: function () {
			return _.extend({
				'click @ui.viewDocument': 'onViewDocument',
				'click @ui.updateDocuments': 'onUpdateDocuments'
			}, this.gridEvents);
		},

		onViewDocument: function (event) {
			var url = Settings.url('compuzz', 'public/document/getfile/' + $(event.currentTarget).find('span').attr('data-row-id')) + '#toolbar=0';
			window.open(url, 'documentation', 'width=' + window.outerWidth * 0.6 + ',height=' + window.outerWidth * 0.7);
		},

		onUpdateDocuments: function () {
			this.triggerMethod('document:update');
			var params = {
				title: 'Update of documentation',
				message: 'Json object, Glossary, Codelist and Array documentation are available. For GDrive documents process can take up to 15 minutes',
				type: 'info'
			};
			CustomBootboxMessage.customAlert(params);
			App.navigate('superAdmin/documents/refresh', {trigger: false});
			App.navigate('superAdmin/documents', {trigger: true, replace: true});
		},

		gridOptions: function (data) {
			var options = {
				colModel: [
					{
						label: '',
						name: 'secId',
						key: true,
						hidden: true
					}, {
						label: _.i18n('common.name'),
						name: 'name',
						classes: 'document-link',
						formatter: this.defaultFormatter,
						search: true,
						index: 'name',
						searchoptions: {size: 10},
						width: 150
					}, {
						label: _.i18n('document.contentType'),
						name: 'contentType.code',
						classes: 'document-link',
						formatter: this.defaultFormatter,
						search: true,
						index: 'contentType',
						width: 100
					}, {
						label: _.i18n('common.language'),
						name: 'language.code',
						classes: 'document-link',
						formatter: this.defaultFormatter,
						search: false,
						index: 'language',
						width: 100
					}, {
						label: _.i18n('document.isValidated'),
						name: 'validated',
						classes: 'document-link',
						formatter: this.simpleBooleanFormatter,
						search: true,
						index: 'validated',
						searchoptions: {
							sopt: ['eq', 'ne'], value:
								':' + _.i18n('common.all') +
								';true:' + _.i18n('common.yes') +
								';false:' + _.i18n('common.no')
						},
						stype: 'select',
						width: 100
					}, {
						label: _.i18n('document.keywords'),
						name: 'keywords',
						classes: 'document-link',
						formatter: this.defaultFormatter,
						search: true,
						index: 'keywords',
						width: 100
					}, {
						label: ' ',
						name: 'view',
						search: false,
						classes: 'view-document-link view-link ',
						formatter: this.viewIconFormatter,
						width: 20
					}
				],

				onSelectRow: function (rowId, bool, e) {
					if (!$(e.target).hasClass('cell-view') && e.type === 'click') {
						if (e.type === 'click') {
							var model = App.request('document:model', {secId: rowId});
							model.fetch().done(function () {
								var createEditView = new CreateEditLayoutView({
									model: model
								});
								createEditView.show({
									title: _.i18n('menu.superadmin.document'),
									className: 'baseTableEditAddPopup'
								});
							});
						}
					}
				},
				sortname: 'contentType'
			};

			options = _.defaults(options, this.gridInitOptions(data));

			return options;
		}
	});
});
