/**
 * Created by GSP on 7/01/2016.
 */
define('itemPagesNameMixin',['module'], function (module) {
    'use strict';

    module.exports = {
        ACCOUNT: 'account',
        PROCESS_CTRL: 'process_control',
        MY_ACCOUNT: 'account_account',
        GRAPHICAL_ID: 'account_graphicalid',
        SAMPLE_MANAGMENT: 'sample_managment',
        PREANALYTIC: 'preanalytic',

        //DASHBOARD
        DASHBOARD: 'dashboard',

        // PRODUCTS
        PRODUCTS: 'products',
        MY_PRODUCTS: 'products_products',
        PRODUCTS_PRICELISTS: 'products_pricelists',
        PRODUCTS_CATALOGUES: 'products_catalogues',

        //CLIENTS
        CLIENTS: 'customers',
        MY_CLIENTS: 'customers_customers',
        //SUPPLIERS
        SUPPLIERS: 'suppliers',
        SUPPLIERS_CATALOGUES: 'suppliers_catalogues',

        //GRAPHICAL REQUESTS
        GRAPHICALREQUESTS: 'graphicalRequests',
        GRAPHICALREQUESTS_ALL_IN_ONE: 'graphicalRequests_all_In_One',
        GRAPHICALREQUESTS_VECTORIZATION: 'graphicalRequests_vectorization',
        GRAPHICALREQUESTS_FILECONVERSION: 'graphicalRequests_fileConversion',
        GRAPHICALREQUESTS_REMOVEBACKGROUND: 'graphicalRequests_RemoveBackground',

        //ADMIN
        ADMIN: 'admin',
        ADMIN_USERCODELISTS: 'admin_userCodeLists',
        ADMIN_TIMETRACKING: 'admin_timeTracking',
        ADMIN_KITS_PCR: 'admin_kits_pcr',
        ADMIN_KITS_EXTRACTION: 'admin_kits_extraction',
        ADMIN_COLORCOMPENSATION: 'admin_colorCompensation',
        ADMIN_CYCLERS: 'admin_cyclers',
        ADMIN_LMBCYCLERS: 'admin_lmbCyclers',
        ADMIN_EXTRACTORS: 'admin_extractors',
        ADMIN_LMBEXTRACTOR: 'admin_lmbExtractor',
        ADMIN_SAMPLEHANDLERS: 'admin_sampleHandlers',
        ADMIN_LMBSAMPLEHANDLER: 'admin_lmbSampleHandler',
        ADMIN_LMBANARES: 'admin_lmbanares',
        ADMIN_MBANAS: 'admin_mbAnas',
        ADMIN_MBANARESGRS: 'admin_mbAnaResGrs',
        ADMIN_KITPROTS: 'admin_kitProts',
        ADMIN_ASSAYS: 'admin_assay',
        ADMIN_ASSAYDEFINITION: 'admin_assayDefinition',
        ADMIN_ASSAYGROUP: 'admin_assayGroup',
        ADMIN_ROUTINGGROUP: 'admin_routingGroup',
        ADMIN_ROUTINGACTION: 'admin_routingAction',
        ADMIN_PREPROCESS: 'admin_preProcess',
        ROUTING: 'sample_routing',
        ADMIN_FILEFORMAT_PLATELAYOUT: 'admin_fileFormat_plateLayout',
        ADMIN_FILEFORMAT_SAMPLELIST: 'admin_fileFormat_sampleList',
        ADMIN_LIS_MENU: 'admin_lis_menu',
        ADMIN_LIS: 'admin_lis',
        ADMIN_LISANA: 'admin_lisana',
        ADMIN_LISBIOGROUP: 'admin_lisBioGroup',
        ADMIN_LISSPECIE: 'admin_lisSpecie',
        ADMIN_SUBACCOUNTS: 'subAccountCreator',
        ADMIN_SETTING: 'admin_setting',
        ADMIN_EXPORT: 'admin_export',
        ADMIN_PCRREAGENT: 'admin_PCRREAGENT',
        ADMIN_PCRREAGENTTYPE: 'admin_PCRREAGENTTYPE',
        ADMIN_PCRREAGENTLOT: 'admin_PCRREAGENTLOT',
        ADMIN_EXTRACTIONMETHOD: 'admin_EXTRACTIONMETHOD',
        ADMIN_SUPPLIER: 'admin_supplier',
        ADMIN_INSTRUMENTS: 'admin_instruments',
        ADMIN_TARGETDEFINITION: 'admin_targetDefinition',
        ADMIN_BIOGROUPS: 'admin_biogroups',
        ADMIN_SAMPLERESULTDEFINITION: 'admin_sampleResultDefinition',
        ADMIN_PRINTERS: 'admin_printers',
        ADMIN_REPORTS: 'admin_reports',
        ADMIN_SPECIES: 'admin_species',
        ADMIN_STANDARDCURVES: 'admin_standardCurves',
        ADMIN_SAMPLECLASS: 'admin_sampleClass',
        ADMIN_ADMINISTRATOR: 'admin_administrator',
        ACCOUNTCREATOR: 'ACCOUNTCREATOR',
        ACCOUNTSHARECREATOR: 'ACCOUNTSHARECREATOR',
        TIMETRACKER: 'TIMETRACKER',
        SUPERADMIN_STATS: 'SUPERADMIN_STATS',
        COLLECTIONS: 'COLLECTIONS',
        ADMIN_USERS: 'admin_users',
        ADMIN_PROFILE: 'admin_profile',

        CONNECTOR_HL7_TRACING: 'connector_hl7_tracing',

        //LAB
        RUN: 'run',
        RUNS: 'runs',
        PREPRUNS: 'prepruns',
        PCRRUNTEMPLATES: 'pcrRunTemplates',
        PREPRUNTEMPLATES: 'prepRunTemplates',
        RACKS: 'racks',
        SAMPLEIDQUERY: 'sampleIdQuery',
        EXTRACTRUNS: 'extractruns',
        RUNTEMPLATES: 'runTemplates',
        EXTRACTTEMPLATES: 'extractRunTemplates',
        SAMPLES: 'samples',
        ORDERS: 'orders',
        ATTACHMENTS: 'attachments',
        PCR_SETUP: 'pcrSetup',

        //SUPER ADMIN
        SUPERADMIN: 'SUPERADMIN',
        SUPERADMIN_CACCOUNTS: 'superAdmin_cAccounts',
        SUPERADMIN_USERS: 'superAdmin_users',
        SUPERADMIN_LOGS: 'superAdmin_logs',
        SUPERADMIN_CYCLERS: 'superAdmin_cyclers',
        SUPERADMIN_DETECTIONFORMATS: 'superAdmin_detectionFormats',
        SUPERADMIN_CODELISTS: 'superAdmin_codeLists',
        SUPERADMIN_ARRAYS: 'superAdmin_arrays',
        SUPERADMIN_DOCGLOSSARIES: 'superAdmin_docGlossaries',
        SUPERADMIN_APPLICATIONOBJECTDOCS: 'superAdmin_ApplicationObjectDocs',
        SUPERADMIN_DOCUMENTS: 'superAdmin_Documents',
        SUPERADMIN_MENUS: 'superAdmin_Menus'
    };
});
