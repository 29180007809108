define('entities/caccounts/colorCompensationMatriceValues',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'dynamic',
    'module'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    Dynamic,
    module
) {
    'use strict';


    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.ColorCompensationMatriceValue = Backbone.RelationalModel.extend({
        defaults: {
            'x': null,
            'y': null,
            'value': 0,
            'manualCorrection': 0
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },
        jsonObjectName: 'ColorCompensationMatriceValueJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ColorCompensationMatriceValue',
        getEntity: function () {
            return module.exports;
        },
        importable: false
    });

    app.ns.ColorCompensationMatriceValueCollection = Backbone.Collection.extend({
        model: app.ns.ColorCompensationMatriceValue,
        comparator: function (o1, o2) {
            if (o1.get('x') === o2.get('x')) {
                return o1.get('y') - o2.get('y');
            }
            return o1.get('x') - o2.get('x');
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'ColorCompensationMatrice';
        },
        getDynamicJson: function () {
            return 'ColorCompensationMatriceJson';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.ColorCompensationMatriceValue.findOrCreate(modelJson);
        }
    }, Dynamic);
});
