
define('template!prepRunWellDetailTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container right-area col-sm-12 well well-left-border" id="right-graph"\n     style="float: initial; display: inline-block; /*min-height: 840px;*/">\n    <div id="header-region"></div>\n    <ul class="nav nav-tabs row hidden">\n    </ul>\n    <div class="tab-content hidden">\n    </div>\n</div>';

}
return __p
};});

