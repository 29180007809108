define('dashboardMain',[
    'module',
    'logger',
    'dashboardMainRouter'
], function (
    module,
    Logger
) {
    'use strict';

    module.exports = {
        start: function () {
            Logger.log(module, 'starting DashboardModule');
        },

        stop: function () {
            Logger.log(module, 'stopping DashboardModule');
        }
    };
});
