define('prepRunTabsLayoutController',[
    'module',
    'prepRunTabsLayoutView',
    'app',
    'jquery',
    'underscore',
    'prepRunTabsData',
    'entities/caccounts/prepruns',
    'entities/caccounts/prepwells'
], function (
    module,
    TabsLayoutView,
    App,
    $,
    _,
    tabs
) {
    'use strict';

    module.exports = {
        model: App.request('run:prep:model'),
        View: TabsLayoutView,
        tabs: tabs,
        filters: [],
        filtersName: '',
        wellFiltersList: [],
        smpIdSearch: null,
        currentTab: null,

        setCurrentTab: function (currentTab) {
            this.currentTab = currentTab;
        },
        getCurrentTab: function () {
            return this.currentTab;
        },

        showTabsLayout: function (region, tabName, model, edit, from, step, to) {
            this._layoutView = new TabsLayoutView({
                model: model,
                tabs: this.tabs,
                edit: edit,
                from: from,
                step: step,
                to: to,
                tabName: tabName
            });

            this.region = region;
            region.show(this._layoutView);
            this._layoutView.triggerMethod('show:active', tabName, '');
            return this._layoutView.getRegion('tabs');
        },

        getFilteredWells: function () {
            return this.filteredWellsCollection;
        },

        getFilteredWellsCollection: function () {
            //------------ utilisation des filtres avec 'ET' logique &&
            this.filteredWellsCollection = [];
            var first = true;
            var filteredArray = [];
            _.each(this.wellFiltersList, _.bind(function (wellFilter) {
                var wellsFromFilter = [];
                wellsFromFilter = this.getWellsFromFilter(wellFilter);

                if (filteredArray) {
                    if (filteredArray.length < 1 && first) {
                        filteredArray = wellsFromFilter;
                        first = false;
                    } else if (filteredArray.length > 0 && wellsFromFilter && wellsFromFilter.length > 0) {
                        filteredArray = filteredArray.filter(function (n) {
                            return wellsFromFilter.indexOf(n) !== -1;
                        });
                    } else if (wellsFromFilter === null) {
                        filteredArray = [];
                    }
                }

            }, this));
            this.filteredWellsCollection = filteredArray;
            return this.filteredWellsCollection;
        },

        onWellSmpidSearch: function (result) {
            if (result && result !== '') {
                this.smpIdSearch = result;
            } else {
                this.smpIdSearch = null;
            }
        },

        getWellsFromFilter: function (wellFilter) {
            var wells = this._layoutView.model.get('wells');
            var filteredWells = [];
            var errorMessage = '';

            switch (wellFilter) {
                case 'select-smpId':
                    filteredWells = this.getSmpidFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noSmpid');
                    break;
                case 'select-comment':
                    filteredWells = this.getCommentFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noComment');
                    break;
                case 'select-qcSample':
                    filteredWells = this.getQcSampleFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noQcSample');
                    break;
                case 'select-unKnowSample':
                    filteredWells = this.getUnKnowSampleFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noUnKnowSample');
                    break;
                case 'select-duplicates':
                    filteredWells = this.getDuplicateSampleFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.duplicates');
                    break;
                case 'select-wrongSid':
                    filteredWells = this.getWrongIdSampleFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noWrongSid');
                    break;
                case 'select-emptySid':
                    filteredWells = this.getEmptySampleFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noEmptySid');
                    break;
                case 'select-withoutIC':
                    filteredWells = this.getWithoutIcFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noWithoutIC');
                    break;
                case 'select-withOneIc':
                    filteredWells = this.getWithOneIcFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noWithOneIC');
                    break;
                case 'select-withMoreIcs':
                    filteredWells = this.getWithMoreIcFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noWithMoreICs');
                    break;
                case 'select-waiting':
                    filteredWells = this.getWaitingFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noWaiting');
                    break;
                case 'select-running':
                    filteredWells = this.getRunningFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noRunning');
                    break;
                case 'select-done':
                    filteredWells = this.getDoneFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noDone');
                    break;
                case 'select-repeat-prep':
                    filteredWells = this.getRepeatFilteredWell(wells, filteredWells);
                    errorMessage = _.i18n('error.noRepeatPrep');
                    break;
            }
            if (filteredWells.length > 0) {
                return filteredWells;
            } else {
                return null;
            }
        },

        getRepeatFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('repeat')) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getSmpidFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            if (this.smpIdSearch !== null) {
                var result = this.smpIdSearch;
                wells.each(_.bind(function (well) {
                    var regex = new RegExp(result, 'i');
                    if (regex.test(well.get('smpId').get('name'))) {
                        filteredWellsToReturn.push(well.get('pos'));
                    }
                }, this));
                return filteredWellsToReturn;
            }
        },

        getCommentFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('sampleComment') || well.get('sampleLisComment')) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getQcSampleFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('smpType').includes('C')) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getUnKnowSampleFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('smpType').includes('U')) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getDuplicateSampleFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;

            var smpids = [];
            wells.each(_.bind(function (well) {
                var smpId = well.get('smpId').get('name');
                if (smpId) {
                    smpids.push(smpId);
                }
            }, this));

            var smpidsByCount = _.countBy(smpids, function (item) {
                return item;
            });

            for (var smpId in smpidsByCount) {
                if (smpidsByCount[smpId] === 1 && smpId !== '') {
                    delete smpidsByCount[smpId];
                }
            }

            wells.each(_.bind(function (well) {
                for (var smpId in smpidsByCount) {
                    if (smpId === well.get('smpId').get('name')) {
                        filteredWellsToReturn.push(well.get('pos'));
                        break;
                    }
                }
            }, this));
            return filteredWellsToReturn;
        },

        getWrongIdSampleFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            if (this._layoutView.model && this._layoutView.model.get('settings') && this._layoutView.model.get('settings').SID) {
                var controlSid = this._layoutView.model.get('settings').SID.split(';')[4] ? this._layoutView.model.get('settings').SID.split(';')[4] : null;
                var lengthMin = this._layoutView.model.get('settings').SID.split(';')[2] ? this._layoutView.model.get('settings').SID.split(';')[2] : null;
                var lengthMax = this._layoutView.model.get('settings').SID.split(';')[3] ? this._layoutView.model.get('settings').SID.split(';')[3] : null;

                if (controlSid && controlSid === 'Y' && lengthMin && lengthMax) {
                    wells.each(_.bind(function (well) {
                        if (well.get('smpId').get('name') && (well.get('smpId').get('name').length < lengthMin || well.get('smpId').get('name').length > lengthMax)) {
                            filteredWellsToReturn.push(well.get('pos'));
                        }
                    }, this));
                }
            }
            return filteredWellsToReturn;
        },

        getEmptySampleFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (!well.get('smpId').get('name')) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getWithoutIcFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('kitLotByKits').length < 1) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getWithOneIcFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('kitLotByKits').length === 1) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getWithMoreIcFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('kitLotByKits').length > 1) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getWaitingFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                var assayStatusCount = well.getAssayStatusCount();
                if (assayStatusCount.waiting > 0) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getRunningFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                var assayStatusCount = well.getAssayStatusCount();
                if (assayStatusCount.running > 0) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getDoneFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                var assayStatusCount = well.getAssayStatusCount();
                if (assayStatusCount.done > 0) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        onWellFiltersListChange: function (selectedWellFilter) {
            if (this.wellFiltersList.includes(selectedWellFilter)) {
                var i = this.wellFiltersList.indexOf(selectedWellFilter);
                this.wellFiltersList.splice(i, 1);
            } else {
                this.wellFiltersList.push(selectedWellFilter);
            }
        },

        removeAllFiltersFromList: function () {
            this.wellFiltersList = [];
            this.smpIdSearch = null;
        },

        removeSmpIdSearchFromWellFiltersList: function () {
            this.wellFiltersList = _.filter(this.wellFiltersList,
                function (filter) {
                    if (filter.includes('select-smpId')) {
                        return false;
                    } else {
                        return true;
                    }
                });
            this.smpIdSearch = null;
        },

        getWellFiltersList: function () {
            return this.wellFiltersList;
        }
    };
});
