define('pcrWellResultCollectionView',[
    'module',
    'marionette',
    'template!pcrWellResultCollectionView',
    'pcrWellResultOvarItemView',
    'pcrWellResultCurveItemView',
    'pcrWellResultMagPixItemView',
    'pcrWellResultOtherItemView',
    'pcrWellResultCalculatedItemView',
    'pcrWellResultHL7ItemView',
    'pcrWellResultSPOOvarItemView'
], function (
    module,
    Marionette,
    Tpl,
    PcrWellResultOvarItemView,
    PcrWellResultCurveItemView,
    PcrWellResultMagPixItemView,
    PcrWellResultOtherItemView,
    PcrWellResultCalculatedItemView,
    PcrWellResultHL7ItemView,
    PcrWellResultSPOOvarItemView
) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
        template: Tpl,
        attributes: {
            style: 'display: flex; flex-direction: column;'
        },
        className: 'well-results',

        getChildView: function (item) {
            switch (item.get('type')) {
                case 'CURVE':
                    return PcrWellResultCurveItemView;
                case 'MAGPIX':
                    return PcrWellResultMagPixItemView;
                case 'OVAR':
                    return PcrWellResultOvarItemView;
                case 'CALCULATED':
                    return PcrWellResultCalculatedItemView;
                case 'HL7':
                    return PcrWellResultHL7ItemView;
                case 'SPO_OVAR':
                    return PcrWellResultSPOOvarItemView;
                default:
                    return PcrWellResultOtherItemView;
            }
        }
    });
});
