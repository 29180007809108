
define('template!dynamicCreateEdit', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="dynamic-regions">\n\n\t\t\t</div>\n\t\t\t<div class="row cancelConfirmRow">\n\t\t\t\t<div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n\t\t\t\t\t<button disabled\n\t\t\t\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

