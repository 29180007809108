
define('template!mbAnaCreateEditLayoutView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="code">\n\t\t\t\t\t\t<label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.code.name') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-1">\n\t\t\t\t\t<div class="form-group" data-field-name="code">\n\t\t\t\t\t\t<input type="text"\n\t\t\t\t\t\t\t   class="form-control floating-label js-info-input js-code dataNameIdInput inputBackground"\n\t\t\t\t\t\t\t   data-field-name="code" placeholder=""\n\t\t\t\t\t\t\t   value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   data-placement="top" autocomplete="off"\n\t\t\t\t\t\t\t   style="width: 100px;">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-7">\n\t\t\t\t\t<div class="form-group" data-field-name="name">\n\t\t\t\t\t\t<input type="text"\n\t\t\t\t\t\t\t   class="form-control floating-label js-info-input dataLocationInput inputBackground"\n\t\t\t\t\t\t\t   data-field-name="name" placeholder=""\n\t\t\t\t\t\t\t   value="' +
((__t = ( name )) == null ? '' : __t) +
'" autocomplete="off"\n\t\t\t\t\t\t\t   style="width: 455px; margin-left: 45px;">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="">\n\t\t\t\t\t\t<label class="control-label dataLabel">' +
((__t = ( _.i18n('lmbanas.resultgroup') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8">\n\t\t\t\t\t<div class="form-group" data-field-name="">\n\t\t\t\t\t\t<div class="js-select-mbanaresgr-region dataNameIdInput inputBackground"\n\t\t\t\t\t\t\t style="width: 100px;"></div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="lisCode">\n                        <label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('lmbanas.lis.code.reportName')
                            )) == null ? '' : __t) +
'</label>\n                    </div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-1">\n\t\t\t\t\t<div class="form-group" data-field-name="lisCode">\n\t\t\t\t\t\t<input type="text" id="lisCode"\n\t\t\t\t\t\t\t   class="form-control floating-label js-info-input js-code  inputBackground"\n\t\t\t\t\t\t\t   data-field-name="lisCode" placeholder=""\n\t\t\t\t\t\t\t   value="' +
((__t = ( lisCode )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   data-placement="top" autocomplete="off"\n\t\t\t\t\t\t\t   style="width: 100px;">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-7">\n\t\t\t\t\t<div class="form-group" data-field-name="lisText">\n                        <textarea type="text" class="form-control floating-label js-info-input inputBackground"\n\t\t\t\t\t\t\t\t  data-field-name="lisText" placeholder=""\n\t\t\t\t\t\t\t\t  autocomplete="off"\n\t\t\t\t\t\t\t\t  style="width: 455px; margin-left: 45px;resize: vertical;">' +
((__t = ( lisText )) == null ? '' : __t) +
'</textarea>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 dataLabelContainer">\n\t\t\t\t\t<div class="form-group" data-field-name="lisVariantParam">\n\t\t\t\t\t\t<label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('lmbanas.lis.variant') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8 js-lisVariant" data-field-name="lisVariant">\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 " style="text-align: right;">\n\t\t\t\t\t<label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('criterias')
						)) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8  p-l-0 js-criterias" style="margin-top: 8px;">\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 " style="text-align: right;">\n\t\t\t\t\t<label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.assay') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8 js-select-assay" style="margin-top: 8px;">\n\t\t\t\t\t';
 _.each(assays, function(assay) { ;
__p += '\n\t\t\t\t\t<span data-id="' +
((__t = (assay.secId)) == null ? '' : __t) +
'" style="cursor: pointer;text-decoration-line: underline;">' +
((__t = ( assay.code )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 " style="text-align: right;">\n\t\t\t\t\t<label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('menu.assay.assayGroup') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8 js-select-assaygroup" style="margin-top: 8px;cursor: pointer;">\n\t\t\t\t\t';
 _.each(assaygroups, function(assaygroup) { ;
__p += '\n\t\t\t\t\t<span data-id="' +
((__t = (assaygroup.secId)) == null ? '' : __t) +
'" style="cursor: pointer;text-decoration-line: underline;"> ' +
((__t = ( assaygroup.code )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 " style="text-align: right;">\n\t\t\t\t\t<label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('menu.lis.lisana') )) == null ? '' : __t) +
'</label>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8 js-select-lisana" style="margin-top: 8px;cursor: pointer;">\n\t\t\t\t\t';
 _.each(lisanas, function(lisana) { ;
__p += '\n\t\t\t\t\t<span data-id="' +
((__t = (lisana.secId)) == null ? '' : __t) +
'" style="cursor: pointer;text-decoration-line: underline;"> ' +
((__t = ( lisana.code )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class="js-description m-t-5 m-b-5"></div>\n\n\t\t\t<div class="row cancelConfirmRow">\n\t\t\t\t<div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n\t\t\t\t\t<button disabled\n\t\t\t\t\t\t\tclass="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

