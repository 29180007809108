define('docGlossaryCreateEditLayoutView',[
	'module',
	'marionette',
	'backbone',
	'dialogFormView',
	'template!docGlossaryCreateEditLayoutTpl',
	'savingBehavior',
	'duplicatePopupBehavior',
	'docUrlCollectionView',
	'bootbox',
	'underscore',
	'app'
], function (
	module,
	Marionette,
	Backbone,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	DuplicatePopupBehavior,
	DocUrlCollectionView,
	bootbox,
	_,
	App
) {
	'use strict';

	var CreateEditLayoutView = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input'
		},

		events: {
			'click .js-cancel-popup': 'onCancel',
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-confirm': 'onConfirm',
			'click .js-add-docurl': 'onAddDocUrl'
		},

		regions: {
			docUrlsTable: '.docUrlsTable'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			},
			DuplicatePopup: {
				behaviorClass: DuplicatePopupBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		fieldsToValidate: [
			{name: 'name', type: 'required'}
		],

		initialize: function (options) {
			this.model = options.model.clone();
			DialogFormView.prototype.initialize.call(this);
		},

		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.newItem = this.options.newItem;
			return templateData;
		},

		onShow: function () {
			$.material.init();
			this.ui.input.popover({
				trigger: 'manual'
			});
			var docUrlsCollection =  App.request('document:collection',this.model.get('documents'));
			this.docUrlsCollectionView = new DocUrlCollectionView({
				collection: docUrlsCollection
			});
			this.getRegion('docUrlsTable').show(this.docUrlsCollectionView);
			var that = this;
			setTimeout(function() {
				$('.js-generate-pdf').click(function() {
					this.generatePdf = true;
					that.onConfirm();
				});
			},500);
			this.triggerMethod('enable:cancel:confirm');
		},

		onConfirm: function () {
			if (this.validate(this.fieldsToValidate)) {
				this.model.set('documents', this.docUrlsCollectionView.collection.models);
				this.model.save();
			}
		},

		onSave: function (model) {
			var modelJSON = model.toJSON();
			this.model.set(modelJSON);
			if (this.generatePdf === true) {
				App.request('docglossary:generate-pdf',this.model.get('secId'));
			}
			this.box.modal('hide');
		},

		hide: function () {
			if (App.getCurrentRoute() === 'superAdmin/docglossaries/new') {
				App.navigate('superAdmin/docglossaries', {trigger: true});
			}
			else{
				App.navigate('superAdmin/docglossaries/refresh', {trigger: false});
				App.navigate('superAdmin/docglossaries', {trigger: true, replace: true});
			}
		},

		onSaveError: function (model, response) {
			if (response.status === 409) {
				this.onDuplicateFieldValue(response.responseJSON);
				this.triggerMethod('hide:label');
			}
		},

		onCancel : function() {
			this.box.modal('hide');
		},

		onDuplicateFieldValue: function (codeError) {
			var fields = this.$el.find('*[data-field-name="' + codeError.field + '"]');
			fields.each(function () {
				if ($(this).val() === codeError.value) {
					$(this).popover('show');
					setTimeout(function () {
						$('.popover').fadeOut('slow', function () {
						});
					}, 2000);
					$(this).css('border-color', 'red');
					$(this).addClass('invalid');
					$('div[data-field-name="' + codeError.field + '"]').addClass('has-error');
				}
			});
		},
		onAddDocUrl: function () {
			var collection = App.request('document:model', null);
			this.docUrlsCollectionView.collection.add(collection);
			$('.js-select-document-region').width(250);
			$('.js-select-document-region').find('input').width(250);
		}
	});

	module.exports = CreateEditLayoutView;
});
