define('dynamicCreateEditTextarea',[
    'module',
    'formView',
    'underscore',
    'jquery',
    'marionette'
], function (
    module,
    FormView,
    _,
    $,
    Marionette
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template('<%= value %>'),
        className: 'form-control js-info-input inputBackground',
        tagName: 'textarea',

        attributes: function () {
            var attributes = {};
            attributes['data-field-name'] = this.options.field;
            attributes.placeholder = '';
            attributes.autocomplete = 'off';
            if (this.options.display && this.options.display.readOnly) {
                attributes.disabled = 'disabled';
                attributes.readonly = 'readonly';
            }
            attributes.rows = this.options.display && this.options.display.rows ? this.options.display.rows : 3;
            return attributes;
        },

        initialize: function () {
            this.listenTo(this.model, 'change:' + this.options.field, _.bind(this.render, this));
        },
        serializeData: function () {
            var templateData = {};
            templateData.value = this.model.get(this.options.field);
            return templateData;
        },
        onShow: function () {
            if (this.options.display && this.options.display.tooltip) {
                var content = Marionette.Renderer.render(this.options.display.tooltip);
                this.$el.popover({
                    trigger: 'hover',
                    html: true,
                    placement: 'auto',
                    delay: {
                        show: '500',
                        hide: '100'
                    },
                    content: content,
                    container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content-region')
                }).data('bs.popover');
            }
        }
    });
});
