define('assayReagentMbAnaPcrKitLotByPcrWell',[
	'module',
	'marionette',
	'underscore',
	'jquery',
	'template!assayReagentMbAnaPcrKitLotByPcrWell',
	'dialogFormView',
	'dateUtils',
	'qcChartView'
], function (
	module,
	Marionette,
	_,
	$,
	Tpl,
	DialogFormView,
	DateUtils,
	QcChartView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		ui: {
			input: '.js-info-input-child'
		},
		regions: {
			result: '.js-result',
			chart: '.js-show-graphics-region'
		},

		events: {
			'change @ui.input': 'onInputChange'
		},
		className: 'rounded col-xs-12 m-b-5 m-l-5',

		initialize: function () {
			// only display values that are not null or bigger than 0
			this.values = _.filter(this.model.get('values'), function (value) {
				return value.value;
			});
		},

		serializeData: function () {
			var assayReagentTargetPcrKitLot = this.model.get('assayReagentTargetPcrKitLot');
			var templateData = this.model;
			templateData.displayedKitCode = assayReagentTargetPcrKitLot.refPcrKitLot.pcrKit.code;
			templateData.displayedKitLotId = assayReagentTargetPcrKitLot.refPcrKitLot.code;
			templateData.delivery = assayReagentTargetPcrKitLot.refPcrKitLot.delivery ? DateUtils.toDateFormatStringFromSettings(assayReagentTargetPcrKitLot.refPcrKitLot.delivery, this.settings) : '';
			templateData.beginUse = assayReagentTargetPcrKitLot.refPcrKitLot.beginUse ? DateUtils.toDateFormatStringFromSettings(assayReagentTargetPcrKitLot.refPcrKitLot.beginUse, this.settings) : '';
			templateData.endUse = assayReagentTargetPcrKitLot.refPcrKitLot.endUse ? DateUtils.toDateFormatStringFromSettings(assayReagentTargetPcrKitLot.refPcrKitLot.endUse, this.settings) : '';
			templateData.expiration = assayReagentTargetPcrKitLot.refPcrKitLot.expiration ? DateUtils.toDateFormatStringFromSettings(assayReagentTargetPcrKitLot.refPcrKitLot.expiration, this.settings) : '';
			templateData.firstRun = _.first(this.values) ? _.first(this.values).pcrWell.pcrRun.name : '';
			templateData.lastRun = _.last(this.values) ? _.last(this.values).pcrWell.pcrRun.name : '';
			templateData.title = this.model.get('assayReagentTargetPcrKitLot').refAssayReagent.refAssay.code + ' - ' + this.model.get('assayReagentTargetPcrKitLot').refMbAna.code;
			templateData.target = ' (' + this.model.get('assayReagentTargetPcrKitLot').refMbAna.name + ')';
			templateData.smpTypeIcon = this.options.smpTypeIcon;
			return templateData;
		},
		onShow: function () {
			$.material.init();

			var graphView = new QcChartView({
				type: 'line',
				pcrKitLotValueRuns: this.values,
				controls: this.model.get('assayReagentTargetPcrKitLot'),
				title: this.model.get('assayReagentTargetPcrKitLot').refMbAna.code + ' (' + this.model.get('assayReagentTargetPcrKitLot').refMbAna.name + ')',
				currentPcrWell: this.options.wellModel.get('secId'),
				height: 300
			});
			this.getRegion('chart').show(graphView);
		}
	});
});
