/**
 * Created by Matteo on 14/07/2015.
 */
define('selectCaccountListController',[
	'require',
	'backbone',
	'module',
	'logger',
	'jquery',
	'underscore',
	'app',
	'settings',
	'entities/global/caccounts',
	'selectCaccountListView',
	'entities/caccounts/userpids'
], function (
	require,
	Backbone,
	module,
	Logger,
	$,
	_,
	App,
	Settings,
	CAccountEntities,
	ListView,
	Userpids
) {
	'use strict';

	module.exports = {

		listCAccounts: function () {
			if (!Settings.get('currentCAccount') || !Settings.get('tokenId')) {
				var that = this,
					cAccountCount = CAccountEntities.getCAccountCount(),
					listView;

				this.view = listView;
				cAccountCount.done(function (count) {
					if (count === 1) {
						that.skipSelectionPage();
						return;
					}

					listView = new ListView();

					that.view = listView;

					listView.listenTo(listView, 'onSignin', _.bind(that.onSignin, that));

					App.regions.getRegion('main').show(listView);
				});
			} else {
				App.trigger('index:show');
			}
		},

		onSignin: function () {
			var that = this,
				cAccount = this.view.selectedAccount,
				postCAccount;

			if (cAccount) {
				this.view.ui.signin.addClass('disabled');
				postCAccount = App.request(
					'caccount:post-caccount',
					cAccount.get('secId')
				);
				postCAccount.done(function (response) {
					Settings.set('tokenId', response.tokenId);
					Settings.set('currentCAccount', response.caccount);
					that.view.ui.signin.removeClass('disabled');
					Userpids.getCurrentUser().done(function(currentUser) {
						Settings.set('currentUser', currentUser);
						if (!Backbone.History.started) {
							Backbone.history.start();
						}
						App.trigger('index:show');
					});
				});
			} else {
				this.view.onError();
			}
		},

		skipSelectionPage: function () {
			var fetchCAccounts = CAccountEntities.getCAccountEntities(),
				postCAccount;

			fetchCAccounts.done(function (cAccounts) {
				postCAccount = App.request(
					'caccount:post-caccount',
					cAccounts.at(0).get('secId')
				);

				postCAccount.done(function (response) {
					Settings.set('tokenId', response.tokenId);
					Settings.set('currentCAccount', cAccounts.at(0).toJSON());
					App.trigger('index:show');
				});
			});
		}
	};
});
