/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemLabel',[
	'module',
	'underscore',
	'dialogFormView'
], function (
	module,
	_,
	DialogFormView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: _.template('<%= value %>'),
		tagName: 'span',
		serializeData: function () {
			var templateData = {};
			var entity = this.model.getEntity();
			templateData.value = _.i18n(entity.getName() + '.' + this.model.get(this.options.field.field) + '.name');
			return templateData;
		}
	});
});
