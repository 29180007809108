/**
 * Created by RKL on 21/08/2015.
 */
define('subMenuView',[
    'module',
    'backbone',
    'marionette',
    'app',
    'menuItemsUtils',
    'underscore',
    'settings',
    'template!subMenusViewTpl'
], function (
    module,
    Backbone,
    Marionette,
    App,
    MenuItemUtils,
    _,
    Settings,
    viewTpl

) {
    'use strict';

    var MainMenuView = Marionette.LayoutView.extend({
        template: viewTpl,
        ui: {
            content:'.content',
            navigate:'.js-navigate_to_menuel'
        },
        events: {
            'click @ui.navigate': 'navigate'
        },
        serializeData: function() {
            var templateData = {};
            if (this.model && this.model[0].link !== undefined &&this.model[0].link === 'caccount/') {
                this.model[0].link = this.model[0].link + (Settings.get('currentCAccount') ? Settings.get('currentCAccount').secId : '');
            }
            templateData.menus = this.model;
            return templateData;
        },
        onShow: function() {
            this.ui.content.parent().height('98%');
        },
        navigate: function(event) {
            var navigationUrl = _.findWhere(this.model,{secId:$(event.target).attr('data-submenu')}).link;
            App.navigate('#'+navigationUrl,{trigger:true});
        }
    });

    module.exports = MainMenuView;
});
