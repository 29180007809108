
define('template!arrayCustomCurveNoEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="t-a-l" style="min-width: 60px;">\n    ' +
((__t = ( dyeName )) == null ? '' : __t) +
'\n</div>\n\n<div class="js-input lightGrey-input-border col-w-all col-w-70 p-l-5"></div>';

}
return __p
};});

