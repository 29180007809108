/**
 * Created by OLD on 30/10/2015.
 */
define('translationsRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'cssUtils',
    'itemPagesNameMixin'
], function (
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    CssUtils,
    ItemPagesNameMixin
) {
    'use strict';

    var TranslationsRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'translations': 'showTranslations'
        }
    });

    var API = {
        showTranslations: function () {
            CssUtils.applyDefaultTheme();
            require([
                'contentShowController',
                'translationsTableController'
            ], function (ContentShowController, TranslationsTableController) {
                ContentShowController.showContent().done(function () {
                    TranslationsTableController.showTranslations();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_TRANSLATIONS);
                });
            });
        }
    };

    App.on('translations:show', function () {
        App.navigate('translations');
        API.showTranslations();
    });

    App.on('before:start', function () {
        new TranslationsRouter({
            controller: API
        });
    });

    module.exports = TranslationsRouter;
});
