define('indexController',[
        'module',
        'settings',
        'menuItemsUtils',
        'app',
        'underscore',
        'entities/caccounts/setting'
    ],
    function (
        module,
        Settings,
        MenuItemUtils,
        App,
        _,
        SettingsEntity
    ) {
        'use strict';

        module.exports = {
            show: function () {
                SettingsEntity.getAllSettingFull().done(function (settings) {
                    var menuItems = MenuItemUtils.getMenuItems(settings);
                    var currentUserModel = Settings.get('currentUserModel');
                    var lastUrlPageCookie = Settings.get('lastUrlPageCookie');
                    if (lastUrlPageCookie) {
                        Settings.set('lastUrlPageCookie', null);
                        App.navigate(lastUrlPageCookie, {trigger: true});
                    } else if (menuItems) {
                        var menuItem = _.find(menuItems, function (menuItem) {
                            if (menuItem.role && typeof menuItem.role === 'string') {
                                return currentUserModel.hasRole(menuItem.role) !== undefined;
                            } else {
                                return false;
                            }
                        });
                        if (menuItem) {
                            if (_.isEmpty(menuItem.subMenu) === false) {
                                menuItem = menuItem.subMenu[0];
                            }
                            if (menuItem.link) {
                                App.navigate('#' + menuItem.link(), {trigger: true});
                            }
                        }
                    }
                });
            }
        };
    });
