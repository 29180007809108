define('pcrSetupOneToOneView',[
	'module',
	'marionette',
	'backbone',
	'dialogFormView',
	'template!pcrSetupOneToOneTpl',
	'savingBehavior',
	'autocompleteView',
	'underscore',
	'moment',
	'app',
	'entities/caccounts/pcrruns',
	'entities/caccounts/assay',
	'entities/createPcrRunSetupOneToOne'
], function (
	module,
	Marionette,
	Backbone,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	AutocompleteView,
	_,
	moment,
	App,
	PcrRuns,
	Assays
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			blockLoading: '.empty-page-loader',
			generateStarterSheet: '.generate-starter-sheet',
			selectedDateRegion: '.js-select-date-region',
			selectedDate: '.date-select'
		},

		events: {
			'click .js-cancel-popup': 'hide',
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-confirm': 'onConfirm',
			'change @ui.generateStarterSheet': 'onChangeGenerateStarterSheet',
			'change @ui.selectedDate': 'onChangeDate'
		},

		regions: {
			selectRunTemplate: '.js-select-run-template-region',
			selectPrepRun: '.js-select-prep-run-region',
			selectAssay: '.js-select-assay-region'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		fieldsToValidate: [],

		initialize: function () {
			this.model = new Backbone.Model();
		},

		serializeData: function () {
			this.model.set('plateIdPc', null);
			this.model.set('selectedDate', Number(moment().format('x')));
			this.model.set('currentDate', moment().format('DD-MM-YYYY'));
			this.model.set('generateStarterSheet', false);
			return this.model.toJSON();
		},

		onDatePickerChange: function (fieldName) {
			this.$el.find(this.ui.selectedDate).val(moment(this.model.get(fieldName)).format('DD-MM-YYYY'));
			this.ui.selectedDate.removeClass('empty');
			App.request('run:pcr:available-for-run-template', this.model.get('refRunTemplate').get('secId'),
				{
					selectedDate: this.model.get(fieldName)
				}
			).done(_.bind(function (model) {
				this.$el.find(this.ui.numberOfRun).attr('max', parseInt(model, 10));
			}, this));
		},

		onChangeDate: function (event) {
			if (event.target.value === '') {
				this.model.set('selectedDate', -1);
			} else {
				this.model.set('selectedDate', Number(moment(event.target.value, 'DD-MM-YYYY').format('x')));
				this.onDatePickerChange('selectedDate');
			}
		},

		onShow: function () {
			$.material.init();
			this.getRegion('selectPrepRun').options.allowMissingEl = true;
			this.getRegion('selectPrepRun').show(new AutocompleteView(
				this._getAutocompleteOptions('refPrepRun', 'name', 'name', 'v2/runs/prep',
					'selectpreprun.placeholder', 'name')
			));

			this.getRegion('selectRunTemplate').options.allowMissingEl = true;
			this.getRegion('selectRunTemplate').show(new AutocompleteView(
				this._getAutocompleteOptions('refPcrRunTemplate', 'name', 'name', 'v2/runtemplates/pcr?sord=asc&sidx=seq',
					'selectruntemplate.placeholder', 'name')
			));

			this.getRegion('selectAssay').options.allowMissingEl = true;
			this.getRegion('selectAssay').show(new AutocompleteView(
				this._getAutocompleteOptionObject(
					Assays.getAutocompleteParam({
						modelProperty: 'refAssay'
					}))));
		},

		onChangeGenerateStarterSheet: function (event) {
			this.generateStarterSheet = event.target.checked;
		},

		onRender: function () {
			this.initDatePicker(this.ui.selectedDate, 'selectedDate');
		},

		onConfirm: function () {
			var request = App.request('createPcrRunSetupOneToOne:model');
			request.set('pcrRunTemplateSecId', this.model.get('refPcrRunTemplate').get('secId'));
			request.set('prepRunSecId', this.model.get('refPrepRun').get('secId'));
			request.set('assaySecId', this.model.get('refAssay').get('secId'));
			request.set('selectedDate', this.model.get('selectedDate'));
			request.set('plateIdPc', this.model.get('plateIdPc'));

			this.ui.blockLoading.show();

			request.save().done(
				_.bind(function (result) {
					App.navigate('runs/pcr', {trigger: true});
					if (this.generateStarterSheet) {
						PcrRuns.generateRunSheet(result.secId);
					}
					this.hide();
				}, this)
			).fail(
				_.bind(function (response) {
					if (response.status === 409) {
						this.ui.selectedDate.addClass('invalid');
						this.ui.selectedDateRegion.closest('div[data-field-name=selectedDate]').addClass('has-error');
					}
					this.ui.blockLoading.hide();
				}, this)
			);
		},

		onSave: function () {
		},

		hide: function () {
		},

		onSaveError: function () {
		},

		onDuplicateCode: function () {
		},

		onFocusOut: function () {
			var $target,
				datepicker;

			// close popover when click outside
			setTimeout(_.bind(function () {
				$target = $(document.activeElement);
				datepicker = $target.parents('.datepicker-element').length > 0 ||
					$target.parents('.ui-datepicker').length > 0 ||
					$target.hasClass('ui-datepicker') ||
					$target.get(0) === this.$el.get(0) ||
					$target.hasClass('popover-shown');

				if (!datepicker) this.trigger('close:popover');
			}, this), 0);
		}
	});
});
