
define('template!headerViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header-left caccount-hover-dropdown dropdown">\n    <div class="js-caccount clickable">\n        <img class="header-logo-left js-caccount-logo" src="' +
((__t = ( logo )) == null ? '' : __t) +
'" style="max-height: 40px;" alt="logo caccount">\n        <span class="js-caccount-code">' +
((__t = ( code )) == null ? '' : __t) +
'</span>\n        <span class="js-caret-caccount mdi mdi-menu-down"></span>\n    </div>\n    <div class="dropdown-menu caccounts-dropdown-container js-caccounts-autocomplete"></div>\n</div>\n\n<div class="header-center" style="padding-left: 240px;">\n    <div class="header-left" style="justify-content: flex-end;">\n        <img class="compuzz_general_logo" src="" alt="logo mobiolink">\n    </div>\n    <div class="header-center">\n        <img class="compuzz_general_title" src="" alt="logo mobiolink">\n    </div>\n    <div class="header-right" style="justify-content: flex-start; margin-top: 20px;">\n        ';
 if(label) { ;
__p += '\n        <span class="label ' +
((__t = ( label )) == null ? '' : __t) +
'">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n        ';
 } ;
__p += '\n    </div>\n</div>\n\n<div class="header-right">\n    <div class="dropdown js-user" title="' +
((__t = ( userNameTitle )) == null ? '' : __t) +
'">\n        <div class="dropdown-toggle clickable" id="user-information" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n            <div class="avatar-in-header">\n                <img src="' +
((__t = ( avatar )) == null ? '' : __t) +
'" alt="avatar">\n            </div>\n            <span class="js-user-name">' +
((__t = ( userName )) == null ? '' : __t) +
'</span>\n            <span class="js-caret-user mdi mdi-menu-down"></span>\n        </div>\n        <ul class="dropdown-menu" aria-labelledby="user-information">\n            <li class="js-edit-login clickable">\n                <span class="mdi mdi-pencil-box-outline"></span>\n                <a class="">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</a>\n            </li>\n            <li class="js-languages clickable">\n                <span class="mdi mdi-flag"></span>\n                <a class="">' +
((__t = ( _.i18n('common.languages') )) == null ? '' : __t) +
'</a>\n            </li>\n            <li class="logout clickable">\n                <span class="mdi mdi-logout"></span>\n                <a>' +
((__t = ( _.i18n('common.logout') )) == null ? '' : __t) +
'</a>\n            </li>\n        </ul>\n    </div>\n</div>';

}
return __p
};});

