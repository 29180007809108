/**
 * Created by OLD on 06/08/2015.
 */
define('thankYouShowView',[
  'module',
  'marionette',
  'app',
  'template!thankYouViewTpl'
], function(
  module,
  Marionette,
  App,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      message: '.thank-you-message'
    },

    onShow: function () {
      this.ui.message.text(this.options.target);

      setTimeout(function () {
        App.trigger('login:show');
      }, 5000);
    }
  });
});
