
define('template!runPrepTooltipTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n    <div class="col-xs-12 p-l-0 p-r-0">\n        <div class="col-xs-12 m-b-20">\n            <div class="row">\n                <div class="col-xs-12">\n                    <div style="display: flow-root; padding: 2px 5px;">\n                        <div class="col-xs-12 p-l-0 p-r-0">\n                            <label style="font-size: 20px;">\n                                ' +
((__t = ( name )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                        <div class="col-xs-12 separator p-l-0 p-r-0 m-b-10" style="border-bottom: 1px solid;">\n                        </div>\n\n                        <div class="col-xs-12 p-l-0 p-r-0" style="display: flex;">\n                            <div class="col-w-all f-l d-f col-w-13 m-t-9">\n                                <div>\n                                    <span class="jqgrid-statusNb" style="bottom:16px; font-size: 12px;">' +
((__t = ( status )) == null ? '' : __t) +
'</span>\n                                    <div class="cell-default js-status-tooltip" style="display: flex; position: relative; top: 3px;">\n                                        ';
 for (var i = 1; i <= 5; i++) { ;
__p += '\n                                        <div class="jqgrid-Header-statusIcon' +
((__t = ( i )) == null ? '' : __t) +
' ' +
((__t = ( i === status || i < status || status === 5 ? 'statusIcon-upTo' : '')) == null ? '' : __t) +
'"></div>\n                                        ';
 } ;
__p += '\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="col-w-all f-l d-f col-w-13 m-t-9"></div>\n                            <div class="col-w-all f-l d-f col-w-10">' +
((__t = ( wellsSizePlateIcon )) == null ? '' : __t) +
'</div>\n                            <div class="col-w-all f-l d-f col-w-10"></div>\n                            <div class="col-w-all f-l d-f col-w-54" style="display: block;">\n                                <div class="col-w-all f-l d-f col-w-100"\n                                     style="display: flex; height: 15px; font-size: 11px; font-weight: bold;">\n                                    <div class="col-w-all f-l d-f col-w-20">\n                                        ' +
((__t = ( _.i18n('department') )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div class="col-w-all f-l d-f col-w-35">\n                                        ' +
((__t = ( _.i18n('period') )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div class="col-w-all f-l d-f col-w-25">\n                                        ' +
((__t = ( _.i18n('group') )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div class="col-w-all f-l d-f col-w-20">\n                                        ' +
((__t = ( _.i18n('seq') )) == null ? '' : __t) +
'\n                                    </div>\n                                </div>\n                                <div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n                                    <div class="col-w-all f-l d-f col-w-20">\n                                        ' +
((__t = ( department ? department : '' )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div class="col-w-all f-l d-f col-w-35">\n                                        ' +
((__t = ( periodFormatted ? periodFormatted : '' )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div class="col-w-all f-l d-f col-w-25">\n                                        ' +
((__t = ( group ? group : '' )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div class="col-w-all f-l d-f col-w-20">\n                                        ' +
((__t = ( sequenceFormatted )) == null ? '' : __t) +
'\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                        <div class="col-xs-12 p-l-0 p-r-0 m-t-10"></div>\n                        <div class="col-xs-12 p-l-0 p-r-0">\n                            <div class="col-w-all f-l d-f col-w-100"\n                                 style="display: flex;height: 15px; font-size: 11px; font-weight: bold;">\n                                <div class="col-w-all f-l d-f col-w-30 noWrapHidden">\n                                    ' +
((__t = ( _.i18n('run.extraction.method') )) == null ? '' : __t) +
'\n                                </div>\n                                <div class="col-w-all f-l d-f col-w-2">\n                                </div>\n                                <div class="col-w-all f-l d-f col-w-30 noWrapHidden">\n                                    ' +
((__t = ( _.i18n('run.extractor') )) == null ? '' : __t) +
'\n                                </div>\n                                <div class="col-w-all f-l d-f col-w-38">\n                                    ' +
((__t = ( _.i18n('run.refRespUser') )) == null ? '' : __t) +
'\n                                </div>\n                            </div>\n                            <div class="col-w-all f-l d-f col-w-100"\n                                 style="display: flex; font-size: 18px;">\n                                <div class="col-w-all f-l d-f col-w-30 noWrapHidden p-t-1">\n                                    ' +
((__t = ( extractionMethod )) == null ? '' : __t) +
'\n                                </div>\n                                <div class="col-w-all f-l d-f col-w-2 noWrapHidden p-t-1">\n                                </div>\n                                <div class="col-w-all f-l d-f col-w-30 noWrapHidden p-t-1">\n                                    ' +
((__t = ( extractor )) == null ? '' : __t) +
'\n                                </div>\n                                <div class="col-w-all f-l d-f col-w-38 noWrapHidden p-t-1">\n                                    ' +
((__t = ( respUser )) == null ? '' : __t) +
'\n                                </div>\n                            </div>\n                        </div>\n\n                        <!--% if(assaysToDisplay && assaysToDisplay.waiting !== \'\'){ %-->\n                        <div class="col-xs-12 p-l-0 p-r-0 m-t-10"></div>\n                        <div class="col-xs-12 p-l-0 p-r-0">\n                            <!--<div class="col-w-all f-l d-f col-w-100"\n                                 style="display: flex; height: 15px; font-size: 11px; font-weight: bold;">\n                                <div class="col-w-all f-l d-f col-w-100 noWrapHidden ">\n                                    %= _.i18n(\'assays.waiting\') %\n                                </div>\n                            </div>-->\n                            <div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n                                <div class="col-w-all f-l d-f col-w-100 noWrapHidden">\n\n                                    <!-- todo - add: style="margin-top: 5px;" for mdi icon / remove for old icons (circle) -->\n                                    <div class="col-w-all f-l d-f col-w-5" style="margin-top: 5px;">' +
((__t = (
                                        assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'\n                                    </div>\n\n                                    <div class="col-w-all f-l d-f col-w-95" style="margin: 2px 0 0 5px;">' +
((__t = (
                                        assaysToDisplay.waitingWithCount )) == null ? '' : __t) +
'\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                        <div class="col-xs-12 p-l-0 p-r-0 m-t-10"></div>\n                        <!--% } %-->\n\n                        <!--% if(assaysToDisplay && assaysToDisplay.running !== \'\'){ %-->\n                        <div class="col-xs-12 p-l-0 p-r-0">\n                            <!--<div class="col-w-all f-l d-f col-w-100"\n                                 style="display: flex; height: 15px; font-size: 11px; font-weight: bold;">\n                                <div class="col-w-all f-l d-f col-w-100 noWrapHidden ">\n                                    %= _.i18n(\'assays.running\') %\n                                </div>\n                            </div>-->\n                            <div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n                                <div class="col-w-all f-l d-f col-w-100 noWrapHidden">\n\n                                    <!-- todo - add: style="margin-top: 5px;" for mdi icon / remove for old icons (circle) -->\n                                    <div class="col-w-all f-l d-f col-w-5" style="margin-top: 5px;">' +
((__t = (
                                        assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
'\n                                    </div>\n\n                                    <div class="col-w-all f-l d-f col-w-95" style="margin: 2px 0 0 5px;"> ' +
((__t = (
                                        assaysToDisplay.runningWithCount )) == null ? '' : __t) +
'\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                        <div class="col-xs-12 p-l-0 p-r-0 m-t-10"></div>\n                        <!--% } %-->\n\n                        <!--% if(assaysToDisplay && assaysToDisplay.done !== \'\'){ %-->\n                        <div class="col-xs-12 p-l-0 p-r-0">\n                            <!--<div class="col-w-all f-l d-f col-w-100"\n                                 style="display: flex; height: 15px; font-size: 11px; font-weight: bold;">\n                                <div class="col-w-all f-l d-f col-w-100 noWrapHidden ">\n                                    %= _.i18n(\'assays.done\') %\n                                </div>\n                            </div>-->\n                            <div class="col-w-all f-l d-f col-w-100" style="display: flex; font-size: 18px;">\n                                <div class="col-w-all f-l d-f col-w-100 noWrapHidden">\n\n                                    <!-- todo - add: style="margin-top: 5px;" for mdi icon / remove for old icons (circle) -->\n                                    <div class="col-w-all f-l d-f col-w-5" style="margin-top: 5px;">' +
((__t = (
                                        assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
'\n                                    </div>\n\n                                    <div class="col-w-all f-l d-f col-w-95" style="margin: 2px 0 0 5px;">' +
((__t = (
                                        assaysToDisplay.doneWithCount )) == null ? '' : __t) +
'\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                        <div class="col-xs-12 p-l-0 p-r-0 m-t-10"></div>\n                        <!--% } %-->\n\n                        ';
 if(formatedPcrRuns && formatedPcrRuns !== ''){ ;
__p += '\n                        <div class="col-xs-12 separator p-l-0 p-r-0 m-b-10" style="border-bottom: 1px solid;">\n                        </div>\n                        <div class="col-xs-12 p-l-0 p-r-0">\n                            <div class="col-xs-2 p-l-10 p-r-0 t-a-r noWrapHidden">\n                                <label class="noWrapHidden"\n                                       style="font-size: 11px; font-weight: bold; display: block;">\n                                    ' +
((__t = ( _.i18n('run.prep.pcrRuns') )) == null ? '' : __t) +
'\n                                </label>\n                            </div>\n                            <div class="col-xs-10" style="display: flex; font-size: 18px;">\n                                ' +
((__t = ( formatedPcrRuns )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                        ';
 } ;
__p += '\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

