
define('template!subMenusViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)),url(\'http://localhost:8085/app/styles/css/images/rt-PCR-items-laboratory-hero.jpg?lang=en&tokenId=TfBJjvRx2jFaBaBGub0RdtqYJLnThUJbLM5F30n4DgUMkFGnMEFzMq7lkULYBL1MYou7lYFGSEnG7HZo4jvbAvalh11adftnSGUfKKYvC0yevV1jRU3rPnKiFUxb5percKIauTtnGz3hggdt4CL1UxmP\');\nbackground-size:100%;\n  background-repeat: no-repeat;\n  position: relative;top: 0;height:1000px;"></div>\n<div class="content" style="height:100%;\n  padding: 20px;position: absolute;top: 0;">\n  <div class= "jq-grid-table-page" style="display:flex;flex-wrap: wrap;border-radius: 25px;\nwidth:100%;height:95%;align-items: flex-start;align-content: flex-start;overflow-y: auto;">\n\n    ';
 _.each(menus, function(menu) { ;
__p += '\n    <div class="clickable js-navigate_to_menuel menu-zoom" data-submenu="' +
((__t = ( menu.secId)) == null ? '' : __t) +
'"\n         style="border: 1px solid grey;border-radius: 25px;\n         width:300px;\n         height: 150px;\n         background-color: whitesmoke;\n         margin:20px;\n         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);">\n      <div data-submenu="' +
((__t = ( menu.secId)) == null ? '' : __t) +
'" style="padding: 20px 0 0 20px;">' +
((__t = (menu.name)) == null ? '' : __t) +
'</div>\n      <div data-submenu="' +
((__t = ( menu.secId)) == null ? '' : __t) +
'" style="padding: 20px 0 0 20px;">' +
((__t = (menu.description)) == null ? '' : __t) +
'</div>\n    </div>\n  ';
 }) ;
__p += '\n\n</div>\n\n</div>\n\n';

}
return __p
};});

