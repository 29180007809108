define('dynamicCreateEditCustomAssayConfigurationResultFromAssay',[
    'module',
    'dialogFormView',
    'template!dynamicCreateEditCustomAssayConfigurationResultFromAssay',
    'autocompleteView',
    'backbone',
    'entities/caccounts/assay',
    'entities/caccounts/assayconfiguration',
    'entities/caccounts/assayConfigurationResult'
], function (
    module,
    DialogFormView,
    tpl,
    AutocompleteView,
    Backbone,
    Assays,
    AssayConfigurations,
    AssayConfigurationResults
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            input: '.js-info-input',
            field: '.js-input-field',
            fieldCustom: '.js-input-fieldCustom'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'focus @ui.fieldCustom': 'onFocusCustom'
        },

        regions: {
            assay: '.js-assay-region',
            assayConfiguration: '.js-assayConfiguration-region',
            assayConfigurationResult: '.js-assayConfigurationResult-region'
        },

        modelEvents: {
            'change:assay': 'onRender',
            'change:assayConfiguration': 'onRender',
            'change:assayConfigurationResult': 'updateModel'
        },

        initialize: function () {
            this.model = new Backbone.Model({assay: null, assayConfiguration: null, assayConfigurationResult: null});
            if (this.options.model.get(this.options.field)) {
                this.model.set('assayConfigurationResult', this.options.model.get(this.options.field));
                if (this.options.model.get(this.options.field).assayConfiguration) {
                    this.model.set('assayConfiguration', this.options.model.get(this.options.field).assayConfiguration);
                    if (this.options.model.get(this.options.field).assayConfiguration) {
                        this.model.set('assay', this.options.model.get(this.options.field).assayConfiguration.refAssay);
                    }
                }
            }
        },

        serializeData: function () {
            var templateData = {};
            templateData.assay = this.model.get('assay');
            templateData.assayConfiguration = this.model.get('assayConfiguration');
            templateData.assayConfigurationResult = this.model.get('assayConfigurationResult');
            return templateData;
        },

        onShow: function () {
            this.getRegion('assay').show(new AutocompleteView(
                this._getAutocompleteOptionObject(Assays.getAutocompleteParam({
                    modelProperty: 'assay',
                    callBackOnChange: this.onChangeAutocomplete
                }))
            ));
        },

        onRender: function () {
            if (this.model.get('assay')) {
                this.getRegion('assayConfiguration').show(new AutocompleteView(
                    this._getAutocompleteOptionObject(AssayConfigurations.getAutocompleteParam({
                        modelProperty: 'assayConfiguration',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'code',
                            'assaySecId': this.model.get('assay').secId
                        },
                        callBackOnChange: this.onChangeAutocomplete
                    }))));

                if (this.model.get('assayConfiguration')) {
                    this.getRegion('assayConfigurationResult').show(new AutocompleteView(
                        this._getAutocompleteOptionObject(AssayConfigurationResults.getAutocompleteParam({
                            modelProperty: 'assayConfigurationResult',
                            paramUrl: {
                                'sord': 'asc',
                                'sidx': 'code',
                                'assayConfigurationSecId': this.model.get('assayConfiguration').secId
                            },
                            callBackOnChange: this.onChangeAutocomplete
                        }))));
                } else {
                    this.getRegion('assayConfigurationResult').empty();
                    this.model.set({'assayConfigurationResult': null}, {silent: true});
                }
            } else {
                this.model.set({'assayConfiguration': null}, {silent: true});
                this.model.set({'assayConfigurationResult': null}, {silent: true});
                this.getRegion('assayConfigurationResult').empty();
                this.getRegion('assayConfiguration').empty();
            }
        },

        onChangeAutocomplete: function (fieldName, model) {
            model = model ? model.toJSON() : null;
            if (fieldName === 'assay' && model !== this.model.get(fieldName)) {
                this.model.set({'assayConfiguration': null, 'assayConfigurationResult': null}, {silent: true});
            }
            if (fieldName === 'assayConfiguration' && model !== this.model.get(fieldName)) {
                this.model.set({'assayConfigurationResult': null}, {silent: true});
            }
            this.onChange(fieldName, model);
        },

        updateModel: function () {
            var data = {};
            data[this.options.field] = AssayConfigurationResults.getModel({secId: this.model.get('assayConfigurationResult') ? this.model.get('assayConfigurationResult').secId : null});
            this.options.model.set(data, {silent: true});
            this.options.model.trigger('change:' + this.options.fieldName);
        }
    });
});
