
define('template!errorView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>' +
((__t = ( _.i18n('error.found.label') )) == null ? '' : __t) +
'</h3>\n<div class="col-xs-12">\n    <label class="col-xs-3">' +
((__t = ( _.i18n('error.identifier') )) == null ? '' : __t) +
' :</label>\n    <label class="col-xs-3">' +
((__t = ( identifier )) == null ? '' : __t) +
'</label>\n</div>\n<div class="js-collection"></div>';

}
return __p
};});

