
define('template!prepRunTabsLayoutHeaderTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n\t<div class="tab-layout-header js-tab-layout-header hidden-print">\n\t\t<div class="tab-layout-info">\n\t\t\t<!-- Row : actions - run details - filters ------------------------------------------------------------------------------------------------------->\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t<!-- Action buttons -------------------------------------------->\n\t\t\t\t\t<div class="col-w-all col-w-41 f-l p-l-0 p-r-0 noWrapHidden"\n\t\t\t\t\t\t style="display: flex; justify-content: flex-end;">\n\t\t\t\t\t\t<!-- Edit plate layout -->\n\t\t\t\t\t\t<div class="css-grayed-out-header">\n\t\t\t\t\t\t\t<div style="padding: 2px;">\n\t\t\t\t\t\t\t\t<div class="run-action-nav-container">\n\t\t\t\t\t\t\t\t\t<button class="run-action-nav-drop-btn js-run-action-nav-drop-btn">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('common.plateLayout') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t<div class="run-action-nav-list hidden">\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm import-plateLayout-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.import.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.import') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm import-plateLayout-fromOtherRun-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.import.from.other.run.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.from.other.run') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm import-sampleList-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.sampleList.import.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.sampleList.import') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm export-plateLayout-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.export.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.export') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm export-sampleList-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.sampleList.export.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.sampleList.export') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm ctrl-plateLayout-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.ctrl.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.ctrl') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm scan-plateLayout-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.scan.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.scan') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm edit-plateLayout-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.edit.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.edit') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-generate-smpids run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.generate.smpId.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.generate.smpId') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-remove-smpids run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.remove.smpId.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.remove.smpId') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-add-assay run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.add.assay.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.add.assay') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-clear-assay run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.clear.assay.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.clear.assay') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-replace-assay run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.replace.assay.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.replace.assay') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-add-test run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.add.test.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.add.test') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-remove-test run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.remove.test.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.platelayout.remove.test') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-clear-wells run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('pcr.platelayout.clear.wells.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('pcr.platelayout.clear.wells') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton import-plateLayout-button-container\n                                    clickable hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.import') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-import mdiHeaderActionButtonIcon import-plateLayout-button clickable"></span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton edit-plateLayout-button-container clickable hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.scan') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-barcode-scan headerIconButton scan-plateLayout-button clickable"\n\t\t\t\t\t\t\t\t\t\t\t style="position: relative; bottom: 4px; left: 4px; font-size: 35px;"></span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t<div class="group-button-container hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px; width: 47px; height: 47px; display: flex; flex-flow: wrap;">\n\t\t\t\t\t\t\t\t\t\t<div class="headerActionButton clickable assay-add-button-container"\n\t\t\t\t\t\t\t\t\t\t\t style="width: 20px; height: 20px; margin: 1px;"\n\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.add.assay.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-plus-circle mdiHeaderActionButtonIcon js-add-assay clickable"\n\t\t\t\t\t\t\t\t\t\t\t\t style="position: relative; line-height: 20px; font-size: 15px; bottom: 2px; left: 2px;"></span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="headerActionButton clickable assay-clear-button-container"\n\t\t\t\t\t\t\t\t\t\t\t style="width: 20px; height: 20px; margin: 1px;"\n\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.clear.assay.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-minus-circle mdiHeaderActionButtonIcon js-clear-assay clickable"\n\t\t\t\t\t\t\t\t\t\t\t\t style="position: relative; line-height: 20px; font-size: 15px; bottom: 2px; left: 2px;"></span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="headerActionButton clickable assay-replace-button-container"\n\t\t\t\t\t\t\t\t\t\t\t style="width: 20px; height: 20px; margin: 1px;"\n\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.replace.assay.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-rotate-3d-variant mdiHeaderActionButtonIcon js-replace-assay clickable"\n\t\t\t\t\t\t\t\t\t\t\t\t style="position: relative; line-height: 20px; font-size: 15px; bottom: 2px; left: 2px;"></span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="headerActionButton clickable edit-plateLayout-button-container"\n\t\t\t\t\t\t\t\t\t\t\t style="width: 20px; height: 20px; margin: 1px;"\n\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.edit.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-pencil mdiHeaderActionButtonIcon edit-plateLayout-button clickable"\n\t\t\t\t\t\t\t\t\t\t\t\t style="position: relative; line-height: 20px; font-size: 15px; bottom: 2px; left: 2px;"></span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton edit-plateLayout-button-container clickable hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.platelayout.ctrl.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-shield-check headerIconButton ctrl-plateLayout-button clickable"\n\t\t\t\t\t\t\t\t\t\t\t style="position: relative; bottom: 4px; left: 4px; font-size: 35px;"></span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<!-- extraction -->\n\t\t\t\t\t\t<div class="css-grayed-out-header">\n\t\t\t\t\t\t\t<div style="padding: 2px;">\n\t\t\t\t\t\t\t\t<div class="run-action-nav-container">\n\t\t\t\t\t\t\t\t\t<button class="run-action-nav-drop-btn js-run-action-nav-drop-btn">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('preprun.extraction') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t<div class="run-action-nav-list hidden">\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm start-extraction-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.extraction.start.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.extraction.start') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm stop-extraction-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.extraction.end.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.extraction.end') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm show-extraction-button run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.extraction.view.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.extraction.view') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t\t';
 if(status === 1 || status > 2) { ;
__p += '\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton start-extraction-button-container clickable hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.extraction.start') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-play mdiHeaderActionButtonIcon start-extraction-button clickable"></span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t\t\t\t\t';
 if(status === 2) { ;
__p += '\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton undo-extraction-button-container clickable hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.extraction.undo') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-cancel mdiHeaderActionButtonIcon undo-extraction-button clickable"></span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton stop-extraction-button-container clickable hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.extraction.end') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-play-pause mdiHeaderActionButtonIcon stop-extraction-button clickable"></span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton show-extraction-button-container clickable hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t\t title="_.i18n(\'prep.extraction.view\')">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-eye-outline mdiHeaderActionButtonIcon show-extraction-button clickable"\n\t\t\t\t\t\t\t\t\t\t\t style="font-size: 38px; position: relative; left: 3px; bottom: 7px;"></span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<!-- pcr setup -->\n\t\t\t\t\t\t<div class="css-grayed-out-header">\n\t\t\t\t\t\t\t<div style="padding: 2px;">\n\t\t\t\t\t\t\t\t<div class="run-action-nav-container">\n\t\t\t\t\t\t\t\t\t<button class="run-action-nav-drop-btn js-run-action-nav-drop-btn">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('preprun.pcrsetup') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t<div class="run-action-nav-list hidden">\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-create-pcrFromPrep run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.pcrFromPrep.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('common.pcrFromPrep') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton clickable pcrFromPrep-btn-container hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.pcrFromPrep') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi-action-settings headerActionButtonIcon js-create-pcrFromPrep"\n\t\t\t\t\t\t\t\t\t\t\t style="top: 1px;">\n\t\t\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<!-- pooling -->\n\t\t\t\t\t\t<div class="css-grayed-out-header">\n\t\t\t\t\t\t\t<div style="padding: 2px;">\n\t\t\t\t\t\t\t\t<div class="run-action-nav-container">\n\t\t\t\t\t\t\t\t\t<button class="run-action-nav-drop-btn js-run-action-nav-drop-btn">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('preprun.pooling') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t<div class="run-action-nav-list hidden">\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-create-bead-pooling run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.pooling.start.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.pooling.start') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-create-primary-pooling run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('pooling.primary.menu.title') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('pooling.primary.menu') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton clickable hidden-print"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px auto 2px auto;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.pooling.start') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-google-circles-communities headerActionButtonIcon js-create-bead-pooling"\n\t\t\t\t\t\t\t\t\t\t\t style="">\n\t\t\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<!-- miscaleanous -->\n\t\t\t\t\t\t<div class="css-grayed-out-header">\n\t\t\t\t\t\t\t<div style="padding: 2px;">\n\t\t\t\t\t\t\t\t<div class="run-action-nav-container">\n\t\t\t\t\t\t\t\t\t<button class="run-action-nav-drop-btn js-run-action-nav-drop-btn">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('common.miscaelenous') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t<div class="run-action-nav-list hidden">\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-generate-starter-sheet run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.generate-starter-sheet.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.generate-starter-sheet') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-generate-extract-setup run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('prep.generate-starter-sheet.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('prep.generate-extract-setup') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\t<button class="btn btn-sm js-prepRun-tracs-view run-action-nav-list-btn clickable">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('run.tracs.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('run.tracs') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton clickable hidden-print js-prepRun-tracs-view"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px auto 2px auto;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('run.tracs.popover') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-gnome headerActionButtonIcon"\n\t\t\t\t\t\t\t\t\t\t\t style="font-size: 35px; bottom: 4px; left: 4px;">\n\t\t\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<!-- more button that open menu dropdown -- temporary hidden ------------------------------>\n\t\t\t\t\t\t\t<div class="headerGroupCellContainer hidden">\n\t\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t\t<div class="headerActionButton clickable hidden-print js-run-action-nav-drop-cell-btn"\n\t\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('more') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-menu-down-outline mdiHeaderActionButtonIcon"></span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<!-- more button that open menu dropdown -- temporary hidden ------------------------------>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<!-- previous button -->\n\t\t\t\t\t\t<div style="padding: 2px;">\n\t\t\t\t\t\t\t<div class="mainHeaderBoxShadow"\n\t\t\t\t\t\t\t\t style="border: solid 1px #333; border-radius: 5px;">\n\t\t\t\t\t\t\t\t<div class="js-show-previous-run css-show-previous-run clickable noWrapHidden"\n\t\t\t\t\t\t\t\t\t style="width: 30px; height: 82px;">\n\t\t\t\t\t\t\t\t\t<div class="previousRunTriangleIcon" style="top: 3px;left: 5px;"></div>\n\t\t\t\t\t\t\t\t\t<div class="previousRunTriangleIcon2" style="bottom: 72px; left: 11px;"></div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\n\t\t\t\t\t</div>\n\t\t\t\t\t<!-- Run details -------------------------------------------->\n\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0 noWrapHidden mainHeaderBoxShadow"\n\t\t\t\t\t\t style="top: 2px; height: 83px; border: solid 1px black; border-radius: 5px; background-color: inherit;">\n\t\t\t\t\t\t<div class="d-f" style="flex-direction: row; display: flex;">\n\t\t\t\t\t\t\t<div class="col-xs-1 p-r-2 p-l-2"\n\t\t\t\t\t\t\t\t style="display:flex; flex-direction: column; justify-content: space-around;">\n\t\t\t\t\t\t\t\t<div class="buttonCircle">\n                                    <span class="clickable hidden-print  btn-edit-preprun mdi-content-create"\n\t\t\t\t\t\t\t\t\t\t  title="' +
((__t = ( _.i18n('well.tooltip.edit') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t  style="\n                                            top: 0;\n                                            position: relative;\n                                            font-size: 14px;\n                                            right: -2px;">\n                                    </span>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="buttonCircle">\n                                    <span class="clickable hidden-print btn-show-kits mdi mdi-flask-outline"\n\t\t\t\t\t\t\t\t\t\t  title="' +
((__t = ( _.i18n('well.tooltip.edit') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t  style="\n                                            top: -2px;\n                                            position: relative;\n                                            font-size: 14px;\n                                            right: -2px;">\n                                    </span>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="buttonCircle">\n                                    <span class="clickable hidden-print btn-show-attachments mdi mdi-flip-v mdi-rotate-270 mdi-attachment"\n\t\t\t\t\t\t\t\t\t\t  title="' +
((__t = ( _.i18n('well.tooltip.edit') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t  style="top: -1px;\n                                            position: relative;\n                                            font-size: 16px;\n                                            line-height: 19px;">\n                                    </span>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-xs-9 p-r-2 p-l-2"\n\t\t\t\t\t\t\t\t style="display: flex; flex-direction: column; justify-content: space-around;">\n\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-5 p-r-5 noWrapHidden pop-mainHeaderRunBox" id="to-clipboard"\n\t\t\t\t\t\t\t\t\t data-name="' +
((__t = ( name )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t';
 if(selectedRunsList && selectedRunsList.length > 0){ ;
__p += '\n\t\t\t\t\t\t\t\t\t<select class="js-show-selected-run headerRunNameDropdown js-runName-selectOption-container">\n\t\t\t\t\t\t\t\t\t\t';
 _.each(selectedRunsList, function(selectedRun){ ;
__p += '\n\t\t\t\t\t\t\t\t\t\t<option style="font-size:20px;"\n\t\t\t\t\t\t\t\t\t\t\t\tvalue="' +
((__t = ( selectedRun.secId )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t';
 if (selectedRun.secId === secId) { ;
__p += ' selected ';
 } ;
__p += '>' +
((__t = ( selectedRun.name
										)) == null ? '' : __t) +
'</option>\n\t\t\t\t\t\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t\t\t<div id="to-clipboard-run-name" class="js-runName-div-container noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t style="\n                                                display: none;">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( name )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t';
 }else{ ;
__p += '\n\t\t\t\t\t\t\t\t\t<div class="js-runName-div-container noWrapHidden"\n\t\t\t\t\t\t\t\t\t\t style="\n                                                font-size: 20px;\n                                                height: 38px;\n                                                border: solid 1px #333;\n                                                border-radius: 5px;\n                                                margin-top: 2px;\n                                                padding-top: 6px;\n                                                padding-left: 2px;\n                                                margin-bottom: 4px;">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( name )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-5 p-r-5 noWrapHidden">\n\t\t\t\t\t\t\t\t\t<div class="js-pcrruns"></div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-xs-2 p-r-2 p-l-2"\n\t\t\t\t\t\t\t\t style="display: flex; flex-direction: column; justify-content: space-around;">\n\t\t\t\t\t\t\t\t<div style="display: flex; justify-content: space-around;">\n\t\t\t\t\t\t\t\t\t<div class="buttonCircle">\n                                    <span class="clickable hidden-print js-copyParameters mdi-content-content-copy"\n\t\t\t\t\t\t\t\t\t\t  data-copy="' +
((__t = ( name )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t  style="\n                                            top: 0;\n                                            position: relative;\n                                            font-size: 14px;\n                                            right: -2px;">\n                                    </span>\n\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t<div class="buttonCircle">\n                                     <span class="clickable hidden-print js-run-refresh mdi-navigation-refresh"\n\t\t\t\t\t\t\t\t\t\t   title="' +
((__t = ( _.i18n('refresh.run') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t   style="\n                                             top: 0;\n                                             position: relative;\n                                             font-size: 18px;\n                                             right: 0;">\n                                    </span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="" style="height: 17px; margin-top: 8px;">\n\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t<span class="jqgrid-statusNb" style="bottom:16px; font-size: 12px;">' +
((__t = ( status )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t\t\t\t\t<div class="cell-default js-status-tooltip"\n\t\t\t\t\t\t\t\t\t\t\t style="display: flex; position: relative; top: 3px;">\n\t\t\t\t\t\t\t\t\t\t\t';
 for (var i = 1; i <= 5; i++) { ;
__p += '\n\t\t\t\t\t\t\t\t\t\t\t<div class="jqgrid-Header-statusIcon' +
((__t = ( i )) == null ? '' : __t) +
' ' +
((__t = ( i === status || i < status || status === 5 ? 'statusIcon-upTo' : '')) == null ? '' : __t) +
'"></div>\n\t\t\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-5 p-l-0 p-r-0 " style="display: flex; justify-content: flex-start;">\n\t\t\t\t\t\t<!-- next button -->\n\t\t\t\t\t\t<div style="padding: 2px;">\n\t\t\t\t\t\t\t<div class="mainHeaderBoxShadow"\n\t\t\t\t\t\t\t\t style="border: solid 1px #333; border-radius: 5px;">\n\t\t\t\t\t\t\t\t<div class="js-show-next-run css-show-next-run clickable noWrapHidden"\n\t\t\t\t\t\t\t\t\t style="width: 30px; height: 82px;">\n\t\t\t\t\t\t\t\t\t<div class="nextRunTriangleIcon" style="left: 7px; top: 3px;"></div>\n\t\t\t\t\t\t\t\t\t<div class="nextRunTriangleIcon2" style="bottom: 72px; left: 2px;"></div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<!-- smpId search filter -->\n\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t<div class="groupCellTitle">&nbsp</div>\n\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-smpId js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-smpId"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.search.on.smpId') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-magnify mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<!-- repeat filter -->\n\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t<div class="groupCellTitle">Repeat</div>\n\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-repeat-prep js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-repeat-prep"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.repeat.pcr') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-play mdi-flip-h mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<!-- Filter buttons -------------------------------------------->\n\t\t\t\t\t\t<!-- filters -->\n\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t<div class="groupCellTitle">' +
((__t = ( _.i18n('common.smpType') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-qcSample js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-qcSample"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.qcSample') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-alpha-q-circle-outline mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-unKnowSample js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-unKnowSample"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.unKnowSample') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-alpha-u-circle-outline mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-emptySid js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-emptySid"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.emptySid') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-alpha-x-circle-outline mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t<div class="groupCellTitle">' +
((__t = ( _.i18n('common.smpId') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-wrongSid js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-wrongSid"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.wrongSid') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-alert-outline mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-duplicates js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-duplicates"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.duplicates') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-content-duplicate mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header hidden"> <!--IC filters hidden-->\n\t\t\t\t\t\t\t<div class="groupCellTitle">' +
((__t = ( _.i18n('common.Ic') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-withoutIC js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-withoutIC"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.withoutIC') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-numeric-0 mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-withOneIc js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-withOneIc"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.withOneIc') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-numeric-1 mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-withMoreIcs js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-withMoreIcs"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.withMoreIcs') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-plus-one mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t<div class="groupCellTitle">' +
((__t = ( _.i18n('assay.status') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-waiting js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-waiting"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.waiting') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( assayStatusIcons.waiting )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-running js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-running"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.running') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( assayStatusIcons.running )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-done js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-done"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.done') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t' +
((__t = ( assayStatusIcons.done )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="headerGroupCellContainer css-grayed-out-header">\n\t\t\t\t\t\t\t<div class="groupCellTitle">' +
((__t = ( _.i18n('common.misc') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t\t<div style="display: flex;">\n\t\t\t\t\t\t\t\t<div class="headerFilterButton js-select-comment js-tab-filters clickable hidden-print js-select-filter"\n\t\t\t\t\t\t\t\t\t name="select-comment"\n\t\t\t\t\t\t\t\t\t style="margin: 2px;"\n\t\t\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('common.select.comment') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t<span class="mdi mdi-comment-alert mdiHeaderIconButton"></span>\n\t\t\t\t\t\t\t\t\t<input type="checkbox" class="js-filter-checkBox">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<!-- reset filters -->\n\t\t\t\t\t\t<div class="clickable hidden-print mdi mdi-filter-remove js-reset-filters"\n\t\t\t\t\t\t\t style=""\n\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('reset.filters') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<!-- Row : actions - run details - filters End ------------------------------------------------------------------------------------------------------->\n\t\t\t<!-- Row Navigation bar ------------------------------------------------------------------------------------------------------->\n\t\t\t<div class="row tabsIconsMenu">\n\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0 run-tabsLayout-navBar-container" id="header-navBar-tabs">\n\t\t\t\t\t<!-- run-tabsLayout-navBar-container added -->\n\t\t\t\t\t<div class="run-tabsLayout-navTab">\n\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0 js-tab-layout-tab js-table clickable header-nav-tab"\n\t\t\t\t\t\t\t style="border: solid 1px black; border-radius: 7px;"\n\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('runs.table.tabTitle') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t data-tab-name="table">\n\t\t\t\t\t\t\t<div class="col-xs-3 nav-tab-icon mdi mdi-grid"></div>\n\t\t\t\t\t\t\t<div class="col-xs-9 nav-tab-text noWrapHidden" style="height: 35px;\n                                    padding: 6px 5px 5px 5px; font-size: 18px;">\n\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('runs.table.tabTitle') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="run-tabsLayout-navTab">\n\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0 js-tab-layout-tab js-details clickable header-nav-tab"\n\t\t\t\t\t\t\t style="border: solid 1px black; border-radius: 7px;"\n\t\t\t\t\t\t\t title="' +
((__t = ( _.i18n('runs.details.tabTitle') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t data-tab-name="details">\n\t\t\t\t\t\t\t<div class="col-xs-3 nav-tab-icon mdi mdi-view-list"></div>\n\t\t\t\t\t\t\t<div class="col-xs-9 nav-tab-text noWrapHidden" style="height: 35px;\n                                    padding: 6px 5px 5px 5px; font-size: 18px;">\n\t\t\t\t\t\t\t\t' +
((__t = ( _.i18n('runs.details.tabTitle') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<!-- Row Navigation bar End ------------------------------------------------------------------------------------------------------->\n\t\t\t<div class="js-header-region"></div>\n\t\t</div>\n\t\t<div class="navbar navbar-default navbar-md hidden row m-b-0">\n\t\t\t<div class="navbar-collapse collapse">\n\t\t\t\t<ul class="nav navbar-nav p-l-10">\n\t\t\t\t\t';
 _.each(tabs, function(tab) { ;
__p += '\n\t\t\t\t\t<li class="js-tab-title tab-layout-tab js-tab-layout-tab js-' +
((__t = ( tab.name )) == null ? '' : __t) +
' clickable"\n\t\t\t\t\t\tdata-tab-name="' +
((__t = ( tab.name )) == null ? '' : __t) +
'"><a>' +
((__t = ( tab.title )) == null ? '' : __t) +
'</a></li>\n\t\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t</ul>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

