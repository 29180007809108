define('entities/caccounts/preprocess',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'arrayCustomFromTypeView'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    ArrayCustomFromTypeView
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.PreProcess = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/preProcess/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'comment': '',
            'configuration': '',
            tags: []
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/preProcess');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'PreProcessJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'PreProcess',
        getEntity: function () {
            return module.exports;
        },
        importable: true,

        processSample: function (sample, lisAnas) {
            var url = Settings.url('compuzz', 'v2/preProcess/' + this.get('secId') + '/process/' + sample.get('secId')),
                defer = $.Deferred();
            $.ajax({
                method: 'POST',
                url: url,
                contentType: 'application/json',
                data: JSON.stringify(lisAnas),
                success: function (json) {
                    defer.resolve(json);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });

    app.ns.PreProcessCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/preProcess/');
        },
        model: app.ns.PreProcess
    });

    module.exports = _.defaults({
        getName: function () {
            return 'preProcess';
        },
        getUrl: function () {
            return 'v2/preProcess';
        },
        getDynamicJson: function () {
            return 'PreProcessJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('preprocess.configuration.type'),
                name: 'configuration',
                formatter: viewDynamic.typeFormatter,
                search: true,
                index: 'configurationType',
                width: 80
            }, {
                label: _.i18n('preprocess.configuration.param'),
                name: 'configuration',
                formatter: viewDynamic.paramFormatter,
                search: false,
                sortable: false,
                width: 80
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.PreProcess.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function (model) {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'configuration', 'param': {
                        'type': 'ARRAYLIST', 'config': _.bind(function () {
                            return new ArrayCustomFromTypeView({
                                fieldNameParam: 'PREPROCESS_TYP',
                                model: model,
                                fieldName: 'configuration',
                                fieldNameCodePrefix: 'PPROC_CONF_'
                            });
                        }, this)
                    }
                }
            );
            return custom;
        },

        findAll: function (params) {
            if (!params) {
                params = {};
            }
            params.sidx = 'code';
            var url = Settings.url('compuzz', 'v2/preProcess', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (response) {
                    defer.resolve(new app.ns.PreProcessCollection(response));
                }
            });

            return defer.promise();
        }

    }, Dynamic);
});
