
define('template!suppliersCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="row">\n                <div>\n                    <div class="col-xs-4 dataLabelContainer">\n                        <div class="form-group" data-field-name="code">\n                            <label class="control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.codeName') )) == null ? '' : __t) +
'</label>\n                        </div>\n                    </div>\n                    <div class="col-xs-1">\n                        <div class="form-group" data-field-name="code">\n                            <input type="text"\n                                   class="form-control floating-label js-info-input js-code dataNameIdInput inputBackground"\n                                   data-field-name="code" placeholder=""\n                                   value="' +
((__t = ( code?code:'' )) == null ? '' : __t) +
'" data-content=""\n                                   data-placement="top" autocomplete="off">\n                        </div>\n                    </div>\n                    <div class="col-xs-7">\n                        <div class="form-group" data-field-name="name">\n                            <input type="text"\n                                   class="form-control floating-label js-info-input dataLocationInput inputBackground"\n                                   data-field-name="name" placeholder=""\n                                   value="' +
((__t = ( name?name:'' )) == null ? '' : __t) +
'" autocomplete="off">\n                        </div>\n                    </div>\n                </div>\n                <div>\n                    <div class="col-xs-4 dataLabelContainer">\n                        <div class="form-group" data-field-name="city">\n                            <label class="control-label dataLabel">' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</label>\n                        </div>\n                    </div>\n                    <div class="col-xs-8 p-l-0">\n                        <div class="form-group col-xs-8" data-field-name="city">\n                            <input type="text" class="form-control floating-label js-info-input js-code inputBackground"\n                                   data-field-name="city" placeholder=""\n                                   style="width: 351px;"\n                                   value="' +
((__t = ( city?city:'' )) == null ? '' : __t) +
'"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'" data-placement="top">\n                        </div>\n                        <div class="form-group col-xs-3" data-field-name="zipCode" style="left: 15px;">\n                            <input type="text" class="form-control floating-label js-info-input inputBackground"\n                                   data-field-name="zipCode" placeholder=""\n                                   style="width: 85px;"\n                                   value="' +
((__t = ( zipCode?zipCode:'' )) == null ? '' : __t) +
'">\n                        </div>\n                        <div class="form-group col-xs-1" data-field-name="countryCode">\n                            <input type="text" class="form-control floating-label js-info-input inputBackground"\n                                   data-field-name="countryCode" placeholder=""\n                                   style="width: 28px;"\n                                   value="' +
((__t = ( countryCode?countryCode:'' )) == null ? '' : __t) +
'">\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class="js-description m-t-5 m-b-5"></div>\n\n            <div class="row cancelConfirmRow">\n                <div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv">\n                    <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n                    <button disabled\n                            class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

