define('entities/admin/detectionFormat',[
	'app',
	'backbone',
	'settings',
	'jquery',
	'underscore',
	'module',
	'dynamic',
	'backboneRelational',
	'entities/admin/dye'
], function (
	App,
	Backbone,
	Settings,
	$,
	_,
	module,
	Dynamic
) {
	'use strict';

	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.DetectionFormat = Backbone.RelationalModel.extend({
		fetch: function () {
			this.url = Settings.url('compuzz', 'public/detectionFormats/' + this.get('secId'));

			return Backbone.Model.prototype.fetch.call(this);
		},
		defaults: {
			'code': undefined,
			'name': undefined,
			'dyes': [],
			'description': undefined,
			'comment': undefined
		},
		idAttribute: 'secId',
		relations: [{
			type: Backbone.HasMany,
			key: 'dyes',
			relatedModel: 'Dye',
			collectionType: 'DyeCollection',
			reverseRelation: {
				key: 'refDetectionFormat',
				includeInJSON: false
			}
		}],
		getImageUid: function () {
		},

		postUrl: function () {
			return Settings.url('compuzz', 'public/detectionFormats');
		},

		save: function () {
			this.url = this.postUrl();
			return Backbone.Model.prototype.save.call(this);
		},

		jsonObjectName: 'DetectionFormatJson',
		getPDF: function () {
			return Settings.getDocUrl(this.jsonObjectName + '.pdf');
		},

		jsonObject: 'DetectionFormat',
		getEntity: function() {
			return module.exports;
		}
	});

	module.exports = _.defaults({
		getName: function () {
			return 'detectionFormat';
		},
		getUrl: function () {
			return 'public/detectionFormats';
		},
		getDynamicJson: function () {
			return 'DetectionFormatJson';
		},
		getTypeDynamicJson: function () {
			return 'ADMIN';
		},
		getJqGridColumnConfig: function () {
			var config = Dynamic.getJqGridColumnConfig();
			config.exportable = false;
			config.archivable = false;
			config.selectArchivable = false;
			config.importable = false;
			config.duplicable = false;
			return config;
		},
		getJqGridColumn: function (viewDynamic) {
			var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
			columns.push({
				label: _.i18n('common.code'),
				name: 'code',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'code',
				searchoptions: {size: 10},
				width: 40
			}, {
				label: _.i18n('common.name'),
				name: 'name',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'name',
				searchoptions: {size: 10},
				width: 45
			}, {
				label: _.i18n('detectionFormat.dye'),
				name: 'dyes',
				classes: 'dynamic-link',
				formatter: this.dyeFormatter,
				search: true,
				index: 'dye',
				searchoptions: {size: 10},
				width: 45
			}, {
				label: _.i18n('common.comment'),
				name: 'comment',
				classes: 'dynamic-link',
				formatter: viewDynamic.defaultFormatter,
				search: true,
				index: 'comment'
			});
			return columns;
		},
		canAddOrEdit: function () {
			return Settings.get('currentUserModel').hasRole('SUPERUSER');
		},
		getModel: function (modelJson) {
			if (!modelJson) {
				modelJson = {};
			}
			return app.ns.DetectionFormat.findOrCreate(modelJson);
		},
		getFieldsToDisplay: function () {
			return {'fieldsWidth': 400, 'fields': ['name']};
		},
		dyeFormatter: function (cellValue, options) {
			if (cellValue === null || cellValue === undefined) {
				cellValue = [];
			}

			var values = [];
			_.each(cellValue, function (val) {
				values.push(val.name + '(' + val.channel + ')');
			});
			cellValue = values.join(', ');
			return '<span class="cell-default" data-row-id="' +
				options.rowId + '">' + cellValue + '</span>';
		},
		getCreateEditFieldIgnore: function () {
			var ignored = Dynamic.getCreateEditFieldIgnore();
			ignored.push('cyclers', 'description');
			return ignored;
		},

		getCreateEditFieldCustom: function () {
			var custom = Dynamic.getCreateEditFieldCustom();
			custom.push(
				{'field': 'comment', 'param': {'type': 'COMMENT'}}
			);
			return custom;
		},
		findFromAssayConfiguration: function(assayConfiguration) {
			return assayConfiguration.get('cyclers').map(function(cycler) {
				return cycler.get('refCycler').get('refDetectionFormat');
			});
		}
	}, Dynamic);
});
