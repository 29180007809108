define('assayReagentQCView',[
    'module',
    'backbone',
    'marionette',
    'underscore',
    'template!assayReagentQCTpl',
    'assayReagentQCItemView',
    'entities/caccounts/assayreagent'
], function (
    module,
    Backbone,
    Marionette,
    _,
    Tpl,
    View,
    AssayReagents
) {
    'use strict';


    module.exports = Marionette.CompositeView.extend({
        template: Tpl,
        childView: View,
        childViewContainer: '.collection',
        childEvents: {
            'item:delete': function (view, model) {
                this.collection.remove(model);
            },
            'item:updateReagent': function () {
                var reagentTypes = this.collection.chain().map(function (model) {
                    if (model && model.get('reagent')) {
                        return model.get('reagent').get('type');
                    }
                    return null;
                }).filter(function (type) {
                    return type;
                }).uniq().value();
                _.each(this.children._views, function (view) {
                    view.options.reagentTypes = reagentTypes;
                });
            }
        },
        childViewOptions: function () {
            return {
                reagentTypes: new Backbone.Collection(this.collection.pluck('reagent')).pluck('type')
            };
        },
        events: {
            'click .js-add': 'onAdd'
        },
        onAdd: function () {
            this.collection.push(AssayReagents.getModel({refAssay: this.model}));
        },
        serializeData: function () {
            return {volumeBySample: this.model.get('volumeBySample')};
        }
    });
});
