define('runCreateNewPcrRunSetupView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!runCreateNewPcrRunSetupTpl',
    'savingBehavior',
    'pcrSetupEmptyRunView',
    'pcrSetupOneToOneView',
    'pcrSetupImportPlateLayoutView',
    'pcrSetupImportResultView',
    'pcrSetupFlexibleSetupView',
    'pcrSetupHitPickingView',
    'bootbox',
    'underscore',
    'app',
    'entities/caccounts/pcrruns',
    'entities/createRunFromTemplate'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    PcrSetupEmptyRunView,
    PcrSetupOneToOneView,
    PcrSetupImportPlateLayoutView,
    PcrSetupImportResultView,
    PcrSetupFlexibleSetupView,
    PcrSetupHitPickingView,
    bootbox,
    _,
    App
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            NewEmptyRun: '.js-emppty-pcrrun',
            setupOneToOnePcrRun: '.js-setup-1-1',
            importPcrRunPlateLayout: '.js-import-pcr-plate-layout',
            importPcrRunResult: '.js-import-reuslt',
            flexiblePcrRunSetup: '.js-flexible-pcr-setup',
            setupPcrRunHitPicking: '.js-pcr-run-hit-picking',

            input: '.js-info-input',
            blockLoading: '.empty-page-loader'
        },

        events: {
            'click @ui.NewEmptyRun': 'onCreateEmpty',
            'click @ui.setupOneToOnePcrRun': 'onSetupOneToOne',
            'click @ui.importPcrRunPlateLayout': 'onImportPlateLayout',
            'click @ui.importPcrRunResult': 'onImportResult',
            'click @ui.flexiblePcrRunSetup': 'onFlexibleSetup',
            'click @ui.setupPcrRunHitPicking': 'onHitPicking',
            'click .js-cancel-popup': 'hide',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'change @ui.createRunRadio': 'onCreateRunRadioChange',
            'change @ui.uploadRunsCyclerExport': 'onLoadFilePlateLayout',
            'change @ui.selectedDate': 'onChangeDate',
            'change @ui.generateStarterSheet': 'onChangeGenerateStarterSheet',
            'change @ui.exportPlateLayout': 'onChangeExportPlateLayout'
        },

        regions: {
            pcrSetupRegion: '.js-display-create-pcrRun'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [],

        onCreateEmpty: function () {
            if (!this.pcrSetupView || this.pcrSetupView.options.tab !== 'emptypcr') {
                if (this.pcrSetupView) {
                    this.pcrSetupView.destroy();
                }
                this.pcrSetupView = new PcrSetupEmptyRunView({
                    model: this.model,
                    tab: 'emptypcr'
                });
                var region = this.getRegion('pcrSetupRegion');
                if (region) {
                    region.show(this.pcrSetupView);
                    this.listenTo(this.pcrSetupView, 'hide:pcrSetup:popup', _.bind(this.hide, this));
                }
            }
        },

        onSetupOneToOne: function () {
            if (!this.pcrSetupView || this.pcrSetupView.options.tab !== 'oneToOne') {
                if (this.pcrSetupView) {
                    this.pcrSetupView.destroy();
                }
                this.pcrSetupView = new PcrSetupOneToOneView({
                    model: this.model,
                    tab: 'oneToOne'
                });
                var region = this.getRegion('pcrSetupRegion');
                if (region) {
                    region.show(this.pcrSetupView);
                    this.listenTo(this.pcrSetupView, 'do something', _.bind(this.onDoSomething, this));
                }
            }
        },

        onImportPlateLayout: function () {
            if (!this.pcrSetupView || this.pcrSetupView.options.tab !== 'importPlateLayout') {
                if (this.pcrSetupView) {
                    this.pcrSetupView.destroy();
                }
                this.pcrSetupView = new PcrSetupImportPlateLayoutView({
                    model: this.model,
                    tab: 'importPlateLayout'
                });
                var region = this.getRegion('pcrSetupRegion');
                if (region) {
                    region.show(this.pcrSetupView);
                    this.listenTo(this.pcrSetupView, 'do something', _.bind(this.onDoSomething, this));
                }
            }
        },

        onImportResult: function () {
            if (!this.pcrSetupView || this.pcrSetupView.options.tab !== 'importResult') {
                if (this.pcrSetupView) {
                    this.pcrSetupView.destroy();
                }
                this.pcrSetupView = new PcrSetupImportResultView({
                    model: this.model,
                    tab: 'importResult'
                });
                var region = this.getRegion('pcrSetupRegion');
                if (region) {
                    region.show(this.pcrSetupView);
                    this.listenTo(this.pcrSetupView, 'run:create-from-popup', _.bind(this.onCreatefromPopup, this));
                    this.listenTo(this.pcrSetupView, 'hide:pcrSetup:popup', _.bind(this.hide, this));
                }
            }
        },

        onCreatefromPopup: function (file, anaylyzer, protocol, plateLayout, generateStarterSheet) {
            this.trigger('run:create-from-popup', file, anaylyzer, protocol, plateLayout, generateStarterSheet);
        },

        onFlexibleSetup: function () {
            if (!this.pcrSetupView || this.pcrSetupView.options.tab !== 'flexibleSetup') {
                if (this.pcrSetupView) {
                    this.pcrSetupView.destroy();
                }
                this.pcrSetupView = new PcrSetupFlexibleSetupView({
                    model: this.model,
                    tab: 'flexibleSetup'
                });
                var region = this.getRegion('pcrSetupRegion');
                if (region) {
                    region.show(this.pcrSetupView);
                    this.listenTo(this.pcrSetupView, 'do something', _.bind(this.onDoSomething, this));
                }
            }
        },

        onHitPicking: function () {
            if (!this.pcrSetupView || this.pcrSetupView.options.tab !== 'hitPicking') {
                if (this.pcrSetupView) {
                    this.pcrSetupView.destroy();
                }
                this.pcrSetupView = new PcrSetupHitPickingView({
                    model: this.model,
                    tab: 'hitPicking'
                });
                var region = this.getRegion('pcrSetupRegion');
                if (region) {
                    region.show(this.pcrSetupView);
                    this.listenTo(this.pcrSetupView, 'do something', _.bind(this.onDoSomething, this));
                }
            }
        },

        onDoSomething: function () {

        },

        initialize: function (options) {
            this.model = options.runModel;
        },

        serializeData: function () {
            return this.model.toJSON();
        },

        onShow: function () {
            $.material.init();
        },

        onRender: function () {
        },

        onConfirm: function () {
        },

        onSave: function () {
        },

        hide: function () {
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
            if (App.getCurrentRoute() === 'runs/pcr/new' || App.getCurrentRoute() === 'runs/pcr/new/setup') {
                App.navigate('runs/pcr', {trigger: (this.currentMode !== 'fromCyclerExport')});
            }
        },

        onSaveError: function () {
        }
    });
});
