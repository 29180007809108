define('prepRunsMainRouter',[
	'require',
	'exports',
	'module',
	'logger',
	'backbone',
	'marionette',
	'app',
	'wellUtils',
	'cssUtils',
	'underscore',
	'jquery',
	'contentShowController',
	'itemPagesNameMixin',
	'emptyView',
	'entities/caccounts/setting',
	'entities/caccounts/prepruns'
], function (
	require,
	exports,
	module,
	Logger,
	Backbone,
	Marionette,
	App,
	WellUtils,
	CssUtils,
	_,
	$,
	ContentShowController,
	ItemPagesNameMixin,
	EmptyView
) {
	'use strict';

	var RunsRouter = Marionette.AppRouter.extend({
		appRoutes: {
			'runs/prep': 'showPrepView',
			'runs/prep/empty': 'showEmpty',
			'runs/prep/new': 'showPrepViewNewRun',
			'runs/prep/:runSecId': 'showPrepFromRunSecID',
			'runs/prep/:runSecId/:from/:step/:to': 'showPrepRunTableTab',
			'runs/prep/:runSecId/edit': 'showPrepRunTableTabWithEdit',
			'runs/prep/:runSecId/detail': 'showPrepRunDetailTab',
			'runs/prep/:runSecId/:from/:step/:to/table': 'showPrepRunTableTab',
			'runs/prep/:runSecId/:from/:step/:to/table/filters/:filters': 'showPrepRunTableTabWithFilters',
			'runs/prep/:runSecId/:from/:step/:to/details': 'showPrepRunDetailsTab',
			'runs/prep/:runSecId/:from/:step/:to/details/filters/:filters': 'showPrepRunDetailsTabFilters',
			'runs/prep/:runSecId/:from/:step/:to/details/row/:row': 'showPrepRunDetailsTabRow',
			'runs/prep/:runSecId/:from/:step/:to/details/column/:column': 'showPrepRunDetailsTabColumn',
			'runs/prep/:runSecId/:from/:step/:to/details/row/:row/column/:column': 'showPrepRunDetailsTab',
			'runs/prep/:runSecId/:from/:step/:to/details/row/:row/column/:column/filters/:filters': 'showPrepRunDetailsRowColTabFilters',
			'runs/prep/:runSecId/:from/:step/:to/details/select': 'showPrepRunDetailsSelectTab',
			'runs/prep/:runSecId/:from/:step/:to/table/select': 'showPrepRunTableSelectTab'
			// 'runs/prep/:runSecId/:from/:step/:to/tracs': 'showPrepRunTracsTab'
		}
	});

	var API = {
		checkContent: function () {
			var defer = $.Deferred();

			require([
				'contentShowController'
			], function (ContentShowController) {
				ContentShowController.showContent().done(function () {
					defer.resolve();
				});
			});
			return defer.promise();
		},

		showPrepRunDetailsSelectTab: function (runSecId, from, step, to) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunDetailsTabController'
					], _.bind(function (PrepRunTabsLayoutController, PrepRunDetailsTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region = PrepRunTabsLayoutController.showTabsLayout(region, 'details', this.model);
						PrepRunDetailsTabController.showDetailsTab(region, this.model, from, step, to, null, null, null);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		},

		showPrepRunTableSelectTab: function (runSecId, from, step, to) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunTableTabController'
					], _.bind(function (prepRunTabsLayoutController, prepRunTableTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region = prepRunTabsLayoutController.showTabsLayout(region, 'table', this.model);
						prepRunTableTabController.showTableTab(region, this.model, from, step, to);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		},

		findModel: function (runSecId, callback) {
			var defer = $.Deferred();
			var model = App.request('run:prep:model', {secId: runSecId});
			model.fetch().done(_.bind(function () {
				this.model = model;
				if (callback) {
					callback();
				}
				defer.resolve();
			}, this));

			return defer.promise();
		},

		setModel: function (modelJson, callback) {
			if (this.model && this.model.get('secId') === modelJson.secId) {
				this.model = App.request('run:prep:model', modelJson);
			}
			var defer = $.Deferred();
			defer.resolve();
			if (callback) {
				callback(this.model);
			}
			return defer.promise();
		},

		getModel: function (callback) {
			var defer = $.Deferred();
			defer.resolve();
			if (callback) {
				callback(this.model);
			}
			return defer.promise();
		},

		showEmpty: function () {
		},

		showPrepViewNewRun: function () {
			this.showPrepViewRun('new');
		},

		showPrepViewImportRun: function () {
			this.showPrepViewRun('import');
		},

		showPrepViewRun: function (newItem) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			require([
				'prepRunsShowController', 'prepRunTabsLayoutController'
			], function (ShowController, PrepRunTabsLayoutController) {
				ContentShowController.showContent().done(function () {

					PrepRunTabsLayoutController.setCurrentTab(null); // always show the 'table' (plate view) tab when showing a selected run from the listView

					PrepRunTabsLayoutController.removeAllFiltersFromList();
					App.startSubModule('prepRunsMain');
					ShowController.showRuns();
					App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					if (newItem) {
						if (newItem === 'new') {
							App.request('setting:get-setting-by-code', 'DEF_DEPT')
								.done(_.bind(function (defDept) {
									ShowController.showNewRun(defDept);
								}, this));
						} else {
							ShowController.showImportRun();
						}
					} else if (ShowController.createEditView) {
						ShowController.createEditView.hide(true);
					}
				});
			});
		},

		showPrepView: function () {
			this.showPrepViewRun(false);
		},

		showPrepRunDetailTab: function (runSecId) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunDetailTabController'
					], _.bind(function (PrepRunTabsLayoutController, PrepRunDetailTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region.show(new EmptyView());
						region = PrepRunTabsLayoutController.showTabsLayout(region, 'detail', this.model);
						PrepRunDetailTabController.showDetailTab(region, this.model);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		},

		showPrepRunTableTabWithEdit: function (runSecId) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunTableTabController'
					], _.bind(function (prepRunTabsLayoutController, prepRunTableTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region.show(new EmptyView());
						region = prepRunTabsLayoutController.showTabsLayout(region, 'table', this.model, true);
						prepRunTableTabController.showTableTab(region, this.model);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		},

		showPrepFromRunSecID: function (runSecId) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				App.navigate('runs/prep/' + this.model.get('secId') + '/A01/1/' + this.model.get('plateSize'), {trigger: true});
			}, this));
		},

		showPrepRunTableTab: function (runSecId, from, step, row, column) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunTableTabController'
					], _.bind(function (PrepRunTabsLayoutController, PrepRunTableTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region.show(new EmptyView());
						region = PrepRunTabsLayoutController.showTabsLayout(region, 'table', this.model);
						PrepRunTableTabController.showTableTab(region, this.model, from, step, row, column);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		},

		showPrepRunTableTabWithFilters: function (runSecId, from, step, pos, filters) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunTableTabController'
					], _.bind(function (prepRunTabsLayoutController, prepRunTableTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region.show(new EmptyView());
						region = prepRunTabsLayoutController.showTabsLayout(region, 'table', this.model, null, from, step, pos, filters);
						prepRunTableTabController.showTableTab(region, this.model, from, step, pos, filters);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		},

		showPrepRunDetailsTab: function (runSecId, from, step, to, row, column) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunDetailsTabController'
					], _.bind(function (PrepRunTabsLayoutController, PrepRunDetailsTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region = PrepRunTabsLayoutController.showTabsLayout(region, 'details', this.model);
						PrepRunDetailsTabController.showDetailsTab(region, this.model, from, step, to, row, column);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		},

		showPrepRunDetailsRowColTabFilters: function (runSecId, from, step, to, row, column, filters) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunDetailsTabController'
					], _.bind(function (PrepRunTabsLayoutController, PrepRunDetailsTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region = PrepRunTabsLayoutController.showTabsLayout(region, 'details', this.model);
						var filtersJson = filters ? JSON.parse(filters) : null;
						PrepRunDetailsTabController.showDetailsTab(region, this.model, from, step, to, row, column, filtersJson);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		},

		showPrepRunDetailsTabFilters: function (runSecId, from, step, to, filters) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunDetailsTabController'
					], _.bind(function (PrepRunTabsLayoutController, PrepRunDetailsTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region = PrepRunTabsLayoutController.showTabsLayout(region, 'details', this.model);
						var filtersJson = filters ? JSON.parse(filters) : null;
						PrepRunDetailsTabController.showDetailsTab(region, this.model, from, step, to, null, null, filtersJson);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		},

		showPrepRunDetailsTabRow: function (runSecId, from, step, to, row) {
			this.showRunDetailsTab(runSecId, from, step, to, row);
		},

		showPrepRunDetailsTabColumn: function (runSecId, from, step, to, column) {
			this.showRunDetailsTab(runSecId, from, step, to, null, column);
		},

		showRunDetailsTab: function (runSecId, from, step, to, row, column) {
			WellUtils.dismissRemainingPopovers();
			CssUtils.applyPrepRunTheme();
			this.findModel(runSecId).done(_.bind(function () {
				ContentShowController.showContent().done(_.bind(function () {
					require([
						'prepRunTabsLayoutController',
						'prepRunDetailsTabController'
					], _.bind(function (PrepRunTabsLayoutController, PrepRunDetailsTabController) {
						var region = App.regions.getRegion('main').currentView.getRegion('content');
						region = PrepRunTabsLayoutController.showTabsLayout(region, 'details', this.model);
						PrepRunDetailsTabController.showDetailsTab(region, this.model, from, step, to, row, column);
						App.execute('set:active:nav:item', ItemPagesNameMixin.PREPRUNS);
					}, this));
				}, this));
			}, this));
		}
	};

	App.on('runs:prep:show', function () {
		App.navigate('runs/prep');
		API.showPrepView();
	});

	App.on('before:start', function () {
		new RunsRouter({
			controller: API
		});
	});

	App.on('run:prep:refresh', _.bind(function (runSecId, callback) {
		$('.js-global-loader').show();
		API.findModel(runSecId, callback).done(_.bind(function () {
			$('.js-global-loader').hide();
		}));
	}));

	App.on('run:prep:refresh:model', _.bind(function (model) {
		API.setModel(model);
	}));

	module.exports = RunsRouter;
});
