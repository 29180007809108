define('documentCreateEditLayoutView',[
	'module',
	'marionette',
	'backbone',
	'dialogFormView',
	'template!documentCreateEditLayoutTpl',
	'savingBehavior',
	'duplicatePopupBehavior',
	'bootbox',
	'settings',
	'underscore',
	'jquery',
	'autocompleteView',
	'app',
	'entities/admin/codelist'
], function (
	module,
	Marionette,
	Backbone,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	DuplicatePopupBehavior,
	bootbox,
	Settings,
	_,
	$,
	AutocompleteView,
	App,
	CodeLists
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			uploadFile: '.js-upload-file',
			fileLinkElement: '.js-file-link'
		},

		events: {
			'click .js-cancel-popup': 'onCancel',
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-confirm': 'onConfirm',
			'change @ui.uploadFile': 'onUploadFile'
		},

		regions: {
			selectContentType: '.js-select-contentype-region',
			selectLanguage: '.js-select-language-region'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			},
			DuplicatePopup: {
				behaviorClass: DuplicatePopupBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		fieldsToValidate: [
			{name: 'name', type: 'required'}
		],

		initialize: function (options) {
			this.model = options.model.clone();
			DialogFormView.prototype.initialize.call(this);
		},

		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.newItem = this.options.newItem;
			return templateData;
		},

		onShow: function () {
			$.material.init();
			this.ui.input.popover({
				trigger: 'manual'
			});
			this.selectContentTypeView = new AutocompleteView(
				this._getAutocompleteOptionObject(
				CodeLists.getAutocompleteParamForCode({
					modelProperty: 'contentType',
					codeListCode: 'DOCTYPE'
				})));
			this.getRegion('selectContentType').show(this.selectContentTypeView);
			this.selectLanguageView = new AutocompleteView(
				this._getAutocompleteOptionObject(
				CodeLists.getAutocompleteParamForCode({
					modelProperty: 'language',
					codeListCode: 'LANG'
				})));
			this.getRegion('selectLanguage').show(this.selectLanguageView);
			this.triggerMethod('enable:cancel:confirm');
			this.ui.fileLinkElement.replaceWith('<a  class="js-file-link" ' +
				'onClick="window.open(\'' + Settings.url('compuzz', 'public/document/getfileByName/' + this.model.get('name')) +
				'\', \'document\', \'width=\' + window.outerWidth * 0.6 + \',height=\' + window.outerWidth * 0.7);">' + this.model.get('name') + '</a>');
		},

		onConfirm: function () {
			if (this.validate(this.fieldsToValidate)) {
				this.model.save();
			}
		},

		onSave: function (model) {
			var modelJSON = model.toJSON();
			this.model.set(modelJSON);
			this.box.modal('hide');
		},

		hide: function () {
			if (App.getCurrentRoute() === 'superAdmin/documents/new') {
				App.navigate('superAdmin/documents', {trigger: true});
			} else {
				App.navigate('superAdmin/documents/refresh', {trigger: false});
				App.navigate('superAdmin/documents', {trigger: true, replace: true});
			}
		},

		onSaveError: function (model, response) {
			if (response.status === 409) {
				this.onDuplicateFieldValue(response.responseJSON);
				this.triggerMethod('hide:label');
			}
		},

		onCancel: function () {
			this.box.modal('hide');
		},

		onDuplicateFieldValue: function (codeError) {
			var fields = this.$el.find('*[data-field-name="' + codeError.field + '"]');
			fields.each(function () {
				if ($(this).val() === codeError.value) {
					$(this).popover('show');
					setTimeout(function () {
						$('.popover').fadeOut('slow', function () {
						});
					}, 2000);
					$(this).css('border-color', 'red');
					$(this).addClass('invalid');
					$('div[data-field-name="' + codeError.field + '"]').addClass('has-error');
				}
			});
		},
		onUploadFile: function (e) {
			if (e && e.target && e.target.files && e.target.files[0]) {
				var that = this;
				this.ui.uploadFile.css('color', 'black');
				this.fileChoosen = true;
				this.model.set('name', e.target.files[0].name);
				$('input[data-field-name=\'name\']').val(this.model.get('name'));
				App.request('document:import-file', e.target.files[0]).done(function () {
					that.ui.fileLinkElement.replaceWith('<a  class="js-file-link" ' +
						'onClick="window.open(' + Settings.url('compuzz', 'public/document/getfileByName/' + that.model.get('templateName')) +
						', \'report\', \'width=\' + window.outerWidth * 0.6 + \',height=\' + window.outerWidth * 0.7);">' + that.model.get('templateName') + '</a>');
				});
			}
			this.hide();
		}


	});
});
