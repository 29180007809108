define('entities/errors',[
    'backbone',
    'module',
    'backboneRelational'
], function (
    Backbone,
    module
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Error = Backbone.RelationalModel.extend({
        defaults: {},
        idAttribute: 'uniqueId',
        relations: [{
            type: Backbone.HasMany,
            key: 'errors',
            relatedModel: 'Error',
            collectionType: 'ErrorCollection'
        }]
    });

    app.ns.ErrorCollection = Backbone.Collection.extend({
        model: app.ns.Error
    });

    module.exports = {
        getCollection: function (models) {
            return new app.ns.ErrorCollection(models);
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Error.findOrCreate(modelJson);
        }
    };
});
