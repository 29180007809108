
define('template!assayReagentICTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h2 class="m-0 m-b-5 col-xs-12 p-0">' +
((__t = ( _.i18n('assay.reagent.ic.title') )) == null ? '' : __t) +
'</h2>\n<div class="col-xs-12 p-r-2 p-l-2">' +
((__t = ( _.i18n('assayReagent.kit.title') )) == null ? '' : __t) +
'</div>\n<div class="collection col-xs-12 p-0 m-b-5">\n\t<div class="col-xs-11 p-r-2 p-l-2 js-kit"></div>\n\t<div class="col-xs-1 p-r-2 p-l-2"><span class="mdi mdi-eye clickable show-row js-icon invisible"\n\t\t\t\t\t\t\t\t\t\t\tstyle="line-height: 22px; font-size: 25px;"></span></div>\n</div>\n\n<div class="col-xs-4 dataLabelContainer p-r-0 p-l-0">\n\t<label class="control-label p-r-5">' +
((__t = ( _.i18n('assay.volumeIC')
		)) == null ? '' : __t) +
'</label>\n</div>\n<div class="col-xs-4 p-r-2 p-l-2">\n\t<input type="number" step="0.01" min="0"\n\t\t   class="js-info-input-IC form-control inputBackground"\n\t\t   data-field-name="volumeIC"\n\t\t   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n\t\t   value="' +
((__t = ( volumeIC?volumeIC:'' )) == null ? '' : __t) +
'"/>\n</div>';

}
return __p
};});

