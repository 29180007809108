
define('template!bioTrashTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="well jq-grid-table-page">\n        <div class="trash-listView">\n            <div class="row">\n                <div class="col-xs-12" style="display: flex;">\n                    <div class="clickable" style="width: 45px; height: 45px; padding-left: 4px;">\n                        <div class="js-back-button">\n                            <span class="menuBackIcon mdi mdi-reply"></span>\n                        </div>\n                    </div>\n                    <div class="" style="width: max-content;\n                    font-size: 27px;\n                    font-weight: 500;\n                    margin-left: 5px;">\n                        ' +
((__t = ( _.i18n('menu.admin.trash') )) == null ? '' : __t) +
'\n                    </div>\n                </div>\n            </div>\n            <div class="trash-grid">\n                <table id="jq-grid-list" class="trash-table" role="grid"></table>\n                <div id="jq-grid-pager-list"></div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

