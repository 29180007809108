define('runChooseDownloadView',[
    'module',
    'marionette',
    'backbone',
    'dialogFormView',
    'template!runChooseDownloadTpl',
    'entities/caccounts/pcrruns',
    'entities/caccounts/prepruns'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    chooseDownloadLayoutTpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: chooseDownloadLayoutTpl,

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .run-export-remote-folder': 'onExportToRemoteFolder',
            'click .run-export-folder': 'onExportFolder'
        },

        fromTemplate: true,

        initialize: function (initObject) {
            this.settings = initObject.settings;
            this.accept = this.options.accept;

        },

        serializeData: function () {
            var templateData = {};
            templateData.settings = this.settings;
            templateData.exportSetting = this.options.exportSetting;
            templateData.accept = this.accept;
            return templateData;
        },

        onExportFolder: function () {
            this.triggerMethod('run:local-export-xml');
            this.hide();
        },

        onExportToRemoteFolder: function () {
            this.triggerMethod('run:remote-export-xml');
            this.hide();
        },

        onCancel: function () {
            this.hide();
        },

        hide: function () {
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        }
    });
});
