define('runDetailsTabView',[
	'module',
	'marionette',
	'template!runDetailsTabTpl',
	'underscore',
	'dialogFormView'
], function (
	module,
	Marionette,
	detailsTabTpl,
	_,
	DialogFormView
) {
	'use strict';


	module.exports = DialogFormView.extend({
		template: detailsTabTpl,

		regions: {
			rightView: '.right-view',
			wellTable: '.well-table'
		},

		serializeData: function () {
			return {};
		}
	});
});
