define('entities/caccounts/pcrSetup/prepRunLite',[
	'app',
	'backbone',
	'underscore',
	'backboneRelational',
	'entities/caccounts/pcrSetup/prepWellLite',
	'entities/caccounts/kitprots'
], function (
	App,
	Backbone,
	_
) {
	'use strict';
	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.PrepRunLite = Backbone.RelationalModel.extend({
		defaults: {
			'name': undefined,
			'period': {},
			'periodFormatted': undefined,
			'group': undefined,
			'wells': [],
			'pcrProtocols': []
		},
		idAttribute: 'secId',
		relations: [{
			type: Backbone.HasMany,
			key: 'wells',
			relatedModel: 'PrepWellLite',
			collectionType: 'PrepWellLiteCollection',
			reverseRelation: {
				key: 'refRun',
				includeInJSON: false
			}
		}, {
			type: Backbone.HasMany,
			key: 'pcrProtocols',
			relatedModel: 'KitProt',
			collectionType: 'KitProtCollection'
		}],
		getColumnNumber: function () {
			return Number.parseInt(this.get('plateSize').substring(1));
		},
		generateColor: function (assay) {
			var indexColor = 0;
			if (assay) {
				var found = true;
				this.get('wells').each(_.bind(function (well) {
					found = well.get('sampleType') !== 'E' && !!well.get('sample').code && well.get('waiting').contains(assay);
					well.set({'color': '#FFFFFF', available: found});
				}, this));
			} else {
				var assays = null;
				this.get('wells').each(_.bind(function (well) {
					var available = well.get('sampleType') !== 'E' && !well.get('waiting').isEmpty() && !!well.get('sample').code;
					var color = '#FFFFFF';
					if (available) {
						if (assays === null) {
							assays = well.get('waiting');
						}
						if (!assays.isEqual(well.get('waiting'))) {
							if (indexColor + 1 >= well.get('refRun').get('refPcrSetup').get('colors').length) {
								indexColor = 0;
							} else {
								indexColor++;
							}
							assays = null;
						}
						color = well.get('refRun').get('refPcrSetup').get('colors')[indexColor];
					} else {
						assays = null;
					}
					well.set({color: color, available: available});
				}, this));
			}
		},
		findBlock: function (begin, end) {
			var indexBegin = this.get('wells').indexOf(begin);
			var indexEnd = this.get('wells').indexOf(end);
			var wells = new Backbone.Collection(this.get('wells').slice(_.min([indexBegin, indexEnd]), _.max([indexBegin, indexEnd]) + 1));
			return wells.filter(function (well) {
				return well.get('available');
			});
		},
		canDisplay: function (assay, protocol) {
			var display = true;
			if (display && protocol) {
				display = display && this.get('pcrProtocols').any(function (pcrProtocol) {
					return pcrProtocol.get('secId') === protocol;
				});
			}
			if (display && assay) {
				display = display && (this.get('wells').any(function (well) {
					return well.get('available');
				}));
			}
			return display;
		}
	});

	app.ns.PrepRunLiteCollection = Backbone.Collection.extend({
		model: app.ns.PrepRunLite,
		type: undefined,
		comparator: function (model1, model2) {
			if (model1.get('period') === model2.get('period')) {
				return 0;
			}
			if (model1.get('period') < model2.get('period')) {
				return 1;
			}
			return -1;
		},
		generateColor: function (assay) {
			_.each(this.models, function (model) {
				model.generateColor(assay);
			});
		},
		findWell: function (prepWell) {
			var well;
			this.each(_.bind(function (model) {
				var w = model.get('wells').findWhere({secId: prepWell.get('secId') + (this.type ? this.type : '')});
				if (!well) {
					well = w;
				}
			}, this));
			return well;
		}
	});
});
