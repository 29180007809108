define('prepRunCreatedPcrFromPrepView',[
    'module',
    'backbone',
    'app',
    'dialogFormView',
    'template!prepRunCreatedPcrFromPrepTpl',
    'underscore',
    'jquery',
    'bootbox',
    'settings',
    'entities/caccounts/pcrruns'
], function (
    module,
    Backbone,
    App,
    DialogFormView,
    tpl,
    _,
    $,
    Bootbox,
    Settings,
    PcrRuns
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            checkboxPrintPcrSetup: '.js-created-pcrRun-printPcrSetup',
            checkboxPrintPcrSetupMMX: '.js-created-pcrRun-printPcrSetupMMX',
            checkboxExportPlateLayout: '.js-created-pcrRun-exportPlateLayout',
            checkboxExportPlateLayoutLocal: '.js-created-pcrRun-exportPlateLayout-local'
        },

        events: {
            'click .js-confirm': 'onConfirm',
            'click @ui.checkboxExportPlateLayout': 'onCheckboxExportPlateLayoutClick'
        },

        onCheckboxExportPlateLayoutClick: function (e) {
            if (e.currentTarget.checked) {
                this.ui.checkboxExportPlateLayoutLocal.prop('disabled', false);
            } else {
                this.ui.checkboxExportPlateLayoutLocal.prop('disabled', true);
                this.ui.checkboxExportPlateLayoutLocal.attr('checked', false);
            }
        },

        serializeData: function () {
            var templateData = {};
            templateData.name = this.model.get('pcrRun').name;
            templateData.prepRunName = this.model.get('prepRun').code;
            templateData.plateId = this.model.get('pcrRun').plateIdPC;
            templateData.protocol = this.model.get('pcrRun').refKitProt.code;
            templateData.cycler = this.model.get('pcrRun').refCyclerPublic.code;
            templateData.assays = this.model.get('assayLines');
            templateData.displayLocalDownload = this.options.exportPlateLayoutSetted;
            templateData.pcrExportPlateLayout = this.options.pcrExportPlateLayout;
            templateData.printPcrSetup = this.options.printPcrSetup;
            templateData.printPcrSetupMMX = this.options.printPcrSetupMMX;
            return templateData;
        },

        onShow: function () {
            $.material.init();
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            if (this.ui.checkboxExportPlateLayout.prop('checked')) {
                var pcrRunModel = PcrRuns.getModel(this.model.get('pcrRun'));
                var downloadFile = this.ui.checkboxExportPlateLayoutLocal.prop('checked');
                pcrRunModel.exportPlateLayout({downloadFile: downloadFile}).done(function (isFile, fileName, fileContent) {
                    if (isFile) {
                        var element = document.createElement('a');
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent));
                        element.setAttribute('download', fileName);

                        element.style.display = 'none';
                        document.body.appendChild(element);

                        element.click();

                        document.body.removeChild(element);
                    } else {
                        Bootbox.alert({
                            message: _.i18n('pcrSetupCreated.plateLayoutExported'),
                            backdrop: true,
                            className: 'success'
                        });
                    }
                });
            }
            if (this.ui.checkboxPrintPcrSetup.prop('checked')) {
                PcrRuns.generatePcrSetup(this.model.get('pcrRun').secId);
            }
            if (this.ui.checkboxPrintPcrSetupMMX.prop('checked')) {
                PcrRuns.generatePcrSetupMMX(this.model.get('pcrRun').secId);
            }
            this.box.modal('hide');
        }
    });
});
