/**
 * Created by OBL on 14/01/2016.
 */
define('superAdminDocumentController',[
  'module',
  'underscore',
  'app',
  'superAdminDocumentsView',
  'bootbox',
  'documentCreateEditLayoutView',
  'entities/admin/document'
], function (
  module,
  _,
  App,
  View,
  bootbox,
  CreateEditLayoutView
) {
  'use strict';

  var SuperAdminDocumentController = {
      show: function () {
          var view = new View();
          this.view = view;
          view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
          view.listenTo(view, 'document:delete', _.bind(this.onDelete, this));
          view.listenTo(view, 'document:update', _.bind(this.onUpdateDocuments, this));

          App.regions.getRegion('main').currentView.getRegion('content').show(view);
      },
      showAdd: function (newItem) {
          this.createEditView = new CreateEditLayoutView({
              model: App.request('document:model'),
              newItem: newItem
          });
          this.createEditView.show({
              title: _.i18n('menu.superadmin.documents'),
              className: 'baseTableEditAddPopup'
          });
      },
      onGridLoaded: function () {
          var data = {
              url: App.request('document:get-collection-url'),
              rowCountUrl: App.request('document:get-count-url')
          };

          this.view.displayGrid(data);
      },

      onDelete: function (secId) {
          bootbox.confirm(_.i18n('document.confirmDelete'), _.bind(function (result) {
              if (result) {
                  App.request('document:delete', secId)
                      .done(_.bind(function () {
                          this.view.updatePagination('reloadGrid');
                      }, this));
              }
          }, this));
    },

    onUpdateDocuments : function() {
      var that = this;
      this.view.$el.find('.js-update-button').prop('disabled',true);
      $.when(App.request('document:update')).done(_.bind(function () {
        that.view.ui.loader.hide();
        this.view.$el.find('.js-update-button').prop('disabled',false);
        that.view.updatePagination('reloadGrid');
      }, this));
    }
  };

  module.exports = SuperAdminDocumentController;
});
