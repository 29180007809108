
define('template!menuElementItemCollectionViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div style="width:100%;display: flex;">\n\t<div style="width:15%;">\n\t\t<input class="js-info-input-child js-code dataNameIdInput inputBackground"\n\t\t\t   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'" data-field-name="code"\n\t\t\t   type="text" value="' +
((__t = (code)) == null ? '' : __t) +
'" style="width:95%;"/>\n\t</div>\n\t<div style="width:17%;">\n\t\t<input class="js-info-input-child dataNameIdInput inputBackground" data-field-name="name"\n\t\t\t   type="text" value="' +
((__t = (name)) == null ? '' : __t) +
'" style="width:95%;"/>\n\t</div>\n\t<div style="width:3%;">\n\t\t<input class="js-info-input-child dataNameIdInput inputBackground" data-field-name="sortSeq"\n\t\t\t   type="text" value="' +
((__t = (sortSeq)) == null ? '' : __t) +
'" maxlength="3" size="3" style="width:95%;"/>\n\t</div>\n\t<div style="width:15%;">\n\t\t<input class="js-info-input-child dataNameIdInput inputBackground" data-field-name="link"\n\t\t\t   type="text" value="' +
((__t = (link)) == null ? '' : __t) +
'" style="width:95%;"/>\n\t</div>\n\t<div style="width:25%;">\n        <textarea class="form-control floating-label js-info-input-child inputBackground"\n\t\t\t\t  cols="60" data-field-name="description" placeholder="" rows="1"\n\t\t\t\t  style="resize:vertical;width:95%;"\n\t\t\t\t  wrap="on" >' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n\t</div>\n\t<div style="width:10%;">\n\t\t<input class="js-info-input-child inputBackground" data-field-name="imagePosition"\n\t\t\t   type="text" value="' +
((__t = (imagePosition)) == null ? '' : __t) +
'" disabled style="width: 80%;"/>\n\t</div>\n\t<div style="width:5%;">\n        <span class="mdi-action-settings-overscan js-select-zone clickable"></span>\n\t</div>\n    <div style="width:5%;">\n        <span class="mdi-action-delete delete-row clickable"></span>\n\t</div>\n</div>';

}
return __p
};});

