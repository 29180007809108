/**
 * Created by bfr on 30/01/2017.
 */
define('runsMainRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'backbone',
    'marionette',
    'app',
    'wellUtils',
    'cssUtils',
    'underscore',
    'contentShowController',
    'itemPagesNameMixin',
    'emptyView',
    'jquery',
    'entities/caccounts/pcrruns'
], function (
    require,
    exports,
    module,
    Logger,
    Backbone,
    Marionette,
    App,
    WellUtils,
    CssUtils,
    _,
    ContentShowController,
    ItemPagesNameMixin,
    EmptyView,
    $,
    PcrRuns
) {
    'use strict';

    var RunsRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'runs/pcr': 'showView',
            'runs/pcr/empty': 'showEmpty',
            'runs/pcr/new': 'showViewNewRun',
            'runs/pcr/new/setup': 'showViewNewPcrRunSetup',
            'runs/pcr/:runSecId': 'showFromRunSecID',
            'runs/pcr/:runSecId/:from/:step/:to': 'showRunTableTab',
            'runs/pcr/:runSecId/edit': 'showRunTableTabWithEdit',
            'runs/pcr/:runSecId/detail': 'showRunDetailTab',
            'runs/pcr/:runSecId/:from/:step/:to/table': 'showRunTableTab',
            'runs/pcr/:runSecId/:from/:step/:to/table/filters/:filters': 'showRunTableTabWithFilters',
            'runs/pcr/:runSecId/:from/:step/:to/details': 'showRunDetailsTab',
            'runs/pcr/:runSecId/:from/:step/:to/details/filters/:filters': 'showRunDetailsTabFilters',
            'runs/pcr/:runSecId/:from/:step/:to/details/row/:row': 'showRunDetailsTabRow',
            'runs/pcr/:runSecId/:from/:step/:to/details/column/:column': 'showRunDetailsTabColumn',
            'runs/pcr/:runSecId/:from/:step/details/row/:row': 'showRunDetailsTabRowTest',
            'runs/pcr/:runSecId/:from/:step/details/column/:column': 'showRunDetailsTabColumnTest',
            'runs/pcr/:runSecId/:from/:step/:to/details/row/:row/column/:column': 'showRunDetailsTab',
            'runs/pcr/:runSecId/:from/:step/:to/details/row/:row/column/:column/filters/:filters': 'showRunDetailsRowColTabFilters',
            'runs/pcr/:runSecId/:from/:step/:to/details/select': 'showRunDetailsSelectTab',
            'runs/pcr/:runSecId/:from/:step/:to/table/select': 'showRunTableSelectTab',
            'runs/pcr/:runSecId/:from/:step/:to/results': 'showRunResultsTab',
            'runs/pcr/:runSecId/:from/:step/:to/assays-targets': 'showRunAssaysTargets2Tab',
            'runs/pcr/:runSecId/:from/:step/:to/contaminations': 'showRunContaminationsTab'
        }
    });

    var API = {
        findModel: function (runSecId, callback) {
            var defer = $.Deferred();
            if (this.model && this.model.get('secId') === runSecId) {
                defer.resolve();
            } else {
                var model = PcrRuns.getModel({secId: runSecId});
                model.fetch().done(_.bind(function () {
                    this.model = model;
                    if (callback) {
                        callback();
                    }
                    defer.resolve();
                }, this));
            }
            return defer.promise();
        },

        setModel: function (modelJson, callback) {
            if (this.model && this.model.get('secId') === modelJson.secId) {
                this.model = PcrRuns.getModel(modelJson);
            }
            var defer = $.Deferred();
            defer.resolve();
            if (callback) {
                callback(this.model);
            }
            return defer.promise();
        },

        getModel: function (callback) {
            var defer = $.Deferred();
            defer.resolve();
            if (callback) {
                callback(this.model);
            }
            return defer.promise();
        },

        showEmpty: function () {
            CssUtils.applyPcrRunTheme();
        },

        showViewNewRun: function () {
            this.showViewRun('new');
        },

        showViewNewPcrRunSetup: function () {
            this.showViewRun('newPcrSetup');
        },

        showViewRun: function (newItem) {
            WellUtils.dismissRemainingPopovers();
            require([
                'runsShowController', 'runTabsLayoutController'
            ], function (ShowController, RunTabsLayoutController) {
                ContentShowController.showContent().done(function () {

                    CssUtils.applyPcrRunTheme();

                    RunTabsLayoutController.setCurrentTab(null); // always show the 'table' (plate view) tab when showing a selected run from the listView
                    RunTabsLayoutController.removeAllFiltersFromList();
                    App.startSubModule('runsMain');
                    ShowController.showRuns();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    if (newItem) {
                        if (newItem === 'new') {
                            ShowController.showNewRun();
                        } else if (newItem === 'newPcrSetup') {
                            ShowController.showNewPcrRunSetup();
                        } else {
                            ShowController.showImportRun();
                        }
                    } else if (ShowController.createEditView) {
                        ShowController.createEditView.hide(true);
                    }
                });
            });
        },

        showView: function () {
            this.showViewRun(false);
        },

        showRunDetailTab: function (runSecId) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runDetailTabController'
                    ], _.bind(function (RunTabsLayoutController, RunDetailTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region.show(new EmptyView());
                        region = RunTabsLayoutController.showTabsLayout(region, 'detail', this.model);
                        RunDetailTabController.showDetailTab(region, this.model);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunAssaysTargets2Tab: function (runSecId, from, step, to) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runAssaysTargetsTabController'
                    ], _.bind(function (RunTabsLayoutController, RunAssaysTargetsTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region.show(new EmptyView());
                        region = RunTabsLayoutController.showTabsLayout(region, 'assays-targets', this.model);
                        RunAssaysTargetsTabController.showAssaysTargetsTab(region, this.model, from, step, to);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunTableTabWithEdit: function (runSecId) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runTableTabController'
                    ], _.bind(function (RunTabsLayoutController, RunTableTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region.show(new EmptyView());
                        region = RunTabsLayoutController.showTabsLayout(region, 'table', this.model, true);
                        RunTableTabController.showTableTab(region, this.model);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showFromRunSecID: function (runSecId) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                var step = this.model.get('wellCount') <= 96 ? 1 : 2;
                App.navigate('runs/pcr/' + this.model.get('secId') + '/A01/' + step + '/' + this.model.get('plateSize'), {trigger: true});
            }, this));
        },


        showRunContaminationsTab: function (runSecId, from, step, row, column) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runContaminationsTabController'
                    ], _.bind(function (RunTabsLayoutController, RunContaminationsTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region.show(new EmptyView());
                        region = RunTabsLayoutController.showTabsLayout(region, 'contaminations', this.model);
                        RunContaminationsTabController.showContaminationsTab(region, this.model, from, step, row, column);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },


        showRunTableTab: function (runSecId, from, step, row, column) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runTableTabController'
                    ], _.bind(function (RunTabsLayoutController, RunTableTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region.show(new EmptyView());
                        region = RunTabsLayoutController.showTabsLayout(region, 'table', this.model);
                        RunTableTabController.showTableTab(region, this.model, from, step, row, column);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunTableTabWithFilters: function (runSecId, from, step, pos, filters) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runTableTabController'
                    ], _.bind(function (RunTabsLayoutController, RunTableTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region.show(new EmptyView());
                        region = RunTabsLayoutController.showTabsLayout(region, 'table', this.model, null, from, step, pos, filters);
                        RunTableTabController.showTableTab(region, this.model, from, step, pos, filters);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunDetailsTab: function (runSecId, from, step, to, row, column) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runDetailsTabController'
                    ], _.bind(function (RunTabsLayoutController, RunDetailsTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region = RunTabsLayoutController.showTabsLayout(region, 'details', this.model);
                        RunDetailsTabController.showDetailsTab(region, this.model, from, step, to, row, column);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunDetailsSelectTab: function (runSecId, from, step, to) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runDetailsTabController'
                    ], _.bind(function (RunTabsLayoutController, RunDetailsTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region = RunTabsLayoutController.showTabsLayout(region, 'details', this.model);
                        RunDetailsTabController.showDetailsTab(region, this.model, from, step, to, null, null, null);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunTableSelectTab: function (runSecId, from, step, to) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runTableTabController'
                    ], _.bind(function (RunTabsLayoutController, RunTableTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region = RunTabsLayoutController.showTabsLayout(region, 'table', this.model);
                        RunTableTabController.showTableTab(region, this.model, from, step, to);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunResultsTab: function (runSecId) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runResultsTabController'
                    ], _.bind(function (RunTabsLayoutController, RunResultsTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region = RunTabsLayoutController.showTabsLayout(region, 'results', this.model);
                        var sortOn = this.model.get('settings')['LAB-SETTINGS'] && this.model.get('settings')['LAB-SETTINGS'] === 'C' ? 'column' : 'row';
                        RunResultsTabController.onShowWellTable(region, this.model, null, null, sortOn);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunDetailsRowColTabFilters: function (runSecId, from, step, to, row, column, filters) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runDetailsTabController'
                    ], _.bind(function (RunTabsLayoutController, RunDetailsTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region = RunTabsLayoutController.showTabsLayout(region, 'details', this.model);
                        var filtersJson = filters ? JSON.parse(filters) : null;
                        RunDetailsTabController.showDetailsTab(region, this.model, from, step, to, row, column, filtersJson);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunDetailsTabFilters: function (runSecId, from, step, to, filters) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPcrRunTheme();
            this.findModel(runSecId).done(_.bind(function () {
                ContentShowController.showContent().done(_.bind(function () {
                    require([
                        'runTabsLayoutController',
                        'runDetailsTabController'
                    ], _.bind(function (RunTabsLayoutController, RunDetailsTabController) {
                        var region = App.regions.getRegion('main').currentView.getRegion('content');
                        region = RunTabsLayoutController.showTabsLayout(region, 'details', this.model);
                        var filtersJson = filters ? JSON.parse(filters) : null;
                        RunDetailsTabController.showDetailsTab(region, this.model, from, step, to, null, null, filtersJson);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    }, this));
                }, this));
            }, this));
        },

        showRunDetailsTabRow: function (runSecId, from, step, to, row) {
            this.showRunDetailsTab(runSecId, from, step, to, row);
        },

        showRunDetailsTabColumn: function (runSecId, from, step, to, column) {
            this.showRunDetailsTab(runSecId, from, step, to, null, column);
        },

        showRunDetailsTabRowTest: function (runSecId, from, step, to, row) {
            this.showRunDetailsTab(runSecId, from, step, to, null, row);
        },

        showRunDetailsTabColumnTest: function (runSecId, from, step, to, column) {
            this.showRunDetailsTab(runSecId, from, step, to, null, column);
        }
    };

    App.on('runs:show', function () {
        App.navigate('runs');
        API.showView();
    });

    App.on('before:start', function () {
        new RunsRouter({
            controller: API
        });
    });

    App.on('run:refresh', _.bind(function (runSecId, callback) {
        $('.js-global-loader').show();
        API.findModel(runSecId, callback).done(_.bind(function () {
            $('.js-global-loader').hide();
        }));
    }));

    App.on('run:refresh:model', _.bind(function (model, callback) {
        API.setModel(model, callback);
    }));

    module.exports = RunsRouter;
});
