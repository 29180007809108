define('entities/caccounts/cyclers',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/admin/cyclers',
    'entities/caccounts/assay',
    'backboneRelational',
    'entities/caccounts/lmbcyclers'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    CyclerPublics
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Cycler = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/cyclers/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            'comment': null,
            tags: [],
            'refCycler': null,
            'colorCompensation': false,
            'assays': [],
            'lmbCyclers': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'lmbCyclers',
            relatedModel: 'LmbCycler',
            collectionType: 'LmbCyclerCollection',
            reverseRelation: {
                key: 'refCycler',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refCycler',
            relatedModel: 'CyclerPublic'
        }],

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/cyclers');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'CyclerJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Cycler',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });


    app.ns.CyclerCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/cyclers');
        },
        model: app.ns.Cycler
    });

    module.exports = _.defaults({
        getName: function () {
            return 'cycler';
        },
        getUrl: function () {
            return 'v2/cyclers';
        },
        getDynamicJson: function () {
            return 'CyclerJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.refCycler'),
                name: 'refCycler.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refCycler.code',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.assays'),
                name: 'assays',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                search: false,
                index: 'assays',
                searchoptions: {size: 10},
                width: 80
            }, {
                label: _.i18n('cycler.colorCompensation'),
                name: 'colorCompensation',
                classes: 'dynamic-link',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                index: 'colorCompensation',
                searchoptions: {size: 10},
                width: 20
            }, {
                label: _.i18n('cycler.lmbCyclers'),
                name: 'lmbCyclers',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                search: true,
                index: 'lmbCyclerCode',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Cycler.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.CyclerCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refCycler.code']};
        },

        getCreateEditFieldValidate: function () {
            var ignored = Dynamic.getCreateEditFieldValidate();
            ignored.push({name: 'refCycler', type: 'required'});
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'colorCompensation',
                    'param': {
                        'type': 'BOOLEAN',
                        'readOnly': true
                    }
                },
                {
                    'field': 'refCycler',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {
                            'getAutocompleteParam': CyclerPublics.getAutocompleteParam
                        }
                        // 'context': CyclerPublics
                    }
                },
                {
                    'field': 'assays',
                    'param': {
                        'type': 'LIST2ENTITY'
                    }
                }
            );
            return custom;
        },
        findDefault: function () {
            var defer = $.Deferred();
            var cyclerDefault;
            App.request('setting:get-setting-by-code', 'DEFAULT-VALUES')
                .done(function (value) {
                    if (value && value.params) {
                        cyclerDefault = value.params.split(';')[0];
                    }
                })
                .always(_.bind(function () {
                    if (cyclerDefault) {
                        var url = Settings.url('compuzz', this.getUrl() + '?' + Settings.generateParamUrl({
                            'sord': 'asc',
                            'sidx': 'code',
                            'code': cyclerDefault
                        }));

                        $.ajax({
                            type: 'GET',
                            url: url,
                            success: _.bind(function (data) {
                                defer.resolve(this.getModel(data[0]));
                            }, this)
                        });
                    } else {
                        defer.resolve(undefined);
                    }
                }, this));
            return defer.promise();
        },
        findAll: function (cyclerPublicSecId) {
            var params = {};
            if (cyclerPublicSecId) {
                params.cyclerSecId = cyclerPublicSecId;
            }
            var url = Settings.url('compuzz', 'v2/cyclers', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        }
    }, Dynamic);
});
