define('assayResultTargetValuesVersionCollection',[
    'module',
    'backbone',
    'marionette',
    'assayResultTargetValueVersion'
], function (
    module,
    Backbone,
    Marionette,
    AssayResultTargetValueVersion
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: AssayResultTargetValueVersion
    });
});
