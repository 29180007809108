
define('template!prepRunCreatePcrFromPrepTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container" style="width: 490px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="form-group p-l-0 p-r-0 col-xs-12" data-field-name="pcrRunTemplates">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" style="text-align: right;">' +
((__t = ( _.i18n('pcr.run.template')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-7 p-l-20 p-r-0">\n                    <div class="js-select-runtemplate-region dropdown-custom-width-css"\n                         style="width: fit-content;"></div>\n                </div>\n            </div>\n\n            <div class="form-group p-l-0 p-r-0 col-xs-12" data-field-name="refAssay">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" style="text-align: right;">' +
((__t = ( _.i18n('assay') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-5 p-l-20 p-r-0">\n                    <div class="js-select-assay-region dropdown-custom-width-css"\n                         style="width: fit-content;"></div>\n                </div>\n            </div>\n\n            <div class="form-group p-l-0 p-r-0 col-xs-12" data-field-name="refCycler">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" style="text-align: right;">' +
((__t = ( _.i18n('cycler') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-5 p-l-20 p-r-0">\n                    <div class="js-select-cycler-region dropdown-custom-width-css"\n                         style="width: fit-content;"></div>\n                </div>\n            </div>\n            <div class="form-group p-l-0 p-r-0 col-xs-12" data-field-name="selectedDate">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" style="text-align: right;">' +
((__t = ( _.i18n('run.selectedDate')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-4 p-l-20">\n                    <input type="datepicker" class="form-control floating-label pcrRun-date-select js-info-input"\n                           placeholder="' +
((__t = ( _.i18n('run.selectedDate') )) == null ? '' : __t) +
'" value="' +
((__t = ( currentDate )) == null ? '' : __t) +
'"\n                           data-field-name="selectedDate">\n                    <span class="input-group-btn">\n                            <span class="caret pull-right" style="position: absolute; left: 115px; bottom: 5px;"></span>\n                      </span>\n                </div>\n            </div>\n            <div class="form-group p-l-0 p-r-0 col-xs-12" data-field-name="plateIdPc">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" style="text-align: right;">' +
((__t = ( _.i18n('pcr.run.plateIdPc')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-7 p-l-20 p-r-0">\n                    <input class="form-control floating-label js-info-input run-plateIdPc dataInput"\n                           data-field-name="plateIdPc" title="' +
((__t = ( _.i18n('pcr.run.plateIdPc') )) == null ? '' : __t) +
'" type="text"\n                           placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'" value="' +
((__t = ( plateIdPc )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n            <div class="form-group p-l-0 p-r-0 col-xs-12" data-field-name="respUserSecId">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" style="text-align: right;">' +
((__t = ( _.i18n('refRespUser')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-3 p-l-20 p-r-0">\n                    <div class="js-select-respUser-region dropdown-custom-width-css"\n                         style="width: fit-content;"></div>\n                </div>\n            </div>\n            <!-- cancel/confirm row -->\n            <div class="row" style="text-align: center;">\n                <div class=" ">\n                    <button class="mdi-navigation-close popupBtn-cancel btn btn-default btn-flat cancel-b js-cancel-popup">\n                    </button>\n                    <button class="mdi-navigation-check popupBtn-confirm btn disabled-grey js-confirm">\n                    </button>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

