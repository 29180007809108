
define('template!runPcrKitsLotsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n\t<div class="saving-label"></div>\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="row col-xs-12 p-l-30">\n\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0 p-b-1" style="height: 21px;">\n\t\t\t\t\t<div class="col-xs-2 p-l-5 p-r-0 noWrapHidden">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('reagent.assay.name') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-2 p-l-5 p-r-0 noWrapHidden">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('pcrReagents.type') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-2 p-l-5 p-r-0 noWrapHidden">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('common.refKit') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-2 p-l-5 p-r-0 noWrapHidden">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('kitLot.lotId') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-1 p-l-5 p-r-0 noWrapHidden">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('needed.wells.number') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-1 p-l-5 p-r-0 noWrapHidden">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('assay.volume.test') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-1 p-l-5 p-r-0 noWrapHidden">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('assay.volume.death') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-1 p-l-5 p-r-0 noWrapHidden">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t' +
((__t = ( _.i18n('assay.volume.tot') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t';
 if(kitLots.length > 0) { ;
__p += '\n\t\t\t\t';
 _.each(kitLots, function(kitLot) { ;
__p += '\n\t\t\t\t';
 _.each(kitLot.assayReagents, function(assayReagent) { ;
__p += '\n\t\t\t\t';
 _.each(assayReagent.pcrKitLots, function(pcrKitLot) { ;
__p += '\n\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0" style="height: 23px;">\n\t\t\t\t\t<div class="col-xs-2"\n\t\t\t\t\t\t style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;height: inherit;">\n\t\t\t\t\t\t' +
((__t = ( kitLot.refAssay.code )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-2"\n\t\t\t\t\t\t style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; }height: inherit;">\n\t\t\t\t\t\t' +
((__t = ( assayReagent.reagent.code )) == null ? '' : __t) +
' (' +
((__t = ( assayReagent.reagent.type )) == null ? '' : __t) +
')\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-2"\n\t\t\t\t\t\t style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n\t\t\t\t\t\t' +
((__t = ( assayReagent.refKit.code )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-2"\n\t\t\t\t\t\t style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n\t\t\t\t\t\t' +
((__t = ( pcrKitLot.refKitLot && pcrKitLot.refKitLot.code ? pcrKitLot.refKitLot.code : '' )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-1"\n\t\t\t\t\t\t style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n\t\t\t\t\t\t' +
((__t = ( pcrKitLot.count + ' (' + pcrKitLot.countInPrepWell + ')' )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-1"\n\t\t\t\t\t\t style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n\t\t\t\t\t\t' +
((__t = ( pcrKitLot.volumeByWell )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-1"\n\t\t\t\t\t\t style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n\t\t\t\t\t\t' +
((__t = ( pcrKitLot.volumeDeath )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-1"\n\t\t\t\t\t\t style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n\t\t\t\t\t\t' +
((__t = ( (pcrKitLot.count - pcrKitLot.countInPrepWell) != 0 ? ((pcrKitLot.count -
						pcrKitLot.countInPrepWell) * pcrKitLot.volumeByWell) + pcrKitLot.volumeDeath : 0 )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t';
 } else { ;
__p += '\n\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0">\n\t\t\t\t\t<div class="col-xs-12 noWrapHidden" style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px;"\n\t\t\t\t\t\t title="">\n\t\t\t\t\t\t' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t';
 } ;
__p += '\n\t\t\t</div>\n\t\t\t<div class="row cancelConfirmRow" style="text-align: center;">\n\t\t\t\t<div class="pull-right p-r-15 col-xs-12 p-l-0 p-r-0 m-t-20">\n\t\t\t\t\t<label style="padding-right: 20px; font-size: 17px;">' +
((__t = ( _.i18n('print.pcr.setup.sheet') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n\t\t\t\t\t<button class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"\n\t\t\t\t\t\t\tdisabled></button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

