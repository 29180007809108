define('prepRunsMain',[
    'module',
    'logger',
    'prepRunsMainRouter'
], function (
    module,
    Logger
) {
    'use strict';

    module.exports = {
        start: function () {
            Logger.log(module, 'starting PrepRunsModule');
        },

        stop: function () {
            Logger.log(module, 'stopping PrepRunsModule');
        }
    };
});
