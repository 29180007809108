define('suppliersCreateEditLayoutView',[
	'module',
	'dialogFormView',
	'template!suppliersCreateEditLayoutTpl',
	'savingBehavior',
	'bootbox',
	'underscore',
	'jquery',
	'settings',
	'dynamicCreateEditDescriptionCommentTags'
], function (
	module,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	bootbox,
	_,
	$,
	Settings,
	DynamicCreateEditDescriptionCommentTags
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			cancel: '.js-cancel-popup',
			confirm: '.js-confirm'
		},

		events: {
			'click .js-cancel-popup': 'onCancel',
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-confirm': 'onConfirm'
		},

		regions: {
			descriptionRegion: '.js-description'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		fieldsToValidate: [
			{name: 'code', type: 'required'}
		],

		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.newItem = this.model.isNew();
			return templateData;
		},

		onRender: function () {
			$.material.init();
			this.setPermissions(Settings.get('currentUserModel').hasType('A02'));

			this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionCommentTags({
				model: this.model
			}));
		},

		onConfirm: function () {
			if (this.validate(this.fieldsToValidate)) {
				this.model.save();
			}
		},

		onSave: function (model) {
			this.triggerMethod('saved', model);
			this.box.modal('hide');
		},

		onCancel: function () {
			this.model.fetch().done(_.bind(function () {
				this.box.modal('hide');
			}, this));
		},

		onSaveError: function (model, response) {
			if (response.status === 409) {
				this.onDuplicateCode();
				this.triggerMethod('hide:label');
			}
		},

		onDuplicateCode: function () {
			this.ui.endUserIdInput.popover('show');
			this.ui.endUserIdInput.addClass('invalid');
			this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
		}
	});
});
