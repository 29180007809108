define('entities/caccounts/mbanas',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/userCodelist',
    'entities/caccounts/assay',
    'entities/caccounts/assaygroup',
    'entities/caccounts/lisana'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    UserCodeLists
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.MbAna = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/mbanas/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'comment': '',
            'tags': [],
            'refMbAnaResGr': null,
            'lisCode': '',
            'lisText': '',
            'lisVariant': {type: 'NO', param: ''},
            'criterias': [],
            'assays': [],
            'assayGroups': [],
            'lisanas': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refMbAnaResGr',
            relatedModel: 'MbAnaResGr',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'assayGroups',
            relatedModel: 'AssayGroup',
            collectionType: 'AssayGroupCollection',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'lisanas',
            relatedModel: 'LisAna',
            collectionType: 'LisAnaCollection',
            includeInJSON: ['secId', 'code', 'name']
        }],
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/mbanas');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'MbAnaJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'MbAna',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.MbAnaCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/mbanas');
        },
        model: app.ns.MbAna
    });

    module.exports = _.defaults({
        getName: function () {
            return 'mbana';
        },
        getPrefixRequestHandler: function () {
            return 'mbana';
        },
        getUrl: function () {
            return 'v2/mbanas';
        },

        getDynamicJson: function () {
            return 'MbAnaJson';
        },

        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'mbana-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'mbana-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 15},
                width: 100
            }, {
                label: _.i18n('mbana.refMbAnaResGr'),
                name: 'refMbAnaResGr',
                classes: 'mbana-link',
                formatter: viewDynamic.mbAnaResGrFormatter,
                search: true,
                index: 'refMbAnaResGr.code',
                width: 50
            }, {
                label: _.i18n('mbana.lisCode'),
                name: 'lisCode',
                classes: 'mbana-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'lisCode',
                width: 50
            }, {
                label: _.i18n('mbana.lisVariantAlgo'),
                name: 'lisVariant',
                classes: 'mbana-link',
                formatter: viewDynamic.typeParamFormatter,
                width: 50,
                search: false
            }, {
                label: _.i18n('common.assayGroup'),
                name: 'assayGroups',
                formatter: viewDynamic.codeNameListFormatter,
                search: false,
                index: 'assayGroupCode',
                width: 100
            }, {
                label: _.i18n('common.assay'),
                name: 'assays',
                formatter: viewDynamic.codeNameListFormatter,
                search: false,
                index: 'assayCode',
                width: 100
            }, {
                label: _.i18n('common.lisana'),
                name: 'lisanas',
                formatter: viewDynamic.lisAnaFormatter,
                search: false,
                index: 'lisanaCode',
                width: 40
            }, {
                label: _.i18n('common.criterias'),
                name: 'criterias',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'criterias',
                width: 40
            }, {
                label: _.i18n('common.tags'),
                name: 'tags',
                formatter: viewDynamic.defaultListFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.MbAna.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.MbAnaCollection(modelJson);
        },

        getFieldsToDisplay: function () {
            return {'fieldsWidth': 300, 'fields': ['name']};
        },

        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push({field: 'refMbAnaResGr', param: {display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({
                field: 'lisVariant',
                param: {type: 'TYPEPARAM', codeListCode: 'ALGO-LISVAR'}
            });
            custom.push({
                'field': 'assays',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });
            custom.push({
                'field': 'lisCode',
                'param': {
                    'type': 'STRING',
                    'display': {
                        'class': 'col-xs-2 p-r-0'
                    }
                }
            });
            custom.push({
                'field': 'assayGroups',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });
            custom.push({
                'field': 'lisanas',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });
            return custom;
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration();
            configuration.done(function (resp) {
                resp.push({
                    fieldName: 'criterias',
                    values: UserCodeLists.findItemsByCode('FCRIT_TARGET'),
                    codeListCode: 'FCRIT_TARGET'
                });
                defer.resolve(resp);
            });
            return defer.promise();
        }
    }, Dynamic);
});
