define('runResultsTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'wellResultView',
    'wellDisplayGraphView',
    'entities/caccounts/pcrwells'
], function (
    module,
    App,
    _,
    Backbone,
    WellResultView,
    DisplayGraphLayoutView
) {
    'use strict';

    var DetailsTabController = {

        onShowWellTable: function (region, runModel, row, column, filters, sortOn) {
            this.model = runModel;
            this.row = row;
            this.column = column;
            this.filters = filters;
            this.region = region;
            this.sortOn = sortOn;
            if (!this.sortOn) {
                this.sortOn = 'row';
            }

            if (this.filters && this.filters.targetSecId) {
                this.channelToDisplay = this.model.getChannelFromTarget(this.filters.targetSecId);
                this.runAt = this.model.getRunAtFromTarget(this.filters.targetSecId);
            } else {
                this.channelToDisplay = null;
                this.runAt = null;
            }

            this.view = new WellResultView({
                tabName: this.tabName
            });

            this.view.model = this.model;
            this.view.channelToDisplay = this.channelToDisplay;
            this.view.sortOn = this.sortOn;

            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.view.listenTo(this.view, 'well-table:sort-row', _.bind(this.onSortWellRow, this));
            this.view.listenTo(this.view, 'well-table:sort-column', _.bind(this.onSortWellColumn, this));
            this.view.listenTo(this.view, 'well-result:opengraphpopup', _.bind(this.onShowGraph, this));

            // refresh the well_table_view (details) after a wellEditPopup action because de wellView use a local gridData
            this.view.listenTo(App, 'well:refresh:jqGrid', _.bind(this.onGridLoaded, this));
            region.show(this.view);
        },

        onGridLoaded: function () {
            var results = [];
            this.model.get('wells').each(_.bind(function (well) {

                var targetResultIcons = well.getTargetsResultIcons();
                var wellJson = {
                    secId: well.get('secId'),
                    pos: well.get('pos'),
                    smpId: well.get('smpId') ? {
                        code: well.get('smpId').get('code'),
                        name: well.get('smpId').get('name'),
                        secId: well.get('smpId').get('secId')
                    } : {code: '', name: '', secId: undefined},
                    refAssay: well.get('refAssay') ? {
                        code: well.get('refAssay').get('code'),
                        name: well.get('refAssay').get('name'),
                        secId: well.get('refAssay').get('secId')
                    } : {code: '', name: '', secId: undefined}
                };
                well.get('results').each(_.bind(function (target) {
                    var result = _.clone(wellJson);
                    delete result.refPcrRun;
                    delete result.wellsPooled;
                    delete result.wellsPool;
                    delete result.targets;
                    result.secId += '_' + target.get('secId');
                    result.target = {
                        mbAna: target.get('mbAna') ? {code: target.get('mbAna').get('code')} : {code: ''},
                        valSt: target.get('valSt'),
                        codeErr: target.get('codeErr'),
                        comment: target.get('comment'),
                        overAllResult: target.get('refAssayResult').get('overAllResult')
                    };
                    delete result.target.refPcrWell;

                    _.each(targetResultIcons, _.bind(function (targetResultIcon) {
                        if (target.get('mbAna').get('code') === targetResultIcon.targetCode) {
                            result.result = targetResultIcon.resCode;
                            result.cts = targetResultIcon.cts;
                            result.quantifications = targetResultIcon.quantifications ? parseInt(targetResultIcon.quantifications, 10) : null;
                            result.iconHtml = targetResultIcon.iconHtml;
                            result.targetResultIcon = targetResultIcon;
                        }
                    }, this));

                    results.push(result);
                }, this));
            }, this));

            var data = {
                data: results,
                rowCount: 9999
            };
            setTimeout(_.bind(function () {
                this.view.gridOptionsObj = this.view.gridOptions(data);
                this.view.onRowCountDone(data, 9999);
                this.view.triggerMethod('checkRowCount', 9999);
            }, this), 500);
        },

        onSortWellRow: function () {
            this.sortOn = 'row';
            this.onShowWellTable(this.region, this.model, this.row, this.column, this.filters);
        },

        onSortWellColumn: function () {
            this.sortOn = 'column';
            this.onShowWellTable(this.region, this.model, this.row, this.column, this.filters);
        },

        onShowGraph: function (rowObject) {
            if (rowObject.refAssay) {
                var well = this.model.get('wells').findWhere({secId: rowObject.wellSecId});

                this.displayGraphView = new DisplayGraphLayoutView({
                    models: [well],
                    newItem: false,
                    model: this.model
                });

                this.displayGraphView.channelToDisplay = rowObject.refKitP && rowObject.refKitP.channelNb ? rowObject.refKitP.channelNb : null;
                // this.displayGraphView.channelToDisplay = rowObject.refKitP ? this.model.getChannelFromTarget(rowObject.refKitP.secId) : null;
                var graphToDisplay = _.uniq(_.flatten(this.model.get('wells').map(function (well) {
                    return well.getGraphToDisplay();
                })));
                if (Array.isArray(graphToDisplay)) {
                    graphToDisplay = graphToDisplay[0];
                }
                this.displayGraphView.graphToDisplay = graphToDisplay;
                this.displayGraphView.runAt = rowObject.refKitP ? this.model.getRunAtFromTarget(rowObject.refKitP.secId) : null;
                this.displayGraphView.show({
                    title: _.i18n('well.graph')
                });
            }
        }
    };

    module.exports = DetailsTabController;
});
