
define('template!dynamicCreateEditDateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<input type="datepicker"\n\t   class="form-control js-datePicker inputBackground"\n\t   data-field-name="' +
((__t = ( field )) == null ? '' : __t) +
'"\n\t   style="width: auto;"\n\t   placeholder="" value="' +
((__t = ( value )) == null ? '' : __t) +
'">';

}
return __p
};});

