define('assayCustomJqGridView',[
    'module',
    'dialogFormView',
    'underscore',
    'jquery',
    'template!assayCustomJqGridView',
    'assayImportFromResultView',
    'entities/caccounts/setting'
], function (
    module,
    DialogFormView,
    _,
    $,
    tpl,
    AssayImportFromResultView,
    SettingEntity
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        events: {
            'click .js-importFromResult': 'onImportFromResult'
        },

        attributes: {
            style: 'display: flex; column-gap: 5px;'
        },

        onImportFromResult: function () {
            SettingEntity.findByCode('IMPORT-RESULT').done(_.bind(function (setting) {
                var view = new AssayImportFromResultView({
                    settingImportResult: setting,
                    assay: this.options.assay
                });
                view.show({
                    title: _.i18n('assay.createFromRunResult'),
                    className: ''
                });
            }, this));
        }
    });
});
