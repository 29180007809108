define('entities/caccounts/suppliers',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Supplier = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/suppliers/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            comment: null,
            tags: [],
            'zipCode': '',
            'city': '',
            'countryCode': ''
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/suppliers');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('SupplierJson.pdf');
        },
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.SupplierCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/suppliers');
        },
        model: app.ns.Supplier
    });

    var API = {

        getFieldsToDisplay: function () {
            return {'fieldsWidth': 550, 'fields': ['name']};
        }
    };

    App.reqres.setHandler('supplier:get-fields-to-display', function () {
        return API.getFieldsToDisplay();
    });

    module.exports = _.defaults({
        getName: function () {
            return 'supplier';
        },
        getUrl: function () {
            return 'v2/suppliers';
        },
        getDynamicJson: function () {
            return 'SupplierJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.city'),
                name: 'city',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'city',
                width: 40
            }, {
                label: _.i18n('common.zipCode'),
                name: 'zipCode',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'zipCode',
                width: 40
            }, {
                label: _.i18n('common.countryCode'),
                name: 'countryCode',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'countryCode',
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Supplier.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 550, 'fields': ['name']};
        }
    }, Dynamic);
});
