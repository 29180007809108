
define('template!dashboardPrepThumbnailTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="well jq-grid-table-page col-xs-12" style="height: 700px;">\n\n        <div class="row baseTableTitleContainer" style="width: inherit;">\n            <div class="col-xs-12">\n                <div>' +
((__t = ( _.i18n('menu.prep.thumbnail.view') )) == null ? '' : __t) +
'</div>\n            </div>\n\n            <div class="col-xs-12 p-l-0 p-r-0 js-prep-thumbnail-filter-container"\n                 style="display: flex; justify-content: center; height: 120px;">\n\n                <!-- Filter dropDowns -------------------------------------------->\n                <div class="headerGroupCellContainer" style="width: 115px;">\n                    <div class="groupCellTitle">' +
((__t = ( _.i18n('department') )) == null ? '' : __t) +
'</div>\n                    <div style="display: flex;">\n                        <div class="headerFilterDropDown js-department-region dropdown-custom-width-css js-select-department"\n                             name="select-department"\n                             style="width: 100px;"></div>\n                    </div>\n                </div>\n                <div class="headerGroupCellContainer" style="width: 115px;">\n                    <div class="groupCellTitle">' +
((__t = ( _.i18n('run.group') )) == null ? '' : __t) +
'</div>\n                    <div style="display: flex;">\n                        <div class="headerFilterDropDown js-runGroup-region dropdown-custom-width-css js-select-runGroup"\n                             name="select-runGroup"\n                             style="width: 100px;"></div>\n                    </div>\n                </div>\n\n                <!-- Filter buttons -------------------------------------------->\n                <!-- status filter -->\n                <div class="headerGroupCellContainer">\n                    <div class="groupCellTitle">filter</div>\n                    <div style="display: flex;">\n                        <!-- waiting for plate layout filling-->\n                        <div class="headerFilterButton js-select-waitingFor-plateLayoutFilling js-tab-filters clickable hidden-print js-select-filter"\n                             name="select-waitingFor-plateLayoutFilling"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('waitingFor.platelayout.filling') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-playlist-check mdiHeaderIconButton"\n                                 style="position: relative;  bottom: 2px; left: 4px; font-size: 24px;"></span>\n                            <input type="checkbox" class="js-filter-checkBox">\n                        </div>\n\n                        <!-- waiting for extraction setup-->\n                        <div class="headerFilterButton js-select-waitingFor-extractionSetup js-tab-filters clickable hidden-print js-select-filter"\n                             name="select-waitingFor-extractionSetup"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('waitingFor.extraction.setup') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-clipboard-arrow-right-outline mdiHeaderIconButton"\n                                 style="position: relative;  bottom: 2px; left: 4px; font-size: 24px;"></span>\n                            <input type="checkbox" class="js-filter-checkBox">\n                        </div>\n\n                        <!-- extraction running -->\n                        <div class="headerFilterButton js-select-extraction-running js-tab-filters clickable hidden-print js-select-filter"\n                             name="select-extraction-running"\n                             style="margin: 2px;"\n                             title="' +
((__t = ( _.i18n('extraction.running') )) == null ? '' : __t) +
'">\n                            <span class="mdi mdi-step-forward mdiHeaderIconButton"\n                                 style="position: relative;  bottom: 2px; left: 4px; font-size: 24px;"></span>\n                            <input type="checkbox" class="js-filter-checkBox">\n                        </div>\n                    </div>\n                </div>\n                <!-- reset filters -->\n                <div class="clickable hidden-print mdi mdi-filter-remove js-reset-filters"\n                     style=""\n                     title="' +
((__t = ( _.i18n('reset.filters') )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n        <div class="prep-runs-cards"\n             style="width: inherit; height: 520px; overflow-y: scroll; padding: 0; margin: 0;"></div>\n    </div>\n</div>\n';

}
return __p
};});

