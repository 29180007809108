define('entities/caccounts/orders',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/species',
    'entities/caccounts/samples'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.Order = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/orders/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'creatDate': undefined,
            'expirationDate': undefined,
            'specie': null,
            'samples': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'specie',
            relatedModel: 'Specie'
        }, {
            type: Backbone.HasMany,
            key: 'samples',
            relatedModel: 'Sample',
            collectionType: 'SampleCollection'
        }],

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/orders');
        },
        save: function () {
            this.url = this.postUrl();

            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('OrderJson.pdf');
        },
        getEntity: function () {
            return module.exports;
        }
    });

    app.ns.OrderCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/orders');
        },
        model: app.ns.Order
    });

    module.exports = _.defaults({
        getName: function () {
            return 'order';
        },
        getUrl: function () {
            return 'v2/orders';
        },
        getDynamicJson: function () {
            return 'OrderJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig();
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.creatable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 80
            }, {
                label: _.i18n('order.specie'),
                name: 'specie',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'specieCode',
                width: 40
            }, {
                label: _.i18n('order.samples'),
                name: 'samples',
                formatter: viewDynamic.codeNameListFormatter,
                search: true,
                index: 'sample'
            }, {
                label: _.i18n('order.created'),
                name: 'creatDate',
                formatter: _.bind(viewDynamic.dateTimeFormatterFromSettings, this), // this formatter need DATEFMT from settings
                search: true,
                index: 'creatDate',
                sorttype: 'date',
                formatoptions: {newformat: 'dd/mm/yy'},
                searchrules: {date: true},
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                },
                width: 90
            }, {
                label: _.i18n('order.expirationDate'),
                name: 'expirationDate',
                formatter: _.bind(viewDynamic.dateTimeFormatterFromSettings, this), // this formatter need DATEFMT from settings
                search: true,
                index: 'expirationDate',
                sorttype: 'date',
                sortable: false,
                searchrules: {date: true},
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                },
                width: 90
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'creatDate';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.Order.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        }
    }, Dynamic);
});
