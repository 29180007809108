define('cssUtils',[
    'module'
], function (
    module
) {
    'use strict';

    module.exports = {
        getPropertyValue: function (property) {
            var body = document.querySelector('body');
            return getComputedStyle(body).getPropertyValue(property);
        },

        setPropertyValue: function (property, value) {
            var body = document.querySelector('body');
            body.style.setProperty(property, value);
        },

        applyPrimarySampleTheme: function () {
            var currentTheme = this.getPropertyValue('--css--currentTheme');
            if (currentTheme !== 'sampleRouting') {
                this.setTheme('sampleRouting',
                    '#58a9ac', '#66BDBB', '#9AF9D1', '#66BDBB',
                    '#30394B', '#66BDBB', '#30394B', '#ffffff',
                    '#DEFF8580', '#4FE3D080');
            }
        },

        applyDefaultTheme: function () {
            var currentTheme = this.getPropertyValue('--css--currentTheme');
            if (currentTheme !== 'default') {
                this.setTheme('default',
                    '#58a9ac', '#6ba950', '#cfe2f3', '#4f8de3',
                    '#789bc8', '#ffd967', '#c0c0c0', '#ffffff',
                    '#007d82', '#a1a1a1');
            }
        },
        applyPrepRunTheme: function () {
            var currentTheme = this.getPropertyValue('--css--currentTheme');
            if (currentTheme !== 'prepRun') {
                this.setTheme('prepRun',
                    '#58a9ac', '#FD5D00', '#FFD8C2', '#FD5D00',
                    '#2C363F', '#FD5D00', '#2C363F', '#ffffff',
                    '#FFF2EB80', '#FF664780');
            }
        },
        applyPcrRunTheme: function () {
            var currentTheme = this.getPropertyValue('--css--currentTheme');
            if (currentTheme !== 'pcrRun') {
                this.setTheme('pcrRun',
                    '#58a9ac', '#017394', '#81c2cb', '#19b5de',
                    '#2C363F', '#28c2d7', '#2C363F', '#ffffff',
                    'rgba(130,245,245,0.5)', 'rgba(13,74,112,0.5)');
            }
        },

        /**
         * @param {String} name = "Theme name"
         * @param {String} bgColor = "#faebd7 backGround color"
         * @param {String} bgColorHover = "#fd5d00 backGround color at hover"
         * @param {String} btnBgColor = "#faebd7 button backGround color at hover"
         * @param {String} btnBgColorHover = "#fd5d00 button backGround color at hover"
         * @param {String} btnIconColor = "#333333 button icon color at hover"
         * @param {String} btnIconColorHover = "#fd5d00 button icon color at hover"
         * @param {String} txtColor = "#333333 text color at hover"
         * @param {String} txtColorHover = "#ffffff text color at hover"
         * @param {String} bgImageFilterColor01 = "#85FFBD80 background-image filter color 01"
         * @param {String} bgImageFilterColor02 = "#FFFB7D80 background-image filter color 02"
         */
        setTheme: function (name,
                            bgColor, bgColorHover,
                            btnBgColor, btnBgColorHover,
                            btnIconColor, btnIconColorHover,
                            txtColor, txtColorHover,
                            bgImageFilterColor01, bgImageFilterColor02) {
            this.setPropertyValue('--css--currentTheme', name);
            this.setPropertyValue('--css-theme-background-color', bgColor);
            this.setPropertyValue('--css-theme-background-color-hover', bgColorHover);
            this.setPropertyValue('--css-theme-btn-background-color', btnBgColor);
            this.setPropertyValue('--css-theme-btn-background-color-hover', btnBgColorHover);
            this.setPropertyValue('--css-theme-btn-icon-color', btnIconColor);
            this.setPropertyValue('--css-theme-btn-icon-color-hover', btnIconColorHover);
            this.setPropertyValue('--css-theme-txt-color', txtColor);
            this.setPropertyValue('--css-theme-txt-color-hover', txtColorHover);
            this.setPropertyValue('--css-theme-image-filter-color-01', bgImageFilterColor01);
            this.setPropertyValue('--css-theme-image-filter-color-02', bgImageFilterColor02);
        }
    };
});

