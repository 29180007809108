
define('template!exportResultStatTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="font16">\n\t';
 if(success) { ;
__p += '\n\t<div class="row m-l-0 m-r-0" style="border: solid 1px black; border-radius: 5px;">\n\t\t<div class="col-xs-12 p-l-0 p-r-0">\n\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t<div class="col-xs-1 p-l-5 p-r-0"></div>\n\t\t\t\t<div class="col-xs-7 p-l-0 p-r-0">' +
((__t = ( _.i18n('export.assay') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0">' +
((__t = ( _.i18n('export.QC') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0">' +
((__t = ( _.i18n('export.numberOfWells') )) == null ? '' : __t) +
'</div>\n\t\t\t</div>\n\t\t\t<div class="col-xs-8 p-l-0 p-r-0">\n\t\t\t\t<div class="col-xs-4 p-l-5 p-r-0 leftBorder rightBorder redCell">' +
((__t = ( _.i18n('export.notReady') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-4 p-l-5 p-r-0 rightBorder greenCell">' +
((__t = ( _.i18n('export.toExport') )) == null ? '' : __t) +
'</div>\n\t\t\t\t<div class="col-xs-4 p-l-5 p-r-0 blueCell">' +
((__t = ( _.i18n('export.exported') )) == null ? '' : __t) +
'</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="col-xs-12 p-l-0 p-r-0" style="height: 21px;">\n\t\t\t<div class="col-xs-4 p-l-5 p-r-0"></div>\n\t\t\t<div class="col-xs-8 p-l-0 p-r-0">\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 p-t-1 leftBorder rightBorder topBorder redCell" style="display: flex;">\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('res') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('resTest') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('qc') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 centeredText">' +
((__t = ( _.i18n('rep') )) == null ? '' : __t) +
'</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 rightBorder topBorder greenCell" style="display: flex;">\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('res') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('resTest') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('qc') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 centeredText">' +
((__t = ( _.i18n('rep') )) == null ? '' : __t) +
'</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 topBorder blueCell" style="display: flex;">\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('res') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('resTest') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('qc') )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 centeredText">' +
((__t = ( _.i18n('rep') )) == null ? '' : __t) +
'</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t\t';
 _.each(exportResultStats, function(exportResultStat) { ;
__p += '\n\t\t<label class="checkBoxLabel" for="line-' +
((__t = ( exportResultStat.line.secId )) == null ? '' : __t) +
'">\n\t\t\t<!--checkBoxLabel is use for extend checkbox click range-->\n\t\t\t<div class="col-xs-12 p-l-0 p-r-0 topBorder ' +
((__t = ( !exportResultStat.validated ? 'redCell': '' )) == null ? '' : __t) +
'"\n\t\t\t\t style="height: 25px;">\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0">\n\t\t\t\t\t<div class="col-xs-1 p-l-5 p-r-0 p-t-1">\n\t\t\t\t\t\t<input class="assayRow" data-lineSecId="' +
((__t = ( exportResultStat.line.secId )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   id="line-' +
((__t = ( exportResultStat.line.secId )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t   type="checkbox"\n\t\t\t\t\t\t' +
((__t = ( exportResultStat.validated ? "checked" : "hidden" )) == null ? '' : __t) +
'/>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-7 p-l-0 p-r-0 p-t-1" style="font-size: 16px;">' +
((__t = (
						exportResultStat.line.assay.code )) == null ? '' : __t) +
'\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0 p-t-1">';
 if(exportResultStat.validated){ ;
__p += '\n\t\t\t\t\t\t<span class="mdi mdi-check exportStatQc qcValidated"></span>\n\t\t\t\t\t\t';
 } else { ;
__p += '\n\t\t\t\t\t\t<span class="mdi mdi-close exportStatQc qcNotValidated"></span>\n\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0 p-t-1">' +
((__t = ( exportResultStat.total )) == null ? '' : __t) +
'</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-8 p-l-0 p-r-0">\n\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 p-t-1 leftBorder rightBorder" style="display: flex;">\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat ' +
((__t = ( (exportResultStat.notReady.result.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.notReady.result.length === 0 ? '-' :
								exportResultStat.notReady.result.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat ' +
((__t = ( (exportResultStat.notReady.resultTest.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.notReady.resultTest.length === 0 ? '-' :
								exportResultStat.notReady.resultTest.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.notReady.qualityControl.length> 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.notReady.qualityControl.length === 0 ? '-' :
								exportResultStat.notReady.qualityControl.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.notReady.repeat.length> 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.notReady.repeat.length === 0 ? '-' :
								exportResultStat.notReady.repeat.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 p-t-1 rightBorder ' +
((__t = ( exportResultStat.readyToExport ? 'greenCell': '' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t style="display: flex;">\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.toExport.result.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.toExport.result.length === 0 ? '-' :
								exportResultStat.toExport.result.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.toExport.resultTest.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.toExport.resultTest.length === 0 ? '-' :
								exportResultStat.toExport.resultTest.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.toExport.qualityControl.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.toExport.qualityControl.length === 0 ? '-' :
								exportResultStat.toExport.qualityControl.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.toExport.repeat.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.toExport.repeat.length === 0 ? '-' :
								exportResultStat.toExport.repeat.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 p-t-1 ' +
((__t = ( exportResultStat.readyToReExport ? 'blueCell': '' )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t style="display: flex;">\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.reExport.result.length === 0 ? '-' :
								exportResultStat.reExport.result.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.reExport.resultTest.length === 0 ? '-' :
								exportResultStat.reExport.resultTest.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.reExport.qualityControl.length === 0 ? '-' :
								exportResultStat.reExport.qualityControl.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-3 p-l-0 p-r-0 centeredText">\n\t\t\t\t\t\t\t<div class="nbStat resultStat">\n\t\t\t\t\t\t\t\t' +
((__t = ( exportResultStat.reExport.repeat.length === 0 ? '-' :
								exportResultStat.reExport.qualityControl.length )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</label>\n\n\t\t';
 }) ;
__p += '\n\t</div>\n\t<div class="row js-lis"></div>\n\t<div class="row m-t-10">\n\t\t<div class="col-xs-12">\n\t\t\t<div class="col-xs-12 p-l-0 p-r-0">\n\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 "></div>\n\t\t\t\t<div class="col-xs-8 p-l-0 p-r-0">\n\t\t\t\t\t<div class="col-xs-4 p-l-0 p-r-0 "></div>\n\t\t\t\t\t<div class="col-xs-4 p-l-5 p-r-5 p-t-0 p-b-0 button ">\n\t\t\t\t\t\t<button class="btn greenCell js-export btn-block invisible"\n\t\t\t\t\t\t\t\tstyle="padding: 5px 5px 5px 5px;"\n\t\t\t\t\t\t' +
((__t = ( enabledExport ? "" : "disabled" )) == null ? '' : __t) +
'>\n\t\t\t\t\t\t' +
((__t = ( _.i18n("export") )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-4 p-l-5 p-r-5 p-t-0 p-b-0 button ">\n\t\t\t\t\t\t<button class="btn blueCell js-reexport btn-block invisible"\n\t\t\t\t\t\t\t\tstyle="padding: 5px 5px 5px 5px;"\n\t\t\t\t\t\t' +
((__t = ( enabledReExport ? "" : "disabled" )) == null ? '' : __t) +
'>\n\t\t\t\t\t\t' +
((__t = ( _.i18n("re.export") )) == null ? '' : __t) +
'\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>\n';
 } else { ;
__p += '\nError : ' +
((__t = ( error )) == null ? '' : __t) +
'\n';
 } ;
__p += '\n</div>';

}
return __p
};});

