define('miniWellView',[
    'module',
    'marionette',
    'colorUtils',
    'underscore',
    'jquery',
    'template!miniWellTpl',
    'wellResultIconView',
    'wellUtils'
], function (
    module,
    Marionette,
    ColorUtils,
    _,
    $,
    miniWellTpl,
    WellResultIconView,
    WellUtils
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: miniWellTpl,

        regions: {
            resultIcon: '.result-icon'
        },

        events: {
            'dblclick': 'onItemDblClick',
            'click': 'onSelectClick'
        },

        modelEvents: {
            'change': 'render'
        },

        onSelectClick: function () {
            if (event.detail === 1) {
                this.timer = setTimeout(_.bind(function () {
                    this.trigger('miniWell:filter', this.model, !this.options.selected);
                }, this), 300);
            }
        },

        onItemDblClick: function () {
            clearTimeout(this.timer);
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails(this.model.get('refPcrWell'), true, this.icRefKitsToDiplay);
            }, this));
            this.trigger('well:filteredDetailItems', this.model);
        },

        onRender: function () {
            this.iconResultView = new WellResultIconView({
                model: this.model
            });
            this.getRegion('resultIcon').show(this.iconResultView);
            var params = {
                context: this,
                model: this.model.get('refPcrWell'),
                placement: 'right'
            };

            params.target = this.options.region;
            WellUtils.showPcrWellPopover(params);
        },

        serializeData: function () {
            var templateData = {
                existOtherWellSameAssay: this.model.get('refPcrWell').get('existOtherWellSameAssay'),
                existOtherWellOtherAssay: this.model.get('refPcrWell').get('existOtherWellOtherAssay')
            };

            templateData.classSelected = this.options.selected ? 'mini-well-selected' : 'mini-well-unselected';

            templateData.colorOtherWellSameAssay = '#FF0000';
            templateData.colorOtherWellOtherAssay = '#FFFFFF';
            if (this.model.get('refPcrWell').get('refPcrRun').get('settings')['OTHER-ASSAY-IND']) {
                var otherAssayInd = this.model.get('refPcrWell').get('refPcrRun').get('settings')['OTHER-ASSAY-IND'].split(';');
                if (otherAssayInd[0]) {
                    templateData.colorOtherWellSameAssay = otherAssayInd[0];
                }
                if (otherAssayInd[1]) {
                    templateData.colorOtherWellOtherAssay = otherAssayInd[1];
                }
            }

            var wellRes = _.first(this.model.get('refPcrWell').getDisplayGroup());
            if (wellRes && wellRes.get('result')) {
                templateData.wellResColor = wellRes.get('result').get('color');
            }
            if (!templateData.wellResColor) {
                templateData.wellResColor = null;
            }
            return templateData;
        }
    });
});
