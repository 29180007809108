define('runCreatePcrRunFromFileSampleListView',[
	'module',
	'marionette',
	'backbone',
	'dialogFormView',
	'template!runCreatePcrRunFromFileSampleListView',
	'savingBehavior',
	'bootbox',
	'underscore',
	'jquery',
	'app',
	'settings',
	'autocompleteView',
	'runRemoteFolderView',
	'entities/caccounts/fileFormatSampleLists',
	'entities/caccounts/setting',
	'fileExplorerController',
	'dynamicCreateEditCodeList',
	'entities/caccounts/pcrruns'
], function (
	module,
	Marionette,
	Backbone,
	DialogFormView,
	createEditLayoutTpl,
	SavingBehavior,
	bootbox,
	_,
	$,
	App,
	Settings,
	AutocompleteView,
	RunRemoteFolderView,
	FileFormatPlateLayouts,
	SettingEntity,
	FileExplorerController,
	CodeListView
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			importFileFromCentralLib: '.importFileFromCentralLib',
			importFileFromComputer: '.importFileFromComputer',
			selectFileFromComputer: '.js-select-file-from-computer',
			blockLoading: '.empty-page-loader'
		},

		events: {
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-cancel-popup': 'onCancel',
			'click @ui.importFileFromCentralLib': 'onSelectFileFromCentralLib',
			'change @ui.selectFileFromComputer': 'onSelectFileFromComputer',
			'click .js-blank': 'onBlankClick'
		},

		regions: {
			selectFileFormat: '.js-select-fileFormat-region',
			plateFillDirection: '.js-plate-direction-region'
		},

		fieldsToValidate: [
			{name: 'refFileFormat', type: 'required'}
		],

		initialize: function () {
			this.model = new Backbone.Model();
			this.model.set('refFileFormat', null);
			this.model.set('accept', '.xlsx,.xls,.xml,.rdml,.csv');
			this.model.set('startPos', 'A01');
			this.model.set('plateFillDirection', this.options.settings['LAB-SETTINGS']);
		},

		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.remoteAvailable = this.options.settings['IMPORT-SAMPLELIST-PCR'] && this.options.settings['IMPORT-SAMPLELIST-PCR'].split(';')[0];
			return templateData;
		},

		onShow: function () {
			$.material.init();
			this.showSelectFileFormatView();
			this.getRegion('plateFillDirection').show(new CodeListView({
				model: this.model,
				config: {
					modelProperty: 'plateFillDirection',
					code: 'PLATE_FILL_DIRECTION'
				}
			}));
		},

		showSelectFileFormatView: function () {
			if (this.selectFileFormatView) {
				this.selectFileFormatView.destroy();
			}
			this.selectFileFormatView = new AutocompleteView(
				this._getAutocompleteOptionObject(FileFormatPlateLayouts.getAutocompleteParamForImport({
					modelProperty: 'refFileFormat',
					callBackOnChange: this.onFileFormatChange
				}))
			);
			this.getRegion('selectFileFormat').show(this.selectFileFormatView);
		},

		onFileFormatChange: function (fieldName, model) {
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this.$el.find('label#importResultFromLocal').attr('for', 'run-xml-input');
			} else {
				this.$el.find('label#importResultFromLocal').attr('for', 'blank');
			}
			this.onChange(fieldName, model ? model.toJSON() : null);
			this.validate(this.fieldsToValidate);
		},

		onBlankClick: function () {
			this.validate(this.fieldsToValidate);
		},

		onSelectFileFromCentralLib: function () {
			if (this.validate(this.fieldsToValidate)) {
				SettingEntity.findByCode('IMPORT-SAMPLELIST-PCR').done(_.bind(function (settings) {
					FileExplorerController.show(settings.secId, true, _.bind(this.fileUpload, this));
				}, this));
			}
		},

		onSelectFileFromComputer: function (e) {
			if (e && e.target && e.target.files && e.target.files[0]) {
				this.fileUpload(e.target.files[0]);
			}
		},

		fileUpload: function (fileInfo) {
			this.$el.find(this.ui.blockLoading).show();
			if (fileInfo && this.validate(this.fieldsToValidate)) {
				this.ui.blockLoading.show();
				App.request('run:pcr:from:sampleList', fileInfo, this.model)
					.done(_.bind(function (result) {
						this.ui.blockLoading.hide();
						if (result && result.secId) {
							var step = result.wellCount <= 96 ? 1 : 2;
							App.navigate('runs/pcr/' + result.secId + '/A01/'+ step + '/' + result.plateSize, {trigger: true});
						} else {
							App.navigate('runs/pcr', {trigger: true});
							this.triggerMethod('run:listView:refresh');
						}
						this.hide();
					}, this));
			}
		},

		onCancel: function () {
			this.box.modal('hide');
		},

		hide: function () {
			this.triggerMethod('hide');
			this.destroy();
			this.box.modal('hide');
		}
	});
});
