
define('template!dynamicCreateEditTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<fieldset class="col-xs-12 m-t-5 DarkRoundedFieldset" id="js-assayKitProts-region">\n    <div class="col-w-all col-w-100 d-f">\n        <div class="col-w-all noWrapHidden" style="border-bottom: solid 1px #c6c6c6; width: 48px; padding-left: 5px;">\n            <span class="mdi mdi-plus-circle action clickable js-add"\n                  style="line-height: 45px; font-size: 34px;"></span>\n        </div>\n\n        <span style="background-color: transparent;\n            height: 41px;\n            width: 10px;\n            position: absolute;\n            left: 46px;\n            border-left: solid 1px #c6c6c6;\n            top: 5px;">\n        </span>\n\n        <div class="col-w-all col-w-96 noWrapHidden js-tab" style="border-radius: 0 8px 0 0;">\n        </div>\n    </div>\n\n    <div class="col-xs-12 js-current-model"></div>\n</fieldset>';

}
return __p
};});

