
define('template!runEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row detailed-info-form-container edit-run-popup" style="min-height: 150px;">\n\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n\t\t<div class="error-msg"></div>\n\t</div>\n\t<div class="form-horizontal">\n\t\t<div class="form-inputs-container">\n\t\t\t<div class="col-xs-12">\n\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t<div class="row edit-run-popup-header">\n\t\t\t\t\t\t<div class="col-w-all col-w-100 d-b p-l-15 p-r-15">\n\t\t\t\t\t\t\t<div class="col-w-all col-w-100 d-f">\n\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-29 "> <!--style="text-align: left;"-->\n\t\t\t\t\t\t\t\t\t<!--mainHeaderBoxShadow-->\n\t\t\t\t\t\t\t\t\t<!--border: solid 1px black; border-radius: 5px; background-color: inherit;-->\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0"\n\t\t\t\t\t\t\t\t\t\t style="height: 74px;">\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-12"\n\t\t\t\t\t\t\t\t\t\t\t style="justify-content: end; display: flex; margin-top: 4px;">\n\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( wellsSizePlateIcon )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-12" data-field-name="refRespUser"\n\t\t\t\t\t\t\t\t\t\t\t style="justify-content: end; display: flex; margin-top: 8px;">\n\t\t\t\t\t\t\t\t\t\t\t<div class="col-xs-7 p-l-0 p-r-0 t-a-r dataLabelContainer">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="form-group">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<label class="control-label dataLabel noWrapHidden">' +
((__t = (
														_.i18n('user') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t<div class="col-xs-5 p-r-0 p-l-0">\n\t\t\t\t\t\t\t\t\t\t\t\t<!--border: solid 1px #333;-->\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="js-select-respUser-region"\n\t\t\t\t\t\t\t\t\t\t\t\t\t style="background-color: white; position: relative;"></div>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-1"></div>\n\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-40 noWrapHidden" style="text-align: left;">\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0 mainHeaderBoxShadow"\n\t\t\t\t\t\t\t\t\t\t style="border: solid 1px black; border-radius: 5px; background-color: inherit;">\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0">\n\t\t\t\t\t\t\t\t\t\t\t<!-- runName Line --------------------------------------------------->\n\t\t\t\t\t\t\t\t\t\t\t<div class="col-xs-10 p-l-5 p-r-5 noWrapHidden pop-mainHeaderRunBox">\n\t\t\t\t\t\t\t\t\t\t\t\t<input class="noWrapHidden form-control floating-label js-info-input edit-run-popup-header-runName-input"\n\t\t\t\t\t\t\t\t\t\t\t\t\t   style="border: solid 1px #333 !important;"\n\t\t\t\t\t\t\t\t\t\t\t\t\t   type="text"\n\t\t\t\t\t\t\t\t\t\t\t\t\t   id="runName-popupField-to-clipboard"\n\t\t\t\t\t\t\t\t\t\t\t\t\t   data-field-name="name"\n\t\t\t\t\t\t\t\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t\t\t   title="' +
((__t = ( name )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t\t\t   data-content=""\n\t\t\t\t\t\t\t\t\t\t\t\t\t   data-placement="top" autocomplete="off">\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-0" style="display: flex; margin-top: 10px;">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="headerRunActionButton edit-run-popup-header-action-button"\n\t\t\t\t\t\t\t\t\t\t\t\t\t style="margin-right: 2px;">\n                                                    <span class="hidden-print js-copyParameters mdi-content-content-copy\n                                                    headerRunActionButtonIcon edit-run-popup-header-action-copy-to-clipboard"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t  data-copy="' +
((__t = ( name )) == null ? '' : __t) +
'">\n                                                    </span>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="headerRunActionButton edit-run-popup-header-action-button">\n                                                     <span class="clickable hidden-print js-edit-runName mdi-content-create headerRunActionButtonIcon\n                                                            edit-run-popup-header-action-edit-runName"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t   title="' +
((__t = ( _.i18n('edit.run.name') )) == null ? '' : __t) +
'">\n                                                    </span>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<!-- run list line --------------------------------------------------->\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0" style="height: 38px;">\n\t\t\t\t\t\t\t\t\t\t\t<div class="col-xs-10 p-l-5 p-r-5 noWrapHidden">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="edit-run-popup-header-runName-list">\n\t\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( _.pluck(prepRuns, 'code') )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t<div class="col-xs-2 p-l-0 p-r-5" style="height: 17px; margin-top: 8px;">\n\t\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( valStatusIcon )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-1"></div>\n\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-29 "> <!--style="text-align: left;"-->\n\t\t\t\t\t\t\t\t\t<!--mainHeaderBoxShadow-->\n\t\t\t\t\t\t\t\t\t<!--border: solid 1px black; border-radius: 5px; background-color: inherit;-->\n\t\t\t\t\t\t\t\t\t<div class="col-xs-12 p-l-0 p-r-0" style="height: 74px;">\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-12 t-a-l" style="margin-top: 20px;">\n\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( dpgs )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class="col-xs-12 t-a-l" style="margin-top: 2px;">\n\t\t\t\t\t\t\t\t\t\t\t' +
((__t = ( creatDateToDisplay )) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class="col-xs-12 ">\n\t\t\t\t\t<hr class="m-b-10 m-t-10">\n\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t<div class="col-w-all col-w-100 d-b">\n\t\t\t\t\t\t\t<div class="col-w-all col-w-100 d-f" style="height: 18px;">\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-15 p-l-15 p-r-15 noWrapHidden"\n\t\t\t\t\t\t\t\t\t data-field-name="refCyclerPublic"\n\t\t\t\t\t\t\t\t\t style="text-align: left;">\n\t\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.refCyclerPublic')
											)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-2"></div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-15 p-l-15 p-r-15 noWrapHidden"\n\t\t\t\t\t\t\t\t\t data-field-name="refCycler"\n\t\t\t\t\t\t\t\t\t style="text-align: left;">\n\t\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.refCycler')
											)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-2"></div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-15 p-l-15 p-r-15 noWrapHidden"\n\t\t\t\t\t\t\t\t\t data-field-name="refLmbCycler"\n\t\t\t\t\t\t\t\t\t style="text-align: left;">\n\t\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.refLmbCycler')
											)) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-2"></div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-15 p-l-15 p-r-15 noWrapHidden"\n\t\t\t\t\t\t\t\t\t data-field-name="refKitProt"\n\t\t\t\t\t\t\t\t\t style="text-align: left;">\n\t\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('kit.protocol') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-2"></div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-40 p-l-15 p-r-15 noWrapHidden"\n\t\t\t\t\t\t\t\t\t data-field-name="plateIdPC"\n\t\t\t\t\t\t\t\t\t style="text-align: left;">\n\t\t\t\t\t\t\t\t\t<div class="form-group m-b-0">\n\t\t\t\t\t\t\t\t\t\t<label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.plateIds') )) == null ? '' : __t) +
'</label>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t<div class="col-w-all col-w-100 d-b p-l-15 p-r-15">\n\t\t\t\t\t\t\t<div class="col-w-all col-w-100 d-f">\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-15"\n\t\t\t\t\t\t\t\t\t data-field-name="refCyclerPublic">\n\t\t\t\t\t\t\t\t\t';
 if(!cyclerPublicToDisplay){;
__p += '\n\t\t\t\t\t\t\t\t\t<div class="js-select-cyclerPublic-region"></div>\n\t\t\t\t\t\t\t\t\t';
 } else {;
__p += '\n\t\t\t\t\t\t\t\t\t<div style="font-size: 18px;">' +
((__t = ( cyclerPublicToDisplay )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-2"></div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-15" data-field-name="refCycler">\n\t\t\t\t\t\t\t\t\t';
 if(!cyclerToDisplay){;
__p += '\n\t\t\t\t\t\t\t\t\t<div class="js-select-cycler-region"></div>\n\t\t\t\t\t\t\t\t\t';
 } else {;
__p += '\n\t\t\t\t\t\t\t\t\t<div style="font-size: 18px;">' +
((__t = ( cyclerToDisplay )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-2"></div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-15" data-field-name="refLmbCycler">\n\t\t\t\t\t\t\t\t\t<div class="js-select-lmbcycler-region"></div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-2"></div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-15"\n\t\t\t\t\t\t\t\t\t data-field-name="refKitProt">\n\t\t\t\t\t\t\t\t\t';
 if(_.isEmpty(assays)){;
__p += '\n\t\t\t\t\t\t\t\t\t<div class="js-select-kitprot-region"></div>\n\t\t\t\t\t\t\t\t\t';
 } else {;
__p += '\n\t\t\t\t\t\t\t\t\t<div style="font-size: 18px;">' +
((__t = ( kitProtToDisplay )) == null ? '' : __t) +
'</div>\n\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-2"></div>\n\t\t\t\t\t\t\t\t<div class="col-w-all col-w-40">\n\t\t\t\t\t\t\t\t\t<input type="text" class="form-control floating-label js-info-input js-code"\n\t\t\t\t\t\t\t\t\t\t   data-field-name="plateIdPC"\n\t\t\t\t\t\t\t\t\t\t   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t   title="' +
((__t = ( plateIdPC )) == null ? '' : __t) +
'" value="' +
((__t = ( plateIdPC )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t   data-content=""\n\t\t\t\t\t\t\t\t\t\t   data-placement="top" autocomplete="off">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<hr class="m-b-15 m-t-10">\n\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-50 p-r-15">\n\t\t\t\t\t\t\t<fieldset class="roundedFieldset" style="width: 100%;">\n\t\t\t\t\t\t\t\t<legend class="leftPositionedLegend">' +
((__t = ( _.i18n('assays') )) == null ? '' : __t) +
'</legend>\n\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-100">\n\t\t\t\t\t\t\t\t\t<div style="overflow-x: hidden; margin-left: 10px; overflow-y: auto; width: inherit;">\n\t\t\t\t\t\t\t\t\t\t<div style="display: contents;">\n\t\t\t\t\t\t\t\t\t\t\t';
 _.each(assays, function (assay) { ;
__p += '\n\t\t\t\t\t\t\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-70 js-textToCopy">' +
((__t = ( assay.code
													)) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-15">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-content-copy js-copyParameters clickable"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t data-copy="' +
((__t = ( assay.code )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t style="font-size: 16px;"></span>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</fieldset>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-50 p-r-15">\n\t\t\t\t\t\t\t<fieldset class="roundedFieldset" style="width: 100%;">\n\t\t\t\t\t\t\t\t<legend class="leftPositionedLegend">' +
((__t = ( _.i18n('runs.prepruns') )) == null ? '' : __t) +
'</legend>\n\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-100">\n\t\t\t\t\t\t\t\t\t<div style="overflow-x: hidden; margin-left: 10px; overflow-y: auto; width: inherit;">\n\t\t\t\t\t\t\t\t\t\t<div style="display: contents;">\n\t\t\t\t\t\t\t\t\t\t\t';
 _.each(prepRuns, function (run) { ;
__p += '\n\t\t\t\t\t\t\t\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-70 js-textToCopy">' +
((__t = ( run.name
													)) == null ? '' : __t) +
'\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-15">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<span class="mdi mdi-content-copy js-copyParameters clickable"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t data-copy="' +
((__t = ( run.name )) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t style="font-size: 16px;"></span>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t';
 }) ;
__p += '\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</fieldset>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<hr class="m-b-10 m-t-10">\n\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t<div class="col-xs-12 commentOrDescriptionContainer" data-field-name="comment"\n\t\t\t\t\t\t\t style="margin-top:0;">\n\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-6">\n\t\t\t\t\t\t\t\t<span class="mdi mdi-message-reply-text commentOrDescriptionIcon">\n\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="col-w-all f-l d-f col-w-94 commentOrDescriptionInputDiv">\n                                    <textarea class="form-control floating-label js-info-input inputBackground"\n\t\t\t\t\t\t\t\t\t\t\t  data-field-name="comment"\n\t\t\t\t\t\t\t\t\t\t\t  rows="2"\n\t\t\t\t\t\t\t\t\t\t\t  style="resize: vertical;"\n\t\t\t\t\t\t\t\t\t\t\t  placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n\t\t\t\t\t\t\t\t\t\t\t  wrap="on">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<hr class="m-b-10 m-t-10">\n\t\t\t\t<!-- cancel/confirm row -->\n\t\t\t\t<div class="row">\n\t\t\t\t\t<div class="pull-right p-r-15 col-xs-12" style="text-align: end;">\n\t\t\t\t\t\t<button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n\t\t\t\t\t\t<button class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"\n\t\t\t\t\t\t\t\tdisabled></button>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

