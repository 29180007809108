/**
 * Created by GSP on 19/10/2015.
 */
define('dialogMixin',['module',
        'jquery',
        'underscore',
        'settings',
        'bootbox',
        'jqueryui'
    ],
    function (module,
              $,
              _,
              Settings,
              Bootbox
    ) {

        'use strict';

        module.exports = {

            previousKey: null,

            show: function (options, onClose, onEscape) {

                if (!onEscape) {
                    onEscape = _.bind(function () {
                        this.box.modal('hide');
                    }, this);
                }
                if (!onClose) {
                    onClose = _.bind(function () {
                        this.box.modal('hide');
                    }, this);
                }
                this.onClose = onClose;
                var title = '<div class="title">' + options.title + '</div>';

                var action = '<div class="action">';

                if (options.generatePdfButton === true) {
                    action += '<span class="mdi mdi-file-outline clickable js-generate-pdf" title="' + _.i18n('common.generatePdf') + '"></span>';
                }

                /**
                 * Add getPDF button
                 * @param {String} jsonObjectName property must be implemented in the entity.js
                 * @function {getPDF} function must be implemented in the entity.js
                 */
                if (this.model && typeof this.model.getPDF === 'function') {
                    action +=
                        '<span class="mdi mdi-information-outline clickable"  onClick="' + this.model.getPDF() + '" title="' + _.i18n('common.showDoc') + '"></span>';
                }

                var id;

                if (this.model) {
                    id = this.model.get(this.model.idAttribute);
                }
                if (id !== undefined) {

                    /**
                     * Add attachment button
                     * @param {String} jsonObject property must be implemented in the entity.js
                     * @param {String} secId property must be implemented in the entity.js
                     * @function {getAttachementRef} function must be implemented in the entity.js
                     */
                    var isAttach = this.model && this.model.getEntity && this.model.getEntity().getJqGridColumnConfig && this.model.getEntity().getJqGridColumnConfig().attachmentable;
                    if (this.model && isAttach) {
                        action +=
                            '<span class="mdi mdi-flip-v mdi-rotate-270 mdi-attachment clickable" id="js-show-attachements-' + id + '" title="' + _.i18n('common.manageAttachements') + '"></span>';
                    }

                    /**
                     * Add import button
                     * @param {String} jsonObject property must be implemented in the entity.js
                     * @param {String} secId property must be implemented in the entity.js
                     * @function {getAttachementRef} function must be implemented in the entity.js
                     */
                    if (this.model && typeof this.model.importable === 'boolean' && this.model.importable) {
                        action +=
                            '<span class="mdi mdi-flip-v mdi-rotate-90 mdi-import clickable" onclick="document.getElementById(\'importFile-' + id + '\').click();" title="' + _.i18n('common.importIntoRecord') + '"></span>' +
                            '<input type="file" class="hidden" id="importFile-' + id + '" />';
                    }
                    if (!this.options.errors) {
                        if (!_.isEmpty(this.model.attributes.entityError)) {
                            action +=
                                '<span class="mdi mdi-alert-octagram clickable" id="' + id + '-error" title="' + _.i18n('common.showErrors') + '"></span>';
                        }
                    }
                }
                action += '</div>';
                title += action;


                this.box = Bootbox.dialog({
                    message: this.render().el,
                    title: title,
                    className: options.className,
                    buttons: options.buttons,
                    onEscape: onEscape
                });
                this.triggerMethod('show');

                document.addEventListener('keydown', _.bind(function (event) {
                    var key = event.key;
                    if (this.previousKey === 'Shift' && key === 'Tab') {
                        this.previousKey = null;
                        // center the popup at the center of the screen
                        this.$el.closest('.modal-dialog').css('inset', '0px');
                    } else if (key === 'Shift') {
                        this.previousKey = 'Shift';
                        setTimeout(_.bind(function () {
                            this.previousKey = null;
                        }, this), 100);
                    } else if (key === 'Escape') {
                        // close the popup
                        this.box.modal('hide');
                    }
                }, this));

                // draggable popup that can pop out the main screen
                this.$el.closest('.modal-dialog').draggable({
                    handle: '.modal-header'
                });

                if (id !== undefined) {

                    $('#js-show-attachements-' + id).on('click', _.bind(function () {
                        this.model.getEntity().openAttachment(this.model);
                    }, this));
                    $('#importFile-' + id).change(_.bind(function (event) {
                        if (event.target && event.target.files && event.target.files[0]) {
                            this.model.getEntity().importIntoEntity(this.model, event.target.files[0]);
                        }
                    }, this));
                    $('#' + id + '-error').click(_.bind(function () {
                        require([
                            'errorController'
                        ], _.bind(function (ErrorController) {
                            ErrorController.showE(this.model, this.model.attributes.entityError);
                        }, this));
                    }, this));
                }

                /**
                 * attachment can also be trigger from a createEditPopup by adding this:
                 * in template: <i class="mdi-editor-attach-file clickable js-show-attachements" data-row-id="<%= secId %>" data-json-object="assay"></i>
                 * in view (DialogFormView):    define(['savingBehavior', 'uploadFileBehavior'], function (SavingBehavior, UploadFileBehavior)
                 *                              behaviors: {Saving: {behaviorClass: SavingBehavior},UploadFile: {behaviorClass: UploadFileBehavior}},
                 * that trigger will be catched by: app/modules/common/behaviors/upload_file_behavior.js -> onShowAttachmentPopup: function(e)
                 */

                this.box.on('hidden.bs.modal', _.bind(function () {
                    this.box.off();
                    this.hide();
                }, this));
                this.box.on('shown.bs.modal', _.bind(function () {
                    this.triggerMethod('shown');
                }, this));
                this.box.on('escape.close.bb', _.bind(function () {
                    this.box.off();
                    this.hide();
                }, this));
            },

            setTitle: function (title) {
                if (this.box) {
                    $(this.box).find('.modal-title').text(title);
                }
            },

            hide: function () {
                this.triggerMethod('hide');
                this.box.modal('hide');
                if (this.onClose) {
                    this.onClose();
                }
                this.destroy();
            },

            setPermissions: function (enable) {
                /*jshint -W069 */
                /*Disable Warning Justification on using [] instead of dot notation*/
                if (enable) {
                    this.triggerMethod('enable:cancel:confirm');
                } else {
                    if (this.ui && this.ui.confirm) {
                        this.ui.confirm.hide();
                    }
                    this.$el.find('input,select,textarea').attr('disabled', true);
                    this.$el.find('input,select,textarea').addClass('disabled');
                    this.$el.find('input,select,textarea').removeClass('clickable');
                    this.$el.find('.mdi-content-add,.mdi-action-delete').addClass('invisible');
                }
                /*jshint +W069 */
            },
            onCancel: function () {
                this.box.modal('hide');
            }
        };
    });
