define('adminTrashController',[
    'module',
    'underscore',
    'app',
    'adminTrashView',
    'entities/trash'
], function (
    module,
    _,
    App,
    View
) {
    'use strict';

    var TrashListController = {
        showTrashList: function (data) {
            this.view = new View({data: data});
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
        },

        onGridLoaded: function () {
            var data = {
                url: App.request('trash:get-collection-url'),
                rowCountUrl: App.request('trash:get-count-url')
            };
            this.view.displayGrid(data);
        }
    };

    module.exports = TrashListController;
});
