define('entities/caccounts/kitprots',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational',
    'entities/caccounts/assay',
    'entities/caccounts/kitprotconfiguration'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.KitProt = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/kitprots/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            comment: null,
            'ampParam': '',
            'ampSmooth': '',
            'meltParam': '',
            'meltSmooth': '',
            'configurations': [],
            'assays': [],
            tags: []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'configurations',
            relatedModel: 'KitProtConfiguration',
            collectionType: 'KitProtConfigurationCollection',
            reverseRelation: {
                key: 'refKitProt',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }],

        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/kitprots');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'KitProtJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'KitProt',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.KitProtCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/kitprots');
        },
        model: app.ns.KitProt
    });

    module.exports = _.defaults({
        getName: function () {
            return 'kitprot';
        },
        getUrl: function () {
            return 'v2/kitprots';
        },
        getDynamicJson: function () {
            return 'KitProtJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {clearSearch: false},
                width: '50px'
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {clearSearch: false},
                width: '70px'
            }, {
                label: _.i18n('kitprot.ampParam'),
                name: 'ampParam',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'ampParam',
                searchoptions: {clearSearch: false},
                width: '45px'
            }, {
                label: _.i18n('kitprot.meltParam'),
                name: 'meltParam',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'meltParam',
                searchoptions: {clearSearch: false},
                width: '45px'
            }, {
                label: _.i18n('kitprot.configurations'),
                name: 'configurations',
                classes: 'dynamic-link',
                formatter: this.configurationFormatter,
                search: true,
                index: 'cyclerCode',
                searchoptions: {clearSearch: false},
                width: '45px'
            }, {
                label: _.i18n('kitprot.assays'),
                name: 'assays',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                search: false,
                width: '100px'
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description',
                searchoptions: {clearSearch: false},
                width: '200px'
            });
            return columns;
        },
        configurationFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }

            var values = [];
            _.each(cellValue, function (configuration) {
                if (configuration.refCycler) {
                    values.push(configuration.refCycler.code);
                }
            });
            cellValue = values.join(', ');
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {'field': 'ampParam', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'PROT-AC'}},
                {'field': 'meltParam', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'PROT-MC'}},
                {'field': 'ampSmooth', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'SMOOTH-AC'}},
                {'field': 'meltSmooth', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'SMOOTH-MC'}},
                {
                    'field': 'assays',
                    'param': {
                        'type': 'LIST2ENTITY'
                    }
                }
            );
            return custom;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.KitProt.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        }
    }, Dynamic);

    App.reqres.setHandler('kitprot:model', function (itemJSON) {
        if (!itemJSON) {
            itemJSON = {};
        }
        return app.ns.KitProt.findOrCreate(itemJSON);
    });
});
