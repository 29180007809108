define('dynamicCreateEditCodeList',[
    'module',
    'dialogFormView',
    'underscore',
    'autocompleteView'
], function (
    module,
    DialogFormView,
    _,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template('<div class="js-autocomplete"></div>'),

        regions: {
            autocompleteRegion: '.js-autocomplete'
        },

        initialize: function () {
            if (!this.options.config.modelProperty) {
                alert('modelProperty not defined in codeList');
            }
            if (!this.options.config.valueProperty) {
                this.options.config.valueProperty = 'code';
            }
            if (!this.options.config.queryProperty) {
                this.options.config.queryProperty = 'code';
            }
            if (!this.options.config.valueKey) {
                this.options.config.valueKey = 'code';
            }
            if (!this.options.config.code && !this.options.config.showAll) {
                alert('code for codeList not defined in codeList');
            }
        },
        serializeData: function () {
            var templateData = {};
            templateData.model = this.model.toJSON();
            templateData.fieldName = this.options.fieldName;
            return templateData;
        },

        onShow: function () {
            var config = this.options.config;

            this.getRegion('autocompleteRegion').show(new AutocompleteView(
                this._getAutocompleteOptions(config.modelProperty, config.valueProperty, config.queryProperty,
                    config.showAll ? 'public/codeLists?sord=asc&sidx=code' :
                        'public/codeLists/code/' + config.code + '/elements/?sord=asc&sidx=code',
                    'common.empty.placeholder', config.valueKey, this.onChangeAutocomplete, null, null,
                    {
                        'fieldsWidth': 300,
                        'fields': ['name']
                    }
                )
            ));
        },

        onChangeAutocomplete: function (fieldName, model) {
            this.onChange(fieldName, model ? model.get('code') : null);
        },

        disabled: function () {
            this.ui.parameters.val(null);
            this.ui.parameters.attr('disabled', 'disabled');
        }
    });
});
