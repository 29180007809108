define('suppliersController',[
	'module',
	'underscore',
	'suppliersCreateEditLayoutView',
	'entities/caccounts/suppliers'
], function (
	module,
	_,
	CreateEditLayoutView,
	Suppliers
) {
	'use strict';

	module.exports = {
		showDetails: function (model, callBackRefresh) {
			if (!model) {
				alert('Can\'t create a new model without entity, need to define empty model or existing model.');
				throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
			}
			var createEditView = new CreateEditLayoutView({
				model: model
			});
			createEditView.show({
				title: _.i18n(Suppliers.getName() + '.new'),
				className: 'baseTableEditAddPopup suppliersEditAddPopup'
			}, _.bind(function () {
				if (callBackRefresh) {
					callBackRefresh();
				}
			}, callBackRefresh ? callBackRefresh.context : undefined));
		}
	};
});
