define('entities/caccounts/assayConfigurationResult',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/mbanas',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.AssayConfigurationResult = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/assayConfigurationResults/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'refMbAna': null,
            'algoResult': {type: '', param: ''},
            'algoValidation': {type: 'NO', param: ''},
            'algoExport': {type: 'NO', param: ''},
            'ic': false,
            'configuration': null,
            'sequence': null,
            'sequenceDisplay': null,
            'sequenceDisplayGroup': null,
            'sequenceValidation': null,
            'needValidation': false
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refMbAna',
            relatedModel: 'MbAna'
        }],

        getImageUid: function () {
        },

        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayConfigurationResultJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },
        jsonObject: 'AssayConfigurationResult'
    });

    app.ns.AssayConfigurationResultCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/assayConfigurationResults/');
        },
        model: app.ns.AssayResult,
        comparator: 'sequence'
    });

    module.exports = _.defaults({
        getName: function () {
            return 'assayResult';
        },
        getUrl: function () {
            return 'v2/assayConfigurationResults';
        },
        getDynamicJson: function () {
            return 'AssayConfigurationResultJson';
        },
        getJqGridColumn: function () {
            return new Error('can\'t get grid column');
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.AssayConfigurationResult.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': []};
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            if (config.paramUrl) {
                config.paramUrl.sidx = 'refMbAna.code';
            }
            configuration.urlPath = (config.url ? config.url : this.getUrl()) + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'refMbAna.code'
            }));
            return configuration;
        }
    }, Dynamic);
});
