define('menuCreateEditLayoutView',[
	'module',
	'marionette',
	'backbone',
	'dialogFormView',
	'template!menuCreateEditLayoutTpl',
	'template!superAdminCustomTooltipTpl',
	'savingBehavior',
	'bootbox',
	'underscore',
	'app',
	'menuElementCollectionView',
	'duplicatePopupBehavior',
	'imageUtils',
	'entities/admin/menu',
	'entities/admin/menuelement'
], function (
	module,
	Marionette,
	Backbone,
	DialogFormView,
	createEditLayoutTpl,
	customTooltips,
	SavingBehavior,
	bootbox,
	_,
	App,
	MenuElementCollectionView,
	DuplicatePopupBehavior,
	imageUtils
) {
	'use strict';

	var CreateEditLayoutView = DialogFormView.extend({
		template: createEditLayoutTpl,

		ui: {
			input: '.js-info-input',
			codeInput: '.dataNameIdInput',
			iconUploadLink: '.js-upload-icon-link',
			imageUploadLink: '.js-upload-image-link',
			iconContainer :'.js-icon-container',
			imageContainer :'.js-image-container'
		},

		events: {
			'click .js-cancel-popup': 'onCancel',
			'focus @ui.input': 'onFocus',
			'change @ui.input': 'onInputChange',
			'click .js-confirm': 'onConfirm',
			'click .js-add-menu-element': 'onAddMenuElement',
			'change @ui.imageUploadLink': 'onAddImageLink',
			'change @ui.iconUploadLink': 'onAddIconLink',
			'focus .js-custom-input': 'onCustomFocus'
		},

		regions: {
			menuElements: '.menuElementsTable'
		},

		behaviors: {
			Saving: {
				behaviorClass: SavingBehavior
			},
			DuplicatePopup: {
				behaviorClass: DuplicatePopupBehavior
			}
		},

		modelEvents: {
			sync: 'onSave',
			error: 'onSaveError'
		},

		fieldsToValidate: [
			{name: 'code', type: 'required'},
			{name: 'name', type: 'required'}
		],

		initialize: function (options) {
			this.model = options.model;
			DialogFormView.prototype.initialize.call(this);
		},

		serializeData: function () {
			var templateData = this.model.toJSON();
			templateData.newItem = this.options.newItem;
			return templateData;
		},

		onShow: function () {
			$.material.init();
			this.ui.codeInput.popover({
				trigger: 'manual'
			});

			var collection = App.request('menuElements:collection', this.model.get('menuElements'));
			this.collectionView = new MenuElementCollectionView({
				collection: collection
			});
			this.collectionView.listenTo(this.collectionView, 'select-zone', _.bind(this.onSelectZone, this));
			this.collectionView.listenTo(this.collectionView, 'hide-select-zone', _.bind(this.onHideSelectZone, this));
			this.getRegion('menuElements').show(this.collectionView);
			this.triggerMethod('enable:cancel:confirm');
			var that = this;
			setTimeout(function() {
				$('.js-generate-pdf').click(function() {
					that.generatePdf = true;
					that.onConfirm();
				});
			},500);
			this.displayImageLink(this.model.get('imageLinkEncodedValue'));
			this.displayIcon(this.model.get('iconEncodedValue'));
			$('.modal-dialog').attr('style', 'width: 80% !important');
		},

		onConfirm: function () {
			var test = this.collectionView.collectionFieldsValidate();
			if (test && this.validate(this.fieldsToValidate)) {
				this.model.set('menuElements', this.collectionView.collection.models);
				this.model.save();
			}
		},

		onSave: function (model) {
			var modelJSON = model.toJSON();
			this.model.set(modelJSON);
			this.triggerMethod('saved');
			if (this.generatePdf === true) {
				App.request('menu:generate-pdf',this.model.get('secId'));
			}
			App.navigate('superAdmin/menus/refresh');
			App.navigate('superAdmin/menus', {trigger: true});
			this.box.modal('hide');
		},

		hide: function () {
			if (App.getCurrentRoute() === 'superAdmin/menus/new') {
				App.navigate('superAdmin/menus', {trigger: true});
			} else {
				App.navigate('superAdmin/menus/refresh', {trigger: false});
				App.navigate('superAdmin/menus', {trigger: true, replace: true});
			}
		},

		onSaveError: function (model, response) {
			if (response.status === 409) {
				var codeError = JSON.parse(response.responseText);
				this.onDuplicateCode(codeError);
				this.triggerMethod('hide:label');
			}
		},

		onCancel: function () {
			this.box.modal('hide');
		},

		onDuplicateCode: function (codeError) {
			var fields = this.$el.find('*[data-field-name="' + codeError.field + '"]');
			fields.each(function () {
				if ($(this).val() === codeError.value) {
					$(this).popover('show');
					setTimeout(function () {
						$('.popover').fadeOut('slow', function () {
						});
					}, 2000);
					$(this).css('border-color', 'red');
					$(this).addClass('invalid');
					$('div[data-field-name="' + codeError.field + '"]').addClass('has-error');
				}
			});
		},

		onAddMenuElement: function () {
			var collection = App.request('menuElements:model', null);
			this.collectionView.collection.add(collection);
		},
		onCustomFocus: function () {
			this.ui.codeInput.popover('hide');
		},
		onAddImageLink:function(e) {
			if (e && e.target && e.target.files && e.target.files[0]) {
				var that = this;
				imageUtils.encodeFileToBase64(e.target.files[0],function(imageBase64) {
					that.model.set('imageLinkEncodedValue',imageBase64);
					that.model.set('imageLinkExtension',e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.')+1));
					that.displayImageLink(imageBase64);

				});
				}
		},
		onAddIconLink:function(e) {
			if (e && e.target && e.target.files && e.target.files[0]) {
				var that = this;
				imageUtils.encodeFileToBase64(e.target.files[0],function(imageBase64) {
					that.model.set('iconEncodedValue',imageBase64);
					that.model.set('iconExtension',e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.')+1));
					that.ui.iconContainer.html('<img src="'+imageBase64+'">');
				});
			}
		},
		displayImageLink: function(imageBase64) {
			if (imageBase64 !== null) {
				var that = this;
				var mapHtml = '';
				mapHtml += '<img id="menuimage" src="'+imageBase64+'" usemap="#imagemap" draggable="false" oncontextmenu="return false;">';
				mapHtml += '  <div class="js-sel-rect" style="border:3px dashed black;position:absolute;background-color: #03a9f4;opacity: 0.5">  </div>';
				that.ui.imageContainer.html(mapHtml);
				var image = new Image();
				image.src = document.getElementById('menuimage').src;     //any img src
				image.onload = function(){
					$('.js-image-container').mousedown(function(e) {
								e.preventDefault();
								e.stopPropagation();
								$('.js-sel-rect').css('top', e.offsetY);
								$('.js-sel-rect').css('left', e.offsetX+16);
								this.startX = e.offsetX;
								this.startY = e.offsetY;
								that.stopDrawing = false;
					});
					$('.js-image-container').mousemove(function(e) {
						if (!that.stopDrawing) {
							e.stopPropagation();
							$('.js-sel-rect').height(e.offsetY - this.startY);
							$('.js-sel-rect').width(e.offsetX - this.startX - 16);
						}
					});
					$('.js-image-container').mouseup(function(e) {
						e.stopPropagation();
						if (that.currentChildView && !that.currentChildView.isDestroyed) {
							that.currentChildView.model.set('imagePosition',this.startX+','+this.startY+','+e.offsetX +','+e.offsetY);
							that.currentChildView.render();
						}
						that.stopDrawing = true;
					});
				};
				$('.js-image-container').mouseleave(function() {
					that.stopDrawing = true;
				});
			}
		},
		displayIcon:function(imageBase64) {
			if (imageBase64 !== null) {
				this.ui.iconContainer.html('<img src="'+imageBase64+'">');
			}
		},
		onSelectZone:function(view) {
			this.currentChildView = view;
			this.stopDrawing = true;
			var imagePosition = view.model.get('imagePosition').split(',');
			$('.js-sel-rect').css('top', imagePosition[1]+'px');
			$('.js-sel-rect').css('left', (Number(imagePosition[0])+16)+'px');
			$('.js-sel-rect').height(imagePosition[3]-imagePosition[1]);
			$('.js-sel-rect').width(imagePosition[2]-imagePosition[0]-16);
			this.startX = this.startY = 0;
		},
		 onHideSelectZone:function() {
			this.stopDrawing = true;
			 $('.js-sel-rect').css('top', '0px');
			 $('.js-sel-rect').css('left', '0px');
			 $('.js-sel-rect').height(0);
			 $('.js-sel-rect').width(0);
			 this.startX = this.startY = 0;
		 }
	});

	module.exports = CreateEditLayoutView;
});
