
define('template!prepRunExtractionShow', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container container-fluid" style="width: 500px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="col-xs-12">\n            <div class="row m-b-20">\n                <div class="col-xs-12 m-b-5">\n                    <label class="col-xs-5 popupTitleLabel m-b-5 t-a-r">' +
((__t = (
                        _.i18n('run.extractionMethod') )) == null ? '' : __t) +
'</label>\n                    <input class="col-xs-7 " type="text" disabled value="' +
((__t = (refExtractionMethod)) == null ? '' : __t) +
'"/>\n                </div>\n\n                <div class="col-xs-12 m-b-5">\n                       <textarea style="width: inherit; resize:vertical;"\n                                 rows="3" wrap="on" data-field-name="comment"\n                                 placeholder="" disabled>' +
((__t = ( startComment )) == null ? '' : __t) +
'</textarea>\n                </div>\n                <span class="col-xs-12">' +
((__t = (startUserpid)) == null ? '' : __t) +
'</span>\n            </div>\n            <hr/>\n            <div class="row m-b-20">\n                <div class="col-xs-12 m-b-5">\n                    <label class="col-xs-5 popupTitleLabel m-b-5 t-a-r">' +
((__t = (
                        _.i18n('common.lmbExtractor') )) == null ? '' : __t) +
'</label>\n                    <input class="col-xs-7 " type="text" disabled value="' +
((__t = (refLmbExtractor)) == null ? '' : __t) +
'"/>\n                </div>\n\n                <div class="col-xs-12 m-b-5">\n                    <textarea style="width: inherit; resize:vertical;"\n                              rows="3" wrap="on" data-field-name="comment"\n                              placeholder="" disabled>' +
((__t = ( endComment )) == null ? '' : __t) +
'</textarea>\n                </div>\n                <span class="col-xs-12">' +
((__t = (endUserpid)) == null ? '' : __t) +
'</span>\n            </div>\n\n            <div class="row cancelConfirmRow" style="text-align: center;">\n                <div class="pull-right p-r-15 col-xs-12 cancelConfirmAssayAddEditDiv">\n                    <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup"></button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

