define('PrepRunWellTableView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'jquery',
    'settings',
    'jqgridView',
    'template!prepRunWellTableTpl',
    'prepRunWellDetailView',
    'entities/caccounts/prepwells'
], function (
    module,
    Marionette,
    _,
    App,
    $,
    Settings,
    JqGridView,
    viewTpl,
    WellDetailView
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'wellViewTableListFilters',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqgRow: 'tr.jqgrow',
                blockLoading: '.empty-page-loader'
            }, this.gridUi);
        },

        onShow: function () {
            $.material.init();
        },

        serializeData: function () {
            var templateData = {};
            templateData.editEnable = !!this.channelToDisplay;
            templateData.sortOn = this.sortOn;
            templateData.multiRes = this.multiRes;
            return templateData;
        },

        pagination: function () {
            return {
                first: 0,
                rowCount: 0,
                pageSize: 384,
                currentPage: 1
            };
        },

        gridOptions: function (data) {
            var that = this,
                defaultsOptions = this.gridInitOptions(data),
                options = {
                    colModel: [
                        {
                            label: '',
                            name: 'secId',
                            key: true,
                            hidden: true
                        }, {
                            label: this.getSmpTypeLabelIcon(),
                            name: 'smpType',
                            formatter: _.bind(this.smptypeFormater, this),
                            search: false,
                            // search: true,
                            sortable: true,
                            index: 'smpType',
                            // stype: 'select',
                            // searchrules: {select: true},
                            // searchoptions: {sopt: ['eq'], value: this.smpTypeList, clearSearch: false},
                            fixed: true,
                            width: 45
                        }, {
                            label: _.i18n('well.pos'),
                            classes: 'well-list-pos-label',
                            name: 'pos',
                            formatter: this.wellPosFormatter,
                            sortable: true,
                            index: 'pos',
                            fixed: true,
                            width: 55,
                            search: true,
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            }
                        }, {
                            label: _.i18n('well.smpId'),
                            name: 'smpId',
                            formatter: _.bind(this.wellSmpidFormatter, this),
                            classes: 'css-no-rightBorder',
                            search: true,
                            sortable: true,
                            index: 'smpId',
                            fixed: true,
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            },
                            width: 180
                        }, {
                            label: _.i18n('common.empty.placeholder'),
                            name: 'smpId.code',
                            sortable: false,
                            search: false,
                            classes: 'ignore-row-click css-no-leftBorder',
                            formatter: this.copyToClipboardFormatter,
                            fixed: true,
                            width: 30
                        }, {
                            label: this.getRepeatLabelIcon(),
                            name: 'repeatStatus',
                            formatter: _.bind(this.repeatStatusFormater, this),
                            search: false,

                            sortable: true,
                            index: 'repeatStatus',
                            fixed: true,
                            width: 50
                        }, {
                            label: this.assaysIcons.waiting,
                            name: 'assaysWaiting',
                            formatter: this.wellAssayWaitingFormatter,
                            classes: '',
                            search: true,
                            sortable: true,
                            index: 'assaysWaiting',
                            searchoptions: {clearSearch: false},
                            fixed: true,
                            width: 150
                        }, {
                            label: this.assaysIcons.running,
                            name: 'assaysRunning',
                            formatter: this.wellAssayRunningFormatter,
                            classes: '',
                            search: true,
                            sortable: true,
                            index: 'assaysRunning',
                            searchoptions: {clearSearch: false},
                            fixed: true,
                            width: 150
                        }, {
                            label: this.assaysIcons.done,
                            name: 'assaysDone',
                            formatter: this.wellAssayDoneFormatter,
                            classes: '',
                            search: true,
                            sortable: true,
                            index: 'assaysDone',
                            searchoptions: {clearSearch: false},
                            fixed: true,
                            width: 150
                        }, {
                            label: _.i18n('well.sample.comment'),
                            name: 'wellComment',
                            formatter: _.bind(this.wellCommentFormatter, this),
                            search: true,
                            sortable: true,
                            index: 'wellComment',
                            fixed: true,
                            searchoptions: {clearSearch: false},
                            width: 150
                        }, {
                            label: _.i18n('well.tags'),
                            name: 'tags',
                            formatter: this.defaultFormatter,
                            search: true,
                            sortable: true,
                            index: 'tags',
                            searchoptions: {clearSearch: false},
                            fixed: true,
                            width: 150
                        }
                    ],
                    sortname: this.sortOn ? this.sortOn : 'row',

                    gridComplete: function () {
                        defaultsOptions.gridComplete.apply(this, arguments);

                        var $waiting = that.$('.assayWaitingCell');
                        _.each($waiting, _.bind(function (waiting) {
                            var $waiting = $(waiting);
                            $waiting.parent().addClass('css-prepRun-listView-assayWaiting');
                        }, this));

                        var $running = that.$('.assayRunningCell');
                        _.each($running, _.bind(function (running) {
                            var $running = $(running);
                            $running.parent().addClass('css-prepRun-listView-assayRunning');
                        }, this));

                        var $done = that.$('.assayDoneCell');
                        _.each($done, _.bind(function (done) {
                            var $done = $(done);
                            $done.parent().addClass('css-prepRun-listView-assayDone');
                        }, this));
                    },

                    onSelectRow: _.bind(function (rowId, bool, e) {
                        if (!$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click') {
                            this.ui.blockLoading.show();
                            var filteredRowList = this.ui.jqGrid.getRowData(null, true);
                            var cSelection = [];
                            _.each(filteredRowList, _.bind(function (wellRow) {
                                cSelection.push(wellRow.pos);
                            }, this));
                            var well = this.model.get('wells').find(function (obj) {
                                return obj.get('secId') === rowId;
                            });
                            var wellModel = App.request('well:prep:model', {secId: well.get('secId')});
                            wellModel.fetch().done(_.bind(function () {
                                var wellDetailView = new WellDetailView({
                                    model: wellModel,
                                    cSelection: cSelection,
                                    modelRun: this.model,
                                    detailsPopup: true,
                                    rowId: rowId
                                });
                                wellDetailView.show({
                                    title: wellModel.get('runName'),
                                    className: 'popupWellView' //popupPrepWellView
                                }, _.bind(function () {
                                    App.trigger('prepWell:refresh:jqGrid', this.model, rowId);
                                }, this));
                                this.ui.blockLoading.hide();
                            }, this));
                        }
                    }, this)
                };

            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },

        wellPosFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellPosPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="position: relative;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="position: relative;">' + cellValue + '</span>';
        },

        wellCommentFormatter: function (cellValue, options, object) {
            var comment = object.smpId ? (object.smpId.lisComment ? object.smpId.lisComment : '') +
                ' ' + (object.smpId.comment ? object.smpId.comment : '') : '';
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + comment + '</span>';
        },

        wellSmpidFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = {name: ''};
            }
            if (object.pos) {
                return '<span class="cell-default wellSmpidPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="font-weight: 600;">' + cellValue.name + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue.name + '</span>';
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12">';

            if (cellValue === 'NC') {
                start += '<span class="mdi-content-remove-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi-content-add-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="iconify" data-icon="ant-design:copyright-circle-filled" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="mdi mdi-alpha-c-circle" style="font-size: 17px;"></span>';
			} else if (cellValue === 'RC') {
				start += '<span class="iconify" data-icon="bxs:registered" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="iconify" data-icon="mdi:google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        repeatStatusFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12">';
            if (cellValue) {
                start += '<div style="height: 22px; width: 22px;">' +
                    '<div class="repeatStatus repeat-' + cellValue + '"></div></div>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        onLoadComplete: function (data) {
            this.codeTableData = data;
        },

        onCheckRowCount: function (rowCount) {
            if (rowCount === 0) {
                this.trigger('well:check-rowcount');
            }
        }
    });
});
