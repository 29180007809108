define('pcrSetupPcrRunCollectionView',[
    'module',
    'backbone',
    'marionette',
    'underscore',
    'jquery',
    'pcrSetupPcrRunItem',
    'pcrSetupPcrRunEmpty',
    'template!pcrSetupPcrRunCollectionView',
    'entities/caccounts/pcrSetup/pcrRunLite',
    'entities/caccounts/pcrSetup/pcrWellLite',
    'entities/caccounts/setting'
], function (
    module,
    Backbone,
    Marionette,
    _,
    $,
    Item,
    Empty,
    Tpl,
    PcrRun,
    PcrWell,
    Settings
) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
        template: Tpl,
        childViewContainer: '.js-container',
        className: 'runList rounded filter height-100',
        getChildView: function (model) {
            if (model.isNew()) {
                return Empty;
            }
            return Item;
        },
        initialize: function () {
            this.collection.push(PcrRun.create());
            this.sortType = 'periodDesc';
        },
        childViewOptions: function () {
            return {
                assay: this.assay,
                protocol: this.protocol
            };
        },
        childEvents: {
            'validateSelection': function () {
                this.trigger('validateSelection');
            },
            'item:new': function () {
                Settings.findByCode('DEF_DEPT')
                    .done(_.bind(function (defDept) {
                        require([
                            'runsShowController'
                        ], _.bind(function (ShowController) {
                            ShowController.onCreateEmptyPcrRun(defDept, _.bind(this.onCreateRun, this));
                        }, this));
                    }, this));
            }
        },
        onCreateRun: function (results) {
            $('.js-global-loader').show();
            var defers = [];
            var models = [];
            _.each(results, _.bind(function (item) {
                var model = PcrRun.create({secId: item.secId});
                models.push(model);
                defers.push(model.fetch());
            }, this));

            $.when.apply($, defers)
                .done(_.bind(function () {
                    _.each(models, _.bind(function (model) {
                        this.collection.add(model, {at: this.collection.length - 1});
                    }, this));
                }, this))
                .always(
                    _.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
        },
        filter: function (model) {
            return model.canDisplay(this.assay, this.protocol);
        },
        applyFilter: function (assay, protocol) {
            this.assay = assay;
            this.protocol = protocol;
            this.render();
        },
        ui: {
            sortPeriodAsc: '.mdi-sort-ascending',
            sortPeriodDesc: '.mdi-sort-descending'
        },
        events: {
            'click .mdi-sort-ascending.clickable': 'onSortPeriodAscClick',
            'click .mdi-sort-descending.clickable': 'onSortPeriodDescClick'
        },
        attributes: function () {
            return {
                'style': 'background-color: #81c2cb;'
            };
        },
        onSortPeriodAscClick: function () {
            this.collection.remove(this.collection.filter(function (model) {
                return model.isNew();
            }));
            this.collection.comparator = function (model1, model2) {
                if (model1.get('period') === model2.get('period')) {
                    return 0;
                }
                if (model1.get('period') < model2.get('period')) {
                    return -1;
                }
                return 1;
            };
            this.collection.sort({silent: true});
            this.collection.push(PcrRun.create(), {silent: true});
            this.sortType = 'periodAsc';
            this.render();
        },
        onSortPeriodDescClick: function () {
            this.collection.remove(this.collection.filter(function (model) {
                return model.isNew();
            }));
            this.collection.comparator = function (model1, model2) {
                if (model1.get('period') === model2.get('period')) {
                    return 0;
                }
                if (model1.get('period') < model2.get('period')) {
                    return 1;
                }
                return -1;
            };
            this.collection.sort({silent: true});
            this.collection.push(PcrRun.create(), {silent: true});
            this.sortType = 'periodDesc';
            this.render();
        },
        _disable: function (ui) {
            ui.removeClass('clickable');
            ui.addClass('disabled');
            ui.attr('disabled', true);
        },
        _clickable: function (ui) {
            ui.addClass('clickable');
            ui.removeClass('disabled');
            ui.removeClass('disabled');
        },
        onRender: function () {
            if (this.sortType === 'periodDesc') {
                this._disable(this.ui.sortPeriodDesc);
                this._clickable(this.ui.sortPeriodAsc);
            }
            if (this.sortType === 'periodAsc') {
                this._disable(this.ui.sortPeriodAsc);
                this._clickable(this.ui.sortPeriodDesc);
            }
        }
    });
});
