define('menuSubMenuView',['module',
        'marionette',
        'template!menuSubMenuView'
    ],
    function (module,
              Marionette,
              Tpl
    ) {
        'use strict';

        module.exports = Marionette.LayoutView.extend({
            template: Tpl,

            ui: {
                menu: '.js-menu-region',
                chevron: '.js-chevron',
                link: 'a'
            },

            events: {
                'click @ui.link': 'onLinkClick'
            },

            regions: {
                menu: '.js-menu-region'
            },

            modelEvents: {},

            tagName: 'li',

            serializeData: function () {
                var templateData = {};
                templateData.title = this.model.get('title');
                templateData.id = this.model.get(this.model.idAttribute);
                return templateData;
            },

            onRender: function () {
                var region = this.getRegion('menu');
                if (!this.options.menuView.backgroundColor) {
                    this.options.menuView.backgroundColor = '#FFFFFF00';
                }

                var red = parseInt(this.options.menuView.backgroundColor.substring(1, 3), 16);
                var green = parseInt(this.options.menuView.backgroundColor.substring(3, 5), 16);
                var blue = parseInt(this.options.menuView.backgroundColor.substring(5, 7), 16);
                var alpha = parseInt(this.options.menuView.backgroundColor.substring(7, 9), 16);

                var newAlpha = Math.min(255, alpha + 24);

                var backgroundColor = '#' + ('00' + red.toString(16)).slice(-2) + ('00' + green.toString(16)).slice(-2) + ('00' + blue.toString(16)).slice(-2) + ('00' + newAlpha.toString(16)).slice(-2);

                this.menuView = new this.options.menuView({
                    model: this.model,
                    collection: this.model.get('subMenu'),
                    settings: this.options.settings,
                    backgroundColor: backgroundColor,
                    parent: this
                });
                region.show(this.menuView);
                this.ui.menu.attr('style', 'background-color: ' + backgroundColor);
            },

            onLinkClick: function () {
                this._resetChevron();
            },
            _resetChevron: function () {
                if (!this.ui.menu.hasClass('in')) {
                    this.ui.chevron.removeClass('mdi-chevron-right');
                    this.ui.chevron.addClass('mdi-chevron-down');
                } else {
                    this.ui.chevron.removeClass('mdi-chevron-down');
                    this.ui.chevron.addClass('mdi-chevron-right');
                }
            },
            findByName: function (name) {
                return this.menuView.findByName(name);
            },
            setActive: function (model) {
                if (this.model === model.get('parent')) {
                    if (!this.ui.menu.hasClass('in')) {
                        this.ui.link.trigger('click');
                    }
                }
                this.menuView.setActive(model);
            }
        });
    });
