
define('template!assayReagentQCTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12 m-0 p-0">\n\t<h2 class="m-0 m-b-5 col-xs-12 p-0">' +
((__t = ( _.i18n('assay.reagent.qc.title') )) == null ? '' : __t) +
'</h2>\n</div>\n\n<div class="col-xs-1 p-r-2 p-l-2" style="width: 24px;">\n\t<span class="mdi mdi-plus-circle action clickable js-add" style="line-height: 24px; font-size: 24px;"></span></div>\n<div class="col-xs-7 p-r-2 p-l-2">' +
((__t = ( _.i18n('assayReagent.kit.title') )) == null ? '' : __t) +
'</div>\n<div class="col-xs-3 p-r-2 p-l-2">' +
((__t = ( _.i18n('assayReagent.qc.title') )) == null ? '' : __t) +
'</div>\n<div class="col-xs-1 p-r-2 p-l-2"></div>\n<div class="collection col-xs-12 p-0"></div>';

}
return __p
};});

