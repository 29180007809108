define('arrayCustomFromTypeView',[
	'module',
	'underscore',
	'autocompleteView',
	'dialogFormView',
	'template!arrayCustomFromTypeView',
	'app',
	'backbone',
	'arrayInputView',
	'entities/admin/codelist'
], function (
	module,
	_,
	AutocompleteView,
	DialogFormView,
	Tpl,
	App,
	Backbone,
	ArrayInputView,
	CodeLists
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		regions: {
			autocomplete: '.js-autocomplete',
			input: '.js-input'
		},

		modelEvents: {
			'change': 'updateModel'
		},

		initialize: function () {
			this.baseModel = this.model;
			this.model = new Backbone.Model();
			var split = this.baseModel.get(this.options.fieldName) ? this.baseModel.get(this.options.fieldName).split(';') : [];
			this.model.set('type', null);
			this.model.set('param', this.baseModel.get('param'));
			if (split.length > 0) {
				this.model.set('type', split[0]);
			}
			if (split.length > 1) {
				this.model.set('param', _.rest(split).join(';'));
			}
		},
		serializeData: function () {
			return {
				'fieldName': this.options.fieldNameParam,
				'model': this.model.toJSON()
			};
		},
		onRender: function () {
			var view = new AutocompleteView(
				this._getAutocompleteOptionObject(
				CodeLists.getAutocompleteParamForCode({
					modelProperty: 'type',
					codeListCode: this.options.fieldNameParam,
					callBackOnChange: this.onChangeCodeList
				})));
			this.getRegion('autocomplete').show(view);
			this.display();
		},
		onChangeCodeList: function (fieldName, model) {
			var lastType = this.model.get(fieldName);
			this.onChange(fieldName, model ? model.get('code') : null);
			if (lastType !== this.model.get(fieldName)) {
				this.onChange('param', null);
			}
			this.updateModel();
			this.display();
		},
		display: function () {
			if (this.model.get('type')) {
				this.getRegion('input').show(new ArrayInputView({
					model: this.model,
					field: {
						field: 'param',
						param: {arrayCode: this.options.fieldNameCodePrefix + this.model.get('type')}
					}
				}));
			} else {
				this.getRegion('input').destroy();
				var data = {};
				data[this.options.fieldName] = null;
				this.model.set(data, {silent: true});
			}
		},
		updateModel: function () {
			var data = {};
			data[this.options.fieldName] = this.model.get('type') + (this.model.get('param') ? ';' + this.model.get('param') : '');
			this.baseModel.set(data, {silent: true});
		}
	});
});
