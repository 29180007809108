define('entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValues',[
    'app',
    'backbone',
    'jquery',
    'settings',
    'entities/caccounts/pcrwells',
    'entities/caccounts/pcrruns',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValuePcrWellValues'
], function (
    App,
    Backbone,
    $,
    Settings
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.StandardCurveAssayConfigurationResultValue = Backbone.RelationalModel.extend({
        defaults: {
            'pcrRun': null,
            'status': null,
            'results': [],
            'slope': 0,
            'intercept': 0,
            'efficiency': 0
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'pcrRun',
            relatedModel: 'PcrRun'
        }, {
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'StandardCurveAssayConfigurationResultValuePcrWellValue',
            collectionType: 'StandardCurveAssayConfigurationResultValuePcrWellValueCollection'
        }],
        getImageUid: function () {
        },
        changeStatus: function (status) {
            var url = Settings.url('compuzz', 'v2/standardCurves/assayConfigurationResults/values/' + this.get('secId'), {
                    status: status
                }),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });

    app.ns.StandardCurveAssayConfigurationResultValueCollection = Backbone.Collection.extend({
        model: app.ns.StandardCurveAssayConfigurationResultValue,
        comparator: function (model1, model2) {
            if (model1.get('pcrRun').get('period') === model2.get('pcrRun').get('period')) {
                return 0;
            }
            if (model1.get('pcrRun').get('period') < model2.get('pcrRun').get('period')) {
                return 1;
            }
            return -1;
        }
    });
});
