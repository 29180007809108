define('entities/caccounts/fileFormatSampleLists',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';
    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.FileFormatSampleList = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/fileFormat/sampleLists/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            comment: null,
            tags: [],
            'inputSampleListService': 'Custom',
            'outputSampleListService': 'Custom',
            'customInputSampleList': '',
            'customOutputSampleList': ''
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/fileFormat/sampleLists');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'FileFormatSampleListJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'FileFormatSampleList',
        getEntity: function () {
            return module.exports;
        },
        importable: true
    });

    app.ns.FileFormatSampleListCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/fileFormat/sampleLists');
        },
        model: app.ns.FileFormatSampleList
    });

    module.exports = _.defaults({
        getName: function () {
            return 'fileFormatSampleList';
        },
        getUrl: function () {
            return 'v2/fileFormat/sampleLists';
        },
        getDynamicJson: function () {
            return 'FileFormatSampleListJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('fileFormat.inputSampleList'),
                name: 'inputSampleListService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'inputSampleListService',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('fileFormat.customInputSampleList'),
                name: 'customInputSampleList',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'customInputSampleListt',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('fileFormat.outputSampleList'),
                name: 'outputSampleListService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'outputSampleListService',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('fileFormat.customOutputSampleList'),
                name: 'customOutputSampleList',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'customOutputSampleList',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.FileFormatSampleList.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore();
            ignored.push('customInputSampleList', 'customOutputSampleList');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'inputSampleListService',
                    'param': {
                        'type': 'SAMPLELIST',
                        'field': 'customInputSampleList',
                        'url': 'v2/inputSampleListService'
                    }
                },
                {
                    'field': 'outputSampleListService',
                    'param': {
                        'type': 'SAMPLELIST',
                        'field': 'customOutputSampleList',
                        'url': 'v2/outputSampleListService'
                    }
                }
            );
            return custom;
        },

        getAutocompleteParamForImport: function (config) {
            config.url = this.getUrl() + '/forImport';
            return Dynamic.getAutocompleteParam(config, this);
        },
        getAutocompleteParamForExport: function (config) {
            config.url = this.getUrl() + '/forExport';
            return Dynamic.getAutocompleteParam(config, this);
        }
    }, Dynamic);
});
