
define('template!dashboardPcrThumbnailCardTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="card" style="background-color: white; border: 1px solid #333;"> <!--padding: 5px; padding-top: 10px-->\n\n        <div class="card-header"></div>\n\n        <div class="col-xs-12 noWrapHidden m-b-5" title="' +
((__t = ( name )) == null ? '' : __t) +
'"\n             style="border: solid 1px #FCB8B8FF;\n                background-color: #FCB8B8FF;">\n            <div class="col-xs-11 p-l-0 p-r-0"\n                 style="font-size: 19px; font-weight: 600; text-align: center;">\n                ' +
((__t = ( name )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-xs-1 p-l-0 p-r-0">\n                <span class="mdi ' +
((__t = ( type === 'P' ? 'mdi-alpha-p-box' : '' )) == null ? '' : __t) +
'"\n                     style="color: #333; font-size: 29px; position: relative;\n                     bottom: 10px; height: 26px; left: 3px;"></span>\n            </div>\n        </div>\n\n        <div style="width: 100%; text-align: center;" class="hidden">\n            <!---------------------------------------------------- hidden image -->\n            <img style="width: 90%;"\n                 src="/app/styles/images/mobiolink.svg"\n                 alt="logo for test">\n        </div>\n\n        <div>\n            <div class="col-xs-12 hidden" style="font-size: 18px;">\n                <!---------------------------------------------------- hidden -->\n                <div class="col-xs-2 noWrapHidden p-r-0">\n                    <label>Prot</label>\n                </div>\n                <div class="col-xs-10 noWrapHidden p-r-0">\n                    ' +
((__t = ( refKitProt? refKitProt.code : '' )) == null ? '' : __t) +
' ' +
((__t = ( assays? '(' + assays + ')' : '' )) == null ? '' : __t) +
'\n                </div>\n            </div>\n\n            <div class="col-xs-12 hidden" style="font-size: 18px;">\n                <!---------------------------------------------------- hidden -->\n                <div class="col-xs-2 noWrapHidden p-r-0">\n                    <label>Cycler</label>\n                </div>\n                <div class="col-xs-10 noWrapHidden p-r-0">\n                    ' +
((__t = ( refKitProt && refKitProt.refCycler? refKitProt.refCycler.code : '' )) == null ? '' : __t) +
'\n                </div>\n            </div>\n\n            <div class="col-xs-12 hidden" style="font-size: 18px;">\n                <!---------------------------------------------------- hidden -->\n                <div class="col-xs-2 noWrapHidden p-r-0">\n                    <label>Com</label>\n                </div>\n                <div class="col-xs-10 noWrapHidden p-r-0">\n                    ' +
((__t = ( comment? comment : '' )) == null ? '' : __t) +
'\n                </div>\n            </div>\n\n            <div class="col-xs-12" style="font-size: 15px;">\n\n                <div class="col-xs-6 noWrapHidden p-r-0" style="height: 29px;">\n                    ' +
((__t = ( statusIcon )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-6 noWrapHidden p-r-0" style="height: 29px;">\n                    ' +
((__t = ( valStatusIcon )) == null ? '' : __t) +
'\n                </div>\n            </div>\n\n            <div class="col-xs-12 hidden" style="font-size: 18px;">\n                <!---------------------------------------------------- hidden -->\n                <div class="col-xs-2 noWrapHidden p-r-0">\n                    <label>elapsedTimeToString</label>\n                </div>\n                <div class="col-xs-10 noWrapHidden p-r-0">\n                    ' +
((__t = ( elapsedTimeToString? elapsedTimeToString : '' )) == null ? '' : __t) +
'\n                </div>\n            </div>\n\n            <div class="col-xs-12 " style="font-size: 18px;">\n\n                <div class="col-xs-12 noWrapHidden p-r-0">\n                    ' +
((__t = ( sequence? sequence : '' )) == null ? '' : __t) +
' ' +
((__t = ( pattern? pattern : '' )) == null ? '' : __t) +
'\n                </div>\n\n                <div class="col-xs-12 noWrapHidden p-r-0">\n                    ' +
((__t = ( period? period : '' )) == null ? '' : __t) +
' ' +
((__t = ( group? group : '' )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

