define('entities/caccounts/assayReagentTargetPcrKitLots',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backboneRelational',
    'entities/caccounts/assayreagent',
    'entities/caccounts/mbanas',
    'entities/caccounts/mbanares'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.AssayReagentTargetPcrKitLot = Backbone.RelationalModel.extend({
        defaults: {
            refAssayReagent: null,
            refMbAna: null,
            refMbAnaRes: null,
            mbAnaRess: [],
            val: undefined,
            deltaWarning: undefined,
            deltaError: undefined
        },
        relations: [{
            type: Backbone.HasOne,
            key: 'refAssayReagent',
            relatedModel: 'AssayReagent'
        }, {
            type: Backbone.HasOne,
            key: 'refMbAna',
            relatedModel: 'MbAna'
        }, {
            type: Backbone.HasOne,
            key: 'refMbAnaRes',
            relatedModel: 'MbAnaRes'
        }],
        idAttribute: 'secId',
        getImageUid: function () {
        },


        jsonObjectName: 'AssayReagentTargetPcrKitLotJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayReagentTargetPcrKitLot',
        getEntity: function () {
            return module.exports;
        },
        importable: true,
        /**
         * Find pcrKitLot for a well sampleType
         * @returns {*|jQuery}
         */
        getControlKitLot: function () {
            var defer = $.Deferred(),
                url = Settings.url('compuzz', 'v2/kitlots/pcr/' + this.get('refKitLotPcr').get('secId') + '/values/' + this.get('secId') + '/controlKitLot');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        getDataForPcrKitLot: function () {
            return {
                url: Settings.url('compuzz', 'v2/kitlots/pcr/' + this.get('refKitLotPcr').get('secId') + '/values/' + this.get('secId') + '/controlKitLot/wells/'),
                rowCountUrl: 'v2/kitlots/pcr/' + this.get('refKitLotPcr').get('secId') + '/values/' + this.get('secId') + '/controlKitLot/wells/rowCount'
            };
        }
    });

    app.ns.AssayReagentTargetPcrKitLotCollection = Backbone.Collection.extend({
        model: app.ns.AssayReagentTargetPcrKitLot,
        comparator: function (item1, item2) {
            var str1 = item1.get('refAssayReagent').get('refAssay').get('code') + '_' + (item1.get('refAssayReagent').get('reagent') ? item1.get('refAssayReagent').get('reagent').get('code') : '') + '_' + item1.get('refMbAna').get('code');
            var str2 = item2.get('refAssayReagent').get('refAssay').get('code') + '_' + (item2.get('refAssayReagent').get('reagent') ? item2.get('refAssayReagent').get('reagent').get('code') : '') + '_' + item2.get('refMbAna').get('code');

            return str1.localeCompare(str2);
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'assayreagenttargetpcrkitlot';
        },
        getDynamicJson: function () {
            return 'AssayReagentTargetPcrKitLotJson';
        },

        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.AssayReagentTargetPcrKitLot.findOrCreate(modelJson);
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.AssayReagentTargetPcrKitLotCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 300, 'fields': ['refKit.code']};
        },

        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore();
            ignored.push('comment');
            return ignored;
        }
    }, Dynamic);
});
