define('superAdminDBDocumentationDetailView',[
	'module',
	'backbone',
	'marionette',
	'underscore',
	'app',
	'autocompleteView',
	'dialogFormView',	'settings',
	'template!superAdminDBDocumentationDetailTpl',
	'jsonPropertiesCollectionView',
	'docUrlCollectionView'
], function (
	module,
	Backbone,
	Marionette,
	_,
	App,
	AutocompleteView,
	DialogFormView,
	Settings,
	ViewTpl,
	JsonObjectView,
	DocUrlCollectionView

) {
	'use strict';

	var JsonDocumentationListView = DialogFormView.extend({
		template: ViewTpl,
		childView: JsonObjectView,
		childViewContainer: '#columns',
		ui: {
			'docurlPane':'.js-docurl-pane'
		},
		events: {
            'focus .js-info-input': 'onFocus',
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'change .js-info-input': 'onInputChangeCheck',
            'click .js-add-docurl': 'onAddDocUrl',
            'click .js-add-field-element': 'onAddFieldElement'
        },
        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        regions: {
            columns: '#columns',
            docUrlsTable: '.docUrlsTable'
        },

        fieldsToValidate: [
            {name: 'internalName', type: 'required'}
        ],

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.newItem = this.options.newItem !== undefined ? this.options.newItem : false;
            return templateData;
        },
        onShow: function () {
            $.material.init();
            var docUrlsCollection = App.request('document:collection', this.model.get('documents'));
            this.docUrlsCollectionView = new DocUrlCollectionView({
                collection: docUrlsCollection
            });
            if (docUrlsCollection && docUrlsCollection.length > 0) {
                this.ui.docurlPane.show();
            }
            this.getRegion('docUrlsTable').show(this.docUrlsCollectionView);
            this.collectionView = new JsonObjectView({
				collection: this.options.collection !== undefined?this.options.collection:App.request('jsonpropertydocumentation:collection', null)
			});
			this.collectionView.options.analysis = this.model.get('analysis');
			this.getRegion('columns').show(this.collectionView);
			this.triggerMethod('enable:cancel:confirm');
			var that = this;
			setTimeout(function() {
				$('.js-generate-pdf').click(function() {
					that.generatePdf = true;
					that.onConfirm();
				});
			},500);
			this.$el.css('height','100%');
			$('.modal-dialog').css('width','98%');
			$('.modal-dialog').css('height','90%');
			$('.modal-content').css('height','100%');
			$('.modal-body').css('height','100%');
			$('.bootbox-body').css('height','100%');
		},
		onConfirm: function() {
            if (this.validate(this.fieldsToValidate)) {
                this.model.set('jsonPropertyDocumentationJsons', this.collection !== undefined ? this.collection.models : this.collectionView.collection.models);
                this.model.set('documents', this.docUrlsCollectionView.collection.models);
                this.model.save();
            }
        },

		onSave: function() {
			if (this.generatePdf === true) {
				App.request('jsonobjectdocumentation:generate-pdf',this.model.get('secId'));
			}
			this.hide();
		},
		onCancel: function () {
			this.box.modal('hide');
		},

		hide: function() {
			this.triggerMethod('hide');
			this.box.modal('hide');
			this.destroy();
			App.navigate('superAdmin/dbDocumentation/refresh', {trigger: false});
			App.navigate('superAdmin/dbDocumentation', {trigger: true, replace: true});

		},
		onChange: function(fieldName,value) {
			this.model.set(fieldName, value);
		},

		onInputChangeCheck: function (e) {
			if (!$(e.target).hasClass('js-column')) {
				this.onInputChange(e);
			}
		},
		onAddDocUrl: function () {
			var collection = App.request('document:model', null);
			this.docUrlsCollectionView.collection.add(collection);
			this.ui.docurlPane.show();
		},
		onAddFieldElement: function () {
			var model = App.request('jsonpropertydocumentation:model', null);
			this.collectionView.collection.add(model);
		},
		onSaveError: function (model, response) {
			if (response.status === 409) {
				this.onDuplicateCode();
				this.triggerMethod('hide:label');
			}
		},

		onDuplicateCode: function () {
			this.ui.endUserIdInput.popover('show');
			this.ui.endUserIdInput.addClass('invalid');
			this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
		}
	});

	module.exports = JsonDocumentationListView;
});
