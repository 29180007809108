define('entities/caccounts/sample_routingactionroutinggroup',[
    'app',
    'backbone',
    'entities/caccounts/routingaction_routinggroup',
    'entities/caccounts/samples',
    'entities/caccounts/userpids'
], function (
    App,
    Backbone
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.SampleRoutingActionRoutingGroup = Backbone.RelationalModel.extend({
        defaults: {
            sample: null,
            routingActionRoutingGroup: null,
            creatBy: null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'creatBy',
            relatedModel: 'UserPid'
        }, {
            type: Backbone.HasOne,
            key: 'sample',
            relatedModel: 'Sample'
        }, {
            type: Backbone.HasOne,
            key: 'routingActionRoutingGroup',
            relatedModel: 'RoutingActionRoutingGroup'
        }]
    });

    app.ns.SampleRoutingActionRoutingGroupCollection = Backbone.Collection.extend({
        model: app.ns.SampleRoutingActionRoutingGroup
    });
});
