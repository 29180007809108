
define('template!translationsTableLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '\n  <div id="translations-filters-region"></div>\n  <div id="translations-table-region"></div>\n';

}
return __p
};});

