/**
 * Created by RKL on 13/08/2015.
 */
define('runAssaysTargetsTabView',[
    'module',
    'app',
    'underscore',
    'jquery',
    'marionette',
    'colorUtils',
    'template!runAssaysTargetsTabTpl',
    'miniPlateView',
    'wellDisplayGraphView',
    'wellDisplayMagPixView',
    'wellDisplayHL7View',
    'entities/caccounts/pcrwells',
    'entities/caccounts/runats'
], function (
    module,
    App,
    _,
    $,
    Marionette,
    ColorUtils,
    viewTpl,
    MiniPlateView,
    DisplayGraphLayoutView,
    DisplayMagPixLayoutView,
    DisplayHL7LayoutView
) {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: viewTpl,

        ui: {
            alert: '.alert',
            inputAssay: '.js-info-input-assay',
            assayLbl: '.lbl-assay'
        },
        events: {
            'click .edit-run-at': 'onEditRunAt',
            'click .btn-previous-assay': 'onPreviousAssayClick',
            'click .btn-next-assay': 'onNextAssayClick',
            'change @ui.inputAssay': 'onBtnAssayClick',
            'click .btn-validate': 'onValidateClick',
            'click .btn-validate2': 'onValidate2Click',
            'click .btn-validate12': 'onValidate12Click',
            'click .btn-confirm-result': 'onConfirmResultClick',
            'click .btn-change-to-neg': 'onChangeToNegClick',
            'click .js-valview-repeat-pcr': 'onValViewRepeatPcr',
            'click .js-valview-repeat-pcr-control': 'onValViewRepeatPcrControl',
            'click .js-valview-repeat-extract': 'onValViewRepeatExtraction',
            'click .js-valview-repeat-extract-control': 'onValViewRepeatExtractionControl',
            'click .js-valViewList-assayClick': 'onAssayClick',
            'click .js-valViewList-targetClick': 'onTargetClick',
            'click .js-target-reset-filters': 'onResetFilter',
            'click .js-assayVersion': 'onAssayVersionClick'
        },

        regions: {
            wellTable: '#right-view',
            miniTargetView: '#mini-plate-view',
            loader: '#region-loader'
        },

        modelEvents: {
            'change': 'render'
        },

        onAssayClick: function (event) {
            var selectedAssaySecId = $(event.currentTarget).attr('data-secId');
            this.currentAssayTarget = _.first(_.where(this.assayTargets, _.bind(function (assayTarget) {
                return assayTarget.assay.secId === selectedAssaySecId;
            }, this)));
            this.onWellFilter(this.currentAssayTarget, []);
        },

        onTargetClick: function (event) {
            var selectedTargetSecId = parseInt($(event.currentTarget).attr('data-id'), 10);

            this.currentAssayTarget = _.findWhere(this.assayTargets, {id: selectedTargetSecId});

            this.onWellFilter(this.currentAssayTarget, []);
        },

        initialize: function (options) {
            this.collectionDisplay = this.model.get('wells').filterByPos(options.from, options.step, options.to);
            this.isMultiDisplay = this.collectionDisplay.size() > 96;
            this.assayVersions = _.map(this.collectionDisplay.getAssays(), function (model) {
                return model.toJSON();
            });

            this.assayTargets = [];
            _.each(this.assayVersions, _.bind(function (assayVersion) {
                if (assayVersion.results.length > 0) {
                    var concatResults = _.chain(assayVersion.results).filter(function (result) {
                        return result.needValidation;
                    }).sortBy(function (result) {
                        return result.sequenceValidation;
                    }).value();
                    this.assayTargets = this.assayTargets.concat(concatResults);
                }
            }, this));
            this.currentAssayTarget = _.first(this.assayTargets);

            this.collection = this.collectionDisplay.search(this.currentAssayTarget);
            this.wellSelected = this.collection.pluck('pos');
            this.displayLegend = false;
            this.collection.each(function (model) {
                model.set({'selected': true}, {silent: true});
            });
        },

        onRender: function () {
            this.miniPlateView = new MiniPlateView({
                model: this.model,
                collection: this.collection,
                collectionDisplay: this.collectionDisplay,
                assays: this.assayVersions,
                currentAssayTarget: this.currentAssayTarget,
                wellSelected: this.wellSelected,
                isMultiDisplay: this.isMultiDisplay
            });
            this.miniPlateView.listenTo(this.miniPlateView, 'well:filter', _.bind(this.onWellFilter, this));
            this.miniPlateView.listenTo(this.miniPlateView, 'well:collection', _.bind(this.onChangeCollection, this));
            this.getRegion('miniTargetView').show(this.miniPlateView);
            var view;
            if (this.model.getTypeWells() === 'MAGPIX') {
                view = new DisplayMagPixLayoutView({
                    model: this.model,
                    collection: this.collection,
                    assays: this.assayVersions,
                    currentAssayTarget: this.currentAssayTarget,
                    wellSelected: this.wellSelected,
                    isMultiDisplay: this.isMultiDisplay,
                    assayTargets: this.assayTargets,
                    controller: this.options.controller,
                    hideOkButton: true
                });
                view.listenTo(view, 'well:filter', _.bind(this.onWellFilter, this));
                view.listenTo(view, 'well:check', _.bind(this.addWellSelected, this));
                view.listenTo(view, 'well:uncheck', _.bind(this.removeWellSelected, this));
                this.getRegion('wellTable').show(view);
            } else if (this.model.getTypeWells() === 'HL7') {
                view = new DisplayHL7LayoutView({
                    model: this.model,
                    collection: this.collection,
                    assays: this.assayVersions,
                    currentAssayTarget: this.currentAssayTarget,
                    wellSelected: this.wellSelected,
                    isMultiDisplay: this.isMultiDisplay,
                    assayTargets: this.assayTargets,
                    controller: this.options.controller,
                    hideOkButton: true
                });
                view.listenTo(view, 'well:filter', _.bind(this.onWellFilter, this));
                view.listenTo(view, 'well:check', _.bind(this.addWellSelected, this));
                view.listenTo(view, 'well:uncheck', _.bind(this.removeWellSelected, this));
                this.getRegion('wellTable').show(view);
            } else {
                var graphToDisplay;
                if (this.currentAssayTarget.algoResult && this.currentAssayTarget.algoResult.type === 'AMP') {
                    graphToDisplay = 'A';
                } else if (this.currentAssayTarget.algoResult && this.currentAssayTarget.algoResult.type === 'MUTV') {
                    graphToDisplay = 'M';
                } else if(this.currentAssayTarget.algoResult && this.currentAssayTarget.algoResult.type === 'CRI') {
                    graphToDisplay = 'A';
                }

                if (graphToDisplay) {
                    this.displayGraphView = new DisplayGraphLayoutView({
                        model: this.model,
                        collection: this.collection,
                        assays: this.assayVersions,
                        assayTargets: this.assayTargets,
                        currentAssayTarget: this.currentAssayTarget,
                        graphToDisplay: graphToDisplay,
                        hideOkButton: true,
                        displayLegend: this.displayLegend,
                        wellSelected: this.wellSelected,
                        controller: this.options.controller,
                        currentGraph: this.currentGraph
                    });
                    this.displayGraphView.listenTo(this.displayGraphView, 'well:filter', _.bind(this.onWellFilter, this));
                    this.displayGraphView.listenTo(this.displayGraphView, 'well:graph:legend', _.bind(this.onChangeLegend, this));
                    this.displayGraphView.displaySlider = true;
                    this.displayGraphView.selectionMode = false;

                    this.getRegion('wellTable').show(this.displayGraphView);
                }
            }

            $('.js-global-loader').hide();

            this.$el.find('.valViewList-targetAssayContainer[data-id="' + this.currentAssayTarget.id + '"]')
                .removeClass('whiteBorder');

            this.$el.find('.valViewList-targetAssayContainer[data-secId="' + this.currentAssayTarget.assay.secId + '"]')
                .find('.valViewList-targetIconContainer[data-id="' + this.currentAssayTarget.id + '"]')
                .find('.valViewList-targetIcon')
                .css('display', 'block');
        },

        addWellSelected: function (well) {
            this.wellSelected.push(well.get('pos'));
        },
        removeWellSelected: function (well) {
            this.wellSelected = _.reject(this.wellSelected, function (ws) {
                return ws === well.get('pos');
            });
        },

        onChangeLegend: function (checked) {
            this.displayLegend = checked;
        },

        serializeData: function () {
            var templateData = {};
            templateData.currentAssayTarget = this.currentAssayTarget;

            var colorsAssay = ['#FF0000', '#00FF00', '#fff302', '#ff0021', '#0012ff'];
            if (this.model.get('settings') && this.model.get('settings')['COLORS-ASSAY']) {
                colorsAssay = this.model.get('settings')['COLORS-ASSAY'].split(';');
            }
            _.each(this.assayVersions, _.bind(function (assay, key) {
                var colorAssay;
                var assayIndex = key + 1;
                if (assayIndex) {
                    var i = (assayIndex % (colorsAssay.length - 2));
                    if (i === 0) {
                        i = (colorsAssay.length - 2);
                    }
                    colorAssay = colorsAssay[i + 1];
                }

                if (assay.secId && colorAssay) {
                    assay.color = {
                        secId: assay.secId,
                        color: colorAssay,
                        backgroundColor: ColorUtils.defineAlpha(colorAssay, colorsAssay[0]), //cellOpacity
                        assayColor: ColorUtils.defineAlpha(colorAssay, colorsAssay[1]) //assayOpacity
                    };
                }
            }, this));

            templateData.assays = _.clone(this.assayVersions);
            _.each(templateData.assays, function (assay) {
                assay.results = _.sortBy(_.filter(assay.results, function (result) {
                    return result.needValidation;
                }), 'sequenceValidation');
            });
            return templateData;
        },

        onChangeCollection: function (from, step, to) {
            $('.js-global-loader').show();
            this.options.from = from;
            this.options.step = step;
            this.options.to = to;
            this.collectionDisplay = this.model.get('wells').filterByPos(this.options.from, this.options.step, this.options.to);
            this.isMultiDisplay = this.collectionDisplay.size() > 96;
            this.assayVersions = _.map(this.collectionDisplay.getAssays(), function (model) {
                var json = model.toJSON();
                json.result = _.where(json.results, {needValidation: true});
                return json;
            });
            this.assayTargets = _.first(this.assayVersions).results;
            this.currentAssayTarget = _.first(this.assayTargets);
            this.collection = this.collectionDisplay.search(this.currentAssayTarget);
            this.wellSelected = this.collection.pluck('pos');
            this.collection.each(function (model) {
                model.set({'selected': true}, {silent: true});
            });

            this.render();
        },

        onWellFilter: function (assayResult, posSelected, currentGraph) {
            $('.js-global-loader').show();
            this.currentAssayTarget = assayResult;
            this.collectionDisplay = this.model.get('wells').filterByPos(this.options.from, this.options.step, this.options.to);
            this.collection = this.collectionDisplay.search(assayResult);
            this.wellSelected = this.collection.pluck('pos');
            this.currentGraph = currentGraph;

            if (posSelected && posSelected.length > 0) {
                this.wellSelected = _.reject(this.wellSelected, function (ws) {
                    return !posSelected.includes(ws);
                });
            }

            this.render();
        },

        onBtnAssayClick: function (event) {
            var $assay = $(event.currentTarget);
            this.changeAssay($assay.val());
        },

        onPreviousAssayClick: function () {
            var index = this.ui.inputAssay.children('option:selected').index();
            if (index !== 0 && this.assayVersions.length > 1) {
                this.changeAssay(this.assayVersions[index - 1].secId);
            }
        },

        onNextAssayClick: function () {
            var index = this.ui.inputAssay.children('option:selected').index();
            if (index + 1 < this.assayVersions.length) {
                this.changeAssay(this.assayVersions[index + 1].secId);
            }
        },

        changeAssay: function (selectedAssaySecId) {
            this.selectedAssayTargets = [];
            _.each(this.assayVersions, _.bind(function (assay) {
                if (assay.secId === selectedAssaySecId) {
                    if (!_.isEmpty(assay.results)) {
                        this.selectedAssayTargets = [].concat(this.selectedAssayTargets, assay.results);
                    }
                }
            }, this));

            this.currentAssayTarget = _.first(this.selectedAssayTargets);

            if (this.currentAssayTarget) {
                this.onWellFilter(this.currentAssayTarget, []);
            }
        },

        onValidateClick: function () {
            $('.js-global-loader').show();
            this.model.validationWells(this.wellSelected, this.currentAssayTarget)
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onValidate2Click: function () {
            $('.js-global-loader').show();
            this.model.validation2Wells(this.wellSelected, this.currentAssayTarget)
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onValidate12Click: function () {
            $('.js-global-loader').show();
            this.model.validation12Wells(this.wellSelected, this.currentAssayTarget)
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onValViewRepeatPcr: function () {
            $('.js-global-loader').show();
            this.model.repeatPCRWells(this.wellSelected)
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onValViewRepeatPcrControl: function () {
            $('.js-global-loader').show();
            this.model.repeatPCRControlWells(this.wellSelected)
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onValViewRepeatExtraction: function () {
            $('.js-global-loader').show();
            this.model.repeatExtractionWells(this.wellSelected, $('#valViewDillution').val())
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onValViewRepeatExtractionControl: function () {
            $('.js-global-loader').show();
            this.model.repeatExtractionControlWells(this.wellSelected, $('#valViewDillutionControl').val())
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onConfirmResultClick: function () {
            $('.js-global-loader').show();
            this.model.confirmWells(this.wellSelected, this.currentAssayTarget)
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onChangeToNegClick: function () {
            $('.js-global-loader').show();
            this.model.negativeWells(this.wellSelected, this.currentAssayTarget)
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onResetFilter: function () {
            this.options.controller.setFiltersList([]);
            this.onWellFilter(this.currentAssayTarget, []);
        },
        onAssayVersionClick: function (e) {
            require(['assayVersionController'], _.bind(function (AssayVersionController) {
                var assayVersionSecId = e.currentTarget.attributes['data-field-secid'].value;
                var firstWell = this.model.get('wells').chain().filter(_.bind(function (well) {
                    return well.get('assayVersion') && well.get('assayVersion').get('secId') === assayVersionSecId;
                }, this)).first().value();
                firstWell.getPcrKitLots().done(_.bind(function (pcrKitLots) {
                    AssayVersionController.showDetails(firstWell.get('assayVersion'), null, {lots: pcrKitLots});
                }, this));
            }, this));
        }
    });
});
