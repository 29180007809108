
define('template!pcrSetupPcrRunCollectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12" style="display: flex; flex-direction: row; justify-content: space-between;">\n    <span class="mdi mdi-sort-ascending clickable" title="' +
((__t = ( _.i18n('pcrSetup.orderBy.period.asc') )) == null ? '' : __t) +
'"></span>\n    <h1>' +
((__t = ( _.i18n('pcrSetup.orderBy.pcrRun') )) == null ? '' : __t) +
'</h1>\n    <span class="mdi mdi-sort-descending clickable" title="' +
((__t = ( _.i18n('pcrSetup.orderBy.period.desc') )) == null ? '' : __t) +
'"></span>\n</div>\n<div class="col-xs-12 js-container p-0"></div>';

}
return __p
};});

