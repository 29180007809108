
define('template!runTabsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="with-header">\n    <div class="tab-layout-main-header-region">\n    </div>\n</div>\n\n<div class="tab-layout-content js-tabs-region">\n</div>\n';

}
return __p
};});

