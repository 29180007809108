define('runPcrKitsLotsView',[
    'module',
    'dialogFormView',
    'template!runPcrKitsLotsTpl',
    'savingBehavior',
    'underscore',
    'jquery'
], function (
    module,
    DialogFormView,
    runPcrKitsLotsTpl,
    SavingBehavior,
    _,
    $
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: runPcrKitsLotsTpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'click .js-cancel-popup': 'hide',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm'
        },

        regions: {},

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [],

        onShow: function () {
            $.material.init();
            this.triggerMethod('enable:cancel:confirm');
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.kitLots = this.options.kitLots ? this.options.kitLots : [];
            return templateData;
        },

        isAlreadyInAssaysList: function (list, well) {
            var result = false;
            _.each(list, _.bind(function (assay) {
                if (assay.secId === well.refAssay.secId) {
                    result = true;
                }
            }, this));
            return result;
        },

        onConfirm: function () {
            this.triggerMethod('on:print:runSheet');
            this.box.modal('hide');
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        save: function () {
        },

        onSave: function () {
        },

        hide: function () {
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        },

        onSaveError: function () {
        }
    });
});
