
define('template!sampleResultIconView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="width: 100%;height: 100%;display: flex;align-content: center;flex-wrap: wrap;">\n    <div class="containerIconResult">\n        <div class="iconResult">\n            <div class="circle"\n                 style="background-color: ' +
((__t = ( color )) == null ? '' : __t) +
'; ' +
((__t = ( isResult )) == null ? '' : __t) +
'"></div>\n            ';
 if (exported) { ;
__p += '\n            <span class="mdi mdi-check"></span>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>';

}
return __p
};});

