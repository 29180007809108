
define('template!superAdminDBDocumentationDetailTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container m-l-20 m-t-20" style="height:100%;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal"  style="height:100%;">\n        <div class="form-inputs-container" style="height:100%;">\n            <div class="row">\n                <div class="col-xs-2 dataLabelContainer">\n                    <div class="form-group" data-field-name="internalName">\n                        <label class="control-label dataLabel" >' +
((__t = ( _.i18n('dbdocumentation.internalName') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-2" style="display: flex;">\n                    <div class="form-group" data-field-name="internalName" style="margin:auto;">\n                        <input type="text"\n                               class="form-control floating-label js-info-input js-custom-input dataInput250 inputBackground"\n                               data-field-name="internalName" placeholder="" value="' +
((__t = ( internalName )) == null ? '' : __t) +
'"\n                               data-placement="top" autocomplete="off" ' +
((__t = ( analysis? '' : 'disabled' )) == null ? '' : __t) +
' >\n                    </div>\n                </div>\n                <div class="col-xs-3" style="display: flex;">\n                    <div class="form-group" data-field-name="name" style="margin:auto;">\n                        <input type="text"\n                               class="form-control floating-label js-info-input js-custom-input dataInput400 inputBackground"\n                               data-field-name="name" placeholder="" value="' +
((__t = ( name )) == null ? '' : __t) +
'"\n                               data-placement="top" autocomplete="off">\n                    </div>\n                </div>\n                <div class="col-xs-1 dataLabelContainer">\n                    <div class="form-group" data-field-name="description">\n                        <label class="control-label dataLabel" >' +
((__t = ( _.i18n('common.analysis') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-2" style="display: flex;margin-left:28px;">\n                    <div class="form-group">\n                        <input data-field-name="analysis" style="width: 20px; height: 20px; margin: 0;"\n                               type="checkbox"\n                               class="js-info-input js-custom-input" ' +
((__t = ( analysis? 'checked' : '' )) == null ? '' : __t) +
' disabled>\n                    </div>\n                </div>\n            </div>\n\n            <div class="row">\n                <div class="col-xs-2 dataLabelContainer">\n                    <div class="form-group" data-field-name="description">\n                        <label class="control-label dataLabel" >' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-8 ">\n                    <div style="padding-left: 15px;">\n                      <textarea class="form-control floating-label js-info-input js-custom-input inputBackground"\n                                rows="3" wrap="on" data-field-name="description"\n                                placeholder="" style="resize: vertical;">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n                    </div>\n                </div>\n            </div>\n            <button class="js-add-docurl btn btn-info">' +
((__t = ( _.i18n('codelist.addDocUrl') )) == null ? '' : __t) +
'</button>\n            <div class="row col-xs-12 js-docurl-pane" style="display: none;">\n                <div class="col-xs-4">\n                    ' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-7">\n                    ' +
((__t = ( _.i18n('common.abstract') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1">\n                </div>\n            </div>\n            <div class="docUrlsTable js-docurl-pane" style="height:8%;overflow-y: auto;overflow-x: hidden;width:100%;display: none;">></div>\n            <div class="row"></div>\n            ';
 if (analysis == true){ ;
__p += '\n            <button class="js-add-field-element btn btn-info">' +
((__t = ( _.i18n('jsondoc.addfield') )) == null ? '' : __t) +
'</button>\n            ';
 } ;
__p += '\n            <div class="m-t-20 m-b-20 " style=";display:flex;font-weight: bold;">\n                <div style="width: 10.6%;">' +
((__t = ( _.i18n('jsondoc.internalName'))) == null ? '' : __t) +
'</div>\n                <div style="width: 2.1%;">' +
((__t = ( _.i18n('jsondoc.seq'))) == null ? '' : __t) +
'</div>\n                <div style="width: 14.4%;">' +
((__t = ( _.i18n('jsondoc.name'))) == null ? '' : __t) +
'</div>\n                <div style="width: 2.2%;">' +
((__t = ( _.i18n('jsondoc.dataType'))) == null ? '' : __t) +
'</div>\n       c         <div style="width: 2.7%;">' +
((__t = ( _.i18n('jsondoc.length'))) == null ? '' : __t) +
'</div>\n                <div style="width: 9%;margin-left: 0.5%;">' +
((__t = ( _.i18n('jsondoc.relation'))) == null ? '' : __t) +
'</div>\n                <div style="width: 28.7%;margin-left: 0.5%;">' +
((__t = ( _.i18n('jsondoc.description'))) == null ? '' : __t) +
'</div>\n                <div>' +
((__t = ( _.i18n('jsondoc.example'))) == null ? '' : __t) +
'</div>\n            </div>\n            <div id="columns" class="m-t-20 m-b-20" style="height:42%;overflow-y: auto;overflow-x: hidden;width:100%;"></div>\n            <div  class="m-t-20 m-b-20"></div>\n            <div class="row cancelConfirmRow">\n                <div class="pull-right p-r-15 col-xs-12 cancelConfirmDiv" style="padding-left: 80%;position: absolute;bottom:65px;">\n                    <button class="mdi-navigation-close btn popupBtn-cancel  cancel-b js-cancel-popup" style="float: left;"></button>\n                    <button class="mdi-navigation-check btn popupBtn-confirm  js-confirm"></button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

