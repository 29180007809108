
define('template!runChooseDownloadTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="form-group">\n                <button class="btn add-btn-text btn-sm run-export-folder">\n                    <i class="mdi mdi-plus-circle"></i>\n                    <span>' +
((__t = ( _.i18n('run.export.folder.local') )) == null ? '' : __t) +
'</span>\n                </button>\n                ';
 if(exportSetting) { ;
__p += '\n                <button class="btn add-btn-text btn-sm run-export-remote-folder">\n                    <span class="mdi mdi-plus-circle"></span>\n                    <span>' +
((__t = ( _.i18n('run.export.folder.remote') )) == null ? '' : __t) +
'</span>\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class="row">\n            <div class="pull-right p-r-15">\n                <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'\n                </button>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

