/**
 * Created by OBL on 14/01/2016.
 */
define('runController',[
    'module',
    'underscore',
    'jquery',
    'generateSampleId'
], function (
    module,
    _,
    $,
    GenerateSampleId
) {
    'use strict';

    module.exports = {
        showGenerateSampleId: function (model, callback) {
            var view = new GenerateSampleId({
                    plateFillDirection: model.get('settings')['LAB-SETTINGS'] ? model.get('settings')['LAB-SETTINGS'] : 'C',
                    plateSize: model.get('plateSize')
                }
            );
            view.show({
                title: _.i18n('run.generateSampleId.title'),
                className: 'actionPopupCss popupFitContent actionMiniPopupCss'
            }, _.bind(function () {
                callback();
            }, this), _.bind(function () {
                callback();
            }, this));
            view.listenTo(view, 'confirm', _.bind(function (sampleIds) {
                $('.js-global-loader').show();
                model.generateSampleId(sampleIds).always(function () {
                    $('.js-global-loader').hide();
                });
            }, this));
        }
    };
});
