define('prepRunDetailsTabView',[
    'module',
    'marionette',
    'template!prepRunDetailsTabTpl',
    'underscore',
    'dialogFormView'
], function (
    module,
    Marionette,
    detailsTabTpl,
    _,
    DialogFormView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: detailsTabTpl,

        regions: {
            rightView: '.right-view',
            wellTable: '.well-table'
        }
    });
});
