define('menuElementCollectionView',['module',
		'backbone',
		'marionette',
		'menuElementItemCollectionView',
		'underscore'
	],
	function (module,
			  Backbone,
			  Marionette,
			  MenuElementItemCollectionView,
			  _
	) {
		'use strict';

		var MenuElementCollection = Marionette.CollectionView.extend({

			childView: MenuElementItemCollectionView,
			collection: new Backbone.Collection(),
			childEvents: {
				'item:delete': function (view, model) {
					this.collection.remove(model);
					this.trigger('hide-select-zone');
				},
				'select-zone': function (view) {
					this.trigger('select-zone',view);
				}
			},

			collectionFieldsValidate: function () {
				var groupedByCount = _.countBy(this.children._views, function (view) {
					return view.model.get('code');
				});
				var codeErrors = [];
				for (var code in groupedByCount) {
					if (groupedByCount[code] > 1) {
						codeErrors.push('{"field":"code","value":"' + code + '"}');
					}
				}
				return codeErrors.length === 0;
			}
		});
		module.exports = MenuElementCollection;
	});
