define('dashboardPrepThumbnailView',[
	'module',
	'marionette',
	'dialogFormView',
	'template!dashboardPrepThumbnailTpl',
	'underscore',
    'jquery',
	'app',
	'dashboardPrepThumbnailCardView',
	'bootbox',
	'autocompleteView',
	'entities/caccounts/userCodelist',
	'entities/caccounts/prepruns'
], function (
	module,
	Marionette,
	DialogFormView,
	PrepThumbnailTpl,
	_,
    $,
	App,
	CardView,
	bootbox,
	AutocompleteView,
	UserCodeLists
) {
	'use strict';


	module.exports = DialogFormView.extend({
		template: PrepThumbnailTpl,

		ui: {
			blockLoading: '.empty-page-loader',
			filters: '.js-tab-filters',
			selectFilter: '.js-select-filter',
			resetFilters: '.js-reset-filters'
		},

		events: {
			'click @ui.selectFilter': 'onSelectFilters',
			'click @ui.resetFilters': 'onResetFilters'
		},

		regions: {
			selectRunDepartment: '.js-department-region',
			selectRunGroup: '.js-runGroup-region'
		},

		serializeData: function () {
			var templateData = {};
			templateData.runsList = this.runsList;
			return templateData;
		},

		onShow: function () {
			$.material.init();
			this.showSelectDepartmentView();
			this.showSelectRunGroupView();
			this.showCards();
			this.filteredRuns = [];
			this.runFiltersList = [];
			this.filteredRunsCollection = [];
			this.processedRunsResult = [];
		},

		initialize: function (options) {
			this.runsList = options.runsList;
			Marionette.LayoutView.prototype.initialize.call(this, options);
		},

		onDepartmentFilterChange: function (fieldName, model) {
			this[fieldName] = null;
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this[fieldName] = model.get('code');
			}
			var filterName = 'select-department';
			this.onSelectFilters(null, filterName);
		},

		onRunGroupFilterChange: function (fieldName, model) {
			this[fieldName] = null;
			if (model) {
				model.unset('autocompleteValue', {silent: true});
				this[fieldName] = model.get('code');
			}
			var filterName = 'select-runGroup';
			this.onSelectFilters(null, filterName);
		},

		onSelectFilters: function (e, filterName) {
			var selectedRunFilter = null;
			if (e) {
				var $target = $(e.currentTarget);
				selectedRunFilter = $target.attr('name');
			} else if (filterName) {
				selectedRunFilter = filterName;
			}

			if (selectedRunFilter === 'select-sample') { //selectedRunFilter === 'select-assay' || selectedRunFilter === 'select-ic'
				this.onSelectSearchFilters(e, selectedRunFilter);
			} else {
				this.onRunsFiltersListChange(selectedRunFilter); //updates this.runFiltersList
				this.onShowActiveFilters(this.runFiltersList);
				if (this.runFiltersList.length > 0) {
					this.filteredRuns = this.getFilteredRunsCollection(); // updates and returns this.filteredRunsCollection
				} else {
					this.filteredRuns = null;
				}
				this.refreshCardsDisplay();
			}
		},

		onResetFilters: function () {
			this.runFiltersList = [];
			this.filteredRuns = null;
			this.resetDropDownFilters();
			this.onShowActiveFilters(this.runFiltersList);
			this.refreshCardsDisplay();
		},

		resetDropDownFilters: function () {
			if (this.selectDepartmentView) {
				this.selectDepartmentView.destroy();
			}
			if (this.selectRunGroupView) {
				this.selectRunGroupView.destroy();
			}
			this.showSelectDepartmentView();
			this.showSelectRunGroupView();
		},

		showSelectDepartmentView: function () {
			this.selectDepartmentView = new AutocompleteView(
				this._getAutocompleteOptionObject(
				UserCodeLists.getAutocompleteParam({
					modelProperty: 'department',
					codeListCode: 'ALGO-AGDEPT',
					callBackOnChange: this.onDepartmentFilterChange
				})));
			this.getRegion('selectRunDepartment').show(this.selectDepartmentView);
		},

		showSelectRunGroupView: function () {
			this.selectRunGroupView = new AutocompleteView(
				this._getAutocompleteOptionObject(
				UserCodeLists.getAutocompleteParam({
					modelProperty: 'runGroup',
					codeListCode: 'RUNGROUP_PREP',
					callBackOnChange: this.onRunGroupFilterChange
				})));
			this.getRegion('selectRunGroup').show(this.selectRunGroupView);
		},

		getFilteredRunsCollection: function () {
			var first = true;
			var filteredArray = [];

			_.each(this.runFiltersList, _.bind(function (runFilter) {
				var runsFromFilter = [];
				runsFromFilter = this.getRunsFromFilter(runFilter);

				if (filteredArray && filteredArray.length < 1 && first) {
					filteredArray = runsFromFilter;
					first = false;
				} else if (filteredArray && filteredArray.length > 0 && runsFromFilter && runsFromFilter.length > 0) {
					filteredArray = filteredArray.filter(function (n) {
						return runsFromFilter.indexOf(n) !== -1;
					});
				} else if (runsFromFilter === null) {
					filteredArray = [];
				}

			}, this));
			this.filteredRunsCollection = filteredArray;
			return this.filteredRunsCollection;
		},

		onShowActiveFilters: function (filtersName) {
			// unset all filter checkbox
			this.ui.filters.each(function (index, tab) {
				var $tab = $(tab);
				$tab.removeClass('activeFilter');
				$tab.find('input').prop('checked', false);
			});

			// set selected from list (this.runFiltersList) checkbox to active
			_.each(filtersName, _.bind(function (f) {
				this.ui.filters.each(function (index, tab) {
					var $tab = $(tab);
					if ($tab.hasClass('js-' + f)) {
						$tab.addClass('activeFilter');
						$tab.find('input').prop('checked', true);
					}
				});
			}, this));
		},

		getRunsFromFilter: function (runFilter) { //todo
			var filteredRuns = [];
			var errorMessage = '';

			switch (runFilter) {
				case 'select-department':
					_.each(this.processedRunsResult, _.bind(function (run) {
						if (run.get('department') && run.get('department') === this.department) {
							filteredRuns.push(run);
						}
					}, this));
					errorMessage = _.i18n('error.noDepartmentMatch');
					break;
				case 'select-runGroup':
					_.each(this.processedRunsResult, _.bind(function (run) {
						if (run.get('group') && run.get('group') === this.runGroup) {
							filteredRuns.push(run);
						}
					}, this));
					errorMessage = _.i18n('error.noRunGroupMatch');
					break;
				case 'select-waitingFor-plateLayoutFilling':
					_.each(this.processedRunsResult, function (run) {
						if (run.get('status') && run.get('status') < 2) {
							filteredRuns.push(run);
						}
					});
					errorMessage = _.i18n('error.noWaitingPlateSettings');
					break;
				case 'select-waitingFor-extractionSetup':
					_.each(this.processedRunsResult, function (run) {
						if (run.get('status') && run.get('status') === 2) {
							filteredRuns.push(run);
						}
					});
					errorMessage = _.i18n('error.noWaitingExtractionSetup');
					break;
				case 'select-extraction-running':
					_.each(this.processedRunsResult, function (run) {
						if (run.get('status') && run.get('status') === 3) {
							filteredRuns.push(run);
						}
					});
					errorMessage = _.i18n('error.noWaitingExtractionRunning');
					break;
			}
			if (filteredRuns.length > 0) {
				return filteredRuns;
			} else {
				return null;
			}
		},

		onSelectSearch: function (result, e, selectedRunFilter) {
			this.onRunsFiltersListChange(selectedRunFilter);
			this.onSearchChange(result, selectedRunFilter);
			this.onShowActiveFilters(this.runFiltersList);
			if (this.runFiltersList.length > 0) {
				this.filteredRuns = this.getFilteredRunsCollection(); // updates and returns this.filteredRunsCollection
			} else {
				this.filteredRuns = null;
			}
			this.refreshCardsDisplay();
		},

		onSearchChange: function (result, selectedRunFilter) {
			switch (selectedRunFilter) {
				case 'select-sample': // 'select-sample' 'select-ic'
					this.sampleSearch = result && result !== '' ? result : null;
					break;
				default:
					break;
			}
		},

		onSelectSearchFilters: function (e, selectedRunFilter) {
			var $target = $(e.currentTarget);
			if (!$target.hasClass('activeFilter')) {
				bootbox.prompt({
					title: _.i18n('popup.select.search'),
					className: 'actionPopupCss popupFitContent headerAction-bootboxPopup',
					onEscape: true,
					buttons: {
						confirm: {
							label: '<i></i>',
							className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm btn-primary'
						},
						cancel: {
							label: '<i></i>',
							className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
						}
					},
					callback: _.bind(function (result) {
						if (result) {
							this.onSelectSearch(result, e, selectedRunFilter);
						}
						bootbox.hideAll();
					}, this)
				});
			} else {
				this.onRunsFiltersListChange(selectedRunFilter); //updates this.runFiltersList
				this.onShowActiveFilters(this.runFiltersList);
				if (this.runFiltersList.length > 0) {
					this.filteredRuns = this.getFilteredRunsCollection(); // updates and returns this.filteredRunsCollection
				} else {
					this.filteredRuns = null;
				}
				this.refreshCardsDisplay();
			}
		},

		onRunsFiltersListChange: function (selectedRunFilter) {
			if (selectedRunFilter === 'select-department') {
				if (this.department && !this.runFiltersList.includes(selectedRunFilter)) {
					this.runFiltersList.push(selectedRunFilter);
				} else {
					if (this.runFiltersList.includes(selectedRunFilter)) {
						var d = this.runFiltersList.indexOf(selectedRunFilter);
						this.runFiltersList.splice(d, 1);
					}
				}
			} else if (selectedRunFilter === 'select-runGroup') {
				if (this.runGroup && !this.runFiltersList.includes(selectedRunFilter)) {
					this.runFiltersList.push(selectedRunFilter);
				} else {
					if (this.runFiltersList.includes(selectedRunFilter)) {
						var r = this.runFiltersList.indexOf(selectedRunFilter);
						this.runFiltersList.splice(r, 1);
					}
				}
			} else {
				if (this.runFiltersList.includes(selectedRunFilter)) {
					var i = this.runFiltersList.indexOf(selectedRunFilter);
					this.runFiltersList.splice(i, 1);
				} else {
					this.runFiltersList.push(selectedRunFilter);
				}
			}
		},

		setOpacity: function (secId, opacity) {
			$('.run-prep-' + secId).css('opacity', opacity);
		},

		setClickable: function (secId, isClickable) {
			if (isClickable) {
				if (!$('.run-prep-' + secId).hasClass('clickable')) {
					$('.run-prep-' + secId).addClass('clickable');
				}
			} else {
				if ($('.run-prep-' + secId).hasClass('clickable')) {
					$('.run-prep-' + secId).removeClass('clickable');
				}
			}
		},

		refreshCardsDisplay: function () {
			if ((this.runFiltersList && this.runFiltersList.length > 0)) {
				if (this.filteredRuns && this.filteredRuns.length > 0) {
					_.each(this.processedRunsResult, _.bind(function (run) {
						if (this.filteredRuns.indexOf(run) !== -1) {
							this.setOpacity(run.get('secId'), 1);
							this.setClickable(run.get('secId'), true);
						} else {
							this.setOpacity(run.get('secId'), 0.3);
							this.setClickable(run.get('secId'), false);
						}
					}, this));
				} else {
					_.each(this.processedRunsResult, _.bind(function (run) {
						this.setOpacity(run.get('secId'), 0.3);
						this.setClickable(run.get('secId'), false);
					}, this));
				}
			} else {
				_.each(this.processedRunsResult, _.bind(function (run) {
					this.setOpacity(run.get('secId'), 1);
					this.setClickable(run.get('secId'), true);
				}, this));
			}
			if (this.filteredRuns) {
				var selection = this.getArrayToJsSon(this.filteredRuns);
				this.updatesHeaderRunsList(selection);
			}
		},

		getArrayToJsSon: function (array) {
			var result = [];
			if (array && array.length > 0) {
				for (var i = 0; i < array.length; i++) {
					result.push(array[i].toJSON());
				}
			}
			return result;
		},

		updatesHeaderRunsList: function (runsList) {
			require([
				'prepRunsShowController'
			], _.bind(function (prepRunsShowController) {
				prepRunsShowController.onSelectedPrepRunsList(runsList);
			}, this));
		},

		showCards: function () {
			this.getRunsToDisplay(this.runsList).done(_.bind(function () { // updates this.processedRunsResult

				var selection = this.getArrayToJsSon(this.processedRunsResult);
				this.updatesHeaderRunsList(selection);

				var filtersToAllow = {};
				filtersToAllow.selectWaitingForPlateLayoutFilling = {
					selector: 'js-select-waitingFor-plateLayoutFilling',
					show: false
				};
				filtersToAllow.selectExtractionRunning = {selector: 'js-select-extraction-running', show: false};
				filtersToAllow.selectWaitingForExtractionSetup = {
					selector: 'js-select-waitingFor-extractionSetup',
					show: false
				};

				_.each(this.processedRunsResult, _.bind(function (run) {
					var cardView = new CardView({model: run});
					var runName = run.get('name');
					var secId = run.get('secId');
					$('.prep-runs-cards').append('<div class="js-runCard-click css-runCards clickable run-prep-' + secId + '" ' +
						'style="" ' + 'data-secid="' + secId + '" ' +
						'data-name="' + runName + '"></div>');
					this.addRegion('run-prep-' + secId, '.run-prep-' + secId);
					this.getRegion('run-prep-' + secId).show(cardView);

					if (run.get('status') && run.get('status') < 2) {
						filtersToAllow.selectWaitingForPlateLayoutFilling.show = true;
					}
					if (run.get('status') && run.get('status') === 2) {
						filtersToAllow.selectWaitingForExtractionSetup.show = true;
					}
					if (run.get('status') && run.get('status') === 3) {
						filtersToAllow.selectExtractionRunning.show = true;
					}

				}, this));
				this.allowFilters(filtersToAllow);
			}, this));
		},

		allowFilters: function (filtersToAllow) {
			_.each(filtersToAllow, _.bind(function (f) {
				var filterComponent = $('.headerFilterButton.' + f.selector + '.js-tab-filters.js-select-filter');
				if (f.show) {
					this.displayFilterComponent(filterComponent, true);
				} else {
					this.displayFilterComponent(filterComponent, false);
				}
			}, this));
		},

		displayFilterComponent: function (filterComponent, display) {
			filterComponent.css('opacity', display ? 1 : 0.3);
			if (display) {
				filterComponent.addClass('js-select-filter');
				filterComponent.addClass('clickable');
			} else {
				filterComponent.removeClass('js-select-filter');
				filterComponent.removeClass('clickable');
			}
			filterComponent.find('input[type="checkbox"]').css('opacity', display ? 1 : 0);
		},

		getRunsToDisplay: function (runsList) {
			var defer = $.Deferred();
			var lst = [];
			_.each(runsList, _.bind(function (secId) {
				var model = App.request('run:prep:model', {secId: secId});
				lst.push({run: model});
			}, this));
			this.process(lst, defer);
			return defer.promise();
		},

		process: function (lst, defer) {
			if (lst.length !== 0) {
				var obj = lst.pop();
				obj.run.fetch().done(_.bind(function () {
					this.processedRunsResult.push(obj.run);
					this.process(lst, defer);
				}, this));
			} else {
				defer.resolve();
			}
		}
	});
});
