define('wellResultIconView',[
    'module',
    'marionette',
    'underscore',
    'template!wellResultIconView'
], function (
    module,
    Marionette,
    _,
    Tpl
) {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: Tpl,

        attributes: function () {
            return {
                style: 'width: 100%;height: 100%;display: flex;align-content: center;flex-wrap: wrap;' + (this.options.width ? 'flex-basis: ' + this.options.width + ';' : '')
            };
        },
        modelEvents: {
            'change': 'renderSafe'
        },

        serializeData: function () {
            var templateData = {
                isResult: this.model.get('result') && this.model.get('result').get('code') ? 'border: grey solid 1px;' : '',
                color: this.model.get('result') ? this.model.get('result').get('color') : 'transparent',
                resCodeErr: this.model.get('codeErr'),
                resCommentErrAuto: this.model.get('commentErrAuto'),
                resComment: this.model.get('comment'),
                valSt: this.model.get('valSt'),
                repeatSt: this.model.get('refPcrWell').get('repeatStatus'),
                ic: this.model.get('internalControl'),
                displayCt: this.options.displayCt,
                cts: this.model.get('cts'),
                quantifications: this.model.get('quantifications'),
                isOVAR: this.model.get('refAssayResult').get('type') === 'CALCULATED'
            };
            return templateData;
        },

        renderSafe: function () {
            if (!this.isDestroyed) {
                this.render();
            }
        }
    });
});
