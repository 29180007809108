define('entities/caccounts/colorCompensationMatrices',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'dynamic',
    'module',
    'entities/caccounts/pcrruns',
    'entities/caccounts/lmbcyclers',
    'entities/caccounts/colorCompensationMatriceValues'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    Dynamic,
    module,
    PcrRuns,
    LmbCyclers
) {
    'use strict';


    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.ColorCompensationMatrice = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/colorCompensations/' + this.get('colorCompensation').get('secId') + '/matrices/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'current': false,
            'code': null,
            'pcrRun': null,
            'status': null,
            'statusDate': null,
            'values': [],
            'valuesRemoved': [],
            'lmbCycler': null,
            createdDate: new Date(),
            show: false
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'pcrRun',
            relatedModel: 'PcrRun',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'values',
            relatedModel: 'ColorCompensationMatriceValue',
            collectionType: 'ColorCompensationMatriceValueCollection',
            reverseRelation: {
                key: 'colorCompensationMatrice',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'valuesRemoved',
            relatedModel: 'ColorCompensationMatriceValue',
            collectionType: 'ColorCompensationMatriceValueCollection'
        }, {
            type: Backbone.HasOne,
            key: 'lmbCycler',
            relatedModel: 'LmbCycler'
        }],
        getImageUid: function () {
        },
        postUrl: function () {
            return Settings.url('compuzz', 'v2/colorCompensations/' + this.get('colorCompensation').get('secId') + '/matrices/' + this.get('secId'));
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ColorCompensationMatriceJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ColorCompensationMatrice',
        getEntity: function () {
            return module.exports;
        },
        reset: function (x, y) {
            var url = Settings.url('compuzz', 'v2/colorCompensations/' + this.get('colorCompensation').get('secId') + '/matrices/' + this.get('secId') + '/reset', {
                    x: x,
                    y: y
                }),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        maxValue: function (max) {
            var valueToRemove = this.get('values').filter(function (value) {
                return value.get('x') > max || value.get('y') > max;
            });
            this.get('values').remove(valueToRemove);
            this.get('valuesRemoved').add(valueToRemove);

            for (var x = 0; x <= max; x++) {
                for (var y = 0; y <= max; y++) {
                    var value = this.get('values').findWhere({x: x, y: y});
                    if (!value) {
                        value = this.get('valuesRemoved').findWhere({x: x, y: y});
                        if (!value) {
                            value = new app.ns.ColorCompensationMatriceValue({
                                x: x,
                                y: y,
                                value: x === y ? 100 : 0,
                                manualCorrection: 0,
                                colorCompensationMatrice: this
                            });
                        }
                        this.get('values').add(value);
                    }
                }
            }
        }
    });

    app.ns.ColorCompensationMatriceCollection = Backbone.Collection.extend({
        model: app.ns.ColorCompensationMatrice,
        comparator: function (item1, item2) {
            var str1 = item1.get('lmbCycler') ? item1.get('lmbCycler').get('code') : '';
            var str2 = item2.get('lmbCycler') ? item2.get('lmbCycler').get('code') : '';

            var ret = str1.localeCompare(str2);
            if (ret === 0) {
                return item2.get('createdDate') - item1.get('createdDate');
            }
            return ret;
        }
    });

    module.exports = _.defaults({
        getName: function () {
            return 'ColorCompensationMatrice';
        },
        getDynamicJson: function () {
            return 'ColorCompensationMatriceJson';
        },
        getCreateEditLine: function (model) {
            return [
                {
                    'field': 'pcrRun',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {'getAutocompleteParam': PcrRuns.getAutocompleteParamForColorCompensation},
                        'context': PcrRuns,
                        'display': {'class': 'col-w-20 p-l-5'},
                        onChange: function (fieldName, modelFieldName, model) {
                            var data = {lmbCycler: null, createdDate: new Date()};
                            if (modelFieldName !== null) {
                                data[fieldName] = modelFieldName.toJSON();
                                data.lmbCycler = model.getRelation('lmbCycler').relatedModel.findOrCreate(data[fieldName].refLmbCycler);
                                data.createdDate = data[fieldName].creatDate;
                            }
                            model.set(data, {silent: true});
                            model.trigger('change:lmbCycler');
                            model.trigger('change:createdDate');
                        }
                    }
                },
                {
                    'field': 'createdDate',
                    'param': {'type': 'DATE', 'display': {'class': 'col-w-20 p-l-5 p-r-5', 'readonly': true}}
                },
                {
                    'field': 'lmbCycler',
                    'param': {
                        'type': 'REFERENCE',
                        'display': {'class': 'col-xs-3'},
                        'config': {
                            'getAutocompleteParam': LmbCyclers.getAutocompleteParamWithColorCompensation
                        },
                        'context': LmbCyclers
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'pcrRun',
                        'allowEmpty': true,
                        clearValue: false,
                        'readOnly': function (model) {
                            return !!model.get('pcrRun');
                        },
                        disableEvent: true
                    }
                },
                {
                    'field': 'status',
                    'param': {
                        'type': 'SELECT',
                        'display': {'class': 'col-w-20 p-l-5 p-r-5'},
                        'config': {
                            'values': [
                                {'key': 'UNKNOWN', 'value': _.i18n('status.unknown')},
                                {
                                    'key': 'VALID',
                                    'value': _.i18n('status.valid'),
                                    'display': {'style': 'background-color: #0080008c'}
                                },
                                {
                                    'key': 'INVALID',
                                    'value': _.i18n('status.invalid'),
                                    'display': {'style': 'background-color: #ff00008c'}
                                },
                                {
                                    'key': 'DEFAULT',
                                    'value': _.i18n('status.default'),
                                    'display': {'style': 'background-color: #0000ff8c'}
                                },
                                {
                                    'key': 'ARCHIVED',
                                    'value': _.i18n('status.archived'),
                                    'display': {'style': 'background-color: #ffa5008c'}
                                }
                            ]
                        }
                    }
                },
                {
                    'field': 'show',
                    'param': {
                        'type': 'POPUP',
                        'config': this.showDetails,
                        'display': {'class': 'col-w-5 p-r-2 p-l-2', 'mdi': 'mdi-eye'}
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'values',
                        allowEmpty: true,
                        enable: function (model) {
                            return !model.isNew();
                        }
                    }
                },
                {
                    'field': 'recalc',
                    'param': {
                        'type': 'POPUP',
                        'config': this.reset,
                        'display': {'class': 'col-w-5 p-r-2 p-l-2', 'mdi': 'mdi-refresh'}
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'pcrRun'
                    }
                }
            ];
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.ColorCompensationMatrice.findOrCreate(modelJson);
        },
        showDetails: function (param) {
            require(['colorCompensationController'], function (ColorCompensationController) {
                ColorCompensationController.showMatrice(param.model);
            });
        },
        reset: function (param) {
            $('.js-global-loader').show();
            param.model.reset().always(function () {
                $('.js-global-loader').hide();
            });
        }
    }, Dynamic);
});
