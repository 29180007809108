
define('template!wellPopupDisplayGraphView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container popup-graphs">\n            <div class="form-group">\n                <div class="row hidden-print m-b-0">\n\n                    <!-- lbl-assay -->\n                    <div class="col-xs-12 m-b-0">\n                        <div class="col-xs-2 p-l-0">\n                            <label>' +
((__t = ( _.i18n('run.run') )) == null ? '' : __t) +
'</label>\n                        </div>\n\n                        <div class="col-xs-2 p-l-0">\n                            <label>' +
((__t = ( _.i18n('assay.assay') )) == null ? '' : __t) +
'</label>\n                        </div>\n\n                        <div class="col-xs-1 p-l-0">\n                            <label>' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n                        </div>\n\n                        <div class="col-xs-2 p-l-0">\n                            <label>' +
((__t = ( _.i18n('common.target') )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="col-xs-5 p-l-0">\n                            <label>' +
((__t = ( _.i18n('common.result') )) == null ? '' : __t) +
'</label>\n                        </div>\n                    </div>\n\n                    <div class="col-xs-12 m-b-0">\n                        <div class="col-xs-2 p-l-0">\n                            ' +
((__t = ( runNameToDisplay )) == null ? '' : __t) +
'\n                        </div>\n\n                        <div class="col-xs-2 p-l-0">\n                            ' +
((__t = ( assayToDisplay )) == null ? '' : __t) +
'\n                        </div>\n\n                        <div class="col-xs-1 p-l-0">\n                            ' +
((__t = ( wellPosToDisplay )) == null ? '' : __t) +
'\n                        </div>\n\n                        <div class="col-xs-2 p-l-0">\n                            ' +
((__t = ( targetToDisplay )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-5 p-l-0">\n                            ' +
((__t = ( iconHtml )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n\n                    <div class="col-xs-6">\n                        <div class="row m-b-0" style="display: none;">\n                            <div class="col-xs-2 p-t-3" style="font-weight: bold;">\n                                ' +
((__t = ( _.i18n('graph.validation') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="col-xs-2">\n                                <div class="row m-b-0">\n                                    <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0"\n                                           style="font-weight: normal;">' +
((__t = ( _.i18n('graph.res.all') )) == null ? '' : __t) +
'</label>\n                                    <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                        <label>\n                                            <input type="checkbox" id="validation-all" data-value="all"\n                                                   class="cbx-validation js-keep-logged-in">\n                                        </label>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="col-xs-2">\n                                <div class="row m-b-0">\n                                    <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0"\n                                           style="font-weight: normal;">' +
((__t = ( _.i18n('graph.validation.tovalidate')
                                        )) == null ? '' : __t) +
'</label>\n                                    <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                        <label>\n                                            <input type="checkbox" id="validation-tovalidate" data-value="1"\n                                                   class="cbx-validation js-keep-logged-in">\n                                        </label>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="col-xs-2">\n                                <div class="row m-b-0">\n                                    <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0"\n                                           style="font-weight: normal;">' +
((__t = ( _.i18n('graph.validation.expert') )) == null ? '' : __t) +
'</label>\n                                    <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                        <label>\n                                            <input type="checkbox" id="validation-expert" data-value="2"\n                                                   class="cbx-validation js-keep-logged-in">\n                                        </label>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="col-xs-2">\n                                <div class="row m-b-0">\n                                    <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0"\n                                           style="font-weight: normal;">' +
((__t = ( _.i18n('graph.res.validated') )) == null ? '' : __t) +
'</label>\n                                    <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                        <label>\n                                            <input type="checkbox" id="validation-validated" data-value="4"\n                                                   class="cbx-validation js-keep-logged-in">\n                                        </label>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <ul class="nav nav-tabs">\n                    ';
 if(displayMelt) { ;
__p += '\n                    <li class="nav-tab ">\n                        <a href="#meltCurvesRaw-graphPopup" data-toggle="tab">MC-Raw</a>\n                    </li>\n                    <li class="nav-tab">\n                        <a href="#meltCurves-graphPopup" data-toggle="tab">MC</a>\n                    </li>\n                    <li class="nav-tab active">\n                        <a href="#meltCurves1-graphPopup" data-toggle="tab">MC-D1</a>\n                    </li>\n                    <li class="nav-tab">\n                        <a href="#meltCurves2-graphPopup" data-toggle="tab">MC-D2</a>\n                    </li>\n                    ';
 } ;
__p += '\n                    ';
 if(displayAmpl) { ;
__p += '\n                    <li class="nav-tab">\n                        <a href="#amplCurvesRaw-graphPopup" data-toggle="tab">AC-Raw</a>\n                    </li>\n                    <li class="nav-tab ' +
((__t = ( !displayMelt?'active':'' )) == null ? '' : __t) +
'">\n                        <a href="#amplCurves-graphPopup" data-toggle="tab">AC</a>\n                    </li>\n                    <li class="nav-tab">\n                        <a href="#amplCurves1-graphPopup" data-toggle="tab">AC-D1</a>\n                    </li>\n                    <li class="nav-tab">\n                        <a href="#amplCurves2-graphPopup" data-toggle="tab">AC-D2</a>\n                    </li>\n                    ';
 } ;
__p += '\n\n                    <li class="nav-tab">\n                        <div class="css-switch-container" style="\n\t\t\t\t\t\t\t\tdisplay: flex;\n\t\t\t\t\t\t\t\ttop: 9px;\n\t\t\t\t\t\t\t\tposition: relative;\n\t\t\t\t\t\t\t\theight: 21px;">\n                            <div class="css-switch-label visible" style="\n\t\t\t\t\t\t\t\t\tpadding-left: 20px;\n\t\t\t\t\t\t\t\t\twidth: auto;\n\t\t\t\t\t\t\t\t\tfont-size: 16px;\n\t\t\t\t\t\t\t\t\tfont-weight: 100;\n\t\t\t\t\t\t\t\t\tcolor: white;\n\t\t\t\t\t\t\t\t\ttop: 1px;\n\t\t\t\t\t\t\t\t\tposition: relative;">' +
((__t = ( _.i18n('chartview.legend') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="css-switch-button" style="width: auto; padding-left: 5px;">\n                                <label class="switch">\n                                    <input type="checkbox" class="chart-legend-change"> <!-- checked -->\n                                    <span class="slider classicSliderColors round"></span>\n                                </label>\n                            </div>\n                        </div>\n                    </li>\n                    <li class="nav-tab">\n                        <div class="css-switch-container" style="\n\t\t\t\t\t\t\t\tdisplay: flex;\n\t\t\t\t\t\t\t\ttop: 9px;\n\t\t\t\t\t\t\t\tposition: relative;\n\t\t\t\t\t\t\t\theight: 21px;">\n                            <div class="css-switch-label visible" style="\n\t\t\t\t\t\t\t\t\tpadding-left: 20px;\n\t\t\t\t\t\t\t\t\twidth: auto;\n\t\t\t\t\t\t\t\t\tfont-size: 16px;\n\t\t\t\t\t\t\t\t\tfont-weight: 100;\n\t\t\t\t\t\t\t\t\tcolor: white;\n\t\t\t\t\t\t\t\t\ttop: 1px;\n\t\t\t\t\t\t\t\t\tposition: relative;">' +
((__t = ( _.i18n('chartview.smooth') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="css-switch-button" style="width: auto; padding-left: 5px;">\n                                <label class="switch">\n                                    <input type="checkbox" class="rangeSmooth" ' +
((__t = ( rangeSmooth ? 'checked="checked"' :
                                    '' )) == null ? '' : __t) +
'>\n                                    <span class="slider classicSliderColors round"></span>\n                                </label>\n                            </div>\n                        </div>\n                    </li>\n\n\n                </ul>\n                <div class="tab-content ">\n                    ';
 if(displayMelt) { ;
__p += '\n                    <div class="tab-pane" id="meltCurvesRaw-graphPopup">\n                        <div id="run-graphRaw-region-graphPopup"></div>\n                    </div>\n                    <div class="tab-pane" id="meltCurves-graphPopup">\n                        <div id="run-graph-region-graphPopup"></div>\n                    </div>\n                    <div class="tab-pane active" id="meltCurves1-graphPopup">\n                        <div id="run-graph1-region-graphPopup"></div>\n                    </div>\n                    <div class="tab-pane" id="meltCurves2-graphPopup">\n                        <div id="run-graph2-region-graphPopup"></div>\n                    </div>\n                    ';
 } ;
__p += '\n                    ';
 if(displayAmpl) { ;
__p += '\n                    <div class="tab-pane" id="amplCurvesRaw-graphPopup">\n                        <div id="run-ampl-graphRaw-region-graphPopup"></div>\n                    </div>\n                    <div class="tab-pane ' +
((__t = ( !displayMelt?'active':'' )) == null ? '' : __t) +
'" id="amplCurves-graphPopup">\n                        <div id="run-ampl-graph-region-graphPopup"></div>\n                    </div>\n                    <div class="tab-pane" id="amplCurves1-graphPopup">\n                        <div id="run-ampl-graph1-region-graphPopup"></div>\n                    </div>\n                    <div class="tab-pane" id="amplCurves2-graphPopup">\n                        <div id="run-ampl-graph2-region-graphPopup"></div>\n                    </div>\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n\n\n        </div>\n    </div>\n</div>';

}
return __p
};});

