define('entities/caccounts/routinggroup',[
    'app',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'template!TooltipRoutingGroupCondition'
], function (
    App,
    Backbone,
    Settings,
    $,
    _,
    module,
    Dynamic,
    ConditionTooltips
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    app.ns.RoutingGroup = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('compuzz', 'v2/routinggroup/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'condition': null,
            'preProcessing': false
        },
        idAttribute: 'secId',
        getImageUid: function () {
        },

        postUrl: function () {
            return Settings.url('compuzz', 'v2/routinggroup');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'RoutingGroupJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'RoutingGroup',
        getEntity: function () {
            return module.exports;
        },
        importable: true,
        validateCondition: function (condition) {
            var params = {
                condition: condition
            };

            var url = Settings.url('compuzz', 'v2/routinggroup/validateCondition', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.ns.RoutingGroupCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('compuzz', 'v2/routinggroup/');
        },
        model: app.ns.RoutingGroup
    });

    module.exports = _.defaults({
        getName: function () {
            return 'routingGroup';
        },
        getUrl: function () {
            return 'v2/routinggroup';
        },
        getDynamicJson: function () {
            return 'RoutingGroupJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn(viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('routingGroup.preProcessing'),
                name: 'preProcessing',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                width: 10
            }, {
                label: _.i18n('validCondition'),
                name: 'validCondition',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                width: 10
            }, {
                label: _.i18n('routing.group.condition'),
                name: 'condition',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 80
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore();
            ignored.push('validCondition');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom();
            custom.push(
                {
                    'field': 'condition',
                    'param': {
                        'type': 'TEXTAREA',
                        'display': {tooltip: ConditionTooltips}
                    },
                    'validate': function (model) {
                        return model.validateCondition(btoa(model.get('condition')));
                    }
                }
            );
            return custom;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.ns.RoutingGroup.findOrCreate(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 150, 'fields': ['name']};
        },

        findByRoutingActionAndSampleId: function (routingActionSecId, sampleId, routingMode) {
            var params = {
                routingActionsecId: routingActionSecId,
                sampleId: sampleId,
                routingMode: routingMode
            };

            var url = Settings.url('compuzz', this.getUrl() + '/findByRoutingActionAndSampleId', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    }, Dynamic);
});
