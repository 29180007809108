define('menuElementItemCollectionView',['module',
		'marionette',
		'template!menuElementItemCollectionViewTpl',
		'dialogFormView',
		'underscore'
	],
	function (module,
			  Marionette,
			  MenuElementItemCollectionViewTpl,
			  DialogFormView,
			  _
	) {
		'use strict';

		var MenuElementItemCollectionView = DialogFormView.extend({
			template: MenuElementItemCollectionViewTpl,

			ui: {
				input: '.js-info-input-child',
				selectZoneIcon: '.js-select-zone'
			},

			events: {
				'change @ui.input': 'onInputChange',
				'focus .inputBackground': 'onInputChange',
				'click .delete-row': 'onDelete',
				'change .js-code': 'onChangeCode',
				'click @ui.selectZoneIcon': 'onClickSelectZone'
			},

			initialize: function () {
				this.model = this.options.model;
				DialogFormView.prototype.initialize.call(this);
			},

			validFields: function () {
				var result = this.validate(this.collectionFieldsToValidate, false);
				return result;
			},

			onError: function (errors, dontShowError) {
				_.each(errors, _.bind(function (error) {
					this.$('input[data-field-name="' + error.name + '"]').addClass('invalidInput');
					if (this.$('input[data-field-name="' + error.name + '"]').attr('class').includes('autocomplete')) {
						this.$('input[data-field-name="' + error.name + '"]').parent().addClass('invalidInput');
					}
				}, this));
				if (dontShowError) {
					return;
				}
				this.triggerMethod('errorMessage', _.i18n('warning.mandatoryFields'));
			},
			onChangeCode: function (e) {
				var regex = /^[A-Z_0-9]*$/;
				if (!regex.test(e.currentTarget.value)) {
					e.currentTarget.value = '';
				}
			},
			onClickSelectZone: function(e) {
				if (this.model.get('code').length > 0) {
					this.trigger('select-zone');
				}
				$('.js-select-zone').css('background-color','white');
				$(e.target).css('background-color','#03a9f4');
			},
			serializeData: function () {
				return this.options.model.toJSON();
			},
			onDelete: function () {
				this.trigger('item:delete', this.model);
			}
		});

		module.exports = MenuElementItemCollectionView;
	});
