define('datePickerPopoverMixin',[
  'module',
  'jquery',
  'underscore',
  'datePickerPopoverView',
  'moment',
  'dateUtils'
], function(
  module,
  $,
  _,
  DatePickerPopoverView,
  moment,
  dateUtils
) {
  'use strict';

  var datePickerHelper = {

    parseDateFrom: function(value) {
      return datePickerHelper._parseDate(/(\d+\/\d+\/\d+)-\d+\/\d+\/\d+/, value);
    },

    parseDateTo: function(value) {
      return datePickerHelper._parseDate(/\d+\/\d+\/\d+-(\d+\/\d+\/\d+)/, value);
    },

    _parseDate: function(regex, value) {
      var regexResult = regex.exec(value),
        date = moment.utc(new Date(dateUtils.toMDYFormat(regexResult[1]))).toDate();

      return date.getTime();
    }

  };

  module.exports = datePickerHelper;
});
