define('tracsView',[
	'module',
	'marionette',
	'underscore',
	'app',
	'jquery',
	'settings',
	'jqgridView',
	'template!tracsTpl',
	'colorUtils',
	'dateUtils',
	'runTracsEditPopupView',
	'entities/caccounts/pcrruntracs'
], function (
	module,
	Marionette,
	_,
	App,
	$,
	Settings,
	JqGridView,
	viewTpl,
	ColorUtils,
	dateConverter,
	RunTracsEditPopupView
) {
	'use strict';

	module.exports = JqGridView.extend({
		template: viewTpl,
		filtersName: 'pcrRunTracsFilters',

		pagination: function () {
			return {
				first: 0,
				rowCount: 0,
				pageSize: 18,
				currentPage: 1
			};
		},

		ui: function () {
			return _.extend({
				jqGrid: '#jq-grid-list',
				jqGridPager: '#jq-grid-pager-list',
				jqgRow: 'tr.jqgrow',
				blockLoading: '.empty-page-loader'
			}, this.gridUi);
		},

		events: function () {
			return _.extend({}, this.gridEvents);
		},

		serializeData: function () {
			this.settings = this.options.settings;
			var templateData = {};
			templateData.creatDate = dateConverter.toDateFormatString(this.model.get('creatDate'));
			templateData.model = this.model.toJSON();
			return templateData;
		},

		reloadGrid: function () {
			this.ui.jqGrid.trigger('reloadGrid');
		},

		gridOptions: function (data) {
			var that = this;

			var defaultsOptions, options,
				_colModel = [];

			_colModel.push({
				label: '',
				name: 'secId',
				key: true,
				hidden: true
			});
			_colModel.push({
				label: _.i18n('trac.creatDate'),
				name: 'creatDate',
				classes: 'order-link',
				formatter: _.bind(this.dateTimeFormatterFromSettings, this), // this formatter need DATEFMT from settings
				search: false,
				searchoptions: {clearSearch: false},
				index: 'creatDate',
				fixed: true,
				width: 140
			});
			_colModel.push({
				label: _.i18n('trac.refUserpid'),
				name: 'refUserpid',
				classes: 'well-list-pos-label',
				formatter: this.userFormatter,
				search: true,
				searchoptions: {clearSearch: false},
				sortable: true,
				index: 'user',
				fixed: true,
				width: 80
			});
			_colModel.push({
				label: _.i18n('trac.level'),
				name: 'level',
				classes: 'well-list-label',
				formatter: this.defaultFormatter,
				search: true,
				searchoptions: {clearSearch: false},
				sortable: true,
				index: 'level',
				fixed: true,
				width: 80
			});
			_colModel.push({
				label: _.i18n('trac.action'),
				name: 'action',
				classes: 'well-list-label',
				formatter: this.defaultFormatter,
				search: true,
				searchoptions: {clearSearch: false},
				sortable: true,
				index: 'action',
				fixed: true,
				width: 150
			});
			_colModel.push({
				label: _.i18n('trac.well'),
				name: 'wells',
				classes: 'well-list-label',
				formatter: this.defaultFormatter,
				search: true,
				searchoptions: {clearSearch: false},
				sortable: true,
				index: 'position',
				fixed: true,
				width: 350
			});
			_colModel.push({
				label: _.i18n('trac.comment'),
				name: 'comment',
				classes: 'well-list-label',
				formatter: this.defaultFormatter,
				search: true,
				searchoptions: {clearSearch: false},
				sortable: true,
				index: 'comment',
				fixed: true,
				width: 350
			});
			var _autowidth = true;

			defaultsOptions = this.gridInitOptions(data);
			options = {
				colModel: _colModel,
				autowidth: _autowidth,

				// sortname: this.sortOn ? this.sortOn : 'date',
				// height: 610,

				onSelectRow: _.bind(function (rowId) {
					this.ui.blockLoading.show();
					App.request('setting:get-all-setting', {'pageSize': 100}).done(_.bind(function (settings) {
						var tracModel = App.request('trac:pcr:model', {secId: rowId});
						tracModel.fetch().done(_.bind(function () {
							var editPopupView = new RunTracsEditPopupView({
								model: tracModel,
								settings: settings
							});
							editPopupView.listenTo(editPopupView, 'reloadGrid', _.bind(that.reloadGrid, that));
							editPopupView.show({
								title: _.i18n('tracs'),
								className: 'baseTableEditAddPopup kitLotEditAddPopup'
							});
							this.ui.blockLoading.hide();
						}, this));
					}, this));
				}, this)
			};

			options.sortorder = 'DESC';
			options = _.defaults(options, defaultsOptions);
			return options;
		}
	});
});
