define('entities/admin/menuelement',[
	'app',
	'backbone',
	'settings'
], function (
	App,
	Backbone,
	Settings
) {
	'use strict';

	var MenuElement = Backbone.Model.extend({
		defaults: {
			secId: null,
			code: '',
			name: '',
			description: '',
			link:'',
			sortSeq: '',
			imagePosition : ''
		},
		idAttribute: 'secId'
	});

	var MenuElementCollection = Backbone.Collection.extend({
		model: MenuElement
	});

	var API = {
		getElement: function (menuCode, menuElementCode) {
			var url = Settings.url('compuzz', 'public/menus/code/' + menuCode + '/elements/' + menuElementCode),
				defer = $.Deferred();

			$.ajax({
				method: 'get',
				url: url,
				success: function (item) {
					defer.resolve(new MenuElement(item));
				}
			});

			return defer.promise();
		}
	};

	App.reqres.setHandler('menuElements:collection', function (itemJSON) {
		var collection = new MenuElementCollection(itemJSON);
		collection.comparator = function (item) {
			return item.get('code');
		};
		collection.sort();
		return collection;
	});

	App.reqres.setHandler('menuElements:model', function (itemJSON) {
		return new MenuElement(itemJSON);
	});

	App.reqres.setHandler('menuElements:fromCode', function (menuCode, menuElementCode) {
		return API.getElement(menuCode, menuElementCode);
	});
});
