define('entities/caccounts/userCodelistelement',[
	'app',
	'backbone',
	'settings',
	'underscore',
	'jquery',
	'module',
	'dynamic',
	'backboneRelational'
], function (
	App,
	Backbone,
	Settings,
	_,
	$,
	module,
	Dynamic
) {
	'use strict';
	var app;
	if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
		app = {ns: Backbone.Relational.store._modelScopes[0]};
	} else {
		app = {ns: {}};
		Backbone.Relational.store.addModelScope(app.ns);
	}

	app.ns.UserCodeListElement = Backbone.RelationalModel.extend({
		defaults: {
			secId: null,
			code: '',
			name: '',
			seq: '',
			description: ''
		},
		idAttribute: 'secId',
		getEntity: function () {
			return module.exports;
		}
	});

	app.ns.UserCodeListElementCollection = Backbone.Collection.extend({
		model: app.ns.UserCodeListElement,
		comparator: function (o1, o2) {
			if (!o1.get('seq')) {
				return -1;
			}
			if (!o2.get('seq')) {
				return 1;
			}
			return (o1.get('seq') < o2.get('seq') ? -1 : (o1.get('seq') > o2.get('seq') ? 1 : 0));
		}
	});

	module.exports = _.defaults({
		getName: function () {
			return 'usercodelistelement';
		},
		getDynamicJson: function () {
			return 'UserCodeListElementJson';
		},
		getCreateEditLine: function () {
			return [
				{'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
				{'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-4'}}},
				{'field': 'seq', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-xs-1'}}},
				{'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-xs-4'}}}
			];
		},
		getModel: function (modelJson) {
			if (!modelJson) {
				modelJson = {};
			}
			return app.ns.UserCodeListElement.findOrCreate(modelJson);
		}
	}, Dynamic);
});
